import { ApolloError, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { KIND } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { Pencil } from "tabler-icons-react";

import Cell from "../../../components/cell";
import Content from "../../../components/content";
import FormControl from "../../../components/form-control";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import { useDictionaries } from "../../../contexts/dictionaries-context";
import { useLoading } from "../../../contexts/loading-context";
import { checkPermission } from "../../../utils/check-permission";
import { PERMISSIONS } from "../../../utils/permissions";
import { DOCUMENT_PARCELS_FIELDS } from "../document-parcels.form";
import { DOCUMENT_PARCELS_SHOW } from "../document-parcels.gql";

export default function DocumentParcelsShow(): React.ReactElement {
  const [css] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams<{ id?: string }>();
  const { isFetching, setIsFetching } = useLoading();
  const { findValue } = useDictionaries();
  const history = useHistory();

  const { refetch, data, error } = useQuery(DOCUMENT_PARCELS_SHOW, {
    variables: { id: id ? parseInt(id) : null },
  });

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    if (data?.documentParcel) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => data?.documentParcel && setIsFetching(false), [data]);

  if (!checkPermission(PERMISSIONS.documentParcel.read))
    return <Redirect to="/" />;

  return (
    <article>
      <Header
        title={`List nadany dokumentu: ${data?.documentParcel?.document?.internalNumber}`}
        labels={["Listy nadane", "Wyświetlanie"]}
        buttons={[
          {
            label: "Edytuj",
            kind: KIND.secondary,
            startEnhancer: <Pencil size={18} />,
            permission: checkPermission(PERMISSIONS.documentParcel.update),
            onClick: () => history.push(`/document-parcels/${id}/edit`),
          },
        ]}
        goBackOption
      />
      <Content>
        <Grid>
          {DOCUMENT_PARCELS_FIELDS.filter(
            (g) => g.fields.filter((f) => f.show.visible).length > 0
          ).map((group) => [
            group.label && (
              <Cell key={group.id + `-group`} span={12}>
                <LabelMedium marginBottom="scale200" marginTop="scale600">
                  {group.label}
                </LabelMedium>
                <hr
                  className={css({
                    borderWidth: "0px",
                    height: "1px",
                    backgroundColor: "#eee",
                  })}
                />
              </Cell>
            ),
            group.fields
              .filter((f) => f.show.visible)
              .map((item, index) => (
                <Cell span={item.span || 6} key={group.id + `-field` + index}>
                  <FormControl label={item.label} disabled={true}>
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : (
                      <FormattedValue
                        dataType={item.dataType}
                        data={
                          item.id === "documentId"
                            ? `${data?.documentParcel?.document?.documentKind?.toLowerCase()}/${
                                data?.documentParcel?.document?.type
                              }/${data?.documentParcel?.documentId}`
                            : item.id === "shipmentContract"
                            ? data?.documentParcel?.shipmentRate
                                ?.shipmentContractId
                            : item.id === "shipmentRate"
                            ? `${data?.documentParcel?.shipmentRate?.shipmentContractId}/${data?.documentParcel?.shipmentRate?.id}`
                            : data?.documentParcel[item.id]
                        }
                      >
                        {item.id === "documentId"
                          ? `${
                              findValue(
                                data?.documentParcel?.document?.documentType
                              ) || "Plik"
                            } [${
                              data?.documentParcel?.document?.internalNumber
                            }]`
                          : item.id === "shipmentContract"
                          ? data?.documentParcel?.shipmentRate?.shipmentContract
                              ?.name
                          : item.show.accessor
                          ? data?.documentParcel[item.show.accessor[0]]?.[
                              item.show.accessor[1]
                            ]
                          : data?.documentParcel[item.id]}
                      </FormattedValue>
                    )}
                  </FormControl>
                </Cell>
              )),
          ])}
        </Grid>
      </Content>
    </article>
  );
}
