import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { User } from "@sentry/types";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { Building, Pencil, Trash } from "tabler-icons-react";

import Cell from "../../../components/cell";
import ConfirmDialog from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import FormControl from "../../../components/form-control";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import { useLoading } from "../../../contexts/loading-context";
import { checkPermission } from "../../../utils/check-permission";
import { renderFieldLabel } from "../../../utils/document-flow";
import { PERMISSIONS } from "../../../utils/permissions";
import { Position } from "../../Positions/positions";
import {
  DOCUMENT_FLOW,
  DOCUMENT_FLOW_STEP,
  DOCUMENT_FLOW_STEP_DELETE,
} from "../flow.gql";
import { FLOW_STEPS_FIELDS } from "../flow-steps.form";

export default function FlowShowStep(): React.ReactElement {
  const [css, theme] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const history = useHistory();
  const { flowId, stepId } = useParams<{
    flowId: string;
    stepId?: string;
  }>();
  const { setIsLoading, isFetching, setIsFetching } = useLoading();

  const { data: flowData } = useQuery(DOCUMENT_FLOW, {
    variables: {
      id: flowId ? parseInt(flowId) : null,
    },
  });

  const { refetch, data, error } = useQuery(DOCUMENT_FLOW_STEP, {
    variables: { id: stepId ? parseInt(stepId) : null },
  });

  const [deleteStep] = useMutation(DOCUMENT_FLOW_STEP_DELETE);

  const submit = async () => {
    setIsLoading(true);

    try {
      await deleteStep({
        variables: {
          documentFlowStepDeleteInput: {
            id: stepId ? parseInt(stepId) : null,
          },
        },
      });

      enqueueSnackbar({
        message: "Usunięto pomyślnie",
        variant: "success",
      });

      setIsConfirmDialogOpen(false);

      history.push(`/flow/${flowId}`);
    } catch (error: unknown) {
      setIsConfirmDialogOpen(false);

      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    if (data?.documentFlowStep) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => data?.documentFlowStep && setIsFetching(false), [data]);

  if (!checkPermission(PERMISSIONS.documentFlowStep.read))
    return <Redirect to="/" />;

  return (
    <article>
      <Header
        title={`${data?.documentFlowStep?.name}`}
        labels={[
          `Kroki dla procesu: ${data?.documentFlowStep?.documentFlow?.name}`,
          "Wyświetlanie",
        ]}
        goBackOption
        buttons={[
          {
            label: "Edytuj",
            kind: KIND.secondary,
            startEnhancer: <Pencil size={18} />,
            permission: checkPermission(PERMISSIONS.documentFlowStep.update),
            onClick: () =>
              history.push(
                `/flow/${data?.documentFlowStep?.documentFlowId}/step/${stepId}/edit`
              ),
          },
        ]}
        {...(data?.documentFlowStep?.isDeletable && {
          actions: [
            {
              label: "Usuń krok",
              icon: Trash,
              color: theme.colors.negative,
              onClick: () => setIsConfirmDialogOpen(true),
              permission: checkPermission(PERMISSIONS.documentFlowStep.delete),
            },
          ],
        })}
      />
      <Content>
        <Grid>
          {FLOW_STEPS_FIELDS.filter(
            (g) =>
              g.fields.filter(
                (f) =>
                  f.showStep.visible &&
                  (!f.documentType ||
                    (f.documentType &&
                      f.documentType.includes(
                        flowData?.documentFlow?.documentType
                      )))
              ).length > 0
          ).map((group) => [
            group.label && (
              <Cell key={group.id + `-group`} span={12}>
                <LabelMedium marginBottom="scale200" marginTop="scale600">
                  {group.label}
                </LabelMedium>
                <hr
                  className={css({
                    borderWidth: "0px",
                    height: "1px",
                    backgroundColor: "#eee",
                  })}
                />
              </Cell>
            ),
            group.fields
              .filter(
                (f) =>
                  f.showStep.visible &&
                  (!f.documentType ||
                    (f.documentType &&
                      f.documentType.includes(
                        flowData?.documentFlow?.documentType
                      )))
              )
              .map((item, index) => (
                <Cell span={item.span || 6} key={group.id + `-field` + index}>
                  <FormControl label={item.label} disabled={true}>
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : (
                      <FormattedValue
                        dataType={item.dataType}
                        data={
                          item.id === "documentFlow"
                            ? data?.documentFlowStep?.documentFlowId
                            : item.id === "previousId"
                            ? `${data?.documentFlowStep?.documentFlowId}/${data?.documentFlowStep?.previousId}`
                            : data?.documentFlowStep[item.id]
                        }
                      >
                        {item.showStep.accessor
                          ? data?.documentFlowStep[item.showStep.accessor[0]]?.[
                              item.showStep.accessor[1]
                            ]
                          : data?.documentFlowStep[item.id]}
                      </FormattedValue>
                    )}
                  </FormControl>
                </Cell>
              )),
          ])}
          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Jednostka organizacyjna, grupa, stanowiska lub osoby
              odpowiedzialne za akceptację kroku
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>

          <Cell span={6}>
            {isFetching ? (
              <Skeleton rows={0} height="20px" width="100%" animation />
            ) : (
              <Block marginBottom="30px">
                {data?.documentFlowStep?.isProcessedByDocumentFlowStarter ? (
                  <FormControl label="Użytkownik" disabled={true}>
                    <FormattedValue>{"Rozpoczynający obieg"}</FormattedValue>
                  </FormControl>
                ) : data?.documentFlowStep?.users?.length ? (
                  <FormControl label="Osoby" disabled={true}>
                    <>
                      {data?.documentFlowStep?.users?.map(
                        (user: User, index: number) => (
                          <Block
                            key={`flow-step-user-${index + 1}`}
                            marginBottom="10px"
                          >
                            <FormattedValue
                              dataType="model:users"
                              data={user?.id}
                            >
                              {`${user?.firstName} ${user?.lastName}`}
                            </FormattedValue>
                            <br />
                          </Block>
                        )
                      )}
                    </>
                  </FormControl>
                ) : data?.documentFlowStep?.organizationalUnit ? (
                  <FormControl label="Jednostka organizacyjna" disabled={true}>
                    <FormattedValue
                      dataType="model:organizational-units"
                      data={data?.documentFlowStep?.organizationalUnitId}
                    >
                      {data?.documentFlowStep?.organizationalUnit?.name}
                    </FormattedValue>
                  </FormControl>
                ) : data?.documentFlowStep?.group ? (
                  <FormControl label="Grupa" disabled={true}>
                    <FormattedValue
                      dataType="model:groups"
                      data={data?.documentFlowStep?.groupId}
                    >
                      {data?.documentFlowStep?.group?.name}
                    </FormattedValue>
                  </FormControl>
                ) : (
                  <FormControl label="Jednostka organizacyjna" disabled={true}>
                    <>
                      <Building
                        size={16}
                        className={css({
                          verticalAlign: "middle",
                          marginRight: "10px",
                          display: "inline",
                        })}
                      />
                      Jednostka dokumentu
                    </>
                  </FormControl>
                )}
              </Block>
            )}
          </Cell>
          <Cell span={6}>
            {isFetching ? (
              <Skeleton rows={0} height="20px" width="100%" animation />
            ) : (
              <FormControl label="Stanowiska" disabled={true}>
                <>
                  {data?.documentFlowStep?.positions?.length ? (
                    data?.documentFlowStep?.positions?.map(
                      (position: Position, index: number) => (
                        <Block
                          key={`flow-step-position-${index + 1}`}
                          marginBottom="10px"
                        >
                          <FormattedValue
                            dataType="model:positions"
                            data={position?.id?.toString()}
                          >
                            {position?.name}
                          </FormattedValue>
                          <br />
                        </Block>
                      )
                    )
                  ) : (
                    <FormattedValue />
                  )}
                </>
              </FormControl>
            )}
          </Cell>
          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Pola dozwolone do edycji
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={6}>
            {isFetching ? (
              <Skeleton rows={0} height="20px" width="100%" animation />
            ) : (
              <FormControl label="Wybrane pola" disabled={true}>
                <FormattedValue>
                  {data?.documentFlowStep?.isAllowedToEditAllFields
                    ? "Wszystkie"
                    : data?.documentFlowStep?.allowedDocumentFields
                        ?.map((fieldName: string) =>
                          renderFieldLabel(fieldName)
                        )
                        .join(", ")}
                </FormattedValue>
              </FormControl>
            )}
          </Cell>
          <ConfirmDialog
            isOpen={isConfirmDialogOpen}
            label={`Usunięcie kroku ${data?.documentFlowStep?.name}`}
            close={() => setIsConfirmDialogOpen(false)}
            confirm={submit}
          />
        </Grid>
      </Content>
    </article>
  );
}
