import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import {
  ArrowBack,
  ArrowBigRightLines,
  ArrowForwardUp,
  Check,
  ChevronDown,
  ClipboardList,
  FileArrowLeft,
  FileCheck,
  FileExport,
  FileX,
  FileZip,
  FolderPlus,
  Folders,
  HandGrab,
  HandOff,
  Link,
  Lock,
  LockOpen,
  Pencil,
  ReportMoney,
  Send,
  Settings,
  Share,
  Unlink,
  UserPlus,
  X,
} from "tabler-icons-react";

import AssignedDocumentsTable from "../../../../components/assigned-documents-table";
import AttachmentsTable from "../../../../components/attachments-table";
import Button from "../../../../components/button";
import Cell from "../../../../components/cell";
import Content from "../../../../components/content";
import DocumentBarcode from "../../../../components/document-barcode";
import {
  DocumentCommonModalsRenderer,
  DocumentModalName,
} from "../../../../components/document-common-modals-renderer";
import FlowActionModal from "../../../../components/flow-action-modal";
import FlowProgress from "../../../../components/flow-progress";
import FormControl from "../../../../components/form-control";
import FormattedValue from "../../../../components/formatted-value";
import Grid from "../../../../components/grid";
import Header from "../../../../components/header";
import HistoryTable from "../../../../components/history-table";
import {
  DOCUMENT_STATES,
  FINANCIAL_ACCOUNTING_DOCUMENTS_DOCUMENT_TYPES,
  HIDDEN_ADDRESS,
  HIDDEN_FILE,
  NOT_FOUND_ERROR,
} from "../../../../constants";
import { useAssignment } from "../../../../contexts/assignment-context";
import { useAuth } from "../../../../contexts/auth-context";
import { useDictionaries } from "../../../../contexts/dictionaries-context";
import { useLoading } from "../../../../contexts/loading-context";
import { useSubstitution } from "../../../../contexts/substitution-context";
import { FILTERS } from "../../../../filters.d";
import { checkPermission } from "../../../../utils/check-permission";
import {
  handleDataForProgressBar,
  handleHistoryFlowSteps,
} from "../../../../utils/document-flow";
import {
  checkIsDispatchable,
  checkIsShareable,
} from "../../../../utils/documents/assignment-actions";
import { renderMutationSuccessLabel } from "../../../../utils/documents/mutations-snackbar";
import { generateUUID } from "../../../../utils/misc";
import { PERMISSIONS } from "../../../../utils/permissions";
import Error404 from "../../../ErrorPages/404";
import {
  DocumentFlowAction,
  HistoryFlowStep,
} from "../../../Flow/document-flow";
import {
  AssignmentLocationState,
  AssignmentType,
} from "../../../Folders/folders.d";
import {
  DocumentLink,
  DocumentMutationAction,
  DocumentStatus,
} from "../../documents.d";
import {
  DOCUMENT_ADDRESS_FIELDS,
  DOCUMENTS_FIELDS,
} from "../../documents.form";
import {
  ADDRESS_REVEAL,
  DOCUMENT_FLOW_AVAILABLE_ACTIONS,
  DOCUMENTS_ACCEPT,
  DOCUMENTS_CANCEL_DISPATCH,
  DOCUMENTS_RELEASE_RESPONSIBLE,
  DOCUMENTS_SET_RESPONSIBLE,
  DOCUMENTS_SHOW,
  DOCUMENTS_TRACK_OPEN,
  DOCUMENTS_UNLINK_FROM_CASE,
  DOCUMENTS_ZIP_EXPORT,
  FILES_REVEAL,
} from "../../documents.gql";

export default function DocumentsShowOutgoing(): React.ReactElement {
  const [isAnyAddressRevealed, setIsAnyAddressRevealed] = useState(false);
  const [isFlowActionModalOpen, setIsFlowActionModalOpen] = useState(false);
  const [targetFlowAction, setTargetFlowAction] = useState<
    DocumentFlowAction | undefined
  >(undefined);

  const [flowStepsHistory, setFlowStepsHistory] = useState<HistoryFlowStep[]>(
    []
  );
  const [statusesForProgressBar, setStatusesForProgressBar] = useState<
    DocumentStatus[]
  >([]);
  const [css, theme] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { refetch: userRefetch, user } = useAuth();
  const { isFetching, setIsFetching, setIsLoading } = useLoading();
  const { findValue } = useDictionaries();
  const { substitution, isSubstitutionActive } = useSubstitution();

  const [
    openedCommonModal,
    setOpenedCommonModal,
  ] = useState<DocumentModalName>();
  const { id } = useParams<{
    id: string;
  }>();
  const documentId = parseInt(id);

  const { assignment, setAssignment, setIsAssignmentActive } = useAssignment();
  const { state } = useLocation<AssignmentLocationState>();

  const assignmentContext = {
    ...((state?.assignmentId || assignment?.id) &&
      state?.assignmentId !== null && {
        context: {
          headers: {
            Assignment: state?.assignmentId || assignment?.id,
          },
        },
      }),
  };

  const { refetch, data, error } = useQuery(DOCUMENTS_SHOW, {
    variables: { id: documentId },
    ...((state?.assignmentId || assignment?.id) &&
      state?.assignmentId !== null && {
        context: {
          headers: {
            Assignment: state?.assignmentId || assignment?.id,
          },
        },
      }),
  });

  const documentLinks = data?.document?.documentLinks;

  const parentDocuments = documentLinks?.filter(
    ({ type, linkerId }: DocumentLink) =>
      type === "Parent" && documentId === linkerId
  );

  const childrenDocuments = documentLinks?.filter(
    ({ type, linkedId }: DocumentLink) =>
      type === "Parent" && documentId === linkedId
  );

  const assignedDocuments = documentLinks?.filter(
    ({ type }: DocumentLink) => type === null
  );

  const files = data?.document?.files;

  const hasHiddenFiles =
    !!files?.length && files[0]?.__typename === HIDDEN_FILE;

  const {
    data: availableActionsData,
    refetch: availableActionsRefetch,
  } = useQuery(DOCUMENT_FLOW_AVAILABLE_ACTIONS, {
    variables: {
      documentId,
    },
  });

  const availableActionsButtons = availableActionsData?.documentFlowAvailableActions?.map(
    (action: DocumentFlowAction) => {
      return {
        label: action.label,
        kind: KIND.primary,
        startEnhancer:
          action.style === "positive" ? (
            <Check size={18} />
          ) : action.style === "negative" ? (
            <X size={18} />
          ) : (
            <Send size={18} />
          ),
        isPositive: action.style === "positive",
        isNegative: action.style === "negative",
        onClick: () => {
          setTargetFlowAction(action);
          setIsFlowActionModalOpen(true);
        },
      };
    }
  );

  const hasFlowSteps = !!data?.document?.currentStatus;
  const hasAnySignaturesBefore = statusesForProgressBar?.some(
    (status: DocumentStatus) => !!status.signatureDownloadUrl
  );
  const lastStatusWithSignature = statusesForProgressBar
    ?.filter((status: DocumentStatus) => !!status.signatureDownloadUrl)
    .pop();

  useEffect(() => {
    refetch();
  }, [data?.document?.currentStatus]);

  const [acceptDocument] = useMutation(DOCUMENTS_ACCEPT, assignmentContext);

  const [cancelDispatch] = useMutation(DOCUMENTS_CANCEL_DISPATCH);

  const [exportZipFile] = useMutation(DOCUMENTS_ZIP_EXPORT, assignmentContext);

  const [releaseResponsible] = useMutation(
    DOCUMENTS_RELEASE_RESPONSIBLE,
    assignmentContext
  );

  const [revealAddress, { loading: revealAddressLoading }] = useMutation(
    ADDRESS_REVEAL,
    assignmentContext
  );

  const [revealFiles, { loading: revealFilesLoading }] = useMutation(
    FILES_REVEAL,
    assignmentContext
  );

  const [setResponsible] = useMutation(
    DOCUMENTS_SET_RESPONSIBLE,
    assignmentContext
  );

  const [unlinkCase] = useMutation(
    DOCUMENTS_UNLINK_FROM_CASE,
    assignmentContext
  );

  const [trackOpen] = useMutation(DOCUMENTS_TRACK_OPEN, assignmentContext);

  const documentMutationVariables = {
    variables: {
      documentId,
    },
  };

  const documentAddressMutationVariables = {
    variables: {
      documentId,
      addressId: data?.document?.sender?.id,
    },
  };

  const onDocumentMutationSubmit = async (
    action: DocumentMutationAction
  ): Promise<void> => {
    setIsLoading(true);

    try {
      if (action === DocumentMutationAction.Accept)
        await acceptDocument(documentMutationVariables);

      if (action === DocumentMutationAction.CancelDispatch)
        await cancelDispatch(documentMutationVariables);

      if (action === DocumentMutationAction.ExportZip) {
        const response = await exportZipFile(documentMutationVariables);

        const apiUrl = process.env.REACT_APP_GRAPHQL_API_URL?.replace(
          "/graphql",
          ""
        );

        window.open(
          `${apiUrl}/documents/download-zip-export/${id}/${response?.data?.documentExportZip}`,
          "_self"
        );
      }

      if (action === DocumentMutationAction.ReleaseResponsible)
        await releaseResponsible(documentMutationVariables);

      if (action === DocumentMutationAction.RevealAddress) {
        await revealAddress(documentAddressMutationVariables);

        setIsAnyAddressRevealed(true);
      }

      if (action === DocumentMutationAction.RevealFiles)
        await revealFiles(documentMutationVariables);

      if (action === DocumentMutationAction.SetResponsible)
        await setResponsible(documentMutationVariables);

      if (action === DocumentMutationAction.UnlinkCase)
        await unlinkCase(documentMutationVariables);

      enqueueSnackbar({
        message: renderMutationSuccessLabel(action),
        variant: "success",
      });

      refetch();
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);

      setOpenedCommonModal(undefined);
    }
  };

  useEffect(() => {
    if (
      error?.graphQLErrors &&
      (error as ApolloError).graphQLErrors?.map(
        ({ extensions }) => extensions?.code
      )[0] !== NOT_FOUND_ERROR
    )
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    if (data?.document) refetch();
    if (availableActionsData?.documentFlowAvailableActions)
      availableActionsRefetch();
    setIsFetching(true);
    setStatusesForProgressBar([]);
    trackOpen({ variables: { id: documentId } }).then(() => userRefetch());
  }, []);

  useEffect(() => {
    if (availableActionsData) availableActionsRefetch();
  }, [data?.document?.currentStatus]);

  useEffect(() => {
    if (data?.document?.currentAssignment) {
      setAssignment(data?.document?.currentAssignment);
      setIsAssignmentActive(true);
    }
    setFlowStepsHistory(handleHistoryFlowSteps(data?.document?.statuses));
    setStatusesForProgressBar(
      handleDataForProgressBar(data?.document?.statuses)
    );
    if (data?.document) setIsFetching(false);
    data?.document?.recipient?.isSensitive
      ? setIsAnyAddressRevealed(true)
      : setIsAnyAddressRevealed(false);
  }, [data]);

  if (
    !checkPermission(PERMISSIONS.document.read) &&
    !checkPermission(PERMISSIONS.document.readAll)
  ) {
    return <Redirect to="/" />;
  } else if (
    (error?.graphQLErrors &&
      (error as ApolloError).graphQLErrors?.map(
        ({ extensions }) => extensions?.code
      )[0] === NOT_FOUND_ERROR) ||
    !id
  ) {
    return <Error404 />;
  }

  return (
    <article>
      <Header
        title={data?.document?.internalNumber}
        labels={[
          <FormattedValue
            key="label_1"
            dataType={"model:cases"}
            data={data?.document?.case?.id}
            additionalData={data?.document?.case?.isConductedElectronically}
            showBlankWhenEmpty
          >
            {`${data?.document?.case?.number}`}
          </FormattedValue>,
          "Dokument",
        ]}
        goBackOption
        hasActiveAssignmentBar
        hasCancellationBar={data?.document?.state === DOCUMENT_STATES.Canceled}
        buttons={[
          {
            label: "Przejmij",
            startEnhancer: <HandGrab size={18} />,
            kind: KIND.secondary,
            isPositive: true,
            onClick: () =>
              onDocumentMutationSubmit(DocumentMutationAction.SetResponsible),
            permission: data?.document?.isResponsibleSettable,
          },
          {
            label: "Zwolnij",
            startEnhancer: <HandOff size={18} />,
            kind: KIND.secondary,
            onClick: () =>
              onDocumentMutationSubmit(
                DocumentMutationAction.ReleaseResponsible
              ),
            permission:
              data?.document?.responsible?.id ===
                (isSubstitutionActive
                  ? substitution?.substitutee.id
                  : user?.id) && data?.document?.isReleasable,
          },
          {
            label: "Załóż sprawę",
            kind: KIND.primary,
            startEnhancer: <Link size={18} />,
            endEnhancer: <ChevronDown size={18} />,
            permission: !data?.document?.case?.id && data?.document?.isEditable,
            subactions: [
              {
                label: "Załóż nową sprawę",
                icon: FolderPlus,
                color: theme.colors.contentPrimary,
                onClick: () => setOpenedCommonModal("case-create"),
                permission:
                  checkPermission(PERMISSIONS.case.create) &&
                  data?.document?.isEditable &&
                  data?.document?.state !== DOCUMENT_STATES.Canceled,
              },
              {
                label: "Przypisz do istniejącej",
                icon: Folders,
                color: theme.colors.contentPrimary,
                onClick: () => setOpenedCommonModal("assign-to-case"),
                permission:
                  checkPermission(PERMISSIONS.document.update) &&
                  data?.document?.isEditable &&
                  data?.document?.state !== DOCUMENT_STATES.Canceled,
              },
            ],
          },
          {
            label: "Udostępnij",
            kind: KIND.primary,
            startEnhancer: <Share size={18} />,
            endEnhancer: <ChevronDown size={18} />,
            subactions: [
              {
                label: "Udostępnij",
                icon: UserPlus,
                color: theme.colors.primary,
                permission: checkIsShareable(data?.document),
                onClick: () =>
                  history.push(
                    location.pathname + `/share`,
                    state || data?.document?.currentAssignment?.id
                      ? { assignmentId: data.document.currentAssignment?.id }
                      : undefined
                  ),
              },
              {
                label: "Zarządzaj dostępem",
                icon: Settings,
                color: theme.colors.contentPrimary,
                onClick: () =>
                  history.push(
                    `/folders/shared?filters=${window.btoa(
                      unescape(
                        encodeURIComponent(
                          JSON.stringify([
                            {
                              uuid: generateUUID(),
                              filter: {
                                id: "primaryAssignableId",
                                label: "Obiekt",
                                type: FILTERS.PrimaryAssignable,
                              },
                              state: {
                                type: "Document",
                                value: data?.document?.id,
                                internalNumber: data?.document?.internalNumber,
                              },
                            },
                            {
                              uuid: generateUUID(),
                              filter: {
                                id: "status",
                                label: "Status",
                                type: FILTERS.AssignmentStatus,
                              },
                              state: {
                                value: [
                                  { id: "New", label: "Nowe" },
                                  { id: "InProgress", label: "W realizacji" },
                                ],
                              },
                            },
                          ])
                        )
                      )
                    )}`
                  ),
              },
            ],
            permission: !data?.document?.documentFlowId,
          },
          {
            label: "Przekaż",
            startEnhancer: <ArrowForwardUp size={18} />,
            kind: KIND.primary,
            endEnhancer: <ChevronDown size={18} />,
            subactions: [
              {
                label: "Przekaż dokument",
                icon: ArrowForwardUp,
                color: theme.colors.contentPrimary,
                onClick: () => setOpenedCommonModal("document-dispatch"),
              },
              {
                label: "Zwróć dokument",
                icon: ArrowBack,
                color: theme.colors.negative,
                onClick: () =>
                  setOpenedCommonModal("document-dispatch-with-dispatcher"),

                permission:
                  !!data?.document?.currentAssignment?.assigner?.id &&
                  data?.document?.currentAssignment?.type !==
                    AssignmentType.Initial,
              },
            ],
            permission: checkIsDispatchable(data?.document),
          },
          {
            label: "Akceptuj",
            startEnhancer: <Check size={18} />,
            kind: KIND.primary,
            isPositive: true,
            permission:
              !data?.document?.documentFlowId &&
              data?.document?.isDispatchable &&
              !!data?.document?.currentAssignment?.isAssessmentRequested &&
              data?.document?.state !== DOCUMENT_STATES.Canceled,
            onClick: () =>
              setOpenedCommonModal("document-assignment-assessment-accept"),
          },
          {
            label: "Anuluj przekazanie dokumentu",
            startEnhancer: <ArrowBack size={18} />,
            kind: KIND.secondary,
            isNegative: true,
            onClick: () => setOpenedCommonModal("cancel-assignment"),
            permission: data?.document?.isDispatchCancelable,
          },
          {
            label: "Odrzuć",
            startEnhancer: <X size={18} />,
            kind: KIND.primary,
            isNegative: true,
            permission:
              !data?.document?.documentFlowId &&
              data?.document?.isDispatchable &&
              !!data?.document?.currentAssignment?.isAssessmentRequested &&
              data?.document?.state !== DOCUMENT_STATES.Canceled,
            onClick: () =>
              setOpenedCommonModal("document-assignment-assessment-reject"),
          },
          {
            label: "Rozpocznij obieg",
            startEnhancer: <ArrowBigRightLines size={18} />,
            kind: KIND.primary,
            isPositive: true,
            permission:
              data?.document?.isEditable &&
              !data?.document?.documentFlowId &&
              !!data?.document?.documentFlow,
            onClick: () => setOpenedCommonModal("document-flow-start"),
          },
          ...(availableActionsButtons || []),
        ]}
        actions={[
          {
            label: "Edytuj dokument",
            icon: Pencil,
            permission:
              checkPermission(PERMISSIONS.document.update) &&
              data?.document?.isEditable,
            onClick: () =>
              history.push(
                `/documents/outgoing/Document/${id}/edit`,
                state || data?.document?.currentAssignment?.id
                  ? { assignmentId: data.document.currentAssignment?.id }
                  : undefined
              ),
          },
          {
            label: "Odepnij dokument od sprawy",
            icon: Unlink,
            permission:
              checkPermission(PERMISSIONS.document.update) &&
              data?.document?.isEditable &&
              !!data?.document?.case &&
              data?.document?.responsible?.id === user?.id,
            onClick: () =>
              onDocumentMutationSubmit(DocumentMutationAction.UnlinkCase),
          },
          {
            label: "Akceptuj dokument",
            icon: FileCheck,
            color: theme.colors.positive,
            permission:
              data?.document?.isEditable &&
              ((data?.document?.isDispatchable &&
                !data?.document?.documentFlowId &&
                !data?.document?.currentAssignment?.isAssessmentRequested) ||
                !data?.document?.isDispatchable),
            onClick: () =>
              onDocumentMutationSubmit(DocumentMutationAction.Accept),
          },
          {
            label: "Nadaj list",
            icon: FileExport,
            color: theme.colors.primary,
            onClick: () =>
              history.push(
                location.pathname + `/ship`,
                state || data?.document?.currentAssignment?.id
                  ? { assignmentId: data.document.currentAssignment?.id }
                  : undefined
              ),
            permission:
              checkPermission(PERMISSIONS.documentParcel.create) &&
              data?.document?.isEditable &&
              !data?.document?.shippedAt,
          },
          {
            label: "Powiąż z dokumentem",
            icon: Link,
            color: theme.colors.primary,
            onClick: () => setOpenedCommonModal("document-link"),
            permission:
              checkPermission(PERMISSIONS.document.update) &&
              data?.document?.isEditable &&
              data?.document?.state !== DOCUMENT_STATES.Canceled,
          },
          {
            label: "Uzupełnij dane F-K",
            icon: ReportMoney,
            color: theme.colors.primary,
            onClick: () =>
              history.push({
                pathname: "/documents/create-financial-accounting-document",
                state: {
                  documentId,
                  documentType: data?.document?.documentType,
                  documentKind: data?.document?.documentKind,
                  documentInternalNumber: data?.document?.internalNumber,
                  assignmentId: state?.assignmentId,
                },
              }),
            permission:
              checkPermission(PERMISSIONS.financialAccountingDocument.create) &&
              FINANCIAL_ACCOUNTING_DOCUMENTS_DOCUMENT_TYPES.map((item) =>
                item.toLowerCase()
              ).includes(data?.document?.documentType?.toLowerCase()) &&
              data?.document?.isEditable &&
              data?.document?.state !== DOCUMENT_STATES.Canceled,
          },
          {
            label: "Anuluj dokument",
            icon: FileX,
            color: theme.colors.negative,
            onClick: () => setOpenedCommonModal("document-cancel"),
            permission:
              checkPermission(PERMISSIONS.document.cancel) &&
              data?.document?.isEditable &&
              (data?.document?.state === DOCUMENT_STATES.New ||
                data?.document?.currentStatus?.documentFlowStep?.isInitial),
          },
          {
            label: "Cofnij anulowanie dokumentu",
            icon: FileArrowLeft,
            color: theme.colors.positive,
            onClick: () => setOpenedCommonModal("document-uncancel"),
            permission:
              checkPermission(PERMISSIONS.document.cancel) &&
              data?.document?.state === DOCUMENT_STATES.Canceled,
          },
          {
            label: "Pokaż logi dokumentu",
            icon: ClipboardList,
            color: theme.colors.black,
            onClick: () => setOpenedCommonModal("activity-logs"),
            permission: checkPermission(PERMISSIONS.document.read),
          },
          {
            label: "Pobierz plik ZIP",
            icon: FileZip,
            color: theme.colors.black,
            onClick: () =>
              onDocumentMutationSubmit(DocumentMutationAction.ExportZip),
          },
        ]}
      >
        <DocumentBarcode
          documentId={documentId}
          internalNumber={data?.document?.internalNumber as string}
        />
      </Header>
      <Content>
        <Grid>
          <Cell span={12}>
            <>
              {hasFlowSteps &&
                availableActionsData &&
                (isFetching ? (
                  <Skeleton rows={0} height="150px" width="100%" animation />
                ) : (
                  <FlowProgress
                    currentStep={
                      data?.document?.currentStatus?.documentFlowStep
                    }
                    originalOrganizationalUnitName={
                      data?.document?.organizationalUnit?.name
                    }
                    statuses={statusesForProgressBar}
                    actions={availableActionsData.documentFlowAvailableActions}
                  />
                ))}
            </>
          </Cell>
          {DOCUMENTS_FIELDS.filter(
            (g) =>
              g.fields.filter(
                (f) => f.show.visible && f.typeName?.includes("Outgoing")
              ).length > 0
          ).map((group) => [
            group.label && (
              <Cell key={group.id + `-group`} span={12}>
                <>
                  <Block
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    marginBottom="scale200"
                    marginTop="scale600"
                  >
                    <Block
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      {data?.document?.recipient?.__typename ===
                        HIDDEN_ADDRESS && group.id === "recipient" ? (
                        <Lock
                          size={18}
                          color={theme.colors.negative}
                          className={css({ marginRight: "10px" })}
                        />
                      ) : (
                        group.id === "recipient" && (
                          <LockOpen
                            size={18}
                            color={theme.colors.positive}
                            className={css({ marginRight: "10px" })}
                          />
                        )
                      )}
                      <LabelMedium>{group.label}</LabelMedium>
                      {data?.document?.recipient?.__typename ===
                        HIDDEN_ADDRESS &&
                        group.id === "recipient" && (
                          <Button
                            size={SIZE.mini}
                            kind={KIND.secondary}
                            $style={{ marginLeft: "10px" }}
                            onClick={() =>
                              setOpenedCommonModal("confirm-address-reveal")
                            }
                            isLoading={revealAddressLoading}
                          >
                            Pokaż dane
                          </Button>
                        )}
                    </Block>
                    {data?.document?.recipient?.__typename !== HIDDEN_ADDRESS &&
                      isAnyAddressRevealed &&
                      group.id === "recipient" && (
                        <FormattedValue $style={{ fontSize: "12px" }}>
                          Dostęp jest aktywny do końca trwania sesji
                        </FormattedValue>
                      )}
                  </Block>
                  <hr
                    className={css({
                      borderWidth: "0px",
                      height: "1px",
                      backgroundColor: "#eee",
                    })}
                  />
                </>
              </Cell>
            ),
            group.fields
              .filter((f) => f.show.visible)
              .filter((f) => f.typeName?.includes("Outgoing"))
              .map((item, index) => (
                <Cell
                  span={item.span || 6}
                  skip={item.skip || 0}
                  key={group.id + `-field` + index}
                >
                  <FormControl
                    label={item.label}
                    disabled={true}
                    overrides={{
                      ControlContainer: {
                        props: {
                          "data-test-id": item.id,
                        },
                      },
                    }}
                  >
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : item.dataType?.includes("model:users") ? (
                      <FormattedValue
                        dataType={item.dataType}
                        data={data?.document?.[item.id]?.id}
                      >
                        {data?.document?.[item.id] &&
                          `${data?.document?.[item.id]?.firstName} ${
                            data?.document?.[item.id]?.lastName
                          }`}
                      </FormattedValue>
                    ) : data?.document?.recipient?.__typename ===
                        HIDDEN_ADDRESS &&
                      item.id.includes("address") &&
                      !item.id.includes("Name") ? (
                      <Skeleton
                        rows={0}
                        height="20px"
                        width="15ch"
                        overrides={{
                          Root: {
                            style: {
                              backgroundColor: "#faebeb",
                            },
                          },
                        }}
                      />
                    ) : (
                      <FormattedValue
                        dataType={item.dataType}
                        data={
                          item.id === "case"
                            ? data?.document?.case?.id
                            : item.id === "organizationalUnit"
                            ? data?.document?.organizationalUnit?.id
                            : data?.document[item.id]
                        }
                        additionalData={
                          data?.document?.case?.[item.additionalData]
                        }
                      >
                        {item.dataType?.includes("cases") ||
                        item.dataType?.includes("documents")
                          ? data?.document[item.id]
                            ? item.dataType === "model:documents"
                              ? `[${
                                  data?.document?.documentLinks?.[0]?.linked &&
                                  data?.document?.documentLinks?.[0]?.linked
                                    ?.internalNumber
                                }] ${findValue(
                                  data?.document?.documentLinks?.[0]?.linked
                                    ?.documentType
                                )}`
                              : item.dataType === "model:cases"
                              ? `[${data?.document?.case?.number}] ${data?.document?.case?.name}`
                              : data?.document[item.id].id
                            : null
                          : item.id === "documentType"
                          ? findValue(data?.document?.documentType)
                          : item.id === "addressAddress"
                          ? data?.document?.recipient?.streetName
                            ? `${data?.document?.recipient?.streetName} ${
                                data?.document?.recipient?.buildingNumber
                              }${
                                data?.document?.recipient?.apartmentNumber
                                  ? "/" +
                                    data?.document?.recipient?.apartmentNumber
                                  : ""
                              }`
                            : data?.document?.recipient?.address
                          : item.id === "senderWhenUser"
                          ? `${data?.document?.sender?.firstName} ${data?.document?.sender?.lastName}`
                          : item.show.accessor
                          ? data?.document[item.show.accessor[0]]?.[
                              item.show.accessor[1]
                            ]
                          : data?.document[item.id]}
                      </FormattedValue>
                    )}
                  </FormControl>
                </Cell>
              )),
          ])}
          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Dokumenty nadrzędne {!parentDocuments?.length && "(0)"}
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={12} $style={{ position: "relative" }}>
            {isFetching ? (
              <Skeleton rows={0} height="300px" width="100%" animation />
            ) : (
              data &&
              !!parentDocuments?.length && (
                <Block paddingTop="scale200" paddingBottom="scale600">
                  <AssignedDocumentsTable
                    documentId={documentId}
                    data={parentDocuments}
                    assignmentId={data.document.currentAssignment?.id}
                    onCompleted={refetch}
                  />
                </Block>
              )
            )}
          </Cell>

          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Dokumenty podrzędne {!childrenDocuments?.length && "(0)"}
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={12} $style={{ position: "relative" }}>
            {isFetching ? (
              <Skeleton rows={0} height="300px" width="100%" animation />
            ) : (
              data &&
              !!childrenDocuments?.length && (
                <Block paddingTop="scale200" paddingBottom="scale600">
                  <AssignedDocumentsTable
                    documentId={documentId}
                    data={childrenDocuments}
                    assignmentId={data.document.currentAssignment?.id}
                    onCompleted={refetch}
                  />
                </Block>
              )
            )}
          </Cell>

          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Dokumenty powiązane {!assignedDocuments?.length && "(0)"}
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={12} $style={{ position: "relative" }}>
            {isFetching ? (
              <Skeleton rows={0} height="300px" width="100%" animation />
            ) : (
              data &&
              !!assignedDocuments?.length && (
                <Block paddingTop="scale200" paddingBottom="scale600">
                  <AssignedDocumentsTable
                    documentId={documentId}
                    data={assignedDocuments}
                    assignmentId={data.document.currentAssignment?.id}
                    onCompleted={refetch}
                  />
                </Block>
              )
            )}
          </Cell>
          <Cell span={12}>
            <>
              <Block
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                marginBottom="scale200"
                marginTop="scale600"
              >
                <Block
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  {hasHiddenFiles
                    ? !!files?.length && (
                        <Lock
                          size={18}
                          color={theme.colors.negative}
                          className={css({ marginRight: "10px" })}
                        />
                      )
                    : !!files?.length && (
                        <LockOpen
                          size={18}
                          color={theme.colors.positive}
                          className={css({ marginRight: "10px" })}
                        />
                      )}
                  <LabelMedium>Załączniki {` (${files?.length})`}</LabelMedium>

                  {hasHiddenFiles && (
                    <Button
                      size={SIZE.mini}
                      kind={KIND.secondary}
                      $style={{ marginLeft: "10px" }}
                      onClick={() =>
                        setOpenedCommonModal("confirm-files-reveal")
                      }
                      isLoading={revealFilesLoading}
                    >
                      Pokaż pliki
                    </Button>
                  )}
                </Block>
                {!hasHiddenFiles && !!files?.length && (
                  <FormattedValue $style={{ fontSize: "12px" }}>
                    Dostęp jest aktywny do końca trwania sesji
                  </FormattedValue>
                )}
              </Block>

              <hr
                className={css({
                  borderWidth: "0px",
                  height: "1px",
                  backgroundColor: "#eee",
                })}
              />
            </>
          </Cell>
          <Cell span={12}>
            {isFetching ? (
              <Skeleton rows={0} height="200px" width="100%" animation />
            ) : hasHiddenFiles ? (
              <Skeleton
                rows={0}
                height="20px"
                width="100%"
                overrides={{
                  Root: {
                    style: {
                      backgroundColor: "#faebeb",
                    },
                  },
                }}
              />
            ) : files?.length ? (
              <Block paddingTop="scale200" paddingBottom="scale600">
                <AttachmentsTable data={files} />
              </Block>
            ) : (
              <></>
            )}
          </Cell>

          {data?.document?.shippedAt && (
            <>
              <Cell span={12}>
                <Block
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <LabelMedium marginBottom="scale200" marginTop="scale600">
                    Nadanie
                  </LabelMedium>
                </Block>
                <hr
                  className={css({
                    borderWidth: "0px",
                    height: "1px",
                    backgroundColor: "#eee",
                  })}
                />
              </Cell>

              <Cell span={4} $style={{ position: "relative" }}>
                <FormControl label="Data nadania" disabled>
                  <FormattedValue dataType="datetime">
                    {data?.document?.shippedAt}
                  </FormattedValue>
                </FormControl>
              </Cell>

              <Cell span={4} $style={{ position: "relative" }}>
                <FormControl label="Typ przesyłki" disabled>
                  <FormattedValue dataType="electronical-boolean">
                    {data?.document?.isElectronical}
                  </FormattedValue>
                </FormControl>
              </Cell>

              <Cell span={4} $style={{ position: "relative" }}>
                <FormControl label="Sposób wysyłki" disabled>
                  <FormattedValue textWhenEmpty="N/D">
                    {data?.document?.shippingProvider === "PocztaPolska"
                      ? "Poczta Polska"
                      : data?.document?.shippingProvider === "Courier"
                      ? "Kurier"
                      : undefined}
                  </FormattedValue>
                </FormControl>
              </Cell>

              {DOCUMENT_ADDRESS_FIELDS.filter(
                (g) =>
                  g.fields.filter(
                    (f) => f.show.visible && f.typeName?.includes("Outgoing")
                  ).length > 0
              ).map((group) => [
                group.label && (
                  <Cell key={group.id + `-group`} span={12}>
                    <>
                      <Block
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        marginBottom="scale200"
                        marginTop="scale600"
                      >
                        <Block
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          {data?.document?.recipient?.__typename ===
                            HIDDEN_ADDRESS && group.id === "recipient" ? (
                            <Lock
                              size={18}
                              color={theme.colors.negative}
                              className={css({ marginRight: "10px" })}
                            />
                          ) : (
                            group.id === "recipient" && (
                              <LockOpen
                                size={18}
                                color={theme.colors.positive}
                                className={css({ marginRight: "10px" })}
                              />
                            )
                          )}
                          <LabelMedium>{group.label}</LabelMedium>
                          {data?.document?.recipient?.__typename ===
                            HIDDEN_ADDRESS &&
                            group.id === "recipient" && (
                              <Button
                                size={SIZE.mini}
                                kind={KIND.secondary}
                                $style={{ marginLeft: "10px" }}
                                onClick={() =>
                                  setOpenedCommonModal("confirm-address-reveal")
                                }
                                isLoading={revealAddressLoading}
                              >
                                Pokaż dane
                              </Button>
                            )}
                        </Block>
                        {data?.document?.recipient?.__typename !==
                          HIDDEN_ADDRESS &&
                          isAnyAddressRevealed &&
                          group.id === "recipient" && (
                            <FormattedValue
                              dataType="pre"
                              $style={{ fontSize: "13px" }}
                            >
                              Dostęp jest aktywny do końca trwania sesji
                            </FormattedValue>
                          )}
                      </Block>
                      <hr
                        className={css({
                          borderWidth: "0px",
                          height: "1px",
                          backgroundColor: "#eee",
                        })}
                      />
                    </>
                  </Cell>
                ),
                group.fields
                  .filter((f) => f.show.visible)
                  .map((item, index) => (
                    <Cell
                      span={item.span || 6}
                      skip={item.skip || 0}
                      key={group.id + `-field` + index}
                    >
                      <FormControl
                        label={item.label}
                        disabled={true}
                        overrides={{
                          ControlContainer: {
                            props: {
                              "data-test-id": item.id,
                            },
                          },
                        }}
                      >
                        {isFetching ? (
                          <Skeleton
                            rows={0}
                            height="20px"
                            width="100%"
                            animation
                          />
                        ) : item.dataType?.includes("model:users") ? (
                          <FormattedValue
                            dataType={item.dataType}
                            data={data?.document?.sender?.id}
                          >
                            {data?.document?.sender &&
                              `${data?.document?.sender?.firstName} ${data?.document?.sender?.lastName}`}
                          </FormattedValue>
                        ) : data?.document?.recipient?.__typename ===
                            HIDDEN_ADDRESS &&
                          item.id.includes("address") &&
                          !item.id.includes("Name") ? (
                          <Skeleton
                            rows={0}
                            height="20px"
                            width="15ch"
                            overrides={{
                              Root: {
                                style: {
                                  backgroundColor: "#faebeb",
                                },
                              },
                            }}
                          />
                        ) : (
                          <FormattedValue
                            dataType={item.dataType}
                            data={
                              item.id === "case"
                                ? data?.document?.case?.id
                                : item.id === "organizationalUnit"
                                ? data?.document?.organizationalUnit?.id
                                : data?.document[item.id]
                            }
                            additionalData={
                              data?.document?.case?.[item.additionalData]
                            }
                          >
                            {item.dataType?.includes("cases") ||
                            item.dataType?.includes("documents")
                              ? data?.document[item.id]
                                ? item.dataType === "model:documents"
                                  ? `[${
                                      data?.document?.documentLinks?.[0]
                                        ?.linked &&
                                      data?.document?.documentLinks?.[0]?.linked
                                        ?.internalNumber
                                    }] ${findValue(
                                      data?.document?.documentLinks?.[0]?.linked
                                        ?.documentType
                                    )}`
                                  : item.dataType === "model:cases"
                                  ? `[${data?.document?.case?.number}] ${data?.document?.case?.name}`
                                  : data?.document[item.id].id
                                : null
                              : item.id === "documentType"
                              ? findValue(data?.document?.documentType)
                              : item.id === "addressAddress"
                              ? data?.document?.recipient?.streetName
                                ? `${data?.document?.recipient?.streetName} ${
                                    data?.document?.recipient?.buildingNumber
                                  }${
                                    data?.document?.recipient?.apartmentNumber
                                      ? "/" +
                                        data?.document?.recipient
                                          ?.apartmentNumber
                                      : ""
                                  }`
                                : data?.document?.recipient?.address
                              : item.id === "senderWhenUser"
                              ? `${data?.document?.sender?.firstName} ${data?.document?.sender?.lastName}`
                              : item.show.accessor
                              ? data?.document[item.show.accessor[0]]?.[
                                  item.show.accessor[1]
                                ]
                              : data?.document[item.id]}
                          </FormattedValue>
                        )}
                      </FormControl>
                    </Cell>
                  )),
              ])}
            </>
          )}

          {hasFlowSteps && (
            <>
              <Cell span={12}>
                <LabelMedium marginBottom="scale200" marginTop="scale600">
                  Historia obiegu dokumentu{" "}
                  {!flowStepsHistory?.length && "(brak)"}
                </LabelMedium>
                <hr
                  className={css({
                    borderWidth: "0px",
                    height: "1px",
                    backgroundColor: "#eee",
                  })}
                />
              </Cell>
              <Cell span={12}>
                {isFetching ? (
                  <Skeleton rows={0} height="200px" width="100%" animation />
                ) : flowStepsHistory?.length ? (
                  <Block paddingTop="scale200" paddingBottom="scale600">
                    <HistoryTable
                      documentStatuses={data?.document?.statuses}
                      flowStepsHistory={flowStepsHistory}
                    />
                  </Block>
                ) : (
                  <></>
                )}
              </Cell>
            </>
          )}

          {targetFlowAction && (
            <FlowActionModal
              documentId={documentId}
              action={targetFlowAction}
              hasAnySignaturesBefore={hasAnySignaturesBefore}
              isOpen={isFlowActionModalOpen}
              label={`${
                data?.document?.documentType &&
                findValue(data?.document?.documentType)
              } ${data?.document?.internalNumber}`}
              lastStatusWithSignature={lastStatusWithSignature}
              reloadPage={() => {
                refetch();
                availableActionsRefetch();
              }}
              setIsOpen={setIsFlowActionModalOpen}
            />
          )}

          <DocumentCommonModalsRenderer
            openedCommonModal={openedCommonModal}
            close={() => setOpenedCommonModal(undefined)}
            documentId={documentId}
            document={data?.document}
            refetch={refetch}
            onDocumentMutationSubmit={onDocumentMutationSubmit}
          />
        </Grid>
      </Content>
    </article>
  );
}
