import { FIELDS, FieldsGroup } from "../../fields.d";

export type GroupsFormInputs = {
  name: string;
  users: {
    id: string | number;
    label?: string;
  }[];
};

export enum GroupsFieldName {
  Name = "name",
  Users = "users",
  CreatedAt = "createdAt",
  CreatedBy = "createdBy",
  UpdatedAt = "updatedAt",
}

export const GROUPS_FIELDS: FieldsGroup[] = [
  {
    id: "basic",
    label: "Podstawowe informacje",
    fields: [
      {
        id: GroupsFieldName.Name,
        label: "Nazwa",
        type: FIELDS.Input,
        span: 6,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: GroupsFieldName.Users,
        label: "Pracownicy",
        type: FIELDS.UsersLiveSearchTableSelect,
        span: 8,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: GroupsFieldName.CreatedAt,
        label: "Data utworzenia",
        type: FIELDS.Input,
        dataType: "datetime",
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: GroupsFieldName.CreatedBy,
        label: "Utworzono przez",
        type: FIELDS.Input,
        dataType: "model:users",
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
    ],
  },
];
