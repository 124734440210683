import { ApolloError, useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import BottomPanel from "../../../components/bottom-panel";
import Cell from "../../../components/cell";
import Content from "../../../components/content";
import DocumentsTable, { FieldName } from "../../../components/documents-table";
import Filters from "../../../components/filters";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import PagingControls from "../../../components/paging-controls";
import { SortDirection } from "../../../components/sorting-table-header";
import { useLoading } from "../../../contexts/loading-context";
import { usePaging } from "../../../contexts/paging-context";
import { BasicFilter, FiltersState } from "../../../filters";
import { translateFiltersState } from "../../../utils/filters";
import { DOCUMENTS_FILTERS } from "../documents.filters";
import { ALL_DOCUMENTS_FOLDER } from "../documents.gql";

export default function DocumentsAll(): React.ReactElement {
  const { pageSize, currentPage, setTotalCount } = usePaging();
  const { search } = useLocation();

  const params = useMemo(() => new URLSearchParams(search), [search]);

  const [sortBy, setSortBy] = useState<FieldName | null>(
    (params.get("sortBy") as FieldName) || FieldName.CreatedAt
  );

  const [sortDirection, setSortDirection] = useState<SortDirection | null>(
    (params.get("sortDirection") as SortDirection) || SortDirection.DESC
  );

  const { enqueueSnackbar } = useSnackbar();
  const {
    isFetching,
    setIsFetching,
    isPartialFetching,
    setIsPartialFetching,
  } = useLoading();
  const [filters, setFilters] = useState<FiltersState>();

  const { refetch, data, loading, error } = useQuery(ALL_DOCUMENTS_FOLDER, {
    variables: {
      pageSize,
      offset: (currentPage - 1) * pageSize,
      sorting: {
        field: sortBy,
        direction: sortDirection,
      },
      filter: {
        and: [
          {
            documentType: {
              notILike: "transferOrder",
            },
          },
          {
            ...(filters && (translateFiltersState(filters) as BasicFilter[])),
          },
        ],
      },
    },
  });

  useEffect(() => {
    if (data?.allDocumentsFolder) setTimeout(() => refetch(), 200);
    setIsFetching(true);
  }, []);

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  const handleSorting = (column: FieldName) => {
    setSortBy(column);
    setSortDirection(
      sortDirection === null
        ? SortDirection.DESC
        : sortDirection === SortDirection.ASC
        ? SortDirection.DESC
        : SortDirection.ASC
    );
  };

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    setTimeout(() => refetch(), 200);
    setIsPartialFetching(true);
  }, [currentPage, pageSize]);

  useEffect(() => {
    setTimeout(() => refetch(), 200);
    setIsPartialFetching(true);
  }, [sortBy, sortDirection]);

  useEffect(() => {
    if (data?.allDocumentsFolder) setIsFetching(false);
    if (data?.allDocumentsFolder?.totalCount >= 0)
      setTotalCount(data?.allDocumentsFolder?.totalCount);
  }, [data]);

  return (
    <article>
      <Header
        title="Wszystkie dokumenty"
        recordsNum={data?.allDocumentsFolder?.totalCount}
        labels={["Lista"]}
      />
      <Filters
        filters={DOCUMENTS_FILTERS}
        state={filters}
        setState={setFilters}
      />
      <Content filtersOffset>
        <Grid>
          <Cell span={12} $style={{ position: "relative" }}>
            <DocumentsTable
              documents={data?.allDocumentsFolder?.nodes}
              handleSorting={handleSorting}
              sortBy={sortBy}
              sortDirection={sortDirection}
              loading={isFetching || isPartialFetching || loading}
              refetch={refetch}
            />
          </Cell>
          <Cell span={12}>
            <BottomPanel>
              <PagingControls />
            </BottomPanel>
          </Cell>
        </Grid>
      </Content>
    </article>
  );
}
