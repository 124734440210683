import { gql } from "@apollo/client";

export const DOCUMENT_PARCELS_INDEX = gql`
  query(
    $pageSize: Int
    $offset: Int
    $sorting: [DocumentParcelSort!]
    $filter: DocumentParcelFilter
  ) {
    documentParcels(
      paging: { limit: $pageSize, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        createdAt
        documentId
        document {
          internalNumber
          documentType
          type
          documentKind
          id
        }
        shipmentRateId
        shipmentRate {
          id
          shipmentContractId
          eNadawcaShipmentType
        }
        width
        height
        length
        weight
        isCountryService
        countryCode
      }
    }
  }
`;

export const DOCUMENT_PARCELS_SHOW = gql`
  query($id: Int!) {
    documentParcel(id: $id) {
      id
      createdAt
      documentId
      document {
        internalNumber
        documentType
        type
        documentKind
        id
      }
      width
      height
      length
      weight
      isCountryService
      countryCode
      shipmentRate {
        id
        name
        price
        shipmentContractId
        shipmentContract {
          name
        }
      }
    }
  }
`;

export const DOCUMENT_PARCELS_CREATE = gql`
  mutation($documentParcelCreateInput: DocumentParcelCreateInput!) {
    documentParcelCreate(
      documentParcelCreateInput: $documentParcelCreateInput
    ) {
      id
      shipmentRateId
      document {
        documentType
        type
        id
      }
    }
  }
`;

export const DOCUMENT_PARCELS_UPDATE = gql`
  mutation($documentParcelUpdateInput: DocumentParcelUpdateInput!) {
    documentParcelUpdate(
      documentParcelUpdateInput: $documentParcelUpdateInput
    ) {
      id
      shipmentRateId
    }
  }
`;

export const DOCUMENT_PARCELS_DELETE = gql`
  mutation($id: Int!) {
    documentParcelDelete(documentParcelDeleteInput: { id: $id }) {
      id
    }
  }
`;
