import { ApolloError, useMutation } from "@apollo/client";
import { KIND } from "baseui/button";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Redirect, useHistory } from "react-router-dom";
import { DeviceFloppy, FileOff, Note } from "tabler-icons-react";

import ConfirmDialog from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import FormRenderer from "../../../components/form-renderer";
import Header from "../../../components/header";
import { useLoading } from "../../../contexts/loading-context";
import { checkPermission } from "../../../utils/check-permission";
import { PERMISSIONS } from "../../../utils/permissions";
import { GROUPS_FIELDS, GroupsFormInputs } from "../groups.form";
import { GROUPS_CREATE } from "../groups.gql";

export default function GroupsCreate(): React.ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const [isClearConfirmDialogOpen, setIsClearConfirmDialogOpen] = useState(
    false
  );
  const history = useHistory();
  const [createGroup, { error }] = useMutation(GROUPS_CREATE);
  const { isLoading, setIsLoading } = useLoading();

  const {
    control,
    formState: { errors, isDirty, ...formState },
    handleSubmit,
    reset,
    ...methods
  } = useForm<GroupsFormInputs>({
    defaultValues: {
      name: "",
      users: [],
    },
  });

  const onSubmit = async ({ name, users }: GroupsFormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      const { data } = await createGroup({
        variables: {
          groupCreateInput: {
            name,
            userIds: users?.map(
              ({ id }: { id: number | string }) => id as number
            ),
          },
        },
      });

      enqueueSnackbar({
        message: "Utworzono pomyślnie",
        variant: "success",
      });

      history.push(`/groups/${data?.groupCreate?.id}`);
    } catch (error: unknown) {
      enqueueSnackbar({
        message:
          (error as ApolloError).graphQLErrors?.map(
            ({ message }) => message
          )[0] || (error as ApolloError).message,
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (!checkPermission(PERMISSIONS.group.create)) return <Redirect to="/" />;

  return (
    <article>
      <Header
        title="Nowa grupa"
        labels={["Grupy", "Tworzenie"]}
        goBackOption
        buttons={[
          {
            label: "Wyczyść",
            kind: KIND.secondary,
            startEnhancer: <Note size={18} />,
            disabled: isLoading,
            onClick: () => {
              setIsClearConfirmDialogOpen(true);
            },
          },
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              isDirty ? setIsCancelConfirmDialogOpen(true) : history.goBack(),
          },
          {
            label: "Utwórz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            onClick: handleSubmit(onSubmit),
            disabled: isLoading,
            isLoading: isLoading,
            type: "submit",
            formId: "createGroup",
          },
        ]}
      />
      <Content>
        <FormProvider<GroupsFormInputs>
          control={control}
          formState={{ errors, isDirty, ...formState }}
          handleSubmit={handleSubmit}
          reset={reset}
          {...methods}
        >
          <FormRenderer
            id="createGroup"
            onSubmit={handleSubmit(onSubmit)}
            type="create"
            isLoading={isLoading}
            error={error}
            fields={GROUPS_FIELDS}
          />
        </FormProvider>

        <ConfirmDialog
          isOpen={isCancelConfirmDialogOpen}
          label="Anulowanie tworzenia nowej grupy"
          close={() => setIsCancelConfirmDialogOpen(false)}
          confirm={() => history.goBack()}
        />

        <ConfirmDialog
          isOpen={isClearConfirmDialogOpen}
          label="Wyczyszczenie formularza"
          close={() => setIsClearConfirmDialogOpen(false)}
          confirm={() => {
            reset();
            setIsClearConfirmDialogOpen(false);
          }}
        />
      </Content>
    </article>
  );
}
