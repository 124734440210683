import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { KIND } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { AlertOctagon, DeviceFloppy, FileOff } from "tabler-icons-react";

import { InputValidationError } from "../../../api";
import { ControlledCaseConductSwitch } from "../../../components/case-conduct-switch";
import Cell from "../../../components/cell";
import { ControlledCheckbox } from "../../../components/checkbox";
import ConfirmDialog from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import FormControl from "../../../components/form-control";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import { ControlledInput } from "../../../components/input";
import { useLoading } from "../../../contexts/loading-context";
import { FIELDS } from "../../../fields.d";
import { checkPermission } from "../../../utils/check-permission";
import { formValidation } from "../../../utils/formValidation";
import { PERMISSIONS } from "../../../utils/permissions";
import {
  FormInputs,
  JRWA_CLASSIFICATIONS_FIELDS,
} from "../jrwa-classifications.form";
import {
  JRWA_CLASSIFICATIONS_SHOW,
  JRWA_CLASSIFICATIONS_UPDATE,
} from "../jrwa-classifications.gql";

export default function JrwaClassificationsEdit(): React.ReactElement {
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const [css] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const { id } = useParams<{ id?: string }>();

  const { data, error: queryError } = useQuery(JRWA_CLASSIFICATIONS_SHOW, {
    variables: { id: id ? parseInt(id) : null },
    fetchPolicy: "cache-first",
  });
  const [editJrwa, { error }] = useMutation(JRWA_CLASSIFICATIONS_UPDATE);

  const parentId = data?.jrwaClassification?.parentId;

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    setValue,
  } = useForm<FormInputs>({
    defaultValues: {
      symbol: data?.jrwaClassification?.symbol,
      name: data?.jrwaClassification?.name,
      archivalCategory: data?.jrwaClassification?.archivalCategory,
      description: data?.jrwaClassification?.description,
      isBeneficiaryAddressRequired:
        data?.jrwaClassification?.isBeneficiaryAddressRequired,
    },
  });

  const { isFetching, setIsFetching, isLoading, setIsLoading } = useLoading();

  const onSubmit = async ({
    symbol,
    name,
    description,
    archivalCategory,
    isBeneficiaryAddressRequired,
    isCaseConductedElectronically,
    isCaseNotRequired,
  }: FormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      await editJrwa({
        variables: {
          jrwaClassificationUpdateInput: {
            symbol,
            name,
            description,
            archivalCategory,
            isBeneficiaryAddressRequired: !!isBeneficiaryAddressRequired,
            isCaseConductedElectronically: !!isCaseConductedElectronically,
            isCaseNotRequired: !!isCaseNotRequired,
            id: id ? parseInt(id) : null,
            parentId,
          },
        },
      });

      enqueueSnackbar({
        message: "Zapisano pomyślnie",
        variant: "success",
      });

      history.push(`/jrwa-classifications/${id}`);
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => setIsFetching(true), []);

  useEffect(() => {
    if (data?.jrwaClassification) {
      setValue("symbol", data.jrwaClassification.symbol);
      setValue("name", data.jrwaClassification.name);
      setValue("archivalCategory", data.jrwaClassification.archivalCategory);
      setValue("description", data.jrwaClassification.description);
      setValue(
        "isBeneficiaryAddressRequired",
        data.jrwaClassification.isBeneficiaryAddressRequired
      );
      setValue(
        "isCaseConductedElectronically",
        data.jrwaClassification.isCaseConductedElectronically
      );
      setValue("isCaseNotRequired", data.jrwaClassification.isCaseNotRequired);
      setIsFetching(false);
    }
  }, [data]);

  useEffect(() => {
    if (queryError?.graphQLErrors)
      enqueueSnackbar({
        message: (queryError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [queryError]);

  if (!checkPermission(PERMISSIONS.jrwa.update)) return <Redirect to="/" />;

  return (
    <article>
      <Header
        title={data?.jrwaClassification?.name}
        labels={["Klasyfikacja JRWA", "Edytowanie"]}
        goBackOption
        buttons={[
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              isDirty ? setIsCancelConfirmDialogOpen(true) : history.goBack(),
          },
          {
            label: "Zapisz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            onClick: handleSubmit(onSubmit),
            disabled: isLoading,
            isLoading: isLoading,
            type: "submit",
            formId: "editJrwa",
          },
        ]}
      />
      <Content>
        <form id="editJrwa" onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            {JRWA_CLASSIFICATIONS_FIELDS.filter(
              (g) => g.fields.filter((f) => f.edit.visible).length > 0
            ).map((group) => [
              group.label && (
                <Cell key={group.id + `-group`} span={12}>
                  <LabelMedium marginBottom="scale200" marginTop="scale600">
                    {group.label}
                  </LabelMedium>
                  <hr
                    className={css({
                      borderWidth: "0px",
                      height: "1px",
                      backgroundColor: "#eee",
                    })}
                  />
                </Cell>
              ),
              group.fields
                .filter((f) => f.edit.visible)
                .map((item, index) => (
                  <Cell span={item.span || 6} key={group.id + `-field` + index}>
                    <FormControl
                      label={item.type === "checkbox" ? "" : item.label}
                      caption={item.caption}
                      required={item.edit.required}
                      error={
                        ((errors as any)[item.id] &&
                          (errors as any)[item.id].message) ||
                        (error &&
                          error.graphQLErrors[0]?.extensions?.code ===
                            "INPUT_VALIDATION_ERROR" &&
                          error.graphQLErrors[0]?.extensions?.validationErrors
                            ?.find(
                              (vE: InputValidationError) =>
                                vE?.property === item.id
                            )
                            ?.errors.map((message: string) => (
                              <div
                                key="error"
                                className={css({
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                })}
                              >
                                {message} <br />
                                {item.caption}
                                <AlertOctagon color="#999" size={12} />
                              </div>
                            ))[0])
                      }
                      disabled={isLoading}
                    >
                      {isFetching ? (
                        <Skeleton
                          rows={0}
                          height="36px"
                          width="100%"
                          animation
                        />
                      ) : item.type === "checkbox" ? (
                        <div className={css({ marginTop: "30px" })}>
                          <ControlledCheckbox
                            control={control}
                            name={item.id}
                            disabled={isLoading}
                            {...(item.create.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                          >
                            {item.label}
                          </ControlledCheckbox>
                        </div>
                      ) : item.type === FIELDS.CaseConductSwitch ? (
                        <ControlledCaseConductSwitch
                          control={control}
                          name={item.id}
                          disabled={isLoading}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : (
                        <ControlledInput
                          control={control}
                          name={item.id}
                          placeholder={item.placeholder}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      )}
                    </FormControl>
                  </Cell>
                )),
            ])}
            <ConfirmDialog
              isOpen={isCancelConfirmDialogOpen}
              label="Anulowanie edycji klasyfikacji JRWA"
              close={() => setIsCancelConfirmDialogOpen(false)}
              confirm={() => history.goBack()}
            />
          </Grid>
        </form>
      </Content>
    </article>
  );
}
