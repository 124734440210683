import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { StyledLink } from "baseui/link";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { MouseEvent, useEffect, useMemo, useState } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { Row } from "react-table";
import { Affiliate, Pencil, Switch } from "tabler-icons-react";

import Button from "../../../components/button";
import Cell from "../../../components/cell";
import ConfirmDialog from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import FormControl from "../../../components/form-control";
import {
  AssignUserToOrganizationalUnitDialog,
  MoveOrganizationalUnitDialog,
} from "../../../components/form-dialog";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import Table from "../../../components/table";
import { useLoading } from "../../../contexts/loading-context";
import { checkPermission } from "../../../utils/check-permission";
import { PERMISSIONS } from "../../../utils/permissions";
import { OrganizationalUnitToUser, User } from "../../Users/users";
import { OrganizationalUnit } from "../organizational-units";
import { ORGANIZATIONAL_UNITS_FIELDS } from "../organizational-units.form";
import {
  ORGANIZATIONAL_UNITS_DELETE,
  ORGANIZATIONAL_UNITS_SHOW,
  ORGANIZATIONAL_UNITS_UNASSIGN_USER,
} from "../organizational-units.gql";

export default function OrganizationalUnitsShow(): React.ReactElement {
  const [css, theme] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isAssignDialogOpen, setIsAssignDialogOpen] = useState(false);
  const [isMoveDialogOpen, setIsMoveDialogOpen] = useState(false);

  const [
    isConfirmUnassignDialogOpen,
    setIsConfirmUnassignDialogOpen,
  ] = useState<User | null>(null);

  const history = useHistory();

  const { id } = useParams<{ id?: string }>();

  const { setIsLoading, isFetching, setIsFetching } = useLoading();

  const { refetch, data, error } = useQuery(ORGANIZATIONAL_UNITS_SHOW, {
    variables: { id: id ? parseInt(id) : null },
    fetchPolicy: "cache-first",
  });

  const [deleteUnit] = useMutation(ORGANIZATIONAL_UNITS_DELETE);
  const [usassignUser] = useMutation(ORGANIZATIONAL_UNITS_UNASSIGN_USER);

  const submit = async () => {
    setIsLoading(true);

    try {
      await deleteUnit({
        variables: {
          id: id ? parseInt(id) : null,
        },
      });

      enqueueSnackbar({
        message: "Usunięto pomyślnie",
        variant: "success",
      });

      setIsConfirmDialogOpen(false);

      history.push("/organizational-units");
    } catch (error: unknown) {
      setIsConfirmDialogOpen(false);

      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const submitUnassign = async ({
    id,
    userId,
  }: {
    id: number;
    userId: number;
  }) => {
    setIsLoading(true);

    try {
      await usassignUser({
        variables: {
          id,
          userId,
        },
      });

      setIsConfirmUnassignDialogOpen(null);

      enqueueSnackbar({
        message: "Zapisano pomyślnie",
        variant: "success",
      });

      refetch();
    } catch (error: unknown) {
      setIsConfirmUnassignDialogOpen(null);
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    if (data?.organizationalUnit) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => data?.organizationalUnit && setIsFetching(false), [data]);

  const columns = {
    childrenUnits: useMemo(
      () => [
        {
          Header: "Nazwa",
          id: "name",
          Cell: ({ row }: { row: Row<OrganizationalUnit> }) => (
            <>
              <StyledLink
                onClick={(event: MouseEvent) => {
                  event.preventDefault();
                  history.push(`/organizational-units/${row.original.id}`);
                }}
                href={`/organizational-units/${row.original.id}`}
              >
                {row.original.name}
              </StyledLink>
            </>
          ),
        },
        {
          Header: "Symbol jednostki",
          accessor: "symbol",
        },
        {
          Header: "Symbol budżetowy",
          accessor: "budgetSymbol",
        },
        {
          id: "actions",
          Cell: ({ row }: { row: Row<OrganizationalUnit> }) =>
            checkPermission(PERMISSIONS.organizationalUnit.update) && (
              <div
                className={css({
                  display: "flex",
                  justifyContent: "flex-end",
                })}
              >
                <Button
                  kind={KIND.secondary}
                  size={SIZE.mini}
                  onClick={() =>
                    history.push(
                      `/organizational-units/${row.original.id}/edit`
                    )
                  }
                  startEnhancer={<Pencil size={14} />}
                />
              </div>
            ),
        },
      ],
      []
    ),
    unitToUsers: useMemo(
      () => [
        {
          Header: "Użytkownik",
          id: "user",
          Cell: ({ row }: { row: Row<OrganizationalUnitToUser> }) => (
            <FormattedValue
              dataType="model:users"
              data={row?.original?.user?.id}
            >
              {`${row?.original?.user?.firstName} ${row?.original?.user?.lastName}`}
            </FormattedValue>
          ),
        },
        {
          Header: "Stanowisko",
          id: "position",
          Cell: ({ row }: { row: Row<OrganizationalUnitToUser> }) => (
            <FormattedValue
              dataType="model:positions"
              data={row?.original?.position?.id}
            >
              {row?.original?.position?.name}
            </FormattedValue>
          ),
        },
        {
          id: "actions",
          Cell: ({ row }: { row: Row<OrganizationalUnitToUser> }) => (
            <div
              className={css({
                display: "flex",
                justifyContent: "flex-end",
              })}
            >
              {checkPermission(PERMISSIONS.organizationalUnit.unassignUser) && (
                <Button
                  kind={KIND.secondary}
                  size={SIZE.mini}
                  onClick={() =>
                    setIsConfirmUnassignDialogOpen({
                      id: row?.original?.user?.id,
                      firstName: row?.original?.user?.firstName,
                      lastName: row?.original?.user?.lastName,
                    })
                  }
                >
                  Usuń
                </Button>
              )}
            </div>
          ),
        },
      ],
      []
    ),
  };

  if (!checkPermission(PERMISSIONS.organizationalUnit.read))
    return <Redirect to="/" />;

  return (
    <article>
      <Header
        title={data?.organizationalUnit?.name}
        labels={["Jednostki organizacyjne", "Wyświetlanie"]}
        goBackOption
        buttons={[
          {
            label: "Edytuj",
            kind: KIND.secondary,
            startEnhancer: <Pencil size={18} />,
            permission: checkPermission(PERMISSIONS.organizationalUnit.update),
            onClick: () => history.push(`/organizational-units/${id}/edit`),
          },
        ]}
        actions={[
          {
            label: "Przypisz użytkownika do jednostki organizacyjnej",
            icon: Affiliate,
            color: theme.colors.primary,
            onClick: () => setIsAssignDialogOpen(true),
            permission: checkPermission(
              PERMISSIONS.organizationalUnit.assignUser
            ),
          },
          {
            label: "Przenieś jednostkę organizacyjną",
            icon: Switch,
            onClick: () => setIsMoveDialogOpen(true),
            permission: checkPermission(PERMISSIONS.organizationalUnit.move),
          },
        ]}
      />
      <Content>
        <Grid>
          {ORGANIZATIONAL_UNITS_FIELDS.filter(
            (g) => g.fields.filter((f) => f.show.visible).length > 0
          ).map((group) => [
            group.label && (
              <Cell key={group.id + `-group`} span={12}>
                <LabelMedium marginBottom="scale200" marginTop="scale600">
                  {group.label}
                </LabelMedium>
                <hr
                  className={css({
                    borderWidth: "0px",
                    height: "1px",
                    backgroundColor: "#eee",
                  })}
                />
              </Cell>
            ),
            group.fields
              .filter((f) => f.show.visible)
              .map((item, index) => (
                <Cell span={item.span || 6} key={group.id + `-field` + index}>
                  <FormControl label={item.label} disabled={true}>
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : item.id === "groups" ? (
                      <ol
                        className={css({
                          paddingLeft: "20px",
                          marginTop: 0,
                        })}
                      >
                        {data?.organizationalUnit?.groups?.length ? (
                          data?.organizationalUnit?.groups?.map(
                            (group: string) => (
                              <li key={group}>
                                <Block marginBottom="5px">
                                  {group}
                                  <br />
                                </Block>
                              </li>
                            )
                          )
                        ) : (
                          <FormattedValue />
                        )}
                      </ol>
                    ) : (
                      <FormattedValue
                        dataType={item.dataType}
                        data={data?.organizationalUnit[item.id]}
                      >
                        {item.show.accessor
                          ? data?.organizationalUnit[item.show.accessor[0]]?.[
                              item.show.accessor[1]
                            ]
                          : data?.organizationalUnit[item.id]}
                      </FormattedValue>
                    )}
                  </FormControl>
                </Cell>
              )),
          ])}
          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Relacje – Jednostki organizacyjne
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={12}>
            {isFetching ? (
              <Skeleton rows={0} height="200px" width="100%" animation />
            ) : (
              data?.organizationalUnit?.children && (
                <Block paddingTop="scale200" paddingBottom="scale600">
                  <Table<OrganizationalUnit>
                    columns={columns.childrenUnits}
                    data={data?.organizationalUnit?.children}
                  />
                </Block>
              )
            )}
          </Cell>
          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Relacje – Użytkownicy
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={12}>
            {isFetching ? (
              <Skeleton rows={0} height="200px" width="100%" animation />
            ) : (
              data?.organizationalUnit?.organizationalUnitToUsers && (
                <Block paddingTop="scale200" paddingBottom="scale600">
                  <Table<OrganizationalUnitToUser>
                    columns={columns.unitToUsers}
                    data={data?.organizationalUnit?.organizationalUnitToUsers}
                  />
                </Block>
              )
            )}
          </Cell>
          <ConfirmDialog
            isOpen={isConfirmDialogOpen}
            label={`Usunięcie jednostki organizacyjnej ${data?.organizationalUnit?.name}`}
            close={() => setIsConfirmDialogOpen(false)}
            confirm={submit}
          />

          <ConfirmDialog
            isOpen={!!isConfirmUnassignDialogOpen}
            label={`Wypisanie użytkownika ${
              isConfirmUnassignDialogOpen?.firstName
            } ${isConfirmUnassignDialogOpen?.lastName} z organizacji ${
              data?.organizationalUnit?.name || ""
            }`}
            close={() => setIsConfirmUnassignDialogOpen(null)}
            confirm={() =>
              isConfirmUnassignDialogOpen &&
              submitUnassign({
                id: data?.organizationalUnit.id,
                userId: isConfirmUnassignDialogOpen?.id,
              })
            }
          />

          <AssignUserToOrganizationalUnitDialog
            organizationalUnit={data?.organizationalUnit}
            isOpen={isAssignDialogOpen}
            close={() => setIsAssignDialogOpen(false)}
            onCompleted={refetch}
          />

          <MoveOrganizationalUnitDialog
            organizationalUnit={data?.organizationalUnit}
            isOpen={isMoveDialogOpen}
            close={() => setIsMoveDialogOpen(false)}
            onCompleted={refetch}
          />
        </Grid>
      </Content>
    </article>
  );
}
