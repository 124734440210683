import { styled, useStyletron } from "baseui";
import React, { ReactNode, RefObject, useEffect, useState } from "react";
import { useRef } from "react";

import Cell from "./cell";
import Grid from "./grid";

const StickedTableFoot = styled(
  "div",
  ({ $sticked, $left }: { $sticked?: boolean; $left?: number }) => ({
    position: "fixed",
    left: $left ? `${$left}px` : "225px",
    right: "0px",
    bottom: "54px",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    "::-webkit-scrollbar": {
      display: "none",
    },
    zIndex: 8,
    paddingRight: "10px",
    transform: "translateY(200px)",
    ...($sticked && {
      animationIterationCount: "1",
      animationDelay: "0ms",
      animationDuration: "200ms",
      animationFillMode: "both",
      animationName: {
        from: {
          transform: "translateY(200px)",
        },
        to: {
          transform: "translateY(0px)",
        },
      } as any,
    }),
    ...(!$sticked && {
      animationIterationCount: "1",
      animationDelay: "0ms",
      animationDuration: "200ms",
      animationFillMode: "both",
      animationName: {
        from: {
          transform: "translateY(0px)",
        },
        to: {
          transform: "translateY(200px)",
        },
      } as any,
    }),
  })
);

type TableFootProps = {
  tableRef: RefObject<HTMLDivElement>;
  children: ReactNode;
  displayConditionally?: boolean;
};

export default function TableFoot({
  tableRef,
  children,
  displayConditionally,
}: TableFootProps): React.ReactElement {
  const [css, theme] = useStyletron();

  const [sticked, setSticked] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  function checkPosition() {
    if (
      (tableRef?.current?.getBoundingClientRect().bottom || 0) -
        window.innerHeight >
      0
    ) {
      setSticked(true);
    } else setSticked(false);
  }

  const customSidebarWidth =
    localStorage.getItem("sidebarWidth") &&
    parseInt(localStorage.getItem("sidebarWidth") as string);

  const [sidebarWidth, setSidebarWidth] = useState<number>(
    customSidebarWidth || 225
  );

  useEffect(() => {
    checkPosition();

    document
      ?.querySelector("main > section")
      ?.addEventListener("scroll", checkPosition, { passive: true });

    window?.addEventListener("resize", checkPosition);

    const observer = new ResizeObserver(function () {
      const width = document?.querySelector("aside")?.offsetWidth;

      if (width) {
        setSidebarWidth(width);
      }
    });

    const sidebarNode = document?.querySelector("aside");

    if (sidebarNode) {
      observer.observe(sidebarNode);
    }

    return () => {
      window?.removeEventListener("resize", checkPosition);
      document
        ?.querySelector("main > section")
        ?.removeEventListener("scroll", checkPosition);
      observer.disconnect();
    };
  }, []);

  useEffect(() => checkPosition(), [displayConditionally]);

  return (
    <StickedTableFoot
      $sticked={
        displayConditionally !== undefined
          ? displayConditionally && sticked
          : sticked
      }
      $left={sidebarWidth}
    >
      <Grid>
        <Cell span={12}>
          <div
            ref={containerRef}
            className={css({
              width: "100%",
              overflowY: "hidden",
              overflowX: "auto",
              borderRightStyle: "solid",
              borderRightWidth: "1px",
              borderRightColor: theme.colors.inputBorder,
              borderLeftStyle: "solid",
              borderLeftWidth: "1px",
              borderLeftColor: theme.colors.inputBorder,
              borderTopWidth: "1px",
              borderTopStyle: "solid",
              borderTopColor: "rgb(238, 238, 238)",
              backgroundColor: "rgb(252, 252, 252)",
            })}
            data-ui="sticked-table-head"
            onScroll={(event: any) => {
              const tableRoot = event.target?.closest("[data-ui=table-root]");

              if (tableRoot) {
                const table = tableRoot?.querySelector("[data-ui=table]");

                if (table) {
                  const scrolling =
                    tableRoot.getAttribute("data-scrolling") === "true"
                      ? true
                      : false;

                  if (scrolling) {
                    tableRoot.setAttribute("data-scrolling", "false");
                    return;
                  }

                  if (
                    table.scrollLeft !==
                    (event.target as HTMLElement).scrollLeft
                  ) {
                    tableRoot.setAttribute("data-scrolling", "true");
                    table.scrollLeft = (event.target as HTMLElement).scrollLeft;
                  }
                }
              }
            }}
          >
            <table
              className={css({
                width: "100%",
                left: "0px",
                position: "relative",
                borderCollapse: "collapse",
              })}
            >
              {children}
            </table>
          </div>
        </Cell>
      </Grid>
    </StickedTableFoot>
  );
}
