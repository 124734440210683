import { ApolloError, useMutation } from "@apollo/client";
import { useStyletron } from "baseui";
import { KIND } from "baseui/button";
import { ModalBody, ModalFooter, ModalHeader } from "baseui/modal";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { AlertOctagon } from "tabler-icons-react";

import { InputValidationError } from "../api";
import { CASES_DISPATCH } from "../containers/Cases/cases.gql";
import { DispatchFormInputs } from "../containers/Documents/documents.form";
import { User } from "../containers/Users/users";
import { useAssignment } from "../contexts/assignment-context";
import { useAuth } from "../contexts/auth-context";
import { useLoading } from "../contexts/loading-context";
import { useSubstitution } from "../contexts/substitution-context";
import { formValidation } from "../utils/formValidation";
import Button from "./button";
import FormControl from "./form-control";
import Modal from "./modal";
import { ControlledUsersSelect } from "./select";
import { ControlledTextArea } from "./text-area";

type CaseDispatchModalProps = {
  caseId?: string;
  dispatcher?: User;
  isOpen: boolean;
  onCompleted: () => void;
  close: () => void;
};

export default function CaseDispatchModal({
  caseId,
  dispatcher,
  isOpen,
  close,
  onCompleted,
}: CaseDispatchModalProps): React.ReactElement {
  const [css] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, setIsLoading } = useLoading();

  const { user } = useAuth();

  const { assignment, isAssignmentActive } = useAssignment();
  const { isSubstitutionActive, substitution } = useSubstitution();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm<DispatchFormInputs>();

  const [changeAsignee, { error, loading }] = useMutation(CASES_DISPATCH, {
    ...(isAssignmentActive &&
      assignment && {
        context: {
          headers: {
            Assignment: assignment.id,
          },
        },
      }),
  });

  const onCaseDispatchSubmit = async ({
    dispatchees,
    comment,
  }: DispatchFormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      await changeAsignee({
        variables: {
          caseDispatchInput: {
            caseId: caseId ? parseInt(caseId) : null,
            dispatcheeId: dispatchees && dispatchees[0]?.id,
            comment,
          },
        },
      });

      enqueueSnackbar({
        message: "Przekazano pomyślnie",
        variant: "success",
      });

      onCompleted();
      close();
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (dispatcher) {
      setValue("dispatchees", [dispatcher as { id: number; label?: string }]);
    } else {
      reset();
    }
  }, [dispatcher]);

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  return (
    <Modal isOpen={isOpen} onClose={close}>
      <form onSubmit={handleSubmit(onCaseDispatchSubmit)}>
        <ModalHeader>Przekazanie sprawy</ModalHeader>
        <ModalBody>
          <FormControl
            label={"Użytkownik"}
            required
            error={
              ((errors as any)["dispatchees"] &&
                (errors as any)["dispatchees"].message) ||
              (error &&
                error.graphQLErrors[0]?.extensions?.code ===
                  "INPUT_VALIDATION_ERROR" &&
                error.graphQLErrors[0]?.extensions?.validationErrors
                  ?.find(
                    (vE: InputValidationError) => vE?.property === "dispatchee"
                  )
                  ?.errors.map((message: string) => (
                    <div
                      key="error"
                      className={css({
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      })}
                    >
                      {message}
                      <AlertOctagon color="#999" size={12} />
                    </div>
                  ))[0])
            }
            disabled={isLoading}
          >
            <ControlledUsersSelect
              control={control}
              name="dispatchees"
              id="dispatchees"
              placeholder="Wybierz"
              rules={{
                required: formValidation.messages.required,
              }}
              filterOptions={(options) =>
                options.filter((o) =>
                  isSubstitutionActive
                    ? o.id !== substitution?.substitutee?.id
                    : o.id !== user?.id
                )
              }
            />
          </FormControl>

          <FormControl
            label={"Komentarz"}
            error={
              ((errors as any)["comment"] &&
                (errors as any)["comment"].message) ||
              (error &&
                error.graphQLErrors[0]?.extensions?.code ===
                  "INPUT_VALIDATION_ERROR" &&
                error.graphQLErrors[0]?.extensions?.validationErrors
                  ?.find(
                    (vE: InputValidationError) => vE?.property === "comment"
                  )
                  ?.errors.map((message: string) => (
                    <div
                      key="error"
                      className={css({
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      })}
                    >
                      {message}
                      <AlertOctagon color="#999" size={12} />
                    </div>
                  ))[0])
            }
            disabled={isLoading}
          >
            <ControlledTextArea
              control={control}
              name={"comment"}
              id={"comment"}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            kind={KIND.secondary}
            $style={{ marginRight: "6px" }}
            onClick={() => {
              close();
              reset();
            }}
            disabled={loading}
          >
            Anuluj
          </Button>

          <Button disabled={loading} isLoading={loading} type="submit">
            Przekaż
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
