import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { AlertOctagon, DeviceFloppy, FileOff } from "tabler-icons-react";

import { InputValidationError } from "../../../api";
import Cell from "../../../components/cell";
import { ControlledCheckbox } from "../../../components/checkbox";
import ConfirmDialog from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import FormControl from "../../../components/form-control";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import { ControlledInput } from "../../../components/input";
import {
  ControlledButtonStylesSelect,
  ControlledDocumentStatesSelect,
  ControlledStepsSelect,
} from "../../../components/select";
import { DOCUMENT_TYPES, FLOW_ACTION_WARNINGS } from "../../../constants";
import { useLoading } from "../../../contexts/loading-context";
import { FIELDS } from "../../../fields.d";
import { checkPermission } from "../../../utils/check-permission";
import { formValidation } from "../../../utils/formValidation";
import { PERMISSIONS } from "../../../utils/permissions";
import { DOCUMENT_FLOW_ACTION, DOCUMENT_FLOW_ACTION_UPDATE } from "../flow.gql";
import { FLOW_ACTIONS_FIELDS, FormInputs } from "../flow-actions.form";

export default function FlowEditAction(): React.ReactElement {
  const [css] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { isFetching, setIsFetching, isLoading, setIsLoading } = useLoading();
  const { flowId, actionId } = useParams<{
    flowId?: string;
    actionId?: string;
  }>();
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );

  const { data, error: queryError, refetch } = useQuery(DOCUMENT_FLOW_ACTION, {
    variables: { id: actionId ? parseInt(actionId) : null },
  });
  const flowDocumentType = data?.documentFlowAction?.documentFlow?.documentType;

  const [editDocumentFlowAction, { error }] = useMutation(
    DOCUMENT_FLOW_ACTION_UPDATE
  );

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    setValue,
    watch,
  } = useForm<FormInputs>();

  const startDocumentFlowStepIdSelectWatch = watch(
    "startDocumentFlowStepIdSelect"
  );
  const endDocumentFlowStepIdSelectWatch = watch("endDocumentFlowStepIdSelect");

  const onSubmit = async ({
    label,
    isCommentRequired,
    isSignatureFileRequired,
    startDocumentFlowStepIdSelect,
    endDocumentFlowStepIdSelect,
    resultingDocumentStateSelect,
    styleSelect,
    notificationContent,
    noPaymentAssignedToInvoice,
    synchronizeDocumentWithRejestr,
    sendDocumentToPlatnosci,
    isEndingDocumentFlow,
  }: FormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      await editDocumentFlowAction({
        variables: {
          documentFlowActionUpdateInput: {
            id: actionId ? parseInt(actionId) : null,
            label,
            notificationContent,
            isCommentRequired: !!isCommentRequired,
            isSignatureFileRequired: !!isSignatureFileRequired,
            synchronizeDocumentWithRejestr: !!synchronizeDocumentWithRejestr,
            sendDocumentToPlatnosci: !!sendDocumentToPlatnosci,
            isEndingDocumentFlow: !!isEndingDocumentFlow,
            startDocumentFlowStepId:
              startDocumentFlowStepIdSelect &&
              startDocumentFlowStepIdSelect[0].id,
            endDocumentFlowStepId:
              endDocumentFlowStepIdSelect && endDocumentFlowStepIdSelect[0].id,
            resultingDocumentState:
              resultingDocumentStateSelect && resultingDocumentStateSelect?.[0]
                ? resultingDocumentStateSelect?.[0].id
                : null,
            style: styleSelect && styleSelect[0].id,
            documentFlowId: flowId ? parseInt(flowId) : null,
            ...(noPaymentAssignedToInvoice
              ? {
                  warnings: [FLOW_ACTION_WARNINGS.NoPaymentAssignedToInvoice],
                }
              : { warnings: [] }),
          },
        },
      });

      await enqueueSnackbar({
        message: "Zapisano pomyślnie",
        variant: "success",
      });

      history.push(`/flow/${flowId}/action/${actionId}`);
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setValue("label", data?.documentFlowAction?.label);
    setValue(
      "notificationContent",
      data?.documentFlowAction?.notificationContent
    );
    setValue("isCommentRequired", data?.documentFlowAction?.isCommentRequired);
    setValue(
      "isSignatureFileRequired",
      data?.documentFlowAction?.isSignatureFileRequired
    );
    setValue(
      "synchronizeDocumentWithRejestr",
      data?.documentFlowAction?.synchronizeDocumentWithRejestr
    );
    setValue(
      "isEndingDocumentFlow",
      data?.documentFlowAction?.isEndingDocumentFlow
    );
    setValue(
      "sendDocumentToPlatnosci",
      data?.documentFlowAction?.sendDocumentToPlatnosci
    );
    setValue("startDocumentFlowStepIdSelect", [
      {
        id: data?.documentFlowAction?.startDocumentFlowStepId,
      },
    ]);
    setValue("endDocumentFlowStepIdSelect", [
      {
        id: data?.documentFlowAction?.endDocumentFlowStepId,
      },
    ]);
    setValue(
      "noPaymentAssignedToInvoice",
      data?.documentFlowAction?.warnings?.length
    );
    !!data?.documentFlowAction?.resultingDocumentState &&
      setValue("resultingDocumentStateSelect", [
        {
          id: data?.documentFlowAction?.resultingDocumentState,
        },
      ]);
    !!data?.documentFlowAction?.style &&
      setValue("styleSelect", [
        {
          id: data?.documentFlowAction?.style,
        },
      ]);
  }, [data]);

  useEffect(() => {
    if (data) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => {
    if (data) setIsFetching(false);
  }, [data]);

  useEffect(() => {
    if (queryError?.graphQLErrors)
      enqueueSnackbar({
        message: (queryError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [queryError]);

  if (!checkPermission(PERMISSIONS.documentFlowStep.update))
    return <Redirect to="/" />;

  return (
    <article>
      <Header
        title={`Edycja kroku ${data?.documentFlowAction?.label}`}
        labels={["Kroki", "Edytowanie"]}
        goBackOption
        buttons={[
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              isDirty ? setIsCancelConfirmDialogOpen(true) : history.goBack(),
          },
          {
            label: "Zapisz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            onClick: handleSubmit(onSubmit),
            disabled: isLoading,
            isLoading: isLoading,
            type: "submit",
            formId: "edit",
          },
        ]}
      />
      <Content>
        <form id="edit" onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            {FLOW_ACTIONS_FIELDS.filter(
              (g) => g.fields.filter((f) => f.edit.visible).length > 0
            ).map((group) => [
              group.label &&
                ((group.id === "warnings" &&
                  flowDocumentType === DOCUMENT_TYPES.Invoice) ||
                  group.id !== "warnings") && (
                  <Cell key={group.id + `-group`} span={12}>
                    <LabelMedium marginBottom="scale200" marginTop="scale600">
                      {group.label}
                    </LabelMedium>
                    <hr
                      className={css({
                        borderWidth: "0px",
                        height: "1px",
                        backgroundColor: "#eee",
                      })}
                    />
                  </Cell>
                ),
              group.fields
                .filter((f) => f.edit.visible)
                .map(
                  (item, index) =>
                    item.id &&
                    ((item.id ===
                      FLOW_ACTION_WARNINGS.NoPaymentAssignedToInvoice &&
                      flowDocumentType === DOCUMENT_TYPES.Invoice) ||
                      item.id !==
                        FLOW_ACTION_WARNINGS.NoPaymentAssignedToInvoice) && (
                      <Cell
                        span={item.span || 6}
                        key={group.id + `-field` + index}
                        skip={item.skip}
                      >
                        <FormControl
                          label={
                            item.type === FIELDS.Checkbox ? "" : item.label
                          }
                          caption={item.caption}
                          required={item.edit.required}
                          error={
                            ((errors as any)[item.id] &&
                              (errors as any)[item.id].message) ||
                            (error &&
                              error.graphQLErrors[0]?.extensions?.code ===
                                "INPUT_VALIDATION_ERROR" &&
                              error.graphQLErrors[0]?.extensions?.validationErrors
                                ?.find(
                                  (vE: InputValidationError) =>
                                    vE?.property === item.id
                                )
                                ?.errors.map((message: string) => (
                                  <div
                                    key="error"
                                    className={css({
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    })}
                                  >
                                    {message} <br />
                                    {item.caption}
                                    <AlertOctagon color="#999" size={12} />
                                  </div>
                                ))[0])
                          }
                          disabled={isLoading}
                        >
                          {isFetching ? (
                            <Skeleton
                              rows={0}
                              height="36px"
                              width="100%"
                              animation
                            />
                          ) : item.type === FIELDS.DocumentStatesSelect ? (
                            <ControlledDocumentStatesSelect
                              control={control}
                              name={item.id}
                              {...(item.create.required && {
                                rules: {
                                  required: formValidation.messages.required,
                                },
                              })}
                            />
                          ) : item.type === FIELDS.StepsSelect ? (
                            <ControlledStepsSelect
                              control={control}
                              name={item.id}
                              placeholder="Wybierz"
                              documentFlowId={flowId ? parseInt(flowId) : null}
                              disabledId={
                                item.id === "startDocumentFlowStepIdSelect"
                                  ? endDocumentFlowStepIdSelectWatch?.[0]?.id
                                  : startDocumentFlowStepIdSelectWatch?.[0]?.id
                              }
                              {...(item.edit.required && {
                                rules: {
                                  required: formValidation.messages.required,
                                },
                              })}
                            />
                          ) : item.type === FIELDS.ButtonStylesSelect ? (
                            <ControlledButtonStylesSelect
                              control={control}
                              name={item.id}
                              placeholder="Wybierz"
                              {...(item.edit.required && {
                                rules: {
                                  required: formValidation.messages.required,
                                },
                              })}
                            />
                          ) : item.type === FIELDS.Checkbox ? (
                            <Block marginTop="10px">
                              <ControlledCheckbox
                                control={control}
                                name={item.id}
                                disabled={isLoading}
                                {...(item.edit.required && {
                                  rules: {
                                    required: formValidation.messages.required,
                                  },
                                })}
                              >
                                {item.label}
                              </ControlledCheckbox>
                            </Block>
                          ) : (
                            <ControlledInput
                              control={control}
                              name={item.id}
                              placeholder={item.placeholder}
                              startEnhancer={
                                <span className={css({ fontSize: "16px" })}>
                                  {item.startEnhancer}
                                </span>
                              }
                              {...(item.edit.required && {
                                rules: {
                                  required: formValidation.messages.required,
                                },
                              })}
                            />
                          )}
                        </FormControl>
                      </Cell>
                    )
                ),
            ])}
            <ConfirmDialog
              isOpen={isCancelConfirmDialogOpen}
              label="Anulowanie edycji kroku"
              close={() => setIsCancelConfirmDialogOpen(false)}
              confirm={() => history.goBack()}
            />
          </Grid>
        </form>
      </Content>
    </article>
  );
}
