import { styled, useStyletron } from "baseui";
import React, { ReactNode, RefObject, useEffect, useState } from "react";
import { useRef } from "react";

import { useAssignment } from "../contexts/assignment-context";
import Cell from "./cell";
import Grid from "./grid";

const StickedTableHeadV8 = styled(
  "div",
  ({
    $sticked,
    $left,
    $compact,
    $barOffset,
  }: {
    $sticked?: boolean;
    $left?: number;
    $compact?: boolean;
    $barOffset?: boolean;
  }) => ({
    position: "fixed",
    left: $left ? `${$left}px` : "225px",
    right: "0px",
    top: `${($compact ? 70 : 120) + ($barOffset ? 42 : 0)}px`,
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    "::-webkit-scrollbar": {
      display: "none",
    },
    zIndex: 8,
    paddingRight: "10px",
    transform: "translateY(-200px)",
    ...($sticked && {
      animationIterationCount: "1",
      animationDelay: "0ms",
      animationDuration: "200ms",
      animationFillMode: "both",
      animationName: {
        from: {
          transform: "translateY(-200px)",
        },
        to: {
          transform: "translateY(0px)",
        },
      } as any,
    }),
  })
);

type TableHeadV8Props = {
  tableRef: RefObject<HTMLDivElement>;
  compact?: boolean;
  children: ReactNode;
};

export default function TableHeadV8({
  tableRef,
  compact = false,
  children,
}: TableHeadV8Props): React.ReactElement {
  const [css, theme] = useStyletron();

  const [sticked, setSticked] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const shouldStickHigher =
    !window?.document?.querySelector("[data-ui=filters]") || compact;

  const { isAssignmentActive } = useAssignment();

  function checkPosition() {
    if (
      (tableRef?.current?.getBoundingClientRect().top || 0) -
        (shouldStickHigher ? 70 : 120) <
      0
    ) {
      if (
        (tableRef?.current?.getBoundingClientRect().bottom || 0) -
          (shouldStickHigher ? 70 : 120) -
          44 <
        0
      )
        setSticked(false);
      else setSticked(true);
    } else setSticked(false);
  }

  const customSidebarWidth =
    localStorage.getItem("sidebarWidth") &&
    parseInt(localStorage.getItem("sidebarWidth") as string);

  const [sidebarWidth, setSidebarWidth] = useState<number>(
    customSidebarWidth || 225
  );

  useEffect(() => {
    checkPosition();

    document
      ?.querySelector("main > section")
      ?.addEventListener("scroll", checkPosition, { passive: true });

    window?.addEventListener("resize", checkPosition);

    const observer = new ResizeObserver(function () {
      const width = document?.querySelector("aside")?.offsetWidth;

      if (width) {
        setSidebarWidth(width);
      }
    });

    const sidebarNode = document?.querySelector("aside");

    if (sidebarNode) {
      observer.observe(sidebarNode);
    }

    return () => {
      window?.removeEventListener("resize", checkPosition);
      document
        ?.querySelector("main > section")
        ?.removeEventListener("scroll", checkPosition);
      observer.disconnect();
    };
  }, []);

  return (
    <StickedTableHeadV8
      $sticked={sticked}
      $left={sidebarWidth}
      $compact={shouldStickHigher}
      $barOffset={compact && isAssignmentActive}
    >
      <Grid>
        <Cell span={12}>
          <div
            ref={containerRef}
            className={css({
              width: "100%",
              overflowY: "hidden",
              overflowX: "auto",
              borderRightStyle: "solid",
              borderRightWidth: "1px",
              borderRightColor: theme.colors.inputBorder,
              borderLeftStyle: "solid",
              borderLeftWidth: "1px",
              borderLeftColor: theme.colors.inputBorder,
              paddingBottom: "4px",
            })}
            data-ui="sticked-table-head"
            onScroll={(event: any) => {
              const tableRoot = event.target?.closest("[data-ui=table-root]");

              if (tableRoot) {
                const table = tableRoot?.querySelector("[data-ui=table]");

                if (table) {
                  const scrolling =
                    tableRoot.getAttribute("data-scrolling") === "true"
                      ? true
                      : false;

                  if (scrolling) {
                    tableRoot.setAttribute("data-scrolling", "false");
                    return;
                  }

                  if (
                    table.scrollLeft !==
                    (event.target as HTMLElement).scrollLeft
                  ) {
                    tableRoot.setAttribute("data-scrolling", "true");
                    table.scrollLeft = (event.target as HTMLElement).scrollLeft;
                  }
                }
              }
            }}
          >
            <table
              className={css({
                width: "100%",
                left: "0px",
                position: "relative",
                borderCollapse: "collapse",
              })}
            >
              {children}
            </table>
          </div>
        </Cell>
      </Grid>
    </StickedTableHeadV8>
  );
}
