export type FlowProceedInputs = {
  comment: string;
  documentFlowProceedSignature?: FileList;
};

export const FLOW_PROCEED_FIELDS: {
  id: string;
  label: string;
  span: number;
}[] = [
  {
    id: "comment",
    label: "Komentarz",
    span: 4,
  },
  {
    id: "documentFlowProceedSignature",
    label: "Plik z podpisem",
    span: 4,
  },
];
