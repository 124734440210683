import { BasicFilter, FILTERS } from "../../filters.d";

export const FOLDERS_FILTERS: BasicFilter[] = [
  {
    id: "createdAt",
    label: "Data otrzymania",
    type: FILTERS.Date,
  },
  {
    id: "type",
    label: "Działanie",
    type: FILTERS.AssignmentType,
  },
  {
    id: "assignerId",
    label: "Otrzymane od",
    type: FILTERS.User,
  },
  {
    id: "primaryAssignableId",
    label: "Obiekt",
    type: FILTERS.PrimaryAssignable,
  },
  {
    id: "isAssessmentRequested",
    label: "Wymagana akceptacja",
    type: FILTERS.Boolean,
  },
];

export const FOLDERS_FILTERS_RECEIVED: BasicFilter[] = [
  {
    id: "createdAt",
    label: "Data otrzymania",
    type: FILTERS.Date,
  },
  {
    id: "type",
    label: "Działanie",
    type: FILTERS.AssignmentType,
  },
  {
    id: "assignerId",
    label: "Otrzymane od",
    type: FILTERS.User,
  },
  {
    id: "primaryAssignableId",
    label: "Obiekt",
    type: FILTERS.PrimaryAssignable,
  },
  {
    id: "isAssessmentRequested",
    label: "Wymagana akceptacja",
    type: FILTERS.Boolean,
  },
];

export const FOLDERS_FILTERS_SENT: BasicFilter[] = [
  {
    id: "createdAt",
    label: "Data wysłania",
    type: FILTERS.Date,
  },
  {
    id: "status",
    label: "Status",
    type: FILTERS.AssignmentStatus,
  },
  {
    id: "assigneeId",
    label: "Wysłane do",
    type: FILTERS.User,
  },
  {
    id: "primaryAssignableId",
    label: "Obiekt",
    type: FILTERS.PrimaryAssignable,
  },
];
