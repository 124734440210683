import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { KIND } from "baseui/button";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { AlertOctagon, DeviceFloppy, FileOff, Note } from "tabler-icons-react";

import { InputValidationError } from "../../../api";
import Cell from "../../../components/cell";
import ConfirmDialog from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import FormControl from "../../../components/form-control";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import { ControlledInput } from "../../../components/input";
import {
  ControlledDictionaryValuesSelect,
  ControlledOutgoingDocumentsSelect,
  ControlledParcelSizesSelect,
  ControlledShipmentRatesSelect,
  ParcelSize,
} from "../../../components/select";
import { PARCEL_SIZES } from "../../../constants";
import { useDictionaries } from "../../../contexts/dictionaries-context";
import { useLoading } from "../../../contexts/loading-context";
import { FIELDS } from "../../../fields.d";
import { checkPermission } from "../../../utils/check-permission";
import { formValidation } from "../../../utils/formValidation";
import { PERMISSIONS } from "../../../utils/permissions";
import { scrollOnError } from "../../../utils/scrollOnError";
import { SHOW_MATCHING_SHIPMENT_RATES } from "../../Documents/documents.gql";
import { ShipmentRatesSelectPlaceholders } from "../document-parcels.d";
import { DOCUMENT_PARCELS_FIELDS, FormInputs } from "../document-parcels.form";
import { DOCUMENT_PARCELS_CREATE } from "../document-parcels.gql";

export default function DocumentParcelsCreate(): React.ReactElement {
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const [isClearConfirmDialogOpen, setIsClearConfirmDialogOpen] = useState(
    false
  );
  const [errorTimeStamp, setErrorTimeStamp] = useState<number>();
  const [
    shipmentRatesSelectPlaceholder,
    setShipmentRatesSelectPlaceholder,
  ] = useState("");
  const [css] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { isLoading, setIsLoading } = useLoading();
  const { findValue } = useDictionaries();
  const location = useLocation();
  const locationState = location.state as {
    documentId: number;
    documentInternalNumber: string;
    documentType: string;
    type: string;
  };

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm<FormInputs>({
    defaultValues: {
      documents: undefined,
      width: "",
      height: "",
      length: "",
      weight: "",
      countryCodes: [{ id: "PL" }],
      shipmentRates: undefined,
    },
  });

  const watchParcelWidth = watch("width");
  const watchParcelHeight = watch("height");
  const watchParcelLength = watch("length");
  const watchParcelWeight = watch("weight");
  const watchParcelSize = watch("parcelSizes");
  const watchCountryCode = watch("countryCodes");

  const {
    data: shipmentRatesData,
    loading: shipmentRatesLoading,
    refetch: shipmentRatesRefetch,
  } = useQuery(SHOW_MATCHING_SHIPMENT_RATES, {
    variables: {
      width: parseInt(watchParcelWidth?.toString()),
      height: parseInt(watchParcelHeight?.toString()),
      length: parseInt(watchParcelLength?.toString()),
      weight: parseInt(watchParcelWeight?.toString()),
      isCountryService: watchCountryCode && watchCountryCode[0]?.id === "PL",
      countryCode: watchCountryCode && watchCountryCode[0]?.id,
    },
    onError: () => {
      setValue("shipmentRates", undefined);
    },
  });

  const [createDocumentParcel, { error }] = useMutation(
    DOCUMENT_PARCELS_CREATE
  );

  const onError = (errors: Record<string, unknown>, e: any) =>
    setErrorTimeStamp(e.timeStamp);

  const onSubmit = async ({
    documents,
    width,
    height,
    length,
    weight,
    countryCodes,
    shipmentRates,
  }: FormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      const response = await createDocumentParcel({
        variables: {
          documentParcelCreateInput: {
            documentId: locationState?.documentId || documents?.[0]?.id,
            width: parseInt(width.toString()),
            height: parseInt(height.toString()),
            length: parseInt(length.toString()),
            weight: parseInt(weight.toString()),
            isCountryService: countryCodes && countryCodes[0]?.id === "PL",
            countryCode: countryCodes && countryCodes[0]?.id,
            shipmentRateId: shipmentRates && shipmentRates[0]?.id,
          },
        },
      });

      enqueueSnackbar({
        message: "Zapisano pomyślnie",
        variant: "success",
      });

      locationState?.documentId
        ? history.push(
            `/documents/outgoing/${response?.data?.documentParcelCreate?.document?.type}/${locationState?.documentId}`
          )
        : history.push(
            `/document-parcels/${response?.data?.documentParcelCreate?.id}`
          );
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    PARCEL_SIZES.map((size: ParcelSize) => {
      if (watchParcelSize?.length && size.id === watchParcelSize[0]?.id) {
        setValue("width", size.width);
        setValue("height", size.height);
        setValue("length", size.length);
      }
    });
  }, [watchParcelSize]);

  const handleParcelSizesClear = () => setValue("parcelSizes", undefined);

  useEffect(() => {
    setValue("shipmentRates", [
      { id: shipmentRatesData?.allShipmentRatesForPackageSize[0]?.id },
    ]);
  }, [shipmentRatesData]);

  useEffect(() => {
    shipmentRatesRefetch();
  }, [
    watchParcelWidth,
    watchParcelHeight,
    watchParcelLength,
    watchParcelWeight,
  ]);

  const handleShipmentRatesSelectPlaceholder = () => {
    const placeholders: string[] = [];
    !watchParcelWidth
      ? placeholders.push(ShipmentRatesSelectPlaceholders.width)
      : placeholders.filter(
          (placeholder: string) =>
            placeholder !== ShipmentRatesSelectPlaceholders.width
        );
    !watchParcelHeight
      ? placeholders.push(ShipmentRatesSelectPlaceholders.height)
      : placeholders.filter(
          (placeholder: string) =>
            placeholder !== ShipmentRatesSelectPlaceholders.height
        );
    !watchParcelLength
      ? placeholders.push(ShipmentRatesSelectPlaceholders.length)
      : placeholders.filter(
          (placeholder: string) =>
            placeholder !== ShipmentRatesSelectPlaceholders.length
        );
    !watchParcelWeight
      ? placeholders.push(ShipmentRatesSelectPlaceholders.weight)
      : placeholders.filter(
          (placeholder: string) =>
            placeholder !== ShipmentRatesSelectPlaceholders.weight
        );
    return setShipmentRatesSelectPlaceholder(
      placeholders.map((placeholder: string) => placeholder).join(", ")
    );
  };

  useEffect(() => {
    handleShipmentRatesSelectPlaceholder();
  }, [
    watchParcelWidth,
    watchParcelLength,
    watchParcelHeight,
    watchParcelWeight,
  ]);

  useEffect(() => {
    if (errors) scrollOnError(errors, DOCUMENT_PARCELS_FIELDS, "create");
  }, [errorTimeStamp]);

  if (!checkPermission(PERMISSIONS.documentParcel.create))
    return <Redirect to="/" />;

  return (
    <article>
      <Header
        title={`Nowy list nadany${
          locationState?.documentInternalNumber
            ? ` dla dokumentu ${locationState?.documentInternalNumber}`
            : ""
        }`}
        labels={["Listy nadane", "Tworzenie"]}
        goBackOption
        buttons={[
          {
            label: "Wyczyść",
            kind: KIND.secondary,
            startEnhancer: <Note size={18} />,
            disabled: isLoading,
            onClick: () => {
              setIsClearConfirmDialogOpen(true);
            },
          },
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              isDirty ? setIsCancelConfirmDialogOpen(true) : history.goBack(),
          },
          {
            label: "Zapisz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            onClick: handleSubmit(onSubmit, onError),
            disabled: isLoading,
            isLoading: isLoading,
            type: "submit",
            formId: "createDocumentParcel",
          },
        ]}
      />
      <Content>
        <form id="createDocumentParcel" onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            {DOCUMENT_PARCELS_FIELDS.filter(
              (g) => g.fields.filter((f) => f.create.visible).length > 0
            ).map((group) => [
              group.label && (
                <Cell key={group.id + `-group`} span={12}>
                  <LabelMedium marginBottom="scale200" marginTop="scale600">
                    {group.label}
                  </LabelMedium>
                  <hr
                    className={css({
                      borderWidth: "0px",
                      height: "1px",
                      backgroundColor: "#eee",
                    })}
                  />
                </Cell>
              ),
              group.fields
                .filter((f) => f.create.visible)
                .map((item, index) => (
                  <Cell span={item.span || 6} key={group.id + `-field` + index}>
                    <FormControl
                      label={item.label}
                      caption={item.caption}
                      required={item.create.required}
                      error={
                        ((errors as any)[item.id] &&
                          (errors as any)[item.id].message) ||
                        (error &&
                          error.graphQLErrors[0]?.extensions?.code ===
                            "INPUT_VALIDATION_ERROR" &&
                          error.graphQLErrors[0]?.extensions?.validationErrors
                            ?.find((vE: InputValidationError) =>
                              item.id.includes("address")
                                ? vE?.property ===
                                  item.id.slice(7).toLowerCase()
                                : vE?.property === item.id
                            )
                            ?.errors.map((message: string) => (
                              <div
                                key="error"
                                className={css({
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                })}
                              >
                                {message}
                                <span
                                  className={css({
                                    color: "#999",
                                    marginLeft: "auto",
                                    marginRight: "5px",
                                  })}
                                >
                                  Walidacja serwera
                                </span>
                                <AlertOctagon color="#999" size={12} />
                              </div>
                            ))[0])
                      }
                      disabled={isLoading}
                    >
                      {item.type === FIELDS.DocumentsSelect &&
                      locationState?.documentId ? (
                        <>
                          <FormattedValue
                            dataType="model:documents"
                            data={`outgoing/${locationState?.type}/${locationState?.documentId}`}
                          >
                            {`${findValue(locationState?.documentType)} [${
                              locationState?.documentInternalNumber
                            }]`}
                          </FormattedValue>
                        </>
                      ) : item.type === FIELDS.DocumentsSelect &&
                        !locationState?.documentId ? (
                        <ControlledOutgoingDocumentsSelect
                          control={control}
                          name={item.id}
                          id={item.id}
                          placeholder="Wybierz"
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.ShipmentRatesSelect ? (
                        <ControlledShipmentRatesSelect
                          control={control}
                          name={item.id}
                          id={item.id}
                          isLoading={shipmentRatesLoading}
                          data={
                            shipmentRatesData &&
                            shipmentRatesData?.allShipmentRatesForPackageSize
                          }
                          placeholder={
                            shipmentRatesSelectPlaceholder
                              ? `Nie podano ${shipmentRatesSelectPlaceholder}`
                              : shipmentRatesLoading
                              ? "Pobieranie stawek"
                              : !shipmentRatesData
                              ? "Brak rekordów"
                              : "Wybierz"
                          }
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.DictionaryValuesSelect ? (
                        <ControlledDictionaryValuesSelect
                          dictionarySystemName={"countryCodes.alpha2.pl"}
                          control={control}
                          name={item.id}
                          id={item.id}
                          placeholder="Wybierz"
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.ParcelSizesSelect ? (
                        <ControlledParcelSizesSelect
                          control={control}
                          name={item.id}
                          id={item.id}
                          placeholder={item.placeholder}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : (
                        <ControlledInput
                          control={control}
                          name={item.id}
                          id={item.id}
                          placeholder={item.placeholder}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                          {...((item.id.includes("width") ||
                            item.id.includes("height") ||
                            item.id.includes("length")) && {
                            endEnhancer: "mm",
                            onKeyUp: () => handleParcelSizesClear(),
                          })}
                          {...(item.id.includes("weight") && {
                            endEnhancer: "g",
                          })}
                          {...(item.id.includes("price") && {
                            endEnhancer: "PLN",
                          })}
                          {...(item.type === FIELDS.NumberInput && {
                            type: "number",
                            min: 0,
                          })}
                        />
                      )}
                    </FormControl>
                  </Cell>
                )),
            ])}
            <ConfirmDialog
              isOpen={isCancelConfirmDialogOpen}
              label="Anulowanie tworzenia listu nadanego"
              close={() => setIsCancelConfirmDialogOpen(false)}
              confirm={() => history.goBack()}
            />
            <ConfirmDialog
              isOpen={isClearConfirmDialogOpen}
              label="Wyczyszczenie formularza"
              close={() => setIsClearConfirmDialogOpen(false)}
              confirm={() => {
                reset();
                setIsClearConfirmDialogOpen(false);
              }}
            />
          </Grid>
        </form>
      </Content>
    </article>
  );
}
