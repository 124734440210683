import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { StatefulPopover, StatefulPopoverProps } from "baseui/popover";
import React from "react";
import { StyleObject } from "styletron-react";

type Props = {
  $style?: StyleObject;
} & StatefulPopoverProps;

const SubmenuPopover = ({ $style, ...rest }: Props): React.ReactElement => {
  const [, theme] = useStyletron();

  return (
    <Block position="relative">
      <StatefulPopover
        overrides={{
          Inner: {
            style: {
              borderTopLeftRadius: theme.borders.radius200,
              borderTopRightRadius: theme.borders.radius200,
              borderBottomRightRadius: theme.borders.radius200,
              borderBottomLeftRadius: theme.borders.radius200,
              paddingTop: theme.sizing.scale200,
              paddingBottom: theme.sizing.scale200,
              paddingRight: theme.sizing.scale300,
              paddingLeft: theme.sizing.scale300,
              borderLeftWidth: "2px",
              borderLeftStyle: "solid",
              borderLeftColor: theme.colors.inputBorder,
              borderBottomWidth: "2px",
              borderBottomStyle: "solid",
              borderBottomColor: theme.colors.inputBorder,
              borderRightWidth: "2px",
              borderRightStyle: "solid",
              borderRightColor: theme.colors.inputBorder,
              borderTopWidth: "2px",
              borderTopStyle: "solid",
              borderTopColor: theme.colors.inputBorder,
              backgroundColor: "white",
              position: "absolute",
              left: 0,
              top: "-50px",
              ...$style,
            },
          },
          Body: {
            style: {
              borderTopLeftRadius: theme.borders.radius200,
              borderTopRightRadius: theme.borders.radius200,
              borderBottomRightRadius: theme.borders.radius200,
              borderBottomLeftRadius: theme.borders.radius200,
              marginRight: "10px",
              boxShadow: "unset",
              zIndex: 150,
            },
          },
          Arrow: {
            style: {
              backgroundColor: "white",
            },
          },
        }}
        autoFocus
        returnFocus
        showArrow={false}
        {...rest}
      />
    </Block>
  );
};

export default SubmenuPopover;
