import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { LabelSmall } from "baseui/typography";
import { formatDistanceToNow } from "date-fns";
import { pl } from "date-fns/locale";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Cell as TableCell, Row } from "react-table";
import {
  Bell,
  BellOff,
  Check,
  Checks,
  ExternalLink,
  ListCheck,
} from "tabler-icons-react";

import BottomPanel from "../../components/bottom-panel";
import Button, { ForwardedButton } from "../../components/button";
import Cell from "../../components/cell";
import Checkbox from "../../components/checkbox";
import Content from "../../components/content";
import Grid from "../../components/grid";
import Header from "../../components/header";
import PagingControls from "../../components/paging-controls";
import Table from "../../components/table";
import Tooltip from "../../components/tooltip";
import { useAuth } from "../../contexts/auth-context";
import { useLoading } from "../../contexts/loading-context";
import { usePaging } from "../../contexts/paging-context";
import { ACCOUNT_UPDATE } from "../MyAccount/my-account.gql";
import { Notification } from "./notifications.d";
import {
  NOTIFICATIONS_INDEX,
  NOTIFICATIONS_MARK_AS_READ,
  NOTIFICATIONS_MARK_MANY_AS_READ,
} from "./notifications.gql";

export default function NotificationsIndex(): React.ReactElement {
  const { pageSize, currentPage, setTotalCount } = usePaging();

  const { user, refetch: userRefetch } = useAuth();
  const {
    isPartialFetching,
    setIsPartialFetching,
    isFetching,
    setIsFetching,
    setIsLoading,
  } = useLoading();
  const { enqueueSnackbar } = useSnackbar();
  const [css, theme] = useStyletron();
  const history = useHistory();

  const [selectedNotifications, setSelectedNotifications] = useState<
    Array<number>
  >([]);

  const { refetch, loading, data } = useQuery(NOTIFICATIONS_INDEX, {
    variables: {
      pageSize,
      offset: (currentPage - 1) * pageSize,
      sorting: [
        {
          field: "createdAt",
          direction: "DESC",
        },
      ],
    },
  });

  const emailNotificationsEnabled = user?.hasEmailNotificationsEnabled;

  const [updateAccount] = useMutation(ACCOUNT_UPDATE);

  const onSubmit = async (): Promise<void> => {
    setIsLoading(true);

    try {
      await updateAccount({
        variables: {
          accountUpdateInput: {
            hasEmailNotificationsEnabled: !emailNotificationsEnabled,
          },
        },
      });

      enqueueSnackbar({
        message: emailNotificationsEnabled
          ? "Wyłączono powiadomienia mailowe"
          : "Włączono powiadomienia mailowe",
        variant: "success",
      });
      userRefetch();
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const [markAsRead] = useMutation(NOTIFICATIONS_MARK_AS_READ);
  const [markManyAsRead] = useMutation(NOTIFICATIONS_MARK_MANY_AS_READ);

  useEffect(() => {
    refetch();
    setIsPartialFetching(true);
  }, [currentPage, pageSize]);

  useEffect(() => {
    if (data?.notifications) setIsFetching(false);
    if (data?.notifications?.totalCount >= 0)
      setTotalCount(data?.notifications?.totalCount);
  }, [data]);

  useEffect(() => {
    if (data?.notifications) refetch();
    setIsFetching(true);
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: () => (
          <Block
            width="20px"
            marginLeft="8px"
            display="flex"
            justifyContent="center"
          >
            <Checkbox
              isIndeterminate={
                selectedNotifications?.length > 0 &&
                selectedNotifications?.length <
                  data?.notifications?.nodes?.length
              }
              checked={
                selectedNotifications?.length ===
                data?.notifications?.nodes?.length
              }
              onChange={() => {
                if (
                  selectedNotifications?.length !==
                  data?.notifications?.nodes?.length
                ) {
                  setSelectedNotifications(
                    data?.notifications?.nodes.map(
                      (notification: Notification) => notification.id
                    )
                  );
                } else {
                  setSelectedNotifications([]);
                }
              }}
            />
          </Block>
        ),
        id: "checkbox",
        accessor: "checked",
        Cell: ({ row }: { row: Row<Notification> }) => (
          <Block
            width="20px"
            marginLeft="8px"
            display="flex"
            justifyContent="center"
          >
            <Checkbox
              checked={selectedNotifications?.includes(row.original.id)}
              onChange={() => {
                if (selectedNotifications?.includes(row.original.id)) {
                  setSelectedNotifications([
                    ...selectedNotifications?.filter(
                      (id) => id !== row.original.id
                    ),
                  ]);
                } else {
                  setSelectedNotifications([
                    ...(selectedNotifications ? selectedNotifications : []),
                    row.original.id,
                  ]);
                }
              }}
            />
          </Block>
        ),
      },
      {
        Header: (
          <Block
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button
              kind="tertiary"
              size="compact"
              type="button"
              key="button"
              startEnhancer={<Checks size={16} />}
              disabled={!selectedNotifications?.length}
              onClick={() => {
                markManyAsRead({
                  variables: { ids: selectedNotifications },
                }).then(() => {
                  refetch();
                  userRefetch();
                  setSelectedNotifications([]);
                });
              }}
            >
              Oznacz zaznaczone jako przeczytane
            </Button>
          </Block>
        ),
        id: "notification",
        accessor: "content",
        Cell: ({ row, cell }: { row: Row<Notification>; cell: TableCell }) => (
          <Block display="flex" alignItems="center">
            {!row.original.readAt && (
              <div
                className={css({
                  borderRadius: "50%",
                  width: "7px",
                  height: "7px",
                  backgroundColor: theme.colors.primary,
                  marginRight: "10px",
                  flexShrink: 0,
                })}
              />
            )}

            <LabelSmall
              display="flex"
              flexDirection="column"
              $style={{
                fontWeight: !row.original.readAt ? 600 : 400,
                lineHeight: "18px",
              }}
            >
              {cell.value}

              <span
                className={css({
                  marginTop: "1px",
                  fontSize: "10px",
                  fontWeight: !row.original.readAt ? 500 : 400,
                })}
              >
                {row.original.createdAt &&
                  formatDistanceToNow(new Date(row.original.createdAt), {
                    locale: pl,
                    addSuffix: true,
                  })}
              </span>
            </LabelSmall>
          </Block>
        ),
      },
      {
        id: "actions",
        Cell: ({ row }: { row: Row<Notification> }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            {!row.original.readAt && (
              <Tooltip content="Oznacz jako przeczytane">
                <ForwardedButton
                  size="mini"
                  startEnhancer={<Check size={16} />}
                  onClick={() => {
                    markAsRead({
                      variables: { id: row.original.id },
                    }).then(() => {
                      refetch();
                      userRefetch();
                    });
                  }}
                />
              </Tooltip>
            )}

            {row.original.actionPath && (
              <Button
                $as="a"
                kind="secondary"
                size="mini"
                $style={{ marginLeft: "10px" }}
                href={row.original.actionPath}
                endEnhancer={<ExternalLink size={16} />}
                onClick={(event) => {
                  event.preventDefault();
                  markAsRead({
                    variables: { id: row.original.id },
                  }).then(() => {
                    refetch();
                    userRefetch();
                  });
                  history.push(row.original.actionPath);
                }}
              >
                Przejdź
              </Button>
            )}
          </div>
        ),
      },
    ],
    [selectedNotifications, data]
  );

  return (
    <article>
      <Header
        title="Powiadomienia"
        recordsNum={data?.notifications?.totalCount}
        labels={["Twoje"]}
        actions={[
          {
            label: emailNotificationsEnabled
              ? "Wyłącz powiadomienia mailowe"
              : "Włącz powiadomienia mailowe",
            icon: emailNotificationsEnabled ? BellOff : Bell,
            color: emailNotificationsEnabled
              ? theme.colors.negative
              : theme.colors.positive,
            onClick: () => onSubmit(),
          },
        ]}
      />

      <Content>
        <Grid>
          <Cell span={12} $style={{ position: "relative" }}>
            <Table<Notification>
              columns={columns}
              data={data?.notifications?.nodes}
              isLoading={isFetching || isPartialFetching || loading}
              stickLastColumn
              compact
              emptyMessage={() => (
                <div
                  className={css({
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#999999",
                  })}
                >
                  <ListCheck
                    color="#999999"
                    size={18}
                    className={css({ marginRight: "5px" })}
                  />
                  Jesteś na bieżąco
                </div>
              )}
            />
          </Cell>

          <Cell span={12}>
            <BottomPanel>
              <PagingControls />
            </BottomPanel>
          </Cell>
        </Grid>
      </Content>
    </article>
  );
}
