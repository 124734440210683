import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import {
  AlertOctagon,
  DeviceFloppy,
  FileOff,
  Lock,
  LockOpen,
  Note,
} from "tabler-icons-react";

import { InputValidationError } from "../../../api";
import Button from "../../../components/button";
import Cell from "../../../components/cell";
import { ControlledCheckbox } from "../../../components/checkbox";
import ConfirmDialog, {
  ConfirmDialogType,
} from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import { ControlledDatePicker } from "../../../components/date-picker";
import FormControl from "../../../components/form-control";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import { ControlledInput } from "../../../components/input";
import {
  ControlledDeklaracjaCelnaRodzajSelect,
  ControlledDokumentyTowarzyszaceRodzajSelect,
  ControlledSendingListsSelect,
  ControlledZawartoscPrzesylkiSelect,
} from "../../../components/select";
import { HIDDEN_ADDRESS } from "../../../constants";
import { useLoading } from "../../../contexts/loading-context";
import { FIELDS } from "../../../fields.d";
import { checkPermission } from "../../../utils/check-permission";
import { formValidation } from "../../../utils/formValidation";
import { PERMISSIONS } from "../../../utils/permissions";
import { scrollOnError } from "../../../utils/scrollOnError";
import {
  ADDRESS_REVEAL,
  DOCUMENTS_ADD_TO_SENDING_LIST,
  DOCUMENTS_SHOW,
} from "../../Documents/documents.gql";
import { PACZKA_ZAGRANICZNA_CREATE } from "../e-nadawca-parcels.gql";
import {
  DOCUMENT_ADDRESS_FIELDS,
  FormInputs,
  PACZKA_ZAGRANICZNA_FIELDS,
} from "./paczka-zagraniczna.form";

export default function PaczkaZagranicznaCreate(): React.ReactElement {
  const [
    isAddressRevealConfirmDialogOpen,
    setIsAddressRevealConfirmDialogOpen,
  ] = useState(false);
  const [isAnyAddressRevealed, setIsAnyAddressRevealed] = useState(false);
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const [isClearConfirmDialogOpen, setIsClearConfirmDialogOpen] = useState(
    false
  );
  const [css, theme] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { isLoading, setIsLoading, isFetching, setIsFetching } = useLoading();
  const { documentId } = useParams<{
    documentId?: string;
  }>();
  const location = useLocation();
  const locationState = location.state as {
    shipmentRateId: number;
    shipmentContractId: number;
  };

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm<FormInputs>();

  const watchParcel = watch("documentParcel");
  const watchSendingList = watch("sendingList");

  const { data, refetch, error: queryError } = useQuery(DOCUMENTS_SHOW, {
    variables: { id: documentId ? parseInt(documentId) : null },
  });

  const addressToSend = data?.document?.recipient;

  const choosedShipmentRate = data?.document?.documentParcels?.find(
    (parcel: { shipmentRate: { id: number } }) =>
      parcel?.shipmentRate?.id === locationState?.shipmentRateId
  )?.shipmentRate;

  const [createPaczkaZagraniczna, { error, loading }] = useMutation(
    PACZKA_ZAGRANICZNA_CREATE
  );
  const [addTosendingList] = useMutation(DOCUMENTS_ADD_TO_SENDING_LIST);

  const onSubmit = async ({
    opis,
    planowanaDataNadania,
    numerNadania,
    sygnatura,
    terminSprawy,
    zwrotPoLiczbieDni,
    traktowacJakPorzucona,
    sposobZwrotu,
    rodzaj,
    posteRestante,
    iloscPotwierdzenOdbioru,
    masa,
    deklaracjaCelnaZawartosc,
    deklaracjaCelnaIlosc,
    deklaracjaCelnaMasa,
    deklaracjaCelnaWartosc,
    deklaracjaCelnaNumerTaryfowy,
    deklaracjaCelnaKrajPochodzenia,
    deklaracjaCelnaPodarunek,
    deklaracjaCelnaDokument,
    deklaracjaCelnaProbkaHandlowa,
    deklaracjaCelnaZwrotTowaru,
    deklaracjaCelnaTowary,
    deklaracjaCelnaInny,
    deklaracjaCelnaWyjasnienie,
    deklaracjaCelnaOplatyPocztowe,
    deklaracjaCelnaUwagi,
    deklaracjaCelnaLicencja,
    deklaracjaCelnaSwiadectwo,
    deklaracjaCelnaFaktura,
    deklaracjaCelnaNumerReferencyjnyImportera,
    deklaracjaCelnaNumerTelefonuImportera,
    deklaracjaCelnaWaluta,
    deklaracjaCelna2RodzajSelect,
    deklaracjaCelna2ZawartoscPrzesylkiSelect,
    deklaracjaCelna2Wyjasnienie,
    deklaracjaCelna2OplatyPocztowe,
    deklaracjaCelna2Uwagi,
    deklaracjaCelna2NumerReferencyjnyImportera,
    deklaracjaCelna2NumerTelefonuImportera,
    deklaracjaCelna2WalutaKodISO,
    deklaracjaCelna2NumerReferencyjnyCelny,
    dokumentyTowarzyszaceRodzajSelect,
    dokumentyTowarzyszaceNumer,
    okreslenieZawartosci,
    ilosc,
    masaNetto,
    wartosc,
    numerTaryfyHs,
    krajPochodzeniaKodAlfa2,
    sposobNadaniaInterconnect,
    sposobDoreczeniaKod,
    sposobDoreczeniaIdentyfikatorPunktuOdbioru,
    kategoria,
    utrudnionaManipulacja,
    ekspres,
    numerReferencyjnyCelny,
    numerPrzesylkiKlienta,
  }: FormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      const response = await createPaczkaZagraniczna({
        variables: {
          paczkaZagranicznaInput: {
            addressId: addressToSend && addressToSend?.id,
            opis,
            planowanaDataNadania: planowanaDataNadania || new Date(),
            numerNadania,
            sygnatura,
            terminSprawy,
            rodzaj,
            zwrot: {
              zwrotPoLiczbieDni: parseInt(zwrotPoLiczbieDni?.toString()),
              traktowacJakPorzucona,
              sposobZwrotu,
            },
            deklaracjaCelna: {
              szczegoly: {
                attributes: {
                  zawartosc: deklaracjaCelnaZawartosc,
                  ilosc:
                    deklaracjaCelnaIlosc &&
                    parseInt(deklaracjaCelnaIlosc?.toString()),
                  masa:
                    deklaracjaCelnaMasa &&
                    parseInt(deklaracjaCelnaMasa?.toString()),
                  wartosc:
                    deklaracjaCelnaWartosc &&
                    parseInt(deklaracjaCelnaWartosc?.toString()),
                  numerTaryfowy: deklaracjaCelnaNumerTaryfowy,
                  krajPochodzenia: deklaracjaCelnaKrajPochodzenia,
                },
              },
              attributes: {
                podarunek: deklaracjaCelnaPodarunek,
                dokument: deklaracjaCelnaDokument,
                probkaHandlowa: deklaracjaCelnaProbkaHandlowa,
                zwrotTowaru: deklaracjaCelnaZwrotTowaru,
                towary: deklaracjaCelnaTowary,
                inny: deklaracjaCelnaInny,
                wyjasnienie: deklaracjaCelnaWyjasnienie,
                oplatyPocztowe: deklaracjaCelnaOplatyPocztowe,
                uwagi: deklaracjaCelnaUwagi,
                licencja: deklaracjaCelnaLicencja,
                swiadectwo: deklaracjaCelnaSwiadectwo,
                faktura: deklaracjaCelnaFaktura,
                numerReferencyjnyImportera: deklaracjaCelnaNumerReferencyjnyImportera,
                numerTelefonuImportera: deklaracjaCelnaNumerTelefonuImportera,
                waluta: deklaracjaCelnaWaluta,
              },
            },
            deklaracjaCelna2: {
              rodzaj:
                deklaracjaCelna2RodzajSelect &&
                deklaracjaCelna2RodzajSelect[0]?.id,
              zawartoscPrzesylki:
                deklaracjaCelna2ZawartoscPrzesylkiSelect &&
                deklaracjaCelna2ZawartoscPrzesylkiSelect[0]?.id,
              dokumentyTowarzyszace: {
                rodzaj:
                  dokumentyTowarzyszaceRodzajSelect &&
                  dokumentyTowarzyszaceRodzajSelect[0]?.id,
                numer: dokumentyTowarzyszaceNumer,
              },
              wyjasnienie: deklaracjaCelna2Wyjasnienie,
              oplatyPocztowe: deklaracjaCelna2OplatyPocztowe,
              uwagi: deklaracjaCelna2Uwagi,
              numerReferencyjnyImportera: deklaracjaCelna2NumerReferencyjnyImportera,
              numerTelefonuImportera: deklaracjaCelna2NumerTelefonuImportera?.toString(),
              walutaKodISO: deklaracjaCelna2WalutaKodISO,
              numerReferencyjnyCelny: deklaracjaCelna2NumerReferencyjnyCelny,
              szczegolyZawartosciPrzesylki: {
                okreslenieZawartosci,
                ilosc: ilosc && parseInt(ilosc?.toString()),
                masaNetto: masaNetto && parseInt(masaNetto?.toString()),
                wartosc: wartosc && parseInt(wartosc?.toString()),
                numerTaryfyHs,
                krajPochodzeniaKodAlfa2,
              },
            },
            sposobNadaniaInterconnect,
            sposobDoreczenia: {
              kod: sposobDoreczeniaKod,
              identyfikatorPunktuOdbioru: sposobDoreczeniaIdentyfikatorPunktuOdbioru,
            },
            posteRestante,
            masa,
            kategoria,
            iloscPotwierdzenOdbioru:
              iloscPotwierdzenOdbioru &&
              parseInt(iloscPotwierdzenOdbioru?.toString()),
            utrudnionaManipulacja,
            ekspres,
            numerReferencyjnyCelny,
            numerPrzesylkiKlienta,
          },
        },
      });

      response?.data?.paczkaZagranicznaCreate?.id &&
        (await addTosendingList({
          variables: {
            addDocumentToSendingListInput: {
              documentId: documentId && parseInt(documentId),
              documentParcelId: parseInt(
                watchParcel && watchParcel[0]?.id?.split("/")[0]
              ),
              shipmentRateId: parseInt(
                watchParcel && watchParcel[0]?.id?.split("/")[1]
              ),
              eNadawcaSendingListId:
                watchSendingList && watchSendingList[0]?.id,
              eNadawcaParcelId: parseInt(
                response?.data?.paczkaZagranicznaCreate?.id
              ),
            },
          },
        }));

      enqueueSnackbar({
        message: "Pomyślnie dodano do listy wysyłkowej",
        variant: "success",
      });

      history.push(`/sending-lists/${watchSendingList[0]?.id}`);
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const [revealAddress, { loading: revealAddressLoading }] = useMutation(
    ADDRESS_REVEAL
  );

  const addressId = data?.document?.recipient?.id;

  const onAddressRevealSubmit = async () => {
    setIsLoading(true);

    try {
      await revealAddress({
        variables: {
          documentAddressRevealInput: {
            documentId: documentId ? parseInt(documentId) : null,
            addressId,
          },
        },
      });

      enqueueSnackbar({
        message: "Odkryto pomyślnie",
        variant: "success",
      });

      refetch();
      setIsAddressRevealConfirmDialogOpen(false);
      setIsAnyAddressRevealed(true);
    } catch (error: unknown) {
      setIsAddressRevealConfirmDialogOpen(false);
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (queryError?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    if (data?.document) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => {
    data?.document && setIsFetching(false);
    setValue("documentParcel", [
      {
        id: `${data?.document?.documentParcels?.[0]?.id}/${data?.document?.documentParcels?.[0]?.shipmentRate?.id}`,
      },
    ]);

    setValue("masa", data?.document?.documentParcels?.[0]?.weight);
    setValue("masaNetto", data?.document?.documentParcels?.[0]?.weight);
  }, [data]);

  const [errorTimeStamp, setErrorTimeStamp] = useState<number>();
  const onError = (errors: Record<string, unknown>, e: any) =>
    setErrorTimeStamp(e.timeStamp);

  useEffect(() => {
    if (errors) scrollOnError(errors, PACZKA_ZAGRANICZNA_FIELDS, "create");
  }, [errorTimeStamp]);

  if (!checkPermission(PERMISSIONS.sendingList.update))
    return <Redirect to="/" />;

  return (
    <article>
      <Header
        title="Dodanie do listy wysyłkowej"
        labels={["Dokumenty"]}
        goBackOption
        buttons={[
          {
            label: "Wyczyść",
            kind: KIND.secondary,
            startEnhancer: <Note size={18} />,
            disabled: isLoading,
            onClick: () => {
              setIsClearConfirmDialogOpen(true);
            },
          },
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              isDirty ? setIsCancelConfirmDialogOpen(true) : history.goBack(),
          },
          {
            label: "Zapisz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            onClick: handleSubmit(onSubmit, onError),
            disabled: isLoading,
            isLoading: isLoading,
            type: "submit",
            formId: "createPaczkaZagraniczna",
          },
        ]}
      />
      <Content>
        <form
          id="createPaczkaZagraniczna"
          onSubmit={handleSubmit(onSubmit, onError)}
        >
          <Grid>
            {DOCUMENT_ADDRESS_FIELDS.filter(
              (g) => g.fields.filter((f) => f.show.visible).length > 0
            ).map((group) => [
              group.label && (
                <Cell key={group.id + `-group`} span={12}>
                  <>
                    <Block
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      marginBottom="scale200"
                      marginTop="scale600"
                    >
                      <Block
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        {data?.document?.recipient?.__typename ===
                          HIDDEN_ADDRESS && group.id === "recipient" ? (
                          <Lock
                            size={18}
                            color={theme.colors.negative}
                            className={css({ marginRight: "10px" })}
                          />
                        ) : (
                          group.id === "recipient" && (
                            <LockOpen
                              size={18}
                              color={theme.colors.positive}
                              className={css({ marginRight: "10px" })}
                            />
                          )
                        )}
                        <LabelMedium>{group.label}</LabelMedium>
                        {data?.document?.recipient?.__typename ===
                          HIDDEN_ADDRESS &&
                          group.id === "recipient" && (
                            <Button
                              size={SIZE.mini}
                              type="button"
                              kind={KIND.secondary}
                              $style={{ marginLeft: "10px" }}
                              onClick={() =>
                                setIsAddressRevealConfirmDialogOpen(true)
                              }
                              isLoading={revealAddressLoading}
                            >
                              Pokaż dane
                            </Button>
                          )}
                      </Block>
                      {data?.document?.recipient?.__typename !==
                        HIDDEN_ADDRESS &&
                        isAnyAddressRevealed &&
                        group.id === "recipient" && (
                          <FormattedValue dataType="pre">
                            Dostęp aktywny do końca trwania sesji
                          </FormattedValue>
                        )}
                    </Block>
                    <hr
                      className={css({
                        borderWidth: "0px",
                        height: "1px",
                        backgroundColor: "#eee",
                      })}
                    />
                  </>
                </Cell>
              ),
              group.fields
                .filter((f) => f.show.visible)
                .map((item, index) => (
                  <Cell span={item.span || 6} key={group.id + `-field` + index}>
                    <FormControl label={item.label} disabled={true}>
                      {isFetching ? (
                        <Skeleton
                          rows={0}
                          height="20px"
                          width="100%"
                          animation
                        />
                      ) : data?.document?.recipient?.__typename ===
                          HIDDEN_ADDRESS &&
                        item.id.includes("address") &&
                        !item.id.includes("Name") ? (
                        <Skeleton
                          rows={0}
                          height="20px"
                          width="15ch"
                          overrides={{
                            Root: {
                              style: {
                                backgroundColor: "#faebeb",
                              },
                            },
                          }}
                        />
                      ) : (
                        <FormattedValue
                          dataType={item.dataType}
                          data={data?.document[item.id]}
                        >
                          {item.id === "addressAddress"
                            ? data?.document?.recipient?.streetName
                              ? `${data?.document?.recipient?.streetName} ${
                                  data?.document?.recipient?.buildingNumber ||
                                  ""
                                }${
                                  data?.document?.recipient?.apartmentNumber
                                    ? `/${data?.document?.recipient?.apartmentNumber}`
                                    : ""
                                }`
                              : data?.document?.recipient?.address
                            : item.show.accessor
                            ? data?.document[item.show.accessor[0]]?.[
                                item.show.accessor[1]
                              ]
                            : data?.document[item.id]}
                        </FormattedValue>
                      )}
                    </FormControl>
                  </Cell>
                )),
            ])}

            {PACZKA_ZAGRANICZNA_FIELDS.filter(
              (g) => g.fields.filter((f) => f.create.visible).length > 0
            ).map((group) => [
              group.label && (
                <Cell key={group.id + `-group`} span={12}>
                  <LabelMedium marginBottom="scale200" marginTop="scale600">
                    {group.label}
                  </LabelMedium>
                  <hr
                    className={css({
                      borderWidth: "0px",
                      height: "1px",
                      backgroundColor: "#eee",
                    })}
                  />
                </Cell>
              ),
              group.fields
                .filter((f) => f.create.visible)
                .map((item, index) => (
                  <Cell span={item.span || 6} key={group.id + `-field` + index}>
                    <FormControl
                      label={item.type === FIELDS.Checkbox ? "" : item.label}
                      required={item.create.required}
                      error={
                        ((errors as any)[item.id] &&
                          (errors as any)[item.id].message) ||
                        (error &&
                          error.graphQLErrors[0]?.extensions?.code ===
                            "INPUT_VALIDATION_ERROR" &&
                          error.graphQLErrors[0]?.extensions?.validationErrors
                            ?.find((vE: InputValidationError) =>
                              item.id.includes("address")
                                ? vE?.property ===
                                  item.id.slice(7).toLowerCase()
                                : vE?.property === item.id
                            )
                            ?.errors.map((message: string) => (
                              <div
                                key="error"
                                className={css({
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                })}
                              >
                                {message}
                                <AlertOctagon color="#999" size={12} />
                              </div>
                            ))[0])
                      }
                      disabled={isLoading}
                    >
                      {item.type === FIELDS.Checkbox ? (
                        <ControlledCheckbox
                          control={control}
                          name={item.id}
                          disabled={loading}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        >
                          {item.label}
                        </ControlledCheckbox>
                      ) : item.type === FIELDS.DeklaracjaCelnaRodzajSelect ? (
                        <ControlledDeklaracjaCelnaRodzajSelect
                          control={control}
                          name={item.id}
                          id={item.id}
                          placeholder={item.placeholder}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.ZawartoscPrzesylkiSelect ? (
                        <ControlledZawartoscPrzesylkiSelect
                          control={control}
                          name={item.id}
                          id={item.id}
                          placeholder={item.placeholder}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type ===
                        FIELDS.DokumentyTowarzyszaceRodzajSelect ? (
                        <ControlledDokumentyTowarzyszaceRodzajSelect
                          control={control}
                          name={item.id}
                          id={item.id}
                          placeholder={item.placeholder}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.SendingListsSelect ? (
                        <ControlledSendingListsSelect
                          control={control}
                          name={item.id}
                          id={item.id}
                          placeholder={item.placeholder}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.DatePicker ? (
                        <ControlledDatePicker
                          name={item.id}
                          control={control}
                          minDate={new Date()}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.id === "shipmentRate" ? (
                        <>
                          {isFetching ? (
                            <Skeleton
                              rows={0}
                              height="20px"
                              width="100%"
                              animation
                            />
                          ) : (
                            <FormattedValue
                              dataType="model:shipment-contracts"
                              data={`${locationState?.shipmentContractId}/${locationState?.shipmentRateId}`}
                            >
                              {`${choosedShipmentRate?.name} [${choosedShipmentRate?.price} PLN]`}
                            </FormattedValue>
                          )}
                        </>
                      ) : (
                        <ControlledInput
                          control={control}
                          name={item.id}
                          placeholder={item.placeholder}
                          masked={item.mask !== undefined}
                          mask={item.mask}
                          maskChar="_"
                          endEnhancer={item.endEnhancer}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                          {...(item.type === FIELDS.NumberInput && {
                            type: "number",
                            min: 0,
                          })}
                        />
                      )}
                    </FormControl>
                  </Cell>
                )),
            ])}
            <ConfirmDialog
              isOpen={isCancelConfirmDialogOpen}
              label="Anulowanie dodania do listy wysyłkowej"
              close={() => setIsCancelConfirmDialogOpen(false)}
              confirm={() => history.goBack()}
            />
            <ConfirmDialog
              isOpen={isClearConfirmDialogOpen}
              label="Wyczyszczenie formularza"
              close={() => setIsClearConfirmDialogOpen(false)}
              confirm={() => {
                reset();
                setIsClearConfirmDialogOpen(false);
              }}
            />
            <ConfirmDialog
              isOpen={isAddressRevealConfirmDialogOpen}
              type={ConfirmDialogType.AddressReveal}
              close={() => setIsAddressRevealConfirmDialogOpen(false)}
              confirm={() => onAddressRevealSubmit()}
            />
          </Grid>
        </form>
      </Content>
    </article>
  );
}
