import { FIELDS, FieldsGroup } from "../../../fields.d";
import {
  DeklaracjaCelnaRodzaj,
  DokumentyTowarzyszaceRodzaj,
  ENadawcaKategoria,
  ZawartoscPrzesylkiZagranicznej,
} from "../e-nadawca-parcels";

export type FormInputs = {
  documentParcel: {
    id: string;
    label?: string;
    shipmentType?: string;
  }[];
  sendingList: { id: number | string; label?: string }[];
  opis?: string;
  planowanaDataNadania?: Date | null;
  zawartosc?: string;
  posteRestante?: boolean;
  kategoriaSelect?: { id: string; label: string }[] | undefined;
  kategoria?: ENadawcaKategoria;
  masa?: number | string;
  ekspres?: boolean;
  kraj?: { id: number | string; label?: string }[];
  deklaracjaCelna2Rodzaj?: DeklaracjaCelnaRodzaj;
  deklaracjaCelna2RodzajSelect: { id: string; label: string }[] | undefined;
  deklaracjaCelna2ZawartoscPrzesylki?: ZawartoscPrzesylkiZagranicznej;
  deklaracjaCelna2ZawartoscPrzesylkiSelect?:
    | { id: string; label: string }[]
    | undefined;
  deklaracjaCelna2Wyjasnienie?: string;
  deklaracjaCelna2OplatyPocztowe?: string;
  deklaracjaCelna2Uwagi?: string;
  deklaracjaCelna2NumerReferencyjnyImportera?: string;
  deklaracjaCelna2NumerTelefonuImportera?: string;
  deklaracjaCelna2WalutaKodISO: string;
  deklaracjaCelna2NumerReferencyjnyCelny?: string;
  dokumentyTowarzyszaceRodzaj?: DokumentyTowarzyszaceRodzaj;
  dokumentyTowarzyszaceRodzajSelect:
    | { id: string; label: string }[]
    | undefined;
  dokumentyTowarzyszaceNumer: string;
  okreslenieZawartosci: string;
  ilosc: number;
  masaNetto?: number;
  wartosc?: number;
  numerTaryfyHs?: string;
  krajPochodzeniaKodAlfa2?: string;
};

export const DOCUMENT_ADDRESS_FIELDS: FieldsGroup[] = [
  {
    id: "recipient",
    label: "Adresat",
    fields: [
      {
        id: "addressName",
        label: "Nazwa",
        type: FIELDS.Input,
        span: 12,
        show: { accessor: ["recipient", "name"], visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "addressIsSensitive",
        label: "Dane osobowe",
        type: FIELDS.Checkbox,
        span: 3,
        show: { visible: false },
        create: { visible: false },
        edit: { visible: true },
      },
      {
        id: "addressAddress",
        label: "Ulica i numer",
        type: FIELDS.Input,
        span: 3,
        show: { accessor: ["recipient", "address"], visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "addressPostalCode",
        label: "Kod pocztowy",
        type: FIELDS.Input,
        span: 3,
        show: { accessor: ["recipient", "postalCode"], visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "addressCity",
        label: "Miejscowość",
        type: FIELDS.Input,
        span: 3,
        show: { accessor: ["recipient", "city"], visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "addressCountryCode",
        label: "Kraj",
        type: FIELDS.Input,
        span: 3,
        show: { accessor: ["recipient", "countryCode"], visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "countryCode",
      },
      {
        id: "addressNip",
        label: "NIP",
        type: FIELDS.Input,
        span: 3,
        show: { accessor: ["recipient", "nip"], visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "nip",
      },
      {
        id: "addressPhoneNumber",
        label: "Numer telefonu",
        type: FIELDS.Input,
        span: 3,
        show: { accessor: ["recipient", "phoneNumber"], visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "addressEmail",
        label: "Adres e-mail",
        type: FIELDS.Input,
        span: 6,
        show: { accessor: ["recipient", "email"], visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "email",
      },
    ],
  },
];

export const PRZESYLKA_ZAGRANICZNA_FIELDS: FieldsGroup[] = [
  {
    id: "basic",
    label: "Podstawowe informacje",
    fields: [
      {
        id: "shipmentRate",
        label: "Stawka",
        type: FIELDS.Input,
        placeholder: "Wybierz",
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "sendingList",
        label: "Lista wysyłkowa",
        type: FIELDS.SendingListsSelect,
        placeholder: "Wybierz",
        span: 6,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: false },
      },
      {
        id: "kategoriaSelect",
        label: "Kategoria",
        type: FIELDS.ENadawcaKategoriaSelect,
        placeholder: "Wybierz",
        span: 3,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: false },
      },
      {
        id: "masa",
        label: "Masa",
        type: FIELDS.NumberInput,
        span: 3,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: false },
        endEnhancer: "g",
      },
    ],
  },
  {
    id: "other",
    label: "Dodatkowe informacje",
    fields: [
      {
        id: "posteRestante",
        label: "Poste Restante",
        type: FIELDS.Checkbox,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "ekspres",
        label: "Ekspres",
        type: FIELDS.Checkbox,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "opis",
        label: "Opis",
        type: FIELDS.Input,
        span: 6,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "kraj",
        label: "Kraj",
        type: FIELDS.DictionaryValuesSelect,
        info: "international",
        placeholder: "Wybierz",
        span: 3,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: false },
      },
      {
        id: "planowanaDataNadania",
        label: "Planowana data nadania",
        type: FIELDS.DatePicker,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "zawartosc",
        label: "Zawartość",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
    ],
  },
  {
    id: "deklaracjaCelna2",
    label: "Deklaracja celna",
    fields: [
      {
        id: "deklaracjaCelna2RodzajSelect",
        label: "Rodzaj",
        type: FIELDS.DeklaracjaCelnaRodzajSelect,
        placeholder: "Wybierz",
        span: 3,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: false },
      },
      {
        id: "deklaracjaCelna2ZawartoscPrzesylkiSelect",
        label: "Zawartość przesyłki",
        type: FIELDS.ZawartoscPrzesylkiSelect,
        placeholder: "Wybierz",
        span: 3,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: false },
      },
      {
        id: "okreslenieZawartosci",
        label: "Określenie zawartości",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: false },
      },
      {
        id: "ilosc",
        label: "Ilość",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: false },
      },
      {
        id: "dokumentyTowarzyszaceRodzajSelect",
        label: "Rodzaj dokumentów towarzyszących",
        type: FIELDS.DokumentyTowarzyszaceRodzajSelect,
        placeholder: "Wybierz",
        span: 3,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: false },
      },
      {
        id: "dokumentyTowarzyszaceNumer",
        label: "Numer dokumentów towarzyszących",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: false },
      },
      {
        id: "deklaracjaCelna2WalutaKodISO",
        label: "Kod ISO waluty",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: false },
      },
      {
        id: "deklaracjaCelna2Wyjasnienie",
        label: "Wyjaśnienie",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "deklaracjaCelna2OplatyPocztowe",
        label: "Opłaty pocztowe",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "deklaracjaCelna2Uwagi",
        label: "Uwagi",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "deklaracjaCelna2NumerReferencyjnyImportera",
        label: "Numer referencyjny importera",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "deklaracjaCelna2NumerTelefonuImportera",
        label: "Numer telefonu importera",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "deklaracjaCelna2NumerReferencyjnyCelny",
        label: "Numer referencyjny celny",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "masaNetto",
        label: "Masa netto",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "wartosc",
        label: "Wartość",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "numerTaryfyHs",
        label: "Numer taryfy HS",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "krajPochodzeniaKodAlfa2",
        label: "Kod Alfa 2 kraju pochodzenia",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
    ],
  },
];
