import { History } from "history";

export function setSortingParams(
  history: History<unknown>,
  search: string,
  column: string,
  sortDirection: string | null
): void {
  const params = new URLSearchParams(search);

  if (params.get("page")) {
    params.delete("page");
  }

  if (params.get("sortBy")) {
    params.delete("sortBy");
  }

  if (params.get("sortDirection")) {
    params.delete("sortDirection");
  }

  history.push({
    search: new URLSearchParams({
      ...(params && Object.fromEntries(params)),
      sortBy: column,
      sortDirection:
        sortDirection === null
          ? "DESC"
          : sortDirection === "ASC"
          ? "DESC"
          : "ASC",
    }).toString(),
  });
}
