import { gql } from "@apollo/client";

export const FOLDERS_SHOW = gql`
  query(
    $pageSize: Int
    $offset: Int
    $sorting: [UserFolderableSort!]
    $key: Folder!
  ) {
    folder(
      paging: { limit: $pageSize, offset: $offset }
      sorting: $sorting
      key: $key
    ) {
      totalCount
      nodes {
        __typename
        ... on Case {
          id
          number
          isConductedElectronically
          projectNumber
          name
        }
        ... on Document {
          id
          documentNumber
          internalNumber
          documentKind
          documentType
          type
          documentDate
          createdAt
          postStampAt
          title
          description
          sequenceNumber
          additionalCode
          state
          hasDocumentFlowEnded
          name
          recipient {
            __typename
            ... on User {
              id
              firstName
              lastName
            }
            ... on Address {
              id
              name
            }
            ... on HiddenAddress {
              id
              name
            }
          }
          sender {
            __typename
            ... on User {
              id
              firstName
              lastName
            }
            ... on Address {
              id
              name
            }
            ... on HiddenAddress {
              id
              name
            }
          }
          case {
            id
            name
            number
            isConductedElectronically
          }
          organizationalUnit {
            id
            name
            symbol
          }
          currentStatus {
            documentFlowStepId
            documentFlowStep {
              id
              name
              documentFlow {
                steps {
                  id
                }
              }
            }
            createdAt
          }
        }
      }
    }
  }
`;

export const FOLDERS_RECEIVED_ASSIGNMENTS = gql`
  query(
    $pageSize: Int
    $offset: Int
    $sorting: [AssignmentSort!]
    $filter: AssignmentFilter
  ) {
    receivedAssignments(
      paging: { limit: $pageSize, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        parentId
        type
        assigner {
          id
          firstName
          lastName
        }
        isAssessmentRequested
        assessmentResult
        assignerId
        assignee {
          id
          firstName
          lastName
        }
        assigneeId
        assignerComment
        assigneeComment
        primaryAssignable {
          __typename
          ... on Case {
            id
            number
            isConductedElectronically
            projectNumber
            name
          }
          ... on Document {
            id
            documentNumber
            internalNumber
            documentKind
            documentType
            type
            documentDate
            createdAt
            sequenceNumber
            postStampAt
            title
            state
            hasDocumentFlowEnded
            additionalCode
            name
            description
            recipient {
              __typename
              ... on User {
                id
                firstName
                lastName
              }
              ... on Address {
                id
                name
              }
              ... on HiddenAddress {
                id
                name
              }
            }
            sender {
              __typename
              ... on User {
                id
                firstName
                lastName
              }
              ... on Address {
                id
                name
              }
              ... on HiddenAddress {
                id
                name
              }
            }
            case {
              id
              name
              number
              isConductedElectronically
            }
            organizationalUnit {
              id
              name
              symbol
            }
            currentStatus {
              documentFlowStepId
              documentFlowStep {
                id
                name
                documentFlow {
                  steps {
                    id
                  }
                }
              }
              createdAt
            }
          }
        }
        status
        createdAt
        finishedAt
      }
    }
  }
`;

export const FOLDERS_SENT_ASSIGNMENTS = gql`
  query(
    $pageSize: Int
    $offset: Int
    $sorting: [AssignmentSort!]
    $filter: AssignmentFilter
  ) {
    sentAssignments(
      paging: { limit: $pageSize, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        parentId
        type
        assigner {
          id
          firstName
          lastName
        }
        isAssessmentRequested
        assignerId
        assignee {
          id
          firstName
          lastName
        }
        assigneeId
        assignerComment
        assigneeComment
        primaryAssignable {
          __typename
          ... on Case {
            id
            number
            isConductedElectronically
            projectNumber
            name
          }
          ... on Document {
            id
            documentNumber
            internalNumber
            documentKind
            sequenceNumber
            documentType
            type
            documentDate
            createdAt
            postStampAt
            title
            state
            hasDocumentFlowEnded
            description
            additionalCode
            name
            recipient {
              __typename
              ... on User {
                id
                firstName
                lastName
              }
              ... on Address {
                id
                name
              }
              ... on HiddenAddress {
                id
                name
              }
            }
            sender {
              __typename
              ... on User {
                id
                firstName
                lastName
              }
              ... on Address {
                id
                name
              }
              ... on HiddenAddress {
                id
                name
              }
            }
            case {
              id
              name
              number
              isConductedElectronically
            }
            organizationalUnit {
              id
              name
              symbol
            }
            currentStatus {
              documentFlowStepId
              documentFlowStep {
                id
                name
                documentFlow {
                  steps {
                    id
                  }
                }
              }
              createdAt
            }
          }
        }
        status
        createdAt
        finishedAt
      }
    }
  }
`;

export const FOLDERS_FLOW_ASSIGNMENTS = gql`
  query(
    $pageSize: Int
    $offset: Int
    $sorting: [AssignmentSort!]
    $filter: AssignmentFilter
  ) {
    flowAssignments(
      paging: { limit: $pageSize, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        parentId
        type
        assigner {
          id
          firstName
          lastName
        }
        isAssessmentRequested
        assessmentResult
        assignerId
        assignee {
          id
          firstName
          lastName
        }
        assigneeId
        assignerComment
        assigneeComment
        primaryAssignable {
          __typename
          ... on Case {
            id
            number
            isConductedElectronically
            projectNumber
            name
          }
          ... on Document {
            id
            documentNumber
            internalNumber
            documentKind
            documentType
            type
            documentDate
            createdAt
            postStampAt
            title
            sequenceNumber
            state
            hasDocumentFlowEnded
            additionalCode
            name
            description
            recipient {
              __typename
              ... on User {
                id
                firstName
                lastName
              }
              ... on Address {
                id
                name
              }
              ... on HiddenAddress {
                id
                name
              }
            }
            sender {
              __typename
              ... on User {
                id
                firstName
                lastName
              }
              ... on Address {
                id
                name
              }
              ... on HiddenAddress {
                id
                name
              }
            }
            case {
              id
              name
              number
              isConductedElectronically
            }
            organizationalUnit {
              id
              name
              symbol
            }
            currentStatus {
              documentFlowStepId
              documentFlowStep {
                id
                name
                documentFlow {
                  steps {
                    id
                  }
                }
              }
              createdAt
            }
          }
        }
        status
        createdAt
        finishedAt
      }
    }
  }
`;

export const ASSIGNMENT_FINISH = gql`
  mutation($assignmentFinishInput: AssignmentFinishInput!) {
    assignmentFinish(assignmentFinishInput: $assignmentFinishInput) {
      id
    }
  }
`;

export const ASSIGNMENT_CREATED = gql`
  subscription {
    assignmentCreated {
      id
    }
  }
`;

export const ASSIGNMENT_DISPATCH_CANCELLED = gql`
  subscription {
    assignmentDispatchCancelled {
      id
    }
  }
`;
