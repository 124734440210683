import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { StyledLink } from "baseui/link";
import { useSnackbar } from "notistack";
import React, { MouseEvent, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { Row } from "react-table";
import { Eye, Plus } from "tabler-icons-react";

import BottomPanel from "../../../components/bottom-panel";
import Button from "../../../components/button";
import Cell from "../../../components/cell";
import ConfirmDialog from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import Filters from "../../../components/filters";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import PagingControls from "../../../components/paging-controls";
import SortingTableHeader, {
  SortDirection,
} from "../../../components/sorting-table-header";
import Table from "../../../components/table";
import { useLoading } from "../../../contexts/loading-context";
import { usePaging } from "../../../contexts/paging-context";
import { FiltersState } from "../../../filters";
import { checkPermission } from "../../../utils/check-permission";
import { translateFiltersState } from "../../../utils/filters";
import { PERMISSIONS } from "../../../utils/permissions";
import { setSortingParams } from "../../../utils/sorting";
import { SendingList } from "../sending-lists";
import { SENDING_LISTS_FILTERS } from "../sending-lists.filters";
import {
  SENDING_LISTS_CREATE,
  SENDING_LISTS_INDEX,
} from "../sending-lists.gql";

enum FieldName {
  Id = "id",
  ENadawcaUrzadNadania = "eNadawcaUrzadNadania",
  CreatedAt = "createdAt",
  IsSent = "isSent",
  SentAt = "sentAt",
}

export default function SendingListsIndex(): React.ReactElement {
  const { pageSize, currentPage, setTotalCount } = usePaging();

  const { search } = useLocation();

  const params = useMemo(() => new URLSearchParams(search), [search]);

  const [sortBy, setSortBy] = useState<FieldName | null>(
    (params.get("sortBy") as FieldName) || FieldName.CreatedAt
  );

  const [sortDirection, setSortDirection] = useState<SortDirection | null>(
    (params.get("sortDirection") as SortDirection) || SortDirection.DESC
  );

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [css] = useStyletron();
  const {
    isPartialFetching,
    setIsPartialFetching,
    isFetching,
    setIsFetching,
    setIsLoading,
  } = useLoading();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [filters, setFilters] = useState<FiltersState>();

  const handleSorting = (column: FieldName) => {
    setSortingParams(history, search, column, sortDirection);
    setSortBy(column);
    setSortDirection(
      sortDirection === null
        ? SortDirection.DESC
        : sortDirection === SortDirection.ASC
        ? SortDirection.DESC
        : SortDirection.ASC
    );
  };

  const { data, error, refetch } = useQuery(SENDING_LISTS_INDEX, {
    variables: {
      pageSize,
      offset: (currentPage - 1) * pageSize,
      sorting: {
        field: sortBy,
        direction: sortDirection,
      },
      ...(filters && { filter: translateFiltersState(filters) }),
    },
  });

  useEffect(() => {
    if (data?.eNadawcaSendingLists) setTimeout(() => refetch(), 200);
    setIsFetching(true);
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.Id)}
            sortDirection={sortBy === FieldName.Id ? sortDirection : null}
            $style={{ display: "flex", justifyContent: "flex-end" }}
          >
            Id
          </SortingTableHeader>
        ),
        id: "id",
        Cell: ({ row }: { row: Row<SendingList> }) => (
          <Block display="flex" justifyContent="flex-end">
            <StyledLink
              onClick={(event: MouseEvent) => {
                event.preventDefault();
                history.push(`/sending-lists/${row?.original?.id}`);
              }}
              href={`/sending-lists/${row?.original?.id}`}
            >
              <FormattedValue>{row?.original?.id}</FormattedValue>
            </StyledLink>
          </Block>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.IsSent)}
            sortDirection={sortBy === FieldName.IsSent ? sortDirection : null}
            $style={{ display: "flex", justifyContent: "center" }}
          >
            Wysłano
          </SortingTableHeader>
        ),
        id: "isSent",
        Cell: ({ row }: { row: Row<SendingList> }) => {
          return (
            <Block display="flex" alignItems="center" justifyContent="center">
              <FormattedValue dataType="boolean">
                {row.original.isSent}
              </FormattedValue>
            </Block>
          );
        },
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.SentAt)}
            sortDirection={sortBy === FieldName.SentAt ? sortDirection : null}
            $style={{ display: "flex", justifyContent: "flex-end" }}
          >
            Data wysłania
          </SortingTableHeader>
        ),
        id: "sentdAt",
        Cell: ({ row }: { row: Row<SendingList> }) => (
          <Block display="flex" alignItems="center" justifyContent="flex-end">
            <FormattedValue dataType="date">
              {row?.original?.sentAt}
            </FormattedValue>
          </Block>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.Id)}
            sortDirection={sortBy === FieldName.Id ? sortDirection : null}
            $style={{ display: "flex", justifyContent: "flex-end" }}
          >
            Data utworzenia
          </SortingTableHeader>
        ),
        id: "createdAt",
        Cell: ({ row }: { row: Row<SendingList> }) => (
          <Block display="flex" alignItems="center" justifyContent="flex-end">
            <FormattedValue dataType="date">
              {row?.original?.createdAt}
            </FormattedValue>
          </Block>
        ),
      },
      {
        Header: (
          <Block display="flex" alignItems="center" justifyContent="flex-end">
            Liczba przesyłek
          </Block>
        ),
        id: "itemsCount",
        Cell: ({ row }: { row: Row<SendingList> }) => (
          <Block display="flex" alignItems="center" justifyContent="flex-end">
            <FormattedValue>
              {row?.original?.sendingListItems?.length}
            </FormattedValue>
          </Block>
        ),
      },
      {
        id: "actions",
        Cell: ({ row }: { row: Row<SendingList> }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              $style={{ marginLeft: "6px" }}
              $as="a"
              href={`/sending-lists/${row.original.id}`}
              onClick={(e) => {
                e.preventDefault();
                history.push(`/sending-lists/${row.original.id}`);
              }}
              startEnhancer={<Eye size={14} />}
            />
          </div>
        ),
      },
    ],
    [sortBy, sortDirection, data, filters]
  );

  const [createList, { error: mutationError, loading }] = useMutation(
    SENDING_LISTS_CREATE
  );

  const onSubmit = async (): Promise<void> => {
    setIsLoading(true);
    try {
      await createList({
        variables: {
          eNadawcaSendingListCreateInput: {
            eNadawcaUrzadNadania:
              process.env.REACT_APP_E_NADAWCA_URZAD_NADANIA_ID,
          },
        },
      });

      enqueueSnackbar({
        message: "Utworzono pomyślnie",
        variant: "success",
      });
      refetch();
      setIsConfirmDialogOpen(false);
    } catch (error: unknown) {
      setIsConfirmDialogOpen(false);
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    if (mutationError?.graphQLErrors)
      enqueueSnackbar({
        message: (mutationError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [mutationError]);

  useEffect(() => {
    setTimeout(() => refetch(), 200);
    setIsPartialFetching(true);
  }, [currentPage, pageSize]);

  useEffect(() => {
    setTimeout(() => refetch(), 200);
    setIsPartialFetching(true);
  }, [sortBy, sortDirection]);

  useEffect(() => {
    if (data?.eNadawcaSendingLists) setIsFetching(false);
    if (data?.eNadawcaSendingLists?.totalCount >= 0)
      setTotalCount(data?.eNadawcaSendingLists?.totalCount);
  }, [data]);

  return (
    <article>
      <Header
        title="Listy wysyłkowe"
        recordsNum={data?.eNadawcaSendingLists?.totalCount}
        labels={["Lista"]}
        buttons={[
          {
            label: "Dodaj listę",
            kind: KIND.primary,
            startEnhancer: <Plus size={18} />,
            isLoading: loading,
            onClick: () => setIsConfirmDialogOpen(true),
            permission: checkPermission(PERMISSIONS.sendingList.create),
          },
        ]}
      />
      <Filters
        filters={SENDING_LISTS_FILTERS}
        state={filters}
        setState={setFilters}
      />
      <Content filtersOffset>
        <Grid>
          <Cell span={12} $style={{ position: "relative" }}>
            <Table<SendingList>
              columns={columns}
              data={data?.eNadawcaSendingLists?.nodes}
              isLoading={isFetching || isPartialFetching || loading}
              stickLastColumn
            />
          </Cell>

          <Cell span={12}>
            <BottomPanel>
              <PagingControls />
            </BottomPanel>
          </Cell>

          <ConfirmDialog
            isOpen={isConfirmDialogOpen}
            label={`Dodanie nowej listy wysyłkowej.`}
            close={() => setIsConfirmDialogOpen(false)}
            confirm={onSubmit}
          />
        </Grid>
      </Content>
    </article>
  );
}
