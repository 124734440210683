import { styled, useStyletron } from "baseui";
import { Avatar as BaseAvatar } from "baseui/avatar";
import { Block } from "baseui/block";
import { StyledLink } from "baseui/link";
import { PLACEMENT } from "baseui/popover";
import { Tag } from "baseui/tag";
import { HeadingSmall, LabelMedium, LabelSmall } from "baseui/typography";
import React, { Fragment, MouseEvent, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import stc from "string-to-color";
import {
  Archive,
  ArrowsLeftRight,
  Bell,
  Book,
  Briefcase,
  BrowserCheck,
  BrowserPlus,
  Building,
  Cash,
  ChevronRight,
  ClipboardList,
  CurrencyDollar,
  CurrencyZloty,
  Direction,
  Divide,
  FileExport,
  FileImport,
  FilePlus,
  FileSymlink,
  FileText,
  Folder,
  FolderPlus,
  Folders,
  FolderX,
  Friends,
  HandGrab,
  Icon,
  List,
  Logout,
  Mail,
  Mailbox,
  Notes,
  Replace,
  ReportMoney,
  Shield,
  Truck,
  User,
  Users,
  Wallet,
  ZoomMoney,
} from "tabler-icons-react";

import { APP_NAME, REACT_APP_SYSTEM_VERSIONS } from "../constants";
import { useAuth } from "../contexts/auth-context";
import { useSubstitution } from "../contexts/substitution-context";
import { useSupport } from "../contexts/support-context";
import { PERMISSIONS } from "../utils/permissions";
import Button from "./button";
import SubmenuPopover from "./submenu-popover";
import SubstitutionSwitch from "./substitution-switch";
import Tooltip from "./tooltip";

const Container = styled("nav", {
  position: "relative",
  backgroundColor: "#f9f9f9",
  display: "flex",
  flexDirection: "column",
  overflowX: "auto",
  height: "100vh",
  paddingBottom: "15px",
  scrollbarWidth: "none",
  msOverflowStyle: "none",
  borderRight: "2px inset #eeeeee",
  "::-webkit-scrollbar": {
    display: "none",
  },
});

type NavCategory = {
  label?: string;
  items: NavItem[];
};

type NavItem = {
  label: string;
  icon: Icon;
  href: string;
  permission?: string;
  exact?: boolean;
  submenu?: NavItem[];
  indicator?: string;
};

const USER_NAV: NavItem[] = [
  {
    label: "Moje konto",
    icon: User,
    href: "/my-account",
  },
  {
    label: "Powiadomienia",
    icon: Bell,
    href: "/notifications",
  },
  {
    label: "Moje zastępstwa",
    icon: Replace,
    href: "/my-substitutions",
  },
  {
    label: "Wyloguj się",
    icon: Logout,
    href: "/logout",
  },
];

const NAV: NavCategory[] = [
  {
    label: "Foldery",
    items: [
      {
        label: "Nowe",
        icon: FolderPlus,
        href: "/folders/new",
        indicator: "new",
      },
      {
        label: "W realizacji",
        icon: Folder,
        href: "/folders/in-progress",
      },
      {
        label: "W obiegu zdefiniowanym",
        icon: Folder,
        href: "/folders/in-flow",
      },
      {
        label: "Przekazane",
        icon: Folder,
        href: "/folders/transferred",
      },
      {
        label: "Udostępnione",
        icon: Folder,
        href: "/folders/shared",
      },
      {
        label: "Zakończone",
        icon: FolderX,
        href: "/folders/finished",
      },
    ],
  },
  {
    label: "Dokumenty",
    items: [
      {
        label: "Wszystkie",
        icon: Briefcase,
        href: "/documents/all",
        exact: true,
      },
      {
        label: "Zarejestruj",
        icon: FilePlus,
        href: "",
        permission: PERMISSIONS.document.create,
        submenu: [
          {
            label: "Przychodzący",
            icon: FileImport,
            href: "/documents/create-incoming",
            permission: PERMISSIONS.document.create,
          },
          {
            label: "Wewnętrzny",
            icon: FileSymlink,
            href: "/documents/create-internal",
            permission: PERMISSIONS.document.create,
          },
          {
            label: "Wychodzący",
            icon: FileExport,
            href: "/documents/create-outgoing",
            permission: PERMISSIONS.document.create,
          },
        ],
      },
      {
        label: "Nowy wniosek",
        icon: BrowserPlus,
        href: "/documents/create-request-form",
        exact: true,
        permission:
          PERMISSIONS.requestForm.create ||
          PERMISSIONS.contractPreparationRequest.create ||
          PERMISSIONS.billIssuanceRequest.create,
        submenu: [
          {
            label: "Wydatkowanie środków publicznych (wniosek wszczęcia)",
            icon: ReportMoney,
            href: "/documents/create-request-form",
            permission: PERMISSIONS.requestForm.create,
            exact: true,
          },
          {
            label: "Wystawienie rachunku",
            icon: BrowserCheck,
            href: "/documents/create-bill-issuance-request",
            permission: PERMISSIONS.billIssuanceRequest.create,
          },
          {
            label: "Wystawienie umowy cywilno-prawnej",
            icon: FileText,
            href: "/documents/create-contract-preparation-request",
            permission: PERMISSIONS.contractPreparationRequest.create,
          },
        ],
      },
      {
        label: "Nowa notatka do wniosku",
        icon: Notes,
        href: "/documents/create-request-form-note",
        permission: PERMISSIONS.requestFormNote.create,
        exact: true,
      },
      {
        label: "Spis spraw",
        icon: Folders,
        href: "/cases",
        // permission: PERMISSIONS.case.read,
      },
    ],
  },
  {
    label: "Kancelaria",
    items: [
      {
        label: "Rejestr",
        icon: Archive,
        href: "/documents/incoming",
        permission: PERMISSIONS.documentsRegister.read,
        submenu: [
          {
            label: "RPW",
            icon: Archive,
            href: "/documents/incoming",
            permission: PERMISSIONS.documentsRegister.read,
            exact: true,
          },
          {
            label: "WEW",
            icon: Archive,
            href: "/documents/internal",
            permission: PERMISSIONS.documentsRegister.read,
            exact: true,
          },
        ],
      },
      {
        label: "Wydania dokumentów",
        icon: HandGrab,
        href: "/document-pickups",
        permission: PERMISSIONS.documentPickup.read,
      },
    ],
  },
  {
    label: "Finanse",
    items: [
      {
        label: "Polecenia płatności",
        icon: Cash,
        href: "/payments",
        permission: PERMISSIONS.payments.read,
      },
      {
        label: "Konta wewnętrzne",
        icon: Wallet,
        href: "/internal-accounts",
        permission: PERMISSIONS.internalAccounts.read,
      },
      {
        label: "Szablony podzielników",
        icon: Divide,
        href: "/divisors",
        permission: PERMISSIONS.divisorTemplates.read,
      },
      {
        label: "Plany finansowe",
        icon: ReportMoney,
        href: "/financial-plans",
        permission: PERMISSIONS.financialPlan.read,
      },
      {
        label: "Historia zmian budżetowych",
        icon: ZoomMoney,
        href: "/budget-ledger-items",
        permission: PERMISSIONS.budgetLedgerItems.read,
      },
    ],
  },
  {
    label: "Wysyłki",
    items: [
      // {
      //   label: "Nadaj dokument",
      //   icon: FileExport,
      //   href: "/document-parcels/create",
      //   permission: PERMISSIONS.document.create,
      //   exact: true,
      // },
      {
        label: "Listy nadane",
        icon: Mail,
        href: "/document-parcels",
        permission: PERMISSIONS.documentParcel.read,
        exact: true,
      },
      {
        label: "Listy wysyłkowe",
        icon: Truck,
        href: "/sending-lists",
        permission: PERMISSIONS.sendingList.read,
      },
    ],
  },
  {
    label: "Administracja",
    items: [
      {
        label: "Jednostki organizacyjne",
        icon: Building,
        href: "/organizational-units",
        permission: PERMISSIONS.organizationalUnit.read,
      },
      {
        label: "Stanowiska",
        icon: Friends,
        href: "/positions",
        permission: PERMISSIONS.position.read,
      },
      {
        label: "Użytkownicy",
        icon: User,
        href: "/users",
        permission: PERMISSIONS.user.read,
      },
      {
        label: "Grupy użytkowników",
        icon: Users,
        href: "/groups",
        permission: PERMISSIONS.group.read,
      },
      {
        label: "Zastępstwa",
        icon: Replace,
        href: "/substitutions",
        permission: PERMISSIONS.substitution.read,
      },
      {
        label: "Role i uprawnienia",
        icon: Shield,
        href: "/roles",
        permission: PERMISSIONS.role.read,
      },
      {
        label: "Słowniki systemowe",
        icon: Book,
        href: "/dictionaries",
        permission:
          PERMISSIONS.dictionary.read &&
          (PERMISSIONS.dictionary.valueCreate ||
            PERMISSIONS.dictionary.valueDelete ||
            PERMISSIONS.dictionary.valueUpdate),
      },
      {
        label: "Cenniki",
        icon: CurrencyZloty,
        href: "/shipment-contracts",
        permission: PERMISSIONS.shipmentContract.read,
      },
      {
        label: "Klasyfikacja JRWA",
        icon: List,
        href: "/jrwa-classifications",
        permission: PERMISSIONS.jrwa.read,
      },
      {
        label: "Adresaci",
        icon: Mailbox,
        href: "/addresses",
        permission: PERMISSIONS.address.read,
      },
      {
        label: "Waluty",
        icon: CurrencyDollar,
        href: "/currencies",
        permission: PERMISSIONS.currency.read,
      },
      {
        label: "Obieg dokumentów",
        icon: ArrowsLeftRight,
        href: "/flow",
        permission: PERMISSIONS.documentFlow.read,
      },
      {
        label: "Logi aktywności",
        icon: ClipboardList,
        href: "/activity-logs",
        permission: PERMISSIONS.activityLog.read,
      },
    ],
  },
];

function UserSummary({ width }: SideNavProps): React.ReactElement {
  const [css, theme] = useStyletron();
  const [isOpen, setIsOpen] = useState<boolean | null>(null);

  const location = useLocation();
  const history = useHistory();
  const { user } = useAuth();

  const { availableSubstitutees } = useSubstitution();

  return (
    <div
      className={css({
        backgroundColor: "white",
        height: isOpen
          ? `${
              USER_NAV.length * 42 +
              ((availableSubstitutees ? availableSubstitutees : []).length > 0
                ? 42
                : 0) +
              48
            }px`
          : "57px",
        overflow: "hidden",
        transition: "all 200ms ease",
        flexShrink: 0,
        position: "sticky",
        top: "66px",
        zIndex: 1,
        borderBottom: "2px solid #f9f9f9",
      })}
    >
      <div
        role="button"
        className={css({
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          paddingTop: theme.sizing.scale400,
          paddingBottom: theme.sizing.scale400,
          paddingLeft: theme.sizing.scale600,
          paddingRight: theme.sizing.scale600,
          ":hover": {
            color: theme.colors.primary,
            backgroundColor: "white",
          },
        })}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div
          className={css({
            position: "relative",
            flexShrink: 0,
            marginRight: theme.sizing.scale400,
          })}
        >
          <BaseAvatar
            name={`${user?.firstName} ${user?.lastName}`}
            size={width < 100 ? "26px" : "scale900"}
            overrides={{
              Root: {
                style: {
                  backgroundColor: stc(`${user?.firstName} ${user?.lastName}`),
                },
              },
              Initials: {
                style: {
                  fontSize: width < 100 ? "11px" : "16px",
                },
              },
            }}
          />

          {!!user?.unreadNotificationCount && (
            <div
              className={css({
                position: "absolute",
                top: width < 100 ? "-6px" : "-3px",
                right: width < 100 ? "-6px" : "-3px",
                borderRadius: "50%",
                backgroundColor: theme.colors.negative,
                flexShrink: 0,
                width: "18px",
                height: "18px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                fontSize: "11px",
                transform: isOpen ? "scale(0)" : "scale(1)",
                transition: "200ms ease",
              })}
            >
              {user?.unreadNotificationCount}
            </div>
          )}
        </div>

        <div
          className={css({
            flexShrink: 1,
            flexGrow: 1,
            overflow: "hidden",
          })}
        >
          <LabelMedium
            $style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >{`${user?.firstName} ${user?.lastName}`}</LabelMedium>
          <LabelSmall
            $style={{
              color: "#aaa",
              marginTop: "0px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "block",
            }}
          >
            {user?.email}
          </LabelSmall>
        </div>
        <Direction
          size={18}
          className={css({ marginLeft: theme.sizing.scale400, flexShrink: 0 })}
        />
      </div>

      {availableSubstitutees && availableSubstitutees.length > 0 && (
        <div
          className={css({
            paddingTop: theme.sizing.scale200,
            paddingBottom: theme.sizing.scale200,
            paddingLeft: theme.sizing.scale600,
            paddingRight: theme.sizing.scale600,
          })}
        >
          <SubstitutionSwitch width={width} />
        </div>
      )}

      <ul
        className={css({
          marginTop: "0px",
          paddingLeft: 0,
          paddingRight: 0,
        })}
      >
        {USER_NAV &&
          USER_NAV.map((item, index) => {
            const isActive =
              item.href.substring(1).length > 0 &&
              location.pathname.includes(item.href.substring(1));

            if (isActive && isOpen === null) setIsOpen(true);

            return (
              <li
                key={`user-nav` + index}
                className={css({
                  display: "flex",
                  position: "relative",
                  height: "22px",
                  alignItems: "center",
                  marginTop: theme.sizing.scale400,
                  marginBottom: theme.sizing.scale400,
                  paddingLeft: theme.sizing.scale600,
                  paddingRight: theme.sizing.scale600,
                  fontWeight: 500,
                  color: isActive
                    ? theme.colors.primary
                    : theme.colors.contentSecondary,
                  ":hover": {
                    color: isActive
                      ? theme.colors.primary
                      : theme.colors.contentPrimary,
                    cursor: "pointer",
                  },
                })}
                onClick={() => history.push(item.href)}
              >
                {width < 100 ? (
                  <Tooltip
                    content={item.label}
                    placement="left"
                    $style={{
                      left: "198px",
                      right: "unset",
                      width: "200px",
                      display: "flex",
                      justifyContent: "flex-start",
                      zIndex: 40,
                    }}
                  >
                    <div
                      className={css({
                        marginRight: "10px",
                        height: "20px",
                        marginLeft: "2px",
                        position: "relative",
                      })}
                    >
                      <item.icon size={20} />

                      {item.label === "Powiadomienia" &&
                        !!user?.unreadNotificationCount && (
                          <div
                            className={css({
                              position: "absolute",
                              top: "-5px",
                              right: "-5px",
                              borderRadius: "50%",
                              backgroundColor: theme.colors.negative,
                              flexShrink: 0,
                              width: "15px",
                              height: "15px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              color: "white",
                              fontSize: "9px",
                              transform: isOpen ? "scale(1)" : "scale(0)",
                              transition: "200ms ease",
                            })}
                          >
                            {user?.unreadNotificationCount}
                          </div>
                        )}
                    </div>
                  </Tooltip>
                ) : (
                  <Fragment>
                    <div
                      className={css({ marginRight: "10px", height: "20px" })}
                    >
                      <item.icon size={22} />
                    </div>

                    {item.label}

                    {item.label === "Powiadomienia" &&
                      !!user?.unreadNotificationCount && (
                        <div
                          className={css({
                            marginLeft: "auto",
                            borderRadius: "50%",
                            backgroundColor: theme.colors.negative,
                            flexShrink: 0,
                            width: "18px",
                            height: "18px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "white",
                            fontSize: "11px",
                            transform: isOpen ? "scale(1)" : "scale(0)",
                            transition: "200ms ease",
                          })}
                        >
                          {user?.unreadNotificationCount}
                        </div>
                      )}
                  </Fragment>
                )}

                {isActive && (
                  <span
                    className={css({
                      position: "absolute",
                      zIndex: 2,
                      right: "0px",
                      height: "100%",
                      width: "2px",
                      backgroundColor: theme.colors.primary,
                    })}
                  ></span>
                )}
              </li>
            );
          })}
      </ul>
    </div>
  );
}

type SideNavElementProps = {
  children?: React.ReactChild;
  isActive?: boolean;
  width: number;
  label: string;
  isSubmenu?: boolean;
  hasSubmenu?: boolean;
  indicator?: string;
};

function SideNavElement({
  children,
  isActive,
  width,
  label,
  isSubmenu,
  hasSubmenu,
  indicator,
}: SideNavElementProps): React.ReactElement {
  const [css, theme] = useStyletron();
  const { newIndicator } = useAuth();

  return (
    <li
      className={css({
        display: "flex",
        position: "relative",
        height: "22px",
        alignItems: "center",
        marginTop: theme.sizing.scale400,
        marginBottom: theme.sizing.scale400,
        paddingLeft: theme.sizing.scale600,
        paddingRight: theme.sizing.scale600,
        fontWeight: 500,
        color: isActive ? theme.colors.primary : theme.colors.contentSecondary,
        ":hover": {
          color: isActive ? theme.colors.primary : theme.colors.contentPrimary,
          cursor: "pointer",
        },
      })}
    >
      {width < 100 && !isSubmenu ? (
        <Tooltip
          content={label}
          placement="left"
          $style={{
            left: "198px",
            right: "unset",
            width: "200px",
            display: "flex",
            justifyContent: "flex-start",
            zIndex: 40,
          }}
        >
          <div
            className={css({
              marginRight: "10px",
              height: "20px",
              marginLeft: "2px",
            })}
          >
            {hasSubmenu ? (
              <Block
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                {children}
                <ChevronRight size={14} />
              </Block>
            ) : (
              children
            )}

            {indicator === "new" && !!newIndicator && newIndicator > 0 && (
              <div
                className={css({
                  position: "absolute",
                  top: "-4px",
                  right: "16px",
                  borderRadius: "50%",
                  backgroundColor: theme.colors.negative,
                  flexShrink: 0,
                  width: "15px",
                  height: "15px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                  fontSize: "9px",
                  transition: "200ms ease",
                  ":empty": {
                    display: "none",
                  },
                })}
              >
                {newIndicator}
              </div>
            )}
          </div>
        </Tooltip>
      ) : (
        <Fragment>
          <div
            className={css({
              marginRight: "10px",
              height: "20px",
            })}
          >
            {children}
          </div>

          <div
            className={css({
              width: "100%",
              lineHeight: "16px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              minWidth: "0",
            })}
          >
            <span
              className={css({
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
              })}
            >
              {label}
            </span>

            {hasSubmenu && <ChevronRight size={12} />}

            {indicator === "new" && !!newIndicator && newIndicator > 0 && (
              <div
                className={css({
                  marginLeft: "auto",
                  borderRadius: "50%",
                  backgroundColor: theme.colors.negative,
                  flexShrink: 0,
                  width: "18px",
                  height: "18px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                  fontSize: "11px",
                  transition: "200ms ease",
                  ":empty": {
                    display: "none",
                  },
                })}
              >
                {newIndicator}
              </div>
            )}
          </div>
        </Fragment>
      )}

      {isActive && !isSubmenu && (
        <span
          className={css({
            position: "absolute",
            right: "0px",
            height: "100%",
            width: "2px",
            backgroundColor: theme.colors.primary,
          })}
        ></span>
      )}
    </li>
  );
}

type SideNavProps = {
  width: number;
};

export default function SideNav({ width }: SideNavProps): React.ReactElement {
  const [css, theme] = useStyletron();

  const history = useHistory();
  const location = useLocation();
  const { openSupportModal } = useSupport();
  const { user } = useAuth();
  const hasOrganizationalUnit = !!user?.organizationalUnitToUsers?.length;
  const { isSubstitutionActive, substitution } = useSubstitution();
  const [permissions, setPermissions] = useState(user?.permissions);

  useEffect(() => {
    setPermissions(
      isSubstitutionActive
        ? substitution?.substitutee?.permissions
        : user?.permissions
    );
  }, [isSubstitutionActive, substitution]);

  const permissionsList = permissions?.map(
    (permission) => permission.systemName
  );

  const systemVersion = process.env.REACT_APP_SYSTEM_VERSION;

  return (
    <Container>
      <StyledLink
        onClick={(event: MouseEvent) => {
          event.preventDefault();
          history.push("/");
        }}
        href={"/"}
        $style={{
          textDecoration: "none",
          position: "sticky",
          top: "0px",
          zIndex: 1,
        }}
      >
        <HeadingSmall
          paddingTop="scale600"
          paddingBottom="scale600"
          paddingLeft="scale600"
          paddingRight="scale600"
          marginTop="0px"
          marginBottom="0px"
          position="sticky"
          backgroundColor="#f9f9f9"
          top="0px"
          $style={{
            height: "66px",
            fontWeight: 700,
            display: "flex",
            alignItems: "center",
            zIndex: 1,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            ...(width < 100 && { fontSize: "14px" }),
          }}
        >
          {width > 100 ? APP_NAME : "EZD"}

          {systemVersion !== REACT_APP_SYSTEM_VERSIONS.PRODUCTION &&
            (width > 100 ? (
              <Tag
                closeable={false}
                {...(systemVersion === REACT_APP_SYSTEM_VERSIONS.TEST && {
                  kind: "negative",
                })}
                {...(systemVersion === REACT_APP_SYSTEM_VERSIONS.TRAINING && {
                  kind: "positive",
                })}
                {...(!systemVersion && {
                  kind: "warning",
                })}
                variant="solid"
                overrides={{
                  Text: { style: { fontSize: "12px", fontWeight: 600 } },
                  Root: { style: { marginLeft: "8px" } },
                }}
              >
                {systemVersion === REACT_APP_SYSTEM_VERSIONS.TEST && "TEST"}
                {systemVersion === REACT_APP_SYSTEM_VERSIONS.TRAINING &&
                  "SZKOLENIE"}
                {!systemVersion && "DEV"}
              </Tag>
            ) : (
              <div
                className={css({
                  position: "absolute",
                  bottom: "12px",
                  borderRadius: "5px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  marginLeft: "1px",
                  flexShrink: 0,
                  display: "flex",
                  fontSize: "9px",
                  paddingLeft: "3px",
                  paddingRight: "3px",
                  height: "12px",
                  lineHeight: "12px",
                  color: "white",
                  fontWeight: 700,
                  ...(systemVersion === REACT_APP_SYSTEM_VERSIONS.TEST && {
                    backgroundColor: theme.colors.negative,
                  }),
                  ...(systemVersion === REACT_APP_SYSTEM_VERSIONS.TRAINING && {
                    backgroundColor: theme.colors.positive,
                  }),
                  ...(!systemVersion && {
                    backgroundColor: "rgb(255, 192, 67)",
                    color: "rgb(103, 77, 27)",
                  }),
                })}
              >
                {systemVersion === REACT_APP_SYSTEM_VERSIONS.TEST && "TEST"}
                {systemVersion === REACT_APP_SYSTEM_VERSIONS.TRAINING &&
                  "SZKOL."}
                {!systemVersion && "DEV"}
              </div>
            ))}
        </HeadingSmall>
      </StyledLink>

      <UserSummary width={width} />

      {permissionsList?.includes(PERMISSIONS.document.create) &&
        (width > 100 ? (
          <Button
            $as="a"
            href="/documents/create-internal"
            $style={{
              marginTop: "20px",
              marginLeft: "16px",
              marginRight: "16px",
              marginBottom: "12px",
            }}
            startEnhancer={<FilePlus />}
            onClick={(event: MouseEvent) => {
              event.preventDefault();

              !hasOrganizationalUnit
                ? openSupportModal(
                    "Ta sekcja jest niedostępna ponieważ nie należysz do żadnej jednostki organizacyjnej. Zgłoś się do administratora o przypisanie"
                  )
                : history.push("/documents/create-internal");
            }}
          >
            {width > 220 ? "Utwórz dokument" : "Utwórz"}
          </Button>
        ) : (
          <div
            className={css({
              display: "flex",
              position: "relative",
              height: "22px",
              alignItems: "center",
              justifyContent: "center",
              marginTop: theme.sizing.scale800,
              marginBottom: theme.sizing.scale400,
              paddingLeft: theme.sizing.scale200,
              paddingRight: theme.sizing.scale200,
              fontWeight: 500,
            })}
          >
            <Tooltip
              content={"Utwórz dokument"}
              placement="left"
              $style={{
                left: "198px",
                right: "unset",
                width: "200px",
                display: "flex",
                justifyContent: "flex-start",
                zIndex: 40,
              }}
            >
              <a
                href="/documents/create-internal"
                onClick={(event: MouseEvent) => {
                  event.preventDefault();

                  !hasOrganizationalUnit
                    ? openSupportModal(
                        "Ta sekcja jest niedostępna ponieważ nie należysz do żadnej jednostki organizacyjnej. Zgłoś się do administratora o przypisanie"
                      )
                    : history.push("/documents/create-internal");
                }}
                className={css({
                  height: "36px",
                  width: "36px",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: theme.colors.primary,
                  color: theme.colors.white,
                  ":hover": {
                    backgroundColor: theme.colors.contentPrimary,
                    cursor: "pointer",
                  },
                })}
              >
                <FilePlus size={22} />
              </a>
            </Tooltip>
          </div>
        ))}

      {NAV &&
        NAV.filter(
          (item) =>
            item.items.filter(
              (item) =>
                (item.permission &&
                  permissionsList?.includes(item.permission)) ||
                !item.permission
            ).length !== 0
        ).map((item, index) => (
          <div
            key={`nav-category` + index}
            className={css({ marginTop: theme.sizing.scale600 })}
          >
            {item.label &&
              item.items.filter(
                (item) =>
                  (item.permission &&
                    permissionsList?.includes(item.permission)) ||
                  !item.permission
              ).length !== 0 && (
                <LabelSmall
                  color="contentTertiary"
                  marginBottom="scale700"
                  $style={{
                    textTransform: "uppercase",
                    letterSpacing: "0.5px",
                    paddingLeft: width > 100 ? theme.sizing.scale600 : "23px",
                    paddingRight: theme.sizing.scale600,
                    whiteSpace: "nowrap",
                  }}
                >
                  {width > 100 ? item.label : "—"}
                </LabelSmall>
              )}
            <ul
              className={css({
                marginTop: "0px",
                paddingLeft: 0,
                paddingRight: 0,
              })}
            >
              {item.items
                .filter(
                  (item) =>
                    (item.permission &&
                      permissionsList?.includes(item.permission)) ||
                    !item.permission
                )
                .map((item, subindex) => {
                  const isItemActive =
                    (item.href.substring(1).length > 0 &&
                      ((item.exact && location.pathname === item.href) ||
                        (!item.exact &&
                          location.pathname.includes(item.href)))) ||
                    item?.submenu?.some(
                      (subitem) =>
                        subitem.href.substring(1).length > 0 &&
                        ((subitem.exact &&
                          location.pathname === subitem.href) ||
                          (!subitem.exact &&
                            location.pathname.includes(subitem.href)))
                    );
                  {
                    if (item.submenu?.length)
                      return (
                        <SubmenuPopover
                          key={`nav-category` + index + `nav-item` + subindex}
                          placement={PLACEMENT.left}
                          content={({ close }) =>
                            item.submenu?.map((subitem, subindex) => {
                              const isSubitemActive =
                                subitem.href.substring(1).length > 0 &&
                                ((subitem.exact &&
                                  location.pathname === subitem.href) ||
                                  (!subitem.exact &&
                                    location.pathname.includes(subitem.href)));
                              return (
                                <StyledLink
                                  key={
                                    `nav-category` +
                                    index +
                                    `nav-item` +
                                    subindex
                                  }
                                  onClick={(event: MouseEvent) => {
                                    event.preventDefault();
                                    (subitem.href.includes("cases") ||
                                      subitem.href.includes("documents")) &&
                                    !hasOrganizationalUnit
                                      ? openSupportModal(
                                          "Ta sekcja jest niedostępna ponieważ nie należysz do żadnej jednostki organizacyjnej. Zgłoś się do administratora o przypisanie"
                                        )
                                      : history.push(subitem.href);
                                    close();
                                  }}
                                  href={subitem.href}
                                  $style={{ textDecoration: "none" }}
                                >
                                  <SideNavElement
                                    isActive={isSubitemActive}
                                    label={subitem.label}
                                    width={width}
                                    isSubmenu
                                  >
                                    <subitem.icon size={20} />
                                  </SideNavElement>
                                </StyledLink>
                              );
                            })
                          }
                        >
                          <div>
                            <SideNavElement
                              isActive={isItemActive}
                              label={item.label}
                              width={width}
                              hasSubmenu
                            >
                              <item.icon size={20} />
                            </SideNavElement>
                          </div>
                        </SubmenuPopover>
                      );

                    return (
                      <StyledLink
                        key={`nav-category` + index + `nav-item` + subindex}
                        onClick={(event: MouseEvent) => {
                          event.preventDefault();

                          (item.href.includes("cases") ||
                            item.href.includes("documents")) &&
                          !hasOrganizationalUnit
                            ? openSupportModal(
                                "Ta sekcja jest niedostępna ponieważ nie należysz do żadnej jednostki organizacyjnej. Zgłoś się do administratora o przypisanie"
                              )
                            : history.push(item.href);
                        }}
                        href={item.href}
                        $style={{ textDecoration: "none" }}
                      >
                        <SideNavElement
                          isActive={isItemActive}
                          label={item.label}
                          indicator={item.indicator}
                          width={width}
                        >
                          <item.icon size={20} />
                        </SideNavElement>
                      </StyledLink>
                    );
                  }
                })}
            </ul>
          </div>
        ))}

      {width > 100 && (
        <LabelSmall
          marginTop="auto"
          paddingTop={theme.sizing.scale600}
          paddingLeft={theme.sizing.scale600}
          paddingRight={theme.sizing.scale600}
          color="contentInverseTertiary"
        >
          © 2023 • {process.env.REACT_APP_VERSION}
        </LabelSmall>
      )}
    </Container>
  );
}
