import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { CheckboxProps } from "baseui/checkbox";
import { ChangeEvent } from "react";
import React from "react";
import { Controller, UseControllerProps } from "react-hook-form";
import { StyleObject } from "styletron-react";
import { CircleCheck, Cloud, Paperclip } from "tabler-icons-react";

import Button from "./button";

type Props = {
  $style?: StyleObject;
  onChange: (value: boolean) => void;
} & Omit<CheckboxProps, "onChange">;

const CaseConductSwitch = ({
  $style,
  name,
  checked,
  onChange,
  disabled,
  ...rest
}: Props): React.ReactElement => {
  const [css, theme] = useStyletron();

  return (
    <Block
      display="flex"
      backgroundColor="inputBorder"
      overrides={{
        Block: {
          style: {
            borderTopLeftRadius: theme.borders.radius200,
            borderTopRightRadius: theme.borders.radius200,
            borderBottomRightRadius: theme.borders.radius200,
            borderBottomLeftRadius: theme.borders.radius200,
            ...(disabled && {
              pointerEvents: "none",
              opacity: 0.8,
            }),
          },
        },
      }}
    >
      <Button
        type="button"
        startEnhancer={<Cloud color={theme.colors.positive} size={15} />}
        size="mini"
        kind={!checked ? "secondary" : "tertiary"}
        $style={{ flexGrow: 1, flexShrink: 0, width: "50%" }}
        onClick={() => onChange(true)}
        {...(checked && {
          endEnhancer: <CircleCheck color={theme.colors.black} size={12} />,
        })}
      >
        Elektronicznie
      </Button>

      <Button
        type="button"
        startEnhancer={<Paperclip color={theme.colors.negative} size={15} />}
        size="mini"
        kind={checked ? "secondary" : "tertiary"}
        $style={{ flexGrow: 1, flexShrink: 0, width: "50%" }}
        onClick={() => onChange(false)}
        {...(!checked && {
          endEnhancer: <CircleCheck color={theme.colors.black} size={12} />,
        })}
      >
        Tradycyjnie
      </Button>
    </Block>
  );
};

export default CaseConductSwitch;

export function ControlledCaseConductSwitch({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & Omit<Props, "onChange">): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <CaseConductSwitch
          onChange={onChange}
          onBlur={onBlur}
          checked={value}
          name={name}
          {...rest}
        />
      )}
    />
  );
}
