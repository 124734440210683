import { useStyletron } from "baseui";
import { KIND } from "baseui/button";
import { ModalBody, ModalFooter, ModalHeader } from "baseui/modal";
import React from "react";
import { AlertTriangle } from "tabler-icons-react";

import Button from "../components/button";
import Modal from "../components/modal";

type SendingListErrorModalProps = {
  label?: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export default function SendingListErrorModal({
  label,
  isOpen,
  setIsOpen,
}: SendingListErrorModalProps): React.ReactElement {
  const [css, theme] = useStyletron();
  return (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <ModalHeader>
        <div
          className={css({
            display: "flex",
            alignItems: "center",
            gap: "10px",
          })}
        >
          <AlertTriangle color={theme.colors.negative} />
          <span>Wystąpił błąd</span>
        </div>
      </ModalHeader>
      <ModalBody>
        <span className={css({ whiteSpace: "pre-line" })}>
          {label || "Proszę spróbować później"}
        </span>
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          kind={KIND.secondary}
          $style={{ marginRight: "10px" }}
          onClick={() => {
            setIsOpen(false);
          }}
        >
          Zamknij
        </Button>
      </ModalFooter>
    </Modal>
  );
}
