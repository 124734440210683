export type InterfacePreferences = {
  columns?: {
    [pathname: string]: string[] | undefined;
  };
  columnWidth?: {
    [pathname: string]:
      | {
          [columnId: string]: string | undefined;
        }
      | undefined;
  };
  columnOrder?: {
    [pathname: string]: string[] | undefined;
  };
  pageSize?: number;
};

export function getPreferencedColumns(
  preferences: JSON | undefined,
  pathname: string
): string[] | undefined {
  const preferencesObject =
    ((preferences as unknown) as InterfacePreferences) || {};

  return preferencesObject?.columns?.[pathname];
}

export function setPreferencedColumns(
  preferences: JSON | undefined,
  pathname: string,
  columns: string[] | undefined
): InterfacePreferences {
  const preferencesObject =
    ((preferences as unknown) as InterfacePreferences) || {};

  return {
    ...preferencesObject,
    columns: {
      ...preferencesObject.columns,
      [pathname]: columns,
    },
  };
}

export function getPreferencedPageSize(
  preferences: JSON | undefined
): number | undefined {
  const preferencesObject =
    ((preferences as unknown) as InterfacePreferences) || {};

  return preferencesObject?.pageSize;
}

export function setPreferencedPageSize(
  preferences: JSON | undefined,
  pageSize: number | undefined
): InterfacePreferences {
  const preferencesObject =
    ((preferences as unknown) as InterfacePreferences) || {};

  return {
    ...preferencesObject,
    pageSize,
  };
}

export function getPreferencedColumnWidth(
  preferences: JSON | undefined,
  pathname: string,
  columnId: string
): string | undefined {
  const preferencesObject =
    ((preferences as unknown) as InterfacePreferences) || {};

  return preferencesObject?.columnWidth?.[pathname]?.[columnId];
}

export function setPreferencedColumnWidth(
  preferences: JSON | undefined,
  pathname: string,
  columnId: string,
  width: string | undefined
): InterfacePreferences {
  const preferencesObject =
    ((preferences as unknown) as InterfacePreferences) || {};

  return {
    ...preferencesObject,
    columnWidth: {
      ...preferencesObject.columnWidth,
      [pathname]: {
        ...preferencesObject.columnWidth?.[pathname],
        [columnId]: width,
      },
    },
  };
}

export function getPreferencedColumnOrder(
  preferences: JSON | undefined,
  pathname: string
): string[] | undefined {
  const preferencesObject =
    ((preferences as unknown) as InterfacePreferences) || {};

  return preferencesObject?.columnOrder?.[pathname];
}

export function setPreferencedColumnOrder(
  preferences: JSON | undefined,
  pathname: string,
  columns: string[] | undefined
): InterfacePreferences {
  const preferencesObject =
    ((preferences as unknown) as InterfacePreferences) || {};

  return {
    ...preferencesObject,
    columnOrder: {
      ...preferencesObject.columnOrder,
      [pathname]: columns,
    },
  };
}

export function resetPreferences(
  preferences: JSON | undefined,
  pathname: string
): InterfacePreferences {
  const preferencesObject =
    ((preferences as unknown) as InterfacePreferences) || {};

  return {
    ...preferencesObject,
    columns: {
      ...preferencesObject.columns,
      [pathname]: undefined,
    },
    columnWidth: {
      ...preferencesObject.columnWidth,
      [pathname]: undefined,
    },
    columnOrder: {
      ...preferencesObject.columnOrder,
      [pathname]: undefined,
    },
  };
}
