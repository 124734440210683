import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { StyledLink } from "baseui/link";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { MouseEvent, useEffect, useMemo, useState } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { Row } from "react-table";
import { Cloud, Eye, Paperclip, Pencil, Trash } from "tabler-icons-react";

import Button from "../../../components/button";
import Cell from "../../../components/cell";
import ConfirmDialog from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import FormControl from "../../../components/form-control";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import Table from "../../../components/table";
import { useLoading } from "../../../contexts/loading-context";
import { checkPermission } from "../../../utils/check-permission";
import { PERMISSIONS } from "../../../utils/permissions";
import { JrwaClassification } from "../jrwa-classifications";
import { JRWA_CLASSIFICATIONS_FIELDS } from "../jrwa-classifications.form";
import {
  JRWA_CLASSIFICATIONS_DELETE,
  JRWA_CLASSIFICATIONS_SHOW,
} from "../jrwa-classifications.gql";

export default function JrwaClassificationsShow(): React.ReactElement {
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [css, theme] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const { setIsLoading, isFetching, setIsFetching } = useLoading();

  const { refetch, data, error } = useQuery(JRWA_CLASSIFICATIONS_SHOW, {
    variables: { id: id ? parseInt(id) : null },
  });
  const [deleteJrwa] = useMutation(JRWA_CLASSIFICATIONS_DELETE);

  const submit = async () => {
    setIsLoading(true);

    try {
      await deleteJrwa({
        variables: {
          id: id ? parseInt(id) : null,
        },
      });

      enqueueSnackbar({
        message: "Usunięto pomyślnie",
        variant: "success",
      });

      setIsConfirmDialogOpen(false);

      history.push("/jrwa-classifications");
    } catch (error: unknown) {
      setIsConfirmDialogOpen(false);

      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    if (data?.jrwaClassification) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => data?.jrwaClassification && setIsFetching(false), [data]);

  const columns = useMemo(
    () => [
      {
        Header: "Symbol",
        accessor: "symbol",
      },
      {
        Header: "Nazwa",
        id: "name",
        Cell: ({ row }: { row: Row<JrwaClassification> }) => (
          <>
            <StyledLink
              onClick={(event: MouseEvent) => {
                event.preventDefault();
                history.push(`/jrwa-classifications/${row.original.id}`);
              }}
              href={`/jrwa-classifications/${row.original.id}`}
            >
              {row.original.isCaseConductedElectronically ? (
                <Cloud
                  size={14}
                  className={css({
                    verticalAlign: "middle",
                    marginRight: "10px",
                    marginTop: "-2px",
                    display: "inline",
                    color: theme.colors.positive,
                  })}
                />
              ) : (
                <Paperclip
                  size={14}
                  className={css({
                    verticalAlign: "middle",
                    marginRight: "10px",
                    marginTop: "-2px",
                    display: "inline",
                    color: theme.colors.negative,
                  })}
                />
              )}
              {row.original.name}
            </StyledLink>
          </>
        ),
      },
      {
        id: "actions",
        Cell: ({ row }: { row: Row<JrwaClassification> }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            {checkPermission(PERMISSIONS.jrwa.update) && (
              <Button
                kind={KIND.secondary}
                size={SIZE.mini}
                onClick={() =>
                  history.push(`/jrwa-classifications/${row.original.id}/edit`)
                }
                startEnhancer={<Pencil size={14} />}
              />
            )}
            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              $style={{ marginLeft: "6px" }}
              onClick={() =>
                history.push(`/jrwa-classifications/${row.original.id}`)
              }
              startEnhancer={<Eye size={14} />}
            />
          </div>
        ),
      },
    ],
    []
  );

  if (!checkPermission(PERMISSIONS.jrwa.read)) return <Redirect to="/" />;

  return (
    <article>
      <Header
        title={data?.jrwaClassification?.name}
        labels={["Klasyfikacja JRWA", "Wyświetlanie"]}
        goBackOption
        buttons={[
          {
            label: "Edytuj",
            kind: KIND.secondary,
            startEnhancer: <Pencil size={18} />,
            permission: checkPermission(PERMISSIONS.jrwa.update),
            onClick: () => history.push(`/jrwa-classifications/${id}/edit`),
          },
        ]}
        actions={[
          {
            label: "Usuń wpis",
            icon: Trash,
            color: theme.colors.negative,
            onClick: () => setIsConfirmDialogOpen(true),
            permission: checkPermission(PERMISSIONS.jrwa.delete),
          },
        ]}
      />
      <Content>
        <Grid>
          {JRWA_CLASSIFICATIONS_FIELDS.filter(
            (g) => g.fields.filter((f) => f.show.visible).length > 0
          ).map((group) => [
            group.label && (
              <Cell key={group.id + `-group`} span={12}>
                <LabelMedium marginBottom="scale200" marginTop="scale600">
                  {group.label}
                </LabelMedium>
                <hr
                  className={css({
                    borderWidth: "0px",
                    height: "1px",
                    backgroundColor: "#eee",
                  })}
                />
              </Cell>
            ),
            group.fields
              .filter((f) => f.show.visible)
              .map((item, index) => (
                <Cell span={item.span || 6} key={group.id + `-field` + index}>
                  <FormControl label={item.label} disabled={true}>
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : (
                      <FormattedValue
                        dataType={item.dataType}
                        data={data?.jrwaClassification[item.id]}
                      >
                        {item.show.accessor
                          ? data?.jrwaClassification[item.show.accessor[0]]?.[
                              item.show.accessor[1]
                            ]
                          : data?.jrwaClassification[item.id]}
                      </FormattedValue>
                    )}
                  </FormControl>
                </Cell>
              )),
          ])}
          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Relacje – Wpisy podrzędne
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={12}>
            {isFetching ? (
              <Skeleton rows={0} height="200px" width="100%" animation />
            ) : (
              data?.jrwaClassification?.children && (
                <Block paddingTop="scale200" paddingBottom="scale600">
                  <Table<JrwaClassification>
                    columns={columns}
                    data={data?.jrwaClassification?.children}
                  />
                </Block>
              )
            )}
          </Cell>
          <ConfirmDialog
            isOpen={isConfirmDialogOpen}
            label={`Usunięcie wpisu ${data?.jrwaClassification?.name}`}
            close={() => setIsConfirmDialogOpen(false)}
            confirm={submit}
          />
        </Grid>
      </Content>
    </article>
  );
}
