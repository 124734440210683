import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { LabelSmall, MonoLabelXSmall } from "baseui/typography";
import React, { useMemo } from "react";
import { Row } from "react-table";

import { EUR } from "../constants";
import {
  FinancialAccountingDocumentItem,
  RequestFormItem,
} from "../containers/Documents/documents";
import { useEuroRate } from "../contexts/euro-rate-context";
import FormattedValue from "./formatted-value";
import Table from "./table";
import Tooltip from "./tooltip";

type RequestFormItemsTableProps = {
  requestFormItems: RequestFormItem[];
  reloadPage: () => void;
};

export default function RequestFormItemsTable({
  requestFormItems,
}: RequestFormItemsTableProps): React.ReactElement {
  const [css, theme] = useStyletron();

  const requestFormItemsSum = requestFormItems?.reduce(
    (a: number, b: RequestFormItem) => a + (b.orderValue ? b.orderValue : 0),
    0
  );

  const { euroRateFromRegulation } = useEuroRate();

  const columns = useMemo(
    () => [
      {
        id: "budgetYear",
        Header: "Rok",
        Cell: ({ row }: { row: Row<RequestFormItem> }) => (
          <>
            <FormattedValue>{row?.original?.year}</FormattedValue>

            {!!row?.original?.financialAccountingDocumentItems?.length && (
              <Block
                height={`${
                  80 +
                  row?.original?.financialAccountingDocumentItems?.length * 25
                }px`}
              />
            )}
          </>
        ),
      },
      {
        id: "isProjectBudget",
        Header: "Budżet projektowy",
        Cell: ({ row }: { row: Row<RequestFormItem> }) => (
          <>
            <FormattedValue dataType="boolean">
              {!!row?.original?.isProjectBudget}
            </FormattedValue>

            {!!row?.original?.financialAccountingDocumentItems?.length && (
              <>
                <Block
                  key={"invoice-item-name-header"}
                  marginLeft="12px"
                  marginTop="12px"
                >
                  <span
                    className={css({
                      fontSize: "13px",
                      fontWeight: 500,
                      textUnderlineOffset: "2px",
                      textDecorationLine: "underline",
                    })}
                  >
                    Pozycja z dokumentu F-K
                  </span>
                </Block>
                {row?.original?.financialAccountingDocumentItems?.map(
                  (item: FinancialAccountingDocumentItem, index: number) => (
                    <>
                      <Block
                        key={`invoice-item-${item.id}-name`}
                        display="flex"
                        marginLeft="12px"
                        marginTop="11px"
                      >
                        <span className={css({ fontSize: "13px" })}>{`${
                          index + 1
                        }. ${item?.name}`}</span>
                      </Block>
                      <hr
                        className={css({
                          margin: "2px 0px 0px 0px",
                          borderWidth: "0px",
                          height: "1px",
                          backgroundColor: "#eee",
                        })}
                      />
                    </>
                  )
                )}
                <Block height="4px" marginTop="9px" />
              </>
            )}
          </>
        ),
      },
      {
        id: "budgetSymbol",
        Header: "Symbol budżetowy",
        Cell: ({ row }: { row: Row<RequestFormItem> }) => (
          <>
            <FormattedValue>{row.original?.budgetSymbol}</FormattedValue>

            {!!row?.original?.financialAccountingDocumentItems?.length && (
              <>
                <Block marginTop="12px">
                  <span
                    className={css({
                      fontSize: "13px",
                      fontWeight: 500,
                      textUnderlineOffset: "2px",
                      textDecorationLine: "underline",
                    })}
                  >
                    Dokument F-K
                  </span>
                </Block>
                {row?.original?.financialAccountingDocumentItems?.map(
                  (item: FinancialAccountingDocumentItem) => (
                    <>
                      <Block
                        key={`invoice-item-${item.id}-invoice`}
                        marginTop="10px"
                      >
                        <FormattedValue
                          dataType="model:documents"
                          data={`incoming/FinancialAccountingDocument/${item.financialAccountingDocumentId}`}
                          $style={{ fontSize: "13px" }}
                        >
                          {item.financialAccountingDocument?.internalNumber}
                        </FormattedValue>
                      </Block>
                      <hr
                        className={css({
                          margin: "2px 0px 0px 0px",
                          borderWidth: "0px",
                          height: "1px",
                          backgroundColor: "#eee",
                        })}
                      />
                    </>
                  )
                )}
                <Block height="4px" marginTop="9px" />
              </>
            )}
          </>
        ),
      },
      {
        id: "categoryPath",
        Header: "Kategoria finansowania",
        Cell: ({ row }: { row: Row<RequestFormItem> }) => {
          const calculatedItems = row?.original?.financialAccountingDocumentItems?.map(
            (item: FinancialAccountingDocumentItem) => {
              const currencyCode =
                item?.financialAccountingDocument?.currency?.code;

              const exchangeRate =
                item?.financialAccountingDocument?.currencyExchangeRate?.value;

              if (currencyCode === undefined)
                return {
                  totalAmount: item.totalAmount,
                };
              else if (currencyCode === EUR)
                return {
                  totalAmount:
                    euroRateFromRegulation &&
                    item.totalAmount * euroRateFromRegulation,
                };
              else
                return {
                  totalAmount: exchangeRate && item.totalAmount * exchangeRate,
                };
            }
          );

          const netValueSum = calculatedItems
            ? calculatedItems.reduce(
                (a: number, b: { totalAmount?: number }) =>
                  a + (b.totalAmount || 0),
                0
              )
            : 0;

          return (
            <>
              {row?.original?.categoryPath && (
                <Block
                  display="flex"
                  justifyContent="flex-start"
                  flexDirection="column"
                  overrides={{
                    Block: {
                      style: {
                        whiteSpace: "nowrap",
                        minWidth: "150px",
                        ...(!!row?.original?.financialAccountingDocumentItems
                          ?.length && {
                          transform: "translateY(20px)",
                        }),
                      },
                    },
                  }}
                >
                  <MonoLabelXSmall>
                    {row?.original?.categoryPath}
                  </MonoLabelXSmall>
                  <LabelSmall>{row?.original?.categoryName}</LabelSmall>
                </Block>
              )}

              {!row?.original?.categoryPath && (
                <Block
                  display="flex"
                  justifyContent="flex-start"
                  flexDirection="column"
                  overrides={{
                    Block: {
                      style: {
                        whiteSpace: "nowrap",
                        minWidth: "150px",
                        ...(!!row?.original?.financialAccountingDocumentItems
                          ?.length && {
                          transform: "translateY(20px)",
                        }),
                      },
                    },
                  }}
                >
                  <FormattedValue />
                </Block>
              )}

              {!!row?.original?.financialAccountingDocumentItems?.length && (
                <>
                  <Block
                    display="flex"
                    justifyContent="flex-end"
                    paddingTop="30px"
                  >
                    <span
                      className={css({
                        fontSize: "13px",
                        fontWeight: 500,
                        textUnderlineOffset: "2px",
                        textDecorationLine: "underline",
                      })}
                    >
                      Kwota netto
                    </span>
                  </Block>

                  {row?.original?.financialAccountingDocumentItems?.map(
                    (item: FinancialAccountingDocumentItem) => {
                      const currency =
                        item?.financialAccountingDocument?.currency?.code;

                      const exchangeRate =
                        item?.financialAccountingDocument?.currencyExchangeRate
                          ?.value;

                      const renderTotalAmount = (totalAmount: number) => {
                        switch (currency) {
                          case undefined:
                            return totalAmount;
                          case EUR:
                            return (
                              euroRateFromRegulation &&
                              totalAmount * euroRateFromRegulation
                            );
                          default:
                            return exchangeRate && totalAmount * exchangeRate;
                        }
                      };

                      return (
                        <>
                          <Block
                            key={`invoice-item-${item.id}-total-amount`}
                            display="flex"
                            justifyContent="flex-end"
                            marginTop="10px"
                          >
                            <FormattedValue
                              dataType="quota"
                              $style={{ fontSize: "13px" }}
                            >
                              {renderTotalAmount(item?.totalAmount)}
                            </FormattedValue>
                          </Block>
                          <hr
                            className={css({
                              margin: "2px 0px 0px 0px",
                              borderWidth: "0px",
                              height: "1px",
                              backgroundColor: "#eee",
                            })}
                          />
                        </>
                      );
                    }
                  )}
                  <Block
                    key={"invoice-item-total-amount-header"}
                    display="flex"
                    justifyContent="flex-end"
                    marginTop="10px"
                  >
                    <span
                      className={css({
                        fontSize: "13px",
                        fontWeight: 500,
                      })}
                    >
                      Suma:
                    </span>
                    <FormattedValue
                      dataType="quota"
                      currency="PLN"
                      $style={{
                        fontSize: "13px",
                        fontWeight: 600,
                        marginLeft: "2px",
                      }}
                    >
                      {netValueSum}
                    </FormattedValue>
                  </Block>
                </>
              )}
            </>
          );
        },
      },
      {
        id: "budgetName",
        Header: "Źródło finansowania",
        Cell: ({ row }: { row: Row<RequestFormItem> }) => {
          const calculatedItems = row?.original?.financialAccountingDocumentItems?.map(
            (item: FinancialAccountingDocumentItem) => {
              const currencyCode =
                item?.financialAccountingDocument?.currency?.code;

              const exchangeRate =
                item?.financialAccountingDocument?.currencyExchangeRate?.value;

              if (currencyCode === undefined)
                return {
                  totalAmountWithTax: item.totalAmountWithTax,
                };
              else if (currencyCode === EUR)
                return {
                  totalAmountWithTax:
                    euroRateFromRegulation &&
                    item.totalAmountWithTax * euroRateFromRegulation,
                };
              else
                return {
                  totalAmountWithTax:
                    exchangeRate && item.totalAmountWithTax * exchangeRate,
                };
            }
          );

          const grossValueSum = calculatedItems
            ? calculatedItems.reduce(
                (a: number, b: { totalAmountWithTax?: number }) =>
                  a + (b.totalAmountWithTax || 0),
                0
              )
            : 0;

          return (
            <Block flexDirection="column" display="flex">
              <Block
                justifyContent="flex-start"
                display="flex"
                overrides={{
                  Block: {
                    style: {
                      whiteSpace: "nowrap",
                      minWidth: "150px",
                      ...(!!row?.original?.financialAccountingDocumentItems
                        ?.length && {
                        transform: "translateY(20px)",
                      }),
                    },
                  },
                }}
              >
                <FormattedValue>{row?.original?.budgetName}</FormattedValue>
              </Block>

              {!!row?.original?.financialAccountingDocumentItems?.length && (
                <>
                  <Block
                    display="flex"
                    justifyContent="flex-end"
                    marginTop="30px"
                  >
                    <span
                      className={css({
                        fontSize: "13px",
                        fontWeight: 500,
                        textUnderlineOffset: "2px",
                        textDecorationLine: "underline",
                      })}
                    >
                      Kwota brutto
                    </span>
                  </Block>

                  {row?.original?.financialAccountingDocumentItems?.map(
                    (item: FinancialAccountingDocumentItem) => {
                      const currency =
                        item?.financialAccountingDocument?.currency?.code;

                      const exchangeRate =
                        item?.financialAccountingDocument?.currencyExchangeRate
                          ?.value;

                      const renderTotalAmountWithTax = (
                        totalAmountWithTax: number
                      ) => {
                        switch (currency) {
                          case undefined:
                            return totalAmountWithTax;
                          case EUR:
                            return (
                              euroRateFromRegulation &&
                              totalAmountWithTax * euroRateFromRegulation
                            );
                          default:
                            return (
                              exchangeRate && totalAmountWithTax * exchangeRate
                            );
                        }
                      };

                      return (
                        <>
                          <Block
                            key={`invoice-item-${item.id}-total-amount`}
                            display="flex"
                            justifyContent="flex-end"
                            marginTop="10px"
                          >
                            <FormattedValue
                              dataType="quota"
                              $style={{ fontSize: "13px" }}
                            >
                              {renderTotalAmountWithTax(
                                item?.totalAmountWithTax
                              )}
                            </FormattedValue>
                          </Block>
                          <hr
                            className={css({
                              margin: "2px 0px 0px 0px",
                              borderWidth: "0px",
                              height: "1px",
                              backgroundColor: "#eee",
                            })}
                          />
                        </>
                      );
                    }
                  )}
                  <Block
                    key={"invoice-item-total-amount-header"}
                    display="flex"
                    justifyContent="flex-end"
                    marginTop="10px"
                  >
                    <span
                      className={css({
                        fontSize: "13px",
                        fontWeight: 500,
                      })}
                    >
                      Suma:
                    </span>
                    <FormattedValue
                      dataType="quota"
                      currency="PLN"
                      $style={{
                        fontSize: "13px",
                        fontWeight: 600,
                        marginLeft: "2px",
                      }}
                    >
                      {grossValueSum}
                    </FormattedValue>
                  </Block>
                </>
              )}
            </Block>
          );
        },
      },
      {
        accessor: "amount",
        Header: (
          <Block justifyContent="flex-end" display="flex">
            Wartość
          </Block>
        ),
        Cell: ({ row }: { row: Row<RequestFormItem> }) => {
          return (
            <Block flexDirection="column" display="flex">
              <Block
                justifyContent="flex-end"
                display="flex"
                overrides={{
                  Block: {
                    style: {
                      whiteSpace: "nowrap",
                      minWidth: "150px",
                      ...(!!row?.original?.financialAccountingDocumentItems
                        ?.length && {
                        transform: "translateY(20px)",
                      }),
                    },
                  },
                }}
              >
                <FormattedValue dataType="quota">
                  {row?.original?.amount}
                </FormattedValue>
              </Block>
              {!!row?.original?.financialAccountingDocumentItems?.length && (
                <>
                  <Block
                    display="flex"
                    justifyContent="flex-end"
                    marginTop="32px"
                  >
                    <span
                      className={css({
                        fontSize: "13px",
                        fontWeight: 500,
                        textUnderlineOffset: "2px",
                        textDecorationLine: "underline",
                      })}
                    >
                      Pozostało
                    </span>
                  </Block>
                  {row?.original?.financialAccountingDocumentItems?.map(
                    (item: FinancialAccountingDocumentItem, index: number) => {
                      const valueToSubtract = row?.original
                        ?.financialAccountingDocumentItems?.length
                        ? row?.original?.financialAccountingDocumentItems
                            ?.slice(0, index + 1)
                            ?.reduce(
                              (a: number, b: FinancialAccountingDocumentItem) =>
                                a + (b?.totalAmountWithTax || 0),
                              0
                            )
                        : 0;
                      return (
                        <>
                          <Block
                            key={`invoice-item-${item.id}-total-amount`}
                            display="flex"
                            justifyContent="flex-end"
                            marginTop="10px"
                          >
                            <FormattedValue
                              dataType="quota"
                              $style={{ fontSize: "13px" }}
                            >
                              {row?.original?.orderValue - valueToSubtract}
                            </FormattedValue>
                          </Block>
                          <hr
                            className={css({
                              margin: "2px 0px 0px 0px",
                              borderWidth: "0px",
                              height: "1px",
                              backgroundColor: "#eee",
                            })}
                          />
                        </>
                      );
                    }
                  )}
                  <Block height="20px" marginTop="10px" />
                </>
              )}
            </Block>
          );
        },
      },
      {
        accessor: "requestedExpenditure",
        Header: () => (
          <Block justifyContent="flex-end" display="flex">
            <Tooltip
              content="Wnioskowane wykorzystanie środków"
              placement="bottom"
            >
              <u
                className={css({
                  textDecorationStyle: "dotted",
                  textUnderlineOffset: "2px",
                })}
              >
                Wnioskowane wyk. środ.
              </u>
            </Tooltip>
          </Block>
        ),
        Cell: ({ row }: { row: Row<RequestFormItem> }) => (
          <>
            <Block
              justifyContent="flex-end"
              display="flex"
              overrides={{
                Block: {
                  style: {
                    whiteSpace: "nowrap",
                    minWidth: "200px",
                  },
                },
              }}
            >
              <FormattedValue dataType="quota">
                {row?.original?.requestedExpenditure}
              </FormattedValue>
            </Block>
            {!!row?.original?.financialAccountingDocumentItems?.length && (
              <Block
                height={`${
                  75 +
                  row?.original?.financialAccountingDocumentItems?.length * 25
                }px`}
              />
            )}
          </>
        ),
      },
      {
        accessor: "realExpenditure",
        Header: () => (
          <Block justifyContent="flex-end" display="flex">
            <Tooltip
              content="Faktyczne wykorzystanie środków"
              placement="bottom"
            >
              <u
                className={css({
                  textDecorationStyle: "dotted",
                  textUnderlineOffset: "2px",
                })}
              >
                Faktyczne wyk. środ.
              </u>
            </Tooltip>
          </Block>
        ),
        Cell: ({ row }: { row: Row<RequestFormItem> }) => (
          <>
            <Block
              justifyContent="flex-end"
              display="flex"
              alignItems="center"
              overrides={{
                Block: { style: { whiteSpace: "nowrap", minWidth: "200px" } },
              }}
            >
              <FormattedValue dataType="quota">
                {row?.original?.realExpenditure}
              </FormattedValue>
            </Block>

            {!!row?.original?.financialAccountingDocumentItems?.length && (
              <Block
                height={`${
                  75 +
                  row?.original?.financialAccountingDocumentItems?.length * 25
                }px`}
              />
            )}
          </>
        ),
      },
      {
        accessor: "orderValue",
        Header: (
          <Block justifyContent="flex-end" display="flex">
            Kwota
          </Block>
        ),
        Cell: ({ row }: { row: Row<RequestFormItem> }) => (
          <>
            <Block
              justifyContent="flex-end"
              display="flex"
              overrides={{ Block: { style: { whiteSpace: "nowrap" } } }}
            >
              <FormattedValue dataType="quota">
                {row?.original?.orderValue}
              </FormattedValue>
            </Block>
            {!!row?.original?.financialAccountingDocumentItems?.length && (
              <Block
                height={`${
                  95 +
                  row?.original?.financialAccountingDocumentItems?.length * 25
                }px`}
              />
            )}
          </>
        ),
      },
    ],
    [requestFormItems]
  );

  return (
    <Table<RequestFormItem>
      compact
      scrollable
      columns={columns}
      data={requestFormItems}
      $tableBodyRowStyle={{ borderTop: "1px solid #DDD" }}
      $footer={() => {
        return (
          <>
            <td colSpan={7} />

            <td
              className={css({
                paddingTop: theme.sizing.scale550,
                paddingBottom: theme.sizing.scale550,
                paddingLeft: theme.sizing.scale500,
                paddingRight: theme.sizing.scale500,
                position: "sticky",
                right: 0,
              })}
              colSpan={2}
            >
              <Block
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
              >
                <LabelSmall>
                  Suma kwoty wniosku:{" "}
                  <strong>
                    <FormattedValue dataType="quota">
                      {requestFormItemsSum}
                    </FormattedValue>
                  </strong>
                </LabelSmall>
              </Block>
            </td>
          </>
        );
      }}
    />
  );
}
