import { useAuth } from "../contexts/auth-context";
import { useSubstitution } from "../contexts/substitution-context";

export function checkPermission(
  componentPermission: string
): boolean | undefined {
  const { user } = useAuth();
  const { isSubstitutionActive, substitution } = useSubstitution();

  const permissions = isSubstitutionActive
    ? substitution?.substitutee?.permissions
    : user?.permissions;

  return (
    permissions &&
    permissions.some(
      (permission) => permission.systemName === componentPermission
    )
  );
}
