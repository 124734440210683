import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND } from "baseui/button";
import { StyledLink } from "baseui/link";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium, LabelSmall } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { MouseEvent, useEffect, useMemo, useState } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { Row } from "react-table";
import { ArrowBack, Man, Pencil, Trash } from "tabler-icons-react";

import Cell from "../../../components/cell";
import ConfirmDialog from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import DocumentPickupModal from "../../../components/document-pickup-modal";
import FormControl from "../../../components/form-control";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import Table from "../../../components/table";
import { DOCUMENT_PICKUP_STATES } from "../../../constants";
import { useDictionaries } from "../../../contexts/dictionaries-context";
import { useLoading } from "../../../contexts/loading-context";
import { checkDocumentPermissionToShow } from "../../../utils/check-document-permission";
import { checkPermission } from "../../../utils/check-permission";
import { PERMISSIONS } from "../../../utils/permissions";
import { Document } from "../../Documents/documents";
import { DOCUMENT_PICKUPS_FIELDS } from "../document-pickups.form";
import {
  DOCUMENT_PICKUPS_DELETE,
  DOCUMENT_PICKUPS_SHOW,
  DOCUMENT_PICKUPS_START_PICKUP,
} from "../document-pickups.gql";

export default function DocumentPickupsShow(): React.ReactElement {
  const [css, theme] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState(
    false
  );
  const [
    isPickupConfirmationDialogOpen,
    setIsPickupConfirmationDialogOpen,
  ] = useState(false);
  const [isUserChangingDialogOpen, setIsUserChangingDialogOpen] = useState(
    false
  );
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const { setIsLoading, isFetching, setIsFetching } = useLoading();
  const { findValue, incomingTypes } = useDictionaries();

  const { refetch, data, error: queryError } = useQuery(DOCUMENT_PICKUPS_SHOW, {
    variables: { id: id ? parseInt(id) : null },
  });
  const [deletePickup, { error: deleteMutationError }] = useMutation(
    DOCUMENT_PICKUPS_DELETE
  );
  const [startPickup, { error: startPickupError }] = useMutation(
    DOCUMENT_PICKUPS_START_PICKUP
  );

  const submit = async (action: "delete" | "startPickup") => {
    setIsLoading(true);

    try {
      action === "delete" &&
        (await deletePickup({
          variables: {
            documentPickupDeleteInput: {
              id: id ? parseInt(id) : null,
            },
          },
        }));

      action === "startPickup" &&
        (await startPickup({
          variables: {
            documentPickupStartPickupInput: {
              id: id ? parseInt(id) : null,
            },
          },
        }));

      action === "delete" &&
        enqueueSnackbar({
          message: "Usunięto pomyślnie",
          variant: "success",
        });

      action === "delete" && setIsConfirmDeleteDialogOpen(false);
      action === "delete" && history.push("/document-pickups");
      action === "startPickup" && refetch();
      action === "startPickup" && setIsPickupConfirmationDialogOpen(true);
    } catch (error: unknown) {
      action === "delete" && setIsConfirmDeleteDialogOpen(false);

      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (queryError?.graphQLErrors)
      enqueueSnackbar({
        message: (queryError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [queryError]);

  useEffect(() => {
    if (deleteMutationError?.graphQLErrors)
      enqueueSnackbar({
        message: (deleteMutationError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [deleteMutationError]);

  useEffect(() => {
    if (startPickupError?.graphQLErrors)
      enqueueSnackbar({
        message: (startPickupError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [startPickupError]);

  useEffect(() => {
    if (data?.documentPickup) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => {
    data?.documentPickup && setIsFetching(false);
  }, [data]);

  if (!checkPermission(PERMISSIONS.documentPickup.read))
    return <Redirect to="/" />;

  const documentsColumns = useMemo(
    () => [
      {
        Header: "Lp.",
        id: "ordinalNumber",
        Cell: ({ row }: { row: Row<Document> }) => (
          <FormattedValue>{row?.index + 1}</FormattedValue>
        ),
      },
      {
        Header: "Numer",
        accessor: "internalNumber",
        Cell: ({ row }: { row: Row<Document> }) => {
          const documentKind = row?.original?.documentKind?.toLowerCase();
          const documentTypename = row?.original?.type;
          return (
            <StyledLink
              {...(row?.original?.documentType &&
                (checkDocumentPermissionToShow(row?.original?.documentType) ||
                  checkPermission(PERMISSIONS.document.readAll)) && {
                  onClick: (event: MouseEvent) => {
                    event.preventDefault();
                    history.push(
                      `/documents/${documentKind}/${documentTypename}/${row.original.id}`
                    );
                  },
                  href: `/documents/${documentKind}/${documentTypename}/${row.original.id}`,
                })}
            >
              {row?.original?.internalNumber}
            </StyledLink>
          );
        },
      },
      {
        Header: "Jednostka organizacyjna",
        id: "organizationalUnit",
        Cell: ({ row }: { row: Row<Document> }) => (
          <FormattedValue
            dataType="model:organizational-units"
            data={row?.original?.organizationalUnit?.id}
          >
            {row?.original?.organizationalUnit?.name}
          </FormattedValue>
        ),
      },
      {
        Header: "Rodzaj dokumentu",
        id: "documentKind",
        Cell: ({ row }: { row: Row<Document> }) => (
          <FormattedValue dataType="document-kind">
            {row?.original?.documentKind}
          </FormattedValue>
        ),
      },
      {
        Header: "Typ dokumentu",
        id: "documentType",
        Cell: ({ row }: { row: Row<Document> }) => {
          const documentType = row?.original?.documentType
            ? findValue(row?.original?.documentType)
            : "";
          return <FormattedValue>{documentType as string}</FormattedValue>;
        },
      },
      {
        Header: (
          <Block display="flex" justifyContent="flex-end">
            Data rejestracji
          </Block>
        ),
        accessor: "createdAt",
        Cell: ({ row }: { row: Row<Document> }) => (
          <Block display="flex" justifyContent="flex-end">
            <FormattedValue dataType="date">
              {row?.original?.createdAt}
            </FormattedValue>
          </Block>
        ),
      },
    ],
    [data, incomingTypes]
  );

  return (
    <article>
      <Header
        title={`Wydanie dokumentów numer ${data?.documentPickup?.id}`}
        labels={["Wydania dokumentów", "Wyświetlanie"]}
        goBackOption
        buttons={[
          {
            label: "Edytuj",
            kind: KIND.secondary,
            startEnhancer: <Pencil size={18} />,
            permission:
              checkPermission(PERMISSIONS.documentPickup.update) &&
              data?.documentPickup?.isModifiable,
            onClick: () => history.push(`/document-pickups/${id}/edit`),
          },
          {
            label: "Wydaj pracownikowi",
            kind: KIND.secondary,
            startEnhancer: <Man size={18} />,
            permission:
              checkPermission(PERMISSIONS.documentPickup.manage) &&
              (data?.documentPickup?.state === DOCUMENT_PICKUP_STATES.New ||
                data?.documentPickup?.state ===
                  DOCUMENT_PICKUP_STATES.PickupInProgress),
            onClick: () =>
              data?.documentPickup?.state ===
              DOCUMENT_PICKUP_STATES.PickupInProgress
                ? setIsPickupConfirmationDialogOpen(true)
                : submit("startPickup"),
          },
          {
            label: "Zmień pracownika odbierającego",
            kind: KIND.secondary,
            startEnhancer: <Man size={18} />,
            permission:
              checkPermission(PERMISSIONS.documentPickup.manage) &&
              data?.documentPickup?.state ===
                DOCUMENT_PICKUP_STATES.PickupInProgress,
            onClick: () => setIsUserChangingDialogOpen(true),
          },
        ]}
        actions={[
          {
            label: "Usuń",
            icon: Trash,
            color: theme.colors.negative,
            onClick: () => setIsConfirmDeleteDialogOpen(true),
            permission:
              checkPermission(PERMISSIONS.documentPickup.delete) &&
              data?.documentPickup?.isModifiable,
          },
          {
            label: "Zwrot",
            icon: ArrowBack,
            color: theme.colors.negative,
            onClick: () => history.push(`/document-pickups/${id}/return`),
            permission:
              checkPermission(PERMISSIONS.documentPickup.manage) &&
              data?.documentPickup?.state !== DOCUMENT_PICKUP_STATES.New &&
              data?.documentPickup?.state !==
                DOCUMENT_PICKUP_STATES.PickupInProgress &&
              data?.documentPickup?.documents?.length,
          },
        ]}
      />
      <Content>
        <Grid>
          {DOCUMENT_PICKUPS_FIELDS.filter(
            (g) => g.fields.filter((f) => f.show.visible).length > 0
          ).map((group) => [
            group.label && (
              <Cell key={group.id + `-group`} span={12}>
                <LabelMedium marginBottom="scale200" marginTop="scale600">
                  {group.label}
                </LabelMedium>
                <hr
                  className={css({
                    borderWidth: "0px",
                    height: "1px",
                    backgroundColor: "#eee",
                  })}
                />
              </Cell>
            ),
            group.fields
              .filter((f) => f.show.visible)
              .map((item, index) => (
                <Cell span={item.span || 6} key={group.id + `-field` + index}>
                  <FormControl label={item.label} disabled={true}>
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : (
                      <FormattedValue
                        dataType={item.dataType}
                        data={
                          item.id === "confirmedById"
                            ? data?.documentPickup?.confirmedBy?.id
                            : item.id === "createdById"
                            ? data?.documentPickup?.confirmedBy?.id
                            : data?.documentPickup[item.id]
                        }
                      >
                        {item.id === "userId"
                          ? data?.documentPickup?.user &&
                            `${data?.documentPickup?.user?.firstName} ${data?.documentPickup?.user?.lastName}`
                          : item.id === "confirmedById"
                          ? data?.documentPickup?.confirmedBy &&
                            `${data?.documentPickup?.confirmedBy?.firstName} ${data?.documentPickup?.confirmedBy?.lastName}`
                          : item.id === "createdById"
                          ? data?.documentPickup?.createdById &&
                            `${data?.documentPickup?.createdBy?.firstName} ${data?.documentPickup?.createdBy?.lastName}`
                          : item.show.accessor
                          ? data?.documentPickup[item.show.accessor[0]]?.[
                              item.show.accessor[1]
                            ]
                          : data?.documentPickup[item.id]}
                      </FormattedValue>
                    )}
                  </FormControl>
                </Cell>
              )),
          ])}
          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Dokumenty
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={12} $style={{ position: "relative" }}>
            {isFetching ? (
              <Skeleton rows={0} height="300px" width="100%" animation />
            ) : data?.documentPickup?.documents?.length ? (
              <Block paddingTop="scale200" paddingBottom="scale600">
                <Table<Document>
                  compact
                  columns={documentsColumns}
                  data={data?.documentPickup?.documents}
                />
              </Block>
            ) : (
              <LabelSmall $style={{ color: "red" }}>
                Wszystkie dokumenty zostały zwrócone, lista dostępna w logach
                aktywności
              </LabelSmall>
            )}
          </Cell>
          <ConfirmDialog
            isOpen={isConfirmDeleteDialogOpen}
            label={`Usunięcie odbioru dokumentów numer ${data?.documentPickup?.id}`}
            close={() => setIsConfirmDeleteDialogOpen(false)}
            confirm={() => submit("delete")}
          />

          <DocumentPickupModal
            isOpen={isPickupConfirmationDialogOpen}
            setIsOpen={setIsPickupConfirmationDialogOpen}
            type="confirmPickup"
            pickupId={data?.documentPickup?.id}
            pickupState={data?.documentPickup?.state}
            user={data?.documentPickup?.user}
            reloadPage={refetch}
          />

          <DocumentPickupModal
            isOpen={isUserChangingDialogOpen}
            setIsOpen={setIsUserChangingDialogOpen}
            type="changeUser"
            pickupId={data?.documentPickup?.id}
            reloadPage={refetch}
          />
        </Grid>
      </Content>
    </article>
  );
}
