import { gql } from "@apollo/client";

export const REQUEST_FORM_CREATE = gql`
  mutation(
    $requestFormCreateInput: RequestFormCreateInput!
    $requestFormItemCreateInputs: [RequestFormItemCreateInput!]!
  ) {
    requestFormCreate(
      requestFormCreateInput: $requestFormCreateInput
      requestFormItemCreateInputs: $requestFormItemCreateInputs
    ) {
      id
    }
  }
`;

export const REQUEST_FORM_SHOW = gql`
  query($id: Int!) {
    requestForm(id: $id) {
      id
      internalNumber
      sequenceNumber
      type
      createdAt
      documentNumber
      nrRejestr
      title
      programName
      orderSubject
      isReleasable
      orderType
      caseId
      isEditable
      beginAt
      endAt
      preferedExecutionBeginAt
      preferedExecutionEndAt
      legalAct
      legalDocumentsVerification
      isContractAgreementRequired
      hasDocumentFlowEnded
      state
      controllingNote
      documentLinks {
        id
        linkerId
        linker {
          ... on RequestForm {
            internalNumber
            legalAct
            id
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on RequestFormNote {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on BillIssuanceRequest {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on ContractPreparationRequest {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on ContractAgreement {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on FinancialAccountingDocument {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on Payment {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on Document {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
        }
        linkedId
        linked {
          ... on RequestForm {
            internalNumber
            legalAct
            id
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on RequestFormNote {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on BillIssuanceRequest {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on ContractPreparationRequest {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on ContractAgreement {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on FinancialAccountingDocument {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on Payment {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on Document {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
        }
        type
        createdAt
      }
      case {
        id
        name
        number
        isConductedElectronically
      }
      documentType
      organizationalUnit {
        id
        name
        symbol
      }
      documentKind
      documentLinks {
        id
        linkerId
        linker {
          ... on RequestForm {
            internalNumber
            legalAct
            id
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on RequestFormNote {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on BillIssuanceRequest {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on ContractPreparationRequest {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on ContractAgreement {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on FinancialAccountingDocument {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on Payment {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on Document {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
        }
        linkedId
        linked {
          ... on RequestForm {
            internalNumber
            legalAct
            id
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on RequestFormNote {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on BillIssuanceRequest {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on ContractPreparationRequest {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on ContractAgreement {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on FinancialAccountingDocument {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on Payment {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on Document {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
        }
        type
        createdAt
      }
      documentRoot {
        ... on RequestForm {
          internalNumber
          legalAct
          id
          documentType
          type
          documentNumber
          documentKind
        }
        ... on BillIssuanceRequest {
          id
          internalNumber
          documentType
          type
          documentNumber
          documentKind
        }
        ... on ContractPreparationRequest {
          id
          internalNumber
          documentType
          type
          documentNumber
          documentKind
        }
        ... on ContractAgreement {
          id
          internalNumber
          documentType
          type
          documentNumber
          documentKind
        }
        ... on FinancialAccountingDocument {
          id
          internalNumber
          documentType
          type
          documentNumber
          documentKind
        }
        ... on Payment {
          id
          internalNumber
          documentType
          type
          documentNumber
          documentKind
        }
        ... on Document {
          id
          internalNumber
          documentType
          type
          documentNumber
          documentKind
        }
      }
      children {
        id
        documentNumber
        internalNumber
        documentKind
        documentType
        type
        documentDate
        createdAt
        postStampAt
        state
      }
      responsible {
        id
        firstName
        lastName
      }
      isResponsibleSettable
      createdBy {
        id
        firstName
        lastName
      }
      currentStatus {
        documentFlowStepId
        documentFlowStep {
          id
          name
          isInitial
          isProcessedByDocumentFlowStarter
          isPaymentCreationEnabled
          isLegalSectionEnabled
          organizationalUnit {
            id
            name
          }
          group {
            id
            name
          }
          users {
            firstName
            lastName
          }
          allowedDocumentFields
          isAllowedToEditAllFields
        }
        createdAt
      }
      statuses {
        id
        comment
        signatureFilePath
        signatureFileMimetype
        signatureDownloadUrl
        user {
          id
          firstName
          lastName
          position
        }
        userPosition
        userOrganizationalUnits
        documentFlowRecipients
        substituter {
          id
          firstName
          lastName
          position
        }
        documentFlowStep {
          isPaymentCreationEnabled
          isLegalSectionEnabled
          organizationalUnit {
            id
            name
            symbol
          }
          users {
            id
            firstName
            lastName
          }
          positions {
            id
            name
          }
          id
          name
          isProcessedByDocumentFlowStarter
        }
        documentFlowAction {
          label
          style
          resultingDocumentState
          isEndingDocumentFlow
        }
        createdAt
      }
      documentFlowId
      isDocumentUsedInFlow
      documentFlow {
        id
        name
        steps {
          id
          name
        }
      }
      requestFormItems {
        id
        requestedExpenditure
        realExpenditure
        orderValue
        year
        isProjectBudget
        budgetSymbol
        categoryPath
        categoryName
        budgetName
        amount
        financialAccountingDocumentItems {
          id
          description
          name
          totalAmount
          totalAmountWithTax
          financialAccountingDocument {
            internalNumber
            documentType
            documentKind
            grossValue
            netValue
            currency {
              code
              id
            }
            currencyExchangeRate {
              id
              value
            }
          }
          financialAccountingDocumentId
        }
      }
      activityLogs {
        id
        activity
        description
        user {
          firstName
          lastName
          id
        }
        substituter {
          id
          firstName
          lastName
        }
        assignmentUsers
        properties
        createdAt
        userOrganizationalUnits
      }
      isDispatchable
      isDispatchCancelable
      isShareable
      shippedAt
      currentAssignment {
        id
        primaryAssignableId
        isAssessmentRequested
        assignerComment
        createdAt
        type
        status
        assigner {
          id
          firstName
          lastName
        }
        substituter {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const REQUEST_FORM_SELECT_SHOW = gql`
  query($id: Int!) {
    flowEndedRequestForm(id: $id) {
      id
      internalNumber
      type
      createdAt
      documentNumber
      nrRejestr
      title
      programName
      orderSubject
      orderType
      caseId
      isEditable
      beginAt
      endAt
      preferedExecutionBeginAt
      preferedExecutionEndAt
      legalAct
      legalDocumentsVerification
      isContractAgreementRequired
      hasDocumentFlowEnded
      state
      controllingNote
      requestFormItems {
        id
        requestedExpenditure
        realExpenditure
        orderValue
        budgetSymbol
        categoryPath
        categoryName
        budgetName
        year
        financialAccountingDocumentItems {
          id
          description
          name
          totalAmount
          totalAmountWithTax
          financialAccountingDocument {
            internalNumber
            documentType
            documentKind
            currency {
              id
              code
            }
          }
          financialAccountingDocumentId
        }
      }
    }
  }
`;

export const REQUEST_FORM_UPDATE = gql`
  mutation(
    $requestFormUpdateInput: RequestFormUpdateInput!
    $requestFormItemUpdateInputs: [RequestFormItemUpdateInput!]!
  ) {
    requestFormUpdate(
      requestFormUpdateInput: $requestFormUpdateInput
      requestFormItemUpdateInputs: $requestFormItemUpdateInputs
    ) {
      id
    }
  }
`;

export const REQUEST_FORM_ITEM_UPDATE = gql`
  mutation($requestFormItemUpdateInput: RequestFormItemUpdateInput!) {
    requestFormItemUpdate(
      requestFormItemUpdateInput: $requestFormItemUpdateInput
    ) {
      id
    }
  }
`;

export const REQUEST_FORM_CLOSE = gql`
  mutation($requestFormCloseInput: RequestFormCloseInput!) {
    requestFormClose(requestFormCloseInput: $requestFormCloseInput) {
      id
    }
  }
`;

export const REQUEST_FORM_VOID = gql`
  mutation($requestFormVoidInput: RequestFormVoidInput!) {
    requestFormVoid(requestFormVoidInput: $requestFormVoidInput) {
      id
    }
  }
`;

export const REQUEST_FORM_EXPORT_TO_XLSX = gql`
  mutation($documentId: Int!) {
    requestFormExportToXlsx(id: $documentId) {
      downloadUrl
    }
  }
`;

export const ALL_REQUEST_FORMS_EXPORT_TO_XLSX = gql`
  mutation {
    requestFormsExportAllToXlsx {
      downloadUrl
    }
  }
`;
