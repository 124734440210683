import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { KIND } from "baseui/button";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { DeviceFloppy, FileOff } from "tabler-icons-react";

import ConfirmDialog from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import FormRenderer from "../../../components/form-renderer";
import Header from "../../../components/header";
import { useLoading } from "../../../contexts/loading-context";
import { checkPermission } from "../../../utils/check-permission";
import { PERMISSIONS } from "../../../utils/permissions";
import { EditFormInputs, POSITIONS_FIELDS } from "../positions.form";
import { POSITIONS_EDIT, POSITIONS_SHOW } from "../positions.gql";

export default function PositionsEdit(): React.ReactElement {
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const { enqueueSnackbar } = useSnackbar();
  const {
    control,
    formState: { errors, isDirty, ...formState },
    handleSubmit,
    setValue,
    reset,
    ...methods
  } = useForm<EditFormInputs>();

  const history = useHistory();
  const { id } = useParams<{ id?: string }>();

  const { data, error: queryError } = useQuery(POSITIONS_SHOW, {
    variables: { id: id ? parseInt(id) : null },
    fetchPolicy: "cache-first",
  });
  const [editUser, { error }] = useMutation(POSITIONS_EDIT);

  const { setIsFetching, isLoading, setIsLoading } = useLoading();

  const onSubmit = async ({
    name,
    description,
    roleId,
  }: EditFormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      await editUser({
        variables: {
          id: id ? parseInt(id) : null,
          name,
          description,
          roleId: roleId && roleId[0]?.id,
        },
      });

      enqueueSnackbar({
        message: "Zapisano pomyślnie",
        variant: "success",
      });

      history.push(`/positions/${id}`);
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => setIsFetching(true), []);

  useEffect(() => {
    if (data?.position) {
      setValue("name", data.position.name);
      setValue("description", data.position.description);
      setValue(
        "roleId",
        data.position.roleId && [
          {
            id: data.position?.role?.id,
            name: data.position?.role?.name,
          },
        ]
      );
      setIsFetching(false);
    }
  }, [data]);

  useEffect(() => {
    if (queryError?.graphQLErrors)
      enqueueSnackbar({
        message: (queryError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [queryError]);

  if (!checkPermission(PERMISSIONS.position.update)) return <Redirect to="/" />;

  return (
    <article>
      <Header
        title={data?.position?.name}
        labels={["Stanowiska", "Edytowanie"]}
        goBackOption
        buttons={[
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              isDirty ? setIsCancelConfirmDialogOpen(true) : history.goBack(),
          },
          {
            label: "Zapisz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            onClick: handleSubmit(onSubmit),
            disabled: isLoading,
            isLoading: isLoading,
            type: "submit",
            formId: "editPosition",
          },
        ]}
      />
      <Content>
        <FormProvider<EditFormInputs>
          control={control}
          formState={{ errors, isDirty, ...formState }}
          handleSubmit={handleSubmit}
          reset={reset}
          setValue={setValue}
          {...methods}
        >
          <FormRenderer
            id="editPosition"
            onSubmit={handleSubmit(onSubmit)}
            type="edit"
            isLoading={isLoading}
            error={error}
            fields={POSITIONS_FIELDS}
          />
        </FormProvider>

        <ConfirmDialog
          isOpen={isCancelConfirmDialogOpen}
          label="Anulowanie edycji stanowiska"
          close={() => setIsCancelConfirmDialogOpen(false)}
          confirm={() => history.goBack()}
        />
      </Content>
    </article>
  );
}
