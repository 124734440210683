import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import {
  AlertOctagon,
  Cloud,
  DeviceFloppy,
  Download,
  FileOff,
  Paperclip,
} from "tabler-icons-react";

import { InputValidationError } from "../../../api";
import Button from "../../../components/button";
import Cell from "../../../components/cell";
import { ControlledCheckbox } from "../../../components/checkbox";
import ConfirmDialog, {
  ConfirmDialogType,
} from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import FormControl from "../../../components/form-control";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import GusDownloadModal from "../../../components/gus-download-modal";
import Header from "../../../components/header";
import { ControlledInput } from "../../../components/input";
import {
  ControlledAddressesSelect,
  ControlledDictionaryValuesSelect,
} from "../../../components/select";
import Tooltip from "../../../components/tooltip";
import { HIDDEN_ADDRESS } from "../../../constants";
import { useAssignment } from "../../../contexts/assignment-context";
import { useLoading } from "../../../contexts/loading-context";
import { FIELDS } from "../../../fields.d";
import { checkPermission } from "../../../utils/check-permission";
import { formValidation } from "../../../utils/formValidation";
import { PERMISSIONS } from "../../../utils/permissions";
import { scrollOnError } from "../../../utils/scrollOnError";
import { Address, DefaultAddress } from "../../Addresses/addresses";
import {
  ADDRESS_SUGGESTION,
  ADDRESS_SUGGESTIONS,
} from "../../Addresses/addresses.gql";
import { AssignmentLocationState } from "../../Folders/folders";
import { CASES_FIELDS, EditFormInputs } from "../cases.form";
import { CASES_SHOW, CASES_UPDATE } from "../cases.gql";

export default function CasesEdit(): React.ReactElement {
  const [
    isAddressRevealConfirmDialogOpen,
    setIsAddressRevealConfirmDialogOpen,
  ] = useState(false);
  const [isAnyAddressRevealed, setIsAnyAddressRevealed] = useState(false);
  const [hasPersonalData, setHasPersonalData] = useState<boolean>();
  const [defaultAddress, setDefaultAddress] = useState<DefaultAddress>();
  const [addressFromGus, setAddressFromGus] = useState<Address>();
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const [errorTimeStamp, setErrorTimeStamp] = useState<number>();
  const [css, theme] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { isFetching, setIsFetching, isLoading, setIsLoading } = useLoading();
  const { id } = useParams<{ id?: string }>();
  const [isGusDownloadOpen, setIsGusDownloadOpen] = useState(false);

  const { assignment, setAssignment, setIsAssignmentActive } = useAssignment();

  const { state } = useLocation<AssignmentLocationState>();
  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    setValue,
    unregister,
    watch,
  } = useForm<EditFormInputs>();

  const watchNip = watch("addressNip");
  const watchBeneficiary = watch("beneficiaryAddress");
  const watchCountryCode = watch("addressCountryCode");

  const [editCase, { error, loading }] = useMutation(CASES_UPDATE, {
    ...((state?.assignmentId || assignment?.id) &&
      state?.assignmentId !== null && {
        context: {
          headers: {
            Assignment: state?.assignmentId || assignment?.id,
          },
        },
      }),
  });

  const { data, error: queryError } = useQuery(CASES_SHOW, {
    variables: { id: id ? parseInt(id) : null },
    ...((state?.assignmentId || assignment?.id) &&
      state?.assignmentId !== null && {
        context: {
          headers: {
            Assignment: state?.assignmentId || assignment?.id,
          },
        },
      }),
  });

  const isBeneficiaryAddressRequired =
    data?.case?.jrwaClassification?.isBeneficiaryAddressRequired;

  const { refetch: fetchAddress } = useQuery(ADDRESS_SUGGESTIONS, {
    skip: true,
  });

  const onError = (errors: Record<string, unknown>, e: any) =>
    setErrorTimeStamp(e.timeStamp);

  const onSubmit = async ({
    name,
    coordinatorName,
    beneficiaryAddress,
    projectNumber,
    addressName,
    addressNip,
    addressStreetName,
    addressBuildingNumber,
    addressApartmentNumber,
    addressMobileNumber,
    addressPostalCode,
    addressCity,
    addressCountryCode,
    addressPhoneNumber,
    addressEmail,
    addressOverwrite,
    addressIsSensitive,
  }: EditFormInputs): Promise<void> => {
    setIsLoading(true);

    const enteredAddress = {
      addressName,
      addressNip,
      addressStreetName,
      addressBuildingNumber,
      addressApartmentNumber,
      addressMobileNumber,
      addressCity,
      addressCountryCode,
      addressPostalCode,
      addressPhoneNumber,
      addressEmail,
      addressIsSensitive,
    };

    const shouldSendAddress =
      isBeneficiaryAddressRequired &&
      !!addressName &&
      !!addressStreetName &&
      !!addressBuildingNumber &&
      !!addressCity &&
      !!addressPostalCode &&
      !!addressCountryCode &&
      JSON.stringify(enteredAddress) !== JSON.stringify(defaultAddress);

    try {
      const response = await editCase({
        variables: {
          caseUpdateInput: {
            id: id ? parseInt(id) : null,
            name,
            projectNumber,
            coordinatorName,
            beneficiaryAddressId: isBeneficiaryAddressRequired
              ? beneficiaryAddress?.[0]?.id
              : null,
          },
          ...(shouldSendAddress && {
            addressInput: {
              name: addressName,
              nip: addressNip && addressNip?.replaceAll("-", "").toString(),
              city: addressCity,
              streetName: addressStreetName,
              buildingNumber: addressBuildingNumber,
              apartmentNumber: addressApartmentNumber,
              mobileNumber: addressMobileNumber,
              postalCode: addressPostalCode,
              countryCode: addressCountryCode && addressCountryCode[0]?.id,
              email: addressEmail ? addressEmail : null,
              phoneNumber: addressPhoneNumber ? addressPhoneNumber : null,
              overwrite: !!addressOverwrite,
              isSensitive: !!addressIsSensitive,
            },
          }),
        },
      });

      enqueueSnackbar({
        message: "Zapisano pomyślnie",
        variant: "success",
      });

      history.push(`/cases/${response.data.caseUpdate.id}`);
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const { refetch: revealAddress, loading: revealAddressLoading } = useQuery(
    ADDRESS_SUGGESTION,
    {
      skip: true,
    }
  );

  const onAddressRevealSubmit = async () => {
    setIsLoading(true);

    try {
      const response = await revealAddress({
        id: watchBeneficiary && watchBeneficiary[0] && watchBeneficiary[0].id,
      });

      const choosedAddress = response?.data?.addressSuggestion;
      if (choosedAddress?.name) setValue("addressName", choosedAddress?.name);
      if (choosedAddress?.isSensitive)
        setValue("addressIsSensitive", choosedAddress?.isSensitive);

      if (choosedAddress?.nip) setValue("addressNip", choosedAddress?.nip);
      if (choosedAddress?.streetName)
        setValue("addressStreetName", choosedAddress?.streetName);
      if (choosedAddress?.buildingNumber)
        setValue("addressBuildingNumber", choosedAddress?.buildingNumber);
      if (choosedAddress?.apartmentNumber)
        setValue("addressApartmentNumber", choosedAddress?.apartmentNumber);
      if (choosedAddress?.mobileNumber)
        setValue("addressMobileNumber", choosedAddress?.mobileNumber);
      if (choosedAddress?.city) setValue("addressCity", choosedAddress?.city);
      if (choosedAddress?.postalCode)
        setValue("addressPostalCode", choosedAddress?.postalCode);
      if (choosedAddress?.countryCode)
        setValue("addressCountryCode", [
          {
            id: choosedAddress?.countryCode,
          },
        ]);
      if (choosedAddress?.phoneNumber)
        setValue("addressPhoneNumber", choosedAddress?.phoneNumber);
      if (choosedAddress?.email)
        setValue("addressEmail", choosedAddress?.email);

      enqueueSnackbar({
        message: "Odkryto pomyślnie",
        variant: "success",
      });

      setIsAnyAddressRevealed(true);
      setIsAddressRevealConfirmDialogOpen(false);
    } catch (error: unknown) {
      setIsAddressRevealConfirmDialogOpen(false);
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => setIsFetching(true), []);

  useEffect(() => {
    if (data?.case?.currentAssignment) {
      setAssignment(data?.case?.currentAssignment);
      setIsAssignmentActive(true);
    }
    if (data?.case) {
      setValue("name", data?.case?.name);
      setValue("projectNumber", data?.case?.projectNumber);
      setValue("coordinatorName", data?.case?.coordinatorName);
      setValue("organizationalUnit", [
        {
          id: data?.case?.organizationalUnit?.id,
          label: `${data?.case?.organizationalUnit?.symbol} - ${data?.case?.organizationalUnit?.name}`,
        },
      ]);
      setValue("jrwaClassification", [
        {
          id: data?.case?.jrwaClassification?.id,
          label: data?.case?.jrwaClassification?.name,
          isBeneficiaryAddressRequired:
            data?.case?.jrwaClassification?.isBeneficiaryAddressRequired,
        },
      ]);

      if (data?.case?.beneficiaryAddress) {
        setValue("beneficiaryAddress", [
          {
            id: data?.case?.beneficiaryAddressId,
            label: `${
              data?.case?.beneficiaryAddress?.name
                ? data?.case?.beneficiaryAddress?.name + ", "
                : ""
            } ${
              data?.case?.beneficiaryAddress?.address
                ? data?.case?.beneficiaryAddress?.address + ", "
                : ""
            } ${
              data?.case?.beneficiaryAddress?.city
                ? data?.case?.beneficiaryAddress?.city
                : ""
            }`,
            typename: data?.case?.beneficiaryAddress?.__typename,
          },
        ]);
        setValue(
          "addressIsSensitive",
          data?.case?.beneficiaryAddress?.isSensitive
        );
      }
      setHasPersonalData(
        data?.case?.beneficiaryAddress?.__typename === "HiddenAddress" ||
          data?.case?.beneficiaryAddress?.isSensitive
      );
      setIsFetching(false);
    }
  }, [data]);

  useEffect(() => {
    setAddressFromGus(undefined);
    if (!watchBeneficiary?.length) {
      setHasPersonalData(false);
      setIsAnyAddressRevealed(false);
      unregister("addressStreetName");
      unregister("addressBuildingNumber");
      unregister("addressApartmentNumber");
      unregister("addressMobileNumber");
      unregister("addressPostalCode");
      unregister("addressCity");
      unregister("addressCountryCode");
    }

    async function getAddressData() {
      setValue("addressName", "");
      setValue("addressNip", "");
      setValue("addressStreetName", "");
      setValue("addressBuildingNumber", "");
      setValue("addressApartmentNumber", "");
      setValue("addressMobileNumber", "");
      setValue("addressCity", "");
      setValue("addressPostalCode", "");
      setValue("addressPhoneNumber", "");
      setValue("addressEmail", "");
      setValue("addressIsSensitive", false);

      if (watchBeneficiary && watchBeneficiary[0] && watchBeneficiary[0].id) {
        const response = await fetchAddress({
          filter: {
            id: {
              eq: watchBeneficiary[0].id as number,
            },
          },
        });
        const choosedAddress = response?.data?.addressSuggestions?.nodes[0];
        if (choosedAddress?.name) setValue("addressName", choosedAddress?.name);
        if (choosedAddress?.__typename === "HiddenAddress") {
          setValue("addressIsSensitive", true);
          setHasPersonalData(true);
          setValue("addressCountryCode", []);
        }
        if (choosedAddress?.__typename === "Address") {
          setHasPersonalData(false);
          setValue("addressIsSensitive", choosedAddress?.isSensitive);
        }

        if (
          (hasPersonalData && isAnyAddressRevealed) ||
          !hasPersonalData ||
          watchBeneficiary[0]?.typename !== "HiddenAddress"
        ) {
          if (choosedAddress?.nip) setValue("addressNip", choosedAddress?.nip);
          if (choosedAddress?.streetName)
            setValue("addressStreetName", choosedAddress?.streetName);
          if (choosedAddress?.buildingNumber)
            setValue("addressBuildingNumber", choosedAddress?.buildingNumber);
          if (choosedAddress?.apartmentNumber)
            setValue("addressApartmentNumber", choosedAddress?.apartmentNumber);
          if (choosedAddress?.mobileNumber)
            setValue("addressMobileNumber", choosedAddress?.mobileNumber);
          if (choosedAddress?.city)
            setValue("addressCity", choosedAddress?.city);
          if (choosedAddress?.postalCode)
            setValue("addressPostalCode", choosedAddress?.postalCode);
          if (choosedAddress?.countryCode)
            setValue("addressCountryCode", [
              {
                id: choosedAddress?.countryCode,
              },
            ]);
          if (choosedAddress?.phoneNumber)
            setValue("addressPhoneNumber", choosedAddress?.phoneNumber);
          if (choosedAddress?.email)
            setValue("addressEmail", choosedAddress?.email);
          if (choosedAddress?.isSensitive)
            setValue("addressEmail", choosedAddress?.isSensitive);
          setValue("addressEmail", choosedAddress?.isSensitive);
        }

        setDefaultAddress({
          addressName: choosedAddress?.name || "",
          addressNip: choosedAddress?.nip || "",
          addressStreetName: choosedAddress?.streetName || "",
          addressBuildingNumber: choosedAddress?.buildingNumber || "",
          addressApartmentNumber: choosedAddress?.apartmentNumber || "",
          addressMobileNumber: choosedAddress?.mobileNumber || "",
          addressCity: choosedAddress?.city || "",
          addressCountryCode: choosedAddress?.countryCode
            ? [
                {
                  id: choosedAddress?.countryCode,
                },
              ]
            : [],
          addressPostalCode: choosedAddress?.postalCode || "",
          addressPhoneNumber: choosedAddress?.phoneNumber || "",
          addressEmail: choosedAddress?.email || "",
          addressIsSensitive: choosedAddress?.__typename === HIDDEN_ADDRESS,
        });
        unregister("addressName");
        unregister("addressStreetName");
        unregister("addressBuildingNumber");
        unregister("addressApartmentNumber");
        unregister("addressMobileNumber");
        unregister("addressPostalCode");
        unregister("addressCity");
        unregister("addressCountryCode");
      }
    }
    !addressFromGus && getAddressData();

    watchBeneficiary?.[0]?.typename === "Address"
      ? setIsAnyAddressRevealed(true)
      : setIsAnyAddressRevealed(false);
  }, [watchBeneficiary]);

  useEffect(() => {
    if (addressFromGus) setValue("beneficiaryAddress", undefined);
    if (addressFromGus?.nip) setValue("addressNip", addressFromGus?.nip);
    if (addressFromGus?.name) setValue("addressName", addressFromGus?.name);
    if (addressFromGus?.streetName)
      setValue("addressStreetName", addressFromGus?.streetName);
    if (addressFromGus?.buildingNumber)
      setValue("addressBuildingNumber", addressFromGus?.buildingNumber);
    if (addressFromGus?.apartmentNumber)
      setValue("addressApartmentNumber", addressFromGus?.apartmentNumber);
    if (addressFromGus?.mobileNumber)
      setValue("addressMobileNumber", addressFromGus?.mobileNumber);
    if (addressFromGus?.postalCode)
      setValue("addressPostalCode", addressFromGus?.postalCode);
    if (addressFromGus?.city) setValue("addressCity", addressFromGus?.city);
    if (addressFromGus?.countryCode)
      setValue("addressCountryCode", [{ id: addressFromGus?.countryCode }]);
    if (addressFromGus?.phoneNumber)
      setValue("addressPhoneNumber", addressFromGus?.phoneNumber);
    if (addressFromGus?.email) setValue("addressEmail", addressFromGus?.email);
  }, [addressFromGus]);

  useEffect(() => {
    if (queryError?.graphQLErrors)
      enqueueSnackbar({
        message: (queryError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [queryError]);

  useEffect(() => {
    if (errors) scrollOnError(errors, CASES_FIELDS, "edit");
  }, [errorTimeStamp]);

  if (
    data &&
    data?.case &&
    (!!data?.case?.closedAt || !checkPermission(PERMISSIONS.case.update))
  )
    return <Redirect to="/" />;

  return (
    <article>
      <Header
        title={
          <div
            className={css({
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
              gap: "5px",
              width: "max-content",
              color: `${
                data?.case?.isConductedElectronically
                  ? theme.colors.positive
                  : theme.colors.negative
              }`,
            })}
          >
            <span
              className={css({
                whiteSpace: "nowrap",
              })}
            >
              {data?.case?.number}
            </span>

            <Tooltip
              content={
                data?.case?.isConductedElectronically
                  ? "Sprawa prowadzona elektronicznie"
                  : "Sprawa prowadzona tradycyjnie"
              }
            >
              <span>
                {data?.case?.isConductedElectronically ? (
                  <Cloud
                    size={18}
                    className={css({
                      verticalAlign: "middle",
                      display: "inline",
                      color: theme.colors.positive,
                    })}
                  />
                ) : (
                  <Paperclip
                    size={18}
                    className={css({
                      verticalAlign: "middle",
                      display: "inline",
                      color: theme.colors.negative,
                    })}
                  />
                )}
              </span>
            </Tooltip>
          </div>
        }
        labels={["Sprawa", data?.case?.name]}
        goBackOption
        hasActiveAssignmentBar
        buttons={[
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              isDirty ? setIsCancelConfirmDialogOpen(true) : history.goBack(),
          },
          {
            label: "Zapisz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            onClick: handleSubmit(onSubmit, onError),
            disabled: isLoading,
            isLoading: isLoading,
            type: "submit",
            formId: "editCase",
          },
        ]}
      />
      <Content>
        <form id="editCase" onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            {CASES_FIELDS.filter(
              (g) => g.fields.filter((f) => f.edit.visible).length > 0
            ).map((group) => [
              group.label && (
                <Cell key={group.id + `-group`} span={12}>
                  <>
                    <Block
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      marginBottom="scale200"
                      marginTop="scale600"
                      height="20px"
                    >
                      <Block
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <LabelMedium>
                          {group.id.includes("beneficiary") &&
                          !isBeneficiaryAddressRequired
                            ? ""
                            : group.label}
                        </LabelMedium>
                        {hasPersonalData &&
                          !isAnyAddressRevealed &&
                          group.id === "beneficiaryAddress" && (
                            <Button
                              size={SIZE.mini}
                              kind={KIND.secondary}
                              $style={{ marginLeft: "10px" }}
                              onClick={() =>
                                setIsAddressRevealConfirmDialogOpen(true)
                              }
                              isLoading={revealAddressLoading}
                              type="button"
                            >
                              Pokaż dane
                            </Button>
                          )}
                      </Block>
                      {hasPersonalData &&
                        !isAnyAddressRevealed &&
                        group.id === "beneficiaryAddress" && (
                          <FormattedValue
                            dataType="pre"
                            $style={{ fontSize: "12px" }}
                          >
                            Dane osobowe - podgląd zablokowany
                          </FormattedValue>
                        )}
                    </Block>
                    {group.id.includes("beneficiary") &&
                    !isBeneficiaryAddressRequired ? (
                      <></>
                    ) : (
                      <hr
                        className={css({
                          borderWidth: "0px",
                          height: "1px",
                          backgroundColor: "#eee",
                        })}
                      />
                    )}
                  </>
                </Cell>
              ),
              group.fields
                .filter((f) => f.edit.visible)
                .map((item, index) => (
                  <Cell span={item.span || 6} key={group.id + `-field` + index}>
                    <div
                      {...(!isBeneficiaryAddressRequired &&
                        (item.id.includes("address") ||
                          item.id.includes("Address") ||
                          item.id.includes("coordinator")) && {
                          className: css({ display: "none" }),
                        })}
                    >
                      <FormControl
                        label={item.type === FIELDS.Checkbox ? "" : item.label}
                        required={
                          item.info === "possiblyRequired"
                            ? data?.case?.jrwaClassification
                                ?.isBeneficiaryAddressRequired &&
                              !hasPersonalData
                            : item.edit.required
                        }
                        error={
                          ((errors as any)[item.id] &&
                            (errors as any)[item.id].message) ||
                          (error &&
                            error.graphQLErrors[0]?.extensions?.code ===
                              "INPUT_VALIDATION_ERROR" &&
                            error.graphQLErrors[0]?.extensions?.validationErrors
                              ?.find((vE: InputValidationError) =>
                                item.id.includes("address")
                                  ? vE?.property ===
                                    item.id.slice(7).toLowerCase()
                                  : vE?.property === item.id
                              )
                              ?.errors.map((message: string) => (
                                <div
                                  key="error"
                                  className={css({
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  })}
                                >
                                  {message}
                                  <span
                                    className={css({
                                      color: "#999",
                                      marginLeft: "auto",
                                      marginRight: "5px",
                                    })}
                                  >
                                    Walidacja serwera
                                  </span>
                                  <AlertOctagon color="#999" size={12} />
                                </div>
                              ))[0])
                        }
                        disabled={isLoading}
                      >
                        {isFetching ? (
                          <Skeleton
                            rows={0}
                            height="36px"
                            width="100%"
                            animation
                          />
                        ) : item.type === FIELDS.AddressesSelect ? (
                          <ControlledAddressesSelect
                            control={control}
                            name={item.id}
                            id={item.id}
                            placeholder={item.placeholder}
                            {...(item.edit.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                          />
                        ) : item.type === FIELDS.DictionaryValuesSelect &&
                          item.info === "possiblyRequired" &&
                          data?.case?.jrwaClassification
                            ?.isBeneficiaryAddressRequired ? (
                          <ControlledDictionaryValuesSelect
                            dictionarySystemName="countryCodes.alpha2.pl"
                            control={control}
                            name={item.id}
                            id={item.id}
                            disabled={hasPersonalData && !isAnyAddressRevealed}
                            placeholder={
                              hasPersonalData &&
                              !isAnyAddressRevealed &&
                              item.id.includes("address")
                                ? "**********"
                                : "Wybierz"
                            }
                            rules={{
                              required: formValidation.messages.required,
                            }}
                          />
                        ) : item.type === FIELDS.DictionaryValuesSelect ? (
                          <ControlledDictionaryValuesSelect
                            dictionarySystemName="countryCodes.alpha2.pl"
                            control={control}
                            name={item.id}
                            id={item.id}
                            disabled={
                              item.id === "addressCountryCode" &&
                              !isAnyAddressRevealed &&
                              !!watchBeneficiary?.length
                            }
                            placeholder={
                              watchBeneficiary?.[0]?.typename ===
                                "HiddenAddress" && item.id.includes("address")
                                ? "**********"
                                : "Wybierz"
                            }
                            {...(item.edit.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                          />
                        ) : item.type === FIELDS.Checkbox ? (
                          <div
                            className={css({
                              marginTop: "40px",
                            })}
                          >
                            {item.id === "addressOverwrite" ? (
                              <Tooltip
                                placement="bottomLeft"
                                content={
                                  <p>
                                    Zaznaczenie tej opcji spowoduje aktualizację
                                    danych <br /> wybranego adresata w bazie,
                                    ale nie wpłynie to na <br /> wcześniejsze
                                    dokumenty powiązane z tym adresatem.
                                  </p>
                                }
                              >
                                <span>
                                  <ControlledCheckbox
                                    control={control}
                                    name={item.id}
                                    disabled={
                                      loading ||
                                      (hasPersonalData &&
                                        !isAnyAddressRevealed &&
                                        item.id.includes("address") &&
                                        !item.id.includes("Name"))
                                    }
                                    {...(item.edit.required && {
                                      rules: {
                                        required:
                                          formValidation.messages.required,
                                      },
                                    })}
                                  >
                                    {item.label}
                                  </ControlledCheckbox>
                                </span>
                              </Tooltip>
                            ) : (
                              <ControlledCheckbox
                                control={control}
                                name={item.id}
                                disabled={
                                  loading ||
                                  (hasPersonalData &&
                                    !isAnyAddressRevealed &&
                                    item.id.includes("address") &&
                                    !item.id.includes("Name"))
                                }
                                {...(item.edit.required && {
                                  rules: {
                                    required: formValidation.messages.required,
                                  },
                                })}
                              >
                                {item.label}
                              </ControlledCheckbox>
                            )}
                          </div>
                        ) : (
                          <ControlledInput
                            control={control}
                            name={item.id}
                            id={item.id}
                            placeholder={
                              hasPersonalData &&
                              !isAnyAddressRevealed &&
                              item.id.includes("address") &&
                              !item.id.includes("Name")
                                ? "**********"
                                : item.placeholder
                            }
                            masked={
                              item.id === "addressNip"
                                ? watchCountryCode?.[0]?.id === "PL"
                                  ? item.mask !== undefined
                                  : false
                                : item.mask !== undefined
                            }
                            mask={item.mask}
                            maskChar="_"
                            disabled={
                              hasPersonalData &&
                              !isAnyAddressRevealed &&
                              item.id.includes("address")
                            }
                            {...((item.edit.required ||
                              (data?.case?.jrwaClassification
                                ?.isBeneficiaryAddressRequired &&
                                !hasPersonalData &&
                                item.info === "possiblyRequired" &&
                                item.id.includes("address") &&
                                !item.id.includes("Name"))) && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                            {...(item.id === "addressEmail" && {
                              rules: {
                                pattern: {
                                  value: formValidation.patterns.email,
                                  message:
                                    formValidation.messages.incorrectEmail,
                                },
                              },
                            })}
                            endEnhancer={
                              item.id === "addressNip" && (
                                <Tooltip content="Pobierz z GUS">
                                  <span
                                    onClick={() => setIsGusDownloadOpen(true)}
                                    className={css({ cursor: "pointer" })}
                                  >
                                    <Download
                                      size={16}
                                      className={css({
                                        verticalAlign: "middle",
                                        marginLeft: "6px",
                                        display: "inline",
                                      })}
                                    />
                                  </span>
                                </Tooltip>
                              )
                            }
                          />
                        )}
                      </FormControl>
                    </div>
                  </Cell>
                )),
            ])}
            <ConfirmDialog
              isOpen={isCancelConfirmDialogOpen}
              label="Anulowanie edycji sprawy"
              close={() => setIsCancelConfirmDialogOpen(false)}
              confirm={() => history.goBack()}
            />
            <ConfirmDialog
              isOpen={isAddressRevealConfirmDialogOpen}
              type={ConfirmDialogType.AddressReveal}
              close={() => setIsAddressRevealConfirmDialogOpen(false)}
              confirm={() => onAddressRevealSubmit()}
            />
            <GusDownloadModal
              isOpen={isGusDownloadOpen}
              close={() => setIsGusDownloadOpen(false)}
              confirm={() => null}
              currentNip={watchNip}
              setAddressFromGus={setAddressFromGus}
            />
          </Grid>
        </form>
      </Content>
    </article>
  );
}
