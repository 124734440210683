import { gql } from "@apollo/client";

export const ADDRESSES_INDEX = gql`
  query(
    $pageSize: Int
    $offset: Int
    $sorting: [AddressSort!]
    $filter: AddressFilter
  ) {
    addresses(
      paging: { limit: $pageSize, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        name
        nip
        address
        streetName
        buildingNumber
        apartmentNumber
        mobileNumber
        postalCode
        countryCode
        phoneNumber
        city
        isHidden
        isSensitive
        createdAt
        bankAccounts {
          payeeName
          accountNumber
          accountType
          bankName
          bankCode
          currency
        }
      }
    }
  }
`;

export const ADDRESS_SUGGESTIONS = gql`
  query($offset: Int, $filter: AddressFilter) {
    addressSuggestions(
      paging: { limit: 1000, offset: $offset }
      sorting: { field: id, direction: ASC }
      filter: $filter
    ) {
      totalCount
      nodes {
        __typename
        ... on Address {
          id
          name
          city
          nip
          address
          streetName
          buildingNumber
          apartmentNumber
          mobileNumber
          postalCode
          countryCode
          phoneNumber
          email
          city
          isHidden
          isSensitive
          createdAt
          bankAccounts {
            id
            payeeName
            currency
            accountNumber
            accountType
            bankName
            bankCode
          }
        }
        ... on HiddenAddress {
          id
          name
          userBankAccounts: bankAccounts {
            id
            payeeName
            currency
            accountNumber
            accountType
            bankName
            bankCode
          }
        }
      }
    }
  }
`;

export const ADDRESS_SUGGESTION = gql`
  query($id: Int!) {
    addressSuggestion(id: $id) {
      __typename
      id
      name
      city
      nip
      address
      streetName
      buildingNumber
      apartmentNumber
      mobileNumber
      postalCode
      countryCode
      phoneNumber
      email
      city
      isHidden
      isSensitive
      createdAt
      bankAccounts {
        id
        payeeName
        currency
        accountNumber
        accountType
        bankName
      }
    }
  }
`;

export const ADDRESSES_SHOW = gql`
  query($id: Int!) {
    address(id: $id) {
      id
      name
      address
      streetName
      buildingNumber
      apartmentNumber
      mobileNumber
      postalCode
      countryCode
      phoneNumber
      nip
      email
      city
      isHidden
      isSensitive
      createdAt
      updatedAt
      bankAccounts {
        id
        payeeName
        accountNumber
        accountType
        bankName
        bankCode
      }
    }
  }
`;

export const ADDRESSES_SELECT_INDEX = gql`
  query($offset: Int, $filter: AddressFilter) {
    addressSuggestions(
      paging: { limit: 10, offset: $offset }
      sorting: { field: id, direction: DESC }
      filter: $filter
    ) {
      totalCount
      nodes {
        __typename
        ... on Address {
          id
          name
          address
          city
          nip
          streetName
          buildingNumber
          apartmentNumber
          mobileNumber
          postalCode
          countryCode
          phoneNumber
          email
          city
          isHidden
          isSensitive
          createdAt
        }
        ... on HiddenAddress {
          id
          name
        }
      }
    }
  }
`;

export const ADDRESSES_HIDE = gql`
  mutation($id: Int!) {
    addressHide(addressHideInput: { id: $id }) {
      name
    }
  }
`;

export const ADDRESSES_UPDATE = gql`
  mutation($addressUpdateInput: AddressUpdateInput!) {
    addressUpdate(addressUpdateInput: $addressUpdateInput) {
      id
    }
  }
`;

export const BANK_ACCOUNTS_SHOW = gql`
  query($id: Int!) {
    bankAccount(id: $id) {
      id
      payeeName
      accountNumber
      accountType
      bankName
      bankCode
      currency
    }
  }
`;
