import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { Lock, LockOpen, Trash } from "tabler-icons-react";

import Button from "../../../components/button";
import Cell from "../../../components/cell";
import ConfirmDialog, {
  ConfirmDialogType,
} from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import FormControl from "../../../components/form-control";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import { HIDDEN_ADDRESS } from "../../../constants";
import { useDictionaries } from "../../../contexts/dictionaries-context";
import { useLoading } from "../../../contexts/loading-context";
import { checkPermission } from "../../../utils/check-permission";
import { PERMISSIONS } from "../../../utils/permissions";
import { SENDING_LISTS_ITEMS_FIELDS } from "../sending-lists.form";
import {
  SENDING_LIST_ITEM_ADDRESS_REVEAL,
  SENDING_LIST_ITEM_SHOW,
  SENDING_LISTS_ITEM_DELETE,
} from "../sending-lists.gql";

export default function SendingListsShowItem(): React.ReactElement {
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState(
    false
  );
  const [
    isAddressRevealConfirmDialogOpen,
    setIsAddressRevealConfirmDialogOpen,
  ] = useState(false);
  const [isAnyAddressRevealed, setIsAnyAddressRevealed] = useState(false);
  const [css, theme] = useStyletron();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { setIsLoading, isFetching, setIsFetching } = useLoading();
  const { listId, itemId } = useParams<{ listId?: string; itemId?: string }>();
  const { findValue } = useDictionaries();

  const { data, error, refetch } = useQuery(SENDING_LIST_ITEM_SHOW, {
    variables: {
      id: itemId ? parseInt(itemId) : null,
    },
  });

  const [deleteSendingListItem] = useMutation(SENDING_LISTS_ITEM_DELETE);

  const deleteSubmit = async () => {
    setIsLoading(true);

    try {
      await deleteSendingListItem({
        variables: {
          eNadawcaSendingListItemDeleteInput: {
            id: itemId ? parseInt(itemId) : null,
          },
        },
      });

      enqueueSnackbar({
        message: "Usunięto pomyślnie",
        variant: "success",
      });

      setIsConfirmDeleteDialogOpen(false);

      history.push(`/sending-lists/${listId}`);
    } catch (error: unknown) {
      setIsConfirmDeleteDialogOpen(false);

      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  const [revealAddress, { loading: revealAddressLoading }] = useMutation(
    SENDING_LIST_ITEM_ADDRESS_REVEAL
  );

  const eNadawcaSendingListItemAddressId =
    data?.eNadawcaSendingListItem?.addressId;

  const onPersonalDataRevealSubmit = async () => {
    setIsLoading(true);

    try {
      await revealAddress({
        variables: {
          eNadawcaSendingListItemAddressRevealInput: {
            eNadawcaSendingListItemId: itemId ? parseInt(itemId) : null,
            eNadawcaSendingListItemAddressId,
          },
        },
      });

      enqueueSnackbar({
        message: "Odkryto pomyślnie",
        variant: "success",
      });

      refetch();
      setIsAddressRevealConfirmDialogOpen(false);
      setIsAnyAddressRevealed(true);
    } catch (error: unknown) {
      setIsAddressRevealConfirmDialogOpen(false);
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (data?.eNadawcaSendingListItem) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => data?.eNadawcaSendingListItem && setIsFetching(false), [
    data,
  ]);

  if (!checkPermission(PERMISSIONS.case.read)) return <Redirect to="/" />;

  return (
    <article>
      <Header
        title={`Wysyłka ${data?.eNadawcaSendingListItem?.id}`}
        labels={["Listy wysyłkowe", "Wyświetlanie wysyłki"]}
        goBackOption
        actions={[
          {
            label: "Usuń wysyłkę z listy",
            icon: Trash,
            color: theme.colors.negative,
            onClick: () => setIsConfirmDeleteDialogOpen(true),
            permission:
              !data?.eNadawcaSendingListItem?.enadawcaSendingList?.isSent &&
              checkPermission(PERMISSIONS.sendingList.update),
          },
        ]}
      />
      <Content>
        <Grid>
          <Block marginBottom="30px" />
          {SENDING_LISTS_ITEMS_FIELDS.filter(
            (g) => g.fields.filter((f) => f.show.visible).length > 0
          ).map((group) => [
            group.label && (
              <Cell key={group.id + `-group`} span={12}>
                <>
                  <Block
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    marginBottom="scale200"
                    marginTop="scale600"
                  >
                    <Block
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      {data?.eNadawcaSendingListItem?.address?.__typename ===
                        HIDDEN_ADDRESS && group.id === "recipient" ? (
                        <Lock
                          size={18}
                          color={theme.colors.negative}
                          className={css({ marginRight: "10px" })}
                        />
                      ) : (
                        group.id === "recipient" && (
                          <LockOpen
                            size={18}
                            color={theme.colors.positive}
                            className={css({ marginRight: "10px" })}
                          />
                        )
                      )}
                      <LabelMedium>{group.label}</LabelMedium>
                      {data?.eNadawcaSendingListItem?.address?.__typename ===
                        HIDDEN_ADDRESS &&
                        group.id === "recipient" && (
                          <Button
                            size={SIZE.mini}
                            kind={KIND.secondary}
                            $style={{ marginLeft: "10px" }}
                            onClick={() =>
                              setIsAddressRevealConfirmDialogOpen(true)
                            }
                            isLoading={revealAddressLoading}
                          >
                            Pokaż dane
                          </Button>
                        )}
                    </Block>
                    {data?.eNadawcaSendingListItem?.address?.__typename !==
                      HIDDEN_ADDRESS &&
                      isAnyAddressRevealed &&
                      group.id === "recipient" && (
                        <FormattedValue
                          dataType="pre"
                          $style={{ fontSize: "13px" }}
                        >
                          Dostęp jest aktywny do końca trwania sesji
                        </FormattedValue>
                      )}
                  </Block>
                  <hr
                    className={css({
                      borderWidth: "0px",
                      height: "1px",
                      backgroundColor: "#eee",
                    })}
                  />
                </>
              </Cell>
            ),
            group.fields
              .filter((f) => f.show.visible)
              .map((item, index) => (
                <Cell
                  span={item.span || 6}
                  key={group.id + `-field` + index}
                  skip={item.skip}
                >
                  <FormControl label={item.label} disabled={true}>
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : data?.eNadawcaSendingListItem?.address?.__typename ===
                        HIDDEN_ADDRESS && item.id.includes("address") ? (
                      <Skeleton
                        rows={0}
                        height="20px"
                        width="15ch"
                        overrides={{
                          Root: {
                            style: {
                              backgroundColor: "#faebeb",
                            },
                          },
                        }}
                      />
                    ) : (
                      <FormattedValue
                        dataType={item.dataType}
                        data={
                          item.id === "shipmentRate"
                            ? `${data?.eNadawcaSendingListItem?.shipmentRate?.shipmentContractId}/${data?.eNadawcaSendingListItem?.shipmentRate?.id}`
                            : item.id === "document"
                            ? `${data?.eNadawcaSendingListItem?.documentParcel?.document?.documentKind?.toLowerCase()}/${
                                data?.eNadawcaSendingListItem?.documentParcel
                                  ?.document?.type
                              }/${
                                data?.eNadawcaSendingListItem?.documentParcel
                                  ?.document?.id
                              }`
                            : item.id === "documentParcel"
                            ? data?.eNadawcaSendingListItem?.documentParcel?.id
                            : data?.eNadawcaSendingListItem[item.id]
                        }
                      >
                        {item.show.accessor
                          ? data?.eNadawcaSendingListItem[
                              item.show.accessor[0]
                            ]?.[item.show.accessor[1]]
                          : item.id === "document"
                          ? `${findValue(
                              data?.eNadawcaSendingListItem?.documentParcel
                                ?.document?.documentType
                            )} [${
                              data?.eNadawcaSendingListItem?.documentParcel
                                ?.document?.documentType === "requestForm"
                                ? data?.eNadawcaSendingListItem?.documentParcel
                                    ?.document?.documentNumber
                                : data?.eNadawcaSendingListItem?.documentParcel
                                    ?.document?.internalNumber
                            }]`
                          : item.id === "addressAddress"
                          ? data?.eNadawcaSendingListItem?.address?.streetName
                            ? `${
                                data?.eNadawcaSendingListItem?.address
                                  ?.streetName
                              } ${
                                data?.eNadawcaSendingListItem?.address
                                  ?.buildingNumber
                              }${
                                data?.addreNadawcaSendingListItem?.address
                                  ?.apartmentNumber
                                  ? "/" +
                                    data?.eNadawcaSendingListItem?.address
                                      ?.apartmentNumber
                                  : ""
                              }`
                            : data?.eNadawcaSendingListItem?.address?.address
                          : data?.eNadawcaSendingListItem[item.id]}
                      </FormattedValue>
                    )}
                  </FormControl>
                </Cell>
              )),
          ])}
          <ConfirmDialog
            isOpen={isConfirmDeleteDialogOpen}
            label={`Usunięcie wysyłki ${data?.eNadawcaSendingListItem?.id} z listy ${data?.eNadawcaSendingListItem?.enadawcaSendingList?.id}`}
            close={() => setIsConfirmDeleteDialogOpen(false)}
            confirm={deleteSubmit}
          />
          <ConfirmDialog
            isOpen={isAddressRevealConfirmDialogOpen}
            type={ConfirmDialogType.AddressReveal}
            close={() => setIsAddressRevealConfirmDialogOpen(false)}
            confirm={() => onPersonalDataRevealSubmit()}
          />
        </Grid>
      </Content>
    </article>
  );
}
