import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND } from "baseui/button";
import { StyledLink } from "baseui/link";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { MouseEvent, useEffect, useMemo, useState } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { Row } from "react-table";
import { Pencil, Trash } from "tabler-icons-react";

import Cell from "../../../components/cell";
import ConfirmDialog from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import FormControl from "../../../components/form-control";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import Table from "../../../components/table";
import { useLoading } from "../../../contexts/loading-context";
import { checkPermission } from "../../../utils/check-permission";
import { PERMISSIONS } from "../../../utils/permissions";
import { User } from "../../Users/users";
import { GROUPS_FIELDS, GroupsFieldName } from "../groups.form";
import { GROUPS_DELETE, GROUPS_SHOW } from "../groups.gql";

export default function GroupsShow(): React.ReactElement {
  const [css, theme] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const { setIsLoading, isFetching, setIsFetching } = useLoading();

  const { refetch, data, error } = useQuery(GROUPS_SHOW, {
    variables: { id: id ? parseInt(id) : null },
  });

  const [deleteGroup] = useMutation(GROUPS_DELETE);

  const submit = async () => {
    setIsLoading(true);

    try {
      await deleteGroup({
        variables: {
          groupDeleteInput: {
            id: id ? parseInt(id) : null,
          },
        },
      });

      enqueueSnackbar({
        message: "Usunięto pomyślnie",
        variant: "success",
      });

      setIsConfirmDialogOpen(false);

      history.push("/groups");
    } catch (error: unknown) {
      setIsConfirmDialogOpen(false);

      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    if (data?.group) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => data?.group && setIsFetching(false), [data]);

  const columns = useMemo(
    () => [
      {
        Header: "Imię i nazwisko",
        id: "name",
        Cell: ({ row }: { row: Row<User> }) => (
          <StyledLink
            onClick={(event: MouseEvent) => {
              event.preventDefault();
              history.push(`/users/${row.original.id}`);
            }}
            href={`/users/${row.original.id}`}
          >
            <FormattedValue dataType="model:users" data={row?.original?.id}>
              {`${row?.original?.firstName} ${row?.original?.lastName}`}
            </FormattedValue>
          </StyledLink>
        ),
      },
      {
        Header: "Adres e-mail",
        id: "e-mail",
        Cell: ({ row }: { row: Row<User> }) => (
          <FormattedValue dataType="email">
            {row?.original?.email}
          </FormattedValue>
        ),
      },
    ],
    []
  );

  if (!checkPermission(PERMISSIONS.group.read)) return <Redirect to="/" />;

  return (
    <article>
      <Header
        title={
          <span
            className={css({
              position: "relative",
              ":before": {
                content: "''",
                position: "absolute",
                top: "8px",
                right: "-20px",
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                background: data?.group?.isUsedInAnyDocumentFlow
                  ? theme.colors.positive
                  : theme.colors.negative,
              },
            })}
          >
            {data?.group?.name}
          </span>
        }
        labels={["Grupy użytkowników", "Wyświetlanie"]}
        goBackOption
        buttons={[
          {
            label: "Edytuj",
            kind: KIND.secondary,
            startEnhancer: <Pencil size={18} />,
            permission: checkPermission(PERMISSIONS.group.update),
            onClick: () => history.push(`/groups/${id}/edit`),
          },
        ]}
        actions={[
          {
            label: "Usuń grupę",
            icon: Trash,
            color: theme.colors.negative,
            onClick: () => setIsConfirmDialogOpen(true),
            permission: checkPermission(PERMISSIONS.group.delete),
          },
        ]}
      />
      <Content>
        <Grid>
          {GROUPS_FIELDS.filter(
            (g) => g.fields.filter((f) => f.show.visible).length > 0
          ).map((group) => [
            group.label && (
              <Cell key={group.id + `-group`} span={12}>
                <LabelMedium marginBottom="scale200" marginTop="scale600">
                  {group.label}
                </LabelMedium>
                <hr
                  className={css({
                    borderWidth: "0px",
                    height: "1px",
                    backgroundColor: "#eee",
                  })}
                />
              </Cell>
            ),
            group.fields
              .filter((f) => f.show.visible)
              .map((item, index) => (
                <Cell span={item.span || 6} key={group.id + `-field` + index}>
                  <FormControl
                    label={item.label}
                    disabled={true}
                    overrides={{
                      ControlContainer: {
                        props: {
                          "data-test-id": item.id,
                        },
                      },
                    }}
                  >
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : (
                      <FormattedValue
                        dataType={item.dataType}
                        data={data?.group[item.id]}
                      >
                        {item.id === GroupsFieldName.CreatedBy
                          ? `${data?.group?.createdBy?.firstName} ${data?.group?.createdBy?.lastName}`
                          : item.show.accessor
                          ? data?.group[item.show.accessor[0]]?.[
                              item.show.accessor[1]
                            ]
                          : data?.group[item.id]}
                      </FormattedValue>
                    )}
                  </FormControl>
                </Cell>
              )),
          ])}
          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              {`Pracownicy${!data?.group?.users?.nodes?.length ? " (0)" : ""}`}
            </LabelMedium>
            {data?.group?.users?.nodes?.length ? (
              <hr
                className={css({
                  borderWidth: "0px",
                  height: "1px",
                  backgroundColor: "#eee",
                })}
              />
            ) : (
              <></>
            )}
          </Cell>
          <Cell span={12}>
            {isFetching ? (
              <Skeleton rows={0} height="200px" width="100%" animation />
            ) : data?.group?.users?.nodes?.length ? (
              <Block paddingTop="scale200" paddingBottom="scale600">
                <Table<User>
                  columns={columns}
                  data={data?.group?.users?.nodes}
                />
              </Block>
            ) : (
              <></>
            )}
          </Cell>
          <ConfirmDialog
            isOpen={isConfirmDialogOpen}
            label={`Usunięcie grupy ${data?.group?.name}`}
            close={() => setIsConfirmDialogOpen(false)}
            confirm={submit}
          />
        </Grid>
      </Content>
    </article>
  );
}
