import { FIELDS, FieldsGroup } from "../../fields.d";

export const BUDGET_LEDGER_ITEMS_FIELDS: FieldsGroup[] = [
  {
    id: "basic",
    label: "Podstawowe informacje",
    fields: [
      {
        id: "createdAt",
        label: "Data utworzenia",
        type: FIELDS.Input,
        dataType: "datetime",
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
    ],
  },
  {
    id: "budgetItem",
    label: "Budżet",
    fields: [
      {
        id: "id",
        label: "ID",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true, accessor: ["budgetItem", "budget", "bazaId"] },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "name",
        label: "Nazwa",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true, accessor: ["budgetItem", "budget", "name"] },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "budgetValue",
        label: "Wartość kategorii",
        type: FIELDS.Input,
        dataType: "quota",
        span: 3,
        show: { visible: true, accessor: ["budgetItem", "budgetValue"] },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "currentValue",
        label: "Dostępne środki",
        type: FIELDS.Input,
        dataType: "quota",
        span: 3,
        show: { visible: true, accessor: ["budgetItem", "currentValue"] },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "budgetCategory",
        label: "Kategoria finansowania",
        type: FIELDS.Input,
        span: 6,
        show: {
          visible: true,
          accessor: ["budgetItem", "budgetCategory", "name"],
        },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "expensePosition",
        label: "Pozycja budżetowa",
        type: FIELDS.Input,
        span: 6,
        show: {
          visible: true,
        },
        create: { visible: false },
        edit: { visible: false },
      },
    ],
  },
  {
    id: "change",
    label: "Zmiana",
    fields: [
      {
        id: "change",
        label: "Wartość zmiany",
        type: FIELDS.Input,
        dataType: "quota",
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "valueAfterChange",
        label: "Wartość budżetu po zmianie",
        type: FIELDS.Input,
        dataType: "quota",
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
    ],
  },
  {
    id: "Invoice",
    label: "Dokument",
    fields: [
      {
        id: "documentId",
        label: "Dokument",
        type: FIELDS.Input,
        dataType: "model:documents",
        span: 3,
        show: { visible: true, accessor: ["document", "internalNumber"] },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "financialAccountingDocumentItem",
        label: "Pozycja z dokumentu F-K",
        type: FIELDS.Input,
        dataType: "model:documents",
        span: 3,
        show: {
          visible: true,
          accessor: ["financialAccountingDocumentItem", "name"],
        },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "financialAccountingDocumentItem",
        label: "Wartość pozycji netto",
        type: FIELDS.Input,
        dataType: "quota",
        span: 3,
        show: {
          visible: true,
          accessor: ["financialAccountingDocumentItem", "totalAmount"],
        },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "financialAccountingDocumentItem",
        label: "Wartość pozycji brutto",
        type: FIELDS.Input,
        dataType: "quota",
        span: 3,
        show: {
          visible: true,
          accessor: ["financialAccountingDocumentItem", "totalAmountWithTax"],
        },
        create: { visible: false },
        edit: { visible: false },
      },
    ],
  },
];
