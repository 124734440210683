import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";

import {
  Assignment,
  AssignmentLocationState,
} from "../containers/Folders/folders";

type AssignmentContextProps = {
  assignment?: Assignment;
  setAssignment: (assignment: Assignment | undefined) => void;
  isAssignmentActive: boolean;
  setIsAssignmentActive: (isAssignmentActive: boolean) => void;
};

export const AssignmentContext = createContext<AssignmentContextProps>(
  {} as AssignmentContextProps
);

export function AssignmentProvider({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement {
  const [assignment, setAssignment] = useState<Assignment>();
  const [isAssignmentActive, setIsAssignmentActive] = useState(false);

  const [assignmentPaths, setAssignmentPaths] = useState<string[]>();

  const { key, state, pathname } = useLocation<AssignmentLocationState>();

  useEffect(() => {
    if (
      (state && state?.assignmentId !== assignment?.id) ||
      (!state && !assignmentPaths?.includes(pathname))
    ) {
      setIsAssignmentActive(false);
      setAssignment(undefined);
      setAssignmentPaths(undefined);
    }

    setAssignmentPaths((assignmentPaths) => [
      ...(assignmentPaths || []),
      pathname,
    ]);
  }, [key]);

  return (
    <AssignmentContext.Provider
      value={{
        assignment,
        setAssignment,
        isAssignmentActive,
        setIsAssignmentActive,
      }}
    >
      {children}
    </AssignmentContext.Provider>
  );
}

export const useAssignment = (): AssignmentContextProps =>
  useContext(AssignmentContext);
