import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { Redirect, useParams } from "react-router-dom";
import { TableExport } from "tabler-icons-react";

import Cell from "../../../components/cell";
import Content from "../../../components/content";
import FormControl from "../../../components/form-control";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import { useLoading } from "../../../contexts/loading-context";
import { checkPermission } from "../../../utils/check-permission";
import { PERMISSIONS } from "../../../utils/permissions";
import { BUDGET_LEDGER_ITEMS_FIELDS } from "../budget-ledger-items.form";
import {
  BUDGET_LEDGER_ITEMS_SHOW,
  EXPORT_BUDGET_ITEM_TO_XLSX,
} from "../budget-ledger-items.gql";

export default function BudgetLedgerItemsShow(): React.ReactElement {
  const [css, theme] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams<{ id?: string }>();
  const { isFetching, setIsFetching, setIsLoading } = useLoading();

  const { refetch, data, error } = useQuery(BUDGET_LEDGER_ITEMS_SHOW, {
    variables: { id: id ? parseInt(id) : null },
  });

  const position = data?.budgetLedgerItem?.budgetItem?.budget?.expensePositions?.find(
    (position: { name: string; budgetId: number }) =>
      position?.budgetId === data?.budgetLedgerItem?.budgetItem?.budgetId
  ) as { name: string };

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    if (data?.budgetLedgerItem) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => {
    data?.budgetLedgerItem && setIsFetching(false);
  }, [data]);

  const [exportXlsxFile] = useMutation(EXPORT_BUDGET_ITEM_TO_XLSX);

  const exportBudetItemToXlsx = async () => {
    setIsLoading(true);

    try {
      const response = await exportXlsxFile({
        variables: {
          id: id ? parseInt(id) : null,
        },
      });

      enqueueSnackbar({
        message: "Rozpoczęto pobieranie pliku",
        variant: "info",
      });

      window.open(
        `${process.env.REACT_APP_GRAPHQL_API_URL?.replace("/graphql", "")}${
          response?.data?.budgetItemExportToXlsx?.downloadUrl
        }`,
        "_self"
      );
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (!checkPermission(PERMISSIONS.budgetLedgerItems.read))
    return <Redirect to="/" />;

  return (
    <article>
      <Header
        title="Zmiana budżetu"
        labels={["Historia zmian budżetowych", "Wyświetlanie"]}
        goBackOption
        actions={[
          {
            label: "Pobierz plik XLSX",
            icon: TableExport,
            color: theme.colors.primary,
            onClick: () => exportBudetItemToXlsx(),
            permission: checkPermission(PERMISSIONS.requestForm.read),
          },
        ]}
      />
      <Content>
        <Grid>
          {BUDGET_LEDGER_ITEMS_FIELDS.filter(
            (g) => g.fields.filter((f) => f.show.visible).length > 0
          ).map((group) => [
            group.label && (
              <Cell key={group.id + `-group`} span={12}>
                <LabelMedium marginBottom="scale200" marginTop="scale600">
                  {group.label}
                </LabelMedium>
                <hr
                  className={css({
                    borderWidth: "0px",
                    height: "1px",
                    backgroundColor: "#eee",
                  })}
                />
              </Cell>
            ),
            group.fields
              .filter((f) => f.show.visible)
              .map((item, index) => (
                <Cell span={item.span || 6} key={group.id + `-field` + index}>
                  <FormControl label={item.label} disabled={true}>
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : (
                      <FormattedValue
                        dataType={item.dataType}
                        data={
                          item.id === "documentId" ||
                          item.id === "financialAccountingDocumentItem"
                            ? `incoming/Invoice/${data?.budgetLedgerItem?.documentId}`
                            : data?.budgetLedgerItem[item.id]
                        }
                      >
                        {item.id === "expensePosition"
                          ? position?.name
                          : item.show.accessor
                          ? item.show.accessor.length === 2
                            ? data?.budgetLedgerItem[item.show.accessor[0]]?.[
                                item.show.accessor[1]
                              ]
                            : data?.budgetLedgerItem[item.show.accessor[0]]?.[
                                item.show.accessor[1]
                              ]?.[item.show.accessor[2]]
                          : data?.budgetLedgerItem[item.id]}
                      </FormattedValue>
                    )}
                  </FormControl>
                </Cell>
              )),
          ])}
        </Grid>
      </Content>
    </article>
  );
}
