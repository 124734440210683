export const formValidation = {
  messages: {
    incorrectEmail: "Adres e-mail jest nieprawidłowy.",
    requiredPermission: "Proszę zaznaczyć przynajmniej jedno uprawnienie.",
    required: "To pole jest wymagane.",
    requiredSignature: "Dodanie pliku z podpisem jest wymagane.",
    tooMuchPercent: "Suma pozycji przekracza 100%",
  },
  patterns: {
    email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    text: "[a-zA-Z]",
  },
};
