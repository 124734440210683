import { useStyletron } from "baseui";
import React, { useState } from "react";
import { AlertOctagon, ChevronDown, ChevronUp, X } from "tabler-icons-react";

import { useSubstitution } from "../contexts/substitution-context";
import Button from "./button";
import Cell from "./cell";
import FormControl from "./form-control";
import FormattedValue from "./formatted-value";
import Grid from "./grid";

export default function SubstitutionBar(): React.ReactElement | null {
  const [css, theme] = useStyletron();

  const [isExpanded, setIsExpanded] = useState(false);

  const { substitution, endSubstitution } = useSubstitution();

  if (!substitution) return null;

  return (
    <div
      className={css({
        zIndex: 20,
        position: "fixed",
        left: "20px",
        bottom: "20px",
        right: "20px",
        borderWidth: "2px",
        borderStyle: "solid",
        borderColor: theme.colors.negative200,
        borderRadius: "6px",
        backgroundColor: theme.colors.negative50,
      })}
    >
      <div
        className={css({
          paddingTop: isExpanded ? "19px" : "12px",
          paddingBottom: isExpanded ? "4px" : "12px",
          cursor: isExpanded ? "auto" : "pointer",
          zIndex: 20,
          transition: "all 200ms ease",
          position: "relative",
        })}
        {...(!isExpanded && {
          onClick: () => setIsExpanded(true),
        })}
      >
        <Grid gridMaxWidth={2400}>
          <Cell span={12}>
            <span
              className={css({
                position: "absolute",
                display: "flex",
                right: "100%",
                top: "50%",
                transform: "translateY(-50%)",
                cursor: "pointer",
                ":hover": {
                  color: "black",
                },
              })}
            >
              <AlertOctagon size={16} color="red" />
            </span>

            <div
              className={css({
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                fontSize: "12px",
              })}
            >
              <div
                className={css({
                  height: "15px",
                  flexShrink: 0,
                })}
              >
                {"Wykonujesz działania w ramach zastępstwa za "}

                <FormattedValue
                  dataType="model:users"
                  $style={{ marginLeft: "8px" }}
                  data={substitution?.substitutee?.id}
                >
                  {`${substitution?.substitutee?.firstName} ${substitution?.substitutee?.lastName}`}
                </FormattedValue>
              </div>

              <Button
                kind="minimal"
                $style={{
                  fontSize: "12px",
                  marginLeft: "auto",
                  display: "flex",
                  alignItems: "center",
                  minHeight: "unset",
                  height: "12px",
                  paddingRight: "0px",
                  paddingLeft: "0px",
                  paddingTop: "4px",
                  paddingBottom: "4px",
                  position: "relative",
                  ":hover": {
                    backgroundColor: "unset",
                    color: "red",
                    textDecoration: "underline",
                  },
                }}
                endEnhancer={
                  <span
                    className={css({
                      marginLeft: "-6px",
                      display: "flex",
                      alignItems: "center",
                    })}
                  >
                    <X size="16" />
                  </span>
                }
                onClick={endSubstitution}
              >
                Zakończ zastępstwo
              </Button>

              <Button
                kind="minimal"
                $style={{
                  fontSize: "12px",
                  marginLeft: "16px",
                  display: "flex",
                  alignItems: "center",
                  minHeight: "unset",
                  height: "12px",
                  //width: "65px",
                  paddingRight: "0px",
                  paddingLeft: "0px",
                  paddingTop: "4px",
                  paddingBottom: "4px",
                  flexShrink: 0,
                  ":hover": {
                    backgroundColor: "unset",
                    color: "black",
                    textDecoration: "underline",
                  },
                  ":focus": {
                    backgroundColor: "unset",
                    color: "black",
                    textDecoration: "underline",
                  },
                }}
                endEnhancer={
                  <span
                    className={css({
                      marginLeft: "-6px",
                      display: "flex",
                      alignItems: "center",
                    })}
                  >
                    {!isExpanded ? (
                      <ChevronUp size="16" />
                    ) : (
                      <ChevronDown size="16" />
                    )}
                  </span>
                }
                onClick={() => setIsExpanded((isExpanded) => !isExpanded)}
              >
                {isExpanded ? "Zwiń" : "Rozwiń"}
              </Button>
            </div>
          </Cell>
        </Grid>

        <div
          data-ui="details"
          className={css({
            maxHeight: isExpanded ? "300px" : "0px",
            transform: isExpanded ? "translateY(0px)" : "translateY(-16px)",
            marginTop: isExpanded ? "30px" : "0px",
            marginBottom: isExpanded ? "16px" : "0px",
            opacity: isExpanded ? 1 : 0,
            overflow: isExpanded ? "auto" : "hidden",
            transition: "all 200ms ease",
          })}
        >
          <Grid gridMaxWidth={2400}>
            <Cell span={4}>
              <FormControl disabled label="Data rozpoczęcia">
                <FormattedValue dataType="date">
                  {substitution?.startedAt}
                </FormattedValue>
              </FormControl>
            </Cell>

            <Cell span={4}>
              <FormControl disabled label="Data zakończenia">
                <FormattedValue dataType="date">
                  {substitution?.endedAt}
                </FormattedValue>
              </FormControl>
            </Cell>
          </Grid>
        </div>
      </div>
    </div>
  );
}
