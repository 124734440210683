import { FIELDS, FieldsGroup } from "../../fields.d";

export type FormInputs = {
  shipmentContractId?: number;
  shipmentType: number | string;
  eNadawcaShipmentType?: { id: string; label?: string }[];
  description: string;
  symbol: string;
  name: string;
  isActive: boolean;
  price: number | string;
  widthMin: number | string;
  widthMax: number | string;
  heightMin: number | string;
  heightMax: number | string;
  lengthMin: number | string;
  lengthMax: number | string;
  weightMin: number | string;
  weightMax: number | string;
  isCountryService: boolean;
  countryCodes: { id: string; label?: string }[];
};

export const SHIPMENT_RATES_FIELDS: FieldsGroup[] = [
  {
    id: "basic",
    label: "Podstawowe informacje",
    fields: [
      {
        id: "name",
        label: "Nazwa",
        type: FIELDS.Input,
        span: 6,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "price",
        label: "Cena",
        type: FIELDS.NumberInput,
        dataType: "price",
        span: 2,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "symbol",
        label: "Symbol",
        type: FIELDS.Input,
        span: 2,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "isActive",
        label: "Aktywna",
        type: FIELDS.Checkbox,
        dataType: "boolean",
        span: 2,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "description",
        label: "Opis",
        type: FIELDS.Input,
        span: 6,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "shipmentContractId",
        label: "Cennik",
        type: FIELDS.Input,
        dataType: "model:shipment-contracts",
        span: 6,
        show: { visible: true, accessor: ["shipmentContract", "name"] },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "shipmentType",
        label: "Typ wysyłki",
        type: FIELDS.NumberInput,
        span: 2,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "eNadawcaShipmentType",
        label: "Typ wysyłki E-nadawcy",
        type: FIELDS.ENadawcaShipmentParcelsSelect,
        dataType: "e-nadawca-shipment-type",
        span: 4,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "countryCodes",
        label: "Kraje",
        type: FIELDS.Input,
        span: 12,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "countryCodes",
        label: "Kody krajów",
        type: FIELDS.DictionaryValuesSelect,
        span: 12,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
    ],
  },
  {
    id: "dimensions",
    label: "Wymiary i waga",
    fields: [
      {
        id: "widthMin",
        label: "Minimalna szerokość",
        type: FIELDS.NumberInput,
        dataType: "dimensions",
        span: 3,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "widthMax",
        label: "Maksymalna szerokość",
        type: FIELDS.NumberInput,
        dataType: "dimensions",
        span: 3,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "width",
        label: "Szerokość",
        type: FIELDS.NumberInput,
        dataType: "dimensions",
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "heightMin",
        label: "Minimalna wysokość",
        type: FIELDS.NumberInput,
        dataType: "dimensions",
        span: 3,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "heightMax",
        label: "Maksymalna wysokość",
        type: FIELDS.NumberInput,
        dataType: "dimensions",
        span: 3,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "height",
        label: "Wysokość",
        type: FIELDS.NumberInput,
        dataType: "dimensions",
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "lengthMin",
        label: "Minimalna długość",
        type: FIELDS.NumberInput,
        dataType: "dimensions",
        span: 3,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "lengthMax",
        label: "Maksymalna długość",
        type: FIELDS.NumberInput,
        dataType: "dimensions",
        span: 3,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "length",
        label: "Długość",
        type: FIELDS.NumberInput,
        dataType: "dimensions",
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "weightMin",
        label: "Minimalna waga",
        type: FIELDS.NumberInput,
        dataType: "weight",
        span: 3,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "weightMax",
        label: "Maksymalna waga",
        type: FIELDS.NumberInput,
        dataType: "weight",
        span: 3,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "weight",
        label: "Waga",
        type: FIELDS.NumberInput,
        dataType: "weight",
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
    ],
  },
];
