import React, { createContext, useContext, useState } from "react";

import { FiltersState } from "../filters";

type SharedFilters = {
  id: string;
  state: FiltersState;
}[];

type FiltersContextProps = {
  filters: SharedFilters;
  setFilters: (filters: SharedFilters) => void;
};

export const FiltersContext = createContext<FiltersContextProps>(
  {} as FiltersContextProps
);

export function FiltersProvider({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement {
  const [filters, setFilters] = useState<SharedFilters>([]);

  return (
    <FiltersContext.Provider value={{ filters, setFilters }}>
      {children}
    </FiltersContext.Provider>
  );
}

export const useFilters = (): FiltersContextProps => useContext(FiltersContext);
