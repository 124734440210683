import { ApolloError, useMutation } from "@apollo/client";
import { KIND } from "baseui/button";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Redirect, useHistory } from "react-router-dom";
import { DeviceFloppy, FileOff, Note } from "tabler-icons-react";

import ConfirmDialog from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import FormRenderer from "../../../components/form-renderer";
import Header from "../../../components/header";
import { useAuth } from "../../../contexts/auth-context";
import { useLoading } from "../../../contexts/loading-context";
import { checkPermission } from "../../../utils/check-permission";
import { PERMISSIONS } from "../../../utils/permissions";
import {
  SubstitutionFormInputs,
  SUBSTITUTIONS_FIELDS,
} from "../substitutions.form";
import { SUBSTITUTIONS_CREATE } from "../substitutions.gql";

export default function SubstitutionsCreate(): React.ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const [isClearConfirmDialogOpen, setIsClearConfirmDialogOpen] = useState(
    false
  );
  const { user } = useAuth();
  const history = useHistory();
  const [createSubstitution, { error }] = useMutation(SUBSTITUTIONS_CREATE);
  const { isLoading, setIsLoading } = useLoading();

  const {
    control,
    formState: { errors, isDirty, ...formState },
    handleSubmit,
    reset,
    ...methods
  } = useForm<SubstitutionFormInputs>({
    defaultValues: {
      substitutee: [{ id: user?.id, ...user }],
    },
  });

  const onSubmit = async ({
    substitutee,
    substituter,
    endedAt,
    ...values
  }: SubstitutionFormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      const data = await createSubstitution({
        variables: {
          substitutionCreateInput: {
            substituteeId: substitutee && substitutee[0].id,
            substituterId: substituter && substituter[0].id,
            endedAt: endedAt && new Date(endedAt.setHours(23, 59, 59)),
            ...values,
          },
        },
      });

      enqueueSnackbar({
        message: "Utworzono pomyślnie",
        variant: "success",
      });

      history.push("/substitutions/" + data.data?.substitutionCreate?.id);
    } catch (error: unknown) {
      enqueueSnackbar({
        message:
          (error as ApolloError).graphQLErrors?.map(
            ({ message }) => message
          )[0] || (error as ApolloError).message,
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (!checkPermission(PERMISSIONS.substitution.create))
    return <Redirect to="/" />;

  return (
    <article>
      <Header
        title="Nowe zastępstwo"
        labels={["Zastępstwa", "Tworzenie"]}
        goBackOption
        buttons={[
          {
            label: "Wyczyść",
            kind: KIND.secondary,
            startEnhancer: <Note size={18} />,
            disabled: isLoading,
            onClick: () => {
              setIsClearConfirmDialogOpen(true);
            },
          },
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              isDirty ? setIsCancelConfirmDialogOpen(true) : history.goBack(),
          },
          {
            label: "Utwórz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            onClick: handleSubmit(onSubmit),
            disabled: isLoading,
            isLoading: isLoading,
            type: "submit",
            formId: "createSubstitution",
          },
        ]}
      />
      <Content>
        <FormProvider<SubstitutionFormInputs>
          control={control}
          formState={{ errors, isDirty, ...formState }}
          handleSubmit={handleSubmit}
          reset={reset}
          {...methods}
        >
          <FormRenderer
            id="createSubstitution"
            onSubmit={handleSubmit(onSubmit)}
            type="create"
            isLoading={isLoading}
            error={error}
            fields={SUBSTITUTIONS_FIELDS}
          />
        </FormProvider>

        <ConfirmDialog
          isOpen={isCancelConfirmDialogOpen}
          label="Anulowanie tworzenia nowego zastępstwa"
          close={() => setIsCancelConfirmDialogOpen(false)}
          confirm={() => history.goBack()}
        />

        <ConfirmDialog
          isOpen={isClearConfirmDialogOpen}
          label="Wyczyszczenie formularza"
          close={() => setIsClearConfirmDialogOpen(false)}
          confirm={() => {
            reset();
            setIsClearConfirmDialogOpen(false);
          }}
        />
      </Content>
    </article>
  );
}
