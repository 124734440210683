import { ApolloError, useMutation } from "@apollo/client";
import { useStyletron } from "baseui";
import { KIND } from "baseui/button";
import { ModalBody, ModalFooter, ModalHeader } from "baseui/modal";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { AlertOctagon } from "tabler-icons-react";

import { InputValidationError } from "../api";
import { ShareFormInputs } from "../containers/Documents/documents.form";
import { DOCUMENTS_SHARE } from "../containers/Documents/documents.gql";
import { AssignmentShorthand } from "../containers/Folders/folders.d";
import { useAuth } from "../contexts/auth-context";
import { useLoading } from "../contexts/loading-context";
import { useSubstitution } from "../contexts/substitution-context";
import { formValidation } from "../utils/formValidation";
import Button from "./button";
import FormControl from "./form-control";
import Modal from "./modal";
import ControlledRadioButton from "./radio-button";
import {
  ControlledOrganizationalUnitsSelect,
  ControlledUsersLiveSearchTableSelect,
} from "./select";
import { ControlledTextArea } from "./text-area";

type DocumentShareModalProps = {
  assignments?: AssignmentShorthand[];
  isOpen: boolean;
  onCompleted: () => void;
  close: () => void;
};

export default function DocumentShareModal({
  assignments,
  isOpen,
  close,
  onCompleted,
}: DocumentShareModalProps): React.ReactElement {
  const [css] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();
  const { isLoading, setIsLoading } = useLoading();

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
    reset,
    unregister,
  } = useForm<ShareFormInputs>({
    defaultValues: {
      variant: "users",
      users: [],
      organizationalUnits: [],
      comment: "",
    },
  });

  const choosedVariant = watch("variant");

  useEffect(() => {
    if (choosedVariant === "users") {
      setValue("organizationalUnits", []);
      unregister("organizationalUnits");
    } else {
      setValue("users", []);
      unregister("users");
    }
  }, [choosedVariant]);

  const { isSubstitutionActive, substitution } = useSubstitution();

  const [shareDocument, { error }] = useMutation(DOCUMENTS_SHARE);

  const onSubmit = async ({
    users,
    organizationalUnits,
    comment,
  }: ShareFormInputs): Promise<void> => {
    if (!assignments?.length) return;

    setIsLoading(true);

    try {
      await Promise.all(
        assignments.map(({ id, primaryAssignableId }: AssignmentShorthand) =>
          shareDocument({
            variables: {
              documentShareInput: {
                documentId: primaryAssignableId,
                comment,
                ...(choosedVariant === "users"
                  ? {
                      shareeIds: users?.map(({ id }) => id),
                    }
                  : {
                      organizationalUnitId: organizationalUnits?.[0]?.id,
                    }),
              },
            },
            context: {
              headers: {
                Assignment: id,
              },
            },
          })
        )
      );

      enqueueSnackbar({
        message: "Udostępniono pomyślnie",
        variant: "success",
      });

      onCompleted();
      reset();
      close();
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={close} $style={{ width: "680px" }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>Udostępnianie dokumentów</ModalHeader>
        <ModalBody>
          <ControlledRadioButton
            control={control}
            name="variant"
            buttons={[
              {
                id: "users",
                label: "Użytkownikom",
              },
              {
                id: "organizationalUnit",
                label: "Jednostce organizacyjnej",
              },
            ]}
          />

          <div>
            <FormControl
              label={
                choosedVariant === "users"
                  ? "Użytkownicy"
                  : "Jednostka organizacyjna"
              }
              required
              disabled={isLoading}
              error={
                ((errors as any)[
                  choosedVariant === "users" ? "users" : "organizationalUnits"
                ] &&
                  (errors as any)[
                    choosedVariant === "users" ? "users" : "organizationalUnits"
                  ].message) ||
                (error &&
                  error.graphQLErrors[0]?.extensions?.code ===
                    "INPUT_VALIDATION_ERROR" &&
                  error.graphQLErrors[0]?.extensions?.validationErrors
                    ?.find(
                      (vE: InputValidationError) => vE?.property === "userIds"
                    )
                    ?.errors.map((message: string) => (
                      <div
                        key="error"
                        className={css({
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        })}
                      >
                        {message}
                        <AlertOctagon color="#999" size={12} />
                      </div>
                    ))[0])
              }
            >
              {choosedVariant === "users" ? (
                <ControlledUsersLiveSearchTableSelect
                  control={control}
                  name="users"
                  id="users"
                  placeholder="Wybierz"
                  multi
                  filterOptions={(options) =>
                    options.filter((o) =>
                      isSubstitutionActive
                        ? o.id !== substitution?.substitutee?.id
                        : o.id !== user?.id
                    )
                  }
                  rules={{
                    required: formValidation.messages.required,
                  }}
                />
              ) : (
                <ControlledOrganizationalUnitsSelect
                  control={control}
                  name="organizationalUnits"
                  id="organizationalUnits"
                  placeholder="Wybierz"
                  filterOptions={(options, filterValue) =>
                    options.filter(
                      (o) =>
                        (isSubstitutionActive
                          ? o.id !== substitution?.substitutee?.id
                          : o.id !== user?.id) &&
                        o?.label
                          ?.toString()
                          .toLocaleLowerCase()
                          .includes(filterValue.toLocaleLowerCase())
                    )
                  }
                  rules={{
                    required: formValidation.messages.required,
                  }}
                />
              )}
            </FormControl>

            <FormControl
              label="Komentarz"
              disabled={isLoading}
              error={
                ((errors as any)["comment"] &&
                  (errors as any)["comment"].message) ||
                (error &&
                  error.graphQLErrors[0]?.extensions?.code ===
                    "INPUT_VALIDATION_ERROR" &&
                  error.graphQLErrors[0]?.extensions?.validationErrors
                    ?.find(
                      (vE: InputValidationError) => vE?.property === "comment"
                    )
                    ?.errors.map((message: string) => (
                      <div
                        key="error"
                        className={css({
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        })}
                      >
                        {message}
                        <AlertOctagon color="#999" size={12} />
                      </div>
                    ))[0])
              }
            >
              <ControlledTextArea control={control} name="comment" />
            </FormControl>
          </div>
        </ModalBody>

        <ModalFooter>
          <Button
            type="button"
            kind={KIND.secondary}
            $style={{ marginRight: "6px" }}
            onClick={() => {
              close();
              reset();
            }}
            disabled={isLoading}
          >
            Anuluj
          </Button>

          <Button disabled={isLoading} isLoading={isLoading} type="submit">
            Udostępnij
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
