import { ApolloError, useMutation } from "@apollo/client";
import { KIND } from "baseui/button";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Redirect, useHistory } from "react-router-dom";
import { DeviceFloppy, FileOff, Note } from "tabler-icons-react";

import ConfirmDialog from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import FormRenderer from "../../../components/form-renderer";
import Header from "../../../components/header";
import { useLoading } from "../../../contexts/loading-context";
import { checkPermission } from "../../../utils/check-permission";
import { PERMISSIONS } from "../../../utils/permissions";
import { scrollOnError } from "../../../utils/scrollOnError";
import { CreateFormInputs, ROLES_FIELDS } from "../roles.form";
import { ROLES_CREATE } from "../roles.gql";

export default function RolesCreate(): React.ReactElement {
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const [isClearConfirmDialogOpen, setIsClearConfirmDialogOpen] = useState(
    false
  );
  const [errorTimeStamp, setErrorTimeStamp] = useState<number>();

  const { enqueueSnackbar } = useSnackbar();

  const {
    control,
    formState: { errors, isDirty, ...formState },
    handleSubmit,
    reset,
    ...methods
  } = useForm<CreateFormInputs>({
    defaultValues: {
      name: "",
      description: "",
      permissionIds: [],
    },
  });

  const history = useHistory();

  const [createRole, { error }] = useMutation(ROLES_CREATE);

  const { isLoading, setIsLoading } = useLoading();

  const onError = (errors: Record<string, unknown>, e: any) =>
    setErrorTimeStamp(e.timeStamp);

  const onSubmit = async (values: CreateFormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      const response = await createRole({
        variables: values,
      });

      enqueueSnackbar({
        message: "Utworzono pomyślnie",
        variant: "success",
      });
      history.push(`/roles/${response?.data?.roleCreate?.id}`);
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (errors) scrollOnError(errors, ROLES_FIELDS, "create");
  }, [errorTimeStamp]);

  if (!checkPermission(PERMISSIONS.role.create)) return <Redirect to="/" />;

  return (
    <article>
      <Header
        title="Nowa rola"
        labels={["Role", "Tworzenie"]}
        goBackOption
        buttons={[
          {
            label: "Wyczyść",
            kind: KIND.secondary,
            startEnhancer: <Note size={18} />,
            disabled: isLoading,
            onClick: () => {
              setIsClearConfirmDialogOpen(true);
            },
          },
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              isDirty ? setIsCancelConfirmDialogOpen(true) : history.goBack(),
          },
          {
            label: "Utwórz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            onClick: handleSubmit(onSubmit, onError),
            disabled: isLoading,
            isLoading: isLoading,
            type: "submit",
            formId: "createRole",
          },
        ]}
      />
      <Content>
        <FormProvider<CreateFormInputs>
          control={control}
          formState={{ errors, isDirty, ...formState }}
          handleSubmit={handleSubmit}
          reset={reset}
          {...methods}
        >
          <FormRenderer
            id="createRole"
            onSubmit={handleSubmit(onSubmit)}
            type="create"
            isLoading={isLoading}
            error={error}
            fields={ROLES_FIELDS}
          />
        </FormProvider>

        <ConfirmDialog
          isOpen={isCancelConfirmDialogOpen}
          label="Anulowanie tworzenia nowej roli"
          close={() => setIsCancelConfirmDialogOpen(false)}
          confirm={() => history.goBack()}
        />

        <ConfirmDialog
          isOpen={isClearConfirmDialogOpen}
          label="Wyczyszczenie formularza"
          close={() => setIsClearConfirmDialogOpen(false)}
          confirm={() => {
            reset();
            setIsClearConfirmDialogOpen(false);
          }}
        />
      </Content>
    </article>
  );
}
