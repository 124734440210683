import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND } from "baseui/button";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { AlertOctagon, DeviceFloppy, FileOff, Note } from "tabler-icons-react";

import { InputValidationError } from "../../../api";
import Cell from "../../../components/cell";
import { ControlledCheckbox } from "../../../components/checkbox";
import ConfirmDialog from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import FormControl from "../../../components/form-control";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import { ControlledInput } from "../../../components/input";
import ControlledRadioButton from "../../../components/radio-button";
import { ControlledDocumentsFieldsTableSelect } from "../../../components/select";
import StepRecipientSelector, {
  RecipientType,
} from "../../../components/step-recipient-selector";
import { useLoading } from "../../../contexts/loading-context";
import { FIELDS } from "../../../fields.d";
import { checkPermission } from "../../../utils/check-permission";
import { renderDocumentFieldsOptions } from "../../../utils/document-flow";
import { formValidation } from "../../../utils/formValidation";
import { PERMISSIONS } from "../../../utils/permissions";
import { DOCUMENT_FLOW_SHORT, DOCUMENT_FLOW_STEP_CREATE } from "../flow.gql";
import { FLOW_STEPS_FIELDS, FormInputs } from "../flow-steps.form";

export default function FlowCreateStep(): React.ReactElement {
  const [css] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { flowId } = useParams<{ flowId?: string | undefined }>();
  const { isLoading, setIsLoading } = useLoading();
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const [isClearConfirmDialogOpen, setIsClearConfirmDialogOpen] = useState(
    false
  );

  const { data: documentFlowData, refetch } = useQuery(DOCUMENT_FLOW_SHORT, {
    variables: {
      id: flowId ? parseInt(flowId) : null,
    },
  });

  const [createDocumentFlowStep, { error }] = useMutation(
    DOCUMENT_FLOW_STEP_CREATE
  );

  const {
    control,
    formState: { errors, isDirty, ...formState },
    handleSubmit,
    reset,
    setValue,
    unregister,
    watch,
    ...methods
  } = useForm<FormInputs>({
    defaultValues: {
      name: "",
      recipientType: RecipientType.DocumentFlowStarter,
    },
  });

  const watchRecipientType = watch("recipientType");

  const onSubmit = async ({
    name,
    isDeletable,
    isInitial,
    disabledDocumentFields,
    hiddenDocumentFields,
    organizationalUnit,
    users,
    positions,
    positionsInOrganizationalUnit,
    groups,
    isPaymentCreationEnabled,
    isLegalSectionEnabled,
    isAllowedToEditAllFields,
    documentFields,
  }: FormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      await createDocumentFlowStep({
        variables: {
          documentFlowStepCreateInput: {
            name,
            isInitial,
            isDeletable,
            disabledDocumentFields,
            hiddenDocumentFields,
            documentFlowId: flowId ? parseInt(flowId) : null,
            isPaymentCreationEnabled: !!isPaymentCreationEnabled,
            isLegalSectionEnabled: !!isLegalSectionEnabled,
            isAllowedToEditAllFields,
            allowedDocumentFields: documentFields?.map(
              ({ id }: { id: string }) => id
            ),
            isProcessedByDocumentFlowStarter:
              watchRecipientType === RecipientType.DocumentFlowStarter,
            ...(watchRecipientType !== RecipientType.DocumentFlowStarter && {
              ...(users?.length
                ? {
                    userIds: users?.map(({ id }: { id: number }) => id),
                    positionIds: [],
                  }
                : { userIds: [] }),
              ...(!!organizationalUnit?.length &&
                organizationalUnit[0].id && {
                  organizationalUnitId: organizationalUnit[0].id,
                }),
              ...(!!positions?.length && {
                positionIds: positions.map(({ id }: { id: number }) => id),
              }),
              ...(!!positionsInOrganizationalUnit?.length && {
                positionIds: positionsInOrganizationalUnit.map(
                  ({ id }: { id: number }) => id
                ),
              }),
              ...(!!groups?.length &&
                groups[0].id && {
                  groupId: groups[0].id,
                }),
            }),
          },
        },
      });

      await enqueueSnackbar({
        message: "Utworzono pomyślnie",
        variant: "success",
      });

      history.push(`/flow/${flowId}`);
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (documentFlowData) refetch();
  }, []);

  const documentKind = documentFlowData?.documentFlow.documentKind;
  const documentType = documentFlowData?.documentFlow.documentType;
  const watchiIsAllowedToEditAllFields = watch("isAllowedToEditAllFields");
  const watchDocumentFields = watch("documentFields");
  const allDocumentFields = renderDocumentFieldsOptions(
    documentKind,
    documentType
  );

  useEffect(() => {
    if (watchiIsAllowedToEditAllFields) {
      setValue("documentFields", allDocumentFields);
    }
  }, [watchiIsAllowedToEditAllFields, allDocumentFields, setValue]);

  useEffect(() => {
    const allDocumentFieldsLength = allDocumentFields.length;
    const choosedDocumentFieldsLength = watchDocumentFields?.length || 0;

    if (
      choosedDocumentFieldsLength > 0 &&
      choosedDocumentFieldsLength < allDocumentFieldsLength
    ) {
      setValue("isAllowedToEditAllFields", false);
    }

    if (choosedDocumentFieldsLength === allDocumentFieldsLength) {
      setValue("isAllowedToEditAllFields", true);
    }
  }, [watchDocumentFields, allDocumentFields, setValue]);

  if (!checkPermission(PERMISSIONS.documentFlowStep.create))
    return <Redirect to="/" />;

  return (
    <article>
      <Header
        title={`Nowy krok w procesie: ${documentFlowData?.documentFlow?.name}`}
        labels={["Kroki", "Tworzenie"]}
        goBackOption
        buttons={[
          {
            label: "Wyczyść",
            kind: KIND.secondary,
            startEnhancer: <Note size={18} />,
            disabled: isLoading,
            onClick: () => {
              setIsClearConfirmDialogOpen(true);
            },
          },
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              isDirty ? setIsCancelConfirmDialogOpen(true) : history.goBack(),
          },
          {
            label: "Utwórz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            onClick: handleSubmit(onSubmit),
            disabled: isLoading,
            isLoading: isLoading,
            type: "submit",
            formId: "createStep",
          },
        ]}
      />
      <Content>
        <form id="createStep" onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            {FLOW_STEPS_FIELDS.filter(
              (g) =>
                g.fields.filter(
                  (f) =>
                    f.createStep.visible &&
                    (!f.documentType ||
                      (f.documentType &&
                        f.documentType.includes(
                          documentFlowData?.documentFlow?.documentType
                        )))
                ).length > 0
            ).map((group) => [
              group.label && (
                <Cell key={group.id + `-group`} span={12}>
                  <LabelMedium marginBottom="scale200" marginTop="scale600">
                    {group.label}
                  </LabelMedium>
                  <hr
                    className={css({
                      borderWidth: "0px",
                      height: "1px",
                      backgroundColor: "#eee",
                    })}
                  />
                </Cell>
              ),
              group.fields
                .filter(
                  (f) =>
                    f.createStep.visible &&
                    (!f.documentType ||
                      (f.documentType &&
                        f.documentType.includes(
                          documentFlowData?.documentFlow?.documentType
                        )))
                )
                .map((item, index) => (
                  <Cell
                    span={item.span || 6}
                    key={group.id + `-field` + index}
                    skip={item.skip}
                  >
                    <FormControl
                      label={item.type === FIELDS.Checkbox ? "" : item.label}
                      caption={item.caption}
                      required={item.createStep.required}
                      error={
                        ((errors as any)[item.id] &&
                          (errors as any)[item.id].message) ||
                        (error &&
                          error.graphQLErrors[0]?.extensions?.code ===
                            "INPUT_VALIDATION_ERROR" &&
                          error.graphQLErrors[0]?.extensions?.validationErrors
                            ?.find(
                              (vE: InputValidationError) =>
                                vE?.property === item.id
                            )
                            ?.errors.map((message: string) => (
                              <div
                                key="error"
                                className={css({
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                })}
                              >
                                {message} <br />
                                {item.caption}
                                <AlertOctagon color="#999" size={12} />
                              </div>
                            ))[0])
                      }
                      disabled={isLoading}
                    >
                      {item.type === FIELDS.RadioButton ? (
                        <ControlledRadioButton
                          control={control}
                          name={item.id}
                          variant="vertical"
                          buttons={[
                            {
                              id: RecipientType.DocumentFlowStarter,
                              label: "Rozpoczynający obieg",
                            },
                            {
                              id: RecipientType.DocumentOrganizationalUnit,
                              label: "Jednostka dokumentu",
                            },
                            {
                              id: RecipientType.OrganizationalUnit,
                              label: "Jednostka organizacyjna",
                            },
                            {
                              id: RecipientType.Users,
                              label: "Wybrani użytkownicy",
                            },
                            {
                              id: RecipientType.Groups,
                              label: "Grupa użytkowników",
                            },
                          ]}
                        />
                      ) : item.type === FIELDS.Checkbox ? (
                        <Block marginTop="10px">
                          <ControlledCheckbox
                            control={control}
                            name={item.id}
                            disabled={isLoading}
                            {...(item.createStep.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                          >
                            {item.label}
                          </ControlledCheckbox>
                        </Block>
                      ) : (
                        <ControlledInput
                          control={control}
                          name={item.id}
                          placeholder={item.placeholder}
                          {...(item.createStep.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      )}
                    </FormControl>
                  </Cell>
                )),
            ])}

            <Cell span={6}>
              <FormProvider<FormInputs>
                control={control}
                formState={{ errors, isDirty, ...formState }}
                handleSubmit={handleSubmit}
                reset={reset}
                setValue={setValue}
                unregister={unregister}
                watch={watch}
                {...methods}
              >
                <StepRecipientSelector />
              </FormProvider>
            </Cell>

            <Cell span={12}>
              <LabelMedium marginBottom="scale200" marginTop="scale600">
                Pola dozwolone do edycji
              </LabelMedium>
              <hr
                className={css({
                  borderWidth: "0px",
                  height: "1px",
                  backgroundColor: "#eee",
                })}
              />
            </Cell>

            <Cell span={2}>
              <Block marginTop="5px">
                <ControlledCheckbox
                  control={control}
                  name={"isAllowedToEditAllFields"}
                  disabled={isLoading}
                >
                  Wszystkie
                </ControlledCheckbox>
              </Block>
            </Cell>

            <Cell span={6}>
              <FormControl label="Wybrane pola" disabled={isLoading}>
                <ControlledDocumentsFieldsTableSelect
                  control={control}
                  name="documentFields"
                  multi
                  documentKind={documentKind}
                  documentType={documentType}
                />
              </FormControl>
            </Cell>

            <ConfirmDialog
              isOpen={isCancelConfirmDialogOpen}
              label="Anulowanie tworzenia nowego kroku"
              close={() => setIsCancelConfirmDialogOpen(false)}
              confirm={() => history.goBack()}
            />
            <ConfirmDialog
              isOpen={isClearConfirmDialogOpen}
              label="Wyczyszczenie formularza"
              close={() => setIsClearConfirmDialogOpen(false)}
              confirm={() => {
                reset();
                setIsClearConfirmDialogOpen(false);
              }}
            />
          </Grid>
        </form>
      </Content>
    </article>
  );
}
