import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { StyledLink } from "baseui/link";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { MouseEvent, useEffect, useState } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { Row } from "react-table";
import { Check, Eye, Trash, Truck, X } from "tabler-icons-react";

import Button from "../../../components/button";
import Cell from "../../../components/cell";
import ConfirmDialog from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import FormControl from "../../../components/form-control";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import SendingListErrorModal from "../../../components/sending-list-error-modal";
import Table from "../../../components/table";
import Tooltip from "../../../components/tooltip";
import { useDictionaries } from "../../../contexts/dictionaries-context";
import { useLoading } from "../../../contexts/loading-context";
import { checkPermission } from "../../../utils/check-permission";
import { PERMISSIONS } from "../../../utils/permissions";
import { SendingListItem } from "../sending-lists";
import { SENDING_LISTS_FIELDS } from "../sending-lists.form";
import {
  SENDING_LIST_DELETE,
  SENDING_LISTS_DISPATCH_ITEMS,
  SENDING_LISTS_ITEM_UPDATE,
  SENDING_LISTS_SHOW,
} from "../sending-lists.gql";

export default function SendingListsShowList(): React.ReactElement {
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState(
    false
  );
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [css, theme] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { listId } = useParams<{ listId?: string }>();
  const { isFetching, setIsFetching, setIsLoading } = useLoading();
  const { findValue } = useDictionaries();
  const { refetch, data, error } = useQuery(SENDING_LISTS_SHOW, {
    variables: { id: listId ? parseInt(listId) : null },
    notifyOnNetworkStatusChange: true,
  });

  const [sendLists, { error: mutationError, loading }] = useMutation(
    SENDING_LISTS_DISPATCH_ITEMS
  );

  const onSubmit = async (): Promise<void> => {
    setIsLoading(true);
    try {
      await sendLists({
        variables: {
          sendingListId: listId ? parseInt(listId) : null,
          urzadNadania: process.env.REACT_APP_E_NADAWCA_URZAD_NADANIA_ID,
        },
      });

      enqueueSnackbar({
        message: "Wysyłki zostały przekazane do systemu eNadawca",
        variant: "success",
      });

      refetch();
    } catch (error: unknown) {
      setErrorText(
        (error as ApolloError)?.graphQLErrors?.map(({ message }) => message)[0]
      );
      setIsErrorDialogOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const [deleteSendingList] = useMutation(SENDING_LIST_DELETE);

  const deleteSubmit = async () => {
    setIsLoading(true);

    try {
      await deleteSendingList({
        variables: {
          eNadawcaSendingListDeleteInput: {
            id: listId ? parseInt(listId) : null,
          },
        },
      });

      enqueueSnackbar({
        message: "Usunięto pomyślnie",
        variant: "success",
      });

      setIsConfirmDeleteDialogOpen(false);

      history.push("/sending-lists");
    } catch (error: unknown) {
      setIsConfirmDeleteDialogOpen(false);

      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const [changeItemStatus] = useMutation(SENDING_LISTS_ITEM_UPDATE);

  const changeStatusSubmit = async (
    itemId: number,
    isReadyForShipment: boolean
  ): Promise<void> => {
    setIsLoading(true);

    try {
      await changeItemStatus({
        variables: {
          eNadawcaSendingListItemUpdateInput: {
            id: itemId,
            isReadyForShipment: !isReadyForShipment,
          },
        },
      });
      enqueueSnackbar({
        message: "Status został zmieniony",
        variant: "success",
      });
      refetch();
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: (
          <Block display="flex" justifyContent="flex-end">
            Lp.
          </Block>
        ),
        id: "id",
        Cell: ({ row }: { row: Row<SendingListItem> }) => (
          <Block display="flex" justifyContent="flex-end">
            <StyledLink
              onClick={(event: MouseEvent) => {
                event.preventDefault();
                history.push(`/sending-lists/${listId}/${row?.original?.id}`);
              }}
              href={`/sending-lists/${listId}/${row?.original?.id}`}
            >
              <FormattedValue>{row?.index + 1}</FormattedValue>
            </StyledLink>
          </Block>
        ),
      },
      {
        Header: "Dokument",
        id: "document",
        Cell: ({ row }: { row: Row<SendingListItem> }) => {
          return (
            <>
              {row?.original?.documentParcel?.document ? (
                <FormattedValue
                  dataType="model:documents"
                  data={`${row?.original?.documentParcel?.document?.documentKind?.toLowerCase()}/${
                    row?.original?.documentParcel?.document?.type
                  }/${row?.original?.documentParcel?.document?.id}`}
                >{`${
                  !!row?.original?.documentParcel?.document?.documentType &&
                  findValue(
                    row?.original?.documentParcel?.document?.documentType
                  )
                } [${
                  row?.original?.documentParcel?.document?.documentType ===
                  "requestForm"
                    ? row?.original?.documentParcel?.document?.documentNumber
                    : row?.original?.documentParcel?.document?.internalNumber
                }]`}</FormattedValue>
              ) : (
                <FormattedValue />
              )}
            </>
          );
        },
      },
      {
        Header: (
          <Block display="flex" justifyContent="flex-end">
            Szerokość
          </Block>
        ),
        id: "width",
        Cell: ({ row }: { row: Row<SendingListItem> }) => (
          <Block display="flex" justifyContent="flex-end">
            <FormattedValue dataType="pre">
              {`${row?.original?.documentParcel?.width} mm`}
            </FormattedValue>
          </Block>
        ),
      },
      {
        Header: (
          <Block display="flex" justifyContent="flex-end">
            Wysokość
          </Block>
        ),
        id: "height",
        Cell: ({ row }: { row: Row<SendingListItem> }) => (
          <Block display="flex" justifyContent="flex-end">
            <FormattedValue dataType="pre">
              {`${row?.original?.documentParcel?.height} mm`}
            </FormattedValue>
          </Block>
        ),
      },
      {
        Header: (
          <Block display="flex" justifyContent="flex-end">
            Długość
          </Block>
        ),
        id: "length",
        Cell: ({ row }: { row: Row<SendingListItem> }) => (
          <Block display="flex" justifyContent="flex-end">
            <FormattedValue dataType="pre">
              {`${row?.original?.documentParcel?.length} mm`}
            </FormattedValue>
          </Block>
        ),
      },
      {
        Header: (
          <Block display="flex" justifyContent="flex-end">
            Waga
          </Block>
        ),
        id: "weight",
        Cell: ({ row }: { row: Row<SendingListItem> }) => (
          <Block display="flex" justifyContent="flex-end">
            <FormattedValue dataType="pre">
              {`${row?.original?.documentParcel?.weight} g`}
            </FormattedValue>
          </Block>
        ),
      },
      {
        Header: "Pozycja z cennika",
        id: "shipmentRate",
        Cell: ({ row }: { row: Row<SendingListItem> }) => {
          return (
            <>
              {row?.original?.documentParcel?.shipmentRate ? (
                <FormattedValue
                  dataType="model:shipment-contracts"
                  data={`${row?.original?.documentParcel?.shipmentRate?.shipmentContractId}/${row?.original?.documentParcel?.shipmentRate?.id}`}
                >
                  {`${row?.original?.documentParcel?.shipmentRate?.symbol} [${row?.original?.documentParcel?.shipmentRate?.name}]`}
                </FormattedValue>
              ) : (
                <FormattedValue />
              )}
            </>
          );
        },
      },
      {
        Header: (
          <Block display="flex" justifyContent="flex-end">
            Cena
          </Block>
        ),
        id: "price",
        Cell: ({ row }: { row: Row<SendingListItem> }) => (
          <Block display="flex" justifyContent="flex-end">
            <FormattedValue dataType="price">
              {row?.original?.shipmentRate?.price}
            </FormattedValue>
          </Block>
        ),
      },
      {
        Header: "Status",
        id: "isReadyForShipment",
        Cell: ({ row }: { row: Row<SendingListItem> }) => (
          <FormattedValue dataType="sending-list-status">
            {row?.original?.isReadyForShipment}
          </FormattedValue>
        ),
      },
      {
        id: "actions",
        Cell: ({ row }: { row: Row<SendingListItem> }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            {!data?.eNadawcaSendingList?.isSent && (
              <Tooltip
                content={
                  row?.original?.isReadyForShipment
                    ? `Zmień status na "Brak"`
                    : `Zmień status na "Przyjęta"`
                }
                placement="bottom"
              >
                <span>
                  <Button
                    kind={KIND.secondary}
                    size={SIZE.mini}
                    onClick={() =>
                      changeStatusSubmit(
                        row?.original?.id,
                        row?.original?.isReadyForShipment
                      )
                    }
                  >
                    {row?.original?.isReadyForShipment ? (
                      <X size={12} />
                    ) : (
                      <Check size={12} />
                    )}
                  </Button>
                </span>
              </Tooltip>
            )}

            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              $style={{ marginLeft: "6px" }}
              $as="a"
              href={`/sending-lists/${listId}/${row.original.id}`}
              onClick={(e) => {
                e.preventDefault();
                history.push(`/sending-lists/${listId}/${row.original.id}`);
              }}
              startEnhancer={<Eye size={14} />}
            />
          </div>
        ),
      },
    ],
    [data, findValue]
  );

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    if (mutationError?.graphQLErrors)
      setErrorText(
        (error as ApolloError)?.graphQLErrors?.map(({ message }) => message)[0]
      );
  }, [mutationError]);

  useEffect(() => {
    if (data?.eNadawcaSendingList) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => data?.eNadawcaSendingList && setIsFetching(false), [data]);

  if (!checkPermission(PERMISSIONS.case.read)) return <Redirect to="/" />;

  return (
    <article>
      <Header
        title={`Lista wysyłkowa ${data?.eNadawcaSendingList?.id}`}
        labels={["Listy wysyłkowe", "Wyświetlanie"]}
        goBackOption
        buttons={[
          {
            label: "Przekaż do eNadawca",
            kind: KIND.primary,
            startEnhancer: <Truck size={18} />,
            isLoading: loading,
            onClick: () => onSubmit(),
            disabled:
              !data?.eNadawcaSendingList?.sendingListItems?.length ||
              data?.eNadawcaSendingList?.isSent,
            permission: checkPermission(PERMISSIONS.sendingList.update),
          },
        ]}
        actions={[
          {
            label: "Usuń listę",
            icon: Trash,
            color: theme.colors.negative,
            onClick: () => setIsConfirmDeleteDialogOpen(true),
            permission: !data?.eNadawcaSendingList?.sendingListItems?.length,
          },
        ]}
      />
      <Content>
        <Grid>
          {SENDING_LISTS_FIELDS.filter(
            (g) => g.fields.filter((f) => f.show.visible).length > 0
          ).map((group) => [
            group.label && (
              <Cell key={group.id + `-group`} span={12}>
                <LabelMedium>{group.label}</LabelMedium>
                <hr
                  className={css({
                    borderWidth: "0px",
                    height: "1px",
                    backgroundColor: "#eee",
                  })}
                />
              </Cell>
            ),
            group.fields
              .filter((f) => f.show.visible)
              .map((item, index) => (
                <Cell span={item.span || 6} key={group.id + `-field` + index}>
                  <FormControl label={item.label} disabled={true}>
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : (
                      <FormattedValue
                        dataType={item.dataType}
                        data={data?.eNadawcaSendingList[item.id]}
                      >
                        {item.show.accessor
                          ? data?.eNadawcaSendingList[item.show.accessor[0]]?.[
                              item.show.accessor[1]
                            ]
                          : data?.eNadawcaSendingList[item.id]}
                      </FormattedValue>
                    )}
                  </FormControl>
                </Cell>
              )),
          ])}
          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Pojedyncze wysyłki
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={12} $style={{ position: "relative" }}>
            {isFetching ? (
              <Skeleton rows={0} height="300px" width="100%" animation />
            ) : (
              data && (
                <Table<SendingListItem>
                  columns={columns}
                  data={data?.eNadawcaSendingList?.sendingListItems}
                />
              )
            )}
          </Cell>
          <ConfirmDialog
            isOpen={isConfirmDeleteDialogOpen}
            label={`Usunięcie listy ${data?.eNadawcaSendingList?.id}`}
            close={() => setIsConfirmDeleteDialogOpen(false)}
            confirm={deleteSubmit}
          />
          <SendingListErrorModal
            isOpen={isErrorDialogOpen}
            setIsOpen={setIsErrorDialogOpen}
            label={errorText}
          />
        </Grid>
      </Content>
    </article>
  );
}
