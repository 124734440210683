import { StyleObject } from "styletron-standard";

export const preferredColumnWidth: { [key: string]: string } = {
  checkbox: "30px",
  notification: "100%",
  switch: "200px",
};

export function getColumnWidth(id: string): string | undefined {
  switch (id) {
    case id.match("year|budgetYear")?.input:
      return "100px";

    case id.match("assessmentResult|loginAD")?.input:
      return "120px";

    case id.match(
      "caseId|assigneeId|assignerId|currentStatus|state|actionResult|flowResult|bazaId"
    )?.input:
      return "150px";

    case id.match("subject.id|change|valueAfterChange")?.input:
      return "200px";

    case id.match("accountNumber|internalAccount|activityLogUser")?.input:
      return "250px";

    case id.match("flowUser")?.input:
      return "300px";

    case id.match("ordinalNumber|^id|Id|isConductedElectronically")?.input:
      return "40px";

    case id.match(
      "At|number|Number|count|Count|amount|Amount|rate|Rate|effectiveOn|value|Value|organizationUnitShort"
    )?.input:
      return "150px";

    case id.match("balance|symbol|isHidden|isVisible|isSensitive|isSent|status")
      ?.input:
      return "100px";

    case id.match("isActive")?.input:
      return "100px";

    case id.match("type")?.input:
      return "150px";

    default:
      return preferredColumnWidth[id];
  }
}

export function getColumnDataAlign(id: string): StyleObject {
  switch (id) {
    case id.match("subject.id|assigneeId|assignerId")?.input:
      return {
        justifyContent: "flex-start",
        textAlign: "left",
      };

    case id.match("isHidden|isVisible|isSensitive|isSent|isActive")?.input:
      return {
        justifyContent: "center",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
      };

    case id.match(
      "At|id|Id|number|Number|count|Count|balance|ordinalNumber|amount|Amount|rate|Rate|effectiveOn|value|Value|percent|Date"
    )?.input:
      return {
        display: "flex",
        justifyContent: "flex-end",
        textAlign: "right",
        float: "right",
      };

    default:
      return {
        justifyContent: "flex-start",
        textAlign: "left",
      };
  }
}
