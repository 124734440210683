import { FlexGridItem, FlexGridItemProps } from "baseui/flex-grid";
import {
  Cell as BaseCell,
  CellProps as BaseCellProps,
} from "baseui/layout-grid";
import React from "react";
import { StyleObject } from "styletron-react";

type CellProps = {
  isFlex?: boolean;
  $style?: StyleObject;
  children?: React.ReactChild | React.ReactChild[];
} & BaseCellProps &
  FlexGridItemProps;

export default function Cell({
  isFlex,
  $style,
  ...props
}: CellProps): React.ReactElement {
  if (isFlex)
    return (
      <FlexGridItem
        {...props}
        overrides={{
          Block: {
            style: {
              position: "relative",
              ...$style,
            },
          },
        }}
      />
    );
  else
    return (
      <BaseCell
        {...props}
        overrides={{
          Cell: {
            style: {
              position: "relative",
              ...$style,
            },
          },
        }}
      />
    );
}
