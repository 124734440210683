import { Row, Table } from "@tanstack/react-table";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { LabelSmall } from "baseui/typography";
import React, { ReactElement } from "react";
import { AlertTriangle, Check, Icon } from "tabler-icons-react";

import { declinateSelectedRecordsNumber } from "../utils/table";
import Button, { ForwardedButton } from "./button";
import Popover from "./popover";

export type TableBulkAction = {
  label: string;
  Icon?: Icon;
  onExecute: (selected: string[], rows: Row<any>[], reset: () => void) => void;
  confirmLabel?: string;
  filterRows?: (row: Row<any>) => boolean;
};

type TableBulkActionsProps = {
  bulkActions: TableBulkAction[];
  table: Table<any>;
  setActiveBulkAction: (bulkAction: TableBulkAction) => void;
  setIsBulkActionConfirmDialogOpen: (isOpen: boolean) => void;
};

export default function TableBulkActions({
  bulkActions,
  table,
  setActiveBulkAction,
  setIsBulkActionConfirmDialogOpen,
}: TableBulkActionsProps): ReactElement {
  const [css, theme] = useStyletron();

  return (
    <Block display="flex" alignItems="center" gridColumnGap="8px">
      {bulkActions.map((bulkAction) => {
        const { label, Icon, confirmLabel, onExecute, filterRows } = bulkAction;

        const filteredRows = table
          .getFilteredSelectedRowModel()
          .rows.filter((row) => (filterRows ? filterRows(row) : true));

        const isBulkActionNotPossibleForEverySelectedRow =
          filteredRows.length < table.getFilteredSelectedRowModel().rows.length;

        const isBulkActionNotPossibleForAnySelectedRow =
          filteredRows.length === 0;

        function execute() {
          confirmLabel
            ? setIsBulkActionConfirmDialogOpen(true)
            : onExecute(
                table
                  .getFilteredSelectedRowModel()
                  .rows.filter((row) => (filterRows ? filterRows(row) : true))
                  .map(({ original }) => original.id),
                table
                  .getFilteredSelectedRowModel()
                  .rows.filter((row) => (filterRows ? filterRows(row) : true)),
                () => table.toggleAllRowsSelected(false)
              );
        }

        return isBulkActionNotPossibleForEverySelectedRow &&
          !isBulkActionNotPossibleForAnySelectedRow ? (
          <Popover
            key={label}
            content={({ close }) => (
              <Block padding="scale300" minWidth={"300px"}>
                <LabelSmall
                  display="flex"
                  alignItems="flex-end"
                  $style={{
                    fontWeight: 600,
                    borderBottomWidth: "1px",
                    borderBottomStyle: "dotted",
                    borderBottomColor: theme.colors.inputBorder,
                    paddingBottom: theme.sizing.scale300,
                    marginBottom: theme.sizing.scale500,
                  }}
                >
                  <div
                    className={css({
                      flexShrink: 0,
                      marginRight: "5px",
                      height: "15px",
                    })}
                  >
                    <AlertTriangle size={14} color={theme.colors.warning400} />
                  </div>

                  <span>
                    Akcja nie jest możliwa dla wszystkich zaznaczonych wierszy
                  </span>
                </LabelSmall>

                <LabelSmall>
                  Wobec poniższych obiektów nie zostanie podjęta wskazana akcja:
                </LabelSmall>

                <ol className={css({ fontSize: "12px" })}>
                  {table
                    .getFilteredSelectedRowModel()
                    .rows.filter((row) =>
                      filterRows ? !filterRows(row) : true
                    )
                    .map(({ original }) => (
                      <li key={original.id}>
                        <LabelSmall as="span" $style={{ display: "flex" }}>
                          {original.primaryAssignable?.internalNumber ||
                            original.internalNumber ||
                            original.name ||
                            original.title ||
                            original.id}
                        </LabelSmall>
                      </li>
                    ))}
                </ol>

                <Block
                  display="flex"
                  justifyContent="flex-end"
                  gridColumnGap={theme.sizing.scale400}
                  marginTop={theme.sizing.scale800}
                >
                  <Button
                    key={label}
                    type="button"
                    kind="minimal"
                    size="mini"
                    onClick={close}
                  >
                    Anuluj
                  </Button>

                  <Button
                    key={label}
                    type="button"
                    kind="primary"
                    size="mini"
                    startEnhancer={() =>
                      Icon ? <Icon size={16} /> : <Check size={16} />
                    }
                    onClick={() => {
                      close();
                      setActiveBulkAction(bulkAction);

                      execute();
                    }}
                  >
                    Kontynuuj mimo to
                  </Button>
                </Block>
              </Block>
            )}
            placement="top"
            ignoreBoundary
          >
            <ForwardedButton
              type="button"
              kind="secondary"
              size="mini"
              startEnhancer={() =>
                Icon ? <Icon size={16} /> : <Check size={16} />
              }
              disabled={
                !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
              }
            >
              {label}
            </ForwardedButton>
          </Popover>
        ) : (
          <Button
            key={label}
            type="button"
            kind="secondary"
            size="mini"
            startEnhancer={() =>
              Icon ? <Icon size={16} /> : <Check size={16} />
            }
            onClick={() => {
              setActiveBulkAction(bulkAction);

              execute();
            }}
            disabled={isBulkActionNotPossibleForAnySelectedRow}
          >
            {label}
          </Button>
        );
      })}

      <LabelSmall $style={{ marginLeft: "16px" }}>
        {declinateSelectedRecordsNumber(
          table.getFilteredSelectedRowModel().rows.length
        )}
      </LabelSmall>
    </Block>
  );
}
