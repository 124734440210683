import { Radio, RadioGroup, RadioGroupProps } from "baseui/radio";
import { ChangeEvent } from "react";
import React from "react";
import { Controller, UseControllerProps } from "react-hook-form";

type Props = {
  onChange: (value: string) => void;
  trueText?: string;
  falseText?: string;
} & Omit<RadioGroupProps, "onChange">;

const RadioCheckbox = ({
  onChange,
  trueText,
  falseText,
  name,
  ...rest
}: Props): React.ReactElement => {
  return (
    <RadioGroup
      onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
      align={"horizontal"}
      name={name}
      overrides={{
        RadioGroupRoot: {
          style: {
            display: "flex",
            marginLeft: "-18px",
            marginRight: "-18px",
          },
        },
      }}
      {...rest}
    >
      <Radio
        value={"true"}
        name="true"
        overrides={{
          Root: {
            style: {
              flexGrow: 1,
              flexShrink: 1,
              flexBasis: 0,
              paddingLeft: "18px",
              paddingRight: "18px",
              marginTop: "0px",
              marginBottom: "0px",
            },
          },
          RadioMarkInner: {
            style: ({ $checked }) => ({
              width: $checked ? "6px" : "16px",
              height: $checked ? "6px" : "16px",
            }),
          },
        }}
      >
        {trueText || "Tak"}
      </Radio>

      <Radio
        value={"false"}
        name="false"
        overrides={{
          Root: {
            style: {
              flexGrow: 1,
              flexShrink: 1,
              flexBasis: 0,
              paddingLeft: "18px",
              paddingRight: "18px",
              marginTop: "0px",
              marginBottom: "0px",
            },
          },
          RadioMarkInner: {
            style: ({ $checked }) => ({
              width: $checked ? "6px" : "16px",
              height: $checked ? "6px" : "16px",
            }),
          },
        }}
      >
        {falseText || "Nie"}
      </Radio>
    </RadioGroup>
  );
};

export default RadioCheckbox;

export function ControlledRadioCheckbox({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & Omit<Props, "onChange">): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { name, onChange, onBlur, value } }) => (
        <RadioCheckbox
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          name={name}
          {...rest}
        />
      )}
    />
  );
}
