import { ApolloError, useMutation } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND } from "baseui/button";
import { ModalBody, ModalFooter, ModalHeader } from "baseui/modal";
import { useSnackbar } from "notistack";
import React from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { AlertOctagon } from "tabler-icons-react";

import { InputValidationError } from "../api";
import { DOCUMENT_FLOW_DELETE } from "../containers/Flow/flow.gql";
import { useLoading } from "../contexts/loading-context";
import { formValidation } from "../utils/formValidation";
import Button from "./button";
import FormControl from "./form-control";
import { ControlledInput } from "./input";
import Modal from "./modal";

type DeleteFlowModalProps = {
  flowId?: string;
  isOpen: boolean;
  label: string;
  setIsOpen: (isOpen: boolean) => void;
};

export default function DeleteFlowModal({
  flowId,
  isOpen,
  setIsOpen,
  label,
}: DeleteFlowModalProps): React.ReactElement {
  const [css] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, setIsLoading } = useLoading();
  const history = useHistory();

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<{ password: string }>();

  const [deleteProcess, { error, loading }] = useMutation(DOCUMENT_FLOW_DELETE);

  const submit = async ({ password }: { password: string }) => {
    setIsLoading(true);

    try {
      await deleteProcess({
        variables: {
          documentFlowDeleteInput: {
            id: flowId ? parseInt(flowId) : null,
          },
          passwordConfirmationInput: {
            password,
          },
        },
      });

      enqueueSnackbar({
        message: "Usunięto pomyślnie",
        variant: "success",
      });

      setIsOpen(false);

      history.push("/flow");
    } catch (error: unknown) {
      // setIsOpen(false);

      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => (loading ? null : setIsOpen(false))}
      autoFocus={false}
    >
      <form onSubmit={handleSubmit(submit)} id="deleteStep">
        <ModalHeader>Usunięcie obiegu</ModalHeader>
        <ModalBody>
          <p className={css({ whiteSpace: "pre-wrap" })}>
            Aby usunąć obieg
            {label ? <strong> {label},</strong> : " "} podaj hasło do swojego
            konta.
          </p>
          <Block height="20px" />
          <FormControl
            label="Hasło"
            error={
              ((errors as any)?.password &&
                (errors as any)?.password?.message) ||
              (error &&
                error.graphQLErrors[0]?.extensions?.code ===
                  "INPUT_VALIDATION_ERROR" &&
                error.graphQLErrors[0]?.extensions?.validationErrors
                  ?.find(
                    (vE: InputValidationError) => vE?.property === "password"
                  )
                  ?.errors.map((message: string) => (
                    <div
                      key="error"
                      className={css({
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      })}
                    >
                      {message}
                      <span
                        className={css({
                          color: "#999",
                          marginLeft: "auto",
                          marginRight: "5px",
                        })}
                      >
                        Walidacja serwera
                      </span>
                      <AlertOctagon color="#999" size={12} />
                    </div>
                  ))[0])
            }
            disabled={isLoading}
          >
            <ControlledInput
              control={control}
              type="password"
              name="password"
              autoComplete="off"
              rules={{
                required: formValidation.messages.required,
              }}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            kind={KIND.secondary}
            $style={{ marginRight: "10px" }}
            onClick={() => {
              setIsOpen(false);
              reset();
            }}
            disabled={loading}
          >
            Anuluj
          </Button>
          <Button
            disabled={loading}
            isLoading={loading}
            type="submit"
            formId="deleteStep"
          >
            Potwierdź
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
