import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { SIZE } from "baseui/button";
import { LabelSmall } from "baseui/typography";
import React, { ReactNode } from "react";
import { DotsVertical, Icon } from "tabler-icons-react";

import { ForwardedButton } from "./button";
import Popover from "./popover";

export type Action = {
  label: string;
  icon: Icon;
  onClick?: () => void;
  color?: string;
  permission?: boolean;
};

type ActionsListProps = {
  actions: Action[];
  sticked?: boolean;
  children?: ReactNode;
};

export default function HeaderActionsList({
  actions,
  sticked,
  children,
}: ActionsListProps): React.ReactElement {
  const [css, theme] = useStyletron();

  return (
    <Popover
      content={({ close }) => (
        <Block padding="scale300">
          {!children ? (
            <LabelSmall
              $style={{
                fontWeight: 600,
                borderBottomWidth: "1px",
                borderBottomStyle: "solid",
                borderBottomColor: theme.colors.inputBorder,
                paddingBottom: theme.sizing.scale300,
                marginBottom: "8px",
              }}
            >
              Akcje
            </LabelSmall>
          ) : null}

          {actions.map(
            (action: Action, index: number) =>
              (action.permission || action.permission === undefined) && (
                <div
                  onClick={() => close()}
                  className={css({
                    borderBottomWidth: "1px",
                    borderBottomStyle: "dotted",
                    borderBottomColor: theme.colors.inputBorder,
                    ":last-of-type": {
                      borderBottomWidth: 0,
                    },
                  })}
                >
                  <div
                    key={`action-${index + 1}`}
                    {...(action.onClick && {
                      onClick: action.onClick,
                    })}
                    className={css({
                      display: "flex",
                      alignItems: "center",
                      borderRadius: "6px",
                      marginRight: "8px",
                      padding: "8px 4px",
                      color: action.color || "#000",
                      ":hover": {
                        backgroundColor: "#f9f9f9",
                        cursor: "pointer",
                      },
                    })}
                  >
                    <div
                      className={css({
                        marginRight: "10px",
                        height: "20px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      })}
                    >
                      <action.icon size={16} />
                    </div>
                    <span className={css({ fontSize: "14px" })}>
                      {action.label}
                    </span>
                  </div>
                </div>
              )
          )}
        </Block>
      )}
      placement={children ? "bottom" : "bottomRight"}
      $style={{ zIndex: 150 }}
    >
      {children || (
        <ForwardedButton
          kind="secondary"
          size={sticked ? SIZE.compact : SIZE.default}
          type="button"
          data-test-id="actions"
          $style={{
            position: "relative",
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: "8px",
            paddingRight: "8px",
            height: sticked ? "38px" : "42px",
          }}
        >
          <DotsVertical size={sticked ? 16 : 18} />
        </ForwardedButton>
      )}
    </Popover>
  );
}
