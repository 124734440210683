import { gql } from "@apollo/client";

export const ORGANIZATIONAL_UNITS_INDEX = gql`
  query(
    $offset: Int
    $filter: OrganizationalUnitFilter
    $sorting: [OrganizationalUnitSort!]
  ) {
    organizationalUnits(
      paging: { limit: 1000, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        name
        symbol
        budgetSymbol
        description
        parent {
          id
          name
        }
        parentId
      }
    }
  }
`;

export const ORGANIZATIONAL_UNITS_CREATE = gql`
  mutation($organizationalUnitCreateInput: OrganizationalUnitCreateInput!) {
    organizationalUnitCreate(
      organizationalUnitCreateInput: $organizationalUnitCreateInput
    ) {
      id
    }
  }
`;

export const ORGANIZATIONAL_UNITS_EDIT = gql`
  mutation($organizationalUnitUpdateInput: OrganizationalUnitUpdateInput!) {
    organizationalUnitUpdate(
      organizationalUnitUpdateInput: $organizationalUnitUpdateInput
    ) {
      id
    }
  }
`;

export const ORGANIZATIONAL_UNITS_SHOW = gql`
  query($id: Int!) {
    organizationalUnit(id: $id) {
      id
      name
      symbol
      budgetSymbol
      description
      groups
      parent {
        id
        name
      }
      children {
        id
        name
        symbol
        budgetSymbol
      }
      organizationalUnitToUsers {
        position {
          id
          name
          role {
            id
            name
          }
        }
        user {
          id
          firstName
          lastName
        }
      }
      parentId
      isDeletable
    }
  }
`;

export const ORGANIZATIONAL_UNITS_DELETE = gql`
  mutation($id: Int!) {
    organizationalUnitDelete(organizationalUnitDeleteInput: { id: $id }) {
      id
    }
  }
`;

export const ORGANIZATIONAL_UNITS_ASSIGN_USER = gql`
  mutation AssignUserToOrganizationalUnit(
    $id: Int!
    $userId: Int!
    $positionId: Int
  ) {
    organizationalUnitAssignUser(
      organizationalUnitAssignUserInput: {
        id: $id
        userId: $userId
        positionId: $positionId
      }
    ) {
      user {
        id
      }
    }
  }
`;

export const ORGANIZATIONAL_UNITS_UNASSIGN_USER = gql`
  mutation($id: Int!, $userId: Int!) {
    organizationalUnitUnassignUser(
      organizationalUnitUnassignUserInput: { id: $id, userId: $userId }
    ) {
      user {
        id
      }
    }
  }
`;

export const ORGANIZATIONAL_UNITS_MOVE = gql`
  mutation($id: Int!, $parentId: Int!) {
    organizationalUnitMove(
      organizationalUnitMoveInput: { id: $id, parentId: $parentId }
    ) {
      id
    }
  }
`;
