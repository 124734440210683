import { ApolloError, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { StyledLink } from "baseui/link";
import { useSnackbar } from "notistack";
import React, { MouseEvent, useEffect, useMemo, useState } from "react";
import { Redirect, useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { Row } from "react-table";
import { Eye, Pencil, Plus } from "tabler-icons-react";

import BottomPanel from "../../../components/bottom-panel";
import Button from "../../../components/button";
import Cell from "../../../components/cell";
import Content from "../../../components/content";
import Filters from "../../../components/filters";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import PagingControls from "../../../components/paging-controls";
import SortingTableHeader, {
  SortDirection,
} from "../../../components/sorting-table-header";
import Table from "../../../components/table";
import { useLoading } from "../../../contexts/loading-context";
import { usePaging } from "../../../contexts/paging-context";
import { FiltersState } from "../../../filters";
import { checkPermission } from "../../../utils/check-permission";
import { translateFiltersState } from "../../../utils/filters";
import { PERMISSIONS } from "../../../utils/permissions";
import { setSortingParams } from "../../../utils/sorting";
import { InternalAccount } from "../internal-accounts";
import { INTERNAL_ACCOUNTS_FILTERS } from "../internal-accounts.filters";
import { INTERNAL_ACCOUNTS_INDEX } from "../internal-accounts.gql";

enum FieldName {
  Name = "name",
  Currency = "currency",
  FundingSource = "fundingSource",
  Limit = "limit",
  Balance = "balance",
}

export default function InternalAccountsIndex(): React.ReactElement {
  const { pageSize, currentPage, setTotalCount } = usePaging();
  const { search } = useLocation();

  const params = useMemo(() => new URLSearchParams(search), [search]);

  const [sortBy, setSortBy] = useState<FieldName | null>(
    (params.get("sortBy") as FieldName) || FieldName.Name
  );

  const [sortDirection, setSortDirection] = useState<SortDirection | null>(
    (params.get("sortDirection") as SortDirection) || SortDirection.ASC
  );

  const [css] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const {
    isFetching,
    setIsFetching,
    isPartialFetching,
    setIsPartialFetching,
  } = useLoading();

  const handleSorting = (column: FieldName) => {
    setSortingParams(history, search, column, sortDirection);
    setSortBy(column);
    setSortDirection(
      sortDirection === null
        ? SortDirection.DESC
        : sortDirection === SortDirection.ASC
        ? SortDirection.DESC
        : SortDirection.ASC
    );
  };

  const [filters, setFilters] = useState<FiltersState>();

  const { data, error, loading, refetch } = useQuery(INTERNAL_ACCOUNTS_INDEX, {
    variables: {
      pageSize,
      offset: (currentPage - 1) * pageSize,
      sorting: {
        field: sortBy,
        direction: sortDirection,
      },
      ...(filters && { filter: translateFiltersState(filters) }),
    },
  });

  useEffect(() => {
    if (data?.internalAccounts) setTimeout(() => refetch(), 200);
    setIsFetching(true);
  }, []);

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    setTimeout(() => refetch(), 200);
    setIsPartialFetching(true);
  }, [currentPage, pageSize]);

  useEffect(() => {
    setTimeout(() => refetch(), 200);
    setIsPartialFetching(true);
  }, [sortBy, sortDirection]);

  useEffect(() => {
    if (data?.internalAccounts) setIsFetching(false);
    if (data?.internalAccounts?.totalCount >= 0)
      setTotalCount(data?.internalAccounts?.totalCount);
  }, [data]);

  const columns = React.useMemo(
    () => [
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.Name)}
            sortDirection={sortBy === FieldName.Name ? sortDirection : null}
          >
            Nazwa
          </SortingTableHeader>
        ),
        accessor: "name",
        Cell: ({ row }: { row: Row<InternalAccount> }) => (
          <StyledLink
            onClick={(event: MouseEvent) => {
              event.preventDefault();
              history.push(`/internal-accounts/${row.original.id}`);
            }}
            href={`/internal-accounts/${row.original.id}`}
          >
            {row.original.name}
          </StyledLink>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.FundingSource)}
            sortDirection={
              sortBy === FieldName.FundingSource ? sortDirection : null
            }
          >
            Źródło finansowania
          </SortingTableHeader>
        ),
        id: "fundingSource",
        Cell: ({ row }: { row: Row<InternalAccount> }) => (
          <FormattedValue>{row.original.fundingSource}</FormattedValue>
        ),
      },
      // {
      //   Header: (
      //     <SortingTableHeader
      //       onClick={() => handleSorting(FieldName.Balance)}
      //       sortDirection={sortBy === FieldName.Balance ? sortDirection : null}
      //       $style={{ display: "flex", justifyContent: "flex-end" }}
      //     >
      //       Stan konta
      //     </SortingTableHeader>
      //   ),
      //   id: "balance",
      //   Cell: ({ row }: { row: Row<InternalAccount> }) => (
      //     <Block display="flex" justifyContent="flex-end">
      //       <FormattedValue>{`${row.original.balance} ${
      //         row?.original?.currency?.code || "PLN"
      //       }`}</FormattedValue>
      //     </Block>
      //   ),
      // },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.Balance)}
            sortDirection={sortBy === FieldName.Balance ? sortDirection : null}
            $style={{ display: "flex", justifyContent: "flex-end" }}
          >
            Numer konta
          </SortingTableHeader>
        ),
        id: "accountNumber",
        Cell: ({ row }: { row: Row<InternalAccount> }) => (
          <Block display="flex" justifyContent="flex-end">
            <FormattedValue>{row.original.accountNumber}</FormattedValue>
          </Block>
        ),
      },
      {
        id: "actions",
        Cell: ({ row }: { row: Row<InternalAccount> }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            {checkPermission(PERMISSIONS.internalAccounts.update) && (
              <Button
                kind={KIND.secondary}
                size={SIZE.mini}
                onClick={() =>
                  history.push(`/internal-accounts/${row.original.id}/edit`)
                }
                startEnhancer={<Pencil size={14} />}
              />
            )}
            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              onClick={() =>
                history.push(`/internal-accounts/${row.original.id}`)
              }
              $style={{ marginLeft: "6px" }}
              startEnhancer={<Eye size={14} />}
            />
          </div>
        ),
      },
    ],
    [data, sortBy, sortDirection, filters]
  );

  if (!checkPermission(PERMISSIONS.internalAccounts.read))
    return <Redirect to="/" />;

  return (
    <article>
      <Header
        title="Konta wewnętrzne"
        recordsNum={data?.internalAccounts.totalCount}
        labels={["Lista"]}
        buttons={[
          {
            label: "Dodaj konto wewnętrzne",
            kind: KIND.primary,
            startEnhancer: <Plus size={18} />,
            permission: checkPermission(PERMISSIONS.internalAccounts.create),
            onClick: () => history.push("/internal-accounts/create"),
          },
        ]}
      />
      <Filters
        filters={INTERNAL_ACCOUNTS_FILTERS}
        state={filters}
        setState={setFilters}
      />
      <Content filtersOffset>
        <Grid>
          <Cell span={12} $style={{ position: "relative" }}>
            <Table<InternalAccount>
              columns={columns}
              data={data?.internalAccounts?.nodes}
              isLoading={isFetching || isPartialFetching || loading}
              stickLastColumn
            />
          </Cell>

          <Cell span={12}>
            <BottomPanel>
              <PagingControls />
            </BottomPanel>
          </Cell>
        </Grid>
      </Content>
    </article>
  );
}
