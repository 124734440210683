import { gql } from "@apollo/client";

export const FINANCIAL_PLANS_INDEX = gql`
  query(
    $pageSize: Int
    $offset: Int
    $sorting: [FinancialPlanSort!]
    $filter: FinancialPlanFilter
  ) {
    financialPlans(
      paging: { limit: $pageSize, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        name
        categoryName
        synchronizedBy {
          id
          firstName
          lastName
        }
        synchronizedAt
        year
        bazaId
        isActive
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
        updatedAt
        updatedBy {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const FINANCIAL_PLAN_ITEMS = gql`
  query(
    $pageSize: Int
    $offset: Int
    $sorting: [FinancialPlanItemSort!]
    $filter: FinancialPlanItemFilter
  ) {
    financialPlanItems(
      paging: { limit: $pageSize, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        categoryName
        budgetName
        categoryPath
        budgetSymbol
        bazaId
        amount
        financialPlan {
          id
          name
        }
      }
    }
  }
`;

export const FINANCIAL_PLAN_ITEMS_SELECT = gql`
  query($filter: FinancialPlanItemFilter) {
    financialPlanItems(paging: { limit: 1000 }, filter: $filter) {
      totalCount
      nodes {
        id
        categoryName
        categoryPath
        budgetSymbol
        budgetName
        amount
      }
    }
  }
`;

export const FINANCIAL_PLANS_SHOW = gql`
  query($id: Int!) {
    financialPlan(id: $id) {
      id
      year
      isActive
    }
  }
`;

export const FINANCIAL_PLANS_ACTIVATE = gql`
  mutation($ids: [Int!]!) {
    financialPlanActivate(ids: $ids) {
      id
    }
  }
`;

export const FINANCIAL_PLANS_DEACTIVATE = gql`
  mutation($ids: [Int!]!) {
    financialPlanDeactivate(ids: $ids) {
      id
    }
  }
`;

export const FINANCIAL_PLANS_IN_RANGE = gql`
  query {
    financialPlansInRange {
      year
      financialPlan {
        id
        isActive
        name
      }
    }
  }
`;
export const AVAILABLE_FINANCIAL_PLANS = gql`
  query {
    financialPlansAvailable {
      bazaId
      budgetName
      categoryName
      periodStart
    }
  }
`;

export const FINANCIAL_PLANS_CREATE = gql`
  mutation($financialPlanCreateInput: FinancialPlanCreateInput!) {
    financialPlanCreate(financialPlanCreateInput: $financialPlanCreateInput) {
      id
    }
  }
`;

export const FINANCIAL_PLANS_SYNCHRONIZE = gql`
  mutation($financialPlanSyncInput: FinancialPlanSyncInput!) {
    financialPlanSynchronize(financialPlanSyncInput: $financialPlanSyncInput)
  }
`;
