import { ApolloError, useMutation } from "@apollo/client";
import { useStyletron } from "baseui";
import { useSnackbar } from "notistack";
import React, { ReactElement, useState } from "react";
import BaseBarcode from "react-barcode";
import { Download } from "tabler-icons-react";

import { DOCUMENTS_BARCODE_EXPORT } from "../containers/Documents/documents.gql";
import { useLoading } from "../contexts/loading-context";
import Tooltip from "./tooltip";

type DocumentBarcodeProps = {
  documentId: number;
  internalNumber: string;
};

export default function DocumentBarcode({
  documentId,
  internalNumber,
}: DocumentBarcodeProps): ReactElement | null {
  if (!internalNumber) return null;

  const [css] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, setIsLoading } = useLoading();

  const [freeze, setFreeze] = useState(false);

  const [exportBarcode] = useMutation(DOCUMENTS_BARCODE_EXPORT);

  const handleClick = async () => {
    setIsLoading(true);

    try {
      const response = await exportBarcode({
        variables: {
          documentPdfLabelGenerateInput: {
            id: documentId,
          },
        },
      });

      enqueueSnackbar({
        message: "Rozpoczęto pobieranie pliku",
        variant: "info",
      });

      window.open(
        `${process.env.REACT_APP_GRAPHQL_API_URL?.replace(
          "/graphql",
          ""
        )}/documents/download-label/${documentId}/${
          response?.data?.documentPdfLabelGenerate
        }`,
        "_self"
      );

      setFreeze(true);

      setTimeout(() => setFreeze(false), 2500);
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Tooltip
      {...(!isLoading && {
        content: "Pobierz naklejkę z kodem kreskowym",
      })}
    >
      <div
        className={css({
          top: "1px",
          maxHeight: "45px",
          position: "relative",
          marginRight: "20px",
          ...(!isLoading
            ? {
                ":hover [data-ui=download-icon]": {
                  display: "block",
                },
                ":hover": {
                  cursor: freeze ? "not-allowed" : "pointer",
                  "::after": {
                    content: "''",
                    position: "absolute",
                    left: "0",
                    top: "0",
                    right: "0",
                    bottom: "2px",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    zIndex: 1,
                  },
                },
              }
            : { ":hover": { cursor: "not-allowed" } }),
        })}
        onClick={() => (freeze || isLoading ? null : handleClick())}
      >
        <span
          data-ui="download-icon"
          className={css({
            display: "none",
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 2,
          })}
        >
          <Download color="white" size={18} />
        </span>

        <BaseBarcode
          value={internalNumber}
          height={42}
          margin={0}
          width={1}
          textMargin={0}
          fontSize={0}
        />
      </div>
    </Tooltip>
  );
}
