import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { AlertOctagon, DeviceFloppy, FileOff } from "tabler-icons-react";

import { InputValidationError } from "../../../api";
import Cell from "../../../components/cell";
import { ControlledCheckbox } from "../../../components/checkbox";
import ConfirmDialog from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import FormControl from "../../../components/form-control";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import { ControlledInput } from "../../../components/input";
import ControlledRadioButton from "../../../components/radio-button";
import { ControlledDocumentsFieldsTableSelect } from "../../../components/select";
import StepRecipientSelector, {
  RecipientType,
} from "../../../components/step-recipient-selector";
import { useLoading } from "../../../contexts/loading-context";
import { FIELDS } from "../../../fields.d";
import { checkPermission } from "../../../utils/check-permission";
import {
  renderDocumentFieldsOptions,
  renderFieldLabel,
} from "../../../utils/document-flow";
import { formValidation } from "../../../utils/formValidation";
import { PERMISSIONS } from "../../../utils/permissions";
import {
  DOCUMENT_FLOW_SHORT,
  DOCUMENT_FLOW_STEP,
  DOCUMENT_FLOW_STEP_UPDATE,
} from "../flow.gql";
import { FLOW_STEPS_FIELDS, FormInputs } from "../flow-steps.form";

export default function FlowEditStep(): React.ReactElement {
  const [css] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { isFetching, setIsFetching, isLoading, setIsLoading } = useLoading();
  const { flowId, stepId } = useParams<{ flowId?: string; stepId?: string }>();
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );

  const { data: documentFlowData } = useQuery(DOCUMENT_FLOW_SHORT, {
    variables: {
      id: flowId ? parseInt(flowId) : null,
    },
  });

  const { data, error: queryError, refetch } = useQuery(DOCUMENT_FLOW_STEP, {
    variables: { id: stepId ? parseInt(stepId) : null },
  });

  const [editDocumentFlowStep, { error }] = useMutation(
    DOCUMENT_FLOW_STEP_UPDATE
  );

  const {
    control,
    formState: { errors, isDirty, ...formState },
    handleSubmit,
    reset,
    setValue,
    unregister,
    watch,
    ...methods
  } = useForm<FormInputs>();

  const watchRecipientType = watch("recipientType");

  const onSubmit = async ({
    name,
    isInitial,
    disabledDocumentFields,
    hiddenDocumentFields,
    organizationalUnit,
    users,
    positions,
    groups,
    positionsInOrganizationalUnit,
    isPaymentCreationEnabled,
    isLegalSectionEnabled,
    isAllowedToEditAllFields,
    documentFields,
  }: FormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      await editDocumentFlowStep({
        variables: {
          documentFlowStepUpdateInput: {
            id: stepId ? parseInt(stepId) : null,
            name,
            isInitial,
            disabledDocumentFields,
            hiddenDocumentFields,
            isPaymentCreationEnabled: !!isPaymentCreationEnabled,
            isLegalSectionEnabled: !!isLegalSectionEnabled,
            documentFlowId: flowId ? parseInt(flowId) : null,
            isAllowedToEditAllFields: !!isAllowedToEditAllFields,
            allowedDocumentFields: documentFields?.map(
              ({ id }: { id: string }) => id
            ),
            isProcessedByDocumentFlowStarter:
              watchRecipientType === "isProcessedByDocumentFlowStarter",
            ...(watchRecipientType !== "isProcessedByDocumentFlowStarter" && {
              ...(users?.length
                ? {
                    userIds: users?.map(({ id }: { id: number }) => id),
                    positionIds: [],
                  }
                : { userIds: [] }),
              ...(organizationalUnit && organizationalUnit[0]?.id
                ? {
                    organizationalUnitId: organizationalUnit[0]?.id,
                  }
                : { organizationalUnitId: null }),
              ...(groups && groups[0]?.id
                ? {
                    groupId: groups[0]?.id,
                  }
                : { groupId: null }),
              ...(!!positions?.length && {
                positionIds: positions?.map(({ id }: { id: number }) => id),
              }),
              ...(!!positionsInOrganizationalUnit?.length && {
                positionIds: positionsInOrganizationalUnit?.map(
                  ({ id }: { id: number }) => id
                ),
              }),
              ...(!positionsInOrganizationalUnit?.length &&
                !positions?.length && {
                  positionIds: [],
                }),
            }),
          },
        },
      });

      await enqueueSnackbar({
        message: "Zapisano pomyślnie",
        variant: "success",
      });

      history.push(`/flow/${flowId}/step/${stepId}`);
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setValue("name", data?.documentFlowStep?.name);
    setValue("isInitial", data?.documentFlowStep?.isInitial);
    setValue("isDeletable", data?.documentFlowStep?.isDeletable);
    setValue(
      "isPaymentCreationEnabled",
      data?.documentFlowStep?.isPaymentCreationEnabled
    );
    setValue(
      "isLegalSectionEnabled",
      data?.documentFlowStep?.isLegalSectionEnabled
    );
    setValue(
      "hiddenDocumentFields",
      data?.documentFlowStep?.hiddenDocumentFields
    );
    setValue(
      "disabledDocumentFields",
      data?.documentFlowStep?.disabledDocumentFields
    );

    data?.documentFlowStep?.isAllowedToEditAllFields
      ? setValue("isAllowedToEditAllFields", true)
      : setValue(
          "documentFields",
          data?.documentFlowStep?.allowedDocumentFields?.map(
            (fieldName: string) => ({
              id: fieldName,
              label: renderFieldLabel(fieldName),
            })
          )
        );
    !!data?.documentFlowStep?.organizationalUnit &&
      setValue("organizationalUnit", [
        { id: data?.documentFlowStep?.organizationalUnit?.id },
      ]);
    !!data?.documentFlowStep?.group &&
      setValue("groups", [{ id: data?.documentFlowStep?.groupId }]);
    !!data?.documentFlowStep?.users?.length &&
      setValue("users", data?.documentFlowStep?.users);
    !!data?.documentFlowStep?.positions?.length &&
      setValue(
        data?.documentFlowStep?.organizationalUnit
          ? "positionsInOrganizationalUnit"
          : "positions",
        data?.documentFlowStep?.positions
      );
    setValue(
      "recipientType",
      data?.documentFlowStep?.isProcessedByDocumentFlowStarter
        ? "isProcessedByDocumentFlowStarter"
        : data?.documentFlowStep?.users?.length
        ? "users"
        : data?.documentFlowStep?.organizationalUnit
        ? "organizationalUnit"
        : data?.documentFlowStep?.group
        ? "groups"
        : "documentOrganizationalUnit"
    );
  }, [data]);

  useEffect(() => {
    if (data) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => {
    if (data) setIsFetching(false);
  }, [data]);

  useEffect(() => {
    if (queryError?.graphQLErrors)
      enqueueSnackbar({
        message: (queryError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [queryError]);

  const documentKind = documentFlowData?.documentFlow.documentKind;
  const documentType = documentFlowData?.documentFlow.documentType;
  const watchiIsAllowedToEditAllFields = watch("isAllowedToEditAllFields");
  const watchDocumentFields = watch("documentFields");
  const allDocumentFields = renderDocumentFieldsOptions(
    documentKind,
    documentType
  );

  useEffect(() => {
    if (watchiIsAllowedToEditAllFields) {
      setValue("documentFields", allDocumentFields);
    }
  }, [watchiIsAllowedToEditAllFields, allDocumentFields, setValue]);

  useEffect(() => {
    const allDocumentFieldsLength = allDocumentFields.length;
    const choosedDocumentFieldsLength = watchDocumentFields?.length || 0;

    if (
      choosedDocumentFieldsLength > 0 &&
      choosedDocumentFieldsLength < allDocumentFieldsLength
    ) {
      setValue("isAllowedToEditAllFields", false);
    }

    if (choosedDocumentFieldsLength === allDocumentFieldsLength) {
      setValue("isAllowedToEditAllFields", true);
    }
  }, [watchDocumentFields, allDocumentFields, setValue]);

  if (!checkPermission(PERMISSIONS.documentFlowStep.update))
    return <Redirect to="/" />;

  return (
    <article>
      <Header
        title={`Edycja kroku ${data?.documentFlowStep?.name}`}
        labels={["Kroki", "Edytowanie"]}
        goBackOption
        buttons={[
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              isDirty ? setIsCancelConfirmDialogOpen(true) : history.goBack(),
          },
          {
            label: "Zapisz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            onClick: handleSubmit(onSubmit),
            disabled: isLoading,
            isLoading: isLoading,
            type: "submit",
            formId: "editStep",
          },
        ]}
      />
      <Content>
        <form id="editStep" onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            {FLOW_STEPS_FIELDS.filter(
              (g) =>
                g.fields.filter(
                  (f) =>
                    f.editStep.visible &&
                    (!f.documentType ||
                      (f.documentType &&
                        f.documentType.includes(
                          documentFlowData?.documentFlow?.documentType
                        )))
                ).length > 0
            ).map((group) => [
              group.label && (
                <Cell key={group.id + `-group`} span={12}>
                  <LabelMedium marginBottom="scale200" marginTop="scale600">
                    {group.label}
                  </LabelMedium>
                  <hr
                    className={css({
                      borderWidth: "0px",
                      height: "1px",
                      backgroundColor: "#eee",
                    })}
                  />
                </Cell>
              ),
              group.fields
                .filter(
                  (f) =>
                    f.editStep.visible &&
                    (!f.documentType ||
                      (f.documentType &&
                        f.documentType.includes(
                          documentFlowData?.documentFlow?.documentType
                        )))
                )
                .map((item, index) => (
                  <Cell
                    span={item.span || 6}
                    key={group.id + `-field` + index}
                    skip={item.skip}
                  >
                    <FormControl
                      label={
                        item.type === FIELDS.Checkbox ||
                        (item.id === "organizationalUnit" &&
                          watchRecipientType !== item.id) ||
                        (item.id === "users" &&
                          watchRecipientType !== item.id) ||
                        (item.id === "positionsInOrganizationalUnit" &&
                          watchRecipientType !== "organizationalUnit") ||
                        (item.id === "positions" &&
                          watchRecipientType !== "documentOrganizationalUnit")
                          ? ""
                          : item.label
                      }
                      caption={
                        (item.id === "organizationalUnit" &&
                          watchRecipientType !== item.id) ||
                        (item.id === "users" &&
                          watchRecipientType !== item.id) ||
                        (item.id === "positionsInOrganizationalUnit" &&
                          watchRecipientType !== "organizationalUnit") ||
                        (item.id === "positions" &&
                          watchRecipientType !== "documentOrganizationalUnit")
                          ? ""
                          : item.caption
                      }
                      required={
                        (item.id === "organizationalUnit" &&
                          watchRecipientType === "organizationalUnit") ||
                        (item.id === "users" &&
                          watchRecipientType === "users") ||
                        item.editStep.required
                      }
                      error={
                        ((errors as any)[item.id] &&
                          (errors as any)[item.id].message) ||
                        (error &&
                          error.graphQLErrors[0]?.extensions?.code ===
                            "INPUT_VALIDATION_ERROR" &&
                          error.graphQLErrors[0]?.extensions?.validationErrors
                            ?.find(
                              (vE: InputValidationError) =>
                                vE?.property === item.id
                            )
                            ?.errors.map((message: string) => (
                              <div
                                key="error"
                                className={css({
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                })}
                              >
                                {message} <br />
                                {item.caption}
                                <AlertOctagon color="#999" size={12} />
                              </div>
                            ))[0])
                      }
                      disabled={isLoading}
                    >
                      {isFetching ? (
                        <Skeleton
                          rows={0}
                          height="36px"
                          width="100%"
                          animation
                        />
                      ) : item.type === FIELDS.RadioButton ? (
                        <ControlledRadioButton
                          control={control}
                          name={item.id}
                          variant="vertical"
                          buttons={[
                            {
                              id: RecipientType.DocumentFlowStarter,
                              label: "Rozpoczynający obieg",
                            },
                            {
                              id: RecipientType.DocumentOrganizationalUnit,
                              label: "Jednostka dokumentu",
                            },
                            {
                              id: RecipientType.OrganizationalUnit,
                              label: "Jednostka organizacyjna",
                            },
                            {
                              id: RecipientType.Users,
                              label: "Wybrani użytkownicy",
                            },
                            {
                              id: RecipientType.Groups,
                              label: "Grupa użytkowników",
                            },
                          ]}
                        />
                      ) : item.type === FIELDS.Checkbox ? (
                        <Block marginTop="10px">
                          <ControlledCheckbox
                            control={control}
                            name={item.id}
                            disabled={isLoading}
                            {...(item.editStep.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                          >
                            {item.label}
                          </ControlledCheckbox>
                        </Block>
                      ) : (
                        <ControlledInput
                          control={control}
                          name={item.id}
                          placeholder={item.placeholder}
                          {...(item.editStep.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      )}
                    </FormControl>
                  </Cell>
                )),
            ])}

            <FormProvider<FormInputs>
              control={control}
              formState={{ errors, isDirty, ...formState }}
              handleSubmit={handleSubmit}
              reset={reset}
              setValue={setValue}
              unregister={unregister}
              watch={watch}
              {...methods}
            >
              <Cell span={6}>
                <StepRecipientSelector />
              </Cell>

              <Cell span={12}>
                <LabelMedium marginBottom="scale200" marginTop="scale600">
                  Pola dozwolone do edycji
                </LabelMedium>
                <hr
                  className={css({
                    borderWidth: "0px",
                    height: "1px",
                    backgroundColor: "#eee",
                  })}
                />
              </Cell>

              <Cell span={4}>
                <Block marginTop="5px">
                  <ControlledCheckbox
                    control={control}
                    name="isAllowedToEditAllFields"
                    disabled={isLoading}
                  >
                    Wszystkie
                  </ControlledCheckbox>
                </Block>
              </Cell>

              <Cell span={6}>
                <FormControl label="Wybrane pola" disabled={isLoading}>
                  <ControlledDocumentsFieldsTableSelect
                    control={control}
                    name="documentFields"
                    multi
                    documentKind={documentKind}
                    documentType={documentType}
                  />
                </FormControl>
              </Cell>
            </FormProvider>

            <ConfirmDialog
              isOpen={isCancelConfirmDialogOpen}
              label="Anulowanie edycji kroku"
              close={() => setIsCancelConfirmDialogOpen(false)}
              confirm={() => history.goBack()}
            />
          </Grid>
        </form>
      </Content>
    </article>
  );
}
