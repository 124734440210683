import { ApolloError, useQuery } from "@apollo/client";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { StyledLink } from "baseui/link";
import { useSnackbar } from "notistack";
import React, { MouseEvent, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { Redirect, useLocation } from "react-router-dom";
import { Row } from "react-table";
import { useStyletron } from "styletron-react";
import { Eye, Plus } from "tabler-icons-react";

import BottomPanel from "../../../components/bottom-panel";
import Button from "../../../components/button";
import Cell from "../../../components/cell";
import Content from "../../../components/content";
import Filters from "../../../components/filters";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import PagingControls from "../../../components/paging-controls";
import SortingTableHeader, {
  SortDirection,
} from "../../../components/sorting-table-header";
import Table from "../../../components/table";
import { useLoading } from "../../../contexts/loading-context";
import { usePaging } from "../../../contexts/paging-context";
import { FiltersState } from "../../../filters";
import { checkPermission } from "../../../utils/check-permission";
import { translateFiltersState } from "../../../utils/filters";
import { PERMISSIONS } from "../../../utils/permissions";
import { setSortingParams } from "../../../utils/sorting";
import { DocumentPickup } from "../document-pickups";
import { DOCUMENT_PICKUPS_FILTERS } from "../document-pickups.filters";
import { DOCUMENT_PICKUPS_INDEX } from "../document-pickups.gql";

enum FieldName {
  CreatedAt = "createdAt",
}

export default function DocumentPickupsIndex(): React.ReactElement {
  const { pageSize, currentPage, setTotalCount } = usePaging();

  const [css] = useStyletron();
  const { search } = useLocation();

  const params = useMemo(() => new URLSearchParams(search), [search]);

  const [sortBy, setSortBy] = useState<FieldName | null>(
    (params.get("sortBy") as FieldName) || FieldName.CreatedAt
  );

  const [sortDirection, setSortDirection] = useState<SortDirection | null>(
    (params.get("sortDirection") as SortDirection) || SortDirection.DESC
  );

  const {
    isFetching,
    setIsFetching,
    isPartialFetching,
    setIsPartialFetching,
  } = useLoading();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [filters, setFilters] = useState<FiltersState>();

  const { data, error, loading, refetch } = useQuery(DOCUMENT_PICKUPS_INDEX, {
    variables: {
      pageSize,
      offset: (currentPage - 1) * pageSize,
      sorting: {
        field: sortBy,
        direction: sortDirection,
      },
      ...(filters && { filter: translateFiltersState(filters) }),
    },
  });

  useEffect(() => {
    if (data?.documentPickups) setTimeout(() => refetch(), 200);
    setIsFetching(true);
  }, []);

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    setTimeout(() => refetch(), 200);
    setIsPartialFetching(true);
  }, [currentPage, pageSize]);

  useEffect(() => {
    setTimeout(() => refetch(), 200);
    setIsPartialFetching(true);
  }, [sortBy, sortDirection]);

  useEffect(() => {
    if (data?.documentPickups) setIsFetching(false);
    if (data?.documentPickups?.totalCount >= 0)
      setTotalCount(data?.documentPickups?.totalCount);
  }, [data]);

  const handleSorting = (column: FieldName) => {
    setSortingParams(history, search, column, sortDirection);
    setSortBy(column);
    setSortDirection(
      sortDirection === null
        ? SortDirection.DESC
        : sortDirection === SortDirection.ASC
        ? SortDirection.DESC
        : SortDirection.ASC
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: (
          <Block display="flex" justifyContent="flex-end">
            Numer
          </Block>
        ),
        id: "id",
        Cell: ({ row }: { row: Row<DocumentPickup> }) => (
          <Block display="flex" justifyContent="flex-end">
            <StyledLink
              onClick={(event: MouseEvent) => {
                event.preventDefault();
                history.push(`/document-pickups/${row.original.id}`);
              }}
              href={`/document-pickups/${row.original.id}`}
            >
              {row.original.id}
            </StyledLink>
          </Block>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.CreatedAt)}
            sortDirection={
              sortBy === FieldName.CreatedAt ? sortDirection : null
            }
            $style={{ display: "flex", justifyContent: "flex-end" }}
          >
            Data utworzenia
          </SortingTableHeader>
        ),
        id: "createdAt",
        Cell: ({ row }: { row: Row<DocumentPickup> }) => (
          <Block display="flex" justifyContent="flex-end">
            <FormattedValue dataType="date">
              {row?.original?.createdAt}
            </FormattedValue>
          </Block>
        ),
      },
      {
        Header: <Block display="flex">Utworzono przez</Block>,
        id: "createdBy",
        Cell: ({ row }: { row: Row<DocumentPickup> }) => (
          <Block>
            <FormattedValue
              dataType="model:users"
              data={row?.original?.createdById}
            >
              {row?.original?.createdBy &&
                `${row?.original?.createdBy?.firstName} ${row?.original?.createdBy?.lastName}`}
            </FormattedValue>
          </Block>
        ),
      },
      {
        Header: (
          <Block display="flex" justifyContent="flex-end">
            Liczba dokumentów
          </Block>
        ),
        id: "documentsCount",
        Cell: ({ row }: { row: Row<DocumentPickup> }) => (
          <Block display="flex" justifyContent="flex-end">
            <FormattedValue>{row?.original?.documents?.length}</FormattedValue>
          </Block>
        ),
      },
      {
        Header: <Block display="flex">Pracownik wydający</Block>,
        id: "confirmedBy",
        Cell: ({ row }: { row: Row<DocumentPickup> }) => (
          <Block>
            <FormattedValue
              dataType="model:users"
              data={row?.original?.confirmedById}
            >
              {row?.original?.confirmedBy &&
                `${row?.original?.confirmedBy?.firstName} ${row?.original?.confirmedBy?.lastName}`}
            </FormattedValue>
          </Block>
        ),
      },
      {
        Header: <Block display="flex">Pracownik odbierający</Block>,
        id: "user",
        Cell: ({ row }: { row: Row<DocumentPickup> }) => (
          <Block>
            <FormattedValue
              dataType="model:users"
              data={row?.original?.user?.id}
            >
              {`${row?.original?.user?.firstName} ${row?.original?.user?.lastName}`}
            </FormattedValue>
          </Block>
        ),
      },
      {
        Header: "Status",
        id: "state",
        Cell: ({ row }: { row: Row<DocumentPickup> }) => (
          <FormattedValue dataType="document-pickup-state">
            {row?.original?.state}
          </FormattedValue>
        ),
      },
      {
        id: "actions",
        Cell: ({ row }: { row: Row<DocumentPickup> }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              $style={{ marginLeft: "6px" }}
              $as="a"
              href={`/document-pickups/${row.original.id}`}
              onClick={(e) => {
                e.preventDefault();
                history.push(`/document-pickups/${row.original.id}`);
              }}
              startEnhancer={<Eye size={14} />}
            />
          </div>
        ),
      },
    ],
    [data, sortBy, sortDirection, filters]
  );

  if (!checkPermission(PERMISSIONS.documentParcel.read))
    return <Redirect to="/" />;

  return (
    <article>
      <Header
        title="Wydania dokumentów"
        recordsNum={data?.documentPickups?.totalCount}
        labels={["Lista"]}
        buttons={[
          {
            label: "Nowe wydanie",
            kind: KIND.primary,
            startEnhancer: <Plus size={18} />,
            permission: checkPermission(PERMISSIONS.documentPickup.create),
            onClick: () => history.push("/document-pickups/create"),
          },
        ]}
      />
      <Filters
        filters={DOCUMENT_PICKUPS_FILTERS}
        state={filters}
        setState={setFilters}
      />
      <Content filtersOffset>
        <Grid>
          <Cell span={12} $style={{ position: "relative" }}>
            <Table<DocumentPickup>
              columns={columns}
              data={data?.documentPickups?.nodes}
              isLoading={isFetching || isPartialFetching || loading}
              stickLastColumn
            />
          </Cell>

          <Cell span={12}>
            <BottomPanel>
              <PagingControls />
            </BottomPanel>
          </Cell>
        </Grid>
      </Content>
    </article>
  );
}
