import { FIELDS, FieldsGroup } from "../../../fields.d";
import { ENadawcaFormat, ENadawcaKategoria } from "../e-nadawca-parcels";

export type FormInputs = {
  documentParcel: {
    id: string;
    label?: string;
    shipmentType?: string;
  }[];
  sendingList: { id: number | string; label?: string }[];
  opis: string;
  planowanaDataNadania: Date;
  ilosc: number;
  idLibraryForLegalDeposit: string;
  kategoria: ENadawcaKategoria;
  kategoriaSelect?: { id: string; label: string }[] | undefined;
  formatSelect?: { id: string; label: string }[] | undefined;
  format: ENadawcaFormat;
  masa: number;
  egzemplarzBiblioteczny: boolean;
};

export const DOCUMENT_ADDRESS_FIELDS: FieldsGroup[] = [
  {
    id: "recipient",
    label: "Adresat",
    fields: [
      {
        id: "addressName",
        label: "Nazwa",
        type: FIELDS.Input,
        span: 12,
        show: { accessor: ["recipient", "name"], visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "addressIsSensitive",
        label: "Dane osobowe",
        type: FIELDS.Checkbox,
        span: 3,
        show: { visible: false },
        create: { visible: false },
        edit: { visible: true },
      },
      {
        id: "addressAddress",
        label: "Ulica i numer",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "addressPostalCode",
        label: "Kod pocztowy",
        type: FIELDS.Input,
        span: 3,
        show: { accessor: ["recipient", "postalCode"], visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "addressCity",
        label: "Miejscowość",
        type: FIELDS.Input,
        span: 3,
        show: { accessor: ["recipient", "city"], visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "addressCountryCode",
        label: "Kraj",
        type: FIELDS.Input,
        span: 3,
        show: { accessor: ["recipient", "countryCode"], visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "countryCode",
      },
      {
        id: "addressNip",
        label: "NIP",
        type: FIELDS.Input,
        span: 3,
        show: { accessor: ["recipient", "nip"], visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "nip",
      },
      {
        id: "addressPhoneNumber",
        label: "Numer telefonu",
        type: FIELDS.Input,
        span: 3,
        show: { accessor: ["recipient", "phoneNumber"], visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "addressEmail",
        label: "Adres e-mail",
        type: FIELDS.Input,
        span: 6,
        show: { accessor: ["recipient", "email"], visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "email",
      },
    ],
  },
];

export const PRZESYLKA_NIEREJESTROWANA_KRAJOWA_FIELDS: FieldsGroup[] = [
  {
    id: "basic",
    label: "Podstawowe informacje",
    fields: [
      {
        id: "shipmentRate",
        label: "Stawka",
        type: FIELDS.Input,
        placeholder: "Wybierz",
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "sendingList",
        label: "Lista wysyłkowa",
        type: FIELDS.SendingListsSelect,
        placeholder: "Wybierz",
        span: 6,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: false },
      },
      {
        id: "kategoriaSelect",
        label: "Kategoria",
        type: FIELDS.ENadawcaKategoriaSelect,
        placeholder: "Wybierz",
        span: 3,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: false },
      },
      {
        id: "masa",
        label: "Masa",
        type: FIELDS.NumberInput,
        span: 3,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: false },
        endEnhancer: "g",
      },
      {
        id: "ilosc",
        label: "Ilość",
        type: FIELDS.NumberInput,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "planowanaDataNadania",
        label: "Planowana data nadania",
        type: FIELDS.DatePicker,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "formatSelect",
        label: "Format",
        type: FIELDS.ENadawcaFormatSelect,
        span: 3,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: false },
        placeholder: "Wybierz",
      },
    ],
  },
  {
    id: "basic",
    label: "Dodatkowe informacje",
    fields: [
      {
        id: "egzemplarzBiblioteczny",
        label: "Egzemplarz biblioteczny",
        type: FIELDS.Checkbox,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "opis",
        label: "Opis",
        type: FIELDS.Input,
        span: 6,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "idLibraryForLegalDeposit",
        label: "Id biblioteki do depozytu prawnego",
        type: FIELDS.Input,
        span: 6,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
    ],
  },
];
