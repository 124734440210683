import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { LabelSmall, MonoLabelXSmall } from "baseui/typography";
import React, { useMemo } from "react";
import { Row } from "react-table";

import { Currency } from "../containers/Currencies/currencies";
import { PaymentItem } from "../containers/Payments/payments";
import FormattedValue from "./formatted-value";
import Table from "./table";

type PaymentItemsTableProps = {
  paymentItems: PaymentItem[];
  tableId: string | null;
  currency?: Currency;
};

export default function PaymentItemsTable({
  paymentItems,
  tableId,
  currency,
}: PaymentItemsTableProps): React.ReactElement {
  const [css, theme] = useStyletron();

  const columns = useMemo(
    () => [
      {
        Header: "Lp.",
        id: "ordinalNumber",
        Cell: ({ row }: { row: Row<PaymentItem> }) => (
          <Block>{row.index + 1}</Block>
        ),
      },
      {
        Header: "Konto wewnętrzne",
        id: "internalAccount",
        Cell: ({ row }: { row: Row<PaymentItem> }) => (
          <Block display="flex" flexDirection="column">
            <FormattedValue
              dataType="model:internal-accounts"
              data={row?.original?.internalAccount?.id}
            >
              {row?.original?.internalAccount?.name}
            </FormattedValue>
            <MonoLabelXSmall>
              {row?.original?.internalAccount?.accountNumber}
            </MonoLabelXSmall>
          </Block>
        ),
      },
      {
        Header: "Źródło finansowania",
        id: "fundingSource",
        Cell: ({ row }: { row: Row<PaymentItem> }) => (
          <FormattedValue>
            {row?.original?.internalAccount?.fundingSource}
          </FormattedValue>
        ),
      },
      {
        Header: "Budżet",
        id: "budget",
        Cell: ({ row }: { row: Row<PaymentItem> }) => {
          return (
            <>
              {row?.original?.budgetInfo ? (
                <Block display="flex" flexDirection="column">
                  <Block
                    display="flex"
                    overrides={{ Block: { style: { gap: "5px" } } }}
                  >
                    <MonoLabelXSmall>
                      {row?.original?.budgetInfo?.bazaId
                        ? `ID: ${row?.original?.budgetInfo?.bazaId}`
                        : ""}
                    </MonoLabelXSmall>
                    <MonoLabelXSmall>
                      {row?.original?.budgetInfo?.budgetName || ""}
                    </MonoLabelXSmall>
                  </Block>
                  <LabelSmall>{`${row?.original?.budgetInfo?.positionName}${
                    row?.original?.budgetInfo?.positionName &&
                    row?.original?.budgetInfo?.categoryName
                      ? " | "
                      : " "
                  }${row?.original?.budgetInfo?.categoryPath} ${
                    row?.original?.budgetInfo?.categoryName
                  }`}</LabelSmall>
                </Block>
              ) : (
                <FormattedValue />
              )}
            </>
          );
        },
      },
      {
        Header: "Opis",
        id: "description",
        Cell: ({ row }: { row: Row<PaymentItem> }) => (
          <FormattedValue>{row?.original?.description}</FormattedValue>
        ),
      },
      {
        accessor: "percent",
        Header: "Udział",
        Cell: ({ row }: { row: Row<PaymentItem> }) => {
          return (
            <FormattedValue>
              {`${parseFloat(
                new Intl.NumberFormat("en-US", {
                  style: "percent",
                  maximumFractionDigits: 2,
                }).format(
                  row?.original?.amount /
                    paymentItems
                      ?.filter((item: PaymentItem) => item?.tableId === tableId)
                      .reduce(
                        (a: number, b: PaymentItem) =>
                          a + (b.amount ? b.amount : 0),
                        0
                      )
                )
              )}%`}
            </FormattedValue>
          );
        },
      },
      {
        Header: (
          <Block display="flex" justifyContent="flex-end">
            Kwota
          </Block>
        ),
        id: "amount",
        Cell: ({ row }: { row: Row<PaymentItem> }) => (
          <Block display="flex" justifyContent="flex-end">
            <FormattedValue dataType="quota" currency={currency?.code}>
              {row?.original?.amount}
            </FormattedValue>
          </Block>
        ),
      },
    ],
    [paymentItems]
  );

  return (
    <Table<PaymentItem>
      compact
      scrollable
      columns={columns}
      data={paymentItems?.filter(
        (item: PaymentItem) => item?.tableId === tableId
      )}
      $tableBodyRowStyle={{ borderTop: "1px solid #DDD" }}
      $footer={() => {
        return (
          <>
            <td colSpan={5} />
            <td
              className={css({
                paddingTop: theme.sizing.scale550,
                paddingBottom: theme.sizing.scale550,
                paddingLeft: theme.sizing.scale500,
                paddingRight: theme.sizing.scale500,
                position: "sticky",
                right: 0,
              })}
              colSpan={2}
            >
              <Block
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
              >
                <LabelSmall>
                  Suma:{" "}
                  <strong>
                    <FormattedValue dataType="quota" currency={currency?.code}>
                      {paymentItems
                        ?.filter(
                          (item: PaymentItem) => item?.tableId === tableId
                        )
                        .reduce(
                          (a: number, b: PaymentItem) =>
                            a + (b.amount ? b.amount : 0),
                          0
                        )}
                    </FormattedValue>
                  </strong>
                </LabelSmall>
              </Block>
            </td>
          </>
        );
      }}
    />
  );
}
