import { FIELDS, FieldsGroup } from "../../../fields.d";
import { Document } from "../documents";

export type ContractAgreementInputs = {
  documentNumber: string;
  description: string;
  documentType: { id: number; label?: string }[];
  documentDate: Date | null;
  registeredAt: string;
  parentIds?: Document[];
  caseName: { id: number; label?: string }[];
  organizationalUnit: { id: number; label?: string }[];
  status: { id: number; label?: string }[];
  contractNumber: string;
  budgetCategory: string;
  contractType: { id: number; label?: string }[];
  requestFormRegistratedAt: string;
  beginAt: Date | null;
  endAt: Date | null;
  collectionPeriodEndAt: Date | null;
  grossValueInPLN: number;
  legalDocumentsVerification: boolean;
  legalAct: { id: number; label?: string }[] | undefined;
  contractor?: { id: number; label?: string; typename?: string }[];
  addressName?: string;
  addressAddress?: string;
  addressStreetName?: string;
  addressBuildingNumber?: string;
  addressApartmentNumber?: string;
  addressCity?: string;
  addressPostalCode?: string;
  addressPhoneNumber?: string;
  addressMobileNumber?: string;
  addressEmail?: string;
  addressNip?: string;
  sequenceNumber?: string;
  addressOverwrite: boolean;
  addressIsSensitive?: boolean;
  addressCountryCode: { id: string; label?: string }[];
  files?: FileList;
};

export const CONTRACT_AGREEMENT_FIELDS: FieldsGroup[] = [
  {
    id: "basic",
    label: "Podstawowe informacje",
    fields: [
      {
        id: "contractType",
        label: "Typ umowy",
        type: FIELDS.ContractTypeSelect,
        span: 3,
        show: { visible: true },
        edit: { visible: true, required: true },
        create: { visible: true, required: true },
        placeholder: "Wybierz",
      },

      {
        id: "documentNumber",
        label: "Numer umowy",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        edit: { visible: true },
        create: { visible: true },
      },
      {
        id: "documentDate",
        label: "Data podpisania umowy",
        type: FIELDS.DatePicker,
        dataType: "date",
        span: 3,
        show: { visible: false },
        edit: { visible: true },
        create: { visible: true },
      },
      {
        id: "documentDate",
        label: "Data podpisania umowy",
        type: FIELDS.Input,
        dataType: "date",
        span: 3,
        show: { visible: true },
        edit: { visible: false },
        create: { visible: false },
      },
      {
        id: "status",
        label: "Status",
        type: FIELDS.ContractStatusSelect,
        span: 3,
        show: { visible: true },
        edit: { visible: true, required: true },
        create: { visible: true, required: true },
        placeholder: "Wybierz",
      },
      {
        id: "sequenceNumber",
        label: "Numer w składzie chronologicznym",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "state",
        label: "Stan dokumentu",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "document-state",
        typeName: ["Incoming", "Outgoing", "Internal"],
      },

      {
        id: "createdAt",
        label: "Data rejestracji w systemie",
        type: FIELDS.Input,
        dataType: "datetime",
        span: 3,
        show: { visible: true },
        edit: { visible: false },
        create: { visible: false },
      },
      {
        id: "createdBy",
        label: "Autor",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "model:users",
      },
      {
        id: "responsible",
        label: "Odpowiedzialny",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "model:users",
      },
      {
        id: "budgetCategory",
        label: "Kategoria budżetowa",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        edit: { visible: true },
        create: { visible: true },
      },
      {
        id: "description",
        label: "Opis",
        type: FIELDS.TextArea,
        span: 12,
        show: { visible: true },
        edit: { visible: true },
        create: { visible: true },
      },
    ],
  },
  // {
  //   id: "case",
  //   label: "Znak sprawy",
  //   fields: [
  //     {
  //       id: "caseName",
  //       label: "",
  //       type: FIELDS.CasesSelect,
  //       span: 12,
  //       dataType: "model:cases",
  //       show: { accessor: ["case", "name"], visible: true },
  //       edit: { visible: true },
  //       create: { visible: true },
  //       placeholder: "Wybierz",
  //       caption:
  //         "W tym polu należy wybrać odpowiedni znak sprawy z jaką zostanie powiązany dokument. Nie jest to obowiązkowe na etapie rejestracji dokumentu w recepcji",
  //     },
  //   ],
  // },
  {
    id: "contractor",
    label: "Kontrahent",
    fields: [
      {
        id: "contractor",
        label: "Wybór z bazy adresowej",
        type: FIELDS.AddressesSelect,
        span: 8,
        show: { visible: false },
        edit: { visible: true },
        create: { visible: true },
        placeholder: "Wybierz",
        typeName: "Address",
      },
      {
        id: "addressName",
        label: "Nazwa kontrahenta",
        type: FIELDS.Input,
        span: 9,
        show: {
          accessor: ["contractorAddress", "name"],
          visible: true,
        },
        edit: { visible: false },
        create: { visible: false },
        typeName: "Address",
      },
      {
        id: "addressNip",
        label: "NIP",
        type: FIELDS.Input,
        span: 3,
        show: {
          accessor: ["contractorAddress", "nip"],
          visible: true,
        },
        edit: { visible: false },
        create: { visible: false },
        typeName: "Address",
        dataType: "nip",
      },
      {
        id: "addressNip",
        label: "NIP",
        type: FIELDS.Input,
        span: 4,
        show: { visible: false },
        edit: { visible: true },
        create: { visible: true },
        mask: "9999999999",
        placeholder: "9999999999",
        typeName: "Address",
        additionalData: "sensitive",
      },
      {
        id: "addressName",
        label: "Nazwa kontrahenta",
        type: FIELDS.Input,
        span: 6,
        info: "possiblyRequired",
        show: { visible: false },
        edit: { visible: true },
        create: { visible: true },
        typeName: "Address",
      },
      {
        id: "addressIsSensitive",
        label: "Dane osobowe",
        type: FIELDS.Checkbox,
        span: 3,
        show: { visible: false },
        edit: { visible: true },
        create: { visible: true },
      },
      {
        id: "addressCountryCode",
        label: "Kraj",
        type: FIELDS.DictionaryValuesSelect,
        span: 3,
        info: "possiblyRequired",
        show: {
          visible: false,
        },
        edit: { visible: true },
        create: { visible: true },
        typeName: "Address",
        dataType: "countryCode",
        additionalData: "sensitive",
      },
      {
        id: "addressStreetName",
        label: "Ulica",
        type: FIELDS.Input,
        span: 3,
        info: "possiblyRequired",
        show: { visible: false },
        edit: { visible: true },
        create: { visible: true },
        typeName: "Address",
        additionalData: "sensitive",
      },
      {
        id: "addressAddress",
        label: "Ulica i numer",
        type: FIELDS.Input,
        span: 3,
        show: {
          visible: true,
        },
        edit: { visible: false },
        create: { visible: false },
        typeName: "Address",
      },
      {
        id: "addressBuildingNumber",
        label: "Numer budynku",
        type: FIELDS.Input,
        span: 2,
        info: "possiblyRequired",
        show: { visible: false },
        edit: { visible: true },
        create: { visible: true },
        typeName: "Address",
        additionalData: "sensitive",
      },
      {
        id: "addressApartmentNumber",
        label: "Numer lokalu",
        type: FIELDS.Input,
        span: 2,
        show: { visible: false },
        edit: { visible: true },
        create: { visible: true },
        typeName: "Address",
        additionalData: "sensitive",
      },
      {
        id: "addressPostalCode",
        label: "Kod pocztowy",
        type: FIELDS.Input,
        span: 2,
        info: "possiblyRequired",
        show: { visible: false },
        edit: { visible: true },
        create: { visible: true },
        typeName: "Address",
        additionalData: "sensitive",
      },
      {
        id: "addressPostalCode",
        label: "Kod pocztowy",
        type: FIELDS.Input,
        span: 3,
        show: {
          accessor: ["contractorAddress", "postalCode"],
          visible: true,
        },
        edit: { visible: false },
        create: { visible: false },
        typeName: "Address",
      },
      {
        id: "addressCity",
        label: "Miejscowość",
        type: FIELDS.Input,
        span: 3,
        info: "possiblyRequired",
        show: {
          accessor: ["contractorAddress", "city"],
          visible: true,
        },
        edit: { visible: true },
        create: { visible: true },
        typeName: "Address",
        additionalData: "sensitive",
      },
      {
        id: "addressCountryCode",
        label: "Kraj",
        type: FIELDS.DictionaryValuesSelect,
        span: 3,
        info: "possiblyRequired",
        show: {
          accessor: ["contractorAddress", "countryCode"],
          visible: true,
        },
        edit: { visible: false },
        create: { visible: false },
        typeName: "Address",
        dataType: "countryCode",
      },
      {
        id: "addressPhoneNumber",
        label: "Numer telefonu",
        type: FIELDS.Input,
        span: 3,
        show: {
          accessor: ["contractorAddress", "phoneNumber"],
          visible: true,
        },
        edit: { visible: true },
        create: { visible: true },
        typeName: "Address",
        mask: "99 999-99-99",
        placeholder: "99 999-99-99",
        additionalData: "sensitive",
      },
      {
        id: "addressMobileNumber",
        label: "Numer telefonu kom.",
        type: FIELDS.Input,
        span: 3,
        show: {
          accessor: ["contractorAddress", "mobileNumber"],
          visible: true,
        },
        edit: { visible: true },
        create: { visible: true },
        typeName: "Address",
        mask: "+99 999-999-999",
        placeholder: "+99 999-999-999",
        additionalData: "sensitive",
      },
      {
        id: "addressEmail",
        label: "Adres e-mail",
        type: FIELDS.Input,
        span: 3,
        show: {
          accessor: ["contractorAddress", "email"],
          visible: true,
        },
        edit: { visible: true },
        create: { visible: true },
        typeName: "Address",
        dataType: "email",
        additionalData: "sensitive",
      },
      {
        id: "addressOverwrite",
        label: "Nadpisz dane adresowe",
        type: FIELDS.Checkbox,
        span: 3,
        show: { visible: false },
        edit: { visible: true },
        create: { visible: true },
      },
    ],
  },
  {
    id: "organizationalUnit",
    label: "Jednostka organizacyjna",
    fields: [
      {
        id: "organizationalUnit",
        label: "",
        type: FIELDS.OrganizationalUnitsSelect,
        span: 8,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
        placeholder: "Wybierz",
      },
      {
        id: "organizationalUnitId",
        label: "",
        type: FIELDS.Input,
        dataType: "model:organizational-units",
        span: 8,
        show: { visible: true, accessor: ["organizationalUnit", "name"] },
        create: { visible: false },
        edit: { visible: false },
        placeholder: "Wybierz",
      },
    ],
  },
  {
    id: "deadlines",
    label: "Terminy",
    fields: [
      {
        id: "beginAt",
        label: "Początek realizacji zamówienia",
        type: FIELDS.DatePicker,
        span: 4,
        show: { visible: true },
        edit: { visible: true },
        create: { visible: true },
        dataType: "date",
      },
      {
        id: "endAt",
        label: "Koniec realizacji zamówienia",
        type: FIELDS.DatePicker,
        span: 4,
        show: { visible: true },
        edit: { visible: true },
        create: { visible: true },
        dataType: "date",
      },
      {
        id: "collectionPeriodEndAt",
        label: "Termin przyjmowania dokumentów finansowych",
        type: FIELDS.DatePicker,
        span: 4,
        show: { visible: true },
        edit: { visible: true },
        create: { visible: true },
        caption: "Sugerowane 14 dni od dnia zakończenia umowy",
        dataType: "date",
      },
    ],
  },
  {
    id: "parent",
    label: "Dokumenty nadrzędne",
    fields: [
      {
        id: "parentIds",
        label: "Dokumenty nadrzędne",
        type: FIELDS.DocumentsSelect,
        dataType: "model:documents",
        span: 12,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
        typeName: ["Incoming", "Outgoing", "Internal"],
      },
    ],
  },
  {
    id: "value",
    label: "Wartość zamówienia",
    fields: [
      {
        id: "grossValueInPLN",
        label: "",
        type: FIELDS.NumberInput,
        dataType: "quota",
        span: 3,
        show: { visible: true },
        edit: { visible: true },
        create: { visible: true },
        endEnhancer: "PLN",
      },
    ],
  },
  {
    id: "legalInformation",
    label: "Informacje prawne",
    fields: [
      {
        id: "legalDocumentsVerification",
        label: "Dodatkowa kontrola prawna dokumentów",
        type: FIELDS.Checkbox,
        dataType: "boolean",
        span: 6,
        show: { visible: true },
        edit: { visible: true },
        create: { visible: true },
      },
      {
        id: "legalAct",
        label: "Podstawa prawna",
        type: FIELDS.DictionaryValuesSelect,
        span: 6,
        show: { visible: true },
        edit: { visible: true },
        create: { visible: true },
      },
    ],
  },
  {
    id: "files",
    label: "Załączniki",
    fields: [
      {
        id: "filesList",
        label: "",
        type: FIELDS.FilesList,
        span: 12,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
        typeName: ["Incoming", "Outgoing"],
        caption:
          "Kliknięcie ikony kosza spowoduje usunięcia załącznika po zapisaniu dokumentu",
      },
      {
        id: "files",
        label: "Dodaj nowy",
        type: FIELDS.FilesPicker,
        span: 12,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
        caption: "Maksymalny rozmiar pliku to 128 MB.",
        typeName: ["Incoming", "Outgoing"],
      },
    ],
  },
];
