const defaultColumns: { [pathname: string]: string[] } = {
  "/documents": [
    "caseId",
    "internalNumber",
    "documentType",
    "createdAt",
    "organizationalUnit",
    "sender",
    "documentNumber",
    "title",
    "currentStatus",
    "actions",
  ],
  "/cases": [
    "number",
    "name",
    "supervisor",
    "organizationUnitShort",
    "initiatedAt",
    "actions",
  ],
  pathname: ["id"],
};

export function getDefaultColumns(pathname: string): string[] | undefined {
  return defaultColumns?.[pathname];
}
