import { ApolloError, useMutation } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND } from "baseui/button";
import { ModalBody, ModalFooter, ModalHeader } from "baseui/modal";
import { useSnackbar } from "notistack";
import React from "react";
import { useForm } from "react-hook-form";
import { AlertOctagon } from "tabler-icons-react";

import { InputValidationError } from "../api";
import { CASES_CLOSE } from "../containers/Cases/cases.gql";
import { useAssignment } from "../contexts/assignment-context";
import { useLoading } from "../contexts/loading-context";
import { formValidation } from "../utils/formValidation";
import Button from "./button";
import { ControlledDatePicker } from "./date-picker";
import FormControl from "./form-control";
import Modal from "./modal";

type CloseCaseModalProps = {
  caseId?: string;
  defaultClosingDate?: Date;
  isOpen: boolean;
  label: string;
  reloadPage: () => void;
  setIsOpen: (isOpen: boolean) => void;
};

export default function CloseCaseModal({
  caseId,
  isOpen,
  reloadPage,
  setIsOpen,
  label,
  defaultClosingDate,
}: CloseCaseModalProps): React.ReactElement {
  const [css] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, setIsLoading } = useLoading();

  const { assignment, isAssignmentActive } = useAssignment();

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<{ closedAt: Date }>({
    defaultValues: {
      closedAt: defaultClosingDate ? new Date(defaultClosingDate) : new Date(),
    },
  });

  const [closeCase, { error, loading }] = useMutation(CASES_CLOSE, {
    ...(isAssignmentActive &&
      assignment && {
        context: {
          headers: {
            Assignment: assignment.id,
          },
        },
      }),
  });

  const submit = async ({ closedAt }: { closedAt: Date }) => {
    setIsLoading(true);

    try {
      await closeCase({
        variables: {
          caseCloseInput: {
            id: caseId ? parseInt(caseId) : null,
            closedAt,
          },
        },
      });

      enqueueSnackbar({
        message: "Zamknięto pomyślnie",
        variant: "success",
      });

      setIsOpen(false);
      reloadPage();
    } catch (error: unknown) {
      setIsOpen(false);

      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => (loading ? null : setIsOpen(false))}
      autoFocus={false}
    >
      <form onSubmit={handleSubmit(submit)}>
        <ModalHeader>Zamykanie sprawy</ModalHeader>
        <ModalBody>
          <p className={css({ whiteSpace: "pre-wrap", marginBottom: "24px" })}>
            Czy na pewno chcesz zamknąć sprawę{" "}
            {label && <strong>{label}</strong>}?
          </p>
          <FormControl
            label="Data zamknięcia sprawy"
            caption="Domyślnie jest ustawiona data utworzenia ostatniego dokumentu, jeżeli sprawa je posiada."
            error={
              ((errors as any)?.closedAt &&
                (errors as any)?.closedAt?.message) ||
              (error &&
                error.graphQLErrors[0]?.extensions?.code ===
                  "INPUT_VALIDATION_ERROR" &&
                error.graphQLErrors[0]?.extensions?.validationErrors
                  ?.find(
                    (vE: InputValidationError) => vE?.property === "closedAt"
                  )
                  ?.errors.map((message: string) => (
                    <div
                      key="error"
                      className={css({
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      })}
                    >
                      {message}
                      <span
                        className={css({
                          color: "#999",
                          marginLeft: "auto",
                          marginRight: "5px",
                        })}
                      >
                        Walidacja serwera
                      </span>
                      <AlertOctagon color="#999" size={12} />
                    </div>
                  ))[0])
            }
            disabled={isLoading}
          >
            <ControlledDatePicker
              name="closedAt"
              control={control}
              rules={{
                required: formValidation.messages.required,
              }}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            kind={KIND.secondary}
            $style={{ marginRight: "10px" }}
            onClick={() => {
              setIsOpen(false);
              reset();
            }}
            disabled={loading}
          >
            Anuluj
          </Button>
          <Button disabled={loading} isLoading={loading} type="submit">
            Potwierdź
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
