import { useStyletron } from "baseui";
import {
  ProgressBar as BaseProgressBar,
  ProgressBarProps as BaseProgressBarProps,
} from "baseui/progress-bar";
import React from "react";
import { StyleObject } from "styletron-react";

type ProgressBarProps = {
  $style?: StyleObject;
} & BaseProgressBarProps;

export default function Progressbar({
  $style,
}: ProgressBarProps): React.ReactElement {
  const [, theme] = useStyletron();

  return (
    <BaseProgressBar
      value={100}
      successValue={100}
      steps={1}
      size="large"
      overrides={{
        Bar: {
          style: () => ({
            backgroundColor: theme.colors.backgroundTertiary,
          }),
        },
        BarProgress: {
          style: () => ({
            marginTop: 0,
            marginRight: 0,
            marginBottom: 0,
            marginLeft: 0,
            paddingLeft: 0,
            ...$style,
          }),
        },
      }}
    />
  );
}
