import { useQuery } from "@apollo/client";
import React, { createContext, useContext, useState } from "react";

import { EURO_RATE_FROM_REGULATION } from "../containers/Currencies/currencies.gql";
import { useAuth } from "./auth-context";

type EuroRateContextProps = {
  euroRateFromRegulation?: number;
};

export const EuroRateContext = createContext<EuroRateContextProps>(
  {} as EuroRateContextProps
);

export function EuroRateProvider({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement {
  const [
    euroRateFromRegulation,
    setEuroRateFromRegulation,
  ] = useState<number>();

  const { isAuthenticated } = useAuth();

  useQuery(EURO_RATE_FROM_REGULATION, {
    fetchPolicy: "cache-and-network",
    skip: !isAuthenticated,
    onCompleted({ euroRateFromRegulation }) {
      setEuroRateFromRegulation(euroRateFromRegulation);
    },
  });

  return (
    <EuroRateContext.Provider
      value={{
        euroRateFromRegulation,
      }}
    >
      {children}
    </EuroRateContext.Provider>
  );
}

export const useEuroRate = (): EuroRateContextProps =>
  useContext(EuroRateContext);
