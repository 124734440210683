import { Cell, flexRender } from "@tanstack/react-table";
import { useStyletron } from "baseui";
import React, { Fragment, memo } from "react";
import { StyleObject } from "styletron-standard";

import { getColumnDataAlign } from "../utils/interface/column-sizes";
import Tooltip from "./tooltip";

type TableCellProps<T, K> = {
  cell: Cell<T, K>;
  editable?: boolean;
};

export default function TableCellV8<T, K>({
  cell,
  editable,
}: TableCellProps<T, K>): React.ReactElement {
  const [css] = useStyletron();

  const wrapper = document?.querySelector(
    `[data-cell=${cell.column.id.replace(/[^a-zA-Z]/g, "")}${
      cell.row.id
    }] > div`
  );

  const shouldShowTooltip =
    !editable &&
    wrapper &&
    (wrapper.clientWidth < wrapper.scrollWidth ||
      wrapper.clientHeight + 5 < wrapper.scrollHeight) &&
    cell.column.id !== "checkbox";

  if (cell.column.id === "actions") {
    return flexRender(
      cell.column.columnDef.cell,
      cell.getContext()
    ) as React.ReactElement;
  }

  const cellStyles: StyleObject = {
    width: "auto",
    minWidth: "calc(100%)",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display:
      wrapper && wrapper.clientHeight + 5 < wrapper.scrollHeight
        ? "-webkit-box"
        : "block",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    boxOrient: "vertical",
    lineClamp: 1,
    fontSize: "12px",
    ...getColumnDataAlign(cell.column.id),
  };

  const renderCell = flexRender(cell.column.columnDef.cell, cell.getContext());

  return (
    <Fragment>
      <div
        key={`${cell.column.id.replace(/[^a-zA-Z]/g, "")}${cell.row.id}`}
        data-cell={`${cell.column.id.replace(/[^a-zA-Z]/g, "")}${cell.row.id}`}
        {...(!editable && {
          className: css({
            position: "absolute",
            left: "0px",
            right: "0px",
            top: "0px",
            bottom: "0px",
            verticalAlign: "middle",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "flex",
            alignItems: "center",
            justifyContent: "inherit",
            ...(cellStyles.justifyContent !== "flex-start" && {
              paddingRight: "8px",
            }),
            ...(cellStyles.justifyContent !== "flex-end" && {
              paddingLeft: "8px",
            }),
          }),
        })}
      >
        {shouldShowTooltip ? (
          <Tooltip content={renderCell}>
            <div className={css(cellStyles)}>{renderCell}</div>
          </Tooltip>
        ) : (
          <div className={css(cellStyles)}>{renderCell}</div>
        )}
      </div>

      {!editable && (
        <div
          className={css({
            width: "1px",
            visibility: "hidden",
            overflow: "hidden",
            userSelect: "none",
            pointerEvents: "none",
          })}
        >
          {renderCell}
        </div>
      )}
    </Fragment>
  );
}

export const MemoizedTableCellV8 = memo(TableCellV8);
