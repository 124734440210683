export type Address = {
  id: number;
  name: string;
  nip: string;
  city: string;
  address?: string;
  streetName?: string;
  buildingNumber?: string;
  apartmentNumber?: string;
  mobileNumber?: string;
  postalCode: string;
  countryCode: string;
  phoneNumber: string;
  email: string;
  isHidden: boolean;
  isSensitive?: boolean;
  createdAt: string;
  updatedAt: string;
  predecessor: Address;
  predecessorId: number;
  bankAccounts: BankAccount[];
  userBankAccounts?: BankAccount[];
  __typename: string;
};

export type DefaultAddress = {
  addressName: string;
  addressNip: string;
  addressAddress?: string;
  addressStreetName?: string;
  addressBuildingNumber?: string;
  addressApartmentNumber?: string;
  addressMobileNumber?: string;
  addressCity: string;
  addressCountryCode: { id: string; label?: string }[];
  addressPostalCode: string;
  addressPhoneNumber: string;
  addressEmail: string;
  addressIsSensitive?: boolean;
  addressBankAccount?: number;
  payeeName?: string;
  currency?: string;
  accountNumber?: string;
  bankName?: string;
  accountType?: BankAccountType;
  bankCode?: string;
};

export enum BankAccountType {
  IBAN = "IBAN",
  Other = "Other",
}

export type BankAccount = {
  id: number;
  payeeName?: string;
  currency?: string;
  accountNumber?: string;
  bankName?: string;
  accountType?: BankAccountType;
  bankCode?: string;
  addressId?: number;
};
