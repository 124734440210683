import { ApolloError, useMutation } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND } from "baseui/button";
import { ModalBody, ModalFooter, ModalHeader } from "baseui/modal";
import { useSnackbar } from "notistack";
import React from "react";
import { useForm } from "react-hook-form";
import { AlertOctagon } from "tabler-icons-react";

import { DOCUMENT_TYPES } from "../constants";
import { CONTRACT_AGREEMENT_CLOSE } from "../containers/Documents/ContractAgreement/contract-agreement.gql";
import { REQUEST_FORM_CLOSE } from "../containers/Documents/RequestForm/request-form.gql";
import { useAssignment } from "../contexts/assignment-context";
import { useLoading } from "../contexts/loading-context";
import { formValidation } from "../utils/formValidation";
import Button from "./button";
import { ControlledDatePicker } from "./date-picker";
import FormControl from "./form-control";
import Modal from "./modal";

type CloseDocumentModalProps = {
  documentId?: number;
  documentType: DOCUMENT_TYPES.ContractAgreement | DOCUMENT_TYPES.RequestForm;
  isOpen: boolean;
  refetch: () => void;
  close: () => void;
};

export default function CloseDocumentModal({
  documentId,
  documentType,
  isOpen,
  refetch,
  close,
}: CloseDocumentModalProps): React.ReactElement {
  const [css] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, setIsLoading } = useLoading();

  const { assignment, isAssignmentActive } = useAssignment();

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<{ closedOn: Date }>({
    defaultValues: {
      closedOn: new Date(),
    },
  });

  const [
    closeRequestForm,
    { error: closeRequestFormError, loading: closeRequestFormLoading },
  ] = useMutation(REQUEST_FORM_CLOSE, {
    ...(isAssignmentActive &&
      assignment && {
        context: {
          headers: {
            Assignment: assignment.id,
          },
        },
      }),
  });

  const [
    closeContractAgreement,
    {
      error: closeContractAgreementError,
      loading: closeContractAgreementLoading,
    },
  ] = useMutation(CONTRACT_AGREEMENT_CLOSE, {
    ...(isAssignmentActive &&
      assignment && {
        context: {
          headers: {
            Assignment: assignment.id,
          },
        },
      }),
  });

  const submit = async ({ closedOn }: { closedOn: Date }) => {
    setIsLoading(true);

    try {
      documentType === DOCUMENT_TYPES.RequestForm &&
        (await closeRequestForm({
          variables: {
            requestFormCloseInput: {
              id: documentId,
              closedOn: new Date(closedOn)?.toISOString().substring(0, 10),
            },
          },
        }));

      documentType === DOCUMENT_TYPES.ContractAgreement &&
        (await closeContractAgreement({
          variables: {
            contractAgreementCloseInput: {
              id: documentId,
              closedOn: new Date(closedOn)?.toISOString().substring(0, 10),
            },
          },
        }));

      enqueueSnackbar({
        message: "Zamknięto pomyślnie",
        variant: "success",
      });

      refetch();
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
      close();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() =>
        closeRequestFormLoading || closeContractAgreementLoading
          ? null
          : close()
      }
      autoFocus={false}
    >
      <form onSubmit={handleSubmit(submit)}>
        <ModalHeader>Zamykanie dokumentu</ModalHeader>
        <ModalBody>
          <p className={css({ whiteSpace: "pre-wrap" })}>
            {`Czy na pewno chcesz zamknąć ${
              documentType === DOCUMENT_TYPES.ContractAgreement
                ? "umowę"
                : "wniosek o wydatkowanie środków publicznych"
            }?`}
          </p>
          <Block height="20px" />
          <FormControl
            label="Data zamknięcia"
            error={
              documentType === DOCUMENT_TYPES.RequestForm
                ? ((errors as any)?.closedOn &&
                    (errors as any)?.closedOn?.message) ||
                  (closeRequestFormError &&
                    closeRequestFormError.graphQLErrors[0]?.extensions?.code ===
                      "INPUT_VALIDATION_ERROR" &&
                    closeRequestFormError.graphQLErrors[0]?.extensions?.validationErrors
                      ?.find((vE: any) => vE?.property === "closedOn")
                      ?.errors.map((message: string) => (
                        <div
                          key="error"
                          className={css({
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          })}
                        >
                          {message}
                          <span
                            className={css({
                              color: "#999",
                              marginLeft: "auto",
                              marginRight: "5px",
                            })}
                          >
                            Walidacja serwera
                          </span>
                          <AlertOctagon color="#999" size={12} />
                        </div>
                      ))[0])
                : ((errors as any)?.closedOn &&
                    (errors as any)?.closedOn?.message) ||
                  (closeContractAgreementError &&
                    closeContractAgreementError.graphQLErrors[0]?.extensions
                      ?.code === "INPUT_VALIDATION_ERROR" &&
                    closeContractAgreementError.graphQLErrors[0]?.extensions?.validationErrors
                      ?.find((vE: any) => vE?.property === "closedOn")
                      ?.errors.map((message: string) => (
                        <div
                          key="error"
                          className={css({
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          })}
                        >
                          {message}
                          <span
                            className={css({
                              color: "#999",
                              marginLeft: "auto",
                              marginRight: "5px",
                            })}
                          >
                            Walidacja serwera
                          </span>
                          <AlertOctagon color="#999" size={12} />
                        </div>
                      ))[0])
            }
            disabled={isLoading}
          >
            <ControlledDatePicker
              name="closedOn"
              control={control}
              rules={{
                required: formValidation.messages.required,
              }}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            kind={KIND.secondary}
            $style={{ marginRight: "10px" }}
            onClick={() => {
              close();
              reset();
            }}
            disabled={closeRequestFormLoading || closeContractAgreementLoading}
          >
            Anuluj
          </Button>
          <Button
            disabled={closeRequestFormLoading || closeContractAgreementLoading}
            isLoading={closeRequestFormLoading || closeContractAgreementLoading}
            type="submit"
          >
            Potwierdź
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
