import { BasicFilter, FILTERS } from "../../filters.d";
import { SubstitutionsField } from "./substitutions.form";

export const SUBSTITUTIONS_FILTERS: BasicFilter[] = [
  {
    id: SubstitutionsField.StartedAt,
    label: "Data rozpoczęcia",
    type: FILTERS.Date,
  },
  {
    id: SubstitutionsField.EndedAt,
    label: "Data zakończenia",
    type: FILTERS.Date,
  },
  {
    id: SubstitutionsField.Substitutee,
    label: "Pracownik zastępowany",
    type: FILTERS.Substitutee,
  },
  {
    id: SubstitutionsField.Substituter,
    label: "Pracownik zastępujący",
    type: FILTERS.Substituter,
  },
  {
    id: SubstitutionsField.CreatedAt,
    label: "Data tworzenia",
    type: FILTERS.Date,
  },
  {
    id: SubstitutionsField.CreatedById,
    label: "Utworzono przez",
    type: FILTERS.User,
  },
  {
    id: SubstitutionsField.UpdatedAt,
    label: "Data aktualizacji",
    type: FILTERS.Date,
  },
  {
    id: SubstitutionsField.updatedById,
    label: "Zaktualizowano przez",
    type: FILTERS.User,
  },
];
