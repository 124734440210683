import { ApolloError, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { KIND, SIZE } from "baseui/button";
import { StyledLink } from "baseui/link";
import { useSnackbar } from "notistack";
import React, { MouseEvent, useEffect, useMemo, useState } from "react";
import { Redirect, useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { Row } from "react-table";
import { Eye } from "tabler-icons-react";

import BottomPanel from "../../../../components/bottom-panel";
import Button from "../../../../components/button";
import Cell from "../../../../components/cell";
import Content from "../../../../components/content";
import Filters from "../../../../components/filters";
import FormattedValue from "../../../../components/formatted-value";
import Grid from "../../../../components/grid";
import Header from "../../../../components/header";
import PagingControls from "../../../../components/paging-controls";
import SortingTableHeader, {
  SortDirection,
} from "../../../../components/sorting-table-header";
import Table from "../../../../components/table";
import { SENDER_TYPENAMES } from "../../../../constants";
import { useDictionaries } from "../../../../contexts/dictionaries-context";
import { useLoading } from "../../../../contexts/loading-context";
import { usePaging } from "../../../../contexts/paging-context";
import { BasicFilter, FiltersState } from "../../../../filters";
import { checkDocumentPermissionToShow } from "../../../../utils/check-document-permission";
import { checkPermission } from "../../../../utils/check-permission";
import { translateFiltersState } from "../../../../utils/filters";
import { PERMISSIONS } from "../../../../utils/permissions";
import { setSortingParams } from "../../../../utils/sorting";
import { Document } from "../../documents";
import { DOCUMENTS_FILTERS } from "../../documents.filters";
import { ALL_DOCUMENTS } from "../../documents.gql";

enum FieldName {
  InternalNumber = "internalNumber",
  DocumentType = "documentType",
  DocumentKind = "documentKind",
  CreatedAt = "createdAt",
  DocumentNumber = "documentNumber",
}

export default function DocumentsInternal(): React.ReactElement {
  const { pageSize, currentPage, setTotalCount } = usePaging();
  const { search } = useLocation();

  const params = useMemo(() => new URLSearchParams(search), [search]);

  const [sortBy, setSortBy] = useState<FieldName | null>(
    (params.get("sortBy") as FieldName) || FieldName.CreatedAt
  );

  const [sortDirection, setSortDirection] = useState<SortDirection | null>(
    (params.get("sortDirection") as SortDirection) || SortDirection.DESC
  );

  const [css] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const {
    isFetching,
    setIsFetching,
    isPartialFetching,
    setIsPartialFetching,
  } = useLoading();
  const { incomingTypes, findValue, outgoingTypes } = useDictionaries();

  const handleSorting = (column: FieldName) => {
    setSortingParams(history, search, column, sortDirection);
    setSortBy(column);
    setSortDirection(
      sortDirection === null
        ? SortDirection.DESC
        : sortDirection === SortDirection.ASC
        ? SortDirection.DESC
        : SortDirection.ASC
    );
  };

  const [filters, setFilters] = useState<FiltersState>();

  const { refetch, data, error, loading } = useQuery(ALL_DOCUMENTS, {
    variables: {
      pageSize,
      offset: (currentPage - 1) * pageSize,
      sorting: {
        field: sortBy,
        direction: sortDirection,
      },
      filter: {
        and: [
          {
            documentKind: {
              eq: "Internal",
            },
          },
          {
            documentType: {
              notILike: "transferOrder",
            },
          },
          {
            ...(filters && (translateFiltersState(filters) as BasicFilter[])),
          },
        ],
      },
    },
  });

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    setTimeout(() => refetch(), 200);
    setIsPartialFetching(true);
  }, [currentPage, pageSize]);

  useEffect(() => {
    setTimeout(() => refetch(), 200);
    setIsPartialFetching(true);
  }, [sortBy, sortDirection]);

  useEffect(() => {
    if (data?.documents) setTimeout(() => refetch(), 200);
    setIsFetching(true);
  }, []);

  useEffect(() => {
    if (data?.documents) setIsFetching(false);
    if (data?.documents?.totalCount >= 0)
      setTotalCount(data?.documents?.totalCount);
  }, [data]);

  if (!checkPermission(PERMISSIONS.documentsRegister.read))
    return <Redirect to="/" />;

  const columns = React.useMemo(
    () => [
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.InternalNumber)}
            sortDirection={
              sortBy === FieldName.InternalNumber ? sortDirection : null
            }
          >
            Numer
          </SortingTableHeader>
        ),
        accessor: "internalNumber",
        Cell: ({ row }: { row: Row<Document> }) => {
          const documentKind = row?.original?.documentKind?.toLowerCase();
          const documentTypename = row?.original?.type;
          return (
            <StyledLink
              {...(row?.original?.documentType &&
                (checkDocumentPermissionToShow(row?.original?.documentType) ||
                  checkPermission(PERMISSIONS.document.readAll)) && {
                  onClick: (event: MouseEvent) => {
                    event.preventDefault();
                    history.push(
                      `/documents/${documentKind}/${documentTypename}/${row.original.id}`
                    );
                  },
                  href: `/documents/${documentKind}/${documentTypename}/${row.original.id}`,
                })}
            >
              {row?.original?.internalNumber}
            </StyledLink>
          );
        },
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.DocumentType)}
            sortDirection={
              sortBy === FieldName.DocumentType ? sortDirection : null
            }
          >
            Typ dokumentu
          </SortingTableHeader>
        ),
        id: "documentType",
        Cell: ({ row }: { row: Row<Document> }) => {
          const documentType = row.original.documentType
            ? findValue(row.original.documentType)
            : "";
          return <FormattedValue>{documentType as string}</FormattedValue>;
        },
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.DocumentKind)}
            sortDirection={
              sortBy === FieldName.DocumentKind ? sortDirection : null
            }
          >
            Rodzaj dokumentu
          </SortingTableHeader>
        ),
        id: "documentKind",
        Cell: ({ row }: { row: Row<Document> }) => (
          <FormattedValue dataType="document-kind">
            {row?.original?.documentKind}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.CreatedAt)}
            sortDirection={
              sortBy === FieldName.CreatedAt ? sortDirection : null
            }
          >
            Data rejestracji
          </SortingTableHeader>
        ),
        id: "createdAt",
        Cell: ({ row }: { row: Row<Document> }) => (
          <FormattedValue dataType="date">
            {row?.original?.createdAt}
          </FormattedValue>
        ),
      },
      {
        Header: "Autor",
        id: "createdBy",
        Cell: ({ row }: { row: Row<Document> }) => {
          return (
            <FormattedValue
              dataType="model:users"
              data={row?.original?.createdBy?.id}
            >
              {`${row?.original?.createdBy?.firstName} ${row?.original?.createdBy?.lastName}`}
            </FormattedValue>
          );
        },
      },
      {
        Header: "Odpowiedzialny",
        id: "responsible",
        Cell: ({ row }: { row: Row<Document> }) => {
          return (
            <FormattedValue
              dataType="model:users"
              data={row?.original?.responsible?.id}
            >
              {`${row?.original?.responsible?.firstName} ${row?.original?.responsible?.lastName}`}
            </FormattedValue>
          );
        },
      },
      {
        Header: "Jednostka organizacyjna",
        id: "organizationalUnit",
        Cell: ({ row }: { row: Row<Document> }) => (
          <FormattedValue
            dataType="model:organizational-units"
            data={row?.original?.organizationalUnit?.id}
          >
            {row?.original?.organizationalUnit?.name}
          </FormattedValue>
        ),
      },
      {
        Header: "Nadawca",
        id: "sender",
        Cell: ({ row }: { row: Row<Document> }) => (
          <FormattedValue
            {...(row?.original?.sender?.__typename ===
              SENDER_TYPENAMES.User && {
              dataType: "model:users",
              data: row?.original?.sender?.id,
            })}
          >
            {row?.original?.sender?.__typename === SENDER_TYPENAMES.Address ||
            row?.original?.sender?.__typename === SENDER_TYPENAMES.HiddenAddress
              ? row?.original?.sender?.name
              : row?.original?.sender?.__typename === SENDER_TYPENAMES.User
              ? `${row?.original?.sender?.firstName} ${row?.original?.sender?.lastName}`
              : ""}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.DocumentNumber)}
            sortDirection={
              sortBy === FieldName.DocumentNumber ? sortDirection : null
            }
          >
            Numer dokumentu
          </SortingTableHeader>
        ),
        id: "documentNumber",
        Cell: ({ row }: { row: Row<Document> }) => (
          <FormattedValue>{row?.original?.documentNumber}</FormattedValue>
        ),
      },
      {
        Header: "Temat",
        id: "title",
        Cell: ({ row }: { row: Row<Document> }) => {
          return <FormattedValue>{row?.original?.title}</FormattedValue>;
        },
      },
      {
        Header: "Opis",
        id: "description",
        Cell: ({ row }: { row: Row<Document> }) => {
          return <FormattedValue>{row?.original?.description}</FormattedValue>;
        },
      },
      {
        Header: "Etap w obiegu",
        id: "currentStatus",
        Cell: ({ row }: { row: Row<Document> }) => {
          return (
            <FormattedValue $style={{ fontSize: "12px" }}>
              {row?.original?.currentStatus?.documentFlowStep &&
                `${row?.original?.currentStatus?.documentFlowStep?.name}`}
            </FormattedValue>
          );
        },
      },
      {
        id: "actions",
        Cell: ({ row }: { row: Row<Document> }) => {
          const documentKind = row?.original?.documentKind?.toLowerCase();
          const documentTypename = row?.original?.type;
          return (
            <div
              className={css({
                display: "flex",
                justifyContent: "flex-end",
              })}
            >
              <Button
                kind={KIND.secondary}
                size={SIZE.mini}
                $style={{ marginLeft: "6px" }}
                onClick={() =>
                  history.push(
                    `/documents/${documentKind}/${documentTypename}/${row.original.id}`
                  )
                }
                startEnhancer={<Eye size={14} />}
              />
            </div>
          );
        },
      },
    ],
    [incomingTypes, outgoingTypes, sortBy, sortDirection, data, filters]
  );

  return (
    <article>
      <Header
        title="Rejestr WEW"
        recordsNum={data?.documents?.totalCount}
        labels={["Lista"]}
      />
      <Filters
        filters={DOCUMENTS_FILTERS}
        state={filters}
        setState={setFilters}
      />
      <Content filtersOffset>
        <Grid>
          <Cell span={12} $style={{ position: "relative" }}>
            <Table<Document>
              columns={columns}
              data={data?.documents?.nodes}
              isLoading={isFetching || isPartialFetching || loading}
              stickLastColumn
            />
          </Cell>

          <Cell span={12}>
            <BottomPanel>
              <PagingControls />
            </BottomPanel>
          </Cell>
        </Grid>
      </Content>
    </article>
  );
}
