import { useQuery } from "@apollo/client";
import React, { createContext, useContext, useMemo } from "react";

import { BudgetInfo } from "../containers/Payments/payments";
import { ALL_BUDGETS } from "../containers/Payments/payments.gql";
import { useAuth } from "./auth-context";

type BudgetsContextProps = {
  allBudgets: BudgetInfo[];
  budgetsLoading: boolean;
};

export const BudgetsContext = createContext<BudgetsContextProps>(
  {} as BudgetsContextProps
);

export function BudgetsProvider({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement {
  const { isAuthenticated } = useAuth();

  const { data, loading } = useQuery(ALL_BUDGETS, {
    skip: !isAuthenticated,
  });

  const allBudgets = useMemo(
    () =>
      data?.budgetInfoAll?.map((budget: BudgetInfo) => ({
        label: `${budget.categoryName} ${budget.categoryPath} ${budget.positionName} ${budget.bazaId} ${budget?.budgetName}`,
        id: budget?.bazaId,
        positionName: budget?.positionName,
        categoryName: budget?.categoryName,
        categoryPath: budget?.categoryPath,
        budgetName: budget?.budgetName,
      })),
    [data]
  );

  return (
    <BudgetsContext.Provider
      value={{
        allBudgets,
        budgetsLoading: loading,
      }}
    >
      {children}
    </BudgetsContext.Provider>
  );
}

export const useBudgets = (): BudgetsContextProps => useContext(BudgetsContext);
