import { useStyletron } from "baseui";
import React, { ReactElement } from "react";
import { Building, ChevronDownLeft, Friends } from "tabler-icons-react";

import { HistoryFlowStep } from "../containers/Flow/document-flow";
import { Position } from "../containers/Positions/positions";
import FormattedValue from "./formatted-value";

type FlowRecipientProps = {
  historyFlowStep: HistoryFlowStep;
};

export function FlowRecipient({
  historyFlowStep,
}: FlowRecipientProps): ReactElement {
  const [css] = useStyletron();

  const accessor =
    historyFlowStep.documentFlowRecipients || historyFlowStep.documentFlowStep;

  return historyFlowStep.endDate ? (
    historyFlowStep.isProcessedByDocumentFlowStarter ? (
      <>Rozpoczynający obieg</>
    ) : accessor.organizationalUnit && !accessor.users?.length ? (
      <div
        className={css({
          display: "flex",
          flexDirection: "column",
          rowGap: "5px",
        })}
      >
        <FormattedValue dataType="model:organizational-units">
          {accessor.organizationalUnit?.name}
        </FormattedValue>

        {!!accessor.positions?.length && (
          <div
            className={css({
              display: "flex",
              rowGap: "2px",
              flexDirection: "column",
            })}
          >
            {accessor.positions?.map(
              (role: Pick<Position, "id" | "name">, index: number) => (
                <div
                  key={`accepting-role-${role.id}`}
                  className={css({
                    fontSize: "12px",
                  })}
                >
                  {index === 0 && (
                    <ChevronDownLeft
                      size={14}
                      className={css({
                        verticalAlign: "middle",
                        marginRight: "6px",
                        display: "inline",
                      })}
                    />
                  )}

                  <Friends
                    size={14}
                    className={css({
                      verticalAlign: "middle",
                      marginRight: "10px",
                      display: "inline",
                      ...(index !== 0 && {
                        marginLeft: "20px",
                      }),
                    })}
                  />
                  {role.name}
                </div>
              )
            )}
          </div>
        )}
      </div>
    ) : !historyFlowStep.organizationalUnit && accessor.users?.length ? (
      <FormattedValue dataType="json:users-with-organizational-units">
        {(accessor.users as unknown) as JSON}
      </FormattedValue>
    ) : (
      <div
        className={css({
          display: "flex",
          flexDirection: "column",
          rowGap: "5px",
        })}
      >
        <div>
          <Building
            size={16}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              display: "inline",
            })}
          />
          Jednostka dokumentu
        </div>

        {!!accessor.positions?.length && (
          <div
            className={css({
              display: "flex",
              rowGap: "2px",
              flexDirection: "column",
            })}
          >
            {accessor.positions?.map(
              (role: Pick<Position, "id" | "name">, index: number) => (
                <div
                  key={`accepting-role-${role.id}`}
                  className={css({
                    fontSize: "12px",
                  })}
                >
                  {index === 0 && (
                    <ChevronDownLeft
                      size={14}
                      className={css({
                        verticalAlign: "middle",
                        marginRight: "6px",
                        display: "inline",
                      })}
                    />
                  )}
                  <Friends
                    size={14}
                    className={css({
                      verticalAlign: "middle",
                      marginRight: "10px",
                      display: "inline",
                      ...(index !== 0 && {
                        marginLeft: "20px",
                      }),
                    })}
                  />
                  {role.name}
                </div>
              )
            )}
          </div>
        )}
      </div>
    )
  ) : (
    <></>
  );
}
