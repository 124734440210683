import { FIELDS, FieldsGroup } from "../../fields.d";

export type FormInputs = {
  users?: [{ id: number; label?: string }];
  userId?: number;
  otp?: string;
};

export const DOCUMENT_PICKUPS_FIELDS: FieldsGroup[] = [
  {
    id: "basic",
    label: "Podstawowe informacje",
    fields: [
      {
        id: "state",
        label: "Stan",
        type: FIELDS.Input,
        dataType: "document-pickup-state",
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "createdById",
        label: "Utworzono przez",
        type: FIELDS.Input,
        dataType: "model:users",
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "confirmedById",
        label: "Pracownik wydający",
        type: FIELDS.Input,
        dataType: "model:users",
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "userId",
        label: "Pracownik odbierający",
        type: FIELDS.Input,
        dataType: "model:users",
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },

      {
        id: "requestedAt",
        label: "Data wysłania kodu",
        type: FIELDS.Input,
        dataType: "datetime",
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "confirmedAt",
        label: "Data potwierdzenia odbioru",
        type: FIELDS.Input,
        dataType: "datetime",
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "createdAt",
        label: "Data utworzenia",
        type: FIELDS.Input,
        dataType: "datetime",
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "updatedAt",
        label: "Data aktualizacji",
        type: FIELDS.Input,
        dataType: "datetime",
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
    ],
  },
];
