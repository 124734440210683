import {
  ColumnDef,
  getCoreRowModel,
  Row,
  RowData,
  Table,
  useReactTable,
} from "@tanstack/react-table";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { FormControl } from "baseui/form-control";
import { StatefulPopover } from "baseui/popover";
import { LabelSmall } from "baseui/typography";
import React, { ChangeEvent, memo, useEffect, useMemo, useState } from "react";
import { AlertTriangle, CirclePlus } from "tabler-icons-react";

import { Currency } from "../containers/Currencies/currencies";
import { DivisorItem, DivisorTemplate } from "../containers/Divisors/divisors";
import { generateUUID, localeParseFloat } from "../utils/misc";
import Button, { ForwardedButton } from "./button";
import FormattedValue from "./formatted-value";
import Input, { NumericInput } from "./input";
import {
  AllBudgetsSelect,
  DivisorTemplatesSelect,
  InternalAccountsSelect,
} from "./select";
import { useSkipper } from "./stateful-table-v8";
import TableV8 from "./table-v8";
import {
  TransferTableData,
  TransferTableItemData,
} from "./transfer-tables-composer";

declare module "@tanstack/react-table" {
  interface TableMeta<TData extends RowData> {
    updateCell: (rowIndex: number, columnId: string, value: unknown) => void;
    updateRow: (rowIndex: number, value: Record<string, unknown>) => void;
    removeRow: (rowIndex: number) => void;
  }
}

type TransferTableProps = {
  table: TransferTableData;
  items: TransferTableItemData[];
  setItems: (items: TransferTableItemData[]) => void;
  setTables: React.Dispatch<React.SetStateAction<TransferTableData[]>>;
  currency?: Currency;
  disabled?: boolean;
  error?: boolean;
};

export function TransferTable({
  table,
  items,
  setItems,
  setTables,
  currency,
  disabled,
  error,
}: TransferTableProps): React.ReactElement {
  const [css, theme] = useStyletron();

  const totalEnteredTablePercent = useMemo(
    () =>
      localeParseFloat(
        items
          ?.filter((item: TransferTableItemData) => item.table === table.id)
          ?.reduce((a, b) => a + (b.percent ? b.percent : 0), 0)
          ?.toFixed(2)
      ),
    [items]
  );

  const shouldShowPercentageAlert = !!(totalEnteredTablePercent > 100);

  const totalEnteredTableAmount = useMemo(
    () =>
      items
        ?.filter((item: TransferTableItemData) => item.table === table.id)
        ?.reduce((a, b) => a + (b.amount ? b.amount : 0), 0)
        ?.toFixed(2),
    [items]
  );

  const shouldShowTableAmountAlert = !!(
    table.availableAmount &&
    localeParseFloat(totalEnteredTableAmount) >
      localeParseFloat(table?.availableAmount)
  );

  const balance = useMemo(
    () =>
      localeParseFloat(table.availableAmount) -
      localeParseFloat(totalEnteredTableAmount),
    [items, table.availableAmount]
  );

  const columns = useMemo<ColumnDef<TransferTableItemData>[]>(
    () => [
      {
        header: "Lp.",
        cell: (cell) => cell.row.index + 1,
      },
      {
        id: "internalAccount",
        accessorKey: "internalAccount",
        header: "Konto wewnętrzne",
        cell: ({ getValue, row: { index }, column: { id }, table }: any) => {
          const initialValue = getValue();

          const [value, setValue] = useState(initialValue);

          useEffect(() => {
            setValue(initialValue);
          }, [initialValue]);

          return (
            <InternalAccountsSelect
              size="mini"
              value={value ? [value] : []}
              onChange={(params) => {
                setValue({
                  id: params?.option?.id,
                  label: params?.option?.label,
                });
              }}
              onBlur={() => {
                table.options.meta.updateCell(index, id, value);
              }}
              disabled={disabled}
            />
          );
        },
      },
      {
        id: "budget",
        accessorKey: "budget",
        header: () => (
          <Block
            display="flex"
            overrides={{
              Block: { style: { whiteSpace: "nowrap", minWidth: "80px" } },
            }}
          >
            Budżet
          </Block>
        ),
        cell: ({ getValue, row: { index }, column: { id }, table }: any) => {
          const initialValue = getValue();

          const [value, setValue] = useState(initialValue);

          useEffect(() => {
            setValue(initialValue);
          }, [initialValue]);

          return (
            <AllBudgetsSelect
              size="mini"
              value={value ? [value] : []}
              onChange={(params) => {
                setValue({
                  id: params?.option?.id,
                  label: params?.option?.label,
                });
              }}
              onBlur={() => {
                table.options.meta.updateCell(index, id, value);
              }}
              disabled={disabled}
            />
          );
        },
      },
      {
        id: "description",
        accessorKey: "description",
        header: () => (
          <Block
            display="flex"
            overrides={{
              Block: { style: { whiteSpace: "nowrap", minWidth: "200px" } },
            }}
          >
            Opis
          </Block>
        ),
        cell: ({ getValue, row: { index }, column: { id }, table }: any) => {
          const initialValue = getValue();

          const [value, setValue] = useState(initialValue);

          useEffect(() => {
            setValue(initialValue);
          }, [initialValue]);

          return (
            <Block display="flex" justifyContent="flex-end">
              <Input
                size="mini"
                value={value}
                onChange={(event) => {
                  setValue(event.currentTarget.value);
                }}
                onBlur={() => {
                  table.options.meta.updateCell(index, id, value);
                }}
                disabled={disabled}
              />
            </Block>
          );
        },
      },
      {
        id: "percent",
        accessorKey: "percent",
        header: "Udział",
        cell: ({
          getValue,
          row: { index, original },
          column: { id },
          table: rTable,
        }: any) => {
          const value = getValue();

          return (
            <NumericInput
              size="mini"
              value={value}
              disabled={!original.internalAccount || disabled}
              endEnhancer="%"
              type="number"
              step={0.1}
              min={0}
              $rootStyle={{ width: "150px" }}
              onValueChange={(value) => {
                const editingTableAmount = table.availableAmount;

                rTable.options.meta.updateRow(index, {
                  percent: localeParseFloat(value),
                  amount:
                    editingTableAmount && value
                      ? localeParseFloat(
                          new Intl.NumberFormat("en-US", {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                          })
                            .format(
                              (localeParseFloat(value) / 100) *
                                localeParseFloat(editingTableAmount)
                            )
                            .replace(/,/g, "")
                        )
                      : 0,
                });
              }}
            />
          );
        },
      },
      {
        id: "amount",
        accessorKey: "amount",
        header: "Kwota",
        cell: ({
          getValue,
          row: { index, original },
          column: { id },
          table: rTable,
        }: any) => {
          const value = getValue();

          return (
            <NumericInput
              size="mini"
              value={value}
              disabled={!original.internalAccount || disabled}
              endEnhancer={currency?.code || "PLN"}
              type="number"
              step={1}
              min={0}
              max={100}
              $rootStyle={{ width: "150px" }}
              onValueChange={(value) => {
                rTable.options.meta.updateRow(index, {
                  percent: localeParseFloat(
                    new Intl.NumberFormat("en-US", {
                      style: "percent",
                      maximumFractionDigits: 2,
                    })
                      .format(
                        localeParseFloat(value) /
                          localeParseFloat(table.availableAmount)
                      )
                      .replace(/%/g, "")
                  ),
                  amount: localeParseFloat(value) || undefined,
                });
              }}
            />
          );
        },
      },
      {
        id: "actions",
        cell: ({
          row,
          table,
        }: {
          row: Row<TransferTableItemData>;
          table: Table<TransferTableItemData>;
        }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            {row.original.internalAccount ||
            row.original.budget ||
            row.original.percent ||
            row.original.amount ? (
              <StatefulPopover
                overrides={{
                  Inner: {
                    style: {
                      borderTopLeftRadius: theme.borders.radius200,
                      borderTopRightRadius: theme.borders.radius200,
                      borderBottomRightRadius: theme.borders.radius200,
                      borderBottomLeftRadius: theme.borders.radius200,
                      paddingTop: theme.sizing.scale200,
                      paddingBottom: theme.sizing.scale200,
                      paddingRight: theme.sizing.scale300,
                      paddingLeft: theme.sizing.scale300,
                      backgroundColor: "white",
                    },
                  },
                  Body: {
                    style: {
                      borderTopLeftRadius: theme.borders.radius200,
                      borderTopRightRadius: theme.borders.radius200,
                      borderBottomRightRadius: theme.borders.radius200,
                      borderBottomLeftRadius: theme.borders.radius200,
                      marginRight: "10px",
                    },
                  },
                  Arrow: {
                    style: {
                      backgroundColor: "white",
                    },
                  },
                }}
                content={({ close }) => (
                  <Block
                    padding="scale200"
                    backgroundColor="white"
                    display="flex"
                    alignItems="center"
                  >
                    <LabelSmall marginRight="8px">Czy na pewno?</LabelSmall>
                    <Button
                      kind="secondary"
                      size="mini"
                      onClick={() => {
                        table.options.meta?.removeRow(row.index);

                        close();
                      }}
                      $style={{
                        backgroundColor: theme.colors.negative400,
                        color: "white",
                        ":hover": {
                          backgroundColor: theme.colors.negative500,
                        },
                      }}
                      disabled={disabled}
                    >
                      Tak, usuń
                    </Button>
                    <Button
                      kind="secondary"
                      size="mini"
                      onClick={() => close()}
                      $style={{
                        marginLeft: "5px",
                      }}
                      disabled={disabled}
                    >
                      Anuluj
                    </Button>
                  </Block>
                )}
                showArrow
              >
                <ForwardedButton
                  type="reset"
                  kind="secondary"
                  size="mini"
                  disabled={disabled}
                >
                  Usuń
                </ForwardedButton>
              </StatefulPopover>
            ) : (
              <Button
                disabled={disabled}
                kind="secondary"
                size="mini"
                type="button"
                onClick={() => {
                  table.options.meta?.removeRow(row.index);
                }}
              >
                Usuń
              </Button>
            )}
          </div>
        ),
      },
    ],
    [table.availableAmount]
  );

  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();

  const rTable = useReactTable({
    columns,
    data: items ? items : [],
    getCoreRowModel: getCoreRowModel(),
    meta: {
      updateCell: (rowIndex: number, columnId: string, value: unknown) => {
        skipAutoResetPageIndex();

        setItems(
          items.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...items[rowIndex],
                [columnId]: value,
              };
            }

            return row;
          })
        );
      },
      updateRow: (rowIndex: number, value: Record<string, unknown>) => {
        skipAutoResetPageIndex();

        setItems(
          items.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...items[rowIndex],
                ...value,
              };
            }

            return row;
          })
        );
      },
      removeRow: (rowIndex: number) => {
        skipAutoResetPageIndex();

        setItems(items.filter((item, index) => index !== rowIndex));
      },
    },
    autoResetAll: false,
    autoResetPageIndex,
    autoResetExpanded: false,
  });

  return (
    <div key={table.id}>
      <Block display="flex" justifyContent="space-between">
        <Block display="flex">
          <Block>
            <FormControl label="Szablon">
              <DivisorTemplatesSelect
                size="mini"
                value={table?.divisorTemplate ? [table?.divisorTemplate] : []}
                onChange={(params) => {
                  if (!params.option) {
                    setTables((tables) => {
                      const index = tables.findIndex(
                        ({ id }) => id === table.id
                      );

                      return [
                        ...tables.slice(0, index),
                        {
                          ...table,
                          divisorTemplate: undefined,
                        },
                        ...tables.slice(index + 1),
                      ];
                    });
                    return;
                  }

                  const divisorTemplate = params.option as DivisorTemplate;

                  const newItems = [
                    ...(divisorTemplate?.divisorItems?.map(
                      (divisorItem: DivisorItem) => {
                        const newId = generateUUID();

                        return {
                          id: newId,
                          table: table.id,
                          internalAccount: {
                            id: divisorItem.internalAccount.id,
                            label: divisorItem.internalAccount.name,
                          },
                          percent: localeParseFloat(
                            new Intl.NumberFormat("en-US", {
                              style: "percent",
                              maximumFractionDigits: 2,
                            })
                              .format(divisorItem.percent)
                              .replace(/%/g, "")
                          ),
                          amount: localeParseFloat(
                            new Intl.NumberFormat("en-US", {
                              maximumFractionDigits: 2,
                            })
                              .format(
                                divisorItem.percent *
                                  localeParseFloat(table.availableAmount)
                              )
                              .replace(/,/g, "")
                          ),
                        };
                      }
                    ) as TransferTableItemData[]),
                  ];

                  const totalEnteredTableAmount = newItems
                    .filter((item) => item.table === table.id)
                    .reduce((a, b) => a + (b.amount ? b.amount : 0), 0)
                    .toFixed(2);

                  setTables((tables) => {
                    const index = tables.findIndex(({ id }) => id === table.id);

                    return [
                      ...tables.slice(0, index),
                      {
                        ...table,
                        items: newItems,
                        divisorTemplate,
                        balance:
                          localeParseFloat(table.availableAmount) -
                          localeParseFloat(totalEnteredTableAmount),
                      },
                      ...tables.slice(index + 1),
                    ];
                  });
                }}
              />
            </FormControl>
          </Block>
          <Block marginLeft="10px">
            <FormControl label="Kwota">
              <Input
                size="mini"
                endEnhancer={currency?.code || "PLN"}
                type="numberasstring"
                step={0.01}
                value={table.availableAmount}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setTables((tables) => {
                    const index = tables.findIndex(({ id }) => id === table.id);

                    return [
                      ...tables.slice(0, index),
                      {
                        ...table,
                        availableAmount: event?.target?.value,
                        balance:
                          localeParseFloat(event?.target?.value) -
                          localeParseFloat(totalEnteredTableAmount),
                      },
                      ...tables.slice(index + 1),
                    ];
                  });
                }}
              />
            </FormControl>
          </Block>
        </Block>
        <Block
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          marginBottom="10px"
        >
          <LabelSmall
            $style={{
              color:
                balance !== 0 ||
                localeParseFloat(table.availableAmount) === 0 ||
                !table.availableAmount
                  ? theme.colors.negative
                  : theme.colors.positive,
              fontWeight: 500,
            }}
          >
            {localeParseFloat(table.availableAmount) === 0 ||
            !table.availableAmount ||
            isNaN(localeParseFloat(table.availableAmount))
              ? "Nie wprowadzono kwoty do podzielenia"
              : balance > 0
              ? `Pozostało ${balance.toFixed(2)} ${currency?.code || "PLN"}`
              : balance === 0
              ? "Wykorzystano całą kwotę"
              : `Przekroczono o ${Math.abs(balance).toFixed(2)} ${
                  currency?.code || "PLN"
                }`}
          </LabelSmall>
        </Block>
      </Block>

      <TableV8<TransferTableItemData>
        table={rTable}
        scrollable
        editable
        {...(error && {
          $style: {
            backgroundColor: theme.colors.inputFillError,
            borderColor: theme.colors.inputBorderError,
            borderWidth: "2px",
          },
        })}
        $footer={() => {
          return (
            <>
              <td
                className={css({
                  paddingTop: theme.sizing.scale100,
                  paddingBottom: theme.sizing.scale100,
                  paddingLeft: theme.sizing.scale500,
                  paddingRight: theme.sizing.scale500,
                  position: "sticky",
                  left: 0,
                })}
                colSpan={4}
              >
                <Block display="flex" alignItems="center" gridColumnGap="12px">
                  <Button
                    kind="tertiary"
                    size="compact"
                    type="button"
                    key="button"
                    onClick={() => {
                      const newId = `temp ${generateUUID()}`;

                      setItems([
                        ...items,
                        { id: newId, edit: true, table: table.id },
                      ]);
                    }}
                    startEnhancer={<CirclePlus size={16} />}
                  >
                    Dodaj pozycję
                  </Button>

                  <Button
                    kind="tertiary"
                    size="compact"
                    type="button"
                    key="button"
                    onClick={() => {
                      setItems([
                        ...items,
                        ...Array.from({ length: 10 }).map(() => ({
                          id: generateUUID(),
                          table: table.id,
                        })),
                      ]);
                    }}
                    startEnhancer={<CirclePlus size={16} />}
                  >
                    Dodaj 10 pozycji
                  </Button>

                  <Button
                    kind="tertiary"
                    size="compact"
                    type="button"
                    key="button"
                    onClick={() => {
                      setItems([
                        ...items,
                        ...Array.from({ length: 25 }).map(() => ({
                          id: generateUUID(),
                          table: table.id,
                        })),
                      ]);
                    }}
                    startEnhancer={<CirclePlus size={16} />}
                  >
                    Dodaj 25 pozycji
                  </Button>
                </Block>
              </td>
              <td
                className={css({
                  paddingTop: theme.sizing.scale100,
                  paddingBottom: theme.sizing.scale100,
                  paddingLeft: theme.sizing.scale500,
                  paddingRight: theme.sizing.scale500,
                  position: "sticky",
                })}
                colSpan={1}
              >
                <div
                  className={css({
                    marginRight: "10px",
                    display: "flex",
                    justifyContent: "flex-end",
                    cursor: "default",
                  })}
                >
                  {shouldShowPercentageAlert && (
                    <AlertTriangle color={theme.colors.negative} size={18} />
                  )}
                  <LabelSmall
                    {...(shouldShowPercentageAlert && {
                      $style: {
                        color: theme.colors.negative,
                        marginLeft: "10px",
                      },
                    })}
                  >
                    Suma:{" "}
                    <strong>
                      <FormattedValue>
                        {totalEnteredTablePercent + "%"}
                      </FormattedValue>
                    </strong>
                  </LabelSmall>
                </div>
              </td>
              <td
                className={css({
                  paddingTop: theme.sizing.scale100,
                  paddingBottom: theme.sizing.scale100,
                  paddingLeft: theme.sizing.scale500,
                  paddingRight: theme.sizing.scale500,
                  position: "sticky",
                })}
                colSpan={1}
              >
                <div
                  className={css({
                    display: "flex",
                    justifyContent: "flex-end",
                    cursor: "default",
                  })}
                >
                  {" "}
                  {shouldShowTableAmountAlert && (
                    <AlertTriangle color={theme.colors.negative} size={18} />
                  )}
                  <LabelSmall
                    {...(shouldShowTableAmountAlert && {
                      $style: {
                        color: theme.colors.negative,
                        marginLeft: "10px",
                      },
                    })}
                  >
                    Suma:{" "}
                    <strong>
                      <FormattedValue
                        dataType="quota"
                        currency={currency?.code}
                      >
                        {totalEnteredTableAmount}
                      </FormattedValue>
                    </strong>
                  </LabelSmall>
                </div>
              </td>
              <td />
            </>
          );
        }}
      />
      <Block display="flex" justifyContent="flex-end" marginTop="10px">
        {items?.some(
          (item: TransferTableItemData) => item.table === table.id
        ) ? (
          <StatefulPopover
            overrides={{
              Inner: {
                style: {
                  borderTopLeftRadius: theme.borders.radius200,
                  borderTopRightRadius: theme.borders.radius200,
                  borderBottomRightRadius: theme.borders.radius200,
                  borderBottomLeftRadius: theme.borders.radius200,
                  paddingTop: theme.sizing.scale200,
                  paddingBottom: theme.sizing.scale200,
                  paddingRight: theme.sizing.scale300,
                  paddingLeft: theme.sizing.scale300,
                  backgroundColor: "white",
                },
              },
              Body: {
                style: {
                  borderTopLeftRadius: theme.borders.radius200,
                  borderTopRightRadius: theme.borders.radius200,
                  borderBottomRightRadius: theme.borders.radius200,
                  borderBottomLeftRadius: theme.borders.radius200,
                  marginRight: "10px",
                },
              },
              Arrow: {
                style: {
                  backgroundColor: "white",
                },
              },
            }}
            content={({ close }) => (
              <Block
                padding="scale200"
                backgroundColor="white"
                display="flex"
                alignItems="center"
              >
                <LabelSmall marginRight="8px">Czy na pewno?</LabelSmall>
                <Button
                  size="mini"
                  $style={{ backgroundColor: theme.colors.negative }}
                  type="button"
                  onClick={() => {
                    setTables((tables) =>
                      tables?.filter(
                        (tableFromState) => tableFromState?.id !== table?.id
                      )
                    );
                  }}
                >
                  Tak, usuń
                </Button>
                <Button
                  size="mini"
                  kind="secondary"
                  $style={{ marginLeft: "5px" }}
                  type="button"
                  onClick={() => close()}
                >
                  Anuluj
                </Button>
              </Block>
            )}
            showArrow
          >
            <ForwardedButton
              size="mini"
              $style={{ backgroundColor: theme.colors.negative }}
              type="button"
            >
              Usuń tabelę
            </ForwardedButton>
          </StatefulPopover>
        ) : (
          <Button
            size="mini"
            $style={{ backgroundColor: theme.colors.negative }}
            type="button"
            onClick={() => {
              setTables((tables) =>
                tables?.filter(
                  (tableFromState) => tableFromState?.id !== table?.id
                )
              );
            }}
          >
            Usuń
          </Button>
        )}
      </Block>
    </div>
  );
}

export const MemoizedTransferTable = memo(TransferTable);
