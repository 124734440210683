import { FIELDS, FieldsGroup } from "../../fields.d";

export type FormInputs = {
  name: string;
  symbol: string;
  parentId: number;
  description?: string;
  archivalCategory?: string;
  isBeneficiaryAddressRequired?: boolean;
  isCaseConductedElectronically?: boolean;
  isCaseNotRequired?: boolean;
};

export const JRWA_CLASSIFICATIONS_FIELDS: FieldsGroup[] = [
  {
    id: "basic",
    label: "Podstawowe informacje",
    fields: [
      {
        id: "symbol",
        label: "Symbol",
        type: FIELDS.Input,
        span: 4,
        caption:
          "Symbol powinien rozpoczynać się od symbolu nadrzędnego wpisu.",
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "name",
        label: "Nazwa",
        type: FIELDS.Input,
        span: 8,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "isBeneficiaryAddressRequired",
        label: "Wymagany adres beneficjenta",
        type: FIELDS.Checkbox,
        dataType: "boolean",
        span: 4,
        show: { visible: true },
        create: { visible: true, required: false },
        edit: { visible: true, required: false },
      },
      {
        id: "archivalCategory",
        label: "Kategoria archiwalna",
        type: FIELDS.Input,
        span: 8,
        show: { visible: true },
        create: { visible: true, required: false },
        edit: { visible: true, required: false },
      },
      {
        id: "description",
        label: "Opis",
        type: FIELDS.Input,
        span: 12,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
    ],
  },
  {
    id: "additional",
    label: "Specyfikacja",
    fields: [
      {
        id: "isCaseConductedElectronically",
        label: "Sposób prowadzenia spraw",
        type: FIELDS.CaseConductSwitch,
        dataType: "case-conduct-boolean",
        span: 6,
        show: { visible: true },
        create: { visible: true, required: false },
        edit: { visible: true, required: false },
      },
      {
        id: "isCaseNotRequired",
        label:
          "W ramach klasyfikacji gromadzona jest dokumentacja niestanowiąca akt sprawy",
        type: FIELDS.Checkbox,
        dataType: "boolean",
        span: 12,
        show: { visible: true },
        create: { visible: true, required: false },
        edit: { visible: true, required: false },
      },
    ],
  },
];
