import { FIELDS, FieldsGroup } from "../../fields.d";
import { SubstitutionsField } from "../Substitutions/substitutions.form";

export const MY_SUBSTITUTIONS_FIELDS: FieldsGroup[] = [
  {
    id: "basic",
    label: "Podstawowe informacje",
    fields: [
      {
        id: SubstitutionsField.Substitutee,
        label: "Pracownik zastępowany",
        type: FIELDS.UsersSelect,
        dataType: "model:users",
        span: 6,
        disabled: true,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: SubstitutionsField.Substituter,
        label: "Pracownika zastępujący",
        type: FIELDS.UsersSelect,
        dataType: "model:users",
        span: 6,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: SubstitutionsField.StartedAt,
        label: "Data rozpoczęcia",
        type: FIELDS.DatePicker,
        dataType: "date",
        span: 6,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
        minDate: new Date(),
        info: "substitutionStartDate",
      },
      {
        id: SubstitutionsField.EndedAt,
        label: "Data zakończenia",
        type: FIELDS.DatePicker,
        dataType: "date",
        span: 6,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
        caption:
          "Pozostawienie tego pola pustego oznacza zastępstwo bezterminowe",
        info: "substitutionEndDate",
      },
      {
        id: SubstitutionsField.EndedAt,
        label: "Status",
        type: FIELDS.SubstitutionStatus,
        span: 6,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
    ],
  },
];
