import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { KIND } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { Pencil, Trash } from "tabler-icons-react";

import Cell from "../../../components/cell";
import ConfirmDialog from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import FormControl from "../../../components/form-control";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import { DOCUMENT_TYPES, FLOW_ACTION_WARNINGS } from "../../../constants";
import { useLoading } from "../../../contexts/loading-context";
import { FIELDS } from "../../../fields.d";
import { checkPermission } from "../../../utils/check-permission";
import { PERMISSIONS } from "../../../utils/permissions";
import { DOCUMENT_FLOW_ACTION, DOCUMENT_FLOW_ACTION_DELETE } from "../flow.gql";
import { FLOW_ACTIONS_FIELDS } from "../flow-actions.form";

export default function FlowShowAction(): React.ReactElement {
  const [css, theme] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const history = useHistory();
  const { flowId, actionId } = useParams<{
    flowId: string;
    actionId?: string;
  }>();
  const { setIsLoading, isFetching, setIsFetching } = useLoading();

  const { refetch, data, error } = useQuery(DOCUMENT_FLOW_ACTION, {
    variables: { id: actionId ? parseInt(actionId) : null },
  });
  const flowDocumentType = data?.documentFlowAction?.documentFlow?.documentType;
  const flowActionWarnings = data?.documentFlowAction?.warnings;

  const [deleteAction] = useMutation(DOCUMENT_FLOW_ACTION_DELETE);

  const submit = async () => {
    setIsLoading(true);

    try {
      await deleteAction({
        variables: {
          documentFlowActionDeleteInput: {
            id: actionId ? parseInt(actionId) : null,
          },
        },
      });

      enqueueSnackbar({
        message: "Usunięto pomyślnie",
        variant: "success",
      });

      setIsConfirmDialogOpen(false);

      history.push(`/flow/${flowId}`);
    } catch (error: unknown) {
      setIsConfirmDialogOpen(false);

      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    if (data?.documentFlowAction) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => data?.documentFlowAction && setIsFetching(false), [data]);

  if (!checkPermission(PERMISSIONS.documentFlowAction.read))
    return <Redirect to="/" />;

  return (
    <article>
      <Header
        title={`${data?.documentFlowAction?.label}`}
        labels={[
          `Kroki dla procesu: ${data?.documentFlowAction?.documentFlow?.name}`,
          "Wyświetlanie",
        ]}
        goBackOption
        buttons={[
          {
            label: "Edytuj",
            kind: KIND.secondary,
            startEnhancer: <Pencil size={18} />,
            permission: checkPermission(PERMISSIONS.documentFlowAction.update),
            onClick: () =>
              history.push(
                `/flow/${data?.documentFlowAction?.documentFlowId}/action/${actionId}/edit`
              ),
          },
        ]}
        actions={[
          {
            label: "Usuń akcję",
            icon: Trash,
            color: theme.colors.negative,
            onClick: () => setIsConfirmDialogOpen(true),
            permission: checkPermission(PERMISSIONS.documentFlowAction.delete),
          },
        ]}
      />
      <Content>
        <Grid>
          {FLOW_ACTIONS_FIELDS.filter(
            (g) => g.fields.filter((f) => f.show.visible).length > 0
          ).map((group) => [
            group.label &&
              ((group.id === "warnings" &&
                flowDocumentType === DOCUMENT_TYPES.Invoice) ||
                group.id !== "warnings") && (
                <Cell key={group.id + `-group`} span={12}>
                  <LabelMedium marginBottom="scale200" marginTop="scale600">
                    {group.label}
                  </LabelMedium>
                  <hr
                    className={css({
                      borderWidth: "0px",
                      height: "1px",
                      backgroundColor: "#eee",
                    })}
                  />
                </Cell>
              ),
            group.fields
              .filter((f) => f.show.visible)
              .map(
                (item, index) =>
                  item.id &&
                  ((item.id ===
                    FLOW_ACTION_WARNINGS.NoPaymentAssignedToInvoice &&
                    flowDocumentType === DOCUMENT_TYPES.Invoice) ||
                    item.id !==
                      FLOW_ACTION_WARNINGS.NoPaymentAssignedToInvoice) && (
                    <Cell
                      span={item.span || 6}
                      key={group.id + `-field` + index}
                    >
                      <FormControl label={item.label} disabled={true}>
                        {isFetching ? (
                          <Skeleton
                            rows={0}
                            height="20px"
                            width="100%"
                            animation
                          />
                        ) : (
                          <FormattedValue
                            dataType={item.dataType}
                            data={
                              item.type === FIELDS.StepsSelect
                                ? `/flow/${flowId}/step/${data?.documentFlowAction.endDocumentFlowStepId}`
                                : item.id === "documentFlow"
                                ? `${flowId}`
                                : data?.documentFlowAction[item.id]
                            }
                            {...(item.id === "notificationContent" && {
                              textWhenEmpty: "(został) przeniesiony",
                              $style: {
                                color: "#000",
                              },
                            })}
                          >
                            {item.id ===
                            FLOW_ACTION_WARNINGS.NoPaymentAssignedToInvoice
                              ? flowActionWarnings
                                ? flowActionWarnings?.length
                                : false
                              : item.show.accessor
                              ? data?.documentFlowAction[
                                  item.show.accessor[0]
                                ]?.[item.show.accessor[1]]
                              : data?.documentFlowAction[item.id]}
                          </FormattedValue>
                        )}
                      </FormControl>
                    </Cell>
                  )
              ),
          ])}
          <ConfirmDialog
            isOpen={isConfirmDialogOpen}
            label={`Usunięcie akcji ${data?.documentFlowAction?.label}`}
            close={() => setIsConfirmDialogOpen(false)}
            confirm={submit}
          />
        </Grid>
      </Content>
    </article>
  );
}
