import { BasicFilter, FILTERS } from "./../../filters.d";

export const INTERNAL_ACCOUNTS_FILTERS: BasicFilter[] = [
  {
    id: "name",
    label: "Nazwa",
    type: FILTERS.Text,
  },
  {
    id: "fundingSource",
    label: "Źródło finansowania",
    type: FILTERS.Text,
  },
  {
    id: "accountNumber",
    label: "Numer konta",
    type: FILTERS.Text,
  },
];
