import { gql } from "@apollo/client";

export const DOCUMENT_PICKUPS_INDEX = gql`
  query(
    $pageSize: Int
    $offset: Int
    $sorting: [DocumentPickupSort!]
    $filter: DocumentPickupFilter
  ) {
    documentPickups(
      paging: { limit: $pageSize, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        state
        user {
          id
          firstName
          lastName
        }
        userId
        isModifiable
        requestedAt
        confirmedAt
        createdAt
        updatedAt
        createdById
        createdBy {
          id
          firstName
          lastName
        }
        confirmedById
        confirmedBy {
          id
          firstName
          lastName
        }
        documents {
          id
        }
      }
    }
  }
`;

export const DOCUMENT_PICKUPS_SHOW = gql`
  query($id: Int!) {
    documentPickup(id: $id) {
      id
      state
      user {
        id
        firstName
        lastName
      }
      userId
      isModifiable
      requestedAt
      confirmedAt
      confirmedById
      confirmedBy {
        id
        firstName
        lastName
      }
      createdAt
      updatedAt
      createdById
      createdBy {
        id
        firstName
        lastName
      }
      documents {
        id
        documentType
        type
        documentKind
        internalNumber
        createdAt
        organizationalUnit {
          id
          name
        }
      }
    }
  }
`;

export const DOCUMENT_PICKUPS_CREATE = gql`
  mutation($documentPickupCreateInput: DocumentPickupCreateInput!) {
    documentPickupCreate(
      documentPickupCreateInput: $documentPickupCreateInput
    ) {
      id
    }
  }
`;

export const DOCUMENT_PICKUPS_UPDATE = gql`
  mutation($documentPickupUpdateInput: DocumentPickupUpdateInput!) {
    documentPickupUpdate(
      documentPickupUpdateInput: $documentPickupUpdateInput
    ) {
      id
    }
  }
`;

export const DOCUMENT_PICKUPS_DELETE = gql`
  mutation($documentPickupDeleteInput: DocumentPickupDeleteInput!) {
    documentPickupDelete(
      documentPickupDeleteInput: $documentPickupDeleteInput
    ) {
      id
    }
  }
`;

export const DOCUMENT_PICKUPS_START_PICKUP = gql`
  mutation($documentPickupStartPickupInput: DocumentPickupStartPickupInput!) {
    documentPickupStartPickup(
      documentPickupStartPickupInput: $documentPickupStartPickupInput
    ) {
      id
    }
  }
`;

export const DOCUMENT_PICKUPS_CONFIRM_PICKUP = gql`
  mutation(
    $documentPickupConfirmPickupInput: DocumentPickupConfirmPickupInput!
  ) {
    documentPickupConfirmPickup(
      documentPickupConfirmPickupInput: $documentPickupConfirmPickupInput
    ) {
      id
    }
  }
`;

export const DOCUMENT_PICKUPS_START_RETURN = gql`
  mutation($documentPickupStartReturnInput: DocumentPickupStartReturnInput!) {
    documentPickupStartReturn(
      documentPickupStartReturnInput: $documentPickupStartReturnInput
    ) {
      id
    }
  }
`;

export const DOCUMENT_PICKUPS_CONFIRM_RETURN = gql`
  mutation(
    $documentPickupConfirmReturnInput: DocumentPickupConfirmReturnInput!
  ) {
    documentPickupConfirmReturn(
      documentPickupConfirmReturnInput: $documentPickupConfirmReturnInput
    ) {
      id
    }
  }
`;

export const DOCUMENT_PICKUPS_RESEND_OTP = gql`
  mutation($documentPickupResendOtpInput: DocumentPickupResendOtpInput!) {
    documentPickupResendOtp(
      documentPickupResendOtpInput: $documentPickupResendOtpInput
    ) {
      id
    }
  }
`;

export const DOCUMENT_PICKUPS_CHANGE_USER = gql`
  mutation(
    $documentPickupUpdateUserInput: DocumentPickupUpdatePickupUserInput!
  ) {
    documentPickupUpdateUser(
      documentPickupUpdateUserInput: $documentPickupUpdateUserInput
    ) {
      id
    }
  }
`;
