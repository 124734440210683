import { gql } from "@apollo/client";

export const PACZKA_POCZTOWA_CREATE = gql`
  mutation($paczkaPocztowaInput: PaczkaPocztowaInput!) {
    paczkaPocztowaCreate(paczkaPocztowaInput: $paczkaPocztowaInput) {
      id
    }
  }
`;

export const LIST_ZWYKLY_CREATE = gql`
  mutation($listZwyklyInput: ListZwyklyInput!) {
    listZwyklyCreate(listZwyklyInput: $listZwyklyInput) {
      id
    }
  }
`;

export const PRZESYLKA_POLECONA_KRAJOWA_CREATE = gql`
  mutation($przesylkaPoleconaKrajowaInput: PrzesylkaPoleconaKrajowaInput!) {
    przesylkaPoleconaKrajowaCreate(
      przesylkaPoleconaKrajowaInput: $przesylkaPoleconaKrajowaInput
    ) {
      id
    }
  }
`;

export const PRZESYLKA_NIEREJESTROWANA_KRAJOWA_CREATE = gql`
  mutation(
    $przesylkaNierejestrowanaKrajowaInput: PrzesylkaNierejestrowanaKrajowaInput!
  ) {
    przesylkaNierejestrowanaKrajowaCreate(
      przesylkaNierejestrowanaKrajowaInput: $przesylkaNierejestrowanaKrajowaInput
    ) {
      id
    }
  }
`;

export const PRZESYLKA_LISTOWA_ZADEKLAROWANA_WARTOSC_CREATE = gql`
  mutation(
    $przesylkaListowaZadeklarowanaWartoscInput: PrzesylkaListowaZadeklarowanaWartoscInput!
  ) {
    przesylkaListowaZadeklarowanaWartoscCreate(
      przesylkaListowaZadeklarowanaWartoscInput: $przesylkaListowaZadeklarowanaWartoscInput
    ) {
      id
    }
  }
`;

export const LIST_WARTOSCIOWY_KRAJOWY_CREATE = gql`
  mutation($listWartosciowyKrajowyInput: ListWartosciowyKrajowyInput!) {
    listWartosciowyKrajowyCreate(
      listWartosciowyKrajowyInput: $listWartosciowyKrajowyInput
    ) {
      id
    }
  }
`;

export const PRZESYLKA_POLECONA_ZAGRANICZNA_CREATE = gql`
  mutation(
    $przesylkaPoleconaZagranicznaInput: PrzesylkaPoleconaZagranicznaInput!
  ) {
    przesylkaPoleconaZagranicznaCreate(
      przesylkaPoleconaZagranicznaInput: $przesylkaPoleconaZagranicznaInput
    ) {
      id
    }
  }
`;

export const PRZESYLKA_ZAGRANICZNA_CREATE = gql`
  mutation($przesylkaZagranicznaInput: PrzesylkaZagranicznaInput!) {
    przesylkaZagranicznaCreate(
      przesylkaZagranicznaInput: $przesylkaZagranicznaInput
    ) {
      id
    }
  }
`;

export const PACZKA_ZAGRANICZNA_CREATE = gql`
  mutation($paczkaZagranicznaInput: PaczkaZagranicznaInput!) {
    paczkaZagranicznaCreate(paczkaZagranicznaInput: $paczkaZagranicznaInput) {
      id
    }
  }
`;
