import { ApolloError, useMutation, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import Cookies from "js-cookie";
import { useSnackbar } from "notistack";
import React, { createContext, useContext, useEffect, useState } from "react";

import Loading from "../components/loading";
import {
  AUTH_TOKEN,
  LOCAL_STORAGE_VARIABLES,
  SUBSTITUTION_TOKEN,
} from "../constants";
import { ACCOUNT_UPDATE } from "../containers/MyAccount/my-account.gql";
import { User } from "../containers/Users/users";
import { client } from "../utils/client";
import { useLoading } from "./loading-context";

type AuthContextProps = {
  user: User | null;
  setUser: (user: User) => void;
  logout: () => void;
  isAuthenticated: boolean;
  setIsAuthenticated: (authenticated: boolean) => void;
  refetch: () => void;
  refetchNewAssignments: () => void;
  interfacePreferences: JSON | undefined;
  getInterfacePreferences: () => JSON | undefined;
  updateUser: (user: Partial<User>) => void;
  newIndicator?: number;
};

export const AuthContext = createContext<AuthContextProps>(
  {} as AuthContextProps
);

const FETCH_ME = gql`
  query Me {
    me {
      id
      firstName
      lastName
      email
      loginAD
      createdAt
      updatedAt
      lastActivityAt
      lastLoginAt
      isBlockedByAdmin
      isBlockedByAD
      hasEmailNotificationsEnabled
      permissions {
        id
        systemName
      }
      roles {
        id
        name
      }
      interfacePreferences
      position
      unreadNotificationCount
      organizationalUnitToUsers {
        organizationalUnit {
          id
          name
          symbol
          budgetSymbol
        }
        position {
          id
          name
          role {
            id
            name
          }
        }
        user {
          id
        }
      }
    }
  }
`;

const NEW_ASSIGNMENTS = gql`
  query {
    receivedAssignments(filter: { status: { eq: New } }) {
      totalCount
    }
  }
`;

const LOGOUT = gql`
  mutation {
    logout
  }
`;

export function AuthProvider({
  children,
  value,
}: {
  children: React.ReactNode;
  value?: AuthContextProps;
}): React.ReactElement {
  const [user, setUser] = useState<User | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [
    isFetchingAuthenticatedUser,
    setIsFetchingAuthenticatedUser,
  ] = useState(true);

  const [newIndicator, setNewIndicator] = useState<number>();

  const { setIsLoading } = useLoading();
  const { enqueueSnackbar } = useSnackbar();

  const token: string | null =
    localStorage.getItem(SUBSTITUTION_TOKEN) ||
    localStorage.getItem(AUTH_TOKEN);

  const { refetch } = useQuery(FETCH_ME, {
    onCompleted(data) {
      if (data) {
        setUser(data.me);
        setIsAuthenticated(true);
        setIsFetchingAuthenticatedUser(false);
        setIsLoading(false);
      }
    },
    onError() {
      localStorage.removeItem(AUTH_TOKEN);
      localStorage.removeItem(SUBSTITUTION_TOKEN);
      setIsFetchingAuthenticatedUser(false);
      setIsLoading(false);
    },
    notifyOnNetworkStatusChange: true,
    skip: !token,
  });

  const { refetch: refetchNewAssignments } = useQuery(NEW_ASSIGNMENTS, {
    onCompleted({ receivedAssignments }) {
      setNewIndicator(receivedAssignments.totalCount);
    },
    onError() {
      setIsLoading(false);
    },
    notifyOnNetworkStatusChange: true,
    skip: !token,
  });

  useEffect(() => {
    if (token) setIsLoading(true);
    else setIsFetchingAuthenticatedUser(false);
  }, []);

  const [logoutUser] = useMutation(LOGOUT);

  const logoutSubmit = async (): Promise<void> => {
    try {
      await logoutUser();
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    }
  };

  const [updateUser] = useMutation(ACCOUNT_UPDATE);

  const updateUserSubmit = (user: Partial<User>): void => {
    updateUser({ variables: { accountUpdateInput: user } });

    setUser((user_) => user_ && { ...user_, ...user });
  };

  if (isFetchingAuthenticatedUser) {
    return <Loading />;
  }

  function logout() {
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(SUBSTITUTION_TOKEN);
    localStorage.removeItem(
      LOCAL_STORAGE_VARIABLES.LastRegisteredIncomingDocument
    );
    localStorage.removeItem(
      LOCAL_STORAGE_VARIABLES.LastRegisteredOutgoingDocument
    );
    localStorage.removeItem(
      LOCAL_STORAGE_VARIABLES.LastRegisteredInternalDocument
    );
    client.cache.reset();
    logoutSubmit();
    setUser(null);
    setIsAuthenticated(false);

    Cookies.remove("ezd.file.access.token");

    enqueueSnackbar({
      message: "Wylogowano pomyślnie",
      variant: "success",
    });
  }

  function getInterfacePreferences() {
    return user?.interfacePreferences as JSON;
  }

  return (
    <AuthContext.Provider
      value={
        value || {
          user,
          setUser,
          logout,
          isAuthenticated,
          setIsAuthenticated,
          refetch,
          interfacePreferences: user?.interfacePreferences as JSON,
          getInterfacePreferences,
          updateUser: updateUserSubmit,
          newIndicator,
          refetchNewAssignments,
        }
      }
    >
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = (): AuthContextProps => useContext(AuthContext);
