import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { StyledLink } from "baseui/link";
import { ModalBody, ModalFooter, ModalHeader } from "baseui/modal";
import { Skeleton } from "baseui/skeleton";
import { Spinner } from "baseui/spinner";
import { LabelXSmall } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { MouseEvent, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { Redirect, useLocation } from "react-router-dom";
import { Row } from "react-table";
import { AlertOctagon, Eye, Plus, Refresh } from "tabler-icons-react";

import { InputValidationError } from "../../../api";
import BottomPanel from "../../../components/bottom-panel";
import Button from "../../../components/button";
import Cell from "../../../components/cell";
import ConfirmDialog from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import Filters from "../../../components/filters";
import FormControl from "../../../components/form-control";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import Modal from "../../../components/modal";
import PagingControls from "../../../components/paging-controls";
import { ControlledInactiveCurrenciesSelect } from "../../../components/select";
import SortingTableHeader, {
  SortDirection,
} from "../../../components/sorting-table-header";
import Table from "../../../components/table";
import { useLoading } from "../../../contexts/loading-context";
import { usePaging } from "../../../contexts/paging-context";
import { BasicFilter, FiltersState } from "../../../filters";
import { checkPermission } from "../../../utils/check-permission";
import { translateFiltersState } from "../../../utils/filters";
import { formValidation } from "../../../utils/formValidation";
import { PERMISSIONS } from "../../../utils/permissions";
import { setSortingParams } from "../../../utils/sorting";
import { Currency } from "../currencies";
import { CURRENCIES_FILTERS } from "../currencies.filters";
import {
  CURRENCIES_INDEX,
  CURRENCIES_SYNCHRONIZATION,
  CURRENCIES_UPDATE,
  GET_LAST_SYNCHRONIZATION_DATE,
} from "../currencies.gql";

enum FieldName {
  Name = "name",
  Code = "code",
  IsActive = "isActive",
  IsVisible = "isVisible",
}

export default function CurrenciesIndex(): React.ReactElement {
  const [newCurrencyId, setNewCurrencyId] = useState<number | null>(null);
  const [isConfirmSyncDialogOpen, setIsConfirmSyncDialogOpen] = useState(false);
  const { pageSize, currentPage, setTotalCount } = usePaging();
  const { search } = useLocation();

  const params = useMemo(() => new URLSearchParams(search), [search]);

  const [sortBy, setSortBy] = useState<FieldName | null>(
    (params.get("sortBy") as FieldName) || FieldName.Name
  );

  const [sortDirection, setSortDirection] = useState<SortDirection | null>(
    (params.get("sortDirection") as SortDirection) || SortDirection.ASC
  );

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<{
    searchingPhrase: string;
    currencies: { id: number; label?: string }[];
  }>();

  const [isAddCurrencyModalOpen, setIsAddCurrencyModalOpen] = useState(false);
  const [css, theme] = useStyletron();
  const {
    setIsLoading,
    isFetching,
    setIsFetching,
    isPartialFetching,
    setIsPartialFetching,
  } = useLoading();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [filters, setFilters] = useState<FiltersState>();

  const handleSorting = (column: FieldName) => {
    setSortingParams(history, search, column, sortDirection);
    setSortBy(column);
    setSortDirection(
      sortDirection === null
        ? SortDirection.DESC
        : sortDirection === SortDirection.ASC
        ? SortDirection.DESC
        : SortDirection.ASC
    );
  };

  const { data, error, loading, refetch } = useQuery(CURRENCIES_INDEX, {
    variables: {
      pageSize,
      offset: (currentPage - 1) * pageSize,
      sorting: {
        field: sortBy,
        direction: sortDirection,
      },
      filter: {
        isActive: {
          is: true,
        },
        ...(filters && (translateFiltersState(filters) as BasicFilter[])),
      },
    },
  });

  useEffect(() => {
    if (data?.currencies) setTimeout(() => refetch(), 200);
    setIsFetching(true);
  }, []);

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    setTimeout(() => refetch(), 200);

    setIsPartialFetching(true);
  }, [currentPage, pageSize]);

  useEffect(() => {
    setTimeout(() => refetch(), 200);
    setIsPartialFetching(true);
  }, [sortBy, sortDirection]);

  useEffect(() => {
    if (data?.currencies) setIsFetching(false);
    if (data?.currencies?.totalCount >= 0)
      setTotalCount(data?.currencies?.totalCount);
  }, [data]);

  const [addCurrency, { loading: mutationLoading }] = useMutation(
    CURRENCIES_UPDATE
  );

  const addCurencyToList = async ({
    currencies,
  }: {
    currencies: { id: number; label?: string }[];
  }): Promise<void> => {
    setIsLoading(true);

    try {
      const response = await addCurrency({
        variables: {
          currencyUpdateInput: {
            id: currencies && currencies[0]?.id,
            isActive: true,
            isVisible: true,
          },
        },
      });

      enqueueSnackbar({
        message: "Dodano pomyślnie",
        variant: "success",
      });

      setIsAddCurrencyModalOpen(false);
      reset();
      setNewCurrencyId(response?.data?.currencyUpdate?.id);
      refetch();
      setTimeout(() => {
        setNewCurrencyId(null);
        refetch();
      }, 5000);
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const {
    data: lastSynchronizationData,
    refetch: lastSynchronizationRefetch,
    loading: lastSynchronizationLoading,
  } = useQuery(GET_LAST_SYNCHRONIZATION_DATE, {
    notifyOnNetworkStatusChange: true,
  });

  const [syncCurrencies] = useMutation(CURRENCIES_SYNCHRONIZATION);

  const submitCurrenciesSynchronization = async () => {
    setIsLoading(true);

    try {
      await syncCurrencies();

      setIsConfirmSyncDialogOpen(false);

      enqueueSnackbar({
        message: "Zlecono wykonanie synchronizacji walut",
        variant: "success",
      });

      refetch();
      setTimeout(() => lastSynchronizationRefetch(), 500);
    } catch (error: unknown) {
      setIsConfirmSyncDialogOpen(false);
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (lastSynchronizationData) lastSynchronizationRefetch();
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.Name)}
            sortDirection={sortBy === FieldName.Name ? sortDirection : null}
          >
            Nazwa
          </SortingTableHeader>
        ),
        accessor: "name",
        Cell: ({ row }: { row: Row<Currency> }) => (
          <StyledLink
            onClick={(event: MouseEvent) => {
              event.preventDefault();
              history.push(`/currencies/${row.original.id}`);
            }}
            href={`/currencies/${row.original.id}`}
          >
            {row.original.name}
          </StyledLink>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.Code)}
            sortDirection={sortBy === FieldName.Code ? sortDirection : null}
          >
            Symbol
          </SortingTableHeader>
        ),
        accessor: "symbol",
        Cell: ({ row }: { row: Row<Currency> }) => (
          <FormattedValue>{row.original.code}</FormattedValue>
        ),
      },
      {
        Header: (
          <Block display="flex" justifyContent="flex-end">
            Kurs
          </Block>
        ),
        id: "exchangeRate",
        Cell: ({ row }: { row: Row<Currency> }) => (
          <Block
            display="flex"
            justifyContent={
              newCurrencyId === row.original.id ? "center" : "flex-end"
            }
          >
            {newCurrencyId === row.original.id ? (
              <Spinner size="14px" color="#999999" />
            ) : (
              <FormattedValue
                dataType="pre"
                {...(!!row?.original?.exchangeRates?.length && {
                  $style: {
                    fontSize: "12px",
                  },
                })}
              >
                {row?.original?.exchangeRates?.length
                  ? `${row?.original?.exchangeRates?.[0]?.value?.toFixed(
                      4
                    )} PLN`
                  : undefined}
              </FormattedValue>
            )}
          </Block>
        ),
      },
      {
        Header: (
          <Block display="flex" justifyContent="flex-end">
            Z dnia
          </Block>
        ),
        id: "effectiveOn",
        Cell: ({ row }: { row: Row<Currency> }) => (
          <Block
            display="flex"
            justifyContent={
              newCurrencyId === row.original.id ? "center" : "flex-end"
            }
          >
            {newCurrencyId === row.original.id ? (
              <Spinner size="14px" color="#999999" />
            ) : (
              <FormattedValue dataType="date">
                {row.original.exchangeRates?.[0]?.effectiveOn}
              </FormattedValue>
            )}
          </Block>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.IsVisible)}
            sortDirection={
              sortBy === FieldName.IsVisible ? sortDirection : null
            }
            $style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            Widoczność
          </SortingTableHeader>
        ),
        accessor: "isHidden",
        id: "isHidden",
        Cell: ({ row }: { row: Row<Currency> }) => (
          <Block display="flex" justifyContent="center" alignItems="center">
            <FormattedValue dataType="visibility-boolean">
              {!row.original.isVisible}
            </FormattedValue>
          </Block>
        ),
      },
      {
        id: "actions",
        Cell: ({ row }: { row: Row<Currency> }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              onClick={() => history.push(`/currencies/${row.original.id}`)}
              startEnhancer={<Eye size={14} />}
            />
          </div>
        ),
      },
    ],
    [data, filters, sortBy, sortDirection, newCurrencyId]
  );

  if (!checkPermission(PERMISSIONS.currency.read)) return <Redirect to="/" />;

  return (
    <article>
      <Header
        title="Waluty"
        recordsNum={data?.currencies?.totalCount}
        labels={["Lista"]}
        buttons={[
          {
            label: "Dodaj walutę",
            kind: KIND.primary,
            startEnhancer: <Plus size={18} />,
            onClick: () => setIsAddCurrencyModalOpen(true),
            permission: checkPermission(PERMISSIONS.currency.update),
          },
        ]}
        actions={[
          {
            label: "Zsynchronizuj kursy walut",
            icon: Refresh,
            color: theme.colors.primary,
            onClick: () => setIsConfirmSyncDialogOpen(true),
            permission: checkPermission(PERMISSIONS.currency.update),
          },
        ]}
      />
      <Filters
        filters={CURRENCIES_FILTERS}
        state={filters}
        setState={setFilters}
      />
      <Content filtersOffset>
        <Grid>
          <Cell span={12} $style={{ position: "relative" }}>
            <Table<Currency>
              columns={columns}
              data={data?.currencies?.nodes}
              isLoading={isFetching || isPartialFetching || loading}
              stickLastColumn
            />
          </Cell>
          <Cell span={12}>
            {isFetching ? (
              <Skeleton rows={0} height="30px" width="100%" animation />
            ) : (
              <Block
                display="flex"
                marginTop="10px"
                marginBottom="20px"
                justifyContent="flex-end"
                color="#999999"
                alignItems="center"
              >
                {lastSynchronizationLoading && (
                  <Spinner size="14px" color="#999999" />
                )}

                <LabelXSmall color="#999999" marginLeft="8px">
                  Ostatnia synchronizacja kursów:
                </LabelXSmall>
                <FormattedValue
                  dataType="datetime"
                  $style={{ fontSize: "12px", marginLeft: "5px" }}
                >
                  {lastSynchronizationData?.activityLogs?.nodes?.[0]?.createdAt}
                </FormattedValue>
              </Block>
            )}
          </Cell>
          <Cell span={12}>
            <BottomPanel>
              <PagingControls />
            </BottomPanel>
          </Cell>
          <ConfirmDialog
            isOpen={isConfirmSyncDialogOpen}
            label="Synchronizacja kursów walut"
            close={() => setIsConfirmSyncDialogOpen(false)}
            confirm={() => submitCurrenciesSynchronization()}
          />
          <Modal
            onClose={() => setIsAddCurrencyModalOpen(false)}
            isOpen={isAddCurrencyModalOpen}
          >
            <form onSubmit={handleSubmit(addCurencyToList)}>
              <ModalHeader>Dodanie waluty</ModalHeader>
              <ModalBody>
                <FormControl
                  error={
                    ((errors as any)?.currencies &&
                      (errors as any)?.currencies?.message) ||
                    (error &&
                      error.graphQLErrors[0]?.extensions?.code ===
                        "INPUT_VALIDATION_ERROR" &&
                      error.graphQLErrors[0]?.extensions?.validationErrors
                        ?.find(
                          (vE: InputValidationError) =>
                            vE?.property === "currencies"
                        )
                        ?.errors.map((message: string) => (
                          <div
                            key="error"
                            className={css({
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            })}
                          >
                            {message}
                            <span
                              className={css({
                                color: "#999",
                                marginLeft: "auto",
                                marginRight: "5px",
                              })}
                            >
                              Walidacja serwera
                            </span>
                            <AlertOctagon color="#999" size={12} />
                          </div>
                        ))[0])
                  }
                >
                  <ControlledInactiveCurrenciesSelect
                    name="currencies"
                    control={control}
                    placeholder="Wybierz z listy"
                    rules={{
                      required: formValidation.messages.required,
                    }}
                  />
                </FormControl>
              </ModalBody>
              <ModalFooter>
                <Button
                  type="button"
                  kind={KIND.secondary}
                  $style={{ marginRight: "10px" }}
                  onClick={() => {
                    setIsAddCurrencyModalOpen(false);
                    reset();
                  }}
                  isLoading={mutationLoading}
                >
                  Anuluj
                </Button>
                <Button
                  type="submit"
                  kind={KIND.primary}
                  isLoading={mutationLoading}
                >
                  Zapisz
                </Button>
              </ModalFooter>
            </form>
          </Modal>
        </Grid>
      </Content>
    </article>
  );
}
