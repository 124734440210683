import { KIND } from "baseui/button";
import { ModalBody, ModalFooter, ModalHeader } from "baseui/modal";
import React from "react";

import Button from "../components/button";
import Modal from "../components/modal";

type InvoiceWarningModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  accept: () => void;
};

export default function InvoiceWarningModal({
  isOpen,
  setIsOpen,
  accept,
}: InvoiceWarningModalProps): React.ReactElement {
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <ModalHeader>Ostrzeżenie</ModalHeader>
      <ModalBody>
        <strong>Dokument nie ma przypisanych poleceń płatności.</strong> Czy na
        pewno chcesz ją zaakceptować?
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          kind={KIND.secondary}
          $style={{ marginRight: "10px" }}
          onClick={() => {
            setIsOpen(false);
          }}
        >
          Anuluj
        </Button>
        <Button
          onClick={() => {
            setIsOpen(false);
            accept();
          }}
        >
          Zapisz
        </Button>
      </ModalFooter>
    </Modal>
  );
}
