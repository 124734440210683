import { ApolloError, useLazyQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { KIND, SIZE as ButtonSize } from "baseui/button";
import { SIZE as InputSize } from "baseui/input";
import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  SIZE as ModalSize,
} from "baseui/modal";
import { Skeleton } from "baseui/skeleton";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { AlertOctagon } from "tabler-icons-react";

import { InputValidationError } from "../api";
import Button from "../components/button";
import Modal from "../components/modal";
import { Address } from "../containers/Addresses/addresses";
import { GET_ADDRESS_FROM_GUS } from "../containers/Documents/documents.gql";
import { useLoading } from "../contexts/loading-context";
import Cell from "./cell";
import FormControl from "./form-control";
import FormattedValue from "./formatted-value";
import Grid from "./grid";
import { ControlledInput } from "./input";

type GusDownloadProps = {
  isOpen: boolean;
  close: () => void;
  confirm: () => void;
  currentAddress?: Address;
  currentNip?: string;
  setAddressFromGus?: (address: Address) => void;
};

type GusDownloadInputs = {
  nip: string;
  regon: string;
  krs: string;
};

const ADDRESS_FIELDS = [
  {
    id: "nip",
    label: "NIP",
    span: 4,
    type: "send",
  },
  {
    id: "regon",
    label: "REGON",
    span: 4,
    type: "send",
  },
  {
    id: "krs",
    label: "KRS",
    span: 4,
    type: "send",
  },
  {
    id: "name",
    label: "Nazwa adresata",
    span: 9,
    type: "get",
  },
  {
    id: "countryCode",
    label: "Kraj",
    span: 3,
    type: "get",
  },
  {
    id: "streetName",
    label: "Ulica",
    span: 3,
    type: "get",
  },
  {
    id: "buildingNumber",
    label: "Numer budynku",
    span: 2,
    type: "get",
  },
  {
    id: "apartmentNumber",
    label: "Numer lokalu",
    span: 2,
    type: "get",
  },
  {
    id: "postalCode",
    label: "Kod pocztowy",
    span: 2,
    type: "get",
  },
  {
    id: "city",
    label: "Miejscowość",
    span: 3,
    type: "get",
  },
  {
    id: "phoneNumber",
    label: "Numer telefonu",
    span: 3,
    type: "get",
  },
  {
    id: "mobileNumber",
    label: "Numer telefonu kom.",
    span: 3,
    type: "get",
  },
  {
    id: "addressEmail",
    label: "Adres e-mail",
    span: 3,
    type: "get",
  },
];

export default function GusDownloadModal({
  isOpen,
  setAddressFromGus,
  currentNip,
  close,
}: GusDownloadProps): React.ReactElement {
  const [css] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const { isFetching, setIsFetching } = useLoading();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm<GusDownloadInputs>();

  const watchFields = watch();

  const [fetchAddress, { data, loading, error }] = useLazyQuery(
    GET_ADDRESS_FROM_GUS
  );

  const onSubmit = async ({
    nip,
    regon,
    krs,
  }: GusDownloadInputs): Promise<void> => {
    setIsFetching(true);

    try {
      await fetchAddress({
        variables: {
          addressFromGUSQueryInput: {
            ...(!!nip && {
              nip: nip && nip.replaceAll("-", ""),
            }),
            ...(!!regon && {
              regon: regon?.replaceAll(" ", ""),
            }),
            ...(!!krs && {
              krs: krs?.toString(),
            }),
          },
        },
      });
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors
          .map(({ message }) => message)[0]
          .toLowerCase()
          .includes("no data found")
          ? "W bazie GUS nie znaleziono pasującego podmiotu"
          : (error as ApolloError).graphQLErrors?.map(
              ({ message }) => message
            )[0],
        variant: "error",
      });
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors
          .map(({ message }) => message)[0]
          .toLowerCase()
          .includes("no data found")
          ? "W bazie GUS nie znaleziono pasującego podmiotu"
          : (error as ApolloError).graphQLErrors?.map(
              ({ message }) => message
            )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    if (currentNip) setValue("nip", currentNip);
  }, [currentNip]);

  useEffect(() => {
    if (data?.addressFromGUS === null)
      enqueueSnackbar({
        message: "W bazie GUS nie znaleziono pasującego podmiotu",
        variant: "error",
      });
  }, [data]);

  const handleAddressUsing = () => {
    setAddressFromGus &&
      setAddressFromGus({
        ...(data?.addressFromGUS?.nip && { nip: data?.addressFromGUS?.nip }),
        ...(data?.addressFromGUS?.name && { name: data?.addressFromGUS?.name }),
        ...(data?.addressFromGUS?.streetName && {
          streetName: data?.addressFromGUS?.streetName,
        }),
        ...(data?.addressFromGUS?.buildingNumber && {
          buildingNumber: data?.addressFromGUS?.buildingNumber,
        }),
        ...(data?.addressFromGUS?.apartmentNumber && {
          apartmentNumber: data?.addressFromGUS?.apartmentNumber,
        }),
        ...(data?.addressFromGUS?.mobileNumber && {
          mobileNumber: data?.addressFromGUS?.mobileNumber,
        }),
        ...(data?.addressFromGUS?.postalCode && {
          postalCode: data?.addressFromGUS?.postalCode,
        }),
        ...(data?.addressFromGUS?.city && { city: data?.addressFromGUS?.city }),
        ...(data?.addressFromGUS?.countryCode && {
          countryCode: data?.addressFromGUS?.countryCode,
        }),
        ...(data?.addressFromGUS?.phoneNumber && {
          phoneNumber: data?.addressFromGUS?.phoneNumber,
        }),
        ...(data?.addressFromGUS?.email && {
          email: data?.addressFromGUS?.email,
        }),
      });
    close();
  };

  return (
    <Modal onClose={close} isOpen={isOpen} size={ModalSize.auto}>
      <ModalHeader>Pobierz dane adresowe z bazy GUS</ModalHeader>
      <ModalBody>
        <form>
          <Grid fill $style={{ width: "70vw" }}>
            {ADDRESS_FIELDS.filter(
              (field: { type: string }) => field.type === "send"
            ).map((field: { id: string; label: string; span: number }) => (
              <Cell key={field.id} span={field.span}>
                <FormControl
                  label={field.label}
                  disabled={loading}
                  error={
                    ((errors as any)[field.id] &&
                      (errors as any)[field.id].message) ||
                    (errors as any)[field.id] === "" ||
                    (error &&
                      error.graphQLErrors[0]?.extensions?.code ===
                        "INPUT_VALIDATION_ERROR" &&
                      error.graphQLErrors[0]?.extensions?.validationErrors
                        ?.find((vE: InputValidationError) =>
                          field.id.includes("address")
                            ? vE?.property === field.id.slice(7).toLowerCase()
                            : vE?.property === field.id
                        )
                        ?.errors.map((message: string) => (
                          <div
                            key="error"
                            className={css({
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            })}
                          >
                            {message}
                            <AlertOctagon color="#999" size={12} />
                          </div>
                        ))[0])
                  }
                >
                  <ControlledInput
                    control={control}
                    name={field.id}
                    masked={true}
                    rules={{
                      validate: (value) => {
                        return !!watchFields.krs ||
                          !!watchFields.regon ||
                          !!watchFields.nip ||
                          value
                          ? true
                          : "";
                      },
                    }}
                    size={InputSize.compact}
                    {...(field.id === "nip" && {
                      mask: "9999999999",
                      placeholder: "9999999999",
                      maskChar: "_",
                    })}
                    {...(field.id === "krs" && {
                      mask: "9999999999",
                    })}
                  />
                </FormControl>
              </Cell>
            ))}
            <Cell span={8}>
              <div>
                <p
                  className={css({
                    marginTop: "-5px",
                    fontSize: "12px",
                  })}
                >
                  Należy wypełnić jedno pole (NIP, REGON lub KRS)
                </p>
              </div>
            </Cell>
            <Cell span={4}>
              <div
                className={css({
                  display: "flex",
                  justifyContent: "flex-end",
                })}
              >
                <Button
                  disabled={loading}
                  isLoading={loading}
                  size={ButtonSize.compact}
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
                >
                  Pobierz dane
                </Button>
              </div>
            </Cell>
            {ADDRESS_FIELDS.filter(
              (field: { type: string }) => field.type === "get"
            ).map((field: { id: string; label: string; span: number }) => (
              <Cell
                key={field.id}
                span={field.span}
                $style={{ marginTop: "20px" }}
              >
                <FormControl label={field.label} disabled>
                  {isFetching ? (
                    <Skeleton height="20px" width="15ch" animation />
                  ) : data && !!data?.addressFromGUS ? (
                    <FormattedValue>
                      {field.id === "streetName"
                        ? data?.addressFromGUS?.streetName
                        : data?.addressFromGUS[field.id]}
                    </FormattedValue>
                  ) : (
                    <Skeleton height="20px" width="15ch" />
                  )}
                </FormControl>
              </Cell>
            ))}
          </Grid>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button
          kind={KIND.secondary}
          $style={{ marginRight: "10px" }}
          onClick={() => {
            close();
          }}
          disabled={loading}
        >
          Anuluj
        </Button>
        <Button
          disabled={!data || data?.addressFromGUS === null}
          isLoading={loading}
          onClick={handleAddressUsing}
          type="button"
        >
          Użyj danych
        </Button>
      </ModalFooter>
    </Modal>
  );
}
