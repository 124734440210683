import {
  CellContext,
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  HeaderContext,
  useReactTable,
} from "@tanstack/react-table";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { Skeleton } from "baseui/skeleton";
import {
  StyledRoot,
  StyledTable,
  StyledTableBody,
  StyledTableBodyCell,
  StyledTableBodyRow,
  StyledTableHead,
  StyledTableHeadCell,
  StyledTableHeadRow,
} from "baseui/table-semantic";
import React, {
  ChangeEvent,
  memo,
  ReactNode,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { StyleObject } from "styletron-react";
import { ListSearch } from "tabler-icons-react";

import { PAGE_SIZE } from "../constants";
import { useAuth } from "../contexts/auth-context";
import { usePaging } from "../contexts/paging-context";
import { getDefaultColumns } from "../utils/interface/default-columns";
import {
  getPreferencedColumnOrder,
  getPreferencedColumns,
} from "../utils/interface/preferences";
import { sortColumnsByOrder } from "../utils/table";
import Checkbox from "./checkbox";
import ConfirmDialog from "./confirm-dialog";
import TableBulkActions, { TableBulkAction } from "./table-bulk-actions";
import TableColumnListV8 from "./table-column-list-v8";
import TableColumnV8 from "./table-column-v8";
import TableFoot from "./table-foot";
import TableHeadV8 from "./table-head-v8";
import TableRowV8 from "./table-row-v8";

declare module "@tanstack/react-table" {
  interface ColumnMeta<TData extends unknown, TValue> {
    text?: string;
  }
}

type Props<T> = {
  expandedNumber?: number;
  data: T[];
  columns: ColumnDef<any>[];
  compact?: boolean;
  $style?: StyleObject;
  $footer?: () => React.ReactNode;
  $tableBodyRowStyle?: StyleObject;
  id?: string;
  isModal?: boolean;
  stickLastColumn?: boolean;
  editable?: boolean;
  isLoading?: boolean;
  bulkActions?: TableBulkAction[];
  emptyMessage?: ReactNode;
};

function highlightColumn(event: any) {
  const index = [...event.target.closest("thead > tr")?.children].indexOf(
    event.target.closest("th")
  );

  event.target
    .closest("[data-ui=table-root]")
    ?.querySelector("[data-ui=table] table")
    ?.querySelectorAll(`td:nth-child(${index + 1})`)
    ?.forEach((el: HTMLElement) => (el.style.backgroundColor = "#fbfbfb"));
}

function unhighlightColumn(event: any) {
  const index = [...event.target.closest("thead > tr")?.children].indexOf(
    event.target.closest("th")
  );

  event.target
    .closest("[data-ui=table-root]")
    ?.querySelector("[data-ui=table] table")
    ?.querySelectorAll(`td:nth-child(${index + 1})`)
    ?.forEach((el: HTMLElement) => (el.style.backgroundColor = ""));
}

export function useSkipper(): readonly [boolean, () => void] {
  const shouldSkipRef = React.useRef(true);
  const shouldSkip = shouldSkipRef.current;

  const skip = React.useCallback(() => {
    shouldSkipRef.current = false;
  }, []);

  React.useEffect(() => {
    shouldSkipRef.current = true;
  });

  return [shouldSkip, skip] as const;
}

export default function StatefulTableV8<T>({
  data,
  columns: inheritedColumns,
  $style,
  $footer,
  $tableBodyRowStyle,
  id,
  stickLastColumn,
  compact,
  editable,
  emptyMessage,
  bulkActions,
  isLoading,
}: Props<T>): React.ReactElement {
  const [css, theme] = useStyletron();

  const tableRef = useRef<HTMLDivElement>(null);
  const [rowSelection, setRowSelection] = useState({});

  const [activeBulkAction, setActiveBulkAction] = useState<TableBulkAction>();

  const [
    isBulkActionConfirmDialogOpen,
    setIsBulkActionConfirmDialogOpen,
  ] = useState(false);

  const columns = useMemo<ColumnDef<any>[]>(
    () =>
      bulkActions
        ? [
            {
              id: "checkbox",
              header: ({ table }: HeaderContext<any, unknown>) => (
                <Block
                  width="20px"
                  marginLeft="8px"
                  display="flex"
                  justifyContent="center"
                >
                  <Checkbox
                    isIndeterminate={table.getIsSomeRowsSelected()}
                    checked={table.getIsAllRowsSelected()}
                    onChange={table.getToggleAllRowsSelectedHandler()}
                  />
                </Block>
              ),
              cell: ({ row }: CellContext<any, unknown>) => (
                <Block display="flex" justifyContent="center">
                  <Checkbox
                    checked={row.getIsSelected()}
                    onChange={row.getToggleSelectedHandler()}
                  />
                </Block>
              ),
            },
            ...inheritedColumns,
          ]
        : inheritedColumns,
    [bulkActions, inheritedColumns]
  );

  const table = useReactTable({
    columns,
    data: data || [],
    state: {
      rowSelection,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    autoResetAll: false,
    autoResetExpanded: false,
  });
  const { interfacePreferences } = useAuth();
  const { pathname } = useLocation();
  const { pageSize } = usePaging();

  const headerGroups = table.getHeaderGroups();

  const preferencedColumns = useMemo(
    () => getPreferencedColumns(interfacePreferences, pathname),
    []
  );

  const allColumns = useMemo(
    () =>
      headerGroups
        ?.map((headerGroup) => headerGroup.headers.map((column) => column.id))
        .flat(),
    []
  );

  const orderableColumns = useMemo(
    () =>
      headerGroups
        ?.map((headerGroup) => {
          return headerGroup.headers
            .filter(
              (column) => column.id !== "actions" && column.id !== "checkbox"
            )
            .map((column) => column.id);
        })
        .flat(),
    []
  );

  const defaultColumns = getDefaultColumns(pathname) || allColumns;

  const [visibleColumns, setVisibleColumns] = useState<string[]>(
    preferencedColumns || defaultColumns
  );

  const [orderedColumns, setOrderedColumns] = useState<string[]>(
    getPreferencedColumnOrder(interfacePreferences, pathname) ||
      orderableColumns
  );

  return (
    <div
      className={css({
        position: "relative",
        scrollSnapType: "x mandatory",
      })}
      data-ui="table-root"
    >
      {!compact && (
        <div
          className={css({
            position: "absolute",
            right: "3px",
            top: "3px",
            borderRadius: "8px",
            zIndex: 8,
            paddingLeft: "6px",
            paddingRight: "6px",
            paddingBottom: "4px",
            paddingTop: "4px",
          })}
        >
          <TableColumnListV8
            headerGroups={headerGroups}
            defaultColumns={defaultColumns}
            orderableColumns={orderableColumns}
            visibleColumns={visibleColumns}
            setVisibleColumns={setVisibleColumns}
            orderedColumns={orderedColumns}
            setOrderedColumns={setOrderedColumns}
          />
        </div>
      )}

      <TableHeadV8 tableRef={tableRef} compact={compact}>
        <StyledTableHead>
          {headerGroups.map((headerGroup) => (
            <StyledTableHeadRow key={headerGroup.id}>
              {headerGroup.headers
                .filter(
                  (column) =>
                    visibleColumns?.includes(column.id) ||
                    column.id === "actions" ||
                    column.id === "checkbox"
                )
                .sort((a, b) => sortColumnsByOrder(a, b, orderedColumns))
                .map((header) => {
                  return (
                    <StyledTableHeadCell
                      key={header.id}
                      className={css({
                        verticalAlign: "middle",
                        zIndex: 12,
                        fontSize: "13.5px",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        whiteSpace: "nowrap",
                      })}
                      onMouseEnter={highlightColumn}
                      onMouseLeave={unhighlightColumn}
                    >
                      <TableColumnV8
                        column={header}
                        editable={editable}
                        tableRef={tableRef}
                      />
                    </StyledTableHeadCell>
                  );
                })}
            </StyledTableHeadRow>
          ))}
        </StyledTableHead>
      </TableHeadV8>

      <StyledRoot
        ref={tableRef}
        data-ui="table"
        onScroll={(event: ChangeEvent<HTMLDivElement>) => {
          const tableRoot = event.target?.closest("[data-ui=table-root]");

          if (tableRoot) {
            const stickedTableHead = tableRoot?.querySelector(
              "[data-ui=sticked-table-head]"
            );

            if (stickedTableHead) {
              const scrolling =
                tableRoot.getAttribute("data-scrolling") === "true"
                  ? true
                  : false;

              if (scrolling) {
                tableRoot.setAttribute("data-scrolling", "false");
                return;
              }

              if (
                stickedTableHead.scrollLeft !==
                (event.target as HTMLElement).scrollLeft
              ) {
                tableRoot.setAttribute("data-scrolling", "true");
                stickedTableHead.scrollLeft = (event.target as HTMLElement).scrollLeft;
              }
            }
          }
        }}
      >
        <StyledTable
          id={id}
          className={css({
            maxWidth: "100%",
            overflowX: "auto",
            scrollSnapType: "x mandatory",
            ...$style,
            position: "relative",
            borderCollapse: "collapse",
            minWidth: "100%",
            boxSizing: "border-box",
          })}
        >
          <StyledTableHead>
            {headerGroups.map((headerGroup) => (
              <StyledTableHeadRow key={headerGroup.id}>
                {headerGroup.headers
                  .filter(
                    (column) =>
                      visibleColumns?.includes(column.id) ||
                      column.id === "actions" ||
                      column.id === "checkbox"
                  )
                  .sort((a, b) => sortColumnsByOrder(a, b, orderedColumns))
                  .map((header) => {
                    return (
                      <StyledTableHeadCell
                        key={header.id}
                        className={css({
                          verticalAlign: "middle",
                          zIndex: 12,
                          fontSize: "13.5px",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                          whiteSpace: "nowrap",
                        })}
                        onMouseEnter={highlightColumn}
                        onMouseLeave={unhighlightColumn}
                      >
                        <TableColumnV8
                          column={header}
                          editable={editable}
                          tableRef={tableRef}
                        />
                      </StyledTableHeadCell>
                    );
                  })}
              </StyledTableHeadRow>
            ))}
          </StyledTableHead>

          <StyledTableBody>
            {isLoading ? (
              <tr>
                <td colSpan={visibleColumns.length}>
                  {[...Array(pageSize || PAGE_SIZE)].map((number, index) => (
                    <div
                      key={`skeleton-${index}`}
                      className={css({
                        borderBottomStyle: "solid",
                        borderBottomWidth: "0.5px",
                        borderBottomColor: theme.colors.inputBorder,
                      })}
                    >
                      <Skeleton height="40px" animation />
                    </div>
                  ))}
                </td>
              </tr>
            ) : table.getRowModel().rows.length > 0 ? (
              table.getRowModel().rows.map((row) => {
                return (
                  <TableRowV8
                    key={row.id}
                    row={row}
                    editable={editable}
                    $tableBodyRowStyle={$tableBodyRowStyle}
                    stickLastColumn={stickLastColumn}
                    visibleColumns={visibleColumns}
                    orderedColumns={orderedColumns}
                  />
                );
              })
            ) : (
              <StyledTableBodyRow>
                <StyledTableBodyCell colSpan={table.getAllColumns().length}>
                  <Block position="sticky" left="0">
                    {emptyMessage || (
                      <div
                        key="error"
                        className={css({
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "#999999",
                          height: "50px",
                        })}
                      >
                        <ListSearch
                          color="#999999"
                          size={18}
                          className={css({ marginRight: "5px" })}
                        />
                        Brak rekordów
                      </div>
                    )}
                  </Block>
                </StyledTableBodyCell>
              </StyledTableBodyRow>
            )}
          </StyledTableBody>

          {$footer && (
            <tfoot
              className={css({
                position: "relative",
                borderTopWidth: "1px",
                borderTopStyle: "solid",
                borderTopColor: "rgb(238, 238, 238)",
                backgroundColor: "rgb(252, 252, 252)",
              })}
            >
              {$footer()}
            </tfoot>
          )}

          {bulkActions && (
            <tfoot
              className={css({
                position: "relative",
                borderTopWidth: "1px",
                borderTopStyle: "solid",
                borderTopColor: "rgb(238, 238, 238)",
                backgroundColor: "rgb(252, 252, 252)",
              })}
            >
              <td
                colSpan={Math.max(5, Math.ceil(visibleColumns.length / 2))}
                className={css({
                  paddingTop: theme.sizing.scale200,
                  paddingBottom: theme.sizing.scale200,
                  paddingLeft: theme.sizing.scale500,
                  paddingRight: theme.sizing.scale500,
                  position: "sticky",
                  left: 0,
                })}
              >
                <TableBulkActions
                  bulkActions={bulkActions}
                  table={table}
                  setActiveBulkAction={setActiveBulkAction}
                  setIsBulkActionConfirmDialogOpen={
                    setIsBulkActionConfirmDialogOpen
                  }
                />
              </td>
            </tfoot>
          )}
        </StyledTable>
      </StyledRoot>

      {bulkActions && (
        <TableFoot
          tableRef={tableRef}
          displayConditionally={
            table.getIsSomeRowsSelected() || table.getIsAllRowsSelected()
          }
        >
          <div
            className={css({
              paddingTop: theme.sizing.scale200,
              paddingBottom: theme.sizing.scale200,
              paddingLeft: theme.sizing.scale500,
              paddingRight: theme.sizing.scale500,
            })}
          >
            <TableBulkActions
              bulkActions={bulkActions}
              table={table}
              setActiveBulkAction={setActiveBulkAction}
              setIsBulkActionConfirmDialogOpen={
                setIsBulkActionConfirmDialogOpen
              }
            />
          </div>
        </TableFoot>
      )}

      <ConfirmDialog
        isOpen={isBulkActionConfirmDialogOpen}
        label={activeBulkAction?.confirmLabel}
        close={() => setIsBulkActionConfirmDialogOpen(false)}
        confirm={() => {
          if (activeBulkAction) {
            setIsBulkActionConfirmDialogOpen(false);
            activeBulkAction.onExecute(
              table
                .getFilteredSelectedRowModel()
                .rows.filter((row) =>
                  activeBulkAction.filterRows
                    ? activeBulkAction.filterRows(row)
                    : true
                )
                .map(({ original }) => original.id),
              table
                .getFilteredSelectedRowModel()
                .rows.filter((row) =>
                  activeBulkAction.filterRows
                    ? activeBulkAction.filterRows(row)
                    : true
                ),
              () => table.toggleAllRowsSelected(false)
            );
          }
        }}
      />
    </div>
  );
}

export const MemoizedStatefulTableV8 = memo(StatefulTableV8);
