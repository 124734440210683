import { FIELDS, FieldsGroup } from "../../fields.d";

export type FormInputs = {
  eNadawcaUrzadNadania: string;
  isSent: boolean;
  createdAt: Date;
  updatedAt: Date;
  sentAt: Date;
  idEnvelope: string;
  envelopeStatus: string;
  envelopeFilename: string;
};

export const SENDING_LISTS_FIELDS: FieldsGroup[] = [
  {
    id: "basic",
    label: "Podstawowe informacje",
    fields: [
      {
        id: "createdAt",
        label: "Data utworzenia",
        type: FIELDS.Input,
        dataType: "datetime",
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "updatedAt",
        label: "Data aktualizacji",
        type: FIELDS.Input,
        dataType: "datetime",
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
    ],
  },
  {
    id: "status",
    label: "Status",
    fields: [
      {
        id: "isSent",
        label: "Wysłano",
        type: FIELDS.Input,
        dataType: "boolean",
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "sentAt",
        label: "Data wysłania",
        type: FIELDS.Input,
        dataType: "datetime",
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
    ],
  },
];

export const SENDING_LISTS_ITEMS_FIELDS: FieldsGroup[] = [
  {
    id: "basic",
    label: "Podstawowe informacje",
    fields: [
      {
        id: "document",
        label: "Dokument",
        type: FIELDS.Input,
        dataType: "model:documents",
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "shipmentRate",
        label: "Stawka",
        type: FIELDS.Input,
        dataType: "model:shipment-contracts",
        span: 3,
        show: { visible: true, accessor: ["shipmentRate", "name"] },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "documentParcel",
        label: "List nadany",
        type: FIELDS.Input,
        dataType: "model:document-parcels",
        span: 3,
        show: { visible: true, accessor: ["documentParcel", "id"] },
        create: { visible: false },
        edit: { visible: false },
      },
    ],
  },
  {
    id: "recipient",
    label: "Odbiorca",
    fields: [
      {
        id: "addressName",
        label: "Nazwa odbiorcy",
        type: FIELDS.Input,
        span: 9,
        show: { accessor: ["address", "name"], visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "addressNip",
        label: "NIP",
        type: FIELDS.Input,
        span: 3,
        show: { accessor: ["address", "nip"], visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "nip",
      },
      {
        id: "addressAddress",
        label: "Ulica i numer",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "addressPostalCode",
        label: "Kod pocztowy",
        type: FIELDS.Input,
        span: 3,
        show: { accessor: ["address", "postalCode"], visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "addressCity",
        label: "Miejscowość",
        type: FIELDS.Input,
        span: 3,
        show: { accessor: ["address", "city"], visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "addressCountryCode",
        label: "Kraj",
        type: FIELDS.Input,
        span: 3,
        show: { accessor: ["address", "countryCode"], visible: true },
        create: { visible: true },
        edit: { visible: true },
        dataType: "countryCode",
      },
      {
        id: "addressPhoneNumber",
        label: "Numer telefonu",
        type: FIELDS.Input,
        span: 3,
        show: { accessor: ["address", "phoneNumber"], visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "addressMobileNumber",
        label: "Numer telefonu kom.",
        type: FIELDS.Input,
        span: 3,
        show: { accessor: ["address", "mobileNumber"], visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "addressEmail",
        label: "Adres e-mail",
        type: FIELDS.Input,
        span: 6,
        show: { accessor: ["address", "email"], visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "email",
      },
    ],
  },
];
