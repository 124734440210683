import { BasicFilter, FILTERS } from "./../../filters.d";

export const SENDING_LISTS_FILTERS: BasicFilter[] = [
  {
    id: "isSent",
    label: "Wysłano",
    type: FILTERS.Boolean,
  },
  {
    id: "sentAt",
    label: "Data wysłania",
    type: FILTERS.Date,
  },
];
