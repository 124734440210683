import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import {
  Archive,
  ArrowBack,
  ArrowForwardUp,
  BookDownload,
  ChevronDown,
  ClipboardList,
  Cloud,
  FilePlus,
  FileUpload,
  FileZip,
  Lock,
  LockOpen,
  Paperclip,
  Pencil,
  Settings,
  Share,
  Switch3,
  Trash,
  UserPlus,
} from "tabler-icons-react";

import ActionsList from "../../../components/actions-list";
import ActivityLogsModal from "../../../components/activity-logs-modal";
import Button from "../../../components/button";
import CaseDispatchModal from "../../../components/case-dispatch.modal";
import Cell from "../../../components/cell";
import CloseCaseModal from "../../../components/close-case-modal";
import ConfirmDialog, {
  ConfirmDialogType,
} from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import DocumentsUploadModal from "../../../components/documents-upload-modal";
import FormControl from "../../../components/form-control";
import { ChangeCaseSupervisorDialog } from "../../../components/form-dialog";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import LegacyDocumentsTable from "../../../components/legacy-documents-table";
import { SortDirection } from "../../../components/sorting-table-header";
import Tooltip from "../../../components/tooltip";
import { HIDDEN_ADDRESS } from "../../../constants";
import { useAssignment } from "../../../contexts/assignment-context";
import { useAuth } from "../../../contexts/auth-context";
import { useLoading } from "../../../contexts/loading-context";
import { FILTERS } from "../../../filters.d";
import { checkPermission } from "../../../utils/check-permission";
import { generateUUID } from "../../../utils/misc";
import { PERMISSIONS } from "../../../utils/permissions";
import { Document } from "../../Documents/documents";
import {
  AssignmentLocationState,
  AssignmentType,
} from "../../Folders/folders.d";
import { CASES_FIELDS } from "../cases.form";
import {
  BENEFICIARY_ADDRESS_REVEAL,
  CASE_XLSX_EXPORT,
  CASES_DELETE,
  CASES_FOLDER_XLSX_EXPORT,
  CASES_SHOW,
  CASES_TRACK_OPEN,
  CASES_ZIP_EXPORT,
} from "../cases.gql";

enum FieldName {
  InternalNumber = "internalNumber",
  DocumentType = "documentType",
  DocumentKind = "documentKind",
  DocumentNumber = "documentNumber",
  CreatedAt = "createdAt",
}

export default function CasesShow(): React.ReactElement {
  const { search } = useLocation();

  const params = useMemo(() => new URLSearchParams(search), [search]);

  const [sortBy, setSortBy] = useState<FieldName | null>(
    (params.get("sortBy") as FieldName) || FieldName.CreatedAt
  );

  const [sortDirection, setSortDirection] = useState<SortDirection | null>(
    (params.get("sortDirection") as SortDirection) || SortDirection.DESC
  );

  const [sortedDocuments, setSortedDocuments] = useState<Document[]>();
  const [
    isAddressRevealConfirmDialogOpen,
    setIsAddressRevealConfirmDialogOpen,
  ] = useState(false);
  const [isAnyAddressRevealed, setIsAnyAddressRevealed] = useState(false);
  const [css, theme] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState(
    false
  );
  const [isConfirmCloseDialogOpen, setIsConfirmCloseDialogOpen] = useState(
    false
  );

  const [isCaseDispatchDialogOpen, setIsCaseDispatchDialogOpen] = useState<
    string | boolean
  >(false);
  const [
    isSupervisorChangeDialogOpen,
    setIsSupervisorChangeDialogOpen,
  ] = useState(false);
  const [
    isDocumentsUploadDialogOpen,
    setIsDocumentsUploadDialogOpen,
  ] = useState(false);
  const [isCaseLogsDialogOpen, setIsCaseLogsDialogOpen] = useState(false);

  const history = useHistory();
  const { state } = useLocation<AssignmentLocationState>();
  const { id } = useParams<{ id: string }>();
  const {
    setIsLoading,
    isFetching,
    setIsFetching,
    setIsPartialFetching,
    isPartialFetching,
  } = useLoading();
  const { user, refetch: userRefetch } = useAuth();
  const { assignment, setAssignment, setIsAssignmentActive } = useAssignment();

  const { refetch, data, error } = useQuery(CASES_SHOW, {
    variables: { id: parseInt(id) },
    ...((state?.assignmentId || assignment?.id) &&
      state?.assignmentId !== null && {
        context: {
          headers: {
            Assignment: state?.assignmentId || assignment?.id,
          },
        },
      }),
    notifyOnNetworkStatusChange: true,
  });

  const lastDocumentDate = data?.case?.documents?.length
    ? data?.case?.documents
        ?.map((document: Document) => document?.createdAt)
        ?.sort((a: Date, b: Date) => (a ? (b ? (a < b ? -1 : 1) : 0) : -1))?.[
        data?.case?.documents?.length - 1
      ]
    : undefined;

  const [deleteCase] = useMutation(CASES_DELETE, {
    ...((state?.assignmentId || assignment?.id) &&
      state?.assignmentId !== null && {
        context: {
          headers: {
            Assignment: state?.assignmentId || assignment?.id,
          },
        },
      }),
  });

  const submitDelete = async () => {
    setIsLoading(true);

    try {
      await deleteCase({
        variables: {
          id: parseInt(id),
        },
      });

      enqueueSnackbar({
        message: "Usunięto pomyślnie",
        variant: "success",
      });

      setIsConfirmDeleteDialogOpen(false);

      history.push("/cases");
    } catch (error: unknown) {
      setIsConfirmDeleteDialogOpen(false);

      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const [revealAddress, { loading: revealAddressLoading }] = useMutation(
    BENEFICIARY_ADDRESS_REVEAL,
    {
      ...((state?.assignmentId || assignment?.id) &&
        state?.assignmentId !== null && {
          context: {
            headers: {
              Assignment: state?.assignmentId || assignment?.id,
            },
          },
        }),
    }
  );

  const handleSorting = (column: FieldName) => {
    setSortBy(column);
    setSortDirection(
      sortDirection === null
        ? SortDirection.DESC
        : sortDirection === SortDirection.ASC
        ? SortDirection.DESC
        : SortDirection.ASC
    );
  };

  const beneficiaryAddressId = data?.case?.beneficiaryAddressId;

  const onPersonalDataRevealSubmit = async () => {
    setIsLoading(true);

    try {
      await revealAddress({
        variables: {
          caseBeneficiaryAddressRevealInput: {
            caseId: id ? parseInt(id) : null,
            beneficiaryAddressId,
          },
        },
      });

      enqueueSnackbar({
        message: "Odkryto pomyślnie",
        variant: "success",
      });

      refetch();
      setIsAddressRevealConfirmDialogOpen(false);
      setIsAnyAddressRevealed(true);
    } catch (error: unknown) {
      setIsAddressRevealConfirmDialogOpen(false);
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const [exportFolderXlsxFile] = useMutation(CASES_FOLDER_XLSX_EXPORT, {
    ...((state?.assignmentId || assignment?.id) &&
      state?.assignmentId !== null && {
        context: {
          headers: {
            Assignment: state?.assignmentId || assignment?.id,
          },
        },
      }),
  });
  const [exportCaseXlsxFile] = useMutation(CASE_XLSX_EXPORT, {
    ...((state?.assignmentId || assignment?.id) &&
      state?.assignmentId !== null && {
        context: {
          headers: {
            Assignment: state?.assignmentId || assignment?.id,
          },
        },
      }),
  });
  const [exportZipFile] = useMutation(CASES_ZIP_EXPORT, {
    ...((state?.assignmentId || assignment?.id) &&
      state?.assignmentId !== null && {
        context: {
          headers: {
            Assignment: state?.assignmentId || assignment?.id,
          },
        },
      }),
  });

  const [trackOpen] = useMutation(CASES_TRACK_OPEN, {
    ...((state?.assignmentId || assignment?.id) &&
      state?.assignmentId !== null && {
        context: {
          headers: {
            Assignment: state?.assignmentId || assignment?.id,
          },
        },
      }),
  });

  enum FileType {
    FolderXlsx = "FolderXlsx",
    CaseXlsx = "CaseXlsx",
    Zip = "Zip",
  }

  const onExportFileSubmit = async (fileType: FileType) => {
    setIsLoading(true);

    try {
      const response =
        fileType === FileType.FolderXlsx
          ? await exportFolderXlsxFile({
              variables: {
                caseFolderXlsxExportInput: {
                  id: id ? parseInt(id) : null,
                },
              },
            })
          : fileType === FileType.CaseXlsx
          ? await exportCaseXlsxFile({
              variables: {
                id: id ? parseInt(id) : null,
              },
            })
          : await exportZipFile({
              variables: {
                caseZipExportInput: {
                  id: id ? parseInt(id) : null,
                },
              },
            });

      enqueueSnackbar({
        message: "Rozpoczęto pobieranie pliku",
        variant: "info",
      });

      const apiUrl = process.env.REACT_APP_GRAPHQL_API_URL?.replace(
        "/graphql",
        ""
      );

      fileType === FileType.FolderXlsx
        ? window.open(
            `${apiUrl}/cases/download-export/${id}/${response?.data?.caseFolderXlsxExport}`,
            "_self"
          )
        : fileType === FileType.CaseXlsx
        ? window.open(
            `${apiUrl}${response?.data?.caseExportToXlsx?.downloadUrl}`,
            "_self"
          )
        : window.open(
            `${apiUrl}/cases/download-zip-export/${id}/${response?.data?.caseZipExport}`,
            "_self"
          );
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    setIsFetching(true);
    trackOpen({ variables: { id: parseInt(id) } }).then(() => userRefetch());

    if (data?.case) refetch();
  }, []);

  useEffect(() => {
    setSortedDocuments(
      (sortedDocuments) =>
        sortedDocuments &&
        [...sortedDocuments]?.sort((a: any, b: any) => {
          if (a[sortBy as string] > b[sortBy as string]) {
            return sortDirection === SortDirection.ASC ? 1 : -1;
          }
          if (a[sortBy as string] < b[sortBy as string]) {
            return sortDirection === SortDirection.ASC ? -1 : 1;
          }
          return 0;
        })
    );
  }, [sortBy, sortDirection]);

  useEffect(() => {
    if (data?.case?.currentAssignment) {
      setAssignment(data?.case?.currentAssignment);
      setIsAssignmentActive(true);
    }

    data?.case?.documents &&
      setSortedDocuments(
        [...data.case.documents].sort((a: any, b: any) => {
          if (
            a[FieldName.CreatedAt as string] > b[FieldName.CreatedAt as string]
          ) {
            return -1;
          }
          if (
            a[FieldName.CreatedAt as string] < b[FieldName.CreatedAt as string]
          ) {
            return 1;
          }
          return 0;
        })
      );
    data?.case && setIsFetching(false);
    data?.case && setIsPartialFetching(false);
    data?.case?.beneficiaryAddress?.isSensitive
      ? setIsAnyAddressRevealed(true)
      : setIsAnyAddressRevealed(false);
  }, [data]);

  if (!checkPermission(PERMISSIONS.case.read)) return <Redirect to="/" />;

  return (
    <article>
      <Header
        title={
          <div
            className={css({
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
              gap: "5px",
              width: "max-content",
              color: data?.case?.isConductedElectronically
                ? theme.colors.positive
                : theme.colors.negative,
            })}
          >
            <span
              className={css({
                whiteSpace: "nowrap",
              })}
            >
              {data?.case?.number}
            </span>

            <Tooltip
              content={
                data?.case?.isConductedElectronically
                  ? "Sprawa prowadzona elektronicznie"
                  : "Sprawa prowadzona tradycyjnie"
              }
            >
              <span>
                {data?.case?.isConductedElectronically ? (
                  <Cloud
                    size={18}
                    className={css({
                      verticalAlign: "middle",
                      display: "inline",
                      color: theme.colors.positive,
                    })}
                  />
                ) : (
                  <Paperclip
                    size={18}
                    className={css({
                      verticalAlign: "middle",
                      display: "inline",
                      color: theme.colors.negative,
                    })}
                  />
                )}
              </span>
            </Tooltip>
          </div>
        }
        labels={["Sprawa", data?.case?.name]}
        goBackOption
        hasActiveAssignmentBar
        buttons={[
          {
            label: "Dodaj dokument",
            kind: KIND.primary,
            startEnhancer: <FilePlus size={18} />,
            endEnhancer: <ChevronDown size={18} />,
            subactions: [
              {
                label: "Utwórz nowy dokument",
                icon: FilePlus,
                color: theme.colors.contentPrimary,
                permission: !data?.case?.closedAt && data?.case?.isEditable,
                onClick: () =>
                  history.push({
                    pathname: "/documents/create-internal",
                    state: {
                      caseId: id ? parseInt(id) : null,
                    },
                  }),
              },
              {
                label: "Wgraj dokument",
                icon: FileUpload,
                color: theme.colors.contentPrimary,
                onClick: () => setIsDocumentsUploadDialogOpen(true),
                permission: !data?.case?.closedAt && data?.case?.isEditable,
              },
            ],
          },
          {
            label: "Udostępnij",
            kind: KIND.primary,
            startEnhancer: <Share size={18} />,
            endEnhancer: <ChevronDown size={18} />,
            subactions: [
              {
                label: "Udostępnij",
                icon: UserPlus,
                color: theme.colors.primary,
                permission: data?.case?.isShareable,
                onClick: () =>
                  history.push(
                    `/cases/${id}/share`,
                    state || data?.case?.currentAssignment?.id
                      ? { assignmentId: data.case.currentAssignment?.id }
                      : undefined
                  ),
              },
              {
                label: "Zarządzaj dostępem",
                icon: Settings,
                color: theme.colors.contentPrimary,
                onClick: () =>
                  history.push(
                    `/folders/shared?filters=${window.btoa(
                      unescape(
                        encodeURIComponent(
                          JSON.stringify([
                            {
                              uuid: generateUUID(),
                              filter: {
                                id: "primaryAssignableId",
                                label: "Obiekt",
                                type: FILTERS.PrimaryAssignable,
                              },
                              state: {
                                type: "Case",
                                value: data?.case?.id,
                                number: data?.case?.number,
                              },
                            },
                            {
                              uuid: generateUUID(),
                              filter: {
                                id: "status",
                                label: "Status",
                                type: FILTERS.AssignmentStatus,
                              },
                              state: {
                                value: [
                                  { id: "New", label: "Nowe" },
                                  { id: "InProgress", label: "W realizacji" },
                                ],
                              },
                            },
                          ])
                        )
                      )
                    )}`
                  ),
              },
            ],
          },
          {
            label: "Przekaż",
            startEnhancer: <ArrowForwardUp size={18} />,
            kind: KIND.primary,
            endEnhancer: <ChevronDown size={18} />,
            subactions: [
              {
                label: "Przekaż sprawę",
                icon: ArrowForwardUp,
                color: theme.colors.contentPrimary,
                onClick: () => setIsCaseDispatchDialogOpen(true),
              },
              {
                label: "Zwróć sprawę",
                icon: ArrowBack,
                color: theme.colors.negative,
                onClick: () => setIsCaseDispatchDialogOpen("withDispatcher"),
                permission:
                  !!data?.case?.currentAssignment?.assigner?.id &&
                  data?.case?.currentAssignment?.type !==
                    AssignmentType.Initial,
              },
            ],
            permission: data?.case?.isDispatchable,
          },
        ]}
        actions={[
          {
            label: "Edytuj sprawę",
            icon: Pencil,
            permission:
              checkPermission(PERMISSIONS.case.update) &&
              !data?.case?.closedAt &&
              data?.case?.isEditable,
            onClick: () =>
              history.push(
                `/cases/${id}/edit`,
                state || data?.case?.currentAssignment?.id
                  ? { assignmentId: data.case.currentAssignment?.id }
                  : undefined
              ),
          },
          {
            label: "Zmień prowadzącego sprawę",
            icon: Switch3,
            color: theme.colors.primary,
            onClick: () => setIsSupervisorChangeDialogOpen(true),
            permission:
              checkPermission(PERMISSIONS.case.changeSupervisor) &&
              !data?.case?.closedAt &&
              data?.case?.isEditable,
          },
          // {
          //   label: "Pobierz plik z opisem teczki",
          //   icon: TableExport,
          //   color: theme.colors.primary,
          //   onClick: () => onExportFileSubmit(FileType.FolderXlsx),
          // },
          // {
          //   label: "Pobierz plik XLSX z danymi sprawy",
          //   icon: TableExport,
          //   color: theme.colors.primary,
          //   onClick: () => onExportFileSubmit(FileType.CaseXlsx),
          // },
          {
            label: "Usuń sprawę",
            icon: Trash,
            color: theme.colors.negative,
            onClick: () => setIsConfirmDeleteDialogOpen(true),
            permission:
              data?.case?.isDeletable &&
              checkPermission(PERMISSIONS.case.delete) &&
              !data?.case?.closedAt,
          },
          {
            label: "Zamknij sprawę",
            icon: Archive,
            color: theme.colors.negative,
            onClick: () => setIsConfirmCloseDialogOpen(true),
            permission:
              checkPermission(PERMISSIONS.case.close) &&
              !data?.case?.closedAt &&
              data?.case?.supervisorId === user?.id,
          },
          {
            label: "Pokaż logi sprawy",
            icon: ClipboardList,
            color: theme.colors.black,
            onClick: () => setIsCaseLogsDialogOpen(true),
            permission: checkPermission(PERMISSIONS.document.read),
          },
          {
            label: "Pobierz plik ZIP",
            icon: FileZip,
            color: theme.colors.primary,
            onClick: () => onExportFileSubmit(FileType.Zip),
            permission:
              checkPermission(PERMISSIONS.case.read) && !!data?.case?.closedAt,
          },
        ]}
      />
      <Content>
        <Grid>
          {CASES_FIELDS.filter(
            (g) => g.fields.filter((f) => f.show.visible).length > 0
          )
            .filter(
              (group) =>
                group.id !== "beneficiaryAddress" ||
                (group.id === "beneficiaryAddress" &&
                  !!data?.case?.beneficiaryAddress)
            )
            .map((group) => [
              group.label && (
                <Cell key={group.id + `-group`} span={12}>
                  <>
                    <Block
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      marginBottom="scale200"
                      marginTop="scale600"
                    >
                      <Block
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        {data?.case?.beneficiaryAddress?.__typename ===
                          HIDDEN_ADDRESS &&
                        group.id === "beneficiaryAddress" ? (
                          <Lock
                            size={18}
                            color={theme.colors.negative}
                            className={css({ marginRight: "10px" })}
                          />
                        ) : (
                          group.id === "beneficiaryAddress" && (
                            <LockOpen
                              size={18}
                              color={theme.colors.positive}
                              className={css({ marginRight: "10px" })}
                            />
                          )
                        )}
                        <LabelMedium>{group.label}</LabelMedium>
                        {data?.case?.beneficiaryAddress?.__typename ===
                          HIDDEN_ADDRESS &&
                          group.id === "beneficiaryAddress" && (
                            <Button
                              size={SIZE.mini}
                              kind={KIND.secondary}
                              $style={{ marginLeft: "10px" }}
                              onClick={() =>
                                setIsAddressRevealConfirmDialogOpen(true)
                              }
                              isLoading={revealAddressLoading}
                            >
                              Pokaż dane
                            </Button>
                          )}
                      </Block>
                      {data?.case?.beneficiaryAddress?.__typename !==
                        HIDDEN_ADDRESS &&
                        isAnyAddressRevealed &&
                        group.id === "beneficiaryAddress" && (
                          <FormattedValue dataType="pre">
                            Dostęp aktywny do końca trwania sesji
                          </FormattedValue>
                        )}
                    </Block>
                    <hr
                      className={css({
                        borderWidth: "0px",
                        height: "1px",
                        backgroundColor: "#eee",
                      })}
                    />
                  </>
                </Cell>
              ),
              group.fields
                .filter((f) => f.show.visible)
                .map((item, index) => (
                  <Cell span={item.span || 6} key={group.id + `-field` + index}>
                    <FormControl label={item.label} disabled={true}>
                      {isFetching ? (
                        <Skeleton
                          rows={0}
                          height="20px"
                          width="100%"
                          animation
                        />
                      ) : data?.case?.beneficiaryAddress?.__typename ===
                          HIDDEN_ADDRESS &&
                        item.id.includes("address") &&
                        !item.id.includes("Name") ? (
                        <Skeleton
                          rows={0}
                          height="20px"
                          width="15ch"
                          overrides={{
                            Root: {
                              style: {
                                backgroundColor: "#faebeb",
                              },
                            },
                          }}
                        />
                      ) : item.id === "user" ? (
                        <FormattedValue
                          dataType={item.dataType}
                          data={data?.case?.[item.id]?.id}
                        >
                          {`${data?.case?.user?.firstName} ${data?.case?.user?.lastName}`}
                        </FormattedValue>
                      ) : item.id === "supervisor" ? (
                        <FormattedValue
                          dataType={item.dataType}
                          data={data?.case?.[item.id]?.id}
                        >
                          {`${data?.case?.supervisor?.firstName} ${data?.case?.supervisor?.lastName}`}
                        </FormattedValue>
                      ) : item.id === "closedBy" ? (
                        <FormattedValue
                          dataType={item.dataType}
                          data={data?.case?.closedBy?.id}
                        >
                          {data?.case?.closedAt
                            ? `${data?.case?.closedBy?.firstName} ${data?.case?.closedBy?.lastName}`
                            : ""}
                        </FormattedValue>
                      ) : item.id === "organizationalUnitGroup" ? (
                        <FormattedValue>
                          {data?.case?.organizationalUnitGroupName}
                        </FormattedValue>
                      ) : item.id === "addressAddress" ? (
                        <FormattedValue>
                          {data?.case?.beneficiaryAddress?.streetName
                            ? `${data?.case?.beneficiaryAddress?.streetName} ${
                                data?.case?.beneficiaryAddress?.buildingNumber
                              }${
                                data?.case?.beneficiaryAddress?.apartmentNumber
                                  ? "/" +
                                    data?.case?.beneficiaryAddress
                                      ?.apartmentNumber
                                  : ""
                              }`
                            : data?.document?.beneficiaryAddress?.address}
                        </FormattedValue>
                      ) : item.id === "children" ? (
                        <Block paddingTop="scale200">
                          <LegacyDocumentsTable
                            documents={sortedDocuments || data?.case?.documents}
                            handleSorting={handleSorting}
                            sortBy={sortBy}
                            sortDirection={sortDirection}
                            loading={isFetching || isPartialFetching}
                            refetch={refetch}
                          />
                        </Block>
                      ) : (
                        <FormattedValue
                          dataType={item.dataType}
                          data={data?.case[item.id]}
                        >
                          {item.show.accessor
                            ? data?.case[item.show.accessor[0]]?.[
                                item.show.accessor[1]
                              ]
                            : data?.case[item.id]}
                        </FormattedValue>
                      )}
                    </FormControl>
                  </Cell>
                )),
            ])}

          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Akcje
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={12}>
            <ActionsList
              actions={[
                {
                  label: "Pobierz plik z opisem teczki",
                  icon: BookDownload,
                  color: theme.colors.contentPrimary,
                  onClick: () => onExportFileSubmit(FileType.FolderXlsx),
                },
              ]}
            />
          </Cell>

          <ConfirmDialog
            isOpen={isConfirmDeleteDialogOpen}
            label={`Usunięcie sprawy ${data?.case?.name}`}
            close={() => setIsConfirmDeleteDialogOpen(false)}
            confirm={submitDelete}
          />

          <CloseCaseModal
            caseId={id}
            isOpen={isConfirmCloseDialogOpen}
            reloadPage={refetch}
            setIsOpen={setIsConfirmCloseDialogOpen}
            label={`[${data?.case?.number}] ${data?.case?.name}`}
            defaultClosingDate={lastDocumentDate}
          />

          <ChangeCaseSupervisorDialog
            caseId={parseInt(id as string)}
            isOpen={isSupervisorChangeDialogOpen}
            close={() => setIsSupervisorChangeDialogOpen(false)}
            onCompleted={() => refetch()}
          />

          <ConfirmDialog
            isOpen={isAddressRevealConfirmDialogOpen}
            type={ConfirmDialogType.AddressReveal}
            close={() => setIsAddressRevealConfirmDialogOpen(false)}
            confirm={() => onPersonalDataRevealSubmit()}
          />

          <DocumentsUploadModal
            isOpen={isDocumentsUploadDialogOpen}
            label={`[${data?.case?.number}] ${data?.case?.name}`}
            caseId={data?.case?.id}
            refetch={() => refetch()}
            close={() => setIsDocumentsUploadDialogOpen(false)}
          />

          <CaseDispatchModal
            caseId={id}
            isOpen={
              !!isCaseDispatchDialogOpen ||
              isCaseDispatchDialogOpen === "withDispatcher"
            }
            close={() => setIsCaseDispatchDialogOpen(false)}
            onCompleted={() => refetch()}
            {...(isCaseDispatchDialogOpen === "withDispatcher" && {
              dispatcher: data?.case?.currentAssignment?.assigner,
            })}
          />

          <ActivityLogsModal
            isOpen={isCaseLogsDialogOpen}
            close={() => setIsCaseLogsDialogOpen(false)}
            activityLogs={data?.case?.activityLogs}
          />
        </Grid>
      </Content>
    </article>
  );
}
