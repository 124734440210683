import { BasicFilter, FILTERS } from "./../../filters.d";

export const GROUPS_FILTERS: BasicFilter[] = [
  {
    id: "name",
    label: "Nazwa",
    type: FILTERS.Text,
  },
  {
    id: "loginAd",
    label: "Login AD pracownika",
    type: FILTERS.LoginAd,
  },
  {
    id: "createdAt",
    label: "Data dodania",
    type: FILTERS.Date,
  },
  {
    id: "createdById",
    label: "Dodano przez",
    type: FILTERS.User,
  },
  {
    id: "updatedAt",
    label: "Data aktualizacji",
    type: FILTERS.Date,
  },
  {
    id: "updatedById",
    label: "Zaktualizowano przez",
    type: FILTERS.User,
  },
];
