import { gql } from "@apollo/client";

export const POSITIONS_INDEX = gql`
  query(
    $pageSize: Int
    $offset: Int
    $filter: PositionFilter
    $sorting: [PositionSort!]
  ) {
    positions(
      paging: { limit: $pageSize, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        name
        description
        users {
          id
        }
        role {
          id
          name
        }
        roleId
      }
    }
  }
`;

export const POSITIONS_CREATE = gql`
  mutation CreatePosition($name: String!, $description: String, $roleId: Int) {
    positionCreate(
      positionCreateInput: {
        name: $name
        description: $description
        roleId: $roleId
      }
    ) {
      id
    }
  }
`;

export const POSITIONS_EDIT = gql`
  mutation EditPosition(
    $id: Int!
    $name: String!
    $description: String
    $roleId: Int
  ) {
    positionUpdate(
      positionUpdateInput: {
        id: $id
        name: $name
        description: $description
        roleId: $roleId
      }
    ) {
      id
    }
  }
`;

export const POSITIONS_SHOW = gql`
  query($id: Int!) {
    position(id: $id) {
      id
      name
      description
      role {
        id
        name
      }
      roleId
      users {
        id
        firstName
        lastName
        email
      }
    }
  }
`;

export const POSITIONS_DELETE = gql`
  mutation DeletePosition($id: Int!) {
    positionDelete(positionDeleteInput: { id: $id }) {
      id
    }
  }
`;
