import { styled, useStyletron } from "baseui";
import { Theme } from "baseui/theme";
import React, { useEffect, useMemo, useState } from "react";
import { useRef } from "react";
import { useLocation } from "react-router-dom";

import { useLoading } from "../contexts/loading-context";
import Cell from "./cell";
import Grid from "./grid";

const StickedBottomPanel = styled(
  "summary",
  ({
    $theme,
    $sticked,
    $left,
  }: {
    $theme?: Theme;
    $sticked?: boolean;
    $left?: number;
  }) => ({
    backgroundColor: "white",
    paddingTop: $sticked ? $theme?.sizing.scale300 : $theme?.sizing.scale300,
    paddingBottom: $sticked ? $theme?.sizing.scale300 : $theme?.sizing.scale300,
    position: "fixed",
    left: $left ? `${$left}px` : "225px",
    right: "0px",
    bottom: "0px",
    zIndex: 10,
    paddingRight: "10px",
    borderTopWidth: $sticked ? "2px" : "0px",
    borderTopStyle: "solid",
    borderTopColor: $theme?.colors.backgroundTertiary,
    transform: "translateY(200px)",
    ...($sticked && {
      animationIterationCount: "1",
      animationDelay: "0ms",
      animationDuration: "200ms",
      animationFillMode: "both",
      animationName: {
        from: {
          transform: "translateY(200px)",
        },
        to: {
          transform: "translateY(0px)",
        },
      } as any,
    }),
  })
);

type BottomPanelProps = {
  children: React.ReactChild | React.ReactChild[];
};

export default function BottomPanel({
  children,
}: BottomPanelProps): React.ReactElement {
  const [css, theme] = useStyletron();
  const [sticked, setSticked] = useState(false);
  const { isFetching, isPartialFetching } = useLoading();
  const { pathname, search } = useLocation();
  const rootRef = useRef<HTMLDivElement>(null);

  const params = useMemo(() => new URLSearchParams(search), [search]);

  function checkPosition() {
    if (
      (rootRef?.current?.getBoundingClientRect().top || 0) -
        window.innerHeight +
        20 >
      0
    )
      setSticked(true);
    else setSticked(false);
  }

  useEffect(checkPosition, [
    isFetching,
    isPartialFetching,
    pathname,
    search,
    params,
  ]);

  useEffect(() => {
    document
      ?.querySelector("main > section")
      ?.addEventListener("scroll", checkPosition);

    window?.addEventListener("resize", checkPosition);

    const observer = new ResizeObserver(function () {
      const width = document?.querySelector("aside")?.offsetWidth;

      if (width) {
        setSidebarWidth(width);
      }
    });

    const sidebarNode = document?.querySelector("aside");

    if (sidebarNode) {
      observer.observe(sidebarNode);
    }

    return () => {
      window?.removeEventListener("resize", checkPosition);
      document
        ?.querySelector("main > section")
        ?.removeEventListener("scroll", checkPosition);
      observer.disconnect();
    };
  }, []);

  const customSidebarWidth =
    localStorage.getItem("sidebarWidth") &&
    parseInt(localStorage.getItem("sidebarWidth") as string);

  const [sidebarWidth, setSidebarWidth] = useState<number>(
    customSidebarWidth || 225
  );

  return (
    <>
      <div ref={rootRef} className={css({ marginTop: theme.sizing.scale600 })}>
        {children}
      </div>

      <StickedBottomPanel $sticked={sticked} $left={sidebarWidth}>
        <Grid>
          <Cell span={12}>{children}</Cell>
        </Grid>
      </StickedBottomPanel>
    </>
  );
}
