import { BasicFilter, FILTERS } from "./../../filters.d";

export const USERS_FILTERS: BasicFilter[] = [
  {
    id: "firstName",
    label: "Imię i nazwisko",
    type: FILTERS.FullName,
  },
  {
    id: "email",
    label: "Adres e-mail",
    type: FILTERS.Text,
  },
  {
    id: "loginAD",
    label: "Login AD",
    type: FILTERS.Text,
  },
  {
    id: "roles",
    label: "Rola",
    type: FILTERS.Role,
  },
];
