import { FIELDS, FlowFieldsGroup } from "../../fields.d";

export type FormInputs = {
  id: number;
  name: string;
  documentTypes?: { id: string; label?: string }[];
  documentType?: string;
  documentKinds?: { id: string; label?: string }[];
  documentKind?: string;
  description: string;
  isActive: boolean;
  createFlowdAt: string;
  updatedAt: string;
};

export const DOCUMENT_FLOW_FIELDS: FlowFieldsGroup[] = [
  {
    id: "basic",
    label: "Podstawowe informacje",
    fields: [
      {
        id: "name",
        label: "Nazwa",
        type: FIELDS.Input,
        span: 4,
        showFlow: { visible: true },
        createFlow: { visible: true, required: true },
        editFlow: { visible: true, required: true },
      },
      {
        id: "documentKinds",
        label: "Rodzaj dokumentu",
        type: FIELDS.DocumentKindsSelect,
        placeholder: "Wybierz",
        span: 4,
        showFlow: { visible: false },
        createFlow: { visible: true, required: true },
        editFlow: { visible: true, required: true },
      },
      {
        id: "documentKind",
        label: "Rodzaj dokumentu",
        type: FIELDS.Input,
        dataType: "document-kind",
        span: 4,
        showFlow: { visible: true },
        createFlow: { visible: false },
        editFlow: { visible: false },
      },
      {
        id: "documentTypes",
        label: "Typ dokumentu",
        type: FIELDS.DocumentTypesSelect,
        placeholder: "Wybierz",
        span: 4,
        showFlow: { visible: false },
        createFlow: { visible: true, required: true },
        editFlow: { visible: true, required: true },
      },
      {
        id: "documentType",
        label: "Typ dokumentu",
        type: FIELDS.Input,
        span: 4,
        showFlow: { visible: true },
        createFlow: { visible: false },
        editFlow: { visible: false },
      },
      {
        id: "createdAt",
        label: "Data utworzenia",
        type: FIELDS.Input,
        dataType: "date",
        span: 4,
        showFlow: { visible: true },
        createFlow: { visible: false },
        editFlow: { visible: false },
      },
      {
        id: "updatedAt",
        label: "Data aktualizacji",
        type: FIELDS.Input,
        dataType: "date",
        span: 4,
        showFlow: { visible: true },
        createFlow: { visible: false },
        editFlow: { visible: false },
      },
      {
        id: "description",
        label: "Opis",
        type: FIELDS.Input,
        span: 8,
        showFlow: { visible: true },
        createFlow: { visible: true },
        editFlow: { visible: true },
      },
      {
        id: "isActive",
        label: "Ustaw jako aktywny",
        type: FIELDS.Checkbox,
        span: 4,
        showFlow: { visible: false },
        createFlow: { visible: true },
        editFlow: { visible: true },
      },
    ],
  },
];
