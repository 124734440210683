import { useStyletron } from "baseui";
import { HeadingSmall } from "baseui/typography";
import React, { useState } from "react";
import { ChevronDown, ChevronUp } from "tabler-icons-react";

import { AssignmentType } from "../containers/Folders/folders.d";
import { useAssignment } from "../contexts/assignment-context";
import Button from "./button";
import Cell from "./cell";
import FormControl from "./form-control";
import FormattedValue from "./formatted-value";
import Grid from "./grid";

type AssignmentBarProps = {
  $sticked: boolean;
  title: React.ReactNode;
};

export default function AssignmentBar({
  $sticked,
  title,
}: AssignmentBarProps): React.ReactElement | null {
  const [css, theme] = useStyletron();

  const [isExpanded, setIsExpanded] = useState(false);

  const { assignment } = useAssignment();

  if (!assignment) return null;

  return (
    <div
      className={css({
        height: "41px",
        aanimationIterationCount: "1",
        animationDelay: "0ms",
        animationDuration: "200ms",
        animationTimingFunction: "ease",
        animationFillMode: "both",
        animationName: {
          from: {
            marginTop: "-80px",
            marginBottom: `${
              $sticked ? theme?.sizing.scale600 : theme?.sizing.scale1000
            }`,
          },
          to: {
            marginTop: `-${
              $sticked ? theme?.sizing.scale600 : theme?.sizing.scale1000
            }`,
            marginBottom: `${
              $sticked ? theme?.sizing.scale600 : theme?.sizing.scale600
            }`,
          },
        } as any,
      })}
    >
      <div
        className={css({
          backgroundColor: isExpanded
            ? "rgb(255, 235, 194)"
            : "rgb(255, 192, 67)",
          color: "rgb(103, 77, 27)",
          paddingTop: isExpanded ? "19px" : "12px",
          paddingBottom: isExpanded ? "4px" : "12px",
          cursor: isExpanded ? "auto" : "pointer",
          zIndex: 2,
          transition: "all 200ms ease",
          position: "relative",
          borderBottomWidth: isExpanded ? "2px" : "0px",
          borderBottomStyle: "solid",
          borderBottomColor: theme?.colors.backgroundTertiary,
        })}
        {...(!isExpanded && {
          onClick: () => setIsExpanded(true),
        })}
      >
        <Grid>
          <Cell span={12}>
            <div
              className={css({
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                fontSize: "12px",
              })}
            >
              <div
                className={css({
                  height: "15px",
                  flexShrink: 0,
                  display: "flex",
                })}
              >
                {assignment.type === AssignmentType.Share
                  ? "Udostępnienie od"
                  : assignment.type === AssignmentType.Initial
                  ? "Utworzenie"
                  : assignment.type === AssignmentType.Flow
                  ? "W obiegu zdefiniowanym od"
                  : `Przekazanie${
                      assignment.isAssessmentRequested ? " do akceptacji" : ""
                    } od`}

                {assignment.type !== AssignmentType.Initial && (
                  <div
                    {...(assignment?.substituter && {
                      className: css({ display: "flex" }),
                    })}
                  >
                    {assignment?.substituter && (
                      <div>
                        <FormattedValue
                          dataType="model:users"
                          $style={{ marginLeft: "8px" }}
                          data={assignment.substituter.id}
                        >
                          {`${assignment.substituter.firstName} ${assignment.substituter.lastName}`}
                        </FormattedValue>
                        <span className={css({ marginLeft: "8px" })}>
                          w zastępstwie za
                        </span>
                      </div>
                    )}
                    <FormattedValue
                      dataType="model:users"
                      $style={{ marginLeft: "8px" }}
                      data={assignment.assigner.id}
                    >
                      {`${assignment.assigner.firstName} ${assignment.assigner.lastName}`}
                    </FormattedValue>
                  </div>
                )}
              </div>

              <div
                className={css({
                  opacity: isExpanded ? 0 : 1,
                  pointerEvents: isExpanded ? "none" : "auto",
                  transition: "all 200ms ease",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  minWidth: "0",
                })}
              >
                {assignment.assignerComment &&
                  `: ${assignment.assignerComment}`}
              </div>

              <div
                className={css({
                  opacity: isExpanded ? 0 : 1,
                  pointerEvents: isExpanded ? "none" : "auto",
                  transition: "all 200ms ease",
                  flexShrink: 0,
                })}
              >
                <FormattedValue
                  dataType="datetime"
                  $style={{
                    marginLeft: "12px",
                    marginRight: "12px",
                    opacity: 0.8,
                  }}
                >
                  {assignment.createdAt}
                </FormattedValue>
              </div>

              {/* <Button
                kind="minimal"
                $style={{
                  fontSize: "12px",
                  marginLeft: "auto",
                  display: "flex",
                  alignItems: "center",
                  minHeight: "unset",
                  height: "12px",
                  paddingRight: "0px",
                  paddingLeft: "0px",
                  paddingTop: "4px",
                  paddingBottom: "4px",
                  position: "relative",
                  ":hover": {
                    backgroundColor: "unset",
                    color: "black",
                    textDecoration: "underline",
                  },
                }}
                endEnhancer={
                  <span
                    className={css({
                      marginLeft: "-6px",
                      display: "flex",
                      alignItems: "center",
                    })}
                  >
                    <Switch size="16" />
                  </span>
                }
                onClick={() => setIsExpanded((isExpanded) => !isExpanded)}
              >
                Zmień
              </Button> */}

              <Button
                kind="minimal"
                $style={{
                  fontSize: "12px",
                  marginLeft: "auto",
                  display: "flex",
                  alignItems: "center",
                  minHeight: "unset",
                  height: "12px",
                  //width: "65px",
                  paddingRight: "0px",
                  paddingLeft: "0px",
                  paddingTop: "4px",
                  paddingBottom: "4px",
                  flexShrink: 0,
                  ":hover": {
                    backgroundColor: "unset",
                    color: "black",
                    textDecoration: "underline",
                  },
                  ":focus": {
                    backgroundColor: "unset",
                    color: "black",
                    textDecoration: "underline",
                  },
                }}
                endEnhancer={
                  <span
                    className={css({
                      marginLeft: "-6px",
                      display: "flex",
                      alignItems: "center",
                    })}
                  >
                    {isExpanded ? (
                      <ChevronUp size="16" />
                    ) : (
                      <ChevronDown size="16" />
                    )}
                  </span>
                }
                onClick={() => setIsExpanded((isExpanded) => !isExpanded)}
              >
                {isExpanded ? "Zwiń" : "Rozwiń"}
              </Button>
            </div>
          </Cell>
        </Grid>

        <div
          data-ui="details"
          className={css({
            maxHeight: isExpanded ? "300px" : "0px",
            transform: isExpanded ? "translateY(0px)" : "translateY(-16px)",
            marginTop: isExpanded ? "30px" : "0px",
            marginBottom: isExpanded ? "16px" : "0px",
            opacity: isExpanded ? 1 : 0,
            overflow: isExpanded ? "auto" : "hidden",
            transition: "all 200ms ease",
          })}
        >
          <Grid>
            <Cell span={12}>
              <HeadingSmall
                marginTop={"0px"}
                marginBottom="16px"
                $style={{ fontWeight: 600 }}
              >
                {title}
              </HeadingSmall>
            </Cell>

            <Cell span={4}>
              <FormControl disabled label="Komentarz">
                <FormattedValue
                  {...(assignment.type === AssignmentType.Initial && {
                    textWhenEmpty: "N/D",
                  })}
                >
                  {assignment.assignerComment}
                </FormattedValue>
              </FormControl>
            </Cell>

            <Cell span={4}>
              <FormControl disabled label="Data otrzymania">
                <FormattedValue dataType="datetime">
                  {assignment.createdAt}
                </FormattedValue>
              </FormControl>
            </Cell>

            <Cell span={4}>
              <FormControl disabled label="Status">
                <FormattedValue dataType="assignment-status">
                  {assignment.status}
                </FormattedValue>
              </FormControl>
            </Cell>
          </Grid>
        </div>
      </div>
    </div>
  );
}
