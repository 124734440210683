import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND } from "baseui/button";
import { ModalBody, ModalFooter, ModalHeader, ROLE, SIZE } from "baseui/modal";
import React from "react";
import { LockAccess } from "tabler-icons-react";

import Button from "../components/button";
import { useLoading } from "../contexts/loading-context";
import Modal from "./modal";
import TextArea from "./text-area";

export enum ConfirmDialogType {
  AddressReveal = "AddressReveal",
  FilesReveal = "FilesReveal",
  Default = "Default",
}

type ConfirmDialogProps = {
  type?: ConfirmDialogType;
  isOpen: boolean;
  label?: string;
  close: () => void;
  confirm: () => void;
  needComment?: boolean;
};

export default function ConfirmDialog({
  type = ConfirmDialogType.Default,
  isOpen,
  label,
  close,
  confirm,
  needComment,
}: ConfirmDialogProps): React.ReactElement {
  const [css, theme] = useStyletron();

  const { isLoading } = useLoading();

  return (
    <Modal
      onClose={close}
      closeable={!isLoading}
      isOpen={isOpen}
      animate
      unstable_ModalBackdropScroll
      autoFocus
      size={SIZE.default}
      role={ROLE.dialog}
    >
      <ModalHeader>
        {type === ConfirmDialogType.AddressReveal ? (
          <Block display="flex" alignItems="center">
            <LockAccess
              color={theme.colors.negative}
              className={css({ marginRight: "10px" })}
            />
            Potwierdź chęć dostępu do danych
          </Block>
        ) : type === ConfirmDialogType.FilesReveal ? (
          <Block display="flex" alignItems="center">
            <LockAccess
              color={theme.colors.negative}
              className={css({ marginRight: "10px" })}
            />
            Potwierdź chęć dostępu do plików
          </Block>
        ) : (
          "Potwierdź akcję"
        )}
      </ModalHeader>
      <ModalBody>
        {needComment ? (
          <>
            <p>Ta akcja wymaga dodatkowego potwierdzenia i komentarza.</p>
            {label && (
              <p>
                <strong>{label}</strong>
              </p>
            )}
            <TextArea />
          </>
        ) : (
          <>
            {type !== ConfirmDialogType.AddressReveal && (
              <p>Ta akcja wymaga dodatkowego potwierdzenia.</p>
            )}

            {label ? (
              <p>
                <strong className={css({ whiteSpace: "pre-wrap" })}>
                  {label}
                </strong>
              </p>
            ) : (
              (type === ConfirmDialogType.AddressReveal && (
                <p>
                  Dane osobowe są domyślnie ukryte w systemie w celu ich
                  ochrony. Każdy użytkownik mający dostęp do dokumentu, sprawy
                  lub listy wysyłkowej może podejrzeć te dane. Takie zdarzenia
                  są rejestrowane przez system w celu analizy kto miał do jakich
                  danych dostęp.
                  <br />
                  <br />
                  <strong>
                    Pamiętaj, że dostęp do danych w ramach dokumentu, sprawy lub
                    listy wysyłkowej jest aktywny do końca czasu trwania sesji.
                  </strong>
                </p>
              )) ||
              (type === ConfirmDialogType.FilesReveal && (
                <p>
                  Pliki do dokumentu są domyślnie ukryte w systemie w celu
                  ochrony potencjanych danych osobowych zawartych w nich. Każdy
                  użytkownik mający dostęp do dokumentu może podejrzeć te pliki.
                  Takie zdarzenia są rejestrowane przez system w celu analizy
                  kto miał do jakich plików dostęp.
                  <br />
                  <br />
                  <strong>
                    Pamiętaj, że dostęp do plików w ramach dokumentu jest
                    aktywny do końca czasu trwania sesji.
                  </strong>
                </p>
              ))
            )}
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          kind={KIND.secondary}
          $style={{ marginRight: "10px" }}
          onClick={close}
          disabled={isLoading}
        >
          Anuluj
        </Button>
        <Button
          disabled={isLoading}
          isLoading={isLoading}
          onClick={() => {
            confirm();
            // close();
          }}
        >
          Potwierdź
        </Button>
      </ModalFooter>
    </Modal>
  );
}
