import { ColumnDef, Row } from "@tanstack/react-table";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { StyledLink } from "baseui/link";
import React, { MouseEvent, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  ArrowForwardUp,
  Cloud,
  Eye,
  FileExport,
  FileImport,
  FileSymlink,
  Paperclip,
  Pencil,
  Share,
} from "tabler-icons-react";

import {
  DOCUMENT_KINDS,
  DOCUMENT_TYPENAMES,
  SENDER_TYPENAMES,
} from "../constants";
import {
  Document,
  FinancialAccountingDocument,
} from "../containers/Documents/documents";
import { AssignmentShorthand } from "../containers/Folders/folders";
import { useDictionaries } from "../contexts/dictionaries-context";
import {
  checkDocumentPermissionToEdit,
  checkDocumentPermissionToShow,
} from "../utils/check-document-permission";
import { checkPermission } from "../utils/check-permission";
import {
  checkIsDispatchable,
  checkIsShareable,
} from "../utils/documents/assignment-actions";
import { PERMISSIONS } from "../utils/permissions";
import { setSortingParams } from "../utils/sorting";
import Button from "./button";
import DocumentDispatchModal from "./document-dispatch-modal";
import DocumentShareModal from "./document-share-modal";
import FormattedValue from "./formatted-value";
import SortingTableHeader, { SortDirection } from "./sorting-table-header";
import { MemoizedStatefulTableV8 } from "./stateful-table-v8";
import Tooltip from "./tooltip";

export enum FieldName {
  InternalNumber = "internalNumber",
  DocumentType = "documentType",
  DocumentKind = "documentKind",
  DocumentNumber = "documentNumber",
  CreatedAt = "createdAt",
  SequenceNumber = "sequenceNumber",
}

type DocumentsTableProps = {
  documents: Document[];
  handleSorting: (column: FieldName) => void;
  sortBy: FieldName | null;
  sortDirection: SortDirection | null;
  loading?: boolean;
  refetch: () => void;
};

export default function DocumentsTable({
  documents,
  handleSorting: handleSortingState,
  sortBy,
  sortDirection,
  loading,
  refetch,
}: DocumentsTableProps): React.ReactElement {
  const [css, theme] = useStyletron();
  const { findValue } = useDictionaries();

  const history = useHistory();
  const { search } = useLocation();

  const handleSorting = (column: FieldName) => {
    handleSortingState(column);
    setSortingParams(history, search, column, sortDirection);
  };

  const columns = useMemo<ColumnDef<any>[]>(
    () => [
      {
        header: () => (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.InternalNumber)}
            sortDirection={
              sortBy === FieldName.InternalNumber ? sortDirection : null
            }
          >
            Numer
          </SortingTableHeader>
        ),
        meta: {
          text: "Numer",
        },
        id: "internalNumber",
        disableGlobalFilter: false,
        cell: ({ row }: { row: Row<Document> }) => {
          const documentKind = row?.original?.documentKind?.toLowerCase();
          const documentTypename = row?.original?.type;
          return (
            <StyledLink
              {...(row?.original?.documentType &&
                (checkDocumentPermissionToShow(row?.original?.documentType) ||
                  checkPermission(PERMISSIONS.document.readAll)) && {
                  onClick: (event: MouseEvent) => {
                    event.preventDefault();
                    history.push(
                      `/documents/${documentKind}/${documentTypename}/${row.original.id}`
                    );
                  },
                  href: `/documents/${documentKind}/${documentTypename}/${row.original.id}`,
                })}
            >
              <div
                className={css({
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  gap: "5px",
                })}
              >
                <span className={css({ whiteSpace: "nowrap" })}>
                  {row?.original?.internalNumber}
                </span>

                <Tooltip
                  {...(row?.original?.documentKind ===
                    DOCUMENT_KINDS.Incoming && {
                    content: "Przychodzący",
                  })}
                  {...(row?.original?.documentKind ===
                    DOCUMENT_KINDS.Outgoing && {
                    content: "Wychodzący",
                  })}
                  {...(row?.original?.documentKind ===
                    DOCUMENT_KINDS.Internal && {
                    content: "Wewnętrzny",
                  })}
                  placement="bottomLeft"
                >
                  <span>
                    {row?.original?.documentKind ===
                      DOCUMENT_KINDS.Incoming && <FileImport size={18} />}

                    {row?.original?.documentKind ===
                      DOCUMENT_KINDS.Outgoing && <FileExport size={18} />}

                    {row?.original?.documentKind ===
                      DOCUMENT_KINDS.Internal && <FileSymlink size={18} />}
                  </span>
                </Tooltip>
              </div>
            </StyledLink>
          );
        },
      },
      {
        id: "caseId",
        header: "Znak sprawy",
        accessorKey: "case.number",
        cell: ({ row }: { row: Row<Document> }) =>
          row.original.case ? (
            <StyledLink
              onClick={(event: MouseEvent) => {
                event.preventDefault();
                history.push(`/cases/${row.original.case?.id}`);
              }}
              href={`/cases/${row.original.case?.id}`}
              $style={{
                color: `${
                  row.original.case?.isConductedElectronically
                    ? theme.colors.positive
                    : theme.colors.negative
                } !important`,
              }}
            >
              <div
                className={css({
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  gap: "5px",
                })}
              >
                <span
                  className={css({
                    whiteSpace: "nowrap",
                  })}
                >
                  {row.original.case?.number}
                </span>

                <Tooltip
                  content={
                    row.original.case?.isConductedElectronically
                      ? "Sprawa prowadzona elektronicznie"
                      : "Sprawa prowadzona tradycyjnie"
                  }
                >
                  <span>
                    {row.original.case?.isConductedElectronically ? (
                      <Cloud
                        size={14}
                        className={css({
                          verticalAlign: "middle",
                          display: "inline",
                          color: theme.colors.positive,
                        })}
                      />
                    ) : (
                      <Paperclip
                        size={14}
                        className={css({
                          verticalAlign: "middle",
                          display: "inline",
                          color: theme.colors.negative,
                        })}
                      />
                    )}
                  </span>
                </Tooltip>
              </div>
            </StyledLink>
          ) : (
            <FormattedValue />
          ),
      },
      {
        header: () => (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.DocumentKind)}
            sortDirection={
              sortBy === FieldName.DocumentKind ? sortDirection : null
            }
          >
            Rodzaj dokumentu
          </SortingTableHeader>
        ),
        id: "documentKind",
        meta: {
          text: "Rodzaj dokumentu",
        },
        cell: ({ row }: { row: Row<Document> }) => (
          <FormattedValue dataType="document-kind">
            {row?.original?.documentKind}
          </FormattedValue>
        ),
      },
      {
        header: () => (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.SequenceNumber)}
            sortDirection={
              sortBy === FieldName.SequenceNumber ? sortDirection : null
            }
          >
            Numer w składzie chronologicznym
          </SortingTableHeader>
        ),
        id: "sequenceNumber",
        meta: {
          text: "Numer w składzie chronologicznym",
        },
        cell: ({ row }: { row: Row<Document> }) => (
          <FormattedValue>{row?.original?.sequenceNumber}</FormattedValue>
        ),
      },
      {
        header: () => (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.DocumentType)}
            sortDirection={
              sortBy === FieldName.DocumentType ? sortDirection : null
            }
          >
            Typ
          </SortingTableHeader>
        ),
        id: "documentType",
        meta: {
          text: "Typ",
        },
        cell: ({ row }: { row: Row<Document> }) => {
          const documentType =
            row.original.documentType &&
            row.original.documentType !== "undefined"
              ? findValue(row.original.documentType)
              : null;

          return (
            <FormattedValue loadingIfUndefined={!!documentType}>
              {documentType}
            </FormattedValue>
          );
        },
      },
      {
        header: () => (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.CreatedAt)}
            sortDirection={
              sortBy === FieldName.CreatedAt ? sortDirection : null
            }
          >
            Data rejestracji
          </SortingTableHeader>
        ),
        id: "createdAt",
        meta: {
          text: "Data rejestracji",
        },
        cell: ({ row }: { row: Row<Document> }) => (
          <FormattedValue dataType="datetime">
            {row?.original?.createdAt}
          </FormattedValue>
        ),
      },
      {
        header: "Autor",
        id: "createdBy",
        cell: ({ row }: { row: Row<Document> }) => {
          return (
            <FormattedValue
              dataType="model:users"
              data={row?.original?.createdBy?.id}
            >
              {`${row?.original?.createdBy?.firstName} ${row?.original?.createdBy?.lastName}`}
            </FormattedValue>
          );
        },
      },
      {
        header: "Odpowiedzialny",
        id: "responsible",
        cell: ({ row }: { row: Row<Document> }) => {
          return (
            <FormattedValue
              dataType="model:users"
              data={row?.original?.responsible?.id}
            >
              {`${row?.original?.responsible?.firstName} ${row?.original?.responsible?.lastName}`}
            </FormattedValue>
          );
        },
      },
      {
        header: "Jednostka",
        id: "organizationalUnit",
        cell: ({ row }: { row: Row<Document> }) => (
          <FormattedValue
            dataType="model:organizational-units"
            data={row?.original?.organizationalUnit?.id}
          >
            {row?.original?.organizationalUnit?.symbol}
          </FormattedValue>
        ),
      },
      {
        header: "Nadawca",
        id: "sender",
        cell: ({ row }: { row: Row<Document> }) => {
          return row?.original?.sender ? (
            row?.original?.sender?.__typename === SENDER_TYPENAMES.User ? (
              <FormattedValue
                dataType="model:users"
                data={row?.original?.sender?.id}
              >
                {`${row?.original?.sender?.firstName} ${row?.original?.sender?.lastName}`}
              </FormattedValue>
            ) : (
              <FormattedValue>{row?.original?.sender?.name}</FormattedValue>
            )
          ) : (
            <FormattedValue />
          );
        },
      },
      {
        header: () => (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.DocumentNumber)}
            sortDirection={
              sortBy === FieldName.DocumentNumber ? sortDirection : null
            }
          >
            Numer dokumentu
          </SortingTableHeader>
        ),
        id: "documentNumber",
        meta: {
          text: "Numer dokumentu",
        },
        cell: ({ row }: { row: Row<Document> }) => {
          return (
            <FormattedValue>{row?.original?.documentNumber}</FormattedValue>
          );
        },
      },
      {
        id: "name",
        header: "Tytuł",
        accessorKey: "name",
        disableFilters: true,
        cell: ({ row }: { row: Row<Document> }) => (
          <FormattedValue>{row?.original?.name}</FormattedValue>
        ),
      },
      {
        id: "additionalCode",
        header: "Kod E+/ACC/QL/Inne",
        accessorKey: "additionalCode",
        disableFilters: true,
        cell: ({ row }: { row: Row<Document> }) => (
          <FormattedValue>{row?.original?.additionalCode}</FormattedValue>
        ),
      },
      {
        header: () => (
          <Block display="flex" justifyContent="flex-end">
            Kwota brutto w PLN
          </Block>
        ),
        id: "grossValue",
        meta: {
          text: "Kwota brutto w PLN",
        },
        cell: ({
          row,
        }: {
          row: Row<Document & FinancialAccountingDocument>;
        }) => {
          const getValue = (typename: string) => {
            switch (typename) {
              case DOCUMENT_TYPENAMES.RequestForm:
                return "totalValue";
              case DOCUMENT_TYPENAMES.FinancialAccountingDocument:
                return "grossValue";
              default:
                return "grossValue";
            }
          };

          const currency = row?.original?.currency?.code;

          const exchangeRate = row?.original?.currencyExchangeRate?.value;

          const calculateValueIntoPLN = (value?: number) => {
            switch (currency) {
              case undefined:
                return value;
              default:
                return value && value * exchangeRate;
            }
          };

          return (
            <Block display="flex" justifyContent="flex-end">
              <FormattedValue dataType="quota">
                {row?.original?.__typename
                  ? calculateValueIntoPLN(
                      row?.original[getValue(row?.original?.__typename)]
                    )
                  : ""}
              </FormattedValue>
            </Block>
          );
        },
      },
      {
        header: () => (
          <Block display="flex" justifyContent="flex-end">
            Kwota brutto w walucie
          </Block>
        ),
        id: "grossValueInCurrency",
        meta: {
          text: "Kwota brutto w walucie",
        },
        cell: ({ row }: { row: Row<FinancialAccountingDocument> }) => {
          const isFinancialAccountingDocument =
            row?.original?.__typename ===
            DOCUMENT_TYPENAMES.FinancialAccountingDocument;

          const currency = row?.original?.currency?.code;

          return (
            <Block display="flex" justifyContent="flex-end">
              <FormattedValue
                {...(isFinancialAccountingDocument && {
                  dataType: "quota",
                  currency,
                })}
              >
                {isFinancialAccountingDocument && currency !== undefined
                  ? row?.original?.grossValue
                  : ""}
              </FormattedValue>
            </Block>
          );
        },
      },
      {
        header: "Temat",
        id: "title",
        cell: ({ row }: { row: Row<Document> }) => {
          return <FormattedValue>{row?.original?.title}</FormattedValue>;
        },
      },
      {
        header: "Etap w obiegu",
        id: "currentStatus",
        disableFilters: true,
        cell: ({ row }: { row: Row<Document> }) => {
          return (
            <FormattedValue>
              {row?.original?.currentStatus?.documentFlowStep?.name}
            </FormattedValue>
          );
        },
      },
      {
        id: "actions",
        cell: ({ row }: { row: Row<Document> }) => {
          const documentKind = row?.original?.documentKind?.toLowerCase();
          const documentTypename = row?.original?.type;

          return (
            <div
              className={css({
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              })}
            >
              <Button
                kind={KIND.secondary}
                size={SIZE.mini}
                disabled={
                  !row.original.isEditable ||
                  (!!row?.original?.documentType &&
                    !checkDocumentPermissionToEdit(row?.original?.documentType))
                }
                onClick={() =>
                  history.push(
                    `/documents/${documentKind}/${documentTypename}/${row.original.id}/edit`
                  )
                }
                startEnhancer={<Pencil size={14} />}
              />
              <Button
                kind={KIND.secondary}
                size={SIZE.mini}
                $style={{ marginLeft: "6px" }}
                disabled={
                  !!row?.original?.documentType &&
                  !checkDocumentPermissionToShow(row?.original?.documentType) &&
                  !checkPermission(PERMISSIONS.document.readAll)
                }
                onClick={() =>
                  history.push(
                    `/documents/${documentKind}/${documentTypename}/${row.original.id}`
                  )
                }
                startEnhancer={<Eye size={14} />}
              />
            </div>
          );
        },
      },
    ],
    [documents, sortBy, sortDirection, findValue]
  );

  const [selectedDocumentRows, setSelectedDocumentRows] = useState<
    Row<Document>[]
  >();
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isDispatchModalOpen, setIsDispatchModalOpen] = useState(false);

  let resetRowSelection: () => void;

  return (
    <>
      <MemoizedStatefulTableV8
        columns={columns as ColumnDef<any>[]}
        data={documents}
        isLoading={loading}
        stickLastColumn
        bulkActions={[
          {
            label: "Przekaż",
            onExecute(selected, rows, reset) {
              setSelectedDocumentRows(rows);
              setIsDispatchModalOpen(true);

              resetRowSelection = reset;
            },
            filterRows: (row) => checkIsDispatchable(row.original),
            Icon: ArrowForwardUp,
          },
          {
            label: "Udostępnij",
            onExecute(selected, rows, reset) {
              setSelectedDocumentRows(rows);
              setIsShareModalOpen(true);

              resetRowSelection = reset;
            },
            filterRows: (row) => checkIsShareable(row.original),
            Icon: Share,
          },
        ]}
      />

      <DocumentShareModal
        assignments={
          (selectedDocumentRows?.map(({ original }: Row<Document>) => ({
            id: original?.currentAssignment?.id,
            primaryAssignableId: original.id,
          })) as unknown) as AssignmentShorthand[]
        }
        isOpen={isShareModalOpen}
        onCompleted={() => {
          resetRowSelection();
          setSelectedDocumentRows([]);
          refetch();
        }}
        close={() => setIsShareModalOpen(false)}
      />

      <DocumentDispatchModal
        assignments={
          (selectedDocumentRows?.map(({ original }: Row<Document>) => ({
            id: original?.currentAssignment?.id,
            primaryAssignableId: original.id,
          })) as unknown) as AssignmentShorthand[]
        }
        isOpen={isDispatchModalOpen}
        onCompleted={() => {
          resetRowSelection();
          setSelectedDocumentRows([]);
          refetch();
        }}
        close={() => setIsDispatchModalOpen(false)}
      />
    </>
  );
}
