import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { StyledLink } from "baseui/link";
import { Skeleton } from "baseui/skeleton";
import { LabelXSmall } from "baseui/typography";
import React from "react";
import { Trash } from "tabler-icons-react";

export type SingleFile = {
  id: number;
  __typename: string;
  originalName?: string;
  downloadUrl?: string;
};

type Props = {
  files?: SingleFile[];
  hasHiddenFiles?: boolean;
  selectedFiles: number[];
  setSelectedFiles: (selectedFiles: number[]) => void;
  disabled?: boolean;
};

export default function FilesList({
  files,
  hasHiddenFiles,
  selectedFiles,
  setSelectedFiles,
  disabled,
}: Props): React.ReactElement {
  const [css, theme] = useStyletron();

  return (
    <Block display="flex" flexDirection="column" marginBottom="scale200">
      {hasHiddenFiles ? (
        <Skeleton
          rows={0}
          height="20px"
          width="30%"
          overrides={{
            Root: {
              style: {
                backgroundColor: "#faebeb",
              },
            },
          }}
        />
      ) : (
        files?.map((file: SingleFile, index: number) => (
          <Block
            key={index}
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            paddingTop="scale100"
            paddingBottom="scale100"
          >
            <Trash
              size={18}
              className={css({
                marginRight: theme.sizing.scale200,
                color: disabled ? "gray" : theme.colors.negative,
                ":hover": {
                  cursor: disabled ? "not-allowed" : "pointer",
                  color: disabled ? "gray" : theme.colors.negative500,
                },
              })}
              {...(!disabled && {
                onClick: () =>
                  selectedFiles.includes(file.id)
                    ? setSelectedFiles(
                        selectedFiles?.filter(
                          (fileId: number) => fileId !== file.id
                        )
                      )
                    : setSelectedFiles([...selectedFiles, file.id]),
              })}
            />
            <StyledLink
              {...(disabled
                ? { $style: { textDecoration: "none" } }
                : {
                    href: `${process.env.REACT_APP_GRAPHQL_API_URL?.replace(
                      "/graphql",
                      ""
                    )}${file.downloadUrl}`,
                  })}
            >
              <LabelXSmall
                $style={{
                  fontWeight: 400,
                  textDecoration: selectedFiles.includes(file.id)
                    ? "line-through"
                    : "none",
                  textDecorationColor: selectedFiles.includes(file.id)
                    ? "red"
                    : undefined,
                  cursor: disabled ? "not-allowed" : "pointer",
                  ...(disabled && {
                    color: "gray",
                  }),
                }}
              >
                {file.originalName}
              </LabelXSmall>
            </StyledLink>
          </Block>
        ))
      )}
    </Block>
  );
}
