import { styled, useStyletron } from "baseui";
import { ButtonProps, SIZE } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { Theme } from "baseui/theme";
import { HeadingSmall, LabelXSmall } from "baseui/typography";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useLocation } from "react-router";
import { ArrowLeft } from "tabler-icons-react";

import { useAssignment } from "../contexts/assignment-context";
import { useLoading } from "../contexts/loading-context";
import { textContent } from "../utils/misc";
import AssignmentBar from "./assignment-bar";
import Button, { ForwardedButton } from "./button";
import CancellationBar from "./cancellation-bar";
import Cell from "./cell";
import Grid from "./grid";
import HeaderActionsList, { Action } from "./header-actions-list";
import Tooltip from "./tooltip";

const Container = styled(
  "header",
  ({ $theme, $sticked }: { $theme?: Theme; $sticked?: boolean }) => ({
    backgroundColor: "white",
    paddingTop: $sticked ? $theme?.sizing.scale600 : $theme?.sizing.scale1000,
    paddingBottom: $sticked ? $theme?.sizing.scale600 : $theme?.sizing.scale600,
    position: $sticked ? "sticky" : "absolute",
    top: "0px",
    left: "0px",
    right: "0px",
    zIndex: 12,
    borderBottomWidth: $sticked ? "2px" : "0px",
    borderBottomStyle: "solid",
    borderBottomColor: $theme?.colors.backgroundTertiary,
    ...($sticked && {
      marginBottom: "-72px",
      animationIterationCount: "1",
      animationDelay: "0ms",
      animationDuration: "200ms",
      animationFillMode: "both",
      animationName: {
        from: {
          transform: "translateY(-200px)",
        },
        to: {
          transform: "translateY(0px)",
        },
      } as any,
    }),
  })
);

type HeaderProps = {
  title: React.ReactNode;
  recordsNum?: number;
  labels?: React.ReactNode[];
  goBackOption?: boolean;
  buttons?: ({
    label: string;
    isNegative?: boolean;
    isPositive?: boolean;
    permission?: boolean;
    formId?: string;
    type?: string;
    disabled?: boolean;
    subactions?: Action[];
  } & ButtonProps)[];
  actions?: Action[];
  children?: React.ReactNode;
  hasActiveAssignmentBar?: boolean;
  hasCancellationBar?: boolean;
};

export default function Header({
  title,
  recordsNum,
  labels,
  goBackOption,
  buttons,
  actions,
  children,
  hasActiveAssignmentBar = false,
  hasCancellationBar = false,
}: HeaderProps): React.ReactElement {
  const [css, theme] = useStyletron();
  const [sticked, setSticked] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const location = useLocation();

  const { isFetching } = useLoading();
  const { assignment, isAssignmentActive } = useAssignment();

  useEffect(() => {
    containerRef?.current?.parentNode?.parentNode?.addEventListener(
      "scroll",
      (event: Event) => {
        if ((event?.target as HTMLElement)?.scrollTop > 52) setSticked(true);
        else setSticked(false);
      }
    );
  }, [containerRef]);

  const locationState = location.state as {
    alternativeDocumentsList: boolean;
  };

  const shouldShowHeaderActions = actions?.some(
    (action: Action) => action.permission || action.permission === undefined
  );

  const shouldShowAssignmentBar =
    hasActiveAssignmentBar && !!assignment && isAssignmentActive;

  return (
    <Container $sticked={sticked} ref={containerRef}>
      <Helmet defer={false}>
        {!isFetching && title ? (
          <title>{textContent(title as ReactElement)} – FRSE EZD</title>
        ) : (
          <title>FRSE EZD</title>
        )}
      </Helmet>

      {shouldShowAssignmentBar && (
        <AssignmentBar title={title} $sticked={sticked} />
      )}

      {hasCancellationBar && (
        <CancellationBar
          $sticked={sticked}
          hasActiveAssignmentBar={shouldShowAssignmentBar}
        />
      )}

      <Grid>
        <Cell span={12}>
          <div
            className={css({
              display: "flex",
              alignItems: "center",
              minHeight: "38px",
            })}
          >
            {goBackOption && (
              <span
                className={css({
                  position: "absolute",
                  display: "flex",
                  right: "100%",
                  top: "50%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                  color: "#444",
                  ":hover": {
                    color: "black",
                  },
                })}
                onClick={() => {
                  history.length > 1
                    ? history.goBack()
                    : locationState && locationState?.alternativeDocumentsList
                    ? history.goBack()
                    : history.push(
                        location.pathname
                          .split("/")
                          .slice(
                            0,
                            location.pathname.includes("documents") &&
                              !location.pathname.includes("edit") &&
                              !location.pathname.includes("paczka") &&
                              !location.pathname.includes("list") &&
                              !location.pathname.includes("przesylka")
                              ? -3
                              : (location.pathname.includes("action") ||
                                  location.pathname.includes("step") ||
                                  location.pathname.includes(
                                    "bank-accounts"
                                  )) &&
                                !location.pathname.includes("edit")
                              ? -2
                              : -1
                          )
                          .join("/")
                      );
                }}
              >
                <ArrowLeft />
              </span>
            )}
            <div
              className={css({
                display: sticked ? "flex" : "block",
                alignItems: "center",
              })}
            >
              {isFetching ? (
                <Skeleton
                  rows={0}
                  height="28px"
                  width={
                    title && (title as string).length > 0
                      ? `${(title as string).length * 1.4}ch`
                      : "160px"
                  }
                  animation
                />
              ) : (
                <HeadingSmall
                  marginTop="0px"
                  marginBottom="0px"
                  marginRight="20px"
                  $style={{ fontWeight: 600 }}
                >
                  {title}
                </HeadingSmall>
              )}
              <div
                className={css({
                  display: "flex",
                  marginTop: "3px",
                })}
              >
                {recordsNum !== undefined && recordsNum >= 0 && (
                  <LabelXSmall
                    marginTop="0px"
                    marginBottom="0px"
                    $style={{
                      textTransform: "uppercase",
                    }}
                  >
                    <span
                      className={css({
                        textDecorationLine: "underline",
                        textDecorationStyle: "dotted",
                      })}
                    >
                      {recordsNum}
                    </span>{" "}
                    {recordsNum === 1
                      ? "rekord"
                      : recordsNum % 10 >= 2 &&
                        recordsNum % 10 <= 4 &&
                        (recordsNum % 100 < 10 || recordsNum % 100 >= 20)
                      ? "rekordy"
                      : "rekordów"}
                  </LabelXSmall>
                )}

                {labels &&
                  labels.map(
                    (label, index) =>
                      label &&
                      ((typeof label === "object" &&
                        (label as any).props.children !== "undefined") ||
                        typeof label === "string") && (
                        <LabelXSmall
                          marginTop="0px"
                          marginBottom="0px"
                          key={`sublabel` + index}
                          $style={{
                            textTransform: "uppercase",
                            ":before": {
                              content: "'\\2022'",
                              marginRight: "6px",
                              marginLeft: "6px",
                              color: "#aaa",
                            },
                            ":first-of-type:before": {
                              content: "unset",
                            },
                          }}
                        >
                          {label}
                        </LabelXSmall>
                      )
                  )}
              </div>
            </div>

            {children && !sticked ? (
              <div
                className={css({
                  marginLeft: "16px",
                })}
              >
                {isFetching ? (
                  <Skeleton rows={0} height="42px" width="223px" animation />
                ) : (
                  children
                )}
              </div>
            ) : null}

            <div
              className={css({
                display: "flex",
                gap: "10px",
                marginLeft: "auto",
                minWidth: "0",
              })}
            >
              {isFetching ? (
                <Skeleton rows={0} height="42px" width="100px" animation />
              ) : (
                buttons &&
                buttons.map((item, index) => {
                  if (
                    item?.permission === false ||
                    (item?.subactions &&
                      item?.subactions?.filter(
                        (subaction) => subaction.permission === false
                      )?.length === item?.subactions?.length)
                  )
                    return false;

                  function renderButton(
                    forwarded?: boolean,
                    disabled?: boolean
                  ) {
                    const ButtonComponent = forwarded
                      ? ForwardedButton
                      : Button;

                    return (
                      <ButtonComponent
                        size={sticked ? SIZE.compact : SIZE.default}
                        $style={{
                          transitionDuration: "100ms",
                          transitionProperty: "all",
                          transitionTimingFunction: "ease-in-out",
                          ...(item?.isNegative && {
                            backgroundColor: theme.colors.negative400,
                            color: "#FFF",
                            ":hover": {
                              backgroundColor: theme.colors.negative500,
                            },
                            ":focus": {
                              backgroundColor: theme.colors.negative500,
                            },
                          }),
                          ...(item?.isPositive && {
                            backgroundColor: theme.colors.positive400,
                            color: "#FFF",
                            ":hover": {
                              backgroundColor: theme.colors.positive500,
                            },
                            ":focus": {
                              backgroundColor: theme.colors.positive500,
                            },
                          }),
                        }}
                        {...(item?.type && {
                          type: item?.type,
                        })}
                        {...(item?.formId && {
                          form: item?.formId,
                        })}
                        {...(item?.disabled && {
                          disabled: item?.disabled,
                        })}
                        {...item}
                      >
                        {item?.label}
                      </ButtonComponent>
                    );
                  }

                  if (item?.disabled && item?.label === "Edytuj") {
                    return (
                      <Tooltip
                        content={
                          "Dokument został podpisany, brak możliwości edycji"
                        }
                        key={`index` + index}
                        $style={{ zIndex: 20 }}
                      >
                        {renderButton(true)}
                      </Tooltip>
                    );
                  }

                  if (item?.subactions) {
                    return (
                      <HeaderActionsList
                        actions={item.subactions}
                        sticked={sticked}
                      >
                        {renderButton(
                          true,
                          item.subactions.filter(
                            (subaction) => subaction.permission === false
                          ).length === item.subactions.length
                        )}
                      </HeaderActionsList>
                    );
                  }

                  return renderButton();
                })
              )}

              {!!actions?.length &&
                shouldShowHeaderActions &&
                (isFetching ? (
                  <Skeleton rows={0} height="42px" width="50px" animation />
                ) : (
                  <HeaderActionsList actions={actions} sticked={sticked} />
                ))}
            </div>
          </div>
        </Cell>
      </Grid>
    </Container>
  );
}
