import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { Pencil, Trash } from "tabler-icons-react";

import Cell from "../../../components/cell";
import ConfirmDialog from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import FormControl from "../../../components/form-control";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import PermissionsTable from "../../../components/permissions-table";
import { useLoading } from "../../../contexts/loading-context";
import { FIELDS } from "../../../fields.d";
import { checkPermission } from "../../../utils/check-permission";
import { PERMISSIONS } from "../../../utils/permissions";
import { ROLES_FIELDS } from "../roles.form";
import { ROLES_DELETE, ROLES_SHOW } from "../roles.gql";

export default function RolesShow(): React.ReactElement {
  const [css, theme] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const { setIsLoading, isFetching, setIsFetching } = useLoading();

  const { refetch, data, error, loading } = useQuery(ROLES_SHOW, {
    variables: { id: id ? parseInt(id) : null },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  const [deleteRole] = useMutation(ROLES_DELETE);

  const submit = async () => {
    setIsLoading(true);

    try {
      await deleteRole({
        variables: {
          id: id ? parseInt(id) : null,
        },
      });

      enqueueSnackbar({
        message: "Usunięto pomyślnie",
        variant: "success",
      });

      setIsConfirmDialogOpen(false);

      history.push("/roles");
    } catch (error: unknown) {
      setIsConfirmDialogOpen(false);

      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (data?.role) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => data?.role && setIsFetching(false), [data]);

  if (!checkPermission(PERMISSIONS.role.read)) return <Redirect to="/" />;

  return (
    <article>
      <Header
        title={data?.role?.name}
        labels={["Role", "Wyświetlanie"]}
        goBackOption
        buttons={[
          {
            label: "Edytuj",
            kind: KIND.secondary,
            startEnhancer: <Pencil size={18} />,
            permission:
              !data?.role?.isSystem && checkPermission(PERMISSIONS.role.update),
            onClick: () => history.push(`/roles/${id}/edit`),
          },
        ]}
        actions={[
          {
            label: "Usuń rolę",
            icon: Trash,
            color: theme.colors.negative,
            onClick: () => setIsConfirmDialogOpen(true),
            permission:
              checkPermission(PERMISSIONS.role.delete) && !data?.role?.isSystem,
          },
        ]}
      />
      <Content>
        <Grid>
          {ROLES_FIELDS.map((group) => [
            group.label && (
              <Cell key={group.id + `-group`} span={12}>
                <LabelMedium marginBottom="scale200" marginTop="scale600">
                  {group.label}
                </LabelMedium>
                <hr
                  className={css({
                    borderWidth: "0px",
                    height: "1px",
                    backgroundColor: "#eee",
                  })}
                />
              </Cell>
            ),
            group.fields
              .filter((f) => f.show.visible)
              .map((item, index) => (
                <Cell span={item.span || 6} key={group.id + `-field` + index}>
                  <FormControl
                    label={
                      item.type !== FIELDS.PermissionsTable ? item.label : ""
                    }
                    overrides={{
                      ControlContainer: {
                        props: {
                          "data-test-id": item.id,
                        },
                      },
                    }}
                  >
                    {item.type === FIELDS.PermissionsTable ? (
                      <Block paddingTop="scale200" paddingBottom="scale600">
                        <PermissionsTable
                          isReadOnly
                          selectedIds={
                            loading
                              ? undefined
                              : data?.role?.permissions.map(
                                  (permission: Record<string, any>) =>
                                    permission.id
                                )
                          }
                        />
                      </Block>
                    ) : isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : (
                      <FormattedValue dataType={item.dataType}>
                        {item.show.accessor
                          ? data?.role[item.show.accessor[0]]?.[
                              item.show.accessor[1]
                            ]
                          : data?.role[item.id]}
                      </FormattedValue>
                    )}
                  </FormControl>
                </Cell>
              )),
          ])}
          <ConfirmDialog
            isOpen={isConfirmDialogOpen}
            label={`Usunięcie roli ${data?.role?.name}`}
            close={() => setIsConfirmDialogOpen(false)}
            confirm={submit}
          />
        </Grid>
      </Content>
    </article>
  );
}
