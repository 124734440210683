import { useStyletron } from "baseui";
import React, { Fragment, ReactNode } from "react";
import { Cell } from "react-table";
import { StyleObject } from "styletron-standard";

import { getColumnDataAlign } from "../utils/interface/column-sizes";
import Tooltip from "./tooltip";

type TableCellProps = {
  cell: Cell;
  editable?: boolean;
};

export default function TableCell({
  cell,
  editable,
}: TableCellProps): React.ReactElement {
  const [css] = useStyletron();

  const wrapper = document?.querySelector(
    `[data-cell=${cell.column.id.replace(/[^a-zA-Z]/g, "")}${
      cell.row.id
    }] > div`
  );

  const shouldShowTooltip =
    !editable &&
    wrapper &&
    (wrapper.clientWidth < wrapper.scrollWidth ||
      wrapper.clientHeight + 5 < wrapper.scrollHeight) &&
    cell.column.id !== "checkbox";

  if (cell.column.id === "actions") {
    return cell.render("Cell") as React.ReactElement;
  }

  const cellStyles: StyleObject = {
    width: "auto",
    minWidth: "calc(100%)",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display:
      wrapper && wrapper.clientHeight + 5 < wrapper.scrollHeight
        ? "-webkit-box"
        : "block",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    boxOrient: "vertical",
    lineClamp: 1,
    ...getColumnDataAlign(cell.column.id),
  };

  return (
    <Fragment>
      <div
        key={cell.value}
        data-cell={`${cell.column.id.replace(/[^a-zA-Z]/g, "")}${cell.row.id}`}
        {...(!editable && {
          className: css({
            position: "absolute",
            left: "0px",
            right: "0px",
            top: "0px",
            bottom: "0px",
            verticalAlign: "middle",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "flex",
            alignItems: "center",
            justifyContent: "inherit",
            ...(cellStyles.justifyContent !== "flex-start" && {
              paddingRight: "8px",
            }),
            ...(cellStyles.justifyContent !== "flex-end" && {
              paddingLeft: "8px",
            }),
          }),
        })}
      >
        {shouldShowTooltip ? (
          <Tooltip content={cell.render("Cell")}>
            <div className={css(cellStyles)}>{cell.render("Cell")}</div>
          </Tooltip>
        ) : (
          <div className={css(cellStyles)}>{cell.render("Cell")}</div>
        )}
      </div>

      {!editable && (
        <div
          className={css({
            width: "1px",
            visibility: "hidden",
            overflow: "hidden",
            userSelect: "none",
            pointerEvents: "none",
          })}
        >
          {cell.render("Cell")}
        </div>
      )}
    </Fragment>
  );
}

type TableCellWithChildrenProps = {
  children: ReactNode;
  editable?: boolean;
};

export function TableCellWithChildren({
  children,
  editable,
}: TableCellWithChildrenProps): React.ReactElement {
  const [css] = useStyletron();

  const cellStyles: StyleObject = {
    width: "auto",
    minWidth: "calc(100%)",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    boxOrient: "vertical",
    lineClamp: 1,
  };

  return (
    <Fragment>
      <div
        {...(!editable && {
          className: css({
            position: "absolute",
            left: "0px",
            right: "0px",
            top: "0px",
            bottom: "0px",
          }),
        })}
      >
        <div className={css(cellStyles)}>{children}</div>
      </div>

      {!editable && (
        <div
          className={css({
            margin: "-12px",
            width: "1px",
            visibility: "hidden",
            overflow: "hidden",
            userSelect: "none",
            pointerEvents: "none",
          })}
        >
          {children}
        </div>
      )}
    </Fragment>
  );
}
