import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND } from "baseui/button";
import { ModalBody, ModalFooter, ModalHeader, ROLE, SIZE } from "baseui/modal";
import { Skeleton } from "baseui/skeleton";
import React from "react";
import { Cell, Row } from "react-table";

import Button from "../components/button";
import { ActivityLog } from "../containers/ActivityLogs/activity-logs";
import { useLoading } from "../contexts/loading-context";
import FormattedValue from "./formatted-value";
import Modal from "./modal";
import Table from "./table";

type ActivityLogsModalProps = {
  isOpen: boolean;
  close: () => void;
  activityLogs: ActivityLog[];
};

export default function ActivityLogsModal({
  isOpen,
  close,
  activityLogs,
}: ActivityLogsModalProps): React.ReactElement {
  const [css] = useStyletron();
  const { isLoading, isFetching } = useLoading();

  const columns = React.useMemo(
    () => [
      {
        Header: "Lp.",
        accessor: "id",
        id: "ordinalNumber",
        Cell: ({ row }: { row: Row<ActivityLog> }) => (
          <FormattedValue>{row.index + 1}</FormattedValue>
        ),
      },
      {
        Header: (
          <Block display="flex" justifyContent="flex-end">
            Data
          </Block>
        ),
        accessor: "createdAt",
        Cell: ({ cell }: { cell: Cell }) => (
          <Block display="flex" justifyContent="flex-end">
            <FormattedValue dataType="datetime">{cell.value}</FormattedValue>
          </Block>
        ),
      },
      {
        Header: "Użytkownik",
        id: "flowUser",
        Cell: ({ row }: { row: Row<ActivityLog> }) =>
          row.original.substituter ? (
            <div
              className={css({
                display: "flex",
                alignItems: "center",
                gap: "5px",
              })}
            >
              <FormattedValue
                dataType={"model:users"}
                data={row.original.substituter?.id}
              >
                {`${row.original.substituter?.firstName} ${row.original.substituter?.lastName}`}
              </FormattedValue>

              <span className={css({ fontWeight: 400 })}>za</span>
              <FormattedValue
                dataType={"model:users"}
                data={row.original.user?.id}
              >
                {`${row.original.user?.firstName} ${row.original.user?.lastName}`}
              </FormattedValue>
              <span className={css({ fontWeight: 400 })}>(zastępstwo)</span>
            </div>
          ) : (
            <FormattedValue
              dataType={"model:users"}
              data={row.original.user?.id}
            >
              {`${row.original.user?.firstName} ${row.original.user?.lastName}`}
            </FormattedValue>
          ),
      },
      {
        Header: "Jednostka organizacyjna",
        Cell: ({ row }: { row: Row<ActivityLog> }) => (
          <FormattedValue dataType="json:organizational-units" inline>
            {row.original.userOrganizationalUnits}
          </FormattedValue>
        ),
      },
      {
        Header: "Akcja",
        accessor: "description",
        Cell: ({ row }: { row: Row<ActivityLog> }) => (
          <FormattedValue>
            {row.original.subject?.__typename
              ? `${row.original.description} (${row.original.subject?.__typename})`
              : row.original.description}
          </FormattedValue>
        ),
      },
      {
        Header: "Komentarz",
        accessor: "properties",
        Cell: ({ row }: { row: Row<ActivityLog> }) => (
          <>
            {row.original.activity === "closed" ? (
              <FormattedValue dataType="date">
                {row.original.properties.closedOn}
              </FormattedValue>
            ) : row?.original?.properties?.requestFormNote ? (
              <Block
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
              >
                <span>Na podstawie notatki:</span>
                <FormattedValue
                  dataType="model:documents"
                  data={`internal/RequestFormNote/${row?.original?.properties?.requestFormNote?.id}`}
                >
                  {row?.original?.properties?.requestFormNote?.internalNumber}
                </FormattedValue>
              </Block>
            ) : row.original.activity === "dispatched" ||
              row.original.activity === "shared" ? (
              <Block
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
              >
                <span>Przekazanie/udostępnienie do:</span>
                <FormattedValue dataType="json:users">
                  {row.original.assignmentUsers}
                </FormattedValue>
              </Block>
            ) : (
              <FormattedValue>{row.original.properties.reason}</FormattedValue>
            )}
          </>
        ),
      },
    ],
    []
  );

  return (
    <Modal
      onClose={close}
      closeable={!isLoading}
      isOpen={isOpen}
      animate
      unstable_ModalBackdropScroll
      autoFocus
      size={SIZE.auto}
      role={ROLE.dialog}
      $style={{
        width: "auto",
        minWidth: "80vw",
      }}
    >
      <ModalHeader>Logi aktywności dokumentu</ModalHeader>
      <ModalBody>
        <Block>
          {isFetching ? (
            <Skeleton rows={0} height="100px" width="100%" animation />
          ) : (
            <Table<ActivityLog>
              compact
              columns={columns}
              data={activityLogs}
              isModal
            />
          )}
        </Block>
      </ModalBody>
      <ModalFooter>
        <Button kind={KIND.secondary} onClick={close} disabled={isLoading}>
          Zamknij
        </Button>
      </ModalFooter>
    </Modal>
  );
}
