import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { KIND } from "baseui/button";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { DeviceFloppy, FileOff } from "tabler-icons-react";

import ConfirmDialog from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import FormRenderer from "../../../components/form-renderer";
import Header from "../../../components/header";
import { useLoading } from "../../../contexts/loading-context";
import { checkPermission } from "../../../utils/check-permission";
import { PERMISSIONS } from "../../../utils/permissions";
import { User } from "../../Users/users";
import { GROUPS_FIELDS, GroupsFormInputs } from "../groups.form";
import { GROUPS_EDIT, GROUPS_SHOW } from "../groups.gql";

export default function GroupsEdit(): React.ReactElement {
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const { enqueueSnackbar } = useSnackbar();
  const {
    control,
    formState: { errors, isDirty, ...formState },
    handleSubmit,
    setValue,
    reset,
    ...methods
  } = useForm<GroupsFormInputs>();

  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const { data, error: queryError } = useQuery(GROUPS_SHOW, {
    variables: { id: id ? parseInt(id) : null },
    fetchPolicy: "cache-first",
  });
  const [editGroup, { error }] = useMutation(GROUPS_EDIT);

  const { setIsFetching, isLoading, setIsLoading } = useLoading();

  const onSubmit = async ({ name, users }: GroupsFormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      await editGroup({
        variables: {
          groupUpdateInput: {
            id: parseInt(id),
            name,
            userIds: users?.map(
              ({ id }: { id: number | string }) => id as number
            ),
          },
        },
      });

      enqueueSnackbar({
        message: "Zapisano pomyślnie",
        variant: "success",
      });

      history.push(`/groups/${id}`);
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => setIsFetching(true), []);

  useEffect(() => {
    if (data?.group) {
      setValue("name", data?.group?.name);
      setValue(
        "users",
        data?.group?.users?.nodes?.map((user: User) => ({ ...user }))
      );
      setIsFetching(false);
    }
  }, [data]);

  useEffect(() => {
    if (queryError?.graphQLErrors)
      enqueueSnackbar({
        message: (queryError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [queryError]);

  if (!checkPermission(PERMISSIONS.group.update)) return <Redirect to="/" />;

  return (
    <article>
      <Header
        title={data?.group?.name}
        labels={["Grupy użytkowników", "Edytowanie"]}
        goBackOption
        buttons={[
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              isDirty ? setIsCancelConfirmDialogOpen(true) : history.goBack(),
          },
          {
            label: "Zapisz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            onClick: handleSubmit(onSubmit),
            disabled: isLoading,
            isLoading: isLoading,
            type: "submit",
            formId: "editGroup",
          },
        ]}
      />
      <Content>
        <FormProvider<GroupsFormInputs>
          control={control}
          formState={{ errors, isDirty, ...formState }}
          handleSubmit={handleSubmit}
          reset={reset}
          setValue={setValue}
          {...methods}
        >
          <FormRenderer
            id="editGroup"
            onSubmit={handleSubmit(onSubmit)}
            type="edit"
            isLoading={isLoading}
            error={error}
            fields={GROUPS_FIELDS}
          />
        </FormProvider>

        <ConfirmDialog
          isOpen={isCancelConfirmDialogOpen}
          label="Anulowanie edycji grupy"
          close={() => setIsCancelConfirmDialogOpen(false)}
          confirm={() => history.goBack()}
        />
      </Content>
    </article>
  );
}
