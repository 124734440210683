import { ApolloError, useMutation } from "@apollo/client";
import { useStyletron } from "baseui";
import { KIND } from "baseui/button";
import { ModalBody, ModalFooter, ModalHeader } from "baseui/modal";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { AlertOctagon } from "tabler-icons-react";

import { InputValidationError } from "../api";
import Button from "../components/button";
import Modal from "../components/modal";
import { DOCUMENTS_ASSIGN_TO_CASE } from "../containers/Documents/documents.gql";
import { RequestFormInputs } from "../containers/Documents/RequestForm/request-form.form";
import { useLoading } from "../contexts/loading-context";
import { formValidation } from "../utils/formValidation";
import FormControl from "./form-control";
import { ControlledCasesSelect } from "./select";

type AssignToCaseModalProps = {
  caseId?: string;
  documentId: number;
  isOpen: boolean;
  refetch: () => void;
  close: () => void;
};

export default function AssignToCaseModal({
  caseId,
  documentId,
  isOpen,
  refetch,
  close,
}: AssignToCaseModalProps): React.ReactElement {
  const [css] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, setIsLoading } = useLoading();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<{ caseName: { id: string; label?: string }[] }>();

  const [assignToCase, { error }] = useMutation(DOCUMENTS_ASSIGN_TO_CASE);

  const onCaseAssignSubmit = async ({ caseName }: RequestFormInputs) => {
    setIsLoading(true);

    try {
      await assignToCase({
        variables: {
          documentCaseAssignInput: {
            id: documentId,
            caseId: caseName && caseName[0]?.id,
          },
        },
      });

      enqueueSnackbar({
        message: "Przypisano pomyślnie",
        variant: "success",
      });

      refetch();
      reset();
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
      close();
    }
  };

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    caseId && setValue("caseName", [{ id: caseId }]);
  }, [caseId]);

  return (
    <Modal isOpen={isOpen} onClose={close}>
      <form onSubmit={handleSubmit(onCaseAssignSubmit)}>
        <ModalHeader>Przypisanie do sprawy</ModalHeader>
        <ModalBody>
          <FormControl
            error={
              ((errors as any)["caseName"] &&
                (errors as any)["caseName"].message) ||
              (error &&
                error.graphQLErrors[0]?.extensions?.code ===
                  "INPUT_VALIDATION_ERROR" &&
                error.graphQLErrors[0]?.extensions?.validationErrors
                  ?.find(
                    (vE: InputValidationError) => vE?.property === "caseName"
                  )
                  ?.errors.map((message: string) => (
                    <div
                      key="error"
                      className={css({
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      })}
                    >
                      {message}
                      <span
                        className={css({
                          color: "#999",
                          marginLeft: "auto",
                          marginRight: "5px",
                        })}
                      >
                        Walidacja serwera
                      </span>
                      <AlertOctagon color="#999" size={12} />
                    </div>
                  ))[0])
            }
            disabled={isLoading}
          >
            <ControlledCasesSelect
              control={control}
              name="caseName"
              placeholder="Wybierz"
              rules={{
                required: formValidation.messages.required,
              }}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            kind={KIND.secondary}
            $style={{ marginRight: "12px" }}
            onClick={close}
          >
            Anuluj
          </Button>
          <Button type="submit" kind={KIND.primary} isLoading={isLoading}>
            Zapisz
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
