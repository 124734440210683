import React from "react";
import { Link as RouterLink } from "react-router-dom";

type LinkProps = {
  path: string;
  children: React.ReactChild;
};

export default function Link({
  children,
  path,
}: LinkProps): React.ReactElement {
  return <RouterLink to={path}>{children}</RouterLink>;
}
