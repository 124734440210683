import {
  ALL_DOCUMENTS_FIELDS,
  AllowedField,
  DocumentFieldsItem,
} from "../constants";
import { DocumentStatus } from "../containers/Documents/documents";
import { HistoryFlowStep } from "../containers/Flow/document-flow";

export const handleHistoryFlowSteps = (
  documentStatuses: DocumentStatus[]
): HistoryFlowStep[] => {
  const historyFlowSteps: HistoryFlowStep[] = [];

  documentStatuses
    ?.filter((item: DocumentStatus) => item.documentFlowAction)
    ?.forEach((item: DocumentStatus) => {
      historyFlowSteps.push({
        id: item?.documentFlowStep?.id,
        documentFlowStep: item?.documentFlowStep,
        comment: item?.comment,
        commentTime: item?.createdAt,
        user: item?.user,
        userPosition: item?.userPosition,
        userOrganizationalUnits: item?.userOrganizationalUnits,
        substituter: item?.substituter,
        commentAuthor: item?.user,
        endDate: item?.createdAt,
        actionLabel: item?.documentFlowAction?.label,
        actionStyle: item?.documentFlowAction?.style,
        actionResultingDocumentState:
          item?.documentFlowAction?.resultingDocumentState,
        isEndingDocumentFlow: item?.documentFlowAction?.isEndingDocumentFlow,
        isProcessedByDocumentFlowStarter:
          item?.documentFlowStep?.isProcessedByDocumentFlowStarter,
        organizationalUnit: item?.documentFlowStep?.organizationalUnit,
        documentFlowRecipients: item?.documentFlowRecipients,
      });
    });
  return historyFlowSteps;
};

export const handleDataForProgressBar = (
  documentStatuses: DocumentStatus[]
): DocumentStatus[] => {
  let foundIndex = 0;
  let dataForProgressBar: DocumentStatus[] = [];

  documentStatuses?.map((status: DocumentStatus, index: number) => {
    if (status?.documentFlowStep?.isInitial) {
      foundIndex = index;
    }
  });
  dataForProgressBar = documentStatuses?.slice(foundIndex);

  return dataForProgressBar;
};

export const renderDocumentFieldsOptions = (
  documentKind: string,
  documentType: string
): AllowedField[] => {
  const specificDocumentTypes = new Set([
    "invoice",
    "requestForm",
    "contract",
    "billIssuanceRequest",
    "contractPreparationRequest",
    "requestFormNote",
    "payment",
  ]);

  const targetDocument = specificDocumentTypes.has(documentType)
    ? documentType
    : documentKind?.toLowerCase();

  const foundItem = ALL_DOCUMENTS_FIELDS.find(
    (item: DocumentFieldsItem) => item.document === targetDocument
  );

  return foundItem?.allowedFields || [];
};

export const renderFieldLabel = (fieldName: string): string => {
  const allFields = ALL_DOCUMENTS_FIELDS.flatMap(
    (fields) => fields.allowedFields
  ).map(({ id, label }) => ({ id, label }));

  return (
    allFields.find(({ id }: AllowedField) => id === fieldName)?.label || ""
  );
};
