import { BasicFilter, FILTERS } from "./../../filters.d";

export const JRWA_CLASSIFICATIONS_FILTERS: BasicFilter[] = [
  {
    id: "symbol",
    label: "Symbol",
    type: FILTERS.Text,
  },
  {
    id: "name",
    label: "Nazwa",
    type: FILTERS.Text,
  },
  {
    id: "archivalCategory",
    label: "Kategoria archiwalna",
    type: FILTERS.Text,
  },
];
