import { ApolloError, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { KIND, SIZE } from "baseui/button";
import { StyledLink } from "baseui/link";
import {
  StyledRoot,
  StyledTable,
  StyledTableBody,
  StyledTableBodyCell,
  StyledTableBodyRow,
} from "baseui/table-semantic";
import { LabelSmall } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { MouseEvent, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { Redirect, useLocation } from "react-router-dom";
import { Cell as TableCell, Row } from "react-table";
import {
  ChevronDown,
  ChevronDownLeft,
  ChevronRight,
  Eye,
  Plus,
} from "tabler-icons-react";

import BottomPanel from "../../../components/bottom-panel";
import Button from "../../../components/button";
import Cell from "../../../components/cell";
import Content from "../../../components/content";
import Filters from "../../../components/filters";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import NewFinancialPlanModal from "../../../components/new-financial-plan-modal";
import PagingControls from "../../../components/paging-controls";
import SortingTableHeader, {
  SortDirection,
} from "../../../components/sorting-table-header";
import Table from "../../../components/table";
import BaseTableCell, {
  TableCellWithChildren,
} from "../../../components/table-cell";
import Tooltip from "../../../components/tooltip";
import { LOCAL_STORAGE_VARIABLES } from "../../../constants";
import { useLoading } from "../../../contexts/loading-context";
import { usePaging } from "../../../contexts/paging-context";
import {
  BasicFilter,
  FILTERS,
  FiltersState,
  SingleFilter,
} from "../../../filters.d";
import { checkPermission } from "../../../utils/check-permission";
import { translateFiltersState } from "../../../utils/filters";
import { PERMISSIONS } from "../../../utils/permissions";
import { setSortingParams } from "../../../utils/sorting";
import { sortColumnsByOrder } from "../../../utils/table";
import { User } from "../../Users/users";
import { Group } from "../groups";
import { GROUPS_FILTERS } from "../groups.filters";
import { GroupsFieldName } from "../groups.form";
import { GROUPS_INDEX } from "../groups.gql";

export default function GroupsIndex(): React.ReactElement {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [css, theme] = useStyletron();
  const {
    isFetching,
    setIsFetching,
    isPartialFetching,
    setIsPartialFetching,
  } = useLoading();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { pageSize, currentPage, setTotalCount } = usePaging();

  const { search } = useLocation();

  const params = useMemo(() => new URLSearchParams(search), [search]);

  const [sortBy, setSortBy] = useState<GroupsFieldName | null>(
    (params.get("sortBy") as GroupsFieldName) || GroupsFieldName.Name
  );

  const [sortDirection, setSortDirection] = useState<SortDirection | null>(
    (params.get("sortDirection") as SortDirection) || SortDirection.DESC
  );

  const [filters, setFilters] = useState<FiltersState>();

  const expandedStateFromLocalStorage = localStorage.getItem(
    LOCAL_STORAGE_VARIABLES.ExpandedRowsByGroupIds
  )
    ? (JSON.parse(
        localStorage.getItem(
          LOCAL_STORAGE_VARIABLES.ExpandedRowsByGroupIds
        ) as string
      ) as number[])
    : [];

  const [expandedRowsByGroupIds, setExpandedRowsByGroupIds] = useState(
    expandedStateFromLocalStorage || []
  );

  const handleSorting = (column: GroupsFieldName) => {
    setSortingParams(history, search, column, sortDirection);
    setSortBy(column);
    setSortDirection(
      sortDirection === null
        ? SortDirection.DESC
        : sortDirection === SortDirection.ASC
        ? SortDirection.DESC
        : SortDirection.ASC
    );
  };

  const { data, error, loading, refetch } = useQuery(GROUPS_INDEX, {
    variables: {
      pageSize,
      offset: (currentPage - 1) * pageSize,
      sorting: {
        field: sortBy,
        direction: sortDirection,
      },
      ...(filters && {
        filter: translateFiltersState(filters),
      }),
    },
  });

  useEffect(() => {
    if (data?.groups) setTimeout(() => refetch(), 200);
    setIsFetching(true);
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined" && data?.groups) {
      localStorage.setItem(
        LOCAL_STORAGE_VARIABLES.ExpandedRowsByGroupIds,
        JSON.stringify(expandedRowsByGroupIds)
      );
    }
  }, [expandedRowsByGroupIds]);

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    setTimeout(() => refetch(), 200);
    setIsPartialFetching(true);
  }, [currentPage, pageSize]);

  useEffect(() => {
    setTimeout(() => refetch(), 200);
    setIsPartialFetching(true);
  }, [sortBy, sortDirection]);

  useEffect(() => {
    if (data?.groups) setIsFetching(false);
    if (data?.groups?.totalCount >= 0) setTotalCount(data?.groups?.totalCount);
  }, [data]);

  const columns = React.useMemo(
    () => [
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(GroupsFieldName.Name)}
            sortDirection={
              sortBy === GroupsFieldName.Name ? sortDirection : null
            }
          >
            Nazwa
          </SortingTableHeader>
        ),
        id: "name",
        Cell: ({ row }: { row: Row<Group> }) => (
          <div
            onClick={() =>
              setExpandedRowsByGroupIds((expandedRowsByGroupIds) =>
                expandedRowsByGroupIds.includes(row.original.id)
                  ? expandedRowsByGroupIds.filter(
                      (id) => id !== row.original.id
                    )
                  : [...expandedRowsByGroupIds, row.original.id]
              )
            }
            className={css({
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            })}
          >
            {row.isExpanded ? (
              <ChevronDown
                size={16}
                className={css({
                  verticalAlign: "middle",
                  marginRight: "6px",
                  display: "inline",
                })}
              />
            ) : (
              <ChevronRight
                size={16}
                className={css({
                  verticalAlign: "middle",
                  marginRight: "6px",
                  display: "inline",
                })}
              />
            )}

            <Tooltip
              content={`Grupa ${
                !row.original?.isUsedInAnyDocumentFlow ? "nie " : ""
              }należy do obiegu zdefiniowanego`}
            >
              <span>
                <StyledLink
                  onClick={(event: MouseEvent) => {
                    event.preventDefault();
                  }}
                  className={css({
                    position: "relative",
                    ":before": {
                      content: "''",
                      position: "absolute",
                      top: "3px",
                      right: "-16px",
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: row.original?.isUsedInAnyDocumentFlow
                        ? theme.colors.positive
                        : theme.colors.negative,
                    },
                  })}
                >
                  {row.original.name}
                </StyledLink>
              </span>
            </Tooltip>
          </div>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(GroupsFieldName.CreatedAt)}
            sortDirection={
              sortBy === GroupsFieldName.CreatedAt ? sortDirection : null
            }
          >
            Data dodania
          </SortingTableHeader>
        ),
        id: "createdAt",
        Cell: ({ row }: { row: Row<Group> }) => (
          <FormattedValue dataType="date">
            {row?.original?.createdAt}
          </FormattedValue>
        ),
      },
      {
        Header: "Dodano przez",
        id: "createdBy",
        Cell: ({ row }: { row: Row<Group> }) => (
          <FormattedValue dataType="model:users">
            {`${row?.original?.createdBy?.firstName} ${row?.original?.createdBy?.lastName}`}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(GroupsFieldName.UpdatedAt)}
            sortDirection={
              sortBy === GroupsFieldName.UpdatedAt ? sortDirection : null
            }
          >
            Data aktualizacji
          </SortingTableHeader>
        ),
        id: "updatedAt",
        Cell: ({ row }: { row: Row<Group> }) => (
          <FormattedValue dataType="date">
            {row?.original?.updatedAt}
          </FormattedValue>
        ),
      },
      {
        Header: "Zaktualizowano przez",
        id: "updatedBy",
        Cell: ({ row }: { row: Row<Group> }) => (
          <FormattedValue dataType="model:users">
            {`${row?.original?.updatedBy?.firstName} ${row?.original?.updatedBy?.lastName}`}
          </FormattedValue>
        ),
      },
      {
        id: "actions",
        Cell: ({ row }: { row: Row<Group> }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              $style={{ marginLeft: "6px" }}
              $as="a"
              href={`/groups/${row.original.id}`}
              onClick={(e) => {
                e.preventDefault();
                history.push(`/groups/${row.original.id}`);
              }}
              startEnhancer={<Eye size={14} />}
            />
          </div>
        ),
      },
    ],
    [data, sortBy, sortDirection, filters, expandedRowsByGroupIds]
  );

  if (!checkPermission(PERMISSIONS.group.read)) return <Redirect to="/" />;

  return (
    <article>
      <Header
        title="Grupy użytkowników"
        recordsNum={data?.groups?.totalCount}
        labels={["Lista"]}
        buttons={[
          {
            label: "Dodaj grupę",
            kind: KIND.primary,
            startEnhancer: <Plus size={18} />,
            permission: checkPermission(PERMISSIONS.group.create),
            onClick: () => history.push("/groups/create"),
          },
        ]}
      />

      <Filters filters={GROUPS_FILTERS} state={filters} setState={setFilters} />

      <Content filtersOffset>
        <Grid>
          <Cell span={12} $style={{ position: "relative" }}>
            <Table<Group>
              columns={columns}
              data={data?.groups?.nodes}
              isLoading={isFetching || isPartialFetching || loading}
              stickLastColumn
              $rows={(rows, prepareRow, visibleColumns, orderedColumns) => {
                return rows
                  .map((row: any) => {
                    row.canExpand = true;

                    if (expandedRowsByGroupIds?.includes(row.original.id)) {
                      row.isExpanded = true;
                    } else {
                      row.isExpanded = false;
                    }

                    prepareRow(row);

                    return row.isExpanded
                      ? [
                          row.cells
                            .filter(
                              (cell: TableCell) =>
                                visibleColumns.includes(cell.column.id) ||
                                cell.column.id === "actions" ||
                                cell.column.id === "checkbox"
                            )
                            .sort(
                              (
                                { column: a }: TableCell,
                                { column: b }: TableCell
                              ) => sortColumnsByOrder(a, b, orderedColumns)
                            )
                            .map((cell: TableCell) => (
                              <BaseTableCell key={cell.column.id} cell={cell} />
                            )),
                          [
                            row.original.users.nodes.length > 0 ? (
                              <TableCellWithChildren key="group-users">
                                <StyledRoot $style={{ margin: "-2px" }}>
                                  <StyledTable
                                    className={css({
                                      maxWidth: "100%",
                                      overflowX: "auto",
                                      scrollSnapType: "x mandatory",
                                      position: "relative",
                                      borderCollapse: "collapse",
                                      minWidth: "100%",
                                      boxSizing: "border-box",
                                    })}
                                  >
                                    <StyledTableBody>
                                      {row.original.users.nodes.map(
                                        (user: User) => (
                                          <StyledTableBodyRow
                                            key={user.id}
                                            $style={{
                                              ":hover": {
                                                backgroundColor: "#fbfbfb",
                                              },
                                              borderBottomStyle: "solid",
                                              borderBottomWidth: "0.5px",
                                              borderBottomColor:
                                                theme.colors.inputBorder,
                                              ":last-of-type": {
                                                borderBottomWidth: "0px",
                                              },
                                            }}
                                          >
                                            <StyledTableBodyCell
                                              $style={{
                                                position: "relative",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                whiteSpace: "nowrap",
                                                verticalAlign:
                                                  "middle !important",
                                                height: "40px",
                                                paddingLeft: "10px",
                                                paddingRight: "10px",
                                                fontSize: "12px",
                                                borderRightStyle: "solid",
                                                borderRightWidth: "0.5px",
                                                borderRightColor:
                                                  theme.colors.inputBorder,
                                                scrollSnapAlign: "start",
                                                flexGrow: 1,
                                                ":last-of-type": {
                                                  borderRightWidth: "0px",
                                                },
                                                ":first-of-type": {
                                                  wordBreak: "break-all",
                                                },
                                              }}
                                            >
                                              <ChevronDownLeft
                                                size={16}
                                                className={css({
                                                  verticalAlign: "middle",
                                                  marginRight: "6px",
                                                  display: "inline",
                                                })}
                                              />

                                              <FormattedValue
                                                data={user.id}
                                                dataType="model:users"
                                                $style={{}}
                                              >
                                                {`${user.firstName} ${user.lastName}`}
                                              </FormattedValue>
                                            </StyledTableBodyCell>
                                          </StyledTableBodyRow>
                                        )
                                      )}
                                    </StyledTableBody>
                                  </StyledTable>
                                </StyledRoot>
                              </TableCellWithChildren>
                            ) : (
                              <LabelSmall>Brak użytkowników</LabelSmall>
                            ),
                          ],
                        ]
                      : [
                          row.cells
                            .filter(
                              (cell: TableCell) =>
                                visibleColumns.includes(cell.column.id) ||
                                cell.column.id === "actions" ||
                                cell.column.id === "checkbox"
                            )
                            .sort(
                              (
                                { column: a }: TableCell,
                                { column: b }: TableCell
                              ) => sortColumnsByOrder(a, b, orderedColumns)
                            )
                            .map((cell: TableCell) => (
                              <BaseTableCell key={cell.column.id} cell={cell} />
                            )),
                        ];
                  })
                  .flat();
              }}
            />
          </Cell>

          <Cell span={12}>
            <BottomPanel>
              <PagingControls />
            </BottomPanel>
          </Cell>
        </Grid>
      </Content>

      <NewFinancialPlanModal
        isOpen={isDialogOpen}
        close={() => setIsDialogOpen(false)}
        refetch={refetch}
      />
    </article>
  );
}
