import { Document } from "../Documents/documents";
import { User } from "../Users/users";

export type DocumentPickup = {
  id: number;
  state: DocumentPickupState;
  user: User;
  userId: number;
  isModifiable: boolean;
  requestedAt: string;
  confirmedAt: string;
  createdAt: string;
  updatedAt: string;
  createdById: number;
  createdBy: User;
  documents: Document[];
  confirmedById: number;
  confirmedBy: User;
};

export enum DocumentPickupState {
  New = "New",
  PickupInProgress = "PickupInProgress",
  PickupConfirmed = "PickupConfirmed",
  ReturnInProgress = "ReturnInProgress",
  ReturnConfirmed = "ReturnConfirmed",
}
