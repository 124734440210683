import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { KIND } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { AlertOctagon, DeviceFloppy, FileOff } from "tabler-icons-react";

import { InputValidationError } from "../../../api";
import Cell from "../../../components/cell";
import ConfirmDialog from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import FormControl from "../../../components/form-control";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import { ControlledInput } from "../../../components/input";
import {
  ControlledCurrencySelect,
  ControlledFundingSourceSelect,
} from "../../../components/select";
import { useLoading } from "../../../contexts/loading-context";
import { FIELDS } from "../../../fields.d";
import { checkPermission } from "../../../utils/check-permission";
import { formValidation } from "../../../utils/formValidation";
import { PERMISSIONS } from "../../../utils/permissions";
import { scrollOnError } from "../../../utils/scrollOnError";
import {
  FormInputs,
  INTERNAL_ACCOUNTS_FIELDS,
} from "../internal-accounts.form";
import {
  INTERNAL_ACCOUNTS_SHOW,
  INTERNAL_ACCOUNTS_UPDATE,
} from "../internal-accounts.gql";

export default function InternalAccountsEdit(): React.ReactElement {
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );

  const [errorTimeStamp, setErrorTimeStamp] = useState<number>();
  const [css] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { isLoading, setIsLoading, isFetching, setIsFetching } = useLoading();
  const { id } = useParams<{ id?: string }>();

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    setValue,
    watch,
  } = useForm<FormInputs>();

  const watchCurrency = watch("currencies");

  const { refetch, data } = useQuery(INTERNAL_ACCOUNTS_SHOW, {
    variables: { id: id ? parseInt(id) : null },
  });

  const [editInternalAccount, { error: mutationError }] = useMutation(
    INTERNAL_ACCOUNTS_UPDATE
  );

  const onError = (errors: Record<string, unknown>, e: any) =>
    setErrorTimeStamp(e.timeStamp);

  const onSubmit = async ({
    name,
    fundingSource,
    accountNumber,
    currencies,
  }: FormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      await editInternalAccount({
        variables: {
          internalAccountUpdateInput: {
            id: id ? parseInt(id) : null,
            name,
            fundingSource: fundingSource && fundingSource[0]?.id,
            accountNumber: accountNumber?.replaceAll(" ", ""),
            limit: 10000,
            currencyId: currencies?.length ? currencies[0]?.currencyId : null,
          },
        },
      });

      enqueueSnackbar({
        message: "Utworzono pomyślnie",
        variant: "success",
      });
      history.push(`/internal-accounts/${id}`);
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (errors) scrollOnError(errors, INTERNAL_ACCOUNTS_FIELDS, "edit");
  }, [errorTimeStamp]);

  useEffect(() => {
    if (data?.internalAccount) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => {
    data?.internalAccount && setIsFetching(false);
    setValue("name", data?.internalAccount?.name);
    setValue("fundingSource", [{ id: data?.internalAccount?.fundingSource }]);
    setValue("accountNumber", data?.internalAccount?.accountNumber);
    setValue("balance", data?.internalAccount?.balance);
    !!data?.internalAccount?.currency &&
      setValue("currencies", [
        {
          id: data?.internalAccount?.currency?.code,
        },
      ]);
  }, [data]);

  if (!checkPermission(PERMISSIONS.internalAccounts.update))
    return <Redirect to="/" />;

  return (
    <article>
      <Header
        title={`Edycja konta wewnętrznego ${data?.internalAccount?.name}`}
        labels={["Konta wewnętrzne", "Edytowanie"]}
        goBackOption
        buttons={[
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              isDirty ? setIsCancelConfirmDialogOpen(true) : history.goBack(),
          },
          {
            label: "Zapisz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            onClick: handleSubmit(onSubmit, onError),
            disabled: isLoading,
            isLoading: isLoading,
            type: "submit",
            formId: "editInternalAccount",
          },
        ]}
      />
      <Content>
        <form id="editInternalAccount" onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            {INTERNAL_ACCOUNTS_FIELDS.map((group) => [
              group.label && (
                <Cell key={group.id + `-group`} span={12}>
                  <LabelMedium marginBottom="scale200" marginTop="scale600">
                    {group.label}
                  </LabelMedium>
                  <hr
                    className={css({
                      borderWidth: "0px",
                      height: "1px",
                      backgroundColor: "#eee",
                    })}
                  />
                </Cell>
              ),
              group.fields
                .filter((f) => f.edit.visible)
                .map((item, index) => (
                  <Cell span={item.span || 6} key={group.id + `-field` + index}>
                    <FormControl
                      label={item.label}
                      required={item.edit.required}
                      caption={item.caption}
                      error={
                        ((errors as any)[item.id] &&
                          (errors as any)[item.id].message) ||
                        (mutationError &&
                          mutationError.graphQLErrors[0].extensions?.code ===
                            "INPUT_VALIDATION_ERROR" &&
                          mutationError.graphQLErrors[0].extensions?.validationErrors
                            ?.find(
                              (vE: InputValidationError) =>
                                vE?.property === item.id
                            )
                            ?.errors.map((message: string) => (
                              <div
                                key="error"
                                className={css({
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                })}
                              >
                                {message}
                                <span
                                  className={css({
                                    color: "#999",
                                    marginLeft: "auto",
                                    marginRight: "5px",
                                  })}
                                >
                                  Walidacja serwera
                                </span>
                                <AlertOctagon color="#999" size={12} />
                              </div>
                            ))[0])
                      }
                      disabled={isLoading}
                    >
                      {isFetching ? (
                        <Skeleton
                          rows={0}
                          height="36px"
                          width="100%"
                          animation
                        />
                      ) : item.type === FIELDS.CurrencySelect ? (
                        <ControlledCurrencySelect
                          control={control}
                          id={item.id}
                          name={item.id}
                          placeholder="Wybierz"
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.FundingSourceSelect ? (
                        <ControlledFundingSourceSelect
                          control={control}
                          id={item.id}
                          name={item.id}
                          placeholder="Wybierz"
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : (
                        <ControlledInput
                          control={control}
                          name={item.id}
                          id={item.id}
                          placeholder={item.placeholder}
                          endEnhancer={
                            (item.id === "limit" || item.id === "balance") &&
                            watchCurrency?.length
                              ? watchCurrency[0]?.code
                              : item.endEnhancer
                          }
                          {...(item.type === FIELDS.NumberInput && {
                            type: "number",
                            min: 0,
                          })}
                          {...(item.edit.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      )}
                    </FormControl>
                  </Cell>
                )),
            ])}
            <ConfirmDialog
              isOpen={isCancelConfirmDialogOpen}
              label="Anulowanie edycji konta wewnętrznego"
              close={() => setIsCancelConfirmDialogOpen(false)}
              confirm={() => history.goBack()}
            />
          </Grid>
        </form>
      </Content>
    </article>
  );
}
