import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import { AlertOctagon, Download, FileOff, MailFast } from "tabler-icons-react";

import { InputValidationError } from "../../../api";
import Button from "../../../components/button";
import Cell from "../../../components/cell";
import { ControlledCheckbox } from "../../../components/checkbox";
import ConfirmDialog, {
  ConfirmDialogType,
} from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import { ControlledDatePicker } from "../../../components/date-picker";
import DocumentBarcode from "../../../components/document-barcode";
import { ControlledFilesPicker } from "../../../components/files-picker";
import FormControl from "../../../components/form-control";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import GusDownloadModal from "../../../components/gus-download-modal";
import Header from "../../../components/header";
import { ControlledInput } from "../../../components/input";
import { ControlledRadioCheckbox } from "../../../components/radio-checkbox";
import {
  ControlledAddressesSelect,
  ControlledCasesSelect,
  ControlledDictionaryValuesSelect,
  ControlledDocumentsSelect,
  ControlledDocumentTypesSelect,
  ControlledOrganizationalUnitsSelect,
  ControlledParcelSizesSelect,
  ControlledShipmentRatesSelect,
  ControlledUsersSelect,
  ParcelSize,
} from "../../../components/select";
import { ControlledTextArea } from "../../../components/text-area";
import Tooltip from "../../../components/tooltip";
import { HIDDEN_ADDRESS, PARCEL_SIZES } from "../../../constants";
import { useAssignment } from "../../../contexts/assignment-context";
import { useDictionaries } from "../../../contexts/dictionaries-context";
import { useLoading } from "../../../contexts/loading-context";
import { FIELDS } from "../../../fields.d";
import { checkPermission } from "../../../utils/check-permission";
import { formValidation } from "../../../utils/formValidation";
import { PERMISSIONS } from "../../../utils/permissions";
import { Address, DefaultAddress } from "../../Addresses/addresses.d";
import {
  ADDRESS_SUGGESTION,
  ADDRESS_SUGGESTIONS,
} from "../../Addresses/addresses.gql";
import { ShipmentRatesSelectPlaceholders } from "../../DocumentParcels/document-parcels.d";
import { AssignmentLocationState } from "../../Folders/folders.d";
import {
  DOCUMENTS_SHIP,
  DOCUMENTS_SHOW,
  SHOW_MATCHING_SHIPMENT_RATES,
} from "../documents.gql";
import { DOCUMENTS_SHIP_FIELDS, ShipFormInputs } from "../ship.form";

export default function DocumentsShip(): React.ReactElement {
  const [
    isAddressRevealConfirmDialogOpen,
    setIsAddressRevealConfirmDialogOpen,
  ] = useState(false);
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const [errorTimeStamp, setErrorTimeStamp] = useState<number>();
  const [css] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const [defaultAddress, setDefaultAddress] = useState<DefaultAddress>();
  const [addressFromGus, setAddressFromGus] = useState<Address>();
  const [isAnyAddressRevealed, setIsAnyAddressRevealed] = useState(true);
  const history = useHistory();
  const [isGusDownloadOpen, setIsGusDownloadOpen] = useState(false);
  const { isFetching, setIsFetching, isLoading, setIsLoading } = useLoading();
  const { id } = useParams<{ id: string }>();
  const documentId = parseInt(id);
  const [
    shipmentRatesSelectPlaceholder,
    setShipmentRatesSelectPlaceholder,
  ] = useState("");

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    setValue,
    register,
    unregister,
    watch,
  } = useForm<ShipFormInputs>({
    defaultValues: {
      shippingProvider: "none",
    },
  });

  const watchAddress = watch("recipient");
  const watchNip = watch("addressNip");
  const watchIsElectronical = watch("isElectronical");
  const watchShippingProvider = watch("shippingProvider");
  const watchParcelWidth = watch("width");
  const watchParcelHeight = watch("height");
  const watchParcelLength = watch("length");
  const watchParcelWeight = watch("weight");
  const watchParcelSize = watch("parcelSizes");
  const watchCountryCode = watch("addressCountryCode");

  const { assignment, setAssignment, setIsAssignmentActive } = useAssignment();

  const { state, pathname } = useLocation<AssignmentLocationState>();

  const [shipDocument, { error: mutationError }] = useMutation(DOCUMENTS_SHIP, {
    ...((state?.assignmentId || assignment?.id) &&
      state?.assignmentId !== null && {
        context: {
          headers: {
            Assignment: state?.assignmentId || assignment?.id,
          },
        },
      }),
  });

  const { data, error: queryError } = useQuery(DOCUMENTS_SHOW, {
    variables: { id: parseInt(id) },
    ...((state?.assignmentId || assignment?.id) &&
      state?.assignmentId !== null && {
        context: {
          headers: {
            Assignment: state?.assignmentId || assignment?.id,
          },
        },
      }),
  });

  const { findValue } = useDictionaries();

  const {
    data: shipmentRatesData,
    loading: shipmentRatesLoading,
    refetch: shipmentRatesRefetch,
  } = useQuery(SHOW_MATCHING_SHIPMENT_RATES, {
    variables: {
      width: parseInt(watchParcelWidth?.toString()),
      height: parseInt(watchParcelHeight?.toString()),
      length: parseInt(watchParcelLength?.toString()),
      weight: parseInt(watchParcelWeight?.toString()),
      isCountryService: watchCountryCode && watchCountryCode[0]?.id === "PL",
      countryCode: watchCountryCode && watchCountryCode[0]?.id,
    },
    onError: () => {
      setValue("shipmentRates", undefined);
    },
  });

  const { refetch: fetchAddress } = useQuery(ADDRESS_SUGGESTIONS, {
    skip: true,
  });

  const onError = (errors: Record<string, unknown>, e: any) =>
    setErrorTimeStamp(e.timeStamp);

  const onSubmit = async (shipFormValues: ShipFormInputs): Promise<void> => {
    setIsLoading(true);

    const enteredAddress = {
      addressName: shipFormValues.addressName,
      addressNip: shipFormValues.addressNip,
      addressStreetName: shipFormValues.addressStreetName,
      addressBuildingNumber: shipFormValues.addressBuildingNumber,
      addressApartmentNumber: shipFormValues.addressApartmentNumber,
      addressMobileNumber: shipFormValues.addressMobileNumber,
      addressCity: shipFormValues.addressCity,
      addressCountryCode: shipFormValues.addressCountryCode,
      addressPostalCode: shipFormValues.addressPostalCode,
      addressPhoneNumber: shipFormValues.addressPhoneNumber,
      addressEmail: shipFormValues.addressEmail,
      addressIsSensitive: shipFormValues.addressIsSensitive,
    };

    const shouldSendAddress =
      JSON.stringify(enteredAddress) !== JSON.stringify(defaultAddress);

    try {
      await shipDocument({
        variables: {
          documentShipInput: {
            documentId: parseInt(id),
            recipientId: watchAddress && watchAddress[0]?.id,
            recipientType: "Address",
            ...(shipFormValues.isElectronical === "false" && {
              shippingProvider:
                shipFormValues.shippingProvider === "true"
                  ? "PocztaPolska"
                  : "Courier",
            }),
            isElectronical: shipFormValues.isElectronical === "true",
            ...(shipFormValues.isElectronical === "false" &&
              shipFormValues.shippingProvider === "true" && {
                documentParcelCreateInput: {
                  documentId: parseInt(id),
                  width: parseInt(shipFormValues.width?.toString()),
                  height: parseInt(shipFormValues.height?.toString()),
                  length: parseInt(shipFormValues.length?.toString()),
                  weight: parseInt(shipFormValues.weight?.toString()),
                  isCountryService:
                    shipFormValues.addressCountryCode &&
                    shipFormValues.addressCountryCode[0]?.id === "PL",
                  countryCode:
                    shipFormValues.addressCountryCode &&
                    shipFormValues.addressCountryCode[0]?.id,
                  shipmentRateId:
                    shipFormValues.shipmentRates &&
                    shipFormValues.shipmentRates[0]?.id,
                },
              }),
          },
          ...(shouldSendAddress && {
            addressInput: {
              name: shipFormValues.addressName,
              nip:
                shipFormValues.addressNip &&
                shipFormValues.addressNip?.replaceAll("-", "").toString(),
              city: shipFormValues.addressCity,
              streetName: shipFormValues.addressStreetName,
              buildingNumber: shipFormValues.addressBuildingNumber,
              apartmentNumber: shipFormValues.addressApartmentNumber,
              mobileNumber: shipFormValues.addressMobileNumber,
              postalCode: shipFormValues.addressPostalCode,
              countryCode:
                shipFormValues.addressCountryCode &&
                shipFormValues.addressCountryCode[0]?.id,
              email: shipFormValues.addressEmail
                ? shipFormValues.addressEmail
                : null,
              phoneNumber: shipFormValues.addressPhoneNumber
                ? shipFormValues.addressPhoneNumber
                : null,
              overwrite: !!shipFormValues.addressOverwrite,
              isSensitive: !!shipFormValues.addressIsSensitive,
            },
          }),
        },
      });

      enqueueSnackbar({
        message: "Nadano pomyślnie",
        variant: "success",
      });

      history.push(pathname.replace("/ship", ""));
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => setIsFetching(true), []);

  useEffect(() => {
    if (data?.document?.currentAssignment) {
      setAssignment(data?.document?.currentAssignment);
      setIsAssignmentActive(true);
    }

    if (data) setIsFetching(false);
  }, [data]);

  useEffect(() => {
    PARCEL_SIZES.map((size: ParcelSize) => {
      if (watchParcelSize?.length && size.id === watchParcelSize[0]?.id) {
        setValue("width", size.width);
        setValue("height", size.height);
        setValue("length", size.length);
      }
    });
  }, [watchParcelSize]);

  useEffect(() => {
    setValue("shipmentRates", [
      { id: shipmentRatesData?.allShipmentRatesForPackageSize[0]?.id },
    ]);
  }, [shipmentRatesData]);

  useEffect(() => {
    shipmentRatesRefetch();
  }, [
    watchParcelWidth,
    watchParcelHeight,
    watchParcelLength,
    watchParcelWeight,
  ]);

  const handleShipmentRatesSelectPlaceholder = () => {
    const placeholders: string[] = [];
    !watchParcelWidth
      ? placeholders.push(ShipmentRatesSelectPlaceholders.width)
      : placeholders.filter(
          (placeholder: string) =>
            placeholder !== ShipmentRatesSelectPlaceholders.width
        );
    !watchParcelHeight
      ? placeholders.push(ShipmentRatesSelectPlaceholders.height)
      : placeholders.filter(
          (placeholder: string) =>
            placeholder !== ShipmentRatesSelectPlaceholders.height
        );
    !watchParcelLength
      ? placeholders.push(ShipmentRatesSelectPlaceholders.length)
      : placeholders.filter(
          (placeholder: string) =>
            placeholder !== ShipmentRatesSelectPlaceholders.length
        );
    !watchParcelWeight
      ? placeholders.push(ShipmentRatesSelectPlaceholders.weight)
      : placeholders.filter(
          (placeholder: string) =>
            placeholder !== ShipmentRatesSelectPlaceholders.weight
        );
    return setShipmentRatesSelectPlaceholder(
      placeholders.map((placeholder: string) => placeholder).join(", ")
    );
  };

  useEffect(() => {
    handleShipmentRatesSelectPlaceholder();
  }, [
    watchParcelWidth,
    watchParcelLength,
    watchParcelHeight,
    watchParcelWeight,
  ]);

  useEffect(() => {
    if (queryError?.graphQLErrors)
      enqueueSnackbar({
        message: (queryError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [queryError]);

  const { refetch: revealAddress, loading: revealAddressLoading } = useQuery(
    ADDRESS_SUGGESTION,
    {
      skip: true,
    }
  );

  const onAddressRevealSubmit = async () => {
    setIsLoading(true);

    try {
      const response = await revealAddress({
        id: watchAddress && watchAddress[0] && watchAddress[0].id,
      });

      const choosedAddress = response?.data?.addressSuggestion;
      if (choosedAddress?.name) setValue("addressName", choosedAddress?.name);
      if (choosedAddress?.name?.isSensitive)
        setValue("addressIsSensitive", choosedAddress?.name?.isSensitive);
      if (choosedAddress?.nip) setValue("addressNip", choosedAddress?.nip);
      if (choosedAddress?.streetName)
        setValue("addressStreetName", choosedAddress?.streetName);
      if (choosedAddress?.buildingNumber)
        setValue("addressBuildingNumber", choosedAddress?.buildingNumber);
      if (choosedAddress?.apartmentNumber)
        setValue("addressApartmentNumber", choosedAddress?.apartmentNumber);
      if (choosedAddress?.mobileNumber)
        setValue("addressMobileNumber", choosedAddress?.mobileNumber);
      if (choosedAddress?.city) setValue("addressCity", choosedAddress?.city);
      if (choosedAddress?.postalCode)
        setValue("addressPostalCode", choosedAddress?.postalCode);
      if (choosedAddress?.countryCode)
        setValue("addressCountryCode", [
          {
            id: choosedAddress?.countryCode,
          },
        ]);
      if (choosedAddress?.phoneNumber)
        setValue("addressPhoneNumber", choosedAddress?.phoneNumber);
      if (choosedAddress?.email)
        setValue("addressEmail", choosedAddress?.email);

      setIsAnyAddressRevealed(true);
      enqueueSnackbar({
        message: "Odkryto pomyślnie",
        variant: "success",
      });

      setIsAddressRevealConfirmDialogOpen(false);
    } catch (error: unknown) {
      setIsAddressRevealConfirmDialogOpen(false);
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setAddressFromGus(undefined);
    async function getAddressData() {
      setValue("addressName", "");
      setValue("addressNip", "");
      setValue("addressStreetName", "");
      setValue("addressBuildingNumber", "");
      setValue("addressApartmentNumber", "");
      setValue("addressMobileNumber", "");
      setValue("addressCity", "");
      setValue("addressPostalCode", "");
      setValue("addressPhoneNumber", "");
      setValue("addressEmail", "");
      setValue("addressIsSensitive", false);
      if (watchAddress && watchAddress[0] && watchAddress[0].id) {
        const response = await fetchAddress({
          filter: {
            id: {
              eq: watchAddress[0].id as number,
            },
          },
        });
        const choosedAddress = response?.data?.addressSuggestions?.nodes[0];
        if (choosedAddress?.name) setValue("addressName", choosedAddress?.name);
        if (watchAddress[0].typename === "HiddenAddress") {
          setValue("addressIsSensitive", true);
          setValue("addressCountryCode", []);
        }

        if (watchAddress[0].typename === "Address") {
          if (choosedAddress?.nip) setValue("addressNip", choosedAddress?.nip);
          if (choosedAddress?.streetName)
            setValue("addressStreetName", choosedAddress?.streetName);
          if (choosedAddress?.buildingNumber)
            setValue("addressBuildingNumber", choosedAddress?.buildingNumber);
          if (choosedAddress?.apartmentNumber)
            setValue("addressApartmentNumber", choosedAddress?.apartmentNumber);
          if (choosedAddress?.mobileNumber)
            setValue("addressMobileNumber", choosedAddress?.mobileNumber);
          if (choosedAddress?.city)
            setValue("addressCity", choosedAddress?.city);
          if (choosedAddress?.postalCode)
            setValue("addressPostalCode", choosedAddress?.postalCode);
          if (choosedAddress?.countryCode)
            setValue("addressCountryCode", [
              {
                id: choosedAddress?.countryCode,
              },
            ]);
          if (choosedAddress?.phoneNumber)
            setValue("addressPhoneNumber", choosedAddress?.phoneNumber);
          if (choosedAddress?.email)
            setValue("addressEmail", choosedAddress?.email);
        }

        setDefaultAddress({
          addressName: choosedAddress?.name || "",
          addressNip: choosedAddress?.nip || "",
          addressStreetName: choosedAddress?.streetName || "",
          addressBuildingNumber: choosedAddress?.buildingNumber || "",
          addressApartmentNumber: choosedAddress?.apartmentNumber || "",
          addressMobileNumber: choosedAddress?.mobileNumber || "",
          addressCity: choosedAddress?.city || "",
          addressCountryCode: choosedAddress?.countryCode
            ? [
                {
                  id: choosedAddress?.countryCode,
                },
              ]
            : [],
          addressPostalCode: choosedAddress?.postalCode || "",
          addressPhoneNumber: choosedAddress?.phoneNumber || "",
          addressEmail: choosedAddress?.email || "",
          addressIsSensitive: choosedAddress?.__typename === HIDDEN_ADDRESS,
        });
        unregister("addressName");
        unregister("addressStreetName");
        unregister("addressBuildingNumber");
        unregister("addressApartmentNumber");
        unregister("addressMobileNumber");
        unregister("addressPostalCode");
        unregister("addressCity");
        unregister("addressCountryCode");
      }
    }
    !addressFromGus && getAddressData();

    watchAddress?.[0]?.typename === "Address"
      ? setIsAnyAddressRevealed(true)
      : setIsAnyAddressRevealed(false);
  }, [watchAddress]);

  useEffect(() => {
    if (addressFromGus) setValue("recipient", undefined);
    if (addressFromGus?.nip) setValue("addressNip", addressFromGus?.nip);
    if (addressFromGus?.name) setValue("addressName", addressFromGus?.name);
    if (addressFromGus?.streetName)
      setValue("addressStreetName", addressFromGus?.streetName);
    if (addressFromGus?.buildingNumber)
      setValue("addressBuildingNumber", addressFromGus?.buildingNumber);
    if (addressFromGus?.apartmentNumber)
      setValue("addressApartmentNumber", addressFromGus?.apartmentNumber);
    if (addressFromGus?.mobileNumber)
      setValue("addressMobileNumber", addressFromGus?.mobileNumber);
    if (addressFromGus?.postalCode)
      setValue("addressPostalCode", addressFromGus?.postalCode);
    if (addressFromGus?.city) setValue("addressCity", addressFromGus?.city);
    if (addressFromGus?.countryCode)
      setValue("addressCountryCode", [{ id: addressFromGus?.countryCode }]);
    if (addressFromGus?.phoneNumber)
      setValue("addressPhoneNumber", addressFromGus?.phoneNumber);
    if (addressFromGus?.email) setValue("addressEmail", addressFromGus?.email);
  }, [addressFromGus]);

  useEffect(() => {
    if (watchIsElectronical === "true") {
      unregister("addressStreetName");
      unregister("addressBuildingNumber");
      unregister("addressApartmentNumber");
      unregister("addressMobileNumber");
      unregister("addressPostalCode");
      unregister("addressCity");
      unregister("addressCountryCode");

      register("addressEmail");
    } else if (watchIsElectronical === "false") {
      unregister("addressEmail");

      register("addressStreetName");
      register("addressBuildingNumber");
      register("addressApartmentNumber");
      register("addressMobileNumber");
      register("addressPostalCode");
      register("addressCity");
      register("addressCountryCode");
    }
  }, [watchIsElectronical]);

  useEffect(() => {
    if (watchShippingProvider === "true") {
      register("width");
      register("height");
      register("length");
      register("weight");
    } else if (watchShippingProvider === "false") {
      unregister("width");
      unregister("height");
      unregister("length");
      unregister("weight");
    }
  }, [watchShippingProvider]);

  if (
    data &&
    data?.case &&
    (!!data?.case?.closedAt || !checkPermission(PERMISSIONS.case.update))
  )
    return <Redirect to="/" />;

  return (
    <article>
      <Header
        title={data?.document?.internalNumber}
        labels={[
          <FormattedValue
            key="label_1"
            dataType={"model:cases"}
            data={data?.document?.case?.id}
            additionalData={data?.document?.case?.isConductedElectronically}
            showBlankWhenEmpty
          >
            {`${data?.document?.case?.number}`}
          </FormattedValue>,
          "Dokument",
        ]}
        goBackOption
        hasActiveAssignmentBar
        buttons={[
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              isDirty ? setIsCancelConfirmDialogOpen(true) : history.goBack(),
          },
          {
            label: "Nadaj",
            kind: KIND.primary,
            startEnhancer: <MailFast size={18} />,
            onClick: handleSubmit(onSubmit, onError),
            disabled: isLoading,
            isLoading: isLoading,
            type: "submit",
            formId: "shipDocument",
          },
        ]}
      >
        <DocumentBarcode
          documentId={documentId}
          internalNumber={data?.document?.internalNumber}
        />
      </Header>

      <Content>
        <form id="shipDocument" onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            <Cell span={4}>
              <FormControl label="Typ dokumentu" disabled={true}>
                {isFetching ? (
                  <Skeleton rows={0} height="30px" width="100%" animation />
                ) : (
                  <FormattedValue loadingIfUndefined>
                    {findValue(data?.document?.documentType) || "Plik"}
                  </FormattedValue>
                )}
              </FormControl>
            </Cell>
            <Cell span={4}>
              <FormControl label="Rodzaj dokumentu" disabled={true}>
                {isFetching ? (
                  <Skeleton rows={0} height="30px" width="100%" animation />
                ) : (
                  <FormattedValue
                    dataType="document-kind"
                    data={data?.document?.documentKind}
                  >
                    {data?.document?.documentKind}
                  </FormattedValue>
                )}
              </FormControl>
            </Cell>
            <Cell span={4}>
              <FormControl label="Jednostka organizacyjna" disabled={true}>
                {isFetching ? (
                  <Skeleton rows={0} height="30px" width="100%" animation />
                ) : (
                  <FormattedValue
                    dataType="model:organizational-units"
                    data={data?.document?.organizationalUnit?.id}
                  >
                    {data?.document?.organizationalUnit?.name}
                  </FormattedValue>
                )}
              </FormControl>
            </Cell>

            {DOCUMENTS_SHIP_FIELDS.filter(
              (g) => g.fields.filter((f) => f.create?.visible).length > 0
            )
              .filter(
                (f) =>
                  f.id !== "shipmentDetails" ||
                  (f.id === "shipmentDetails" &&
                    watchShippingProvider === "true")
              )
              .map((group) => [
                group.label && (
                  <Cell key={group.id + `-group`} span={12}>
                    <>
                      <Block
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        marginBottom="scale200"
                        marginTop="scale600"
                        height="20px"
                      >
                        <Block
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <LabelMedium>{group.label}</LabelMedium>
                          {watchAddress?.[0]?.typename === "HiddenAddress" &&
                            !isAnyAddressRevealed &&
                            group.id === "recipient" && (
                              <Button
                                size={SIZE.mini}
                                kind={KIND.secondary}
                                $style={{ marginLeft: "10px" }}
                                onClick={() =>
                                  setIsAddressRevealConfirmDialogOpen(true)
                                }
                                isLoading={revealAddressLoading}
                                type="button"
                              >
                                Pokaż dane
                              </Button>
                            )}
                        </Block>
                        {watchAddress?.[0]?.typename === "HiddenAddress" &&
                          !isAnyAddressRevealed &&
                          group.id === "recipient" && (
                            <FormattedValue $style={{ fontSize: "11px" }}>
                              Dane osobowe - podgląd zablokowany
                            </FormattedValue>
                          )}
                      </Block>
                      <hr
                        className={css({
                          borderWidth: "0px",
                          height: "1px",
                          backgroundColor: "#eee",
                        })}
                      />
                    </>
                  </Cell>
                ),
                group.fields
                  .filter((f) => f.create?.visible)
                  .filter(
                    (f) =>
                      f.id !== "shippingProvider" ||
                      (f.id === "shippingProvider" &&
                        watchIsElectronical === "false")
                  )
                  .map((item, index) => (
                    <Cell
                      span={item.span || 6}
                      skip={item.skip || 0}
                      key={group.id + `-field` + index}
                    >
                      <FormControl
                        label={item.type === FIELDS.Checkbox ? "" : item.label}
                        caption={item.caption}
                        required={
                          (item.info === "notElectronicalRequired" &&
                            watchIsElectronical === "false") ||
                          (item.info === "electronicalRequired" &&
                            watchIsElectronical === "true") ||
                          item.create.required
                        }
                        error={
                          ((errors as any)[item.id] &&
                            (errors as any)[item.id].message) ||
                          (mutationError &&
                            mutationError.graphQLErrors[0]?.extensions?.code ===
                              "INPUT_VALIDATION_ERROR" &&
                            mutationError.graphQLErrors[0]?.extensions?.validationErrors
                              ?.find((vE: InputValidationError) =>
                                item.id.includes("address")
                                  ? vE?.property ===
                                    item.id.slice(7).toLowerCase()
                                  : vE?.property === item.id
                              )
                              ?.errors.map((message: string) => (
                                <div
                                  key="error"
                                  className={css({
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  })}
                                >
                                  {message}
                                  <AlertOctagon color="#999" size={12} />
                                </div>
                              ))[0])
                        }
                        disabled={isLoading}
                      >
                        {item.type === FIELDS.AddressesSelect ? (
                          <ControlledAddressesSelect
                            control={control}
                            id={item.id}
                            name={item.id}
                            placeholder={item.placeholder}
                            {...(item.create.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                          />
                        ) : item.type === FIELDS.UsersSelect ? (
                          <ControlledUsersSelect
                            control={control}
                            id={item.id}
                            name={item.id}
                            placeholder="Wybierz"
                            {...(item.create.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                          />
                        ) : item.type === FIELDS.CasesSelect ? (
                          <ControlledCasesSelect
                            control={control}
                            id={item.id}
                            name={item.id}
                            placeholder="Wybierz"
                            {...(item.create.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                          />
                        ) : item.type === FIELDS.OrganizationalUnitsSelect ? (
                          <ControlledOrganizationalUnitsSelect
                            control={control}
                            id={item.id}
                            name={item.id}
                            placeholder="Wybierz"
                            {...(item.create.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                          />
                        ) : item.type === FIELDS.DocumentsSelect ? (
                          <ControlledDocumentsSelect
                            control={control}
                            id={item.id}
                            name={item.id}
                            placeholder="Wybierz"
                            {...(item.create.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                          />
                        ) : item.type === FIELDS.DictionaryValuesSelect ? (
                          <ControlledDictionaryValuesSelect
                            dictionarySystemName={
                              item.id === "addressCountryCode"
                                ? "countryCodes.alpha2.pl"
                                : item.id === "documentType"
                                ? "documentTypes.incoming"
                                : undefined
                            }
                            control={control}
                            name={item.id}
                            id={item.id}
                            disabled={
                              group.id === "recipient" &&
                              !isAnyAddressRevealed &&
                              !!watchAddress?.length
                            }
                            placeholder={"Wybierz"}
                            {...((item.create.required ||
                              (item.info === "electronicalRequired" &&
                                watchIsElectronical === "true") ||
                              (item.info === "notElectronicalRequired" &&
                                watchIsElectronical === "false")) && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                          />
                        ) : item.type === FIELDS.DocumentTypesSelect ? (
                          <ControlledDocumentTypesSelect
                            documentKind={"Incoming"}
                            control={control}
                            name={item.id}
                            id={item.id}
                            placeholder={"Wybierz"}
                            {...(item.create.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                          />
                        ) : item.type === FIELDS.RadioCheckbox ? (
                          <ControlledRadioCheckbox
                            control={control}
                            name={item.id}
                            {...(item.create.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                            {...(item.id === "isElectronical" && {
                              trueText: "Elektroniczna",
                              falseText: "Papierowa",
                            })}
                            {...(item.id === "shippingProvider" && {
                              trueText: "Poczta Polska",
                              falseText: "Kurier",
                            })}
                          />
                        ) : item.type === FIELDS.DatePicker ? (
                          <div
                            className={css({
                              position: "relative",
                            })}
                          >
                            <ControlledDatePicker
                              name={item.id}
                              control={control}
                              {...(item.create.required && {
                                rules: {
                                  required: formValidation.messages.required,
                                },
                              })}
                            />
                          </div>
                        ) : item.type === FIELDS.FilesPicker ? (
                          <ControlledFilesPicker
                            control={control}
                            name={item.id}
                            maxSize={134217728}
                            {...(item.create.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                          />
                        ) : item.type === FIELDS.Checkbox ? (
                          <div className={css({ marginTop: "40px" })}>
                            {item.id === "addressOverwrite" ? (
                              <Tooltip
                                placement="bottomLeft"
                                content={
                                  <div>
                                    Zaznaczenie tej opcji spowoduje aktualizację
                                    danych <br /> wybranego adresata w bazie,
                                    ale nie wpłynie to na <br /> wcześniejsze
                                    dokumenty powiązane z tym adresatem.
                                  </div>
                                }
                              >
                                <span>
                                  <ControlledCheckbox
                                    control={control}
                                    name={item.id}
                                    disabled={
                                      group.id === "recipient" &&
                                      !isAnyAddressRevealed &&
                                      !!watchAddress?.length
                                    }
                                    {...(item.create.required && {
                                      rules: {
                                        required:
                                          formValidation.messages.required,
                                      },
                                    })}
                                  >
                                    {item.label}
                                  </ControlledCheckbox>
                                </span>
                              </Tooltip>
                            ) : (
                              <ControlledCheckbox
                                control={control}
                                name={item.id}
                                disabled={
                                  group.id === "recipient" &&
                                  !isAnyAddressRevealed &&
                                  !!watchAddress?.length
                                }
                                {...(item.create.required && {
                                  rules: {
                                    required: formValidation.messages.required,
                                  },
                                })}
                              >
                                {item.label}
                              </ControlledCheckbox>
                            )}
                          </div>
                        ) : item.type === FIELDS.TextArea ? (
                          <ControlledTextArea
                            control={control}
                            name={item.id}
                            {...(item.create.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                          />
                        ) : item.type === FIELDS.ShipmentRatesSelect ? (
                          <ControlledShipmentRatesSelect
                            control={control}
                            name={item.id}
                            id={item.id}
                            isLoading={shipmentRatesLoading}
                            data={
                              shipmentRatesData &&
                              shipmentRatesData?.allShipmentRatesForPackageSize
                            }
                            placeholder={
                              shipmentRatesSelectPlaceholder
                                ? `Nie podano ${shipmentRatesSelectPlaceholder}`
                                : !shipmentRatesData
                                ? "Brak rekordów"
                                : ""
                            }
                            {...(item.create.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                          />
                        ) : item.type === FIELDS.DictionaryValuesSelect ? (
                          <ControlledDictionaryValuesSelect
                            dictionarySystemName={"countryCodes.alpha2.pl"}
                            control={control}
                            name={item.id}
                            id={item.id}
                            placeholder="Wybierz"
                            {...(item.create.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                          />
                        ) : item.type === FIELDS.ParcelSizesSelect ? (
                          <ControlledParcelSizesSelect
                            control={control}
                            name={item.id}
                            id={item.id}
                            placeholder={item.placeholder}
                            {...(item.create.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                          />
                        ) : (
                          <ControlledInput
                            control={control}
                            name={item.id}
                            id={item.id}
                            placeholder={
                              (group.id === "recipient" &&
                                !isAnyAddressRevealed &&
                                !!watchAddress?.length &&
                                "********") ||
                              item.placeholder
                            }
                            disabled={
                              group.id === "recipient" &&
                              !isAnyAddressRevealed &&
                              !!watchAddress?.length
                            }
                            rules={{
                              ...(((item.info === "notElectronicalRequired" &&
                                watchIsElectronical === "false") ||
                                (item.info === "electronicalRequired" &&
                                  watchIsElectronical === "true") ||
                                item.create.required) && {
                                required: formValidation.messages.required,
                              }),
                              ...(item.id === "addressEmail" && {
                                pattern: {
                                  value: formValidation.patterns.email,
                                  message:
                                    formValidation.messages.incorrectEmail,
                                },
                              }),
                            }}
                            masked={
                              item.id === "addressNip"
                                ? watchCountryCode?.[0]?.id === "PL"
                                  ? item.mask !== undefined
                                  : false
                                : item.mask !== undefined
                            }
                            mask={item.mask}
                            maskChar="_"
                            endEnhancer={
                              item.id === "addressNip" ? (
                                <Tooltip content="Pobierz z GUS">
                                  <span
                                    onClick={() => setIsGusDownloadOpen(true)}
                                    className={css({ cursor: "pointer" })}
                                  >
                                    <Download
                                      size={16}
                                      className={css({
                                        verticalAlign: "middle",
                                        marginLeft: "6px",
                                        display: "inline",
                                      })}
                                    />
                                  </span>
                                </Tooltip>
                              ) : (
                                item.endEnhancer
                              )
                            }
                          />
                        )}
                      </FormControl>
                    </Cell>
                  )),
              ])}

            <ConfirmDialog
              isOpen={isCancelConfirmDialogOpen}
              label="Anulowanie nadania listu"
              close={() => setIsCancelConfirmDialogOpen(false)}
              confirm={() => history.goBack()}
            />
            <ConfirmDialog
              isOpen={isAddressRevealConfirmDialogOpen}
              type={ConfirmDialogType.AddressReveal}
              close={() => setIsAddressRevealConfirmDialogOpen(false)}
              confirm={() => onAddressRevealSubmit()}
            />
            <GusDownloadModal
              isOpen={isGusDownloadOpen}
              close={() => setIsGusDownloadOpen(false)}
              confirm={() => null}
              currentNip={watchNip}
              setAddressFromGus={setAddressFromGus}
            />
          </Grid>
        </form>
      </Content>
    </article>
  );
}
