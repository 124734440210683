import { gql } from "@apollo/client";

export const MY_CASES_INDEX = gql`
  query(
    $pageSize: Int
    $offset: Int
    $sorting: [CaseSort!]
    $filter: CaseFilter
  ) {
    myCases(
      paging: { limit: $pageSize, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        name
        number
        isConductedElectronically
        folderNumber
        jrwaClassificationName
        user {
          id
          firstName
          lastName
        }
        supervisor {
          id
          firstName
          lastName
        }
        initiatedAt
        jrwaClassification {
          id
          symbol
          isCaseConductedElectronically
        }
        organizationalUnit {
          id
          symbol
          budgetSymbol
        }
        closedAt
      }
    }
  }
`;

export const CASES_INDEX = gql`
  query(
    $pageSize: Int
    $offset: Int
    $sorting: [CaseSort!]
    $filter: CaseFilter
  ) {
    cases(
      paging: { limit: $pageSize, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        name
        number
        isConductedElectronically
        folderNumber
        jrwaClassificationName
        isAccessable
        user {
          id
          firstName
          lastName
        }
        supervisor {
          id
          firstName
          lastName
        }
        initiatedAt
        jrwaClassification {
          id
          symbol
          isCaseConductedElectronically
        }
        organizationalUnit {
          id
          symbol
          budgetSymbol
        }
        closedAt
      }
    }
  }
`;

export const MY_CASES_SELECT_INDEX = gql`
  query($offset: Int, $filter: CaseFilter) {
    myCases(
      paging: { limit: 5, offset: $offset }
      sorting: { field: id, direction: DESC }
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        name
        number
        folderNumber
        jrwaClassificationName
        isConductedElectronically
        user {
          id
          firstName
          lastName
        }
        supervisor {
          id
          firstName
          lastName
        }
        initiatedAt
        jrwaClassification {
          id
          symbol
          isCaseConductedElectronically
        }
        organizationalUnit {
          id
          symbol
          budgetSymbol
        }
        closedAt
      }
    }
  }
`;

export const CASES_SELECT_INDEX = gql`
  query($offset: Int, $filter: CaseFilter) {
    cases(
      paging: { limit: 5, offset: $offset }
      sorting: { field: id, direction: DESC }
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        name
        number
        folderNumber
        jrwaClassificationName
        isConductedElectronically
        user {
          id
          firstName
          lastName
        }
        supervisor {
          id
          firstName
          lastName
        }
        initiatedAt
        jrwaClassification {
          id
          symbol
          isCaseConductedElectronically
        }
        organizationalUnit {
          id
          symbol
          budgetSymbol
        }
        closedAt
      }
    }
  }
`;

export const CASES_SHOW = gql`
  query($id: Int!) {
    case(id: $id) {
      id
      number
      folderNumber
      jrwaClassificationArchivalCategory
      jrwaClassificationName
      projectNumber
      name
      isEditable
      isShareable
      isDispatchable
      coordinatorName
      organizationalUnitGroupName
      isConductedElectronically
      organizationalUnitGroupNumber
      beneficiaryAddressId
      beneficiaryAddress {
        __typename
        ... on Address {
          id
          name
          city
          nip
          address
          streetName
          buildingNumber
          apartmentNumber
          mobileNumber
          postalCode
          countryCode
          phoneNumber
          email
          city
          isHidden
          isSensitive
          createdAt
        }
        ... on HiddenAddress {
          id
          name
        }
      }
      initiatedAt
      closedAt
      closedBy {
        firstName
        lastName
        id
      }
      isDeletable
      organizationalUnitId
      organizationalUnit {
        id
        name
        symbol
        budgetSymbol
      }
      jrwaClassificationId
      jrwaClassification {
        id
        name
        symbol
        isBeneficiaryAddressRequired
        isCaseConductedElectronically
      }
      documents {
        id
        documentNumber
        internalNumber
        documentKind
        documentType
        type
        documentDate
        createdAt
        postStampAt
        isEditable
        title
        name
        description
        recipient {
          __typename
          ... on User {
            id
            firstName
            lastName
          }
          ... on Address {
            id
            name
          }
          ... on HiddenAddress {
            id
            name
          }
        }
        sender {
          __typename
          ... on User {
            id
            firstName
            lastName
          }
          ... on Address {
            id
            name
          }
          ... on HiddenAddress {
            id
            name
          }
        }
        case {
          id
          name
          number
          isConductedElectronically
        }
        organizationalUnit {
          id
          name
          symbol
        }
        currentStatus {
          documentFlowStepId
          documentFlowStep {
            id
            name
            documentFlow {
              steps {
                id
              }
            }
          }
          createdAt
        }
      }
      user {
        id
        firstName
        lastName
      }
      supervisorId
      supervisor {
        id
        firstName
        lastName
      }
      currentAssignment {
        id
        type
        createdAt
        status
        assignerComment
        assigner {
          id
          firstName
          lastName
        }
        substituter {
          id
          firstName
          lastName
        }
      }
      activityLogs {
        id
        activity
        description
        user {
          firstName
          lastName
          id
        }
        substituter {
          id
          firstName
          lastName
        }
        properties
        createdAt
        userOrganizationalUnits
        assignmentUsers
      }
    }
  }
`;

export const CASES_CREATE = gql`
  mutation($caseCreateInput: CaseCreateInput!, $addressInput: AddressInput) {
    caseCreate(caseCreateInput: $caseCreateInput, addressInput: $addressInput) {
      id
      name
    }
  }
`;

export const CASES_UPDATE = gql`
  mutation($caseUpdateInput: CaseUpdateInput!, $addressInput: AddressInput) {
    caseUpdate(caseUpdateInput: $caseUpdateInput, addressInput: $addressInput) {
      id
      name
    }
  }
`;

export const CASES_DELETE = gql`
  mutation($id: Int!) {
    caseDelete(caseDeleteInput: { id: $id }) {
      name
    }
  }
`;

export const CASES_CLOSE = gql`
  mutation($caseCloseInput: CaseCloseInput!) {
    caseClose(caseCloseInput: $caseCloseInput) {
      id
      name
    }
  }
`;

export const BENEFICIARY_ADDRESS_REVEAL = gql`
  mutation(
    $caseBeneficiaryAddressRevealInput: CaseBeneficiaryAddressRevealInput!
  ) {
    caseBeneficiaryAddressReveal(
      caseBeneficiaryAddressRevealInput: $caseBeneficiaryAddressRevealInput
    ) {
      id
    }
  }
`;

export const CASES_FOLDER_XLSX_EXPORT = gql`
  mutation($caseFolderXlsxExportInput: CaseFolderXlsxExportInput!) {
    caseFolderXlsxExport(caseFolderXlsxExportInput: $caseFolderXlsxExportInput)
  }
`;

export const CASES_ZIP_EXPORT = gql`
  mutation($caseZipExportInput: CaseZipExportInput!) {
    caseZipExport(caseZipExportInput: $caseZipExportInput)
  }
`;

export const ALL_CASES_XLSX_EXPORT = gql`
  mutation {
    casesExportAllToXlsx {
      downloadUrl
    }
  }
`;

export const CASES_XLSX_EXPORT = gql`
  mutation($filter: CaseFilter) {
    casesExportToXlsx(filter: $filter) {
      downloadUrl
    }
  }
`;

export const CASE_XLSX_EXPORT = gql`
  mutation($id: Int!) {
    caseExportToXlsx(id: $id) {
      downloadUrl
    }
  }
`;

export const CASE_SUPERVISOR_CHANGE = gql`
  mutation($caseSupervisorChangeInput: CaseSupervisorChangeInput!) {
    caseSupervisorChange(
      caseSupervisorChangeInput: $caseSupervisorChangeInput
    ) {
      id
    }
  }
`;

export const CASES_SHARE = gql`
  mutation($caseShareInput: CaseShareInput!) {
    caseShare(caseShareInput: $caseShareInput) {
      id
    }
  }
`;

export const CASES_UNSHARE = gql`
  mutation($caseUnshareInput: CaseUnshareInput!) {
    caseUnshare(caseUnshareInput: $caseUnshareInput) {
      id
    }
  }
`;

export const CASES_TRACK_OPEN = gql`
  mutation($id: Int!) {
    caseTrackOpen(id: $id)
  }
`;

export const CASES_DISPATCH = gql`
  mutation($caseDispatchInput: CaseDispatchInput!) {
    caseDispatch(caseDispatchInput: $caseDispatchInput) {
      id
    }
  }
`;
