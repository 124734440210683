import { ApolloError, useMutation } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND } from "baseui/button";
import { ModalBody, ModalFooter, ModalHeader } from "baseui/modal";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { AlertOctagon } from "tabler-icons-react";

import { InputValidationError } from "../api";
import Button from "../components/button";
import Modal from "../components/modal";
import {
  DOCUMENT_CANCEL,
  DOCUMENT_UNCANCEL,
} from "../containers/Documents/documents.gql";
import { useAssignment } from "../contexts/assignment-context";
import { useLoading } from "../contexts/loading-context";
import { formValidation } from "../utils/formValidation";
import FormControl from "./form-control";
import { ControlledTextArea } from "./text-area";

type DocumentCancelationModalProps = {
  action: "cancel" | "uncancel";
  documentId?: number;
  isOpen: boolean;
  refetch: () => void;
  close: () => void;
};

export default function DocumentCancelationModal({
  action,
  documentId,
  isOpen,
  refetch,
  close,
}: DocumentCancelationModalProps): React.ReactElement {
  const [css] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, setIsLoading } = useLoading();

  const { assignment, isAssignmentActive } = useAssignment();

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<{ reason: string }>();

  const [
    cancelDocument,
    { error: cancelError, loading: cancelLoading },
  ] = useMutation(DOCUMENT_CANCEL, {
    ...(isAssignmentActive &&
      assignment && {
        context: {
          headers: {
            Assignment: assignment.id,
          },
        },
      }),
  });
  const [
    uncancelDocument,
    { error: uncancelError, loading: uncancelLoading },
  ] = useMutation(DOCUMENT_UNCANCEL, {
    ...(isAssignmentActive &&
      assignment && {
        context: {
          headers: {
            Assignment: assignment.id,
          },
        },
      }),
  });

  const onSubmit = async ({ reason }: { reason: string }): Promise<void> => {
    setIsLoading(true);

    try {
      action === "cancel" &&
        (await cancelDocument({
          variables: {
            documentCancelInput: {
              id: documentId,
              reason,
            },
          },
        }));

      action === "uncancel" &&
        (await uncancelDocument({
          variables: {
            documentUncancelInput: {
              id: documentId,
              reason,
            },
          },
        }));

      enqueueSnackbar({
        message:
          action === "cancel"
            ? "Pomyślnie anulowano dokument"
            : "Cofnięto anulowanie dokumentu",
        variant: "success",
      });
      refetch();
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
      close && close();
    }
  };

  useEffect(() => {
    if (cancelError?.graphQLErrors)
      enqueueSnackbar({
        message: (cancelError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [cancelError]);

  useEffect(() => {
    if (uncancelError?.graphQLErrors)
      enqueueSnackbar({
        message: (uncancelError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [uncancelError]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        close && close();
        reset();
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>
          {action === "cancel"
            ? "Anulowanie dokumentu"
            : "Cofnięcie anulowania dokumentu"}
        </ModalHeader>
        <ModalBody>
          <Block>
            <FormControl
              label="Powód"
              required
              {...(action === "cancel" && {
                error:
                  ((errors as any)?.reason &&
                    (errors as any)?.reason?.message) ||
                  (cancelError &&
                    cancelError.graphQLErrors[0]?.extensions?.code ===
                      "INPUT_VALIDATION_ERROR" &&
                    cancelError.graphQLErrors[0]?.extensions?.validationErrors
                      ?.find(
                        (vE: InputValidationError) => vE?.property === "reason"
                      )
                      ?.errors.map((message: string) => (
                        <div
                          key="error"
                          className={css({
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          })}
                        >
                          {message}
                          <span
                            className={css({
                              color: "#999",
                              marginLeft: "auto",
                              marginRight: "5px",
                            })}
                          >
                            Walidacja serwera
                          </span>
                          <AlertOctagon color="#999" size={12} />
                        </div>
                      ))[0]),
              })}
              {...(action === "uncancel" && {
                error:
                  ((errors as any)?.reason &&
                    (errors as any)?.reason?.message) ||
                  (uncancelError &&
                    uncancelError.graphQLErrors[0]?.extensions?.code ===
                      "INPUT_VALIDATION_ERROR" &&
                    uncancelError.graphQLErrors[0]?.extensions?.validationErrors
                      ?.find(
                        (vE: InputValidationError) => vE?.property === "reason"
                      )
                      ?.errors.map((message: string) => (
                        <div
                          key="error"
                          className={css({
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          })}
                        >
                          {message}
                          <span
                            className={css({
                              color: "#999",
                              marginLeft: "auto",
                              marginRight: "5px",
                            })}
                          >
                            Walidacja serwera
                          </span>
                          <AlertOctagon color="#999" size={12} />
                        </div>
                      ))[0]),
              })}
              disabled={isLoading}
            >
              <ControlledTextArea
                control={control}
                name="reason"
                id="reason"
                rules={{
                  required: formValidation.messages.required,
                }}
              />
            </FormControl>
          </Block>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            kind={KIND.secondary}
            $style={{ marginRight: "10px" }}
            onClick={() => {
              close && close();
              reset();
            }}
            disabled={action === "cancel" ? cancelLoading : uncancelLoading}
          >
            Anuluj
          </Button>
          <Button
            disabled={action === "cancel" ? cancelLoading : uncancelLoading}
            isLoading={action === "cancel" ? cancelLoading : uncancelLoading}
            type="submit"
          >
            Zapisz
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
