import { useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import React, { useEffect } from "react";
import { Redirect, useParams } from "react-router-dom";

import Cell from "../../../components/cell";
import Content from "../../../components/content";
import FormControl from "../../../components/form-control";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import { useLoading } from "../../../contexts/loading-context";
import { checkPermission } from "../../../utils/check-permission";
import { PERMISSIONS } from "../../../utils/permissions";
import { BANK_ACCOUNTS_SHOW } from "../addresses.gql";
import { BANK_ACCOUNTS_FIELDS } from "../bank-accounts.form";

export default function AddressesBankAccountShow(): React.ReactElement {
  const [css] = useStyletron();
  const { bankAccountId } = useParams<{ bankAccountId?: string }>();
  const { isFetching, setIsFetching } = useLoading();

  const { refetch, data } = useQuery(BANK_ACCOUNTS_SHOW, {
    variables: { id: bankAccountId ? parseInt(bankAccountId) : null },
  });

  useEffect(() => {
    if (data?.bankAccount) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => data?.bankAccount && setIsFetching(false), [data]);

  if (!checkPermission(PERMISSIONS.bankAccount.read))
    return <Redirect to="/" />;

  return (
    <article>
      <Header
        title={data?.bankAccount?.payeeName}
        labels={["Adresaci", "Konta bankowe", "Wyświetlanie"]}
        goBackOption
      />
      <Content>
        <Grid>
          {BANK_ACCOUNTS_FIELDS.filter(
            (g) =>
              g.fields.filter((f) => f.show.visible).length > 0 &&
              ((g.accessor && data?.bankAccount[g.accessor]) || !g.accessor)
          ).map((group) => [
            group.label && (
              <Cell key={group.id + `-group`} span={12}>
                <LabelMedium marginBottom="scale200" marginTop="scale600">
                  {group.label}
                </LabelMedium>
                <hr
                  className={css({
                    borderWidth: "0px",
                    height: "1px",
                    backgroundColor: "#eee",
                  })}
                />
              </Cell>
            ),
            group.fields
              .filter((f) => f.show.visible)
              .map((item, index) => (
                <Cell span={item.span || 6} key={group.id + `-field` + index}>
                  <FormControl label={item.label} disabled={true}>
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : (
                      <FormattedValue
                        dataType={item.dataType}
                        data={data?.bankAccount[item.id]}
                        {...(item.id === "currency" && {
                          textWhenEmpty: "PLN",
                        })}
                      >
                        {item.show.accessor
                          ? data?.bankAccount[item.show.accessor[0]]?.[
                              item.show.accessor[1]
                            ]
                          : data?.bankAccount[item.id]}
                      </FormattedValue>
                    )}
                  </FormControl>
                </Cell>
              )),
          ])}
        </Grid>
      </Content>
    </article>
  );
}
