import {
  FormControl as BaseFormControl,
  FormControlProps,
} from "baseui/form-control";
import React from "react";

type Props = {
  required?: boolean;
  caption?: string;
} & FormControlProps;

const FormControl = ({
  required,
  caption,
  ...rest
}: Props): React.ReactElement => {
  return (
    <BaseFormControl
      {...rest}
      {...(required && { label: rest.label + " *" })}
      {...(caption && {
        caption: () => <div dangerouslySetInnerHTML={{ __html: caption }} />,
      })}
    />
  );
};

export default FormControl;
