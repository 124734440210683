import { Table } from "@tanstack/react-table";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import {
  StyledRoot,
  StyledTable,
  StyledTableBody,
  StyledTableBodyCell,
  StyledTableBodyRow,
  StyledTableHead,
  StyledTableHeadCell,
  StyledTableHeadRow,
} from "baseui/table-semantic";
import React, { ChangeEvent, memo, useRef } from "react";
import { StyleObject } from "styletron-react";
import { ListSearch } from "tabler-icons-react";

import TableColumnV8 from "./table-column-v8";
import TableHead from "./table-head";
import { MemoizedTableRowV8 } from "./table-row-v8";

type Props<T> = {
  $style?: StyleObject;
  $tableBodyRowStyle?: StyleObject;
  $footer?: () => React.ReactNode;
  scrollable?: boolean;
  id?: string;
  stickLastColumn?: boolean;
  editable?: boolean;
  table: Table<T>;
};

function highlightColumn(event: any) {
  const index = [...event.target.closest("thead > tr")?.children].indexOf(
    event.target.closest("th")
  );

  event.target
    .closest("[data-ui=table-root]")
    ?.querySelector("[data-baseweb=table-semantic] table")
    ?.querySelectorAll(`td:nth-child(${index + 1})`)
    ?.forEach((el: HTMLElement) => (el.style.backgroundColor = "#fbfbfb"));
}

function unhighlightColumn(event: any) {
  const index = [...event.target.closest("thead > tr")?.children].indexOf(
    event.target.closest("th")
  );

  event.target
    .closest("[data-ui=table-root]")
    ?.querySelector("[data-baseweb=table-semantic] table")
    ?.querySelectorAll(`td:nth-child(${index + 1})`)
    ?.forEach((el: HTMLElement) => (el.style.backgroundColor = ""));
}

export default function TableV8<T>({
  $style,
  $tableBodyRowStyle,
  $footer,
  id,
  stickLastColumn,
  editable,
  table,
}: Props<T>): React.ReactElement {
  const [css] = useStyletron();

  const tableRef = useRef<HTMLDivElement>(null);

  return (
    <div
      className={css({
        position: "relative",
        scrollSnapType: "x mandatory",
      })}
      data-ui="table-root"
    >
      <TableHead tableRef={tableRef} compact={true} />

      <StyledRoot
        ref={tableRef}
        data-ui="table"
        onScroll={(event: ChangeEvent<HTMLDivElement>) => {
          const tableRoot = event.target?.closest("[data-ui=table-root]");

          if (tableRoot) {
            const stickedTableHead = tableRoot?.querySelector(
              "[data-ui=sticked-table-head]"
            );

            if (stickedTableHead) {
              const scrolling =
                tableRoot.getAttribute("data-scrolling") === "true"
                  ? true
                  : false;

              if (scrolling) {
                tableRoot.setAttribute("data-scrolling", "false");
                return;
              }

              if (
                stickedTableHead.scrollLeft !==
                (event.target as HTMLElement).scrollLeft
              ) {
                tableRoot.setAttribute("data-scrolling", "true");
                stickedTableHead.scrollLeft = (event.target as HTMLElement).scrollLeft;
              }
            }
          }
        }}
      >
        <StyledTable
          id={id}
          className={css({
            maxWidth: "100%",
            overflowX: "auto",
            scrollSnapType: "x mandatory",
            ...$style,
            position: "relative",
            borderCollapse: "collapse",
            minWidth: "100%",
            boxSizing: "border-box",
          })}
        >
          <StyledTableHead>
            {table.getHeaderGroups().map((headerGroup) => (
              <StyledTableHeadRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <StyledTableHeadCell
                      key={header.id}
                      className={css({
                        verticalAlign: "middle",
                        zIndex: 12,
                        fontSize: "13.5px",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        whiteSpace: "nowrap",
                      })}
                      onMouseEnter={highlightColumn}
                      onMouseLeave={unhighlightColumn}
                    >
                      <TableColumnV8
                        column={header}
                        editable={editable}
                        tableRef={tableRef}
                      />
                    </StyledTableHeadCell>
                  );
                })}
              </StyledTableHeadRow>
            ))}
          </StyledTableHead>

          <StyledTableBody>
            {table.getRowModel().rows.length > 0 ? (
              table.getRowModel().rows.map((row) => {
                return (
                  <MemoizedTableRowV8
                    key={row.id}
                    row={row}
                    editable={editable}
                    $tableBodyRowStyle={$tableBodyRowStyle}
                    stickLastColumn={stickLastColumn}
                  />
                );
              })
            ) : (
              <StyledTableBodyRow>
                <StyledTableBodyCell colSpan={table.getAllColumns().length}>
                  <Block position="sticky" left="0">
                    <div
                      key="error"
                      className={css({
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#999999",
                        height: "50px",
                      })}
                    >
                      <ListSearch
                        color="#999999"
                        size={18}
                        className={css({ marginRight: "5px" })}
                      />
                      Brak rekordów
                    </div>
                  </Block>
                </StyledTableBodyCell>
              </StyledTableBodyRow>
            )}
          </StyledTableBody>

          {$footer && (
            <tfoot
              className={css({
                position: "relative",
                borderTopWidth: "1px",
                borderTopStyle: "solid",
                borderTopColor: "rgb(238, 238, 238)",
                backgroundColor: "rgb(252, 252, 252)",
              })}
            >
              {$footer()}
            </tfoot>
          )}
        </StyledTable>
      </StyledRoot>
    </div>
  );
}

export const MemoizedTableV8 = memo(TableV8);
