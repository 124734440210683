import { useStyletron } from "baseui";
import {
  Button as BaseButton,
  ButtonProps as BaseButtonProps,
} from "baseui/button";
import React, { ForwardedRef, HTMLProps } from "react";
import { forwardRef } from "react";

type ButtonProps = {
  children?: React.ReactChild | React.ReactChild[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  forwardedRef?: ForwardedRef<any>;
  formId?: string;
} & BaseButtonProps;

export default function Button({
  children,
  size,
  kind,
  type,
  forwardedRef,
  formId,
  ...props
}: ButtonProps): React.ReactElement {
  const [css, theme] = useStyletron();

  return (
    <BaseButton
      ref={forwardedRef}
      overrides={{
        BaseButton: {
          style: {
            position: "relative",
            borderTopLeftRadius: theme.borders.radius200,
            borderTopRightRadius: theme.borders.radius200,
            borderBottomRightRadius: theme.borders.radius200,
            borderBottomLeftRadius: theme.borders.radius200,
            minHeight:
              size === "mini" ? "unset" : size === "compact" ? "unset" : "42px",
            height:
              size === "mini" ? "unset" : size === "compact" ? "unset" : "42px",
            ...(kind === "tertiary" &&
              size === "mini" && {
                backgroundColor: "white",
                borderLeftWidth: "2px",
                borderLeftStyle: "solid",
                borderLeftColor: theme.colors.inputBorder,
                borderBottomWidth: "2px",
                borderBottomStyle: "solid",
                borderBottomColor: theme.colors.inputBorder,
                borderRightWidth: "2px",
                borderRightStyle: "solid",
                borderRightColor: theme.colors.inputBorder,
                borderTopWidth: "2px",
                borderTopStyle: "solid",
                borderTopColor: theme.colors.inputBorder,
              }),
            ...(size === "large" && { fontSize: "16px" }),
            ...props.$style,
          },
        },
        StartEnhancer: {
          style: {
            ...(!children && { marginRight: 0 }),
          },
        },
      }}
      kind={kind}
      size={size}
      type={type}
      {...(formId && {
        form: formId,
      })}
      {...props}
    >
      {Array.isArray(children) ? (
        children
      ) : (
        <span
          className={css({
            position: "relative",
            width: "fit-content",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            display: "contents",
          })}
        >
          {children}
        </span>
      )}
    </BaseButton>
  );
}

// eslint-disable-next-line react/display-name
export const ForwardedButton = forwardRef((
  {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    $as = "button",
    ...restProps
  }: ButtonProps & Omit<HTMLProps<typeof $as>, keyof ButtonProps>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: ForwardedRef<any>
) => <Button forwardedRef={ref} $as={$as} {...restProps} />);
