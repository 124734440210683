import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { StyledLink } from "baseui/link";
import { useSnackbar } from "notistack";
import React, { MouseEvent, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { Redirect, useLocation } from "react-router-dom";
import { Row } from "react-table";
import { useStyletron } from "styletron-react";
import { Check, Eye, Plus, X } from "tabler-icons-react";

import BottomPanel from "../../../components/bottom-panel";
import Button from "../../../components/button";
import Cell from "../../../components/cell";
import Checkbox from "../../../components/checkbox";
import Content from "../../../components/content";
import Filters from "../../../components/filters";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import NewFinancialPlanModal from "../../../components/new-financial-plan-modal";
import PagingControls from "../../../components/paging-controls";
import SortingTableHeader, {
  SortDirection,
} from "../../../components/sorting-table-header";
import Table from "../../../components/table";
import { useLoading } from "../../../contexts/loading-context";
import { usePaging } from "../../../contexts/paging-context";
import { FiltersState } from "../../../filters";
import { checkPermission } from "../../../utils/check-permission";
import { translateFiltersState } from "../../../utils/filters";
import { PERMISSIONS } from "../../../utils/permissions";
import { setSortingParams } from "../../../utils/sorting";
import { Document } from "../../Documents/documents";
import { FinancialPlan } from "../financial-plans";
import { FINANCIAL_PLANS_FILTERS } from "../financial-plans.filters";
import {
  FINANCIAL_PLANS_ACTIVATE,
  FINANCIAL_PLANS_DEACTIVATE,
  FINANCIAL_PLANS_INDEX,
} from "../financial-plans.gql";

enum FinancialPlansFieldName {
  IsActive = "isActive",
  Year = "year",
  Name = "name",
  CategoryName = "categoryName",
  BazaId = "bazaId",
  SynchronizedAt = "synchronizedAt",
  SynchronizedBy = "synchronizedBy",
  CreatedBy = "createdBy",
  CreatedAt = "createdAt",
}

export default function FinancialPlansIndex(): React.ReactElement {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [css] = useStyletron();
  const {
    isFetching,
    setIsFetching,
    isPartialFetching,
    setIsPartialFetching,
    setIsLoading,
  } = useLoading();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { pageSize, currentPage, setTotalCount } = usePaging();

  const { search } = useLocation();

  const params = useMemo(() => new URLSearchParams(search), [search]);

  const [sortBy, setSortBy] = useState<FinancialPlansFieldName | null>(
    (params.get("sortBy") as FinancialPlansFieldName) ||
      FinancialPlansFieldName.Year
  );

  const [sortDirection, setSortDirection] = useState<SortDirection | null>(
    (params.get("sortDirection") as SortDirection) || SortDirection.DESC
  );

  const [filters, setFilters] = useState<FiltersState>();

  const handleSorting = (column: FinancialPlansFieldName) => {
    setSortingParams(history, search, column, sortDirection);
    setSortBy(column);
    setSortDirection(
      sortDirection === null
        ? SortDirection.DESC
        : sortDirection === SortDirection.ASC
        ? SortDirection.DESC
        : SortDirection.ASC
    );
  };

  const { data, error, loading, refetch } = useQuery(FINANCIAL_PLANS_INDEX, {
    variables: {
      pageSize,
      offset: (currentPage - 1) * pageSize,
      sorting: {
        field: sortBy,
        direction: sortDirection,
      },
      ...(filters && { filter: translateFiltersState(filters) }),
    },
  });

  useEffect(() => {
    if (data?.financialPlans) setTimeout(() => refetch(), 200);
    setIsFetching(true);
  }, []);

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    setTimeout(() => refetch(), 200);
    setIsPartialFetching(true);
  }, [currentPage, pageSize]);

  useEffect(() => {
    setTimeout(() => refetch(), 200);
    setIsPartialFetching(true);
  }, [sortBy, sortDirection]);

  useEffect(() => {
    if (data?.financialPlans) setIsFetching(false);
    if (data?.financialPlans?.totalCount >= 0)
      setTotalCount(data?.financialPlans?.totalCount);
  }, [data]);

  const [selectedIds, setSelectedIds] = useState<Array<number>>([]);

  const [
    activatePlan,
    { error: activateError, loading: activateLoading },
  ] = useMutation(FINANCIAL_PLANS_ACTIVATE);

  const [
    deactivatePlan,
    { error: deactivateError, loading: deactivateLoading },
  ] = useMutation(FINANCIAL_PLANS_DEACTIVATE);

  const changePlanStatus = async (action: "activate" | "deactivate") => {
    setIsLoading(true);

    try {
      action === "activate" &&
        (await activatePlan({
          variables: {
            ids: selectedIds,
          },
        }));

      action === "deactivate" &&
        (await deactivatePlan({
          variables: {
            ids: selectedIds,
          },
        }));

      refetch();
      setSelectedIds([]);

      enqueueSnackbar({
        message: `${
          action === "activate" ? "Aktywowano" : "Dezaktywowano"
        } pomyślnie`,
        variant: "success",
      });
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (activateError?.graphQLErrors)
      enqueueSnackbar({
        message: (activateError as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [activateError]);

  useEffect(() => {
    if (deactivateError?.graphQLErrors)
      enqueueSnackbar({
        message: (deactivateError as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [deactivateError]);

  const columns = React.useMemo(
    () => [
      {
        Header: () => (
          <Block
            width="20px"
            marginLeft="8px"
            display="flex"
            justifyContent="center"
          >
            <Checkbox
              disabled={activateLoading || deactivateLoading}
              isIndeterminate={
                selectedIds?.length > 0 &&
                selectedIds?.length < data?.financialPlans?.totalCount
              }
              checked={
                data?.financialPlans?.totalCount > 0 &&
                selectedIds?.length === data?.financialPlans?.totalCount
              }
              onChange={() => {
                if (selectedIds?.length !== data?.financialPlans?.totalCount) {
                  setSelectedIds(
                    data?.financialPlans?.nodes?.map(
                      (financialPlan: FinancialPlan) => financialPlan?.id
                    )
                  );
                } else {
                  setSelectedIds([]);
                }
              }}
            />
          </Block>
        ),
        id: "checkbox",
        accessor: "checked",
        Cell: ({ row }: { row: Row<Document> }) => (
          <Block
            width="20px"
            marginLeft="8px"
            display="flex"
            justifyContent="center"
          >
            <Checkbox
              checked={selectedIds?.includes(row.original.id)}
              onChange={() => {
                if (selectedIds?.includes(row.original.id)) {
                  setSelectedIds([
                    ...selectedIds?.filter((id) => id !== row.original.id),
                  ]);
                } else {
                  setSelectedIds([
                    ...(selectedIds ? selectedIds : []),
                    row.original.id,
                  ]);
                }
              }}
            />
          </Block>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FinancialPlansFieldName.Year)}
            sortDirection={
              sortBy === FinancialPlansFieldName.Year ? sortDirection : null
            }
          >
            Rok
          </SortingTableHeader>
        ),
        id: "year",
        Cell: ({ row }: { row: Row<FinancialPlan> }) => (
          <StyledLink
            onClick={(event: MouseEvent) => {
              event.preventDefault();
              history.push(`/financial-plans/${row?.original?.id}`);
            }}
            href={`/financial-plans/${row?.original?.id}`}
          >
            <FormattedValue>{row?.original?.year}</FormattedValue>
          </StyledLink>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FinancialPlansFieldName.IsActive)}
            sortDirection={
              sortBy === FinancialPlansFieldName.IsActive ? sortDirection : null
            }
          >
            Aktywny
          </SortingTableHeader>
        ),
        id: "isActive",
        Cell: ({ row }: { row: Row<FinancialPlan> }) => (
          <FormattedValue dataType="boolean">
            {row?.original?.isActive}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FinancialPlansFieldName.Name)}
            sortDirection={
              sortBy === FinancialPlansFieldName.Name ? sortDirection : null
            }
          >
            Nazwa
          </SortingTableHeader>
        ),
        id: "name",
        Cell: ({ row }: { row: Row<FinancialPlan> }) => (
          <FormattedValue>{row?.original?.name}</FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FinancialPlansFieldName.CategoryName)}
            sortDirection={
              sortBy === FinancialPlansFieldName.CategoryName
                ? sortDirection
                : null
            }
          >
            Nazwa kategorii
          </SortingTableHeader>
        ),
        id: "categoryName",
        Cell: ({ row }: { row: Row<FinancialPlan> }) => (
          <FormattedValue>{row?.original?.categoryName}</FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FinancialPlansFieldName.BazaId)}
            sortDirection={
              sortBy === FinancialPlansFieldName.BazaId ? sortDirection : null
            }
          >
            Baza Id
          </SortingTableHeader>
        ),
        id: "bazaId",
        Cell: ({ row }: { row: Row<FinancialPlan> }) => (
          <FormattedValue
            dataType="pre"
            $style={{
              fontSize: "12px",
              paddingTop: "2px",
              paddingBottom: "2px",
            }}
          >
            {row?.original?.bazaId}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() =>
              handleSorting(FinancialPlansFieldName.SynchronizedAt)
            }
            sortDirection={
              sortBy === FinancialPlansFieldName.SynchronizedAt
                ? sortDirection
                : null
            }
          >
            Data synchronizacji
          </SortingTableHeader>
        ),
        id: "synchronizedAt",
        Cell: ({ row }: { row: Row<FinancialPlan> }) => (
          <FormattedValue dataType="datetime">
            {row?.original?.synchronizedAt}
          </FormattedValue>
        ),
      },
      {
        Header: "Zsynchronizowano przez",
        id: "synchronizedBy",
        Cell: ({ row }: { row: Row<FinancialPlan> }) => (
          <FormattedValue dataType="model:users">
            {`${row?.original?.synchronizedBy?.firstName} ${row?.original?.synchronizedBy?.lastName}`}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FinancialPlansFieldName.CreatedAt)}
            sortDirection={
              sortBy === FinancialPlansFieldName.CreatedAt
                ? sortDirection
                : null
            }
          >
            Data dodania
          </SortingTableHeader>
        ),
        id: "createdAt",
        Cell: ({ row }: { row: Row<FinancialPlan> }) => (
          <FormattedValue dataType="date">
            {row?.original?.createdAt}
          </FormattedValue>
        ),
      },
      {
        Header: "Dodano przez",
        id: "createdBy",
        Cell: ({ row }: { row: Row<FinancialPlan> }) => (
          <FormattedValue dataType="model:users">
            {`${row?.original?.createdBy?.firstName} ${row?.original?.createdBy?.lastName}`}
          </FormattedValue>
        ),
      },
      {
        id: "actions",
        Cell: ({ row }: { row: Row<FinancialPlan> }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              $style={{ marginLeft: "6px" }}
              $as="a"
              href={`/financial-plans/${row.original.id}`}
              onClick={(e) => {
                e.preventDefault();
                history.push(`/financial-plans/${row.original.id}`);
              }}
              startEnhancer={<Eye size={14} />}
            />
          </div>
        ),
      },
    ],
    [data, sortBy, sortDirection, filters, selectedIds]
  );

  if (!checkPermission(PERMISSIONS.financialPlan.read))
    return <Redirect to="/" />;

  const shouldShowActivateButton = data?.financialPlans?.nodes?.some(
    (financialPlan: FinancialPlan) =>
      selectedIds.includes(financialPlan.id) && !financialPlan.isActive
  );

  const shouldShowDeactivateButton = data?.financialPlans?.nodes?.some(
    (financialPlan: FinancialPlan) =>
      selectedIds.includes(financialPlan.id) && financialPlan.isActive
  );

  return (
    <article>
      <Header
        title="Plany finansowe"
        recordsNum={data?.financialPlans?.totalCount}
        labels={["Lista"]}
        buttons={[
          {
            label: "Aktywuj",
            permission:
              checkPermission(PERMISSIONS.financialPlan.activate) &&
              !!shouldShowActivateButton,
            isPositive: true,
            isLoading: activateLoading || deactivateLoading,
            onClick: () => changePlanStatus("activate"),
            startEnhancer: <Check size={18} />,
          },
          {
            label: "Dezaktywuj",
            permission:
              checkPermission(PERMISSIONS.financialPlan.deactivate) &&
              !!shouldShowDeactivateButton,
            isNegative: true,
            isLoading: activateLoading || deactivateLoading,
            onClick: () => changePlanStatus("deactivate"),
            startEnhancer: <X size={18} />,
          },
          {
            label: "Dodaj nowy plan",
            permission: checkPermission(PERMISSIONS.financialPlan.create),
            isLoading: activateLoading || deactivateLoading,
            onClick: () => setIsDialogOpen(true),
            startEnhancer: <Plus size={18} />,
          },
        ]}
      />

      <Filters
        filters={FINANCIAL_PLANS_FILTERS}
        state={filters}
        setState={setFilters}
      />

      <Content filtersOffset>
        <Grid>
          <Cell span={12} $style={{ position: "relative" }}>
            <Table<FinancialPlan>
              columns={columns}
              data={data?.financialPlans?.nodes}
              isLoading={isFetching || isPartialFetching || loading}
              stickLastColumn
            />
          </Cell>

          <Cell span={12}>
            <BottomPanel>
              <PagingControls />
            </BottomPanel>
          </Cell>
        </Grid>
      </Content>

      <NewFinancialPlanModal
        isOpen={isDialogOpen}
        close={() => setIsDialogOpen(false)}
        refetch={refetch}
      />
    </article>
  );
}
