import { styled, useStyletron } from "baseui";
import { Block } from "baseui/block";
import { SIZE } from "baseui/select";
import { Theme } from "baseui/theme";
import { LabelXSmall } from "baseui/typography";
import debounce from "lodash.debounce";
import React, {
  ChangeEvent,
  FormEvent,
  Fragment,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory, useLocation } from "react-router";
import {
  AlertTriangle,
  Calendar,
  Check,
  Checkbox as CheckboxIcon,
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
  CircleCheck,
  CircleX,
  Cloud,
  CurrencyDollar,
  CurrencyZloty,
  Filter,
  Folder,
  LetterCase,
  Paperclip,
  Search,
  Trash,
  User,
} from "tabler-icons-react";

import { useFilters } from "../contexts/filters-context";
import { useLoading } from "../contexts/loading-context";
import {
  BasicFilter,
  FILTERS,
  FiltersState,
  FilterState,
  SingleFilter,
} from "../filters.d";
import { translateFilterToString } from "../utils/filters";
import { generateUUID } from "../utils/misc";
import Button, { ForwardedButton } from "./button";
import Cell from "./cell";
import DatePicker from "./date-picker";
import Grid from "./grid";
import Input, { ForwardedInput } from "./input";
import Popover from "./popover";
import Select, {
  ActivitiesSelect,
  ActivityLogsSubjectSelect,
  CasesSelectForFilters,
  DictionaryValuesSelect,
  DocumentKindsSelect,
  DocumentPickupStateSelect,
  DocumentsSelectForFilters,
  DocumentStatesSelect,
  DocumentTypesSelect,
  OrganizationalUnitsSelectForFilters,
  RolesSelect,
  UsersSelect,
} from "./select";

const TEXT_FILTERS = [FILTERS.Text, FILTERS.FullName, FILTERS.LoginAd];

const Container = styled(
  "summary",
  ({ $theme, $sticked }: { $theme?: Theme; $sticked?: boolean }) => ({
    backgroundColor: "white",
    paddingTop: $sticked ? $theme?.sizing.scale300 : $theme?.sizing.scale300,
    paddingBottom: $sticked ? $theme?.sizing.scale300 : $theme?.sizing.scale300,
    position: $sticked ? "sticky" : "absolute",
    top: $sticked ? "72px" : "104px",
    left: "0px",
    right: "0px",
    zIndex: 10,
    borderBottomWidth: $sticked ? "2px" : "0px",
    borderBottomStyle: "solid",
    borderBottomColor: $theme?.colors.backgroundTertiary,
    ...($sticked && {
      marginBottom: "-46px",
      animationIterationCount: "1",
      animationDelay: "0ms",
      animationDuration: "200ms",
      animationFillMode: "both",
      animationName: {
        from: {
          transform: "translateY(-200px)",
        },
        to: {
          transform: "translateY(0px)",
        },
      } as any,
    }),
  })
);

function FilterIcon({
  type,
  size: inheritedSize,
}: {
  type: FILTERS;
  size?: number;
}): ReactElement {
  const size = inheritedSize || 14;

  switch (type) {
    case FILTERS.Text:
      return <LetterCase size={size} />;

    case FILTERS.User:
    case FILTERS.FullName:
    case FILTERS.LoginAd:
    case FILTERS.Substitutee:
    case FILTERS.Substituter:
      return <User size={size} />;

    case FILTERS.Date:
      return <Calendar size={size} />;

    case FILTERS.Boolean:
    case FILTERS.HasCaseAssigned:
      return <Check size={size} />;

    case FILTERS.DocumentValueInPLN:
    case FILTERS.Amount:
      return <CurrencyZloty size={size} />;

    case FILTERS.DocumentValueInCurrency:
      return <CurrencyDollar size={size} />;

    case FILTERS.Case:
      return <Folder size={size} />;

    default:
      return <Filter size={size} />;
  }
}

type FilterFormProps = {
  type: FILTERS;
  filter: BasicFilter;
  state?: FilterState;
  onSubmit?: (state: FilterState) => void;
};

function FilterForm({
  type,
  filter,
  state: inheritedState,
  onSubmit: inheritedOnSubmit,
}: FilterFormProps): ReactElement {
  const [, theme] = useStyletron();

  const initialState = inheritedState || {
    kind: "single",
  };

  const [state, setState] = useState<FilterState | undefined>(initialState);

  function onSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault();

    inheritedOnSubmit && state && inheritedOnSubmit(state);
  }

  function ApplyButton({ isDisabled }: { isDisabled?: boolean }): ReactElement {
    return (
      <Button
        startEnhancer={<CheckboxIcon size={14} />}
        size="mini"
        kind="secondary"
        $style={{ width: "100%", marginTop: "10px" }}
        {...(isDisabled && {
          disabled: true,
        })}
      >
        {inheritedState ? "Zastosuj" : "Dodaj i zastosuj"}
      </Button>
    );
  }

  switch (type) {
    case FILTERS.Date:
      return (
        <form onSubmit={onSubmit}>
          <Block display="flex" justifyContent="space-between">
            <Button
              size="mini"
              type="button"
              kind={state?.kind === "single" ? "primary" : "secondary"}
              onClick={() => setState({ ...state, kind: "single" })}
              $style={{
                borderTopRightRadius: "0px",
                borderBottomRightRadius: "0px",
                flexGrow: 1,
                flexShrink: 1,
                marginRight: "0.75px",
              }}
            >
              W dniu
            </Button>

            <Button
              size="mini"
              type="button"
              kind={state?.kind === "between" ? "primary" : "secondary"}
              onClick={() => setState({ ...state, kind: "between" })}
              $style={{
                borderTopLeftRadius: "0px",
                borderBottomLeftRadius: "0px",
                flexGrow: 1,
                flexShrink: 1,
                marginLeft: "0.75px",
              }}
            >
              Od — Do
            </Button>
          </Block>

          {state?.kind === "between" ? (
            <Fragment>
              <Block marginTop="scale300">
                <DatePicker
                  size="mini"
                  placeholder="Data od"
                  {...(state?.to && { maxDate: new Date(state.to) })}
                  {...(state?.from && { value: new Date(state.from) })}
                  setDate={(date) => setState({ ...state, from: date })}
                />
              </Block>

              <Block marginTop="scale300">
                <DatePicker
                  size="mini"
                  placeholder="Data do"
                  {...(state?.from && { minDate: new Date(state.from) })}
                  {...(state?.to && { value: new Date(state.to) })}
                  setDate={(date) => setState({ ...state, to: date })}
                />
              </Block>
            </Fragment>
          ) : (
            <Block marginTop="scale300">
              <DatePicker
                size="mini"
                placeholder="W dniu"
                {...(state?.in && { value: new Date(state.in) })}
                required
                setDate={(date) => setState({ ...state, in: date })}
              />
            </Block>
          )}

          <ApplyButton
            isDisabled={
              state?.kind === "between"
                ? !state?.from || !state?.to
                : !state?.in
            }
          />
        </form>
      );

    case FILTERS.OrganizationalUnit:
      return (
        <form onSubmit={onSubmit}>
          <Block marginTop="scale300">
            <OrganizationalUnitsSelectForFilters
              size="mini"
              placeholder="Wybierz"
              onChange={(params) => {
                setState({
                  ...state,
                  value: params.option?.id,
                  label: params.option?.symbol,
                });
              }}
              {...(state?.value && {
                value: [{ id: state?.value }],
              })}
            />
          </Block>
          <ApplyButton />
        </form>
      );

    case FILTERS.User:
      return (
        <form onSubmit={onSubmit}>
          <Block marginTop="scale300">
            <UsersSelect
              size="mini"
              placeholder="Wybierz"
              onChange={(params) => {
                setState({
                  ...state,
                  value: params.option?.id,
                  ...params.option,
                });
              }}
              {...(state?.value && {
                value: [{ id: state?.value, ...state }],
              })}
            />
          </Block>
          <ApplyButton isDisabled={!state?.value} />
        </form>
      );

    case FILTERS.Substitutee:
      return (
        <form onSubmit={onSubmit}>
          <Block marginTop="scale300">
            <UsersSelect
              size="mini"
              placeholder="Wybierz"
              onChange={(params) => {
                setState({
                  ...state,
                  value: params.option?.id,
                  ...params.option,
                });
              }}
              {...(state?.value && {
                value: [{ id: state?.value, ...state }],
              })}
            />
          </Block>
          <ApplyButton isDisabled={!state?.value} />
        </form>
      );

    case FILTERS.Substituter:
      return (
        <form onSubmit={onSubmit}>
          <Block marginTop="scale300">
            <UsersSelect
              size="mini"
              placeholder="Wybierz"
              onChange={(params) => {
                setState({
                  ...state,
                  value: params.option?.id,
                  ...params.option,
                });
              }}
              {...(state?.value && {
                value: [{ id: state?.value, ...state }],
              })}
            />
          </Block>
          <ApplyButton isDisabled={!state?.value} />
        </form>
      );

    case FILTERS.Case:
      return (
        <form onSubmit={onSubmit}>
          <Block marginTop="scale300">
            <CasesSelectForFilters
              size="mini"
              placeholder="Wybierz"
              onChange={(params) => {
                setState({
                  ...state,
                  value: params.option?.id,
                  ...params.option,
                });
              }}
              {...(state?.value && {
                value: [{ id: state?.value, ...state }],
              })}
            />
          </Block>
          <ApplyButton isDisabled={!state?.value} />
        </form>
      );

    case FILTERS.Boolean:
      return (
        <form onSubmit={onSubmit}>
          <Block
            marginTop="scale300"
            display="flex"
            backgroundColor="inputBorder"
            overrides={{
              Block: {
                style: {
                  borderTopLeftRadius: theme.borders.radius200,
                  borderTopRightRadius: theme.borders.radius200,
                  borderBottomRightRadius: theme.borders.radius200,
                  borderBottomLeftRadius: theme.borders.radius200,
                },
              },
            }}
          >
            <Button
              type="button"
              startEnhancer={
                <CircleCheck color={theme.colors.positive} size={14} />
              }
              size="mini"
              kind={!state?.is ? "secondary" : "tertiary"}
              $style={{ flexGrow: 1, flexShrink: 0, width: "50%" }}
              onClick={() => setState({ ...state, is: !state?.is })}
            >
              Tak
            </Button>

            <Button
              type="button"
              startEnhancer={
                <CircleX color={theme.colors.negative} size={14} />
              }
              size="mini"
              kind={state?.is ? "secondary" : "tertiary"}
              $style={{ flexGrow: 1, flexShrink: 0, width: "50%" }}
              onClick={() => setState({ ...state, is: !state?.is })}
            >
              Nie
            </Button>
          </Block>

          <ApplyButton />
        </form>
      );

    case FILTERS.DocumentTypes:
      return (
        <form onSubmit={onSubmit}>
          <Block
            marginTop="scale300"
            display="flex"
            backgroundColor="inputBorder"
            overrides={{
              Block: {
                style: {
                  borderTopLeftRadius: theme.borders.radius200,
                  borderTopRightRadius: theme.borders.radius200,
                  borderBottomRightRadius: theme.borders.radius200,
                  borderBottomLeftRadius: theme.borders.radius200,
                },
              },
            }}
          >
            <DocumentTypesSelect
              size={SIZE.mini}
              onChange={(params) => {
                setState({
                  ...state,
                  value: params.value,
                });
              }}
              $style={{
                maxWidth: "290px !important",
              }}
              {...(state?.value && {
                value: state?.value,
              })}
            />
          </Block>

          <ApplyButton isDisabled={!state?.value} />
        </form>
      );

    case FILTERS.DocumentKinds:
      return (
        <form onSubmit={onSubmit}>
          <Block
            marginTop="scale300"
            display="flex"
            backgroundColor="inputBorder"
            overrides={{
              Block: {
                style: {
                  borderTopLeftRadius: theme.borders.radius200,
                  borderTopRightRadius: theme.borders.radius200,
                  borderBottomRightRadius: theme.borders.radius200,
                  borderBottomLeftRadius: theme.borders.radius200,
                },
              },
            }}
          >
            <DocumentKindsSelect
              size={SIZE.mini}
              onChange={(params) => {
                setState({
                  ...state,
                  value: params.option?.id,
                  label: params.option?.label,
                });
              }}
              {...(state?.value && {
                value: [{ id: state?.value }],
              })}
            />
          </Block>
          <ApplyButton isDisabled={!state?.value} />
        </form>
      );

    case FILTERS.DocumentPickupState:
      return (
        <form onSubmit={onSubmit}>
          <Block
            marginTop="scale300"
            display="flex"
            backgroundColor="inputBorder"
            overrides={{
              Block: {
                style: {
                  borderTopLeftRadius: theme.borders.radius200,
                  borderTopRightRadius: theme.borders.radius200,
                  borderBottomRightRadius: theme.borders.radius200,
                  borderBottomLeftRadius: theme.borders.radius200,
                },
              },
            }}
          >
            <DocumentPickupStateSelect
              size={SIZE.mini}
              onChange={(params) => {
                setState({
                  ...state,
                  value: params.option?.id,
                  label: params.option?.label,
                });
              }}
              {...(state?.value && {
                value: [{ id: state?.value }],
              })}
            />
          </Block>
          <ApplyButton isDisabled={!state?.value} />
        </form>
      );

    case FILTERS.DocumentStates:
      return (
        <form onSubmit={onSubmit}>
          <Block
            marginTop="scale300"
            display="flex"
            backgroundColor="inputBorder"
            overrides={{
              Block: {
                style: {
                  borderTopLeftRadius: theme.borders.radius200,
                  borderTopRightRadius: theme.borders.radius200,
                  borderBottomRightRadius: theme.borders.radius200,
                  borderBottomLeftRadius: theme.borders.radius200,
                },
              },
            }}
          >
            <DocumentStatesSelect
              size={SIZE.mini}
              onChange={(params) => {
                setState({
                  ...state,
                  value: params.option?.id,
                  label: params.option?.label,
                });
              }}
              {...(state?.value && {
                value: [{ id: state?.value }],
              })}
            />
          </Block>
          <ApplyButton isDisabled={!state?.value} />
        </form>
      );

    case FILTERS.DictionaryValues:
      return (
        <form onSubmit={onSubmit}>
          <Block
            marginTop="scale300"
            display="flex"
            backgroundColor="inputBorder"
            overrides={{
              Block: {
                style: {
                  borderTopLeftRadius: theme.borders.radius200,
                  borderTopRightRadius: theme.borders.radius200,
                  borderBottomRightRadius: theme.borders.radius200,
                  borderBottomLeftRadius: theme.borders.radius200,
                },
              },
            }}
          >
            <DictionaryValuesSelect
              dictionarySystemName={filter.dictionarySystemName}
              size={SIZE.mini}
              onChange={(params) => {
                setState({
                  ...state,
                  value: params.option?.id,
                  label: params.option?.label,
                });
              }}
              {...(state?.value && {
                value: [{ id: state?.value }],
              })}
            />
          </Block>
          <ApplyButton isDisabled={!state?.value} />
        </form>
      );

    case FILTERS.Activity:
      return (
        <form onSubmit={onSubmit}>
          <Block
            marginTop="scale300"
            display="flex"
            backgroundColor="inputBorder"
            overrides={{
              Block: {
                style: {
                  borderTopLeftRadius: theme.borders.radius200,
                  borderTopRightRadius: theme.borders.radius200,
                  borderBottomRightRadius: theme.borders.radius200,
                  borderBottomLeftRadius: theme.borders.radius200,
                },
              },
            }}
          >
            <ActivitiesSelect
              size={SIZE.mini}
              onChange={(params) => {
                setState({
                  ...state,
                  value: params.option?.id,
                  label: params.option?.label,
                });
              }}
              {...(state?.value && {
                value: [{ label: state?.label }],
              })}
            />
          </Block>
          <ApplyButton isDisabled={!state?.value} />
        </form>
      );

    case FILTERS.ActivityLogsSubjectType:
      return (
        <form onSubmit={onSubmit}>
          <Block
            marginTop="scale300"
            display="flex"
            backgroundColor="inputBorder"
            overrides={{
              Block: {
                style: {
                  borderTopLeftRadius: theme.borders.radius200,
                  borderTopRightRadius: theme.borders.radius200,
                  borderBottomRightRadius: theme.borders.radius200,
                  borderBottomLeftRadius: theme.borders.radius200,
                },
              },
            }}
          >
            <ActivityLogsSubjectSelect
              size={SIZE.mini}
              onChange={(params) => {
                setState({
                  ...state,
                  value: params.option?.id,
                  label: params.option?.label,
                });
              }}
              {...(state?.value && {
                value: [{ label: state?.label }],
              })}
            />
          </Block>
          <ApplyButton isDisabled={!state?.value} />
        </form>
      );

    case FILTERS.PrimaryAssignable:
      return (
        <form onSubmit={onSubmit}>
          <Block marginTop="scale300" display="flex" flexDirection={"column"}>
            <Select
              size={SIZE.mini}
              options={[
                {
                  id: "Case",
                  label: "Sprawa",
                },
                {
                  id: "Document",
                  label: "Dokument",
                },
              ]}
              onChange={(params) => {
                setState({
                  type: params.option?.id,
                  typeLabel: params.option?.label,
                });
              }}
              placeholder="Wybierz"
              {...(state?.type && {
                value: [{ id: state?.type }],
              })}
            />

            {state?.type === "Case" && (
              <Block marginTop="scale300">
                <CasesSelectForFilters
                  size="mini"
                  placeholder="Wybierz"
                  onChange={(params) => {
                    setState({
                      ...state,
                      value: params.option?.id,
                      ...params.option,
                    });
                  }}
                  {...(state?.value && {
                    value: [{ id: state?.value, ...state }],
                  })}
                />
              </Block>
            )}

            {state?.type === "Document" && (
              <Block marginTop="scale300">
                <DocumentsSelectForFilters
                  size="mini"
                  placeholder="Wybierz"
                  onChange={(params) => {
                    setState({
                      ...state,
                      value: params.option?.id,
                      ...params.option,
                      type: state.type,
                    });
                  }}
                  {...(state?.value && {
                    value: [{ id: state?.value, ...state }],
                  })}
                />
              </Block>
            )}
          </Block>
          <ApplyButton isDisabled={!state?.type} />
        </form>
      );

    case FILTERS.Document:
      return (
        <form onSubmit={onSubmit}>
          <Block marginTop="scale300" display="flex" flexDirection={"column"}>
            <Block marginTop="scale300">
              <DocumentsSelectForFilters
                size="mini"
                placeholder="Wybierz"
                onChange={(params) => {
                  setState({
                    ...state,
                    value: params.option?.id,
                    ...params.option,
                  });
                }}
                {...(state?.value && {
                  value: [{ id: state?.value, ...state }],
                })}
              />
            </Block>
          </Block>

          <ApplyButton isDisabled={!state?.value} />
        </form>
      );

    case FILTERS.AssignmentType:
      return (
        <form onSubmit={onSubmit}>
          <Block marginTop="scale300" display="flex">
            <Select
              size={SIZE.mini}
              options={[
                {
                  id: "Share",
                  label: "Udostępnienie",
                },
                {
                  id: "Dispatch",
                  label: "Przekazanie",
                },
                {
                  id: "Initial",
                  label: "Utworzenie",
                },
                {
                  id: "Flow",
                  label: "Obieg zdefiniowany",
                },
              ]}
              onChange={(params) => {
                setState({
                  ...state,
                  value: params.option?.id,
                  label: params.option?.label,
                });
              }}
              placeholder="Wybierz"
              {...(state?.value && {
                value: [{ id: state?.value }],
              })}
            />
          </Block>
          <ApplyButton isDisabled={!state?.value} />
        </form>
      );

    case FILTERS.AssignmentStatus:
      return (
        <form onSubmit={onSubmit}>
          <Block marginTop="scale300" display="flex">
            <Select
              size={SIZE.mini}
              multi
              options={[
                {
                  id: "New",
                  label: "Nowe",
                },
                {
                  id: "InProgress",
                  label: "W realizacji",
                },
                {
                  id: "Finished",
                  label: "Zakończone",
                },
              ]}
              onChange={(params) => {
                setState({
                  ...state,
                  value: params.value,
                });
              }}
              placeholder="Wybierz"
              {...(state?.value && {
                value: state?.value,
              })}
            />
          </Block>
          <ApplyButton isDisabled={!state?.value} />
        </form>
      );

    case FILTERS.CaseStatus:
      return (
        <form onSubmit={onSubmit}>
          <Block
            marginTop="scale300"
            display="flex"
            backgroundColor="inputBorder"
            overrides={{
              Block: {
                style: {
                  borderTopLeftRadius: theme.borders.radius200,
                  borderTopRightRadius: theme.borders.radius200,
                  borderBottomRightRadius: theme.borders.radius200,
                  borderBottomLeftRadius: theme.borders.radius200,
                },
              },
            }}
          >
            <Button
              type="button"
              startEnhancer={
                <CircleCheck color={theme.colors.positive} size={14} />
              }
              size="mini"
              kind={!state?.is ? "secondary" : "tertiary"}
              $style={{ flexGrow: 1, flexShrink: 0, width: "50%" }}
              onClick={() => setState({ ...state, is: true })}
            >
              Otwarta
            </Button>

            <Button
              type="button"
              startEnhancer={
                <CircleX color={theme.colors.negative} size={14} />
              }
              size="mini"
              kind={state?.is ? "secondary" : "tertiary"}
              $style={{ flexGrow: 1, flexShrink: 0, width: "50%" }}
              onClick={() => setState({ ...state, is: false })}
            >
              Zamknięta
            </Button>
          </Block>

          <ApplyButton />
        </form>
      );

    case FILTERS.HasCaseAssigned:
    case FILTERS.HasSequenceNumber:
      return (
        <form onSubmit={onSubmit}>
          <Block
            marginTop="scale300"
            display="flex"
            backgroundColor="inputBorder"
            overrides={{
              Block: {
                style: {
                  borderTopLeftRadius: theme.borders.radius200,
                  borderTopRightRadius: theme.borders.radius200,
                  borderBottomRightRadius: theme.borders.radius200,
                  borderBottomLeftRadius: theme.borders.radius200,
                },
              },
            }}
          >
            <Button
              type="button"
              startEnhancer={
                <CircleCheck color={theme.colors.positive} size={14} />
              }
              size="mini"
              kind={!state?.is ? "secondary" : "tertiary"}
              $style={{ flexGrow: 1, flexShrink: 0, width: "50%" }}
              onClick={() => setState({ ...state, is: true })}
            >
              Tak
            </Button>

            <Button
              type="button"
              startEnhancer={
                <CircleX color={theme.colors.negative} size={14} />
              }
              size="mini"
              kind={state?.is ? "secondary" : "tertiary"}
              $style={{ flexGrow: 1, flexShrink: 0, width: "50%" }}
              onClick={() => setState({ ...state, is: false })}
            >
              Nie
            </Button>
          </Block>

          <ApplyButton />
        </form>
      );

    case FILTERS.CaseConduct:
      return (
        <form onSubmit={onSubmit}>
          <Block
            marginTop="scale300"
            display="flex"
            backgroundColor="inputBorder"
            overrides={{
              Block: {
                style: {
                  borderTopLeftRadius: theme.borders.radius200,
                  borderTopRightRadius: theme.borders.radius200,
                  borderBottomRightRadius: theme.borders.radius200,
                  borderBottomLeftRadius: theme.borders.radius200,
                },
              },
            }}
          >
            <Button
              type="button"
              startEnhancer={<Cloud color={theme.colors.positive} size={14} />}
              size="mini"
              kind={!state?.is ? "secondary" : "tertiary"}
              $style={{ flexGrow: 1, flexShrink: 0, width: "50%" }}
              onClick={() => setState({ ...state, is: true })}
            >
              Elektronicznie
            </Button>

            <Button
              type="button"
              startEnhancer={
                <Paperclip color={theme.colors.negative} size={14} />
              }
              size="mini"
              kind={state?.is ? "secondary" : "tertiary"}
              $style={{ flexGrow: 1, flexShrink: 0, width: "50%" }}
              onClick={() => setState({ ...state, is: false })}
            >
              Tradycyjnie
            </Button>
          </Block>

          <ApplyButton />
        </form>
      );

    case FILTERS.DocumentValueInPLN:
    case FILTERS.Amount:
      return (
        <form onSubmit={onSubmit}>
          <Block display="flex" justifyContent="space-between">
            <Button
              size="mini"
              type="button"
              kind={state?.kind === "single" ? "primary" : "secondary"}
              onClick={() => setState({ ...state, kind: "single" })}
              $style={{
                borderTopRightRadius: "0px",
                borderBottomRightRadius: "0px",
                flexGrow: 1,
                flexShrink: 1,
                marginRight: "0.75px",
              }}
            >
              Wartość
            </Button>

            <Button
              size="mini"
              type="button"
              kind={state?.kind === "between" ? "primary" : "secondary"}
              onClick={() => setState({ ...state, kind: "between" })}
              $style={{
                borderTopLeftRadius: "0px",
                borderBottomLeftRadius: "0px",
                flexGrow: 1,
                flexShrink: 1,
                marginLeft: "0.75px",
              }}
            >
              Od — Do
            </Button>
          </Block>

          {state?.kind === "between" ? (
            <Fragment>
              <Block marginTop="scale300">
                <Input
                  size="mini"
                  placeholder="Od"
                  {...(state?.to && { max: state.to })}
                  {...(state?.from && { value: state.from })}
                  onChange={(event) =>
                    setState({ ...state, from: event.currentTarget.value })
                  }
                  type="number"
                  step="0.01"
                  endEnhancer={
                    <Block
                      display="flex"
                      overrides={{
                        Block: {
                          style: {
                            borderTopLeftRadius: theme.borders.radius200,
                            borderTopRightRadius: theme.borders.radius200,
                            borderBottomRightRadius: theme.borders.radius200,
                            borderBottomLeftRadius: theme.borders.radius200,
                          },
                        },
                      }}
                    >
                      <Button
                        type="button"
                        startEnhancer={<ChevronRight size={15} />}
                        size="mini"
                        disabled={!state?.from}
                        kind={!state?.gtOrEq ? "secondary" : "minimal"}
                        $style={{ flexGrow: 1, flexShrink: 0, width: "50%" }}
                        onClick={() => setState({ ...state, gtOrEq: false })}
                      />

                      <Button
                        type="button"
                        startEnhancer={<ChevronsRight size={15} />}
                        size="mini"
                        disabled={!state?.from}
                        kind={state?.gtOrEq ? "secondary" : "minimal"}
                        $style={{ flexGrow: 1, flexShrink: 0, width: "50%" }}
                        onClick={() => setState({ ...state, gtOrEq: true })}
                      />
                    </Block>
                  }
                />
              </Block>

              <Block marginTop="scale300">
                <Input
                  size="mini"
                  placeholder="Do"
                  {...(state?.from && { min: state.from })}
                  {...(state?.to && { value: state.to })}
                  onChange={(event) =>
                    setState({ ...state, to: event.currentTarget.value })
                  }
                  type="number"
                  step="0.01"
                  endEnhancer={
                    <Block
                      display="flex"
                      overrides={{
                        Block: {
                          style: {
                            borderTopLeftRadius: theme.borders.radius200,
                            borderTopRightRadius: theme.borders.radius200,
                            borderBottomRightRadius: theme.borders.radius200,
                            borderBottomLeftRadius: theme.borders.radius200,
                          },
                        },
                      }}
                    >
                      <Button
                        type="button"
                        startEnhancer={<ChevronLeft size={15} />}
                        size="mini"
                        disabled={!state?.to}
                        kind={!state?.ltOrEq ? "secondary" : "minimal"}
                        $style={{ flexGrow: 1, flexShrink: 0, width: "50%" }}
                        onClick={() => setState({ ...state, ltOrEq: false })}
                      />

                      <Button
                        type="button"
                        startEnhancer={<ChevronsLeft size={15} />}
                        size="mini"
                        disabled={!state?.to}
                        kind={state?.ltOrEq ? "secondary" : "minimal"}
                        $style={{ flexGrow: 1, flexShrink: 0, width: "50%" }}
                        onClick={() => setState({ ...state, ltOrEq: true })}
                      />
                    </Block>
                  }
                />
              </Block>
            </Fragment>
          ) : (
            <Block marginTop="scale300">
              <Input
                size="mini"
                placeholder="Wartość"
                {...(state?.eq && { value: state.eq })}
                required
                onChange={(event) =>
                  setState({ ...state, eq: event.currentTarget.value })
                }
                type="number"
                step="0.01"
              />
            </Block>
          )}

          <ApplyButton />
        </form>
      );

    case FILTERS.DocumentValueInCurrency:
      return (
        <form onSubmit={onSubmit}>
          <Block display="flex" justifyContent="space-between">
            <Button
              size="mini"
              type="button"
              kind={state?.kind === "single" ? "primary" : "secondary"}
              onClick={() => setState({ ...state, kind: "single" })}
              $style={{
                borderTopRightRadius: "0px",
                borderBottomRightRadius: "0px",
                flexGrow: 1,
                flexShrink: 1,
                marginRight: "0.75px",
              }}
            >
              Wartość
            </Button>

            <Button
              size="mini"
              type="button"
              kind={state?.kind === "between" ? "primary" : "secondary"}
              onClick={() => setState({ ...state, kind: "between" })}
              $style={{
                borderTopLeftRadius: "0px",
                borderBottomLeftRadius: "0px",
                flexGrow: 1,
                flexShrink: 1,
                marginLeft: "0.75px",
              }}
            >
              Od — Do
            </Button>
          </Block>

          {state?.kind === "between" ? (
            <Fragment>
              <Block marginTop="scale300">
                <Input
                  size="mini"
                  placeholder="Od"
                  {...(state?.to && { max: state.to })}
                  {...(state?.from && { value: state.from })}
                  onChange={(event) =>
                    setState({ ...state, from: event.currentTarget.value })
                  }
                  type="number"
                  step="0.01"
                  endEnhancer={
                    <Block
                      display="flex"
                      overrides={{
                        Block: {
                          style: {
                            borderTopLeftRadius: theme.borders.radius200,
                            borderTopRightRadius: theme.borders.radius200,
                            borderBottomRightRadius: theme.borders.radius200,
                            borderBottomLeftRadius: theme.borders.radius200,
                          },
                        },
                      }}
                    >
                      <Button
                        type="button"
                        startEnhancer={<ChevronRight size={15} />}
                        size="mini"
                        disabled={!state?.from}
                        kind={!state?.gtOrEq ? "secondary" : "minimal"}
                        $style={{ flexGrow: 1, flexShrink: 0, width: "50%" }}
                        onClick={() => setState({ ...state, gtOrEq: false })}
                      />

                      <Button
                        type="button"
                        startEnhancer={<ChevronsRight size={15} />}
                        size="mini"
                        disabled={!state?.from}
                        kind={state?.gtOrEq ? "secondary" : "minimal"}
                        $style={{ flexGrow: 1, flexShrink: 0, width: "50%" }}
                        onClick={() => setState({ ...state, gtOrEq: true })}
                      />
                    </Block>
                  }
                />
              </Block>

              <Block marginTop="scale300">
                <Input
                  size="mini"
                  placeholder="Do"
                  {...(state?.from && { min: state.from })}
                  {...(state?.to && { value: state.to })}
                  onChange={(event) =>
                    setState({ ...state, to: event.currentTarget.value })
                  }
                  type="number"
                  step="0.01"
                  endEnhancer={
                    <Block
                      display="flex"
                      overrides={{
                        Block: {
                          style: {
                            borderTopLeftRadius: theme.borders.radius200,
                            borderTopRightRadius: theme.borders.radius200,
                            borderBottomRightRadius: theme.borders.radius200,
                            borderBottomLeftRadius: theme.borders.radius200,
                          },
                        },
                      }}
                    >
                      <Button
                        type="button"
                        startEnhancer={<ChevronLeft size={15} />}
                        size="mini"
                        disabled={!state?.to}
                        kind={!state?.ltOrEq ? "secondary" : "minimal"}
                        $style={{ flexGrow: 1, flexShrink: 0, width: "50%" }}
                        onClick={() => setState({ ...state, ltOrEq: false })}
                      />

                      <Button
                        type="button"
                        startEnhancer={<ChevronsLeft size={15} />}
                        size="mini"
                        disabled={!state?.to}
                        kind={state?.ltOrEq ? "secondary" : "minimal"}
                        $style={{ flexGrow: 1, flexShrink: 0, width: "50%" }}
                        onClick={() => setState({ ...state, ltOrEq: true })}
                      />
                    </Block>
                  }
                />
              </Block>
            </Fragment>
          ) : (
            <Block marginTop="scale300">
              <Input
                size="mini"
                placeholder="Wartość"
                {...(state?.eq && { value: state.eq })}
                required
                onChange={(event) =>
                  setState({ ...state, eq: event.currentTarget.value })
                }
                type="number"
                step="0.01"
              />
            </Block>
          )}

          <ApplyButton />
        </form>
      );

    case FILTERS.Number:
      return (
        <form onSubmit={onSubmit}>
          <Block marginTop="scale300">
            <Input
              size="mini"
              placeholder="Wartość"
              {...(state?.eq && { value: state.eq })}
              required
              onChange={(event) =>
                setState({ ...state, eq: event.currentTarget.value })
              }
              type="number"
              step="1"
            />
          </Block>

          <ApplyButton />
        </form>
      );

    case FILTERS.Role:
      return (
        <form onSubmit={onSubmit}>
          <Block marginTop="scale300" display="flex">
            <RolesSelect
              size={SIZE.mini}
              onChange={(params) => {
                setState({
                  ...state,
                  value: params.option?.id,
                  label: params.option?.label,
                });
              }}
              {...(state?.value && {
                value: [{ id: state?.value }],
              })}
            />
          </Block>
          <ApplyButton />
        </form>
      );

    default:
      return (
        <form onSubmit={onSubmit}>
          <Input size="mini" placeholder="Wartość" />
          <ApplyButton />
        </form>
      );
  }
}

type FiltersProps = {
  filters: BasicFilter[];
  state?: FiltersState;
  setState: (items: FiltersState) => void;
  compact?: boolean;
};

export default function Filters({
  filters,
  state,
  setState,
  compact = false,
}: FiltersProps): React.ReactElement {
  const [css, theme] = useStyletron();
  const [sticked, setSticked] = useState(false);
  const [selectedSearchables, setSelectedSearchables] = useState<BasicFilter[]>(
    filters?.filter((filter: BasicFilter) =>
      TEXT_FILTERS.includes(filter.type)
    ) || []
  );
  const [selectedFilter, setSelectedFilter] = useState<BasicFilter | null>(
    null
  );
  const [choosedFilters, setChoosedFilters] = useState<BasicFilter[]>([]);
  const [shouldResetResults, setShouldResetResults] = useState(false);
  const [textFilterPhrase, setTextFilterPhrase] = useState("");

  const [hasSharedFiltersBeenLoaded, setHasSharedFiltersBeenLoaded] = useState(
    false
  );

  const [
    hasSharedFiltersBeenModified,
    setHasSharedFiltersBeenModified,
  ] = useState(false);

  const debouncedSetState = useCallback(debounce(setState, 1000), []);

  const containerRef = useRef<HTMLDivElement>(null);
  const filtersRef = useRef<HTMLDivElement>(null);

  const history = useHistory();
  const { search, pathname } = useLocation();

  const { setIsPartialFetching } = useLoading();

  const params = useMemo(() => new URLSearchParams(search), [search]);

  const { filters: sharedFilters, setFilters } = useFilters();

  useEffect(() => {
    containerRef?.current?.parentNode?.parentNode?.addEventListener(
      "scroll",

      (event: Event) => {
        if ((event?.target as HTMLDivElement)?.scrollTop > 52) setSticked(true);
        else setSticked(false);
      }
    );
  }, [containerRef]);

  useEffect(() => {
    const newState = [
      ...(state
        ? state.filter(({ filter }) => !TEXT_FILTERS.includes(filter.type))
        : []),
      ...(selectedSearchables?.length && textFilterPhrase?.length
        ? selectedSearchables.map((filter) => ({
            uuid: generateUUID(),
            filter,
            state: { iLike: textFilterPhrase },
          }))
        : []),
    ];

    if (textFilterPhrase?.length === 0) {
      setState(newState);
    } else {
      setIsPartialFetching(true);
      debouncedSetState(newState);
    }
  }, [selectedSearchables, textFilterPhrase]);

  useEffect(() => {
    const columnFilters = state?.filter(
      ({ filter }) => !TEXT_FILTERS.includes(filter.type)
    );

    if (window && state) {
      const searchParams = new URLSearchParams({
        ...(params && Object.fromEntries(params)),
        filters: window.btoa(
          unescape(encodeURIComponent(JSON.stringify(columnFilters)))
        ),
        ...(shouldResetResults && params.has("page") && { page: "1" }),
      });

      if (!columnFilters || columnFilters?.length === 0)
        searchParams.delete("filters");

      if (searchParams.toString() !== params.toString())
        history.push({ search: searchParams.toString() });
    }

    if (shouldResetResults) {
      setShouldResetResults(false);

      document?.querySelector("main > section")?.scrollTo({
        top: 0,
        left: 0,
        behavior: "auto",
      });
    }

    if (state?.length) {
      setFilters([
        ...sharedFilters.filter(({ id }) => id !== pathname),
        { id: pathname, state },
      ]);
    } else {
      setFilters([...sharedFilters.filter(({ id }) => id !== pathname)]);
    }
  }, [state]);

  useEffect(() => {
    const filters = params.get("filters");

    const parsedFilters = filters
      ? JSON.parse(decodeURIComponent(escape(window.atob(filters as string))))
      : null;

    setChoosedFilters([
      ...(parsedFilters
        ? parsedFilters?.map((item: SingleFilter) => item?.filter)
        : []),
    ]);

    if (filters && window) {
      setState([
        ...(selectedSearchables.length
          ? selectedSearchables.map((filter) => ({
              uuid: generateUUID(),
              filter,
              state: { iLike: textFilterPhrase },
            }))
          : []),
        ...(parsedFilters.length
          ? JSON.parse(decodeURIComponent(escape(window.atob(filters))))
          : []),
      ]);

      setFilters([...sharedFilters.filter(({ id }) => id !== pathname)]);
    }
  }, [search]);

  useEffect(() => {
    if (!hasSharedFiltersBeenLoaded) {
      const foundSharedFilters = sharedFilters?.find(
        ({ id }) => id === pathname
      );

      if (foundSharedFilters?.state?.length && setState) {
        const state = foundSharedFilters.state;

        const searchables = state?.filter(({ filter: { type } }) =>
          TEXT_FILTERS.includes(type)
        );

        const searchablesFilters = state
          ?.filter(({ filter: { type } }) => TEXT_FILTERS.includes(type))
          ?.map(({ filter }) => filter);

        setState(state);

        if (searchables.length) {
          setSelectedSearchables(searchablesFilters);

          const textPhrase = searchables[0]?.state?.iLike;

          setTextFilterPhrase(textPhrase);
        }
      }
      setHasSharedFiltersBeenLoaded(true);
    } else {
      setHasSharedFiltersBeenModified(true);
    }
  }, [sharedFilters]);

  const shouldShowInput =
    !!filters?.length &&
    filters?.some((filter: BasicFilter) => TEXT_FILTERS.includes(filter.type));

  const shouldShowButton =
    !!filters?.length &&
    filters?.some((filter: BasicFilter) => !TEXT_FILTERS.includes(filter.type));

  function renderFilters() {
    return (
      <div className={css({ display: "flex", gap: "10px", height: "30px" })}>
        {shouldShowInput && (
          <Popover
            content={() => (
              <Block width="200px" padding="scale300">
                <LabelXSmall
                  $style={{
                    fontWeight: 600,
                    borderBottomWidth: "1px",
                    borderBottomStyle: "dotted",
                    borderBottomColor: theme.colors.inputBorder,
                    paddingBottom: theme.sizing.scale300,
                    marginBottom: theme.sizing.scale500,
                  }}
                >
                  Przeszukuj po
                </LabelXSmall>

                {filters
                  ?.filter(({ type }) => TEXT_FILTERS.includes(type))
                  ?.map((filter) => (
                    <div
                      key={`filterToSelect-${filter.id}`}
                      className={css({
                        display: "flex",
                        alignItems: "center",
                        marginTop: theme.sizing.scale300,
                        borderRadius: "6px",
                        ":hover": {
                          backgroundColor: "#f9f9f9",
                          cursor: "pointer",
                        },
                      })}
                      onClick={() => {
                        setSelectedSearchables((selectedSearchables) =>
                          selectedSearchables?.includes(filter)
                            ? selectedSearchables.filter(
                                (searchable) => searchable !== filter
                              )
                            : [...selectedSearchables, filter]
                        );
                      }}
                    >
                      <div
                        className={css({
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: selectedSearchables?.includes(filter)
                            ? theme.colors.primary
                            : "#eee",
                          padding: "4px",
                          borderRadius: "6px",
                          marginRight: "8px",
                          flexShrink: 0,
                        })}
                      >
                        {selectedSearchables?.includes(filter) ? (
                          <Check size={14} color="white" />
                        ) : (
                          <FilterIcon type={filter.type} />
                        )}
                      </div>

                      <LabelXSmall>{filter.label}</LabelXSmall>
                    </div>
                  ))}

                {!selectedSearchables.length && (
                  <LabelXSmall
                    display="flex"
                    alignItems="flex-start"
                    $style={{
                      fontWeight: 400,
                      borderTopWidth: "1px",
                      borderTopStyle: "dotted",
                      borderTopColor: theme.colors.inputBorder,
                      paddingTop: theme.sizing.scale300,
                      marginTop: theme.sizing.scale500,
                    }}
                  >
                    <div
                      className={css({
                        flexShrink: 0,
                        marginTop: "3px",
                        marginRight: "8px",
                      })}
                    >
                      <AlertTriangle
                        size={14}
                        color={theme.colors.warning400}
                      />
                    </div>
                    Wybierz przynajmniej jedno pole, aby rozpocząć
                    przeszukiwanie.
                  </LabelXSmall>
                )}
              </Block>
            )}
            placement="bottom"
          >
            <div
              className={css({
                width: "300px",
                height: "30px",
                flexShrink: 0,
              })}
            >
              <ForwardedInput
                size="mini"
                startEnhancer={() => <Search size={12} />}
                {...(!selectedSearchables.length && {
                  endEnhancer: () => (
                    <AlertTriangle size={14} color={theme.colors.warning400} />
                  ),
                })}
                value={textFilterPhrase}
                clearable={true}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setTextFilterPhrase(event.target.value);
                  setShouldResetResults(true);
                }}
                $style={{
                  height: "30px",
                  fontSize: "12px",
                  paddingTop: "1px",
                  lineHeight: "12px",
                }}
              />
            </div>
          </Popover>
        )}

        {state &&
          state.filter(({ filter }) => !TEXT_FILTERS.includes(filter.type))
            .length > 0 && (
            <div
              ref={filtersRef}
              className={css({
                overflowX: "scroll",
                overflowY: "hidden",
                position: "relative",
                scrollbarWidth: "none",
                msOverflowStyle: "none",
                "::-webkit-scrollbar": {
                  display: "none",
                },
              })}
            >
              <div
                className={css({
                  gap: "10px",
                  display: "flex",
                })}
              >
                {state
                  .filter(({ filter }) => !TEXT_FILTERS.includes(filter.type))
                  .map((instance) => {
                    const {
                      uuid: filterUuid,
                      filter,
                      state: filterState,
                    } = instance;

                    return (
                      <Popover
                        key={generateUUID()}
                        ignoreBoundary
                        content={({ close }) => (
                          <Block padding="scale300" minWidth={"300px"}>
                            <LabelXSmall
                              display="flex"
                              alignItems="flex-end"
                              $style={{
                                fontWeight: 600,
                                borderBottomWidth: "1px",
                                borderBottomStyle: "dotted",
                                borderBottomColor: theme.colors.inputBorder,
                                paddingBottom: theme.sizing.scale300,
                                marginBottom: theme.sizing.scale500,
                              }}
                            >
                              <div
                                className={css({
                                  flexShrink: 0,
                                  marginRight: "5px",
                                  height: "15px",
                                  cursor: "pointer",
                                })}
                                onClick={() => setSelectedFilter(null)}
                              >
                                <FilterIcon type={filter.type} size={14} />
                              </div>

                              {filter.label}
                            </LabelXSmall>

                            <FilterForm
                              type={filter.type}
                              filter={filter}
                              state={filterState}
                              onSubmit={(filterState) => {
                                close();
                                setSelectedFilter(null);
                                setShouldResetResults(true);
                                setState &&
                                  setState([
                                    ...state.filter(
                                      ({ uuid }) => uuid !== filterUuid
                                    ),
                                    {
                                      uuid: filterUuid,
                                      filter: filter,
                                      state: filterState as JSON,
                                    },
                                  ]);
                              }}
                            />

                            <Button
                              startEnhancer={<Trash size={14} />}
                              size="mini"
                              kind="secondary"
                              $style={{
                                width: "100%",
                                marginTop: "10px",
                              }}
                              onClick={() => {
                                close();

                                setSelectedFilter(null);
                                setChoosedFilters([
                                  ...choosedFilters?.filter(
                                    (choosedFilter) =>
                                      !state?.some(
                                        (item) =>
                                          item.filter.id === choosedFilter.id
                                      )
                                  ),
                                ]);
                                setShouldResetResults(true);

                                if (setState) {
                                  setState([
                                    ...state.filter(
                                      ({ uuid }) => uuid !== filterUuid
                                    ),
                                  ]);

                                  setFilters([
                                    ...sharedFilters.filter(
                                      ({ id }) => id !== pathname
                                    ),
                                  ]);
                                }
                              }}
                            >
                              Usuń
                            </Button>
                          </Block>
                        )}
                        placement="bottom"
                      >
                        <ForwardedButton
                          startEnhancer={
                            <FilterIcon type={filter.type} size={14} />
                          }
                          size="mini"
                          kind="tertiary"
                          $style={{
                            minWidth: "180px",
                            maxWidth: "320px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            height: "30px",
                            justifyContent: "flex-start",
                          }}
                        >
                          {translateFilterToString(instance)}
                        </ForwardedButton>
                      </Popover>
                    );
                  })}
              </div>
            </div>
          )}

        {shouldShowButton && (
          <Popover
            content={({ close }) => {
              if (
                choosedFilters.length ===
                filters?.filter(
                  (filter: BasicFilter) => !TEXT_FILTERS.includes(filter.type)
                )?.length
              )
                close();

              return (
                <Block padding="scale300" minWidth={"300px"}>
                  <LabelXSmall
                    display="flex"
                    alignItems="flex-end"
                    $style={{
                      fontWeight: 600,
                      borderBottomWidth: "1px",
                      borderBottomStyle: "dotted",
                      borderBottomColor: theme.colors.inputBorder,
                      paddingBottom: theme.sizing.scale300,
                      marginBottom: theme.sizing.scale500,
                    }}
                  >
                    {selectedFilter && (
                      <div
                        className={css({
                          flexShrink: 0,
                          marginRight: "5px",
                          height: "15px",
                          cursor: "pointer",
                        })}
                        onClick={() => setSelectedFilter(null)}
                      >
                        <ChevronLeft size={14} />
                      </div>
                    )}

                    {selectedFilter ? selectedFilter.label : "Filtruj po"}
                  </LabelXSmall>

                  {selectedFilter ? (
                    <FilterForm
                      type={selectedFilter.type}
                      filter={selectedFilter}
                      onSubmit={(filterState) => {
                        close();

                        setShouldResetResults(true);
                        setChoosedFilters([...choosedFilters, selectedFilter]);
                        setSelectedFilter(null);
                        setState &&
                          setState([
                            ...(state ? state : []),
                            {
                              uuid: generateUUID(),
                              filter: selectedFilter,
                              state: filterState as JSON,
                            },
                          ]);
                      }}
                    />
                  ) : (
                    filters
                      ?.filter(({ type }) => !TEXT_FILTERS.includes(type))
                      ?.filter(
                        (filter) =>
                          !choosedFilters?.some(
                            (choosedFilter) => choosedFilter?.id === filter?.id
                          )
                      )
                      .map((filter) => (
                        <div
                          key={`filterToSelect-${filter.id}`}
                          className={css({
                            display: "flex",
                            alignItems: "center",
                            marginTop: theme.sizing.scale300,
                            borderRadius: "6px",
                            ":hover": {
                              backgroundColor: "#f9f9f9",
                              cursor: "pointer",
                            },
                          })}
                          onClick={() => setSelectedFilter(filter)}
                        >
                          <div
                            className={css({
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              backgroundColor: "#eee",
                              padding: "4px",
                              borderRadius: "6px",
                              marginRight: "8px",
                              flexShrink: 0,
                            })}
                          >
                            <FilterIcon type={filter.type} />
                          </div>

                          <LabelXSmall>{filter.label}</LabelXSmall>
                        </div>
                      ))
                  )}
                </Block>
              );
            }}
            placement="bottom"
          >
            <ForwardedButton
              kind="secondary"
              size="compact"
              type="button"
              startEnhancer={<Filter size={14} />}
              disabled={
                choosedFilters.length ===
                filters?.filter(
                  (filter: BasicFilter) => !TEXT_FILTERS.includes(filter.type)
                )?.length
              }
              $style={{
                flexShrink: 0,
              }}
            >
              Filtruj
            </ForwardedButton>
          </Popover>
        )}

        {filters &&
          !!filters?.filter(
            (filter: BasicFilter) => !TEXT_FILTERS.includes(filter.type)
          )?.length && (
            <ForwardedButton
              kind="secondary"
              size="compact"
              type="button"
              startEnhancer={<Trash size={14} />}
              disabled={!choosedFilters.length}
              $style={{
                flexShrink: 0,
              }}
              {...(setState && {
                onClick: () => {
                  setState([]);
                  setChoosedFilters([]);
                  setShouldResetResults(true);
                  setFilters([
                    ...sharedFilters.filter(({ id }) => id !== pathname),
                  ]);
                },
              })}
            >
              Usuń filtry
            </ForwardedButton>
          )}
      </div>
    );
  }

  if (compact) {
    return renderFilters();
  }

  return (
    <Container $sticked={sticked} ref={containerRef} data-ui="filters">
      <Grid>
        <Cell span={12}>{renderFilters()}</Cell>
      </Grid>
    </Container>
  );
}
