import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { LabelSmall } from "baseui/typography";
import React, { useMemo } from "react";
import { Cell, Row } from "react-table";

import { FinancialAccountingDocumentItem } from "../containers/Documents/documents";
import FormattedValue from "./formatted-value";
import Table from "./table";

type FinancialAccountingDocumentItemsTableProps = {
  currency?: string;
  tableItems: FinancialAccountingDocumentItem[];
};

export default function FinancialAccountingDocumentItemsTable({
  currency,
  tableItems,
}: FinancialAccountingDocumentItemsTableProps): React.ReactElement {
  const [css, theme] = useStyletron();

  const totalAmountSum = tableItems
    .reduce(
      (a: number, b: FinancialAccountingDocumentItem) =>
        a + (b.totalAmount ? b.totalAmount : 0),
      0
    )
    .toFixed(2);
  const totalAmountWithTaxSum = tableItems
    .reduce(
      (a: number, b: FinancialAccountingDocumentItem) =>
        a + (b.totalAmountWithTax ? b.totalAmountWithTax : 0),
      0
    )
    .toFixed(2);

  const columns = useMemo(
    () => [
      {
        accessor: "lp",
        Header: "Lp.",
        id: "ordinalNumber",
        Cell: ({ row }: { row: Row<FinancialAccountingDocumentItem> }) => (
          <Block width="30px">{row.index + 1}</Block>
        ),
      },
      {
        accessor: "name",
        Header: "Nazwa pozycji",
      },
      {
        accessor: "totalAmount",
        Header: (
          <Block display="flex" justifyContent="flex-end">
            Kwota netto
          </Block>
        ),
        Cell: ({ cell }: { cell: Cell }) => (
          <Block display="flex" justifyContent="flex-end">
            <FormattedValue dataType="quota" currency={currency}>
              {cell.value}
            </FormattedValue>
          </Block>
        ),
      },
      {
        accessor: "totalAmountWithTax",
        id: "totalAmountWithTax",
        Header: (
          <Block display="flex" justifyContent="flex-end">
            Kwota brutto
          </Block>
        ),
        Cell: ({ cell }: { cell: Cell }) => (
          <Block display="flex" justifyContent="flex-end">
            <FormattedValue dataType="quota" currency={currency}>
              {cell.value}
            </FormattedValue>
          </Block>
        ),
      },
      {
        accessor: "requestFormItem.year",
        id: "budgetYear",
        Header: (
          <Block display="flex" justifyContent="flex-end">
            Rok budżetowy
          </Block>
        ),
        Cell: ({ cell }: { cell: Cell }) => (
          <Block display="flex" justifyContent="flex-end">
            <FormattedValue>{cell.value}</FormattedValue>
          </Block>
        ),
      },
      {
        id: "requestFormItem",
        Header: "Pozycja wniosku",
        Cell: ({ row }: { row: Row<FinancialAccountingDocumentItem> }) => {
          const requestFormItem = row?.original?.requestFormItem;

          return (
            <FormattedValue>
              {requestFormItem &&
                `[${requestFormItem.budgetSymbol || requestFormItem.year}]${
                  requestFormItem.categoryPath
                    ? `, ${requestFormItem.categoryPath}`
                    : ""
                }${
                  requestFormItem.categoryName
                    ? `, ${requestFormItem.categoryName}`
                    : ""
                }${
                  requestFormItem.budgetName
                    ? `, ${requestFormItem.budgetName}`
                    : ""
                }`}
            </FormattedValue>
          );
        },
      },
    ],
    [tableItems]
  );

  return (
    <Table<FinancialAccountingDocumentItem>
      compact
      scrollable
      columns={columns}
      data={tableItems}
      $footer={() => {
        return (
          <>
            <td colSpan={1} />
            <td colSpan={1} />
            <td
              className={css({
                paddingTop: theme.sizing.scale550,
                paddingBottom: theme.sizing.scale550,
                paddingLeft: theme.sizing.scale500,
                paddingRight: theme.sizing.scale500,
                position: "sticky",
                right: 0,
              })}
              colSpan={1}
            >
              <Block
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
              >
                <LabelSmall>
                  Suma:{" "}
                  <strong>
                    <FormattedValue dataType="quota" currency={currency}>
                      {totalAmountSum}
                    </FormattedValue>
                  </strong>
                </LabelSmall>
              </Block>
            </td>
            <td
              className={css({
                paddingTop: theme.sizing.scale550,
                paddingBottom: theme.sizing.scale550,
                paddingLeft: theme.sizing.scale500,
                paddingRight: theme.sizing.scale500,
                position: "sticky",
                right: 0,
              })}
              colSpan={1}
            >
              <Block
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
              >
                <LabelSmall>
                  Suma:{" "}
                  <strong>
                    <FormattedValue dataType="quota" currency={currency}>
                      {totalAmountWithTaxSum}
                    </FormattedValue>
                  </strong>
                </LabelSmall>
              </Block>
            </td>
            <td></td>
          </>
        );
      }}
    />
  );
}
