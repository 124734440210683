import { Row } from "@tanstack/react-table";
import { Cell } from "@tanstack/react-table";
import { useStyletron } from "baseui";
import { StyledTableBodyCell, StyledTableBodyRow } from "baseui/table-semantic";
import React, { memo, ReactElement } from "react";
import { StyleObject } from "styletron-standard";

import { sortColumnsByOrder } from "../utils/table";
import TableCellV8, { MemoizedTableCellV8 } from "./table-cell-v8";

type TableRowV8Props = {
  row: Row<any>;
  stickLastColumn?: boolean;
  $tableBodyRowStyle?: StyleObject;
  editable?: boolean;
  visibleColumns?: string[];
  orderedColumns?: string[];
};

export default function TableRowV8({
  row,
  stickLastColumn,
  $tableBodyRowStyle,
  editable,
  visibleColumns,
  orderedColumns,
}: TableRowV8Props): ReactElement {
  const [css, theme] = useStyletron();

  let cells = row.getVisibleCells();

  if (visibleColumns && orderedColumns) {
    cells = row
      .getVisibleCells()
      .filter(
        (cell) =>
          visibleColumns.includes(cell.column.id) ||
          cell.column.id === "actions" ||
          cell.column.id === "checkbox"
      )
      .sort(
        (
          { column: a }: Cell<any, unknown>,
          { column: b }: Cell<any, unknown>
        ) => sortColumnsByOrder(a, b, orderedColumns)
      );
  }

  return (
    <StyledTableBodyRow
      $style={{
        ":hover": {
          backgroundColor: "#fbfbfb",
        },
        ...(stickLastColumn && {
          ":hover [data-ui=actions]": {
            opacity: "100%",
          },
        }),
        borderBottomStyle: "solid",
        borderBottomWidth: "0.5px",
        borderBottomColor: theme.colors.inputBorder,
        ":last-of-type": {
          borderBottomWidth: "0px",
        },
        ...$tableBodyRowStyle,
        ...(row.getIsSelected() && {
          backgroundColor: "#fbfbfb",
        }),
      }}
    >
      {cells.map((cell) => (
        <StyledTableBodyCell
          key={cell.id}
          className={css({
            position: "relative",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            verticalAlign: "middle !important",
            height: "40px",
            paddingLeft: "10px",
            paddingRight: "10px",
            fontSize: "12px",
            borderRightStyle: "solid",
            borderRightWidth: "0.5px",
            borderRightColor: theme.colors.inputBorder,
            scrollSnapAlign: "start",
            flexGrow: 1,
            ":last-of-type": {
              borderRightWidth: "0px",
              ...(stickLastColumn && {
                position: "sticky",
                right: 0,
              }),
            },
            ":first-of-type": {
              wordBreak: "break-all",
            },
          })}
        >
          {cell.column.id === "checkbox" ? (
            <TableCellV8 cell={cell} editable={editable} />
          ) : (
            <MemoizedTableCellV8 cell={cell} editable={editable} />
          )}
        </StyledTableBodyCell>
      ))}
    </StyledTableBodyRow>
  );
}

export const MemoizedTableRowV8 = memo(TableRowV8);
