import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import {
  ArrowBack,
  ArrowBigRightLines,
  ArrowForwardUp,
  Check,
  ChevronDown,
  ClipboardList,
  FileArrowLeft,
  FileCheck,
  FileX,
  FileZip,
  FolderPlus,
  Folders,
  HandGrab,
  HandOff,
  Link,
  Lock,
  LockOpen,
  Pencil,
  Send,
  Settings,
  Share,
  Unlink,
  UserPlus,
  X,
} from "tabler-icons-react";

import AssignedDocumentsTable from "../../../../components/assigned-documents-table";
import AttachmentsTable from "../../../../components/attachments-table";
import Button from "../../../../components/button";
import Cell from "../../../../components/cell";
import Content from "../../../../components/content";
import DocumentBarcode from "../../../../components/document-barcode";
import {
  DocumentCommonModalsRenderer,
  DocumentModalName,
} from "../../../../components/document-common-modals-renderer";
import FlowActionModal from "../../../../components/flow-action-modal";
import FlowProgress from "../../../../components/flow-progress";
import FormControl from "../../../../components/form-control";
import FormattedValue from "../../../../components/formatted-value";
import Grid from "../../../../components/grid";
import Header from "../../../../components/header";
import HistoryTable from "../../../../components/history-table";
import {
  DOCUMENT_STATES,
  DOCUMENT_TYPENAMES,
  HIDDEN_FILE,
  NOT_FOUND_ERROR,
} from "../../../../constants";
import { useAssignment } from "../../../../contexts/assignment-context";
import { useAuth } from "../../../../contexts/auth-context";
import { useDictionaries } from "../../../../contexts/dictionaries-context";
import { useLoading } from "../../../../contexts/loading-context";
import { useSubstitution } from "../../../../contexts/substitution-context";
import { FILTERS } from "../../../../filters.d";
import { checkPermission } from "../../../../utils/check-permission";
import {
  handleDataForProgressBar,
  handleHistoryFlowSteps,
} from "../../../../utils/document-flow";
import {
  checkIsDispatchable,
  checkIsShareable,
} from "../../../../utils/documents/assignment-actions";
import { renderMutationSuccessLabel } from "../../../../utils/documents/mutations-snackbar";
import { generateUUID } from "../../../../utils/misc";
import { PERMISSIONS } from "../../../../utils/permissions";
import Error404 from "../../../ErrorPages/404";
import {
  DocumentFlowAction,
  HistoryFlowStep,
} from "../../../Flow/document-flow";
import {
  AssignmentLocationState,
  AssignmentType,
} from "../../../Folders/folders.d";
import {
  DocumentLink,
  DocumentMutationAction,
  DocumentStatus,
} from "../../documents.d";
import {
  DOCUMENT_FLOW_AVAILABLE_ACTIONS,
  DOCUMENTS_ACCEPT,
  DOCUMENTS_CANCEL_DISPATCH,
  DOCUMENTS_RELEASE_RESPONSIBLE,
  DOCUMENTS_SET_RESPONSIBLE,
  DOCUMENTS_TRACK_OPEN,
  DOCUMENTS_UNLINK_FROM_CASE,
  DOCUMENTS_ZIP_EXPORT,
  FILES_REVEAL,
} from "../../documents.gql";
import { BILL_ISSUANCE_REQUEST_FIELDS } from "../bill-issuance-request.form";
import { BILL_ISSUANCE_REQUEST_SHOW } from "../bill-issuance-request.gql";

export default function DocumentsShowBillIssuancenRequest(): React.ReactElement {
  const [isFlowActionModalOpen, setIsFlowActionModalOpen] = useState(false);
  const [targetFlowAction, setTargetFlowAction] = useState<
    DocumentFlowAction | undefined
  >(undefined);

  const [flowStepsHistory, setFlowStepsHistory] = useState<HistoryFlowStep[]>(
    []
  );
  const [statusesForProgressBar, setStatusesForProgressBar] = useState<
    DocumentStatus[]
  >([]);
  const [css, theme] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { id } = useParams<{
    id: string;
  }>();
  const documentId = parseInt(id);
  const [
    openedCommonModal,
    setOpenedCommonModal,
  ] = useState<DocumentModalName>();
  const { refetch: userRefetch, user } = useAuth();
  const { isFetching, setIsFetching, setIsLoading } = useLoading();
  const { findValue } = useDictionaries();
  const { substitution, isSubstitutionActive } = useSubstitution();

  const { assignment, setAssignment, setIsAssignmentActive } = useAssignment();
  const { state } = useLocation<AssignmentLocationState>();

  const assignmentContext = {
    ...((state?.assignmentId || assignment?.id) &&
      state?.assignmentId !== null && {
        context: {
          headers: {
            Assignment: state?.assignmentId || assignment?.id,
          },
        },
      }),
  };

  const { refetch, data, error } = useQuery(BILL_ISSUANCE_REQUEST_SHOW, {
    variables: { id: documentId },
    ...((state?.assignmentId || assignment?.id) &&
      state?.assignmentId !== null && {
        context: {
          headers: {
            Assignment: state?.assignmentId || assignment?.id,
          },
        },
      }),
  });

  const documentLinks = data?.billIssuanceRequest?.documentLinks;

  const parentDocuments = documentLinks?.filter(
    ({ type, linkerId }: DocumentLink) =>
      type === "Parent" && documentId === linkerId
  );

  const childrenDocuments = documentLinks?.filter(
    ({ type, linkedId }: DocumentLink) =>
      type === "Parent" && documentId === linkedId
  );

  const assignedDocuments = documentLinks?.filter(
    ({ type }: DocumentLink) => type === null
  );

  const files = data?.billIssuanceRequest?.files;

  const hasHiddenFiles =
    !!files?.length && files[0]?.__typename === HIDDEN_FILE;

  const parent = data?.contractPreparationRequest?.documentLinks?.find(
    (item: DocumentLink) => item.linked.__typename === "ContractAgreement"
  )?.linked;

  const {
    data: availableActionsData,
    refetch: availableActionsRefetch,
  } = useQuery(DOCUMENT_FLOW_AVAILABLE_ACTIONS, {
    variables: {
      documentId,
    },
  });

  const availableActionsButtons = availableActionsData?.documentFlowAvailableActions?.map(
    (action: DocumentFlowAction) => {
      return {
        label: action.label,
        kind: KIND.primary,
        startEnhancer:
          action.style === "positive" ? (
            <Check size={18} />
          ) : action.style === "negative" ? (
            <X size={18} />
          ) : (
            <Send size={18} />
          ),
        isPositive: action.style === "positive",
        isNegative: action.style === "negative",
        onClick: () => {
          setTargetFlowAction(action);
          setIsFlowActionModalOpen(true);
        },
      };
    }
  );

  const hasFlowSteps = !!data?.billIssuanceRequest?.currentStatus;
  const hasAnySignaturesBefore = statusesForProgressBar?.some(
    (status: DocumentStatus) => !!status.signatureDownloadUrl
  );
  const lastStatusWithSignature = statusesForProgressBar
    ?.filter((status: DocumentStatus) => !!status.signatureDownloadUrl)
    .pop();

  useEffect(() => {
    refetch();
  }, [data?.billIssuanceRequest?.currentStatus]);

  const [acceptDocument] = useMutation(DOCUMENTS_ACCEPT, assignmentContext);

  const [cancelDispatch] = useMutation(DOCUMENTS_CANCEL_DISPATCH);

  const [exportZipFile] = useMutation(DOCUMENTS_ZIP_EXPORT, assignmentContext);

  const [releaseResponsible] = useMutation(
    DOCUMENTS_RELEASE_RESPONSIBLE,
    assignmentContext
  );

  const [revealFiles, { loading: revealFilesLoading }] = useMutation(
    FILES_REVEAL,
    assignmentContext
  );

  const [setResponsible] = useMutation(
    DOCUMENTS_SET_RESPONSIBLE,
    assignmentContext
  );

  const [unlinkCase] = useMutation(
    DOCUMENTS_UNLINK_FROM_CASE,
    assignmentContext
  );

  const [trackOpen] = useMutation(DOCUMENTS_TRACK_OPEN, assignmentContext);

  const documentMutationVariables = {
    variables: {
      documentId,
    },
  };

  const onDocumentMutationSubmit = async (
    action: DocumentMutationAction
  ): Promise<void> => {
    setIsLoading(true);

    try {
      if (action === DocumentMutationAction.Accept)
        await acceptDocument(documentMutationVariables);

      if (action === DocumentMutationAction.CancelDispatch)
        await cancelDispatch(documentMutationVariables);

      if (action === DocumentMutationAction.ExportZip) {
        const response = await exportZipFile(documentMutationVariables);

        const apiUrl = process.env.REACT_APP_GRAPHQL_API_URL?.replace(
          "/graphql",
          ""
        );

        window.open(
          `${apiUrl}/documents/download-zip-export/${id}/${response?.data?.documentExportZip}`,
          "_self"
        );
      }

      if (action === DocumentMutationAction.ReleaseResponsible)
        await releaseResponsible(documentMutationVariables);

      if (action === DocumentMutationAction.RevealFiles)
        await revealFiles(documentMutationVariables);

      if (action === DocumentMutationAction.SetResponsible)
        await setResponsible(documentMutationVariables);

      if (action === DocumentMutationAction.UnlinkCase)
        await unlinkCase(documentMutationVariables);

      enqueueSnackbar({
        message: renderMutationSuccessLabel(action),
        variant: "success",
      });

      refetch();
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
      setOpenedCommonModal(undefined);
    }
  };

  useEffect(() => {
    if (
      error?.graphQLErrors &&
      (error as ApolloError).graphQLErrors?.map(
        ({ extensions }) => extensions?.code
      )[0] !== NOT_FOUND_ERROR
    )
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    if (data?.billIssuanceRequest) refetch();
    if (availableActionsData?.documentFlowAvailableActions)
      availableActionsRefetch();
    setIsFetching(true);
    setStatusesForProgressBar([]);
    trackOpen({ variables: { id: documentId } }).then(() => userRefetch());
  }, []);

  useEffect(() => {
    if (availableActionsData) availableActionsRefetch();
  }, [data?.billIssuanceRequest?.currentStatus]);

  useEffect(() => {
    if (data?.billIssuanceRequest?.currentAssignment) {
      setAssignment(data?.billIssuanceRequest?.currentAssignment);
      setIsAssignmentActive(true);
    }
    setFlowStepsHistory(
      handleHistoryFlowSteps(data?.billIssuanceRequest?.statuses)
    );
    setStatusesForProgressBar(
      handleDataForProgressBar(data?.billIssuanceRequest?.statuses)
    );
    if (data?.billIssuanceRequest) setIsFetching(false);
  }, [data]);

  if (
    !checkPermission(PERMISSIONS.billIssuanceRequest.read) &&
    !checkPermission(PERMISSIONS.document.readAll)
  ) {
    return <Redirect to="/" />;
  } else if (
    error?.graphQLErrors &&
    (error as ApolloError).graphQLErrors?.map(
      ({ extensions }) => extensions?.code
    )[0] === NOT_FOUND_ERROR
  ) {
    return <Error404 />;
  }

  return (
    <article>
      <Header
        title={data?.billIssuanceRequest?.internalNumber}
        labels={[
          <FormattedValue
            key="label_1"
            dataType={"model:cases"}
            data={data?.billIssuanceRequest?.case?.id}
            additionalData={
              data?.billIssuanceRequest?.case?.isConductedElectronically
            }
            showBlankWhenEmpty
          >
            {`${data?.billIssuanceRequest?.case?.number}`}
          </FormattedValue>,
          "Dokument",
        ]}
        goBackOption
        hasActiveAssignmentBar
        hasCancellationBar={
          data?.billIssuanceRequest?.state === DOCUMENT_STATES.Canceled
        }
        buttons={[
          {
            label: "Przejmij",
            startEnhancer: <HandGrab size={18} />,
            kind: KIND.secondary,
            isPositive: true,
            onClick: () =>
              onDocumentMutationSubmit(DocumentMutationAction.SetResponsible),
            permission: data?.billIssuanceRequest?.isResponsibleSettable,
          },
          {
            label: "Zwolnij",
            startEnhancer: <HandOff size={18} />,
            kind: KIND.secondary,
            onClick: () =>
              onDocumentMutationSubmit(
                DocumentMutationAction.ReleaseResponsible
              ),
            permission:
              data?.billIssuanceRequest?.responsible?.id ===
                (isSubstitutionActive
                  ? substitution?.substitutee.id
                  : user?.id) && data?.billIssuanceRequest?.isReleasable,
          },
          {
            label: "Załóż sprawę",
            kind: KIND.primary,
            startEnhancer: <Link size={18} />,
            endEnhancer: <ChevronDown size={18} />,
            permission:
              !data?.billIssuanceRequest?.case?.id &&
              data?.billIssuanceRequest?.isEditable,
            subactions: [
              {
                label: "Załóż nową sprawę",
                icon: FolderPlus,
                color: theme.colors.contentPrimary,
                onClick: () => setOpenedCommonModal("case-create"),
                permission:
                  checkPermission(PERMISSIONS.case.create) &&
                  data?.billIssuanceRequest?.isEditable &&
                  data?.billIssuanceRequest?.state !== DOCUMENT_STATES.Canceled,
              },
              {
                label: "Przypisz do istniejącej",
                icon: Folders,
                color: theme.colors.contentPrimary,
                onClick: () => setOpenedCommonModal("assign-to-case"),
                permission:
                  checkPermission(PERMISSIONS.document.update) &&
                  data?.billIssuanceRequest?.isEditable &&
                  data?.billIssuanceRequest?.state !== DOCUMENT_STATES.Canceled,
              },
            ],
          },
          {
            label: "Udostępnij",
            kind: KIND.primary,
            startEnhancer: <Share size={18} />,
            endEnhancer: <ChevronDown size={18} />,
            subactions: [
              {
                label: "Udostępnij",
                icon: UserPlus,
                color: theme.colors.primary,
                permission: checkIsShareable(data?.billIssuanceRequest),
                onClick: () =>
                  history.push(
                    location.pathname + `/share`,
                    state || data?.billIssuanceRequest?.currentAssignment?.id
                      ? {
                          assignmentId:
                            data.billIssuanceRequest.currentAssignment?.id,
                        }
                      : undefined
                  ),
              },
              {
                label: "Zarządzaj dostępem",
                icon: Settings,
                color: theme.colors.contentPrimary,
                onClick: () =>
                  history.push(
                    `/folders/shared?filters=${window.btoa(
                      unescape(
                        encodeURIComponent(
                          JSON.stringify([
                            {
                              uuid: generateUUID(),
                              filter: {
                                id: "primaryAssignableId",
                                label: "Obiekt",
                                type: FILTERS.PrimaryAssignable,
                              },
                              state: {
                                type: "Document",
                                value: data?.billIssuanceRequest?.id,
                                internalNumber:
                                  data?.billIssuanceRequest?.internalNumber,
                              },
                            },
                            {
                              uuid: generateUUID(),
                              filter: {
                                id: "status",
                                label: "Status",
                                type: FILTERS.AssignmentStatus,
                              },
                              state: {
                                value: [
                                  { id: "New", label: "Nowe" },
                                  { id: "InProgress", label: "W realizacji" },
                                ],
                              },
                            },
                          ])
                        )
                      )
                    )}`
                  ),
              },
            ],
            permission: !data?.billIssuanceRequest?.documentFlowId,
          },
          {
            label: "Przekaż",
            startEnhancer: <ArrowForwardUp size={18} />,
            kind: KIND.primary,
            endEnhancer: <ChevronDown size={18} />,
            subactions: [
              {
                label: "Przekaż dokument",
                icon: ArrowForwardUp,
                color: theme.colors.contentPrimary,
                onClick: () => setOpenedCommonModal("document-dispatch"),
              },
              {
                label: "Zwróć dokument",
                icon: ArrowBack,
                color: theme.colors.negative,
                onClick: () =>
                  setOpenedCommonModal("document-dispatch-with-dispatcher"),

                permission:
                  !!data?.billIssuanceRequest?.currentAssignment?.assigner
                    ?.id &&
                  data?.billIssuanceRequest?.currentAssignment?.type !==
                    AssignmentType.Initial,
              },
            ],
            permission: checkIsDispatchable(data?.billIssuanceRequest),
          },
          {
            label: "Anuluj przekazanie dokumentu",
            startEnhancer: <ArrowBack size={18} />,
            kind: KIND.secondary,
            isNegative: true,
            onClick: () => setOpenedCommonModal("cancel-assignment"),
            permission: data?.billIssuanceRequest?.isDispatchCancelable,
          },
          {
            label: "Akceptuj",
            startEnhancer: <Check size={18} />,
            kind: KIND.primary,
            isPositive: true,
            permission:
              !data?.billIssuanceRequest?.documentFlowId &&
              data?.billIssuanceRequest?.isDispatchable &&
              !!data?.billIssuanceRequest?.currentAssignment
                ?.isAssessmentRequested &&
              data?.billIssuanceRequest?.state !== DOCUMENT_STATES.Canceled,
            onClick: () =>
              setOpenedCommonModal("document-assignment-assessment-accept"),
          },
          {
            label: "Odrzuć",
            startEnhancer: <X size={18} />,
            kind: KIND.primary,
            isNegative: true,
            permission:
              !data?.billIssuanceRequest?.documentFlowId &&
              data?.billIssuanceRequest?.isDispatchable &&
              !!data?.billIssuanceRequest?.currentAssignment
                ?.isAssessmentRequested &&
              data?.billIssuanceRequest?.state !== DOCUMENT_STATES.Canceled,
            onClick: () =>
              setOpenedCommonModal("document-assignment-assessment-reject"),
          },
          {
            label: "Rozpocznij obieg",
            startEnhancer: <ArrowBigRightLines size={18} />,
            kind: KIND.primary,
            isPositive: true,
            permission:
              data?.billIssuanceRequest?.isEditable &&
              !data?.billIssuanceRequest?.documentFlowId &&
              !!data?.billIssuanceRequest?.documentFlow,
            onClick: () => setOpenedCommonModal("document-flow-start"),
          },
          ...(availableActionsButtons || []),
        ]}
        actions={[
          {
            label: "Edytuj dokument",
            icon: Pencil,
            permission:
              checkPermission(PERMISSIONS.billIssuanceRequest.update) &&
              data?.billIssuanceRequest?.isEditable,
            onClick: () =>
              history.push(
                `/documents/internal/BillIssuanceRequest/${id}/edit`,
                state
              ),
          },
          {
            label: "Odepnij dokument od sprawy",
            icon: Unlink,
            permission:
              checkPermission(PERMISSIONS.billIssuanceRequest.update) &&
              data?.billIssuanceRequest?.isEditable &&
              !!data?.billIssuanceRequest?.case &&
              data?.billIssuanceRequest?.responsible?.id === user?.id,
            onClick: () =>
              onDocumentMutationSubmit(DocumentMutationAction.UnlinkCase),
          },
          {
            label: "Akceptuj dokument",
            icon: FileCheck,
            color: theme.colors.positive,
            permission:
              data?.billIssuanceRequest?.isEditable &&
              ((data?.billIssuanceRequest?.isDispatchable &&
                !data?.billIssuanceRequest?.documentFlowId &&
                !data?.billIssuanceRequest?.currentAssignment
                  ?.isAssessmentRequested) ||
                !data?.billIssuanceRequest?.isDispatchable),
            onClick: () =>
              onDocumentMutationSubmit(DocumentMutationAction.Accept),
          },
          {
            label: "Powiąż z dokumentem",
            icon: Link,
            color: theme.colors.primary,
            onClick: () => setOpenedCommonModal("document-link"),
            permission:
              checkPermission(PERMISSIONS.billIssuanceRequest.update) &&
              data?.billIssuanceRequest?.isEditable &&
              data?.billIssuanceRequest?.state !== DOCUMENT_STATES.Canceled,
          },
          {
            label: "Anuluj dokument",
            icon: FileX,
            color: theme.colors.negative,
            onClick: () => setOpenedCommonModal("document-cancel"),
            permission:
              checkPermission(PERMISSIONS.document.cancel) &&
              data?.billIssuanceRequest?.isEditable &&
              (data?.billIssuanceRequest?.state === DOCUMENT_STATES.New ||
                data?.billIssuanceRequest?.currentStatus?.documentFlowStep
                  ?.isInitial),
          },
          {
            label: "Cofnij anulowanie wniosku",
            icon: FileArrowLeft,
            onClick: () => setOpenedCommonModal("document-uncancel"),
            permission:
              checkPermission(PERMISSIONS.document.cancel) &&
              data?.billIssuanceRequest?.state === DOCUMENT_STATES.Canceled,
          },
          {
            label: "Pokaż logi dokumentu",
            icon: ClipboardList,
            color: theme.colors.black,
            onClick: () => setOpenedCommonModal("activity-logs"),
            permission: checkPermission(PERMISSIONS.document.read),
          },
          {
            label: "Pobierz plik ZIP",
            icon: FileZip,
            color: theme.colors.black,
            onClick: () =>
              onDocumentMutationSubmit(DocumentMutationAction.ExportZip),
          },
        ]}
      >
        <DocumentBarcode
          documentId={documentId}
          internalNumber={data?.billIssuanceRequest?.internalNumber}
        />
      </Header>

      <Content>
        <Grid>
          <Cell span={12}>
            <>
              {hasFlowSteps &&
                availableActionsData &&
                (isFetching ? (
                  <Skeleton rows={0} height="150px" width="100%" animation />
                ) : (
                  <FlowProgress
                    currentStep={
                      data?.billIssuanceRequest?.currentStatus?.documentFlowStep
                    }
                    originalOrganizationalUnitName={
                      data?.billIssuanceRequest?.organizationalUnit?.name
                    }
                    statuses={statusesForProgressBar}
                    actions={availableActionsData.documentFlowAvailableActions}
                  />
                ))}
            </>
          </Cell>
          {BILL_ISSUANCE_REQUEST_FIELDS.filter(
            (g) => g.fields.filter((f) => f.show.visible).length > 0
          ).map((group) => [
            group.label && (
              <Cell key={group.id + `-group`} span={12}>
                <LabelMedium marginBottom="scale200" marginTop="scale600">
                  {group.label}
                </LabelMedium>
                <hr
                  className={css({
                    borderWidth: "0px",
                    height: "1px",
                    backgroundColor: "#eee",
                  })}
                />
              </Cell>
            ),
            group.fields
              .filter((f) => f.show.visible)
              .map((item, index) => (
                <Cell
                  span={item.span || 6}
                  skip={item.skip || 0}
                  key={group.id + `-field` + index}
                >
                  {item.id === "remarks" ? (
                    <LabelMedium
                      $style={{ marginBottom: "20px", marginTop: "10px" }}
                    >
                      Potwierdzono bez zastrzeżeń wykonanie prac zgodnie z umową
                    </LabelMedium>
                  ) : (
                    <></>
                  )}
                  <FormControl
                    label={item.label}
                    disabled={true}
                    overrides={{
                      ControlContainer: {
                        props: {
                          "data-test-id": item.id,
                        },
                      },
                    }}
                  >
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : (
                      <FormattedValue
                        dataType={item.dataType}
                        data={
                          item.id === "case"
                            ? data?.billIssuanceRequest?.caseId
                            : item.id === "parentIds"
                            ? `${parent?.documentKind?.toLowerCase()}/${
                                parent?.type
                              }/${parent?.id}`
                            : item.id === "documentRoot"
                            ? `${data?.billIssuanceRequest?.documentRoot?.documentKind?.toLowerCase()}/${
                                data?.billIssuanceRequest?.documentRoot?.type
                              }/${data?.billIssuanceRequest?.documentRoot?.id}`
                            : item.id === "organizationalUnit"
                            ? data?.billIssuanceRequest?.organizationalUnit?.id
                            : item.id === "createdBy" ||
                              item.id === "responsible"
                            ? data?.billIssuanceRequest?.[item.id]?.id
                            : data?.billIssuanceRequest[item.id]
                        }
                        additionalData={
                          data?.billIssuanceRequest?.case?.[item.additionalData]
                        }
                        {...(item.id === "currencies" && {
                          textWhenEmpty: "PLN",
                        })}
                        currency={data?.billIssuanceRequest?.currency?.code}
                      >
                        {item.dataType === "model:cases" &&
                        data?.billIssuanceRequest?.case
                          ? `[${data?.billIssuanceRequest?.case?.number}] ${data?.billIssuanceRequest?.case?.name}`
                          : item.id === "documentType"
                          ? findValue(data?.billIssuanceRequest?.documentType)
                          : item.id === "documentRoot" &&
                            data?.billIssuanceRequest?.documentRoot
                              ?.__typename !== DOCUMENT_TYPENAMES.RequestForm
                          ? ""
                          : item.id === "parentIds"
                          ? parent?.internalNumber
                          : item.id === "createdBy" || item.id === "responsible"
                          ? `${
                              data?.billIssuanceRequest?.[item.id]?.firstName
                            } ${data?.billIssuanceRequest?.[item.id]?.lastName}`
                          : item?.show.accessor
                          ? data?.billIssuanceRequest?.[
                              item.show.accessor[0]
                            ]?.[item.show.accessor[1]]
                          : data?.billIssuanceRequest[item.id]}
                      </FormattedValue>
                    )}
                  </FormControl>
                </Cell>
              )),
          ])}
          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Dokumenty nadrzędne {!parentDocuments?.length && "(0)"}
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={12} $style={{ position: "relative" }}>
            {isFetching ? (
              <Skeleton rows={0} height="300px" width="100%" animation />
            ) : (
              data &&
              !!parentDocuments?.length && (
                <Block paddingTop="scale200" paddingBottom="scale600">
                  <AssignedDocumentsTable
                    documentId={documentId}
                    data={parentDocuments}
                    assignmentId={
                      data.billIssuanceRequest.currentAssignment?.id
                    }
                    onCompleted={refetch}
                  />
                </Block>
              )
            )}
          </Cell>

          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Dokumenty podrzędne {!childrenDocuments?.length && "(0)"}
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={12} $style={{ position: "relative" }}>
            {isFetching ? (
              <Skeleton rows={0} height="300px" width="100%" animation />
            ) : (
              data &&
              !!childrenDocuments?.length && (
                <Block paddingTop="scale200" paddingBottom="scale600">
                  <AssignedDocumentsTable
                    documentId={data?.billIssuanceRequest?.id}
                    data={childrenDocuments}
                    assignmentId={
                      data.billIssuanceRequest.currentAssignment?.id
                    }
                    onCompleted={refetch}
                  />
                </Block>
              )
            )}
          </Cell>

          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Dokumenty powiązane {!assignedDocuments?.length && "(0)"}
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={12} $style={{ position: "relative" }}>
            {isFetching ? (
              <Skeleton rows={0} height="300px" width="100%" animation />
            ) : (
              data &&
              !!assignedDocuments?.length && (
                <Block paddingTop="scale200" paddingBottom="scale600">
                  <AssignedDocumentsTable
                    documentId={documentId}
                    data={assignedDocuments}
                    assignmentId={
                      data.billIssuanceRequest.currentAssignment?.id
                    }
                    onCompleted={refetch}
                  />
                </Block>
              )
            )}
          </Cell>
          {hasFlowSteps && (
            <>
              <Cell span={12}>
                <LabelMedium marginBottom="scale200" marginTop="scale600">
                  Historia obiegu dokumentu{" "}
                  {!flowStepsHistory?.length && "(brak)"}
                </LabelMedium>
                <hr
                  className={css({
                    borderWidth: "0px",
                    height: "1px",
                    backgroundColor: "#eee",
                  })}
                />
              </Cell>
              <Cell span={12}>
                {isFetching ? (
                  <Skeleton rows={0} height="200px" width="100%" animation />
                ) : flowStepsHistory?.length ? (
                  <Block paddingTop="scale200" paddingBottom="scale600">
                    <HistoryTable
                      documentStatuses={data?.billIssuanceRequest?.statuses}
                      flowStepsHistory={flowStepsHistory}
                    />
                  </Block>
                ) : (
                  <></>
                )}
              </Cell>
            </>
          )}
          <Cell span={12}>
            <>
              <Block
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                marginBottom="scale200"
                marginTop="scale600"
              >
                <Block
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  {hasHiddenFiles
                    ? !!files?.length && (
                        <Lock
                          size={18}
                          color={theme.colors.negative}
                          className={css({ marginRight: "10px" })}
                        />
                      )
                    : !!files?.length && (
                        <LockOpen
                          size={18}
                          color={theme.colors.positive}
                          className={css({ marginRight: "10px" })}
                        />
                      )}
                  <LabelMedium>Załączniki {` (${files?.length})`}</LabelMedium>

                  {hasHiddenFiles && (
                    <Button
                      size={SIZE.mini}
                      kind={KIND.secondary}
                      $style={{ marginLeft: "10px" }}
                      onClick={() =>
                        setOpenedCommonModal("confirm-files-reveal")
                      }
                      isLoading={revealFilesLoading}
                    >
                      Pokaż pliki
                    </Button>
                  )}
                </Block>

                {!hasHiddenFiles && !!files?.length && (
                  <FormattedValue dataType="pre" $style={{ fontSize: "13px" }}>
                    Dostęp jest aktywny do końca trwania sesji
                  </FormattedValue>
                )}
              </Block>

              {!!files?.length && (
                <hr
                  className={css({
                    borderWidth: "0px",
                    height: "1px",
                    backgroundColor: "#eee",
                  })}
                />
              )}
            </>
          </Cell>
          <Cell span={12}>
            {isFetching ? (
              <Skeleton rows={0} height="200px" width="100%" animation />
            ) : hasHiddenFiles ? (
              <Skeleton
                rows={0}
                height="20px"
                width="100%"
                overrides={{
                  Root: {
                    style: {
                      backgroundColor: "#faebeb",
                    },
                  },
                }}
              />
            ) : files?.length ? (
              <Block paddingTop="scale200" paddingBottom="scale600">
                <AttachmentsTable data={files} />
              </Block>
            ) : (
              <></>
            )}
          </Cell>

          {targetFlowAction && (
            <FlowActionModal
              documentId={documentId}
              action={targetFlowAction}
              hasAnySignaturesBefore={hasAnySignaturesBefore}
              isOpen={isFlowActionModalOpen}
              label={`Wniosek o wystawienie rachunków do umowy ${data?.billIssuanceRequest?.internalNumber}`}
              lastStatusWithSignature={lastStatusWithSignature}
              reloadPage={() => {
                refetch();
                availableActionsRefetch();
              }}
              setIsOpen={setIsFlowActionModalOpen}
            />
          )}

          <DocumentCommonModalsRenderer
            openedCommonModal={openedCommonModal}
            close={() => setOpenedCommonModal(undefined)}
            documentId={documentId}
            document={data?.billIssuanceRequest}
            refetch={refetch}
            onDocumentMutationSubmit={onDocumentMutationSubmit}
          />
        </Grid>
      </Content>
    </article>
  );
}
