import { gql } from "@apollo/client";

export const SENDING_LISTS_INDEX = gql`
  query(
    $pageSize: Int
    $offset: Int
    $sorting: [ENadawcaSendingListSort!]
    $filter: ENadawcaSendingListFilter
  ) {
    eNadawcaSendingLists(
      paging: { limit: $pageSize, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        eNadawcaBuforId
        eNadawcaUrzadNadania
        isSent
        createdAt
        updatedAt
        sentAt
        idEnvelope
        envelopeStatus
        envelopeFilename
        sendingListItems {
          id
        }
      }
    }
  }
`;

export const SENDING_LISTS_SHOW = gql`
  query($id: Int!) {
    eNadawcaSendingList(id: $id) {
      id
      eNadawcaBuforId
      eNadawcaUrzadNadania
      isSent
      createdAt
      updatedAt
      sentAt
      idEnvelope
      envelopeStatus
      envelopeFilename
      sendingListItems {
        id
        isReadyForShipment
        documentParcel {
          width
          height
          length
          weight
          document {
            id
            internalNumber
            documentType
            documentNumber
            type
            documentKind
          }
          shipmentRate {
            id
            shipmentContractId
            symbol
            name
            price
          }
        }
        shipmentRate {
          id
          shipmentContractId
          symbol
          name
          price
        }
      }
    }
  }
`;

export const SENDING_LIST_ITEM_SHOW = gql`
  query($id: Int!) {
    eNadawcaSendingListItem(id: $id) {
      id
      documentParcel {
        id
        document {
          id
          internalNumber
          documentType
          documentNumber
          type
          documentKind
        }
      }
      documentParcelId
      shipmentRate {
        shipmentContractId
        name
        price
        id
      }
      shipmentRateId
      eNadawcaSendingListId
      addressId
      address {
        __typename
        ... on Address {
          id
          address
          streetName
          buildingNumber
          apartmentNumber
          mobileNumber
          name
          city
          postalCode
          nip
          phoneNumber
          name
          countryCode
          email
          isSensitive
        }
        ... on HiddenAddress {
          id
          name
        }
      }
    }
  }
`;

export const SENDING_LISTS_CREATE = gql`
  mutation($eNadawcaSendingListCreateInput: ENadawcaSendingListCreateInput!) {
    eNadawcaSendingListCreate(
      eNadawcaSendingListCreateInput: $eNadawcaSendingListCreateInput
    ) {
      id
    }
  }
`;

export const SENDING_LISTS_DISPATCH_ITEMS = gql`
  mutation($sendingListId: Int!, $urzadNadania: String!) {
    dispatchAllItemsFromSendingList(
      urzadNadania: $urzadNadania
      sendingListId: $sendingListId
    ) {
      addShipmentResponse {
        retval {
          error {
            attributes {
              errorNumber
              errorDesc
              guid
            }
          }
          attributes {
            numerNadania
            guid
            numerTransakcjiOdbioru
          }
        }
      }
      sendEnvelopeResponse {
        idEnvelope
        envelopeStatus
        error {
          attributes {
            errorNumber
            errorDesc
            guid
          }
        }
        envelopeFilename
      }
    }
  }
`;

export const SENDING_LIST_DELETE = gql`
  mutation($eNadawcaSendingListDeleteInput: ENadawcaSendingListDeleteInput!) {
    removeEnadawcaSendingList(
      eNadawcaSendingListDeleteInput: $eNadawcaSendingListDeleteInput
    ) {
      id
    }
  }
`;

export const SENDING_LISTS_ITEM_UPDATE = gql`
  mutation(
    $eNadawcaSendingListItemUpdateInput: ENadawcaSendingListItemUpdateInput!
  ) {
    eNadawcaSendingListItemUpdate(
      eNadawcaSendingListItemUpdateInput: $eNadawcaSendingListItemUpdateInput
    ) {
      id
    }
  }
`;

export const SENDING_LISTS_ITEM_DELETE = gql`
  mutation(
    $eNadawcaSendingListItemDeleteInput: ENadawcaSendingListItemDeleteInput!
  ) {
    eNadawcaSendingListItemDelete(
      eNadawcaSendingListItemDeleteInput: $eNadawcaSendingListItemDeleteInput
    ) {
      id
    }
  }
`;

export const SENDING_LIST_ITEM_ADDRESS_REVEAL = gql`
  mutation(
    $eNadawcaSendingListItemAddressRevealInput: ENadawcaSendingListItemRevealInput!
  ) {
    eNadawcaSendingListItemAddressReveal(
      eNadawcaSendingListItemAddressRevealInput: $eNadawcaSendingListItemAddressRevealInput
    ) {
      id
    }
  }
`;
