import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { StyledLink } from "baseui/link";
import { PLACEMENT } from "baseui/popover";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { MouseEvent, useEffect, useState } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { Cell as TableCell, Row } from "react-table";
import {
  Bolt,
  Building,
  Click,
  Eye,
  Pencil,
  Pin,
  Plus,
  Trash,
} from "tabler-icons-react";

import Button from "../../../components/button";
import Cell from "../../../components/cell";
import ConfirmDialog from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import DeleteFlowModal from "../../../components/delete-flow-modal";
import FormControl from "../../../components/form-control";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import Table from "../../../components/table";
import Tooltip from "../../../components/tooltip";
import { useDictionaries } from "../../../contexts/dictionaries-context";
import { useLoading } from "../../../contexts/loading-context";
import { checkPermission } from "../../../utils/check-permission";
import { PERMISSIONS } from "../../../utils/permissions";
import { User } from "../../Users/users";
import { DocumentFlowAction, DocumentFlowStep } from "../document-flow";
import { DOCUMENT_FLOW_FIELDS } from "../document-flow.form";
import { DOCUMENT_FLOW, DOCUMENT_FLOW_SET_AS_ACTIVE } from "../flow.gql";

export default function FlowShowProcess(): React.ReactElement {
  const [
    isFirstConfirmDeleteDialogOpen,
    setIsFirstConfirmDeleteDialogOpen,
  ] = useState(false);
  const [
    isSecondConfirmDeleteDialogOpen,
    setIsSecondConfirmDeleteDialogOpen,
  ] = useState(false);
  const [
    isConfirmActivationDialogOpen,
    setIsConfirmActivationDialogOpen,
  ] = useState(false);
  const [css, theme] = useStyletron();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { setIsLoading, isFetching, setIsFetching } = useLoading();
  const { flowId } = useParams<{ flowId?: string }>();
  const { findValue } = useDictionaries();

  const { data, error, refetch } = useQuery(DOCUMENT_FLOW, {
    variables: {
      id: flowId ? parseInt(flowId) : null,
    },
  });

  const [setAsActive] = useMutation(DOCUMENT_FLOW_SET_AS_ACTIVE);

  const activationSubmit = async () => {
    setIsLoading(true);

    try {
      await setAsActive({
        variables: {
          documentFlowSetAsActiveInput: {
            id: flowId ? parseInt(flowId) : null,
          },
        },
      });

      enqueueSnackbar({
        message: "Aktywowano pomyślnie",
        variant: "success",
      });

      refetch();
      setIsConfirmActivationDialogOpen(false);
    } catch (error: unknown) {
      setIsConfirmActivationDialogOpen(false);

      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Lp.",
        id: "ordinalNumber",
        Cell: ({ row }: { row: Row<DocumentFlowStep> }) => (
          <FormattedValue>{row.index + 1}</FormattedValue>
        ),
      },
      {
        Header: "Nazwa kroku",
        accessor: "name",
        Cell: ({ row }: { row: Row<DocumentFlowStep> }) => (
          <StyledLink
            {...(checkPermission(PERMISSIONS.documentFlowStep.read)
              ? {
                  onClick: (event: MouseEvent) => {
                    event.preventDefault();
                    history.push(
                      `/flow/${row?.original?.documentFlowId}/step/${row?.original?.id}`
                    );
                  },
                  href: `/flow/${row?.original?.documentFlowId}/step/${row?.original?.id}`,
                }
              : { $style: { cursor: "not-allowed" } })}
          >
            <FormattedValue>{row?.original?.name}</FormattedValue>
            {row.original.isInitial && (
              <Tooltip content="Początkowy krok" placement={PLACEMENT.right}>
                <span>
                  <Bolt
                    size={16}
                    className={css({
                      verticalAlign: "middle",
                      marginLeft: "6px",
                      display: "inline",
                      color: theme.colors.positive,
                    })}
                  />
                </span>
              </Tooltip>
            )}
          </StyledLink>
        ),
      },
      {
        Header: "Jednostka organizacyjna, grupa lub osoby",
        id: "organizationalUnitOrUsers",
        Cell: ({ row }: { row: Row<DocumentFlowStep> }) =>
          row?.original?.isProcessedByDocumentFlowStarter ? (
            <FormattedValue>{"Rozpoczynający obieg"}</FormattedValue>
          ) : row?.original?.organizationalUnit ? (
            <FormattedValue
              dataType="model:organizational-units"
              data={row.original.organizationalUnitId}
            >
              {row?.original?.organizationalUnit?.name}
            </FormattedValue>
          ) : row?.original?.group ? (
            <FormattedValue dataType="model:groups" data={row.original.groupId}>
              {row?.original?.group?.name}
            </FormattedValue>
          ) : row?.original?.users?.length ? (
            row?.original?.users?.map((user: User) => (
              <Block
                marginBottom="5px"
                key={`${row.original.id}-user-${user.id}`}
              >
                <FormattedValue
                  dataType="model:users"
                  data={user.id.toString()}
                >
                  {`${user?.firstName} ${user?.lastName}`}
                </FormattedValue>
              </Block>
            ))
          ) : (
            <div>
              <Building
                size={16}
                className={css({
                  verticalAlign: "middle",
                  marginRight: "10px",
                  display: "inline",
                })}
              />
              Jednostka dokumentu
            </div>
          ),
      },
      {
        id: "actionResult",
        Header: "Stan dokumentu po wykonaniu akcji",
      },
      {
        id: "flowResult",
        Header: "Stan obiegu po wykonaniu akcji",
      },
      {
        id: "actions",
        Cell: ({ row }: { row: Row<DocumentFlowStep> }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            {checkPermission(PERMISSIONS.documentFlowStep.update) && (
              <Button
                kind={KIND.secondary}
                size={SIZE.mini}
                onClick={() =>
                  history.push(
                    `/flow/${row?.original?.documentFlowId}/step/${row?.original?.id}/edit`
                  )
                }
                startEnhancer={<Pencil size={14} />}
              />
            )}
            {checkPermission(PERMISSIONS.documentFlowStep.read) && (
              <Button
                kind={KIND.secondary}
                size={SIZE.mini}
                onClick={() =>
                  history.push(
                    `/flow/${row?.original?.documentFlowId}/step/${row?.original?.id}`
                  )
                }
                $style={{ marginLeft: "6px" }}
                startEnhancer={<Eye size={14} />}
              />
            )}
          </div>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    if (data?.documentFlow) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => data?.documentFlow && setIsFetching(false), [data]);

  if (!checkPermission(PERMISSIONS.documentFlow.read))
    return <Redirect to="/" />;

  return (
    <article>
      <Header
        title={data?.documentFlow?.name}
        labels={["Obieg dokumentów", "Wyświetlanie"]}
        goBackOption
        buttons={[
          {
            label: "Edytuj",
            kind: KIND.secondary,
            startEnhancer: <Pencil size={18} />,
            permission: checkPermission(PERMISSIONS.documentFlow.update),
            onClick: () =>
              history.push(`/flow/${data?.documentFlow?.id}/edit-process`),
          },
          {
            label: "Dodaj krok",
            kind: KIND.primary,
            startEnhancer: <Plus size={18} />,
            permission: checkPermission(PERMISSIONS.documentFlowStep.create),
            onClick: () =>
              history.push(`/flow/${data?.documentFlow?.id}/step/new`),
          },
          {
            label: "Dodaj akcję",
            kind: KIND.primary,
            startEnhancer: <Click size={18} />,
            permission: checkPermission(PERMISSIONS.documentFlowAction.create),
            onClick: () =>
              history.push(`/flow/${data?.documentFlow?.id}/action/new`),
          },
        ]}
        actions={[
          {
            label: "Ustaw jako aktywny",
            icon: Pin,
            color: theme.colors.positive,
            onClick: () => setIsConfirmActivationDialogOpen(true),
            permission:
              checkPermission(PERMISSIONS.documentFlow.update) &&
              !data?.documentFlow?.isActive,
          },
          {
            label: "Usuń proces",
            icon: Trash,
            color: theme.colors.negative,
            onClick: () => setIsFirstConfirmDeleteDialogOpen(true),
            permission: checkPermission(PERMISSIONS.documentFlow.delete),
          },
        ]}
      />
      <Content>
        <Grid>
          <Cell span={12}>
            {isFetching ? (
              <Skeleton rows={0} height="20px" width="100%" animation />
            ) : (
              <FormattedValue dataType="activity-boolean">
                {data?.documentFlow?.isActive}
              </FormattedValue>
            )}
          </Cell>
          <Block marginBottom="30px" />
          {DOCUMENT_FLOW_FIELDS.filter(
            (g) => g.fields.filter((f) => f.showFlow.visible).length > 0
          ).map((group) => [
            group.label && (
              <Cell key={group.id + `-group`} span={12}>
                <LabelMedium marginBottom="scale200" marginTop="scale600">
                  {group.label}
                </LabelMedium>
                <hr
                  className={css({
                    borderWidth: "0px",
                    height: "1px",
                    backgroundColor: "#eee",
                  })}
                />
              </Cell>
            ),
            group.fields
              .filter((f) => f.showFlow.visible)
              .map((item, index) => (
                <Cell span={item.span || 6} key={group.id + `-field` + index}>
                  <FormControl label={item.label} disabled={true}>
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : (
                      <FormattedValue
                        dataType={item.dataType}
                        data={data?.documentFlow[item.id]}
                      >
                        {item.showFlow.accessor
                          ? data?.documentFlow[item.showFlow.accessor[0]]?.[
                              item.showFlow.accessor[1]
                            ]
                          : item.id === "documentType"
                          ? findValue(data?.documentFlow[item.id])
                          : data?.documentFlow[item.id]}
                      </FormattedValue>
                    )}
                  </FormControl>
                </Cell>
              )),
          ])}
          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Etapy procesu
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={12} $style={{ position: "relative" }}>
            {isFetching ? (
              <Skeleton rows={0} height="300px" width="100%" animation />
            ) : (
              data && (
                <Table<DocumentFlowStep>
                  columns={columns}
                  data={data?.documentFlow?.steps}
                  $rows={(rows, prepareRow) => {
                    return rows
                      .map((row: any) => {
                        prepareRow(row);

                        const actions = data?.documentFlow?.actions.filter(
                          ({ startDocumentFlowStepId }: DocumentFlowAction) =>
                            startDocumentFlowStepId === row.original.id
                        );

                        return actions.length
                          ? [
                              row.cells.map((cell: TableCell) =>
                                cell.render("Cell")
                              ),
                              ...actions.map((action: DocumentFlowAction) => [
                                "",
                                <FormattedValue
                                  key={"action" + row.original.id}
                                  dataType="flow-action"
                                  data={`/flow/${row?.original?.documentFlowId}/action/${action.id}`}
                                >
                                  {action.label}
                                </FormattedValue>,
                                <FormattedValue
                                  key={"end-flow-step" + row.original.id}
                                  dataType="flow-end-step"
                                  data={`/flow/${row?.original?.documentFlowId}/step/${action.endDocumentFlowStepId}`}
                                >
                                  {action.endDocumentFlowStep.name}
                                </FormattedValue>,
                                <FormattedValue
                                  key={
                                    "resulting-document-state" + row.original.id
                                  }
                                  dataType="action-result"
                                >
                                  {action.resultingDocumentState}
                                </FormattedValue>,
                                <FormattedValue
                                  key={"resulting-flow-state" + row.original.id}
                                >
                                  {action.isEndingDocumentFlow
                                    ? "Zakończony"
                                    : "W obiegu"}
                                </FormattedValue>,

                                <div
                                  key={"actions" + row.original.id}
                                  className={css({
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  })}
                                >
                                  {checkPermission(
                                    PERMISSIONS.documentFlowAction.update
                                  ) && (
                                    <Button
                                      kind={KIND.secondary}
                                      size={SIZE.mini}
                                      onClick={() =>
                                        history.push(
                                          `/flow/${row?.original?.documentFlowId}/action/${action.id}/edit`
                                        )
                                      }
                                      startEnhancer={<Pencil size={14} />}
                                    />
                                  )}
                                  {checkPermission(
                                    PERMISSIONS.documentFlowAction.read
                                  ) && (
                                    <Button
                                      kind={KIND.secondary}
                                      size={SIZE.mini}
                                      onClick={() =>
                                        history.push(
                                          `/flow/${row?.original?.documentFlowId}/action/${action.id}`
                                        )
                                      }
                                      $style={{ marginLeft: "6px" }}
                                      startEnhancer={<Eye size={14} />}
                                    />
                                  )}
                                </div>,
                              ]),
                            ]
                          : [
                              row.cells.map((cell: TableCell) =>
                                cell.render("Cell")
                              ),
                            ];
                      })
                      .flat();
                  }}
                  compact
                />
              )
            )}
          </Cell>
          <ConfirmDialog
            isOpen={isFirstConfirmDeleteDialogOpen}
            label={`Usunięcie procesu ${data?.documentFlow?.name}`}
            close={() => setIsFirstConfirmDeleteDialogOpen(false)}
            confirm={() => {
              setIsFirstConfirmDeleteDialogOpen(false);
              setIsSecondConfirmDeleteDialogOpen(true);
            }}
          />
          <DeleteFlowModal
            isOpen={isSecondConfirmDeleteDialogOpen}
            setIsOpen={setIsSecondConfirmDeleteDialogOpen}
            label={data?.documentFlow?.name}
            flowId={flowId}
          />
          <ConfirmDialog
            isOpen={isConfirmActivationDialogOpen}
            label={`Ustawienie procesu ${
              data?.documentFlow?.name
            } jako aktywny dla typu dokumentu ${findValue(
              data?.documentFlow?.documentType
            )}`}
            close={() => setIsConfirmActivationDialogOpen(false)}
            confirm={activationSubmit}
          />
        </Grid>
      </Content>
    </article>
  );
}
