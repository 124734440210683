import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { ProgressBar } from "baseui/progress-bar";
import React from "react";

import { useLoading } from "../contexts/loading-context";

export default function Loading(): React.ReactElement {
  const [, theme] = useStyletron();

  const { isLoading, isFetching } = useLoading();

  return (
    <>
      {isLoading || isFetching ? (
        <ProgressBar
          infinite
          overrides={{
            BarProgress: {
              style: {
                backgroundColor: theme.colors.primary,
                animationDuration: "1200ms",
              },
            },
            BarContainer: {
              style: {
                marginTop: "0px",
                marginLeft: "0px",
                marginRight: "0px",
                marginBottom: "0px",
              },
            },
            Bar: {
              style: {
                borderTopLeftRadius: "0px",
                borderTopRightRadius: "0px",
                borderBottomLeftRadius: "0px",
                borderBottomRightRadius: "0px",
              },
            },
            Root: {
              style: {
                position: "sticky",
                top: "0px",
                left: "0px",
                zIndex: 13,
                margin: "0px",
                transition: "all 0ms ease-in-out",
                ...(isLoading || isFetching
                  ? {
                      transition: "all 300ms ease-in-out",
                      animationIterationCount: "1",
                      animationDelay: "0ms",
                      animationDuration: "200ms",
                      animationFillMode: "both",
                      animationName: {
                        from: {
                          transform: "translateY(-5px)",
                        },
                        to: {
                          transform: "translateY(0px)",
                        },
                      } as any,
                    }
                  : {
                      animationIterationCount: "1",
                      animationDelay: "500ms",
                      animationDuration: "200ms",
                      animationFillMode: "both",
                      animationName: {
                        from: {
                          transform: "translateY(0px)",
                        },
                        to: {
                          transform: "translateY(-5px)",
                        },
                      } as any,
                    }),
              },
            },
          }}
        />
      ) : (
        <Block height="4px"></Block>
      )}
    </>
  );
}
