import { gql } from "@apollo/client";

export const ACTIVITY_LOGS_INDEX = gql`
  query(
    $pageSize: Int
    $offset: Int
    $sorting: [ActivityLogSort!]
    $filter: ActivityLogFilter
  ) {
    activityLogs(
      paging: { limit: $pageSize, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      nodes {
        id
        activity
        description
        properties
        createdAt
        user {
          loginAD
          id
          firstName
          lastName
        }
        substituter {
          id
          firstName
          lastName
        }
        userOrganizationalUnits
        subject {
          __typename
          ... on User {
            id
            firstName
            lastName
          }
          ... on Dictionary {
            id
            systemName
            name
          }
          ... on DictionaryValue {
            id
            name
            dictionaryId
            dictionary {
              name
            }
          }
          ... on FinancialPlan {
            id
            name
          }
          ... on JrwaClassification {
            id
            name
          }
          ... on OrganizationalUnit {
            id
            name
          }
          ... on Position {
            id
            name
          }
          ... on Role {
            id
            name
          }
          ... on Case {
            id
            number
            isConductedElectronically
          }
          ... on Address {
            id
            name
          }
          ... on Document {
            id
            internalNumber
            documentType
            type
            documentKind
          }
          ... on ContractAgreement {
            id
            internalNumber
            documentType
            type
            documentKind
          }
          ... on RequestForm {
            id
            internalNumber
            documentType
            documentNumber
            type
            documentKind
          }
          ... on BudgetItem {
            id
            budget {
              name
            }
          }
          ... on RequestFormItem {
            id
            requestForm {
              id
              internalNumber
              documentNumber
              documentType
              type
              documentKind
            }
          }
          ... on FinancialAccountingDocument {
            id
            internalNumber
            documentType
            type
            documentKind
          }
          ... on BudgetCategory {
            id
            name
          }
          ... on BankAccount {
            id
            address {
              id
              name
            }
          }
          ... on DocumentFlowStep {
            id
            name
            documentFlow {
              id
              name
            }
          }
          ... on DocumentFlow {
            id
            name
          }
          ... on File {
            id
          }
          ... on ShipmentRate {
            id
            shipmentContractId
            name
          }
          ... on ShipmentContract {
            id
            name
          }
          ... on DocumentParcel {
            id
            document {
              id
              internalNumber
              documentType
              type
            }
          }
          ... on Currency {
            id
            name
            code
          }
          ... on FinancialAccountingDocumentItem {
            id
            financialAccountingDocument {
              id
              internalNumber
              documentType
              type
              documentKind
            }
          }
          ... on DocumentFlowAction {
            id
            label
            documentFlowId
          }
          ... on Payment {
            id
            internalNumber
          }
          ... on InternalAccount {
            id
            name
          }
          ... on DivisorTemplate {
            id
            name
          }
          ... on DivisorItem {
            id
            name
            divisorTemplateId
          }
          ... on PaymentItem {
            id
            paymentId
            payment {
              internalNumber
            }
          }
          ... on ContractPreparationRequest {
            id
            internalNumber
            documentType
            type
            documentKind
          }
          ... on BillIssuanceRequest {
            id
            internalNumber
            documentType
            type
            documentKind
          }
          ... on DocumentPickup {
            id
          }
          ... on RequestFormNote {
            id
            internalNumber
            documentType
            type
            documentKind
          }
        }
      }
      totalCount
    }
  }
`;

export const ACTIVITY_LOGS_SHOW = gql`
  query($id: Int!) {
    activityLog(id: $id) {
      id
      activity
      description
      properties
      user {
        id
        loginAD
        id
        firstName
        lastName
      }
      substituter {
        id
        firstName
        lastName
      }
      properties
      createdAt
      userOrganizationalUnits
      assignmentUsers
      subject {
        __typename
        ... on User {
          id
          firstName
          lastName
        }
        ... on Dictionary {
          name
          id
          systemName
        }
        ... on DictionaryValue {
          id
          name
          dictionaryId
          dictionary {
            name
          }
        }
        ... on FinancialPlan {
          id
          name
        }
        ... on JrwaClassification {
          id
          name
        }
        ... on OrganizationalUnit {
          id
          name
        }
        ... on Position {
          id
          name
        }
        ... on Role {
          id
          name
        }
        ... on Case {
          id
          number
          isConductedElectronically
        }
        ... on Address {
          id
          name
        }
        ... on Document {
          id
          internalNumber
          documentType
          type
          documentKind
        }
        ... on ContractAgreement {
          id
          internalNumber
          documentType
          type
          documentKind
        }
        ... on RequestForm {
          id
          internalNumber
          documentType
          documentNumber
          type
          documentKind
        }
        ... on BudgetItem {
          id
          budget {
            name
          }
        }
        ... on RequestFormItem {
          id
          requestForm {
            id
            internalNumber
            documentNumber
            documentType
            type
            documentKind
          }
        }
        ... on FinancialAccountingDocument {
          id
          internalNumber
          documentType
          type
          documentKind
        }
        ... on BudgetCategory {
          id
          name
        }
        ... on BankAccount {
          id
          address {
            id
            name
          }
        }
        ... on DocumentFlowStep {
          id
          name
          documentFlow {
            id
          }
        }
        ... on DocumentFlow {
          id
          name
        }
        ... on File {
          id
        }
        ... on ShipmentRate {
          id
          shipmentContractId
          name
        }
        ... on ShipmentContract {
          id
          name
        }
        ... on DocumentParcel {
          id
          document {
            id
            internalNumber
            documentType
            type
          }
        }
        ... on Currency {
          id
          name
          code
        }
        ... on FinancialAccountingDocumentItem {
          id
          financialAccountingDocument {
            id
            internalNumber
            documentType
            type
            documentKind
          }
        }
        ... on DocumentFlowAction {
          id
          label
          documentFlowId
        }
        ... on Payment {
          id
          internalNumber
        }
        ... on InternalAccount {
          id
          name
        }
        ... on DivisorTemplate {
          id
          name
        }
        ... on DivisorItem {
          id
          name
          divisorTemplateId
        }
        ... on PaymentItem {
          paymentId
          payment {
            internalNumber
          }
        }
        ... on ContractPreparationRequest {
          id
          internalNumber
          documentType
          type
          documentKind
        }
        ... on BillIssuanceRequest {
          id
          internalNumber
          documentType
          type
          documentKind
        }
        ... on DocumentPickup {
          id
        }
        ... on RequestFormNote {
          id
          internalNumber
          documentType
          type
          documentKind
        }
      }
    }
  }
`;

export const ACTIVITY_LOGS_EXPORT_TO_XLSX = gql`
  mutation($filter: ActivityLogFilter, $sorting: [ActivityLogSort!]) {
    activityLogsExportToXlsx(
      paging: { limit: 10000 }
      sorting: $sorting
      filter: $filter
    ) {
      downloadUrl
    }
  }
`;
