import { gql } from "@apollo/client";

export const CURRENCIES_INDEX = gql`
  query(
    $pageSize: Int
    $offset: Int
    $sorting: [CurrencySort!]
    $filter: CurrencyFilter
  ) {
    currencies(
      paging: { limit: $pageSize, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        name
        code
        isActive
        isVisible
        exchangeRates {
          id
          effectiveOn
          value
          currencyId
        }
      }
    }
  }
`;

export const CURRENCIES_SHOW = gql`
  query($id: Int!) {
    currency(id: $id) {
      id
      name
      code
      isActive
      isVisible
      exchangeRates {
        id
        effectiveOn
        value
        currencyId
      }
    }
  }
`;

export const CURRENCIES_SELECT_INDEX = gql`
  query($sorting: [CurrencySort!], $filter: CurrencyFilter) {
    currencies(paging: { limit: 10000 }, sorting: $sorting, filter: $filter) {
      totalCount
      nodes {
        id
        name
        code
        isActive
        isVisible
        exchangeRates {
          id
          effectiveOn
          value
          currencyId
        }
      }
    }
  }
`;

export const CURRENCIES_UPDATE = gql`
  mutation($currencyUpdateInput: CurrencyUpdateInput!) {
    currencyUpdate(currencyUpdateInput: $currencyUpdateInput) {
      id
    }
  }
`;

export const CURRENCIES_SYNCHRONIZATION = gql`
  mutation {
    currencyExchangeRatesSynchronizationRequest
  }
`;

export const GET_LAST_SYNCHRONIZATION_DATE = gql`
  query {
    activityLogs(
      filter: { activity: { eq: "currencyExchangeRatesSynchronized" } }
      paging: { limit: 1 }
      sorting: { field: createdAt, direction: DESC }
    ) {
      nodes {
        createdAt
      }
    }
  }
`;

export const EURO_RATE_FROM_REGULATION = gql`
  query {
    euroRateFromRegulation
  }
`;
