import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { KIND } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { Pencil, Trash } from "tabler-icons-react";

import Cell from "../../../components/cell";
import ConfirmDialog from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import FormControl from "../../../components/form-control";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import { useDictionaries } from "../../../contexts/dictionaries-context";
import { useLoading } from "../../../contexts/loading-context";
import { checkPermission } from "../../../utils/check-permission";
import { PERMISSIONS } from "../../../utils/permissions";
import {
  SHIPMENT_RATES_DELETE,
  SHIPMENT_RATES_SHOW,
} from "../shipment-contracts.gql";
import { SHIPMENT_RATES_FIELDS } from "../shipment-rates.form";

export default function ShipmentContractsShowRate(): React.ReactElement {
  const [css, theme] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const history = useHistory();
  const { contractId, rateId } = useParams<{
    contractId?: string;
    rateId?: string;
  }>();
  const { setIsLoading, isFetching, setIsFetching } = useLoading();
  const { findValue } = useDictionaries();

  const { refetch, data, error } = useQuery(SHIPMENT_RATES_SHOW, {
    variables: { id: rateId ? parseInt(rateId) : null },
  });
  const [deleteShipmentRate] = useMutation(SHIPMENT_RATES_DELETE);

  const submit = async () => {
    setIsLoading(true);

    try {
      await deleteShipmentRate({
        variables: {
          shipmentRateDeleteInput: {
            id: rateId ? parseInt(rateId) : null,
          },
        },
      });

      enqueueSnackbar({
        message: "Usunięto pomyślnie",
        variant: "success",
      });

      setIsConfirmDialogOpen(false);

      history.push(`/shipment-contracts/${contractId}`);
    } catch (error: unknown) {
      setIsConfirmDialogOpen(false);

      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    if (data?.shipmentRate) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => data?.shipmentRate && setIsFetching(false), [data]);

  if (!checkPermission(PERMISSIONS.shipmentRate.read))
    return <Redirect to="/" />;

  return (
    <article>
      <Header
        title={data?.shipmentRate?.name}
        labels={["Cenniki", "Wyświetlanie stawki"]}
        goBackOption
        buttons={[
          {
            label: "Edytuj",
            kind: KIND.secondary,
            startEnhancer: <Pencil size={18} />,
            permission: checkPermission(PERMISSIONS.shipmentRate.update),
            onClick: () =>
              history.push(
                `/shipment-contracts/${contractId}/${rateId}/edit-rate`
              ),
          },
        ]}
        actions={[
          {
            label: "Usuń stawkę",
            icon: Trash,
            color: theme.colors.negative,
            onClick: () => setIsConfirmDialogOpen(true),
            permission: checkPermission(PERMISSIONS.shipmentRate.delete),
          },
        ]}
      />
      <Content>
        <Grid>
          {SHIPMENT_RATES_FIELDS.filter(
            (g) => g.fields.filter((f) => f.show.visible).length > 0
          ).map((group) => [
            group.label && (
              <Cell key={group.id + `-group`} span={12}>
                <LabelMedium marginBottom="scale200" marginTop="scale600">
                  {group.label}
                </LabelMedium>
                <hr
                  className={css({
                    borderWidth: "0px",
                    height: "1px",
                    backgroundColor: "#eee",
                  })}
                />
              </Cell>
            ),
            group.fields
              .filter((f) => f.show.visible)
              .map((item, index) => (
                <Cell span={item.span || 6} key={group.id + `-field` + index}>
                  <FormControl label={item.label} disabled={true}>
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : (
                      <FormattedValue
                        dataType={item.dataType}
                        data={data?.shipmentRate[item.id]}
                      >
                        {item.show.accessor
                          ? data?.shipmentRate[item.show.accessor[0]]?.[
                              item.show.accessor[1]
                            ]
                          : item.id === "width"
                          ? `${data?.shipmentRate?.widthMin} - ${data?.shipmentRate?.widthMax}`
                          : item.id === "height"
                          ? `${data?.shipmentRate?.heightMin} - ${data?.shipmentRate?.heightMax}`
                          : item.id === "length"
                          ? `${data?.shipmentRate?.lengthMin} - ${data?.shipmentRate?.lengthMax}`
                          : item.id === "weight"
                          ? `${data?.shipmentRate?.weightMin} - ${data?.shipmentRate?.weightMax}`
                          : item.id === "countryCodes"
                          ? data?.shipmentRate?.countryCodes
                              ?.map(
                                (code: string) => `${findValue(code)} (${code})`
                              )
                              ?.join(", ")
                          : data?.shipmentRate[item.id]}
                      </FormattedValue>
                    )}
                  </FormControl>
                </Cell>
              )),
          ])}
          <ConfirmDialog
            isOpen={isConfirmDialogOpen}
            label={`Usunięcie stawki ${data?.shipmentRate?.name}`}
            close={() => setIsConfirmDialogOpen(false)}
            confirm={submit}
          />
        </Grid>
      </Content>
    </article>
  );
}
