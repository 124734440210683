import { ApolloError, useMutation } from "@apollo/client";
import { useStyletron } from "baseui";
import { KIND } from "baseui/button";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Redirect, useHistory } from "react-router-dom";
import { AlertOctagon, DeviceFloppy, FileOff, Note } from "tabler-icons-react";

import { InputValidationError } from "../../../api";
import Cell from "../../../components/cell";
import { ControlledCheckbox } from "../../../components/checkbox";
import ConfirmDialog from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import { ControlledDatePicker } from "../../../components/date-picker";
import FormControl from "../../../components/form-control";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import { ControlledInput } from "../../../components/input";
import {
  ControlledContractsSelect,
  ControlledShipmentsApiSelect,
} from "../../../components/select";
import { useLoading } from "../../../contexts/loading-context";
import { FIELDS } from "../../../fields.d";
import { checkPermission } from "../../../utils/check-permission";
import { formValidation } from "../../../utils/formValidation";
import { PERMISSIONS } from "../../../utils/permissions";
import {
  FormInputs,
  SHIPMENT_CONTRACTS_FIELDS,
} from "../shipment-contracts.form";
import { SHIPMENT_CONTRACTS_CREATE } from "../shipment-contracts.gql";

export default function ShipmentContractsCreateContract(): React.ReactElement {
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const [isClearConfirmDialogOpen, setIsClearConfirmDialogOpen] = useState(
    false
  );
  const [css] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { isLoading, setIsLoading } = useLoading();

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    reset,
    watch,
    setValue,
  } = useForm<FormInputs>({
    defaultValues: {
      name: "",
      description: "",
      companyName: "",
      isActive: false,
      contractAgreement: undefined,
    },
  });

  const watchFields = watch();

  const [createShipmentContract, { error, loading }] = useMutation(
    SHIPMENT_CONTRACTS_CREATE
  );

  const onSubmit = async ({
    beginAt,
    endAt,
    name,
    description,
    companyName,
    isActive,
    contractAgreement,
  }: FormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      const response = await createShipmentContract({
        variables: {
          shipmentContractCreateInput: {
            name,
            beginAt,
            endAt,
            description,
            companyName,
            isActive: !!isActive,
            ...(contractAgreement &&
              !!contractAgreement.length && {
                contractAgreementId: contractAgreement[0].id,
              }),
          },
        },
      });

      enqueueSnackbar({
        message: "Utworzono pomyślnie",
        variant: "success",
      });

      history.push(
        `/shipment-contracts/${response.data.shipmentContractCreate.id}`
      );
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setValue("shipmentApiSelect", [{ id: 1 }]);
  }, []);

  if (!checkPermission(PERMISSIONS.shipmentContract.create))
    return <Redirect to="/" />;

  return (
    <article>
      <Header
        title="Nowy cennik"
        labels={["Cenniki", "Tworzenie"]}
        goBackOption
        buttons={[
          {
            label: "Wyczyść",
            kind: KIND.secondary,
            startEnhancer: <Note size={18} />,
            disabled: isLoading,
            onClick: () => {
              setIsClearConfirmDialogOpen(true);
            },
          },
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              isDirty ? setIsCancelConfirmDialogOpen(true) : history.goBack(),
          },
          {
            label: "Zapisz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            onClick: handleSubmit(onSubmit),
            disabled: isLoading,
            isLoading: isLoading,
            type: "submit",
            formId: "createShipmentContract",
          },
        ]}
      />
      <Content>
        <form id="createShipmentContract" onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            {SHIPMENT_CONTRACTS_FIELDS.filter(
              (g) => g.fields.filter((f) => f.create.visible).length > 0
            ).map((group) => [
              group.label && (
                <Cell key={group.id + `-group`} span={12}>
                  <LabelMedium marginBottom="scale200" marginTop="scale600">
                    {group.label}
                  </LabelMedium>
                  <hr
                    className={css({
                      borderWidth: "0px",
                      height: "1px",
                      backgroundColor: "#eee",
                    })}
                  />
                </Cell>
              ),
              group.fields
                .filter((f) => f.create.visible)
                .map((item, index) => (
                  <Cell span={item.span || 6} key={group.id + `-field` + index}>
                    <FormControl
                      label={item.type === FIELDS.Checkbox ? "" : item.label}
                      required={item.create.required}
                      error={
                        ((errors as any)[item.id] &&
                          (errors as any)[item.id].message) ||
                        (error &&
                          error.graphQLErrors[0]?.extensions?.code ===
                            "INPUT_VALIDATION_ERROR" &&
                          error.graphQLErrors[0]?.extensions?.validationErrors
                            ?.find((vE: InputValidationError) =>
                              item.id.includes("address")
                                ? vE?.property ===
                                  item.id.slice(7).toLowerCase()
                                : vE?.property === item.id
                            )
                            ?.errors.map((message: string) => (
                              <div
                                key="error"
                                className={css({
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                })}
                              >
                                {message}
                                <AlertOctagon color="#999" size={12} />
                              </div>
                            ))[0])
                      }
                      disabled={isLoading}
                    >
                      {item.type === FIELDS.ContractAgreementSelect ? (
                        <ControlledContractsSelect
                          control={control}
                          name={item.id}
                          placeholder="Wybierz"
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.Checkbox ? (
                        <div className={css({ marginTop: "30px" })}>
                          <ControlledCheckbox
                            control={control}
                            name={item.id}
                            disabled={loading}
                            {...(item.create.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                          >
                            {item.label}
                          </ControlledCheckbox>
                        </div>
                      ) : item.type === FIELDS.DatePicker ? (
                        <ControlledDatePicker
                          control={control}
                          name={item.id}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                          {...(item.id === "endAt" && {
                            minDate: (watchFields.beginAt as unknown) as Date,
                          })}
                          {...(item.id === "beginAt" && {
                            maxDate: (watchFields.endAt as unknown) as Date,
                          })}
                        />
                      ) : item.type === FIELDS.ShipmentApiSelect ? (
                        <ControlledShipmentsApiSelect
                          control={control}
                          name={item.id}
                          placeholder="Wybierz"
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : (
                        <ControlledInput
                          control={control}
                          name={item.id}
                          placeholder={item.placeholder}
                          masked={item.mask !== undefined}
                          mask={item.mask}
                          maskChar="_"
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      )}
                    </FormControl>
                  </Cell>
                )),
            ])}
            <ConfirmDialog
              isOpen={isCancelConfirmDialogOpen}
              label="Anulowanie tworzenia nowego cennika"
              close={() => setIsCancelConfirmDialogOpen(false)}
              confirm={() => history.goBack()}
            />
            <ConfirmDialog
              isOpen={isClearConfirmDialogOpen}
              label="Wyczyszczenie formularza"
              close={() => setIsClearConfirmDialogOpen(false)}
              confirm={() => {
                reset();
                setIsClearConfirmDialogOpen(false);
              }}
            />
          </Grid>
        </form>
      </Content>
    </article>
  );
}
