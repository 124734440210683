import { BasicFilter, FILTERS } from "./../../filters.d";

export const CASES_FILTERS: BasicFilter[] = [
  {
    id: "number",
    label: "Znak sprawy",
    type: FILTERS.Text,
  },
  {
    id: "name",
    label: "Tytuł",
    type: FILTERS.Text,
  },
  {
    id: "folderNumber",
    label: "Teczka",
    type: FILTERS.Text,
  },
  {
    id: "status",
    label: "Status",
    type: FILTERS.CaseStatus,
  },
  {
    id: "initiatedAt",
    label: "Data otwarcia",
    type: FILTERS.Date,
  },
  {
    id: "closedAt",
    label: "Data zamknięcia",
    type: FILTERS.Date,
  },
  {
    id: "isConductedElectronically",
    label: "Sposób prowadzenia sprawy",
    type: FILTERS.CaseConduct,
  },
  {
    id: "supervisorId",
    label: "Prowadzący sprawę",
    type: FILTERS.User,
  },
];
