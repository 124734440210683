import { ApolloError, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { KIND, SIZE } from "baseui/button";
import { StyledLink } from "baseui/link";
import { PLACEMENT } from "baseui/popover";
import { useSnackbar } from "notistack";
import React, { MouseEvent, useEffect, useMemo, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { Cell as TableCell, Row } from "react-table";
import {
  ChevronDown,
  ChevronRight,
  Crown,
  Eye,
  Pencil,
  Plus,
} from "tabler-icons-react";

import Button from "../../../components/button";
import Cell from "../../../components/cell";
import Content from "../../../components/content";
import Filters from "../../../components/filters";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import { SortDirection } from "../../../components/sorting-table-header";
import Table from "../../../components/table";
import BaseTableCell from "../../../components/table-cell";
import Tooltip from "../../../components/tooltip";
import { useLoading } from "../../../contexts/loading-context";
import { FiltersState, SingleFilter } from "../../../filters";
import { checkPermission } from "../../../utils/check-permission";
import { translateFiltersState } from "../../../utils/filters";
import { PERMISSIONS } from "../../../utils/permissions";
import { sortColumnsByOrder } from "../../../utils/table";
import { OrganizationalUnit } from "../organizational-units";
import { ORGANIZATIONAL_UNITS_FILTERS } from "../organizational-units.filters";
import { ORGANIZATIONAL_UNITS_INDEX } from "../organizational-units.gql";

export default function OrganizationalUnitsIndex(): React.ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const [css, theme] = useStyletron();
  const { isFetching, setIsFetching } = useLoading();
  const history = useHistory();
  const [filters, setFilters] = useState<FiltersState>();

  const { refetch, data, error, loading } = useQuery(
    ORGANIZATIONAL_UNITS_INDEX,
    {
      variables: {
        ...(filters && { filter: translateFiltersState(filters) }),
      },
    }
  );

  useEffect(() => {
    if (data?.organizationalUnits) setTimeout(() => refetch(), 200);
    setIsFetching(true);
  }, []);

  useEffect(() => {
    if (data?.organizationalUnits) setIsFetching(false);
  }, [data]);

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  const shouldHaveTreeStructure =
    !filters?.length ||
    filters?.some((filter: SingleFilter) => filter.state.iLike === "");

  const columns = useMemo(
    () => [
      {
        Header: "Nazwa",
        id: "name",
        Cell: ({ row }: { row: Row<OrganizationalUnit> }) =>
          shouldHaveTreeStructure ? (
            <>
              {row.canExpand && (
                <span {...row.getToggleRowExpandedProps()}>
                  {row.isExpanded ? (
                    <ChevronDown
                      size={16}
                      className={css({
                        verticalAlign: "middle",
                        marginRight: "6px",
                        display: "inline",
                        marginLeft: `${row.depth * 16}px`,
                      })}
                    />
                  ) : (
                    <ChevronRight
                      size={16}
                      className={css({
                        verticalAlign: "middle",
                        marginRight: "6px",
                        display: "inline",
                        marginLeft: `${row.depth * 16}px`,
                      })}
                    />
                  )}

                  <StyledLink
                    onClick={(event: MouseEvent) => {
                      event.preventDefault();
                    }}
                  >
                    {row.original.name}
                  </StyledLink>
                  {row.original.parentId === null && (
                    <Tooltip content="Organizacja" placement={PLACEMENT.right}>
                      <span>
                        <Crown
                          size={16}
                          className={css({
                            verticalAlign: "middle",
                            marginLeft: "6px",
                            display: "inline",
                            color: theme.colors.warning500,
                          })}
                        />
                      </span>
                    </Tooltip>
                  )}
                </span>
              )}
            </>
          ) : (
            <StyledLink
              onClick={(event: MouseEvent) => {
                event.preventDefault();
                history.push(`/organizational-units/${row.original.id}`);
              }}
              href={`/organizational-units/${row.original.id}`}
            >
              {row.original.name}
            </StyledLink>
          ),
      },
      {
        Header: "Symbol jednostki",
        accessor: "symbol",
      },
      {
        Header: "Symbol budżetowy",
        accessor: "budgetSymbol",
      },
      {
        id: "actions",
        Cell: ({ row }: { row: Row<OrganizationalUnit> }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            {checkPermission(PERMISSIONS.organizationalUnit.update) && (
              <Button
                kind={KIND.secondary}
                size={SIZE.mini}
                $as="a"
                href={`/organizational-units/${row.original.id}/edit`}
                onClick={(event: MouseEvent) => {
                  event.preventDefault();
                  history.push(`/organizational-units/${row.original.id}/edit`);
                }}
                startEnhancer={<Pencil size={14} />}
              />
            )}

            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              $as="a"
              href={`/organizational-units/${row.original.id}`}
              onClick={(event: MouseEvent) => {
                event.preventDefault();
                history.push(`/organizational-units/${row.original.id}`);
              }}
              $style={{ marginLeft: "6px" }}
              startEnhancer={<Eye size={14} />}
            />
          </div>
        ),
      },
    ],
    [filters, SortDirection, data]
  );

  if (!checkPermission(PERMISSIONS.organizationalUnit.read))
    return <Redirect to="/" />;

  return (
    <article>
      <Header
        title="Jednostki organizacyjne"
        recordsNum={data?.organizationalUnits?.totalCount}
        labels={["Lista"]}
      />
      <Filters
        filters={ORGANIZATIONAL_UNITS_FILTERS}
        state={filters}
        setState={setFilters}
      />
      <Content filtersOffset>
        <Grid>
          <Cell span={12} $style={{ position: "relative" }}>
            <Table<OrganizationalUnit>
              columns={columns}
              isLoading={isFetching || loading}
              expandedNumber={100}
              data={(data?.organizationalUnits?.nodes as OrganizationalUnit[])
                ?.slice()
                ?.sort((a, b) =>
                  a.parentId
                    ? b.parentId
                      ? a.parentId > b.parentId
                        ? -1
                        : 1
                      : 0
                    : -1
                )}
              {...(shouldHaveTreeStructure && {
                $rows: (rows, prepareRow, visibleColumns, orderedColumns) => {
                  const nestChildrenUnits = (
                    unit: OrganizationalUnit,
                    depth: number
                  ): Row<OrganizationalUnit>[] => {
                    return rows.filter(
                      (row) =>
                        (row.original as OrganizationalUnit).parentId ===
                        unit?.id
                    )?.length > 0
                      ? rows
                          .filter(
                            (row) =>
                              (row.original as OrganizationalUnit).parentId ===
                              unit?.id
                          )
                          .map((row: any) => {
                            row.depth = depth;
                            row.canExpand = true;

                            prepareRow(row);
                            return row.isExpanded
                              ? [
                                  row.cells
                                    .filter(
                                      (cell: TableCell) =>
                                        visibleColumns.includes(
                                          cell.column.id
                                        ) ||
                                        cell.column.id === "actions" ||
                                        cell.column.id === "checkbox"
                                    )
                                    .sort(
                                      (
                                        { column: a }: TableCell,
                                        { column: b }: TableCell
                                      ) =>
                                        sortColumnsByOrder(a, b, orderedColumns)
                                    )
                                    .map((cell: TableCell) => (
                                      <BaseTableCell
                                        key={cell.column.id}
                                        cell={cell}
                                      />
                                    )),
                                  ...nestChildrenUnits(row.original, depth + 1),
                                  row.cells
                                    .filter(
                                      (cell: TableCell) =>
                                        visibleColumns.includes(
                                          cell.column.id
                                        ) ||
                                        cell.column.id === "actions" ||
                                        cell.column.id === "checkbox"
                                    )
                                    .sort(
                                      (
                                        { column: a }: TableCell,
                                        { column: b }: TableCell
                                      ) =>
                                        sortColumnsByOrder(a, b, orderedColumns)
                                    )
                                    .map((cell: TableCell) => (
                                      <div
                                        key={cell.column.id}
                                        className={css({
                                          position: "absolute",
                                          left: "10px",
                                          right: "10px",
                                          top: "8px",
                                          bottom: "8px",
                                          display: "flex",
                                          alignItems: "center",
                                          verticalAlign: "middle",
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        })}
                                      >
                                        {checkPermission(
                                          PERMISSIONS.organizationalUnit.create
                                        ) &&
                                          cell.column.id === "name" && (
                                            <>
                                              <Plus
                                                color="#999999"
                                                size={16}
                                                className={css({
                                                  verticalAlign: "middle",
                                                  display: "inline",
                                                  marginLeft: `${
                                                    (row.depth + 1) * 16
                                                  }px`,
                                                  marginRight: "6px",
                                                  flexShrink: 0,
                                                })}
                                              />
                                              <StyledLink
                                                key="add"
                                                href={`/organizational-units/${row.original.id}/new`}
                                                onClick={(
                                                  event: MouseEvent
                                                ) => {
                                                  event.preventDefault();
                                                  history.push(
                                                    `/organizational-units/${row.original.id}/new`
                                                  );
                                                }}
                                                $style={{
                                                  textDecorationLine: "unset",
                                                }}
                                              >
                                                Dodaj
                                              </StyledLink>
                                            </>
                                          )}
                                      </div>
                                    )),
                                ]
                              : [
                                  row.cells
                                    .filter(
                                      (cell: TableCell) =>
                                        visibleColumns.includes(
                                          cell.column.id
                                        ) ||
                                        cell.column.id === "actions" ||
                                        cell.column.id === "checkbox"
                                    )
                                    .sort(
                                      (
                                        { column: a }: TableCell,
                                        { column: b }: TableCell
                                      ) =>
                                        sortColumnsByOrder(a, b, orderedColumns)
                                    )
                                    .map((cell: TableCell) => (
                                      <BaseTableCell
                                        key={cell.column.id}
                                        cell={cell}
                                      />
                                    )),
                                ];
                          })
                          .flat()
                      : [];
                  };

                  return rows
                    .filter(
                      (row) => !(row.original as OrganizationalUnit).parentId
                    )
                    .map((row: any) => {
                      row.canExpand = true;
                      prepareRow(row);
                      return row.isExpanded
                        ? [
                            row.cells
                              .filter(
                                (cell: TableCell) =>
                                  visibleColumns.includes(cell.column.id) ||
                                  cell.column.id === "actions" ||
                                  cell.column.id === "checkbox"
                              )
                              .sort(
                                (
                                  { column: a }: TableCell,
                                  { column: b }: TableCell
                                ) => sortColumnsByOrder(a, b, orderedColumns)
                              )
                              .map((cell: TableCell) => (
                                <BaseTableCell
                                  key={cell.column.id}
                                  cell={cell}
                                />
                              )),
                            ...nestChildrenUnits(row.original, 1),
                            row.cells
                              .filter(
                                (cell: TableCell) =>
                                  visibleColumns.includes(cell.column.id) ||
                                  cell.column.id === "actions" ||
                                  cell.column.id === "checkbox"
                              )
                              .sort(
                                (
                                  { column: a }: TableCell,
                                  { column: b }: TableCell
                                ) => sortColumnsByOrder(a, b, orderedColumns)
                              )
                              .map((cell: TableCell) => (
                                <div
                                  key={cell.column.id}
                                  className={css({
                                    position: "absolute",
                                    left: "10px",
                                    right: "10px",
                                    top: "8px",
                                    bottom: "8px",
                                    display: "flex",
                                    alignItems: "center",
                                    verticalAlign: "middle",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  })}
                                >
                                  {checkPermission(
                                    PERMISSIONS.organizationalUnit.create
                                  ) &&
                                    cell.column.id === "name" && (
                                      <>
                                        <Plus
                                          color="#999999"
                                          size={16}
                                          className={css({
                                            verticalAlign: "middle",
                                            display: "inline",
                                            marginLeft: `${
                                              (row.depth + 1) * 16
                                            }px`,
                                            marginRight: "6px",
                                            flexShrink: 0,
                                          })}
                                        />
                                        <StyledLink
                                          key="add"
                                          href={`/organizational-units/${row.original.id}/new`}
                                          onClick={(event: MouseEvent) => {
                                            event.preventDefault();
                                            history.push(
                                              `/organizational-units/${row.original.id}/new`
                                            );
                                          }}
                                          $style={{
                                            textDecorationLine: "unset",
                                          }}
                                        >
                                          Dodaj
                                        </StyledLink>
                                      </>
                                    )}
                                </div>
                              )),
                          ]
                        : [
                            row.cells
                              .filter(
                                (cell: TableCell) =>
                                  visibleColumns.includes(cell.column.id) ||
                                  cell.column.id === "actions" ||
                                  cell.column.id === "checkbox"
                              )
                              .sort(
                                (
                                  { column: a }: TableCell,
                                  { column: b }: TableCell
                                ) => sortColumnsByOrder(a, b, orderedColumns)
                              )
                              .map((cell: TableCell) => (
                                <BaseTableCell
                                  key={cell.column.id}
                                  cell={cell}
                                />
                              )),
                          ];
                    })
                    .flat();
                },
              })}
            />
          </Cell>
        </Grid>
      </Content>
    </article>
  );
}
