import { ApolloError, useQuery } from "@apollo/client";
import { KIND } from "baseui/button";
import { useSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";
import { Redirect, useHistory, useParams } from "react-router";
import { useLocation } from "react-router-dom";
import { ArrowBigRight, FileOff } from "tabler-icons-react";

import BottomPanel from "../../../components/bottom-panel";
import Cell from "../../../components/cell";
import ConfirmDialog from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import DocumentPickupModal from "../../../components/document-pickup-modal";
import DocumentsCheckboxTable, {
  FieldName,
} from "../../../components/documents-checkbox-table";
import Filters from "../../../components/filters";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import PagingControls from "../../../components/paging-controls";
import { SortDirection } from "../../../components/sorting-table-header";
import { useLoading } from "../../../contexts/loading-context";
import { usePaging } from "../../../contexts/paging-context";
import { BasicFilter, FiltersState } from "../../../filters";
import { checkPermission } from "../../../utils/check-permission";
import { translateFiltersState } from "../../../utils/filters";
import { PERMISSIONS } from "../../../utils/permissions";
import { setSortingParams } from "../../../utils/sorting";
import { ALL_DOCUMENTS } from "../../Documents/documents.gql";
import { DOCUMENT_PICKUPS_FILTERS_DOCUMENTS } from "../document-pickups.filters";
import { DOCUMENT_PICKUPS_SHOW } from "../document-pickups.gql";

export default function DocumentPickupsEdit(): React.ReactElement {
  const { pageSize, currentPage, setTotalCount } = usePaging();
  const { search } = useLocation();

  const params = useMemo(() => new URLSearchParams(search), [search]);

  const [sortBy, setSortBy] = useState<FieldName | null>(
    (params.get("sortBy") as FieldName) || FieldName.CreatedAt
  );

  const [sortDirection, setSortDirection] = useState<SortDirection | null>(
    (params.get("sortDirection") as SortDirection) || SortDirection.DESC
  );

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const {
    isFetching,
    setIsFetching,
    isPartialFetching,
    setIsPartialFetching,
  } = useLoading();
  const [selectedDocuments, setSelectedDocuments] = useState<Array<number>>([]);
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const [isCreatePickupDialogOpen, setIsCreatePickupDialogOpen] = useState(
    false
  );
  const [filters, setFilters] = useState<FiltersState>();
  const { isLoading } = useLoading();
  const { id } = useParams<{ id?: string }>();

  const handleSorting = (column: FieldName) => {
    setSortingParams(history, search, column, sortDirection);
    setSortBy(column);
    setSortDirection(
      sortDirection === null
        ? SortDirection.DESC
        : sortDirection === SortDirection.ASC
        ? SortDirection.DESC
        : SortDirection.ASC
    );
  };

  const {
    refetch: documentsRefetch,
    data: documentsData,
    error: documentsError,
    loading,
  } = useQuery(ALL_DOCUMENTS, {
    variables: {
      pageSize,
      offset: (currentPage - 1) * pageSize,
      sorting: {
        field: sortBy,
        direction: sortDirection,
      },
      filter: {
        and: [
          {
            documentKind: {
              iLike: "Incoming",
            },
            or: [
              {
                documentPickupId: {
                  is: null,
                },
              },
              {
                documentPickupId: {
                  eq: id ? parseInt(id) : null,
                },
              },
            ],
          },
          {
            ...(filters && (translateFiltersState(filters) as BasicFilter[])),
          },
        ],
      },
    },
  });

  useEffect(() => {
    if (documentsError?.graphQLErrors)
      enqueueSnackbar({
        message: (documentsError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [documentsError]);

  const {
    refetch: pickupRefetch,
    data: pickupData,
    error: pickupError,
  } = useQuery(DOCUMENT_PICKUPS_SHOW, {
    variables: {
      id: id ? parseInt(id) : null,
    },
  });

  useEffect(() => {
    if (pickupError?.graphQLErrors)
      enqueueSnackbar({
        message: (pickupError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [pickupError]);

  useEffect(() => {
    documentsRefetch();
    setIsPartialFetching(true);
  }, [currentPage, pageSize]);

  useEffect(() => {
    documentsRefetch();
    setIsPartialFetching(true);
  }, [sortBy, sortDirection]);

  useEffect(() => {
    if (documentsData?.documents) documentsRefetch();
    if (pickupData?.documentPickup) pickupRefetch();
    setIsFetching(true);
  }, []);

  useEffect(() => {
    documentsData?.documents &&
      pickupData?.documentPickup &&
      setIsFetching(false);

    if (documentsData?.documents?.totalCount)
      setTotalCount(documentsData?.documents?.totalCount);
  }, [documentsData, pickupData]);

  useEffect(
    () =>
      setSelectedDocuments(
        pickupData?.documentPickup?.documents?.map(
          (document: { id: number }) => document?.id
        )
      ),
    [pickupData]
  );

  if (!checkPermission(PERMISSIONS.documentPickup.update))
    return <Redirect to="/" />;

  return (
    <article>
      <Header
        title="Edycja wydania dokumentów"
        labels={["Wydania dokumentów", "Edytowanie"]}
        goBackOption
        buttons={[
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () => history.push(`/document-pickups/${id}`),
          },
          {
            label: "Kontynuuj",
            kind: KIND.primary,
            startEnhancer: <ArrowBigRight size={18} />,
            onClick: () => setIsCreatePickupDialogOpen(true),
            disabled: isLoading || !selectedDocuments?.length,
            isLoading: isLoading,
          },
        ]}
      />
      <Filters
        filters={DOCUMENT_PICKUPS_FILTERS_DOCUMENTS}
        state={filters}
        setState={setFilters}
      />
      <Content filtersOffset>
        <Grid>
          <Cell span={12} $style={{ position: "relative" }}>
            <DocumentsCheckboxTable
              data={documentsData?.documents?.nodes}
              loading={isFetching || isPartialFetching || loading}
              handleSorting={handleSorting}
              selectedDocuments={selectedDocuments}
              setSelectedDocuments={setSelectedDocuments}
              sortBy={sortBy}
              sortDirection={sortDirection}
            />
          </Cell>

          <Cell span={12}>
            <BottomPanel>
              <PagingControls />
            </BottomPanel>
          </Cell>

          <ConfirmDialog
            isOpen={isCancelConfirmDialogOpen}
            label="Anulowanie tworzenia odbioru dokumentów"
            close={() => setIsCancelConfirmDialogOpen(false)}
            confirm={() => history.push("/document-pickups")}
          />
          <DocumentPickupModal
            isOpen={isCreatePickupDialogOpen}
            setIsOpen={setIsCreatePickupDialogOpen}
            documentIds={selectedDocuments}
            type="edit"
            pickupId={id}
            user={pickupData?.documentPickup?.user}
          />
        </Grid>
      </Content>
    </article>
  );
}
