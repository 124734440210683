import { Document } from "../documents.d";
import { ShipmentRate } from "../ShipmentContracts/shipment-contracts";

export type DocumentParcel = {
  id: number;
  documentId: number;
  document: Document;
  width: number;
  height: number;
  length: number;
  weight: number;
  isCountryService: boolean;
  countryCode: string;
  shipmentRate?: ShipmentRate;
  createdAt?: string;
};

export const ShipmentRatesSelectPlaceholders = {
  width: "szerokości",
  height: "wysokości",
  length: "długości",
  weight: "wagi",
};
