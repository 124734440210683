import { ApolloError, useMutation } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND } from "baseui/button";
import { StyledLink } from "baseui/link";
import { ModalBody, ModalFooter, ModalHeader } from "baseui/modal";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { AlertOctagon, Check, Send, X } from "tabler-icons-react";

import { InputValidationError } from "../api";
import { DocumentStatus } from "../containers/Documents/documents";
import {
  DOCUMENT_FLOW_ACTION_EXECUTE,
  DOCUMENTS_XML_EXPORT,
} from "../containers/Documents/documents.gql";
import {
  FLOW_PROCEED_FIELDS,
  FlowProceedInputs,
} from "../containers/Documents/flow-proceed.form";
import { DocumentFlowAction } from "../containers/Flow/document-flow";
import { useLoading } from "../contexts/loading-context";
import { formValidation } from "../utils/formValidation";
import Button from "./button";
import { ControlledFilesPicker, FilesPickerType } from "./files-picker";
import FormControl from "./form-control";
import Modal from "./modal";
import { ControlledTextArea } from "./text-area";

type FlowActionModalProps = {
  action: DocumentFlowAction;
  documentId?: number;
  isOpen: boolean;
  label: string;
  hasAnySignaturesBefore: boolean;
  lastStatusWithSignature?: DocumentStatus;
  reloadPage: () => void;
  setIsOpen: (isOpen: boolean) => void;
};

export default function FlowActionModal({
  documentId,
  action,
  isOpen,
  label,
  hasAnySignaturesBefore,
  lastStatusWithSignature,
  reloadPage,
  setIsOpen,
}: FlowActionModalProps): React.ReactElement {
  const [css, theme] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, setIsLoading } = useLoading();

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    unregister,
  } = useForm<FlowProceedInputs>();

  const [exportXmlFile] = useMutation(DOCUMENTS_XML_EXPORT);

  const onExportFileSubmit = async () => {
    setIsLoading(true);

    try {
      const response = await exportXmlFile({
        variables: {
          documentXmlExportInput: {
            id: documentId,
          },
        },
      });

      enqueueSnackbar({
        message: "Rozpoczęto pobieranie pliku",
        variant: "info",
      });
      hasAnySignaturesBefore
        ? window.open(
            `${process.env.REACT_APP_GRAPHQL_API_URL?.replace("/graphql", "")}${
              lastStatusWithSignature?.signatureDownloadUrl
            }`,
            "_self"
          )
        : window.open(
            `${process.env.REACT_APP_GRAPHQL_API_URL?.replace(
              "/graphql",
              ""
            )}/documents/download-export/${documentId}/${
              response?.data?.documentXmlExport
            }`,
            "_self"
          );
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const [execute, { error, loading }] = useMutation(
    DOCUMENT_FLOW_ACTION_EXECUTE
  );

  const onProceedFlowSubmit = async ({
    comment,
    documentFlowProceedSignature,
  }: FlowProceedInputs) => {
    setIsLoading(true);

    try {
      await execute({
        variables: {
          documentFlowActionExecuteInput: {
            documentId: documentId,
            documentFlowActionId: action.id,
            comment,
          },
          ...(action.isSignatureFileRequired && {
            documentFlowActionExecuteSignatureFile:
              documentFlowProceedSignature && documentFlowProceedSignature[0],
          }),
        },
      });
      enqueueSnackbar({
        message: "Przesłano pomyślnie",
        variant: "success",
      });
      reloadPage();
      setIsOpen(false);
      reset();
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    unregister("comment");
    unregister("documentFlowProceedSignature");
  }, [action]);

  return (
    <Modal isOpen={isOpen} onClose={() => (loading ? null : setIsOpen(false))}>
      <form onSubmit={handleSubmit(onProceedFlowSubmit)}>
        <ModalHeader>Wykonanie akcji: {action.label}</ModalHeader>
        <ModalBody>
          {!!label && (
            <p>
              Dotyczy dokumentu: <strong>{label}</strong>{" "}
            </p>
          )}

          {FLOW_PROCEED_FIELDS.map((item, index) => (
            <FormControl
              key={`flow-proceed-input-${index + 1}`}
              error={
                ((errors as any)[item.id] &&
                  (errors as any)[item.id].message) ||
                (error &&
                  error.graphQLErrors[0]?.extensions?.code ===
                    "INPUT_VALIDATION_ERROR" &&
                  error.graphQLErrors[0]?.extensions?.validationErrors
                    ?.find(
                      (vE: InputValidationError) => vE?.property === item.id
                    )
                    ?.errors.map((message: string) => (
                      <div
                        key="error"
                        className={css({
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        })}
                      >
                        {message}
                        <span
                          className={css({
                            color: "#999",
                            marginLeft: "auto",
                            marginRight: "5px",
                          })}
                        >
                          Walidacja serwera
                        </span>
                        <AlertOctagon color="#999" size={12} />
                      </div>
                    ))[0])
              }
              disabled={isLoading}
            >
              {item.id === "comment" ? (
                <ControlledTextArea
                  control={control}
                  name={item.id}
                  placeholder={`Komentarz (${
                    action.isCommentRequired ? "wymagany" : "opcjonalny"
                  })`}
                  {...(action.isCommentRequired && {
                    rules: {
                      required: formValidation.messages.required,
                    },
                  })}
                />
              ) : item.id === "documentFlowProceedSignature" &&
                action.isSignatureFileRequired ? (
                <>
                  Ten krok wymaga złożenia podpisu elektronicznego na
                  dokumencie.{" "}
                  <StyledLink
                    {...(loading
                      ? {
                          className: css({
                            color: theme.colors.mono600,
                            ":hover": {
                              cursor: "default",
                              color: theme.colors.mono600,
                            },
                          }),
                        }
                      : {
                          onClick: () => onExportFileSubmit(),
                          $style: { cursor: "pointer" },
                        })}
                  >
                    Pobierz plik XML tego dokumentu
                  </StyledLink>
                  , podpisz podpisem wewnętrznym (XAdes) i dołącz plik poniżej.
                  <Block height="20px" />
                  <ControlledFilesPicker
                    control={control}
                    name={item.id}
                    filesPickerType={FilesPickerType.SingleFile}
                    multiple={false}
                    loading={isLoading}
                    accept={".xml, .xades"}
                    {...(action.isSignatureFileRequired && {
                      rules: {
                        required: formValidation.messages.requiredSignature,
                      },
                    })}
                  />
                </>
              ) : (
                <></>
              )}
            </FormControl>
          ))}
        </ModalBody>

        <ModalFooter>
          <Button
            type="button"
            kind={KIND.secondary}
            $style={{ marginRight: "10px" }}
            onClick={() => {
              setIsOpen(false);
              reset();
            }}
            disabled={loading}
          >
            Anuluj
          </Button>

          <Button
            disabled={loading}
            isLoading={loading}
            type="submit"
            $style={{
              transitionDuration: "100ms",
              transitionProperty: "all",
              transitionTimingFunction: "ease-in-out",
              ...(action.style === "negative" && {
                backgroundColor: theme.colors.negative400,
                color: "#FFF",
                ":hover": {
                  backgroundColor: theme.colors.negative500,
                },
                ":focus": {
                  backgroundColor: theme.colors.negative500,
                },
              }),
              ...(action.style === "positive" && {
                backgroundColor: theme.colors.positive400,
                color: "#FFF",
                ":hover": {
                  backgroundColor: theme.colors.positive500,
                },
                ":focus": {
                  backgroundColor: theme.colors.positive500,
                },
              }),
            }}
            startEnhancer={
              action.style === "positive" ? (
                <Check size={18} />
              ) : action.style === "negative" ? (
                <X size={18} />
              ) : (
                <Send size={18} />
              )
            }
          >
            Potwierdź
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
