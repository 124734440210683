import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { LabelMedium } from "baseui/typography";
import React from "react";
import { Minus, Trash } from "tabler-icons-react";

type Props = {
  groups?: string[];
  setGroups: (selectedGroups: string[]) => void;
};

export default function GroupPicker({
  groups,
  setGroups,
}: Props): React.ReactElement {
  const [css, theme] = useStyletron();

  return (
    <Block display="flex" flexDirection="column" marginTop="scale200">
      <ol
        className={css({
          paddingLeft: "20px",
          marginTop: 0,
        })}
      >
        {groups?.length ? (
          groups?.map((group: string) => (
            <li key={group}>
              <Block
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                paddingTop="scale100"
                paddingBottom="scale100"
                marginBottom="scale200"
              >
                <LabelMedium
                  $style={{
                    fontWeight: 400,
                  }}
                >
                  {group}
                </LabelMedium>
                <Trash
                  size={18}
                  className={css({
                    marginLeft: theme.sizing.scale200,
                    color: theme.colors.negative,
                    ":hover": {
                      cursor: "pointer",
                      color: theme.colors.negative500,
                    },
                  })}
                  onClick={() =>
                    setGroups([
                      ...groups?.filter(
                        (groupName: string) => groupName !== group
                      ),
                    ])
                  }
                />
              </Block>
            </li>
          ))
        ) : (
          <div
            key="error"
            className={css({
              display: "flex",
              alignItems: "center",
              color: "#999999",
            })}
          >
            <Minus
              color="#999999"
              size={18}
              className={css({ marginRight: "5px" })}
            />
            Brak
          </div>
        )}
      </ol>
    </Block>
  );
}
