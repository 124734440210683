import { FIELDS, FieldsGroup } from "../../fields.d";
import { OrganizationalUnit } from "./../OrganizationalUnits/organizational-units.d";
import { Position } from "./../Positions/positions.d";
import { User } from "./users.d";

export type CreateFormInputs = {
  firstName: string;
  lastName: string;
  email: string;
  loginAD?: string;
  password?: string;
  // TODO: uzupełnić typowanie po mergu
  roleIds: any[];
};

export type EditFormInputs = {
  firstName: string;
  lastName: string;
  email: string;
  loginAD?: string;
  password?: string;
  position?: any[];
  // TODO: uzupełnić typowanie po mergu
  roleIds: any[];
};

export type AssignFormInputs = {
  id: OrganizationalUnit[];
  userId: User[];
  positionId: Position[];
};

export const USERS_FIELDS: FieldsGroup[] = [
  // {
  //   id: "basic",
  //   label: "Podstawowe informacje",
  //   fields: [
  //     {
  //       id: "firstName",
  //       label: "Imię",
  //       type: FIELDS.Input,
  //       show: { visible: true },
  //       create: { visible: true, required: true },
  //       edit: { visible: false },
  //     },
  //     {
  //       id: "lastName",
  //       label: "Nazwisko",
  //       type: FIELDS.Input,
  //       show: { visible: true },
  //       create: { visible: true, required: true },
  //       edit: { visible: false },
  //     },
  //   ],
  // },
  {
    id: "auth",
    label: "Autoryzacja",
    fields: [
      {
        id: "email",
        label: "Adres e-mail",
        type: FIELDS.Input,
        dataType: "email",
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: false },
      },
      {
        id: "loginAD",
        label: "Login AD",
        type: FIELDS.Input,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "password",
        label: "Hasło",
        span: 12,
        type: FIELDS.Input,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "lastLoginAt",
        label: "Ostatnie logowanie",
        type: FIELDS.Input,
        dataType: "datetime",
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "lastActivityAt",
        label: "Ostatnia aktywność",
        type: FIELDS.Input,
        dataType: "datetime",
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
    ],
  },
  {
    id: "roles",
    label: "Role i uprawnienia",
    fields: [
      {
        id: "roleIds",
        label: "Role",
        span: 12,
        type: FIELDS.RolesMultiSelect,
        dataType: "model:roles",
        show: { accessor: ["roles", "name"], visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "position",
        label: "Stanowisko",
        span: 12,
        type: FIELDS.DictionaryValuesSelect,
        dataType: "dictionary-value",
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
    ],
  },
  {
    id: "access",
    label: "Blokady",
    fields: [
      {
        id: "isBlockedByAD",
        label: "Blokada AD",
        type: FIELDS.Input,
        dataType: "access-boolean",
        span: 6,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "isBlockedByAdmin",
        label: "Blokada administratora",
        type: FIELDS.Input,
        dataType: "access-boolean",
        span: 6,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
    ],
  },
];
