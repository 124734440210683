import { ApolloError, useQuery } from "@apollo/client";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { StyledLink } from "baseui/link";
import { useSnackbar } from "notistack";
import React, { MouseEvent, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { Redirect, useLocation } from "react-router-dom";
import { Row } from "react-table";
import { useStyletron } from "styletron-react";
import { Eye, Pencil, Plus } from "tabler-icons-react";

import BottomPanel from "../../../components/bottom-panel";
import Button from "../../../components/button";
import Cell from "../../../components/cell";
import Content from "../../../components/content";
import Filters from "../../../components/filters";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import PagingControls from "../../../components/paging-controls";
import SortingTableHeader, {
  SortDirection,
} from "../../../components/sorting-table-header";
import Table from "../../../components/table";
import Tooltip from "../../../components/tooltip";
import { ENADAWCA_PARCELS } from "../../../constants";
import { useDictionaries } from "../../../contexts/dictionaries-context";
import { useLoading } from "../../../contexts/loading-context";
import { usePaging } from "../../../contexts/paging-context";
import { BasicFilter, FiltersState } from "../../../filters";
import { checkPermission } from "../../../utils/check-permission";
import { translateFiltersState } from "../../../utils/filters";
import { PERMISSIONS } from "../../../utils/permissions";
import { setSortingParams } from "../../../utils/sorting";
import { DocumentParcel } from "../document-parcels";
import { DOCUMENT_PARCELS_FILTERS } from "../document-parcels.filters";
import { DOCUMENT_PARCELS_INDEX } from "../document-parcels.gql";

enum FieldName {
  CreatedAt = "createdAt",
  Width = "width",
  Height = "height",
  Length = "length",
  Weight = "weight",
}

export default function DocumentParcelsIndex(): React.ReactElement {
  const { pageSize, currentPage, setTotalCount } = usePaging();

  const [css] = useStyletron();
  const { search } = useLocation();

  const params = useMemo(() => new URLSearchParams(search), [search]);

  const [sortBy, setSortBy] = useState<FieldName | null>(
    (params.get("sortBy") as FieldName) || FieldName.CreatedAt
  );

  const [sortDirection, setSortDirection] = useState<SortDirection | null>(
    (params.get("sortDirection") as SortDirection) || SortDirection.DESC
  );

  const {
    isFetching,
    setIsFetching,
    isPartialFetching,
    setIsPartialFetching,
  } = useLoading();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { findValue } = useDictionaries();
  const [filters, setFilters] = useState<FiltersState>();

  const { data, error, loading, refetch } = useQuery(DOCUMENT_PARCELS_INDEX, {
    variables: {
      pageSize,
      offset: (currentPage - 1) * pageSize,
      sorting: {
        field: sortBy,
        direction: sortDirection,
      },
      filter: {
        eNadawcaSendingListItems: {
          id: { is: null },
        },
        ...{
          ...(filters && (translateFiltersState(filters) as BasicFilter[])),
        },
      },
    },
  });

  useEffect(() => {
    if (data?.documentParcels) setTimeout(() => refetch(), 200);
    setIsFetching(true);
  }, []);

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    setTimeout(() => refetch(), 200);
    setIsPartialFetching(true);
  }, [currentPage, pageSize]);

  useEffect(() => {
    setTimeout(() => refetch(), 200);
    setIsPartialFetching(true);
  }, [sortBy, sortDirection]);

  useEffect(() => {
    if (data?.documentParcels) setIsFetching(false);
    if (data?.documentParcels?.totalCount >= 0)
      setTotalCount(data?.documentParcels?.totalCount);
  }, [data]);

  const handleSorting = (column: FieldName) => {
    setSortingParams(history, search, column, sortDirection);
    setSortBy(column);
    setSortDirection(
      sortDirection === null
        ? SortDirection.DESC
        : sortDirection === SortDirection.ASC
        ? SortDirection.DESC
        : SortDirection.ASC
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: (
          <Block display="flex" justifyContent="flex-end">
            Id
          </Block>
        ),
        id: "id",
        Cell: ({ row }: { row: Row<DocumentParcel> }) => (
          <Block display="flex" justifyContent="flex-end">
            <StyledLink
              onClick={(event: MouseEvent) => {
                event.preventDefault();
                history.push(`/document-parcels/${row.original.id}`);
              }}
              href={`/document-parcels/${row.original.id}`}
            >
              {row.original.id}
            </StyledLink>
          </Block>
        ),
      },
      {
        Header: "Dokument",
        id: "document",
        Cell: ({ row }: { row: Row<DocumentParcel> }) => (
          <FormattedValue
            dataType="model:documents"
            data={`${row?.original?.document?.documentKind?.toLowerCase()}/${
              row?.original?.document?.type
            }/${row?.original?.documentId}`}
            loadingIfUndefined
          >
            {`${findValue(row?.original?.document?.documentType) || "Plik"} [${
              row?.original?.document?.internalNumber
            }]`}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.CreatedAt)}
            sortDirection={
              sortBy === FieldName.CreatedAt ? sortDirection : null
            }
            $style={{ display: "flex", justifyContent: "flex-end" }}
          >
            Data utworzenia
          </SortingTableHeader>
        ),
        id: "createdAt",
        Cell: ({ row }: { row: Row<DocumentParcel> }) => (
          <Block display="flex" justifyContent="flex-end">
            <FormattedValue dataType="date">
              {row.original.createdAt}
            </FormattedValue>
          </Block>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.Width)}
            sortDirection={sortBy === FieldName.Width ? sortDirection : null}
            $style={{ display: "flex", justifyContent: "flex-end" }}
          >
            Szerokość
          </SortingTableHeader>
        ),
        accessor: "width",
        Cell: ({ row }: { row: Row<DocumentParcel> }) => (
          <Block display="flex" justifyContent="flex-end">
            <FormattedValue
              dataType="pre"
              $style={{ fontSize: "12px" }}
            >{`${row.original.width} mm`}</FormattedValue>
          </Block>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.Height)}
            sortDirection={sortBy === FieldName.Height ? sortDirection : null}
            $style={{ display: "flex", justifyContent: "flex-end" }}
          >
            Wysokość
          </SortingTableHeader>
        ),
        accessor: "height",
        Cell: ({ row }: { row: Row<DocumentParcel> }) => (
          <Block display="flex" justifyContent="flex-end">
            <FormattedValue
              dataType="pre"
              $style={{ fontSize: "12px" }}
            >{`${row.original.height} mm`}</FormattedValue>
          </Block>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.Length)}
            sortDirection={sortBy === FieldName.Length ? sortDirection : null}
            $style={{ display: "flex", justifyContent: "flex-end" }}
          >
            Długość
          </SortingTableHeader>
        ),
        accessor: "length",
        Cell: ({ row }: { row: Row<DocumentParcel> }) => (
          <Block display="flex" justifyContent="flex-end">
            <FormattedValue
              dataType="pre"
              $style={{ fontSize: "12px" }}
            >{`${row.original.length} mm`}</FormattedValue>
          </Block>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.Weight)}
            sortDirection={sortBy === FieldName.Weight ? sortDirection : null}
            $style={{ display: "flex", justifyContent: "flex-end" }}
          >
            Waga
          </SortingTableHeader>
        ),
        accessor: "weight",
        Cell: ({ row }: { row: Row<DocumentParcel> }) => (
          <Block display="flex" justifyContent="flex-end">
            <FormattedValue
              dataType="pre"
              $style={{ fontSize: "12px" }}
            >{`${row.original.weight} g`}</FormattedValue>
          </Block>
        ),
      },
      {
        Header: "Typ paczki",
        accessor: "eNadawcaShipmentType",
        Cell: ({ row }: { row: Row<DocumentParcel> }) => (
          <FormattedValue dataType="e-nadawca-shipment-type">
            {row?.original?.shipmentRate?.eNadawcaShipmentType}
          </FormattedValue>
        ),
      },
      {
        id: "actions",
        Cell: ({ row }: { row: Row<DocumentParcel> }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            {!!row?.original?.shipmentRate?.eNadawcaShipmentType &&
              checkPermission(PERMISSIONS.sendingList.update) && (
                <Tooltip content="Dodaj do listy wysyłkowej">
                  <span>
                    <Button
                      kind={KIND.secondary}
                      size={SIZE.mini}
                      onClick={() =>
                        history.push({
                          pathname: `/documents/outgoing/${
                            row?.original?.document?.type
                          }/${row?.original?.document?.id}/${
                            ENADAWCA_PARCELS?.find(
                              (parcel: { id: string }) =>
                                parcel?.id ===
                                row?.original?.shipmentRate
                                  ?.eNadawcaShipmentType
                            )?.urlToCreate
                          }`,
                          state: {
                            shipmentRateId: row?.original?.shipmentRate?.id,
                            shipmentContractId:
                              row?.original?.shipmentRate?.shipmentContractId,
                          },
                        })
                      }
                    >
                      <Plus size={12} />
                    </Button>
                  </span>
                </Tooltip>
              )}
            {checkPermission(PERMISSIONS.documentParcel.update) && (
              <Button
                kind={KIND.secondary}
                size={SIZE.mini}
                $style={{ marginLeft: "10px" }}
                $as="a"
                href={`/document-parcels/${row.original.id}/edit`}
                onClick={(e) => {
                  e.preventDefault();
                  history.push(`/document-parcels/${row.original.id}/edit`);
                }}
                startEnhancer={<Pencil size={14} />}
              />
            )}

            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              $style={{ marginLeft: "6px" }}
              $as="a"
              href={`/document-parcels/${row.original.id}`}
              onClick={(e) => {
                e.preventDefault();
                history.push(`/document-parcels/${row.original.id}`);
              }}
              startEnhancer={<Eye size={14} />}
            />
          </div>
        ),
      },
    ],
    [data, sortBy, sortDirection, findValue, filters]
  );

  if (!checkPermission(PERMISSIONS.documentParcel.read))
    return <Redirect to="/" />;

  return (
    <article>
      <Header
        title="Listy nadane"
        recordsNum={data?.documentParcels?.totalCount}
        labels={["Lista"]}
        buttons={[
          {
            label: "Nadaj list",
            kind: KIND.primary,
            startEnhancer: <Plus size={18} />,
            permission: checkPermission(PERMISSIONS.documentParcel.create),
            onClick: () => history.push("/document-parcels/create"),
          },
        ]}
      />
      <Filters
        filters={DOCUMENT_PARCELS_FILTERS}
        state={filters}
        setState={setFilters}
      />
      <Content filtersOffset>
        <Grid>
          <Cell span={12} $style={{ position: "relative" }}>
            <Table<DocumentParcel>
              columns={columns}
              data={data?.documentParcels?.nodes}
              isLoading={isFetching || isPartialFetching || loading}
              stickLastColumn
            />
          </Cell>

          <Cell span={12}>
            <BottomPanel>
              <PagingControls />
            </BottomPanel>
          </Cell>
        </Grid>
      </Content>
    </article>
  );
}
