import { useStyletron } from "baseui";
import {
  Card as BaseCard,
  CardProps as BaseCardProps,
  StyledBody,
} from "baseui/card";
import React from "react";
import { StyleObject } from "styletron-react";

type CardProps = {
  $style?: StyleObject;
  children?: React.ReactChild | React.ReactChild[];
} & BaseCardProps;

export default function Card({
  $style,
  children,
}: CardProps): React.ReactElement {
  const [, theme] = useStyletron();

  return (
    <BaseCard
      overrides={{
        Root: {
          style: {
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            borderBottomRightRadius: "10px",
            borderBottomLeftRadius: "10px",
            borderTopWidth: "1px",
            borderRightWidth: "1px",
            borderBottomWidth: "1px",
            borderLeftWidth: "1px",
            marginBottom: "20px",
            backgroundColor: theme.colors.backgroundSecondary,
            ...$style,
          },
        },
        Contents: {
          style: {
            marginLeft: "24px",
            marginTop: "24px",
            marginRight: "24px",
            marginBottom: "24px",
          },
        },
      }}
    >
      <StyledBody>{children}</StyledBody>
    </BaseCard>
  );
}
