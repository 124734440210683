import { ApolloError, useMutation } from "@apollo/client";
import { KIND } from "baseui/button";
import { useSnackbar } from "notistack";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Redirect, useHistory } from "react-router-dom";
import { DeviceFloppy } from "tabler-icons-react";

import Content from "../../../components/content";
import FormRenderer from "../../../components/form-renderer";
import Header from "../../../components/header";
import { useLoading } from "../../../contexts/loading-context";
import { checkPermission } from "../../../utils/check-permission";
import { PERMISSIONS } from "../../../utils/permissions";
import { CreateFormInputs, USERS_FIELDS } from "../users.form";
import { USERS_CREATE } from "../users.gql";

export default function UsersCreate(): React.ReactElement {
  const { enqueueSnackbar } = useSnackbar();

  const {
    control,
    formState: { errors, ...formState },
    handleSubmit,
    ...methods
  } = useForm<CreateFormInputs>({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
    },
  });

  const history = useHistory();

  const [createUser, { error }] = useMutation(USERS_CREATE);

  const { isLoading, setIsLoading } = useLoading();

  const onSubmit = async ({
    firstName,
    lastName,
    email,
    loginAD,
    password,
    roleIds,
  }: CreateFormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      const data = await createUser({
        variables: {
          firstName,
          lastName,
          email,
          loginAD,
          password,
          roleIds: roleIds && roleIds.map(({ id }) => id),
        },
      });

      enqueueSnackbar({
        message: "Utworzono pomyślnie",
        variant: "success",
      });

      history.push("/users/" + data.data?.userCreate?.id);
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (!checkPermission(PERMISSIONS.user.create)) return <Redirect to="/" />;

  return (
    <article>
      <Header
        title="Nowy użytkownik"
        labels={["Użytkownicy", "Tworzenie"]}
        goBackOption
        buttons={[
          {
            label: "Utwórz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            onClick: handleSubmit(onSubmit),
            disabled: isLoading,
            isLoading: isLoading,
          },
        ]}
      />
      <Content>
        <FormProvider<CreateFormInputs>
          control={control}
          formState={{ errors, ...formState }}
          handleSubmit={handleSubmit}
          {...methods}
        >
          <FormRenderer
            id="createUser"
            onSubmit={handleSubmit(onSubmit)}
            type="create"
            isLoading={isLoading}
            error={error}
            fields={USERS_FIELDS}
          />
        </FormProvider>
      </Content>
    </article>
  );
}
