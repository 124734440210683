import { FIELDS, FieldsGroup } from "../../fields.d";
import { OrganizationalUnit } from "./organizational-units.d";

export type CreateFormInputs = {
  name: string;
  symbol: string;
  budgetSymbol: string;
  description?: string;
};

export type EditFormInputs = {
  name: string;
  symbol: string;
  budgetSymbol: string;
  description?: string;
  parentId?: OrganizationalUnit[];
};

export type MoveFormInputs = {
  id: OrganizationalUnit[];
  parentId: OrganizationalUnit[];
};

export const ORGANIZATIONAL_UNITS_FIELDS: FieldsGroup[] = [
  {
    id: "basic",
    label: "Podstawowe informacje",
    fields: [
      {
        id: "name",
        label: "Nazwa",
        type: FIELDS.Input,
        span: 12,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "symbol",
        label: "Symbol jednostki",
        caption:
          "Symbol powinien zawierać litery nadrzędnych jednostek organizacyjnych, np. H.1.WZW",
        type: FIELDS.Input,
        span: 4,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "budgetSymbol",
        label: "Symbol budżetowy",
        type: FIELDS.Input,
        span: 4,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "description",
        label: "Opis",
        type: FIELDS.Input,
        span: 12,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
    ],
  },
  {
    id: "groups",
    label: "Grupy",
    fields: [
      {
        id: "groups",
        label: "",
        type: FIELDS.Input,
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "groups",
        label: "",
        type: FIELDS.GroupPicker,
        span: 12,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
        caption: "Kliknięcie ikony kosza usunie grupę z listy",
      },
    ],
  },
];
