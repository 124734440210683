import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { ModalBody, ModalFooter, ModalHeader } from "baseui/modal";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { AlertOctagon, DeviceFloppy } from "tabler-icons-react";

import { InputValidationError } from "../../../api";
import Button from "../../../components/button";
import Cell from "../../../components/cell";
import Content from "../../../components/content";
import FormControl from "../../../components/form-control";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import GroupPicker from "../../../components/group-picker";
import Header from "../../../components/header";
import { ControlledInput } from "../../../components/input";
import Modal from "../../../components/modal";
import { ControlledOrganizationalUnitsSelect } from "../../../components/select";
import { useLoading } from "../../../contexts/loading-context";
import { FIELDS } from "../../../fields.d";
import { checkPermission } from "../../../utils/check-permission";
import { formValidation } from "../../../utils/formValidation";
import { PERMISSIONS } from "../../../utils/permissions";
import {
  CreateFormInputs,
  ORGANIZATIONAL_UNITS_FIELDS,
} from "../organizational-units.form";
import {
  ORGANIZATIONAL_UNITS_CREATE,
  ORGANIZATIONAL_UNITS_SHOW,
} from "../organizational-units.gql";

export default function OrganizationalUnitsCreate(): React.ReactElement {
  const [enteredGroups, setEnteredGroups] = useState<string[]>([]);
  const [isAddGroupModalOpen, setIsAddGroupModalOpen] = useState(false);
  const [css] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<CreateFormInputs>({
    defaultValues: {
      name: "",
      symbol: "",
      budgetSymbol: "",
    },
  });

  const history = useHistory();
  const { id } = useParams<{ id?: string }>();

  const { data, error: queryError } = useQuery(ORGANIZATIONAL_UNITS_SHOW, {
    variables: { id: id ? parseInt(id, 10) : null },
  });

  const [createOrganizationalUnit, { error }] = useMutation(
    ORGANIZATIONAL_UNITS_CREATE
  );

  const { isFetching, setIsFetching, isLoading, setIsLoading } = useLoading();

  const onSubmit = async ({
    name,
    symbol,
    budgetSymbol,
    description,
  }: CreateFormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      const data = await createOrganizationalUnit({
        variables: {
          organizationalUnitCreateInput: {
            name,
            symbol,
            budgetSymbol,
            description,
            parentId: id ? parseInt(id, 10) : null,
            groups: enteredGroups,
          },
        },
      });

      enqueueSnackbar({
        message: "Utworzono pomyślnie",
        variant: "success",
      });

      history.push(
        "/organizational-units/" + data.data?.organizationalUnitCreate?.id
      );
    } catch (error: unknown) {
      enqueueSnackbar({
        message:
          (error as ApolloError).graphQLErrors?.map(
            ({ message }) => message
          )[0] || (error as ApolloError).message,
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => setIsFetching(true), []);

  useEffect(() => {
    if (queryError?.graphQLErrors)
      enqueueSnackbar({
        message: (queryError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [queryError]);

  useEffect(() => {
    if (data?.organizationalUnit) {
      setIsFetching(false);
    }
  }, [data]);

  const {
    control: groupControl,
    handleSubmit: groupHandleSubmit,
    reset: groupReset,
    watch: groupWatch,
    unregister: groupUnregister,
  } = useForm<{
    groupName: string;
  }>({
    defaultValues: {
      groupName: "",
    },
  });
  const watchGroupName = groupWatch("groupName");

  const addGroup = () => {
    setEnteredGroups([...enteredGroups, watchGroupName]);
    setIsAddGroupModalOpen(false);
    groupReset();
  };

  if (!checkPermission(PERMISSIONS.organizationalUnit.create))
    return <Redirect to="/" />;

  return (
    <article>
      <Header
        title="Nowa jednostka organizacyjna"
        labels={["Jednostki organizacyjne", "Tworzenie"]}
        goBackOption
        buttons={[
          {
            label: "Utwórz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            onClick: handleSubmit(onSubmit),
            disabled: isLoading,
            isLoading: isLoading,
          },
        ]}
      />
      <Content>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            <Cell span={12}>
              <LabelMedium marginBottom="scale200" marginTop="scale600">
                Jednostka nadrzędna
              </LabelMedium>
              <hr
                className={css({
                  borderWidth: "0px",
                  height: "1px",
                  backgroundColor: "#eee",
                })}
              />
            </Cell>
            <Cell span={4}>
              <FormControl label="Symbol" disabled={true}>
                {isFetching ? (
                  <Skeleton rows={0} height="20px" width="100%" animation />
                ) : (
                  <FormattedValue>
                    {data?.organizationalUnit?.symbol}
                  </FormattedValue>
                )}
              </FormControl>
            </Cell>
            <Cell span={8}>
              <FormControl label="Nazwa" disabled={true}>
                {isFetching ? (
                  <Skeleton rows={0} height="20px" width="100%" animation />
                ) : (
                  <FormattedValue>
                    {data?.organizationalUnit?.name}
                  </FormattedValue>
                )}
              </FormControl>
            </Cell>
            {ORGANIZATIONAL_UNITS_FIELDS.filter(
              (g) => g.fields.filter((f) => f.create.visible).length > 0
            ).map((group) => [
              group.label && (
                <Cell key={group.id + `-group`} span={12}>
                  <Block
                    marginTop="scale600"
                    marginBottom="scale200"
                    display="flex"
                    alignItems="center"
                  >
                    <LabelMedium>{group.label}</LabelMedium>
                    {group.id === "groups" && (
                      <Button
                        size={SIZE.mini}
                        kind={KIND.secondary}
                        $style={{ marginLeft: "10px" }}
                        onClick={() => setIsAddGroupModalOpen(true)}
                        type="button"
                      >
                        Dodaj nową
                      </Button>
                    )}
                  </Block>
                  <hr
                    className={css({
                      borderWidth: "0px",
                      height: "1px",
                      backgroundColor: "#eee",
                    })}
                  />
                </Cell>
              ),
              group.fields
                .filter((f) => f.create.visible)
                .map((item, index) => (
                  <Cell span={item.span || 6} key={group.id + `-field` + index}>
                    <FormControl
                      label={item.label}
                      caption={
                        item.id === "groups" && !enteredGroups?.length
                          ? ""
                          : item.caption
                      }
                      required={item.create.required}
                      error={
                        ((errors as any)[item.id] &&
                          (errors as any)[item.id].message) ||
                        (error &&
                          error.graphQLErrors[0]?.extensions?.code ===
                            "INPUT_VALIDATION_ERROR" &&
                          error.graphQLErrors[0]?.extensions?.validationErrors
                            ?.find(
                              (vE: InputValidationError) =>
                                vE?.property === item.id
                            )
                            ?.errors.map((message: string) => (
                              <div
                                key="error"
                                className={css({
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                })}
                              >
                                {message}
                                <span
                                  className={css({
                                    color: "#999",
                                    marginLeft: "auto",
                                    marginRight: "5px",
                                  })}
                                >
                                  Walidacja serwera
                                </span>
                                <AlertOctagon color="#999" size={12} />
                              </div>
                            ))[0])
                      }
                      disabled={isLoading}
                    >
                      {item.type === FIELDS.OrganizationalUnitsSelect ? (
                        <ControlledOrganizationalUnitsSelect
                          control={control}
                          name={item.id}
                          placeholder={item.placeholder}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.GroupPicker ? (
                        <GroupPicker
                          groups={enteredGroups}
                          setGroups={setEnteredGroups}
                        />
                      ) : (
                        <ControlledInput
                          control={control}
                          name={item.id}
                          placeholder={item.placeholder}
                          {...(item.create.required && {
                            rules: {
                              required: item.caption
                                ? `${formValidation.messages.required} ${item.caption}`
                                : formValidation.messages.required,
                            },
                          })}
                        />
                      )}
                    </FormControl>
                  </Cell>
                )),
            ])}
          </Grid>
        </form>
        <form onSubmit={groupHandleSubmit(addGroup)}>
          <Modal
            isOpen={isAddGroupModalOpen}
            onClose={() => {
              setIsAddGroupModalOpen(false);
              groupUnregister("groupName");
              groupReset();
            }}
          >
            <ModalHeader>Dodanie nowej grupy</ModalHeader>
            <ModalBody>
              <FormControl required label="Nazwa grupy">
                <ControlledInput control={groupControl} name={"groupName"} />
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                kind={KIND.secondary}
                $style={{ marginRight: "12px" }}
                onClick={() => {
                  setIsAddGroupModalOpen(false);
                  groupUnregister("groupName");
                  groupReset();
                }}
              >
                Anuluj
              </Button>
              <Button
                type="submit"
                onClick={() => addGroup()}
                kind={KIND.primary}
              >
                Dodaj
              </Button>
            </ModalFooter>
          </Modal>
        </form>
      </Content>
    </article>
  );
}
