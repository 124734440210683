import { FIELDS, FieldsGroup } from "../../../fields.d";
import { Document } from "../documents";

export type RequestFormNoteInputs = {
  documentNumber: string;
  description: string;
  documentDate: Date | null;
  requestFormId?: Document[];
  registeredAt: string;
  caseName: { id: number; label?: string }[];
  organizationalUnit: { id: number; label?: string }[];
  files?: FileList;
  documentId?: number;
  senderWhenUser?: { id: number; label?: string }[];
  sequenceNumber?: string;
};

export type LinkFormInputs = {
  parentIds: Document[];
};

export const REQUEST_FORM_NOTE_FIELDS: FieldsGroup[] = [
  {
    id: "basic",
    label: "Podstawowe informacje",
    fields: [
      {
        id: "documentType",
        label: "Typ dokumentu",
        type: FIELDS.DocumentTypesSelect,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
        placeholder: "Wybierz",
      },
      {
        id: "documentKind",
        label: "Rodzaj dokumentu",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "document-kind",
      },
      {
        id: "documentNumber",
        label: "Numer notatki",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "requestFormId",
        label: "Wniosek o wydatkowanie",
        type: FIELDS.RequestFormsSelect,
        dataType: "model:documents",
        span: 3,
        show: { visible: true },
        create: { visible: true, required: false },
        edit: { visible: true, required: false },
      },
      {
        id: "sequenceNumber",
        label: "Numer w składzie chronologicznym",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "state",
        label: "Stan dokumentu",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "document-state",
      },
      {
        id: "createdAt",
        label: "Data rejestracji w systemie",
        type: FIELDS.Input,
        dataType: "datetime",
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "createdBy",
        label: "Autor",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "model:users",
      },
      {
        id: "responsible",
        label: "Odpowiedzialny",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "model:users",
      },
      {
        id: "description",
        label: "Opis",
        type: FIELDS.TextArea,
        span: 12,
        show: { visible: true },
        create: { visible: true, required: false },
        edit: { visible: true, required: false },
      },
    ],
  },
  {
    id: "sender",
    label: "Nadawca",
    fields: [
      {
        id: "organizationalUnit",
        label: "Jednostka organizacyjna",
        type: FIELDS.UsersOrganizationalUnitsSelect,
        span: 8,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: true },
        placeholder: "Wybierz",
        typeName: ["Outgoing"],
      },
      {
        id: "organizationalUnit",
        label: "Jednostka organizacyjna",
        type: FIELDS.Input,
        span: 6,
        show: { accessor: ["organizationalUnit", "name"], visible: true },
        create: { visible: false },
        edit: { visible: false },
        placeholder: "Wybierz",
        dataType: "model:organizational-units",
        typeName: ["Outgoing"],
      },
      {
        id: "senderWhenUser",
        label: "Pracownik",
        type: FIELDS.OrganizationalUnitUsersSelect,
        span: 4,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
        placeholder: "Wybierz",
        typeName: ["Outgoing"],
      },
      {
        id: "senderWhenUser",
        label: "Pracownik",
        type: FIELDS.Input,
        span: 6,
        dataType: "model:users",
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
        placeholder: "Wybierz",
        typeName: ["Outgoing", "Internal"],
      },
    ],
  },
  {
    id: "case",
    label: "Znak sprawy",
    fields: [
      // {
      //   id: "caseName",
      //   label: "",
      //   type: FIELDS.CasesSelect,
      //   span: 12,
      //   show: { visible: false },
      //   create: { visible: true },
      //   edit: { visible: true },
      //   placeholder: "Wybierz",
      //   caption:
      //     "W tym polu należy wybrać odpowiedni znak sprawy z jaką zostanie powiązany dokument. Nie jest to obowiązkowe na etapie rejestracji dokumentu w recepcji",
      // },
      {
        id: "case",
        label: "",
        type: FIELDS.Input,
        span: 12,
        show: { accessor: ["case", "name"], visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "model:cases",
        additionalData: "isConductedElectronically",
      },
    ],
  },
  {
    id: "parent",
    label: "Dokumenty nadrzędne",
    fields: [
      {
        id: "parentIds",
        label: "",
        type: FIELDS.DocumentsSelect,
        dataType: "model:documents",
        span: 12,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
        typeName: ["Incoming", "Outgoing", "Internal"],
      },
    ],
  },
  {
    id: "files",
    label: "Załączniki",
    fields: [
      {
        id: "filesList",
        label: "",
        type: FIELDS.FilesList,
        span: 12,
        show: { visible: false },
        create: { visible: false },
        edit: { visible: true },
        caption:
          "Kliknięcie ikony kosza spowoduje usunięcia załącznika po zapisaniu dokumentu",
      },
      {
        id: "files",
        label: "Dodaj nowy",
        type: FIELDS.FilesPicker,
        span: 12,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
        caption: "Maksymalny rozmiar pliku to 128 MB.",
      },
    ],
  },
];
