import { useStyletron } from "baseui";
import {
  Input as BaseInput,
  InputProps,
  MaskedInput as BaseMaskedInput,
  MaskedInputProps,
} from "baseui/input";
import React, {
  ForwardedRef,
  forwardRef,
  HTMLProps,
  useEffect,
  useState,
} from "react";
import { ChangeEvent } from "react";
import { Controller, UseControllerProps } from "react-hook-form";
import { StyleObject } from "styletron-react";
import { AlertTriangle, X } from "tabler-icons-react";

import { localeParseFloat } from "../utils/misc";

type Props = {
  $style?: StyleObject;
  $rootStyle?: StyleObject;
  masked?: boolean;
  maskChar?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  forwardedRef?: ForwardedRef<any>;
} & InputProps &
  MaskedInputProps;

const Input = ({
  $style,
  $rootStyle,
  maskChar,
  masked,
  forwardedRef,
  endEnhancer,
  size,
  ...rest
}: Props): React.ReactElement => {
  const [css, theme] = useStyletron();
  const Component = masked ? BaseMaskedInput : BaseInput;

  return (
    <Component
      inputRef={forwardedRef}
      overrides={{
        Root: {
          style: () => ({
            borderTopLeftRadius: theme.borders.radius200,
            borderTopRightRadius: theme.borders.radius200,
            borderBottomRightRadius: theme.borders.radius200,
            borderBottomLeftRadius: theme.borders.radius200,
            paddingRight: "5px",
            appearance: "textfield",
            "::-webkit-outer-spin-button, ::-webkit-inner-spin-button": {
              WebkitAppearance: "none",
              margin: 0,
            },
            ...$style,
            ...$rootStyle,
          }),
        },
        InputContainer: {
          style: {},
        },
        Input: {
          style: {
            backgroundColor: "transparent",
            ":-webkit-autofill": {
              WebkitBackgroundClip: "text",
              backgroundClip: "text",
            },
            paddingRight: 0,
            ...$style,
          },
        },
        EndEnhancer: {
          style: {
            fontSize: "12px",
            marginRight: 0,
          },
        },
        StartEnhancer: {
          style: {
            fontSize: "12px",
            padding: 0,
          },
        },
        ClearIcon: {
          props: {
            overrides: {
              Svg: (props: HTMLProps<HTMLElement>) => (
                <span
                  {...props}
                  className={css({
                    cursor: "pointer",
                    display: "inline",
                    lineHeight: 0,
                  })}
                >
                  <X color={theme.colors.borderFocus} size={18} />
                </span>
              ),
            },
          },
        },
      }}
      {...rest}
      size="compact"
      maskChar={maskChar}
      endEnhancer={endEnhancer}
    />
  );
};

export default Input;

type IsolatedInputProps = {
  onValueChange?: (value: string | number | undefined) => void;
};

export function IsolatedInput({
  onValueChange,
  value,
  ...rest
}: IsolatedInputProps & Props): React.ReactElement {
  const [userValue, setUserValue] = useState(value);

  return (
    <Input
      value={userValue}
      onChange={(e: ChangeEvent<HTMLInputElement>) =>
        setUserValue(e.currentTarget.value)
      }
      {...(onValueChange &&
        value !== userValue && { onBlur: () => onValueChange(userValue) })}
      {...rest}
    />
  );
}

type NumericInputProps = {
  onValueChange?: (value: string) => void;
};

export function NumericInput({
  onValueChange,
  value,
  ...rest
}: NumericInputProps & Props): React.ReactElement {
  const [userValue, setUserValue] = useState(value);
  const [isInvalid, setIsInvalid] = useState(false);

  useEffect(() => {
    setUserValue(value);
  }, [value]);

  return (
    <Input
      value={userValue}
      onChange={(e: ChangeEvent<HTMLInputElement>) =>
        setUserValue(e.currentTarget.value)
      }
      onBlur={() => {
        if (typeof userValue === "string") {
          const parsedValue = localeParseFloat(userValue.replace(/ /g, ""));

          if (
            onValueChange &&
            !isNaN(parsedValue) &&
            !/.[,|.]$/g.test(userValue) &&
            !/[a-zA-Z]$/g.test(userValue)
          ) {
            onValueChange(userValue);
            setIsInvalid(false);
          } else {
            setIsInvalid(true);
          }
        }
      }}
      {...rest}
      step={0.01}
      type="tel"
      error={isInvalid}
    />
  );
}

export function ControlledInput({
  control,
  name,
  rules,
  type,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & Props): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Input
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            type === "number" && e.target.value !== "-"
              ? onChange(
                  !Number.isNaN(parseFloat(e.target.value)) &&
                    parseFloat(e.target.value)
                )
              : onChange(e.target.value);
          }}
          onBlur={onBlur}
          value={value}
          type={type}
          name={name}
          id={name}
          {...rest}
        />
      )}
    />
  );
}

// eslint-disable-next-line react/display-name
export const ForwardedInput = forwardRef((
  props: Props & Omit<HTMLProps<"input">, keyof Props>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: ForwardedRef<any>
) => <Input forwardedRef={ref} {...props} />);
