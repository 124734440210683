import { useStyletron } from "baseui";
import { Avatar as BaseAvatar } from "baseui/avatar";
import { Block } from "baseui/block";
import { LabelSmall } from "baseui/typography";
import React from "react";
import stc from "string-to-color";
import { ToggleRight, X } from "tabler-icons-react";

import { Substitution } from "../containers/Substitutions/substitutions";
import { User } from "../containers/Users/users";
import { useSubstitution } from "../contexts/substitution-context";
import Button from "./button";
import Select from "./select";

type SubstitutionSwitchProps = {
  width: number;
};

export default function SubstitutionSwitch({
  width,
}: SubstitutionSwitchProps): React.ReactElement | null {
  const [css, theme] = useStyletron();

  const {
    isSubstitutionActive,
    substitution,
    availableSubstitutees,
    startSubstitution,
    endSubstitution,
  } = useSubstitution();

  return isSubstitutionActive && substitution ? (
    <div
      className={css({
        position: "relative",
        borderTopLeftRadius: "20px",
        borderTopRightRadius: "20px",
        borderBottomRightRadius: "20px",
        borderBottomLeftRadius: "20px",
        transitionDuration: "100ms",
        minHeight: width < 100 ? "unset" : "36px",
        borderWidth: "2px",
        borderStyle: "solid",
        borderColor: theme.colors.negative200,
        display: "flex",
        alignItems: "center",
        paddingLeft: width < 100 ? "3px" : "10px",
        paddingBottom: "4px",
        paddingTop: "4px",
        overflow: "hidden",
        backgroundColor: theme.colors.negative50,
        ":hover": {
          borderColor: theme.colors.negative500,
        },
        ":hover [data-ui=exit-substitution]": {
          display: "flex",
          justifyContent: "flex-start",
        },
        transition: "200ms",
      })}
    >
      <Block display="flex" alignItems="center" position="relative">
        <BaseAvatar
          name={`${substitution?.substitutee?.firstName} ${substitution?.substitutee?.lastName}`}
          size={"16px"}
          overrides={{
            Root: {
              style: {
                backgroundColor: stc(
                  `${substitution?.substitutee?.firstName} ${substitution?.substitutee?.lastName}`
                ),
                verticalAlign: "middle",
                display: "inline-block",
                flexShrink: 0,
              },
            },
            Initials: {
              style: {
                fontSize: "8px",
              },
            },
          }}
        />

        {width > 100 && (
          <LabelSmall $style={{ marginLeft: "8px" }}>
            {`${substitution?.substitutee?.firstName} ${substitution?.substitutee?.lastName}`}
          </LabelSmall>
        )}
      </Block>

      <Button
        data-ui="exit-substitution"
        startEnhancer={<X size={16} color="white" />}
        size="mini"
        $style={{
          position: "absolute",
          left: "0",
          top: "0",
          right: "0",
          bottom: "0",
          width: "100%",
          backgroundColor: theme.colors.negative400,
          color: "#FFF",
          ":hover": {
            backgroundColor: theme.colors.negative500,
          },
          ":focus": {
            backgroundColor: theme.colors.negative500,
          },
          display: "none",
          borderRadius: "unset",
          ...(width < 100 && { padding: "3px" }),
        }}
        onClick={() => {
          endSubstitution();
        }}
      >
        Zakończ zastępstwo
      </Button>
    </div>
  ) : width > 100 ? (
    <Select
      options={availableSubstitutees?.map((user) => ({
        label: `${user?.firstName} ${user?.lastName}`,
        ...user,
      }))}
      value={[{ id: substitution?.substitutee?.id || 0 }]}
      onChange={(params) => {
        startSubstitution((params?.option as User).id);
      }}
      hideIcon={isSubstitutionActive}
      getValueLabel={({ option }) => (
        <Block display="flex" alignItems="center">
          {option?.id === 0 ? (
            <ToggleRight size={16} className={css({ flexShrink: 0 })} />
          ) : (
            <BaseAvatar
              name={`${(option as User).firstName} ${
                (option as User).lastName
              }`}
              size={"16px"}
              overrides={{
                Root: {
                  style: {
                    backgroundColor: stc(
                      `${(option as User).firstName} ${
                        (option as User).lastName
                      }`
                    ),
                    verticalAlign: "middle",
                    display: "inline-block",
                    flexShrink: 0,
                  },
                },
                Initials: {
                  style: {
                    fontSize: "8px",
                  },
                },
              }}
            />
          )}

          <LabelSmall
            $style={{
              marginLeft: "10px",
            }}
          >
            {option?.id === 0 ? "Rozpocznij zastępstwo" : option?.label}
          </LabelSmall>
        </Block>
      )}
      getOptionLabel={({ option }) => (
        <Block display="flex" alignItems="center" position="relative">
          {option?.id === 0 ? (
            <ToggleRight size={12} />
          ) : (
            <BaseAvatar
              name={`${(option as User).firstName} ${
                (option as User).lastName
              }`}
              size={"16px"}
              overrides={{
                Root: {
                  style: {
                    backgroundColor: stc(
                      `${(option as User).firstName} ${
                        (option as User).lastName
                      }`
                    ),
                    verticalAlign: "middle",
                    display: "inline-block",
                    flexShrink: 0,
                  },
                },
                Initials: {
                  style: {
                    fontSize: "8px",
                  },
                },
              }}
            />
          )}

          <LabelSmall $style={{ marginLeft: "8px" }}>
            {`${(option as User).firstName} ${(option as User).lastName}`}
          </LabelSmall>
        </Block>
      )}
      clearable={false}
      searchable={false}
      $controlContainerStyle={{
        borderRadius: "20px",
        background: "white",
      }}
      $dropdownStyle={{
        borderRadius: "20px",
      }}
    />
  ) : null;
}
