import { FIELDS, FieldsGroup } from "../../fields.d";

export const BANK_ACCOUNTS_FIELDS: FieldsGroup[] = [
  {
    id: "data",
    label: "Dane",
    fields: [
      {
        id: "payeeName",
        label: "Nazwa",
        type: FIELDS.Input,
        span: 12,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "accountNumber",
        label: "Numer rachunku",
        type: FIELDS.Input,
        span: 6,
        show: { visible: true },
        edit: { visible: true },
        create: { visible: true },
      },
      {
        id: "bankName",
        label: "Nazwa banku",
        type: FIELDS.Input,
        span: 6,
        show: { visible: true },
        edit: { visible: true },
        create: { visible: true },
        typeName: ["Incoming"],
      },
      {
        id: "bankCode",
        label: "Kod SWIFT/BIC",
        type: FIELDS.Input,
        span: 6,
        show: { visible: true },
        edit: { visible: true },
        create: { visible: true },
      },
      {
        id: "accountType",
        label: "Typ konta",
        dataType: "account-type",
        type: FIELDS.BankAccountTypesSelect,
        span: 6,
        show: { visible: true },
        edit: { visible: true },
        create: { visible: true },
      },
      {
        id: "currency",
        label: "Waluta",
        type: FIELDS.CurrencySelect,
        span: 6,
        show: { visible: true },
        edit: { visible: true },
        create: { visible: true },
      },
    ],
  },
];
