import { useStyletron } from "baseui";
import { ModalBody } from "baseui/modal";
import { formatDistanceToNow } from "date-fns";
import { pl } from "date-fns/locale";
import React from "react";

import FormattedValue from "./formatted-value";
import Modal from "./modal";

type CommentModalProps = {
  isOpen: boolean;
  onClose: () => void;
  commentContent?: { user: any; comment: string; date: string }; // TODO - typ User rzuca błędy
};

export default function CommentModal({
  isOpen,
  onClose,
  commentContent,
}: CommentModalProps): React.ReactElement {
  const [css, theme] = useStyletron();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalBody>
        <div
          className={css({
            position: "relative",
            borderLeft: `10px solid ${theme.colors.backgroundSecondary} `,
            padding: "20px",
          })}
        >
          <FormattedValue>{commentContent?.comment}</FormattedValue>
          <div
            className={css({
              position: "absolute",
              right: "20px",
              bottom: "-15px",
            })}
          >
            <FormattedValue
              dataType="model:users"
              data={commentContent?.user?.id}
            >{`${commentContent?.user?.firstName} ${commentContent?.user?.lastName}`}</FormattedValue>
            {", "}
            {commentContent?.date &&
              formatDistanceToNow(new Date(commentContent?.date), {
                locale: pl,
              })}
            {" temu"}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}
