import { ApolloError, useMutation } from "@apollo/client";
import { useStyletron } from "baseui";
import { KIND } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Redirect, useHistory } from "react-router-dom";
import { AlertOctagon, DeviceFloppy, FileOff, Note } from "tabler-icons-react";

import { InputValidationError } from "../../../../api";
import Cell from "../../../../components/cell";
import ConfirmDialog from "../../../../components/confirm-dialog";
import Content from "../../../../components/content";
import { ControlledFilesPicker } from "../../../../components/files-picker";
import FormControl from "../../../../components/form-control";
import FormattedValue from "../../../../components/formatted-value";
import Grid from "../../../../components/grid";
import Header from "../../../../components/header";
import { ControlledInput } from "../../../../components/input";
import {
  ControlledCasesSelect,
  ControlledContractsSelect,
  ControlledCurrencySelect,
  ControlledUsersOrganizationalUnitsSelect,
} from "../../../../components/select";
import { ControlledTextArea } from "../../../../components/text-area";
import { useAuth } from "../../../../contexts/auth-context";
import { useLoading } from "../../../../contexts/loading-context";
import { FIELDS } from "../../../../fields.d";
import { checkPermission } from "../../../../utils/check-permission";
import { formValidation } from "../../../../utils/formValidation";
import { PERMISSIONS } from "../../../../utils/permissions";
import { scrollOnError } from "../../../../utils/scrollOnError";
import {
  BILL_ISSUANCE_REQUEST_FIELDS,
  BillIssuanceRequestInputs,
} from "../bill-issuance-request.form";
import { BILL_ISSUANCE_REQUEST_CREATE } from "../bill-issuance-request.gql";

export default function DocumentsCreateBillIssuanceRequest(): React.ReactElement {
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const [isClearConfirmDialogOpen, setIsClearConfirmDialogOpen] = useState(
    false
  );
  const [errorTimeStamp, setErrorTimeStamp] = useState<number>();
  const [css] = useStyletron();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { isFetching, isLoading, setIsLoading } = useLoading();
  const { user } = useAuth();

  const time = new Date();

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    reset,
    watch,
  } = useForm<BillIssuanceRequestInputs>({
    defaultValues: {
      parentIds: undefined,
      contractorName: "",
      workedHoursCount: "",
      contractorAccountNumber: "",
      remarks: "",
      description: "",
      grossValue: "",
      sequenceNumber: "",
      files: undefined,
      cases: undefined,
      organizationalUnits: [
        { id: user?.organizationalUnitToUsers?.[0]?.organizationalUnit?.id },
      ],
    },
  });

  const [createBillIssuanceRequest, { error: mutationError }] = useMutation(
    BILL_ISSUANCE_REQUEST_CREATE
  );

  const watchCurrency = watch("currencies");

  const onError = (errors: Record<string, unknown>, e: any) =>
    setErrorTimeStamp(e.timeStamp);

  const onSubmit = async ({
    parentIds,
    contractorName,
    workedHoursCount,
    contractorAccountNumber,
    remarks,
    description,
    grossValue,
    sequenceNumber,
    files,
    cases,
    organizationalUnits,
    currencies,
  }: BillIssuanceRequestInputs): Promise<void> => {
    setIsLoading(true);
    try {
      const response = await createBillIssuanceRequest({
        variables: {
          billIssuanceRequestCreateInput: {
            documentKind: "Internal",
            documentType: "billIssuanceRequest",
            parentIds: parentIds && parentIds.map(({ id }) => id),
            senderType: "User",
            senderId: user?.id,
            contractorName,
            sequenceNumber,
            description,
            grossValue: parseFloat(grossValue?.toString()?.replace(/,/g, ".")),
            caseId: cases && cases[0]?.id,
            organizationalUnitId:
              organizationalUnits && organizationalUnits[0]?.id,
            workedHoursCount: parseFloat(
              workedHoursCount?.toString().replace(/,/, ".")
            ),
            contractorAccountNumber,
            remarks,
            currencyId: currencies?.[0]?.currencyId || null,
            userId: user?.id,
          },
          files,
        },
      });

      enqueueSnackbar({
        message: "Utworzono pomyślnie",
        variant: "success",
      });
      history.push(
        `/documents/internal/BillIssuanceRequest/${response?.data?.billIssuanceRequestCreate?.id}`
      );
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (errors) scrollOnError(errors, BILL_ISSUANCE_REQUEST_FIELDS, "create");
  }, [errorTimeStamp]);

  if (!checkPermission(PERMISSIONS.billIssuanceRequest.create))
    return <Redirect to="/" />;

  return (
    <article>
      <Header
        title="Nowy wniosek o wystawienie rachunku"
        labels={["Dokumenty", "Tworzenie wniosku o wystawienie rachunku"]}
        goBackOption
        buttons={[
          {
            label: "Wyczyść",
            kind: KIND.secondary,
            startEnhancer: <Note size={18} />,
            disabled: isLoading,
            onClick: () => {
              setIsClearConfirmDialogOpen(true);
            },
          },
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              isDirty
                ? setIsCancelConfirmDialogOpen(true)
                : history.push("/documents"),
          },
          {
            label: "Utwórz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            disabled: isLoading,
            isLoading: isLoading,
            onClick: handleSubmit(onSubmit, onError),
            type: "submit",
            formId: "createBillIssuanceRequest",
          },
        ]}
      />
      <Content>
        <Grid>
          <Cell span={12}>
            <FormControl label="Data rejestracji w systemie" disabled={true}>
              {isFetching ? (
                <Skeleton rows={0} height="20px" width="100%" animation />
              ) : (
                <FormattedValue>{time.toLocaleDateString()}</FormattedValue>
              )}
            </FormControl>
          </Cell>
        </Grid>
        <form id="createBillIssuanceRequest" onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            {BILL_ISSUANCE_REQUEST_FIELDS.filter(
              (g) => g.fields.filter((f) => f.create?.visible).length > 0
            ).map((group) => [
              group.label && (
                <Cell key={group.id + `-group`} span={12}>
                  <LabelMedium>{group.label}</LabelMedium>
                  <hr
                    className={css({
                      borderWidth: "0px",
                      height: "1px",
                      backgroundColor: "#eee",
                    })}
                  />
                </Cell>
              ),
              group.fields
                .filter((f) => f.create?.visible)
                .map((item, index) => (
                  <Cell span={item.span || 6} key={group.id + `-field` + index}>
                    {item.id === "remarks" ? (
                      <LabelMedium
                        $style={{ marginBottom: "20px", marginTop: "10px" }}
                      >
                        Potwierdzam bez zastrzeżeń wykonanie prac zgodnie z
                        umową
                      </LabelMedium>
                    ) : (
                      <></>
                    )}
                    <FormControl
                      label={item.type === FIELDS.Checkbox ? "" : item.label}
                      caption={item.caption}
                      required={item.create?.required}
                      error={
                        ((errors as any)[item.id] &&
                          (errors as any)[item.id].message) ||
                        (mutationError &&
                          mutationError.graphQLErrors[0]?.extensions?.code ===
                            "INPUT_VALIDATION_ERROR" &&
                          mutationError.graphQLErrors[0]?.extensions?.validationErrors
                            ?.find(
                              (vE: InputValidationError) =>
                                vE?.property === item.id
                            )
                            ?.errors.map((message: string) => (
                              <div
                                key="error"
                                className={css({
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                })}
                              >
                                {message}
                                <AlertOctagon color="#999" size={12} />
                              </div>
                            ))[0])
                      }
                      disabled={isLoading}
                    >
                      {item.type === FIELDS.CasesSelect ? (
                        <ControlledCasesSelect
                          control={control}
                          name={item.id}
                          id={item.id}
                          placeholder="Wybierz"
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.ContractAgreementSelect ? (
                        <ControlledContractsSelect
                          control={control}
                          id={item.id}
                          name={item.id}
                          placeholder="Wybierz"
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type ===
                        FIELDS.UsersOrganizationalUnitsSelect ? (
                        <ControlledUsersOrganizationalUnitsSelect
                          control={control}
                          name={item.id}
                          id={item.id}
                          objectId={user?.id}
                          placeholder="Wybierz"
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.FilesPicker ? (
                        <ControlledFilesPicker
                          control={control}
                          name={item.id}
                          maxSize={134217728}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.CurrencySelect ? (
                        <ControlledCurrencySelect
                          control={control}
                          id={item.id}
                          name={item.id}
                          placeholder="PLN"
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.TextArea ? (
                        <ControlledTextArea
                          control={control}
                          name={item.id}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : (
                        <ControlledInput
                          control={control}
                          name={item.id}
                          id={item.id}
                          placeholder={item.placeholder}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                          {...(item.type === FIELDS.NumberInput && {
                            type: "number",
                            min: 0,
                          })}
                          masked={item.mask !== undefined}
                          mask={item.mask}
                          maskChar="_"
                          endEnhancer={
                            item?.endEnhancer === "PLN"
                              ? watchCurrency?.[0]?.code || item?.endEnhancer
                              : item?.endEnhancer
                          }
                          disabled={
                            item.id === "sequenceNumber" &&
                            !checkPermission(
                              PERMISSIONS.document.updateSequenceNumber
                            )
                          }
                        />
                      )}
                    </FormControl>
                  </Cell>
                )),
            ])}
            <ConfirmDialog
              isOpen={isCancelConfirmDialogOpen}
              label="Anulowanie tworzenia wniosku o wystawienie rachunku"
              close={() => setIsCancelConfirmDialogOpen(false)}
              confirm={() => history.push("/documents")}
            />
            <ConfirmDialog
              isOpen={isClearConfirmDialogOpen}
              label="Wyczyszczenie formularza"
              close={() => setIsClearConfirmDialogOpen(false)}
              confirm={() => {
                reset();
                setIsClearConfirmDialogOpen(false);
              }}
            />
          </Grid>
        </form>
      </Content>
    </article>
  );
}
