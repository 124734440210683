import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { LabelLarge, LabelSmall } from "baseui/typography";
import React from "react";
import { Movie } from "tabler-icons-react";

import Button from "../../components/button";
import Card from "../../components/card";
import Cell from "../../components/cell";
import Content from "../../components/content";
import Grid from "../../components/grid";
import Header from "../../components/header";
import Layout from "../../components/layout";
import { MOVIES } from "../../constants";
import { useAuth } from "../../contexts/auth-context";

type Movie = {
  id: string;
  title: string;
  href?: string;
  topics: string[];
};

export default function KnowledgeBase(): React.ReactElement {
  const { isAuthenticated } = useAuth();

  const ArticleElement = () => (
    <article>
      <Header title="Baza wiedzy" labels={["Filmy szkoleniowe"]} goBackOption />
      <Content>
        <Grid>
          {MOVIES.map((movie: Movie, index: number) => (
            <Cell span={4} key={movie.id}>
              <Card
                $style={{
                  minHeight: "220px",
                  borderTopWidth: "2px",
                  borderRightWidth: "2px",
                  borderBottomWidth: "2px",
                  borderLeftWidth: "2px",
                }}
              >
                <Block
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <LabelLarge>{`${index + 1}. ${movie.title}`}</LabelLarge>
                  {movie?.href && (
                    <Button
                      size={SIZE.mini}
                      kind={KIND.secondary}
                      $style={{ whiteSpace: "nowrap" }}
                      $as="a"
                      href={movie?.href}
                      target="_blank"
                      startEnhancer={<Movie />}
                    >
                      Obejrzyj film
                    </Button>
                  )}
                </Block>
                <Block>
                  <ul>
                    {movie.topics.map((topic: string, index: number) => (
                      <li key={`${movie.id}_${index + 1}`}>
                        <LabelSmall>{topic}</LabelSmall>
                      </li>
                    ))}
                  </ul>
                </Block>
              </Card>
            </Cell>
          ))}
        </Grid>
      </Content>
    </article>
  );

  return isAuthenticated ? (
    <Layout>
      <ArticleElement />
    </Layout>
  ) : (
    <ArticleElement />
  );
}
