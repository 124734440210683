import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { Row } from "react-table";
import { Affiliate, Lock, LockOpen, Minus, Pencil } from "tabler-icons-react";

import Button from "../../../components/button";
import Cell from "../../../components/cell";
import ConfirmDialog from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import FormControl from "../../../components/form-control";
import { AssignUserToOrganizationalUnitDialog } from "../../../components/form-dialog";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import PermissionsTable from "../../../components/permissions-table";
import Table from "../../../components/table";
import { useLoading } from "../../../contexts/loading-context";
import { checkPermission } from "../../../utils/check-permission";
import { PERMISSIONS } from "../../../utils/permissions";
import { OrganizationalUnit } from "../../OrganizationalUnits/organizational-units";
import { ORGANIZATIONAL_UNITS_UNASSIGN_USER } from "../../OrganizationalUnits/organizational-units.gql";
import { Role } from "../../Roles/roles";
import { OrganizationalUnitToUser } from "../users";
import { USERS_FIELDS } from "../users.form";
import { USERS_MANAGE_BLOCK, USERS_SHOW } from "../users.gql";

export default function UsersShow(): React.ReactElement {
  const [css, theme] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const [isConfirmBlockDialogOpen, setIsConfirmBlockDialogOpen] = useState(
    false
  );
  const [
    isConfirmUnassignDialogOpen,
    setIsConfirmUnassignDialogOpen,
  ] = useState<OrganizationalUnit | null>(null);
  const [isAssignDialogOpen, setIsAssignDialogOpen] = useState(false);
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const { setIsLoading, isFetching, setIsFetching } = useLoading();

  const { refetch, data, error, loading } = useQuery(USERS_SHOW, {
    variables: { id: id ? parseInt(id) : null },
    fetchPolicy: "cache-first",
  });

  const [manageBlock] = useMutation(USERS_MANAGE_BLOCK);
  const [usassignUser] = useMutation(ORGANIZATIONAL_UNITS_UNASSIGN_USER);

  const submitBlock = async ({ isBlocked }: { isBlocked: boolean }) => {
    setIsLoading(true);

    try {
      await manageBlock({
        variables: {
          id: id ? parseInt(id) : null,
          isBlocked,
        },
      });

      setIsConfirmBlockDialogOpen(false);

      enqueueSnackbar({
        message: isBlocked ? "Zablokowano pomyślnie" : "Odblokowano pomyślnie",
        variant: "success",
      });

      refetch();
    } catch (error: unknown) {
      setIsConfirmBlockDialogOpen(false);
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const submitUnassign = async ({
    id,
    userId,
  }: {
    id: number;
    userId: number;
  }) => {
    setIsLoading(true);

    try {
      await usassignUser({
        variables: {
          id,
          userId,
        },
      });

      setIsConfirmUnassignDialogOpen(null);

      enqueueSnackbar({
        message: "Zapisano pomyślnie",
        variant: "success",
      });

      refetch();
    } catch (error: unknown) {
      setIsConfirmUnassignDialogOpen(null);
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    if (data?.user) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => data?.user && setIsFetching(false), [data]);

  const columns = useMemo(
    () => [
      {
        Header: "Jednostka organizacyjna",
        id: "unit",
        Cell: ({ row }: { row: Row<OrganizationalUnitToUser> }) => (
          <FormattedValue
            dataType="model:organizational-units"
            data={row?.original?.organizationalUnit?.id}
          >
            {row?.original?.organizationalUnit?.name}
          </FormattedValue>
        ),
      },
      {
        Header: "Stanowisko",
        id: "position",
        Cell: ({ row }: { row: Row<OrganizationalUnitToUser> }) => (
          <FormattedValue
            dataType="model:positions"
            data={row?.original?.position?.id}
          >
            {row?.original?.position?.name}
          </FormattedValue>
        ),
      },
      {
        id: "actions",
        Cell: ({ row }: { row: Row<OrganizationalUnitToUser> }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            {checkPermission(PERMISSIONS.organizationalUnit.unassignUser) && (
              <Button
                kind={KIND.secondary}
                size={SIZE.mini}
                onClick={() =>
                  setIsConfirmUnassignDialogOpen({
                    id: row?.original?.organizationalUnit?.id,
                    name: row?.original?.organizationalUnit?.name,
                  })
                }
                data-test-id={`delete-organizational-unit-${row?.original?.organizationalUnit?.id}`}
              >
                Usuń
              </Button>
            )}
          </div>
        ),
      },
    ],
    []
  );

  if (!checkPermission(PERMISSIONS.user.read)) return <Redirect to="/" />;

  return (
    <article>
      <Header
        title={data?.user?.firstName + " " + data?.user?.lastName}
        labels={["Użytkownicy", "Wyświetlanie"]}
        goBackOption
        buttons={[
          {
            label: "Edytuj",
            kind: KIND.secondary,
            startEnhancer: <Pencil size={18} />,
            permission: checkPermission(PERMISSIONS.user.update),
            onClick: () => history.push(`/users/${id}/edit`),
          },
        ]}
        actions={[
          {
            label: "Przypisz użytkownika do jednostki organizacyjnej",
            icon: Affiliate,
            color: theme.colors.primary,
            onClick: () => setIsAssignDialogOpen(true),
            permission: checkPermission(
              PERMISSIONS.organizationalUnit.assignUser
            ),
          },
          data?.user?.isBlockedByAdmin
            ? {
                label: "Odblokuj użytkownika",
                icon: LockOpen,
                color: theme.colors.positive,
                onClick: () => setIsConfirmBlockDialogOpen(true),
                permission: checkPermission(PERMISSIONS.user.block),
              }
            : {
                label: "Zablokuj użytkownika",
                icon: Lock,
                color: theme.colors.negative,
                onClick: () => setIsConfirmBlockDialogOpen(true),
                permission: checkPermission(PERMISSIONS.user.block),
              },
        ]}
      />
      <Content>
        <Grid>
          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Podstawowe informacje
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={6}>
            <FormControl label={"Imię i nazwisko"}>
              {isFetching ? (
                <Skeleton rows={0} height="20px" width="100%" animation />
              ) : (
                <span>
                  {data?.user
                    ? `${data?.user?.firstName} ${data?.user?.lastName}`
                    : ""}
                </span>
              )}
            </FormControl>
          </Cell>
          <Cell span={6}>
            <FormControl
              label="Role"
              overrides={{
                ControlContainer: {
                  props: {
                    "data-test-id": "roles",
                  },
                },
              }}
            >
              {isFetching ? (
                <Skeleton rows={0} height="20px" width="100%" animation />
              ) : (
                <>
                  {data?.user?.roles.length ? (
                    data?.user?.roles?.map((role: Role) => (
                      <FormattedValue
                        key={`role-` + role.id}
                        dataType={"model:roles"}
                        data={role.id}
                        $style={{ marginRight: theme.sizing.scale600 }}
                      >
                        {role.name}
                      </FormattedValue>
                    ))
                  ) : (
                    <div
                      key="error"
                      className={css({
                        display: "flex",
                        alignItems: "center",
                        color: "#999999",
                      })}
                    >
                      <Minus
                        color="#999999"
                        size={18}
                        className={css({ marginRight: "5px" })}
                      />
                      Brak
                    </div>
                  )}
                </>
              )}
            </FormControl>
          </Cell>
          {USERS_FIELDS.filter(
            (g) => g.fields.filter((f) => f.show.visible).length > 0
          ).map((group) => [
            group.label && (
              <Cell key={group.id + `-group`} span={12}>
                <LabelMedium marginBottom="scale200" marginTop="scale600">
                  {group.label}
                </LabelMedium>
                <hr
                  className={css({
                    borderWidth: "0px",
                    height: "1px",
                    backgroundColor: "#eee",
                  })}
                />
              </Cell>
            ),
            group.fields
              .filter((f) => f.show.visible)
              .map((item, index) => (
                <Cell span={item.span || 6} key={group.id + `-field` + index}>
                  <FormControl
                    label={item.label}
                    disabled={true}
                    overrides={{
                      ControlContainer: {
                        props: {
                          "data-test-id": item.id,
                        },
                      },
                    }}
                  >
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : item.id === "roleIds" ? (
                      <>
                        {data?.user?.roles?.length > 0 ? (
                          data?.user?.roles?.map(({ id, name }: Role) => (
                            <FormattedValue
                              key={`role-` + id}
                              dataType={item.dataType}
                              data={id}
                              $style={{
                                marginRight: theme.sizing.scale600,
                              }}
                            >
                              {name}
                            </FormattedValue>
                          ))
                        ) : (
                          <div
                            key="error"
                            className={css({
                              display: "flex",
                              alignItems: "center",
                              color: "#999999",
                            })}
                          >
                            <Minus
                              color="#999999"
                              size={18}
                              className={css({ marginRight: "5px" })}
                            />
                            Brak
                          </div>
                        )}
                      </>
                    ) : (
                      <FormattedValue dataType={item.dataType}>
                        {item.show.accessor
                          ? data?.user[item.show.accessor[0]]?.[
                              item.show.accessor[1]
                            ]
                          : data?.user[item.id]}
                      </FormattedValue>
                    )}
                  </FormControl>
                </Cell>
              )),
          ])}
          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Relacje
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={12}>
            {isFetching ? (
              <Skeleton rows={0} height="200px" width="100%" animation />
            ) : (
              data?.user?.organizationalUnitToUsers && (
                <Block paddingTop="scale200" paddingBottom="scale600">
                  <Table<OrganizationalUnitToUser>
                    compact
                    columns={columns}
                    data={data?.user?.organizationalUnitToUsers}
                  />
                </Block>
              )
            )}
          </Cell>
          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Uprawnienia
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={12}>
            {isFetching ? (
              <Skeleton rows={0} height="200px" width="100%" animation />
            ) : (
              data?.user?.permissions && (
                <Block paddingTop="scale200" paddingBottom="scale600">
                  <PermissionsTable
                    isReadOnly
                    selectedIds={
                      loading
                        ? undefined
                        : data?.user?.permissions.map(
                            (permission: Record<string, any>) => permission.id
                          )
                    }
                  />
                </Block>
              )
            )}
          </Cell>
          <ConfirmDialog
            isOpen={isConfirmBlockDialogOpen}
            label={`${
              data?.user?.isBlockedByAdmin ? "Odblokowanie" : "Zablokowanie"
            } użytkownika ${data?.user?.firstName} ${data?.user?.lastName}`}
            close={() => setIsConfirmBlockDialogOpen(false)}
            confirm={() =>
              submitBlock({ isBlocked: !data?.user?.isBlockedByAdmin })
            }
          />
          <ConfirmDialog
            isOpen={!!isConfirmUnassignDialogOpen}
            label={`Wypisanie użytkownika ${data?.user?.firstName} ${
              data?.user?.lastName
            } z organizacji ${isConfirmUnassignDialogOpen?.name || ""}`}
            close={() => setIsConfirmUnassignDialogOpen(null)}
            confirm={() =>
              isConfirmUnassignDialogOpen &&
              submitUnassign({
                id: isConfirmUnassignDialogOpen?.id,
                userId: data?.user?.id,
              })
            }
          />
          <AssignUserToOrganizationalUnitDialog
            user={data?.user}
            isOpen={isAssignDialogOpen}
            onCompleted={refetch}
            close={() => setIsAssignDialogOpen(false)}
          />
        </Grid>
      </Content>
    </article>
  );
}
