import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { KIND, SIZE } from "baseui/button";
import { StyledLink } from "baseui/link";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { MouseEvent, useEffect, useState } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { Row } from "react-table";
import { Eye, Pencil, Plus, Trash } from "tabler-icons-react";

import Button from "../../../components/button";
import Cell from "../../../components/cell";
import ConfirmDialog from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import FormControl from "../../../components/form-control";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import Table from "../../../components/table";
import { useLoading } from "../../../contexts/loading-context";
import { checkPermission } from "../../../utils/check-permission";
import { PERMISSIONS } from "../../../utils/permissions";
import { DivisorItem } from "../divisors";
import { DIVISOR_TEMPLATES_FIELDS } from "../divisors.form";
import {
  DIVISOR_TEMPLATES_DELETE,
  DIVISOR_TEMPLATES_SHOW,
} from "../divisors.gql";

export default function DivisorsShowTemplate(): React.ReactElement {
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState(
    false
  );
  const [css, theme] = useStyletron();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { setIsLoading, isFetching, setIsFetching } = useLoading();
  const { templateId } = useParams<{ templateId?: string }>();

  const { data, error, refetch } = useQuery(DIVISOR_TEMPLATES_SHOW, {
    variables: {
      id: templateId ? parseInt(templateId) : null,
    },
  });

  const totalPercent = data?.divisorTemplate?.divisorItems.reduce(
    (a: number, b: DivisorItem) => a + (b.percent ? b.percent : 0),
    0
  );

  const [deleteTemplate] = useMutation(DIVISOR_TEMPLATES_DELETE);

  const deleteSubmit = async () => {
    setIsLoading(true);

    try {
      await deleteTemplate({
        variables: {
          divisorTemplateDeleteInput: {
            id: templateId ? parseInt(templateId) : null,
          },
        },
      });

      enqueueSnackbar({
        message: "Usunięto pomyślnie",
        variant: "success",
      });

      setIsConfirmDeleteDialogOpen(false);

      history.push("/divisors");
    } catch (error: unknown) {
      setIsConfirmDeleteDialogOpen(false);

      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Lp.",
        id: "ordinalNumber",
        Cell: ({ row }: { row: Row<DivisorItem> }) => row.index + 1,
      },
      {
        Header: "Nazwa",
        id: "name",
        Cell: ({ row }: { row: Row<DivisorItem> }) => (
          <StyledLink
            onClick={(event: MouseEvent) => {
              event.preventDefault();
              history.push(`/divisors/${templateId}/${row?.original?.id}`);
            }}
            href={`/divisors/${templateId}/${row?.original?.id}`}
          >
            <FormattedValue>{row?.original?.name}</FormattedValue>
          </StyledLink>
        ),
      },
      {
        Header: "Udział",
        id: "percent",
        Cell: ({ row }: { row: Row<DivisorItem> }) => (
          <FormattedValue>{`${new Intl.NumberFormat("pl-PL", {
            style: "percent",
            maximumSignificantDigits: 4,
          }).format(row?.original?.percent)}`}</FormattedValue>
        ),
      },
      {
        Header: "Konto wewnętrzne",
        id: "stepsCount",
        Cell: ({ row }: { row: Row<DivisorItem> }) => (
          <FormattedValue
            dataType="model:internal-accounts"
            data={row?.original?.internalAccount?.id}
          >
            {row?.original?.internalAccount?.name}
          </FormattedValue>
        ),
      },
      {
        id: "actions",
        Cell: ({ row }: { row: Row<DivisorItem> }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            {checkPermission(PERMISSIONS.divisorTemplates.update) && (
              <Button
                kind={KIND.secondary}
                size={SIZE.mini}
                $as="a"
                href={`/divisors/${templateId}/${row.original.id}`}
                onClick={(e) => {
                  e.preventDefault();
                  history.push(
                    `/divisors/${templateId}/${row.original.id}/edit-item`
                  );
                }}
                startEnhancer={<Pencil size={14} />}
              />
            )}
            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              $style={{ marginLeft: "6px" }}
              $as="a"
              href={`/divisors/${templateId}/${row.original.id}`}
              onClick={(e) => {
                e.preventDefault();
                history.push(`/divisors/${templateId}/${row.original.id}`);
              }}
              startEnhancer={<Eye size={14} />}
            />
          </div>
        ),
      },
    ],
    [data]
  );

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    if (data?.divisorTemplate) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => data?.divisorTemplate && setIsFetching(false), [data]);

  if (!checkPermission(PERMISSIONS.divisorTemplates.read))
    return <Redirect to="/" />;

  return (
    <article>
      <Header
        title={data?.divisorTemplate?.name}
        labels={["Szablony podzielników", "Wyświetlanie"]}
        goBackOption
        buttons={[
          {
            label: "Edytuj",
            kind: KIND.secondary,
            startEnhancer: <Pencil size={18} />,
            permission: checkPermission(PERMISSIONS.divisorTemplates.update),
            onClick: () =>
              history.push(
                `/divisors/${data?.divisorTemplate?.id}/edit-template`
              ),
          },
          {
            label: "Dodaj podzielnik",
            kind: KIND.primary,
            startEnhancer: <Plus size={18} />,
            permission:
              totalPercent < 1 &&
              checkPermission(PERMISSIONS.divisorItems.create),
            onClick: () =>
              history.push({
                pathname: `/divisors/${data?.divisorTemplate?.id}/create-item`,
                state: {
                  totalPercent,
                },
              }),
          },
        ]}
        actions={[
          {
            label: "Usuń szablon",
            icon: Trash,
            color: theme.colors.negative,
            onClick: () => setIsConfirmDeleteDialogOpen(true),
            permission: checkPermission(PERMISSIONS.divisorTemplates.delete),
          },
        ]}
      />
      <Content>
        <Grid>
          {DIVISOR_TEMPLATES_FIELDS.filter(
            (g) => g.fields.filter((f) => f.show.visible).length > 0
          ).map((group) => [
            group.label && (
              <Cell key={group.id + `-group`} span={12}>
                <LabelMedium marginBottom="scale200" marginTop="scale600">
                  {group.label}
                </LabelMedium>
                <hr
                  className={css({
                    borderWidth: "0px",
                    height: "1px",
                    backgroundColor: "#eee",
                  })}
                />
              </Cell>
            ),
            group.fields
              .filter((f) => f.show.visible)
              .map((item, index) => (
                <Cell span={item.span || 6} key={group.id + `-field` + index}>
                  <FormControl label={item.label} disabled={true}>
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : (
                      <FormattedValue
                        dataType={item.dataType}
                        data={data?.divisorTemplate[item.id]}
                      >
                        {item.show.accessor
                          ? data?.divisorTemplate[item.show.accessor[0]]?.[
                              item.show.accessor[1]
                            ]
                          : data?.divisorTemplate[item.id]}
                      </FormattedValue>
                    )}
                  </FormControl>
                </Cell>
              )),
          ])}
          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Podzielniki
            </LabelMedium>
          </Cell>
          <Cell span={12} $style={{ position: "relative" }}>
            {isFetching ? (
              <Skeleton rows={0} height="300px" width="100%" animation />
            ) : (
              data && (
                <Table<DivisorItem>
                  columns={columns}
                  data={data?.divisorTemplate?.divisorItems}
                  compact
                />
              )
            )}
          </Cell>
          <ConfirmDialog
            isOpen={isConfirmDeleteDialogOpen}
            label={`Usunięcie szablonu ${data?.divisorTemplate?.name}`}
            close={() => setIsConfirmDeleteDialogOpen(false)}
            confirm={deleteSubmit}
          />
        </Grid>
      </Content>
    </article>
  );
}
