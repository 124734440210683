import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { AlertOctagon, DeviceFloppy, FileOff } from "tabler-icons-react";

import { InputValidationError } from "../../../api";
import Cell from "../../../components/cell";
import { ControlledCheckbox } from "../../../components/checkbox";
import ConfirmDialog from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import FormControl from "../../../components/form-control";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import { ControlledInput } from "../../../components/input";
import {
  ControlledDocumentKindsSelect,
  ControlledDocumentTypesSelect,
} from "../../../components/select";
import { useLoading } from "../../../contexts/loading-context";
import { FIELDS } from "../../../fields.d";
import { checkPermission } from "../../../utils/check-permission";
import { formValidation } from "../../../utils/formValidation";
import { PERMISSIONS } from "../../../utils/permissions";
import { DOCUMENT_FLOW_FIELDS, FormInputs } from "../document-flow.form";
import { DOCUMENT_FLOW, DOCUMENT_FLOW_UPDATE } from "../flow.gql";

export default function FlowEditProcess(): React.ReactElement {
  const [css] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { isFetching, setIsFetching, isLoading, setIsLoading } = useLoading();
  const { id } = useParams<{ id?: string }>();
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );

  const { data, error: queryError, refetch } = useQuery(DOCUMENT_FLOW, {
    variables: { id: id ? parseInt(id) : null },
  });

  const [editDocumentFlow, { error }] = useMutation(DOCUMENT_FLOW_UPDATE);

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    setValue,
    watch,
  } = useForm<FormInputs>();
  const watchDocumentKind = watch("documentKinds");

  useEffect(() => setValue("documentTypes", undefined), [watchDocumentKind]);

  const onSubmit = async ({
    name,
    documentTypes,
    documentKinds,
    description,
    isActive,
  }: FormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      const response = await editDocumentFlow({
        variables: {
          documentFlowUpdateInput: {
            id: id ? parseInt(id) : null,
            name,
            documentType: documentTypes && documentTypes[0]?.id,
            documentKind: documentKinds && documentKinds[0]?.id,
            description,
            isActive: !!isActive,
          },
        },
      });

      enqueueSnackbar({
        message: "Zapisano pomyślnie",
        variant: "success",
      });

      history.push(`/flow/${response.data.documentFlowUpdate.id}`);
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setValue("name", data?.documentFlow?.name);
    setValue("documentKinds", [{ id: data?.documentFlow?.documentKind }]);
    setTimeout(
      () =>
        setValue("documentTypes", [{ id: data?.documentFlow?.documentType }]),
      500
    );
    setValue("description", data?.documentFlow?.description);
    setValue("isActive", data?.documentFlow?.isActive);
  }, [data]);

  useEffect(() => {
    if (data) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => {
    if (data) setIsFetching(false);
  }, [data]);

  useEffect(() => {
    if (queryError?.graphQLErrors)
      enqueueSnackbar({
        message: (queryError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [queryError]);

  if (!checkPermission(PERMISSIONS.documentFlow.update))
    return <Redirect to="/" />;

  return (
    <article>
      <Header
        title="Edycja procesu"
        labels={["Obieg dokumentów", "Edytowanie"]}
        goBackOption
        buttons={[
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              isDirty ? setIsCancelConfirmDialogOpen(true) : history.goBack(),
          },
          {
            label: "Zapisz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            onClick: handleSubmit(onSubmit),
            disabled: isLoading,
            isLoading: isLoading,
            type: "submit",
            formId: "editProcess",
          },
        ]}
      />
      <Content>
        <form id="editProcess" onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            {DOCUMENT_FLOW_FIELDS.filter(
              (g) => g.fields.filter((f) => f.editFlow.visible).length > 0
            ).map((group) => [
              group.label && (
                <Cell key={group.id + `-group`} span={12}>
                  <LabelMedium marginBottom="scale200" marginTop="scale600">
                    {group.label}
                  </LabelMedium>
                  <hr
                    className={css({
                      borderWidth: "0px",
                      height: "1px",
                      backgroundColor: "#eee",
                    })}
                  />
                </Cell>
              ),
              group.fields
                .filter((f) => f.editFlow.visible)
                .map((item, index) => (
                  <Cell span={item.span || 6} key={group.id + `-field` + index}>
                    <FormControl
                      label={item.type === FIELDS.Checkbox ? "" : item.label}
                      caption={item.caption}
                      required={item.editFlow.required}
                      error={
                        ((errors as any)[item.id] &&
                          (errors as any)[item.id].message) ||
                        (error &&
                          error.graphQLErrors[0]?.extensions?.code ===
                            "INPUT_VALIDATION_ERROR" &&
                          error.graphQLErrors[0]?.extensions?.validationErrors
                            ?.find(
                              (vE: InputValidationError) =>
                                vE?.property === item.id
                            )
                            ?.errors.map((message: string) => (
                              <div
                                key="error"
                                className={css({
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                })}
                              >
                                {message} <br />
                                {item.caption}
                                <AlertOctagon color="#999" size={12} />
                              </div>
                            ))[0])
                      }
                      disabled={isLoading}
                    >
                      {isFetching ? (
                        <Skeleton
                          rows={0}
                          height="36px"
                          width="100%"
                          animation
                        />
                      ) : item.type === FIELDS.Checkbox ? (
                        <Block marginTop="35px">
                          <ControlledCheckbox
                            control={control}
                            name={item.id}
                            disabled={isLoading}
                            {...(item.editFlow.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                          >
                            {item.label}
                          </ControlledCheckbox>
                        </Block>
                      ) : item.type === FIELDS.DocumentTypesSelect ? (
                        <ControlledDocumentTypesSelect
                          control={control}
                          name={item.id}
                          placeholder={
                            !watchDocumentKind?.[0]?.id
                              ? "Nie wybrano rodzaju dokumentu"
                              : item.placeholder
                          }
                          documentKind={watchDocumentKind?.[0]?.id}
                          {...(item.createFlow.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.DocumentKindsSelect ? (
                        <ControlledDocumentKindsSelect
                          control={control}
                          name={item.id}
                          placeholder={item.placeholder}
                          {...(item.createFlow.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : (
                        <ControlledInput
                          control={control}
                          name={item.id}
                          placeholder={item.placeholder}
                          {...(item.editFlow.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      )}
                    </FormControl>
                  </Cell>
                )),
            ])}
            <ConfirmDialog
              isOpen={isCancelConfirmDialogOpen}
              label="Anulowanie edycji procesu"
              close={() => setIsCancelConfirmDialogOpen(false)}
              confirm={() => history.goBack()}
            />
          </Grid>
        </form>
      </Content>
    </article>
  );
}
