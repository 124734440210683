import { useQuery } from "@apollo/client";
import React, { createContext, useContext, useState } from "react";

import { DICTIONARIES_VALUES } from "../containers/Dictionaries/dictionaries.gql";
import {
  Dictionary,
  DictionaryValue,
} from "../containers/Dictionaries/dictionary";
import { useAuth } from "./auth-context";

type DictionariesContextProps = {
  incomingTypes: DictionaryValue[];
  internalTypes: DictionaryValue[];
  outgoingTypes: DictionaryValue[];
  countryCodes: DictionaryValue[];
  findValue: (type: string) => string;
  findId: (type: string) => string;
};

export const DictionariesContext = createContext<DictionariesContextProps>(
  {} as DictionariesContextProps
);

export function DictionariesProvider({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement {
  const [incomingTypes, setIncomingTypes] = useState<DictionaryValue[]>([]);
  const [outgoingTypes, setOutgoingTypes] = useState<DictionaryValue[]>([]);
  const [internalTypes, setInternalTypes] = useState<DictionaryValue[]>([]);
  const [countryCodes, setCountryCodes] = useState<DictionaryValue[]>([]);

  const { isAuthenticated } = useAuth();

  const { data } = useQuery(DICTIONARIES_VALUES, {
    fetchPolicy: "cache-and-network",
    skip: !isAuthenticated,
    onCompleted({ dictionaryValues }) {
      setIncomingTypes(
        dictionaryValues.filter(
          (dictionaryValue: { dictionary: Dictionary }) =>
            dictionaryValue.dictionary?.systemName === "documentTypes.incoming"
        )
      );
      setOutgoingTypes(
        dictionaryValues.filter(
          (dictionaryValue: { dictionary: Dictionary }) =>
            dictionaryValue.dictionary?.systemName === "documentTypes.outgoing"
        )
      );
      setInternalTypes(
        dictionaryValues.filter(
          (dictionaryValue: { dictionary: Dictionary }) =>
            dictionaryValue.dictionary?.systemName === "documentTypes.internal"
        )
      );
      setCountryCodes(
        dictionaryValues.filter(
          (dictionaryValue: { dictionary: Dictionary }) =>
            dictionaryValue.dictionary?.systemName === "countryCodes.alpha2.pl"
        )
      );
    },
  });

  const findValue = (type: string): string =>
    data?.dictionaryValues?.find(
      (dictionaryValue: DictionaryValue) => dictionaryValue.value === type
    )?.name;

  const findId = (type: string): string =>
    data?.dictionaryValues?.find(
      (dictionaryValue: DictionaryValue) => dictionaryValue.value === type
    )?.id;

  return (
    <DictionariesContext.Provider
      value={{
        incomingTypes,
        internalTypes,
        outgoingTypes,
        countryCodes,
        findValue,
        findId,
      }}
    >
      {children}
    </DictionariesContext.Provider>
  );
}

export const useDictionaries = (): DictionariesContextProps =>
  useContext(DictionariesContext);
