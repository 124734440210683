import { ApolloError, useMutation } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND } from "baseui/button";
import { ModalBody, ModalFooter, ModalHeader } from "baseui/modal";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { AlertOctagon } from "tabler-icons-react";

import { InputValidationError } from "../api";
import Button from "../components/button";
import Modal from "../components/modal";
import { REQUEST_FORM_VOID } from "../containers/Documents/RequestForm/request-form.gql";
import { useLoading } from "../contexts/loading-context";
import { formValidation } from "../utils/formValidation";
import FormControl from "./form-control";
import { ControlledTextArea } from "./text-area";

type RequestFormVoidModalProps = {
  requestFormId?: string;
  requestFormNumber: string;
  isOpen: boolean;
  reloadPage: () => void;
  setIsOpen: (isOpen: boolean) => void;
};

export default function RequestFormVoidModal({
  requestFormId,
  requestFormNumber,
  isOpen,
  reloadPage,
  setIsOpen,
}: RequestFormVoidModalProps): React.ReactElement {
  const [css] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, setIsLoading } = useLoading();

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<{ reason: string }>();

  const [voidRequstForm, { error, loading }] = useMutation(REQUEST_FORM_VOID);

  const onSubmit = async ({ reason }: { reason: string }): Promise<void> => {
    setIsLoading(true);

    try {
      await voidRequstForm({
        variables: {
          requestFormVoidInput: {
            id: requestFormId ? parseInt(requestFormId) : null,
            reason,
          },
        },
      });

      enqueueSnackbar({
        message: "Pomyślnie unieważniono wniosek",
        variant: "success",
      });
      reloadPage();
      setIsOpen(false);
    } catch (error: unknown) {
      setIsOpen(false);
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
        reset();
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>{`Unieważnienie wniosku ${requestFormNumber}`}</ModalHeader>
        <ModalBody>
          <Block>
            <FormControl
              label="Powód"
              required
              error={
                ((errors as any)?.reason && (errors as any)?.reason?.message) ||
                (error &&
                  error.graphQLErrors[0]?.extensions?.code ===
                    "INPUT_VALIDATION_ERROR" &&
                  error.graphQLErrors[0]?.extensions?.validationErrors
                    ?.find(
                      (vE: InputValidationError) => vE?.property === "reason"
                    )
                    ?.errors.map((message: string) => (
                      <div
                        key="error"
                        className={css({
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        })}
                      >
                        {message}
                        <span
                          className={css({
                            color: "#999",
                            marginLeft: "auto",
                            marginRight: "5px",
                          })}
                        >
                          Walidacja serwera
                        </span>
                        <AlertOctagon color="#999" size={12} />
                      </div>
                    ))[0])
              }
              disabled={isLoading}
            >
              <ControlledTextArea
                control={control}
                name="reason"
                id="reason"
                rules={{
                  required: formValidation.messages.required,
                }}
              />
            </FormControl>
          </Block>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            kind={KIND.secondary}
            $style={{ marginRight: "10px" }}
            onClick={() => {
              setIsOpen(false);
              reset();
            }}
            disabled={loading}
          >
            Anuluj
          </Button>
          <Button disabled={loading} isLoading={loading} type="submit">
            Zapisz
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
