import { BasicFilter, FILTERS } from "./../../filters.d";

export const ACTIVITY_LOGS_FILTERS: BasicFilter[] = [
  {
    id: "createdAt",
    label: "Data",
    type: FILTERS.Date,
  },
  {
    id: "userId",
    label: "Użytkownik",
    type: FILTERS.User,
  },
  {
    id: "activity",
    label: "Rodzaj aktywności",
    type: FILTERS.Activity,
  },
  {
    id: "subjectType",
    label: "Obiekt",
    type: FILTERS.ActivityLogsSubjectType,
  },
];
