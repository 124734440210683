import {
  ALIGN,
  Radio,
  RadioGroup,
  RadioGroupProps,
  RadioProps,
} from "baseui/radio";
import React, { ChangeEvent } from "react";
import { Controller, UseControllerProps } from "react-hook-form";

type Props = {
  buttons: { id: string; label: string }[];
  variant?: "horizontal" | "vertical";
} & RadioProps &
  RadioGroupProps;

export default function ControlledRadioButton({
  buttons,
  variant = "horizontal",
  control,
  name,
  rules,
  ...rest
}: UseControllerProps<any> & Props): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value, name } }) => (
        <RadioGroup
          value={value}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChange(e.target.value)
          }
          name={name}
          align={ALIGN.horizontal}
          overrides={{
            RadioGroupRoot: {
              style: {
                display: "flex",
                marginLeft: "-18px",
                marginRight: "-18px",
                ...(variant === "vertical" && {
                  flexDirection: "column",
                  alignItems: "flex-start",
                }),
              },
            },
          }}
          {...rest}
        >
          {buttons.map((button) => (
            <Radio
              value={button.id}
              key={button.id}
              overrides={{
                Root: {
                  style: {
                    flexGrow: 1,
                    flexShrink: 1,
                    flexBasis: 0,
                    paddingLeft: "18px",
                    paddingRight: "18px",
                  },
                },
                RadioMarkInner: {
                  style: ({ $checked }) => ({
                    width: $checked ? "6px" : "16px",
                    height: $checked ? "6px" : "16px",
                  }),
                },
              }}
            >
              {button.label}
            </Radio>
          ))}
        </RadioGroup>
      )}
    />
  );
}
