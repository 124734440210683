import { gql } from "@apollo/client";

export const DIVISOR_TEMPLATES_INDEX = gql`
  query(
    $pageSize: Int
    $offset: Int
    $sorting: [DivisorTemplateSort!]
    $filter: DivisorTemplateFilter
  ) {
    divisorTemplates(
      paging: { limit: $pageSize, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        name
        createdAt
        divisorItems {
          id
          name
          internalAccount {
            id
            name
            fundingSource
          }
          percent
        }
      }
    }
  }
`;

export const DIVISOR_TEMPLATES_SHOW = gql`
  query($id: Int!) {
    divisorTemplate(id: $id) {
      id
      name
      createdAt
      updatedAt
      divisorItems {
        id
        name
        internalAccount {
          id
          name
          fundingSource
        }
        percent
      }
    }
  }
`;

export const DIVISOR_TEMPLATES_CREATE = gql`
  mutation($divisorTemplateCreateInput: DivisorTemplateCreateInput!) {
    divisorTemplateCreate(
      divisorTemplateCreateInput: $divisorTemplateCreateInput
    ) {
      id
    }
  }
`;

export const DIVISOR_TEMPLATES_UPDATE = gql`
  mutation($divisorTemplateUpdateInput: DivisorTemplateUpdateInput!) {
    divisorTemplateUpdate(
      divisorTemplateUpdateInput: $divisorTemplateUpdateInput
    ) {
      id
    }
  }
`;

export const DIVISOR_TEMPLATES_DELETE = gql`
  mutation($divisorTemplateDeleteInput: DivisorTemplateDeleteInput!) {
    divisorTemplateDelete(
      divisorTemplateDeleteInput: $divisorTemplateDeleteInput
    ) {
      id
    }
  }
`;

export const DIVISOR_ITEMS_SHOW = gql`
  query($id: Int!) {
    divisorItem(id: $id) {
      id
      divisorTemplate {
        id
        name
        divisorItems {
          id
          percent
        }
      }
      name
      internalAccount {
        id
        name
      }
      percent
      createdAt
      updatedAt
    }
  }
`;

export const DIVISOR_TEMPLATES_SELECT_INDEX = gql`
  query {
    divisorTemplates(paging: { limit: 100, offset: 0 }) {
      totalCount
      nodes {
        id
        name
        createdAt
        divisorItems {
          id
          name
          internalAccount {
            id
            name
            fundingSource
          }
          percent
        }
      }
    }
  }
`;

export const DIVISOR_ITEMS_CREATE = gql`
  mutation($divisorItemCreateInput: DivisorItemCreateInput!) {
    divisorItemCreate(divisorItemCreateInput: $divisorItemCreateInput) {
      id
    }
  }
`;

export const DIVISOR_ITEMS_UPDATE = gql`
  mutation($divisorItemUpdateInput: DivisorItemUpdateInput!) {
    divisorItemUpdate(divisorItemUpdateInput: $divisorItemUpdateInput) {
      id
    }
  }
`;

export const DIVISOR_ITEMS_DELETE = gql`
  mutation($divisorItemDeleteInput: DivisorItemDeleteInput!) {
    divisorItemDelete(divisorItemDeleteInput: $divisorItemDeleteInput) {
      id
    }
  }
`;
