import { useStyletron } from "baseui";
import React from "react";

import Cell from "./cell";
import Grid from "./grid";

type CancellationBarProps = {
  $sticked: boolean;
  hasActiveAssignmentBar: boolean;
};

export default function CancellationBar({
  $sticked,
  hasActiveAssignmentBar,
}: CancellationBarProps): React.ReactElement | null {
  const [css, theme] = useStyletron();

  return (
    <div
      className={css({
        height: "26px",
        animationDuration: "200ms",
        animationTimingFunction: "ease",
        animationFillMode: "both",
        animationName: {
          from: {
            marginTop: "-60px",
            marginBottom: `${
              $sticked ? theme?.sizing.scale650 : theme?.sizing.scale1000
            }`,
          },
          to: {
            marginTop: `-${
              $sticked
                ? theme?.sizing.scale650
                : !hasActiveAssignmentBar
                ? theme?.sizing.scale1000
                : theme?.sizing.scale650
            }`,
            marginBottom: `${
              $sticked
                ? theme?.sizing.scale650
                : !hasActiveAssignmentBar
                ? theme?.sizing.scale700
                : theme?.sizing.scale650
            }`,
          },
        } as any,
      })}
    >
      <div
        className={css({
          backgroundColor: "rgb(255 69 46)",
          color: "white",
          paddingTop: "6px",
          paddingBottom: "6px",
          pointerEvents: "none",
          userSelect: "none",
          zIndex: 1,
          transition: "all 200ms ease",
          position: "relative",
          borderBottomWidth: "0px",
          borderBottomStyle: "solid",
          borderBottomColor: theme?.colors.backgroundTertiary,
          overflow: "hidden",
        })}
      >
        <Grid>
          <Cell span={12}>
            <div
              className={css({
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "11px",
                fontWeight: 700,
                textTransform: "uppercase",
                letterSpacing: "1px",
                gap: "50px",
              })}
            >
              {Array.from({ length: 34 }).map((value, index) => (
                <span key={index}>Anulowany</span>
              ))}
            </div>
          </Cell>
        </Grid>
      </div>
    </div>
  );
}
