import { FIELDS, FieldsGroup } from "../../fields.d";

export const CURRENCIES_FIELDS: FieldsGroup[] = [
  {
    id: "basicInfo",
    label: "Podstawowe informacje",
    fields: [
      {
        id: "code",
        label: "Symbol",
        type: FIELDS.Input,
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "name",
        label: "Nazwa",
        type: FIELDS.Input,
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "isVisible",
        label: "Widoczność",
        type: FIELDS.Input,
        dataType: "visibility-boolean",
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "value",
        label: "Kurs",
        type: FIELDS.Input,
        info: "exchangeRate",
        dataType: "pre",
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "effectiveOn",
        label: "Data kursu",
        type: FIELDS.Input,
        info: "exchangeRate",
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
    ],
  },
];
