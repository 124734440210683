import { FIELDS, FieldsGroup } from "../../fields.d";
import { JrwaClassification } from "../JrwaClassifications/jrwa-classifications";
import { OrganizationalUnit } from "../OrganizationalUnits/organizational-units";
import { User } from "../Users/users";

export type CreateFormInputs = {
  name: string;
  number: string;
  isCustomNumber: boolean;
  coordinatorName: string;
  jrwaClassification: JrwaClassification[];
  organizationalUnit: OrganizationalUnit[];
  groups?: { id: number; label: string }[];
  organizationalUnitGroupNumber?: number;
  organizationalUnitGroupName?: string;
  projectNumber: string;
  beneficiaryAddress: any;
  addressName: string;
  addressNip?: string;
  addressAddress?: string;
  addressStreetName?: string;
  addressBuildingNumber?: string;
  addressApartmentNumber?: string;
  addressCity: string;
  addressPostalCode: string;
  addressPhoneNumber?: string;
  addressMobileNumber?: string;
  addressEmail?: string;
  addressCountryCode: any;
  addressOverwrite: boolean;
  addressIsSensitive: boolean;
};

export type EditFormInputs = {
  name: string;
  coordinatorName: string;
  jrwaClassification: any;
  organizationalUnit: any;
  groups?: { id: number; label: string }[];
  organizationalUnitGroupNumber?: number;
  organizationalUnitGroupName?: string;
  projectNumber: string;
  beneficiaryAddress: any;
  addressName: string;
  addressNip?: string;
  addressAddress?: string;
  addressStreetName?: string;
  addressBuildingNumber?: string;
  addressApartmentNumber?: string;
  addressCity: string;
  addressPostalCode: string;
  addressPhoneNumber?: string;
  addressMobileNumber?: string;
  addressEmail?: string;
  addressCountryCode: any;
  addressOverwrite: boolean;
  addressIsSensitive: boolean;
};

export type ShareFormInputs = {
  users?: { id: number; label: string }[];
  scope?: "Full" | "Partial";
  accessLevel?: "Read" | "Update";
  comment?: string;
};

export type ChangeSupervisorInputs = {
  supervisorId: User[];
};

export const CASES_FIELDS: FieldsGroup[] = [
  {
    id: "basic",
    label: " ",
    fields: [
      {
        id: "name",
        label: "Tytuł",
        type: FIELDS.Input,
        span: 6,
        show: { visible: false },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "name",
        label: "Tytuł",
        type: FIELDS.Input,
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "supervisor",
        label: "Prowadzący sprawę",
        type: FIELDS.Input,
        span: 4,
        show: { accessor: ["supervisor", "firstName"], visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "model:users",
      },
      {
        id: "organizationalUnit",
        label: "Jednostka organizacyjna",
        type: FIELDS.UsersOrganizationalUnitsSelect,
        span: 6,
        show: { visible: false },
        create: { visible: false },
        edit: { visible: false },
        placeholder: "Wybierz",
      },
      {
        id: "organizationalUnitId",
        label: "Jednostka organizacyjna",
        type: FIELDS.Input,
        span: 4,
        show: { accessor: ["organizationalUnit", "name"], visible: true },
        create: { visible: false },
        edit: { visible: false },
        placeholder: "Wybierz",
        dataType: "model:organizational-units",
      },
      {
        id: "number",
        label: "Znak sprawy",
        type: FIELDS.Input,
        span: 6,
        placeholder: "Znak zostanie wygenerowany automatycznie",
        show: { visible: false },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "isCustomNumber",
        label: "Wprowadź znak sprawy kontynuowanej",
        type: FIELDS.Checkbox,
        span: 6,
        show: { visible: false },
        create: { visible: false },
        edit: { visible: false },
      },
    ],
  },
  {
    id: "documents",
    label: "Dokumenty",
    fields: [
      {
        id: "children",
        label: "",
        type: FIELDS.Input,
        span: 12,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
    ],
  },
  {
    id: "basic2",
    label: "Podstawowe informacje",
    fields: [
      {
        id: "name",
        label: "Tytuł",
        type: FIELDS.Input,
        span: 6,
        show: { visible: false },
        create: { visible: false },
        edit: { visible: true },
      },
      {
        id: "number",
        label: "Znak sprawy",
        type: FIELDS.Input,
        span: 6,
        placeholder: "Znak zostanie wygenerowany automatycznie",
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "isCustomNumber",
        label: "Wprowadź znak sprawy kontynuowanej",
        type: FIELDS.Checkbox,
        span: 6,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "name",
        label: "Tytuł",
        type: FIELDS.Input,
        span: 12,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "jrwaClassification",
        label: "Klasyfikacja JRWA",
        type: FIELDS.JrwaClassificationsSelect,
        span: 6,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: false },
        placeholder: "Wpisz lub wybierz",
      },
      {
        id: "number",
        label: "Znak sprawy",
        type: FIELDS.Input,
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "folderNumber",
        label: "Numer teczki",
        type: FIELDS.Input,
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "projectNumber",
        label: "Numer projektu",
        type: FIELDS.Input,
        span: 6,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "organizationalUnit",
        label: "Jednostka organizacyjna",
        type: FIELDS.UsersOrganizationalUnitsSelect,
        span: 6,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: false },
        placeholder: "Wybierz",
      },
      {
        id: "groups",
        label: "Grupa",
        type: FIELDS.GroupSelect,
        placeholder: "Wybierz",
        span: 6,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "organizationalUnitGroup",
        label: "Grupa",
        type: FIELDS.Input,
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "isConductedElectronically",
        label: "Sposób prowadzenia sprawy",
        type: FIELDS.Input,
        dataType: "case-conduct-boolean",
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "projectNumber",
        label: "Numer projektu",
        type: FIELDS.Input,
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
    ],
  },
  {
    id: "dates",
    label: "Daty",
    fields: [
      {
        id: "initiatedAt",
        label: "Utworzenie",
        type: FIELDS.Input,
        dataType: "datetime",
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "closedAt",
        label: "Zamknięcie",
        type: FIELDS.Input,
        dataType: "datetime",
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "closedBy",
        label: "Osoba zamykająca",
        type: FIELDS.Input,
        dataType: "model:users",
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
    ],
  },
  {
    id: "jrwaShow",
    label: "Klasyfikacja JRWA",
    fields: [
      {
        id: "jrwaClassificationId",
        label: "Nazwa",
        type: FIELDS.Input,
        span: 6,
        show: { accessor: ["jrwaClassification", "name"], visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "model:jrwa-classifications",
      },
      {
        id: "jrwaClassificationArchivalCategory",
        label: "Kategoria archiwalna",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "isBeneficiaryAddressRequired",
        label: "Wymagany adres beneficjenta",
        type: FIELDS.Input,
        span: 3,
        show: {
          accessor: ["jrwaClassification", "isBeneficiaryAddressRequired"],
          visible: true,
        },
        create: { visible: false },
        edit: { visible: false },
        dataType: "boolean",
      },
    ],
  },
  {
    id: "beneficiaryAddress",
    label: "Beneficjent",
    fields: [
      {
        id: "beneficiaryAddress",
        label: "Wybór z bazy adresowej",
        type: FIELDS.AddressesSelect,
        span: 6,
        edit: { visible: true },
        show: { visible: false },
        create: { visible: true },
        placeholder: "Wybierz",
      },
      {
        id: "addressNip",
        label: "NIP",
        type: FIELDS.Input,
        span: 3,
        edit: { visible: true },
        show: { visible: false },
        create: { visible: true },
        mask: "9999999999",
        placeholder: "9999999999",
      },
      {
        id: "coordinatorName",
        label: "Koordynator",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
        placeholder: "Imię i nazwisko",
      },
      {
        id: "addressName",
        label: "Nazwa",
        type: FIELDS.Input,
        span: 6,
        edit: { visible: true },
        show: { visible: false },
        create: { visible: true },
      },
      {
        id: "addressName",
        label: "Nazwa",
        type: FIELDS.Input,
        span: 6,
        edit: { visible: false },
        show: { accessor: ["beneficiaryAddress", "name"], visible: true },
        create: { visible: false },
      },
      {
        id: "addressIsSensitive",
        label: "Dane osobowe",
        type: FIELDS.Checkbox,
        span: 3,
        edit: { visible: true },
        show: { visible: false },
        create: { visible: true },
      },
      {
        id: "addressCountryCode",
        label: "Kraj",
        type: FIELDS.DictionaryValuesSelect,
        span: 3,
        dataType: "countryCode",
        edit: { visible: true },
        show: {
          visible: false,
        },
        create: { visible: true },
        typeName: "Address",
      },
      {
        id: "addressStreetName",
        label: "Ulica",
        type: FIELDS.Input,
        span: 3,
        edit: { visible: true },
        show: { visible: false },
        create: { visible: true },
      },
      {
        id: "addressAddress",
        label: "Ulica i numer",
        type: FIELDS.Input,
        span: 3,
        edit: { visible: false },
        show: { visible: true },
        create: { visible: false },
      },
      {
        id: "addressBuildingNumber",
        label: "Numer budynku",
        type: FIELDS.Input,
        span: 2,
        edit: { visible: true },
        show: { visible: false },
        create: { visible: true },
      },
      {
        id: "addressApartmentNumber",
        label: "Numer lokalu",
        type: FIELDS.Input,
        span: 2,
        edit: { visible: true },
        show: { visible: false },
        create: { visible: true },
      },
      {
        id: "addressPostalCode",
        label: "Kod pocztowy",
        type: FIELDS.Input,
        span: 2,
        edit: { visible: true },
        show: { visible: false },
        create: { visible: true },
      },
      {
        id: "addressPostalCode",
        label: "Kod pocztowy",
        type: FIELDS.Input,
        span: 3,
        edit: { visible: false },
        show: { accessor: ["beneficiaryAddress", "postalCode"], visible: true },
        create: { visible: false },
      },
      {
        id: "addressCity",
        label: "Miejscowość",
        type: FIELDS.Input,
        span: 3,
        edit: { visible: true },
        show: { accessor: ["beneficiaryAddress", "city"], visible: true },
        create: { visible: true },
      },
      {
        id: "addressCountryCode",
        label: "Kraj",
        type: FIELDS.DictionaryValuesSelect,
        span: 3,
        dataType: "countryCode",
        edit: { visible: false },
        show: {
          accessor: ["beneficiaryAddress", "countryCode"],
          visible: true,
        },
        create: { visible: false },
        typeName: "Address",
      },
      {
        id: "addressNip",
        label: "NIP",
        dataType: "nip",
        type: FIELDS.Input,
        span: 3,
        edit: { visible: false },
        show: { accessor: ["beneficiaryAddress", "nip"], visible: true },
        create: { visible: false },
      },
      {
        id: "addressPhoneNumber",
        label: "Numer telefonu",
        type: FIELDS.Input,
        span: 3,
        edit: { visible: true },
        show: {
          accessor: ["beneficiaryAddress", "phoneNumber"],
          visible: true,
        },
        create: { visible: true },
        mask: "99 999-99-99",
        placeholder: "99 999-99-99",
      },
      {
        id: "addressMobileNumber",
        label: "Numer telefonu kom.",
        type: FIELDS.Input,
        span: 3,
        edit: { visible: true },
        show: {
          accessor: ["beneficiaryAddress", "mobileNumber"],
          visible: true,
        },
        create: { visible: true },
        mask: "+99 999-999-999",
        placeholder: "+99 999-999-999",
      },
      {
        id: "addressEmail",
        label: "Adres e-mail",
        type: FIELDS.Input,
        span: 3,
        edit: { visible: true },
        show: { accessor: ["beneficiaryAddress", "email"], visible: true },
        create: { visible: true },
        dataType: "email",
      },
      {
        id: "addressOverwrite",
        label: "Nadpisz dane adresowe",
        type: FIELDS.Checkbox,
        span: 3,
        edit: { visible: true },
        show: { visible: false },
        create: { visible: true },
      },
    ],
  },
];
