import { FIELDS, FieldsGroup } from "../../../fields.d";
import { ENadawcaGabaryt, ENadawcaKategoria } from "../e-nadawca-parcels";

export type FormInputs = {
  documentParcel: {
    id: string;
    label?: string;
    shipmentType?: string;
  }[];
  sendingList: { id: number | string; label?: string }[];
  opis?: string;
  planowanaDataNadania?: Date | null;
  numerNadania?: string;
  sygnatura?: string;
  terminSprawy?: string;
  rodzaj?: string;
  posteRestante?: boolean;
  iloscPotwierdzenOdbioru?: number | string;
  kategoriaSelect?: { id: string; label: string }[] | undefined;
  kategoria?: ENadawcaKategoria;
  gabarytSelect?: { id: string; label: string }[] | undefined;
  gabaryt?: ENadawcaGabaryt | undefined;
  masa?: number | string;
  wartosc?: number | string;
};

export const DOCUMENT_ADDRESS_FIELDS: FieldsGroup[] = [
  {
    id: "recipient",
    label: "Adresat",
    fields: [
      {
        id: "addressName",
        label: "Nazwa",
        type: FIELDS.Input,
        span: 12,
        show: { accessor: ["recipient", "name"], visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "addressIsSensitive",
        label: "Dane osobowe",
        type: FIELDS.Checkbox,
        span: 3,
        show: { visible: false },
        create: { visible: false },
        edit: { visible: true },
      },
      {
        id: "addressAddress",
        label: "Ulica i numer",
        type: FIELDS.Input,
        span: 3,
        show: { accessor: ["recipient", "address"], visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "addressPostalCode",
        label: "Kod pocztowy",
        type: FIELDS.Input,
        span: 3,
        show: { accessor: ["recipient", "postalCode"], visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "addressCity",
        label: "Miejscowość",
        type: FIELDS.Input,
        span: 3,
        show: { accessor: ["recipient", "city"], visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "addressCountryCode",
        label: "Kraj",
        type: FIELDS.Input,
        span: 3,
        show: { accessor: ["recipient", "countryCode"], visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "countryCode",
      },
      {
        id: "addressNip",
        label: "NIP",
        type: FIELDS.Input,
        span: 3,
        show: { accessor: ["recipient", "nip"], visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "nip",
      },
      {
        id: "addressPhoneNumber",
        label: "Numer telefonu",
        type: FIELDS.Input,
        span: 3,
        show: { accessor: ["recipient", "phoneNumber"], visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "addressEmail",
        label: "Adres e-mail",
        type: FIELDS.Input,
        span: 6,
        show: { accessor: ["recipient", "email"], visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "email",
      },
    ],
  },
];

export const PRZESYLKA_LISTOWA_ZADEKLAROWANA_WARTOSC_FIELDS: FieldsGroup[] = [
  {
    id: "basic",
    label: "Podstawowe informacje",
    fields: [
      {
        id: "shipmentRate",
        label: "Stawka",
        type: FIELDS.Input,
        placeholder: "Wybierz",
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "sendingList",
        label: "Lista wysyłkowa",
        type: FIELDS.SendingListsSelect,
        placeholder: "Wybierz",
        span: 6,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: false },
      },
      {
        id: "kategoriaSelect",
        label: "Kategoria",
        type: FIELDS.ENadawcaKategoriaSelect,
        placeholder: "Wybierz",
        span: 3,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: false },
      },
      {
        id: "gabarytSelect",
        label: "Gabaryt",
        type: FIELDS.ENadawcaGabarytSelect,
        placeholder: "Wybierz",
        span: 3,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: false },
      },
      {
        id: "masa",
        label: "Masa",
        type: FIELDS.NumberInput,
        span: 3,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: false },
        endEnhancer: "g",
      },
      {
        id: "wartosc",
        label: "Wartość",
        type: FIELDS.NumberInput,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
    ],
  },
  {
    id: "basic",
    label: "Dodatkowe informacje",
    fields: [
      {
        id: "posteRestante",
        label: "Poste Restante",
        type: FIELDS.Checkbox,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "opis",
        label: "Opis",
        type: FIELDS.Input,
        span: 6,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "planowanaDataNadania",
        label: "Planowana data nadania",
        type: FIELDS.DatePicker,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "terminSprawy",
        label: "Termin sprawy",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "numerNadania",
        label: "Numer nadania",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "rodzaj",
        label: "Rodzaj",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "iloscPotwierdzenOdbioru",
        label: "Ilość potwierdzeń odbioru",
        type: FIELDS.NumberInput,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
    ],
  },
];
