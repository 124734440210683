import { ApolloError, useMutation } from "@apollo/client";
import { useStyletron } from "baseui";
import { KIND } from "baseui/button";
import { ModalBody, ModalFooter, ModalHeader, ROLE, SIZE } from "baseui/modal";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router";
import { AlertOctagon, Share } from "tabler-icons-react";

import { InputValidationError } from "../api";
import FormControl from "../components/form-control";
import { ChangeSupervisorInputs } from "../containers/Cases/cases.form";
import { CASE_SUPERVISOR_CHANGE } from "../containers/Cases/cases.gql";
import { Document } from "../containers/Documents/documents";
import {
  FlowStartFormInputs,
  LinkFormInputs,
} from "../containers/Documents/documents.form";
import {
  DOCUMENTS_FLOW_START,
  DOCUMENTS_LINK,
} from "../containers/Documents/documents.gql";
import { AssignmentLocationState } from "../containers/Folders/folders";
import { OrganizationalUnit } from "../containers/OrganizationalUnits/organizational-units";
import { MoveFormInputs } from "../containers/OrganizationalUnits/organizational-units.form";
import {
  ORGANIZATIONAL_UNITS_ASSIGN_USER,
  ORGANIZATIONAL_UNITS_MOVE,
} from "../containers/OrganizationalUnits/organizational-units.gql";
import { User } from "../containers/Users/users";
import { AssignFormInputs } from "../containers/Users/users.form";
import { useAssignment } from "../contexts/assignment-context";
import { useLoading } from "../contexts/loading-context";
import { formValidation } from "../utils/formValidation";
import Button from "./button";
import { ControlledCaseShareSwitch } from "./case-share-switch";
import Modal from "./modal";
import {
  ControlledDocumentsSelect,
  ControlledOrganizationalUnitsSelect,
  ControlledPositionsSelect,
  ControlledUsersOrganizationalUnitsSelect,
  ControlledUsersSelect,
} from "./select";

type Props = {
  isOpen: boolean;
  close: () => void;
  confirm?: () => void;
  onCompleted?: () => void;
};

type AssignUserToOrganizationalUnitDialogProps = {
  user?: User;
  organizationalUnit?: OrganizationalUnit;
} & Props;

export function AssignUserToOrganizationalUnitDialog({
  user,
  organizationalUnit,
  isOpen,
  close,
  onCompleted,
}: AssignUserToOrganizationalUnitDialogProps): React.ReactElement {
  const [css] = useStyletron();

  const { isLoading, setIsLoading } = useLoading();
  const { enqueueSnackbar } = useSnackbar();

  const {
    setValue,
    control,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm<AssignFormInputs>({
    defaultValues: {
      userId: user && [
        {
          id: user?.id,
          firstName: user?.firstName,
          lastName: user?.lastName,
        },
      ],
    },
  });

  useEffect(() => {
    if (user) {
      setValue("userId", user && [user]);
    }

    if (organizationalUnit) {
      setValue("id", organizationalUnit && [organizationalUnit]);
    }
  }, [user, organizationalUnit]);

  const [assignUser, { error }] = useMutation(ORGANIZATIONAL_UNITS_ASSIGN_USER);

  const onSubmit = async ({
    id,
    userId,
    positionId,
  }: AssignFormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      await assignUser({
        variables: {
          id: id && id[0]?.id,
          userId: userId && userId[0]?.id,
          positionId: positionId && positionId[0]?.id,
        },
      });

      enqueueSnackbar({
        message: "Zapisano pomyślnie",
        variant: "success",
      });

      onCompleted && onCompleted();
      close();
      reset();
    } catch (error: unknown) {
      enqueueSnackbar({
        message:
          (error as ApolloError).graphQLErrors?.map(
            ({ message }) => message
          )[0] || (error as ApolloError).message,
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      onClose={close}
      closeable
      isOpen={isOpen}
      size={SIZE.default}
      role={ROLE.dialog}
    >
      <ModalHeader>Przypisz do jednostki organizacyjnej</ModalHeader>
      <ModalBody>
        <FormControl
          label="Użytkownik"
          required
          error={
            (errors.userId && (errors.userId as any).message) ||
            (error &&
              error.graphQLErrors[0]?.extensions?.code ===
                "INPUT_VALIDATION_ERROR" &&
              error.graphQLErrors[0]?.extensions?.validationErrors
                ?.find((vE: InputValidationError) => vE?.property === "userId")
                ?.errors.map((message: string) => (
                  <div
                    key="error"
                    className={css({
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    })}
                  >
                    {message}
                    <span
                      className={css({
                        color: "#999",
                        marginLeft: "auto",
                        marginRight: "5px",
                      })}
                    >
                      Walidacja serwera
                    </span>
                    <AlertOctagon color="#999" size={12} />
                  </div>
                ))[0])
          }
          disabled={isLoading || !!user}
        >
          <ControlledUsersSelect
            control={control}
            name="userId"
            placeholder="Użytkownik"
            rules={{
              required: formValidation.messages.required,
            }}
          />
        </FormControl>

        <FormControl
          label="Jednostka organizacyjna"
          required
          error={
            (errors.id && (errors.id as any).message) ||
            (error &&
              error.graphQLErrors[0]?.extensions?.code ===
                "INPUT_VALIDATION_ERROR" &&
              error.graphQLErrors[0]?.extensions?.validationErrors
                ?.find((vE: InputValidationError) => vE?.property === "id")
                ?.errors.map((message: string) => (
                  <div
                    key="error"
                    className={css({
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    })}
                  >
                    {message}
                    <span
                      className={css({
                        color: "#999",
                        marginLeft: "auto",
                        marginRight: "5px",
                      })}
                    >
                      Walidacja serwera
                    </span>
                    <AlertOctagon color="#999" size={12} />
                  </div>
                ))[0])
          }
          disabled={isLoading || !!organizationalUnit}
        >
          <ControlledOrganizationalUnitsSelect
            control={control}
            name="id"
            placeholder="Jednostka organizacyjna"
            rules={{
              required: formValidation.messages.required,
            }}
          />
        </FormControl>

        <FormControl
          label="Stanowisko"
          error={
            (errors.positionId && (errors.positionId as any).message) ||
            (error &&
              error.graphQLErrors[0]?.extensions?.code ===
                "INPUT_VALIDATION_ERROR" &&
              error.graphQLErrors[0]?.extensions?.validationErrors
                ?.find(
                  (vE: InputValidationError) => vE?.property === "positionId"
                )
                ?.errors.map((message: string) => (
                  <div
                    key="error"
                    className={css({
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    })}
                  >
                    {message}
                    <span
                      className={css({
                        color: "#999",
                        marginLeft: "auto",
                        marginRight: "5px",
                      })}
                    >
                      Walidacja serwera
                    </span>
                    <AlertOctagon color="#999" size={12} />
                  </div>
                ))[0])
          }
          disabled={isLoading}
        >
          <ControlledPositionsSelect
            control={control}
            name="positionId"
            placeholder="Stanowisko"
          />
        </FormControl>
      </ModalBody>
      <ModalFooter>
        <Button
          kind={KIND.secondary}
          $style={{ marginRight: "10px" }}
          onClick={close}
        >
          Anuluj
        </Button>
        <Button
          disabled={isLoading}
          isLoading={isLoading}
          onClick={handleSubmit(onSubmit)}
        >
          Przypisz
        </Button>
      </ModalFooter>
    </Modal>
  );
}

type MoveOrganizationalUnitDialogProps = {
  user?: User;
  organizationalUnit?: OrganizationalUnit;
} & Props;

export function MoveOrganizationalUnitDialog({
  organizationalUnit,
  isOpen,
  close,
  onCompleted,
}: MoveOrganizationalUnitDialogProps): React.ReactElement {
  const [css] = useStyletron();

  const { isLoading, setIsLoading } = useLoading();
  const { enqueueSnackbar } = useSnackbar();

  const {
    setValue,
    control,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm<MoveFormInputs>();

  useEffect(() => {
    if (organizationalUnit) {
      setValue("id", organizationalUnit && [organizationalUnit]);
    }
  }, [organizationalUnit]);

  const [moveUnit, { error }] = useMutation(ORGANIZATIONAL_UNITS_MOVE);

  const onSubmit = async ({ id, parentId }: MoveFormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      await moveUnit({
        variables: {
          id: id && id[0]?.id,
          parentId: parentId && parentId[0]?.id,
        },
      });

      enqueueSnackbar({
        message: "Zapisano pomyślnie",
        variant: "success",
      });

      onCompleted && onCompleted();
      close();
      reset();
    } catch (error: unknown) {
      enqueueSnackbar({
        message:
          (error as ApolloError).graphQLErrors?.map(
            ({ message }) => message
          )[0] || (error as ApolloError).message,
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      onClose={close}
      closeable
      isOpen={isOpen}
      size={SIZE.default}
      role={ROLE.dialog}
    >
      <ModalHeader>Przenieś jednostkę organizacyjną</ModalHeader>
      <ModalBody>
        <FormControl
          label="Jednostka organizacyjna"
          required
          error={
            (errors.id && (errors.id as any).message) ||
            (error &&
              error.graphQLErrors[0]?.extensions?.code ===
                "INPUT_VALIDATION_ERROR" &&
              error.graphQLErrors[0]?.extensions?.validationErrors
                ?.find((vE: InputValidationError) => vE?.property === "id")
                ?.errors.map((message: string) => (
                  <div
                    key="error"
                    className={css({
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    })}
                  >
                    {message}
                    <span
                      className={css({
                        color: "#999",
                        marginLeft: "auto",
                        marginRight: "5px",
                      })}
                    >
                      Walidacja serwera
                    </span>
                    <AlertOctagon color="#999" size={12} />
                  </div>
                ))[0])
          }
          disabled={true}
        >
          <ControlledOrganizationalUnitsSelect
            control={control}
            name="id"
            placeholder="Jednostka organizacyjna"
            rules={{
              required: formValidation.messages.required,
            }}
          />
        </FormControl>

        <FormControl
          label="Nowa jednostka nadrzędna"
          required
          error={
            (errors.parentId && (errors.parentId as any).message) ||
            (error &&
              error.graphQLErrors[0]?.extensions?.code ===
                "INPUT_VALIDATION_ERROR" &&
              error.graphQLErrors[0]?.extensions?.validationErrors
                ?.find(
                  (vE: InputValidationError) => vE?.property === "parentId"
                )
                ?.errors.map((message: string) => (
                  <div
                    key="error"
                    className={css({
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    })}
                  >
                    {message}
                    <span
                      className={css({
                        color: "#999",
                        marginLeft: "auto",
                        marginRight: "5px",
                      })}
                    >
                      Walidacja serwera
                    </span>
                    <AlertOctagon color="#999" size={12} />
                  </div>
                ))[0])
          }
          disabled={isLoading}
        >
          <ControlledOrganizationalUnitsSelect
            control={control}
            name="parentId"
            placeholder="Nowa jednostka nadrzędna"
            rules={{
              required: formValidation.messages.required,
            }}
          />
        </FormControl>
      </ModalBody>
      <ModalFooter>
        <Button
          kind={KIND.secondary}
          $style={{ marginRight: "10px" }}
          onClick={close}
        >
          Anuluj
        </Button>
        <Button
          disabled={isLoading}
          isLoading={isLoading}
          onClick={handleSubmit(onSubmit)}
        >
          Przenieś
        </Button>
      </ModalFooter>
    </Modal>
  );
}

type LinkDocumentsDialogProps = {
  document?: Document;
} & Props;

export function LinkDocumentsDialog({
  document,
  isOpen,
  close,
  onCompleted,
}: LinkDocumentsDialogProps): React.ReactElement {
  const [css] = useStyletron();

  const { isLoading, setIsLoading } = useLoading();
  const { enqueueSnackbar } = useSnackbar();

  const { state } = useLocation<AssignmentLocationState>();

  const {
    control,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm<LinkFormInputs>();

  const [link, { error }] = useMutation(DOCUMENTS_LINK, {
    ...(state?.assignmentId && {
      context: {
        headers: {
          Assignment: state?.assignmentId,
        },
      },
    }),
  });

  const onSubmit = async ({ parentId }: LinkFormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      await link({
        variables: {
          documentLinkCreateInput: {
            linkerId: document?.id,
            linkedId: parentId && parentId[0]?.id,
          },
        },
      });

      enqueueSnackbar({
        message: "Zapisano pomyślnie",
        variant: "success",
      });

      onCompleted && onCompleted();
      close();
      reset();
    } catch (error: unknown) {
      enqueueSnackbar({
        message:
          (error as ApolloError).graphQLErrors?.map(
            ({ message }) => message
          )[0] || (error as ApolloError).message,
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      onClose={close}
      closeable
      isOpen={isOpen}
      size={SIZE.default}
      role={ROLE.dialog}
    >
      <ModalHeader>Powiąż z dokumentem</ModalHeader>
      <ModalBody>
        <FormControl
          label="Dokument"
          required
          error={
            (errors.parentId && (errors.parentId as any).message) ||
            (error &&
              error.graphQLErrors[0]?.extensions?.code ===
                "INPUT_VALIDATION_ERROR" &&
              error.graphQLErrors[0]?.extensions?.validationErrors
                ?.find(
                  (vE: InputValidationError) => vE?.property === "parentId"
                )
                ?.errors.map((message: string) => (
                  <div
                    key="error"
                    className={css({
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    })}
                  >
                    {message}
                    <span
                      className={css({
                        color: "#999",
                        marginLeft: "auto",
                        marginRight: "5px",
                      })}
                    >
                      Walidacja serwera
                    </span>
                    <AlertOctagon color="#999" size={12} />
                  </div>
                ))[0])
          }
          disabled={isLoading}
        >
          <ControlledDocumentsSelect
            control={control}
            name="parentId"
            placeholder="Wybierz"
            rules={{
              required: formValidation.messages.required,
            }}
            filterOptions={(options, filterValue) =>
              options.filter(
                (o) =>
                  o.id !== document?.id &&
                  o?.label
                    ?.toString()
                    .toLocaleLowerCase()
                    .includes(filterValue.toLocaleLowerCase())
              )
            }
          />
        </FormControl>
      </ModalBody>
      <ModalFooter>
        <Button
          kind={KIND.secondary}
          $style={{ marginRight: "10px" }}
          onClick={close}
        >
          Anuluj
        </Button>
        <Button
          disabled={isLoading}
          isLoading={isLoading}
          onClick={handleSubmit(onSubmit)}
        >
          Powiąż
        </Button>
      </ModalFooter>
    </Modal>
  );
}

type ChangeCaseSupervisorDialogProps = {
  caseId: number;
} & Props;

export function ChangeCaseSupervisorDialog({
  caseId,
  isOpen,
  close,
  onCompleted,
}: ChangeCaseSupervisorDialogProps): React.ReactElement {
  const [css] = useStyletron();

  const { isLoading, setIsLoading } = useLoading();
  const { enqueueSnackbar } = useSnackbar();

  const { assignment, isAssignmentActive } = useAssignment();

  const {
    control,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm<ChangeSupervisorInputs>();

  const [changeSupervisor, { error }] = useMutation(CASE_SUPERVISOR_CHANGE, {
    ...(isAssignmentActive &&
      assignment && {
        context: {
          headers: {
            Assignment: assignment.id,
          },
        },
      }),
  });

  const onSubmit = async ({
    supervisorId,
  }: ChangeSupervisorInputs): Promise<void> => {
    setIsLoading(true);

    try {
      await changeSupervisor({
        variables: {
          caseSupervisorChangeInput: {
            caseId,
            supervisorId: supervisorId && supervisorId[0]?.id,
          },
        },
      });

      enqueueSnackbar({
        message: "Zapisano pomyślnie",
        variant: "success",
      });

      onCompleted && onCompleted();
      close();
      reset();
    } catch (error: unknown) {
      enqueueSnackbar({
        message:
          (error as ApolloError).graphQLErrors?.map(
            ({ message }) => message
          )[0] || (error as ApolloError).message,
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      onClose={close}
      closeable
      isOpen={isOpen}
      size={SIZE.default}
      role={ROLE.dialog}
    >
      <ModalHeader>Zmień prowadzącego sprawę</ModalHeader>
      <ModalBody>
        <FormControl
          label="Użytkownik"
          required
          error={
            (errors.supervisorId && (errors.supervisorId as any).message) ||
            (error &&
              error.graphQLErrors[0]?.extensions?.code ===
                "INPUT_VALIDATION_ERROR" &&
              error.graphQLErrors[0]?.extensions?.validationErrors
                ?.find((vE: InputValidationError) => vE?.property === "userId")
                ?.errors.map((message: string) => (
                  <div
                    key="error"
                    className={css({
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    })}
                  >
                    {message}
                    <span
                      className={css({
                        color: "#999",
                        marginLeft: "auto",
                        marginRight: "5px",
                      })}
                    >
                      Walidacja serwera
                    </span>
                    <AlertOctagon color="#999" size={12} />
                  </div>
                ))[0])
          }
          disabled={isLoading}
        >
          <ControlledUsersSelect
            control={control}
            name="supervisorId"
            placeholder="Nowy prowadzący sprawę"
            rules={{
              required: formValidation.messages.required,
            }}
          />
        </FormControl>
      </ModalBody>
      <ModalFooter>
        <Button
          kind={KIND.secondary}
          $style={{ marginRight: "10px" }}
          onClick={close}
        >
          Anuluj
        </Button>
        <Button
          disabled={isLoading}
          isLoading={isLoading}
          onClick={handleSubmit(onSubmit)}
        >
          Zmień
        </Button>
      </ModalFooter>
    </Modal>
  );
}

type ShareCaseDialogProps = {
  caseId: number;
} & Props;

export function ShareCaseDialog({
  caseId,
  isOpen,
  close,
  onCompleted,
}: ShareCaseDialogProps): React.ReactElement {
  const [css] = useStyletron();

  const { isLoading, setIsLoading } = useLoading();
  const { enqueueSnackbar } = useSnackbar();

  const {
    control,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm<ChangeSupervisorInputs>();

  const [changeSupervisor, { error }] = useMutation(CASE_SUPERVISOR_CHANGE);

  const onSubmit = async ({
    supervisorId,
  }: ChangeSupervisorInputs): Promise<void> => {
    setIsLoading(true);

    try {
      await changeSupervisor({
        variables: {
          caseSupervisorChangeInput: {
            caseId,
            supervisorId: supervisorId && supervisorId[0]?.id,
          },
        },
      });

      enqueueSnackbar({
        message: "Zapisano pomyślnie",
        variant: "success",
      });

      onCompleted && onCompleted();
      close();
      reset();
    } catch (error: unknown) {
      enqueueSnackbar({
        message:
          (error as ApolloError).graphQLErrors?.map(
            ({ message }) => message
          )[0] || (error as ApolloError).message,
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      onClose={close}
      closeable
      isOpen={isOpen}
      size={SIZE.auto}
      role={ROLE.dialog}
      $style={{
        width: "100%",
        maxWidth: "50vw",
      }}
    >
      <ModalHeader>Udostępnij sprawę</ModalHeader>
      <ModalBody>
        <FormControl
          label="Użytkownicy"
          required
          error={
            (errors.supervisorId && (errors.supervisorId as any).message) ||
            (error &&
              error.graphQLErrors[0]?.extensions?.code ===
                "INPUT_VALIDATION_ERROR" &&
              error.graphQLErrors[0]?.extensions?.validationErrors
                ?.find((vE: InputValidationError) => vE?.property === "userId")
                ?.errors.map((message: string) => (
                  <div
                    key="error"
                    className={css({
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    })}
                  >
                    {message}
                    <span
                      className={css({
                        color: "#999",
                        marginLeft: "auto",
                        marginRight: "5px",
                      })}
                    >
                      Walidacja serwera
                    </span>
                    <AlertOctagon color="#999" size={12} />
                  </div>
                ))[0])
          }
          disabled={isLoading}
        >
          <ControlledUsersSelect
            multi
            control={control}
            name="supervisorId"
            placeholder="Wybierz"
            rules={{
              required: formValidation.messages.required,
            }}
          />
        </FormControl>

        <FormControl
          label="Rodzaj udostępnienia"
          required
          error={
            (errors.supervisorId && (errors.supervisorId as any).message) ||
            (error &&
              error.graphQLErrors[0]?.extensions?.code ===
                "INPUT_VALIDATION_ERROR" &&
              error.graphQLErrors[0]?.extensions?.validationErrors
                ?.find((vE: InputValidationError) => vE?.property === "userId")
                ?.errors.map((message: string) => (
                  <div
                    key="error"
                    className={css({
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    })}
                  >
                    {message}
                    <span
                      className={css({
                        color: "#999",
                        marginLeft: "auto",
                        marginRight: "5px",
                      })}
                    >
                      Walidacja serwera
                    </span>
                    <AlertOctagon color="#999" size={12} />
                  </div>
                ))[0])
          }
          disabled={isLoading}
        >
          <ControlledCaseShareSwitch
            control={control}
            name={"shareType"}
            disabled={isLoading}
            rules={{
              required: formValidation.messages.required,
            }}
          />
        </FormControl>
      </ModalBody>

      <ModalFooter>
        <Button
          kind={KIND.secondary}
          $style={{ marginRight: "10px" }}
          onClick={close}
        >
          Anuluj
        </Button>
        <Button
          disabled={isLoading}
          isLoading={isLoading}
          startEnhancer={<Share size={12} />}
          onClick={handleSubmit(onSubmit)}
        >
          Udostępnij
        </Button>
      </ModalFooter>
    </Modal>
  );
}

type DocumentFlowStartDialogProps = { documentId?: number } & Props;

export function DocumentFlowStartDialog({
  documentId,
  isOpen,
  close,
  onCompleted,
}: DocumentFlowStartDialogProps): React.ReactElement {
  const [css] = useStyletron();

  const { isLoading, setIsLoading } = useLoading();
  const { enqueueSnackbar } = useSnackbar();

  const {
    setValue,
    control,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm<FlowStartFormInputs>();

  const [startFlow, { error }] = useMutation(DOCUMENTS_FLOW_START);

  const onSubmit = async ({
    organizationalUnitId,
  }: FlowStartFormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      await startFlow({
        variables: {
          documentFlowStartInput: {
            documentId,
            organizationalUnitId:
              organizationalUnitId && organizationalUnitId?.[0]?.id,
          },
        },
      });

      enqueueSnackbar({
        message: "Zapisano pomyślnie",
        variant: "success",
      });

      onCompleted && onCompleted();
      close();
      reset();
    } catch (error: unknown) {
      enqueueSnackbar({
        message:
          (error as ApolloError).graphQLErrors?.map(
            ({ message }) => message
          )[0] || (error as ApolloError).message,
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      onClose={close}
      closeable
      isOpen={isOpen}
      size={SIZE.default}
      role={ROLE.dialog}
    >
      <ModalHeader>Rozpocznij obieg</ModalHeader>
      <ModalBody>
        <FormControl
          label="Jednostka organizacyjna"
          required
          error={
            (errors.organizationalUnitId &&
              (errors.organizationalUnitId as any).message) ||
            (error &&
              error.graphQLErrors[0]?.extensions?.code ===
                "INPUT_VALIDATION_ERROR" &&
              error.graphQLErrors[0]?.extensions?.validationErrors
                ?.find(
                  (vE: InputValidationError) =>
                    vE?.property === "organizationalUnitId"
                )
                ?.errors.map((message: string) => (
                  <div
                    key="error"
                    className={css({
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    })}
                  >
                    {message}
                    <span
                      className={css({
                        color: "#999",
                        marginLeft: "auto",
                        marginRight: "5px",
                      })}
                    >
                      Walidacja serwera
                    </span>
                    <AlertOctagon color="#999" size={12} />
                  </div>
                ))[0])
          }
        >
          <ControlledUsersOrganizationalUnitsSelect
            control={control}
            name="organizationalUnitId"
            placeholder="Jednostka organizacyjna"
            rules={{
              required: formValidation.messages.required,
            }}
          />
        </FormControl>
      </ModalBody>

      <ModalFooter>
        <Button
          kind={KIND.secondary}
          $style={{ marginRight: "10px" }}
          onClick={close}
        >
          Anuluj
        </Button>
        <Button
          disabled={isLoading}
          isLoading={isLoading}
          onClick={handleSubmit(onSubmit)}
        >
          Rozpocznij
        </Button>
      </ModalFooter>
    </Modal>
  );
}
