import { ApolloError, useQuery } from "@apollo/client";
import { ColumnDef, Row } from "@tanstack/react-table";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { StyledLink } from "baseui/link";
import { HeadingSmall } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { MouseEvent, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  Check,
  CircleCheck,
  Cloud,
  Eye,
  FileExport,
  FileImport,
  FileSymlink,
  Paperclip,
} from "tabler-icons-react";

import AssignmentFinishModal from "../../../components/assignment-finish-modal";
import BottomPanel from "../../../components/bottom-panel";
import Button, { ForwardedButton } from "../../../components/button";
import Cell from "../../../components/cell";
import Content from "../../../components/content";
import { FieldName } from "../../../components/documents-table";
import Filters from "../../../components/filters";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import PagingControls from "../../../components/paging-controls";
import SortingTableHeader, {
  SortDirection,
} from "../../../components/sorting-table-header";
import { MemoizedStatefulTableV8 } from "../../../components/stateful-table-v8";
import Tooltip from "../../../components/tooltip";
import { DOCUMENT_KINDS } from "../../../constants";
import { useAuth } from "../../../contexts/auth-context";
import { useDictionaries } from "../../../contexts/dictionaries-context";
import { useLoading } from "../../../contexts/loading-context";
import { usePaging } from "../../../contexts/paging-context";
import { useSubstitution } from "../../../contexts/substitution-context";
import { BasicFilter, FiltersState } from "../../../filters";
import { checkDocumentPermissionToShow } from "../../../utils/check-document-permission";
import { checkPermission } from "../../../utils/check-permission";
import { client } from "../../../utils/client";
import { translateFiltersState } from "../../../utils/filters";
import { PERMISSIONS } from "../../../utils/permissions";
import { setSortingParams } from "../../../utils/sorting";
import { Case } from "../../Cases/cases";
import { Document } from "../../Documents/documents";
import {
  Assignment,
  AssignmentType,
  FolderKey,
  FOLDERS_NAME,
} from "../folders.d";
import { FOLDERS_FILTERS_RECEIVED } from "../folders.filters";
import {
  ASSIGNMENT_CREATED,
  ASSIGNMENT_DISPATCH_CANCELLED,
  FOLDERS_RECEIVED_ASSIGNMENTS,
} from "../folders.gql";

export default function FoldersReceived(): React.ReactElement {
  const [css, theme] = useStyletron();
  const { pageSize, currentPage, setTotalCount } = usePaging();

  const { folder } = useParams<{
    folder: string;
  }>();

  const { search } = useLocation();

  const params = useMemo(() => new URLSearchParams(search), [search]);

  const [sortBy, setSortBy] = useState<FieldName | null>(
    (params.get("sortBy") as FieldName) || FieldName.CreatedAt
  );

  const [sortDirection, setSortDirection] = useState<SortDirection | null>(
    (params.get("sortDirection") as SortDirection) || SortDirection.DESC
  );

  const [assignment, setAssignment] = useState<Assignment>();
  const [animate, setAnimate] = useState(false);

  const [filters, setFilters] = useState<FiltersState>();

  const history = useHistory();
  const location = useLocation();
  const { findValue } = useDictionaries();

  const { enqueueSnackbar } = useSnackbar();
  const {
    isFetching,
    setIsFetching,
    isPartialFetching,
    setIsPartialFetching,
  } = useLoading();

  const { refetch, data, loading, error, networkStatus } = useQuery(
    FOLDERS_RECEIVED_ASSIGNMENTS,
    {
      variables: {
        pageSize: pageSize,
        offset: (currentPage - 1) * pageSize,
        ...(sortBy &&
          sortDirection && {
            sorting: {
              field: sortBy,
              direction: sortDirection,
            },
          }),
        filter: {
          and: [
            {
              status: {
                eq: Object.keys(FolderKey)[
                  Object.values(FolderKey).indexOf(folder)
                ],
              },
            },
            {
              ...(filters && (translateFiltersState(filters) as BasicFilter[])),
            },
          ],
        },
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  const { substitution } = useSubstitution();

  const { refetchNewAssignments } = useAuth();

  useEffect(() => {
    refetchNewAssignments();

    return () => {
      setTimeout(refetchNewAssignments, 1000);
    };
  }, []);

  useEffect(() => {
    refetch();
  }, [substitution]);

  const columns = React.useMemo<ColumnDef<any>[]>(
    () => [
      {
        header: () => (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.CreatedAt)}
            sortDirection={
              sortBy === FieldName.CreatedAt ? sortDirection : null
            }
          >
            Data otrzymania
          </SortingTableHeader>
        ),
        id: "createdAt",
        meta: { text: "Data otrzymania" },
        cell: ({ row }: { row: Row<Assignment> }) => {
          return (
            <FormattedValue dataType="datetime-relative">
              {row?.original?.createdAt}
            </FormattedValue>
          );
        },
      },
      {
        id: "type",
        header: "Działanie",
        accessorKey: "type",
        cell: ({ row }: { row: Row<Assignment> }) => {
          return row.original.type === AssignmentType.Share
            ? "Udostępnienie"
            : row.original.type === AssignmentType.Initial
            ? "Utworzenie"
            : row.original.type === AssignmentType.Flow
            ? "Obieg zdefiniowany"
            : `Przekazanie${
                row.original.isAssessmentRequested ? " do akceptacji" : ""
              }`;
        },
      },
      {
        header: "Od",
        id: "assignerId",
        meta: { text: "Od" },
        cell: ({ row }: { row: Row<Assignment> }) => (
          <FormattedValue
            dataType={"model:users"}
            data={row.original.assigner?.id}
          >
            {`${row.original.assigner?.firstName} ${row.original.assigner?.lastName}`}
          </FormattedValue>
        ),
      },
      {
        id: "__typeName",
        header: "Typ",
        accessorKey: "__typeName",
        cell: ({ row }: { row: Row<Assignment> }) => {
          return (
            <FormattedValue
              loadingIfUndefined={
                !!(
                  (row.original.primaryAssignable as Document)?.documentType &&
                  (row.original.primaryAssignable as Document)?.documentType !==
                    "undefined"
                )
              }
            >
              {row.original.primaryAssignable.__typename === "Case"
                ? "Sprawa"
                : (row.original.primaryAssignable as Document)?.documentType &&
                  (row.original.primaryAssignable as Document)?.documentType
                ? findValue(
                    (row.original.primaryAssignable as Document)
                      ?.documentType as string
                  )
                : null}
            </FormattedValue>
          );
        },
      },
      {
        header: "Numer w składzie chronologicznym",
        id: "sequenceNumber",
        cell: ({ row }: { row: Row<Assignment> }) => {
          return (
            <FormattedValue>
              {(row?.original?.primaryAssignable as Document)?.sequenceNumber}
            </FormattedValue>
          );
        },
      },
      {
        id: "caseId",
        header: "Znak sprawy",
        accessorKey: "case.number",
        disableGlobalFilter: false,
        cell: ({ row }: { row: Row<Assignment> }) => {
          const typeName = row?.original?.primaryAssignable?.__typename;

          const accessor: Case | undefined =
            typeName === "Case"
              ? (row.original.primaryAssignable as Case)
              : (row.original.primaryAssignable as Document).case;

          return accessor ? (
            <StyledLink
              onClick={(event: MouseEvent) => {
                event.preventDefault();
                history.push(
                  `/cases/${accessor?.id}`,
                  typeName === "Case" && {
                    assignmentId: row.original.id,
                  }
                );
              }}
              href={`/cases/${accessor?.id}`}
              $style={{
                color: `${
                  accessor?.isConductedElectronically
                    ? theme.colors.positive
                    : theme.colors.negative
                } !important`,
              }}
            >
              <div
                className={css({
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  gap: "5px",
                })}
              >
                <span
                  className={css({
                    whiteSpace: "nowrap",
                  })}
                >
                  {accessor?.number}
                </span>

                <Tooltip
                  content={
                    accessor?.isConductedElectronically
                      ? "Sprawa prowadzona elektronicznie"
                      : "Sprawa prowadzona tradycyjnie"
                  }
                >
                  <span>
                    {accessor?.isConductedElectronically ? (
                      <Cloud
                        size={14}
                        className={css({
                          verticalAlign: "middle",
                          display: "inline",
                          color: theme.colors.positive,
                        })}
                      />
                    ) : (
                      <Paperclip
                        size={14}
                        className={css({
                          verticalAlign: "middle",
                          display: "inline",
                          color: theme.colors.negative,
                        })}
                      />
                    )}
                  </span>
                </Tooltip>
              </div>
            </StyledLink>
          ) : (
            <FormattedValue />
          );
        },
      },
      {
        header: "Numer",
        accessorKey: "internalNumber",
        disableGlobalFilter: false,
        cell: ({ row }: { row: Row<Assignment> }) => {
          const typeName = row?.original?.primaryAssignable?.__typename;

          const documentKind = (row?.original
            ?.primaryAssignable as Document).documentKind?.toLowerCase();
          const documentTypename = (row?.original
            ?.primaryAssignable as Document).type;

          return typeName === "Case" ? (
            <FormattedValue
              textWhenEmpty={typeName === "Case" ? "N/D" : undefined}
            />
          ) : (
            <StyledLink
              {...((row?.original?.primaryAssignable as Document)
                .documentType &&
                (checkDocumentPermissionToShow(
                  (row?.original?.primaryAssignable as Document)
                    .documentType as string
                ) ||
                  checkPermission(PERMISSIONS.document.readAll)) && {
                  onClick: (event: MouseEvent) => {
                    event.preventDefault();
                    history.push(
                      `/documents/${documentKind}/${documentTypename}/${row.original.primaryAssignable.id}`,
                      {
                        assignmentId: row.original.id,
                      }
                    );
                  },
                  href: `/documents/${documentKind}/${documentTypename}/${row.original.primaryAssignable.id}`,
                })}
            >
              <div
                className={css({
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  gap: "5px",
                })}
              >
                <span className={css({ whiteSpace: "nowrap" })}>
                  {
                    (row?.original?.primaryAssignable as Document)
                      .internalNumber
                  }
                </span>

                <Tooltip
                  {...((row?.original?.primaryAssignable as Document)
                    .documentKind === DOCUMENT_KINDS.Incoming && {
                    content: "Przychodzący",
                  })}
                  {...((row?.original?.primaryAssignable as Document)
                    .documentKind === DOCUMENT_KINDS.Outgoing && {
                    content: "Wychodzący",
                  })}
                  {...((row?.original?.primaryAssignable as Document)
                    .documentKind === DOCUMENT_KINDS.Internal && {
                    content: "Wewnętrzny",
                  })}
                  placement="bottomLeft"
                >
                  <span>
                    {(row?.original?.primaryAssignable as Document)
                      .documentKind === DOCUMENT_KINDS.Incoming && (
                      <FileImport size={18} />
                    )}

                    {(row?.original?.primaryAssignable as Document)
                      .documentKind === DOCUMENT_KINDS.Outgoing && (
                      <FileExport size={18} />
                    )}

                    {(row?.original?.primaryAssignable as Document)
                      .documentKind === DOCUMENT_KINDS.Internal && (
                      <FileSymlink size={18} />
                    )}
                  </span>
                </Tooltip>
              </div>
            </StyledLink>
          );
        },
      },
      {
        id: "assessmentResult",
        header: "Rezultat",
        accessorKey: "assessmentResult",
        cell: ({ row }: { row: Row<Assignment> }) => (
          <FormattedValue
            dataType="assignment-assessment-result"
            textWhenEmpty="N/D"
          >
            {row?.original?.assessmentResult}
          </FormattedValue>
        ),
      },
      {
        header: "Komentarz",
        id: "assignerComment",
        cell: ({ row }: { row: Row<Assignment> }) => {
          return (
            <FormattedValue
              $style={{
                whiteSpace: "pre-wrap",
                width: "max-content",
                display: "flex",
              }}
            >
              {row?.original?.assignerComment}
            </FormattedValue>
          );
        },
      },
      {
        id: "name",
        header: "Tytuł",
        accessorKey: "name",
        disableFilters: true,
        cell: ({ row }: { row: Row<Assignment> }) => (
          <FormattedValue>
            {row?.original?.primaryAssignable?.name}
          </FormattedValue>
        ),
      },
      {
        id: "projectNumber",
        header: "Numer projektu",
        accessorKey: "projectNumber",
        disableFilters: true,
        cell: ({ row }: { row: Row<Assignment> }) => (
          <FormattedValue
            {...(row?.original?.primaryAssignable?.__typename ===
              "Document" && { textWhenEmpty: "N/D" })}
          >
            {(row?.original?.primaryAssignable as Case).projectNumber}
          </FormattedValue>
        ),
      },
      {
        id: "documentNumber",
        header: "Numer dokumentu",
        accessorKey: "documentNumber",
        disableFilters: true,
        cell: ({ row }: { row: Row<Assignment> }) => (
          <FormattedValue
            {...(row?.original?.primaryAssignable?.__typename === "Case" && {
              textWhenEmpty: "N/D",
            })}
          >
            {(row?.original?.primaryAssignable as Document).documentNumber}
          </FormattedValue>
        ),
      },
      {
        id: "additionalCode",
        header: "Kod E+/ACC/QL/Inne",
        accessorKey: "additionalCode",
        disableFilters: true,
        cell: ({ row }: { row: Row<Assignment> }) => (
          <FormattedValue
            {...(row?.original?.primaryAssignable?.__typename === "Case" && {
              textWhenEmpty: "N/D",
            })}
          >
            {(row?.original?.primaryAssignable as Document).additionalCode}
          </FormattedValue>
        ),
      },
      {
        id: "actions",
        cell: ({ row }: { row: Row<Assignment> }) => {
          const typeName = row?.original?.primaryAssignable?.__typename;

          const documentKind = (row?.original
            ?.primaryAssignable as Document)?.documentKind?.toLowerCase();
          const documentTypename = (row?.original
            ?.primaryAssignable as Document)?.type;

          return (
            <div
              className={css({
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              })}
            >
              {folder === "in-progress" &&
                row.original.type === AssignmentType.Share && (
                  <Tooltip content="Zakończ">
                    <ForwardedButton
                      size="mini"
                      startEnhancer={<Check size={14} />}
                      onClick={() => setAssignment(row.original)}
                    />
                  </Tooltip>
                )}

              <Button
                kind={KIND.secondary}
                size={SIZE.mini}
                $style={{ marginLeft: "6px" }}
                disabled={
                  typeName === "Document" &&
                  !!(row?.original?.primaryAssignable as Document)
                    ?.documentType &&
                  !checkDocumentPermissionToShow(
                    (row?.original?.primaryAssignable as Document)
                      ?.documentType as string
                  ) &&
                  !checkPermission(PERMISSIONS.document.readAll)
                }
                onClick={() =>
                  typeName === "Document"
                    ? history.push(
                        `/documents/${documentKind}/${documentTypename}/${row.original.primaryAssignable.id}`,
                        {
                          assignmentId: row.original.id,
                        }
                      )
                    : history.push(
                        `/cases/${row.original.primaryAssignable.id}`,
                        {
                          assignmentId: row.original.id,
                        }
                      )
                }
                startEnhancer={<Eye size={14} />}
              />
            </div>
          );
        },
      },
    ],
    [data, sortBy, sortDirection, findValue, folder, animate, filters]
  );

  useEffect(() => {
    const observer = client.subscribe<{ assignmentCreated: Assignment }>({
      query: ASSIGNMENT_CREATED,
    });

    const subscription = observer.subscribe(() => refetch());

    const observerDispatchCancel = client.subscribe<{
      assignmentDispatchCancelled: Assignment;
    }>({
      query: ASSIGNMENT_DISPATCH_CANCELLED,
    });

    const subscriptionDispatchCancel = observerDispatchCancel.subscribe(() => {
      refetch();
    });

    return () => {
      subscription.unsubscribe();
      subscriptionDispatchCancel.unsubscribe();
    };
  }, []);

  const handleSorting = (column: FieldName) => {
    setSortingParams(history, search, column, sortDirection);

    setSortBy(column);
    setSortDirection(
      sortDirection === null
        ? SortDirection.DESC
        : sortDirection === SortDirection.ASC
        ? SortDirection.DESC
        : SortDirection.ASC
    );
  };

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    setTimeout(() => refetch(), 200);
    setIsPartialFetching(true);
  }, [currentPage, pageSize]);

  useEffect(() => {
    setTimeout(() => refetch(), 200);
    setIsPartialFetching(true);
  }, [sortBy, sortDirection]);

  useEffect(() => {
    setAnimate(true);

    if (data?.receivedAssignments || data?.sentAssignments) {
      setTimeout(() => refetch(), 200);
      setIsPartialFetching(true);
    } else setIsFetching(true);
  }, [folder, location.key]);

  useEffect(() => {
    if (data?.receivedAssignments || data?.sentAssignments) {
      setIsFetching(false);
      setIsPartialFetching(false);
      setTimeout(() => setAnimate(false), 1000);
    }
    if ((data?.receivedAssignments || data?.sentAssignments)?.totalCount >= 0)
      setTotalCount(
        (data?.receivedAssignments || data?.sentAssignments)?.totalCount
      );
  }, [data, networkStatus]);

  return (
    <article>
      <Header
        title={`${FOLDERS_NAME.get(folder)}`}
        recordsNum={
          (data?.receivedAssignments || data?.sentAssignments)?.totalCount
        }
        labels={["Lista"]}
      />

      <Filters
        filters={FOLDERS_FILTERS_RECEIVED}
        state={filters}
        setState={setFilters}
      />

      <Content filtersOffset>
        <Grid>
          <Cell span={12} $style={{ position: "relative" }}>
            <MemoizedStatefulTableV8
              data={(data?.receivedAssignments || data?.sentAssignments)?.nodes}
              columns={columns as ColumnDef<any>[]}
              isLoading={isFetching || isPartialFetching || loading}
              stickLastColumn
              {...((folder === "new" ||
                folder === "in-progress" ||
                folder === "in-flow") &&
                !filters?.length && {
                  emptyMessage: (
                    <Block
                      display={"flex"}
                      flexDirection="column"
                      alignItems={"center"}
                      justifyContent="center"
                      marginTop={"scale1000"}
                      marginBottom={"scale1000"}
                    >
                      <CircleCheck
                        size={160}
                        color="gray"
                        strokeWidth={0.75}
                        {...(animate && {
                          className: css({
                            animationDuration: "1000ms",
                            strokeDashoffset: "1500",
                            strokeDasharray: "1500",
                            animationFillMode: "both",
                            animationTimingFunction:
                              "cubic-bezier(.15,.81,.73,.51)",
                            animationIterationCount: "1",
                            animationName: {
                              "0%": {
                                strokeDashoffset: "1500",
                                stroke: "gray",
                              },
                              "60%": {
                                stroke: "gray",
                              },
                              "100%": {
                                strokeDashoffset: "1440",
                                stroke: "gray",
                              },
                            } as any,
                          }),
                        })}
                      />

                      <HeadingSmall
                        color={"gray"}
                        marginTop="scale200"
                        marginBottom={"scale0"}
                      >
                        Nie masz zadań w tym folderze
                      </HeadingSmall>
                    </Block>
                  ),
                })}
            />
          </Cell>

          <Cell span={12}>
            <BottomPanel>
              <PagingControls />
            </BottomPanel>
          </Cell>
        </Grid>
      </Content>

      <AssignmentFinishModal
        assignmentId={assignment?.id}
        label={`${
          assignment?.primaryAssignable?.__typename === "Case"
            ? "Sprawy"
            : "Dokumentu"
        } o numerze ${
          assignment?.primaryAssignable?.__typename === "Case"
            ? (assignment?.primaryAssignable as Case)?.number
            : (assignment?.primaryAssignable as Document)?.internalNumber
        }`}
        isOpen={!!assignment}
        close={() => setAssignment(undefined)}
        onCompleted={() => refetch()}
      />
    </article>
  );
}
