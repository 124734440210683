import { FIELDS, FieldsGroup } from "../../fields.d";

export type ShipFormInputs = {
  isElectronical?: string;
  shippingProvider?: string;
  recipient?: { id: number; label?: string; typename?: string }[];
  addressName?: string;
  addressAddress?: string;
  addressStreetName?: string;
  addressBuildingNumber?: string;
  addressApartmentNumber?: string;
  addressCity?: string;
  addressPostalCode?: string;
  addressPhoneNumber?: string;
  addressMobileNumber?: string;
  addressEmail?: string;
  addressNip?: string;
  addressOverwrite?: boolean;
  addressIsSensitive?: boolean;
  addressCountryCode: { id: number | string; label?: string }[];
  parcelSizes?: { id: string; label?: string }[];
  width: number | string;
  height: number | string;
  length: number | string;
  weight: number | string;
  isCountryService: boolean;
  countryCode?: string;
  countryCodes?: { id: string; label?: string }[];
  shipmentRates?: { id: number; label?: string }[];
  shipmentRateId?: number;
};

export const DOCUMENTS_SHIP_FIELDS: FieldsGroup[] = [
  {
    id: "basic",
    label: "Podstawowe informacje",
    fields: [
      {
        id: "isElectronical",
        label: "Typ przesyłki",
        type: FIELDS.RadioCheckbox,
        dataType: "electronical-boolean",
        span: 6,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "shippingProvider",
        label: "Sposób wysyłki",
        type: FIELDS.RadioCheckbox,
        dataType: "boolean",
        span: 6,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
    ],
  },
  {
    id: "recipient",
    label: "Adresat",
    fields: [
      {
        id: "recipient",
        label: "Wybór z bazy adresowej",
        type: FIELDS.AddressesSelect,
        span: 8,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
        placeholder: "Wpisz lub wybierz",
      },
      {
        id: "addressNip",
        label: "NIP",
        type: FIELDS.Input,
        span: 4,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
        mask: "9999999999",
        placeholder: "9999999999",
        dataType: "nip",
      },
      {
        id: "addressName",
        label: "Nazwa nadawcy",
        type: FIELDS.Input,
        span: 6,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: true },
      },
      {
        id: "addressName",
        label: "Nazwa nadawcy",
        type: FIELDS.Input,
        span: 9,
        show: { accessor: ["sender", "name"], visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "addressNip",
        label: "NIP",
        type: FIELDS.Input,
        span: 3,
        show: { accessor: ["sender", "nip"], visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "nip",
      },
      {
        id: "addressIsSensitive",
        label: "Dane osobowe",
        type: FIELDS.Checkbox,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "addressCountryCode",
        label: "Kraj",
        type: FIELDS.DictionaryValuesSelect,
        span: 3,
        info: "notElectronicalRequired",
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
        dataType: "countryCode",
      },
      {
        id: "addressStreetName",
        label: "Ulica",
        type: FIELDS.Input,
        span: 3,
        info: "notElectronicalRequired",
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "addressAddress",
        label: "Ulica i numer",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "addressBuildingNumber",
        label: "Numer budynku",
        type: FIELDS.Input,
        span: 2,
        info: "notElectronicalRequired",
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "addressApartmentNumber",
        label: "Numer lokalu",
        type: FIELDS.Input,
        span: 2,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "addressPostalCode",
        label: "Kod pocztowy",
        type: FIELDS.Input,
        span: 2,
        info: "notElectronicalRequired",
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "addressPostalCode",
        label: "Kod pocztowy",
        type: FIELDS.Input,
        span: 3,
        show: { accessor: ["sender", "postalCode"], visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "addressCity",
        label: "Miejscowość",
        type: FIELDS.Input,
        span: 3,
        info: "notElectronicalRequired",
        show: { accessor: ["sender", "city"], visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "addressCountryCode",
        label: "Kraj",
        type: FIELDS.DictionaryValuesSelect,
        span: 3,
        info: "notElectronicalRequired",
        show: { accessor: ["sender", "countryCode"], visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "countryCode",
      },
      {
        id: "addressPhoneNumber",
        label: "Numer telefonu",
        type: FIELDS.Input,
        span: 3,
        show: { accessor: ["sender", "phoneNumber"], visible: true },
        create: { visible: true },
        edit: { visible: true },
        mask: "99 999-99-99",
        placeholder: "99 999-99-99",
      },
      {
        id: "addressMobileNumber",
        label: "Numer telefonu kom.",
        type: FIELDS.Input,
        span: 3,
        show: { accessor: ["sender", "mobileNumber"], visible: true },
        create: { visible: true },
        edit: { visible: true },
        mask: "+99 999-999-999",
        placeholder: "+99 999-999-999",
      },
      {
        id: "addressEmail",
        label: "Adres e-mail",
        type: FIELDS.Input,
        span: 3,
        info: "electronicalRequired",
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
        dataType: "email",
      },
      {
        id: "addressEmail",
        label: "Adres e-mail",
        type: FIELDS.Input,
        span: 6,
        show: { accessor: ["sender", "email"], visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "email",
      },
      {
        id: "addressOverwrite",
        label: "Nadpisz dane adresowe",
        type: FIELDS.Checkbox,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
    ],
  },
  {
    id: "shipmentDetails",
    label: "Dane wysyłkowe",
    fields: [
      {
        id: "parcelSizes",
        label: "Rozmiar",
        type: FIELDS.ParcelSizesSelect,
        placeholder: "Wybierz",
        span: 4,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "width",
        label: "Szerokość",
        type: FIELDS.Input,
        dataType: "dimensions",
        span: 2,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
        endEnhancer: "mm",
      },
      {
        id: "height",
        label: "Wysokość",
        type: FIELDS.Input,
        dataType: "dimensions",
        span: 2,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
        endEnhancer: "mm",
      },
      {
        id: "length",
        label: "Długość",
        type: FIELDS.Input,
        dataType: "dimensions",
        span: 2,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
        endEnhancer: "mm",
      },
      {
        id: "weight",
        label: "Waga",
        type: FIELDS.Input,
        dataType: "weight",
        span: 2,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
        endEnhancer: "g",
      },
      {
        id: "shipmentContract",
        label: "Cennik",
        type: FIELDS.Input,
        dataType: "model:shipment-contracts",
        span: 6,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "shipmentRate",
        label: "Stawka",
        type: FIELDS.Input,
        dataType: "model:shipment-contracts",
        span: 3,
        show: { visible: true, accessor: ["shipmentRate", "name"] },
        create: { visible: false },
        edit: { visible: false },
        endEnhancer: "PLN",
      },
      {
        id: "shipmentRates",
        label: "Stawka",
        type: FIELDS.ShipmentRatesSelect,
        span: 6,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
        caption:
          "Domyślnie ustawiana jest najtańsza stawka pasująca do wprowadzonych parametrów. Aby zmienić, rozwiń listę.",
      },
      {
        id: "price",
        label: "Cena",
        type: FIELDS.Input,
        dataType: "price",
        span: 3,
        show: { visible: true, accessor: ["shipmentRate", "price"] },
        create: { visible: false },
        edit: { visible: false },
      },
    ],
  },
];
