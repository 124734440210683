import { ApolloError, useMutation } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { StyledLink } from "baseui/link";
import { StatefulPopover } from "baseui/popover";
import { LabelSmall } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { MouseEvent, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Row } from "react-table";
import { Eye, Unlink } from "tabler-icons-react";

import { DocumentLink } from "../containers/Documents/documents";
import { DOCUMENTS_UNLINK } from "../containers/Documents/documents.gql";
import { useDictionaries } from "../contexts/dictionaries-context";
import { useLoading } from "../contexts/loading-context";
import { checkDocumentPermissionToShow } from "../utils/check-document-permission";
import { checkPermission } from "../utils/check-permission";
import { PERMISSIONS } from "../utils/permissions";
import Button, { ForwardedButton } from "./button";
import FormattedValue from "./formatted-value";
import Table from "./table";

type AssignedDocumentsTableProps = {
  documentId: number;
  assignmentId: number;
  data: DocumentLink[];
  onCompleted: () => void;
};

export default function AssignedDocumentsTable({
  documentId,
  assignmentId,
  data,
  onCompleted,
}: AssignedDocumentsTableProps): React.ReactElement {
  const [css, theme] = useStyletron();
  const history = useHistory();
  const { findValue } = useDictionaries();
  const { enqueueSnackbar } = useSnackbar();
  const { setIsLoading } = useLoading();

  const [unlink] = useMutation(DOCUMENTS_UNLINK);

  const submitUnlink = async ({ id }: { id: number }) => {
    setIsLoading(true);

    try {
      await unlink({
        variables: {
          documentLinkDeleteInput: {
            id,
          },
        },
      });

      enqueueSnackbar({
        message: "Usunięto powiązanie",
        variant: "success",
      });

      onCompleted && onCompleted();
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Numer",
        accessor: "internalNumber",
        Cell: ({ row }: { row: Row<DocumentLink> }) => {
          const accessor =
            documentId === row.original.linkerId
              ? row.original.linked
              : row.original.linker;

          const documentKind = accessor?.documentKind?.toLowerCase();
          const documentTypename = accessor?.type;

          return (
            <StyledLink
              {...(((accessor?.documentType &&
                checkDocumentPermissionToShow(accessor?.documentType)) ||
                checkPermission(PERMISSIONS.document.readAll)) && {
                onClick: (event: MouseEvent) => {
                  event.preventDefault();
                  history.push(
                    `/documents/${documentKind}/${documentTypename}/${accessor.id}`,
                    {
                      assignmentId,
                    }
                  );
                },
                href: `/documents/${documentKind}/${documentTypename}/${accessor.id}`,
              })}
            >
              {accessor?.internalNumber}
            </StyledLink>
          );
        },
      },
      {
        Header: "Rodzaj dokumentu",
        id: "documentKind",
        Cell: ({ row }: { row: Row<DocumentLink> }) => {
          const accessor =
            documentId === row.original.linkerId
              ? row.original.linked
              : row.original.linker;

          return (
            <FormattedValue dataType="document-kind">
              {accessor?.documentKind}
            </FormattedValue>
          );
        },
      },
      {
        Header: "Typ dokumentu",
        id: "documentType",
        Cell: ({ row }: { row: Row<DocumentLink> }) => {
          const accessor =
            documentId === row.original.linkerId
              ? row.original.linked
              : row.original.linker;

          return (
            <FormattedValue
              loadingIfUndefined={
                !!(
                  accessor?.documentType &&
                  accessor?.documentType !== "undefined"
                )
              }
            >
              {accessor?.documentType && accessor?.documentType !== "undefined"
                ? findValue(accessor?.documentType)
                : null}
            </FormattedValue>
          );
        },
      },
      {
        Header: (
          <Block display="flex" justifyContent="flex-end">
            Data rejestracji
          </Block>
        ),
        accessor: "createdAt",
        Cell: ({ row }: { row: Row<DocumentLink> }) => {
          const accessor =
            documentId === row.original.linkerId
              ? row.original.linked
              : row.original.linker;
          return (
            <Block display="flex" justifyContent="flex-end">
              <FormattedValue dataType="datetime">
                {accessor?.createdAt}
              </FormattedValue>
            </Block>
          );
        },
      },
      {
        id: "actions",
        Cell: ({ row }: { row: Row<DocumentLink> }) => {
          const accessor =
            documentId === row.original.linkerId
              ? row.original.linked
              : row.original.linker;

          const documentKind = accessor?.documentKind?.toLowerCase();
          const documentTypename = accessor?.type;
          return (
            <div
              className={css({
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              })}
            >
              {row.original.type === null && (
                <StatefulPopover
                  overrides={{
                    Inner: {
                      style: {
                        borderTopLeftRadius: theme.borders.radius200,
                        borderTopRightRadius: theme.borders.radius200,
                        borderBottomRightRadius: theme.borders.radius200,
                        borderBottomLeftRadius: theme.borders.radius200,
                        paddingTop: theme.sizing.scale200,
                        paddingBottom: theme.sizing.scale200,
                        paddingRight: theme.sizing.scale300,
                        paddingLeft: theme.sizing.scale300,
                        backgroundColor: "white",
                      },
                    },
                    Body: {
                      style: {
                        borderTopLeftRadius: theme.borders.radius200,
                        borderTopRightRadius: theme.borders.radius200,
                        borderBottomRightRadius: theme.borders.radius200,
                        borderBottomLeftRadius: theme.borders.radius200,
                        marginRight: "10px",
                        zIndex: 22,
                      },
                    },
                    Arrow: {
                      style: {
                        backgroundColor: "white",
                      },
                    },
                  }}
                  content={({ close }) => (
                    <Block
                      padding="scale200"
                      backgroundColor="white"
                      display="flex"
                      alignItems="center"
                    >
                      <LabelSmall marginRight="8px">
                        Czy chcesz usunąć to powiązanie?
                      </LabelSmall>
                      <Button
                        kind="secondary"
                        size={"mini"}
                        onClick={() =>
                          submitUnlink({
                            id: row.original.id,
                          })
                        }
                        $style={{
                          backgroundColor: theme.colors.negative400,
                          color: "white",
                          ":hover": {
                            backgroundColor: theme.colors.negative500,
                          },
                        }}
                      >
                        Usuń
                      </Button>
                      <Button
                        kind="secondary"
                        size={"mini"}
                        onClick={() => close()}
                        $style={{
                          marginLeft: "5px",
                        }}
                      >
                        Anuluj
                      </Button>
                    </Block>
                  )}
                  showArrow
                >
                  <ForwardedButton
                    type="reset"
                    kind="primary"
                    size={"mini"}
                    startEnhancer={<Unlink size={14} />}
                  />
                </StatefulPopover>
              )}

              <Button
                kind={KIND.secondary}
                size={SIZE.mini}
                $style={{ marginLeft: "6px" }}
                disabled={
                  !!accessor?.documentType &&
                  !checkDocumentPermissionToShow(accessor?.documentType) &&
                  !checkPermission(PERMISSIONS.document.readAll)
                }
                onClick={() =>
                  history.push(
                    `/documents/${documentKind}/${documentTypename}/${accessor.id}`,
                    {
                      assignmentId,
                    }
                  )
                }
                startEnhancer={<Eye size={14} />}
              />
            </div>
          );
        },
      },
    ],
    [data, documentId]
  );

  return (
    <Table<DocumentLink>
      id="documentsTable"
      compact
      columns={columns}
      data={data}
      virtualized
    />
  );
}
