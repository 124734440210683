import { BasicFilter, FILTERS } from "./../../filters.d";

export const PAYMENTS_FILTERS: BasicFilter[] = [
  {
    id: "organizationalUnit",
    label: "Jednostka organizacyjna",
    type: FILTERS.OrganizationalUnit,
  },
  {
    id: "internalNumber",
    label: "Numer",
    type: FILTERS.Text,
  },
];
