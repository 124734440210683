import { useStyletron } from "baseui";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ROLE,
  SIZE,
} from "baseui/modal";
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Mail, X } from "tabler-icons-react";

import Button from "../components/button";
import { SUPPORT_MAIL } from "../constants";

type SupportContextProps = {
  openSupportModal: (text?: string) => void;
};

export const SupportContext = createContext<SupportContextProps>(
  {} as SupportContextProps
);

export function SupportProvider({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement {
  const [css, theme] = useStyletron();
  const [isOpen, setIsOpen] = useState(false);
  const [modalText, setModalText] = useState<string | null>();

  const modalRef = useRef<Modal>(null);

  function openSupportModal(text?: string) {
    if (text) setModalText(text);
    setIsOpen(true);
  }

  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.animateOutComplete = () => setModalText(null);
    }
  }, [modalText]);

  return (
    <SupportContext.Provider
      value={{
        openSupportModal,
      }}
    >
      <Modal
        ref={modalRef}
        onClose={() => setIsOpen(false)}
        closeable
        isOpen={isOpen}
        animate
        autoFocus
        overrides={{
          Root: {
            style: {
              zIndex: 14,
            },
          },
          Dialog: {
            style: {
              borderTopLeftRadius: theme.borders.radius200,
              borderTopRightRadius: theme.borders.radius200,
              borderBottomRightRadius: theme.borders.radius200,
              borderBottomLeftRadius: theme.borders.radius200,
              paddingTop: theme.sizing.scale300,
              paddingBottom: theme.sizing.scale600,
              paddingRight: theme.sizing.scale300,
              paddingLeft: theme.sizing.scale300,
            },
          },
          Close: {
            component: () => (
              <span
                className={css({
                  position: "absolute",
                  right: theme.sizing.scale600,
                  top: theme.sizing.scale600,
                  cursor: "pointer",
                  color: "#444",
                  ":hover": {
                    color: "black",
                  },
                })}
                onClick={() => setIsOpen(false)}
              >
                <X size={20} />
              </span>
            ),
          },
        }}
        size={SIZE.default}
        role={ROLE.dialog}
      >
        <ModalHeader>Skontaktuj się z administratorem</ModalHeader>
        <ModalBody>
          <p>
            {modalText?.length
              ? modalText
              : "Ta funkcja jest niedostępna. Aby z niej skorzystać, skontaktuj się z administratorem przez system magazyn."}
          </p>
        </ModalBody>
        <ModalFooter>
          <Button
            $as="a"
            target="_blank"
            href={`mailto:${
              process.env.REACT_APP_SYSTEM_VERSION === "TEST"
                ? SUPPORT_MAIL
                : process.env.REACT_APP_ADMIN_MAIL
            }`}
            startEnhancer={<Mail size={20} />}
          >
            Skontaktuj się
          </Button>
        </ModalFooter>
      </Modal>

      {children}
    </SupportContext.Provider>
  );
}

export const useSupport = (): SupportContextProps => useContext(SupportContext);
