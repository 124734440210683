import { BasicFilter, FILTERS } from "./../../filters.d";

export const ORGANIZATIONAL_UNITS_FILTERS: BasicFilter[] = [
  {
    id: "name",
    label: "Nazwa",
    type: FILTERS.Text,
  },
  {
    id: "symbol",
    label: "Symbol jednostki",
    type: FILTERS.Text,
  },
  {
    id: "budgetSymbol",
    label: "Symbol budżetowy",
    type: FILTERS.Text,
  },
];
