import { FIELDS, FieldsGroup } from "../../fields.d";

export type FormInputs = {
  id: number;
  label: string;
  isCommentRequired: boolean;
  isSignatureFileRequired: boolean;
  resultingDocumentState: string;
  resultingDocumentStateSelect: { id: number; label?: string }[];
  requiredDocumentFields: string[];
  startDocumentFlowStepId: number;
  endDocumentFlowStepId: number;
  startDocumentFlowStepIdSelect?: { id: number; label?: string }[];
  endDocumentFlowStepIdSelect?: { id: number; label?: string }[];
  style: string;
  styleSelect: { id: string; label?: string }[];
  notificationContent: string;
  documentFlowId: number;
  noPaymentAssignedToInvoice?: boolean;
  warnings?: string[];
  synchronizeDocumentWithRejestr?: boolean;
  isEndingDocumentFlow?: boolean;
  sendDocumentToPlatnosci?: boolean;
};

export const FLOW_ACTIONS_FIELDS: FieldsGroup[] = [
  {
    id: "basic",
    label: "Podstawowe informacje",
    fields: [
      {
        id: "label",
        label: "Nazwa",
        type: FIELDS.Input,
        span: 4,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "styleSelect",
        label: "Styl akcji",
        type: FIELDS.ButtonStylesSelect,
        dataType: "button-style",
        span: 4,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "style",
        label: "Styl akcji",
        type: FIELDS.ButtonStylesSelect,
        dataType: "button-style",
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "notificationContent",
        label: "Treść w powiadomieniu",
        type: FIELDS.Input,
        startEnhancer: "został",
        placeholder: "przeniesiony",
        dataType: "notification-content",
        span: 4,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "documentFlow",
        label: "Nazwa procesu",
        type: FIELDS.Input,
        dataType: "model:flow",
        span: 4,
        show: { visible: true, accessor: ["documentFlow", "name"] },
        create: { visible: false },
        edit: { visible: false },
      },
    ],
  },
  {
    id: "requirements",
    label: "Wymagania",
    fields: [
      {
        id: "isCommentRequired",
        label: "Wymagany komentarz",
        type: FIELDS.Checkbox,
        dataType: "boolean",
        span: 4,
        show: { visible: true },
        edit: { visible: true },
        create: { visible: true },
      },
      {
        id: "isSignatureFileRequired",
        label: "Wymagany podpis",
        type: FIELDS.Checkbox,
        dataType: "boolean",
        span: 4,
        show: { visible: true },
        edit: { visible: true },
        create: { visible: true },
      },
    ],
  },
  {
    id: "parameters",
    label: "Parametry",
    fields: [
      {
        id: "startDocumentFlowStepIdSelect",
        label: "Krok startowy",
        type: FIELDS.StepsSelect,
        span: 4,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "endDocumentFlowStepIdSelect",
        label: "Krok końcowy",
        type: FIELDS.StepsSelect,
        span: 4,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "startDocumentFlowStepId",
        label: "Krok startowy",
        type: FIELDS.StepsSelect,
        dataType: "flow-step",
        span: 4,
        show: { visible: true, accessor: ["startDocumentFlowStep", "name"] },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "endDocumentFlowStepId",
        label: "Krok końcowy",
        type: FIELDS.StepsSelect,
        dataType: "flow-step",
        span: 4,
        show: { visible: true, accessor: ["endDocumentFlowStep", "name"] },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "resultingDocumentStateSelect",
        label: "Końcowy stan dokumentu",
        type: FIELDS.DocumentStatesSelect,
        span: 4,
        show: { visible: false },
        edit: { visible: true },
        create: { visible: true },
      },
      {
        id: "resultingDocumentState",
        label: "Końcowy stan dokumentu",
        type: FIELDS.Input,
        dataType: "action-result",
        span: 4,
        show: { visible: true },
        edit: { visible: false },
        create: { visible: false },
      },
      {
        id: "isEndingDocumentFlow",
        label: "Zakończ obieg",
        type: FIELDS.Checkbox,
        dataType: "boolean",
        span: 12,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
    ],
  },
  {
    id: "warnings",
    label: "Ostrzeżenia",
    fields: [
      {
        id: "noPaymentAssignedToInvoice",
        label: "Brak podłączonych poleceń płatności",
        type: FIELDS.Checkbox,
        dataType: "boolean",
        span: 12,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
    ],
  },
  {
    id: "syncs",
    label: "Synchonizacje",
    fields: [
      {
        id: "synchronizeDocumentWithRejestr",
        label: "Synchronizuj z systemem Rejestr",
        type: FIELDS.Checkbox,
        dataType: "boolean",
        span: 12,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "sendDocumentToPlatnosci",
        label: "Przekaż do systemu Płatności",
        type: FIELDS.Checkbox,
        dataType: "boolean",
        span: 12,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
    ],
  },
];
