import { FIELDS, FieldsGroup } from "../../fields.d";

export type CreateFormInputs = {
  name: string;
  description: string;
  permissionIds: number[];
};

export type EditFormInputs = {
  name: string;
  description: string;
  permissionIds?: number[];
};

export const ROLES_FIELDS: FieldsGroup[] = [
  {
    id: "basic",
    label: "Podstawowe informacje",
    fields: [
      {
        id: "name",
        label: "Nazwa",
        type: FIELDS.Input,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "description",
        label: "Opis",
        span: 6,
        type: FIELDS.Input,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "isSystem",
        label: "Systemowa?",
        type: FIELDS.Input,
        dataType: "access-boolean",
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
    ],
  },
  {
    id: "permissions",
    label: "",
    fields: [
      {
        id: "permissionIds",
        label: "Uprawnienia",
        span: 12,
        type: FIELDS.PermissionsTable,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
    ],
  },
];
