import { useStyletron } from "baseui";
import {
  ACCESSIBILITY_TYPE,
  StatefulTooltip,
  StatefulTooltipProps,
} from "baseui/tooltip";
import React from "react";
import { StyleObject } from "styletron-react";

type Props = {
  $style?: StyleObject;
} & StatefulTooltipProps;

const Tooltip = ({ $style, ...rest }: Props): React.ReactElement => {
  const [, theme] = useStyletron();

  return (
    <StatefulTooltip
      overrides={{
        Inner: {
          style: {
            borderTopLeftRadius: theme.borders.radius200,
            borderTopRightRadius: theme.borders.radius200,
            borderBottomRightRadius: theme.borders.radius200,
            borderBottomLeftRadius: theme.borders.radius200,
            paddingTop: theme.sizing.scale200,
            paddingBottom: theme.sizing.scale200,
            paddingRight: theme.sizing.scale300,
            paddingLeft: theme.sizing.scale300,
            backgroundColor: "white",
            color: "black",
            fontSize: "12px",
            boxShadow: theme.lighting.shadow400,
          },
        },
        Body: {
          style: {
            borderTopLeftRadius: theme.borders.radius200,
            borderTopRightRadius: theme.borders.radius200,
            borderBottomRightRadius: theme.borders.radius200,
            borderBottomLeftRadius: theme.borders.radius200,
            backgroundColor: "unset",
            boxShadow: "unset",
            zIndex: 150,
            ...$style,
          },
        },
        Arrow: {
          style: {
            backgroundColor: "white",
          },
        },
      }}
      autoFocus
      returnFocus
      showArrow={false}
      popoverMargin={5}
      accessibilityType={ACCESSIBILITY_TYPE.tooltip}
      {...rest}
    />
  );
};

export default Tooltip;
