import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { Block } from "baseui/block";
import { KIND } from "baseui/button";
import { StyledLink } from "baseui/link";
import { Skeleton } from "baseui/skeleton";
import { useSnackbar } from "notistack";
import React, { MouseEvent, useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { Row } from "react-table";
import { ArrowBigRight, FileOff } from "tabler-icons-react";

import Cell from "../../../components/cell";
import Checkbox from "../../../components/checkbox";
import ConfirmDialog from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import DocumentPickupModal from "../../../components/document-pickup-modal";
import FormControl from "../../../components/form-control";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import Table from "../../../components/table";
import { DOCUMENT_PICKUP_STATES } from "../../../constants";
import { useDictionaries } from "../../../contexts/dictionaries-context";
import { useLoading } from "../../../contexts/loading-context";
import { checkPermission } from "../../../utils/check-permission";
import { PERMISSIONS } from "../../../utils/permissions";
import { Document } from "../../Documents/documents";
import {
  DOCUMENT_PICKUPS_SHOW,
  DOCUMENT_PICKUPS_START_RETURN,
} from "../document-pickups.gql";

export default function DocumentPickupsReturn(): React.ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { isFetching, setIsFetching, setIsLoading } = useLoading();
  const { incomingTypes, findValue, outgoingTypes } = useDictionaries();
  const [selectedDocuments, setSelectedDocuments] = useState<Array<number>>([]);
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const [isConfirmReturnDialogOpen, setIsConfirmReturnDialogOpen] = useState(
    false
  );
  const { isLoading } = useLoading();
  const { id } = useParams<{ id?: string }>();

  const { refetch, data, error: queryError } = useQuery(DOCUMENT_PICKUPS_SHOW, {
    variables: {
      id: id ? parseInt(id) : null,
    },
  });

  useEffect(() => {
    if (queryError?.graphQLErrors)
      enqueueSnackbar({
        message: (queryError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [queryError]);

  const [startReturn, { error: startReturnError }] = useMutation(
    DOCUMENT_PICKUPS_START_RETURN
  );

  const submit = async () => {
    setIsLoading(true);

    try {
      await startReturn({
        variables: {
          documentPickupStartReturnInput: {
            id: id ? parseInt(id) : null,
          },
        },
      });

      setIsConfirmReturnDialogOpen(true);
    } catch (error: unknown) {
      setIsConfirmReturnDialogOpen(false);

      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (startReturnError?.graphQLErrors)
      enqueueSnackbar({
        message: (startReturnError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [startReturnError]);

  useEffect(() => {
    if (data?.documentPickup) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => data?.documentPickup && setIsFetching(false), [data]);

  if (!checkPermission(PERMISSIONS.documentPickup.manage))
    return <Redirect to="/" />;

  const columns = React.useMemo(
    () => [
      {
        id: "a",
        accessor: "checked",
        Cell: ({ row }: { row: Row<Document> }) => (
          <Block
            width="20px"
            marginLeft="8px"
            display="flex"
            justifyContent="center"
          >
            <Checkbox
              checked={selectedDocuments?.includes(row.original.id)}
              onChange={() => {
                if (selectedDocuments?.includes(row.original.id)) {
                  setSelectedDocuments([
                    ...selectedDocuments?.filter(
                      (id) => id !== row.original.id
                    ),
                  ]);
                } else {
                  setSelectedDocuments([
                    ...(selectedDocuments ? selectedDocuments : []),
                    row.original.id,
                  ]);
                }
              }}
            />
          </Block>
        ),
      },
      {
        Header: "Numer",
        accessor: "internalNumber",
        Cell: ({ row }: { row: Row<Document> }) => {
          const documentKind = row?.original?.documentKind?.toLowerCase();
          const documentTypename = row?.original?.type;
          return (
            <StyledLink
              onClick={(event: MouseEvent) => {
                event.preventDefault();
                history.push({
                  pathname: `/documents/${documentKind}/${documentTypename}/${row.original.id}`,
                  state: {
                    alternativeDocumentsList: true,
                  },
                });
              }}
              href={`/documents/${documentKind}/${documentTypename}/${row.original.id}`}
            >
              {row.original.internalNumber}
            </StyledLink>
          );
        },
      },
      {
        Header: "Jednostka organizacyjna",
        id: "organizationalUnit",
        Cell: ({ row }: { row: Row<Document> }) => (
          <FormattedValue
            dataType="model:organizational-units"
            data={row?.original?.organizationalUnit?.id}
          >
            {row?.original?.organizationalUnit?.name}
          </FormattedValue>
        ),
      },
      {
        Header: "Rodzaj dokumentu",
        id: "documentKind",
        Cell: ({ row }: { row: Row<Document> }) => (
          <FormattedValue dataType="document-kind">
            {row.original.documentKind}
          </FormattedValue>
        ),
      },
      {
        Header: "Typ dokumentu",
        id: "documentType",
        Cell: ({ row }: { row: Row<Document> }) => {
          const documentType = row.original.documentType
            ? findValue(row.original.documentType)
            : "";
          return <FormattedValue>{documentType as string}</FormattedValue>;
        },
      },
      {
        Header: "Data rejestracji",
        accessor: "createdAt",
        Cell: ({ row }: { row: Row<Document> }) => (
          <FormattedValue dataType="date">
            {row.original.createdAt}
          </FormattedValue>
        ),
      },
    ],
    [incomingTypes, outgoingTypes, selectedDocuments]
  );

  return (
    <article>
      <Header
        title="Zwrot dokumentów"
        labels={["Wydania dokumentów", "Zwracanie"]}
        goBackOption
        buttons={[
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () => history.push(`/document-pickups/${id}`),
          },
          {
            label: "Kontynuuj",
            kind: KIND.primary,
            startEnhancer: <ArrowBigRight size={18} />,
            onClick: () =>
              data?.documentPickup?.state ===
              DOCUMENT_PICKUP_STATES.ReturnInProgress
                ? setIsConfirmReturnDialogOpen(true)
                : submit(),
            disabled: isLoading || !selectedDocuments.length,
            isLoading: isLoading,
          },
        ]}
      />
      <Content filtersOffset>
        <Grid>
          <Cell span={12} $style={{ position: "relative" }}>
            {isFetching ? (
              <Skeleton rows={0} height="300px" width="100%" animation />
            ) : (
              data && (
                <FormControl label="Dokumenty do zwrotu">
                  <Table<Document>
                    columns={columns}
                    data={data?.documentPickup?.documents}
                  />
                </FormControl>
              )
            )}
          </Cell>
          <ConfirmDialog
            isOpen={isCancelConfirmDialogOpen}
            label="Anulowanie zwrotu dokumentów"
            close={() => setIsCancelConfirmDialogOpen(false)}
            confirm={() => history.push(`/document-pickups/${id}`)}
          />
          <DocumentPickupModal
            isOpen={isConfirmReturnDialogOpen}
            setIsOpen={setIsConfirmReturnDialogOpen}
            documentIds={selectedDocuments}
            type="confirmReturn"
            user={data?.documentPickup?.user}
            pickupId={id}
          />
        </Grid>
      </Content>
    </article>
  );
}
