import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { KIND } from "baseui/button";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { DeviceFloppy, FileOff } from "tabler-icons-react";

import ConfirmDialog from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import FormRenderer from "../../../components/form-renderer";
import Header from "../../../components/header";
import { useLoading } from "../../../contexts/loading-context";
import { SubstitutionFormInputs } from "../../Substitutions/substitutions.form";
import { MY_SUBSTITUTIONS_FIELDS } from "../my-substitutions.form";
import {
  MY_SUBSTITUTIONS_EDIT,
  MY_SUBSTITUTIONS_SHOW,
} from "../my-substitutions.gql";

export default function MySubstitutionsEdit(): React.ReactElement {
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const [css] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const {
    control,
    formState: { errors, isDirty, ...formState },
    handleSubmit,
    setValue,
    reset,
    ...methods
  } = useForm<SubstitutionFormInputs>();

  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const { data, error: queryError } = useQuery(MY_SUBSTITUTIONS_SHOW, {
    variables: { id: id ? parseInt(id) : null },
    fetchPolicy: "cache-first",
  });
  const [editSubstitution, { error }] = useMutation(MY_SUBSTITUTIONS_EDIT);

  const { setIsFetching, isLoading, setIsLoading } = useLoading();

  const onSubmit = async ({
    substitutee,
    substituter,
    endedAt,
    ...values
  }: SubstitutionFormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      await editSubstitution({
        variables: {
          mySubstitutionUpdateInput: {
            id: parseInt(id),
            substituterId: substituter && substituter[0].id,
            endedAt: endedAt && new Date(endedAt.setHours(23, 59, 59)),
            ...values,
          },
        },
      });

      enqueueSnackbar({
        message: "Zapisano pomyślnie",
        variant: "success",
      });

      history.push(`/my-substitutions/${id}`);
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => setIsFetching(true), []);

  useEffect(() => {
    if (data?.mySubstitution) {
      setValue("startedAt", new Date(data?.mySubstitution?.startedAt));
      data?.mySubstitution?.endedAt &&
        setValue("endedAt", new Date(data?.mySubstitution?.endedAt));
      setValue("substitutee", [
        {
          id: data?.mySubstitution?.substitutee?.id,
          ...data?.mySubstitution?.substitutee,
        },
      ]);
      setValue("substituter", [
        {
          id: data?.mySubstitution?.substituter?.id,
          ...data?.mySubstitution?.substituter,
        },
      ]);
      setIsFetching(false);
    }
  }, [data]);

  useEffect(() => {
    if (queryError?.graphQLErrors)
      enqueueSnackbar({
        message: (queryError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [queryError]);

  return (
    <article>
      <Header
        title={
          <span
            className={css({
              display: "flex",
              alignItems: "center",
              gap: "5px",
            })}
          >
            <span className={css({ whiteSpace: "nowrap" })}>
              {data?.mySubstitution?.substitutee?.firstName[0]}.{" "}
              {data?.mySubstitution?.substitutee?.lastName}
            </span>{" "}
            ←{" "}
            <span className={css({ whiteSpace: "nowrap" })}>
              {data?.mySubstitution?.substituter?.firstName[0]}.{" "}
              {data?.mySubstitution?.substituter?.lastName}
            </span>
          </span>
        }
        labels={["Zastępstwa", "Edytowanie"]}
        goBackOption
        buttons={[
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              isDirty ? setIsCancelConfirmDialogOpen(true) : history.goBack(),
          },
          {
            label: "Zapisz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            onClick: handleSubmit(onSubmit),
            disabled: isLoading,
            isLoading: isLoading,
            type: "submit",
            formId: "editSubstitution",
          },
        ]}
      />
      <Content>
        <FormProvider<SubstitutionFormInputs>
          control={control}
          formState={{ errors, isDirty, ...formState }}
          handleSubmit={handleSubmit}
          reset={reset}
          setValue={setValue}
          {...methods}
        >
          <FormRenderer
            id="editSubstitution"
            onSubmit={handleSubmit(onSubmit)}
            type="edit"
            isLoading={isLoading}
            error={error}
            fields={MY_SUBSTITUTIONS_FIELDS}
          />
        </FormProvider>

        <ConfirmDialog
          isOpen={isCancelConfirmDialogOpen}
          label="Anulowanie edycji zastępstwa"
          close={() => setIsCancelConfirmDialogOpen(false)}
          confirm={() => history.goBack()}
        />
      </Content>
    </article>
  );
}
