import { BasicFilter, FILTERS } from "./../../filters.d";

export const CURRENCIES_FILTERS: BasicFilter[] = [
  {
    id: "code",
    label: "Symbol",
    type: FILTERS.Text,
  },
  {
    id: "name",
    label: "Nazwa",
    type: FILTERS.Text,
  },
  {
    id: "isVisible",
    label: "Widoczność",
    type: FILTERS.Boolean,
  },
];
