import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, Tag } from "baseui/tag";
import React, { useMemo, useState } from "react";
import { Row } from "react-table";
import { MessageDots } from "tabler-icons-react";

import { DocumentStatus } from "../containers/Documents/documents";
import { HistoryFlowStep } from "../containers/Flow/document-flow";
import { User } from "../containers/Users/users";
import CommentModal from "./comment-modal";
import { FlowRecipient } from "./flow-recipient";
import FormattedValue from "./formatted-value";
import Table from "./table";
import Tooltip from "./tooltip";

type HistoryTableProps = {
  documentStatuses?: DocumentStatus[];
  flowStepsHistory: HistoryFlowStep[];
};

export default function HistoryTable({
  flowStepsHistory,
}: HistoryTableProps): React.ReactElement {
  const [css] = useStyletron();

  const [isCommentOpen, setIsCommentOpen] = useState(false);
  const [commentContent, setCommentContent] = useState<{
    user: User;
    comment: string;
    date: string;
  }>();

  const columns = useMemo(
    () => [
      {
        Header: "Lp.",
        id: "ordinalNumber",
        Cell: ({ row }: { row: Row<HistoryFlowStep> }) => (
          <FormattedValue>{row.index + 1}</FormattedValue>
        ),
      },
      {
        Header: "Autor akcji",
        id: "actionUser",
        Cell: ({ row }: { row: Row<HistoryFlowStep> }) =>
          row?.original?.substituter ? (
            <div
              className={css({
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              })}
            >
              <div>
                <FormattedValue
                  key={`subtitutee-${row?.original?.substituter?.id}`}
                  dataType="model:users"
                  data={row?.original?.substituter?.id}
                >
                  {`${row?.original?.substituter?.firstName} ${row?.original?.substituter?.lastName}`}
                </FormattedValue>
                <span className={css({ fontWeight: 400 })}> za</span>
              </div>

              <FormattedValue
                key={`subtituter-${row?.original?.user?.id}`}
                dataType="model:users"
                data={row?.original?.user?.id}
              >
                {`${row?.original?.user?.firstName} ${row?.original?.user?.lastName}`}
              </FormattedValue>
              <span className={css({ fontWeight: 400 })}>(zastępstwo)</span>
            </div>
          ) : (
            <FormattedValue
              dataType="model:users"
              data={row?.original?.user?.id}
            >{`${row?.original?.user?.firstName} ${row?.original?.user?.lastName}`}</FormattedValue>
          ),
      },
      {
        Header: "Stanowisko",
        id: "actionPosition",
        Cell: ({ row }: { row: Row<HistoryFlowStep> }) =>
          row?.original?.substituter ? (
            <FormattedValue>{row?.original?.userPosition}</FormattedValue>
          ) : (
            <FormattedValue>{row?.original?.userPosition}</FormattedValue>
          ),
      },
      {
        Header: "Jednostka organizacyjna",
        Cell: ({ row }: { row: Row<HistoryFlowStep> }) => (
          <FormattedValue dataType="json:organizational-units" inline>
            {row.original.userOrganizationalUnits}
          </FormattedValue>
        ),
      },
      {
        Header: "Wykonana akcja",
        id: "performedAction",
        Cell: ({ row }: { row: Row<HistoryFlowStep> }) => {
          const wrapper = document.querySelector(
            `#action-label-wrapper-${row.original.id}`
          );
          const shouldShowTooltip =
            wrapper && wrapper.clientWidth < wrapper.scrollWidth;
          return row?.original?.actionLabel ? (
            <Tooltip
              {...(shouldShowTooltip &&
                row?.original?.actionLabel && {
                  content: row?.original?.actionLabel,
                })}
              placement="right"
            >
              <span>
                <Tag
                  closeable={false}
                  overrides={{
                    Root: {
                      style: {
                        marginLeft: 0,
                        marginRight: 0,
                        marginTop: 0,
                        marginBottom: 0,
                        whiteSpace: "nowrap",
                      },
                    },
                  }}
                  kind={
                    row?.original?.actionStyle === "positive"
                      ? KIND.positive
                      : row?.original?.actionStyle === "negative"
                      ? KIND.negative
                      : KIND.neutral
                  }
                >
                  <div
                    className={css({
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    })}
                    id={`action-label-wrapper-${row.original.id}`}
                  >
                    {row?.original?.actionLabel}
                  </div>
                </Tag>
              </span>
            </Tooltip>
          ) : (
            <></>
          );
        },
      },
      {
        Header: "Stan obiegu",
        id: "flowStepName",
        Cell: ({ row }: { row: Row<HistoryFlowStep> }) => (
          <>
            <FormattedValue $style={{ fontSize: "12px" }}>
              {row?.original?.isEndingDocumentFlow ? "Zakończony" : "W obiegu"}
            </FormattedValue>
          </>
        ),
      },
      {
        Header: "Komentarz",
        id: "comment",
        Cell: ({ row }: { row: Row<HistoryFlowStep> }) =>
          row.original.endDate ? (
            row.original.comment ? (
              <div
                className={css({
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  ":hover": {
                    cursor: "pointer",
                  },
                })}
                onClick={() => {
                  setCommentContent({
                    user: row?.original.commentAuthor,
                    comment: row?.original?.comment,
                    date: row?.original?.commentTime,
                  });
                  setIsCommentOpen(true);
                }}
              >
                <Tooltip placement="bottomLeft" content="Zobacz komentarz">
                  <span>
                    <MessageDots />
                  </span>
                </Tooltip>
              </div>
            ) : (
              <FormattedValue />
            )
          ) : (
            <></>
          ),
      },
      {
        Header: (
          <Block display="flex" justifyContent="flex-end">
            Data zakończenia
          </Block>
        ),
        id: "endDate",
        Cell: ({ row }: { row: Row<HistoryFlowStep> }) => {
          return (
            <FormattedValue dataType="datetime" textWhenEmpty=" ">
              {row?.original?.endDate}
            </FormattedValue>
          );
        },
      },
      {
        Header: "Przekazano do",
        id: "organizationalUnit",
        Cell: ({ row }: { row: Row<HistoryFlowStep> }) => {
          return <FlowRecipient historyFlowStep={row.original} />;
        },
      },
    ],
    [flowStepsHistory]
  );

  return (
    <>
      <Table<HistoryFlowStep>
        id="historyTable"
        compact
        columns={columns}
        data={flowStepsHistory}
      />

      <CommentModal
        isOpen={isCommentOpen}
        onClose={() => {
          setIsCommentOpen(false);
        }}
        commentContent={commentContent}
      />
    </>
  );
}
