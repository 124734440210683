import { FIELDS, FieldsGroup } from "../../fields.d";

export type DivisorTemplatesFormInputs = {
  name: string;
};

export type DivisorItemsFormInputs = {
  divisorTemplateId?: number;
  name: string;
  internalAccountId?: number;
  internalAccounts?: { id: number; label?: string }[];
  percent: number | string;
};

export const DIVISOR_TEMPLATES_FIELDS: FieldsGroup[] = [
  {
    id: "basic",
    label: "Podstawowe informacje",
    fields: [
      {
        id: "name",
        label: "Nazwa",
        type: FIELDS.Input,
        span: 4,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "createdAt",
        label: "Data utworzenia",
        type: FIELDS.Input,
        dataType: "datetime",
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "updatedAt",
        label: "Data aktualizacji",
        type: FIELDS.Input,
        dataType: "datetime",
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
    ],
  },
];

export const DIVISOR_ITEMS_FIELDS: FieldsGroup[] = [
  {
    id: "basic",
    label: "Podstawowe informacje",
    fields: [
      {
        id: "name",
        label: "Nazwa",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "divisorTemplate",
        label: "Podzielnik",
        type: FIELDS.Input,
        dataType: "model:divisors",
        span: 3,
        show: { visible: true, accessor: ["divisorTemplate", "name"] },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "percent",
        label: "Udział",
        type: FIELDS.NumberInput,
        endEnhancer: "%",
        span: 2,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "internalAccount",
        label: "Konto wewnętrzne",
        type: FIELDS.Input,
        span: 4,
        dataType: "model:internal-accounts",
        show: { visible: true, accessor: ["internalAccount", "name"] },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "internalAccounts",
        label: "Konto wewnętrzne",
        type: FIELDS.InternalAccountsSelect,
        span: 7,
        placeholder: "Wybierz",
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "createdAt",
        label: "Data utworzenia",
        type: FIELDS.Input,
        dataType: "datetime",
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "updatedAt",
        label: "Data aktualizacji",
        type: FIELDS.Input,
        dataType: "datetime",
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
    ],
  },
];
