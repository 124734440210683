import { Block } from "baseui/block";
import { StyledLink } from "baseui/link";
import React, { MouseEvent } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Row } from "react-table";

import { Document } from "../containers/Documents/documents";
import { useDictionaries } from "../contexts/dictionaries-context";
import { setSortingParams } from "../utils/sorting";
import Checkbox from "./checkbox";
import FormattedValue from "./formatted-value";
import SortingTableHeader, { SortDirection } from "./sorting-table-header";
import Table from "./table";

type DocumentsCheckboxTableProps = {
  data: Document[];
  handleSorting: (column: FieldName) => void;
  loading?: boolean;
  selectedDocuments: number[];
  setSelectedDocuments: (ids: number[]) => void;
  sortBy: FieldName | null;
  sortDirection: SortDirection | null;
};

export enum FieldName {
  InternalNumber = "internalNumber",
  DocumentType = "documentType",
  DocumentKind = "documentKind",
  CreatedAt = "createdAt",
}

export default function DocumentsCheckboxTable({
  data,
  handleSorting: handleSortingState,
  loading,
  selectedDocuments,
  setSelectedDocuments,
  sortBy,
  sortDirection,
}: DocumentsCheckboxTableProps): React.ReactElement {
  const history = useHistory();
  const { findValue } = useDictionaries();

  const { search } = useLocation();

  const handleSorting = (column: FieldName) => {
    handleSortingState(column);
    setSortingParams(history, search, column, sortDirection);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: () => (
          <Block
            width="20px"
            marginLeft="8px"
            display="flex"
            justifyContent="center"
          >
            <Checkbox
              isIndeterminate={
                selectedDocuments?.length > 0 &&
                selectedDocuments?.length < data?.length
              }
              checked={selectedDocuments?.length === data?.length}
              onChange={() => {
                if (selectedDocuments?.length !== data?.length) {
                  setSelectedDocuments(
                    data?.map((document: Document) => document?.id)
                  );
                } else {
                  setSelectedDocuments([]);
                }
              }}
            />
          </Block>
        ),
        id: "checkbox",
        accessor: "checked",
        Cell: ({ row }: { row: Row<Document> }) => (
          <Block width="20px" marginLeft="5px" display="block">
            <Checkbox
              checked={selectedDocuments?.includes(row.original.id)}
              onChange={() => {
                if (selectedDocuments?.includes(row.original.id)) {
                  setSelectedDocuments([
                    ...selectedDocuments?.filter(
                      (id) => id !== row.original.id
                    ),
                  ]);
                } else {
                  setSelectedDocuments([
                    ...(selectedDocuments ? selectedDocuments : []),
                    row.original.id,
                  ]);
                }
              }}
            />
          </Block>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.InternalNumber)}
            sortDirection={
              sortBy === FieldName.InternalNumber ? sortDirection : null
            }
          >
            Numer
          </SortingTableHeader>
        ),
        accessor: "internalNumber",
        Cell: ({ row }: { row: Row<Document> }) => {
          const documentKind = row?.original?.documentKind?.toLowerCase();
          const documentTypename = row?.original?.type;
          return (
            <StyledLink
              onClick={(event: MouseEvent) => {
                event.preventDefault();
                history.push({
                  pathname: `/documents/${documentKind}/${documentTypename}/${row.original.id}`,
                  state: {
                    alternativeDocumentsList: true,
                  },
                });
              }}
              href={`/documents/${documentKind}/${documentTypename}/${row.original.id}`}
            >
              {row.original.internalNumber}
            </StyledLink>
          );
        },
      },
      {
        Header: "Jednostka organizacyjna",
        id: "organizationalUnit",
        Cell: ({ row }: { row: Row<Document> }) => (
          <FormattedValue
            dataType="model:organizational-units"
            data={row?.original?.organizationalUnit?.id}
          >
            {row?.original?.organizationalUnit?.name}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.DocumentKind)}
            sortDirection={
              sortBy === FieldName.DocumentKind ? sortDirection : null
            }
          >
            Rodzaj dokumentu
          </SortingTableHeader>
        ),
        id: "documentKind",
        Cell: ({ row }: { row: Row<Document> }) => (
          <FormattedValue dataType="document-kind">
            {row.original.documentKind}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.DocumentType)}
            sortDirection={
              sortBy === FieldName.DocumentType ? sortDirection : null
            }
          >
            Typ dokumentu
          </SortingTableHeader>
        ),
        id: "documentType",
        Cell: ({ row }: { row: Row<Document> }) => {
          const documentType = row.original.documentType
            ? findValue(row.original.documentType)
            : "";
          return <FormattedValue>{documentType as string}</FormattedValue>;
        },
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.CreatedAt)}
            sortDirection={
              sortBy === FieldName.CreatedAt ? sortDirection : null
            }
          >
            Data rejestracji
          </SortingTableHeader>
        ),
        id: "createdAt",
        accessor: "createdAt",
        Cell: ({ row }: { row: Row<Document> }) => (
          <FormattedValue dataType="date">
            {row.original.createdAt}
          </FormattedValue>
        ),
      },
    ],
    [data, sortBy, sortDirection, selectedDocuments]
  );

  return <Table<Document> columns={columns} data={data} isLoading={loading} />;
}
