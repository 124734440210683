import { FIELDS, FieldsGroup } from "../../fields.d";

export const ADDRESSES_FIELDS: FieldsGroup[] = [
  {
    id: "addressData",
    label: "Dane teleadresowe",
    fields: [
      {
        id: "name",
        label: "Nazwa",
        type: FIELDS.Input,
        span: 6,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "nip",
        label: "NIP",
        dataType: "nip",
        type: FIELDS.Input,
        span: 6,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "address",
        label: "Ulica i numer",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "postalCode",
        label: "Kod pocztowy",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "city",
        label: "Miejscowość",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "countryCode",
        label: "Kraj",
        dataType: "countryCode",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "phoneNumber",
        label: "Numer telefonu",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "mobileNumber",
        label: "Numer telefonu kom.",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "email",
        label: "Adres e-mail",
        type: FIELDS.Input,
        dataType: "email",
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "isSensitive",
        label: "Dane osobowe",
        type: FIELDS.Input,
        dataType: "personal-data-boolean",
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
    ],
  },
  {
    id: "system",
    label: "Dane systemowe",
    fields: [
      {
        id: "isHidden",
        label: "Widoczność",
        type: FIELDS.Input,
        dataType: "visibility-boolean",
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },

      {
        id: "createdAt",
        label: "Data utworzenia",
        type: FIELDS.Input,
        dataType: "datetime",
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "updatedAt",
        label: "Data aktualizacji",
        type: FIELDS.Input,
        dataType: "datetime",
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
    ],
  },
];
