import { Block } from "baseui/block";
import React, { useEffect, useMemo } from "react";
import { Cell } from "react-table";

import {
  RequestFormItem,
  RequestFormValue,
} from "../containers/Documents/documents";
import { useEuroRate } from "../contexts/euro-rate-context";
import FormControl from "./form-control";
import FormattedValue from "./formatted-value";
import Table from "./table";

type RequestFormValueTableProps = {
  requestFormItems: RequestFormItem[];
  reloadPage: () => void;
};

export default function RequestFormValueTable({
  requestFormItems,
  reloadPage,
}: RequestFormValueTableProps): React.ReactElement {
  const { euroRateFromRegulation } = useEuroRate();

  useEffect(() => {
    reloadPage();
  }, [requestFormItems]);

  const requestFormItemsSum = requestFormItems.reduce(
    (a: number, b: RequestFormItem) => a + (b.orderValue ? b.orderValue : 0),
    0
  );
  const requestFormValueData = [
    {
      grossValue: requestFormItemsSum,
      netValue: requestFormItemsSum / 1.23,
      grossValueEur: euroRateFromRegulation
        ? requestFormItemsSum / euroRateFromRegulation
        : undefined,
      netValueEur: euroRateFromRegulation
        ? requestFormItemsSum / euroRateFromRegulation / 1.23
        : undefined,
    },
  ];

  const columns = useMemo(
    () => [
      {
        accessor: "grossValue",
        Header: (
          <Block display="flex" justifyContent="flex-end">
            Wartość brutto
          </Block>
        ),
        Cell: ({ cell }: { cell: Cell }) => (
          <Block display="flex" justifyContent="flex-end">
            <FormattedValue dataType="quota">{cell.value}</FormattedValue>
          </Block>
        ),
      },
      {
        accessor: "netValue",
        Header: (
          <Block display="flex" justifyContent="flex-end">
            Wartość netto
          </Block>
        ),
        Cell: ({ cell }: { cell: Cell }) => (
          <Block display="flex" justifyContent="flex-end">
            <FormattedValue dataType="quota">{cell.value}</FormattedValue>
          </Block>
        ),
      },
      {
        accessor: "grossValueEur",
        Header: (
          <Block display="flex" justifyContent="flex-end">
            Wartość brutto w EUR*
          </Block>
        ),
        Cell: ({ cell }: { cell: Cell }) => (
          <Block display="flex" justifyContent="flex-end">
            <FormattedValue dataType="quota" currency="EUR" loadingIfUndefined>
              {cell.value}
            </FormattedValue>
          </Block>
        ),
      },
      {
        accessor: "netValueEur",
        Header: (
          <Block display="flex" justifyContent="flex-end">
            Wartość netto w EUR*
          </Block>
        ),
        Cell: ({ cell }: { cell: Cell }) => (
          <Block display="flex" justifyContent="flex-end">
            <FormattedValue dataType="quota" currency="EUR" loadingIfUndefined>
              {cell.value}
            </FormattedValue>
          </Block>
        ),
      },
    ],
    [requestFormItems, euroRateFromRegulation]
  );

  return (
    <FormControl
      caption={
        euroRateFromRegulation
          ? `* Wg kursu z rozporządzenia (${euroRateFromRegulation?.toFixed(
              4
            )} PLN)`
          : "* Kurs z rozporządzenia niedostępny"
      }
      overrides={{
        Caption: {
          style: {
            marginTop: "-5px",
            textAlign: "right",
          },
        },
      }}
    >
      <Block paddingTop="scale200" paddingBottom="scale600">
        <Table<RequestFormValue>
          compact
          columns={columns}
          data={requestFormValueData}
        />
      </Block>
    </FormControl>
  );
}
