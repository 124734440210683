import { ApolloError, useMutation } from "@apollo/client";
import { useStyletron } from "baseui";
import { KIND } from "baseui/button";
import { ModalBody, ModalFooter, ModalHeader } from "baseui/modal";
import { format } from "date-fns";
import { pl } from "date-fns/locale";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AlertOctagon, Check, Refresh, X } from "tabler-icons-react";

import { InputValidationError } from "../api";
import { FinancialPlan } from "../containers/FinancialPlans/financial-plans";
import { FINANCIAL_PLANS_SYNCHRONIZE } from "../containers/FinancialPlans/financial-plans.gql";
import { useLoading } from "../contexts/loading-context";
import Button from "./button";
import { ControlledCheckbox } from "./checkbox";
import { ControlledDatePicker } from "./date-picker";
import FormControl from "./form-control";
import Modal from "./modal";

type FinancialPlanSynchronizationModalProps = {
  isOpen: boolean;
  close: () => void;
  financialPlan: FinancialPlan;
};

export default function FinancialPlanSynchronizationModal({
  isOpen,
  close,
  financialPlan,
}: FinancialPlanSynchronizationModalProps): React.ReactElement {
  const [css] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, setIsLoading } = useLoading();
  const [synchronizeFinancialPlan, { error, loading }] = useMutation(
    FINANCIAL_PLANS_SYNCHRONIZE
  );

  const [activePage, setActivePage] = useState<"date" | "confirmation">("date");

  type FormInputs = {
    date: Date;
    overwrite: boolean;
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm<FormInputs>({
    defaultValues: {
      date: "",
      overwrite: false,
    },
  });

  const onClose = () => {
    close();
    setActivePage("date");
    reset();
  };

  const onSubmit = async (formInputs: FormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      await synchronizeFinancialPlan({
        variables: {
          financialPlanSyncInput: {
            financialPlanId: financialPlan.id,
            ...formInputs,
          },
        },
      });

      enqueueSnackbar({
        message: "Zaplanowano synchronizację pomyślnie",
        variant: "success",
      });

      onClose();
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });

      setActivePage("date");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      $style={{ height: "280px" }}
      autoFocus={false}
    >
      <ModalHeader>Aktualizacja planu finansowego</ModalHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          {activePage === "date" && (
            <>
              <FormControl
                label={"Data i godzina"}
                required
                error={
                  ((errors as any)["date"] &&
                    (errors as any)["date"].message) ||
                  (error &&
                    error.graphQLErrors[0]?.extensions?.code ===
                      "INPUT_VALIDATION_ERROR" &&
                    error.graphQLErrors[0]?.extensions?.validationErrors
                      ?.find(
                        (vE: InputValidationError) => vE?.property === "date"
                      )
                      ?.errors.map((message: string) => (
                        <div
                          key="error"
                          className={css({
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          })}
                        >
                          {message}
                          <AlertOctagon color="#999" size={12} />
                        </div>
                      ))[0])
                }
                disabled={isLoading}
              >
                <ControlledDatePicker
                  name="date"
                  placeholder="Wybierz"
                  withTime
                  control={control}
                  autoFocusCalendar={false}
                  minDate={new Date()}
                />
              </FormControl>

              <FormControl
                error={
                  ((errors as any)["overwrite"] &&
                    (errors as any)["overwrite"].message) ||
                  (error &&
                    error.graphQLErrors[0]?.extensions?.code ===
                      "INPUT_VALIDATION_ERROR" &&
                    error.graphQLErrors[0]?.extensions?.validationErrors
                      ?.find(
                        (vE: InputValidationError) =>
                          vE?.property === "overwrite"
                      )
                      ?.errors.map((message: string) => (
                        <div
                          key="error"
                          className={css({
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          })}
                        >
                          {message}
                          <AlertOctagon color="#999" size={12} />
                        </div>
                      ))[0])
                }
                disabled={isLoading}
              >
                <ControlledCheckbox name="overwrite" control={control}>
                  <span className={css({ fontSize: "12px" })}>
                    Nadpisz datę zakolejkowanej synchronizacji
                  </span>
                </ControlledCheckbox>
              </FormControl>
            </>
          )}

          {activePage === "confirmation" && (
            <>
              <p>Czy potwierdzasz wykonanie aktualizacji planu finansowego</p>
              <p>
                <strong>
                  {`${format(new Date(watch("date")), "d MMMM yyyy", {
                    locale: pl,
                  })} o godzinie ${format(new Date(watch("date")), "H:mm", {
                    locale: pl,
                  })}?`}
                </strong>
              </p>
            </>
          )}
        </ModalBody>

        <ModalFooter>
          {activePage === "date" && (
            <Button
              type="button"
              kind={KIND.secondary}
              $style={{ marginRight: "6px" }}
              onClick={onClose}
              disabled={loading}
              startEnhancer={<X size={18} />}
            >
              Anuluj
            </Button>
          )}

          {activePage === "date" && (
            <Button
              disabled={loading || !watch("date")}
              isLoading={loading}
              type="button"
              onClick={() => setActivePage("confirmation")}
              startEnhancer={<Refresh size={18} />}
            >
              Aktualizuj
            </Button>
          )}

          {activePage === "confirmation" && (
            <Button
              type="button"
              kind={KIND.secondary}
              $style={{ marginRight: "6px" }}
              onClick={() => {
                setActivePage("date");
              }}
              disabled={loading}
              startEnhancer={<X size={18} />}
            >
              Nie
            </Button>
          )}

          {activePage === "confirmation" && (
            <Button
              disabled={loading}
              isLoading={loading}
              type="submit"
              startEnhancer={<Check size={18} />}
            >
              Tak
            </Button>
          )}
        </ModalFooter>
      </form>
    </Modal>
  );
}
