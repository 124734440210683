import { ApolloError, useMutation } from "@apollo/client";
import { useStyletron } from "baseui";
import { KIND } from "baseui/button";
import { ModalBody, ModalFooter, ModalHeader } from "baseui/modal";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { AlertOctagon } from "tabler-icons-react";

import { InputValidationError } from "../api";
import { DOCUMENT_ASSIGNMENT_CREATE } from "../containers/Documents/documents.gql";
import { AssignmentFinishInputs } from "../containers/Folders/folders";
import { ASSIGNMENT_FINISH } from "../containers/Folders/folders.gql";
import { useAuth } from "../contexts/auth-context";
import { useLoading } from "../contexts/loading-context";
import { formValidation } from "../utils/formValidation";
import Button from "./button";
import FormControl from "./form-control";
import Modal from "./modal";
import { ControlledUsersSelect } from "./select";
import { ControlledTextArea } from "./text-area";

type AssignmentFinishModalProps = {
  assignmentId?: number;
  isOpen: boolean;
  onCompleted: () => void;
  close: () => void;
  label?: string;
};

export default function AssignmentFinishModal({
  assignmentId,
  isOpen,
  close,
  onCompleted,
  label,
}: AssignmentFinishModalProps): React.ReactElement {
  const [css] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, setIsLoading } = useLoading();

  const { user } = useAuth();

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<AssignmentFinishInputs>();

  const [finishAssigment, { error, loading }] = useMutation(ASSIGNMENT_FINISH);

  const onAssignmentFinishSubmit = async ({
    assigneeComment,
  }: AssignmentFinishInputs): Promise<void> => {
    setIsLoading(true);

    try {
      await finishAssigment({
        variables: {
          assignmentFinishInput: {
            assignmentId,
            assigneeComment,
          },
        },
      });

      enqueueSnackbar({
        message: "Zakończono pomyślnie",
        variant: "success",
      });

      close();
      onCompleted();
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  return (
    <Modal isOpen={isOpen} onClose={close}>
      <form onSubmit={handleSubmit(onAssignmentFinishSubmit)}>
        <ModalHeader>Zakończ</ModalHeader>

        <ModalBody>
          {label && (
            <p>
              Dotyczy: <strong>{label}</strong>
            </p>
          )}

          <FormControl
            label={""}
            error={
              ((errors as any)["assigneeComment"] &&
                (errors as any)["assigneeComment"].message) ||
              (error &&
                error.graphQLErrors[0]?.extensions?.code ===
                  "INPUT_VALIDATION_ERROR" &&
                error.graphQLErrors[0]?.extensions?.validationErrors
                  ?.find(
                    (vE: InputValidationError) =>
                      vE?.property === "assigneeComment"
                  )
                  ?.errors.map((message: string) => (
                    <div
                      key="error"
                      className={css({
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      })}
                    >
                      {message}
                      <AlertOctagon color="#999" size={12} />
                    </div>
                  ))[0])
            }
            disabled={isLoading}
          >
            <ControlledTextArea
              placeholder="Komentarz (opcjonalnie)"
              control={control}
              name={"assigneeComment"}
              id={"assigneeComment"}
            />
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            type="button"
            kind={KIND.secondary}
            $style={{ marginRight: "6px" }}
            onClick={() => {
              close();
              reset();
            }}
            disabled={loading}
          >
            Anuluj
          </Button>

          <Button disabled={loading} isLoading={loading} type="submit">
            Zakończ
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
