import { FIELDS, FieldsGroup } from "../../fields.d";

export type FormInputs = {
  name: string;
  fundingSource: { id: number; label?: string }[];
  accountNumber: string;
  limit: number | string;
  balance: number | string;
  currency?: string;
  currencies?: {
    id: string;
    label?: string;
    code?: string;
    currencyId?: string;
  }[];
};

export const INTERNAL_ACCOUNTS_FIELDS: FieldsGroup[] = [
  {
    id: "basic",
    label: "Podstawowe informacje",
    fields: [
      {
        id: "name",
        label: "Nazwa",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "fundingSource",
        label: "Źródło finansowania",
        type: FIELDS.FundingSourceSelect,
        span: 3,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "accountNumber",
        label: "Numer konta",
        type: FIELDS.Input,
        span: 6,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      // {
      //   id: "balance",
      //   label: "Stan konta",
      //   type: FIELDS.NumberInput,
      //   dataType: "quota",
      //   span: 3,
      //   show: { visible: true },
      //   create: { visible: true, required: true },
      //   edit: { visible: true, required: true },
      //   endEnhancer: "PLN",
      // },
      {
        id: "currencies",
        label: "Waluta",
        type: FIELDS.CurrencySelect,
        span: 3,
        show: { visible: true, accessor: ["currency", "code"] },
        create: { visible: true },
        edit: { visible: true },
        caption: "Wybierz, jeżeli inna niż PLN",
      },
    ],
  },
];
