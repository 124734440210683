import { gql } from "@apollo/client";

export const NOTIFICATIONS_INDEX = gql`
  query(
    $pageSize: Int
    $offset: Int
    $sorting: [NotificationSort!]
    $filter: NotificationFilter
  ) {
    notifications(
      paging: { limit: $pageSize, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        content
        actionPath
        readAt
        createdAt
      }
    }
  }
`;

export const NOTIFICATIONS_MARK_AS_READ = gql`
  mutation($id: Int!) {
    notificationMarkAsRead(notificationMarkAsReadInput: { id: $id }) {
      id
    }
  }
`;

export const NOTIFICATIONS_MARK_MANY_AS_READ = gql`
  mutation($ids: [Int!]!) {
    notificationMarkManyAsRead(notificationMarkManyAsReadInput: { ids: $ids }) {
      id
    }
  }
`;

export const NOTIFICATIONS_SUBSCRIPTION = gql`
  subscription {
    notificationCreated {
      id
      content
      actionPath
      user {
        id
        unreadNotificationCount
      }
    }
  }
`;
