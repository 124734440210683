import { BasicFilter, FILTERS } from "./../../filters.d";

export const POSITIONS_FILTERS: BasicFilter[] = [
  {
    id: "name",
    label: "Nazwa",
    type: FILTERS.Text,
  },
  {
    id: "description",
    label: "Opis",
    type: FILTERS.Text,
  },
];
