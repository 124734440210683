import { Block } from "baseui/block";
import { FormControl } from "baseui/form-control";
import React, { ReactElement, useEffect } from "react";
import { useFormContext } from "react-hook-form";

import {
  ControlledGroupsSelect,
  ControlledOrganizationalUnitsSelect,
  ControlledPositionsSelect,
  ControlledUsersSelect,
} from "./select";

export enum RecipientType {
  DocumentFlowStarter = "isProcessedByDocumentFlowStarter",
  DocumentOrganizationalUnit = "documentOrganizationalUnit",
  OrganizationalUnit = "organizationalUnit",
  Users = "users",
  Groups = "groups",
}

export default function StepRecipientSelector(): ReactElement {
  const {
    control,
    setValue,
    unregister,
    watch,
    formState: { dirtyFields },
  } = useFormContext();

  const watchRecipientType = watch("recipientType");

  const renderInput = (type: RecipientType) => {
    switch (type) {
      case RecipientType.DocumentOrganizationalUnit:
        return (
          <Block>
            <FormControl
              label="Stanowiska"
              caption="Przy nieuzupełnieniu pola każdy użytkownik z tej jednostki będzie mógł akceptować ten krok"
            >
              <ControlledPositionsSelect
                control={control}
                name="positions"
                placeholder="Dowolne lub wybierz"
                multi
              />
            </FormControl>
          </Block>
        );

      case RecipientType.OrganizationalUnit:
        return (
          <Block display="flex" flexDirection="column">
            <FormControl label="Jednostka organizacyjna">
              <ControlledOrganizationalUnitsSelect
                control={control}
                name="organizationalUnit"
                placeholder="Wybierz"
                required
              />
            </FormControl>

            <FormControl
              label="Stanowiska"
              caption="Przy nieuzupełnieniu pola każdy użytkownik z tej jednostki będzie mógł akceptować ten krok"
            >
              <ControlledPositionsSelect
                control={control}
                name="positionsInOrganizationalUnit"
                placeholder="Dowolne lub wybierz"
                multi
              />
            </FormControl>
          </Block>
        );

      case RecipientType.Users:
        return (
          <Block>
            <FormControl label="Użytkownicy">
              <ControlledUsersSelect
                control={control}
                name="users"
                placeholder="Wybierz"
                multi
                required
              />
            </FormControl>
          </Block>
        );

      case RecipientType.Groups:
        return (
          <Block>
            <FormControl label="Grupa użytkowników">
              <ControlledGroupsSelect
                control={control}
                name="groups"
                placeholder="Wybierz"
                required
              />
            </FormControl>
          </Block>
        );

      default:
        return null;
    }
  };

  useEffect(() => {
    if (!dirtyFields?.recipientType) return;

    if (watchRecipientType === RecipientType.DocumentOrganizationalUnit) {
      setValue("organizationalUnit", undefined);
      setValue("users", undefined);
      setValue("positionsInOrganizationalUnit", undefined);
      setValue("positions", undefined);
      setValue("groups", undefined);
      unregister("organizationalUnit");
      unregister("positionsInOrganizationalUnit");
      unregister("positions");
      unregister("users");
      unregister("groups");
    }
    if (watchRecipientType === RecipientType.DocumentFlowStarter) {
      setValue("organizationalUnit", undefined);
      setValue("users", undefined);
      setValue("positionsInOrganizationalUnit", undefined);
      setValue("positions", undefined);
      setValue("groups", undefined);
      unregister("organizationalUnit");
      unregister("positionsInOrganizationalUnit");
      unregister("positions");
      unregister("users");
      unregister("groups");
    }
    if (watchRecipientType === RecipientType.OrganizationalUnit) {
      setValue("users", undefined);
      setValue("positions", undefined);
      setValue("positionsInOrganizationalUnit", undefined);
      setValue("groups", undefined);
      unregister("users");
      unregister("positions");
      unregister("positionsInOrganizationalUnit");
      unregister("groups");
    }
    if (watchRecipientType === RecipientType.Users) {
      setValue("organizationalUnit", undefined);
      setValue("positions", undefined);
      setValue("positionsInOrganizationalUnit", undefined);
      setValue("groups", undefined);
      unregister("organizationalUnit");
      unregister("positions");
      unregister("positionsInOrganizationalUnit");
      unregister("groups");
    }
    if (watchRecipientType === RecipientType.Groups) {
      setValue("organizationalUnit", undefined);
      setValue("positions", undefined);
      setValue("positionsInOrganizationalUnit", undefined);
      setValue("users", undefined);
      unregister("organizationalUnit");
      unregister("positions");
      unregister("positionsInOrganizationalUnit");
      unregister("users");
    }
  }, [watchRecipientType, dirtyFields]);

  return renderInput(watchRecipientType) || <></>;
}
