import { BasicFilter, FILTERS } from "./../../filters.d";

export const DOCUMENT_PARCELS_FILTERS: BasicFilter[] = [
  {
    id: "createdAt",
    label: "Data utworzenia",
    type: FILTERS.Date,
  },
  {
    id: "documentId",
    label: "Dokument",
    type: FILTERS.Document,
  },
];
