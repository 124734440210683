import { gql, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { KIND, SIZE } from "baseui/button";
import { StyledLink } from "baseui/link";
import { ModalBody, ModalHeader } from "baseui/modal";
import { Skeleton } from "baseui/skeleton";
import { formatDistanceToNow } from "date-fns";
import { pl } from "date-fns/locale";
import React, { useEffect, useState } from "react";
import { useMemo } from "react";
import { Row } from "react-table";

import { useLoading } from "../contexts/loading-context";
import Button from "./button";
import { formatBytes } from "./files-picker";
import FormattedValue from "./formatted-value";
import Modal from "./modal";
import SortingTableHeader, { SortDirection } from "./sorting-table-header";
import Table from "./table";

const SCANNER_OBJECTS = gql`
  query($sorting: [ScannerObjectSort!]) {
    scannerObjects(
      filter: { isDirectory: { is: false } }
      paging: { limit: 1000 }
      sorting: $sorting
    ) {
      totalCount
      nodes {
        name
        path
        isDirectory
        size
        createdAt
        modifiedAt
        __typename
      }
    }
  }
`;

type ScannerTableProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  files: File[];
  setFiles: (files: File[]) => void;
};

enum FieldName {
  Name = "name",
  Size = "size",
  CreatedAt = "createdAt",
}

type ExtendedFile = File & {
  downloadUrl?: string;
  originalName?: string;
  mimetype?: string;
  path: string;
  createdAt: string;
};

export default function ScannerTable({
  isOpen,
  setIsOpen,
  files,
  setFiles,
}: ScannerTableProps): React.ReactElement {
  const [css] = useStyletron();
  const { isFetching, setIsFetching, isLoading, setIsLoading } = useLoading();
  const [sortBy, setSortBy] = useState<FieldName | null>(null);
  const [sortDirection, setSortDirection] = useState<SortDirection | null>(
    null
  );

  const { data, refetch } = useQuery(SCANNER_OBJECTS, {
    variables: {
      sorting: {
        field: sortBy || FieldName.CreatedAt,
        direction: sortDirection || SortDirection.DESC,
      },
    },
  });

  const tableData = data?.scannerObjects?.nodes?.filter(
    (file: { isDirectory: boolean }) => !file?.isDirectory
  );

  const handleSorting = (column: FieldName) => {
    setSortBy(column);
    setSortDirection(
      sortDirection === null
        ? SortDirection.DESC
        : sortDirection === SortDirection.ASC
        ? SortDirection.DESC
        : SortDirection.ASC
    );
  };

  useEffect(() => {
    refetch();
  }, [isOpen]);

  const handleFileConvertion = async (
    choosedFile: {
      name: string;
      path: string;
    },
    files?: File[]
  ) => {
    const response = await fetch(
      `${process.env.REACT_APP_GRAPHQL_API_URL?.replace(
        "/graphql",
        ""
      )}/scanner/download-file/${encodeURIComponent(choosedFile?.path)}`
    );
    setIsLoading(true);
    const data = await response.blob();
    const metadata = {
      type: "application/pdf",
    };
    const file = new File([data], choosedFile.name, metadata);
    const filesArray = [...(files || []), file];
    setFiles && setFiles(filesArray);
    setIsOpen(false);
    setIsLoading(false);
  };

  const scannerColumns = useMemo(
    () => [
      {
        Header: "Lp.",
        id: "ordinalNumber",
        Cell: ({ row }: { row: Row<ExtendedFile> }) => (
          <FormattedValue>{row?.index + 1}</FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => {
              setIsFetching(true);
              handleSorting(FieldName.Name);
            }}
            sortDirection={sortBy === FieldName.Name ? sortDirection : null}
          >
            Nazwa
          </SortingTableHeader>
        ),
        id: "name",
        Cell: ({ row }: { row: Row<ExtendedFile> }) => (
          <>
            <StyledLink
              href={`${process.env.REACT_APP_GRAPHQL_API_URL?.replace(
                "/graphql",
                ""
              )}/scanner/download-file/${encodeURIComponent(
                row?.original?.path
              )}`}
            >
              {row?.original?.name}
            </StyledLink>
          </>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.Size)}
            sortDirection={sortBy === FieldName.Size ? sortDirection : null}
          >
            Rozmiar
          </SortingTableHeader>
        ),
        id: "size",
        Cell: ({ row }: { row: Row<ExtendedFile> }) => (
          <FormattedValue>{formatBytes(row?.original?.size)}</FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.CreatedAt)}
            sortDirection={
              sortBy === FieldName.CreatedAt ? sortDirection : null
            }
          >
            Data utworzenia
          </SortingTableHeader>
        ),
        id: "createdAt",
        Cell: ({ row }: { row: Row<ExtendedFile> }) => (
          <>
            <FormattedValue dataType="datetime">
              {row?.original?.createdAt}
            </FormattedValue>
            <FormattedValue>
              {` (${formatDistanceToNow(new Date(row?.original?.createdAt), {
                locale: pl,
                addSuffix: true,
              })})`}
            </FormattedValue>
          </>
        ),
      },
      {
        id: "actions",
        Cell: ({ row }: { row: Row<ExtendedFile> }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            <StyledLink
              href={`${process.env.REACT_APP_GRAPHQL_API_URL?.replace(
                "/graphql",
                ""
              )}/scanner/download-file/${encodeURIComponent(
                row?.original?.path
              )}`}
              $style={{ textDecoration: "none !important" }}
            >
              <Button
                kind={KIND.secondary}
                size={SIZE.mini}
                disabled={isLoading}
              >
                Pobierz
              </Button>
            </StyledLink>
            <Button
              kind={KIND.primary}
              size={SIZE.mini}
              $style={{ marginLeft: "10px" }}
              onClick={() => handleFileConvertion(row.original, files)}
              disabled={
                isLoading ||
                files
                  ?.map((file: { name: string }) => file?.name)
                  .includes(row?.original?.name)
              }
            >
              Użyj
            </Button>
          </div>
        ),
      },
    ],
    [data, isLoading, files, sortBy, sortDirection]
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      $style={{
        width: "66vw",
        height: "600px",
        overflowY: "scroll",
      }}
      closeable={!isLoading}
    >
      <ModalHeader>
        {isFetching ? (
          <Skeleton height="38px" width="200px" animation />
        ) : (
          `Skaner (${tableData?.length + " plików" || ""})`
        )}
      </ModalHeader>
      <ModalBody>
        <Table<File>
          compact
          isModal
          columns={scannerColumns}
          data={tableData}
        />
      </ModalBody>
    </Modal>
  );
}
