import { gql } from "@apollo/client";

export const CONTRACT_AGREEMENT_CREATE_FROM_DOCUMENT = gql`
  mutation(
    $contractAgreementCreateInput: ContractAgreementCreateInput!
    $files: [Upload!]
    $addressInput: AddressInput
    $documentId: Float!
  ) {
    contractAgreementCreateFromDocument(
      contractAgreementCreateInput: $contractAgreementCreateInput
      contractAgreementFiles: $files
      addressInput: $addressInput
      documentId: $documentId
    ) {
      id
    }
  }
`;

export const CONTRACT_AGREEMENT_SHOW = gql`
  query($id: Int!) {
    contractAgreement(id: $id) {
      id
      documentNumber
      internalNumber
      description
      title
      isReleasable
      programName
      documentType
      type
      sequenceNumber
      documentDate
      registeredAt
      createdAt
      postStampAt
      handDelivered
      isEditable
      state
      documentLinks {
        id
        linkerId
        linker {
          ... on RequestForm {
            internalNumber
            legalAct
            id
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on RequestFormNote {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on BillIssuanceRequest {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on ContractPreparationRequest {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on ContractAgreement {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on FinancialAccountingDocument {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on Payment {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on Document {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
        }
        linkedId
        linked {
          ... on RequestForm {
            internalNumber
            legalAct
            id
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on RequestFormNote {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on BillIssuanceRequest {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on ContractPreparationRequest {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on ContractAgreement {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on FinancialAccountingDocument {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on Payment {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on Document {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
        }
        type
        createdAt
      }
      contractorAddress {
        __typename
        ... on Address {
          id
          address
          streetName
          buildingNumber
          apartmentNumber
          mobileNumber
          name
          city
          postalCode
          nip
          phoneNumber
          name
          countryCode
          email
          isSensitive
        }
        ... on HiddenAddress {
          id
          name
        }
      }
      createdAt
      updatedAt
      createdBy {
        firstName
        lastName
      }
      updatedBy {
        firstName
        lastName
      }
      case {
        id
        number
        name
        isConductedElectronically
      }
      caseId
      organizationalUnitId
      organizationalUnit {
        id
        name
      }
      status
      contractNumber
      budgetCategory
      contractType
      beginAt
      endAt
      collectionPeriodEndAt
      netValueInPLN
      grossValueInPLN
      exchangeRateEUR
      netValueInEUR
      legalDocumentsVerification
      legalAct
      documentRoot {
        ... on RequestForm {
          internalNumber
          legalAct
          id
          documentType
          type
          documentNumber
          documentKind
        }
        ... on BillIssuanceRequest {
          id
          internalNumber
          documentType
          type
          documentNumber
          documentKind
        }
        ... on ContractPreparationRequest {
          id
          internalNumber
          documentType
          type
          documentNumber
          documentKind
        }
        ... on ContractAgreement {
          id
          internalNumber
          documentType
          type
          documentNumber
          documentKind
        }
        ... on FinancialAccountingDocument {
          id
          internalNumber
          documentType
          type
          documentNumber
          documentKind
        }
        ... on Payment {
          id
          internalNumber
          documentType
          type
          documentNumber
          documentKind
        }
        ... on Document {
          id
          internalNumber
          documentType
          type
          documentNumber
          documentKind
        }
      }
      responsible {
        id
        firstName
        lastName
      }
      isResponsibleSettable
      createdBy {
        id
        firstName
        lastName
      }
      currentStatus {
        documentFlowStepId
        documentFlowStep {
          id
          name
          isInitial
          isProcessedByDocumentFlowStarter
          isPaymentCreationEnabled
          isLegalSectionEnabled
          organizationalUnit {
            id
            name
          }
          group {
            id
            name
          }
          users {
            firstName
            lastName
          }
          allowedDocumentFields
          isAllowedToEditAllFields
        }
        createdAt
      }
      statuses {
        id
        comment
        signatureFilePath
        signatureFileMimetype
        signatureDownloadUrl
        user {
          id
          firstName
          lastName
          position
        }
        userPosition
        userOrganizationalUnits
        documentFlowRecipients
        substituter {
          id
          firstName
          lastName
          position
        }
        documentFlowStep {
          isPaymentCreationEnabled
          isLegalSectionEnabled
          organizationalUnit {
            id
            name
            symbol
          }
          users {
            id
            firstName
            lastName
          }
          positions {
            id
            name
          }
          id
          name
          isProcessedByDocumentFlowStarter
        }
        documentFlowAction {
          label
          style
          resultingDocumentState
          isEndingDocumentFlow
        }
        createdAt
      }
      documentFlowId
      isDocumentUsedInFlow
      documentFlow {
        id
        name
        steps {
          id
          name
        }
      }
      files {
        __typename
        ... on File {
          id
          downloadUrl
          originalName
          mimetype
          createdAt
        }
        ... on HiddenFile {
          id
        }
      }
      activityLogs {
        id
        activity
        description
        user {
          firstName
          lastName
          id
        }
        substituter {
          id
          firstName
          lastName
        }
        assignmentUsers
        properties
        createdAt
        userOrganizationalUnits
      }
      isDispatchable
      isDispatchCancelable
      isShareable
      shippedAt
      currentAssignment {
        id
        primaryAssignableId
        isAssessmentRequested
        assignerComment
        createdAt
        type
        status
        assigner {
          id
          firstName
          lastName
        }
        substituter {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const CONTRACT_AGREEMENT_CLOSE = gql`
  mutation($contractAgreementCloseInput: ContractAgreementCloseInput!) {
    contractAgreementClose(
      contractAgreementCloseInput: $contractAgreementCloseInput
    ) {
      id
    }
  }
`;

export const CONTRACT_AGREEMENT_FILES_SHOW = gql`
  query($id: Int!) {
    contractAgreement(id: $id) {
      files {
        __typename
        ... on File {
          id
          downloadUrl
          originalName
          mimetype
          createdAt
        }
        ... on HiddenFile {
          id
        }
      }
    }
  }
`;

export const CONTRACT_AGREEMENT_UPDATE = gql`
  mutation(
    $contractAgreementUpdateInput: ContractAgreementUpdateInput!
    $addressInput: AddressInput
    $files: [Upload!]
  ) {
    contractAgreementUpdate(
      contractAgreementUpdateInput: $contractAgreementUpdateInput
      addressInput: $addressInput
      contractAgreementFiles: $files
    ) {
      id
    }
  }
`;

export const CONTRACTOR_ADDRESS_REVEAL = gql`
  mutation RevealAddress($documentId: Int!, $addressId: Int!) {
    contractAgreementContractorAddressReveal(
      contractAgreementContractorAddressRevealInput: {
        contractAgreementId: $documentId
        contractorAddressId: $addressId
      }
    ) {
      id
    }
  }
`;
