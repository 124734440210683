import { ApolloError, useMutation } from "@apollo/client";
import { useStyletron } from "baseui";
import { KIND } from "baseui/button";
import { ModalBody, ModalFooter, ModalHeader } from "baseui/modal";
import { useSnackbar } from "notistack";
import React from "react";
import { useForm } from "react-hook-form";
import { AlertOctagon } from "tabler-icons-react";

import { InputValidationError } from "../api";
import { DOCUMENTS_UPLOAD } from "../containers/Documents/documents.gql";
import { useAssignment } from "../contexts/assignment-context";
import { useLoading } from "../contexts/loading-context";
import { formValidation } from "../utils/formValidation";
import Button from "./button";
import { ControlledFilesPicker, FilesPickerType } from "./files-picker";
import FormControl from "./form-control";
import Modal from "./modal";

type DocumentsUploadModalProps = {
  caseId: number;
  isOpen: boolean;
  close: () => void;
  refetch: () => void;
  label?: string;
};

export default function DocumentsUploadModal({
  caseId,
  isOpen,
  close,
  refetch,
}: DocumentsUploadModalProps): React.ReactElement {
  const [css] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, setIsLoading } = useLoading();

  const { assignment, isAssignmentActive } = useAssignment();

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<{ file: File[] }>();

  const [uploadDocuments, { error, loading }] = useMutation(DOCUMENTS_UPLOAD, {
    ...(isAssignmentActive &&
      assignment && {
        context: {
          headers: {
            Assignment: assignment.id,
          },
        },
      }),
  });

  const onDocumentsUpload = async ({ file }: { file: File[] }) => {
    try {
      await uploadDocuments({
        variables: {
          documentUploadInput: {
            caseId,
          },
          documentFile: file && file[0],
        },
      });

      enqueueSnackbar({
        message: "Wgrano pomyślnie",
        variant: "success",
      });
      refetch();
      close();
      reset();
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={close}>
      <form onSubmit={handleSubmit(onDocumentsUpload)}>
        <ModalHeader>Wgraj dokument do sprawy</ModalHeader>

        <ModalBody>
          <FormControl
            error={
              ((errors as any)["file"] && (errors as any)["file"].message) ||
              (error &&
                error.graphQLErrors[0]?.extensions?.code ===
                  "INPUT_VALIDATION_ERROR" &&
                error.graphQLErrors[0]?.extensions?.validationErrors
                  ?.find((vE: InputValidationError) => vE?.property === "file")
                  ?.errors.map((message: string) => (
                    <div
                      key="error"
                      className={css({
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      })}
                    >
                      {message}
                      <span
                        className={css({
                          color: "#999",
                          marginLeft: "auto",
                          marginRight: "5px",
                        })}
                      >
                        Walidacja serwera
                      </span>
                      <AlertOctagon color="#999" size={12} />
                    </div>
                  ))[0])
            }
            disabled={isLoading}
          >
            <ControlledFilesPicker
              control={control}
              name={"file"}
              filesPickerType={FilesPickerType.SingleFile}
              multiple={false}
              loading={loading}
              rules={{
                required: formValidation.messages.required,
              }}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            kind={KIND.secondary}
            $style={{ marginRight: "10px" }}
            onClick={close}
            disabled={loading}
          >
            Anuluj
          </Button>

          <Button disabled={loading} isLoading={loading} type="submit">
            Wgraj
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
