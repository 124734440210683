import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { KIND } from "baseui/button";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { DeviceFloppy, FileOff } from "tabler-icons-react";

import ConfirmDialog from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import FormRenderer from "../../../components/form-renderer";
import Header from "../../../components/header";
import { useLoading } from "../../../contexts/loading-context";
import { checkPermission } from "../../../utils/check-permission";
import { PERMISSIONS } from "../../../utils/permissions";
import { scrollOnError } from "../../../utils/scrollOnError";
import { EditFormInputs, ROLES_FIELDS } from "../roles.form";
import { ROLES_EDIT, ROLES_SHOW } from "../roles.gql";

export default function RolesEdit(): React.ReactElement {
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const [errorTimeStamp, setErrorTimeStamp] = useState<number>();

  const { enqueueSnackbar } = useSnackbar();
  const {
    control,
    formState: { errors, isDirty, ...formState },
    handleSubmit,
    setValue,
    ...methods
  } = useForm<EditFormInputs>({
    defaultValues: {
      name: "",
      description: "",
    },
  });

  const history = useHistory();
  const { id } = useParams<{ id?: string }>();

  const { refetch, data, error: queryError } = useQuery(ROLES_SHOW, {
    variables: { id: id ? parseInt(id) : null },
  });
  const [editRole, { error }] = useMutation(ROLES_EDIT);

  const { setIsFetching, isLoading, setIsLoading } = useLoading();

  const onError = (errors: Record<string, unknown>, e: any) =>
    setErrorTimeStamp(e.timeStamp);

  const onSubmit = async (values: EditFormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      await editRole({
        variables: {
          id: id ? parseInt(id) : null,
          ...values,
        },
      });

      enqueueSnackbar({
        message: "Zapisano pomyślnie",
        variant: "success",
      });
      history.push(`/roles/${id}`);
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (data?.role) refetch();
    setIsFetching(true);
    setValue("permissionIds", undefined);
  }, []);

  useEffect(() => {
    if (data && data.role) {
      setValue("name", data.role.name);
      setValue("description", data.role.description);
      setValue(
        "permissionIds",
        data.role.permissions.map(
          (permission: Record<string, any>) => permission.id
        )
      );
      setIsFetching(false);
    }
  }, [data]);

  useEffect(() => {
    if (queryError?.graphQLErrors)
      enqueueSnackbar({
        message: (queryError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [queryError]);

  useEffect(() => {
    if (errors) scrollOnError(errors, ROLES_FIELDS, "edit");
  }, [errorTimeStamp]);

  if (!checkPermission(PERMISSIONS.role.update)) return <Redirect to="/" />;

  return (
    <article>
      <Header
        title={data?.role?.name}
        labels={["Role", "Edytowanie"]}
        goBackOption
        buttons={[
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              isDirty ? setIsCancelConfirmDialogOpen(true) : history.goBack(),
          },
          {
            label: "Zapisz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            onClick: handleSubmit(onSubmit, onError),
            disabled: isLoading,
            isLoading: isLoading,
            type: "submit",
            formId: "editRole",
          },
        ]}
      />
      <Content>
        <FormProvider<EditFormInputs>
          control={control}
          formState={{ errors, isDirty, ...formState }}
          handleSubmit={handleSubmit}
          setValue={setValue}
          {...methods}
        >
          <FormRenderer
            id="editRole"
            onSubmit={handleSubmit(onSubmit)}
            type="edit"
            isLoading={isLoading}
            error={error}
            fields={ROLES_FIELDS}
          />
        </FormProvider>

        <ConfirmDialog
          isOpen={isCancelConfirmDialogOpen}
          label="Anulowanie edycji roli"
          close={() => setIsCancelConfirmDialogOpen(false)}
          confirm={() => history.goBack()}
        />
      </Content>
    </article>
  );
}
