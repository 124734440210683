import { useStyletron } from "baseui";
import React from "react";

import PageSizeControl from "./page-size-control";
import Pagination from "./pagination";
import ScrollUpButton from "./scroll-up-button";

export default function PagingControls(): React.ReactElement {
  const [css] = useStyletron();

  return (
    <div
      className={css({
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      })}
      data-ui="table-foot"
    >
      <PageSizeControl />
      <ScrollUpButton />
      <Pagination />
    </div>
  );
}
