import { ApolloError, useMutation } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { MouseEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { AlertOctagon, DeviceFloppy, FileOff, Note } from "tabler-icons-react";

import { InputValidationError } from "../../../api";
import Cell from "../../../components/cell";
import ConfirmDialog from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import { ControlledDatePicker } from "../../../components/date-picker";
import FormControl from "../../../components/form-control";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import { ControlledInput } from "../../../components/input";
import {
  ControlledCasesSelect,
  ControlledPaymentTypesSelect,
} from "../../../components/select";
import { ControlledTransferTablesComposer } from "../../../components/transfer-tables-composer";
import { useAuth } from "../../../contexts/auth-context";
import { BudgetsProvider } from "../../../contexts/budgets-context";
import { useDictionaries } from "../../../contexts/dictionaries-context";
import { useLoading } from "../../../contexts/loading-context";
import { FIELDS } from "../../../fields.d";
import { checkPermission } from "../../../utils/check-permission";
import { formValidation } from "../../../utils/formValidation";
import { PERMISSIONS } from "../../../utils/permissions";
import { Currency } from "../../Currencies/currencies";
import { FormInputs, PAYMENTS_FIELDS } from "../payments.form";
import { PAYMENTS_CREATE } from "../payments.gql";

export default function PaymentsCreate(): React.ReactElement {
  const [css, theme] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const [isClearConfirmDialogOpen, setIsClearConfirmDialogOpen] = useState(
    false
  );
  const [isSaveConfirmDialogOpen, setIsSaveConfirmDialogOpen] = useState(false);
  const history = useHistory();
  const { isLoading, setIsLoading, isFetching } = useLoading();
  const { findValue } = useDictionaries();
  const { user } = useAuth();

  const location = useLocation();
  const locationState = location.state as {
    document: {
      organizationalUnit: {
        id: number;
        name: string;
      };
      id: number;
      grossValue: number;
      case?: {
        id: number;
      };
      paymentAt?: string;
      paymentType?: string;
      documentDate?: string;
      internalNumber: string;
      description: string;
      title: string;
      programName: string;
      invoiceType?: string;
      documentRoot?: {
        legalAct?: string;
      };
      currency?: Currency;
    };
  };

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    reset,
    watch,
  } = useForm<FormInputs>({
    defaultValues: {
      paymentTypes: locationState?.document?.paymentType
        ? [{ id: locationState?.document?.paymentType }]
        : undefined,
      nrRejestr: "",
      paymentItems: [],
    },
  });

  const [focusedInput, setFocusedInput] = useState<keyof FormInputs>();

  const watchTable = watch("paymentItems");

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (focusedInput !== name && type) {
        setFocusedInput(name as keyof FormInputs);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, focusedInput]);

  const totalEnteredTablesAmount = watchTable
    ? parseFloat(
        watchTable
          ?.reduce((a, b) => a + (b.amount ? b.amount : 0), 0)
          ?.toFixed(2)
      )
    : 0;

  const legalAct = locationState?.document?.documentRoot?.legalAct
    ? findValue(locationState?.document?.documentRoot?.legalAct)
    : "";

  const invoiceAmount = locationState?.document?.grossValue;
  const invoiceBalance = invoiceAmount - totalEnteredTablesAmount;
  const shouldShowAlertBeforeSave = invoiceBalance !== 0;
  const [createPayment, { error }] = useMutation(PAYMENTS_CREATE);

  const onSubmit = async ({
    paymentTypes,
    nrRejestr,
    paymentItems,
    sequenceNumber,
  }: FormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      const response = await createPayment({
        variables: {
          paymentCreateInput: {
            userId: user?.id,
            sequenceNumber,
            documentType: "transferOrder",
            documentKind: "Internal",
            caseId: locationState?.document?.case?.id,
            organizationalUnitId:
              locationState?.document?.organizationalUnit?.id,
            paymentType: paymentTypes && paymentTypes[0]?.id,
            issuedOn: new Date(),
            paymentDeadline: locationState?.document?.paymentAt,
            nrRejestr: nrRejestr || null,
            financialAccountingDocumentId: locationState?.document?.id,
            amount: locationState?.document?.grossValue,
            substantivelyAcceptedById: 9,
            controllingAcceptedById: 9,
            accountingAcceptedById: 9,
            payoutAcceptedById: 9,
          },
          paymentItemInputs: paymentItems,
        },
      });

      enqueueSnackbar({
        message: "Utworzono pomyślnie",
        variant: "success",
      });

      history.push("/payments/" + response?.data?.paymentCreate?.id);
    } catch (error: unknown) {
      enqueueSnackbar({
        message:
          (error as ApolloError).graphQLErrors?.map(
            ({ message }) => message
          )[0] || (error as ApolloError).message,
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (!checkPermission(PERMISSIONS.payments.create) || !locationState)
    return <Redirect to="/" />;

  return (
    <article>
      <Header
        title="Nowe polecenie płatności"
        labels={["Polecenia płatności", "Tworzenie"]}
        goBackOption
        buttons={[
          {
            label: "Wyczyść",
            kind: KIND.secondary,
            startEnhancer: <Note size={18} />,
            disabled: isLoading,
            onClick: () => {
              setIsClearConfirmDialogOpen(true);
            },
          },
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              isDirty ? setIsCancelConfirmDialogOpen(true) : history.goBack(),
          },
          {
            label: "Utwórz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            onClick: shouldShowAlertBeforeSave
              ? (event: MouseEvent) => {
                  event.preventDefault();
                  setIsSaveConfirmDialogOpen(true);
                }
              : handleSubmit(onSubmit),
            disabled: isLoading,
            isLoading: isLoading,
            type: shouldShowAlertBeforeSave ? "button" : "submit",
            ...(shouldShowAlertBeforeSave && {
              formId: "createPayment",
            }),
          },
        ]}
      />
      <Content>
        <form
          id="createPayment"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          onFocus={() => {
            const inputName =
              typeof window !== "undefined" &&
              (window?.document?.activeElement?.id as keyof FormInputs);

            inputName &&
              inputName !== focusedInput &&
              !inputName.includes("bui") &&
              setFocusedInput(inputName);
          }}
        >
          <Grid>
            {locationState.document && (
              <>
                <Cell span={12}>
                  <LabelMedium marginBottom="scale200" marginTop="scale600">
                    Dokument
                  </LabelMedium>
                  <hr
                    className={css({
                      borderWidth: "0px",
                      height: "1px",
                      backgroundColor: "#eee",
                    })}
                  />
                </Cell>
                <Cell span={3}>
                  <FormControl label="Numer" disabled={true}>
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : (
                      <FormattedValue
                        dataType="model:documents"
                        data={`incoming/FinancialAccountingDocument/${locationState?.document?.id}`}
                      >
                        {locationState?.document?.internalNumber}
                      </FormattedValue>
                    )}
                  </FormControl>
                </Cell>
                <Cell span={3}>
                  <FormControl label="Jednostka organizacyjna" disabled={true}>
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : (
                      <FormattedValue
                        dataType="model:organizational-units"
                        data={locationState?.document?.organizationalUnit?.id?.toString()}
                      >
                        {locationState?.document?.organizationalUnit?.name}
                      </FormattedValue>
                    )}
                  </FormControl>
                </Cell>
                <Cell span={3}>
                  <FormControl label="Data wystawienia" disabled={true}>
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : (
                      <FormattedValue dataType="date">
                        {locationState?.document?.documentDate}
                      </FormattedValue>
                    )}
                  </FormControl>
                </Cell>
                <Cell span={3}>
                  <FormControl label="Termin płatności" disabled={true}>
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : (
                      <FormattedValue dataType="date">
                        {locationState?.document?.paymentAt}
                      </FormattedValue>
                    )}
                  </FormControl>
                </Cell>
                <Cell span={3}>
                  <FormControl label="Kwota" disabled={true}>
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : (
                      <FormattedValue
                        dataType="quota"
                        currency={locationState?.document?.currency?.code}
                      >
                        {locationState?.document?.grossValue}
                      </FormattedValue>
                    )}
                  </FormControl>
                </Cell>
                {/* <Cell span={3}>
                  <FormControl label="Typ faktury" disabled={true}>
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : (
                      <FormattedValue>{invoiceType || ""}</FormattedValue>
                    )}
                  </FormControl>
                </Cell> */}
                <Cell span={9}>
                  <FormControl
                    label="Podstawa prawna z wniosku"
                    disabled={true}
                  >
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : (
                      <FormattedValue>{legalAct || ""}</FormattedValue>
                    )}
                  </FormControl>
                </Cell>
                <Cell span={3}>
                  <FormControl label="Temat" disabled={true}>
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : (
                      <FormattedValue>
                        {locationState?.document?.title}
                      </FormattedValue>
                    )}
                  </FormControl>
                </Cell>
                <Cell span={3}>
                  <FormControl label="Program " disabled={true}>
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : (
                      <FormattedValue>
                        {locationState?.document?.programName}
                      </FormattedValue>
                    )}
                  </FormControl>
                </Cell>
                <Cell span={6}>
                  <FormControl label="Opis " disabled={true}>
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : (
                      <FormattedValue>
                        {locationState?.document?.description}
                      </FormattedValue>
                    )}
                  </FormControl>
                </Cell>
              </>
            )}
            {PAYMENTS_FIELDS.filter(
              (g) => g.fields.filter((f) => f.create.visible).length > 0
            ).map((group) => [
              group.label && (
                <Cell
                  key={group.id + `-group`}
                  span={12}
                  $style={{
                    position: "sticky",
                    top: "72px",
                    backgroundColor: "white",
                    zIndex: 1,
                  }}
                >
                  <Block
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <LabelMedium marginBottom="scale200" marginTop="scale600">
                      {group.label}
                    </LabelMedium>
                    <Block display="flex" justifyContent="flex-end">
                      {group.id === "bankAccount" && (
                        <LabelMedium
                          marginBottom="scale200"
                          marginTop="scale600"
                          $style={{
                            color:
                              invoiceBalance !== 0
                                ? theme.colors.negative
                                : theme.colors.positive,
                          }}
                        >
                          {`Kwota: ${invoiceAmount
                            ?.toFixed(2)
                            .replace(/\./g, ",")} ${
                            locationState?.document?.currency?.code || "PLN"
                          },  ${
                            invoiceBalance === 0
                              ? "wykorzystano całą kwotę"
                              : invoiceBalance > 0
                              ? "pozostało " +
                                invoiceBalance?.toFixed(2).replace(/\./g, ",") +
                                ` ${
                                  locationState?.document?.currency?.code ||
                                  "PLN"
                                }`
                              : invoiceBalance < 0
                              ? "przekroczono o " +
                                Math.abs(
                                  invoiceAmount - totalEnteredTablesAmount
                                )?.toFixed(2) +
                                ` ${
                                  locationState?.document?.currency?.code ||
                                  "PLN"
                                }`
                              : ""
                          }`}
                        </LabelMedium>
                      )}
                    </Block>
                  </Block>
                  <hr
                    className={css({
                      borderWidth: "0px",
                      height: "1px",
                      backgroundColor: "#eee",
                    })}
                  />
                </Cell>
              ),
              group.fields
                .filter((f) => f.create.visible)
                .map((item, index) => (
                  <Cell span={item.span || 6} key={group.id + `-field` + index}>
                    <FormControl
                      label={item.label}
                      required={item.create.required}
                      caption={item?.caption}
                      error={
                        ((errors as any)[item.id] &&
                          (errors as any)[item.id].message) ||
                        (error &&
                          error.graphQLErrors[0]?.extensions?.code ===
                            "INPUT_VALIDATION_ERROR" &&
                          error.graphQLErrors[0]?.extensions?.validationErrors
                            ?.find(
                              (vE: InputValidationError) =>
                                vE?.property === item.id
                            )
                            ?.errors.map((message: string) => (
                              <div
                                key="error"
                                className={css({
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                })}
                              >
                                {message}
                                <span
                                  className={css({
                                    color: "#999",
                                    marginLeft: "auto",
                                    marginRight: "5px",
                                  })}
                                >
                                  Walidacja serwera
                                </span>
                                <AlertOctagon color="#999" size={12} />
                              </div>
                            ))[0])
                      }
                      disabled={isLoading}
                    >
                      {item.type === FIELDS.PaymentTypesSelect ? (
                        <ControlledPaymentTypesSelect
                          control={control}
                          name={item.id}
                          placeholder={item.placeholder}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.CasesSelect ? (
                        <ControlledCasesSelect
                          control={control}
                          name={item.id}
                          placeholder={item.placeholder}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.DatePicker ? (
                        <ControlledDatePicker
                          name={item.id}
                          control={control}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.TransferTable ? (
                        <BudgetsProvider>
                          <ControlledTransferTablesComposer
                            control={control}
                            name={item.id}
                            id={item.id}
                            totalAmount={locationState.document.grossValue}
                            organizationalUnitId={
                              locationState?.document?.organizationalUnit?.id
                            }
                            hasFocus={focusedInput === item.id}
                            currency={locationState?.document?.currency}
                          />
                        </BudgetsProvider>
                      ) : (
                        <ControlledInput
                          control={control}
                          name={item.id}
                          placeholder={item.placeholder}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                          endEnhancer={item?.endEnhancer}
                          disabled={
                            item.id === "sequenceNumber" &&
                            !checkPermission(
                              PERMISSIONS.document.updateSequenceNumber
                            )
                          }
                        />
                      )}
                    </FormControl>
                  </Cell>
                )),
            ])}
            <ConfirmDialog
              isOpen={isCancelConfirmDialogOpen}
              label="Anulowanie tworzenia nowego polecenie płatności"
              close={() => setIsCancelConfirmDialogOpen(false)}
              confirm={() => history.goBack()}
            />
            <ConfirmDialog
              isOpen={isClearConfirmDialogOpen}
              label="Wyczyszczenie formularza"
              close={() => setIsClearConfirmDialogOpen(false)}
              confirm={() => {
                reset();
                setIsClearConfirmDialogOpen(false);
              }}
            />
            <ConfirmDialog
              isOpen={isSaveConfirmDialogOpen}
              label="Suma przelewów nie jest równa kwocie z dokumentu. Czy na pewno chcesz utworzyć polecenie płatności?"
              close={() => setIsSaveConfirmDialogOpen(false)}
              confirm={handleSubmit(onSubmit)}
            />
          </Grid>
        </form>
      </Content>
    </article>
  );
}
