import { gql } from "@apollo/client";

export const USERS_PAGINATED_INDEX = gql`
  query(
    $pageSize: Int
    $offset: Int
    $filter: UserFilter
    $sorting: [UserSort!]
  ) {
    usersPaginated(
      paging: { limit: $pageSize, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        firstName
        lastName
        position
        email
        loginAD
        createdAt
        updatedAt
        lastLoginAt
        isBlockedByAdmin
        roles {
          id
          name
        }
      }
    }
  }
`;

export const USERS_SELECT_INDEX = gql`
  query($offset: Int, $filter: UserFilter, $sorting: [UserSort!]) {
    usersPaginated(
      paging: { limit: 5, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        firstName
        lastName
        email
      }
    }
  }
`;

export const MY_ORGANIZATIONAL_UNITS_USERS = gql`
  query($offset: Int, $filter: UserFilter, $sorting: [UserSort!]) {
    myOrganizationalUnitsUsers(
      paging: { limit: 5, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        firstName
        lastName
      }
    }
  }
`;

export const USERS_CREATE = gql`
  mutation(
    $firstName: String!
    $lastName: String!
    $email: String!
    $loginAD: String
    $password: String
    $roleIds: [Int!]
  ) {
    userCreate(
      userCreateInput: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        loginAD: $loginAD
        password: $password
        roleIds: $roleIds
      }
    ) {
      id
      createdAt
    }
  }
`;

export const USERS_EDIT = gql`
  mutation EditUser(
    $id: Int!
    $firstName: String!
    $lastName: String!
    $email: String!
    $loginAD: String
    $password: String
    $position: String
    $roleIds: [Int!]
  ) {
    userUpdate(
      userUpdateInput: {
        id: $id
        firstName: $firstName
        lastName: $lastName
        email: $email
        loginAD: $loginAD
        password: $password
        roleIds: $roleIds
        position: $position
      }
    ) {
      id
      createdAt
    }
  }
`;

export const USERS_SHOW = gql`
  query($id: Int!) {
    user(id: $id) {
      id
      firstName
      lastName
      email
      loginAD
      createdAt
      updatedAt
      lastActivityAt
      lastLoginAt
      isBlockedByAdmin
      isBlockedByAD
      position
      hasEmailNotificationsEnabled
      permissions {
        id
      }
      roles {
        id
        name
      }
      organizationalUnitToUsers {
        organizationalUnit {
          id
          name
          symbol
        }
        position {
          id
          name
          role {
            id
            name
          }
        }
        user {
          id
        }
      }
    }
  }
`;

export const USERS_SHOW_ORGANIZATIONAL_UNITS = gql`
  query($id: Int!) {
    user(id: $id) {
      id
      firstName
      lastName
      organizationalUnitToUsers {
        organizationalUnit {
          id
          name
          symbol
          budgetSymbol
          groups
        }
      }
    }
  }
`;

export const USERS_MANAGE_BLOCK = gql`
  mutation ManageBlock($id: Int!, $isBlocked: Boolean!) {
    userBlockByAdmin(userBlockInput: { id: $id, isBlocked: $isBlocked }) {
      id
      isBlockedByAdmin
    }
  }
`;

export const USERS_SYNC_WITH_AD = gql`
  mutation {
    activeDirectoryUsersSynchronizationRequest
  }
`;
