import React from "react";
import {
  Redirect,
  Route as RouterRoute,
  RouteProps,
  useLocation,
} from "react-router-dom";

import { useAuth } from "../contexts/auth-context";

type Props = {
  component: (() => React.ReactElement) | React.Component;
  $private?: boolean;
  $shared?: boolean;
  key?: string;
} & RouteProps;

const Route = ({
  component: Component,
  $private,
  $shared,
  key,
  ...props
}: Props): React.ReactElement => {
  const { isAuthenticated } = useAuth();

  const { key: locationKey } = useLocation();

  return (
    <RouterRoute
      key={`${key}---${locationKey}`}
      {...props}
      render={(props) =>
        $shared ? (
          <Component {...props} />
        ) : $private ? (
          isAuthenticated ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          )
        ) : isAuthenticated ? (
          <Redirect to={"/"} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default Route;
