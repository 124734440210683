import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import React, { useEffect, useMemo } from "react";
import { Row } from "react-table";
import { Lock, Pencil } from "tabler-icons-react";

import Cell from "../../components/cell";
import Content from "../../components/content";
import FormControl from "../../components/form-control";
import FormattedValue from "../../components/formatted-value";
import Grid from "../../components/grid";
import Header from "../../components/header";
import Table from "../../components/table";
import { useAuth } from "../../contexts/auth-context";
import { useLoading } from "../../contexts/loading-context";
import { useSupport } from "../../contexts/support-context";
import { Role } from "../Roles/roles";
import { OrganizationalUnitToUser } from "../Users/users";

export default function MyAccount(): React.ReactElement {
  const [css, theme] = useStyletron();

  const { isFetching } = useLoading();
  const { user, refetch } = useAuth();
  const { openSupportModal } = useSupport();

  const columns = useMemo(
    () => [
      {
        Header: "Jednostka organizacyjna",
        id: "unit",
        Cell: ({ row }: { row: Row<OrganizationalUnitToUser> }) => (
          <FormattedValue
            dataType="model:organizational-units"
            data={row?.original?.organizationalUnit?.id}
          >
            {row?.original?.organizationalUnit?.name}
          </FormattedValue>
        ),
      },
      {
        Header: "Stanowisko",
        id: "position",
        Cell: ({ row }: { row: Row<OrganizationalUnitToUser> }) => (
          <FormattedValue
            dataType="model:positions"
            data={row?.original?.position?.id}
          >
            {row?.original?.position?.name}
          </FormattedValue>
        ),
      },
    ],
    []
  );

  useEffect(refetch, []);

  return (
    <article>
      <Header
        title={"Moje konto"}
        actions={[
          {
            label: "Zmień dane",
            icon: Pencil,
            onClick: openSupportModal,
          },
          { label: "Zmień hasło", icon: Lock, onClick: openSupportModal },
        ]}
      />
      <Content>
        <Grid>
          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Dane
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={6}>
            <FormControl label={"Imię i nazwisko"}>
              {isFetching ? (
                <Skeleton rows={0} height="20px" width="100%" animation />
              ) : (
                <span>
                  {user?.firstName} {user?.lastName}
                </span>
              )}
            </FormControl>
          </Cell>
          <Cell span={6}>
            <FormControl label={"Adres e-mail"}>
              {isFetching ? (
                <Skeleton rows={0} height="20px" width="100%" animation />
              ) : (
                <FormattedValue dataType={"email"}>
                  {user?.email}
                </FormattedValue>
              )}
            </FormControl>
          </Cell>
          <Cell span={6}>
            <FormControl label={"Login AD"}>
              {isFetching ? (
                <Skeleton rows={0} height="20px" width="100%" animation />
              ) : (
                <span>{user?.loginAD}</span>
              )}
            </FormControl>
          </Cell>
          <Cell span={6}>
            <FormControl label={"Stanowisko"}>
              {isFetching ? (
                <Skeleton rows={0} height="20px" width="100%" animation />
              ) : (
                <FormattedValue>{user?.position}</FormattedValue>
              )}
            </FormControl>
          </Cell>
          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Role i uprawnienia
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={12}>
            <FormControl label={"Role"}>
              {isFetching ? (
                <Skeleton rows={0} height="20px" width="100%" animation />
              ) : (
                <>
                  {user?.roles?.map(({ id, name }: Role) => (
                    <FormattedValue
                      key={`role-` + id}
                      dataType={"model:roles"}
                      data={id}
                      $style={{ marginRight: theme.sizing.scale600 }}
                    >
                      {name}
                    </FormattedValue>
                  ))}
                </>
              )}
            </FormControl>
          </Cell>

          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Relacje
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={12}>
            {isFetching ? (
              <Skeleton rows={0} height="200px" width="100%" animation />
            ) : (
              user?.organizationalUnitToUsers && (
                <Block paddingTop="scale200" paddingBottom="scale600">
                  <Table<OrganizationalUnitToUser>
                    compact
                    columns={columns}
                    data={user?.organizationalUnitToUsers}
                  />
                </Block>
              )
            )}
          </Cell>
        </Grid>
      </Content>
    </article>
  );
}
