import { ApolloError, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { StyledLink } from "baseui/link";
import { useSnackbar } from "notistack";
import React, { MouseEvent, useEffect, useMemo, useState } from "react";
import { Redirect, useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { Row } from "react-table";
import { Eye, Pencil, Plus } from "tabler-icons-react";

import BottomPanel from "../../../components/bottom-panel";
import Button from "../../../components/button";
import Cell from "../../../components/cell";
import Content from "../../../components/content";
import Filters from "../../../components/filters";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import PagingControls from "../../../components/paging-controls";
import SortingTableHeader, {
  SortDirection,
} from "../../../components/sorting-table-header";
import Table from "../../../components/table";
import { useDictionaries } from "../../../contexts/dictionaries-context";
import { useLoading } from "../../../contexts/loading-context";
import { usePaging } from "../../../contexts/paging-context";
import { FiltersState } from "../../../filters";
import { checkPermission } from "../../../utils/check-permission";
import { translateFiltersState } from "../../../utils/filters";
import { PERMISSIONS } from "../../../utils/permissions";
import { setSortingParams } from "../../../utils/sorting";
import { ShipmentContract } from "../shipment-contracts";
import { SHIPMENT_CONTRACTS_FILTERS } from "../shipment-contracts.filters";
import { SHIPMENT_CONTRACTS_INDEX } from "../shipment-contracts.gql";

enum FieldName {
  Name = "name",
  IsActive = "isActive",
}

export default function ShipmentContractsIndex(): React.ReactElement {
  const { pageSize, currentPage, setTotalCount } = usePaging();
  const { search } = useLocation();

  const params = useMemo(() => new URLSearchParams(search), [search]);

  const [sortBy, setSortBy] = useState<FieldName | null>(
    (params.get("sortBy") as FieldName) || FieldName.Name
  );

  const [sortDirection, setSortDirection] = useState<SortDirection | null>(
    (params.get("sortDirection") as SortDirection) || SortDirection.ASC
  );

  const [css] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const {
    isFetching,
    setIsFetching,
    isPartialFetching,
    setIsPartialFetching,
  } = useLoading();
  const { findValue } = useDictionaries();
  const [filters, setFilters] = useState<FiltersState>();

  const handleSorting = (column: FieldName) => {
    setSortingParams(history, search, column, sortDirection);
    setSortBy(column);
    setSortDirection(
      sortDirection === null
        ? SortDirection.DESC
        : sortDirection === SortDirection.ASC
        ? SortDirection.DESC
        : SortDirection.ASC
    );
  };

  const { data, error, loading, refetch } = useQuery(SHIPMENT_CONTRACTS_INDEX, {
    variables: {
      pageSize,
      offset: (currentPage - 1) * pageSize,
      sorting: {
        field: sortBy,
        direction: sortDirection,
      },
      ...(filters && { filter: translateFiltersState(filters) }),
    },
  });

  useEffect(() => {
    if (data?.shipmentContracts) setTimeout(() => refetch(), 200);
    setIsFetching(true);
  }, []);

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    setTimeout(() => refetch(), 200);
    setIsPartialFetching(true);
  }, [currentPage, pageSize]);

  useEffect(() => {
    setTimeout(() => refetch(), 200);
    setIsPartialFetching(true);
  }, [sortBy, sortDirection]);

  useEffect(() => {
    if (data?.shipmentContracts) setIsFetching(false);
    if (data?.shipmentContracts?.totalCount >= 0)
      setTotalCount(data?.shipmentContracts?.totalCount);
  }, [data]);

  const columns = React.useMemo(
    () => [
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.Name)}
            sortDirection={sortBy === FieldName.Name ? sortDirection : null}
          >
            Nazwa
          </SortingTableHeader>
        ),
        accessor: "name",
        Cell: ({ row }: { row: Row<ShipmentContract> }) => (
          <StyledLink
            onClick={(event: MouseEvent) => {
              event.preventDefault();
              history.push(`/shipment-contracts/${row.original.id}`);
            }}
            href={`/shipment-contracts/${row.original.id}`}
          >
            {row.original.name}
          </StyledLink>
        ),
      },
      {
        Header: "Umowa",
        accessor: "contractAgreement",
        Cell: ({ row }: { row: Row<ShipmentContract> }) => {
          const documentType = row?.original?.contractAgreement?.documentType
            ? `${findValue(row?.original?.contractAgreement?.documentType)} [${
                row?.original?.contractAgreement?.internalNumber
              }]`
            : "";

          return (
            <FormattedValue
              dataType="model:documents"
              data={`incoming/ContractAgreement/${row?.original?.contractAgreement?.id}`}
            >
              {documentType as string}
            </FormattedValue>
          );
        },
      },
      {
        Header: (
          <Block display="flex" justifyContent="flex-end">
            Okres obowiązywania
          </Block>
        ),
        id: "validityPeriod",
        Cell: ({ row }: { row: Row<ShipmentContract> }) => (
          <Block display="flex" justifyContent="flex-end">
            <FormattedValue dataType="date">
              {`${row.original.beginAt}`}
            </FormattedValue>
            {" - "}
            <FormattedValue dataType="date">
              {`${row.original.endAt}`}
            </FormattedValue>
          </Block>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.IsActive)}
            sortDirection={sortBy === FieldName.IsActive ? sortDirection : null}
          >
            Aktywność
          </SortingTableHeader>
        ),
        accessor: "isActive",
        Cell: ({ row }: { row: Row<ShipmentContract> }) => (
          <FormattedValue dataType="boolean">
            {row?.original?.isActive}
          </FormattedValue>
        ),
      },
      {
        id: "actions",
        Cell: ({ row }: { row: Row<ShipmentContract> }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            {checkPermission(PERMISSIONS.shipmentContract.update) && (
              <Button
                kind={KIND.secondary}
                size={SIZE.mini}
                onClick={() =>
                  history.push(
                    `/shipment-contracts/${row.original.id}/edit-contract`
                  )
                }
                startEnhancer={<Pencil size={14} />}
              />
            )}
            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              onClick={() =>
                history.push(`/shipment-contracts/${row.original.id}`)
              }
              $style={{ marginLeft: "6px" }}
              startEnhancer={<Eye size={14} />}
            />
          </div>
        ),
      },
    ],
    [sortBy, sortDirection, data, filters]
  );

  if (!checkPermission(PERMISSIONS.shipmentContract.read))
    return <Redirect to="/" />;

  return (
    <article>
      <Header
        title="Cenniki"
        recordsNum={data?.shipmentContracts?.totalCount}
        labels={["Lista"]}
        buttons={[
          {
            label: "Dodaj cennik",
            kind: KIND.primary,
            startEnhancer: <Plus size={18} />,
            permission: checkPermission(PERMISSIONS.shipmentContract.create),
            onClick: () => history.push("/shipment-contracts/create-contract"),
          },
        ]}
      />
      <Filters
        filters={SHIPMENT_CONTRACTS_FILTERS}
        state={filters}
        setState={setFilters}
      />
      <Content filtersOffset>
        <Grid>
          <Cell span={12} $style={{ position: "relative" }}>
            <Table<ShipmentContract>
              columns={columns}
              data={data?.shipmentContracts?.nodes}
              isLoading={isFetching || isPartialFetching || loading}
              stickLastColumn
            />
          </Cell>

          <Cell span={12}>
            <BottomPanel>
              <PagingControls />
            </BottomPanel>
          </Cell>
        </Grid>
      </Content>
    </article>
  );
}
