import { gql } from "@apollo/client";

export const SUBSTITUTIONS_INDEX = gql`
  query(
    $pageSize: Int
    $offset: Int
    $filter: SubstitutionFilter
    $sorting: [SubstitutionSort!]
  ) {
    substitutions(
      paging: { limit: $pageSize, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        substitutee {
          id
          firstName
          lastName
        }
        substituter {
          id
          firstName
          lastName
        }
        startedAt
        endedAt
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
        updatedAt
        updatedBy {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const SUBSTITUTIONS_CREATE = gql`
  mutation SubstitutionCreate(
    $substitutionCreateInput: SubstitutionCreateInput!
  ) {
    substitutionCreate(substitutionCreateInput: $substitutionCreateInput) {
      id
    }
  }
`;

export const SUBSTITUTIONS_EDIT = gql`
  mutation SubstitutionEdit(
    $substitutionUpdateInput: SubstitutionUpdateInput!
  ) {
    substitutionUpdate(substitutionUpdateInput: $substitutionUpdateInput) {
      id
    }
  }
`;

export const SUBSTITUTIONS_SHOW = gql`
  query($id: Int!) {
    substitution(id: $id) {
      id
      substitutee {
        id
        firstName
        lastName
      }
      substituter {
        id
        firstName
        lastName
      }
      startedAt
      endedAt
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
      updatedAt
      updatedBy {
        id
        firstName
        lastName
      }
    }
  }
`;

export const SUBSTITUTIONS_DELETE = gql`
  mutation SubstitutionDelete(
    $substitutionDeleteInput: SubstitutionDeleteInput!
  ) {
    substitutionDelete(substitutionDeleteInput: $substitutionDeleteInput) {
      id
    }
  }
`;

export const SUBSTITUTION_START = gql`
  mutation($substitutionStartInput: SubstitutionStartInput!) {
    substitutionStart(substitutionStartInput: $substitutionStartInput) {
      substitutionToken
    }
  }
`;
