import { FIELDS, FieldsGroup } from "../../fields.d";

export type FormInputs = {
  id?: number;
  documents?: { id: number; label?: string }[];
  documentId?: number;
  parcelSizes?: { id: string; label?: string }[];
  width: number | string;
  height: number | string;
  length: number | string;
  weight: number | string;
  isCountryService: boolean;
  countryCode?: string;
  countryCodes?: { id: string; label?: string }[];
  shipmentRates?: { id: number; label?: string }[];
  shipmentRateId?: number;
};

export const DOCUMENT_PARCELS_FIELDS: FieldsGroup[] = [
  {
    id: "basicInfo",
    label: "Podstawowe informacje",
    fields: [
      {
        id: "id",
        label: "Id wysyłki",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "documentId",
        label: "Dokument",
        type: FIELDS.Input,
        dataType: "model:documents",
        span: 3,
        show: { visible: true, accessor: ["document", "internalNumber"] },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "documents",
        label: "Dokument",
        type: FIELDS.DocumentsSelect,
        span: 9,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "createdAt",
        label: "Data utworzenia",
        type: FIELDS.Input,
        dataType: "date",
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "countryCode",
        label: "Kraj",
        type: FIELDS.Input,
        dataType: "countryCode",
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "countryCodes",
        label: "Kraj",
        type: FIELDS.DictionaryValuesSelect,
        span: 3,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: false },
      },
    ],
  },
  {
    id: "dimensionsAndWeight",
    label: "Wymiary i waga",
    fields: [
      {
        id: "parcelSizes",
        label: "Rozmiar",
        type: FIELDS.ParcelSizesSelect,
        placeholder: "Wybierz",
        span: 4,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "width",
        label: "Szerokość",
        type: FIELDS.Input,
        dataType: "dimensions",
        span: 2,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "height",
        label: "Wysokość",
        type: FIELDS.Input,
        dataType: "dimensions",
        span: 2,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "length",
        label: "Długość",
        type: FIELDS.Input,
        dataType: "dimensions",
        span: 2,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "weight",
        label: "Waga",
        type: FIELDS.Input,
        dataType: "weight",
        span: 2,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
    ],
  },
  {
    id: "shipmentContract",
    label: "Dane z cennika",
    fields: [
      {
        id: "shipmentContract",
        label: "Cennik",
        type: FIELDS.Input,
        dataType: "model:shipment-contracts",
        span: 6,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "shipmentRate",
        label: "Stawka",
        type: FIELDS.Input,
        dataType: "model:shipment-contracts",
        span: 3,
        show: { visible: true, accessor: ["shipmentRate", "name"] },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "shipmentRates",
        label: "Stawka",
        type: FIELDS.ShipmentRatesSelect,
        span: 6,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
        caption:
          "Domyślnie ustawiana jest najtańsza stawka pasująca do wprowadzonych parametrów. Aby zmienić, rozwiń listę.",
      },
      {
        id: "price",
        label: "Cena",
        type: FIELDS.Input,
        dataType: "price",
        span: 3,
        show: { visible: true, accessor: ["shipmentRate", "price"] },
        create: { visible: false },
        edit: { visible: false },
      },
    ],
  },
];
