import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import {
  AlertOctagon,
  DeviceFloppy,
  Download,
  FileOff,
  Note,
} from "tabler-icons-react";

import { InputValidationError } from "../../../../api";
import Button from "../../../../components/button";
import Cell from "../../../../components/cell";
import { ControlledCheckbox } from "../../../../components/checkbox";
import ConfirmDialog, {
  ConfirmDialogType,
} from "../../../../components/confirm-dialog";
import Content from "../../../../components/content";
import { ControlledDatePicker } from "../../../../components/date-picker";
import DocumentBarcode from "../../../../components/document-barcode";
import FilesList, { SingleFile } from "../../../../components/files-list";
import { ControlledFilesPicker } from "../../../../components/files-picker";
import FormControl from "../../../../components/form-control";
import FormattedValue from "../../../../components/formatted-value";
import Grid from "../../../../components/grid";
import GusDownloadModal from "../../../../components/gus-download-modal";
import Header from "../../../../components/header";
import { ControlledInput } from "../../../../components/input";
import { ControlledInvoiceTable } from "../../../../components/invoice-table";
import {
  ControlledAddressesSelect,
  ControlledBankAccountsSelect,
  ControlledBankAccountTypesSelect,
  ControlledCasesSelect,
  ControlledCurrencyExchangeRatesSelect,
  ControlledCurrencySelect,
  ControlledDictionaryValuesSelect,
  ControlledDocumentsSelect,
  ControlledOrganizationalUnitsSelect,
  ControlledOrganizationalUnitUsersSelect,
  ControlledPaymentTypesSelect,
  ControlledUsersOrganizationalUnitsSelect,
  ControlledUsersSelect,
} from "../../../../components/select";
import { ControlledTextArea } from "../../../../components/text-area";
import Tooltip from "../../../../components/tooltip";
import { HIDDEN_ADDRESS, HIDDEN_FILE } from "../../../../constants";
import { useAssignment } from "../../../../contexts/assignment-context";
import { useAuth } from "../../../../contexts/auth-context";
import { useLoading } from "../../../../contexts/loading-context";
import { FIELDS } from "../../../../fields.d";
import { checkPermission } from "../../../../utils/check-permission";
import { formValidation } from "../../../../utils/formValidation";
import { PERMISSIONS } from "../../../../utils/permissions";
import { scrollOnError } from "../../../../utils/scrollOnError";
import {
  Address,
  BankAccount,
  BankAccountType,
  DefaultAddress,
} from "../../../Addresses/addresses.d";
import {
  ADDRESS_SUGGESTION,
  ADDRESS_SUGGESTIONS,
} from "../../../Addresses/addresses.gql";
import {
  DocumentLink,
  FinancialAccountingDocumentItem,
} from "../../documents.d";
import { DOCUMENTS_SHOW, FILES_REVEAL } from "../../documents.gql";
import {
  FINANCIAL_ACCOUNTING_DOCUMENTS_FIELDS,
  FinancialAccountingDocumentInputs,
} from "../financial-accounting-document.form";
import { FINANCIAL_ACCOUTING_DOCUMENT_CREATE_FROM_DOCUMENT } from "../financial-accounting-document.gql";

export default function DocumentsCreateFinancialAccountingDocument(): React.ReactElement {
  const [
    isAddressRevealConfirmDialogOpen,
    setIsAddressRevealConfirmDialogOpen,
  ] = useState(false);
  const [isAnyAddressRevealed, setIsAnyAddressRevealed] = useState(false);
  const [hasPersonalData, setHasPersonalData] = useState<boolean>();
  const [
    isFilesRevealConfirmDialogOpen,
    setIsFilesRevealConfirmDialogOpen,
  ] = useState(false);
  const [defaultAddress, setDefaultAddress] = useState<DefaultAddress>();
  const [addressFromGus, setAddressFromGus] = useState<Address>();
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const [isClearConfirmDialogOpen, setIsClearConfirmDialogOpen] = useState(
    false
  );
  const [isGusDownloadOpen, setIsGusDownloadOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<Array<number>>([]);
  const [errorTimeStamp, setErrorTimeStamp] = useState<number>();
  const [availableBankAccounts, setAvailableBankAccounts] = useState<
    BankAccount[]
  >();
  const [css, theme] = useStyletron();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { isFetching, isLoading, setIsLoading } = useLoading();
  const location = useLocation();
  const locationState = location.state as {
    documentId: number;
    documentType: string;
    documentKind: string;
    documentInternalNumber: string;
    assignmentId?: number;
  };
  const { user } = useAuth();

  const { assignment, setAssignment, setIsAssignmentActive } = useAssignment();

  const { data, refetch } = useQuery(DOCUMENTS_SHOW, {
    variables: {
      id: locationState?.documentId || null,
    },
    ...((locationState?.assignmentId || assignment?.id) &&
      locationState?.assignmentId !== null && {
        context: {
          headers: {
            Assignment: locationState?.assignmentId || assignment?.id,
          },
        },
      }),
  });

  const hasHiddenFiles =
    locationState &&
    !!data?.document?.files?.length &&
    data?.document?.files?.[0]?.__typename === HIDDEN_FILE;

  const {
    control,
    formState: { errors, dirtyFields },
    handleSubmit,
    reset,
    setValue,
    unregister,
    watch,
  } = useForm<FinancialAccountingDocumentInputs>({
    defaultValues: {
      documentNumber: "",
      description: "",
      title: "",
      programName: "",
      caseName: undefined,
      senderWhenAddress: undefined,
      addressName: "",
      addressStreetName: "",
      addressBuildingNumber: "",
      addressApartmentNumber: "",
      addressMobileNumber: "",
      addressCity: "",
      addressPostalCode: "",
      addressPhoneNumber: "",
      addressEmail: "",
      addressNip: "",
      handDelivered: false,
      organizationalUnit: undefined,
      recipientWhenUser: undefined,
      addressOverwrite: false,
      payeeName: "",
      accountNumber: "",
      bankName: "",
      bankCode: "",
      invoiceTypes: undefined,
      currencies: undefined,
      paymentTypes: undefined,
    },
  });

  const [focusedInput, setFocusedInput] = useState<
    keyof FinancialAccountingDocumentInputs
  >();

  const watchAddress = watch("senderWhenAddress");
  const watchOrganizationalUnit = watch("organizationalUnit");
  const watchHandDelivered = watch("handDelivered");
  const watchRecipientWhenUser = watch("recipientWhenUser");
  const watchNip = watch("addressNip");
  const watchCurrency = watch("currencies");
  const watchBankAccount = watch("addressBankAccount");
  const watchAccountType = watch("accountType");
  const watchGrossValue = watch("grossValue");
  const watchFinancialAccountingDocumentItems = watch(
    "financialAccountingDocumentItems"
  );

  const isSensitiveAddressChoosed =
    watchAddress && watchAddress?.[0]?.typename === HIDDEN_ADDRESS;

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name && type) {
        if (
          [
            "payeeName",
            "accountNumber",
            "bankName",
            "bankCode",
            "accountType",
            "accountCurrency",
          ].includes(name) &&
          watchBankAccount?.[0]?.id !== 0
        ) {
          setValue("addressBankAccount", [{ id: 0 }]);
        }

        if (focusedInput !== name)
          setFocusedInput(name as keyof FinancialAccountingDocumentInputs);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, focusedInput]);

  const { refetch: fetchAddress } = useQuery(ADDRESS_SUGGESTIONS, {
    skip: true,
  });

  const [createInvoiceFromDocument, { error }] = useMutation(
    FINANCIAL_ACCOUTING_DOCUMENT_CREATE_FROM_DOCUMENT,
    {
      ...((locationState?.assignmentId || assignment?.id) &&
        locationState?.assignmentId !== null && {
          context: {
            headers: {
              Assignment: locationState?.assignmentId || assignment?.id,
            },
          },
        }),
    }
  );

  const time = new Date();

  const onError = (errors: Record<string, unknown>, e: any) => {
    setFocusedInput(undefined);
    setErrorTimeStamp(e.timeStamp);
  };

  const onSubmit = async ({
    description,
    title,
    programName,
    parentIds,
    documentDate,
    inflowDate,
    postStampAt,
    senderWhenAddress,
    caseName,
    organizationalUnit,
    documentNumber,
    handDelivered,
    sequenceNumber,
    files,
    addressName,
    addressNip,
    addressCity,
    addressStreetName,
    addressBuildingNumber,
    addressApartmentNumber,
    addressMobileNumber,
    addressPostalCode,
    addressCountryCode,
    addressEmail,
    addressPhoneNumber,
    addressOverwrite,
    addressIsSensitive,
    addressBankAccount,
    paymentAt,
    payeeName,
    accountNumber,
    bankName,
    accountType,
    bankCode,
    financialAccountingDocumentItems,
    invoiceTypes,
    currencies,
    exchangeRates,
    grossValue,
    paymentTypes,
    raksDiaryNumber,
    accountCurrency,
  }: FinancialAccountingDocumentInputs): Promise<void> => {
    setIsLoading(true);

    const enteredAddress = {
      addressName,
      addressNip,
      addressStreetName,
      addressBuildingNumber,
      addressApartmentNumber,
      addressMobileNumber,
      addressCity,
      addressCountryCode,
      addressPostalCode,
      addressPhoneNumber,
      addressEmail,
      addressIsSensitive,
    };

    const shouldSendAddress =
      !!addressName &&
      !!addressStreetName &&
      !!addressBuildingNumber &&
      !!addressCity &&
      !!addressPostalCode &&
      !!addressCountryCode &&
      JSON.stringify(enteredAddress) !== JSON.stringify(defaultAddress);

    try {
      await createInvoiceFromDocument({
        variables: {
          financialAccountingDocumentCreateInput: {
            parentIds: parentIds && parentIds.map(({ id }) => id),
            userId: user?.id,
            documentNumber,
            documentKind: locationState.documentKind,
            documentDate,
            sequenceNumber,
            inflowDate,
            description,
            title,
            programName,
            documentType: locationState.documentType,
            postStampAt,
            handDelivered,
            senderId: senderWhenAddress && senderWhenAddress[0]?.id,
            senderType: "Address",
            caseId: caseName ? caseName[0]?.id : null,
            organizationalUnitId:
              organizationalUnit && organizationalUnit[0]?.id,
            paymentAt,
            paymentType: paymentTypes ? paymentTypes[0]?.id : "Transfer",
            netValue: financialAccountingDocumentItems.reduce(
              (a, b) => a + (b.totalAmount || 0),
              0
            ),
            grossValue: parseFloat(grossValue as any),
            currencyId: currencies?.[0]?.currencyId || null,
            invoiceType: invoiceTypes && invoiceTypes[0]?.id,
            amountInCurrency:
              exchangeRates && currencies && exchangeRates[0]?.id,
            currencyExchangeRateId:
              exchangeRates && currencies && exchangeRates[0]?.id,
            ...(addressBankAccount?.length &&
              addressBankAccount?.[0]?.id !== 0 && {
                bankAccountId: addressBankAccount[0].id,
              }),
            raksDiaryNumber,
            deleteFilesIds: selectedFiles.length ? selectedFiles : null,
            ...(addressBankAccount?.[0]?.id !== 0 && {
              bankAccountId: addressBankAccount?.[0]?.id,
            }),
          },
          files,
          financialAccountingDocumentItemInputs: financialAccountingDocumentItems?.map(
            (item: FinancialAccountingDocumentItem) => ({
              name: item.name,
              description: item.description,
              totalAmount: item.totalAmount,
              totalAmountWithTax: item.totalAmountWithTax,
              requestFormItemId: item.requestFormItemId,
              tableId: item.tableId,
            })
          ),
          documentId: locationState?.documentId,
          ...(shouldSendAddress && {
            addressInput: {
              name: addressName,
              nip: addressNip && addressNip?.replaceAll("-", "").toString(),
              city: addressCity,
              streetName: addressStreetName,
              buildingNumber: addressBuildingNumber,
              apartmentNumber: addressApartmentNumber,
              mobileNumber: addressMobileNumber,
              postalCode: addressPostalCode,
              countryCode: addressCountryCode && addressCountryCode?.[0]?.id,
              email: addressEmail ? addressEmail : null,
              phoneNumber: addressPhoneNumber ? addressPhoneNumber : null,
              overwrite: !!addressOverwrite,
              isSensitive: !!addressIsSensitive,
              ...(addressBankAccount?.[0].id === 0 && {
                bankAccountCreateInput: {
                  payeeName,
                  accountNumber,
                  bankName,
                  accountType: accountType && accountType?.[0]?.id,
                  bankCode: bankCode || null,
                  currency: accountCurrency && accountCurrency?.[0]?.code,
                },
              }),
            },
          }),
          ...(!shouldSendAddress &&
            addressBankAccount?.[0]?.id === 0 && {
              bankAccountCreateInput: {
                payeeName,
                accountNumber,
                bankName,
                accountType: accountType && accountType?.[0]?.id,
                bankCode: bankCode || null,
                currency: accountCurrency && accountCurrency?.[0]?.code,
              },
            }),
        },
      });

      enqueueSnackbar({
        message: "Utworzono pomyślnie",
        variant: "success",
      });
      history.push(
        `/documents/${locationState.documentKind.toLowerCase()}/FinancialAccountingDocument/${
          locationState?.documentId
        }`
      );
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const { refetch: revealAddress, loading: revealAddressLoading } = useQuery(
    ADDRESS_SUGGESTION,
    {
      skip: true,
    }
  );

  const onAddressRevealSubmit = async () => {
    setIsLoading(true);

    try {
      const response = await revealAddress({
        id: watchAddress && watchAddress[0] && watchAddress[0].id,
      });
      const choosedAddress = response?.data?.addressSuggestion;

      if (choosedAddress?.name) setValue("addressName", choosedAddress?.name);
      if (choosedAddress?.isSensitive)
        setValue("addressIsSensitive", choosedAddress?.isSensitive);

      if (choosedAddress?.nip) setValue("addressNip", choosedAddress?.nip);
      if (choosedAddress?.streetName)
        setValue("addressStreetName", choosedAddress?.streetName);
      if (choosedAddress?.buildingNumber)
        setValue("addressBuildingNumber", choosedAddress?.buildingNumber);
      if (choosedAddress?.apartmentNumber)
        setValue("addressApartmentNumber", choosedAddress?.apartmentNumber);
      if (choosedAddress?.mobileNumber)
        setValue("addressMobileNumber", choosedAddress?.mobileNumber);
      if (choosedAddress?.city) setValue("addressCity", choosedAddress?.city);
      if (choosedAddress?.postalCode)
        setValue("addressPostalCode", choosedAddress?.postalCode);
      if (choosedAddress?.countryCode)
        setValue("addressCountryCode", [
          {
            id: choosedAddress?.countryCode,
          },
        ]);
      if (choosedAddress?.phoneNumber)
        setValue("addressPhoneNumber", choosedAddress?.phoneNumber);
      if (choosedAddress?.email)
        setValue("addressEmail", choosedAddress?.email);

      setIsAnyAddressRevealed(true);
      enqueueSnackbar({
        message: "Odkryto pomyślnie",
        variant: "success",
      });

      setIsAddressRevealConfirmDialogOpen(false);
    } catch (error: unknown) {
      setIsAddressRevealConfirmDialogOpen(false);
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const [revealFiles, { loading: revealFilesLoading }] = useMutation(
    FILES_REVEAL,
    {
      ...((locationState?.assignmentId || assignment?.id) &&
        locationState?.assignmentId !== null && {
          context: {
            headers: {
              Assignment: locationState?.assignmentId || assignment?.id,
            },
          },
        }),
    }
  );

  const onFilesRevealSubmit = async () => {
    setIsLoading(true);

    try {
      await revealFiles({
        variables: {
          documentFilesRevealInput: {
            documentId: locationState?.documentId,
          },
        },
      });

      enqueueSnackbar({
        message: "Odkryto pomyślnie",
        variant: "success",
      });

      refetch();
      setIsFilesRevealConfirmDialogOpen(false);
    } catch (error: unknown) {
      setIsFilesRevealConfirmDialogOpen(false);
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (errors)
      scrollOnError(errors, FINANCIAL_ACCOUNTING_DOCUMENTS_FIELDS, "create");
  }, [errorTimeStamp]);

  useEffect(() => {
    if (!watchAddress?.length) {
      setHasPersonalData(false);
      setIsAnyAddressRevealed(false);
      setValue("addressBankAccount", []);
      setAvailableBankAccounts([]);
      unregister("addressStreetName");
      unregister("addressBuildingNumber");
      unregister("addressApartmentNumber");
      unregister("addressMobileNumber");
      unregister("addressPostalCode");
      unregister("addressCity");
      unregister("addressCountryCode");
    }

    setAddressFromGus(undefined);

    async function getAddressData() {
      setValue("addressName", "");
      setValue("addressNip", "");
      setValue("addressStreetName", "");
      setValue("addressBuildingNumber", "");
      setValue("addressApartmentNumber", "");
      setValue("addressMobileNumber", "");
      setValue("addressCity", "");
      setValue("addressPostalCode", "");
      setValue("addressPhoneNumber", "");
      setValue("addressEmail", "");
      setValue("addressIsSensitive", false);
      setValue("payeeName", "");
      setValue("accountNumber", "");
      setValue("bankName", "");
      setValue("bankCode", "");
      setValue("accountType", [{ id: BankAccountType.IBAN }]);
      setValue("accountCurrency", []);

      if (watchAddress && watchAddress[0] && watchAddress[0].id) {
        const response = await fetchAddress({
          filter: {
            id: {
              eq: watchAddress[0].id as number,
            },
          },
        });

        const choosedAddress: Address =
          response?.data?.addressSuggestions?.nodes[0];

        if (choosedAddress?.name) setValue("addressName", choosedAddress?.name);
        if (choosedAddress?.__typename === "HiddenAddress") {
          setValue("addressIsSensitive", true);
          setValue("addressCountryCode", []);
          setHasPersonalData(true);
          setAvailableBankAccounts(choosedAddress?.userBankAccounts);
        }
        if (choosedAddress?.__typename === "Address") {
          setHasPersonalData(false);
          setValue("addressIsSensitive", choosedAddress?.isSensitive);
          setAvailableBankAccounts(choosedAddress?.bankAccounts);
        }

        if (
          (hasPersonalData && isAnyAddressRevealed) ||
          !hasPersonalData ||
          watchAddress?.[0]?.typename !== "HiddenAddress"
        ) {
          if (choosedAddress?.nip) setValue("addressNip", choosedAddress?.nip);
          if (addressFromGus?.streetName)
            setValue("addressStreetName", addressFromGus?.streetName);
          if (choosedAddress?.streetName)
            setValue("addressStreetName", choosedAddress?.streetName);
          if (choosedAddress?.buildingNumber)
            setValue("addressBuildingNumber", choosedAddress?.buildingNumber);
          if (choosedAddress?.apartmentNumber)
            setValue("addressApartmentNumber", choosedAddress?.apartmentNumber);
          if (choosedAddress?.mobileNumber)
            setValue("addressMobileNumber", choosedAddress?.mobileNumber);
          if (choosedAddress?.city)
            setValue("addressCity", choosedAddress?.city);
          if (choosedAddress?.postalCode)
            setValue("addressPostalCode", choosedAddress?.postalCode);
          if (choosedAddress?.countryCode)
            setValue("addressCountryCode", [
              {
                id: choosedAddress?.countryCode,
              },
            ]);
          if (choosedAddress?.phoneNumber)
            setValue("addressPhoneNumber", choosedAddress?.phoneNumber);
          if (choosedAddress?.email)
            setValue("addressEmail", choosedAddress?.email);
          if (choosedAddress?.isSensitive)
            setValue("addressIsSensitive", choosedAddress?.isSensitive);

          if (choosedAddress?.bankAccounts?.length) {
            choosedAddress?.bankAccounts?.[0]?.id &&
              setValue("addressBankAccount", [
                {
                  ...choosedAddress?.bankAccounts?.[0],
                  id: choosedAddress?.bankAccounts?.[0]?.id,
                },
              ]);

            setValue("payeeName", choosedAddress?.bankAccounts?.[0]?.payeeName);
            setValue(
              "accountNumber",
              choosedAddress?.bankAccounts?.[0]?.accountNumber
            );
            setValue("bankName", choosedAddress?.bankAccounts?.[0]?.bankName);
            setValue("bankCode", choosedAddress?.bankAccounts?.[0]?.bankCode);
            choosedAddress?.bankAccounts?.[0]?.accountType &&
              setValue("accountType", [
                { id: choosedAddress?.bankAccounts?.[0]?.accountType },
              ]);

            choosedAddress?.bankAccounts?.[0]?.currency !== "PLN" &&
              setValue("accountCurrency", [
                {
                  code: choosedAddress?.bankAccounts?.[0]?.currency,
                  label: choosedAddress?.bankAccounts?.[0]?.currency,
                },
              ]);
          }
        }

        setDefaultAddress({
          addressName: choosedAddress?.name || "",
          addressNip: choosedAddress?.nip || "",
          addressStreetName: choosedAddress?.streetName || "",
          addressBuildingNumber: choosedAddress?.buildingNumber || "",
          addressApartmentNumber: choosedAddress?.apartmentNumber || "",
          addressMobileNumber: choosedAddress?.mobileNumber || "",
          addressCity: choosedAddress?.city || "",
          addressCountryCode: choosedAddress?.countryCode
            ? [
                {
                  id: choosedAddress?.countryCode,
                },
              ]
            : [],
          addressPostalCode: choosedAddress?.postalCode || "",
          addressPhoneNumber: choosedAddress?.phoneNumber || "",
          addressEmail: choosedAddress?.email || "",
          addressIsSensitive: choosedAddress?.__typename === HIDDEN_ADDRESS,
        });
        unregister("addressName");
        unregister("addressStreetName");
        unregister("addressBuildingNumber");
        unregister("addressApartmentNumber");
        unregister("addressMobileNumber");
        unregister("addressPostalCode");
        unregister("addressCity");
        unregister("addressCountryCode");
      }
    }
    !addressFromGus && getAddressData();
  }, [watchAddress]);

  useEffect(() => {
    setValue("addressCountryCode", [{ id: "PL", label: "Polska (PL)" }]);
    setValue("inflowDate", new Date());
    locationState && refetch();
  }, []);

  useEffect(() => {
    if (watchHandDelivered) setValue("postStampAt", null);
  }, [watchHandDelivered]);

  useEffect(() => {
    if (watchCurrency) setValue("exchangeRates", undefined);
  }, [watchCurrency]);

  useEffect(() => {
    if (addressFromGus) setValue("senderWhenAddress", undefined);
    if (addressFromGus?.nip) setValue("addressNip", addressFromGus?.nip);
    if (addressFromGus?.name) setValue("addressName", addressFromGus?.name);
    if (addressFromGus?.streetName)
      setValue("addressStreetName", addressFromGus?.streetName);
    if (addressFromGus?.buildingNumber)
      setValue("addressBuildingNumber", addressFromGus?.buildingNumber);
    if (addressFromGus?.apartmentNumber)
      setValue("addressApartmentNumber", addressFromGus?.apartmentNumber);
    if (addressFromGus?.mobileNumber)
      setValue("addressMobileNumber", addressFromGus?.mobileNumber);
    if (addressFromGus?.postalCode)
      setValue("addressPostalCode", addressFromGus?.postalCode);
    if (addressFromGus?.city) setValue("addressCity", addressFromGus?.city);
    if (addressFromGus?.countryCode)
      setValue("addressCountryCode", [{ id: addressFromGus?.countryCode }]);
    if (addressFromGus?.phoneNumber)
      setValue("addressPhoneNumber", addressFromGus?.phoneNumber);
    if (addressFromGus?.email) setValue("addressEmail", addressFromGus?.email);
  }, [addressFromGus]);

  useEffect(() => {
    if (data?.document?.currentAssignment) {
      setAssignment(data?.document?.currentAssignment);
      setIsAssignmentActive(true);
    }

    if (data?.document) {
      setValue("documentNumber", data?.document?.documentNumber);
      data?.document?.documentDate &&
        setValue(
          "documentDate",
          new Date(data?.document?.documentDate as Date)
        );
      setValue("description", data?.document?.description);
      setValue("title", data?.document?.title);
      setValue("programName", data?.document?.programName);
      data?.document?.inflowDate &&
        setValue("inflowDate", new Date(data?.document?.inflowDate as Date));
      data?.document?.postStampAt &&
        setValue("postStampAt", new Date(data?.document?.postStampAt as Date));

      if (data?.document?.sender?.__typename !== "User") {
        setValue("senderWhenAddress", [
          {
            id: data?.document?.sender?.id,
            label: `${
              data?.document?.sender?.name ? data?.document?.sender?.name : ""
            } ${
              data?.document?.sender?.address
                ? ", " + data?.document?.sender?.address
                : ""
            } ${
              data?.document?.sender?.city
                ? ", " + data?.document?.sender?.city
                : ""
            }`,
            typename: data?.document?.sender?.__typename,
          },
        ]);

        setAvailableBankAccounts(data?.document?.sender?.bankAccounts);
      }

      setValue("addressIsSensitive", data?.document?.sender?.isSensitive);
      data?.document?.recipientId &&
        setValue("recipientWhenUser", [{ id: data?.document?.recipientId }]);
      if (data?.document?.documentLinks?.length)
        setValue(
          "parentIds",
          data?.document?.documentLinks
            ?.filter(
              ({ type, linkerId }: DocumentLink) =>
                type === "Parent" && parseInt(data.document.id) === linkerId
            )
            .map(({ linked, linker }: DocumentLink) =>
              parseInt(data.document.id) === linker.id ? linked : linker
            )
        );
      setValue("handDelivered", data?.document?.handDelivered);
      setValue("caseName", [{ id: data?.document?.caseId }]);
      if (data?.document?.organizationalUnit?.id)
        setValue("organizationalUnit", [
          {
            id: data?.document?.organizationalUnit?.id,
            ...data?.document?.organizationalUnit,
          },
        ]);
    }
  }, [data]);

  useEffect(() => {
    if (
      watchBankAccount &&
      watchBankAccount?.[0]?.id !== 0 &&
      watchBankAccount?.length !== 0
    ) {
      setValue("payeeName", watchBankAccount?.[0]?.payeeName || "");
      setValue("accountNumber", watchBankAccount?.[0]?.accountNumber || "");
      setValue("bankName", watchBankAccount?.[0]?.bankName || "");
      setValue("bankCode", watchBankAccount[0]?.bankCode || "");
      watchBankAccount?.[0]?.accountType &&
        setValue("accountType", [{ id: watchBankAccount?.[0]?.accountType }]);
      watchBankAccount?.[0]?.currency !== "PLN"
        ? setValue("accountCurrency", [{ id: watchBankAccount?.[0]?.currency }])
        : setValue("accountCurrency", []);
    }

    if (!watchBankAccount?.length) {
      setValue("payeeName", "");
      setValue("accountNumber", "");
      setValue("bankName", "");
      setValue("bankCode", "");
      setValue("accountType", [{ id: BankAccountType.IBAN }]);
      setValue("accountCurrency", []);
    }
  }, [watchBankAccount]);

  useEffect(() => {
    if (availableBankAccounts?.length) {
      setValue("addressBankAccount", [
        { ...availableBankAccounts?.[0], id: availableBankAccounts?.[0]?.id },
      ]);
    } else setValue("addressBankAccount", []);
  }, [availableBankAccounts]);

  if (!checkPermission(PERMISSIONS.document.create)) return <Redirect to="/" />;

  return (
    <article>
      <Header
        title={
          locationState?.documentId
            ? locationState?.documentInternalNumber
              ? locationState?.documentInternalNumber
              : "Uzupełnienie danych finansowo-księgowych"
            : "Nowa faktura"
        }
        labels={[
          locationState?.documentInternalNumber
            ? "Uzupełnienie danych finansowo-księgowych"
            : "Dokumenty",
          !locationState?.documentId &&
            "Tworzenie dokumentu finansowo-księgowego",
        ]}
        goBackOption
        hasActiveAssignmentBar
        buttons={[
          {
            label: "Wyczyść",
            kind: KIND.secondary,
            startEnhancer: <Note size={18} />,
            disabled: isLoading,
            onClick: () => {
              setIsClearConfirmDialogOpen(true);
            },
            permission: !locationState?.documentId,
          },
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            isLoading: isLoading,
            onClick: () =>
              !(Object.keys(dirtyFields).length <= 1)
                ? setIsCancelConfirmDialogOpen(true)
                : history.push("/documents"),
          },
          {
            label: locationState?.documentId ? "Zapisz" : "Utwórz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            disabled: isLoading,
            isLoading: isLoading,
            onClick: handleSubmit(onSubmit, onError),
            type: "submit",
            formId: "createInvoice",
          },
        ]}
      >
        <DocumentBarcode
          documentId={locationState?.documentId}
          internalNumber={locationState?.documentInternalNumber}
        />
      </Header>

      <Content>
        <Grid>
          <Cell span={12}>
            <FormControl label="Data rejestracji w systemie" disabled={true}>
              {isFetching ? (
                <Skeleton rows={0} height="20px" width="100%" animation />
              ) : (
                <FormattedValue>{time.toLocaleDateString()}</FormattedValue>
              )}
            </FormControl>
          </Cell>
        </Grid>
        <form
          id="createInvoice"
          onSubmit={handleSubmit(onSubmit)}
          onFocus={() => {
            const inputName =
              typeof window !== "undefined" &&
              (window?.document?.activeElement
                ?.id as keyof FinancialAccountingDocumentInputs);

            inputName &&
              inputName !== focusedInput &&
              !inputName.includes("bui") &&
              setFocusedInput(inputName);
          }}
        >
          <Grid>
            {FINANCIAL_ACCOUNTING_DOCUMENTS_FIELDS.filter(
              (g) =>
                g.fields.filter(
                  (f) =>
                    f.create?.visible &&
                    (!f.documentType ||
                      (f.documentType &&
                        f.documentType.includes(
                          locationState.documentType.toLowerCase()
                        )))
                ).length > 0
            ).map((group) => [
              group.label && (
                <Cell key={group.id + `-group`} span={12}>
                  <>
                    <Block
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      marginBottom="scale200"
                      marginTop="scale600"
                      height="20px"
                    >
                      <Block
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <LabelMedium>
                          {!!data?.document?.files?.length &&
                          group.id === "files"
                            ? `${group.label} (${data?.document?.files?.length})`
                            : group.label}
                        </LabelMedium>
                        {hasPersonalData &&
                          !isAnyAddressRevealed &&
                          group.id === "sender" && (
                            <Button
                              size={SIZE.mini}
                              kind={KIND.secondary}
                              $style={{ marginLeft: "10px" }}
                              onClick={() =>
                                setIsAddressRevealConfirmDialogOpen(true)
                              }
                              isLoading={revealAddressLoading}
                              type="button"
                            >
                              Pokaż dane
                            </Button>
                          )}
                        {hasHiddenFiles && group.id === "files" && (
                          <Button
                            size={SIZE.mini}
                            kind={KIND.secondary}
                            $style={{ marginLeft: "10px" }}
                            onClick={() =>
                              setIsFilesRevealConfirmDialogOpen(true)
                            }
                            isLoading={revealFilesLoading || isFetching}
                            type="button"
                          >
                            Pokaż pliki
                          </Button>
                        )}
                      </Block>
                      {hasPersonalData &&
                        !isAnyAddressRevealed &&
                        group.id === "sender" && (
                          <FormattedValue
                            dataType="pre"
                            $style={{ fontSize: "12px" }}
                          >
                            Dane osobowe - podgląd zablokowany
                          </FormattedValue>
                        )}
                      {locationState &&
                        !hasHiddenFiles &&
                        !!data?.document?.files?.length &&
                        group.id === "files" && (
                          <FormattedValue
                            dataType="pre"
                            $style={{ fontSize: "13px" }}
                          >
                            Dostęp jest aktywny do końca trwania sesji
                          </FormattedValue>
                        )}
                    </Block>
                    <hr
                      className={css({
                        borderWidth: "0px",
                        height: "1px",
                        backgroundColor: "#eee",
                      })}
                    />
                  </>
                </Cell>
              ),
              group.fields
                .filter(
                  (f) =>
                    f.create?.visible &&
                    (!f.documentType ||
                      (f.documentType &&
                        f.documentType.includes(
                          locationState.documentType.toLowerCase()
                        )))
                )
                .map((item, index) => (
                  <Cell
                    span={item.span || 6}
                    skip={item.skip || 0}
                    key={group.id + `-field` + index}
                  >
                    <FormControl
                      label={item.type === FIELDS.Checkbox ? "" : item.label}
                      caption={
                        (locationState &&
                          item.id === "filesList" &&
                          hasHiddenFiles) ||
                        (locationState &&
                          !data?.document?.files?.length &&
                          item.id === "filesList") ||
                        (!locationState && item.id === "filesList")
                          ? ""
                          : item.id === "accountNumber" &&
                            watchAccountType?.[0]?.id === BankAccountType.Other
                          ? ""
                          : item.id === "grossValue" &&
                            parseFloat(
                              watchGrossValue?.toString().replace(",", ".")
                            ) !==
                              parseFloat(
                                watchFinancialAccountingDocumentItems
                                  ?.reduce(
                                    (a, b) =>
                                      a +
                                      (b
                                        ? b?.totalAmountWithTax
                                          ? b?.totalAmountWithTax
                                          : 0
                                        : 0),
                                    0
                                  )
                                  ?.toString()
                              ) &&
                            watchFinancialAccountingDocumentItems?.length > 0
                          ? `<span class="${css({
                              color: theme.colors.negative,
                            })}">Kwota brutto nie zgadza się z sumą kwot brutto pozycji w tabeli.</span>`
                          : item.caption
                      }
                      required={
                        item.info === "possiblyRequired"
                          ? (hasPersonalData && isAnyAddressRevealed) ||
                            !hasPersonalData
                          : item.create.required
                      }
                      error={
                        ((errors as any)[item.id] &&
                          (errors as any)[item.id].message) ||
                        (error &&
                          error.graphQLErrors[0]?.extensions?.code ===
                            "INPUT_VALIDATION_ERROR" &&
                          error.graphQLErrors[0]?.extensions?.validationErrors
                            ?.find((vE: InputValidationError) =>
                              vE?.property.split(".").includes(item.id)
                            )
                            ?.errors.map((message: string) => (
                              <div
                                key="error"
                                className={css({
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                })}
                              >
                                {message}
                                <AlertOctagon color="#999" size={12} />
                              </div>
                            ))[0])
                      }
                      disabled={isLoading}
                    >
                      {item.type === FIELDS.PaymentTypesSelect ? (
                        <ControlledPaymentTypesSelect
                          control={control}
                          name={item.id}
                          placeholder={item.placeholder}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.AddressesSelect ? (
                        <ControlledAddressesSelect
                          control={control}
                          id={item.id}
                          name={item.id}
                          placeholder="Wybierz"
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.UsersSelect ? (
                        <ControlledUsersSelect
                          control={control}
                          id={item.id}
                          name={item.id}
                          placeholder="Wybierz"
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.CasesSelect ? (
                        <ControlledCasesSelect
                          control={control}
                          id={item.id}
                          name={item.id}
                          placeholder="Wybierz"
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.CurrencySelect ? (
                        <ControlledCurrencySelect
                          control={control}
                          id={item.id}
                          name={item.id}
                          placeholder={
                            hasPersonalData &&
                            !isAnyAddressRevealed &&
                            item.additionalData === "sensitive"
                              ? "**********"
                              : "PLN"
                          }
                          disabled={
                            hasPersonalData &&
                            !isAnyAddressRevealed &&
                            item.additionalData === "sensitive"
                          }
                          size={SIZE.mini}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.CurrencyExchangeRatesSelect ? (
                        <ControlledCurrencyExchangeRatesSelect
                          control={control}
                          id={item.id}
                          name={item.id}
                          size={SIZE.mini}
                          currencyId={watchCurrency?.[0]?.currencyId}
                          disabled={!watchCurrency || !watchCurrency?.length}
                          placeholder=""
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.OrganizationalUnitsSelect ? (
                        <ControlledOrganizationalUnitsSelect
                          control={control}
                          id={item.id}
                          name={item.id}
                          placeholder="Wybierz"
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.DocumentsSelect ? (
                        <ControlledDocumentsSelect
                          control={control}
                          id={item.id}
                          name={item.id}
                          placeholder="Wybierz"
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.OrganizationalUnitUsersSelect ? (
                        watchOrganizationalUnit &&
                        watchOrganizationalUnit.length > 0 ? (
                          <ControlledOrganizationalUnitUsersSelect
                            objectId={watchOrganizationalUnit[0].id}
                            control={control}
                            id={item.id}
                            name={item.id}
                            placeholder="Wybierz"
                            {...(item.create.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                          />
                        ) : (
                          <ControlledUsersSelect
                            control={control}
                            name={item.id}
                            id={item.id}
                            placeholder="Wybierz"
                            {...(item.create.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                          />
                        )
                      ) : item.type ===
                        FIELDS.UsersOrganizationalUnitsSelect ? (
                        watchRecipientWhenUser &&
                        watchRecipientWhenUser.length > 0 ? (
                          <ControlledUsersOrganizationalUnitsSelect
                            control={control}
                            name={item.id}
                            id={item.id}
                            objectId={watchRecipientWhenUser[0].id}
                            placeholder="Wybierz"
                            {...(item.create.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                          />
                        ) : (
                          <ControlledOrganizationalUnitsSelect
                            control={control}
                            name={item.id}
                            id={item.id}
                            placeholder="Wybierz"
                            {...(item.create.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                          />
                        )
                      ) : item.type === FIELDS.DictionaryValuesSelect ? (
                        <ControlledDictionaryValuesSelect
                          dictionarySystemName={
                            item.id === "addressCountryCode"
                              ? "countryCodes.alpha2.pl"
                              : item.id === "documentType"
                              ? "documentTypes.incoming"
                              : item.id === "invoiceTypes"
                              ? "invoice.types"
                              : undefined
                          }
                          control={control}
                          name={item.id}
                          id={item.id}
                          placeholder={
                            hasPersonalData &&
                            !isAnyAddressRevealed &&
                            item.additionalData === "sensitive"
                              ? "**********"
                              : "Wybierz"
                          }
                          disabled={
                            item.additionalData === "sensitive" &&
                            hasPersonalData &&
                            !isAnyAddressRevealed
                          }
                          {...((item.create.required ||
                            (item.info === "possiblyRequired" &&
                              hasPersonalData &&
                              isAnyAddressRevealed &&
                              item.id.includes("address") &&
                              item.id !== "addressName") ||
                            (item.info === "possiblyRequired" &&
                              !hasPersonalData &&
                              item.id.includes("address") &&
                              item.id !== "addressName")) && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.DatePicker ? (
                        <ControlledDatePicker
                          name={item.id}
                          control={control}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                          disabled={
                            item.id === "postStampAt" && watchHandDelivered
                          }
                          {...(item.id === "postStampAt" && {
                            $style: { paddingRight: "35px" },
                          })}
                        />
                      ) : item.type === FIELDS.FilesPicker ? (
                        <ControlledFilesPicker
                          control={control}
                          name={item.id}
                          maxSize={134217728}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.FilesList &&
                        locationState &&
                        data?.document?.files ? (
                        <FilesList
                          files={
                            (data?.document?.files as unknown) as SingleFile[]
                          }
                          hasHiddenFiles={hasHiddenFiles}
                          selectedFiles={selectedFiles}
                          setSelectedFiles={setSelectedFiles}
                        />
                      ) : item.type === FIELDS.InvoiceTable ? (
                        <ControlledInvoiceTable
                          control={control}
                          name={item.id}
                          id={item.id}
                          currency={watchCurrency?.[0]?.code}
                          hasFocus={focusedInput === item.id}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.BankAccountTypesSelect ? (
                        <ControlledBankAccountTypesSelect
                          control={control}
                          name={item.id}
                          id={item.id}
                          placeholder="Wybierz"
                          disabled={
                            hasPersonalData &&
                            !isAnyAddressRevealed &&
                            item.additionalData === "sensitive"
                          }
                        />
                      ) : item.type === FIELDS.BankAccountsSelect ? (
                        <ControlledBankAccountsSelect
                          control={control}
                          name={item.id}
                          id={item.id}
                          options={availableBankAccounts}
                          placeholder={
                            hasPersonalData &&
                            !isAnyAddressRevealed &&
                            item.additionalData === "sensitive"
                              ? "**********"
                              : item.placeholder
                          }
                          disabled={
                            hasPersonalData &&
                            !isAnyAddressRevealed &&
                            item.additionalData === "sensitive"
                          }
                          defaultValue={[{ id: 0 }]}
                        />
                      ) : item.type === FIELDS.Checkbox ? (
                        <div className={css({ marginTop: "40px" })}>
                          {item.id === "addressOverwrite" ? (
                            <Tooltip
                              placement="bottomLeft"
                              content={
                                <p>
                                  Zaznaczenie tej opcji spowoduje aktualizację
                                  danych <br /> wybranego adresata w bazie, ale
                                  nie wpłynie to na <br /> wcześniejsze
                                  dokumenty powiązane z tym adresatem.
                                </p>
                              }
                            >
                              <span>
                                <ControlledCheckbox
                                  control={control}
                                  name={item.id}
                                  disabled={
                                    isLoading ||
                                    (hasPersonalData &&
                                      !isAnyAddressRevealed &&
                                      item.id.includes("address") &&
                                      !item.id.includes("Name"))
                                  }
                                  {...(item.create.required && {
                                    rules: {
                                      required:
                                        formValidation.messages.required,
                                    },
                                  })}
                                >
                                  {item.label}
                                </ControlledCheckbox>
                              </span>
                            </Tooltip>
                          ) : (
                            <ControlledCheckbox
                              control={control}
                              name={item.id}
                              disabled={
                                isLoading ||
                                (hasPersonalData &&
                                  !isAnyAddressRevealed &&
                                  item.id.includes("address") &&
                                  !item.id.includes("Name"))
                              }
                              {...(item.create.required && {
                                rules: {
                                  required: formValidation.messages.required,
                                },
                              })}
                            >
                              {item.label}
                            </ControlledCheckbox>
                          )}
                        </div>
                      ) : item.type === FIELDS.TextArea ? (
                        <ControlledTextArea
                          control={control}
                          name={item.id}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : (
                        <ControlledInput
                          control={control}
                          name={item.id}
                          id={item.id}
                          {...(item.type === FIELDS.NumberInput && {
                            type: "number",
                            step: 0.01,
                          })}
                          placeholder={
                            hasPersonalData &&
                            !isAnyAddressRevealed &&
                            item.additionalData === "sensitive"
                              ? "**********"
                              : item.id === "accountNumber" &&
                                watchAccountType?.[0]?.id ===
                                  BankAccountType.Other
                              ? ""
                              : item.placeholder
                          }
                          {...((item.create.required ||
                            (item.info === "possiblyRequired" &&
                              !isSensitiveAddressChoosed)) && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                          {...(item.id === "addressEmail" && {
                            rules: {
                              pattern: {
                                value: formValidation.patterns.email,
                                message: formValidation.messages.incorrectEmail,
                              },
                            },
                          })}
                          {...(item.id === "filesList" && {
                            overrides: {
                              Root: { style: { visibility: "hidden" } },
                            },
                          })}
                          masked={
                            item.mask !== undefined &&
                            ((item.id === "accountNumber" &&
                              watchAccountType?.[0]?.id ===
                                BankAccountType.IBAN) ||
                              item.id !== "accountNumber")
                          }
                          mask={item.mask}
                          maskChar={item.maskChar || " "}
                          disabled={
                            (hasPersonalData &&
                              !isAnyAddressRevealed &&
                              item.additionalData === "sensitive") ||
                            (item.id === "sequenceNumber" &&
                              !checkPermission(
                                PERMISSIONS.document.updateSequenceNumber
                              ))
                          }
                          endEnhancer={
                            item.id === "addressNip" ? (
                              <Tooltip content="Pobierz z GUS">
                                <span
                                  onClick={() => setIsGusDownloadOpen(true)}
                                  className={css({ cursor: "pointer" })}
                                >
                                  <Download
                                    size={16}
                                    className={css({
                                      verticalAlign: "middle",
                                      marginLeft: "6px",
                                      display: "inline",
                                    })}
                                  />
                                </span>
                              </Tooltip>
                            ) : item.id === "grossValue" ? (
                              watchCurrency?.[0]?.code || item.endEnhancer
                            ) : (
                              item.endEnhancer
                            )
                          }
                        />
                      )}
                    </FormControl>
                  </Cell>
                )),
            ])}
            <ConfirmDialog
              isOpen={isCancelConfirmDialogOpen}
              label="Anulowanie tworzenia dokumentu"
              close={() => setIsCancelConfirmDialogOpen(false)}
              confirm={() => history.push("/documents")}
            />
            <ConfirmDialog
              isOpen={isClearConfirmDialogOpen}
              label="Wyczyszczenie formularza"
              close={() => setIsClearConfirmDialogOpen(false)}
              confirm={() => {
                reset();
                setIsClearConfirmDialogOpen(false);
              }}
            />
            <ConfirmDialog
              isOpen={isAddressRevealConfirmDialogOpen}
              type={ConfirmDialogType.AddressReveal}
              close={() => setIsAddressRevealConfirmDialogOpen(false)}
              confirm={() => onAddressRevealSubmit()}
            />
            <ConfirmDialog
              isOpen={isFilesRevealConfirmDialogOpen}
              type={ConfirmDialogType.FilesReveal}
              close={() => setIsFilesRevealConfirmDialogOpen(false)}
              confirm={() => onFilesRevealSubmit()}
            />
            <GusDownloadModal
              isOpen={isGusDownloadOpen}
              close={() => setIsGusDownloadOpen(false)}
              confirm={() => null}
              currentNip={watchNip}
              setAddressFromGus={setAddressFromGus}
            />
          </Grid>
        </form>
      </Content>
    </article>
  );
}
