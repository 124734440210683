import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { Row } from "react-table";
import { Eye, EyeOff, Trash } from "tabler-icons-react";

import Cell from "../../../components/cell";
import ConfirmDialog from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import FormControl from "../../../components/form-control";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import Table from "../../../components/table";
import { useLoading } from "../../../contexts/loading-context";
import { checkPermission } from "../../../utils/check-permission";
import { PERMISSIONS } from "../../../utils/permissions";
import { CurrencyExchangeRate } from "../currencies";
import { CURRENCIES_FIELDS } from "../currencies.form";
import { CURRENCIES_SHOW, CURRENCIES_UPDATE } from "../currencies.gql";

export default function CurrenciesShow(): React.ReactElement {
  const [css, theme] = useStyletron();
  const [
    isHideCurrencyConfirmDialogOpen,
    setIsHideCurrencyConfirmDialogOpen,
  ] = useState(false);
  const [
    isRemoveCurrencyConfirmDialogOpen,
    setIsRemoveCurrencyConfirmDialogOpen,
  ] = useState(false);
  const { isFetching, setIsFetching, setIsLoading } = useLoading();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();

  const { refetch, data, error } = useQuery(CURRENCIES_SHOW, {
    variables: { id: id ? parseInt(id) : null },
  });
  const isVisible = data?.currency?.isVisible;

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    if (data?.currency) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => {
    data?.currency && setIsFetching(false);
  }, [data]);

  const [updateCurrency] = useMutation(CURRENCIES_UPDATE);

  const updateCurrencyStatus = async (
    action: "Visibility" | "Remove"
  ): Promise<void> => {
    setIsLoading(true);

    try {
      action === "Visibility" &&
        (await updateCurrency({
          variables: {
            currencyUpdateInput: {
              id: id ? parseInt(id) : null,
              isVisible: !isVisible,
            },
          },
        }));

      action === "Remove" &&
        (await updateCurrency({
          variables: {
            currencyUpdateInput: {
              id: id ? parseInt(id) : null,
              isActive: false,
            },
          },
        }));

      enqueueSnackbar({
        message:
          action === "Visibility"
            ? isVisible
              ? "Ukryto pomyślnie"
              : "Odkryto pomyślnie"
            : "Usunięto pomyślnie",
        variant: "success",
      });

      action === "Visibility"
        ? setIsHideCurrencyConfirmDialogOpen(false)
        : setIsRemoveCurrencyConfirmDialogOpen(false);

      action === "Visibility" ? refetch() : history.push("/currencies");
    } catch (error: unknown) {
      action === "Visibility"
        ? setIsHideCurrencyConfirmDialogOpen(false)
        : setIsRemoveCurrencyConfirmDialogOpen(false);

      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Lp.",
        accessor: "ordinalNumber",
        Cell: ({ row }: { row: Row<CurrencyExchangeRate> }) => (
          <FormattedValue>{row.index + 1}</FormattedValue>
        ),
      },
      {
        Header: (
          <Block display="flex" justifyContent="flex-end">
            Kurs
          </Block>
        ),
        accessor: "value",
        Cell: ({ row }: { row: Row<CurrencyExchangeRate> }) => (
          <Block display="flex" justifyContent="flex-end">
            <FormattedValue dataType="pre">{`${row?.original?.value?.toFixed(
              4
            )} PLN`}</FormattedValue>
          </Block>
        ),
      },
      {
        Header: (
          <Block display="flex" justifyContent="flex-end">
            Data
          </Block>
        ),
        id: "effectiveOn",
        Cell: ({ row }: { row: Row<CurrencyExchangeRate> }) => (
          <Block display="flex" justifyContent="flex-end">
            <FormattedValue>{row?.original?.effectiveOn}</FormattedValue>
          </Block>
        ),
      },
    ],
    []
  );

  if (!checkPermission(PERMISSIONS.currency.read)) return <Redirect to="/" />;
  if (data && !data?.currency?.isActive) return <Redirect to="/currencies" />;

  return (
    <article>
      <Header
        title={`${data?.currency?.name} [${data?.currency?.code}]`}
        labels={["Waluty", "Wyświetlanie"]}
        goBackOption
        actions={[
          {
            label: `${isVisible ? "Ukryj" : "Pokaż"} walutę na liście`,
            icon: isVisible ? EyeOff : Eye,
            color: isVisible ? theme.colors.negative : theme.colors.positive,
            onClick: () => setIsHideCurrencyConfirmDialogOpen(true),
            permission: checkPermission(PERMISSIONS.currency.update),
          },
          {
            label: "Usuń walutę z listy",
            icon: Trash,
            color: theme.colors.negative,
            onClick: () => setIsRemoveCurrencyConfirmDialogOpen(true),
            permission: checkPermission(PERMISSIONS.currency.update),
          },
        ]}
      />
      <Content>
        <Grid>
          {CURRENCIES_FIELDS.filter(
            (g) => g.fields.filter((f) => f.show.visible).length > 0
          ).map((group) => [
            group.label && (
              <Cell key={group.id + `-group`} span={12}>
                <LabelMedium marginBottom="scale200" marginTop="scale600">
                  {group.label}
                </LabelMedium>
                <hr
                  className={css({
                    borderWidth: "0px",
                    height: "1px",
                    backgroundColor: "#eee",
                  })}
                />
              </Cell>
            ),
            group.fields
              .filter((f) => f.show.visible)
              .map((item, index) => (
                <Cell span={item.span || 6} key={group.id + `-field` + index}>
                  <FormControl label={item.label} disabled={true}>
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : (
                      <FormattedValue dataType={item.dataType}>
                        {item.info === "exchangeRate"
                          ? data?.currency?.exchangeRates?.length
                            ? item.id === "value"
                              ? `${data?.currency?.exchangeRates?.[0]?.value?.toFixed(
                                  4
                                )} PLN`
                              : item.id === "effectiveOn"
                              ? data?.currency?.exchangeRates?.[0]?.effectiveOn
                              : undefined
                            : undefined
                          : item.id === "isVisible"
                          ? !data?.currency?.isVisible
                          : data?.currency[item.id]}
                      </FormattedValue>
                    )}
                  </FormControl>
                </Cell>
              )),
          ])}
          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Historia
            </LabelMedium>
          </Cell>
          <Cell span={12}>
            {isFetching ? (
              <Skeleton rows={0} height="200px" width="100%" animation />
            ) : (
              <Block paddingTop="scale200" paddingBottom="scale600">
                <Table<CurrencyExchangeRate>
                  overrides={{
                    Root: {
                      style: {
                        maxHeight: "500px",
                        overflowY: "scroll",
                      },
                    },
                  }}
                  columns={columns}
                  data={data?.currency?.exchangeRates}
                />
              </Block>
            )}
          </Cell>
          <ConfirmDialog
            isOpen={isHideCurrencyConfirmDialogOpen}
            label={`${isVisible ? "Ukrycie" : "Pokazanie"} na liście waluty ${
              data?.currency?.name
            } [${data?.currency?.code}]`}
            close={() => setIsHideCurrencyConfirmDialogOpen(false)}
            confirm={() => updateCurrencyStatus("Visibility")}
          />
          <ConfirmDialog
            isOpen={isRemoveCurrencyConfirmDialogOpen}
            label={`Usunięcie z listy waluty ${data?.currency?.name} [${data?.currency?.code}]`}
            close={() => setIsRemoveCurrencyConfirmDialogOpen(false)}
            confirm={() => updateCurrencyStatus("Remove")}
          />
        </Grid>
      </Content>
    </article>
  );
}
