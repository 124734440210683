import { ApolloError, useMutation } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import {
  AlertOctagon,
  DeviceFloppy,
  FileOff,
  InfoCircle,
  Note,
} from "tabler-icons-react";

import { InputValidationError } from "../../../../api";
import Cell from "../../../../components/cell";
import { ControlledCheckbox } from "../../../../components/checkbox";
import ConfirmDialog from "../../../../components/confirm-dialog";
import Content from "../../../../components/content";
import { ControlledDatePicker } from "../../../../components/date-picker";
import { ControlledFilesPicker } from "../../../../components/files-picker";
import FormControl from "../../../../components/form-control";
import FormattedValue from "../../../../components/formatted-value";
import Grid from "../../../../components/grid";
import Header from "../../../../components/header";
import { ControlledInput } from "../../../../components/input";
import {
  ControlledCasesSelect,
  ControlledDocumentsSelect,
  ControlledInternalDocumentTypesSelect,
  ControlledOrganizationalUnitsSelect,
  ControlledOrganizationalUnitUsersSelect,
  ControlledUsersOrganizationalUnitsSelect,
  ControlledUsersSelect,
} from "../../../../components/select";
import { ControlledTextArea } from "../../../../components/text-area";
import { LOCAL_STORAGE_VARIABLES } from "../../../../constants";
import { useAuth } from "../../../../contexts/auth-context";
import { useLoading } from "../../../../contexts/loading-context";
import { FIELDS } from "../../../../fields.d";
import { checkPermission } from "../../../../utils/check-permission";
import { formValidation } from "../../../../utils/formValidation";
import { PERMISSIONS } from "../../../../utils/permissions";
import { scrollOnError } from "../../../../utils/scrollOnError";
import { DocumentInputs, DOCUMENTS_FIELDS } from "../../documents.form";
import { DOCUMENTS_CREATE } from "../../documents.gql";

export default function DocumentsCreateInternal(): React.ReactElement {
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const [isClearConfirmDialogOpen, setIsClearConfirmDialogOpen] = useState(
    false
  );
  const [errorTimeStamp, setErrorTimeStamp] = useState<number>();
  const [css, theme] = useStyletron();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { isFetching, isLoading, setIsLoading } = useLoading();
  const { user } = useAuth();

  const location = useLocation();
  const locationState = location.state as {
    caseId?: number;
  };

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm<DocumentInputs>({
    defaultValues: {
      documentNumber: "",
      documentType: undefined,
      documentDate: null,
      description: "",
      caseName: undefined,
      senderWhenUser: undefined,
      organizationalUnit: undefined,
      sequenceNumber: "",
      recipientWhenUser: undefined,
      parentIds: undefined,
      files: undefined,
    },
  });

  const watchOrganizationalUnit = watch("organizationalUnit");
  const watchRecipientWhenUser = watch("recipientWhenUser");

  const [
    createInternalDocument,
    { error: mutationError, loading },
  ] = useMutation(DOCUMENTS_CREATE);

  const time = new Date();

  const onError = (errors: Record<string, unknown>, e: any) =>
    setErrorTimeStamp(e.timeStamp);

  const onSubmit = async ({
    description,
    parentIds,
    documentType,
    documentDate,
    organizationalUnit,
    documentNumber,
    files,
    name,
    sequenceNumber,
    additionalCode,
    title,
    programName,
    raksDiaryNumber,
  }: DocumentInputs): Promise<void> => {
    setIsLoading(true);

    localStorage.setItem(
      LOCAL_STORAGE_VARIABLES.LastRegisteredInternalDocument,
      documentType && documentType[0]?.id.toString()
    );

    try {
      const response = await createInternalDocument({
        variables: {
          documentCreateInput: {
            parentIds: parentIds && parentIds.map(({ id }) => id),
            documentNumber,
            documentKind: "Internal",
            documentDate,
            description,
            name,
            userId: user?.id,
            additionalCode,
            sequenceNumber,
            programName,
            title,
            documentType: documentType && documentType[0]?.id,
            senderId: user?.id,
            senderType: "User",
            raksDiaryNumber,
            ...(locationState?.caseId && { caseId: locationState.caseId }),
            organizationalUnitId:
              organizationalUnit && organizationalUnit[0]?.id,
          },
          files,
        },
      });

      enqueueSnackbar({
        message: "Zarejestrowano pomyślnie",
        variant: "success",
      });
      history.push(
        `/documents/${response?.data?.documentCreate?.documentKind?.toLowerCase()}/Document/${
          response?.data?.documentCreate?.id
        }`
      );
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (errors) scrollOnError(errors, DOCUMENTS_FIELDS, "create");
  }, [errorTimeStamp]);

  useEffect(() => {
    const lastRegisteredDocumentType = localStorage.getItem(
      LOCAL_STORAGE_VARIABLES.LastRegisteredInternalDocument
    );
    lastRegisteredDocumentType &&
      setValue("documentType", [{ id: lastRegisteredDocumentType }]);
  }, []);

  if (!checkPermission(PERMISSIONS.document.create)) return <Redirect to="/" />;

  return (
    <article>
      <Header
        title="Rejestracja dokumentu wewnętrznego"
        labels={["Dokumenty", "Rejestracja"]}
        goBackOption
        buttons={[
          {
            label: "Wyczyść",
            kind: KIND.secondary,
            startEnhancer: <Note size={18} />,
            disabled: isLoading,
            onClick: () => {
              setIsClearConfirmDialogOpen(true);
            },
          },
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              isDirty
                ? setIsCancelConfirmDialogOpen(true)
                : history.push("/documents"),
          },
          {
            label: "Zarejestruj",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            disabled: isLoading,
            isLoading: isLoading,
            onClick: handleSubmit(onSubmit, onError),
            type: "submit",
            formId: "createInternalDocument",
          },
        ]}
      />
      <Content>
        <Grid>
          <Cell span={12}>
            <FormControl label="Data rejestracji w systemie" disabled={true}>
              {isFetching ? (
                <Skeleton rows={0} height="20px" width="100%" animation />
              ) : (
                <FormattedValue>{time.toLocaleDateString()}</FormattedValue>
              )}
            </FormControl>
          </Cell>
        </Grid>
        <form id="createInternalDocument" onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            {DOCUMENTS_FIELDS.filter(
              (g) => g.fields.filter((f) => f.create?.visible).length > 0
            )
              .filter(
                (g) =>
                  g.fields.filter((f) => f?.typeName?.includes("Internal"))
                    .length > 0 && g.id !== "sender"
              )
              .map((group) => [
                group.label && (
                  <Cell key={group.id + `-group`} span={12}>
                    <>
                      <LabelMedium marginBottom="scale200" marginTop="scale600">
                        {group.label}
                      </LabelMedium>
                      <hr
                        className={css({
                          borderWidth: "0px",
                          height: "1px",
                          backgroundColor: "#eee",
                        })}
                      />

                      {group.id === "recipient" ? (
                        <div
                          className={css({
                            backgroundColor: theme.colors.warning100,
                            color: theme.colors.warning700,
                            padding: "12px",
                            borderRadius: "6px",
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "6px",
                            width: "fit-content",
                          })}
                        >
                          <InfoCircle
                            color={theme.colors.warning700}
                            size={16}
                          />

                          <Block marginLeft={"8px"}>
                            W przypadku obiegu zdefiniowanego należy odznaczyć
                            jednostkę organizacyjną, do której ma trafić
                            dokument.
                          </Block>
                        </div>
                      ) : null}
                    </>
                  </Cell>
                ),
                group.fields
                  .filter((f) => f.create?.visible)
                  .filter((f) => f.typeName?.includes("Internal"))
                  .map((item, index) => (
                    <Cell
                      span={item.span || 6}
                      skip={item.skip || 0}
                      key={group.id + `-field` + index}
                    >
                      <FormControl
                        label={item.type === FIELDS.Checkbox ? "" : item.label}
                        caption={item.caption}
                        required={item.create.required}
                        error={
                          ((errors as any)[item.id] &&
                            (errors as any)[item.id].message) ||
                          (mutationError &&
                            mutationError.graphQLErrors[0]?.extensions?.code ===
                              "INPUT_VALIDATION_ERROR" &&
                            mutationError.graphQLErrors[0]?.extensions?.validationErrors
                              ?.find((vE: InputValidationError) =>
                                item.id.includes("address")
                                  ? vE?.property ===
                                    item.id.slice(7).toLowerCase()
                                  : vE?.property === item.id
                              )
                              ?.errors.map((message: string) => (
                                <div
                                  key="error"
                                  className={css({
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  })}
                                >
                                  {message}
                                  <AlertOctagon color="#999" size={12} />
                                </div>
                              ))[0])
                        }
                        disabled={isLoading}
                      >
                        {item.type === FIELDS.UsersSelect ? (
                          <ControlledUsersSelect
                            control={control}
                            id={item.id}
                            name={item.id}
                            placeholder="Wybierz"
                            {...(item.create.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                          />
                        ) : item.type === FIELDS.CasesSelect ? (
                          <ControlledCasesSelect
                            control={control}
                            id={item.id}
                            name={item.id}
                            placeholder="Wybierz"
                            {...(item.create.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                          />
                        ) : item.type === FIELDS.OrganizationalUnitsSelect ? (
                          <ControlledOrganizationalUnitsSelect
                            control={control}
                            id={item.id}
                            name={item.id}
                            placeholder="Wybierz"
                            {...(item.create.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                          />
                        ) : item.type === FIELDS.DocumentsSelect ? (
                          <ControlledDocumentsSelect
                            control={control}
                            id={item.id}
                            name={item.id}
                            placeholder="Wybierz"
                            {...(item.create.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                          />
                        ) : item.type ===
                          FIELDS.OrganizationalUnitUsersSelect ? (
                          watchOrganizationalUnit &&
                          watchOrganizationalUnit.length > 0 ? (
                            <ControlledOrganizationalUnitUsersSelect
                              objectId={watchOrganizationalUnit[0].id}
                              control={control}
                              id={item.id}
                              name={item.id}
                              placeholder="Wybierz"
                              {...(item.create.required && {
                                rules: {
                                  required: formValidation.messages.required,
                                },
                              })}
                            />
                          ) : (
                            <ControlledUsersSelect
                              control={control}
                              name={item.id}
                              id={item.id}
                              placeholder="Wybierz"
                              {...(item.create.required && {
                                rules: {
                                  required: formValidation.messages.required,
                                },
                              })}
                            />
                          )
                        ) : item.type ===
                          FIELDS.UsersOrganizationalUnitsSelect ? (
                          watchRecipientWhenUser &&
                          watchRecipientWhenUser.length > 0 ? (
                            <ControlledUsersOrganizationalUnitsSelect
                              control={control}
                              name={item.id}
                              id={item.id}
                              objectId={watchRecipientWhenUser[0].id}
                              placeholder="Wybierz"
                              {...(item.create.required && {
                                rules: {
                                  required: formValidation.messages.required,
                                },
                              })}
                            />
                          ) : (
                            <ControlledOrganizationalUnitsSelect
                              control={control}
                              name={item.id}
                              id={item.id}
                              placeholder="Wybierz"
                              {...(item.create.required && {
                                rules: {
                                  required: formValidation.messages.required,
                                },
                              })}
                            />
                          )
                        ) : item.type === FIELDS.DocumentTypesSelect ? (
                          <ControlledInternalDocumentTypesSelect
                            control={control}
                            name={item.id}
                            id={item.id}
                            placeholder={"Wybierz"}
                            {...(item.create.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                          />
                        ) : item.type === FIELDS.DatePicker ? (
                          <div
                            className={css({
                              position: "relative",
                            })}
                          >
                            <ControlledDatePicker
                              name={item.id}
                              control={control}
                              disabled={isLoading}
                              {...(item.create.required && {
                                rules: {
                                  required: formValidation.messages.required,
                                },
                              })}
                            />
                          </div>
                        ) : item.type === FIELDS.FilesPicker ? (
                          <ControlledFilesPicker
                            control={control}
                            name={item.id}
                            maxSize={134217728}
                            {...(item.create.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                          />
                        ) : item.type === FIELDS.Checkbox ? (
                          <ControlledCheckbox
                            control={control}
                            name={item.id}
                            disabled={loading}
                            {...(item.create.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                          >
                            {item.label}
                          </ControlledCheckbox>
                        ) : item.type === FIELDS.TextArea ? (
                          <ControlledTextArea
                            control={control}
                            name={item.id}
                            {...(item.create.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                          />
                        ) : (
                          <ControlledInput
                            control={control}
                            name={item.id}
                            id={item.id}
                            placeholder={item.placeholder}
                            {...(item.create.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                            masked={item.mask !== undefined}
                            mask={item.mask}
                            maskChar="_"
                            endEnhancer={item.endEnhancer}
                            disabled={
                              item.id === "sequenceNumber" &&
                              !checkPermission(
                                PERMISSIONS.document.updateSequenceNumber
                              )
                            }
                          />
                        )}
                      </FormControl>
                    </Cell>
                  )),
              ])}
            <ConfirmDialog
              isOpen={isCancelConfirmDialogOpen}
              label="Anulowanie rejestracji dokumentu przychodzącego"
              close={() => setIsCancelConfirmDialogOpen(false)}
              confirm={() => history.push("/documents")}
            />
            <ConfirmDialog
              isOpen={isClearConfirmDialogOpen}
              label="Wyczyszczenie formularza"
              close={() => setIsClearConfirmDialogOpen(false)}
              confirm={() => {
                reset();
                setIsClearConfirmDialogOpen(false);
              }}
            />
          </Grid>
        </form>
      </Content>
    </article>
  );
}
