import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import { AlertOctagon, FileOff, Share } from "tabler-icons-react";

import { InputValidationError } from "../../../api";
import Cell from "../../../components/cell";
import ConfirmDialog from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import DocumentBarcode from "../../../components/document-barcode";
import FormControl from "../../../components/form-control";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import ControlledRadioButton from "../../../components/radio-button";
import {
  ControlledOrganizationalUnitsSelect,
  ControlledUsersLiveSearchTableSelect,
} from "../../../components/select";
import { ControlledTextArea } from "../../../components/text-area";
import { useAssignment } from "../../../contexts/assignment-context";
import { useAuth } from "../../../contexts/auth-context";
import { useDictionaries } from "../../../contexts/dictionaries-context";
import { useLoading } from "../../../contexts/loading-context";
import { useSubstitution } from "../../../contexts/substitution-context";
import { checkPermission } from "../../../utils/check-permission";
import { formValidation } from "../../../utils/formValidation";
import { PERMISSIONS } from "../../../utils/permissions";
import { AssignmentLocationState } from "../../Folders/folders.d";
import { ShareFormInputs } from "../documents.form";
import { DOCUMENTS_SHARE, DOCUMENTS_SHOW } from "../documents.gql";

export default function DocumentsShare(): React.ReactElement {
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const [errorTimeStamp, setErrorTimeStamp] = useState<number>();
  const [css] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { user } = useAuth();
  const { isFetching, setIsFetching, isLoading, setIsLoading } = useLoading();
  const { id } = useParams<{ id: string }>();
  const documentId = parseInt(id);

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    watch,
    setValue,
    unregister,
  } = useForm<ShareFormInputs>({
    defaultValues: {
      variant: "users",
      users: [],
      organizationalUnits: [],
      comment: "",
    },
  });

  const choosedVariant = watch("variant");

  useEffect(() => {
    if (choosedVariant === "users") {
      setValue("organizationalUnits", []);
      unregister("organizationalUnits");
    } else {
      setValue("users", []);
      unregister("users");
    }
  }, [choosedVariant]);

  const { assignment, setAssignment, setIsAssignmentActive } = useAssignment();
  const { isSubstitutionActive, substitution } = useSubstitution();
  const { state, pathname } = useLocation<AssignmentLocationState>();

  const [shareDocument, { error }] = useMutation(DOCUMENTS_SHARE, {
    ...((state?.assignmentId || assignment?.id) &&
      state?.assignmentId !== null && {
        context: {
          headers: {
            Assignment: state?.assignmentId || assignment?.id,
          },
        },
      }),
  });

  const { data, error: queryError } = useQuery(DOCUMENTS_SHOW, {
    variables: { id: parseInt(id) },
    ...((state?.assignmentId || assignment?.id) &&
      state?.assignmentId !== null && {
        context: {
          headers: {
            Assignment: state?.assignmentId || assignment?.id,
          },
        },
      }),
  });

  const { findValue } = useDictionaries();

  const onError = (errors: Record<string, unknown>, e: any) =>
    setErrorTimeStamp(e.timeStamp);

  const onSubmit = async (shareFormValues: ShareFormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      await shareDocument({
        variables: {
          documentShareInput: {
            documentId: parseInt(id),
            comment: shareFormValues.comment,
            permission: shareFormValues.accessLevel,
            ...(shareFormValues.variant === "users"
              ? {
                  shareeIds: shareFormValues.users?.map(({ id }) => id),
                }
              : {
                  organizationalUnitId:
                    shareFormValues.organizationalUnits?.[0]?.id,
                }),
          },
        },
      });

      enqueueSnackbar({
        message: "Udostępniono pomyślnie",
        variant: "success",
      });

      history.push(pathname.replace("/share", ""));
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => setIsFetching(true), []);

  useEffect(() => {
    if (data?.document?.currentAssignment) {
      setAssignment(data?.document?.currentAssignment);
      setIsAssignmentActive(true);
    }

    if (data) setIsFetching(false);
  }, [data]);

  useEffect(() => {
    if (queryError?.graphQLErrors)
      enqueueSnackbar({
        message: (queryError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [queryError]);

  if (
    data &&
    data?.case &&
    (!!data?.case?.closedAt || !checkPermission(PERMISSIONS.case.update))
  )
    return <Redirect to="/" />;

  return (
    <article>
      <Header
        title={data?.document?.internalNumber}
        labels={[
          <FormattedValue
            key="label_1"
            dataType={"model:cases"}
            data={data?.document?.case?.id}
            additionalData={data?.document?.case?.isConductedElectronically}
            showBlankWhenEmpty
          >
            {`${data?.document?.case?.number}`}
          </FormattedValue>,
          "Dokument",
        ]}
        goBackOption
        hasActiveAssignmentBar
        buttons={[
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              isDirty ? setIsCancelConfirmDialogOpen(true) : history.goBack(),
          },
          {
            label: "Udostępnij",
            kind: KIND.primary,
            startEnhancer: <Share size={18} />,
            onClick: handleSubmit(onSubmit, onError),
            disabled: isLoading,
            isLoading: isLoading,
            type: "submit",
            formId: "shareDocument",
          },
        ]}
      >
        <DocumentBarcode
          documentId={documentId}
          internalNumber={data?.document?.internalNumber}
        />
      </Header>

      <Content>
        <form id="shareDocument" onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            <Cell span={4}>
              <FormControl label="Typ dokumentu" disabled={true}>
                {isFetching ? (
                  <Skeleton rows={0} height="30px" width="100%" animation />
                ) : (
                  <FormattedValue
                    loadingIfUndefined={
                      !!(
                        data?.document?.documentType &&
                        data?.document?.documentType !== "undefined"
                      )
                    }
                  >
                    {data?.document?.documentType &&
                    data?.document?.documentType !== "undefined"
                      ? findValue(data?.document?.documentType)
                      : null}
                  </FormattedValue>
                )}
              </FormControl>
            </Cell>
            <Cell span={4}>
              <FormControl label="Rodzaj dokumentu" disabled={true}>
                {isFetching ? (
                  <Skeleton rows={0} height="30px" width="100%" animation />
                ) : (
                  <FormattedValue
                    dataType="document-kind"
                    data={data?.document?.documentKind}
                  >
                    {data?.document?.documentKind}
                  </FormattedValue>
                )}
              </FormControl>
            </Cell>
            <Cell span={4}>
              <FormControl label="Jednostka organizacyjna" disabled={true}>
                {isFetching ? (
                  <Skeleton rows={0} height="30px" width="100%" animation />
                ) : (
                  <FormattedValue
                    dataType="model:organizational-units"
                    data={data?.document?.organizationalUnit?.id}
                  >
                    {data?.document?.organizationalUnit?.name}
                  </FormattedValue>
                )}
              </FormControl>
            </Cell>

            <Cell span={12}>
              <Block
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                marginBottom="scale200"
                marginTop="scale600"
                height="20px"
              >
                <Block
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <LabelMedium>Dostęp</LabelMedium>
                </Block>
              </Block>
              <hr
                className={css({
                  borderWidth: "0px",
                  height: "1px",
                  backgroundColor: "#eee",
                })}
              />
            </Cell>

            <Cell span={12}>
              <ControlledRadioButton
                control={control}
                name="variant"
                buttons={[
                  {
                    id: "users",
                    label: "Użytkownicy",
                  },
                  {
                    id: "organizationalUnit",
                    label: "Jednostka organizacyjna",
                  },
                ]}
              />
            </Cell>

            <Cell span={12}>
              <FormControl
                label={
                  choosedVariant === "users"
                    ? "Użytkownicy"
                    : "Jednostka organizacyjna"
                }
                required
                disabled={isLoading}
                error={
                  ((errors as any)[
                    choosedVariant === "users" ? "users" : "organizationalUnits"
                  ] &&
                    (errors as any)[
                      choosedVariant === "users"
                        ? "users"
                        : "organizationalUnits"
                    ].message) ||
                  (error &&
                    error.graphQLErrors[0]?.extensions?.code ===
                      "INPUT_VALIDATION_ERROR" &&
                    error.graphQLErrors[0]?.extensions?.validationErrors
                      ?.find(
                        (vE: InputValidationError) => vE?.property === "userIds"
                      )
                      ?.errors.map((message: string) => (
                        <div
                          key="error"
                          className={css({
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          })}
                        >
                          {message}
                          <AlertOctagon color="#999" size={12} />
                        </div>
                      ))[0])
                }
              >
                {choosedVariant === "users" ? (
                  <ControlledUsersLiveSearchTableSelect
                    control={control}
                    name="users"
                    id="users"
                    placeholder="Wybierz"
                    multi
                    filterOptions={(options) =>
                      options.filter((o) =>
                        isSubstitutionActive
                          ? o.id !== substitution?.substitutee?.id
                          : o.id !== user?.id
                      )
                    }
                    rules={{
                      required: formValidation.messages.required,
                    }}
                  />
                ) : (
                  <ControlledOrganizationalUnitsSelect
                    control={control}
                    name="organizationalUnits"
                    id="organizationalUnits"
                    placeholder="Wybierz"
                    filterOptions={(options, filterValue) =>
                      options.filter(
                        (o) =>
                          (isSubstitutionActive
                            ? o.id !== substitution?.substitutee?.id
                            : o.id !== user?.id) &&
                          o?.label
                            ?.toString()
                            .toLocaleLowerCase()
                            .includes(filterValue.toLocaleLowerCase())
                      )
                    }
                    rules={{
                      required: formValidation.messages.required,
                    }}
                  />
                )}
              </FormControl>

              <FormControl
                label="Komentarz"
                disabled={isLoading}
                error={
                  ((errors as any)["comment"] &&
                    (errors as any)["comment"].message) ||
                  (error &&
                    error.graphQLErrors[0]?.extensions?.code ===
                      "INPUT_VALIDATION_ERROR" &&
                    error.graphQLErrors[0]?.extensions?.validationErrors
                      ?.find(
                        (vE: InputValidationError) => vE?.property === "comment"
                      )
                      ?.errors.map((message: string) => (
                        <div
                          key="error"
                          className={css({
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          })}
                        >
                          {message}
                          <AlertOctagon color="#999" size={12} />
                        </div>
                      ))[0])
                }
              >
                <ControlledTextArea control={control} name="comment" />
              </FormControl>
            </Cell>

            <ConfirmDialog
              isOpen={isCancelConfirmDialogOpen}
              label="Anulowanie udostępniania dokumentu"
              close={() => setIsCancelConfirmDialogOpen(false)}
              confirm={() => history.goBack()}
            />
          </Grid>
        </form>
      </Content>
    </article>
  );
}
