import { Block } from "baseui/block";
import { KIND } from "baseui/button";
import { ModalBody, ModalFooter, ModalHeader } from "baseui/modal";
import React from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import Button from "../components/button";
import Modal from "../components/modal";
import { formValidation } from "../utils/formValidation";
import FormControl from "./form-control";
import { ControlledRequestFormNotesSelect } from "./select";

type EditBasedOnNoteModalProps = {
  requestFormId?: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export default function EditBasedOnNoteModal({
  requestFormId,
  isOpen,
  setIsOpen,
}: EditBasedOnNoteModalProps): React.ReactElement {
  const history = useHistory();

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<{
    requestFormNotes?: { id: number; label: string }[];
  }>({
    defaultValues: {
      requestFormNotes: undefined,
    },
  });

  const onSubmit = async ({
    requestFormNotes,
  }: {
    requestFormNotes: {
      id: number;
      label: string;
    }[];
  }): Promise<void> => {
    requestFormId &&
      history.push({
        pathname: `/documents/internal/RequestForm/${requestFormId}/edit`,
        state: {
          requestFormNoteId: requestFormNotes && requestFormNotes[0]?.id,
        },
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
        reset();
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>Wybierz notatkę</ModalHeader>
        <ModalBody>
          <Block>
            <form>
              <FormControl
                label="Notatka"
                required
                error={
                  (errors as any).requestFormNotes &&
                  (errors as any)?.requestFormNotes?.message
                }
              >
                <ControlledRequestFormNotesSelect
                  control={control}
                  name="requestFormNotes"
                  id="requestFormNotes"
                  placeholder="Wybierz"
                  requestFormId={requestFormId}
                  rules={{
                    required: formValidation.messages.required,
                  }}
                />
              </FormControl>
            </form>
          </Block>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            kind={KIND.secondary}
            $style={{ marginRight: "10px" }}
            onClick={() => {
              setIsOpen(false);
              reset();
            }}
          >
            Anuluj
          </Button>
          <Button type="submit">Edytuj wniosek</Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
