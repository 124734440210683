import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { Row } from "react-table";
import { Building, EyeOff, Man } from "tabler-icons-react";

import Cell from "../../../components/cell";
import ConfirmDialog from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import FormControl from "../../../components/form-control";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import Table from "../../../components/table";
import { useLoading } from "../../../contexts/loading-context";
import { checkPermission } from "../../../utils/check-permission";
import { PERMISSIONS } from "../../../utils/permissions";
import { BankAccount } from "../addresses";
import { ADDRESSES_FIELDS } from "../addresses.form";
import {
  ADDRESSES_HIDE,
  ADDRESSES_SHOW,
  ADDRESSES_UPDATE,
} from "../addresses.gql";

export default function AddressesShow(): React.ReactElement {
  const [css, theme] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams<{ id?: string }>();
  const [
    isAddressHideConfirmDialogOpen,
    setIsAddressHideConfirmDialogOpen,
  ] = useState(false);
  const [
    isChangeAddressStatusConfirmDialogOpen,
    setIsChangeAddressStatusConfirmDialogOpen,
  ] = useState(false);
  const { setIsLoading, isFetching, setIsFetching } = useLoading();

  const { refetch, data, error } = useQuery(ADDRESSES_SHOW, {
    variables: { id: id ? parseInt(id) : null },
    fetchPolicy: "cache-first",
  });

  const isSensitive = data?.address?.isSensitive;

  const [hideAddress] = useMutation(ADDRESSES_HIDE);
  const [updateAddressStatus] = useMutation(ADDRESSES_UPDATE);

  const submit = async (action: "Hide" | "UpdateStatus") => {
    setIsLoading(true);

    try {
      action === "Hide" &&
        (await hideAddress({
          variables: {
            id: id ? parseInt(id) : null,
          },
        }));

      action === "UpdateStatus" &&
        (await updateAddressStatus({
          variables: {
            addressUpdateInput: {
              id: id ? parseInt(id) : null,
              isSensitive: !isSensitive,
            },
          },
        }));

      enqueueSnackbar({
        message:
          action === "Hide" ? "Ukryto pomyślnie" : "Zmieniono status pomyślnie",
        variant: "success",
      });

      action === "Hide"
        ? setIsAddressHideConfirmDialogOpen(false)
        : setIsChangeAddressStatusConfirmDialogOpen(false);
      refetch();
    } catch (error: unknown) {
      action === "Hide"
        ? setIsAddressHideConfirmDialogOpen(false)
        : setIsChangeAddressStatusConfirmDialogOpen(false);

      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    if (data?.address) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => data?.address && setIsFetching(false), [data]);

  const columns = useMemo(
    () => [
      {
        Header: "Nazwa",
        id: "payeeName",
        Cell: ({ row }: { row: Row<BankAccount> }) => (
          <FormattedValue
            dataType="model:bank-accounts"
            data={row?.original?.id}
            href={`/addresses/${id}/bank-accounts/${row?.original?.id}`}
          >
            {row?.original?.payeeName}
          </FormattedValue>
        ),
      },
      {
        Header: "Bank",
        id: "bank",
        Cell: ({ row }: { row: Row<BankAccount> }) => (
          <FormattedValue>{row?.original?.bankName}</FormattedValue>
        ),
      },
      {
        Header: "Numer konta",
        id: "accountNumber",
        Cell: ({ row }: { row: Row<BankAccount> }) => (
          <FormattedValue dataType="accountNumber">
            {row?.original?.accountNumber}
          </FormattedValue>
        ),
      },
    ],
    []
  );

  if (!checkPermission(PERMISSIONS.address.read)) return <Redirect to="/" />;

  return (
    <article>
      <Header
        title={data?.address?.name}
        labels={["Adresaci", "Wyświetlanie"]}
        goBackOption
        actions={[
          {
            label: "Ukryj adres",
            icon: EyeOff,
            color: theme.colors.negative,
            onClick: () => setIsAddressHideConfirmDialogOpen(true),
            permission:
              checkPermission(PERMISSIONS.address.hide) &&
              !data?.address?.isHidden,
          },
          {
            label: isSensitive
              ? "Oznacz jako dane publiczne"
              : "Oznacz jako dane osobowe",
            icon: isSensitive ? Building : Man,
            color: theme.colors.primary,
            onClick: () => setIsChangeAddressStatusConfirmDialogOpen(true),
            permission: checkPermission(PERMISSIONS.address.update),
          },
        ]}
      />
      <Content>
        <Grid>
          {ADDRESSES_FIELDS.filter(
            (g) =>
              g.fields.filter((f) => f.show.visible).length > 0 &&
              ((g.accessor && data?.address[g.accessor]) || !g.accessor)
          ).map((group) => [
            group.label && (
              <Cell key={group.id + `-group`} span={12}>
                <LabelMedium marginBottom="scale200" marginTop="scale600">
                  {group.label}
                </LabelMedium>
                <hr
                  className={css({
                    borderWidth: "0px",
                    height: "1px",
                    backgroundColor: "#eee",
                  })}
                />
              </Cell>
            ),
            group.fields
              .filter(
                (f) =>
                  f.show.visible &&
                  ((f.typeName &&
                    f.typeName === data?.address?.subject?.__typename) ||
                    !f.typeName)
              )
              .map((item, index) => (
                <Cell span={item.span || 6} key={group.id + `-field` + index}>
                  <FormControl label={item.label} disabled={true}>
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : item.typeName ? (
                      item.typeName === "User" ? (
                        <FormattedValue
                          dataType={item.dataType}
                          data={data?.address?.subject?.id}
                        >
                          {`${data?.address?.subject?.firstName} ${data?.address?.subject?.lastName}`}
                        </FormattedValue>
                      ) : item.typeName === "DictionaryValue" ? (
                        <FormattedValue
                          dataType={item.dataType}
                          data={
                            item.show.accessor
                              ? data?.address[item.show.accessor[0]]?.[
                                  item.show.accessor[1]
                                ]
                              : data?.address[item.id]
                          }
                        >
                          {`${data?.address?.subject?.name}`}
                        </FormattedValue>
                      ) : (
                        <FormattedValue
                          dataType={item.dataType}
                          data={data?.address?.subject?.id}
                        >
                          {item.show.accessor
                            ? data?.address[item.show.accessor[0]]?.[
                                item.show.accessor[1]
                              ]
                            : data?.address[item.id]}
                        </FormattedValue>
                      )
                    ) : item.id === "address" ? (
                      <FormattedValue>
                        {data?.address?.streetName
                          ? `${data?.address?.streetName} ${
                              data?.address?.buildingNumber
                            }${
                              data?.address?.apartmentNumber
                                ? "/" + data?.address?.apartmentNumber
                                : ""
                            }`
                          : data?.address?.address}
                      </FormattedValue>
                    ) : (
                      <FormattedValue
                        dataType={item.dataType}
                        data={data?.address[item.id]}
                      >
                        {item.show.accessor
                          ? data?.address[item.show.accessor[0]]?.[
                              item.show.accessor[1]
                            ]
                          : data?.address[item.id]}
                      </FormattedValue>
                    )}
                  </FormControl>
                </Cell>
              )),
          ])}
          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Konta bankowe
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={12}>
            {isFetching ? (
              <Skeleton rows={0} height="200px" width="100%" animation />
            ) : (
              data?.address?.bankAccounts && (
                <Block paddingTop="scale200" paddingBottom="scale600">
                  <Table<BankAccount>
                    compact
                    columns={columns}
                    data={data?.address?.bankAccounts}
                  />
                </Block>
              )
            )}
          </Cell>
          <ConfirmDialog
            isOpen={isAddressHideConfirmDialogOpen}
            label={`Ukrycie adresu ${data?.address?.name}`}
            close={() => setIsAddressHideConfirmDialogOpen(false)}
            confirm={() => submit("Hide")}
          />
          <ConfirmDialog
            isOpen={isChangeAddressStatusConfirmDialogOpen}
            label={
              isSensitive
                ? `Oznaczenie adresu ${data?.address?.name} jako dane publiczne`
                : `Oznaczenie adresu ${data?.address?.name} jako dane osobowe`
            }
            close={() => setIsChangeAddressStatusConfirmDialogOpen(false)}
            confirm={() => submit("UpdateStatus")}
          />
        </Grid>
      </Content>
    </article>
  );
}
