export enum ENadawcaKategoria {
  Ekonomiczna = "EKONOMICZNA",
  Priorytetowa = "PRIORYTETOWA",
}

export enum ENadawcaGabaryt {
  Gabaryt_A = "GABARYT_A",
  Gabaryt_B = "GABARYT_B",
}

export enum ENadawcaFormat {
  S = "S",
  M = "M",
  L = "L",
}

export enum ENadawcaAddressType {
  AdresatIndywidualny = "ADRESAT_INDYWIDUALNY",
  AdresatUmowny = "ADRESAT_UMOWNY",
  Oddzial = "ODDZIAL",
}

export type ENadawcaAdres = {
  nazwa: string;
  nazwa2: string;
  ulica: string;
  numerDomu: string;
  numerLokalu: string;
  miejscowosc: string;
  kodPocztowy: string;
  kraj: string;
  telefon: string;
  email: string;
  mobile: string;
  osobaKontaktowa: string;
  nip: string;
};

export type DeklaracjaCelna = {
  szczegoly: DeklaracjaCelnaSzczegoly;
  attributes: DeklaracjaCelnaAttributes;
};

export type DeklaracjaCelnaSzczegoly = {
  attributes: {
    zawartosc: string;
    ilosc: number;
    masa: number;
    wartosc: number;
    numerTaryfowy: string;
    krajPochodzenia: string;
  };
};

export type DeklaracjaCelnaAttributes = {
  podarunek: boolean;
  dokument: boolean;
  probkaHandlowa: boolean;
  zwrotTowaru: boolean;
  towary: boolean;
  inny: boolean;
  wyjasnienie: string;
  oplatyPocztowe: string;
  uwagi: string;
  licencja: string;
  swiadectwo: string;
  faktura: string;
  numerReferencyjnyImportera: string;
  numerTelefonuImportera: string;
  waluta: string;
};

export type DeklaracjaCelna2 = {
  rodzaj: DeklaracaCelnaRodzaj;
  zawartoscPrzesylki: ZawartoscPrzesylkiZagranicznej;
  dokumentyTowarzyszace: DokumentyTowarzyszace;
  wyjasnienie: string;
  oplatyPocztowe: string;
  uwagi: string;
  numerReferencyjnyImportera: string;
  numerTelefonuImportera: string;
  walutaKodISO: string;
  szczegolyZawartosciPrzesylki: SzczegolyZawartosciPrzesylkiZagranicznej;
  numerReferencyjnyCelny: string;
};

export type DokumentyTowarzyszace = {
  rodzaj: DokumentTowarzyszacyRodzaj;
  numer: string;
};

export type SzczegolyZawartosciPrzesylkiZagranicznej = {
  okreslenieZawartosci: string;
  ilosc: number;
  masaNetto: number;
  wartosc: number;
  numerTaryfyHs: string;
  krajPochodzeniaKodAlfa2: string;
};

export enum DeklaracjaCelnaRodzaj {
  CN22 = "CN22",
  CN23 = "CN23",
}

export enum ZawartoscPrzesylkiZagranicznej {
  SPRZEDAZ_TOWARU = "SPRZEDAZ_TOWARU",
  ZWROT_TOWARU = "ZWROT_TOWARU",
  PREZENT = "PREZENT",
  PROBKA_HANDLOWA = "PROBKA_HANDLOWA",
  DOKUMENT = "DOKUMENT",
  INNE = "INNE",
}

export enum DokumentyTowarzyszaceRodzaj {
  LICENCJA = "LICENCJA",
  CERTYFIKAT = "CERTYFIKAT",
  FAKTURA = "FAKTURA",
}

export type Zwrot = {
  zwrotPoLiczbieDni: number;
  traktowacJakPorzucona: boolean;
  sposobZwrotu: string;
};

export type SposobDoreczenia = {
  kod: stringl;
  identyfikatorPunktuOdbioru: stringl;
};
