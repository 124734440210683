import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { StyledLink } from "baseui/link";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { MouseEvent, useEffect, useState } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { Row } from "react-table";
import { Eye, Pencil, Plus, Trash } from "tabler-icons-react";

import Button from "../../../components/button";
import Cell from "../../../components/cell";
import ConfirmDialog from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import FormControl from "../../../components/form-control";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import Table from "../../../components/table";
import { useLoading } from "../../../contexts/loading-context";
import { checkPermission } from "../../../utils/check-permission";
import { PERMISSIONS } from "../../../utils/permissions";
import { ShipmentRate } from "../../ShipmentContracts/shipment-contracts";
import { SHIPMENT_CONTRACTS_FIELDS } from "../shipment-contracts.form";
import {
  SHIPMENT_CONTRACTS_DELETE,
  SHIPMENT_CONTRACTS_SHOW,
} from "../shipment-contracts.gql";

export default function ShipmentContractsShowContract(): React.ReactElement {
  const [css, theme] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const history = useHistory();
  const { contractId } = useParams<{ contractId?: string }>();
  const { setIsLoading, isFetching, setIsFetching } = useLoading();

  const { refetch, data, error } = useQuery(SHIPMENT_CONTRACTS_SHOW, {
    variables: { id: contractId ? parseInt(contractId) : null },
  });
  const [deleteShipmentContract] = useMutation(SHIPMENT_CONTRACTS_DELETE);

  const submit = async () => {
    setIsLoading(true);

    try {
      await deleteShipmentContract({
        variables: {
          shipmentContractDeleteInput: {
            id: contractId ? parseInt(contractId) : null,
          },
        },
      });

      enqueueSnackbar({
        message: "Usunięto pomyślnie",
        variant: "success",
      });

      setIsConfirmDialogOpen(false);

      history.push("/shipment-contracts");
    } catch (error: unknown) {
      setIsConfirmDialogOpen(false);

      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    if (data?.shipmentContract) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => data?.shipmentContract && setIsFetching(false), [data]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Nazwa",
        accessor: "name",
        Cell: ({ row }: { row: Row<ShipmentRate> }) => (
          <StyledLink
            {...(checkPermission(PERMISSIONS.shipmentRate.read)
              ? {
                  onClick: (event: MouseEvent) => {
                    event.preventDefault();
                    history.push(
                      `/shipment-contracts/${contractId}/${row.original.id}`
                    );
                  },
                  href: `/shipment-contracts/${contractId}/${row.original.id}`,
                }
              : {
                  $style: { cursor: "not-allowed" },
                })}
          >
            {row.original.name}
          </StyledLink>
        ),
      },
      {
        Header: "Symbol",
        id: "symbol",
        Cell: ({ row }: { row: Row<ShipmentRate> }) => (
          <FormattedValue>{row.original.symbol}</FormattedValue>
        ),
      },
      {
        Header: (
          <Block display="flex" justifyContent="flex-end">
            Szerokość
          </Block>
        ),
        id: "width",
        Cell: ({ row }: { row: Row<ShipmentRate> }) => (
          <Block display="flex" justifyContent="flex-end">
            <FormattedValue dataType="dimensions">
              {`${row.original.widthMin} - ${row.original.widthMax}`}
            </FormattedValue>
          </Block>
        ),
      },
      {
        Header: (
          <Block display="flex" justifyContent="flex-end">
            Wysokość
          </Block>
        ),
        id: "height",
        Cell: ({ row }: { row: Row<ShipmentRate> }) => (
          <Block display="flex" justifyContent="flex-end">
            <FormattedValue dataType="dimensions">
              {`${row.original.heightMin} - ${row.original.heightMax}`}
            </FormattedValue>
          </Block>
        ),
      },
      {
        Header: (
          <Block display="flex" justifyContent="flex-end">
            Długość
          </Block>
        ),
        id: "length",
        Cell: ({ row }: { row: Row<ShipmentRate> }) => (
          <Block display="flex" justifyContent="flex-end">
            <FormattedValue dataType="dimensions">
              {`${row.original.lengthMin} - ${row.original.lengthMax}`}
            </FormattedValue>
          </Block>
        ),
      },
      {
        Header: (
          <Block display="flex" justifyContent="flex-end">
            Waga
          </Block>
        ),
        id: "weigth",
        Cell: ({ row }: { row: Row<ShipmentRate> }) => (
          <Block display="flex" justifyContent="flex-end">
            <FormattedValue dataType="weight">
              {`${row.original.weightMin} - ${row.original.weightMax}`}
            </FormattedValue>
          </Block>
        ),
      },
      {
        Header: (
          <Block display="flex" justifyContent="flex-end">
            Cena
          </Block>
        ),
        id: "price",
        Cell: ({ row }: { row: Row<ShipmentRate> }) => (
          <Block display="flex" justifyContent="flex-end">
            <FormattedValue dataType="price">
              {row.original.price}
            </FormattedValue>
          </Block>
        ),
      },
      {
        Header: "Aktywna",
        id: "isActive",
        Cell: ({ row }: { row: Row<ShipmentRate> }) => (
          <FormattedValue dataType="boolean">
            {row.original.isActive}
          </FormattedValue>
        ),
      },
      {
        Header: "Typ paczki",
        id: "eNadawcaShipmentType",
        Cell: ({ row }: { row: Row<ShipmentRate> }) => (
          <FormattedValue dataType="e-nadawca-shipment-type">
            {row?.original?.eNadawcaShipmentType}
          </FormattedValue>
        ),
      },
      {
        id: "actions",
        Cell: ({ row }: { row: Row<ShipmentRate> }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            {checkPermission(PERMISSIONS.shipmentRate.update) && (
              <Button
                data-test-id={`edit-rate-${row.index + 1}`}
                kind={KIND.secondary}
                size={SIZE.mini}
                onClick={() =>
                  history.push(
                    `/shipment-contracts/${contractId}/${row.original.id}/edit-rate`
                  )
                }
                startEnhancer={<Pencil size={14} />}
              />
            )}

            {checkPermission(PERMISSIONS.shipmentRate.read) && (
              <Button
                data-test-id={`show-rate-${row.index + 1}`}
                kind={KIND.secondary}
                size={SIZE.mini}
                $style={{ marginLeft: "6px" }}
                onClick={() =>
                  history.push(
                    `/shipment-contracts/${contractId}/${row.original.id}`
                  )
                }
                startEnhancer={<Eye size={14} />}
              />
            )}
          </div>
        ),
      },
    ],
    []
  );

  if (!checkPermission(PERMISSIONS.shipmentContract.read))
    return <Redirect to="/" />;

  return (
    <article>
      <Header
        title={data?.shipmentContract?.name}
        labels={["Cenniki", "Wyświetlanie"]}
        goBackOption
        buttons={[
          {
            label: "Edytuj",
            kind: KIND.secondary,
            startEnhancer: <Pencil size={18} />,
            permission: checkPermission(PERMISSIONS.shipmentContract.update),
            onClick: () =>
              history.push(`/shipment-contracts/${contractId}/edit-contract`),
          },
          {
            label: "Dodaj stawkę",
            kind: KIND.primary,
            startEnhancer: <Plus size={18} />,
            permission: checkPermission(PERMISSIONS.shipmentRate.create),
            onClick: () =>
              history.push(`/shipment-contracts/${contractId}/create-rate`),
          },
        ]}
        actions={[
          {
            label: "Usuń cennik",
            icon: Trash,
            color: theme.colors.negative,
            onClick: () => setIsConfirmDialogOpen(true),
            permission: checkPermission(PERMISSIONS.shipmentContract.delete),
          },
        ]}
      />
      <Content>
        <Grid>
          {SHIPMENT_CONTRACTS_FIELDS.filter(
            (g) => g.fields.filter((f) => f.show.visible).length > 0
          ).map((group) => [
            group.label && (
              <Cell key={group.id + `-group`} span={12}>
                <LabelMedium marginBottom="scale200" marginTop="scale600">
                  {group.label}
                </LabelMedium>
                <hr
                  className={css({
                    borderWidth: "0px",
                    height: "1px",
                    backgroundColor: "#eee",
                  })}
                />
              </Cell>
            ),
            group.fields
              .filter((f) => f.show.visible)
              .map((item, index) => (
                <Cell span={item.span || 6} key={group.id + `-field` + index}>
                  <FormControl
                    label={item.label}
                    disabled={true}
                    overrides={{
                      ControlContainer: {
                        props: {
                          "data-test-id": item.id,
                        },
                      },
                    }}
                  >
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : item.id === "validityPeriod" ? (
                      <Block>
                        <FormattedValue dataType="date">
                          {data?.shipmentContract?.beginAt}
                        </FormattedValue>
                        {" - "}
                        <FormattedValue dataType="date">
                          {data?.shipmentContract?.endAt}
                        </FormattedValue>
                      </Block>
                    ) : (
                      <FormattedValue
                        dataType={item.dataType}
                        data={
                          item.id === "contractAgreementId"
                            ? `incoming/ContractAgreement/${
                                data?.shipmentContract[item.id]
                              }`
                            : data?.shipmentContract[item.id]
                        }
                      >
                        {item.id === "shipmentApi"
                          ? "Poczta Polska eNadawca API"
                          : item.show.accessor
                          ? data?.shipmentContract[item.show.accessor[0]]?.[
                              item.show.accessor[1]
                            ]
                          : data?.shipmentContract[item.id]}
                      </FormattedValue>
                    )}
                  </FormControl>
                </Cell>
              )),
          ])}
          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Stawki
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={12} $style={{ position: "relative" }}>
            {isFetching ? (
              <Skeleton rows={0} height="300px" width="100%" animation />
            ) : (
              data && (
                <Block paddingTop="scale200" paddingBottom="scale600">
                  <Table<ShipmentRate>
                    compact
                    columns={columns}
                    data={data?.shipmentContract?.shipmentRates}
                    stickLastColumn
                  />
                </Block>
              )
            )}
          </Cell>
          <ConfirmDialog
            isOpen={isConfirmDialogOpen}
            label={`Usunięcie cennika ${data?.shipmentContract?.name}`}
            close={() => setIsConfirmDialogOpen(false)}
            confirm={submit}
          />
        </Grid>
      </Content>
    </article>
  );
}
