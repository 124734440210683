import { gql } from "@apollo/client";

export const SHIPMENT_CONTRACTS_INDEX = gql`
  query(
    $pageSize: Int
    $offset: Int
    $sorting: [ShipmentContractSort!]
    $filter: ShipmentContractFilter
  ) {
    shipmentContracts(
      paging: { limit: $pageSize, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        name
        description
        companyName
        isActive
        contractAgreementId
        contractAgreement {
          id
          internalNumber
          beginAt
          endAt
          internalNumber
          documentType
          type
          documentKind
        }
        beginAt
        endAt
        createdAt
        updatedAt
        shipmentRates {
          id
          symbol
          name
          price
        }
      }
    }
  }
`;

export const SHIPMENT_CONTRACTS_SHOW = gql`
  query($id: Int!) {
    shipmentContract(id: $id) {
      name
      description
      companyName
      isActive
      contractAgreementId
      contractAgreement {
        id
        internalNumber
        documentNumber
        documentDate
      }
      beginAt
      endAt
      createdAt
      updatedAt
      shipmentRates {
        id
        symbol
        name
        price
        widthMin
        widthMax
        heightMin
        heightMax
        lengthMin
        lengthMax
        weightMin
        weightMax
        isActive
        eNadawcaShipmentType
      }
    }
  }
`;

export const SHIPMENT_CONTRACTS_CREATE = gql`
  mutation CreateShipmentContract(
    $shipmentContractCreateInput: ShipmentContractCreateInput!
  ) {
    shipmentContractCreate(
      shipmentContractCreateInput: $shipmentContractCreateInput
    ) {
      id
    }
  }
`;

export const SHIPMENT_CONTRACTS_UPDATE = gql`
  mutation EditShipmentContract(
    $shipmentContractUpdateInput: ShipmentContractUpdateInput!
  ) {
    shipmentContractUpdate(
      shipmentContractUpdateInput: $shipmentContractUpdateInput
    ) {
      id
    }
  }
`;

export const SHIPMENT_CONTRACTS_DELETE = gql`
  mutation DeleteShipmentContract(
    $shipmentContractDeleteInput: ShipmentContractDeleteInput!
  ) {
    shipmentContractDelete(
      shipmentContractDeleteInput: $shipmentContractDeleteInput
    ) {
      id
    }
  }
`;

export const SHIPMENT_RATES_SHOW = gql`
  query($id: Int!) {
    shipmentRate(id: $id) {
      id
      shipmentContractId
      shipmentContract {
        name
      }
      shipmentType
      eNadawcaShipmentType
      description
      symbol
      name
      isActive
      price
      widthMin
      widthMax
      heightMin
      heightMax
      lengthMin
      lengthMax
      weightMin
      weightMax
      isCountryService
      countryCodes
      createdAt
      updatedAt
    }
  }
`;

export const SHIPMENT_RATES_CREATE = gql`
  mutation CreateShipmentRate(
    $shipmentRateCreateInput: ShipmentRateCreateInput!
  ) {
    shipmentRateCreate(shipmentRateCreateInput: $shipmentRateCreateInput) {
      id
      shipmentContractId
    }
  }
`;

export const SHIPMENT_RATES_UPDATE = gql`
  mutation EditShipmentRate(
    $shipmentRateUpdateInput: ShipmentRateUpdateInput!
  ) {
    shipmentRateUpdate(shipmentRateUpdateInput: $shipmentRateUpdateInput) {
      id
    }
  }
`;

export const SHIPMENT_RATES_DELETE = gql`
  mutation DeleteShipmentRate(
    $shipmentRateDeleteInput: ShipmentRateDeleteInput!
  ) {
    shipmentRateDelete(shipmentRateDeleteInput: $shipmentRateDeleteInput) {
      id
    }
  }
`;
