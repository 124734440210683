import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { StyledLink } from "baseui/link";
import { useSnackbar } from "notistack";
import React, { MouseEvent, useEffect, useMemo, useState } from "react";
import { Redirect, useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { Cell as TableCell, Row } from "react-table";
import { Eye, Lock, Pencil, Refresh } from "tabler-icons-react";

import BottomPanel from "../../../components/bottom-panel";
import Button from "../../../components/button";
import Cell from "../../../components/cell";
import ConfirmDialog from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import Filters from "../../../components/filters";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import PagingControls from "../../../components/paging-controls";
import SortingTableHeader, {
  SortDirection,
} from "../../../components/sorting-table-header";
import Table from "../../../components/table";
import Tooltip from "../../../components/tooltip";
import { useLoading } from "../../../contexts/loading-context";
import { usePaging } from "../../../contexts/paging-context";
import { FiltersState } from "../../../filters";
import { checkPermission } from "../../../utils/check-permission";
import { translateFiltersState } from "../../../utils/filters";
import { PERMISSIONS } from "../../../utils/permissions";
import { setSortingParams } from "../../../utils/sorting";
import { Role } from "../../Roles/roles";
import { User } from "../users";
import { USERS_FILTERS } from "../users.filters";
import { USERS_PAGINATED_INDEX, USERS_SYNC_WITH_AD } from "../users.gql";

enum FieldName {
  FirstName = "firstName",
  LastName = "lastName",
  Email = "email",
  LoginAD = "loginAD",
  LastLoginAt = "lastLoginAt",
}

export default function UsersIndex(): React.ReactElement {
  const { pageSize, currentPage, setTotalCount } = usePaging();
  const { search } = useLocation();

  const params = useMemo(() => new URLSearchParams(search), [search]);

  const [sortBy, setSortBy] = useState<FieldName | null>(
    (params.get("sortBy") as FieldName) || FieldName.LastName
  );

  const [sortDirection, setSortDirection] = useState<SortDirection | null>(
    (params.get("sortDirection") as SortDirection) || SortDirection.ASC
  );

  const [isConfirmSyncDialogOpen, setIsConfirmSyncDialogOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [css, theme] = useStyletron();
  const {
    isPartialFetching,
    setIsPartialFetching,
    isFetching,
    setIsFetching,
    setIsLoading,
  } = useLoading();
  const history = useHistory();

  const [filters, setFilters] = useState<FiltersState>();

  const handleSorting = (column: FieldName) => {
    setSortingParams(history, search, column, sortDirection);
    setSortBy(column);
    setSortDirection(
      sortDirection === null
        ? SortDirection.DESC
        : sortDirection === SortDirection.ASC
        ? SortDirection.DESC
        : SortDirection.ASC
    );
  };

  const { refetch, data, error, loading } = useQuery(USERS_PAGINATED_INDEX, {
    variables: {
      pageSize,
      offset: (currentPage - 1) * pageSize,
      ...(filters && { filter: translateFiltersState(filters) }),
      sorting: {
        field: sortBy,
        direction: sortDirection,
      },
    },
  });

  useEffect(() => {
    if (data?.usersPaginated) setTimeout(() => refetch(), 200);
    setIsFetching(true);
  }, []);

  useEffect(() => {
    setTimeout(() => refetch(), 200);
    setIsPartialFetching(true);
  }, [currentPage, pageSize]);

  useEffect(() => {
    setTimeout(() => refetch(), 200);
    setIsPartialFetching(true);
  }, [sortBy, sortDirection]);

  useEffect(() => {
    if (data?.usersPaginated) setIsFetching(false);
    if (data?.usersPaginated?.totalCount >= 0)
      setTotalCount(data?.usersPaginated?.totalCount);
  }, [data]);

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  const [syncWithAD] = useMutation(USERS_SYNC_WITH_AD);

  const submitSyncWithAD = async () => {
    setIsLoading(true);

    try {
      await syncWithAD();

      setIsConfirmSyncDialogOpen(false);

      enqueueSnackbar({
        message: "Zlecono wykonanie synchronizacji",
        variant: "success",
      });

      refetch();
    } catch (error: unknown) {
      setIsConfirmSyncDialogOpen(false);
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Imię i nazwisko",
        id: "fullName",
        Cell: ({ row }: { row: Row<User> }) => (
          <>
            <FormattedValue data={row.original.id} dataType="model:users">
              {`${row.original.firstName} ${row.original.lastName}`}
            </FormattedValue>
            {row.original.isBlockedByAdmin && (
              <Tooltip content="Użytkownik zablokowany">
                <span>
                  <Lock
                    size={14}
                    className={css({
                      verticalAlign: "middle",
                      marginLeft: "6px",
                      display: "inline",
                      color: theme.colors.negative,
                    })}
                  />
                </span>
              </Tooltip>
            )}
          </>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.FirstName)}
            sortDirection={
              sortBy === FieldName.FirstName ? sortDirection : null
            }
          >
            Imię
          </SortingTableHeader>
        ),
        id: "firstName",
        disableFilters: true,
        Cell: ({ row }: { row: Row<User> }) => (
          <>
            <StyledLink
              onClick={(event: MouseEvent) => {
                event.preventDefault();
                history.push(`/users/${row.original.id}`);
              }}
              href={`/users/${row.original.id}`}
            >
              {row.original.firstName}
            </StyledLink>
            {row.original.isBlockedByAdmin && (
              <Tooltip content="Użytkownik zablokowany">
                <span>
                  <Lock
                    size={14}
                    className={css({
                      verticalAlign: "middle",
                      marginLeft: "6px",
                      display: "inline",
                      color: theme.colors.negative,
                    })}
                  />
                </span>
              </Tooltip>
            )}
          </>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.LastName)}
            sortDirection={sortBy === FieldName.LastName ? sortDirection : null}
          >
            Nazwisko
          </SortingTableHeader>
        ),
        id: "lastName",
        disableFilters: true,
        Cell: ({ row }: { row: Row<User> }) => (
          <>
            <StyledLink
              onClick={(event: MouseEvent) => {
                event.preventDefault();
                history.push(`/users/${row.original.id}`);
              }}
              href={`/users/${row.original.id}`}
            >
              {row.original.lastName}
            </StyledLink>
            {row.original.isBlockedByAdmin && (
              <Tooltip content="Użytkownik zablokowany">
                <span>
                  <Lock
                    size={14}
                    className={css({
                      verticalAlign: "middle",
                      marginLeft: "6px",
                      display: "inline",
                      color: theme.colors.negative,
                    })}
                  />
                </span>
              </Tooltip>
            )}
          </>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.Email)}
            sortDirection={sortBy === FieldName.Email ? sortDirection : null}
          >
            Adres e-mail
          </SortingTableHeader>
        ),
        accessor: "email",
        Cell: ({ cell }: { cell: TableCell }) => (
          <FormattedValue dataType="email">{cell.value}</FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.LoginAD)}
            sortDirection={sortBy === FieldName.LoginAD ? sortDirection : null}
          >
            Login AD
          </SortingTableHeader>
        ),
        accessor: "loginAD",
        Cell: ({ cell }: { cell: TableCell }) => (
          <FormattedValue>{cell.value}</FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.LastLoginAt)}
            sortDirection={
              sortBy === FieldName.LastLoginAt ? sortDirection : null
            }
            $style={{ display: "flex", justifyContent: "flex-end" }}
          >
            Ostatnie logowanie
          </SortingTableHeader>
        ),
        accessor: "lastLoginAt",
        Cell: ({ cell }: { cell: TableCell }) => (
          <Block display="flex" justifyContent="flex-end">
            <FormattedValue dataType="datetime">{cell.value}</FormattedValue>
          </Block>
        ),
      },
      {
        Header: "Role",
        accessor: "roles",
        Cell: ({ cell }: { cell: TableCell }) => (
          <div>
            {cell?.value?.map((role: Role) => (
              <>
                <FormattedValue
                  dataType="model:roles"
                  data={role?.id}
                  key={role.id}
                >
                  {role?.name}
                </FormattedValue>
                <br />
              </>
            ))}
          </div>
        ),
      },
      {
        id: "actions",
        Cell: ({ row }: { row: Row<User> }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            {checkPermission(PERMISSIONS.user.update) && (
              <Button
                kind={KIND.secondary}
                size={SIZE.mini}
                onClick={() => history.push(`/users/${row.original.id}/edit`)}
                startEnhancer={<Pencil size={14} />}
              />
            )}
            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              $style={{ marginLeft: "6px" }}
              onClick={() => history.push(`/users/${row.original.id}`)}
              startEnhancer={<Eye size={14} />}
            />
          </div>
        ),
      },
    ],
    [sortBy, sortDirection, data, filters]
  );

  if (!checkPermission(PERMISSIONS.user.read)) return <Redirect to="/" />;

  return (
    <article>
      <Header
        title="Użytkownicy"
        recordsNum={data?.usersPaginated?.totalCount}
        labels={["Lista"]}
        actions={[
          {
            label: "Zsynchronizuj użytkowników z bazą Active Directory",
            icon: Refresh,
            color: theme.colors.primary,
            onClick: () => setIsConfirmSyncDialogOpen(true),
            permission: checkPermission(
              PERMISSIONS.user.requestSynchronization
            ),
          },
        ]}
      />
      <Filters filters={USERS_FILTERS} state={filters} setState={setFilters} />
      <Content filtersOffset>
        <Grid>
          <Cell span={12} $style={{ position: "relative" }}>
            <Table<User>
              columns={columns}
              data={data?.usersPaginated?.nodes}
              isLoading={isFetching || isPartialFetching || loading}
              stickLastColumn
            />
          </Cell>

          <Cell span={12}>
            <BottomPanel>
              <PagingControls />
            </BottomPanel>
          </Cell>

          <ConfirmDialog
            isOpen={isConfirmSyncDialogOpen}
            label={`Synchronizacja użytkowników z bazy Active Directory`}
            close={() => setIsConfirmSyncDialogOpen(false)}
            confirm={() => submitSyncWithAD()}
          />
        </Grid>
      </Content>
    </article>
  );
}
