import { Checkbox as BaseCheckbox, CheckboxProps } from "baseui/checkbox";
import { ChangeEvent } from "react";
import React from "react";
import { Controller, UseControllerProps } from "react-hook-form";
import { StyleObject } from "styletron-react";

type Props = {
  $style?: StyleObject;
  disabled?: boolean;
} & CheckboxProps;

const Checkbox = ({
  $style,
  name,
  disabled,
  ...rest
}: Props): React.ReactElement => {
  return (
    <BaseCheckbox
      name={name}
      overrides={{
        Root: {
          props: {
            id: name,
          },
          style: {
            display: "flex",
            alignItems: "center",
            ...$style,
          },
        },
        Checkmark: {
          style: ({ $theme }) => ({
            borderTopLeftRadius: $theme.borders.radius200,
            borderTopRightRadius: $theme.borders.radius200,
            borderBottomRightRadius: $theme.borders.radius200,
            borderBottomLeftRadius: $theme.borders.radius200,
            borderLeftWidth: "2px",
            borderTopWidth: "2px",
            borderRightWidth: "2px",
            borderBottomWidth: "2px",
            backgroundSize: "9px",
            width: "18px",
            height: "18px",
          }),
        },
        Label: {
          style: {
            fontSize: "14px",
          },
        },
      }}
      disabled={disabled}
      {...rest}
    />
  );
};

export default Checkbox;

export function ControlledCheckbox({
  control,
  name,
  rules,
  disabled,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & Props): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Checkbox
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChange(e.target.checked)
          }
          onBlur={onBlur}
          checked={value}
          name={name}
          disabled={disabled}
          {...rest}
        />
      )}
    />
  );
}
