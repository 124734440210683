import { useStyletron } from "baseui";
import { KIND, SIZE } from "baseui/button";
import { StyledLink } from "baseui/link";
import React, { useMemo } from "react";
import { Row } from "react-table";

import Button from "./button";
import FormattedValue from "./formatted-value";
import Table from "./table";

type attachmentsTableProps = {
  data: File[];
};

type ExtendedFile = File & {
  downloadUrl?: string;
  originalName?: string;
  mimetype?: string;
  createdAt?: string;
};

export default function AttachmentsTable({
  data,
}: attachmentsTableProps): React.ReactElement {
  const [css] = useStyletron();

  const columns = useMemo(
    () => [
      {
        Header: "Lp.",
        id: "ordinalNumber",
        Cell: ({ row }: { row: Row<ExtendedFile> }) => (
          <FormattedValue>{row?.index + 1}</FormattedValue>
        ),
      },
      {
        Header: "Nazwa",
        id: "name",
        Cell: ({ row }: { row: Row<ExtendedFile> }) => (
          <>
            <StyledLink
              href={`${process.env.REACT_APP_GRAPHQL_API_URL?.replace(
                "/graphql",
                ""
              )}${row?.original?.downloadUrl}`}
            >
              {row?.original?.originalName}
            </StyledLink>
          </>
        ),
      },
      {
        Header: "Typ",
        id: "fileType",
        Cell: ({ row }: { row: Row<ExtendedFile> }) => (
          <FormattedValue dataType="pre" $style={{ fontSize: "12px" }}>
            {row?.original?.mimetype?.split("/")[1].toUpperCase()}
          </FormattedValue>
        ),
      },
      {
        Header: "Data dodania",
        id: "createdAt",
        Cell: ({ row }: { row: Row<ExtendedFile> }) => (
          <FormattedValue dataType="datetime">
            {row?.original?.createdAt}
          </FormattedValue>
        ),
      },
      {
        id: "actions",
        Cell: ({ row }: { row: Row<ExtendedFile> }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            <StyledLink
              href={`${process.env.REACT_APP_GRAPHQL_API_URL?.replace(
                "/graphql",
                ""
              )}${row?.original?.downloadUrl}`}
              $style={{ textDecoration: "none !important" }}
            >
              <Button kind={KIND.primary} size={SIZE.mini}>
                Pobierz
              </Button>
            </StyledLink>
          </div>
        ),
      },
    ],
    [data]
  );

  return (
    <Table<File> id="attachmentsTable" compact columns={columns} data={data} />
  );
}
