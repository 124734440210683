import { BasicFilter, FILTERS } from "./../../filters.d";

export const SHIPMENT_CONTRACTS_FILTERS: BasicFilter[] = [
  {
    id: "name",
    label: "Nazwa",
    type: FILTERS.Text,
  },
  {
    id: "beginAt",
    label: "Obowiązuje od",
    type: FILTERS.Date,
  },
  {
    id: "endAt",
    label: "Obowiązuje do",
    type: FILTERS.Date,
  },
  {
    id: "isActive",
    label: "Aktywność",
    type: FILTERS.Boolean,
  },
];
