import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { LabelSmall, MonoLabelXSmall } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";
import { Redirect, useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { Row } from "react-table";
import { Eye, TableExport } from "tabler-icons-react";

import BottomPanel from "../../../components/bottom-panel";
import Button from "../../../components/button";
import Cell from "../../../components/cell";
import Content from "../../../components/content";
import Filters from "../../../components/filters";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import PagingControls from "../../../components/paging-controls";
import SortingTableHeader, {
  SortDirection,
} from "../../../components/sorting-table-header";
import Table from "../../../components/table";
import { useLoading } from "../../../contexts/loading-context";
import { usePaging } from "../../../contexts/paging-context";
import { FiltersState } from "../../../filters";
import { checkPermission } from "../../../utils/check-permission";
import { translateFiltersState } from "../../../utils/filters";
import { PERMISSIONS } from "../../../utils/permissions";
import { setSortingParams } from "../../../utils/sorting";
import { BudgetLedgerItem } from "../budget-ledger-items";
import { BUDGET_LEDGER_ITEMS_FILTERS } from "../budget-ledger-items.filters";
import {
  BUDGET_LEDGER_ITEMS_INDEX,
  EXPORT_ALL_BUDGET_ITEMS_TO_XLSX,
} from "../budget-ledger-items.gql";

enum FieldName {
  CreatedAt = "createdAt",
  ValueAfterChange = "valueAfterChange",
  Change = "change",
}

export default function BudgetLedgerItemsIndex(): React.ReactElement {
  const { pageSize, currentPage, setTotalCount } = usePaging();
  const { search } = useLocation();

  const params = useMemo(() => new URLSearchParams(search), [search]);

  const [sortBy, setSortBy] = useState<FieldName | null>(
    (params.get("sortBy") as FieldName) || FieldName.CreatedAt
  );

  const [sortDirection, setSortDirection] = useState<SortDirection | null>(
    (params.get("sortDirection") as SortDirection) || SortDirection.DESC
  );

  const [css, theme] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const {
    isPartialFetching,
    setIsPartialFetching,
    isFetching,
    setIsFetching,
    setIsLoading,
  } = useLoading();

  const handleSorting = (column: FieldName) => {
    setSortingParams(history, search, column, sortDirection);
    setSortBy(column);
    setSortDirection(
      sortDirection === null
        ? SortDirection.DESC
        : sortDirection === SortDirection.ASC
        ? SortDirection.DESC
        : SortDirection.ASC
    );
  };

  const [filters, setFilters] = useState<FiltersState>();

  const { data, error, loading, refetch } = useQuery(
    BUDGET_LEDGER_ITEMS_INDEX,
    {
      variables: {
        pageSize,
        offset: (currentPage - 1) * pageSize,
        sorting: {
          field: sortBy,
          direction: sortDirection,
        },
        ...(filters && { filter: translateFiltersState(filters) }),
      },
    }
  );

  useEffect(() => {
    if (data?.budgetLedgerItems) setTimeout(() => refetch(), 200);
    setIsFetching(true);
  }, []);

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    setTimeout(() => refetch(), 200);
    setIsPartialFetching(true);
  }, [currentPage, pageSize]);

  useEffect(() => {
    setTimeout(() => refetch(), 200);
    setIsPartialFetching(true);
  }, [sortBy, sortDirection]);

  useEffect(() => {
    if (data?.budgetLedgerItems) setIsFetching(false);
    if (data?.budgetLedgerItems?.totalCount >= 0)
      setTotalCount(data?.budgetLedgerItems?.totalCount);
  }, [data]);

  const [exportXlsxFile] = useMutation(EXPORT_ALL_BUDGET_ITEMS_TO_XLSX);

  const exportAllBudgetItemsToXlsx = async () => {
    setIsLoading(true);

    try {
      const response = await exportXlsxFile();

      enqueueSnackbar({
        message: "Rozpoczęto pobieranie pliku",
        variant: "info",
      });

      window.open(
        `${process.env.REACT_APP_GRAPHQL_API_URL?.replace("/graphql", "")}${
          response?.data?.budgetItemsExportAllToXlsx?.downloadUrl
        }`,
        "_self"
      );
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (!checkPermission(PERMISSIONS.budgetLedgerItems.read))
    return <Redirect to="/" />;

  const columns = React.useMemo(
    () => [
      {
        Header: "Budżet",
        accessor: "budget",
        Cell: ({ row }: { row: Row<BudgetLedgerItem> }) => {
          const position = row?.original?.budgetItem?.budget?.expensePositions?.find(
            (position: { name: string; budgetId: number }) =>
              position?.budgetId === row?.original?.budgetItem?.budgetId
          ) as { name: string };

          return (
            <Block display="flex" flexDirection="column">
              <Block
                display="flex"
                overrides={{ Block: { style: { gap: "5px" } } }}
              >
                <MonoLabelXSmall>
                  {row?.original?.budgetItem?.budget?.bazaId
                    ? `ID: ${row?.original?.budgetItem?.budget?.bazaId}`
                    : ""}
                </MonoLabelXSmall>
                <MonoLabelXSmall>
                  {row?.original?.budgetItem?.budget?.name || ""}
                </MonoLabelXSmall>
              </Block>
              <LabelSmall>
                {`${position?.name || ""}${
                  position?.name &&
                  row?.original?.budgetItem?.budgetCategory?.name
                    ? " | "
                    : " "
                }${row?.original?.budgetItem?.budgetCategory?.path || ""} ${
                  row?.original?.budgetItem?.budgetCategory?.name || ""
                }`}
              </LabelSmall>
            </Block>
          );
        },
      },
      {
        Header: "Pozycja z dokumentu",
        accessor: "financialAccountingDocumentItem",
        Cell: ({ row }: { row: Row<BudgetLedgerItem> }) => (
          <FormattedValue
            dataType="model:documents"
            data={`incoming/FinancialAccountingDocument/${row?.original?.financialAccountingDocumentItem?.financialAccountingDocument?.id}`}
          >
            {row?.original?.financialAccountingDocumentItem?.name}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.Change)}
            sortDirection={sortBy === FieldName.Change ? sortDirection : null}
            $style={{ display: "flex", justifyContent: "flex-end" }}
          >
            Wartość pozycji brutto
          </SortingTableHeader>
        ),
        accessor: "change",
        Cell: ({ row }: { row: Row<BudgetLedgerItem> }) => (
          <Block display="flex" justifyContent="flex-end">
            <FormattedValue dataType="quota">
              {row?.original?.change}
            </FormattedValue>
          </Block>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.ValueAfterChange)}
            sortDirection={
              sortBy === FieldName.ValueAfterChange ? sortDirection : null
            }
            $style={{ display: "flex", justifyContent: "flex-end" }}
          >
            Wartość budżetu po zmianie
          </SortingTableHeader>
        ),
        accessor: "valueAfterChange",
        Cell: ({ row }: { row: Row<BudgetLedgerItem> }) => (
          <Block display="flex" justifyContent="flex-end">
            <FormattedValue dataType="quota">
              {row?.original?.valueAfterChange}
            </FormattedValue>
          </Block>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.CreatedAt)}
            sortDirection={
              sortBy === FieldName.CreatedAt ? sortDirection : null
            }
          >
            Data utworzenia
          </SortingTableHeader>
        ),
        accessor: "createdAt",
        Cell: ({ row }: { row: Row<BudgetLedgerItem> }) => (
          <FormattedValue dataType="date">
            {row.original.createdAt}
          </FormattedValue>
        ),
      },
      {
        id: "actions",
        Cell: ({ row }: { row: Row<BudgetLedgerItem> }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              onClick={() =>
                history.push(`/budget-ledger-items/${row.original.id}`)
              }
              $style={{ marginLeft: "6px" }}
              startEnhancer={<Eye size={14} />}
            />
          </div>
        ),
      },
    ],
    [data, sortBy, sortDirection, filters]
  );

  return (
    <article>
      <Header
        title="Historia zmian budżetowych"
        recordsNum={data?.budgetLedgerItems.totalCount}
        labels={["Lista"]}
        actions={[
          {
            label: "Pobierz plik XLSX z budżetami",
            icon: TableExport,
            color: theme.colors.primary,
            onClick: () => exportAllBudgetItemsToXlsx(),
            permission: checkPermission(PERMISSIONS.requestForm.read),
          },
        ]}
      />
      <Filters
        filters={BUDGET_LEDGER_ITEMS_FILTERS}
        state={filters}
        setState={setFilters}
      />
      <Content filtersOffset>
        <Grid>
          <Cell span={12} $style={{ position: "relative" }}>
            <Table<BudgetLedgerItem>
              columns={columns}
              data={data?.budgetLedgerItems?.nodes}
              isLoading={isFetching || isPartialFetching || loading}
              stickLastColumn
            />
          </Cell>

          <Cell span={12}>
            <BottomPanel>
              <PagingControls />
            </BottomPanel>
          </Cell>
        </Grid>
      </Content>
    </article>
  );
}
