import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { Skeleton } from "baseui/skeleton";
import {
  Table as BaseTable,
  TableProps as BaseTableProps,
} from "baseui/table-semantic";
import React, {
  Fragment,
  memo,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { Cell, Column, Row, useExpanded, useTable } from "react-table";
import { AutoSizer, List } from "react-virtualized";
import {
  CellMeasurer,
  CellMeasurerCache,
} from "react-virtualized/dist/commonjs/CellMeasurer";
import { StyleObject } from "styletron-react";
import { ListSearch } from "tabler-icons-react";

import { PAGE_SIZE } from "../constants";
import { useAuth } from "../contexts/auth-context";
import { usePaging } from "../contexts/paging-context";
import { getDefaultColumns } from "../utils/interface/default-columns";
import {
  getPreferencedColumnOrder,
  getPreferencedColumns,
} from "../utils/interface/preferences";
import { sortColumnsByOrder } from "../utils/table";
import TableCell from "./table-cell";
import TableColumn from "./table-column";
import TableColumnList from "./table-column-list";
import TableHead from "./table-head";

type Props<T> = {
  expandedNumber?: number;
  data: T[];
  columns: Column[];
  compact?: boolean;
  $style?: StyleObject;
  $rootStyle?: StyleObject;
  $tableRootStyle?: StyleObject;
  $tableHeadCellStyle?: StyleObject;
  $tableBodyRowStyle?: StyleObject;
  $rows?: (
    rows: Row[],
    prepareRow: (row: Row) => void,
    visibleColumns: string[],
    orderedColumns: string[]
  ) => React.ReactNode[][];
  $footer?: () => React.ReactNode;
  scrollable?: boolean;
  id?: string;
  isModal?: boolean;
  stickLastColumn?: boolean;
  editable?: boolean;
  virtualized?: boolean;
} & Omit<BaseTableProps, "data" | "columns">;

const cache = new CellMeasurerCache({
  defaultHeight: 40,
  fixedWidth: true,
});

function highlightColumn(event: any) {
  const index = [...event.target.closest("thead > tr")?.children].indexOf(
    event.target.closest("th")
  );

  event.target
    .closest("[data-ui=table-root]")
    ?.querySelector("[data-baseweb=table-semantic] table")
    ?.querySelectorAll(`td:nth-child(${index + 1})`)
    ?.forEach((el: HTMLElement) => (el.style.backgroundColor = "#fbfbfb"));
}

function unhighlightColumn(event: any) {
  const index = [...event.target.closest("thead > tr")?.children].indexOf(
    event.target.closest("th")
  );

  event.target
    .closest("[data-ui=table-root]")
    ?.querySelector("[data-baseweb=table-semantic] table")
    ?.querySelectorAll(`td:nth-child(${index + 1})`)
    ?.forEach((el: HTMLElement) => (el.style.backgroundColor = ""));
}

export default function Table<T>({
  data,
  columns,
  $style,
  $rootStyle,
  $tableRootStyle,
  $tableHeadCellStyle,
  $tableBodyRowStyle,
  $rows,
  $footer,
  expandedNumber,
  scrollable = true,
  id,
  stickLastColumn,
  compact,
  editable,
  emptyMessage,
  virtualized = false,
  ...props
}: Props<T>): React.ReactElement {
  const [css, theme] = useStyletron();

  const [fullWindowMode, setFullWindowMode] = useState(false);
  const [tooltipsReady, setTooltipsReady] = useState(false);

  const tableRef = useRef<HTMLDivElement>(null);

  const { pathname } = useLocation();
  const { interfacePreferences } = useAuth();
  const { pageSize } = usePaging();

  const preferencedColumns = useMemo(
    () => getPreferencedColumns(interfacePreferences, pathname),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { expanded },
  } = useTable(
    {
      columns,
      data: data ? (data as any[]) : [],
      initialState: {
        ...(expandedNumber && {
          expanded: Array.from(new Array(expandedNumber).keys()).reduce(
            (a, _b, index) => ({ ...a, [index]: true }),
            {}
          ),
        }),
      },
      autoResetExpanded: false,
    },
    useExpanded
  );

  const allColumns = useMemo(
    () =>
      headerGroups
        ?.map((headerGroup) => {
          return headerGroup.headers
            .filter((column) => !column.disableFilters)
            .map((column) => column.id);
        })
        .flat(),
    []
  );

  const orderableColumns = useMemo(
    () =>
      headerGroups
        ?.map((headerGroup) => {
          return headerGroup.headers
            .filter(
              (column) => column.id !== "actions" && column.id !== "checkbox"
            )
            .map((column) => column.id);
        })
        .flat(),
    []
  );

  const defaultColumns = getDefaultColumns(pathname) || allColumns;

  const [visibleColumns, setVisibleColumns] = useState<string[]>(
    preferencedColumns || defaultColumns
  );

  const [orderedColumns, setOrderedColumns] = useState<string[]>(
    getPreferencedColumnOrder(interfacePreferences, pathname) ||
      orderableColumns
  );

  const tableColumns = useMemo(
    () =>
      headerGroups
        .map((headerGroup) => {
          return headerGroup.headers
            .filter(
              (column) =>
                visibleColumns?.includes(column.id) ||
                column.id === "actions" ||
                column.id === "checkbox"
            )
            .sort((a, b) => sortColumnsByOrder(a, b, orderedColumns))
            .map((column) => {
              return (
                <TableColumn
                  key={column.id}
                  column={column}
                  editable={editable}
                  tableRef={tableRef}
                />
              );
            });
        })
        .flat(),
    [headerGroups, visibleColumns, orderedColumns]
  );

  const tableRows = useMemo(
    () =>
      rows.map((row: any) => {
        prepareRow(row);
        return row.cells
          .filter(
            (cell: Cell) =>
              visibleColumns.includes(cell.column.id) ||
              cell.column.id === "actions" ||
              cell.column.id === "checkbox"
          )
          .sort(({ column: a }: Cell, { column: b }: Cell) =>
            sortColumnsByOrder(a, b, orderedColumns)
          )
          .map((cell: Cell) => (
            <TableCell key={cell.column.id} cell={cell} editable={editable} />
          ));
      }),
    [rows, visibleColumns, orderedColumns, tooltipsReady]
  );

  const tableCustomRows = useMemo(
    () => $rows && $rows(rows, prepareRow, visibleColumns, orderedColumns),
    [rows, visibleColumns, orderedColumns, tooltipsReady]
  );

  useEffect(() => {
    if (data)
      setTimeout(() => setTooltipsReady((tooltipsReady) => !tooltipsReady), 50);
  }, [data]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      document.querySelectorAll("[data-strict-size]").forEach((element) => {
        if (element.parentElement)
          element.parentElement.style.width = (element as any).dataset.strictSize;
      });
    }
  });

  return (
    <div
      className={css({
        position: fullWindowMode ? "fixed" : "relative",
        scrollSnapType: "x mandatory",
        ...$rootStyle,
        ...(fullWindowMode && {
          inset: "0px",
          zIndex: 50,
          overflow: "auto",
          maxHeight: "100vh",
        }),
      })}
      data-ui="table-root"
    >
      {!compact && (
        <div
          className={css({
            position: "absolute",
            right: "3px",
            top: "3px",
            borderRadius: "8px",
            zIndex: 8,
            paddingLeft: "6px",
            paddingRight: "6px",
            paddingBottom: "4px",
            paddingTop: "4px",
          })}
        >
          <TableColumnList
            headerGroups={headerGroups}
            defaultColumns={defaultColumns}
            orderableColumns={orderableColumns}
            visibleColumns={visibleColumns}
            setVisibleColumns={setVisibleColumns}
            orderedColumns={orderedColumns}
            setOrderedColumns={setOrderedColumns}
          />

          {/* <Tooltip
          content={fullWindowMode ? "Zmniejsz tabelę" : "Powiększ tabelę"}
        >
          <ForwardedButton
            onClick={toggleFullWindowMode}
            kind={fullWindowMode ? "primary" : "secondary"}
            size="mini"
            $style={{ marginLeft: "4px" }}
            startEnhancer={
              fullWindowMode ? (
                <ArrowsMinimize size={18} />
              ) : (
                <ArrowsMaximize size={18} />
              )
            }
          />
        </Tooltip> */}
        </div>
      )}

      <TableHead tableRef={tableRef} compact={compact} />

      <BaseTable
        data={$rows ? (tableCustomRows as any[]) : tableRows}
        columns={tableColumns}
        emptyMessage={() => (
          <Block
            position="sticky"
            left="0"
            width={
              tableRef.current?.closest("[data-ui=table-root]")?.scrollWidth
                ? `${
                    (tableRef?.current?.closest("[data-ui=table-root]")
                      ?.scrollWidth as number) - 40
                  }px`
                : "100%"
            }
          >
            {emptyMessage || (
              <div
                key="error"
                className={css({
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#999999",
                })}
              >
                <ListSearch
                  color="#999999"
                  size={18}
                  className={css({ marginRight: "5px" })}
                />
                Brak rekordów
              </div>
            )}
          </Block>
        )}
        overrides={{
          Root: {
            props: {
              ref: tableRef,
              "data-ui": "table",
              onScroll: (event: any) => {
                const tableRoot = event.target?.closest("[data-ui=table-root]");

                if (tableRoot) {
                  const stickedTableHead = tableRoot?.querySelector(
                    "[data-ui=sticked-table-head]"
                  );

                  if (stickedTableHead) {
                    const scrolling =
                      tableRoot.getAttribute("data-scrolling") === "true"
                        ? true
                        : false;

                    if (scrolling) {
                      tableRoot.setAttribute("data-scrolling", "false");
                      return;
                    }

                    if (
                      stickedTableHead.scrollLeft !==
                      (event.target as HTMLElement).scrollLeft
                    ) {
                      tableRoot.setAttribute("data-scrolling", "true");
                      stickedTableHead.scrollLeft = (event.target as HTMLElement).scrollLeft;
                    }
                  }
                }
              },
            },
            style: {
              ...$tableRootStyle,
            },
          },
          Table: {
            component: ({ $style: $tableStyle, children }: any) => {
              return (
                <table
                  id={id}
                  className={css({
                    maxWidth: "100%",
                    overflowX: "auto",
                    scrollSnapType: "x mandatory",
                    ...$style,
                    ...$tableStyle,
                  })}
                >
                  {children}

                  {$footer && (
                    <tfoot
                      className={css({
                        position: "relative",
                        borderTopWidth: "1px",
                        borderTopStyle: "solid",
                        borderTopColor: "rgb(238, 238, 238)",
                        backgroundColor: "rgb(252, 252, 252)",
                      })}
                    >
                      {$footer()}
                    </tfoot>
                  )}
                </table>
              );
            },
            style: {
              position: "relative",
              borderCollapse: "collapse",
              minWidth: "100%",
              boxSizing: "border-box",
              ...getTableProps()?.style,
            },
          },
          TableBody: {
            style: { ...getTableBodyProps()?.style },
            component: ({ children }: any) => {
              return (
                <tbody
                  className={css({
                    ...getTableBodyProps()?.style,
                    ...(virtualized &&
                      rows.length > 25 && {
                        height: "80vh",
                        width: "100%",
                      }),
                  })}
                >
                  {virtualized && rows.length > 25 ? (
                    <AutoSizer>
                      {({ width, height }) => (
                        <List
                          height={height}
                          width={width}
                          rowCount={rows.length}
                          rowHeight={cache.rowHeight}
                          deferredMeasurementCache={cache}
                          rowRenderer={({ index, key, parent, style }) => (
                            <CellMeasurer
                              cache={cache}
                              columnIndex={0}
                              key={key}
                              parent={parent}
                              rowIndex={index}
                            >
                              <tr
                                key={key}
                                className={css({
                                  ":hover": {
                                    backgroundColor: "#fbfbfb",
                                  },
                                  ...(stickLastColumn && {
                                    ":hover [data-ui=actions]": {
                                      opacity: "100%",
                                    },
                                  }),
                                  borderBottomStyle: "solid",
                                  borderBottomWidth: "0.5px",
                                  borderBottomColor: theme.colors.inputBorder,
                                  display: "flex",
                                  ":last-of-type": {
                                    borderBottomWidth: "0px",
                                  },
                                  ...$tableBodyRowStyle,
                                })}
                                style={style}
                              >
                                {children?.[2]?.[index]?.props?.children}
                              </tr>
                            </CellMeasurer>
                          )}
                        />
                      )}
                    </AutoSizer>
                  ) : (
                    children
                  )}
                </tbody>
              );
            },
          },
          TableHeadCell: {
            style: {
              verticalAlign: "middle",
              zIndex: 12,
              fontSize: "13.5px",
              paddingLeft: "10px",
              paddingRight: "10px",
              whiteSpace: scrollable ? "nowrap" : "pre-wrap",
              ...$tableHeadCellStyle,
            },
            props: {
              onMouseEnter: highlightColumn,
              onMouseLeave: unhighlightColumn,
            },
          },
          TableBodyCell: {
            style: {
              position: "relative",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              verticalAlign: "middle",
              height: "40px",
              paddingLeft: "10px",
              paddingRight: "10px",
              fontSize: "12px",
              borderRightStyle: "solid",
              borderRightWidth: "0.5px",
              borderRightColor: theme.colors.inputBorder,
              scrollSnapAlign: "start",
              flexGrow: 1,
              ":last-of-type": {
                borderRightWidth: "0px",
                ...(stickLastColumn && {
                  position: "sticky",
                  right: 0,
                }),
              },
              ":first-of-type": {
                wordBreak: "break-all",
              },
            },
          },
          TableBodyRow: {
            style: () => ({
              ":hover": {
                backgroundColor: "#fbfbfb",
              },
              ...(stickLastColumn && {
                ":hover [data-ui=actions]": {
                  opacity: "100%",
                },
              }),
              borderBottomStyle: "solid",
              borderBottomWidth: "0.5px",
              borderBottomColor: theme.colors.inputBorder,
              ":last-of-type": {
                borderBottomWidth: "0px",
              },
              ...$tableBodyRowStyle,
            }),
          },
          TableLoadingMessage: {
            component: () => {
              return (
                <Fragment>
                  {[...Array(pageSize || PAGE_SIZE)].map((number, index) => (
                    <div
                      key={`skeleton-${index}`}
                      className={css({
                        borderBottomStyle: "solid",
                        borderBottomWidth: "0.5px",
                        borderBottomColor: theme.colors.inputBorder,
                      })}
                    >
                      <Skeleton height="40px" animation />
                    </div>
                  ))}
                </Fragment>
              );
            },
          },
        }}
        {...props}
      />
    </div>
  );
}

export const MemoizedTable = memo(Table);
