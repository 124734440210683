import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { KIND, SIZE } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router";
import { Cell as TableCell, Row } from "react-table";
import { FileExport, Lock, LockOpen, Plus } from "tabler-icons-react";

import Button from "../../../components/button";
import Cell from "../../../components/cell";
import Content from "../../../components/content";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import Table from "../../../components/table";
import { useLoading } from "../../../contexts/loading-context";
import { checkPermission } from "../../../utils/check-permission";
import { PERMISSIONS } from "../../../utils/permissions";
import {
  DICTIONARIES_EXPORT_TO_XLSX,
  DICTIONARIES_SHOW,
} from "../dictionaries.gql";
import { Dictionary } from "../dictionary";
import ValueCreate from "../ValueCreate";
import ValueDelete from "../ValueDelete";
import ValueUpdate from "../ValueUpdate";

export default function DictionariesShow(): React.ReactElement {
  const [isCreateValueModalOpen, setIsCreateValueModalOpen] = useState<boolean>(
    false
  );
  const [isDeleteValueModalOpen, setIsDeleteValueModalOpen] = useState<boolean>(
    false
  );
  const [isUpdateValueModalOpen, setIsUpdateValueModalOpen] = useState<boolean>(
    false
  );
  const [valueContent, setValueContent] = useState<string>();
  const [valueId, setValueId] = useState<number>();
  const [valueName, setValueName] = useState<string>();
  const { enqueueSnackbar } = useSnackbar();
  const [css, theme] = useStyletron();
  const { id }: { id: string } = useParams();

  const { isFetching, setIsFetching, setIsLoading } = useLoading();

  const [dictionariesExportToXlsx] = useMutation(DICTIONARIES_EXPORT_TO_XLSX, {
    variables: {
      id: id ? parseInt(id) : null,
    },
  });

  const exportDictionariesToXlsx = async () => {
    setIsLoading(true);

    try {
      const response = await dictionariesExportToXlsx();

      enqueueSnackbar({
        message: "Rozpoczęto pobieranie pliku",
        variant: "success",
      });

      window.open(
        `${process.env.REACT_APP_GRAPHQL_API_URL?.replace("/graphql", "")}${
          response?.data?.dictionaryExportToXlsx?.downloadUrl
        }`,
        "_self"
      );
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Nazwa",
        accessor: "name",
        Cell: ({ row }: { row: Row<Dictionary> }) => (
          <div>
            {row.original.isSystem ? (
              <Lock
                size={14}
                className={css({
                  verticalAlign: "middle",
                  marginRight: "10px",
                  marginTop: "-2px",
                  display: "inline",
                  color: theme.colors.negative,
                })}
              />
            ) : (
              <LockOpen
                size={14}
                className={css({
                  verticalAlign: "middle",
                  marginRight: "10px",
                  marginTop: "-2px",
                  display: "inline",
                  color: theme.colors.positive,
                })}
              />
            )}
            {row.original.name}
          </div>
        ),
      },
      {
        Header: "Wartość",
        accessor: "value",
        Cell: ({ cell }: { cell: TableCell }) => (
          <FormattedValue dataType="pre" $style={{ fontSize: "12px" }}>
            {cell.value}
          </FormattedValue>
        ),
      },
      {
        Header: "Widoczność",
        accessor: "isHidden",
        Cell: ({ cell }: { cell: TableCell }) => (
          <FormattedValue dataType="visibility-boolean">
            {cell.value}
          </FormattedValue>
        ),
      },
      {
        id: "actions",
        Cell: ({ row }: { row: Row<Dictionary> }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            {checkPermission(PERMISSIONS.dictionary.valueUpdate) && (
              <Button
                kind={KIND.secondary}
                size={SIZE.mini}
                onClick={() => {
                  setValueId(row.original.id);
                  setValueName(row.original.name);
                  setIsUpdateValueModalOpen(!isUpdateValueModalOpen);
                  setValueContent(row.values.value);
                }}
              >
                Edycja
              </Button>
            )}
            {!row.original.isSystem &&
              checkPermission(PERMISSIONS.dictionary.valueDelete) && (
                <Button
                  kind={KIND.secondary}
                  size={SIZE.mini}
                  onClick={() => {
                    setValueId(row.original.id);
                    setValueName(row.original.name);
                    setIsDeleteValueModalOpen(!isDeleteValueModalOpen);
                  }}
                  $style={{ marginLeft: "5px" }}
                >
                  Usuń
                </Button>
              )}
          </div>
        ),
      },
    ],
    []
  );

  const { data, error, refetch } = useQuery(DICTIONARIES_SHOW, {
    variables: { id: parseInt(id), showHidden: true },
  });

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    if (data?.dictionary?.values) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => data?.dictionary && setIsFetching(false), [data]);

  if (!checkPermission(PERMISSIONS.dictionary.read)) return <Redirect to="/" />;

  return (
    <article>
      <Header
        title={`${data?.dictionary?.name}`}
        recordsNum={data?.dictionary?.values?.length}
        labels={["Słowniki", "Wyświetlanie"]}
        goBackOption
        buttons={
          data &&
          data?.dictionary &&
          !data?.dictionary?.isSystem &&
          checkPermission(PERMISSIONS.dictionary.valueCreate)
            ? [
                {
                  label: "Dodaj wartość",
                  kind: KIND.primary,
                  onClick: () =>
                    setIsCreateValueModalOpen(!isCreateValueModalOpen),
                  startEnhancer: <Plus />,
                },
              ]
            : undefined
        }
        actions={[
          {
            label: "Eksport do pliku XLSX",
            icon: FileExport,
            color: theme.colors.primary,
            permission: checkPermission(PERMISSIONS.dictionary.read),
            onClick: exportDictionariesToXlsx,
          },
        ]}
      />
      <Content>
        <Grid>
          <Cell span={12} $style={{ position: "relative" }}>
            {isFetching ? (
              <Skeleton rows={0} height="300px" width="100%" animation />
            ) : (
              data && (
                <Table<Dictionary>
                  columns={columns}
                  data={data?.dictionary?.values}
                />
              )
            )}
          </Cell>
        </Grid>
      </Content>
      <ValueCreate
        close={() => setIsCreateValueModalOpen(false)}
        dictionaryId={parseInt(id)}
        isOpen={isCreateValueModalOpen}
        onCompleted={refetch}
      />

      <ValueDelete
        close={() => setIsDeleteValueModalOpen(false)}
        id={valueId}
        isOpen={isDeleteValueModalOpen}
        onCompleted={refetch}
        valueName={valueName}
      />

      <ValueUpdate
        close={() => setIsUpdateValueModalOpen(false)}
        currentName={valueName}
        currentValue={valueContent}
        dictionaryId={parseInt(id)}
        id={valueId}
        isOpen={isUpdateValueModalOpen}
        onCompleted={refetch}
      />
    </article>
  );
}
