import { ApolloError, useQuery } from "@apollo/client";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { StyledLink } from "baseui/link";
import { sortBy } from "lodash";
import { useSnackbar } from "notistack";
import React, { MouseEvent, useEffect } from "react";
import { useHistory } from "react-router";
import { Redirect } from "react-router-dom";
import { Cell as TableCell, Row } from "react-table";
import { useStyletron } from "styletron-react";
import { Eye, Pencil, Plus } from "tabler-icons-react";

import Button from "../../../components/button";
import Cell from "../../../components/cell";
import Content from "../../../components/content";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import { SortDirection } from "../../../components/sorting-table-header";
import Table from "../../../components/table";
import { useDictionaries } from "../../../contexts/dictionaries-context";
import { useLoading } from "../../../contexts/loading-context";
import { usePaging } from "../../../contexts/paging-context";
import { checkPermission } from "../../../utils/check-permission";
import { PERMISSIONS } from "../../../utils/permissions";
import { DocumentFlow } from "../document-flow";
import { DOCUMENT_FLOWS } from "../flow.gql";

export default function FlowIndex(): React.ReactElement {
  const { setTotalCount } = usePaging();
  const [css] = useStyletron();
  const { isFetching, setIsFetching, isPartialFetching } = useLoading();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { internalTypes, findValue } = useDictionaries();

  const { data, error, loading, refetch } = useQuery(DOCUMENT_FLOWS);

  useEffect(() => {
    if (data?.documentFlows) setTimeout(() => refetch(), 200);
    setIsFetching(true);
  }, []);

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    if (data?.documentFlows) setIsFetching(false);
    if (data?.documentFlows?.totalCount >= 0)
      setTotalCount(data?.documentFlows?.totalCount);
  }, [data]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Nazwa procesu",
        id: "name",
        Cell: ({ row }: { row: Row<DocumentFlow> }) => (
          <StyledLink
            onClick={(event: MouseEvent) => {
              event.preventDefault();
              history.push(`/flow/${row?.original?.id}`);
            }}
            href={`/flow/${row?.original?.id}`}
          >
            <FormattedValue>{row?.original?.name}</FormattedValue>
          </StyledLink>
        ),
      },
      {
        Header: "Rodzaj dokumentu",
        accessor: "documentKind",
        Cell: ({ cell }: { cell: TableCell }) => (
          <FormattedValue dataType="document-kind">
            {cell?.value}
          </FormattedValue>
        ),
      },
      {
        Header: "Typ dokumentu",
        accessor: "documentType",
        Cell: ({ row }: { row: Row<DocumentFlow> }) => {
          const documentType = row?.original?.documentType
            ? findValue(row?.original?.documentType)
            : "";
          return <FormattedValue>{documentType as string}</FormattedValue>;
        },
      },
      {
        Header: (
          <Block display="flex" justifyContent="flex-end">
            Etapy
          </Block>
        ),
        id: "stepsCount",
        Cell: ({ row }: { row: Row<DocumentFlow> }) => (
          <Block display="flex" justifyContent="flex-end">
            <FormattedValue>{row?.original?.steps?.length}</FormattedValue>
          </Block>
        ),
      },
      {
        Header: "Aktywny",
        id: "isActive",
        Cell: ({ row }: { row: Row<DocumentFlow> }) => (
          <FormattedValue dataType="boolean">
            {row?.original?.isActive}
          </FormattedValue>
        ),
      },
      {
        id: "actions",
        Cell: ({ row }: { row: Row<DocumentFlow> }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            {checkPermission(PERMISSIONS.documentFlow.update) && (
              <Button
                kind={KIND.secondary}
                size={SIZE.mini}
                $as="a"
                href={`/flow/${row.original.id}`}
                onClick={(e) => {
                  e.preventDefault();
                  history.push(`/flow/${row.original.id}/edit-process`);
                }}
                startEnhancer={<Pencil size={14} />}
              />
            )}

            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              $style={{ marginLeft: "6px" }}
              $as="a"
              href={`/flow/${row.original.id}`}
              onClick={(e) => {
                e.preventDefault();
                history.push(`/flow/${row.original.id}`);
              }}
              startEnhancer={<Eye size={14} />}
            />
          </div>
        ),
      },
    ],
    [internalTypes, data, sortBy, SortDirection]
  );

  if (!checkPermission(PERMISSIONS.documentFlow.read))
    return <Redirect to="/" />;

  return (
    <article>
      <Header
        title="Obieg dokumentów"
        recordsNum={data?.documentFlows?.length}
        labels={["Lista"]}
        buttons={[
          {
            label: "Dodaj proces",
            kind: KIND.primary,
            startEnhancer: <Plus size={18} />,
            permission: checkPermission(PERMISSIONS.documentFlow.create),
            onClick: (e) => {
              e.preventDefault();
              history.push("/flow/create-process");
            },
          },
        ]}
      />
      <Content>
        <Grid>
          <Cell span={12} $style={{ position: "relative" }}>
            <Table<DocumentFlow>
              columns={columns}
              data={data?.documentFlows}
              isLoading={isFetching || isPartialFetching || loading}
              stickLastColumn
            />
          </Cell>
        </Grid>
      </Content>
    </article>
  );
}
