import { gql } from "@apollo/client";

export const MY_SUBSTITUTIONS_INDEX = gql`
  query(
    $pageSize: Int
    $offset: Int
    $filter: SubstitutionFilter
    $sorting: [SubstitutionSort!]
  ) {
    mySubstitutions(
      paging: { limit: $pageSize, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        substitutee {
          id
          firstName
          lastName
          permissions {
            id
            systemName
          }
        }
        substituter {
          id
          firstName
          lastName
        }
        startedAt
        endedAt
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
        updatedAt
        updatedBy {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const AVAILABLE_SUBSTITUTEES_INDEX = gql`
  query(
    $pageSize: Int
    $offset: Int
    $filter: UserFilter
    $sorting: [UserSort!]
  ) {
    availableSubstitutees(
      paging: { limit: $pageSize, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        firstName
        lastName
      }
    }
  }
`;

export const MY_SUBSTITUTIONS_CREATE = gql`
  mutation($mySubstitutionCreateInput: MySubstitutionCreateInput!) {
    mySubstitutionCreate(
      mySubstitutionCreateInput: $mySubstitutionCreateInput
    ) {
      id
    }
  }
`;

export const MY_SUBSTITUTIONS_EDIT = gql`
  mutation($mySubstitutionUpdateInput: MySubstitutionUpdateInput!) {
    mySubstitutionUpdate(
      mySubstitutionUpdateInput: $mySubstitutionUpdateInput
    ) {
      id
    }
  }
`;

export const MY_SUBSTITUTIONS_SHOW = gql`
  query($id: Int!) {
    mySubstitution(id: $id) {
      id
      substitutee {
        id
        firstName
        lastName
        permissions {
          id
          systemName
        }
      }
      substituter {
        id
        firstName
        lastName
      }
      startedAt
      endedAt
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
      updatedAt
      updatedBy {
        id
        firstName
        lastName
      }
    }
  }
`;

export const MY_SUBSTITUTIONS_DELETE = gql`
  mutation($mySubstitutionDeleteInput: MySubstitutionDeleteInput!) {
    mySubstitutionDelete(
      mySubstitutionDeleteInput: $mySubstitutionDeleteInput
    ) {
      id
    }
  }
`;

export const CURRENT_SUBSTITUTION = gql`
  query {
    currentSubstitution {
      id
      substitutee {
        id
        firstName
        lastName
        permissions {
          id
          systemName
        }
      }
      substituter {
        id
        firstName
        lastName
      }
      startedAt
      endedAt
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
      updatedAt
      updatedBy {
        id
        firstName
        lastName
      }
    }
  }
`;
