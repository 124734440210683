import { gql } from "@apollo/client";

export const GROUPS_INDEX = gql`
  query(
    $pageSize: Int
    $offset: Int
    $filter: GroupFilter
    $usersFilter: UserFilter
    $sorting: [GroupSort!]
  ) {
    groups(
      paging: { limit: $pageSize, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        name
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
        users(filter: $usersFilter, paging: { limit: 1000 }) {
          nodes {
            id
            firstName
            lastName
            email
            loginAD
          }
        }
        isUsedInAnyDocumentFlow
        updatedAt
      }
    }
  }
`;

export const GROUPS_CREATE = gql`
  mutation($groupCreateInput: GroupCreateInput!) {
    groupCreate(groupCreateInput: $groupCreateInput) {
      id
    }
  }
`;

export const GROUPS_EDIT = gql`
  mutation($groupUpdateInput: GroupUpdateInput!) {
    groupUpdate(groupUpdateInput: $groupUpdateInput) {
      id
    }
  }
`;

export const GROUPS_SHOW = gql`
  query($id: Int!) {
    group(id: $id) {
      id
      name
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
      users(paging: { limit: 1000 }) {
        nodes {
          id
          firstName
          lastName
          email
        }
      }
      isUsedInAnyDocumentFlow
    }
  }
`;

export const GROUPS_DELETE = gql`
  mutation($groupDeleteInput: GroupDeleteInput!) {
    groupDelete(groupDeleteInput: $groupDeleteInput) {
      id
    }
  }
`;
