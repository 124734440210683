import { DOCUMENT_STATES } from "../../constants";
import { Document } from "../../containers/Documents/documents";

export const checkIsDispatchable = (document: Document): boolean =>
  !document?.documentFlowId &&
  !!document?.isDispatchable &&
  !document?.currentAssignment?.isAssessmentRequested &&
  document?.state !== DOCUMENT_STATES.Canceled;

export const checkIsShareable = (document: Document): boolean =>
  !!document?.isShareable && !document?.documentFlowId;
