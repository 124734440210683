import { BasicFilter, FILTERS } from "./../../filters.d";

export const FINANCIAL_PLANS_FILTERS: BasicFilter[] = [
  {
    id: "year",
    label: "Rok",
    type: FILTERS.Text,
  },
  {
    id: "name",
    label: "Nazwa",
    type: FILTERS.Text,
  },
  {
    id: "categoryName",
    label: "Nazwa kategorii",
    type: FILTERS.Text,
  },
  {
    id: "bazaId",
    label: "Baza Id",
    type: FILTERS.Number,
  },
  {
    id: "isActive",
    label: "Aktywny",
    type: FILTERS.Boolean,
  },
  {
    id: "synchronizedAt",
    label: "Data synchronizacji",
    type: FILTERS.Date,
  },
  {
    id: "synchronizedById",
    label: "Zsynchronizowano przez",
    type: FILTERS.User,
  },
  {
    id: "createdAt",
    label: "Data dodania",
    type: FILTERS.Date,
  },
  {
    id: "createdById",
    label: "Dodano przez",
    type: FILTERS.User,
  },
];

export const FINANCIAL_PLAN_ITEMS_FILTERS: BasicFilter[] = [
  {
    id: "budgetName",
    label: "Nazwa budżetu",
    type: FILTERS.Text,
  },
  {
    id: "categoryPath",
    label: "Kategoria",
    type: FILTERS.Text,
  },
  {
    id: "categoryName",
    label: "Nazwa kategorii",
    type: FILTERS.Text,
  },
  {
    id: "bazaId",
    label: "Baza Id",
    type: FILTERS.Number,
  },
  {
    id: "budgetSymbol",
    label: "Symbol budżetowy",
    type: FILTERS.Text,
  },
  {
    id: "amount",
    label: "Suma wartości",
    type: FILTERS.Amount,
  },
];
