import { FIELDS, FieldsGroup } from "../../fields.d";

export type SubstitutionFormInputs = {
  substitutee: { id: string | number; label?: string }[];
  substituter: { id: string | number; label?: string }[];
  startedAt: Date;
  endedAt: Date;
};

export enum SubstitutionsField {
  Substitutee = "substitutee",
  Substituter = "substituter",
  StartedAt = "startedAt",
  EndedAt = "endedAt",
  CreatedAt = "createdAt",
  CreatedBy = "createdBy",
  CreatedById = "createdById",
  UpdatedAt = "updatedAt",
  updatedBy = "updatedBy",
  updatedById = "updatedById",
}

export const SUBSTITUTIONS_FIELDS: FieldsGroup[] = [
  {
    id: "basic",
    label: "Podstawowe informacje",
    fields: [
      {
        id: SubstitutionsField.Substitutee,
        label: "Pracownik zastępowany",
        type: FIELDS.MyOrganizationalUnitsUsersSelect,
        dataType: "model:users",
        span: 6,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: SubstitutionsField.Substituter,
        label: "Pracownika zastępujący",
        type: FIELDS.MyOrganizationalUnitsUsersSelect,
        dataType: "model:users",
        span: 6,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: SubstitutionsField.StartedAt,
        label: "Data rozpoczęcia",
        type: FIELDS.DatePicker,
        dataType: "date",
        span: 6,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
        minDate: new Date(),
      },
      {
        id: SubstitutionsField.EndedAt,
        label: "Data zakończenia",
        type: FIELDS.DatePicker,
        dataType: "date",
        span: 6,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
        caption:
          "Pozostawienie tego pola pustego oznacza zastępstwo bezterminowe",
        minDate: new Date(),
      },
      {
        id: SubstitutionsField.EndedAt,
        label: "Status",
        type: FIELDS.SubstitutionStatus,
        span: 6,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
    ],
  },
];
