import React, { ReactElement } from "react";
import { useHistory } from "react-router-dom";

import { DOCUMENT_TYPES } from "../constants";
import { ActivityLog } from "../containers/ActivityLogs/activity-logs";
import CasesCreateModal from "../containers/Cases/Create/modal";
import {
  AssignmentAssessmentResult,
  Document,
  DocumentMutationAction,
} from "../containers/Documents/documents.d";
import { AssignmentShorthand } from "../containers/Folders/folders";
import { useAssignment } from "../contexts/assignment-context";
import ActivityLogsModal from "./activity-logs-modal";
import AssignToCaseModal from "./assign-to-case-modal";
import CloseDocumentModal from "./close-document-modal";
import ConfirmDialog, { ConfirmDialogType } from "./confirm-dialog";
import DocumentAssessmentModal from "./document-assesment-modal";
import DocumentCancelationModal from "./document-cancelation-modal";
import DocumentDispatchModal from "./document-dispatch-modal";
import { DocumentFlowStartDialog, LinkDocumentsDialog } from "./form-dialog";

export type DocumentModalName =
  | "activity-logs"
  | "assign-to-case"
  | "cancel-assignment"
  | "case-create"
  | "confirm-address-reveal"
  | "confirm-files-reveal"
  | "document-assignment-assessment-accept"
  | "document-assignment-assessment-reject"
  | "document-cancel"
  | "document-close"
  | "document-dispatch"
  | "document-dispatch-with-dispatcher"
  | "document-flow-start"
  | "document-link"
  | "document-uncancel";

type DocumentCommonModalsRendererProps = {
  openedCommonModal: DocumentModalName | undefined;
  close: () => void;
  documentId: number;
  document: Document;
  refetch: () => void;
  onDocumentMutationSubmit: (action: DocumentMutationAction) => void;
  commentContent?: string;
};

export function DocumentCommonModalsRenderer({
  openedCommonModal,
  close,
  documentId,
  document,
  refetch,
  onDocumentMutationSubmit,
}: DocumentCommonModalsRendererProps): ReactElement {
  const checkIsOpen = (modalName: DocumentModalName) =>
    openedCommonModal === modalName;

  const { setAssignment } = useAssignment();
  const history = useHistory();

  return (
    <>
      <ActivityLogsModal
        isOpen={checkIsOpen("activity-logs")}
        close={close}
        activityLogs={document?.activityLogs as ActivityLog[]}
      />

      <AssignToCaseModal
        isOpen={checkIsOpen("assign-to-case")}
        close={close}
        documentId={documentId}
        {...(document?.case?.id && {
          caseId: document.case.id.toString(),
        })}
        refetch={refetch}
      />

      <ConfirmDialog
        isOpen={checkIsOpen("cancel-assignment")}
        close={close}
        confirm={() =>
          onDocumentMutationSubmit(DocumentMutationAction.CancelDispatch)
        }
        label="Anulowanie przekazania dokumentu"
      />

      <CasesCreateModal
        isOpen={checkIsOpen("case-create")}
        close={close}
        documentId={documentId}
      />

      <ConfirmDialog
        isOpen={checkIsOpen("confirm-address-reveal")}
        close={close}
        confirm={() =>
          onDocumentMutationSubmit(DocumentMutationAction.RevealAddress)
        }
        type={ConfirmDialogType.AddressReveal}
      />

      <ConfirmDialog
        isOpen={checkIsOpen("confirm-files-reveal")}
        close={close}
        confirm={() =>
          onDocumentMutationSubmit(DocumentMutationAction.RevealFiles)
        }
        type={ConfirmDialogType.FilesReveal}
      />

      <DocumentAssessmentModal
        isOpen={checkIsOpen("document-assignment-assessment-accept")}
        close={close}
        documentId={documentId}
        assignmentId={document?.currentAssignment?.id}
        assessmentResult={"Accept" as AssignmentAssessmentResult}
        onCompleted={refetch}
      />

      <DocumentAssessmentModal
        isOpen={checkIsOpen("document-assignment-assessment-reject")}
        close={close}
        documentId={documentId}
        assignmentId={document?.currentAssignment?.id}
        assessmentResult={"Reject" as AssignmentAssessmentResult}
        onCompleted={refetch}
      />

      <DocumentCancelationModal
        isOpen={checkIsOpen("document-cancel")}
        close={close}
        documentId={documentId}
        action="cancel"
        refetch={refetch}
      />

      <CloseDocumentModal
        isOpen={checkIsOpen("document-close")}
        close={close}
        documentId={documentId}
        documentType={
          document?.documentType === DOCUMENT_TYPES.RequestForm
            ? DOCUMENT_TYPES.RequestForm
            : DOCUMENT_TYPES.ContractAgreement
        }
        refetch={refetch}
      />

      <DocumentDispatchModal
        isOpen={checkIsOpen("document-dispatch")}
        close={close}
        assignments={[document?.currentAssignment as AssignmentShorthand]}
        onCompleted={refetch}
      />

      <DocumentDispatchModal
        isOpen={checkIsOpen("document-dispatch-with-dispatcher")}
        close={close}
        assignments={[document?.currentAssignment as AssignmentShorthand]}
        dispatcher={document?.currentAssignment?.assigner}
        onCompleted={refetch}
      />

      <DocumentFlowStartDialog
        isOpen={checkIsOpen("document-flow-start")}
        close={close}
        documentId={documentId}
        onCompleted={() => {
          setAssignment(undefined);
          history.push(location.pathname, { assignmentId: null });
        }}
      />

      <LinkDocumentsDialog
        isOpen={checkIsOpen("document-link")}
        close={close}
        document={document}
        onCompleted={refetch}
      />

      <DocumentCancelationModal
        isOpen={checkIsOpen("document-uncancel")}
        close={close}
        documentId={documentId}
        action="uncancel"
        refetch={refetch}
      />
    </>
  );
}
