import { ApolloError, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { StyledLink } from "baseui/link";
import { useSnackbar } from "notistack";
import React, { MouseEvent, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { Redirect, useLocation } from "react-router-dom";
import { Row } from "react-table";
import { Eye, Pencil } from "tabler-icons-react";

import BottomPanel from "../../../components/bottom-panel";
import Button from "../../../components/button";
import Cell from "../../../components/cell";
import Content from "../../../components/content";
import Filters from "../../../components/filters";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import PagingControls from "../../../components/paging-controls";
import SortingTableHeader, {
  SortDirection,
} from "../../../components/sorting-table-header";
import Table from "../../../components/table";
import { useLoading } from "../../../contexts/loading-context";
import { usePaging } from "../../../contexts/paging-context";
import { FiltersState } from "../../../filters";
import { checkPermission } from "../../../utils/check-permission";
import { translateFiltersState } from "../../../utils/filters";
import { PERMISSIONS } from "../../../utils/permissions";
import { setSortingParams } from "../../../utils/sorting";
import { Payment } from "../payments";
import { PAYMENTS_FILTERS } from "../payments.filters";
import { PAYMENTS_INDEX } from "../payments.gql";

enum FieldName {
  Amount = "amount",
  CreatedAt = "createdAt",
  InternalNumber = "internalNumber",
}

export default function PaymentsIndex(): React.ReactElement {
  const { pageSize, currentPage, setTotalCount } = usePaging();

  const { search } = useLocation();

  const params = useMemo(() => new URLSearchParams(search), [search]);

  const [sortBy, setSortBy] = useState<FieldName | null>(
    (params.get("sortBy") as FieldName) || FieldName.CreatedAt
  );

  const [sortDirection, setSortDirection] = useState<SortDirection | null>(
    (params.get("sortDirection") as SortDirection) || SortDirection.DESC
  );

  const [css] = useStyletron();
  const {
    isFetching,
    setIsFetching,
    isPartialFetching,
    setIsPartialFetching,
  } = useLoading();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const handleSorting = (column: FieldName) => {
    setSortingParams(history, search, column, sortDirection);
    setSortBy(column);
    setSortDirection(
      sortDirection === null
        ? SortDirection.DESC
        : sortDirection === SortDirection.ASC
        ? SortDirection.DESC
        : SortDirection.ASC
    );
  };

  const [filters, setFilters] = useState<FiltersState>();

  const { data, error, loading, refetch } = useQuery(PAYMENTS_INDEX, {
    variables: {
      pageSize,
      offset: (currentPage - 1) * pageSize,
      sorting: {
        field: sortBy,
        direction: sortDirection,
      },
      ...(filters && { filter: translateFiltersState(filters) }),
    },
  });

  useEffect(() => {
    if (data?.payments) setTimeout(() => refetch(), 200);
    setIsFetching(true);
  }, []);

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    setTimeout(() => refetch(), 200);
    setIsPartialFetching(true);
  }, [currentPage, pageSize]);

  useEffect(() => {
    setTimeout(() => refetch(), 200);
    setIsPartialFetching(true);
  }, [sortBy, sortDirection]);

  useEffect(() => {
    if (data?.payments) setIsFetching(false);
    if (data?.payments?.totalCount >= 0)
      setTotalCount(data?.payments?.totalCount);
  }, [data]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Numer",
        id: "internalNumber",
        Cell: ({ row }: { row: Row<Payment> }) => {
          return (
            <StyledLink
              onClick={(event: MouseEvent) => {
                event.preventDefault();
                history.push(`/payments/${row.original.id}`);
              }}
              href={`/payments/${row.original.id}`}
            >
              <FormattedValue>{row?.original?.internalNumber}</FormattedValue>
            </StyledLink>
          );
        },
      },
      {
        Header: "Dokument",
        id: "financialAccountingDocument",
        Cell: ({ row }: { row: Row<Payment> }) => {
          return (
            <FormattedValue
              dataType="model:documents"
              data={`${row?.original?.financialAccountingDocument?.documentKind?.toLowerCase()}/FinancialAccountingDocument/${
                row?.original?.financialAccountingDocument?.id
              }`}
            >
              {`${row?.original?.financialAccountingDocument?.internalNumber} ${
                row?.original?.financialAccountingDocument?.documentNumber
                  ? `(numer ${row?.original?.financialAccountingDocument?.documentNumber})`
                  : ""
              }`}
            </FormattedValue>
          );
        },
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.Amount)}
            $style={{ display: "flex", justifyContent: "flex-end" }}
          >
            Kwota
          </SortingTableHeader>
        ),
        id: "amount",
        Cell: ({ row }: { row: Row<Payment> }) => {
          return (
            <Block display="flex" justifyContent="flex-end">
              <FormattedValue
                dataType="quota"
                currency={
                  row.original.financialAccountingDocument?.currency?.code
                }
              >
                {row?.original?.amount}
              </FormattedValue>
            </Block>
          );
        },
      },
      {
        Header: "Jednostka organizacyjna",
        id: "organizationalUnit",
        Cell: ({ row }: { row: Row<Payment> }) => {
          return (
            <FormattedValue
              dataType="model:organizational-units"
              data={row?.original?.organizationalUnit?.id}
            >
              {row?.original?.organizationalUnit?.name}
            </FormattedValue>
          );
        },
      },
      {
        Header: "Etap w obiegu",
        id: "currentStatus",
        Cell: ({ row }: { row: Row<Payment> }) => {
          return (
            <FormattedValue $style={{ fontSize: "12px" }}>
              {row?.original?.currentStatus?.documentFlowStep &&
                `${row?.original?.currentStatus?.documentFlowStep?.name}`}
            </FormattedValue>
          );
        },
      },
      {
        id: "actions",
        Cell: ({ row }: { row: Row<Payment> }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              href={`/payments/${row.original.id}/edit`}
              onClick={() => {
                history.push(`/payments/${row.original.id}/edit`);
              }}
              disabled={
                !(
                  checkPermission(PERMISSIONS.payments.update) &&
                  row?.original?.isEditable
                )
              }
              startEnhancer={<Pencil size={14} />}
            />
            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              $style={{ marginLeft: "6px" }}
              $as="a"
              href={`/payments/${row.original.id}`}
              onClick={(e) => {
                e.preventDefault();
                history.push(`/payments/${row.original.id}`);
              }}
              startEnhancer={<Eye size={14} />}
            />
          </div>
        ),
      },
    ],
    [data, sortBy, sortDirection, filters]
  );

  if (!checkPermission(PERMISSIONS.payments.read)) return <Redirect to="/" />;

  return (
    <article>
      <Header
        title="Polecenia płatności"
        recordsNum={data?.payments?.totalCount}
        labels={["Lista"]}
      />
      <Filters
        filters={PAYMENTS_FILTERS}
        state={filters}
        setState={setFilters}
      />
      <Content filtersOffset>
        <Grid>
          <Cell span={12} $style={{ position: "relative" }}>
            <Table<Payment>
              columns={columns}
              data={data?.payments?.nodes}
              isLoading={isFetching || isPartialFetching || loading}
              stickLastColumn
            />
          </Cell>

          <Cell span={12}>
            <BottomPanel>
              <PagingControls />
            </BottomPanel>
          </Cell>
        </Grid>
      </Content>
    </article>
  );
}
