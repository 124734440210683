import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { KIND, SIZE } from "baseui/button";
import { StyledLink } from "baseui/link";
import { useSnackbar } from "notistack";
import React, { MouseEvent, useEffect, useMemo, useState } from "react";
import { Redirect, useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { Row } from "react-table";
import { Cloud, Eye, Paperclip, Pencil, TableExport } from "tabler-icons-react";

import BottomPanel from "../../../components/bottom-panel";
import Button from "../../../components/button";
import Cell from "../../../components/cell";
import Content from "../../../components/content";
import Filters from "../../../components/filters";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import PagingControls from "../../../components/paging-controls";
import SortingTableHeader, {
  SortDirection,
} from "../../../components/sorting-table-header";
import Table from "../../../components/table";
import Tooltip from "../../../components/tooltip";
import { useLoading } from "../../../contexts/loading-context";
import { usePaging } from "../../../contexts/paging-context";
import { BasicFilter, FiltersState } from "../../../filters";
import { checkPermission } from "../../../utils/check-permission";
import { translateFiltersState } from "../../../utils/filters";
import { PERMISSIONS } from "../../../utils/permissions";
import { setSortingParams } from "../../../utils/sorting";
import { Case } from "../cases";
import { CASES_FILTERS } from "../cases.filters";
import { CASES_INDEX, CASES_XLSX_EXPORT } from "../cases.gql";

enum FieldName {
  Number = "number",
  Name = "name",
  FolderNumber = "folderNumber",
  InitiatedAt = "initiatedAt",
  ClosedAt = "closedAt",
}

export default function CasesIndex(): React.ReactElement {
  const { pageSize, currentPage, setTotalCount } = usePaging();

  const { search } = useLocation();

  const params = useMemo(() => new URLSearchParams(search), [search]);

  const [sortBy, setSortBy] = useState<FieldName | null>(
    (params.get("sortBy") as FieldName) || FieldName.InitiatedAt
  );

  const [sortDirection, setSortDirection] = useState<SortDirection | null>(
    (params.get("sortDirection") as SortDirection) || SortDirection.DESC
  );

  const [css, theme] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const {
    isPartialFetching,
    setIsPartialFetching,
    isFetching,
    setIsFetching,
    setIsLoading,
  } = useLoading();

  const handleSorting = (column: FieldName) => {
    setSortingParams(history, search, column, sortDirection);
    setSortBy(column);
    setSortDirection(
      sortDirection === null
        ? SortDirection.DESC
        : sortDirection === SortDirection.ASC
        ? SortDirection.DESC
        : SortDirection.ASC
    );
  };

  const [filters, setFilters] = useState<FiltersState>();

  const { data, loading, error, refetch } = useQuery(CASES_INDEX, {
    variables: {
      pageSize,
      offset: (currentPage - 1) * pageSize,
      sorting: {
        field: sortBy,
        direction: sortDirection,
      },
      filter: {
        and: [
          {
            ...(filters && (translateFiltersState(filters) as BasicFilter[])),
          },
        ],
      },
    },
  });

  useEffect(() => {
    if (data?.cases) setTimeout(() => refetch(), 200);
    setIsFetching(true);
  }, []);

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    setTimeout(() => refetch(), 200);
    setIsPartialFetching(true);
  }, [currentPage, pageSize]);

  useEffect(() => {
    setTimeout(() => refetch(), 200);
    setIsPartialFetching(true);
  }, [sortBy, sortDirection]);

  useEffect(() => {
    if (data?.cases) setIsFetching(false);
    if (data?.cases?.totalCount >= 0) setTotalCount(data?.cases?.totalCount);
  }, [data]);

  const [exportXlsxFile] = useMutation(CASES_XLSX_EXPORT);

  const exportAllCasesToXlsxFile = async () => {
    setIsLoading(true);

    try {
      const response = await exportXlsxFile({
        variables: {
          ...(filters && { filter: translateFiltersState(filters) }),
        },
      });

      enqueueSnackbar({
        message: "Rozpoczęto pobieranie pliku",
        variant: "info",
      });

      window.open(
        `${process.env.REACT_APP_GRAPHQL_API_URL?.replace("/graphql", "")}${
          response?.data?.casesExportToXlsx?.downloadUrl
        }`,
        "_self"
      );
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (!checkPermission(PERMISSIONS.case.read)) return <Redirect to="/" />;

  const columns = React.useMemo(
    () => [
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.Number)}
            sortDirection={sortBy === FieldName.Number ? sortDirection : null}
          >
            Znak sprawy
          </SortingTableHeader>
        ),
        accessor: "number",
        Cell: ({ row }: { row: Row<Case> }) => (
          <StyledLink
            {...(row.original.isAccessable && {
              href: `/cases/${row.original.id}`,
              onClick: (event: MouseEvent) => {
                event.preventDefault();
                history.push(`/cases/${row.original.id}`);
              },
            })}
            $style={{
              color: `${
                row.original.isConductedElectronically
                  ? theme.colors.positive
                  : theme.colors.negative
              } !important`,
              ...(!row.original.isAccessable && { textDecoration: "unset" }),
            }}
          >
            <div
              className={css({
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                gap: "5px",
              })}
            >
              <span
                className={css({
                  whiteSpace: "nowrap",
                })}
              >
                {row.original.number}
              </span>

              <Tooltip
                content={
                  row.original.isConductedElectronically
                    ? "Sprawa prowadzona elektronicznie"
                    : "Sprawa prowadzona tradycyjnie"
                }
              >
                <span>
                  {row.original.isConductedElectronically ? (
                    <Cloud
                      size={14}
                      className={css({
                        verticalAlign: "middle",
                        display: "inline",
                        color: theme.colors.positive,
                      })}
                    />
                  ) : (
                    <Paperclip
                      size={14}
                      className={css({
                        verticalAlign: "middle",
                        display: "inline",
                        color: theme.colors.negative,
                      })}
                    />
                  )}
                </span>
              </Tooltip>
            </div>
          </StyledLink>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.Name)}
            sortDirection={sortBy === FieldName.Name ? sortDirection : null}
          >
            Tytuł
          </SortingTableHeader>
        ),
        id: "name",
        Cell: ({ row }: { row: Row<Case> }) => (
          <FormattedValue>{row.original.name}</FormattedValue>
        ),
      },
      {
        Header: "Prowadzący sprawę",
        id: "supervisor",
        Cell: ({ row }: { row: Row<Case> }) => (
          <FormattedValue
            dataType={"model:users"}
            data={row.original.supervisor?.id}
          >
            {`${row.original.supervisor?.firstName} ${row.original.supervisor?.lastName}`}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.FolderNumber)}
            sortDirection={
              sortBy === FieldName.FolderNumber ? sortDirection : null
            }
          >
            Teczka
          </SortingTableHeader>
        ),
        accessor: "folderNumber",
      },
      {
        Header: "JRWA",
        accessor: "jrwaSymbol",
        Cell: ({ row }: { row: Row<Case> }) => (
          <FormattedValue
            dataType="model:jrwa-classifications"
            data={row?.original?.jrwaClassification?.id}
            additionalData={
              row?.original?.jrwaClassification?.isCaseConductedElectronically
            }
          >
            {row?.original?.jrwaClassification?.symbol}
          </FormattedValue>
        ),
      },
      {
        id: "organizationUnitShort",
        Header: "Jednostka",
        accessor: "symbol",
        Cell: ({ row }: { row: Row<Case> }) => (
          <FormattedValue
            dataType="model:organizational-units"
            data={row?.original?.organizationalUnit?.id}
          >
            {row?.original?.organizationalUnit?.symbol}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.InitiatedAt)}
            sortDirection={
              sortBy === FieldName.InitiatedAt ? sortDirection : null
            }
          >
            Data otwarcia
          </SortingTableHeader>
        ),
        accessor: "initiatedAt",
        Cell: ({ row }: { row: Row<Case> }) => (
          <FormattedValue dataType="datetime">
            {row?.original?.initiatedAt}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.ClosedAt)}
            sortDirection={sortBy === FieldName.ClosedAt ? sortDirection : null}
          >
            Data zamknięcia
          </SortingTableHeader>
        ),
        accessor: "closedAt",
        Cell: ({ row }: { row: Row<Case> }) => (
          <FormattedValue dataType="datetime">
            {row?.original?.closedAt}
          </FormattedValue>
        ),
      },
      {
        id: "actions",
        Cell: ({ row }: { row: Row<Case> }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            {row.original.isAccessable && (
              <>
                {checkPermission(PERMISSIONS.case.update) && (
                  <Tooltip
                    {...(!!row.original.closedAt && {
                      content:
                        "Sprawa została zamknięta, brak możliwości edycji",
                    })}
                  >
                    <span>
                      <Button
                        kind={KIND.secondary}
                        size={SIZE.mini}
                        disabled={!!row.original.closedAt}
                        onClick={() =>
                          history.push(`/cases/${row.original.id}/edit`)
                        }
                        startEnhancer={<Pencil size={14} />}
                      />
                    </span>
                  </Tooltip>
                )}
                <Button
                  kind={KIND.secondary}
                  size={SIZE.mini}
                  onClick={() => history.push(`/cases/${row.original.id}`)}
                  $style={{ marginLeft: "6px" }}
                  startEnhancer={<Eye size={14} />}
                />
              </>
            )}
          </div>
        ),
      },
    ],
    [data, sortBy, sortDirection, filters]
  );

  return (
    <article>
      <Header
        title="Spis spraw"
        recordsNum={data?.cases.totalCount}
        labels={["Lista"]}
        actions={[
          {
            label: "Eksportuj plik XLSX",
            icon: TableExport,
            color: theme.colors.primary,
            permission: checkPermission(PERMISSIONS.case.readAll),
            onClick: () => exportAllCasesToXlsxFile(),
          },
        ]}
      />
      <Filters filters={CASES_FILTERS} state={filters} setState={setFilters} />
      <Content filtersOffset>
        <Grid>
          <Cell span={12} $style={{ position: "relative" }}>
            <Table<Case>
              columns={columns}
              data={data?.cases?.nodes}
              isLoading={isFetching || isPartialFetching || loading}
              stickLastColumn
            />
          </Cell>

          <Cell span={12}>
            <BottomPanel>
              <PagingControls />
            </BottomPanel>
          </Cell>
        </Grid>
      </Content>
    </article>
  );
}
