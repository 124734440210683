import { ApolloError, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { StyledLink } from "baseui/link";
import { useSnackbar } from "notistack";
import React, { MouseEvent, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { Cell as TableCell, Row } from "react-table";
import { Eye } from "tabler-icons-react";

import BottomPanel from "../../../components/bottom-panel";
import Button from "../../../components/button";
import Cell from "../../../components/cell";
import Content from "../../../components/content";
import Filters from "../../../components/filters";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import PagingControls from "../../../components/paging-controls";
import SortingTableHeader, {
  SortDirection,
} from "../../../components/sorting-table-header";
import Table from "../../../components/table";
import { useLoading } from "../../../contexts/loading-context";
import { usePaging } from "../../../contexts/paging-context";
import { FiltersState } from "../../../filters";
import { translateFiltersState } from "../../../utils/filters";
import { setSortingParams } from "../../../utils/sorting";
import { Address } from "../addresses";
import { ADDRESSES_FILTERS } from "../addresses.filters";
import { ADDRESSES_INDEX } from "../addresses.gql";

enum FieldName {
  Name = "name",
  City = "city",
  Nip = "nip",
  IsHidden = "isHidden",
  CreatedAt = "createdAt",
  IsSensitive = "isSensitive",
}

export default function AddressesIndex(): React.ReactElement {
  const [css] = useStyletron();
  const { pageSize, currentPage, setTotalCount } = usePaging();
  const { search } = useLocation();

  const params = useMemo(() => new URLSearchParams(search), [search]);

  const [sortBy, setSortBy] = useState<FieldName | null>(
    (params.get("sortBy") as FieldName) || FieldName.Name
  );

  const [sortDirection, setSortDirection] = useState<SortDirection | null>(
    (params.get("sortDirection") as SortDirection) || SortDirection.ASC
  );

  const { enqueueSnackbar } = useSnackbar();
  const {
    isPartialFetching,
    setIsPartialFetching,
    isFetching,
    setIsFetching,
  } = useLoading();
  const history = useHistory();

  const [filters, setFilters] = useState<FiltersState>();

  const handleSorting = (column: FieldName) => {
    setSortingParams(history, search, column, sortDirection);
    setSortBy(column);
    setSortDirection(
      sortDirection === null
        ? SortDirection.DESC
        : sortDirection === SortDirection.ASC
        ? SortDirection.DESC
        : SortDirection.ASC
    );
  };

  const { refetch, loading, data, error } = useQuery(ADDRESSES_INDEX, {
    variables: {
      pageSize,
      offset: (currentPage - 1) * pageSize,
      sorting: {
        field: sortBy,
        direction: sortDirection,
      },
      ...(filters && { filter: translateFiltersState(filters) }),
    },
  });

  useEffect(() => {
    if (data?.addresses) setTimeout(() => refetch(), 200);
    setIsFetching(true);
  }, []);

  useEffect(() => {
    setTimeout(() => refetch(), 200);
    setIsPartialFetching(true);
  }, [currentPage, pageSize]);

  useEffect(() => {
    setTimeout(() => refetch(), 200);
    setIsPartialFetching(true);
  }, [sortBy, sortDirection]);

  useEffect(() => {
    if (data?.addresses) setIsFetching(false);
    if (data?.addresses?.totalCount >= 0)
      setTotalCount(data?.addresses?.totalCount);
  }, [data]);

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  const columns = React.useMemo(
    () => [
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.Name)}
            sortDirection={sortBy === FieldName.Name ? sortDirection : null}
          >
            Nazwa
          </SortingTableHeader>
        ),
        accessor: "name",
        Cell: ({ row }: { row: Row<Address> }) => (
          <StyledLink
            onClick={(event: MouseEvent) => {
              event.preventDefault();
              history.push(`/addresses/${row.original.id}`);
            }}
            href={`/addresses/${row.original.id}`}
          >
            {row.original.name}
          </StyledLink>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.City)}
            sortDirection={sortBy === FieldName.City ? sortDirection : null}
          >
            Miejscowość
          </SortingTableHeader>
        ),
        accessor: "city",
        Cell: ({ cell }: { cell: TableCell }) => (
          <FormattedValue>{cell.value}</FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.Nip)}
            sortDirection={sortBy === FieldName.Nip ? sortDirection : null}
          >
            NIP
          </SortingTableHeader>
        ),
        accessor: "nip",
        Cell: ({ cell }: { cell: TableCell }) => (
          <FormattedValue dataType="nip">{cell.value}</FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.IsHidden)}
            sortDirection={sortBy === FieldName.IsHidden ? sortDirection : null}
          >
            Widoczność
          </SortingTableHeader>
        ),
        accessor: "isHidden",
        Cell: ({ cell }: { cell: TableCell }) => (
          <FormattedValue dataType="visibility-boolean">
            {cell.value}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.IsSensitive)}
            sortDirection={
              sortBy === FieldName.IsSensitive ? sortDirection : null
            }
          >
            Dane osobowe
          </SortingTableHeader>
        ),
        accessor: "isSensitive",
        Cell: ({ cell }: { cell: TableCell }) => (
          <FormattedValue dataType="personal-data-boolean">
            {cell.value}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.CreatedAt)}
            sortDirection={
              sortBy === FieldName.CreatedAt ? sortDirection : null
            }
            $style={{ display: "flex", justifyContent: "flex-end" }}
          >
            Data utworzenia
          </SortingTableHeader>
        ),
        accessor: "createdAt",
        Cell: ({ cell }: { cell: TableCell }) => (
          <Block display="flex" justifyContent="flex-end">
            <FormattedValue dataType="datetime">{cell.value}</FormattedValue>
          </Block>
        ),
      },
      {
        id: "actions",
        Cell: ({ row }: { row: Row<Address> }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              onClick={() => history.push(`/addresses/${row.original.id}`)}
              startEnhancer={<Eye size={14} />}
            />
          </div>
        ),
      },
    ],
    [data, sortBy, sortDirection, filters]
  );

  return (
    <article>
      <Header
        title="Adresaci"
        recordsNum={data?.addresses?.totalCount}
        labels={["Lista"]}
      />
      <Filters
        filters={ADDRESSES_FILTERS}
        state={filters}
        setState={setFilters}
      />
      <Content filtersOffset>
        <Grid>
          <Cell span={12} $style={{ position: "relative" }}>
            <Table<Address>
              columns={columns}
              data={data?.addresses?.nodes}
              isLoading={isFetching || isPartialFetching || loading}
              stickLastColumn
            />
          </Cell>

          <Cell span={12}>
            <BottomPanel>
              <PagingControls />
            </BottomPanel>
          </Cell>
        </Grid>
      </Content>
    </article>
  );
}
