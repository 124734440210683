import { FIELDS, FieldsGroup } from "../../../fields.d";
import {
  DeklaracjaCelnaRodzaj,
  DokumentyTowarzyszaceRodzaj,
  ZawartoscPrzesylkiZagranicznej,
} from "../e-nadawca-parcels";

export type FormInputs = {
  documentParcel: {
    id: string;
    label?: string;
    shipmentType?: string;
  }[];
  sendingList: { id: number | string; label?: string }[];
  opis?: string;
  planowanaDataNadania?: Date | null;
  numerNadania?: string;
  sygnatura?: string;
  terminSprawy?: string;
  posteRestante?: boolean;
  masa?: number | string;
  wartosc?: string;
  iloscPotwierdzenOdbioru?: number | string;
  rodzaj?: string;
  zwrotPoLiczbieDni: number | string;
  traktowacJakPorzucona: boolean;
  sposobZwrotu: string;
  deklaracjaCelnaZawartosc: string;
  deklaracjaCelnaIlosc: number;
  deklaracjaCelnaMasa: number;
  deklaracjaCelnaWartosc: number;
  deklaracjaCelnaNumerTaryfowy: string;
  deklaracjaCelnaKrajPochodzenia: string;
  deklaracjaCelnaPodarunek: boolean;
  deklaracjaCelnaDokument: boolean;
  deklaracjaCelnaProbkaHandlowa: boolean;
  deklaracjaCelnaZwrotTowaru: boolean;
  deklaracjaCelnaTowary: boolean;
  deklaracjaCelnaInny: boolean;
  deklaracjaCelnaWyjasnienie: string;
  deklaracjaCelnaOplatyPocztowe: string;
  deklaracjaCelnaUwagi: string;
  deklaracjaCelnaLicencja: string;
  deklaracjaCelnaSwiadectwo: string;
  deklaracjaCelnaFaktura: string;
  deklaracjaCelnaNumerReferencyjnyImportera: string;
  deklaracjaCelnaNumerTelefonuImportera: string;
  deklaracjaCelnaWaluta: string;
  deklaracjaCelna2Rodzaj?: DeklaracjaCelnaRodzaj;
  deklaracjaCelna2RodzajSelect: { id: string; label: string }[] | undefined;
  deklaracjaCelna2ZawartoscPrzesylki?: ZawartoscPrzesylkiZagranicznej;
  deklaracjaCelna2ZawartoscPrzesylkiSelect?:
    | { id: string; label: string }[]
    | undefined;
  deklaracjaCelna2Wyjasnienie?: string;
  deklaracjaCelna2OplatyPocztowe?: string;
  deklaracjaCelna2Uwagi?: string;
  deklaracjaCelna2NumerReferencyjnyImportera?: string;
  deklaracjaCelna2NumerTelefonuImportera?: string;
  deklaracjaCelna2WalutaKodISO: string;
  deklaracjaCelna2NumerReferencyjnyCelny?: string;
  dokumentyTowarzyszaceRodzaj?: DokumentyTowarzyszaceRodzaj;
  dokumentyTowarzyszaceRodzajSelect:
    | { id: string; label: string }[]
    | undefined;
  dokumentyTowarzyszaceNumer: string;
  okreslenieZawartosci: string;
  ilosc: number;
  masaNetto?: number;
  numerTaryfyHs?: string;
  krajPochodzeniaKodAlfa2?: string;
  sposobNadaniaInterconnect?: string;
  sposobDoreczeniaKod?: string;
  sposobDoreczeniaIdentyfikatorPunktuOdbioru?: string;
  kategoria?: string;
  utrudnionaManipulacja?: boolean;
  ekspres?: boolean;
  numerReferencyjnyCelny?: string;
  numerPrzesylkiKlienta?: string;
};

export const DOCUMENT_ADDRESS_FIELDS: FieldsGroup[] = [
  {
    id: "recipient",
    label: "Adresat",
    fields: [
      {
        id: "addressName",
        label: "Nazwa",
        type: FIELDS.Input,
        span: 12,
        show: { accessor: ["recipient", "name"], visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "addressIsSensitive",
        label: "Dane osobowe",
        type: FIELDS.Checkbox,
        span: 3,
        show: { visible: false },
        create: { visible: false },
        edit: { visible: true },
      },
      {
        id: "addressAddress",
        label: "Ulica i numer",
        type: FIELDS.Input,
        span: 3,
        show: { accessor: ["recipient", "address"], visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "addressPostalCode",
        label: "Kod pocztowy",
        type: FIELDS.Input,
        span: 3,
        show: { accessor: ["recipient", "postalCode"], visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "addressCity",
        label: "Miejscowość",
        type: FIELDS.Input,
        span: 3,
        show: { accessor: ["recipient", "city"], visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "addressCountryCode",
        label: "Kraj",
        type: FIELDS.Input,
        span: 3,
        show: { accessor: ["recipient", "countryCode"], visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "countryCode",
      },
      {
        id: "addressNip",
        label: "NIP",
        type: FIELDS.Input,
        span: 3,
        show: { accessor: ["recipient", "nip"], visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "nip",
      },
      {
        id: "addressPhoneNumber",
        label: "Numer telefonu",
        type: FIELDS.Input,
        span: 3,
        show: { accessor: ["recipient", "phoneNumber"], visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "addressEmail",
        label: "Adres e-mail",
        type: FIELDS.Input,
        span: 6,
        show: { accessor: ["recipient", "email"], visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "email",
      },
    ],
  },
];

export const PACZKA_ZAGRANICZNA_FIELDS: FieldsGroup[] = [
  {
    id: "basic",
    label: "Podstawowe informacje",
    fields: [
      {
        id: "shipmentRate",
        label: "Stawka",
        type: FIELDS.Input,
        placeholder: "Wybierz",
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "sendingList",
        label: "Lista wysyłkowa",
        type: FIELDS.SendingListsSelect,
        placeholder: "Wybierz",
        span: 6,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: false },
      },
      {
        id: "masa",
        label: "Masa",
        type: FIELDS.NumberInput,
        span: 3,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: false },
        endEnhancer: "g",
      },
    ],
  },
  {
    id: "other",
    label: "Dodatkowe informacje",
    fields: [
      {
        id: "posteRestante",
        label: "Poste Restante",
        type: FIELDS.Checkbox,
        span: 2,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "ekspres",
        label: "Ekspres",
        type: FIELDS.Checkbox,
        span: 2,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "utrudnionaManipulacja",
        label: "Utrudniona manipulacja",
        type: FIELDS.Checkbox,
        span: 2,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "opis",
        label: "Opis",
        type: FIELDS.Input,
        span: 6,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "planowanaDataNadania",
        label: "Planowana data nadania",
        type: FIELDS.DatePicker,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "terminSprawy",
        label: "Termin sprawy",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "numerNadania",
        label: "Numer nadania",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "sygnatura",
        label: "Sygnatura",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "rodzaj",
        label: "Rodzaj",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "iloscPotwierdzenOdbioru",
        label: "Ilość potwierdzeń odbioru",
        type: FIELDS.NumberInput,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "sposobNadaniaInterconnect",
        label: "Sposob nadania Interconnect",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "kategoria",
        label: "Kategoria",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "numerReferencyjnyCelny",
        label: "Numer referencyjny celny",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "numerPrzesylkiKlienta",
        label: "Numer przesylki klienta",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
    ],
  },
  {
    id: "zwrot",
    label: "Zwrot",
    fields: [
      {
        id: "zwrotPoLiczbieDni",
        label: "Zwrot po liczbie dni",
        type: FIELDS.NumberInput,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "sposobZwrotu",
        label: "Sposób zwrotu",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: false },
      },
      {
        id: "traktowacJakPorzucona",
        label: "Traktować jak porzucona",
        type: FIELDS.Checkbox,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
    ],
  },
  {
    id: "deklaracjaCelna",
    label: "Deklaracja celna",
    fields: [
      {
        id: "deklaracjaCelnaPodarunek",
        label: "Podarunek",
        type: FIELDS.Checkbox,
        span: 2,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "deklaracjaCelnaDokument",
        label: "Dokument",
        type: FIELDS.Checkbox,
        span: 2,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "deklaracjaCelnaProbkaHandlowa",
        label: "Próbka handlowa",
        type: FIELDS.Checkbox,
        span: 2,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "deklaracjaCelnaZwrotTowaru",
        label: "Zwrot towaru",
        type: FIELDS.Checkbox,
        span: 2,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "deklaracjaCelnaTowary",
        label: "Towary",
        type: FIELDS.Checkbox,
        span: 2,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "deklaracjaCelnaInny",
        label: "Inny",
        type: FIELDS.Checkbox,
        span: 2,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "deklaracjaCelnaZawartosc",
        label: "Zawartość",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "deklaracjaCelnaIlosc",
        label: "Ilość",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "deklaracjaCelnaMasa",
        label: "Masa",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "deklaracjaCelnaWartosc",
        label: "Wartość",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "deklaracjaCelnaNumerTaryfowy",
        label: "Numer taryfowy",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "deklaracjaCelnaKrajPochodzenia",
        label: "Kraj pochodzenia",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },

      {
        id: "deklaracjaCelnaWyjasnienie",
        label: "Wyjaśnienie",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "deklaracjaCelnaOplatyPocztowe",
        label: "Opłaty pocztowe",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "deklaracjaCelnaUwagi",
        label: "Uwagi",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "deklaracjaCelnaLicencja",
        label: "Licencja",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "deklaracjaCelnaSwiadectwo",
        label: "Świadectwo",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "deklaracjaCelnaFaktura",
        label: "Faktura",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "deklaracjaCelnaNumerReferencyjnyImportera",
        label: "Numer referencyjny importera",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "deklaracjaCelnaNumerTelefonuImportera",
        label: "Numer telefonu importera",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "deklaracjaCelnaWaluta",
        label: "Waluta",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
    ],
  },
  {
    id: "deklaracjaCelna2",
    label: "Deklaracja celna 2",
    fields: [
      {
        id: "deklaracjaCelna2RodzajSelect",
        label: "Rodzaj",
        type: FIELDS.DeklaracjaCelnaRodzajSelect,
        placeholder: "Wybierz",
        span: 3,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: false },
      },
      {
        id: "ilosc",
        label: "Ilość",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: false },
      },
      {
        id: "deklaracjaCelna2ZawartoscPrzesylkiSelect",
        label: "Zawartość przesyłki",
        type: FIELDS.ZawartoscPrzesylkiSelect,
        placeholder: "Wybierz",
        span: 3,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: false },
      },
      {
        id: "okreslenieZawartosci",
        label: "Określenie zawartości",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: false },
      },
      {
        id: "deklaracjaCelna2WalutaKodISO",
        label: "Kod ISO waluty",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: false },
      },
      {
        id: "dokumentyTowarzyszaceRodzajSelect",
        label: "Rodzaj dokumentów towarzyszących",
        type: FIELDS.DokumentyTowarzyszaceRodzajSelect,
        placeholder: "Wybierz",
        span: 3,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: false },
      },
      {
        id: "dokumentyTowarzyszaceNumer",
        label: "Numer dokumentów towarzyszących",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: false },
      },
      {
        id: "deklaracjaCelna2Wyjasnienie",
        label: "Wyjaśnienie",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "deklaracjaCelna2OplatyPocztowe",
        label: "Opłaty pocztowe",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "deklaracjaCelna2Uwagi",
        label: "Uwagi",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "deklaracjaCelna2NumerReferencyjnyImportera",
        label: "Numer referencyjny importera",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "deklaracjaCelna2NumerTelefonuImportera",
        label: "Numer telefonu importera",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "deklaracjaCelna2NumerReferencyjnyCelny",
        label: "Numer referencyjny celny",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "masaNetto",
        label: "Masa netto",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "wartosc",
        label: "Wartość",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "numerTaryfyHs",
        label: "Numer taryfy HS",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
      {
        id: "krajPochodzeniaKodAlfa2",
        label: "Kod Alfa 2 kraju pochodzenia",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
    ],
  },
  {
    id: "sposobDoreczenia",
    label: "Sposób doręczenia",
    fields: [
      {
        id: "sposobDoreczeniaKod",
        label: "Kod",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: false },
      },
      {
        id: "sposobDoreczeniaIdentyfikatorPunktuOdbioru",
        label: "Identyfikator punktu odbioru",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: false },
      },
    ],
  },
];
