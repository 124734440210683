import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { KIND } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { Pencil, Trash } from "tabler-icons-react";

import Cell from "../../../components/cell";
import ConfirmDialog from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import FormControl from "../../../components/form-control";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import { useLoading } from "../../../contexts/loading-context";
import { checkPermission } from "../../../utils/check-permission";
import { PERMISSIONS } from "../../../utils/permissions";
import { INTERNAL_ACCOUNTS_FIELDS } from "../internal-accounts.form";
import {
  INTERNAL_ACCOUNTS_DELETE,
  INTERNAL_ACCOUNTS_SHOW,
} from "../internal-accounts.gql";

export default function InternalAccountsShow(): React.ReactElement {
  const [css, theme] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const { setIsLoading, isFetching, setIsFetching } = useLoading();

  const { refetch, data, error } = useQuery(INTERNAL_ACCOUNTS_SHOW, {
    variables: { id: id ? parseInt(id) : null },
  });
  const [deleteInternalAccount] = useMutation(INTERNAL_ACCOUNTS_DELETE);

  const submit = async () => {
    setIsLoading(true);

    try {
      await deleteInternalAccount({
        variables: {
          internalAccountDeleteInput: { id: id ? parseInt(id) : null },
        },
      });

      enqueueSnackbar({
        message: "Usunięto pomyślnie",
        variant: "success",
      });

      setIsConfirmDialogOpen(false);

      history.push("/internal-accounts");
    } catch (error: unknown) {
      setIsConfirmDialogOpen(false);

      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    if (data?.internalAccount) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => {
    data?.internalAccount && setIsFetching(false);
  }, [data]);

  if (!checkPermission(PERMISSIONS.internalAccounts.read))
    return <Redirect to="/" />;

  return (
    <article>
      <Header
        title={data?.internalAccount?.name}
        labels={["Konta wewnętrzne", "Wyświetlanie"]}
        goBackOption
        buttons={[
          {
            label: "Edytuj",
            kind: KIND.secondary,
            startEnhancer: <Pencil size={18} />,
            permission: checkPermission(PERMISSIONS.internalAccounts.update),
            onClick: () => history.push(`/internal-accounts/${id}/edit`),
          },
        ]}
        actions={[
          {
            label: "Usuń konto wewnętrzne",
            icon: Trash,
            color: theme.colors.negative,
            onClick: () => setIsConfirmDialogOpen(true),
            permission: checkPermission(PERMISSIONS.internalAccounts.delete),
          },
        ]}
      />
      <Content>
        <Grid>
          {INTERNAL_ACCOUNTS_FIELDS.filter(
            (g) => g.fields.filter((f) => f.show.visible).length > 0
          ).map((group) => [
            group.label && (
              <Cell key={group.id + `-group`} span={12}>
                <LabelMedium marginBottom="scale200" marginTop="scale600">
                  {group.label}
                </LabelMedium>
                <hr
                  className={css({
                    borderWidth: "0px",
                    height: "1px",
                    backgroundColor: "#eee",
                  })}
                />
              </Cell>
            ),
            group.fields
              .filter((f) => f.show.visible)
              .map((item, index) => (
                <Cell span={item.span || 6} key={group.id + `-field` + index}>
                  <FormControl label={item.label} disabled={true}>
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : (
                      <FormattedValue
                        dataType={item.dataType}
                        {...(item.dataType === "quota" && {
                          currency: data?.internalAccount?.currency?.code,
                        })}
                        data={
                          item.id === "currency"
                            ? data?.internalAccount?.currency?.id
                            : data?.internalAccount[item.id]
                        }
                        {...(item.id === "currencies" && {
                          textWhenEmpty: "PLN",
                        })}
                      >
                        {item.id === "limit" || item.id === "balance"
                          ? `${data?.internalAccount[item.id]} ${
                              data?.internalAccount?.currency?.code || "PLN"
                            }`
                          : item.show.accessor
                          ? data?.internalAccount[item.show.accessor[0]]?.[
                              item.show.accessor[1]
                            ]
                          : data?.internalAccount[item.id]}
                      </FormattedValue>
                    )}
                  </FormControl>
                </Cell>
              )),
          ])}

          <ConfirmDialog
            isOpen={isConfirmDialogOpen}
            label={`Usunięcie konta wewnętrznego ${data?.internalAccount?.name}`}
            close={() => setIsConfirmDialogOpen(false)}
            confirm={submit}
          />
        </Grid>
      </Content>
    </article>
  );
}
