import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { StyledLink } from "baseui/link";
import { LabelXSmall } from "baseui/typography";
import { isEqual } from "lodash";
import React, { ReactElement, useState } from "react";
import {
  DragDropContext,
  Draggable,
  DraggingStyle,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { useLocation } from "react-router-dom";
import { HeaderGroup } from "react-table";
import {
  CircleCheck,
  DeviceFloppy,
  Eye,
  EyeCheck,
  EyeOff,
  Table as TableIcon,
} from "tabler-icons-react";

import { useAuth } from "../contexts/auth-context";
import {
  resetPreferences,
  setPreferencedColumnOrder,
  setPreferencedColumns,
} from "../utils/interface/preferences";
import { textContent } from "../utils/misc";
import { sortColumnsByOrder } from "../utils/table";
import { ForwardedButton } from "./button";
import Popover from "./popover";
import Tooltip from "./tooltip";

type TableColumnListProps = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  headerGroups: HeaderGroup<object>[];
  defaultColumns: string[];
  orderableColumns: string[];
  visibleColumns: string[];
  setVisibleColumns: (columns: string[]) => void;
  orderedColumns: string[];
  setOrderedColumns: (columns: string[]) => void;
};

function reorder(list: string[], startIndex: number, endIndex: number) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

export default function TableColumnList({
  headerGroups,
  defaultColumns,
  orderableColumns,
  visibleColumns,
  setVisibleColumns,
  orderedColumns,
  setOrderedColumns,
}: TableColumnListProps): React.ReactElement {
  const [css, theme] = useStyletron();

  const { interfacePreferences, updateUser } = useAuth();
  const { pathname } = useLocation();

  const [columnsChanged, setColumnsChanged] = useState(false);

  function onDragEnd(result: DropResult) {
    if (!result.destination) {
      return;
    }

    const newOrderedColumns = reorder(
      orderedColumns,
      result.source.index,
      result.destination.index
    );

    updateUser({
      interfacePreferences: setPreferencedColumnOrder(
        interfacePreferences,
        pathname,
        newOrderedColumns
      ) as JSON,
    });

    setOrderedColumns(newOrderedColumns);
    setColumnsChanged(true);
  }

  return (
    <Popover
      focusLock
      autoFocus
      content={() => (
        <Block width="200px" padding="scale300">
          <LabelXSmall
            $style={{
              fontWeight: 600,
              borderBottomWidth: "1px",
              borderBottomStyle: "dotted",
              borderBottomColor: theme.colors.inputBorder,
              paddingBottom: theme.sizing.scale300,
              marginBottom: theme.sizing.scale500,
            }}
          >
            Wyświetlaj kolumny
          </LabelXSmall>

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className={css({
                    ...(snapshot.isDraggingOver && {
                      outline: "1px dashed #aaa",
                      outlineOffset: "5px",
                    }),
                  })}
                >
                  {headerGroups
                    .map((headerGroup) => {
                      return headerGroup.headers
                        ?.filter(
                          (column) =>
                            column.id !== "actions" && column.id !== "checkbox"
                        )
                        .sort((a, b) =>
                          sortColumnsByOrder(a, b, orderedColumns)
                        )
                        .map((column, index) => {
                          return (
                            <Draggable
                              key={column.id}
                              draggableId={column.id}
                              index={index}
                            >
                              {(provided) => {
                                const { top, left, ...styles } = provided
                                  .draggableProps.style as DraggingStyle;

                                return (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className={css({
                                      display: "flex",
                                      alignItems: "center",
                                      marginTop: theme.sizing.scale300,
                                      borderRadius: "6px",
                                      userSelect: "none",
                                      ...(!column.disableGlobalFilter
                                        ? {
                                            ":hover": {
                                              backgroundColor: "#f9f9f9",
                                              cursor: "pointer",
                                            },
                                          }
                                        : {
                                            cursor: "not-allowed",
                                          }),
                                    })}
                                    style={{
                                      ...styles,
                                    }}
                                    {...(!column.disableGlobalFilter && {
                                      onClick: () => {
                                        const newVisibleColumns = visibleColumns?.includes(
                                          column.id
                                        )
                                          ? visibleColumns.filter(
                                              (columnId) =>
                                                columnId !== column.id
                                            )
                                          : [...visibleColumns, column.id];

                                        setVisibleColumns(newVisibleColumns);
                                        setColumnsChanged(true);

                                        updateUser({
                                          interfacePreferences: setPreferencedColumns(
                                            interfacePreferences,
                                            pathname,
                                            newVisibleColumns
                                          ) as JSON,
                                        });
                                      },
                                    })}
                                  >
                                    <div
                                      className={css({
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        backgroundColor: visibleColumns?.includes(
                                          column.id
                                        )
                                          ? theme.colors.primary
                                          : "#eee",
                                        padding: "4px",
                                        borderRadius: "6px",
                                        marginRight: "8px",
                                        flexShrink: 0,
                                      })}
                                    >
                                      {visibleColumns?.includes(column.id) ? (
                                        column.disableGlobalFilter ? (
                                          <EyeCheck size={14} color="white" />
                                        ) : (
                                          <Eye size={14} color="white" />
                                        )
                                      ) : (
                                        <EyeOff size={14} />
                                      )}
                                    </div>

                                    <LabelXSmall>
                                      {textContent(
                                        column.render("Header") as ReactElement
                                      )}
                                    </LabelXSmall>
                                  </div>
                                );
                              }}
                            </Draggable>
                          );
                        });
                    })
                    .flat()}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          {(!isEqual(visibleColumns, defaultColumns) ||
            !isEqual(orderedColumns, orderableColumns)) && (
            <LabelXSmall
              display="flex"
              alignItems="flex-start"
              $style={{
                fontWeight: 400,
                borderTopWidth: "1px",
                borderTopStyle: "dotted",
                borderTopColor: theme.colors.inputBorder,
                paddingTop: theme.sizing.scale300,
                marginTop: theme.sizing.scale500,
              }}
            >
              <div
                className={css({
                  flexShrink: 0,
                  marginTop: "3px",
                  marginRight: "8px",
                })}
              >
                {columnsChanged ? (
                  <DeviceFloppy size={14} color={theme.colors.positive} />
                ) : (
                  <CircleCheck size={14} color={theme.colors.positive} />
                )}
              </div>

              <span>
                {columnsChanged
                  ? "Zapisano preferencje użytkownika. "
                  : "Wczytano preferencje użytkownika. "}
                <StyledLink
                  $as={"span"}
                  $style={{ cursor: "pointer" }}
                  onClick={() => {
                    setVisibleColumns(defaultColumns);
                    setOrderedColumns(orderableColumns);

                    updateUser({
                      interfacePreferences: {
                        ...(resetPreferences(
                          interfacePreferences,
                          pathname
                        ) as JSON),
                      },
                    });
                  }}
                >
                  Przywróć domyślne
                </StyledLink>
              </span>
            </LabelXSmall>
          )}

          <LabelXSmall
            display="flex"
            alignItems="flex-start"
            $style={{
              fontWeight: 400,
              borderTopWidth: "1px",
              borderTopStyle: "dotted",
              borderTopColor: theme.colors.inputBorder,
              paddingTop: theme.sizing.scale300,
              marginTop: theme.sizing.scale500,
              color: "#afafaf",
            }}
          >
            Kliknij, aby zmienić widoczność kolumny lub przeciągnij, aby zmienić
            kolejność kolumn.
          </LabelXSmall>
        </Block>
      )}
      placement="bottom"
    >
      <span>
        <Tooltip content="Wyświetlaj kolumny">
          <ForwardedButton
            kind="secondary"
            size="mini"
            startEnhancer={<TableIcon size={18} />}
          />
        </Tooltip>
      </span>
    </Popover>
  );
}
