import { ApolloError, useMutation } from "@apollo/client";
import { useStyletron } from "baseui";
import { KIND } from "baseui/button";
import { ModalBody, ModalFooter, ModalHeader } from "baseui/modal";
import { useSnackbar } from "notistack";
import React, { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { AlertOctagon } from "tabler-icons-react";

import { InputValidationError } from "../api";
import { AssignmentAssessmentResult } from "../containers/Documents/documents.d";
import { AssessmentFormInputs } from "../containers/Documents/documents.form";
import { DOCUMENT_ASSESS } from "../containers/Documents/documents.gql";
import { useAssignment } from "../contexts/assignment-context";
import { useLoading } from "../contexts/loading-context";
import Button from "./button";
import FormControl from "./form-control";
import Modal from "./modal";
import { ControlledTextArea } from "./text-area";

type DocumentAssessmentModalProps = {
  documentId?: number;
  assignmentId?: number;
  assessmentResult?: AssignmentAssessmentResult;
  isOpen: boolean;
  onCompleted: () => void;
  close: () => void;
};

export default function DocumentAssessmentModal({
  documentId,
  assessmentResult,
  isOpen,
  close,
  onCompleted,
}: DocumentAssessmentModalProps): React.ReactElement {
  const [css] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, setIsLoading } = useLoading();

  const { assignment, isAssignmentActive } = useAssignment();

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<AssessmentFormInputs>();

  const [assessDocument, { error, loading }] = useMutation(DOCUMENT_ASSESS, {
    ...(isAssignmentActive &&
      assignment && {
        context: {
          headers: {
            Assignment: assignment.id,
          },
        },
      }),
  });

  const onDocumentAssessmentSubmit = async ({
    comment,
  }: AssessmentFormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      await assessDocument({
        variables: {
          documentAssessInput: {
            documentId,
            assessmentResult,
            comment,
          },
        },
      });

      enqueueSnackbar({
        message: `${
          memoizedAssessmentResult === AssignmentAssessmentResult.Accept
            ? "Zaakceptowano"
            : "Odrzucono"
        } pomyślnie`,
        variant: "success",
      });

      onCompleted();
      close();
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  const memoizedAssessmentResult = useMemo(() => {
    return (
      (assessmentResult === AssignmentAssessmentResult.Accept ||
        assessmentResult === AssignmentAssessmentResult.Reject) &&
      assessmentResult
    );
  }, [assessmentResult]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        close();
        reset();
      }}
      animate={false}
    >
      <form onSubmit={handleSubmit(onDocumentAssessmentSubmit)}>
        <ModalHeader>
          {memoizedAssessmentResult === AssignmentAssessmentResult.Accept
            ? "Akceptacja"
            : "Odrzucenie"}{" "}
          dokumentu
        </ModalHeader>
        <ModalBody>
          <FormControl
            label={"Komentarz"}
            error={
              ((errors as any)["comment"] &&
                (errors as any)["comment"].message) ||
              (error &&
                error.graphQLErrors[0]?.extensions?.code ===
                  "INPUT_VALIDATION_ERROR" &&
                error.graphQLErrors[0]?.extensions?.validationErrors
                  ?.find(
                    (vE: InputValidationError) => vE?.property === "comment"
                  )
                  ?.errors.map((message: string) => (
                    <div
                      key="error"
                      className={css({
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      })}
                    >
                      {message}
                      <AlertOctagon color="#999" size={12} />
                    </div>
                  ))[0])
            }
            disabled={isLoading}
          >
            <ControlledTextArea
              control={control}
              name={"comment"}
              id={"comment"}
            />
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            type="button"
            kind={KIND.secondary}
            $style={{ marginRight: "6px" }}
            onClick={() => {
              close();
              reset();
            }}
            disabled={loading}
          >
            Anuluj
          </Button>

          <Button disabled={loading} isLoading={loading} type="submit">
            {memoizedAssessmentResult === AssignmentAssessmentResult.Accept
              ? "Zaakceptuj"
              : "Odrzuć"}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
