import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import { Row } from "react-table";
import { Check, Refresh, X } from "tabler-icons-react";

import BottomPanel from "../../../components/bottom-panel";
import Cell from "../../../components/cell";
import Content from "../../../components/content";
import Filters from "../../../components/filters";
import FinancialPlanSynchronizationModal from "../../../components/financial-plan-synchronization-modal";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import PagingControls from "../../../components/paging-controls";
import SortingTableHeader, {
  SortDirection,
} from "../../../components/sorting-table-header";
import Table from "../../../components/table";
import { useLoading } from "../../../contexts/loading-context";
import { usePaging } from "../../../contexts/paging-context";
import { BasicFilter, FiltersState } from "../../../filters";
import { checkPermission } from "../../../utils/check-permission";
import { translateFiltersState } from "../../../utils/filters";
import { PERMISSIONS } from "../../../utils/permissions";
import { setSortingParams } from "../../../utils/sorting";
import { FinancialPlan, FinancialPlanItem } from "../financial-plans";
import { FINANCIAL_PLAN_ITEMS_FILTERS } from "../financial-plans.filters";
import {
  FINANCIAL_PLAN_ITEMS,
  FINANCIAL_PLANS_ACTIVATE,
  FINANCIAL_PLANS_DEACTIVATE,
  FINANCIAL_PLANS_SHOW,
} from "../financial-plans.gql";

enum FinancialPlanItemsFieldName {
  CategoryName = "categoryName",
  BudgetName = "budgetName",
  CategoryPath = "categoryPath",
  BudgetSymbol = "budgetSymbol",
  BazaId = "bazaId",
  Amount = "amount",
}

export default function FinancialPlansShow(): React.ReactElement {
  const {
    isFetching,
    setIsFetching,
    isPartialFetching,
    setIsPartialFetching,
    setIsLoading,
  } = useLoading();
  const { enqueueSnackbar } = useSnackbar();
  const { financialPlanId } = useParams<{ financialPlanId?: string }>();
  const [
    isSynchronizationDialogOpen,
    setIsSynchronizationDialogOpen,
  ] = useState(false);
  const { pageSize, currentPage, setTotalCount } = usePaging();

  const history = useHistory();
  const { search } = useLocation();

  const params = useMemo(() => new URLSearchParams(search), [search]);

  const [sortBy, setSortBy] = useState<FinancialPlanItemsFieldName | null>(
    (params.get("sortBy") as FinancialPlanItemsFieldName) ||
      FinancialPlanItemsFieldName.CategoryPath
  );

  const [sortDirection, setSortDirection] = useState<SortDirection | null>(
    (params.get("sortDirection") as SortDirection) || SortDirection.ASC
  );

  const [filters, setFilters] = useState<FiltersState>();

  const handleSorting = (column: FinancialPlanItemsFieldName) => {
    setSortingParams(history, search, column, sortDirection);
    setSortBy(column);
    setSortDirection(
      sortDirection === null
        ? SortDirection.DESC
        : sortDirection === SortDirection.ASC
        ? SortDirection.DESC
        : SortDirection.ASC
    );
  };

  const {
    data: financialPlanItemsData,
    error: financialPlanItemsError,
    loading: financialPlanItemsLoading,
    refetch: financialPlanItemsRefetch,
  } = useQuery(FINANCIAL_PLAN_ITEMS, {
    variables: {
      pageSize,
      offset: (currentPage - 1) * pageSize,
      sorting: {
        field: sortBy,
        direction: sortDirection,
      },
      filter: {
        and: [
          {
            ...(financialPlanId && {
              financialPlanId: {
                eq: parseInt(financialPlanId),
              },
            }),
          },
          {
            ...(filters && (translateFiltersState(filters) as BasicFilter[])),
          },
        ],
      },
    },
  });

  useEffect(() => {
    if (financialPlanItemsData?.financialPlanItems)
      setTimeout(() => financialPlanItemsRefetch(), 200);
    setIsFetching(true);
  }, []);

  useEffect(() => {
    if (financialPlanItemsError?.graphQLErrors)
      enqueueSnackbar({
        message: (financialPlanItemsError as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [financialPlanItemsError]);

  useEffect(() => {
    setTimeout(() => financialPlanItemsRefetch(), 200);
    setIsPartialFetching(true);
  }, [currentPage, pageSize]);

  useEffect(() => {
    setTimeout(() => financialPlanItemsRefetch(), 200);
    setIsPartialFetching(true);
  }, [sortBy, sortDirection]);

  useEffect(() => {
    if (financialPlanItemsData?.financialPlanItems) setIsFetching(false);
    if (financialPlanItemsData?.financialPlanItems?.totalCount >= 0)
      setTotalCount(financialPlanItemsData?.financialPlanItems?.totalCount);
  }, [financialPlanItemsData]);

  const {
    refetch: financialPlanRefetch,
    data: financialPlanData,
    error: financialPlanError,
  } = useQuery(FINANCIAL_PLANS_SHOW, {
    variables: { id: financialPlanId ? parseInt(financialPlanId) : null },
  });

  useEffect(() => {
    if (financialPlanError?.graphQLErrors)
      enqueueSnackbar({
        message: (financialPlanError as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [financialPlanError]);

  useEffect(() => {
    if (financialPlanData?.financialPlan)
      setTimeout(() => financialPlanRefetch(), 200);
    setIsFetching(true);
  }, []);

  useEffect(() => {
    if (financialPlanData?.financialPlan) setIsFetching(false);
  }, [financialPlanData]);

  const [
    activatePlan,
    { error: activateError, loading: activateLoading },
  ] = useMutation(FINANCIAL_PLANS_ACTIVATE);

  const [
    deactivatePlan,
    { error: deactivateError, loading: deactivateLoading },
  ] = useMutation(FINANCIAL_PLANS_DEACTIVATE);

  const changePlanStatus = async (action: "activate" | "deactivate") => {
    setIsLoading(true);

    try {
      action === "activate" &&
        (await activatePlan({
          variables: {
            ids: [financialPlanData?.financialPlan?.id],
          },
        }));

      action === "deactivate" &&
        (await deactivatePlan({
          variables: {
            ids: [financialPlanData?.financialPlan?.id],
          },
        }));

      financialPlanItemsRefetch();
      financialPlanRefetch();

      enqueueSnackbar({
        message: `${
          action === "activate" ? "Aktywowano" : "Dezaktywowano"
        } pomyślnie`,
        variant: "success",
      });
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (activateError?.graphQLErrors)
      enqueueSnackbar({
        message: (activateError as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [activateError]);

  useEffect(() => {
    if (deactivateError?.graphQLErrors)
      enqueueSnackbar({
        message: (deactivateError as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [deactivateError]);

  const columns = React.useMemo(
    () => [
      {
        Header: (
          <SortingTableHeader
            onClick={() =>
              handleSorting(FinancialPlanItemsFieldName.BudgetName)
            }
            sortDirection={
              sortBy === FinancialPlanItemsFieldName.BudgetName
                ? sortDirection
                : null
            }
          >
            Nazwa budżetu
          </SortingTableHeader>
        ),
        id: "budgetName",
        Cell: ({ row }: { row: Row<FinancialPlanItem> }) => (
          <FormattedValue>{row?.original?.budgetName}</FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() =>
              handleSorting(FinancialPlanItemsFieldName.CategoryPath)
            }
            sortDirection={
              sortBy === FinancialPlanItemsFieldName.CategoryPath
                ? sortDirection
                : null
            }
          >
            Kategoria
          </SortingTableHeader>
        ),
        id: "categoryPath",
        Cell: ({ row }: { row: Row<FinancialPlanItem> }) => (
          <FormattedValue>{row?.original?.categoryPath}</FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() =>
              handleSorting(FinancialPlanItemsFieldName.CategoryName)
            }
            sortDirection={
              sortBy === FinancialPlanItemsFieldName.CategoryName
                ? sortDirection
                : null
            }
          >
            Nazwa kategorii
          </SortingTableHeader>
        ),
        id: "categoryName",
        Cell: ({ row }: { row: Row<FinancialPlanItem> }) => (
          <FormattedValue>{row?.original?.categoryName}</FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() =>
              handleSorting(FinancialPlanItemsFieldName.BudgetSymbol)
            }
            sortDirection={
              sortBy === FinancialPlanItemsFieldName.BudgetSymbol
                ? sortDirection
                : null
            }
          >
            Symbol budżetowy
          </SortingTableHeader>
        ),
        id: "budgetSymbol",
        Cell: ({ row }: { row: Row<FinancialPlanItem> }) => (
          <FormattedValue>{row?.original?.budgetSymbol}</FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FinancialPlanItemsFieldName.Amount)}
            sortDirection={
              sortBy === FinancialPlanItemsFieldName.Amount
                ? sortDirection
                : null
            }
          >
            Suma wartości
          </SortingTableHeader>
        ),
        id: "amount",
        Cell: ({ row }: { row: Row<FinancialPlanItem> }) => (
          <FormattedValue dataType="quota" currency="PLN">
            {row?.original?.amount}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FinancialPlanItemsFieldName.BazaId)}
            sortDirection={
              sortBy === FinancialPlanItemsFieldName.BazaId
                ? sortDirection
                : null
            }
          >
            Baza Id
          </SortingTableHeader>
        ),
        id: "bazaId",
        Cell: ({ row }: { row: Row<FinancialPlanItem> }) => (
          <FormattedValue dataType="pre">
            {row?.original?.bazaId}
          </FormattedValue>
        ),
      },
      {
        id: "actions",
        Cell: () => <div />,
      },
    ],
    [financialPlanItemsData, sortBy, sortDirection, filters]
  );

  if (!checkPermission(PERMISSIONS.financialPlanItem.read))
    return <Redirect to="/" />;

  return (
    <article>
      <Header
        title={`Plan finansowy ${financialPlanData?.financialPlan?.year} ${
          financialPlanData?.financialPlan?.isActive
            ? "(Aktywny)"
            : "(Nieaktywny)"
        }`}
        recordsNum={financialPlanItemsData?.financialPlanItems?.totalCount}
        labels={["Pozycje planu"]}
        goBackOption
        buttons={[
          {
            label: "Aktywuj",
            permission:
              checkPermission(PERMISSIONS.financialPlan.activate) &&
              !financialPlanData?.financialPlan?.isActive,
            isPositive: true,
            isLoading: activateLoading,
            onClick: () => changePlanStatus("activate"),
            startEnhancer: <Check size={18} />,
          },
          {
            label: "Dezaktywuj",
            permission:
              checkPermission(PERMISSIONS.financialPlan.deactivate) &&
              !!financialPlanData?.financialPlan?.isActive,
            isNegative: true,
            isLoading: deactivateLoading,
            onClick: () => changePlanStatus("deactivate"),
            startEnhancer: <X size={18} />,
          },
          {
            label: "Zgłoś aktualizację planu finansowego",
            permission: checkPermission(PERMISSIONS.financialPlan.update),
            onClick: () => setIsSynchronizationDialogOpen(true),
            startEnhancer: <Refresh size={18} />,
          },
        ]}
      />

      <Filters
        filters={FINANCIAL_PLAN_ITEMS_FILTERS}
        state={filters}
        setState={setFilters}
      />

      <Content filtersOffset>
        <Grid>
          <Cell span={12} $style={{ position: "relative" }}>
            <Table<FinancialPlan>
              columns={columns}
              data={financialPlanItemsData?.financialPlanItems?.nodes}
              isLoading={
                isFetching || isPartialFetching || financialPlanItemsLoading
              }
              stickLastColumn
            />
          </Cell>

          <Cell span={12}>
            <BottomPanel>
              <PagingControls />
            </BottomPanel>
          </Cell>
        </Grid>
      </Content>

      <FinancialPlanSynchronizationModal
        isOpen={isSynchronizationDialogOpen}
        financialPlan={financialPlanData?.financialPlan}
        close={() => setIsSynchronizationDialogOpen(false)}
      />
    </article>
  );
}
