import {
  ApolloError,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { ModalBody, ModalFooter, ModalHeader, ROLE } from "baseui/modal";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Redirect, useHistory } from "react-router-dom";
import { AlertOctagon, Download } from "tabler-icons-react";

import { InputValidationError } from "../../../api";
import Button from "../../../components/button";
import Cell from "../../../components/cell";
import { ControlledCheckbox } from "../../../components/checkbox";
import ConfirmDialog, {
  ConfirmDialogType,
} from "../../../components/confirm-dialog";
import FormControl from "../../../components/form-control";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import GusDownloadModal from "../../../components/gus-download-modal";
import { ControlledInput } from "../../../components/input";
import Modal from "../../../components/modal";
import {
  ControlledAddressesSelect,
  ControlledCaseGroupsSelect,
  ControlledDictionaryValuesSelect,
  ControlledJrwaClassificationsSelect,
  ControlledUsersOrganizationalUnitsSelect,
} from "../../../components/select";
import Tooltip from "../../../components/tooltip";
import { HIDDEN_ADDRESS } from "../../../constants";
import { useAssignment } from "../../../contexts/assignment-context";
import { useAuth } from "../../../contexts/auth-context";
import { useLoading } from "../../../contexts/loading-context";
import { FIELDS } from "../../../fields.d";
import { checkPermission } from "../../../utils/check-permission";
import { formValidation } from "../../../utils/formValidation";
import { PERMISSIONS } from "../../../utils/permissions";
import { scrollOnError } from "../../../utils/scrollOnError";
import { Address, DefaultAddress } from "../../Addresses/addresses";
import {
  ADDRESS_SUGGESTION,
  ADDRESS_SUGGESTIONS,
} from "../../Addresses/addresses.gql";
import { ORGANIZATIONAL_UNITS_SHOW } from "../../OrganizationalUnits/organizational-units.gql";
import { CASES_FIELDS, CreateFormInputs } from "../cases.form";
import { CASES_CREATE } from "../cases.gql";

type CasesCreateModalProps = {
  documentId: number;
  isOpen: boolean;
  close: () => void;
  confirm?: () => void;
  onCompleted?: () => void;
};

export default function CasesCreateModal({
  documentId,
  isOpen,
  close,
}: CasesCreateModalProps): React.ReactElement {
  const [groups, setGroups] = useState<string[]>([]);
  const [
    isAddressRevealConfirmDialogOpen,
    setIsAddressRevealConfirmDialogOpen,
  ] = useState(false);
  const [isAnyAddressRevealed, setIsAnyAddressRevealed] = useState(true);
  const [defaultAddress, setDefaultAddress] = useState<DefaultAddress>();
  const [addressFromGus, setAddressFromGus] = useState<Address>();
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const [isClearConfirmDialogOpen, setIsClearConfirmDialogOpen] = useState(
    false
  );
  const [isGusDownloadOpen, setIsGusDownloadOpen] = useState(false);
  const [errorTimeStamp, setErrorTimeStamp] = useState<number>();
  const [css] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { user } = useAuth();
  const { isLoading, setIsLoading } = useLoading();

  const [createCase, { error, loading }] = useMutation(CASES_CREATE);

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    unregister,
    watch,
    register,
    clearErrors,
  } = useForm<CreateFormInputs>({
    defaultValues: {
      name: "",
      isCustomNumber: false,
      organizationalUnit: [
        user?.organizationalUnitToUsers[0]?.organizationalUnit,
      ],
      projectNumber: "",
      coordinatorName: "",
      addressName: "",
      addressNip: "",
      addressCity: "",
      addressStreetName: "",
      addressBuildingNumber: "",
      addressApartmentNumber: "",
      addressMobileNumber: "",
      addressPostalCode: "",
      addressEmail: "",
      addressPhoneNumber: "",
      addressOverwrite: false,
      addressIsSensitive: false,
    },
  });

  const watchBeneficiary = watch("beneficiaryAddress");
  const watchNip = watch("addressNip");
  const watchJrwa = watch("jrwaClassification");
  const watchOrganizationalUnit = watch("organizationalUnit");
  const watchIsCustomNumber = watch("isCustomNumber");
  const watchCountryCode = watch("addressCountryCode");

  const { refetch: fetchAddress } = useQuery(ADDRESS_SUGGESTIONS, {
    skip: true,
  });

  const { setAssignment } = useAssignment();

  const onError = (errors: Record<string, unknown>, e: any) =>
    setErrorTimeStamp(e.timeStamp);

  const onSubmit = async ({
    name,
    coordinatorName,
    isCustomNumber,
    number,
    jrwaClassification,
    organizationalUnit,
    groups,
    projectNumber,
    beneficiaryAddress,
    addressName,
    addressNip,
    addressCity,
    addressStreetName,
    addressBuildingNumber,
    addressApartmentNumber,
    addressMobileNumber,
    addressPostalCode,
    addressCountryCode,
    addressEmail,
    addressPhoneNumber,
    addressOverwrite,
    addressIsSensitive,
  }: CreateFormInputs): Promise<void> => {
    setIsLoading(true);

    const enteredAddress = {
      addressName,
      addressNip,
      addressStreetName,
      addressBuildingNumber,
      addressApartmentNumber,
      addressMobileNumber,
      addressCity,
      addressCountryCode,
      addressPostalCode,
      addressPhoneNumber,
      addressEmail,
      addressIsSensitive,
    };

    const shouldSendAddress =
      watchJrwa?.[0]?.isBeneficiaryAddressRequired &&
      !!addressName &&
      !!addressStreetName &&
      !!addressBuildingNumber &&
      !!addressCity &&
      !!addressPostalCode &&
      !!addressCountryCode &&
      JSON.stringify(enteredAddress) !== JSON.stringify(defaultAddress);

    try {
      const response = await createCase({
        variables: {
          caseCreateInput: {
            name,
            coordinatorName,
            documentId,
            jrwaClassificationId: jrwaClassification[0].id,
            organizationalUnitId: organizationalUnit[0].id,
            organizationalUnitGroupNumber: groups && groups[0]?.id,
            projectNumber,
            beneficiaryAddressId:
              watchJrwa?.[0]?.isBeneficiaryAddressRequired && beneficiaryAddress
                ? beneficiaryAddress[0]?.id
                : null,
            ...(isCustomNumber && number && { number }),
          },
          ...(shouldSendAddress && {
            addressInput: {
              name: addressName,
              nip: addressNip && addressNip?.replaceAll("-", "").toString(),
              city: addressCity,
              streetName: addressStreetName,
              buildingNumber: addressBuildingNumber,
              apartmentNumber: addressApartmentNumber,
              mobileNumber: addressMobileNumber,
              postalCode: addressPostalCode,
              countryCode: addressCountryCode && addressCountryCode[0]?.id,
              email: addressEmail ? addressEmail : null,
              phoneNumber: addressPhoneNumber ? addressPhoneNumber : null,
              overwrite: !!addressOverwrite,
              isSensitive: !!addressIsSensitive,
            },
          }),
        },
      });

      enqueueSnackbar({
        message: "Utworzono pomyślnie",
        variant: "success",
      });

      setAssignment(undefined);
      history.push(`/cases/${response.data.caseCreate.id}`, {
        assignmentId: null,
      });
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const { refetch: revealAddress, loading: revealAddressLoading } = useQuery(
    ADDRESS_SUGGESTION,
    {
      skip: true,
    }
  );

  const onAddressRevealSubmit = async () => {
    setIsLoading(true);

    try {
      const response = await revealAddress({
        id: watchBeneficiary && watchBeneficiary[0] && watchBeneficiary[0].id,
      });

      const choosedAddress = response?.data?.addressSuggestion;
      if (choosedAddress?.name) setValue("addressName", choosedAddress?.name);
      if (choosedAddress?.name?.isSensitive)
        setValue("addressIsSensitive", choosedAddress?.name?.isSensitive);

      if (choosedAddress?.nip) setValue("addressNip", choosedAddress?.nip);
      if (choosedAddress?.streetName)
        setValue("addressStreetName", choosedAddress?.streetName);
      if (choosedAddress?.buildingNumber)
        setValue("addressBuildingNumber", choosedAddress?.buildingNumber);
      if (choosedAddress?.apartmentNumber)
        setValue("addressApartmentNumber", choosedAddress?.apartmentNumber);
      if (choosedAddress?.mobileNumber)
        setValue("addressMobileNumber", choosedAddress?.mobileNumber);
      if (choosedAddress?.city) setValue("addressCity", choosedAddress?.city);
      if (choosedAddress?.postalCode)
        setValue("addressPostalCode", choosedAddress?.postalCode);
      if (choosedAddress?.countryCode)
        setValue("addressCountryCode", [
          {
            id: choosedAddress?.countryCode,
          },
        ]);
      if (choosedAddress?.phoneNumber)
        setValue("addressPhoneNumber", choosedAddress?.phoneNumber);
      if (choosedAddress?.email)
        setValue("addressEmail", choosedAddress?.email);

      setIsAnyAddressRevealed(true);
      enqueueSnackbar({
        message: "Odkryto pomyślnie",
        variant: "success",
      });

      setIsAddressRevealConfirmDialogOpen(false);
    } catch (error: unknown) {
      setIsAddressRevealConfirmDialogOpen(false);
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setAddressFromGus(undefined);
    async function getAddressData() {
      setValue("addressName", "");
      setValue("addressNip", "");
      setValue("addressStreetName", "");
      setValue("addressBuildingNumber", "");
      setValue("addressApartmentNumber", "");
      setValue("addressMobileNumber", "");
      setValue("addressCity", "");
      setValue("addressPostalCode", "");
      setValue("addressPhoneNumber", "");
      setValue("addressEmail", "");
      setValue("addressCountryCode", undefined);
      setValue("addressIsSensitive", false);
      if (watchBeneficiary && watchBeneficiary[0] && watchBeneficiary[0].id) {
        const response = await fetchAddress({
          filter: {
            id: {
              eq: watchBeneficiary[0].id as number,
            },
          },
        });
        const choosedAddress = response?.data?.addressSuggestions?.nodes[0];
        if (choosedAddress?.name) setValue("addressName", choosedAddress?.name);
        if (watchBeneficiary[0].typename === "HiddenAddress") {
          setValue("addressIsSensitive", true);
          setValue("addressCountryCode", []);
        }

        if (watchBeneficiary[0].typename === "Address") {
          if (choosedAddress?.nip) setValue("addressNip", choosedAddress?.nip);
          if (choosedAddress?.streetName)
            setValue("addressStreetName", choosedAddress?.streetName);
          if (choosedAddress?.buildingNumber)
            setValue("addressBuildingNumber", choosedAddress?.buildingNumber);
          if (choosedAddress?.apartmentNumber)
            setValue("addressApartmentNumber", choosedAddress?.apartmentNumber);
          if (choosedAddress?.mobileNumber)
            setValue("addressMobileNumber", choosedAddress?.mobileNumber);
          if (choosedAddress?.city)
            setValue("addressCity", choosedAddress?.city);
          if (choosedAddress?.postalCode)
            setValue("addressPostalCode", choosedAddress?.postalCode);
          if (choosedAddress?.countryCode)
            setValue("addressCountryCode", [
              {
                id: choosedAddress?.countryCode,
              },
            ]);
          if (choosedAddress?.phoneNumber)
            setValue("addressPhoneNumber", choosedAddress?.phoneNumber);
          if (choosedAddress?.email)
            setValue("addressEmail", choosedAddress?.email);
        }

        setDefaultAddress({
          addressName: choosedAddress?.name || "",
          addressNip: choosedAddress?.nip || "",
          addressStreetName: choosedAddress?.streetName || "",
          addressBuildingNumber: choosedAddress?.buildingNumber || "",
          addressApartmentNumber: choosedAddress?.apartmentNumber || "",
          addressMobileNumber: choosedAddress?.mobileNumber || "",
          addressCity: choosedAddress?.city || "",
          addressCountryCode: choosedAddress?.countryCode
            ? [
                {
                  id: choosedAddress?.countryCode,
                },
              ]
            : [],
          addressPostalCode: choosedAddress?.postalCode || "",
          addressPhoneNumber: choosedAddress?.phoneNumber || "",
          addressEmail: choosedAddress?.email || "",
          addressIsSensitive: choosedAddress?.__typename === HIDDEN_ADDRESS,
        });
        unregister("addressName");
        unregister("addressStreetName");
        unregister("addressBuildingNumber");
        unregister("addressApartmentNumber");
        unregister("addressMobileNumber");
        unregister("addressPostalCode");
        unregister("addressCity");
        unregister("addressCountryCode");
      }
    }
    !addressFromGus && getAddressData();

    watchBeneficiary?.[0]?.typename === "Address"
      ? setIsAnyAddressRevealed(true)
      : setIsAnyAddressRevealed(false);
  }, [watchBeneficiary]);

  useEffect(() => {
    if (addressFromGus) setValue("beneficiaryAddress", undefined);
    if (addressFromGus?.nip) setValue("addressNip", addressFromGus?.nip);
    if (addressFromGus?.name) setValue("addressName", addressFromGus?.name);
    if (addressFromGus?.streetName)
      setValue("addressStreetName", addressFromGus?.streetName);
    if (addressFromGus?.buildingNumber)
      setValue("addressBuildingNumber", addressFromGus?.buildingNumber);
    if (addressFromGus?.apartmentNumber)
      setValue("addressApartmentNumber", addressFromGus?.apartmentNumber);
    if (addressFromGus?.mobileNumber)
      setValue("addressMobileNumber", addressFromGus?.mobileNumber);
    if (addressFromGus?.postalCode)
      setValue("addressPostalCode", addressFromGus?.postalCode);
    if (addressFromGus?.city) setValue("addressCity", addressFromGus?.city);
    if (addressFromGus?.countryCode)
      setValue("addressCountryCode", [{ id: addressFromGus?.countryCode }]);
    if (addressFromGus?.phoneNumber)
      setValue("addressPhoneNumber", addressFromGus?.phoneNumber);
    if (addressFromGus?.email) setValue("addressEmail", addressFromGus?.email);
  }, [addressFromGus]);

  useEffect(() => {
    if (errors) scrollOnError(errors, CASES_FIELDS, "create");
  }, [errorTimeStamp]);

  useEffect(() => {
    if (watchJrwa?.[0]?.isBeneficiaryAddressRequired)
      setValue("addressCountryCode", [{ id: "PL", label: "Polska (PL)" }]);
    else {
      unregister("addressName");
      unregister("addressStreetName");
      unregister("addressBuildingNumber");
      unregister("addressApartmentNumber");
      unregister("addressMobileNumber");
      unregister("addressPostalCode");
      unregister("addressCity");
      unregister("addressCountryCode");
    }
  }, [watchJrwa]);

  const [
    fetchOrganizationalUnit,
    { data: queryData, loading: queryLoading },
  ] = useLazyQuery(ORGANIZATIONAL_UNITS_SHOW);

  useEffect(() => {
    setValue("groups", undefined);
    watchOrganizationalUnit?.length
      ? fetchOrganizationalUnit({
          variables: { id: watchOrganizationalUnit[0]?.id },
        })
      : setGroups([]);
  }, [watchOrganizationalUnit]);

  useEffect(() => {
    if (watchIsCustomNumber === false) {
      setValue("number", "");
      clearErrors("number");
      unregister("number");
    } else {
      register("number");
    }
  }, [watchIsCustomNumber]);

  useEffect(() => {
    queryData && setGroups(queryData?.organizationalUnit?.groups);
  }, [queryData]);

  useEffect(() => {
    if (queryData)
      fetchOrganizationalUnit({
        variables: { id: watchOrganizationalUnit[0]?.id },
      });
  }, []);

  if (!checkPermission(PERMISSIONS.case.create)) return <Redirect to="/" />;

  return (
    <Modal
      onClose={close}
      closeable
      isOpen={isOpen}
      $style={{
        maxWidth: "1200px",
        width: "95vw",
      }}
      role={ROLE.dialog}
    >
      <ModalHeader>Utwórz sprawę z dokumentem</ModalHeader>
      <ModalBody>
        <form id="createCase" onSubmit={handleSubmit(onSubmit)}>
          <Grid fill>
            {CASES_FIELDS.filter(
              (g) => g.fields.filter((f) => f.create.visible).length > 0
            ).map((group) => [
              group.label &&
                (!group.id.includes("beneficiary") ||
                  (group.id.includes("beneficiary") &&
                    watchJrwa?.[0]?.isBeneficiaryAddressRequired)) && (
                  <Cell key={group.id + `-group`} span={12}>
                    <>
                      <Block
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        marginBottom="scale200"
                        marginTop="scale600"
                        height="20px"
                      >
                        <Block
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <LabelMedium>
                            {group.id.includes("beneficiary") &&
                            !watchJrwa?.[0]?.isBeneficiaryAddressRequired
                              ? ""
                              : group.label}
                          </LabelMedium>
                          {watchBeneficiary?.[0]?.typename ===
                            "HiddenAddress" &&
                            !isAnyAddressRevealed &&
                            group.id === "beneficiaryAddress" && (
                              <Button
                                size={SIZE.mini}
                                kind={KIND.secondary}
                                $style={{ marginLeft: "10px" }}
                                onClick={() =>
                                  setIsAddressRevealConfirmDialogOpen(true)
                                }
                                isLoading={revealAddressLoading}
                                type="button"
                              >
                                Pokaż dane
                              </Button>
                            )}
                        </Block>
                        {watchBeneficiary?.[0]?.typename === "HiddenAddress" &&
                          !isAnyAddressRevealed &&
                          group.id === "beneficiaryAddress" && (
                            <FormattedValue
                              dataType="pre"
                              $style={{ fontSize: "12px" }}
                            >
                              Dane osobowe - podgląd zablokowany
                            </FormattedValue>
                          )}
                      </Block>
                      {group.id.includes("beneficiary") &&
                      !watchJrwa?.[0]?.isBeneficiaryAddressRequired ? (
                        <></>
                      ) : (
                        <hr
                          className={css({
                            borderWidth: "0px",
                            height: "1px",
                            backgroundColor: "#eee",
                          })}
                        />
                      )}
                    </>
                  </Cell>
                ),
              group.fields
                .filter((f) => f.create.visible)
                .map((item, index) => (
                  <Cell span={item.span || 6} key={group.id + `-field` + index}>
                    <div
                      {...((!watchJrwa?.[0]?.isBeneficiaryAddressRequired ||
                        !watchJrwa?.length) &&
                        (item.id.toLowerCase().includes("address") ||
                          item.id.includes("coordinator")) && {
                          className: css({ display: "none" }),
                        })}
                    >
                      <FormControl
                        label={item.type === FIELDS.Checkbox ? "" : item.label}
                        required={
                          item.info === "possiblyRequired"
                            ? watchJrwa?.[0]?.isBeneficiaryAddressRequired
                            : item.id === "number" &&
                              watchIsCustomNumber === true
                            ? true
                            : item.create.required
                        }
                        error={
                          ((errors as any)[item.id] &&
                            (errors as any)[item.id].message) ||
                          (error &&
                            error.graphQLErrors[0]?.extensions?.code ===
                              "INPUT_VALIDATION_ERROR" &&
                            error.graphQLErrors[0]?.extensions?.validationErrors
                              ?.find((vE: InputValidationError) =>
                                item.id.includes("address")
                                  ? vE?.property ===
                                    item.id.slice(7).toLowerCase()
                                  : vE?.property === item.id
                              )
                              ?.errors.map((message: string) => (
                                <div
                                  key="error"
                                  className={css({
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  })}
                                >
                                  {message}
                                  <AlertOctagon color="#999" size={12} />
                                </div>
                              ))[0])
                        }
                        disabled={isLoading}
                      >
                        {item.type === FIELDS.UsersOrganizationalUnitsSelect ? (
                          <ControlledUsersOrganizationalUnitsSelect
                            control={control}
                            name={item.id}
                            id={item.id}
                            placeholder={item.placeholder}
                            {...(item.create.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                          />
                        ) : item.type === FIELDS.AddressesSelect ? (
                          <ControlledAddressesSelect
                            control={control}
                            name={item.id}
                            id={item.id}
                            placeholder={item.placeholder}
                            {...(item.create.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                          />
                        ) : item.type === FIELDS.GroupSelect ? (
                          <ControlledCaseGroupsSelect
                            control={control}
                            name={item.id}
                            id={item.id}
                            placeholder={item.placeholder}
                            groups={groups}
                            isLoading={queryLoading}
                            {...(item.create.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                          />
                        ) : item.type === FIELDS.JrwaClassificationsSelect ? (
                          <ControlledJrwaClassificationsSelect
                            control={control}
                            name={item.id}
                            id={item.id}
                            placeholder={item.placeholder}
                            maxDropdownHeight="300px"
                            {...(item.create.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                          />
                        ) : item.type === FIELDS.DictionaryValuesSelect &&
                          item.info === "possiblyRequired" &&
                          watchJrwa?.[0]?.isBeneficiaryAddressRequired ? (
                          <ControlledDictionaryValuesSelect
                            dictionarySystemName="countryCodes.alpha2.pl"
                            control={control}
                            name={item.id}
                            id={item.id}
                            disabled={
                              item.id === "addressCountryCode" &&
                              !isAnyAddressRevealed &&
                              !!watchBeneficiary?.length
                            }
                            placeholder={
                              watchBeneficiary?.[0]?.typename ===
                                "HiddenAddress" && item.id.includes("address")
                                ? "**********"
                                : "Wybierz"
                            }
                            rules={{
                              required: formValidation.messages.required,
                            }}
                          />
                        ) : item.type === FIELDS.DictionaryValuesSelect ? (
                          <ControlledDictionaryValuesSelect
                            dictionarySystemName="countryCodes.alpha2.pl"
                            control={control}
                            name={item.id}
                            id={item.id}
                            disabled={
                              item.id === "addressCountryCode" &&
                              !isAnyAddressRevealed &&
                              !!watchBeneficiary?.length
                            }
                            placeholder={
                              watchBeneficiary?.[0]?.typename ===
                                "HiddenAddress" && item.id.includes("address")
                                ? "**********"
                                : "Wybierz"
                            }
                            {...(item.create.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                          />
                        ) : item.type === FIELDS.Checkbox ? (
                          <div className={css({ marginTop: "40px" })}>
                            {item.id === "addressOverwrite" ? (
                              <Tooltip
                                placement="bottomLeft"
                                content={
                                  <p>
                                    Zaznaczenie tej opcji spowoduje aktualizację
                                    danych <br /> wybranego adresata w bazie,
                                    ale nie wpłynie to na <br /> wcześniejsze
                                    dokumenty powiązane z tym adresatem.
                                  </p>
                                }
                              >
                                <span>
                                  <ControlledCheckbox
                                    control={control}
                                    name={item.id}
                                    disabled={
                                      loading ||
                                      (!isAnyAddressRevealed &&
                                        !!watchBeneficiary?.length &&
                                        item.id.includes("address") &&
                                        !item.id.includes("Name"))
                                    }
                                    {...(item.create.required && {
                                      rules: {
                                        required:
                                          formValidation.messages.required,
                                      },
                                    })}
                                  >
                                    {item.label}
                                  </ControlledCheckbox>
                                </span>
                              </Tooltip>
                            ) : (
                              <ControlledCheckbox
                                control={control}
                                name={item.id}
                                disabled={
                                  loading ||
                                  (!isAnyAddressRevealed &&
                                    !!watchBeneficiary?.length &&
                                    item.id.includes("address") &&
                                    !item.id.includes("Name"))
                                }
                                {...(item.create.required && {
                                  rules: {
                                    required: formValidation.messages.required,
                                  },
                                })}
                              >
                                {item.label}
                              </ControlledCheckbox>
                            )}
                          </div>
                        ) : item.info === "possiblyRequired" &&
                          (watchJrwa?.[0]?.isBeneficiaryAddressRequired ||
                            watchBeneficiary?.[0]?.typename === "Address" ||
                            isAnyAddressRevealed) ? (
                          <ControlledInput
                            control={control}
                            name={item.id}
                            id={item.id}
                            placeholder={
                              !isAnyAddressRevealed &&
                              !!watchBeneficiary?.length &&
                              item.id.includes("address") &&
                              !item.id.includes("Name")
                                ? "**********"
                                : item.id === "number" &&
                                  watchIsCustomNumber === true
                                ? ""
                                : item.placeholder
                            }
                            masked={
                              item.id === "addressNip"
                                ? watchCountryCode?.[0]?.id === "PL"
                                  ? item.mask !== undefined
                                  : false
                                : item.mask !== undefined
                            }
                            mask={item.mask}
                            maskChar="_"
                            key={watchJrwa?.[0]?.name + " " + item.id}
                            disabled={
                              (!isAnyAddressRevealed &&
                                !!watchBeneficiary?.length &&
                                item.id.includes("address")) ||
                              (item.id === "number" &&
                                watchIsCustomNumber === false)
                            }
                            {...(watchJrwa?.[0]
                              ?.isBeneficiaryAddressRequired && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                            {...(item.id === "addressEmail" && {
                              rules: {
                                pattern: {
                                  value: formValidation.patterns.email,
                                  message:
                                    formValidation.messages.incorrectEmail,
                                },
                              },
                            })}
                            endEnhancer={
                              item.id === "addressNip" && (
                                <Tooltip content="Pobierz z GUS">
                                  <span
                                    onClick={() => setIsGusDownloadOpen(true)}
                                    className={css({ cursor: "pointer" })}
                                  >
                                    <Download
                                      size={16}
                                      className={css({
                                        verticalAlign: "middle",
                                        marginLeft: "6px",
                                        display: "inline",
                                      })}
                                    />
                                  </span>
                                </Tooltip>
                              )
                            }
                          />
                        ) : (
                          <ControlledInput
                            control={control}
                            name={item.id}
                            id={item.id}
                            placeholder={
                              !isAnyAddressRevealed &&
                              !!watchBeneficiary?.length &&
                              item.id.includes("address") &&
                              !item.id.includes("Name")
                                ? "**********"
                                : item.id === "number" &&
                                  watchIsCustomNumber === true
                                ? ""
                                : item.placeholder
                            }
                            masked={item.mask !== undefined}
                            mask={item.mask}
                            maskChar="_"
                            disabled={
                              (!isAnyAddressRevealed &&
                                !!watchBeneficiary?.length &&
                                item.id.includes("address")) ||
                              (item.id === "number" &&
                                watchIsCustomNumber === false)
                            }
                            {...(item.create.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                            {...(item.id === "addressEmail" && {
                              rules: {
                                pattern: {
                                  value: formValidation.patterns.email,
                                  message:
                                    formValidation.messages.incorrectEmail,
                                },
                              },
                            })}
                            {...(item.id === "number" &&
                              watchIsCustomNumber === true && {
                                rules: {
                                  required: formValidation.messages.required,
                                },
                              })}
                            endEnhancer={
                              item.id === "addressNip" && (
                                <Tooltip content="Pobierz z GUS">
                                  <span
                                    onClick={() => setIsGusDownloadOpen(true)}
                                    className={css({ cursor: "pointer" })}
                                  >
                                    <Download
                                      size={16}
                                      className={css({
                                        verticalAlign: "middle",
                                        marginLeft: "6px",
                                        display: "inline",
                                      })}
                                    />
                                  </span>
                                </Tooltip>
                              )
                            }
                          />
                        )}
                      </FormControl>
                    </div>
                  </Cell>
                )),
            ])}
            <ConfirmDialog
              isOpen={isCancelConfirmDialogOpen}
              label="Anulowanie tworzenia sprawy"
              close={() => setIsCancelConfirmDialogOpen(false)}
              confirm={() => history.push("/cases")}
            />
            <ConfirmDialog
              isOpen={isClearConfirmDialogOpen}
              label="Wyczyszczenie formularza"
              close={() => setIsClearConfirmDialogOpen(false)}
              confirm={() => {
                reset();
                setIsClearConfirmDialogOpen(false);
              }}
            />
            <ConfirmDialog
              isOpen={isAddressRevealConfirmDialogOpen}
              type={ConfirmDialogType.AddressReveal}
              close={() => setIsAddressRevealConfirmDialogOpen(false)}
              confirm={() => onAddressRevealSubmit()}
            />
            <GusDownloadModal
              isOpen={isGusDownloadOpen}
              close={() => setIsGusDownloadOpen(false)}
              confirm={() => null}
              currentNip={watchNip}
              setAddressFromGus={setAddressFromGus}
            />
          </Grid>
        </form>
      </ModalBody>

      <ModalFooter>
        <Button
          kind={KIND.secondary}
          $style={{ marginRight: "10px" }}
          onClick={close}
        >
          Anuluj
        </Button>
        <Button
          disabled={isLoading}
          isLoading={isLoading}
          onClick={handleSubmit(onSubmit)}
        >
          Utwórz
        </Button>
      </ModalFooter>
    </Modal>
  );
}
