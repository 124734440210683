import { gql } from "@apollo/client";

export const DICTIONARIES_PAGINATED_INDEX = gql`
  query(
    $pageSize: Int
    $offset: Int
    $sorting: [DictionarySort!]
    $filter: DictionaryFilter
  ) {
    dictionariesPaginated(
      paging: { limit: $pageSize, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        name
        systemName
        description
        isSystem
        values {
          name
        }
      }
    }
  }
`;

export const DICTIONARIES_SHOW = gql`
  query($id: Int, $systemName: String, $showHidden: Boolean) {
    dictionary(id: $id, systemName: $systemName) {
      id
      name
      systemName
      description
      isSystem
      values(showHidden: $showHidden) {
        id
        name
        value
        isSystem
        isHidden
        dictionary {
          id
          isSystem
        }
      }
    }
  }
`;

export const DICTIONARIES_VALUE = gql`
  query($id: Int!) {
    dictionaryValue(id: $id) {
      name
      value
      id
      isHidden
      isSystem
    }
  }
`;

export const DICTIONARIES_VALUES = gql`
  query {
    dictionaryValues {
      name
      value
      id
      isHidden
      dictionaryId
      dictionary {
        id
        systemName
      }
    }
  }
`;

export const DICTIONARIES_VALUE_CREATE = gql`
  mutation CreateDictionaryValue(
    $name: String!
    $value: String!
    $isSystem: Boolean!
    $dictionaryId: Int!
  ) {
    dictionaryValueCreate(
      dictionaryValueCreateInput: {
        name: $name
        value: $value
        isSystem: $isSystem
        dictionaryId: $dictionaryId
      }
    ) {
      name
      value
      dictionary {
        id
        name
      }
    }
  }
`;

export const DICTIONARIES_VALUE_DELETE = gql`
  mutation DeleteDictionaryValue($id: Int!) {
    dictionaryValueDelete(dictionaryValueDeleteInput: { id: $id }) {
      name
    }
  }
`;

export const DICTIONARIES_VALUE_UPDATE = gql`
  mutation EditDictionaryValue(
    $id: Int!
    $name: String
    $value: String
    $dictionaryId: Int
    $isSystem: Boolean
    $isHidden: Boolean
  ) {
    dictionaryValueUpdate(
      dictionaryValueUpdateInput: {
        name: $name
        value: $value
        dictionaryId: $dictionaryId
        id: $id
        isSystem: $isSystem
        isHidden: $isHidden
      }
    ) {
      id
      name
      value
    }
  }
`;

export const DICTIONARIES_EXPORT_TO_XLSX = gql`
  mutation($id: Int!) {
    dictionaryExportToXlsx(id: $id) {
      downloadUrl
    }
  }
`;
