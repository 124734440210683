import { gql } from "@apollo/client";

export const JRWA_CLASSIFICATIONS_INDEX = gql`
  query(
    $offset: Int
    $filter: JrwaClassificationFilter
    $sorting: [JrwaClassificationSort!]
  ) {
    jrwaClassifications(
      paging: { limit: 1000, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        name
        symbol
        isDeletable
        isBeneficiaryAddressRequired
        isCaseConductedElectronically
        parent {
          id
        }
        isAssignable
        parentId
        archivalCategory
      }
    }
  }
`;

export const JRWA_CLASSIFICATIONS_SHOW = gql`
  query($id: Int!) {
    jrwaClassification(id: $id) {
      id
      name
      symbol
      archivalCategory
      isDeletable
      isBeneficiaryAddressRequired
      isCaseConductedElectronically
      isCaseNotRequired
      description
      parent {
        id
        name
      }
      parentId
      children {
        id
        name
        symbol
        isCaseConductedElectronically
        isDeletable
      }
    }
  }
`;

export const JRWA_CLASSIFICATIONS_DELETE = gql`
  mutation($id: Int!) {
    jrwaClassificationDelete(jrwaClassificationDeleteInput: { id: $id }) {
      id
    }
  }
`;

export const JRWA_CLASSIFICATIONS_CREATE = gql`
  mutation($jrwaClassificationCreateInput: JrwaClassificationCreateInput!) {
    jrwaClassificationCreate(
      jrwaClassificationCreateInput: $jrwaClassificationCreateInput
    ) {
      id
      name
    }
  }
`;

export const JRWA_CLASSIFICATIONS_UPDATE = gql`
  mutation($jrwaClassificationUpdateInput: JrwaClassificationUpdateInput!) {
    jrwaClassificationUpdate(
      jrwaClassificationUpdateInput: $jrwaClassificationUpdateInput
    ) {
      id
      name
    }
  }
`;
