import ActivityLogsIndex from "./containers/ActivityLogs/Index";
import ActivityLogsShow from "./containers/ActivityLogs/Show";
import AddressesBankAccountShow from "./containers/Addresses/BankAccountShow";
import AddressesIndex from "./containers/Addresses/Index";
import AddressesShow from "./containers/Addresses/Show";
import Login from "./containers/Auth/Login";
import Logout from "./containers/Auth/Logout";
import BudgetLedgerItemsIndex from "./containers/BudgetLedgerItems/Index";
import BudgetLedgerItemsShow from "./containers/BudgetLedgerItems/Show";
import CasesCreate from "./containers/Cases/Create";
import CasesEdit from "./containers/Cases/Edit";
import CasesIndex from "./containers/Cases/Index";
import CasesShare from "./containers/Cases/Share";
import CasesShow from "./containers/Cases/Show";
import CurrenciesIndex from "./containers/Currencies/Index";
import CurrenciesShow from "./containers/Currencies/Show";
import DictionariesIndex from "./containers/Dictionaries/Index";
import DictionariesShow from "./containers/Dictionaries/Show";
import DivisorsCreateItem from "./containers/Divisors/CreateItem";
import DivisorsCreateTemplate from "./containers/Divisors/CreateTemplate";
import DivisorsEditItem from "./containers/Divisors/EditItem";
import DivisorsEditTemplate from "./containers/Divisors/EditTemplate";
import DivisorsIndex from "./containers/Divisors/Index";
import DivisorsShowItem from "./containers/Divisors/ShowItem";
import DivisorsShowTemplate from "./containers/Divisors/ShowTemplate";
import DocumentParcelsCreate from "./containers/DocumentParcels/Create";
import DocumentParcelsEdit from "./containers/DocumentParcels/Edit";
import DocumentParcelsIndex from "./containers/DocumentParcels/Index";
import DocumentParcelsShow from "./containers/DocumentParcels/Show";
import DocumentPickupsCreate from "./containers/DocumentPickups/Create";
import DocumentPickupsEdit from "./containers/DocumentPickups/Edit";
import DocumentPickupsIndex from "./containers/DocumentPickups/Index";
import DocumentPickupsReturn from "./containers/DocumentPickups/Return";
import DocumentPickupsShow from "./containers/DocumentPickups/Show";
import DocumentsAll from "./containers/Documents/All";
import DocumentsCreateBillIssuanceRequest from "./containers/Documents/BillIssuanceRequest/Create";
import DocumentsEditBillIssuanceRequest from "./containers/Documents/BillIssuanceRequest/Edit";
import DocumentsShowBillIssuancenRequest from "./containers/Documents/BillIssuanceRequest/Show";
import DocumentsCreateContractAgreement from "./containers/Documents/ContractAgreement/Create";
import DocumentsEditContractAgreement from "./containers/Documents/ContractAgreement/Edit";
import DocumentsShowContractAgreement from "./containers/Documents/ContractAgreement/Show";
import DocumentsCreateContractPreparationRequest from "./containers/Documents/ContractPreparationRequest/Create";
import DocumentsEditContractPreparationRequest from "./containers/Documents/ContractPreparationRequest/Edit";
import DocumentsShowContractPreparationRequest from "./containers/Documents/ContractPreparationRequest/Show";
import DocumentsCreateFinancialAccountingDocument from "./containers/Documents/FinancialAccountingDocument/Create";
import DocumentsEditFinancialAccountingDocument from "./containers/Documents/FinancialAccountingDocument/Edit";
import DocumentsShowFinancialAccoutingDocument from "./containers/Documents/FinancialAccountingDocument/Show";
import DocumentsCreateIncoming from "./containers/Documents/Incoming/Create";
import DocumentsEditIncoming from "./containers/Documents/Incoming/Edit";
import DocumentsIncoming from "./containers/Documents/Incoming/Index";
import DocumentsShowIncoming from "./containers/Documents/Incoming/Show";
import DocumentsCreateInternal from "./containers/Documents/Internal/Create";
import DocumentsEditInternal from "./containers/Documents/Internal/Edit";
import DocumentsInternal from "./containers/Documents/Internal/Index";
import DocumentsShowInternal from "./containers/Documents/Internal/Show";
import DocumentsCreateOutgoing from "./containers/Documents/Outgoing/Create";
import DocumentsEditOutgoing from "./containers/Documents/Outgoing/Edit";
import DocumentsShowOutgoing from "./containers/Documents/Outgoing/Show";
import DocumentsCreateRequestForm from "./containers/Documents/RequestForm/Create";
import DocumentsEditRequestForm from "./containers/Documents/RequestForm/Edit";
import DocumentsShowRequestForm from "./containers/Documents/RequestForm/Show";
import DocumentsCreateRequestFormNote from "./containers/Documents/RequestFormNote/Create";
import DocumentsEditRequestFormNote from "./containers/Documents/RequestFormNote/Edit";
import DocumentsShowRequestFormNote from "./containers/Documents/RequestFormNote/Show";
import DocumentsShare from "./containers/Documents/Share";
import DocumentsShip from "./containers/Documents/Ship";
import ListWartosciowyKrajowyCreate from "./containers/ENadawcaParcels/ListWartosciowyKrajowy";
import ListZwyklyCreate from "./containers/ENadawcaParcels/ListZwykly";
import PaczkaPocztowaCreate from "./containers/ENadawcaParcels/PaczkaPocztowa";
import PaczkaZagranicznaCreate from "./containers/ENadawcaParcels/PaczkaZagraniczna";
import PrzesylkaListowaZadeklarowanaWartoscCreate from "./containers/ENadawcaParcels/PrzesylkaListowaZadeklarowanaWartosc";
import PrzesylkaNierejestrowanaKrajowa from "./containers/ENadawcaParcels/PrzesylkaNierejestrowanaKrajowa";
import PrzesylkaPoleconaKrajowaCreate from "./containers/ENadawcaParcels/PrzesylkaPoleconaKrajowa";
import PrzesylkaPoleconaZagranicznaCreate from "./containers/ENadawcaParcels/PrzesylkaPoleconaZagraniczna";
import PrzesylkaZagranicznaCreate from "./containers/ENadawcaParcels/PrzesylkaZagraniczna";
import Error404 from "./containers/ErrorPages/404";
import FinancialPlansIndex from "./containers/FinancialPlans/Index";
import FinancialPlansShow from "./containers/FinancialPlans/Show";
import FlowCreateAction from "./containers/Flow/CreateAction";
import FlowCreateProcess from "./containers/Flow/CreateProcess";
import FlowCreateStep from "./containers/Flow/CreateStep";
import FlowEditAction from "./containers/Flow/EditAction";
import FlowEditProcess from "./containers/Flow/EditProcess";
import FlowEditStep from "./containers/Flow/EditStep";
import FlowIndex from "./containers/Flow/Index";
import FlowShowAction from "./containers/Flow/ShowAction";
import FlowShowProcess from "./containers/Flow/ShowProcess";
import FlowShowStep from "./containers/Flow/ShowStep";
import FoldersFinished from "./containers/Folders/Finished";
import FoldersInFlow from "./containers/Folders/InFlow";
import FoldersReceived from "./containers/Folders/Received";
import FoldersSent from "./containers/Folders/Sent";
import GroupsCreate from "./containers/Groups/Create";
import GroupsEdit from "./containers/Groups/Edit";
import GroupsIndex from "./containers/Groups/Index";
import GroupsShow from "./containers/Groups/Show";
import Home from "./containers/Home";
import InternalAccountsCreate from "./containers/InternalAccounts/Create";
import InternalAccountsEdit from "./containers/InternalAccounts/Edit";
import InternalAccountsIndex from "./containers/InternalAccounts/Index";
import InternalAccountsShow from "./containers/InternalAccounts/Show";
import JrwaClassificationsCreate from "./containers/JrwaClassifications/Create";
import JrwaClassificationsEdit from "./containers/JrwaClassifications/Edit";
import JrwaClassifications from "./containers/JrwaClassifications/Index";
import JrwaClassificationsShow from "./containers/JrwaClassifications/Show";
import KnowledgeBase from "./containers/KnowledgeBase";
import MyAccount from "./containers/MyAccount";
import MySubstitutionsCreate from "./containers/MySubstitutions/Create";
import MySubstitutionsEdit from "./containers/MySubstitutions/Edit";
import MySubstitutionsIndex from "./containers/MySubstitutions/Index";
import MySubstitutionsShow from "./containers/MySubstitutions/Show";
import NotificationsIndex from "./containers/Notifications";
import OrganizationalUnitsCreate from "./containers/OrganizationalUnits/Create";
import OrganizationalUnitsEdit from "./containers/OrganizationalUnits/Edit";
import OrganizationalUnitsIndex from "./containers/OrganizationalUnits/Index";
import OrganizationalUnitsShow from "./containers/OrganizationalUnits/Show";
import PaymentsCreate from "./containers/Payments/Create";
import PaymentsEdit from "./containers/Payments/Edit";
import PaymentsIndex from "./containers/Payments/Index";
import PaymentsShow from "./containers/Payments/Show";
import PositionsCreate from "./containers/Positions/Create";
import PositionsEdit from "./containers/Positions/Edit";
import PositionsIndex from "./containers/Positions/Index";
import PositionsShow from "./containers/Positions/Show";
import RolesCreate from "./containers/Roles/Create";
import RolesEdit from "./containers/Roles/Edit";
import RolesIndex from "./containers/Roles/Index";
import RolesShow from "./containers/Roles/Show";
import SendingListsIndex from "./containers/SendingLists/Index";
import SendingListsShowList from "./containers/SendingLists/ShowSendingList";
import SendingListsShowListItem from "./containers/SendingLists/ShowSendingListItem";
import ShipmentContractsCreateContract from "./containers/ShipmentContracts/CreateContract";
import ShipmentContractsCreateRate from "./containers/ShipmentContracts/CreateRate";
import ShipmentContractsEditContract from "./containers/ShipmentContracts/EditContract";
import ShipmentContractsEditRate from "./containers/ShipmentContracts/EditRate";
import ShipmentContractsIndex from "./containers/ShipmentContracts/Index";
import ShipmentContractsShowContract from "./containers/ShipmentContracts/ShowContract";
import ShipmentContractsShowRate from "./containers/ShipmentContracts/ShowRate";
import SubstitutionsCreate from "./containers/Substitutions/Create";
import SubstitutionsEdit from "./containers/Substitutions/Edit";
import SubstitutionsIndex from "./containers/Substitutions/Index";
import SubstitutionsShow from "./containers/Substitutions/Show";
import UsersCreate from "./containers/Users/Create";
import UsersEdit from "./containers/Users/Edit";
import UsersIndex from "./containers/Users/Index";
import UsersShow from "./containers/Users/Show";

export type Route = {
  path: string;
  component: () => React.ReactElement;
  exact?: boolean;
};

export type ModelRoute = {
  name: string;
  path: string;
  index?: Route;
  show?: Route;
  showContractAgreement?: Route;
  showIncoming?: Route;
  showFinancialAccoutingDocument?: Route;
  showOutgoing?: Route;
  showInternal?: Route;
  showRequestForm?: Route;
  showBillIssuanceRequest?: Route;
  showContractPreparationRequest?: Route;
  showProcess?: Route;
  showStep?: Route;
  showAction?: Route;
  showSendingList?: Route;
  showSendingListItem?: Route;
  showShipmentContract?: Route;
  showShipmentRate?: Route;
  showDivisorTemplate?: Route;
  showDivisorItem?: Route;
  showBankAccount?: Route;
  showRequestFormNote?: Route;
  share?: Route;
  shareEdit?: Route;
  editStep?: Route;
  editAction?: Route;
  editProcess?: Route;
  edit?: Route;
  editIncoming?: Route;
  editOutgoing?: Route;
  editInternal?: Route;
  editRequestForm?: Route;
  editBillIssuanceRequest?: Route;
  editContractPreparationRequest?: Route;
  editContractAgreement?: Route;
  editFinancialAccoutingDocument?: Route;
  editShipmentContract?: Route;
  editShipmentRate?: Route;
  editSendingListItem?: Route;
  editDivisorTemplate?: Route;
  editDivisorItem?: Route;
  editRequestFormNote?: Route;
  create?: Route;
  createAction?: Route;
  createNested?: Route;
  createIncoming?: Route;
  createOutgoing?: Route;
  createInternal?: Route;
  createContractAgreement?: Route;
  createRequestForm?: Route;
  createBillIssuanceRequest?: Route;
  createContractPreparationRequest?: Route;
  createFinancialAccoutingDocument?: Route;
  createStep?: Route;
  createProcess?: Route;
  createShipmentContract?: Route;
  createShipmentRate?: Route;
  createDivisorTemplate?: Route;
  createDivisorItem?: Route;
  createRequestFormNote?: Route;
  incoming?: Route;
  internal?: Route;
  all?: Route;
  return?: Route;
  my?: Route;
  common?: Route;
  shared?: Route;
  received?: Route;
  sent?: Route;
  finished?: Route;
  ship?: Route;
  inFlow?: Route;
};

const ROUTES = {
  private: {
    basic: [
      {
        path: "/",
        component: Home,
        exact: true,
      },
      {
        path: "/my-account",
        component: MyAccount,
        exact: true,
      },
      {
        path: "/logout",
        component: Logout,
        exact: true,
      },
    ] as Route[],
    models: [
      {
        name: "users",
        path: "/users",
        index: {
          path: "/",
          component: UsersIndex,
        },
        create: {
          path: "/new",
          component: UsersCreate,
        },
        edit: {
          path: "/:id/edit",
          component: UsersEdit,
        },
        show: {
          path: "/:id",
          component: UsersShow,
        },
      },
      {
        name: "Groups",
        path: "/groups",
        index: {
          path: "/",
          component: GroupsIndex,
        },
        create: {
          path: "/create",
          component: GroupsCreate,
        },
        edit: {
          path: "/:id/edit",
          component: GroupsEdit,
        },
        show: {
          path: "/:id",
          component: GroupsShow,
        },
      },
      {
        name: "roles",
        path: "/roles",
        index: {
          path: "/",
          component: RolesIndex,
        },
        create: {
          path: "/new",
          component: RolesCreate,
        },
        edit: {
          path: "/:id/edit",
          component: RolesEdit,
        },
        show: {
          path: "/:id",
          component: RolesShow,
        },
      },
      {
        name: "organizational-units",
        path: "/organizational-units",
        index: {
          path: "/",
          component: OrganizationalUnitsIndex,
        },
        create: {
          path: "/:id/new",
          component: OrganizationalUnitsCreate,
        },
        edit: {
          path: "/:id/edit",
          component: OrganizationalUnitsEdit,
        },
        show: {
          path: "/:id",
          component: OrganizationalUnitsShow,
        },
      },
      {
        name: "positions",
        path: "/positions",
        index: {
          path: "/",
          component: PositionsIndex,
        },
        create: {
          path: "/new",
          component: PositionsCreate,
        },
        edit: {
          path: "/:id/edit",
          component: PositionsEdit,
        },
        show: {
          path: "/:id",
          component: PositionsShow,
        },
      },
      {
        name: "substitutions",
        path: "/substitutions",
        index: {
          path: "/",
          component: SubstitutionsIndex,
        },
        create: {
          path: "/new",
          component: SubstitutionsCreate,
        },
        edit: {
          path: "/:id/edit",
          component: SubstitutionsEdit,
        },
        show: {
          path: "/:id",
          component: SubstitutionsShow,
        },
      },
      {
        name: "my-substitutions",
        path: "/my-substitutions",
        index: {
          path: "/",
          component: MySubstitutionsIndex,
        },
        create: {
          path: "/new",
          component: MySubstitutionsCreate,
        },
        edit: {
          path: "/:id/edit",
          component: MySubstitutionsEdit,
        },
        show: {
          path: "/:id",
          component: MySubstitutionsShow,
        },
      },
      {
        name: "dictionaries",
        path: "/dictionaries",
        index: {
          path: "/",
          component: DictionariesIndex,
        },
        show: {
          path: "/:id",
          component: DictionariesShow,
        },
      },
      {
        name: "activity-logs",
        path: "/activity-logs",
        index: {
          path: "/",
          component: ActivityLogsIndex,
        },
        show: {
          path: "/:id",
          component: ActivityLogsShow,
        },
      },
      {
        name: "jrwa-classifications",
        path: "/jrwa-classifications",
        index: {
          path: "/",
          component: JrwaClassifications,
        },
        show: {
          path: "/:id",
          component: JrwaClassificationsShow,
        },
        create: {
          path: "/new",
          component: JrwaClassificationsCreate,
        },
        createNested: {
          path: "/:id/new",
          component: JrwaClassificationsCreate,
        },
        edit: {
          path: "/:id/edit",
          component: JrwaClassificationsEdit,
        },
      },
      {
        name: "payments",
        path: "/(documents/Internal/Payment|payments)",
        index: {
          path: "/",
          component: PaymentsIndex,
        },
        show: {
          path: "/:id",
          component: PaymentsShow,
        },
        create: {
          path: "/create",
          component: PaymentsCreate,
        },
        edit: {
          path: "/:id/edit",
          component: PaymentsEdit,
        },
      },
      {
        name: "cases",
        path: "/cases",
        index: {
          path: "/",
          component: CasesIndex,
        },
        show: {
          path: "/:id",
          component: CasesShow,
        },
        create: {
          path: "/new",
          component: CasesCreate,
        },
        edit: {
          path: "/:id/edit",
          component: CasesEdit,
        },
        share: {
          path: "/:id/share",
          component: CasesShare,
        },
      },
      {
        name: "folders",
        path: "/folders",
        received: {
          path: "/:folder(new|in-progress)?",
          component: FoldersReceived,
        },
        sent: {
          path: "/:folder(transferred|shared)?",
          component: FoldersSent,
        },
        finished: {
          path: "/:folder(finished)?",
          component: FoldersFinished,
        },
        inFlow: {
          path: "/:folder(in-flow)?",
          component: FoldersInFlow,
        },
      },
      {
        name: "documents",
        path: "/documents",
        all: {
          path: "/:folder(all)?",
          component: DocumentsAll,
        },
        incoming: {
          path: "/(incoming|register)",
          component: DocumentsIncoming,
        },
        internal: {
          path: "/internal",
          component: DocumentsInternal,
        },
        createIncoming: {
          path: "/create-incoming",
          component: DocumentsCreateIncoming,
        },
        createOutgoing: {
          path: "/create-outgoing",
          component: DocumentsCreateOutgoing,
        },
        createInternal: {
          path: "/create-internal",
          component: DocumentsCreateInternal,
        },
        createContractAgreement: {
          path: "/create-contract-agreement",
          component: DocumentsCreateContractAgreement,
        },
        createRequestForm: {
          path: "/create-request-form",
          component: DocumentsCreateRequestForm,
        },
        createBillIssuanceRequest: {
          path: "/create-bill-issuance-request",
          component: DocumentsCreateBillIssuanceRequest,
        },
        createContractPreparationRequest: {
          path: "/create-contract-preparation-request",
          component: DocumentsCreateContractPreparationRequest,
        },
        createFinancialAccoutingDocument: {
          path: "/create-financial-accounting-document",
          component: DocumentsCreateFinancialAccountingDocument,
        },
        createRequestFormNote: {
          path: "/create-request-form-note",
          component: DocumentsCreateRequestFormNote,
        },
        showContractAgreement: {
          path: "/incoming/ContractAgreement/:id",
          component: DocumentsShowContractAgreement,
        },
        showFinancialAccoutingDocument: {
          path: "/(incoming|internal|outgoing)/FinancialAccountingDocument/:id",
          component: DocumentsShowFinancialAccoutingDocument,
        },
        showRequestForm: {
          path: "/internal/RequestForm/:id",
          component: DocumentsShowRequestForm,
        },
        showBillIssuanceRequest: {
          path: "/internal/BillIssuanceRequest/:id",
          component: DocumentsShowBillIssuancenRequest,
        },
        showContractPreparationRequest: {
          path: "/internal/ContractPreparationRequest/:id",
          component: DocumentsShowContractPreparationRequest,
        },
        showRequestFormNote: {
          path: "/internal/RequestFormNote/:id",
          component: DocumentsShowRequestFormNote,
        },
        showIncoming: {
          path: "/incoming/Document/:id",
          component: DocumentsShowIncoming,
        },
        showOutgoing: {
          path: "/outgoing/Document/:id",
          component: DocumentsShowOutgoing,
        },
        showInternal: {
          path: "/internal/Document/:id",
          component: DocumentsShowInternal,
        },
        editFinancialAccoutingDocument: {
          path:
            "/(incoming|internal|outgoing)/FinancialAccountingDocument/:id/edit",
          component: DocumentsEditFinancialAccountingDocument,
        },
        editRequestForm: {
          path: "/internal/RequestForm/:id/edit",
          component: DocumentsEditRequestForm,
        },
        editBillIssuanceRequest: {
          path: "/internal/BillIssuanceRequest/:id/edit",
          component: DocumentsEditBillIssuanceRequest,
        },
        editContractAgreement: {
          path: "/incoming/ContractAgreement/:id/edit",
          component: DocumentsEditContractAgreement,
        },
        editContractPreparationRequest: {
          path: "/internal/ContractPreparationRequest/:id/edit",
          component: DocumentsEditContractPreparationRequest,
        },
        editRequestFormNote: {
          path: "/internal/RequestFormNote/:id/edit",
          component: DocumentsEditRequestFormNote,
        },
        editIncoming: {
          path: "/incoming/Document/:id/edit",
          component: DocumentsEditIncoming,
        },
        editOutgoing: {
          path: "/outgoing/Document/:id/edit",
          component: DocumentsEditOutgoing,
        },
        editInternal: {
          path: "/internal/Document/:id/edit",
          component: DocumentsEditInternal,
        },
        share: {
          path: "/(internal|incoming|outgoing)/(.*)/:id/share",
          component: DocumentsShare,
        },
        ship: {
          path: "/(internal|incoming|outgoing)/(.*)/:id/ship",
          component: DocumentsShip,
        },
      },
      {
        name: "DocumentPickups",
        path: "/document-pickups",
        index: {
          path: "/",
          component: DocumentPickupsIndex,
        },
        create: {
          path: "/create",
          component: DocumentPickupsCreate,
        },
        edit: {
          path: "/:id/edit",
          component: DocumentPickupsEdit,
        },
        show: {
          path: "/:id",
          component: DocumentPickupsShow,
        },
        return: {
          path: "/:id/return",
          component: DocumentPickupsReturn,
        },
      },
      {
        name: "addresses",
        path: "/addresses",
        index: {
          path: "/",
          component: AddressesIndex,
        },
        show: {
          path: "/:id",
          component: AddressesShow,
        },
        showBankAccount: {
          path: "/:id/bank-accounts/:bankAccountId",
          component: AddressesBankAccountShow,
        },
      },
      {
        name: "flow",
        path: "/flow",
        index: {
          path: "/",
          component: FlowIndex,
        },
        createProcess: {
          path: "/create-process",
          component: FlowCreateProcess,
        },
        showProcess: {
          path: "/:flowId",
          component: FlowShowProcess,
        },
        editProcess: {
          path: "/:id/edit-process",
          component: FlowEditProcess,
        },
        showStep: {
          path: "/:flowId/step/:stepId",
          component: FlowShowStep,
        },
        createStep: {
          path: "/:flowId/step/new",
          component: FlowCreateStep,
        },
        editStep: {
          path: "/:flowId/step/:stepId/edit",
          component: FlowEditStep,
        },
        showAction: {
          path: "/:flowId/action/:actionId",
          component: FlowShowAction,
        },
        createAction: {
          path: "/:flowId/action/new",
          component: FlowCreateAction,
        },
        editAction: {
          path: "/:flowId/action/:actionId/edit",
          component: FlowEditAction,
        },
      },
      {
        name: "currencies",
        path: "/currencies",
        index: {
          path: "/",
          component: CurrenciesIndex,
        },
        show: {
          path: "/:id",
          component: CurrenciesShow,
        },
      },
      {
        name: "divisors",
        path: "/divisors",
        index: {
          path: "/",
          component: DivisorsIndex,
        },
        createDivisorTemplate: {
          path: "/create-template",
          component: DivisorsCreateTemplate,
        },
        showDivisorTemplate: {
          path: "/:templateId",
          component: DivisorsShowTemplate,
        },
        editDivisorTemplate: {
          path: "/:templateId/edit-template",
          component: DivisorsEditTemplate,
        },
        showDivisorItem: {
          path: "/:templateId/:itemId",
          component: DivisorsShowItem,
        },
        createDivisorItem: {
          path: "/:templateId/create-item",
          component: DivisorsCreateItem,
        },
        editDivisorItem: {
          path: "/:templateId/:itemId/edit-item",
          component: DivisorsEditItem,
        },
      },
      {
        name: "internalAccounts",
        path: "/internal-accounts",
        index: {
          path: "/",
          component: InternalAccountsIndex,
        },
        show: {
          path: "/:id",
          component: InternalAccountsShow,
        },
        create: {
          path: "/create",
          component: InternalAccountsCreate,
        },
        edit: {
          path: "/:id/edit",
          component: InternalAccountsEdit,
        },
      },
      {
        name: "shipment-contracts",
        path: "/shipment-contracts",
        index: {
          path: "/",
          component: ShipmentContractsIndex,
        },
        showShipmentContract: {
          path: "/:contractId",
          component: ShipmentContractsShowContract,
        },
        createShipmentContract: {
          path: "/create-contract",
          component: ShipmentContractsCreateContract,
        },
        editShipmentContract: {
          path: "/:contractId/edit-contract",
          component: ShipmentContractsEditContract,
        },
        showShipmentRate: {
          path: "/:contractId/:rateId",
          component: ShipmentContractsShowRate,
        },
        createShipmentRate: {
          path: "/:contractId/create-rate",
          component: ShipmentContractsCreateRate,
        },
        editShipmentRate: {
          path: "/:contractId/:rateId/edit-rate",
          component: ShipmentContractsEditRate,
        },
      },
      {
        name: "document-parcels",
        path: "/document-parcels",
        index: {
          path: "/",
          component: DocumentParcelsIndex,
        },
        show: {
          path: "/:id",
          component: DocumentParcelsShow,
        },
        edit: {
          path: "/:id/edit",
          component: DocumentParcelsEdit,
        },
        create: {
          path: "/create",
          component: DocumentParcelsCreate,
        },
      },
      {
        name: "budget-ledger-items",
        path: "/budget-ledger-items",
        index: {
          path: "/",
          component: BudgetLedgerItemsIndex,
        },
        show: {
          path: "/:id",
          component: BudgetLedgerItemsShow,
        },
      },
      {
        name: "notifications",
        path: "/notifications",
        index: {
          path: "/",
          component: NotificationsIndex,
        },
      },
      {
        name: "sendingLists",
        path: "/sending-lists",
        index: {
          path: "/",
          component: SendingListsIndex,
        },
        showSendingList: {
          path: "/:listId",
          component: SendingListsShowList,
        },
        showSendingListItem: {
          path: "/:listId/:itemId",
          component: SendingListsShowListItem,
        },
      },
      {
        name: "paczkaPocztowa",
        path: "/documents/outgoing/:documentType/:documentId",
        create: {
          path: "/paczka-pocztowa",
          component: PaczkaPocztowaCreate,
        },
      },
      {
        name: "paczkaZagraniczna",
        path: "/documents/outgoing/:documentType/:documentId",
        create: {
          path: "/paczka-zagraniczna",
          component: PaczkaZagranicznaCreate,
        },
      },
      {
        name: "listZwykly",
        path: "/documents/outgoing/:documentType/:documentId",
        create: {
          path: "/list-zwykly",
          component: ListZwyklyCreate,
        },
      },
      {
        name: "przesylkaPoleconaKrajowa",
        path: "/documents/outgoing/:documentType/:documentId",
        create: {
          path: "/przesylka-polecona-krajowa",
          component: PrzesylkaPoleconaKrajowaCreate,
        },
      },
      {
        name: "przesylkaPoleconaZagraniczna",
        path: "/documents/outgoing/:documentType/:documentId",
        create: {
          path: "/przesylka-polecona-zagraniczna",
          component: PrzesylkaPoleconaZagranicznaCreate,
        },
      },
      {
        name: "przesylkaZagraniczna",
        path: "/documents/outgoing/:documentType/:documentId",
        create: {
          path: "/przesylka-zagraniczna",
          component: PrzesylkaZagranicznaCreate,
        },
      },
      {
        name: "przesylkaNierejestrowanaKrajowa",
        path: "/documents/outgoing/:documentType/:documentId",
        create: {
          path: "/przesylka-nierejestrowana-krajowa",
          component: PrzesylkaNierejestrowanaKrajowa,
        },
      },
      {
        name: "przesylkaListowaZadeklarowanaWartosc",
        path: "/documents/outgoing/:documentType/:documentId",
        create: {
          path: "/przesylka-listowa-zadeklarowana-wartosc",
          component: PrzesylkaListowaZadeklarowanaWartoscCreate,
        },
      },
      {
        name: "listWartosciowyKrajowy",
        path: "/documents/outgoing/:documentType/:documentId",
        create: {
          path: "/list-wartosciowy-krajowy",
          component: ListWartosciowyKrajowyCreate,
        },
      },
      {
        name: "financialPlan",
        path: "/financial-plans",
        index: {
          path: "/",
          component: FinancialPlansIndex,
        },
        show: {
          path: "/:financialPlanId",
          component: FinancialPlansShow,
        },
      },
      {
        name: "listWartosciowyKrajowy",
        path: "/documents/outgoing/:documentType/:documentId",
        create: {
          path: "/list-wartosciowy-krajowy",
          component: ListWartosciowyKrajowyCreate,
        },
      },
    ] as ModelRoute[],
  },
  auth: [
    {
      path: "/login",
      component: Login,
      exact: true,
    },
  ] as Route[],
  shared: [
    {
      path: "/knowledge-base",
      component: KnowledgeBase,
      exact: true,
    },
  ] as Route[],
  fallback: [
    {
      path: "*",
      component: Error404,
      exact: false,
    },
  ] as Route[],
};

export default ROUTES;
