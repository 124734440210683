import { gql } from "@apollo/client";

export const BUDGET_LEDGER_ITEMS_INDEX = gql`
  query(
    $pageSize: Int
    $offset: Int
    $sorting: [BudgetLedgerItemSort!]
    $filter: BudgetLedgerItemFilter
  ) {
    budgetLedgerItems(
      paging: { limit: $pageSize, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        budgetItemId
        budgetItem {
          budgetValue
          budgetId
          currentValue
          budget {
            name
            bazaId
            expensePositions {
              name
              budgetId
            }
          }
          budgetCategory {
            name
            path
          }
        }
        financialAccountingDocumentItem {
          name
          financialAccountingDocument {
            internalNumber
            documentType
            type
            documentKind
            id
          }
        }
        document {
          id
          documentType
          type
          documentKind
          internalNumber
        }
        change
        valueAfterChange
        createdAt
      }
    }
  }
`;

export const BUDGET_LEDGER_ITEMS_SHOW = gql`
  query($id: Int!) {
    budgetLedgerItem(id: $id) {
      id
      budgetItemId
      budgetItem {
        budgetValue
        currentValue
        budgetId
        budget {
          name
          bazaId
          expensePositions {
            name
            budgetId
          }
        }
        budgetCategory {
          name
          path
        }
      }
      financialAccountingDocumentItem {
        id
        name
        totalAmount
        totalAmountWithTax
        financialAccountingDocument {
          internalNumber
          documentType
          type
          documentKind
          id
        }
      }
      financialAccountingDocumentItemId
      documentId
      document {
        internalNumber
        documentType
        type
        documentKind
      }
      change
      valueAfterChange
      createdAt
      updatedAt
    }
  }
`;

export const EXPORT_BUDGET_ITEM_TO_XLSX = gql`
  mutation($id: Int!) {
    budgetItemExportToXlsx(id: $id) {
      downloadUrl
    }
  }
`;

export const EXPORT_ALL_BUDGET_ITEMS_TO_XLSX = gql`
  mutation {
    budgetItemsExportAllToXlsx {
      downloadUrl
    }
  }
`;
