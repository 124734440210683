import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND } from "baseui/button";
import { StyledLink } from "baseui/link";
import { Radio, RadioGroup } from "baseui/radio";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import { Row } from "react-table";
import {
  AlertOctagon,
  Cloud,
  FileOff,
  Paperclip,
  Share,
} from "tabler-icons-react";

import { InputValidationError } from "../../../api";
import CasePermissionsSwitch from "../../../components/case-permissions-switch";
import Cell from "../../../components/cell";
import Checkbox from "../../../components/checkbox";
import ConfirmDialog from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import FormControl from "../../../components/form-control";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import { ControlledUsersSelect } from "../../../components/select";
import Table from "../../../components/table";
import { ControlledTextArea } from "../../../components/text-area";
import Tooltip from "../../../components/tooltip";
import { useAssignment } from "../../../contexts/assignment-context";
import { useAuth } from "../../../contexts/auth-context";
import { useDictionaries } from "../../../contexts/dictionaries-context";
import { useLoading } from "../../../contexts/loading-context";
import { useSubstitution } from "../../../contexts/substitution-context";
import { checkPermission } from "../../../utils/check-permission";
import { formValidation } from "../../../utils/formValidation";
import { PERMISSIONS } from "../../../utils/permissions";
import { scrollOnError } from "../../../utils/scrollOnError";
import { DictionaryValue } from "../../Dictionaries/dictionary";
import { Document } from "../../Documents/documents";
import { AssignmentLocationState } from "../../Folders/folders";
import { CaseSharingAccessLevel, CaseSharingScope } from "../cases.d";
import { CASES_FIELDS, ShareFormInputs } from "../cases.form";
import { CASES_SHARE, CASES_SHOW } from "../cases.gql";

export default function CasesShare(): React.ReactElement {
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const [errorTimeStamp, setErrorTimeStamp] = useState<number>();
  const [css, theme] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { user } = useAuth();
  const { isFetching, setIsFetching, isLoading, setIsLoading } = useLoading();
  const { id } = useParams<{ id: string }>();

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    watch,
  } = useForm<ShareFormInputs>({
    defaultValues: {
      scope: CaseSharingScope.Full,
      accessLevel: CaseSharingAccessLevel.Read,
    },
  });

  const [selectedDocuments, setSelectedDocuments] = useState<Array<number>>([]);
  const [selectedAccessLevels, setSelectedAccessLevels] = useState<
    Map<number, CaseSharingAccessLevel>
  >(new Map());

  const [
    globalAccessLevel,
    setGlobalAccessLevel,
  ] = useState<CaseSharingAccessLevel>();

  const { incomingTypes, internalTypes, outgoingTypes } = useDictionaries();

  const { assignment, setAssignment, setIsAssignmentActive } = useAssignment();
  const { isSubstitutionActive, substitution } = useSubstitution();

  const { state } = useLocation<AssignmentLocationState>();
  const [shareCase, { error }] = useMutation(CASES_SHARE, {
    ...((state?.assignmentId || assignment?.id) &&
      state?.assignmentId !== null && {
        context: {
          headers: {
            Assignment: state?.assignmentId || assignment?.id,
          },
        },
      }),
  });

  const { data, error: queryError } = useQuery(CASES_SHOW, {
    variables: { id: parseInt(id) },
  });

  const onError = (errors: Record<string, unknown>, e: any) =>
    setErrorTimeStamp(e.timeStamp);

  const onSubmit = async (shareFormValues: ShareFormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      await shareCase({
        variables: {
          caseShareInput: {
            caseId: parseInt(id),
            shareeIds: shareFormValues.users?.map(({ id }) => id),
            comment: shareFormValues.comment,
            scope: shareFormValues.scope,
            ...(shareFormValues.scope === CaseSharingScope.Full && {
              permission: shareFormValues.accessLevel,
            }),
            ...(shareFormValues.scope === CaseSharingScope.Partial && {
              documentIncludeInputs: selectedDocuments.map((documentId) => ({
                documentId,
                permission:
                  selectedAccessLevels?.get(documentId) ||
                  globalAccessLevel ||
                  CaseSharingAccessLevel.Read,
              })),
            }),
          },
        },
      });

      enqueueSnackbar({
        message: "Udostępniono pomyślnie",
        variant: "success",
      });

      history.push(`/cases/${id}`);
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const watchScope = watch("scope");

  useEffect(() => setIsFetching(true), []);

  useEffect(() => {
    if (data?.case?.currentAssignment) {
      setAssignment(data?.case?.currentAssignment);
      setIsAssignmentActive(true);
    }
    if (data) setIsFetching(false);
  }, [data]);

  useEffect(() => {
    if (queryError?.graphQLErrors)
      enqueueSnackbar({
        message: (queryError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [queryError]);

  useEffect(() => {
    if (errors) scrollOnError(errors, CASES_FIELDS, "edit");
  }, [errorTimeStamp]);

  const columns = React.useMemo(
    () => [
      {
        Header: () => (
          <Block
            width="20px"
            marginLeft="8px"
            display="flex"
            justifyContent="center"
          >
            <Checkbox
              isIndeterminate={
                selectedDocuments?.length > 0 &&
                selectedDocuments?.length < data?.case?.documents.length
              }
              checked={
                selectedDocuments?.length === data?.case?.documents.length
              }
              onChange={() => {
                if (
                  selectedDocuments?.length !== data?.case?.documents.length
                ) {
                  setSelectedDocuments(
                    data?.case?.documents.map(
                      (document: Document) => document.id
                    )
                  );
                } else {
                  setSelectedDocuments([]);
                }
              }}
            />
          </Block>
        ),
        id: "checkbox",
        accessor: "checked",
        Cell: ({ row }: { row: Row<Document> }) => (
          <Block
            width="20px"
            marginLeft="8px"
            display="flex"
            justifyContent="center"
          >
            <Checkbox
              checked={selectedDocuments?.includes(row.original.id)}
              onChange={() => {
                if (selectedDocuments?.includes(row.original.id)) {
                  setSelectedDocuments([
                    ...selectedDocuments?.filter(
                      (id) => id !== row.original.id
                    ),
                  ]);
                } else {
                  setSelectedDocuments([
                    ...(selectedDocuments ? selectedDocuments : []),
                    row.original.id,
                  ]);
                }
              }}
            />
          </Block>
        ),
      },
      {
        Header: "Numer",
        accessor: "internalNumber",
        Cell: ({ row }: { row: Row<Document> }) => (
          <StyledLink
            onClick={(event: MouseEvent) => {
              event.preventDefault();
              history.push(
                `/documents/${row?.original?.documentKind?.toLowerCase()}/${
                  row.original.type
                }/${row.original.id}`
              );
            }}
            href={`/documents/${row?.original?.documentKind?.toLowerCase()}/${
              row.original.type
            }/${row.original.id}`}
          >
            {row.original.internalNumber}
          </StyledLink>
        ),
      },
      {
        Header: "Typ dokumentu",
        id: "documentType",
        Cell: ({ row }: { row: Row<Document> }) => (
          <FormattedValue>
            {row.original.documentKind === "Incoming"
              ? incomingTypes.find(
                  (type: DictionaryValue) =>
                    type.value === row.original.documentType
                )?.name
              : row.original.documentKind === "Outgoing"
              ? outgoingTypes.find(
                  (type: DictionaryValue) =>
                    type.value === row.original.documentType
                )?.name
              : row.original.documentKind === "Internal"
              ? internalTypes.find(
                  (type: DictionaryValue) =>
                    type.value === row.original.documentType
                )?.name
              : row.original.documentType}
          </FormattedValue>
        ),
      },

      {
        Header: (
          <Block display="flex" justifyContent="flex-end">
            Data rejestracji
          </Block>
        ),
        accessor: "createdAt",
        Cell: ({ row }: { row: Row<Document> }) => (
          <Block display="flex" justifyContent="flex-end">
            <FormattedValue dataType="date">
              {row.original.createdAt}
            </FormattedValue>
          </Block>
        ),
      },
      {
        Header: () => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            <CasePermissionsSwitch
              checked={globalAccessLevel === CaseSharingAccessLevel.Update}
              onChange={(checked) => {
                setGlobalAccessLevel(
                  checked
                    ? CaseSharingAccessLevel.Update
                    : CaseSharingAccessLevel.Read
                );

                setSelectedAccessLevels((selectedAccessLevels) => {
                  const selectedAccessLevels_ = new Map(selectedAccessLevels);

                  [...selectedAccessLevels_.keys()].forEach((key) => {
                    selectedAccessLevels_.set(
                      key,
                      checked
                        ? CaseSharingAccessLevel.Update
                        : CaseSharingAccessLevel.Read
                    );
                  });

                  return selectedAccessLevels_;
                });
              }}
            />
          </div>
        ),
        id: "switch",
        Cell: ({ row }: { row: Row<Document> }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            <CasePermissionsSwitch
              checked={
                (selectedAccessLevels?.get(row.original.id) ||
                  globalAccessLevel) === CaseSharingAccessLevel.Update
              }
              disabled={!selectedDocuments?.includes(row.original.id)}
              onChange={(checked: boolean) => {
                setSelectedAccessLevels((selectedAccessLevels) =>
                  new Map(selectedAccessLevels).set(
                    row.original.id,
                    checked
                      ? CaseSharingAccessLevel.Update
                      : CaseSharingAccessLevel.Read
                  )
                );
              }}
            />
          </div>
        ),
      },
    ],
    [
      incomingTypes,
      outgoingTypes,
      selectedDocuments,
      selectedAccessLevels,
      globalAccessLevel,
    ]
  );

  if (
    data &&
    data?.case &&
    (!!data?.case?.closedAt || !checkPermission(PERMISSIONS.case.update))
  )
    return <Redirect to="/" />;

  return (
    <article>
      <Header
        title={
          <div
            className={css({
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
              gap: "5px",
              width: "max-content",
              color: data?.case?.isConductedElectronically
                ? theme.colors.positive
                : theme.colors.negative,
            })}
          >
            <span
              className={css({
                whiteSpace: "nowrap",
              })}
            >
              {data?.case?.number}
            </span>

            <Tooltip
              content={
                data?.case?.isConductedElectronically
                  ? "Sprawa prowadzona elektronicznie"
                  : "Sprawa prowadzona tradycyjnie"
              }
            >
              <span>
                {data?.case?.isConductedElectronically ? (
                  <Cloud
                    size={18}
                    className={css({
                      verticalAlign: "middle",
                      display: "inline",
                      color: theme.colors.positive,
                    })}
                  />
                ) : (
                  <Paperclip
                    size={18}
                    className={css({
                      verticalAlign: "middle",
                      display: "inline",
                      color: theme.colors.negative,
                    })}
                  />
                )}
              </span>
            </Tooltip>
          </div>
        }
        labels={["Sprawa", data?.case?.name]}
        goBackOption
        hasActiveAssignmentBar
        buttons={[
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              isDirty ? setIsCancelConfirmDialogOpen(true) : history.goBack(),
          },
          {
            label: "Udostępnij",
            kind: KIND.primary,
            startEnhancer: <Share size={18} />,
            onClick: handleSubmit(onSubmit, onError),
            disabled: isLoading,
            isLoading: isLoading,
            type: "submit",
            formId: "shareCase",
          },
        ]}
      />
      <Content>
        <form id="shareCase" onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            <Cell span={4}>
              <FormControl label="Tytuł" disabled={true}>
                {isFetching ? (
                  <Skeleton rows={0} height="30px" width="100%" animation />
                ) : (
                  <FormattedValue>{data?.case?.name}</FormattedValue>
                )}
              </FormControl>
            </Cell>
            <Cell span={4}>
              <FormControl label="Prowadzący sprawę" disabled={true}>
                {isFetching ? (
                  <Skeleton rows={0} height="30px" width="100%" animation />
                ) : (
                  <FormattedValue
                    dataType="model:users"
                    data={data?.case?.supervisor?.id}
                  >
                    {`${data?.case?.supervisor?.firstName} ${data?.case?.supervisor?.lastName}`}
                  </FormattedValue>
                )}
              </FormControl>
            </Cell>
            <Cell span={4}>
              <FormControl label="Jednostka organizacyjna" disabled={true}>
                {isFetching ? (
                  <Skeleton rows={0} height="30px" width="100%" animation />
                ) : (
                  <FormattedValue
                    dataType="model:organizational-units"
                    data={data?.case?.organizationalUnit?.id}
                  >
                    {data?.case?.organizationalUnit?.name}
                  </FormattedValue>
                )}
              </FormControl>
            </Cell>

            <Cell span={12}>
              <Block
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                marginBottom="scale200"
                marginTop="scale600"
                height="20px"
              >
                <Block
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <LabelMedium>Dostęp</LabelMedium>
                </Block>
              </Block>
              <hr
                className={css({
                  borderWidth: "0px",
                  height: "1px",
                  backgroundColor: "#eee",
                })}
              />
            </Cell>

            <Cell span={12}>
              <FormControl
                label="Użytkownicy"
                required
                disabled={isLoading}
                error={
                  ((errors as any)["users"] &&
                    (errors as any)["users"].message) ||
                  (error &&
                    error.graphQLErrors[0]?.extensions?.code ===
                      "INPUT_VALIDATION_ERROR" &&
                    error.graphQLErrors[0]?.extensions?.validationErrors
                      ?.find(
                        (vE: InputValidationError) => vE?.property === "userIds"
                      )
                      ?.errors.map((message: string) => (
                        <div
                          key="error"
                          className={css({
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          })}
                        >
                          {message}
                          <AlertOctagon color="#999" size={12} />
                        </div>
                      ))[0])
                }
              >
                <ControlledUsersSelect
                  multi
                  control={control}
                  name="users"
                  placeholder="Wybierz"
                  rules={{
                    required: formValidation.messages.required,
                  }}
                  filterOptions={(options) =>
                    options.filter((o) =>
                      isSubstitutionActive
                        ? o.id !== substitution?.substitutee?.id
                        : o.id !== user?.id
                    )
                  }
                />
              </FormControl>

              <FormControl
                label="Komentarz"
                disabled={isLoading}
                error={
                  ((errors as any)["comment"] &&
                    (errors as any)["comment"].message) ||
                  (error &&
                    error.graphQLErrors[0]?.extensions?.code ===
                      "INPUT_VALIDATION_ERROR" &&
                    error.graphQLErrors[0]?.extensions?.validationErrors
                      ?.find(
                        (vE: InputValidationError) => vE?.property === "comment"
                      )
                      ?.errors.map((message: string) => (
                        <div
                          key="error"
                          className={css({
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          })}
                        >
                          {message}
                          <AlertOctagon color="#999" size={12} />
                        </div>
                      ))[0])
                }
              >
                <ControlledTextArea control={control} name="comment" />
              </FormControl>

              <FormControl
                label="Rodzaj udostępnienia"
                required
                disabled={isLoading}
                error={
                  ((errors as any)["scope"] &&
                    (errors as any)["scope"].message) ||
                  (error &&
                    error.graphQLErrors[0]?.extensions?.code ===
                      "INPUT_VALIDATION_ERROR" &&
                    error.graphQLErrors[0]?.extensions?.validationErrors
                      ?.find(
                        (vE: InputValidationError) => vE?.property === "scope"
                      )
                      ?.errors.map((message: string) => (
                        <div
                          key="error"
                          className={css({
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          })}
                        >
                          {message}
                          <AlertOctagon color="#999" size={12} />
                        </div>
                      ))[0])
                }
              >
                <Controller
                  control={control}
                  name={"scope"}
                  defaultValue="all"
                  rules={{
                    required: formValidation.messages.required,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <RadioGroup
                      value={value}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        onChange(e.target.value)
                      }
                      name="number"
                      align="horizontal"
                      overrides={{
                        RadioGroupRoot: {
                          style: {
                            display: "flex",
                            marginLeft: "-18px",
                            marginRight: "-18px",
                          },
                        },
                      }}
                    >
                      <Radio
                        value="Full"
                        overrides={{
                          Root: {
                            style: {
                              flexGrow: 1,
                              flexShrink: 1,
                              flexBasis: 0,
                              paddingLeft: "18px",
                              paddingRight: "18px",
                              marginTop: 0,
                              marginBottom: 0,
                            },
                          },
                          RadioMarkInner: {
                            style: ({ $checked }) => ({
                              width: $checked ? "6px" : "16px",
                              height: $checked ? "6px" : "16px",
                            }),
                          },
                        }}
                      >
                        Sprawa ze wszystkimi dokumentami
                      </Radio>

                      <Radio
                        value="Partial"
                        overrides={{
                          Root: {
                            style: {
                              flexGrow: 1,
                              flexShrink: 1,
                              flexBasis: 0,
                              paddingLeft: "18px",
                              paddingRight: "18px",
                              marginTop: 0,
                              marginBottom: 0,
                            },
                          },
                          RadioMarkInner: {
                            style: ({ $checked }) => ({
                              width: $checked ? "6px" : "16px",
                              height: $checked ? "6px" : "16px",
                            }),
                          },
                        }}
                      >
                        Sprawa z wybranymi dokumentami
                      </Radio>
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </Cell>

            {watchScope === CaseSharingScope.Full && (
              <Cell span={6}>
                <FormControl
                  label="Uprawnienia"
                  required
                  disabled={isLoading}
                  error={
                    ((errors as any)["accessLevel"] &&
                      (errors as any)["accessLevel"].message) ||
                    (error &&
                      error.graphQLErrors[0]?.extensions?.code ===
                        "INPUT_VALIDATION_ERROR" &&
                      error.graphQLErrors[0]?.extensions?.validationErrors
                        ?.find(
                          (vE: InputValidationError) =>
                            vE?.property === "accessLevel"
                        )
                        ?.errors.map((message: string) => (
                          <div
                            key="error"
                            className={css({
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            })}
                          >
                            {message}
                            <AlertOctagon color="#999" size={12} />
                          </div>
                        ))[0])
                  }
                >
                  <Controller
                    control={control}
                    name={"accessLevel"}
                    rules={{
                      required: formValidation.messages.required,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <RadioGroup
                        value={value}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          onChange(e.target.value)
                        }
                        name="number"
                        align="horizontal"
                        overrides={{
                          RadioGroupRoot: {
                            style: {
                              display: "flex",
                              marginLeft: "-18px",
                              marginRight: "-18px",
                            },
                          },
                        }}
                      >
                        <Radio
                          value="Read"
                          overrides={{
                            Root: {
                              style: {
                                flexGrow: 1,
                                flexShrink: 1,
                                flexBasis: 0,
                                paddingLeft: "18px",
                                paddingRight: "18px",
                                marginTop: 0,
                                marginBottom: 0,
                              },
                            },
                            RadioMarkInner: {
                              style: ({ $checked }) => ({
                                width: $checked ? "6px" : "16px",
                                height: $checked ? "6px" : "16px",
                              }),
                            },
                          }}
                        >
                          Odczyt
                        </Radio>

                        <Radio
                          value="Update"
                          overrides={{
                            Root: {
                              style: {
                                flexGrow: 1,
                                flexShrink: 1,
                                flexBasis: 0,
                                paddingLeft: "18px",
                                paddingRight: "18px",
                                marginTop: 0,
                                marginBottom: 0,
                              },
                            },
                            RadioMarkInner: {
                              style: ({ $checked }) => ({
                                width: $checked ? "6px" : "16px",
                                height: $checked ? "6px" : "16px",
                              }),
                            },
                          }}
                        >
                          Edycja
                        </Radio>
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              </Cell>
            )}

            {watchScope === CaseSharingScope.Partial && (
              <Cell span={12}>
                <FormControl label="Dokumenty" required disabled={isLoading}>
                  <>
                    {/* <Filters
                      compact
                      filters={DOCUMENTS_FILTERS}
                      state={filters}
                      setState={setFilters}
                    /> */}

                    <Table<Document>
                      $style={{ marginTop: theme.sizing.scale500 }}
                      columns={columns}
                      compact
                      data={data?.case?.documents}
                    />
                  </>
                </FormControl>
              </Cell>
            )}

            <ConfirmDialog
              isOpen={isCancelConfirmDialogOpen}
              label="Anulowanie udostępniania sprawy"
              close={() => setIsCancelConfirmDialogOpen(false)}
              confirm={() => history.goBack()}
            />
          </Grid>
        </form>
      </Content>
    </article>
  );
}
