import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { KIND } from "baseui/button";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { DeviceFloppy, FileOff } from "tabler-icons-react";

import ConfirmDialog from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import FormRenderer from "../../../components/form-renderer";
import Header from "../../../components/header";
import { useLoading } from "../../../contexts/loading-context";
import { checkPermission } from "../../../utils/check-permission";
import { PERMISSIONS } from "../../../utils/permissions";
import { Role } from "../../Roles/roles";
import { EditFormInputs, USERS_FIELDS } from "../users.form";
import { USERS_EDIT, USERS_SHOW } from "../users.gql";

export default function UsersEdit(): React.ReactElement {
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );

  const { enqueueSnackbar } = useSnackbar();

  const {
    control,
    formState: { errors, isDirty, ...formState },
    handleSubmit,
    setValue,
    reset,
    ...methods
  } = useForm<EditFormInputs>({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
    },
  });

  const history = useHistory();

  const { id } = useParams<{ id?: string }>();

  const { refetch, data, error: queryError } = useQuery(USERS_SHOW, {
    variables: { id: id ? parseInt(id) : null },
    fetchPolicy: "cache-first",
  });

  const [editUser, { error }] = useMutation(USERS_EDIT);

  const { setIsFetching, isLoading, setIsLoading } = useLoading();

  const onSubmit = async ({
    firstName,
    lastName,
    position,
    email,
    roleIds,
  }: EditFormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      await editUser({
        variables: {
          id: id ? parseInt(id) : null,
          firstName,
          lastName,
          email,
          position: position?.[0]?.label,
          roleIds: roleIds && roleIds.map(({ id }) => id),
        },
      });

      enqueueSnackbar({
        message: "Zapisano pomyślnie",
        variant: "success",
      });
      history.push(`/users/${id}`);
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (data?.user) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => {
    if (data?.user) {
      setValue("firstName", data.user.firstName);
      setValue("lastName", data.user.lastName);
      setValue("email", data.user.email);
      setValue("password", data.user.password);
      setValue("position", [{ label: data.user.position }]);
      setValue(
        "roleIds",
        data.user?.roles &&
          data.user?.roles.map(({ id, name }: Role) => ({ id, name }))
      );
      setIsFetching(false);
    }
  }, [data]);

  useEffect(() => {
    if (queryError?.graphQLErrors)
      enqueueSnackbar({
        message: (queryError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [queryError]);

  if (!checkPermission(PERMISSIONS.user.update)) return <Redirect to="/" />;

  return (
    <article>
      <Header
        title={data?.user?.firstName + " " + data?.user?.lastName}
        labels={["Użytkownicy", "Edytowanie"]}
        goBackOption
        buttons={[
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              isDirty ? setIsCancelConfirmDialogOpen(true) : history.goBack(),
          },
          {
            label: "Zapisz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            onClick: handleSubmit(onSubmit),
            disabled: isLoading,
            isLoading: isLoading,
            type: "submit",
            formId: "editUser",
          },
        ]}
      />
      <Content>
        <FormProvider<EditFormInputs>
          control={control}
          formState={{ errors, isDirty, ...formState }}
          handleSubmit={handleSubmit}
          reset={reset}
          setValue={setValue}
          {...methods}
        >
          <FormRenderer
            id="editUser"
            onSubmit={handleSubmit(onSubmit)}
            type="edit"
            isLoading={isLoading}
            error={error}
            fields={USERS_FIELDS}
          />
        </FormProvider>

        <ConfirmDialog
          isOpen={isCancelConfirmDialogOpen}
          label="Anulowanie edycji użytkownika"
          close={() => setIsCancelConfirmDialogOpen(false)}
          confirm={() => history.goBack()}
        />
      </Content>
    </article>
  );
}
