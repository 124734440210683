import { useDrag } from "@use-gesture/react";
import { useStyletron } from "baseui";
import React, { useEffect, useState } from "react";

import { Assignment } from "../containers/Folders/folders";
import {
  ASSIGNMENT_CREATED,
  ASSIGNMENT_DISPATCH_CANCELLED,
} from "../containers/Folders/folders.gql";
import { useAuth } from "../contexts/auth-context";
import { useSubstitution } from "../contexts/substitution-context";
import { client } from "../utils/client";
import Loading from "./loading";
import SideNav from "./side-nav";
import SubstitutionBar from "./substitution-bar";

type LayoutProps = {
  children?: React.ReactNode;
};

const notificationSound = new Audio("/wav/chord.wav");

export default function Layout({ children }: LayoutProps): React.ReactElement {
  const [css, theme] = useStyletron();

  const { substitution, isSubstitutionActive } = useSubstitution();

  const { isAuthenticated, refetchNewAssignments } = useAuth();

  const customSidebarWidth =
    localStorage.getItem("sidebarWidth") &&
    parseInt(localStorage.getItem("sidebarWidth") as string);

  const [sidebarWidth, setSidebarWidth] = useState<number>(
    customSidebarWidth || 225
  );

  const [initialSidebarWidth, setInitialSidebarWidth] = useState<number>(
    customSidebarWidth || 225
  );

  useEffect(() => localStorage.setItem("sidebarWidth", String(sidebarWidth)), [
    sidebarWidth,
  ]);

  const bind = useDrag(({ movement: [mx], event }) => {
    event.stopPropagation();
    event.preventDefault();

    if (mx === 0) setInitialSidebarWidth(sidebarWidth);
    else setSidebarWidth(initialSidebarWidth + mx);
  });

  useEffect(() => {
    if (isAuthenticated) {
      const observer = client.subscribe<{ assignmentCreated: Assignment }>({
        query: ASSIGNMENT_CREATED,
      });

      const subscription = observer.subscribe(() => {
        refetchNewAssignments();
        notificationSound.play();
      });

      const observerDispatchCancel = client.subscribe<{
        assignmentDispatchCancelled: Assignment;
      }>({
        query: ASSIGNMENT_DISPATCH_CANCELLED,
      });

      const subscriptionDispatchCancel = observerDispatchCancel.subscribe(() =>
        refetchNewAssignments()
      );

      return () => {
        subscription.unsubscribe();
        subscriptionDispatchCancel.unsubscribe();
      };
    }
  }, [isAuthenticated]);

  return (
    <main className={css({ display: "flex", height: "100vh" })}>
      <aside
        className={css({
          ...(sidebarWidth > 100
            ? {
                maxWidth: "300px",
                minWidth: "200px",
                width: sidebarWidth ? `${sidebarWidth}px` : "30%",
              }
            : {
                width: "60px",
                ...(initialSidebarWidth > 100 && {
                  animationDuration: "200ms",
                  animationName: {
                    from: {
                      width: "100px",
                    },
                    to: {
                      width: "60px",
                    },
                  } as any,
                }),
              }),
          flexShrink: 0,
          position: "relative",
        })}
      >
        <SideNav width={sidebarWidth} />

        <div
          {...bind()}
          className={css({
            position: "absolute",
            right: "-3px",
            top: "0px",
            bottom: "0px",
            width: "8px",
            zIndex: 20,
            cursor: "col-resize",
            ":hover [data-ui=fill]": {
              transform: "scaleX(1)",
            },
          })}
        >
          <div
            data-ui="fill"
            className={css({
              width: "100%",
              height: "100%",
              backgroundColor: "#ddd",
              transform: "scaleX(0)",
              transition: "200ms ease",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            })}
          >
            <span
              className={css({
                width: "2px",
                height: "20px",
                borderRadius: "1px",
                backgroundColor: "#eee",
              })}
            />
          </div>
        </div>
      </aside>

      <section
        className={css({
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
          position: "relative",
          paddingBottom: theme.sizing.scale1600,
        })}
      >
        <Loading />
        {children}
      </section>

      {substitution && isSubstitutionActive && <SubstitutionBar />}
    </main>
  );
}
