import { ApolloError, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { KIND, SIZE } from "baseui/button";
import { StyledLink } from "baseui/link";
import { useSnackbar } from "notistack";
import React, { MouseEvent, useEffect, useMemo, useState } from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { Row } from "react-table";
import { Circle, Eye, Pencil, Plus } from "tabler-icons-react";

import BottomPanel from "../../../components/bottom-panel";
import Button from "../../../components/button";
import Cell from "../../../components/cell";
import Content from "../../../components/content";
import Filters from "../../../components/filters";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import PagingControls from "../../../components/paging-controls";
import SortingTableHeader, {
  SortDirection,
} from "../../../components/sorting-table-header";
import Table from "../../../components/table";
import { useLoading } from "../../../contexts/loading-context";
import { usePaging } from "../../../contexts/paging-context";
import { FiltersState } from "../../../filters";
import { checkPermission } from "../../../utils/check-permission";
import { translateFiltersState } from "../../../utils/filters";
import { PERMISSIONS } from "../../../utils/permissions";
import { setSortingParams } from "../../../utils/sorting";
import { Substitution } from "../substitutions";
import { SUBSTITUTIONS_FILTERS } from "../substitutions.filters";
import { SubstitutionsField } from "../substitutions.form";
import { SUBSTITUTIONS_INDEX } from "../substitutions.gql";

export default function SubstitutionsIndex(): React.ReactElement {
  const { pageSize, currentPage, setTotalCount } = usePaging();
  const { search } = useLocation();

  const params = useMemo(() => new URLSearchParams(search), [search]);

  const [sortBy, setSortBy] = useState<SubstitutionsField | null>(
    (params.get("sortBy") as SubstitutionsField) || SubstitutionsField.CreatedAt
  );

  const [sortDirection, setSortDirection] = useState<SortDirection | null>(
    (params.get("sortDirection") as SortDirection) || SortDirection.ASC
  );

  const { enqueueSnackbar } = useSnackbar();
  const [css, theme] = useStyletron();
  const {
    isFetching,
    setIsFetching,
    isPartialFetching,
    setIsPartialFetching,
  } = useLoading();
  const history = useHistory();
  const [filters, setFilters] = useState<FiltersState>();

  const handleSorting = (column: SubstitutionsField) => {
    setSortingParams(history, search, column, sortDirection);
    setSortBy(column);
    setSortDirection(
      sortDirection === null
        ? SortDirection.DESC
        : sortDirection === SortDirection.ASC
        ? SortDirection.DESC
        : SortDirection.ASC
    );
  };

  const { refetch, data, loading, error } = useQuery(SUBSTITUTIONS_INDEX, {
    fetchPolicy: "cache-first",
    variables: {
      pageSize,
      offset: (currentPage - 1) * pageSize,
      sorting: {
        field: sortBy,
        direction: sortDirection,
      },
      ...(filters && { filter: translateFiltersState(filters) }),
    },
  });

  useEffect(() => {
    if (data?.substitutions) setTimeout(() => refetch(), 200);
    setIsFetching(true);
  }, []);

  useEffect(() => {
    setTimeout(() => refetch(), 200);
    setIsPartialFetching(true);
  }, [currentPage, pageSize]);

  useEffect(() => {
    setTimeout(() => refetch(), 200);
    setIsPartialFetching(true);
  }, [sortBy, sortDirection]);

  useEffect(() => {
    if (data?.substitutions) setIsFetching(false);
    if (data?.substitutions?.totalCount >= 0)
      setTotalCount(data?.substitutions?.totalCount);
  }, [data]);

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  if (!checkPermission(PERMISSIONS.substitution.read))
    return <Redirect to="/" />;

  const columns = React.useMemo(
    () => [
      {
        id: "name",
        Header: "Zastępstwo",
        Cell: ({ row }: { row: Row<Substitution> }) => (
          <StyledLink
            onClick={(event: MouseEvent) => {
              event.preventDefault();
              history.push(`/substitutions/${row.original.id}`);
            }}
            href={`/substitutions/${row.original.id}`}
          >
            <span className={css({ whiteSpace: "nowrap" })}>
              {row?.original?.substitutee?.firstName[0]}.{" "}
              {row?.original?.substitutee?.lastName}
            </span>{" "}
            ←{" "}
            <span className={css({ whiteSpace: "nowrap" })}>
              {row?.original?.substituter?.firstName[0]}.{" "}
              {row?.original?.substituter?.lastName}
            </span>
          </StyledLink>
        ),
      },
      {
        id: "status",
        Header: "Status",
        Cell: ({ row }: { row: Row<Substitution> }) => (
          <span>
            {new Date() > new Date(row.original.startedAt) &&
            (!row.original.endedAt ||
              (row.original.endedAt &&
                new Date() < new Date(row.original.endedAt))) ? (
              <Circle
                size={8}
                fill={theme.colors.positive}
                className={css({
                  verticalAlign: "middle",
                  marginRight: "10px",
                  marginTop: "-2px",
                  display: "inline",
                  color: theme.colors.positive,
                })}
              />
            ) : (
              <Circle
                size={8}
                fill={theme.colors.negative}
                className={css({
                  verticalAlign: "middle",
                  marginRight: "10px",
                  marginTop: "-2px",
                  display: "inline",
                  color: theme.colors.negative,
                })}
              />
            )}
            {new Date() > new Date(row.original.startedAt) &&
            (!row.original.endedAt ||
              (row.original.endedAt &&
                new Date() < new Date(row.original.endedAt)))
              ? "Aktywne"
              : "Nieaktywne"}
          </span>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(SubstitutionsField.StartedAt)}
            sortDirection={
              sortBy === SubstitutionsField.StartedAt ? sortDirection : null
            }
          >
            Data rozpoczęcia
          </SortingTableHeader>
        ),
        id: SubstitutionsField.StartedAt,
        Cell: ({ row }: { row: Row<Substitution> }) => (
          <FormattedValue dataType="date">
            {row.original.startedAt}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(SubstitutionsField.EndedAt)}
            sortDirection={
              sortBy === SubstitutionsField.EndedAt ? sortDirection : null
            }
          >
            Data zakończenia
          </SortingTableHeader>
        ),
        id: SubstitutionsField.EndedAt,
        Cell: ({ row }: { row: Row<Substitution> }) => (
          <FormattedValue dataType="date">
            {row.original.endedAt}
          </FormattedValue>
        ),
      },
      {
        Header: "Pracownik zastępowany",
        id: SubstitutionsField.Substitutee,
        Cell: ({ row }: { row: Row<Substitution> }) => (
          <FormattedValue
            dataType="model:users"
            data={row.original.substitutee.id}
          >{`${row.original.substitutee.firstName} ${row.original.substitutee.lastName}`}</FormattedValue>
        ),
      },
      {
        Header: "Pracownik zastępujący",
        id: SubstitutionsField.Substituter,
        Cell: ({ row }: { row: Row<Substitution> }) => (
          <FormattedValue
            dataType="model:users"
            data={row.original.substituter.id}
          >{`${row.original.substituter.firstName} ${row.original.substituter.lastName}`}</FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(SubstitutionsField.CreatedAt)}
            sortDirection={
              sortBy === SubstitutionsField.CreatedAt ? sortDirection : null
            }
          >
            Data utworzenia
          </SortingTableHeader>
        ),
        id: SubstitutionsField.CreatedAt,
        Cell: ({ row }: { row: Row<Substitution> }) => (
          <FormattedValue dataType="date">
            {row.original.createdAt}
          </FormattedValue>
        ),
      },
      {
        Header: "Utworzono przez",
        id: SubstitutionsField.CreatedBy,
        Cell: ({ row }: { row: Row<Substitution> }) => (
          <FormattedValue
            dataType="model:users"
            data={row.original.createdBy.id}
          >{`${row.original.createdBy.firstName} ${row.original.createdBy.lastName}`}</FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(SubstitutionsField.UpdatedAt)}
            sortDirection={
              sortBy === SubstitutionsField.UpdatedAt ? sortDirection : null
            }
          >
            Data aktualizacji
          </SortingTableHeader>
        ),
        id: SubstitutionsField.UpdatedAt,
        Cell: ({ row }: { row: Row<Substitution> }) => (
          <FormattedValue dataType="date">
            {row.original.updatedAt}
          </FormattedValue>
        ),
      },
      {
        Header: "Zaktualizowano przez",
        id: SubstitutionsField.updatedBy,
        Cell: ({ row }: { row: Row<Substitution> }) => (
          <FormattedValue
            dataType="model:users"
            data={row.original.updatedBy.id}
          >{`${row.original.updatedBy.firstName} ${row.original.updatedBy.lastName}`}</FormattedValue>
        ),
      },
      {
        id: "actions",
        Cell: ({ row }: { row: Row<Substitution> }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            {checkPermission(PERMISSIONS.substitution.update) && (
              <Button
                kind={KIND.secondary}
                size={SIZE.mini}
                onClick={() =>
                  history.push(`/substitutions/${row.original.id}/edit`)
                }
                startEnhancer={<Pencil size={14} />}
              />
            )}
            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              onClick={() => history.push(`/substitutions/${row.original.id}`)}
              $style={{ marginLeft: "6px" }}
              startEnhancer={<Eye size={14} />}
            />
          </div>
        ),
      },
    ],
    [data, sortBy, sortDirection, filters]
  );

  return (
    <article>
      <Header
        title="Zastępstwa"
        recordsNum={data?.substitutions?.totalCount}
        labels={["Lista"]}
        buttons={[
          {
            label: "Dodaj zastępstwo",
            kind: KIND.primary,
            startEnhancer: <Plus size={18} />,
            permission: checkPermission(PERMISSIONS.substitution.create),
            onClick: () => history.push("/substitutions/new"),
          },
        ]}
      />
      <Filters
        filters={SUBSTITUTIONS_FILTERS}
        state={filters}
        setState={setFilters}
      />
      <Content filtersOffset>
        <Grid>
          <Cell span={12} $style={{ position: "relative" }}>
            <Table<Substitution>
              columns={columns}
              data={data?.substitutions?.nodes}
              isLoading={isFetching || isPartialFetching || loading}
              stickLastColumn
            />
          </Cell>

          <Cell span={12}>
            <BottomPanel>
              <PagingControls />
            </BottomPanel>
          </Cell>
        </Grid>
      </Content>
    </article>
  );
}
