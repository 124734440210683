import { BasicFilter, FILTERS } from "./../../filters.d";

export const DOCUMENTS_FILTERS: BasicFilter[] = [
  {
    id: "caseId",
    label: "Sprawa",
    type: FILTERS.Case,
  },
  {
    id: "internalNumber",
    label: "Numer",
    type: FILTERS.Text,
  },
  {
    id: "documentNumber",
    label: "Numer dokumentu",
    type: FILTERS.Text,
  },
  {
    id: "addressNip",
    label: "NIP",
    type: FILTERS.Text,
  },
  {
    id: "addressName",
    label: "Nazwa nadawcy/odbiorcy",
    type: FILTERS.Text,
  },
  {
    id: "name",
    label: "Temat",
    type: FILTERS.Text,
  },
  {
    id: "description",
    label: "Opis",
    type: FILTERS.Text,
  },
  {
    id: "createdAt",
    label: "Data rejestracji",
    type: FILTERS.Date,
  },
  {
    id: "createdById",
    label: "Autor",
    type: FILTERS.User,
  },
  {
    id: "responsibleId",
    label: "Odpowiedzialny",
    type: FILTERS.User,
  },
  {
    id: "documentDate",
    label: "Data wystawienia dokumentu",
    type: FILTERS.Date,
  },
  {
    id: "inflowDate",
    label: "Data wpływu",
    type: FILTERS.Date,
  },
  {
    id: "postStampAt",
    label: "Data stempla pocztowego",
    type: FILTERS.Date,
  },
  {
    id: "handDelivered",
    label: "Dostarczony osobiście",
    type: FILTERS.Boolean,
  },
  {
    id: "documentType",
    label: "Typ dokumentu",
    type: FILTERS.DocumentTypes,
    dictionarySystemName: "documentTypes.incoming",
  },
  {
    id: "documentKind",
    label: "Rodzaj dokumentu",
    type: FILTERS.DocumentKinds,
  },
  {
    id: "state",
    label: "Stan dokumentu",
    type: FILTERS.DocumentStates,
  },
  {
    id: "organizationalUnit",
    label: "Jednostka organizacyjna",
    type: FILTERS.OrganizationalUnit,
  },
  {
    id: "createdById",
    label: "Osoba wystawiająca dokument",
    type: FILTERS.User,
  },
  {
    id: "hasCaseAssigned",
    label: "Przypisanie do sprawy",
    type: FILTERS.HasCaseAssigned,
  },
  {
    id: "valueInPLN",
    label: "Kwota brutto w PLN",
    type: FILTERS.DocumentValueInPLN,
  },
  {
    id: "valueInCurrency",
    label: "Kwota brutto w walucie",
    type: FILTERS.DocumentValueInCurrency,
  },
  {
    id: "sequenceNumber",
    label: "Numer w składzie chronologicznym",
    type: FILTERS.HasSequenceNumber,
  },
];
