import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { KIND } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Circle, Pencil, ToggleRight, Trash, X } from "tabler-icons-react";

import Cell from "../../../components/cell";
import ConfirmDialog from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import FormControl from "../../../components/form-control";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import { useAuth } from "../../../contexts/auth-context";
import { useLoading } from "../../../contexts/loading-context";
import { useSubstitution } from "../../../contexts/substitution-context";
import { FIELDS } from "../../../fields.d";
import { MY_SUBSTITUTIONS_FIELDS } from "../my-substitutions.form";
import {
  MY_SUBSTITUTIONS_DELETE,
  MY_SUBSTITUTIONS_SHOW,
} from "../my-substitutions.gql";

export default function MySubstitutionsShow(): React.ReactElement {
  const [css, theme] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const { setIsLoading, isFetching, setIsFetching } = useLoading();

  const { refetch, data, error } = useQuery(MY_SUBSTITUTIONS_SHOW, {
    variables: { id: id ? parseInt(id) : null },
    fetchPolicy: "cache-first",
  });

  const [deleteSubstitution] = useMutation(MY_SUBSTITUTIONS_DELETE);

  const submit = async () => {
    setIsLoading(true);

    try {
      await deleteSubstitution({
        variables: {
          mySubstitutionDeleteInput: {
            id: id ? parseInt(id) : null,
          },
        },
      });

      enqueueSnackbar({
        message: "Usunięto pomyślnie",
        variant: "success",
      });

      setIsConfirmDialogOpen(false);

      history.push("/my-substitutions");
    } catch (error: unknown) {
      setIsConfirmDialogOpen(false);

      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    if (data?.mySubstitution) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => data?.mySubstitution && setIsFetching(false), [data]);

  const {
    isSubstitutionActive,
    substitution,
    startSubstitution,
    endSubstitution,
  } = useSubstitution();

  const { user } = useAuth();

  return (
    <article>
      <Header
        title={
          <span
            className={css({
              display: "flex",
              alignItems: "center",
              gap: "5px",
            })}
          >
            <span className={css({ whiteSpace: "nowrap" })}>
              {data?.mySubstitution?.substitutee?.firstName[0]}.{" "}
              {data?.mySubstitution?.substitutee?.lastName}
            </span>{" "}
            ←{" "}
            <span className={css({ whiteSpace: "nowrap" })}>
              {data?.mySubstitution?.substituter?.firstName[0]}.{" "}
              {data?.mySubstitution?.substituter?.lastName}
            </span>
          </span>
        }
        labels={["Zastępstwa", "Wyświetlanie"]}
        goBackOption
        buttons={[
          {
            label: "Edytuj",
            kind: KIND.secondary,
            startEnhancer: <Pencil size={18} />,
            permission: data?.mySubstitution?.substituter?.id !== user?.id,
            onClick: () => history.push(`/my-substitutions/${id}/edit`),
          },
          {
            label: "Rozpocznij zastępstwo",
            isPositive: true,
            startEnhancer: <ToggleRight size={18} />,
            onClick: () => {
              startSubstitution(data?.mySubstitution?.substitutee?.id);
            },
            permission:
              data?.mySubstitution?.substituter?.id === user?.id &&
              !isSubstitutionActive &&
              (!data?.mySubstitution?.endedAt ||
                (data?.mySubstitution?.endedAt &&
                  new Date() < new Date(data?.mySubstitution?.endedAt))),
          },
          {
            label: "Zakończ zastępstwo",
            isNegative: true,
            startEnhancer: <X size={18} />,
            onClick: endSubstitution,
            permission:
              data?.mySubstitution?.substituter?.id === user?.id &&
              data?.mySubstitution?.id === substitution?.id &&
              isSubstitutionActive &&
              (!data?.mySubstitution?.endedAt ||
                (data?.mySubstitution?.endedAt &&
                  new Date() < new Date(data?.mySubstitution?.endedAt))),
          },
        ]}
        actions={[
          {
            label: "Usuń zastępstwo",
            icon: Trash,
            color: theme.colors.negative,

            onClick: () => setIsConfirmDialogOpen(true),
            permission: data?.mySubstitution?.substituter?.id !== user?.id,
          },
        ]}
      />
      <Content>
        <Grid>
          {MY_SUBSTITUTIONS_FIELDS.filter(
            (g) => g.fields.filter((f) => f.show.visible).length > 0
          ).map((group) => [
            group.label && (
              <Cell key={group.id + `-group`} span={12}>
                <LabelMedium marginBottom="scale200" marginTop="scale600">
                  {group.label}
                </LabelMedium>
                <hr
                  className={css({
                    borderWidth: "0px",
                    height: "1px",
                    backgroundColor: "#eee",
                  })}
                />
              </Cell>
            ),
            group.fields
              .filter((f) => f.show.visible)
              .map((item, index) => (
                <Cell span={item.span || 6} key={group.id + `-field` + index}>
                  <FormControl label={item.label} disabled={true}>
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : item.type === FIELDS.SubstitutionStatus ? (
                      <span>
                        {new Date() >
                          new Date(data?.mySubstitution.startedAt) &&
                        (!data?.mySubstitution.endedAt ||
                          (data?.mySubstitution.endedAt &&
                            new Date() <
                              new Date(data?.mySubstitution.endedAt))) ? (
                          <Circle
                            size={8}
                            fill={theme.colors.positive}
                            className={css({
                              verticalAlign: "middle",
                              marginRight: "10px",
                              marginTop: "-2px",
                              display: "inline",
                              color: theme.colors.positive,
                            })}
                          />
                        ) : (
                          <Circle
                            size={8}
                            fill={theme.colors.negative}
                            className={css({
                              verticalAlign: "middle",
                              marginRight: "10px",
                              marginTop: "-2px",
                              display: "inline",
                              color: theme.colors.negative,
                            })}
                          />
                        )}
                        {new Date() >
                          new Date(data?.mySubstitution.startedAt) &&
                        (!data?.mySubstitution.endedAt ||
                          (data?.mySubstitution.endedAt &&
                            new Date() <
                              new Date(data?.mySubstitution.endedAt)))
                          ? "Aktywne"
                          : "Nieaktywne"}
                      </span>
                    ) : (
                      <FormattedValue
                        dataType={item.dataType}
                        data={
                          item.dataType === "model:users"
                            ? data?.mySubstitution[item.id]?.id
                            : data?.mySubstitution[item.id]
                        }
                      >
                        {item.dataType === "model:users"
                          ? `${data?.mySubstitution[item.id]?.firstName} ${
                              data?.mySubstitution[item.id]?.lastName
                            }`
                          : item.show.accessor
                          ? data?.mySubstitution[item.show.accessor[0]]?.[
                              item.show.accessor[1]
                            ]
                          : data?.mySubstitution[item.id]}
                      </FormattedValue>
                    )}
                  </FormControl>
                </Cell>
              )),
          ])}

          <ConfirmDialog
            isOpen={isConfirmDialogOpen}
            label={`Usunięcie zastępstwa ${data?.mySubstitution?.substitutee?.firstName[0]}. ${data?.mySubstitution?.substitutee?.lastName} ← ${data?.mySubstitution?.substituter?.firstName[0]}. ${data?.mySubstitution?.substituter?.lastName}`}
            close={() => setIsConfirmDialogOpen(false)}
            confirm={submit}
          />
        </Grid>
      </Content>
    </article>
  );
}
