/* eslint-disable @typescript-eslint/no-explicit-any */
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { SIZE } from "baseui/input";
import { LabelSmall, MonoLabelXSmall } from "baseui/typography";
import React, { useEffect, useMemo, useState } from "react";
import { Controller, UseControllerProps } from "react-hook-form";
import { Row } from "react-table";
import { CirclePlus } from "tabler-icons-react";

import { RequestFormItem } from "../containers/Documents/documents";
import { generateUUID, localeParseFloat } from "../utils/misc";
import Button, { ForwardedButton } from "./button";
import Checkbox from "./checkbox";
import FormattedValue from "./formatted-value";
import { NumericInput } from "./input";
import Popover from "./popover";
import {
  BudgetCategoriesSelect,
  BudgetNamesSelect,
  BudgetSymbolsSelect,
  BudgetYearsSelect,
} from "./select";
import Table from "./table";
import Tooltip from "./tooltip";

type RequestFormTableProps = {
  organizationalUnitId?: number;
  value?: RequestFormTableItem[];
  onChange?: (items: RequestFormItem[]) => void;
  error?: boolean;
  disabled?: boolean;
  id?: string;
  hasFocus?: boolean;
  formType: "create" | "edit";
};

export type RequestFormTableItem = {
  id: string;
  edit?: boolean;
  orderValue?: number;
  requestedExpenditure?: number;
  realExpenditure?: number;
  originalId?: number;
  year?: string;
  isProjectBudget?: boolean;
  isActive?: boolean;
  financialPlanId?: number;
  financialPlanName?: string;
  budgetSymbol?: any;
  categoryPath?: any;
  categoryName?: any;
  budgetName?: any;
  amount?: any;
};

export default function RequestFormTable({
  value,
  onChange,
  error,
  disabled,
  id,
  hasFocus = false,
  formType,
}: RequestFormTableProps): React.ReactElement {
  const [items, setItems] = useState<Array<RequestFormTableItem>>([
    { id: "autoGeneratedFirstRow", edit: true },
  ]);

  const [changes, setChanges] = useState(0);
  const [editingRow, setEditingRow] = useState("autoGeneratedFirstRow");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [focusedRow, setFocusedRow] = useState("autoGeneratedFirstRow");

  const [css, theme] = useStyletron();

  useEffect(
    () =>
      onChange &&
      onChange(
        items
          .filter(
            (item) =>
              item.year &&
              (item.isProjectBudget ||
                !item.isActive ||
                (!item.isProjectBudget && item.isActive && item.orderValue))
          )
          ?.map((item: any) => ({
            requestedExpenditure: item.requestedExpenditure,
            realExpenditure: item.realExpenditure,
            orderValue: item.orderValue,
            isProjectBudget: item.isProjectBudget,
            year: item.year,
            budgetSymbol: item.budgetSymbol,
            categoryPath: item.categoryPath,
            categoryName: item.categoryName,
            budgetName: item.budgetName,
            amount: item.amount,
            ...(item.originalId && { originalId: item.originalId }),
          }))
      ),
    [changes]
  );

  useEffect(() => {
    function updateState() {
      (window.document.activeElement as HTMLElement).blur();

      setTimeout(() => setChanges((changes) => changes + 1), 200);
    }

    const header = window.document.getElementsByTagName("header")?.[0];

    if (header) {
      header.addEventListener("mouseenter", updateState);
    }

    return () => {
      if (header) {
        header.removeEventListener("mouseenter", updateState);
      }
    };
  }, []);

  useEffect(() => {
    if (!value) setItems([{ id: "autoGeneratedFirstRow", edit: true }]);

    if (changes === 0 && !!value?.length) {
      setItems(value);
      setChanges((changes) => changes + 1);
    }
  }, [value]);

  const columns = useMemo(
    () => [
      {
        Header: "Rok budżetowy",
        Cell: ({ row }: { row: Row<RequestFormTableItem> }) => {
          if (row?.original?.originalId)
            return <FormattedValue>{row?.original?.year}</FormattedValue>;
          else
            return (
              <BudgetYearsSelect
                size={SIZE.compact}
                value={
                  row?.original?.year
                    ? [
                        {
                          id: row?.original?.financialPlanId,
                          label: row?.original?.year,
                          isActive: row?.original?.isActive,
                        },
                      ]
                    : []
                }
                onChange={(params) => {
                  setItems((items) => {
                    const editedItem =
                      items[
                        items.findIndex((item) => item.id === row.original.id)
                      ];

                    delete editedItem.isProjectBudget;
                    delete editedItem.budgetSymbol;
                    delete editedItem.categoryPath;
                    delete editedItem.categoryName;
                    delete editedItem.amount;
                    delete editedItem.requestedExpenditure;
                    delete editedItem.realExpenditure;
                    delete editedItem.orderValue;

                    editedItem.year = params?.value?.[0]?.label as string;

                    editedItem.financialPlanId = params?.value?.[0]
                      ?.id as number;

                    editedItem.financialPlanName = params?.value?.[0]
                      ?.name as string;

                    editedItem.isActive = params?.value?.[0]
                      ?.isActive as boolean;

                    return items;
                  });

                  setEditingRow(row.original.id);
                  setChanges((changes) => changes + 1);
                }}
                onFocus={() => setFocusedRow(row.original.id)}
                disabled={disabled}
              />
            );
        },
      },
      {
        Header: "Budżet projektowy",
        Cell: ({ row }: { row: Row<RequestFormTableItem> }) => {
          if (row?.original?.originalId)
            return (
              <Block
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <FormattedValue dataType="boolean">
                  {!!row?.original?.isProjectBudget}
                </FormattedValue>
              </Block>
            );
          else
            return (
              <Block display={"flex"} justifyContent={"center"}>
                <Checkbox
                  checked={row?.original?.isProjectBudget}
                  onChange={(event) => {
                    setItems((items) => {
                      const editedItem =
                        items[
                          items.findIndex((item) => item.id === row.original.id)
                        ];

                      delete editedItem.categoryPath;

                      editedItem.isProjectBudget = (event.target as any)?.checked;

                      return items;
                    });

                    setEditingRow(row.original.id);
                    setChanges((changes) => changes + 1);
                  }}
                  onFocus={() => setFocusedRow(row.original.id)}
                  disabled={disabled}
                />
              </Block>
            );
        },
      },
      {
        Header: "Symbol budżetowy",
        Cell: ({ row }: { row: Row<RequestFormTableItem> }) => {
          if (row?.original?.originalId)
            return (
              <FormattedValue>{row?.original?.budgetSymbol}</FormattedValue>
            );
          return (
            <BudgetSymbolsSelect
              financialPlanId={row?.original?.financialPlanId}
              disabled={disabled}
              size={SIZE.compact}
              value={
                row?.original?.budgetSymbol
                  ? [
                      {
                        id: row?.original?.budgetSymbol,
                        label: row?.original?.budgetSymbol,
                      },
                    ]
                  : []
              }
              onChange={(params) => {
                setItems((items) => {
                  const editedItem =
                    items[
                      items.findIndex((item) => item.id === row.original.id)
                    ];

                  editedItem.budgetSymbol = params?.value?.[0]?.id;

                  if (
                    !row?.original?.isProjectBudget &&
                    row?.original?.isActive
                  ) {
                    delete editedItem.categoryPath;
                    delete editedItem.budgetName;
                    delete editedItem.categoryName;
                    delete editedItem.amount;
                    delete editedItem.requestedExpenditure;
                    delete editedItem.realExpenditure;
                    delete editedItem.orderValue;
                  }

                  return items;
                });

                setEditingRow(row.original.id);
                setChanges((changes) => changes + 1);
              }}
              onFocus={() => setFocusedRow(row.original.id)}
            />
          );
        },
      },
      {
        Header: "Kategoria finansowania",
        Cell: ({ row }: { row: Row<RequestFormTableItem> }) => {
          if (row?.original?.originalId && row?.original?.categoryPath)
            return (
              <Block
                display="flex"
                justifyContent="flex-start"
                flexDirection="column"
              >
                <MonoLabelXSmall>{row?.original?.categoryPath}</MonoLabelXSmall>
                <LabelSmall>{row?.original?.categoryName}</LabelSmall>
              </Block>
            );

          if (row?.original?.originalId && !row?.original?.categoryPath)
            return <FormattedValue />;

          return (
            <BudgetCategoriesSelect
              financialPlanId={row?.original?.financialPlanId}
              budgetSymbol={row?.original?.budgetSymbol}
              isProjectBudget={row?.original?.isProjectBudget}
              disabled={
                disabled ||
                (!row?.original?.budgetSymbol &&
                  !row?.original?.isProjectBudget &&
                  row?.original?.isActive)
              }
              size={SIZE.compact}
              value={
                row?.original?.categoryPath
                  ? [
                      {
                        id: row?.original?.categoryPath,
                        categoryPath: row?.original?.categoryPath,
                        categoryName: row?.original?.categoryName,
                      },
                    ]
                  : []
              }
              onChange={(params) => {
                setItems((items) => {
                  const editedItem =
                    items[
                      items.findIndex((item) => item.id === row.original.id)
                    ];

                  editedItem.categoryPath = params?.value?.[0]?.categoryPath;
                  editedItem.categoryName = params?.value?.[0]?.categoryName;

                  if (
                    !row?.original?.isProjectBudget &&
                    row?.original?.isActive
                  ) {
                    delete editedItem.amount;
                    delete editedItem.budgetName;
                    delete editedItem.requestedExpenditure;
                    delete editedItem.realExpenditure;
                    delete editedItem.orderValue;
                  }

                  if (
                    row?.original?.isProjectBudget ||
                    row?.original?.isActive
                  ) {
                    if (!params?.value?.[0]?.id) {
                      delete editedItem.categoryName;
                    }

                    delete editedItem.amount;
                  }

                  return items;
                });

                setEditingRow(row.original.id);
                setChanges((changes) => changes + 1);
              }}
              onFocus={() => setFocusedRow(row.original.id)}
            />
          );
        },
      },
      {
        Header: "Źródło finansowania",
        Cell: ({ row }: { row: Row<RequestFormTableItem> }) => {
          if (row?.original?.originalId)
            return <FormattedValue>{row?.original?.budgetName}</FormattedValue>;
          return (
            <BudgetNamesSelect
              financialPlanId={row?.original?.financialPlanId}
              budgetSymbol={row?.original?.budgetSymbol}
              categoryPath={row?.original?.categoryPath}
              disabled={
                disabled ||
                (!row?.original?.categoryPath &&
                  !row?.original?.isProjectBudget &&
                  row?.original?.isActive)
              }
              size={SIZE.compact}
              autoFocus={
                hasFocus &&
                row.original.id === editingRow &&
                !row.original.categoryPath &&
                !row?.original?.isProjectBudget &&
                row?.original?.isActive
              }
              value={
                row?.original?.budgetName
                  ? [
                      {
                        id: row?.original?.budgetName,
                        label: row?.original?.budgetName,
                        amount: row?.original?.amount,
                      },
                    ]
                  : []
              }
              onChange={(params) => {
                setItems((items) => {
                  const editedItem =
                    items[
                      items.findIndex((item) => item.id === row.original.id)
                    ];

                  items[
                    items.findIndex((item) => item.id === row.original.id)
                  ].budgetName = params?.value?.[0]?.id;

                  items[
                    items.findIndex((item) => item.id === row.original.id)
                  ].amount = params?.value?.[0]?.amount;

                  if (
                    !params?.value?.[0]?.id &&
                    !row?.original?.isProjectBudget &&
                    row?.original?.isActive
                  ) {
                    delete editedItem.amount;
                    delete editedItem.requestedExpenditure;
                    delete editedItem.realExpenditure;
                    delete editedItem.orderValue;
                  }

                  if (
                    !params?.value?.[0]?.id &&
                    (row?.original?.isProjectBudget || row?.original?.isActive)
                  ) {
                    delete editedItem.amount;
                  }

                  return items;
                });

                setChanges((changes) => changes + 1);
              }}
              onFocus={() => setFocusedRow(row.original.id)}
            />
          );
        },
      },
      {
        Header: "Wartość",
        Cell: ({ row }: { row: Row<RequestFormTableItem> }) => {
          return (
            <Block
              display="flex"
              justifyContent="flex-start"
              overrides={{
                Block: { style: { whiteSpace: "nowrap", minWidth: "200px" } },
              }}
            >
              {row?.original?.categoryName ? (
                <FormattedValue dataType="quota">
                  {row?.original?.amount}
                </FormattedValue>
              ) : (
                <FormattedValue />
              )}
            </Block>
          );
        },
      },
      {
        accessor: "requestedExpenditure",
        Header: () => (
          <Tooltip
            content="Wnioskowane wykorzystanie środków"
            placement="bottom"
          >
            <u
              className={css({
                textDecorationStyle: "dotted",
                textUnderlineOffset: "2px",
              })}
            >
              Wnioskowane wyk. środ.
            </u>
          </Tooltip>
        ),
        Cell: ({ row }: { row: Row<RequestFormTableItem> }) => {
          return (
            <NumericInput
              size={SIZE.compact}
              value={row?.original?.requestedExpenditure}
              endEnhancer="PLN"
              required
              type="number"
              step={0.01}
              min={0}
              disabled={
                disabled ||
                (!row?.original?.categoryName &&
                  !row?.original?.isProjectBudget &&
                  row?.original?.isActive)
              }
              onValueChange={(value: string) => {
                setItems((items) => {
                  items[
                    items.findIndex((item) => item.id === row.original.id)
                  ].requestedExpenditure = localeParseFloat(value) ?? undefined;

                  return items;
                });
              }}
              $style={{ minWidth: "150px" }}
            />
          );
        },
      },
      {
        accessor: "realExpenditure",
        Header: () => (
          <Tooltip content="Faktyczne wykorzystanie środków" placement="bottom">
            <u
              className={css({
                textDecorationStyle: "dotted",
                textUnderlineOffset: "2px",
              })}
            >
              Faktyczne wyk. środ.
            </u>
          </Tooltip>
        ),
        Cell: ({ row }: { row: Row<RequestFormTableItem> }) => {
          return (
            <NumericInput
              size={SIZE.compact}
              value={row?.original?.realExpenditure}
              endEnhancer="PLN"
              required
              type="number"
              step={0.01}
              min={0}
              disabled={
                disabled ||
                (!row?.original?.categoryName &&
                  !row?.original?.isProjectBudget &&
                  row?.original?.isActive)
              }
              onValueChange={(value: string) => {
                setItems((items) => {
                  items[
                    items.findIndex((item) => item.id === row.original.id)
                  ].realExpenditure = localeParseFloat(value) ?? undefined;

                  return items;
                });
              }}
              $style={{ minWidth: "150px" }}
            />
          );
        },
      },
      {
        accessor: "orderValue",
        Header: "Kwota",
        Cell: ({ row }: { row: Row<RequestFormTableItem> }) => {
          return (
            <NumericInput
              size={SIZE.compact}
              value={row?.original?.orderValue}
              endEnhancer="PLN"
              required
              type="number"
              step={0.01}
              min={0}
              disabled={
                disabled ||
                (!row?.original?.categoryName &&
                  !row?.original?.isProjectBudget &&
                  row?.original?.isActive)
              }
              onValueChange={(value: string) => {
                setItems((items) => {
                  items[
                    items.findIndex((item) => item.id === row.original.id)
                  ].orderValue = localeParseFloat(value) ?? undefined;

                  return items;
                });
              }}
              $style={{ minWidth: "100px" }}
            />
          );
        },
      },
      {
        id: "actions",
        Cell: ({ row }: { row: Row<RequestFormTableItem> }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            {row.original.budgetSymbol ? (
              <Popover
                content={({ close }) => (
                  <Block
                    padding="scale200"
                    backgroundColor="white"
                    display="flex"
                    alignItems="center"
                  >
                    <LabelSmall marginRight="8px">
                      Czy na pewno chcesz usunąć tę pozycję?
                    </LabelSmall>

                    <Button
                      kind="secondary"
                      size={SIZE.mini}
                      onClick={() => {
                        setItems((items) =>
                          items.filter((item) => item.id !== row.original.id)
                        );
                        setChanges((changes) => changes + 1);
                        close();
                      }}
                      $style={{
                        backgroundColor: theme.colors.negative400,
                        color: "white",
                        ":hover": {
                          backgroundColor: theme.colors.negative500,
                        },
                      }}
                      disabled={disabled}
                    >
                      Tak, usuń.
                    </Button>
                  </Block>
                )}
                showArrow
              >
                <ForwardedButton
                  type="reset"
                  kind="secondary"
                  size={SIZE.mini}
                  disabled={disabled}
                >
                  Usuń
                </ForwardedButton>
              </Popover>
            ) : (
              <Button
                disabled={disabled}
                kind="secondary"
                size={SIZE.mini}
                onClick={() => {
                  setItems((items) =>
                    items.filter((item) => item.id !== row.original.id)
                  );
                  setChanges((changes) => changes + 1);
                }}
              >
                Usuń
              </Button>
            )}
          </div>
        ),
      },
    ],
    [changes, editingRow, items, hasFocus]
  );

  return (
    <>
      {formType === "edit" && (
        <LabelSmall
          $style={{
            marginTop: "10px",
            marginBottom: "10px",
            color: theme.colors.negative,
          }}
        >
          Uwaga! Należy usuwać tylko pozycje nie wskazane na żadnej z faktur.
        </LabelSmall>
      )}

      <Table<RequestFormTableItem>
        id={id}
        compact
        columns={columns}
        data={items}
        expandedNumber={0}
        scrollable
        editable
        {...(error && {
          $style: {
            backgroundColor: theme.colors.inputFillError,
            borderColor: theme.colors.inputBorderError,
            borderWidth: "2px",
          },
        })}
        $footer={() => {
          return (
            <>
              <td
                className={css({
                  paddingTop: theme.sizing.scale100,
                  paddingBottom: theme.sizing.scale100,
                  paddingLeft: theme.sizing.scale500,
                  paddingRight: theme.sizing.scale500,
                  position: "sticky",
                  left: 0,
                })}
                colSpan={2}
              >
                <Block
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Button
                    kind="tertiary"
                    size="compact"
                    type="button"
                    key="button"
                    onClick={() => {
                      const newId = generateUUID();

                      setItems([...items, { id: newId, edit: true }]);
                      setEditingRow(newId);
                    }}
                    startEnhancer={<CirclePlus size={16} />}
                    disabled={disabled}
                  >
                    Dodaj pozycję
                  </Button>
                </Block>
              </td>

              <td colSpan={4} />

              <td
                className={css({
                  paddingTop: theme.sizing.scale100,
                  paddingBottom: theme.sizing.scale100,
                  paddingLeft: theme.sizing.scale500,
                  paddingRight: theme.sizing.scale500,
                  position: "sticky",
                  right: 0,
                })}
                colSpan={3}
              >
                <Block
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <LabelSmall>
                    Suma:{" "}
                    <strong>
                      <FormattedValue dataType="quota">
                        {items.reduce(
                          (a, b) => a + (b.orderValue ? b.orderValue : 0),
                          0
                        )}
                      </FormattedValue>
                    </strong>
                  </LabelSmall>
                </Block>
              </td>

              <td />
            </>
          );
        }}
      />
    </>
  );
}

export function ControlledRequestFormTable({
  control,
  name,
  id,
  disabled,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & RequestFormTableProps): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={{
        validate: (value) => {
          return !value?.filter(
            (item: RequestFormTableItem) =>
              item.year &&
              (item?.isProjectBudget ||
                !item.isActive ||
                (!item?.isProjectBudget && item.isActive && item?.orderValue))
          ).length
            ? "Wniosek musi zawierać przynajmniej jedną pozycję."
            : true;
        },
      }}
      render={({ field: { onChange, value } }) => (
        <RequestFormTable
          id={id}
          onChange={onChange}
          value={value}
          disabled={disabled}
          {...rest}
        />
      )}
    />
  );
}
