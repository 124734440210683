import { useStyletron } from "baseui";
import { DisplayXSmall, LabelMedium, ParagraphLarge } from "baseui/typography";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import {
  AlertTriangle,
  Briefcase,
  Browser,
  FileImport,
} from "tabler-icons-react";

import ActionsList from "../../components/actions-list";
import Cell from "../../components/cell";
import Content from "../../components/content";
import Grid from "../../components/grid";
import { useLoading } from "../../contexts/loading-context";

export default function Error404(): React.ReactElement {
  const [css] = useStyletron();
  const { setIsFetching } = useLoading();
  const history = useHistory();

  useEffect(() => {
    setIsFetching(false);
  }, []);

  return (
    <article>
      <Content>
        <Grid>
          <Cell span={12}>
            <AlertTriangle size={90} />
            <DisplayXSmall marginBottom="scale600">
              Nie znaleziono
            </DisplayXSmall>
            <ParagraphLarge marginBottom="scale600" marginTop="scale600">
              Pod wskazanym adresem nie znaleziono strony. Upewnij się, że adres
              jest poprawny.
            </ParagraphLarge>
          </Cell>
          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale1000">
              Skróty
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={12}>
            <ActionsList
              actions={[
                {
                  label: "Zarejestruj nowy dokument",
                  icon: FileImport,
                  onClick: () => history.push("/documents/create-incoming"),
                },
                {
                  label: "Przeglądaj moje dokumenty",
                  icon: Briefcase,
                  onClick: () => history.push("/documents"),
                },
                {
                  label: "Złóż wniosek o wydatkowanie środków publicznych",
                  icon: Browser,
                  onClick: () => history.push("/documents/create-request-form"),
                },
              ]}
            />
          </Cell>
        </Grid>
      </Content>
    </article>
  );
}
