import { Column } from "react-table";

export function sortColumnsByOrder(
  a: Column,
  b: Column,
  orderedColumns: string[]
): 1 | -1 | 0 {
  if (a.id === "actions" || b.id === "checkbox") {
    return 1;
  }

  if (a.id === "checkbox" || b.id === "actions") {
    return -1;
  }

  const aIndex = orderedColumns.findIndex((id) => id === a.id);
  const bIndex = orderedColumns.findIndex((id) => id === b.id);

  if (aIndex > bIndex) {
    return 1;
  } else if (aIndex < bIndex) {
    return -1;
  } else {
    return 0;
  }
}

export function declinateSelectedRecordsNumber(number: number): string {
  if (number == 0) {
    return `Nie zaznaczono żadnych rekordów`;
  }

  if (number == 1) {
    return `Zaznaczono ${number} rekord`;
  }

  if (
    number % 10 > 1 &&
    number % 10 < 5 &&
    !(number % 100 >= 10 && number % 100 <= 21)
  ) {
    return `Zaznaczono ${number} rekordy`;
  }

  return `Zaznaczono ${number} rekordów`;
}
