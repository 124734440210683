import { BasicFilter, FILTERS } from "./../../filters.d";

export const DICTIONARIES_FILTERS: BasicFilter[] = [
  {
    id: "name",
    label: "Nazwa",
    type: FILTERS.Text,
  },
  {
    id: "systemName",
    label: "Nazwa systemowa",
    type: FILTERS.Text,
  },
];
