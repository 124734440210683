import { FIELDS, FieldsGroup } from "../../fields.d";

export const ACTIVITY_LOGS_FIELDS: FieldsGroup[] = [
  {
    id: "basic",
    label: "Podstawowe informacje",
    fields: [
      {
        id: "user",
        label: "Użytkownik",
        type: FIELDS.Input,
        dataType: "model:users",
        span: 4,
        show: { accessor: ["user", "id"], visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "userOrganizationalUnits",
        label: "Jednostka organizacyjna",
        type: FIELDS.Input,
        dataType: "json:organizational-units",
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "createdAt",
        label: "Data",
        type: FIELDS.Input,
        dataType: "datetime",
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
    ],
  },
  {
    id: "details",
    label: "Szczegóły akcji",
    fields: [
      {
        id: "description",
        label: "Akcja",
        type: FIELDS.Input,
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "subject",
        label: "Przedmiot aktywności",
        type: FIELDS.Input,
        span: 4,
        show: { accessor: ["subject", "__typename"], visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "object",
        label: "Obiekt",
        typeName: "User",
        type: FIELDS.Input,
        dataType: "model:users",
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "object",
        label: "Obiekt",
        typeName: "Dictionary",
        type: FIELDS.Input,
        dataType: "model:dictionaries",
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "object",
        label: "Obiekt",
        typeName: "DictionaryValue",
        type: FIELDS.Input,
        dataType: "model:dictionaries",
        span: 4,
        show: { accessor: ["subject", "dictionaryId"], visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "object",
        label: "Obiekt",
        typeName: "JrwaClassification",
        type: FIELDS.Input,
        dataType: "model:jrwa-classifications",
        span: 4,
        show: {
          accessor: ["subject", "name"],
          visible: true,
        },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "object",
        label: "Obiekt",
        typeName: "OrganizationalUnit",
        type: FIELDS.Input,
        dataType: "model:organizational-units",
        span: 4,
        show: {
          accessor: ["subject", "name"],
          visible: true,
        },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "object",
        label: "Obiekt",
        typeName: "Position",
        type: FIELDS.Input,
        dataType: "model:positions",
        span: 4,
        show: { accessor: ["subject", "name"], visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "object",
        label: "Obiekt",
        typeName: "Role",
        type: FIELDS.Input,
        dataType: "model:roles",
        span: 4,
        show: { accessor: ["subject", "name"], visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "object",
        label: "Obiekt",
        typeName: "Case",
        type: FIELDS.Input,
        dataType: "model:cases",
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "object",
        label: "Obiekt",
        typeName: "Address",
        type: FIELDS.Input,
        dataType: "model:addresses",
        span: 4,
        show: { accessor: ["subject", "name"], visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "object",
        label: "Obiekt",
        typeName: "Document",
        type: FIELDS.Input,
        dataType: "model:documents",
        span: 4,
        show: { accessor: ["subject", "internalNumber"], visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "object",
        label: "Obiekt",
        typeName: "ContractAgreement",
        type: FIELDS.Input,
        dataType: "model:documents",
        span: 4,
        show: { accessor: ["subject", "internalNumber"], visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "object",
        label: "Obiekt",
        typeName: "RequestForm",
        type: FIELDS.Input,
        dataType: "model:documents",
        span: 4,
        show: { accessor: ["subject", "internalNumber"], visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "object",
        label: "Obiekt",
        typeName: "BudgetItem",
        type: FIELDS.Input,
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "object",
        label: "Obiekt",
        typeName: "RequestFormItem",
        dataType: "model:documents",
        type: FIELDS.Input,
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "object",
        label: "Obiekt",
        typeName: "Invoice",
        type: FIELDS.Input,
        dataType: "model:documents",
        span: 4,
        show: { accessor: ["subject", "internalNumber"], visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "object",
        label: "Obiekt",
        typeName: "RequestFormNote",
        type: FIELDS.Input,
        dataType: "model:documents",
        span: 4,
        show: { accessor: ["subject", "internalNumber"], visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "object",
        label: "Obiekt",
        typeName: "BudgetCategory",
        type: FIELDS.Input,
        span: 4,
        show: { accessor: ["subject", "name"], visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "object",
        label: "Obiekt",
        typeName: "BankAccount",
        type: FIELDS.Input,
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "object",
        label: "Obiekt",
        typeName: "DocumentFlowStep",
        dataType: "model:flow",
        type: FIELDS.Input,
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "object",
        label: "Obiekt",
        typeName: "DocumentFlow",
        dataType: "model:flow",
        type: FIELDS.Input,
        span: 4,
        show: { visible: true, accessor: ["subject", "name"] },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "object",
        label: "Obiekt",
        typeName: "File",
        dataType: "model:documents",
        type: FIELDS.Input,
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "object",
        label: "Obiekt",
        typeName: "ShipmentRate",
        dataType: "model:shipment-contracts",
        type: FIELDS.Input,
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "object",
        label: "Obiekt",
        typeName: "ShipmentContract",
        dataType: "model:shipment-contracts",
        type: FIELDS.Input,
        span: 4,
        show: { visible: true, accessor: ["subject", "name"] },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "object",
        label: "Obiekt",
        typeName: "DocumentParcel",
        dataType: "model:document-parcels",
        type: FIELDS.Input,
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "object",
        label: "Obiekt",
        typeName: "Currency",
        dataType: "model:currencies",
        type: FIELDS.Input,
        span: 4,
        show: { visible: true, accessor: ["subject", "name"] },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "object",
        label: "Obiekt",
        typeName: "FinancialAccountingDocumentItem",
        dataType: "model:documents",
        type: FIELDS.Input,
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "object",
        label: "Obiekt",
        typeName: "DocumentFlowAction",
        dataType: "model:flow",
        type: FIELDS.Input,
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "object",
        label: "Obiekt",
        typeName: "Payment",
        dataType: "model:payments",
        type: FIELDS.Input,
        span: 4,
        show: { visible: true, accessor: ["subject", "internalNumber"] },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "object",
        label: "Obiekt",
        typeName: "InternalAccount",
        dataType: "model:internal-accounts",
        type: FIELDS.Input,
        span: 4,
        show: { visible: true, accessor: ["subject", "name"] },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "object",
        label: "Obiekt",
        typeName: "DivisorTemplate",
        dataType: "model:divisors",
        type: FIELDS.Input,
        span: 4,
        show: { visible: true, accessor: ["subject", "name"] },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "object",
        label: "Obiekt",
        typeName: "DivisorItem",
        dataType: "model:divisors",
        type: FIELDS.Input,
        span: 4,
        show: { visible: true, accessor: ["subject", "name"] },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "object",
        label: "Obiekt",
        typeName: "PaymentItem",
        dataType: "model:payments",
        type: FIELDS.Input,
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "object",
        label: "Obiekt",
        typeName: "ContractPreparationRequest",
        type: FIELDS.Input,
        dataType: "model:documents",
        span: 4,
        show: { accessor: ["subject", "internalNumber"], visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "object",
        label: "Obiekt",
        typeName: "BillIssuanceRequest",
        type: FIELDS.Input,
        dataType: "model:documents",
        span: 4,
        show: { accessor: ["subject", "internalNumber"], visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "object",
        label: "Obiekt",
        typeName: "DocumentPickup",
        type: FIELDS.Input,
        dataType: "model:document-pickups",
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "object",
        label: "Obiekt",
        typeName: "FinancialPlan",
        type: FIELDS.Input,
        dataType: "model:financial-plans",
        span: 4,
        show: { accessor: ["subject", "name"], visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "assignmentUsers",
        label: "Przekazanie/udostępnienie do",
        type: FIELDS.Input,
        dataType: "json:users",
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
    ],
  },
  {
    id: "properties",
    label: "Właściwości",
    accessor: "properties",
    fields: [
      {
        id: "ipAddress",
        label: "Adres IP",
        type: FIELDS.Input,
        dataType: "pre",
        span: 12,
        show: { accessor: ["properties", "ipAddress"], visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "operatingSystem",
        label: "System operacyjny",
        type: FIELDS.Input,
        span: 4,
        show: { accessor: ["properties", "operatingSystem"], visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "browserName",
        label: "Przeglądarka",
        type: FIELDS.Input,
        span: 4,
        show: { accessor: ["properties", "browserName"], visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "browserVersion",
        label: "Wersja przeglądarki",
        type: FIELDS.Input,
        span: 4,
        show: { accessor: ["properties", "browserVersion"], visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
    ],
  },
];
