import { JrwaClassification } from "../JrwaClassifications/jrwa-classifications";
import { OrganizationalUnit } from "../OrganizationalUnits/organizational-units";
import { User } from "../Users/users";

export enum CaseSharingAccessLevel {
  Read = "Read",
  Update = "Update",
}

export enum CaseSharingScope {
  Full = "Full",
  Partial = "Partial",
}

export type Case = {
  id: number;
  name: string;
  number: string;
  folderNumber: string;
  isConductedElectronically: boolean;
  jrwaClassificationName: string;
  jrwaClassification: JrwaClassification;
  user: User;
  supervisor: User;
  initiatedAt: string;
  closedAt: string;
  organizationalUnit: OrganizationalUnit;
  organizationalUnitGroupNumber: number;
  organizationalUnitGroupName: string;
  projectNumber: string;
  isAccessable: boolean;
};

export type CaseSharing = {
  id: number;
  scope: CaseSharingScope;
  accessLevel: CaseSharingAccessLevel;
  comment?: string;
  createdAt: string;
  user?: User;
  userId?: number;
  case?: Case;
  caseId?: number;
  createdBy?: User;
  createdById?: number;
  documents?: { documentId: number; accessLevel: CaseSharingAccessLevel }[];
};
