import { flexRender, Header } from "@tanstack/react-table";
import { useDrag } from "@use-gesture/react";
import { useStyletron } from "baseui";
import debounce from "lodash.debounce";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { useAuth } from "../contexts/auth-context";
import {
  getColumnDataAlign,
  getColumnWidth,
} from "../utils/interface/column-sizes";
import {
  getPreferencedColumnWidth,
  setPreferencedColumnWidth,
} from "../utils/interface/preferences";

type TableColumnProps<T, K> = {
  column: Header<T, K>;
  editable?: boolean;
  tableRef?: React.RefObject<HTMLDivElement>;
};

export default function TableColumnV8<T, K>({
  column,
  editable,
  tableRef,
}: TableColumnProps<T, K>): React.ReactElement {
  const [css] = useStyletron();

  const { interfacePreferences, updateUser } = useAuth();
  const { pathname } = useLocation();

  const preferencedColumnWidth = getPreferencedColumnWidth(
    interfacePreferences,
    pathname,
    column.id
  );

  const systemColumnWidth = getColumnWidth(column.id);

  const [columnWidth, setColumnWidth] = useState<string>(
    column.id === "actions"
      ? "90px"
      : preferencedColumnWidth || systemColumnWidth || "200px"
  );

  const [initialColumnWidth, setInitialColumnWidth] = useState(columnWidth);
  const [finalColumnWidth, setFinalColumnWidth] = useState<string>();

  const parsedColumnWidth = parseInt(columnWidth.slice(0, -2));
  const parsedInitialColumnWidth = parseInt(initialColumnWidth.slice(0, -2));

  const debouncedSetFinalColumnWidth = useCallback(
    debounce(setFinalColumnWidth, 400),
    []
  );

  const bind = useDrag(({ movement: [mx], event }) => {
    event.stopPropagation();
    event.preventDefault();

    if (mx === 0) setInitialColumnWidth(columnWidth);
    else if (parsedInitialColumnWidth + mx > 15)
      setColumnWidth(`${parsedInitialColumnWidth + mx}px`);
  });

  function calculateRestWidth() {
    if (tableRef?.current) {
      const tableWidth = tableRef.current.clientWidth;
      const scrollWidth = tableRef.current.scrollWidth;

      if (scrollWidth > tableWidth + 10) {
        return 100;
      }

      const columnsWidth = Array.from(
        tableRef.current.querySelectorAll("[data-column-width]")
      ).reduce((sum, element) => {
        const parsedColumnWidth =
          parseInt(element.getAttribute("data-column-width") || "200") + 20;

        return sum + parsedColumnWidth;
      }, 0);

      return tableWidth - columnsWidth - 24;
    }

    return 1000;
  }

  const observer = new MutationObserver(function () {
    if (tableRef?.current) {
      const restWidth = calculateRestWidth();

      setColumnWidth(`${restWidth}px`);
    }
  });

  useEffect(() => {
    if (tableRef?.current && column.id === "actions" && !editable) {
      observer.observe(tableRef.current, {
        attributes: true,
        subtree: true,
        attributeFilter: ["data-column-width"],
      });

      const restWidth = calculateRestWidth();

      setColumnWidth(`${restWidth}px`);
    }
  }, []);

  useEffect(() => {
    debouncedSetFinalColumnWidth(columnWidth);
  }, [columnWidth]);

  useEffect(() => {
    if (
      finalColumnWidth &&
      finalColumnWidth != initialColumnWidth &&
      column.id !== "actions"
    ) {
      updateUser({
        interfacePreferences: setPreferencedColumnWidth(
          interfacePreferences,
          pathname,
          column.id,
          finalColumnWidth
        ) as JSON,
      });
    }
  }, [finalColumnWidth]);

  return (
    <div
      data-column-width={column.id !== "actions" ? parsedColumnWidth : 0}
      className={css({
        width:
          column.id !== "actions"
            ? editable
              ? "auto"
              : columnWidth
            : columnWidth,
        ...getColumnDataAlign(column.id),
        minWidth: "calc(100%)",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      })}
    >
      <span
        className={css({
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          minWidth: "0",
        })}
      >
        {flexRender(column.column.columnDef.header, column.getContext())}
      </span>

      {!editable && column.column.id !== "checkbox" && (
        <div
          {...bind()}
          onDoubleClick={() => setColumnWidth(systemColumnWidth || "200px")}
          data-ui="resize-handle"
          className={css({
            position: "absolute",
            right: "0px",
            top: "0px",
            bottom: "0px",
            width: "5px",
            zIndex: 20,
            cursor: "col-resize",
            ":hover [data-ui=fill]": {
              transform: "scaleX(1)",
            },
          })}
        >
          <div
            data-ui="fill"
            className={css({
              width: "100%",
              height: "100%",
              backgroundColor: "#ddd",
              transform: "scaleX(0)",
              transition: "200ms ease",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            })}
          >
            <span
              className={css({
                width: "2px",
                height: "20px",
                borderRadius: "1px",
                backgroundColor: "#eee",
              })}
            />
          </div>
        </div>
      )}
    </div>
  );
}
