import { DOCUMENT_TYPES } from "../constants";
import { checkPermission } from "./check-permission";
import { PERMISSIONS } from "./permissions";

export const checkDocumentPermissionToShow = (
  documentType: string
): boolean | undefined => {
  switch (documentType) {
    // case DOCUMENT_TYPES.ContractAgreement:
    //   return checkPermission(PERMISSIONS.contractAgreement.read);
    case DOCUMENT_TYPES.RequestForm:
      return checkPermission(PERMISSIONS.requestForm.read);
    case DOCUMENT_TYPES.Invoice:
      return checkPermission(PERMISSIONS.financialAccountingDocument.read);
    case DOCUMENT_TYPES.ContractPreparationRequest:
      return checkPermission(PERMISSIONS.contractPreparationRequest.read);
    case DOCUMENT_TYPES.BillIssuanceRequest:
      return checkPermission(PERMISSIONS.billIssuanceRequest.read);
    default:
      return checkPermission(PERMISSIONS.document.read);
  }
};

export const checkDocumentPermissionToEdit = (
  documentType: string
): boolean | undefined => {
  switch (documentType) {
    // case DOCUMENT_TYPES.ContractAgreement:
    //   return checkPermission(PERMISSIONS.contractAgreement.update);
    case DOCUMENT_TYPES.RequestForm:
      return checkPermission(PERMISSIONS.requestForm.update);
    case DOCUMENT_TYPES.Invoice:
      return checkPermission(PERMISSIONS.financialAccountingDocument.update);
    case DOCUMENT_TYPES.ContractPreparationRequest:
      return checkPermission(PERMISSIONS.contractPreparationRequest.update);
    case DOCUMENT_TYPES.BillIssuanceRequest:
      return checkPermission(PERMISSIONS.billIssuanceRequest.update);
    default:
      return checkPermission(PERMISSIONS.document.update);
  }
};
