import { BasicFilter, FILTERS } from "./../../filters.d";

export const ADDRESSES_FILTERS: BasicFilter[] = [
  {
    id: "name",
    label: "Nazwa",
    type: FILTERS.Text,
  },
  {
    id: "city",
    label: "Miejscowość",
    type: FILTERS.Text,
  },
  {
    id: "nip",
    label: "NIP",
    type: FILTERS.Text,
  },
  {
    id: "isSensitive",
    label: "Dane osobowe",
    type: FILTERS.Boolean,
  },
];
