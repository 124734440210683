import { useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { StyledLink } from "baseui/link";
import React, { MouseEvent, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Row } from "react-table";
import { Eye } from "tabler-icons-react";

import { Payment } from "../containers/Payments/payments";
import { PAYMENTS_INDEX } from "../containers/Payments/payments.gql";
import { useLoading } from "../contexts/loading-context";
import Button from "./button";
import FormattedValue from "./formatted-value";
import Table from "./table";

type InvoicePaymentsTableProps = {
  financialAccountingDocumentId?: number;
};

export default function InvoicePaymentsTable({
  financialAccountingDocumentId,
}: InvoicePaymentsTableProps): React.ReactElement {
  const [css] = useStyletron();
  const history = useHistory();
  const { setIsFetching } = useLoading();

  const { data, refetch } = useQuery(PAYMENTS_INDEX, {
    variables: {
      pageSize: 100,
      offset: 0,
      filter: {
        financialAccountingDocumentId: {
          eq: financialAccountingDocumentId
            ? financialAccountingDocumentId
            : null,
        },
      },
    },
  });

  useEffect(() => {
    if (data?.payments) refetch();
  }, []);

  useEffect(() => {
    if (data?.payments) setIsFetching(false);
  }, [data]);

  const columns = useMemo(
    () => [
      {
        Header: "Lp.",
        id: "ordinalNumber",
        Cell: ({ row }: { row: Row<Payment> }) => (
          <StyledLink
            onClick={(event: MouseEvent) => {
              event.preventDefault();
              history.push(`/payments/${row.original.id}`);
            }}
            href={`/payments/${row.original.id}`}
          >
            {row.index + 1}
          </StyledLink>
        ),
      },
      {
        Header: (
          <Block display="flex" justifyContent="flex-end">
            Kwota
          </Block>
        ),
        id: "amount",
        Cell: ({ row }: { row: Row<Payment> }) => {
          return (
            <Block display="flex" justifyContent="flex-end">
              <FormattedValue
                dataType="quota"
                currency={
                  row?.original?.financialAccountingDocument?.currency?.code
                }
              >
                {row?.original?.amount}
              </FormattedValue>
            </Block>
          );
        },
      },
      {
        Header: "Utworzone przez",
        id: "createdByBy",
        Cell: ({ row }: { row: Row<Payment> }) => {
          return (
            <FormattedValue
              dataType="model:users"
              data={row?.original?.madeBy?.id}
            >
              {`${row?.original?.createdBy?.firstName} ${row?.original?.createdBy?.lastName}`}
            </FormattedValue>
          );
        },
      },
      {
        Header: "Jednostka organizacyjna",
        id: "organizationalUnit",
        Cell: ({ row }: { row: Row<Payment> }) => {
          return (
            <FormattedValue
              dataType="model:organizational-units"
              data={row?.original?.organizationalUnit?.id}
            >
              {row?.original?.organizationalUnit?.name}
            </FormattedValue>
          );
        },
      },
      {
        Header: "Status",
        id: "ststus",
        Cell: ({ row }: { row: Row<Payment> }) => {
          return (
            <FormattedValue dataType="document-state">
              {
                row?.original?.currentStatus?.documentFlowAction
                  ?.resultingDocumentState
              }
            </FormattedValue>
          );
        },
      },
      {
        id: "actions",
        Cell: ({ row }: { row: Row<Payment> }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              $style={{ marginLeft: "10px" }}
              $as="a"
              href={`/payments/${row.original.id}`}
              onClick={(e) => {
                e.preventDefault();
                history.push(`/payments/${row.original.id}`);
              }}
              startEnhancer={<Eye size={14} />}
            />
          </div>
        ),
      },
    ],
    [data]
  );

  return (
    <Table<Payment> compact columns={columns} data={data?.payments?.nodes} />
  );
}
