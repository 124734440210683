import { useStyletron } from "baseui";
import { Avatar as BaseAvatar } from "baseui/avatar";
import { Block } from "baseui/block";
import { ALIGNMENT } from "baseui/layout-grid";
import { PLACEMENT } from "baseui/popover";
import { LabelLarge, LabelSmall } from "baseui/typography";
import React from "react";
import stc from "string-to-color";
import { Bolt, ChevronsRight, History, Replace } from "tabler-icons-react";

import { DocumentStatus } from "../containers/Documents/documents";
import {
  DocumentFlowAction,
  DocumentFlowStep,
} from "../containers/Flow/document-flow";
import Card from "./card";
import Cell from "./cell";
import FormattedValue from "./formatted-value";
import Grid from "./grid";
import Link from "./link";
import ProgressBar from "./progress-bar";
import Tooltip from "./tooltip";

type FlowProgressProps = {
  currentStep: DocumentFlowStep;
  originalOrganizationalUnitName: string;
  statuses: DocumentStatus[];
  actions: DocumentFlowAction[];
};

export default function FlowProgress({
  currentStep,
  originalOrganizationalUnitName,
  statuses,
  actions,
}: FlowProgressProps): React.ReactElement {
  const [css, theme] = useStyletron();

  const statusIndex = statuses?.findIndex(
    (status: DocumentStatus) => status?.documentFlowStep.id === currentStep?.id
  );

  const status = statuses?.find(
    (status: DocumentStatus) => status.documentFlowStep?.id === currentStep.id
  );

  const comment = statuses && statuses[statusIndex + 1]?.comment;

  const hasFirstStepEnded = statuses?.length > 1;

  return (
    <Card>
      <>
        {(currentStep?.isProcessedByDocumentFlowStarter ||
          !!currentStep?.organizationalUnit ||
          !!currentStep?.group ||
          !!currentStep?.users?.length) && (
          <LabelLarge $style={{ fontWeight: 400 }}>
            Aktualnie u:{" "}
            <strong>
              {currentStep?.isProcessedByDocumentFlowStarter
                ? "Rozpoczynający obieg"
                : !currentStep?.organizationalUnit &&
                  !currentStep?.users?.length &&
                  !currentStep.group
                ? originalOrganizationalUnitName
                : currentStep.organizationalUnit
                ? currentStep.organizationalUnit.name
                : currentStep.group
                ? currentStep.group.name
                : currentStep.users
                    .map((user: any) => `${user.firstName} ${user.lastName}`)
                    .join(", ")}
            </strong>
          </LabelLarge>
        )}

        <div
          className={css({
            marginTop: "40px",
          })}
        >
          <Grid fill>
            <Cell span={2} align={ALIGNMENT.center}>
              <ProgressBar
                $style={{
                  backgroundColor: theme.colors.primary,
                }}
              />

              <LabelSmall
                $style={{
                  position: "relative",
                  textAlign: "center",
                  fontWeight: 400,
                  color: hasFirstStepEnded ? "#333" : "#AAA",
                  textDecoration: "underline",
                  textUnderlineOffset: "2px",
                  cursor: hasFirstStepEnded ? "pointer" : "not-allowed",
                }}
                {...(hasFirstStepEnded && {
                  onClick: () => {
                    window &&
                      window.document
                        .getElementById("historyTable")
                        ?.scrollIntoView({ behavior: "smooth" });
                  },
                })}
              >
                Historia
                <History
                  size={16}
                  className={css({
                    verticalAlign: "middle",
                    marginLeft: "6px",
                    display: "inline",
                  })}
                />
              </LabelSmall>
            </Cell>

            <Cell span={actions.length ? 6 : 10} align={ALIGNMENT.center}>
              <div
                className={css({
                  display: "block",
                  marginTop: "auto",
                })}
              >
                <div
                  className={css({
                    position: "relative",
                    ...(status && {
                      ":before": {
                        position: "absolute",
                        top: "-8px",
                        left: "-18px",
                        content: "''",
                        width: "1px",
                        height: "24px",
                        backgroundColor: "black",
                        opacity: 0.5,
                      },
                    }),
                  })}
                >
                  <ProgressBar
                    $style={{
                      backgroundColor: theme.colors.primary,
                    }}
                  />
                </div>
                {status?.user ? (
                  <div
                    className={css({
                      position: "absolute",
                      top: "-25px",
                      transform: "translateX(calc(-50% - 18px))",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    })}
                  >
                    <div
                      className={css({
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      })}
                    >
                      {status?.substituter ? (
                        <Tooltip
                          placement="bottomLeft"
                          content={`${status?.substituter?.firstName} ${status?.substituter?.lastName} w zastępstwie za ${status?.user?.firstName} ${status?.user?.lastName}`}
                        >
                          <span
                            className={css({
                              display: "flex",
                              alignItems: "center",
                            })}
                          >
                            <Link path={`/users/${status?.substituter?.id}`}>
                              <BaseAvatar
                                name={`${status?.substituter?.firstName} ${status?.substituter?.lastName}`}
                                size="scale700"
                                overrides={{
                                  Root: {
                                    style: {
                                      flexShrink: 0,
                                      backgroundColor: stc(
                                        `${status?.substituter?.firstName} ${status?.substituter?.lastName}`
                                      ),
                                      marginRight: theme.sizing.scale200,
                                      transition: ".2s",
                                      ":hover": {
                                        cursor: "pointer",
                                        transform: "scale(1.1)",
                                      },
                                    },
                                  },
                                  Initials: {
                                    style: {
                                      fontSize: "10px",
                                    },
                                  },
                                }}
                              />
                            </Link>
                            <Replace
                              size={18}
                              className={css({ marginTop: "2px" })}
                            />
                          </span>
                        </Tooltip>
                      ) : (
                        <Tooltip
                          placement="bottomLeft"
                          content={`${status?.user?.firstName} ${status?.user?.lastName}`}
                        >
                          <span>
                            <Link path={`/users/${status?.user?.id}`}>
                              <BaseAvatar
                                name={`${status?.user?.firstName} ${status?.user?.lastName}`}
                                size="scale700"
                                overrides={{
                                  Root: {
                                    style: {
                                      flexShrink: 0,
                                      backgroundColor: stc(
                                        `${status?.user?.firstName} ${status?.user?.lastName}`
                                      ),
                                      marginRight: theme.sizing.scale200,
                                      transition: ".2s",
                                      ":hover": {
                                        cursor: "pointer",
                                        transform: "scale(1.1)",
                                      },
                                    },
                                  },
                                  Initials: {
                                    style: {
                                      fontSize: "10px",
                                    },
                                  },
                                }}
                              />
                            </Link>
                          </span>
                        </Tooltip>
                      )}

                      <FormattedValue
                        dataType="datetime"
                        $style={{
                          fontSize: "10px",
                          lineHeight: "10px",
                        }}
                      >
                        {status?.createdAt}
                      </FormattedValue>
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                <Tooltip placement="bottomLeft" content={comment && comment}>
                  <span>
                    <LabelSmall
                      $style={{
                        position: "relative",
                        textAlign: "center",
                        fontWeight: 600,
                        color: "#000",
                        ...(comment && {
                          ":before": {
                            position: "absolute",
                            top: 0,
                            right: "-7px",
                            content: "''",
                            width: "5px",
                            height: "5px",
                            borderRadius: "50%",
                            backgroundColor: theme.colors.primary,
                          },
                          ":hover": {
                            cursor: "pointer",
                          },
                        }),
                      }}
                    >
                      {currentStep.name}
                      {currentStep.isInitial && (
                        <Tooltip
                          content="Początkowy krok"
                          placement={PLACEMENT.right}
                        >
                          <span>
                            <Bolt
                              size={16}
                              className={css({
                                verticalAlign: "middle",
                                marginLeft: "6px",
                                display: "inline",
                                color: theme.colors.positive,
                              })}
                            />
                          </span>
                        </Tooltip>
                      )}
                    </LabelSmall>
                  </span>
                </Tooltip>
              </div>
            </Cell>

            <Cell span={4}>
              <Block display="flex" flexDirection="column">
                {actions?.map((action) => (
                  <Block key={action.id} marginTop="8px">
                    <div
                      className={css({
                        position: "relative",
                        ":before": {
                          position: "absolute",
                          top: "4px",
                          left: "-32px",
                          content: "''",
                          width: "24px",
                          height: "1px",
                          backgroundColor: "black",
                          opacity: 0.5,
                        },
                      })}
                    >
                      <ProgressBar
                        $style={{
                          backgroundColor: "#666",
                          opacity: 0.45,
                        }}
                      />

                      <div
                        className={css({
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "5px",
                        })}
                      >
                        <LabelSmall
                          $style={{
                            position: "relative",
                            textAlign: "center",
                            fontWeight: 400,
                            color: "#666",
                          }}
                        >
                          {action.label}

                          <ChevronsRight
                            size={12}
                            className={css({
                              verticalAlign: "middle",
                              marginLeft: "6px",
                              marginRight: "6px",
                              display: "inline",
                            })}
                          />

                          {action.endDocumentFlowStep.name}
                        </LabelSmall>
                      </div>
                    </div>
                  </Block>
                ))}
              </Block>
            </Cell>
          </Grid>
        </div>
      </>
    </Card>
  );
}
