/* eslint-disable @typescript-eslint/no-explicit-any */
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { mergeOverrides, useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { Overrides } from "baseui/overrides";
import {
  Option,
  Select as BaseSelect,
  SelectProps,
  Value,
} from "baseui/select";
import { LabelSmall, LabelXSmall, MonoLabelXSmall } from "baseui/typography";
import React, {
  ChangeEvent,
  FormEvent,
  Fragment,
  HTMLProps,
  ReactNodeArray,
  useEffect,
} from "react";
import { useState } from "react";
import { Controller, UseControllerProps } from "react-hook-form";
import { Row } from "react-table";
import { VariableSizeList as List } from "react-window";
import { StyleObject } from "styletron-react";
import {
  Building,
  Check,
  ChevronDown,
  ChevronDownLeft,
  Circle,
  Cloud,
  FolderOff,
  Keyboard,
  ListSearch,
  Man,
  Paperclip,
  Send,
  Trash,
  World,
  X,
} from "tabler-icons-react";

import {
  DEKLARACJA_CELNA_RODZAJ,
  DOCUMENT_PICKUP_STATE_OPTIONS,
  DOKUMENTY_TOWARZYSZACE_RODZAJ,
  ENADAWCA_ADDRESS_TYPES,
  ENADAWCA_FORMAT,
  ENADAWCA_PARCEL_CATEGORIES,
  ENADAWCA_PARCEL_OVERALL_DIMENSIONS,
  ENADAWCA_PARCELS,
  EXAMPLE_FUNDING_SOURCES,
  PARCEL_SIZES,
  ZAWARTOSC_PRZESYLKI,
} from "../constants";
import { Address, BankAccountType } from "../containers/Addresses/addresses.d";
import { ADDRESSES_SELECT_INDEX } from "../containers/Addresses/addresses.gql";
import { Case } from "../containers/Cases/cases";
import {
  CASES_SELECT_INDEX,
  MY_CASES_SELECT_INDEX,
} from "../containers/Cases/cases.gql";
import { Currency } from "../containers/Currencies/currencies";
import {
  CURRENCIES_SELECT_INDEX,
  CURRENCIES_SHOW,
} from "../containers/Currencies/currencies.gql";
import {
  DICTIONARIES_SHOW,
  DICTIONARIES_VALUES,
} from "../containers/Dictionaries/dictionaries.gql";
import { DictionaryValue } from "../containers/Dictionaries/dictionary";
import { DivisorTemplate } from "../containers/Divisors/divisors";
import { DIVISOR_TEMPLATES_SELECT_INDEX } from "../containers/Divisors/divisors.gql";
import {
  Budget,
  BudgetItem,
  Document,
  RequestFormItem,
} from "../containers/Documents/documents";
import {
  BUDGETS_INDEX,
  DOCUMENTS_SELECT_INDEX,
} from "../containers/Documents/documents.gql";
import { REQUEST_FORM_SELECT_SHOW } from "../containers/Documents/RequestForm/request-form.gql";
import {
  FinancialPlan,
  FinancialPlanItem,
} from "../containers/FinancialPlans/financial-plans";
import { AvailableFinancialPlan } from "../containers/FinancialPlans/financial-plans";
import {
  FINANCIAL_PLAN_ITEMS_SELECT,
  FINANCIAL_PLANS_IN_RANGE,
} from "../containers/FinancialPlans/financial-plans.gql";
import { AVAILABLE_FINANCIAL_PLANS } from "../containers/FinancialPlans/financial-plans.gql";
import { DocumentFlowStep } from "../containers/Flow/document-flow";
import { DOCUMENT_FLOW } from "../containers/Flow/flow.gql";
import { Group } from "../containers/Groups/groups";
import { InternalAccount } from "../containers/InternalAccounts/internal-accounts";
import { INTERNAL_ACCOUNTS_SELECT_INDEX } from "../containers/InternalAccounts/internal-accounts.gql";
import { JrwaClassification } from "../containers/JrwaClassifications/jrwa-classifications";
import { JRWA_CLASSIFICATIONS_INDEX } from "../containers/JrwaClassifications/jrwa-classifications.gql";
import { OrganizationalUnit } from "../containers/OrganizationalUnits/organizational-units";
import {
  ORGANIZATIONAL_UNITS_INDEX,
  ORGANIZATIONAL_UNITS_SHOW,
} from "../containers/OrganizationalUnits/organizational-units.gql";
import { Position } from "../containers/Positions/positions";
import { Role } from "../containers/Roles/roles";
import { User } from "../containers/Users/users";
import {
  MY_ORGANIZATIONAL_UNITS_USERS,
  USERS_SELECT_INDEX,
  USERS_SHOW_ORGANIZATIONAL_UNITS,
} from "../containers/Users/users.gql";
import { useAuth } from "../contexts/auth-context";
import { useBudgets } from "../contexts/budgets-context";
import { useDictionaries } from "../contexts/dictionaries-context";
import { useLoading } from "../contexts/loading-context";
import { renderDocumentFieldsOptions } from "../utils/document-flow";
import Button from "./button";
import FormattedValue from "./formatted-value";
import Table from "./table";

type Props = {
  $style?: StyleObject;
  $controlContainerStyle?: StyleObject;
  $dropdownStyle?: StyleObject;
  objectId?: number;
  hideIcon?: boolean;
} & SelectProps;

const Select = ({
  $style,
  $controlContainerStyle,
  $dropdownStyle,
  overrides,
  isLoading,
  multi,
  size,
  disabled,
  hideIcon,
  id,
  ...rest
}: Props): React.ReactElement => {
  const [css, theme] = useStyletron();
  const Component = BaseSelect;

  return (
    <Component
      filterOutSelected={false}
      closeOnSelect={!multi}
      maxDropdownHeight="300px"
      noResultsMsg={
        <div
          key="error"
          className={css({
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          })}
        >
          {isLoading ? (
            <ListSearch
              color="#999"
              size={18}
              className={css({ marginRight: "5px" })}
            />
          ) : (
            <FolderOff
              color="#999"
              size={18}
              className={css({ marginRight: "5px" })}
            />
          )}

          {isLoading ? "Trwa przeszukiwanie" : "Brak rekordów"}
        </div>
      }
      isLoading={isLoading}
      multi={multi}
      size={size || "compact"}
      disabled={disabled}
      overrides={mergeOverrides<any>(
        {
          Root: {
            style: {
              ...$style,
            },
          },
          ControlContainer: {
            style: {
              borderTopLeftRadius: theme.borders.radius200,
              borderTopRightRadius: theme.borders.radius200,
              borderBottomRightRadius: theme.borders.radius200,
              borderBottomLeftRadius: theme.borders.radius200,
              transitionDuration: "100ms",
              minHeight: "32px",
              ...$controlContainerStyle,
            },
          },
          ValueContainer: {
            style: {
              height: "32px",
              display: "flex",
              alignItems: "center",
              ...(!multi && { paddingTop: "0px", paddingBottom: "2px" }),
              ...(disabled && { opacity: 0.5 }),
            },
          },
          SingleValue: {
            style: {
              height: "auto",
              fontSize: "12px",
            },
          },
          OptionContent: {
            style: ({ $selected }) => ({
              fontWeight: $selected ? 600 : 400,
              fontSize: "12px",
              ...($selected &&
                multi && {
                  paddingLeft: "20px",
                  "::before": {
                    content: "'✓'",
                    fontSize: "7px",
                    backgroundColor: theme.colors.primary,
                    paddingLeft: "4px",
                    paddingRight: "4px",
                    paddingTop: "1px",
                    paddingBottom: "1px",
                    color: "white",
                    borderRadius: "8px",
                    position: "absolute",
                    height: "12px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    top: "9px",
                    left: "16px",
                  },
                }),
            }),
          },
          LoadingIndicator: {
            props: {
              overrides: {
                ActivePath: {
                  style: {
                    fill: theme.colors.borderFocus,
                  },
                },
                Svg: {
                  style: {
                    height: "16px",
                    marginRight: "5px",
                  },
                },
              },
            },
          },
          SelectArrow: {
            props: {
              overrides: {
                Svg: () =>
                  hideIcon ? null : (
                    <ChevronDown color={theme.colors.borderFocus} size={16} />
                  ),
              },
            },
          },
          ClearIcon: {
            props: {
              overrides: {
                Svg: (props: HTMLProps<HTMLElement>) => (
                  <span
                    className={css({
                      marginRight: "5px",
                      cursor: "pointer",
                      display: "inline",
                      lineHeight: 0,
                    })}
                    {...props}
                  >
                    <X color={theme.colors.borderFocus} size={16} />
                  </span>
                ),
              },
            },
          },
          DropdownListItem: {
            style: {
              paddingTop: theme.sizing.scale200,
              paddingBottom: theme.sizing.scale200,
            },
          },
          Dropdown: {
            style: {
              boxShadow: "unset",
              borderTopLeftRadius: theme.borders.radius200,
              borderTopRightRadius: theme.borders.radius200,
              borderBottomRightRadius: theme.borders.radius200,
              borderBottomLeftRadius: theme.borders.radius200,
              borderLeftWidth: "2px",
              borderLeftStyle: "solid",
              borderLeftColor: theme.colors.borderFocus,
              borderBottomWidth: "2px",
              borderBottomStyle: "solid",
              borderBottomColor: theme.colors.borderFocus,
              borderRightWidth: "2px",
              borderRightStyle: "solid",
              borderRightColor: theme.colors.borderFocus,
              borderTopWidth: "2px",
              borderTopStyle: "solid",
              borderTopColor: theme.colors.borderFocus,
              backgroundColor: theme.colors.inputFill,
              ...$dropdownStyle,
            },
          },
          Popover: {
            props: {
              ignoreBoundary: true,
              overrides: {
                Body: {
                  style: {
                    zIndex: 200,
                    boxShadow: "none",
                    marginLeft: "1px",
                    marginTop: "5px",
                    marginBottom: "5px",
                    backgroundColor: "transparent",
                  },
                },
                Inner: {
                  style: {
                    backgroundColor: "transparent",
                  },
                },
              },
            },
          },
          StatefulMenu: {
            props: {
              overrides: {
                EmptyState: {
                  style: {
                    color: "#999999",
                    paddingTop: theme.sizing.scale300,
                    paddingBottom: theme.sizing.scale300,
                  },
                },
              },
            },
          },
          Input: {
            props: {
              id,
              name: id,
            },
          },
        },
        overrides as Overrides<any>
      )}
      {...rest}
    />
  );
};

export default Select;

function LiveSearchSelect({
  value,
  onInputChange,
  ...rest
}: Props): React.ReactElement {
  const [css, theme] = useStyletron();
  const [isSelectedOrSearched, setIsSelectedOrSearched] = useState(false);

  useEffect(() => {
    if (value?.length) {
      setIsSelectedOrSearched(true);
    } else {
      setIsSelectedOrSearched(false);
    }
  }, [value]);

  return (
    <Select
      onInputChange={(event: FormEvent<HTMLInputElement>) => {
        setIsSelectedOrSearched(!!(event.currentTarget.value as string));

        onInputChange && onInputChange(event);
      }}
      value={value}
      {...(!isSelectedOrSearched && {
        overrides: {
          Dropdown: {
            component: (props: HTMLProps<HTMLUListElement>) => {
              return (
                <div
                  className={css({
                    padding: "16px",
                    boxShadow: "none",
                    borderTopLeftRadius: theme.borders.radius200,
                    borderTopRightRadius: theme.borders.radius200,
                    borderBottomRightRadius: theme.borders.radius200,
                    borderBottomLeftRadius: theme.borders.radius200,
                    backgroundColor: "#FDFDFD",
                    borderLeftWidth: "2px",
                    borderLeftStyle: "solid",
                    borderLeftColor: theme.colors.borderFocus,
                    borderBottomWidth: "2px",
                    borderBottomStyle: "solid",
                    borderBottomColor: theme.colors.borderFocus,
                    borderRightWidth: "2px",
                    borderRightStyle: "solid",
                    borderRightColor: theme.colors.borderFocus,
                    borderTopWidth: "2px",
                    borderTopStyle: "solid",
                    borderTopColor: theme.colors.borderFocus,
                  })}
                >
                  <Block display="flex" alignItems="center" color="#000">
                    <Keyboard
                      color="#000"
                      size={16}
                      className={css({ marginRight: "5px" })}
                    />

                    <LabelSmall>
                      Rozpocznij wpisywanie, aby przeszukać
                    </LabelSmall>
                  </Block>

                  <Block
                    display="flex"
                    alignItems="center"
                    color="#000"
                    marginTop="12px"
                    marginBottom="10px"
                  >
                    <LabelSmall
                      $style={{
                        flexGrow: 1,
                        paddingTop: "12px",
                        borderTop: "1px solid #eee",
                      }}
                    >
                      Najnowsze
                    </LabelSmall>
                  </Block>

                  <ul
                    className={css({
                      padding: "unset",
                      margin: "unset",
                      borderTopLeftRadius: theme.borders.radius200,
                      borderTopRightRadius: theme.borders.radius200,
                      borderBottomRightRadius: theme.borders.radius200,
                      borderBottomLeftRadius: theme.borders.radius200,
                      borderLeftWidth: "2px",
                      borderLeftStyle: "solid",
                      borderLeftColor: theme.colors.inputBorder,
                      borderBottomWidth: "2px",
                      borderBottomStyle: "solid",
                      borderBottomColor: theme.colors.inputBorder,
                      borderRightWidth: "2px",
                      borderRightStyle: "solid",
                      borderRightColor: theme.colors.inputBorder,
                      borderTopWidth: "2px",
                      borderTopStyle: "solid",
                      borderTopColor: theme.colors.inputBorder,
                      backgroundColor: theme.colors.inputFill,
                    })}
                    {...props}
                  />
                </div>
              );
            },
          },
        },
      })}
      {...rest}
    />
  );
}

function VirtualizedSelect({
  options,
  value,
  onInputChange,
  $dropdownStyle,
  ...rest
}: Props): React.ReactElement {
  const [css, theme] = useStyletron();
  const [searchPhrase, setSearchPhrase] = useState<string>();

  function getItemSize(index: number): number {
    const option = (searchPhrase
      ? (options as Value)?.filter((value) =>
          value.label?.toString().includes(searchPhrase)
        )
      : (options as Value))?.[index];

    return (
      Math.max(
        Math.round((option?.label?.toString()?.length || 90) / 32) * 16,
        16
      ) + 32
    );
  }

  return (
    <Select
      onInputChange={(event: FormEvent<HTMLInputElement>) => {
        setSearchPhrase(event.currentTarget.value as string);

        onInputChange && onInputChange(event);
      }}
      value={value}
      options={options}
      {...(!!(searchPhrase
        ? (options as Value)?.filter((value) =>
            value.label?.toString().includes(searchPhrase)
          )
        : (options as Value)
      )?.length && {
        overrides: {
          Dropdown: {
            component: (props: HTMLProps<HTMLUListElement>) => {
              return (
                <div
                  className={css({
                    boxShadow: "unset",
                    borderTopLeftRadius: theme.borders.radius200,
                    borderTopRightRadius: theme.borders.radius200,
                    borderBottomRightRadius: theme.borders.radius200,
                    borderBottomLeftRadius: theme.borders.radius200,
                    borderLeftWidth: "2px",
                    borderLeftStyle: "solid",
                    borderLeftColor: theme.colors.borderFocus,
                    borderBottomWidth: "2px",
                    borderBottomStyle: "solid",
                    borderBottomColor: theme.colors.borderFocus,
                    borderRightWidth: "2px",
                    borderRightStyle: "solid",
                    borderRightColor: theme.colors.borderFocus,
                    borderTopWidth: "2px",
                    borderTopStyle: "solid",
                    borderTopColor: theme.colors.borderFocus,
                    backgroundColor: theme.colors.inputFill,
                    ...$dropdownStyle,
                  })}
                >
                  <List
                    height={200}
                    itemCount={
                      (searchPhrase
                        ? (options as Value)?.filter((value) =>
                            value.label?.toString().includes(searchPhrase)
                          )
                        : (options as Value)
                      )?.length as number
                    }
                    itemSize={getItemSize}
                    width={295}
                    className={css({
                      padding: "unset",
                      margin: "unset",
                      borderTopLeftRadius: theme.borders.radius200,
                      borderTopRightRadius: theme.borders.radius200,
                      borderBottomRightRadius: theme.borders.radius200,
                      borderBottomLeftRadius: theme.borders.radius200,
                      borderLeftWidth: "2px",
                      borderLeftStyle: "solid",
                      borderLeftColor: theme.colors.inputBorder,
                      borderBottomWidth: "2px",
                      borderBottomStyle: "solid",
                      borderBottomColor: theme.colors.inputBorder,
                      borderRightWidth: "2px",
                      borderRightStyle: "solid",
                      borderRightColor: theme.colors.inputBorder,
                      borderTopWidth: "2px",
                      borderTopStyle: "solid",
                      borderTopColor: theme.colors.inputBorder,
                      backgroundColor: theme.colors.inputFill,
                      position: "relative",
                    })}
                    itemData={props.children}
                  >
                    {({ data, index, style }) => {
                      return (
                        <div style={style}>
                          {(data as ReactNodeArray)[index]}
                        </div>
                      );
                    }}
                  </List>
                </div>
              );
            },
          },
        },
      })}
      {...rest}
    />
  );
}

export function ControlledSelect({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & Props): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Select
          onInputChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChange(e.target.value)
          }
          onBlur={onBlur}
          value={value}
          id={name}
          {...rest}
        />
      )}
    />
  );
}

export function ControlledOrganizationalUnitsSelect({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & Props): React.ReactElement {
  const { refetch, data, loading } = useQuery(ORGANIZATIONAL_UNITS_INDEX);
  const [isSelectedOrSearched, setIsSelectedOrSearched] = useState(false);

  useEffect(() => data?.organizationalUnits && refetch(), []);

  const nestChildrenUnits = (
    unit: OrganizationalUnit,
    depth: number
  ): Array<Option> => {
    return data?.organizationalUnits?.nodes?.filter(
      (oU: OrganizationalUnit) => oU.parentId === unit?.id
    )?.length > 0
      ? (data?.organizationalUnits?.nodes as OrganizationalUnit[])
          ?.filter((oU: OrganizationalUnit) => oU.parentId === unit?.id)
          .map((item: OrganizationalUnit) => [
            {
              label: `${item?.symbol} ${item?.budgetSymbol} ${item?.name}`,
              depth: depth,
              ...item,
            },
            ...nestChildrenUnits(item, depth + 1),
          ])
          .flat()
      : [];
  };

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Select
          id={name}
          options={(data?.organizationalUnits?.nodes as OrganizationalUnit[])
            ?.filter((oU) => !oU.parentId)
            .map((item: OrganizationalUnit) => [
              {
                label: `${item?.symbol}  ${item?.budgetSymbol} ${item?.name}`,
                depth: 0,
                ...item,
              },
              ...nestChildrenUnits(item, 1),
            ])
            .flat()}
          onInputChange={(event: FormEvent<HTMLInputElement>) => {
            setIsSelectedOrSearched(!!(event.currentTarget.value as string));
          }}
          getOptionLabel={({ option }) => (
            <Block
              {...(!isSelectedOrSearched && {
                marginLeft: option?.depth && `${(option?.depth - 1) * 24}px`,
              })}
              display="flex"
              alignItems="center"
            >
              {!isSelectedOrSearched && option?.depth > 0 && (
                <ChevronDownLeft size={16} style={{ flexShrink: 0 }} />
              )}
              <Block
                display="flex"
                flexDirection="column"
                {...(!isSelectedOrSearched && {
                  marginLeft: option?.depth && `8px`,
                })}
              >
                <MonoLabelXSmall>{option?.budgetSymbol}</MonoLabelXSmall>
                <LabelSmall>{`${option?.symbol ? `[${option?.symbol}] ` : ""}${
                  option?.name
                }`}</LabelSmall>
              </Block>
            </Block>
          )}
          getValueLabel={({ option }) => (
            <Block display="flex" flexDirection="column">
              <MonoLabelXSmall>{option?.budgetSymbol}</MonoLabelXSmall>
              <LabelSmall>{`${option?.symbol ? `[${option?.symbol}] ` : ""}${
                option?.name
              }`}</LabelSmall>
            </Block>
          )}
          isLoading={loading}
          onChange={(params) => params && onChange(params.value)}
          onBlur={(event: Event) => {
            onBlur();
            setIsSelectedOrSearched(
              !!(event.currentTarget as HTMLInputElement).value
            );
          }}
          value={value}
          maxDropdownHeight="300px"
          {...rest}
        />
      )}
    />
  );
}

export function ControlledUsersOrganizationalUnitsSelect({
  objectId,
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & Props): React.ReactElement {
  const [firstRender, setFirstRender] = useState<boolean>(true);

  const { user } = useAuth();

  const [fetchUser, { data: queryData, loading }] = useLazyQuery(
    USERS_SHOW_ORGANIZATIONAL_UNITS
  );

  useEffect(() => {
    if (objectId) fetchUser({ variables: { id: objectId } });

    setFirstRender(true);
  }, [objectId]);

  const data = objectId ? queryData?.user : user;

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value } }) => {
        if (
          !loading &&
          !value?.length &&
          firstRender &&
          data?.organizationalUnitToUsers?.length > 0
        ) {
          onChange([
            {
              id: data?.organizationalUnitToUsers[0]?.organizationalUnit?.id,
              label: `${data?.organizationalUnitToUsers[0]?.organizationalUnit?.symbol} ${data?.organizationalUnitToUsers[0]?.organizationalUnit?.budgetSymbol} ${data?.organizationalUnitToUsers[0]?.organizationalUnit?.name}`,
              ...data?.organizationalUnitToUsers[0]?.organizationalUnit,
            },
          ]);
          setFirstRender(false);
        }

        return (
          <Select
            options={data?.organizationalUnitToUsers?.map(
              (item: {
                organizationalUnit: {
                  id: number;
                  name: string;
                  symbol: string;
                  budgetSymbol?: string;
                };
              }) => ({
                label: `${item?.organizationalUnit?.symbol} ${item?.organizationalUnit?.budgetSymbol} ${item?.organizationalUnit?.name}`,
                ...item.organizationalUnit,
              })
            )}
            getOptionLabel={({ option }) => (
              <Block display="flex" flexDirection="column">
                <MonoLabelXSmall>{option?.budgetSymbol}</MonoLabelXSmall>
                <LabelSmall>{`${option?.symbol ? `[${option?.symbol}] ` : ""}${
                  option?.name
                }`}</LabelSmall>
              </Block>
            )}
            getValueLabel={({ option }) => (
              <Block display="flex" flexDirection="column">
                <MonoLabelXSmall>{option?.budgetSymbol}</MonoLabelXSmall>
                <LabelSmall>{`${option?.symbol ? `[${option?.symbol}] ` : ""}${
                  option?.name
                }`}</LabelSmall>
              </Block>
            )}
            isLoading={loading}
            onChange={(params) => params && onChange(params.value)}
            onBlur={onBlur}
            value={value}
            {...rest}
          />
        );
      }}
    />
  );
}

export function ControlledOrganizationalUnitUsersSelect({
  control,
  name,
  rules,
  objectId,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & Props): React.ReactElement {
  const [fetchData, { data, loading }] = useLazyQuery(
    ORGANIZATIONAL_UNITS_SHOW
  );

  const [searchPhrase, setSearchPhrase] = useState("");

  useEffect(() => {
    fetchData({ variables: { id: objectId } });
  }, [objectId]);

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value } }) => (
        <LiveSearchSelect
          options={data?.organizationalUnit?.organizationalUnitToUsers
            .filter(({ user: { firstName, lastName } }: { user: User }) =>
              `${firstName} ${lastName}`
                .toLowerCase()
                .includes(searchPhrase.toLowerCase())
            )
            .slice(0, 5)
            .map((item: any) => ({
              id: item?.user?.id,
              label: item?.user?.firstName + " " + item?.user?.lastName,
            }))}
          onClose={() => setSearchPhrase("")}
          onInputChange={(event: FormEvent<HTMLInputElement>) => {
            setSearchPhrase(event.currentTarget.value as string);
          }}
          isLoading={loading}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          value={value}
          maxDropdownHeight="300px"
          getValueLabel={({ option }) => option?.label}
          {...rest}
        />
      )}
    />
  );
}

export function ControlledUsersSelect({
  control,
  name,
  rules,
  fromMyOrganizationalUnits,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> &
  Props & { fromMyOrganizationalUnits?: boolean }): React.ReactElement {
  const [fetchData, { data, loading }] = useLazyQuery(
    fromMyOrganizationalUnits
      ? MY_ORGANIZATIONAL_UNITS_USERS
      : USERS_SELECT_INDEX
  );
  const options = fromMyOrganizationalUnits
    ? data?.myOrganizationalUnitsUsers?.nodes
    : data?.usersPaginated?.nodes;

  useEffect(
    () =>
      fetchData({
        variables: {
          filter: {
            and: {
              isBlockedByAdmin: { isNot: true },
              isBlockedByAD: { isNot: true },
            },
          },
        },
      }),
    []
  );

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value } }) => (
        <LiveSearchSelect
          options={options?.map((user: User) => ({
            label: `${user.firstName} ${user.lastName} ${user.lastName} ${user.firstName}`,
            ...user,
          }))}
          getValueLabel={({ option }) =>
            `${option?.firstName} ${option?.lastName}`
          }
          getOptionLabel={({ option }) =>
            `${option?.firstName} ${option?.lastName}`
          }
          value={value}
          id={name}
          isLoading={loading}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          onInputChange={(event: FormEvent) => {
            const value = (event.currentTarget as any).value.split(" ");

            let nameFilters;

            if (value.length === 1) {
              nameFilters = {
                or: [
                  {
                    firstName: {
                      iLike: `%${value[0]}%`,
                    },
                  },
                  {
                    lastName: {
                      iLike: `%${value[0]}%`,
                    },
                  },
                ],
              };
            } else {
              let array: any = [];

              value.forEach((a: string) => {
                const rest = value.filter((b: string) => b !== a);

                rest.forEach((b: string) => {
                  const more =
                    value.filter((c: string) => c !== a && c !== b).join(" ") ??
                    "";

                  array = [
                    ...array,
                    {
                      and: [
                        {
                          firstName: {
                            iLike: `%${a}%`,
                          },
                        },
                        {
                          lastName: {
                            iLike: `%${b}${more.length > 0 ? " " + more : ""}%`,
                          },
                        },
                      ],
                    },
                    {
                      lastName: {
                        iLike: `%${a} ${b}%`,
                      },
                    },
                  ];
                });
              });

              nameFilters = { or: array };
            }

            fetchData({
              variables: {
                filter: {
                  ...nameFilters,
                  and: {
                    isBlockedByAdmin: { isNot: true },
                    isBlockedByAD: { isNot: true },
                  },
                },
              },
            });
          }}
          onClose={fetchData}
          {...rest}
        />
      )}
    />
  );
}

export function ControlledUsersLiveSearchTableSelect({
  control,
  disabled,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & SelectProps): React.ReactElement {
  const [css] = useStyletron();
  const { isLoading } = useLoading();
  const [fetchData, { data, loading }] = useLazyQuery(USERS_SELECT_INDEX);

  const options = data?.usersPaginated?.nodes;

  useEffect(() => fetchData(), []);

  const columns = React.useMemo(
    () => [
      {
        Header: "Imię i nazwisko",
        accessor: "fullName",
        Cell: ({ row }: { row: Row<User> }) => (
          <FormattedValue
            dataType="model:users"
            data={row.original.id}
          >{`${row.original.firstName} ${row.original.lastName}`}</FormattedValue>
        ),
        disableGlobalFilter: true,
      },
      {
        Header: "Adres e-mail",
        accessor: "email",
        Cell: ({ row }: { row: Row<User> }) => (
          <FormattedValue dataType="email">{row.original.email}</FormattedValue>
        ),
      },
    ],
    []
  );

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => {
        return (
          <Fragment>
            <LiveSearchSelect
              options={options?.map((user: User) => ({
                label: `${user.firstName} ${user.lastName}`,
                email: user.email,
                ...user,
              }))}
              getValueLabel={({ option }) => `${option?.label}`}
              getOptionLabel={({ option }) => `${option?.label}`}
              id={name}
              isLoading={loading}
              disabled={disabled}
              onChange={(params) => params && onChange(params.value)}
              onBlur={onBlur}
              placeholder="Wybierz"
              onInputChange={(event: FormEvent) => {
                const value = (event.currentTarget as any).value.split(" ");

                let nameFilters;

                if (value.length === 1) {
                  nameFilters = {
                    or: [
                      {
                        firstName: {
                          iLike: `%${value[0]}%`,
                        },
                      },
                      {
                        lastName: {
                          iLike: `%${value[0]}%`,
                        },
                      },
                    ],
                  };
                } else {
                  let array: any = [];

                  value.forEach((a: string) => {
                    const rest = value.filter((b: string) => b !== a);

                    rest.forEach((b: string) => {
                      const more =
                        value
                          .filter((c: string) => c !== a && c !== b)
                          .join(" ") ?? "";

                      array = [
                        ...array,
                        {
                          and: [
                            {
                              firstName: {
                                iLike: `%${a}%`,
                              },
                            },
                            {
                              lastName: {
                                iLike: `%${b}${
                                  more.length > 0 ? " " + more : ""
                                }%`,
                              },
                            },
                          ],
                        },
                        {
                          lastName: {
                            iLike: `%${a} ${b}%`,
                          },
                        },
                      ];
                    });
                  });

                  nameFilters = { or: array };
                }

                fetchData({
                  variables: {
                    filter: {
                      ...nameFilters,
                      and: {
                        isBlockedByAdmin: { isNot: true },
                        isBlockedByAD: { isNot: true },
                      },
                    },
                  },
                });
              }}
              onClose={fetchData}
              overrides={{
                Tag: {
                  component: () => null,
                },
                ...(value?.length > 0 && {
                  Input: {
                    style: {
                      ":empty": {
                        visibility: "visible",
                        height: "auto",
                        overflow: "hidden",
                        "::before": {
                          content: `'Wybrano ${value.length}'`,
                          color: "#666",
                        },
                      },
                    },
                  },
                }),
                ClearIcon: {
                  component: () => null,
                },
                Popover: {
                  props: {
                    placement: "top",
                  },
                },
              }}
              backspaceRemoves={false}
              {...(data && { value: value })}
              {...rest}
            />

            {value?.length > 0 && (
              <Block marginTop="16px" overflow="auto">
                <Table<User>
                  columns={[
                    ...columns,
                    {
                      id: "actions",
                      Cell: ({ row }: { row: Row<User> }) => (
                        <div
                          className={css({
                            display: "flex",
                            justifyContent: "flex-end",
                          })}
                        >
                          <Button
                            kind={KIND.secondary}
                            size={SIZE.mini}
                            $style={{ marginLeft: "6px" }}
                            disabled={isLoading}
                            onClick={() =>
                              onChange(
                                value.filter(
                                  ({ id }: User) => id !== row.original.id
                                )
                              )
                            }
                            startEnhancer={<Trash size={14} />}
                          />
                        </div>
                      ),
                    },
                  ]}
                  data={value}
                  compact
                  stickLastColumn
                  $tableRootStyle={{ maxHeight: "300px" }}
                />
              </Block>
            )}
          </Fragment>
        );
      }}
    />
  );
}

export function UsersSelect({ ...rest }: Props): React.ReactElement {
  const [fetchData, { data, loading }] = useLazyQuery(USERS_SELECT_INDEX);

  useEffect(
    () =>
      fetchData({
        variables: {
          filter: {
            isBlockedByAdmin: { isNot: true },
          },
        },
      }),
    []
  );

  return (
    <LiveSearchSelect
      options={data?.usersPaginated?.nodes.map((user: User) => ({
        label: `${user.firstName} ${user.lastName} ${user.lastName} ${user.firstName}`,
        ...user,
      }))}
      getValueLabel={({ option }) => `${option?.firstName} ${option?.lastName}`}
      getOptionLabel={({ option }) =>
        `${option?.firstName} ${option?.lastName}`
      }
      isLoading={loading}
      onInputChange={(event: FormEvent) => {
        const value = (event.currentTarget as any).value.split(" ");

        let nameFilters;

        if (value.length === 1) {
          nameFilters = {
            or: [
              {
                firstName: {
                  iLike: `%${value[0]}%`,
                },
              },
              {
                lastName: {
                  iLike: `%${value[0]}%`,
                },
              },
            ],
          };
        } else {
          let array: any = [];

          value.forEach((a: string) => {
            const rest = value.filter((b: string) => b !== a);

            rest.forEach((b: string) => {
              const more =
                value.filter((c: string) => c !== a && c !== b).join(" ") ?? "";

              array = [
                ...array,
                {
                  and: [
                    {
                      firstName: {
                        iLike: `%${a}%`,
                      },
                    },
                    {
                      lastName: {
                        iLike: `%${b}${more.length > 0 ? " " + more : ""}%`,
                      },
                    },
                  ],
                },
                {
                  lastName: {
                    iLike: `%${a} ${b}%`,
                  },
                },
              ];
            });
          });

          nameFilters = { or: array };
        }

        fetchData({
          variables: {
            filter: {
              ...nameFilters,
              isBlockedByAdmin: { isNot: true },
            },
          },
        });
      }}
      onClose={fetchData}
      {...rest}
    />
  );
}

export function ControlledPositionsSelect({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & Props): React.ReactElement {
  const { refetch, data, loading } = useQuery(gql`
    query {
      positions(paging: { limit: 100 }) {
        nodes {
          id
          name
        }
      }
    }
  `);

  useEffect(() => data?.positions?.nodes && refetch(), []);

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Select
          id={name}
          options={data?.positions?.nodes?.map((item: Position) => ({
            id: item?.id,
            label: item?.name,
          }))}
          isLoading={loading}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          value={value}
          {...rest}
        />
      )}
    />
  );
}

export function ControlledRolesSelect({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & Props): React.ReactElement {
  const { data, loading } = useQuery(
    gql`
      query {
        roles(paging: { limit: 10000 }) {
          nodes {
            id
            name
          }
        }
      }
    `
  );

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Select
          id={name}
          options={data?.roles?.nodes?.map((item: Role) => ({
            id: item?.id,
            label: item?.name,
          }))}
          isLoading={loading}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          {...(data && { value: value })}
          {...rest}
        />
      )}
    />
  );
}

export const ControlledRolesMultiSelect = (
  props: UseControllerProps<any> & Props
): React.ReactElement => <ControlledRolesSelect multi {...props} />;

export function ControlledJrwaClassificationsSelect({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & Props): React.ReactElement {
  const [css, theme] = useStyletron();
  const { refetch, data, loading } = useQuery(JRWA_CLASSIFICATIONS_INDEX);

  useEffect(() => data?.jrwaClassifications?.nodes && refetch(), []);

  const nestChildrenUnits = (
    parent: JrwaClassification,
    depth: number
  ): Array<Option> => {
    return data?.jrwaClassifications?.nodes?.filter(
      (oU: JrwaClassification) => oU.parentId === parent?.id
    )?.length > 0
      ? (data?.jrwaClassifications?.nodes as JrwaClassification[])
          ?.filter((oU: JrwaClassification) => oU.parentId === parent?.id)
          .sort((a: any, b: any) =>
            a.symbol ? (b.symbol ? (a.symbol < b.symbol ? -1 : 1) : 0) : -1
          )
          .map((item: JrwaClassification) => [
            {
              id: item?.id,
              label: `[${item?.symbol}]  ${item?.name}`,
              depth: depth,
              disabled: !item?.isAssignable,
              isBeneficiaryAddressRequired: item.isBeneficiaryAddressRequired,
              isCaseConductedElectronically: item.isCaseConductedElectronically,
            },
            ...nestChildrenUnits(item, depth + 1),
          ])
          .flat()
      : [];
  };

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => {
        return (
          <Select
            id={name}
            options={(data?.jrwaClassifications?.nodes as JrwaClassification[])
              ?.filter((oU) => !oU.parentId)
              .sort((a, b) =>
                a.symbol ? (b.symbol ? (a.symbol < b.symbol ? -1 : 1) : 0) : -1
              )
              .map((item: JrwaClassification) => [
                {
                  id: item?.id,
                  label: `[${item?.symbol}]  ${item?.name}`,
                  depth: 0,
                  disabled: !item?.isAssignable,
                  isBeneficiaryAddressRequired:
                    item.isBeneficiaryAddressRequired,
                  isCaseConductedElectronically:
                    item.isCaseConductedElectronically,
                },
                ...nestChildrenUnits(item, 1),
              ])
              .flat()}
            getOptionLabel={({ option }) => (
              <Block
                marginLeft={option?.depth && `${(option?.depth - 1) * 24}px`}
                display="flex"
                alignItems="center"
              >
                {option?.depth > 0 && <ChevronDownLeft size={16} />}
                <Block
                  marginLeft={option?.depth && `8px`}
                  color={option?.disabled ? "#777" : "#000"}
                >
                  {option?.isCaseConductedElectronically ? (
                    <Cloud
                      size={14}
                      className={css({
                        verticalAlign: "middle",
                        marginRight: "10px",
                        marginTop: "-2px",
                        display: "inline",
                        color: theme.colors.positive,
                      })}
                    />
                  ) : (
                    <Paperclip
                      size={14}
                      className={css({
                        verticalAlign: "middle",
                        marginRight: "10px",
                        marginTop: "-2px",
                        display: "inline",
                        color: theme.colors.negative,
                      })}
                    />
                  )}
                  {option?.label}
                </Block>
              </Block>
            )}
            isLoading={loading}
            onChange={(params) => {
              params && onChange(params.value);
            }}
            onBlur={onBlur}
            value={value}
            {...rest}
          />
        );
      }}
    />
  );
}

export function RolesSelect({
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & Props): React.ReactElement {
  const { data, loading } = useQuery(
    gql`
      query {
        roles(paging: { limit: 10000 }) {
          nodes {
            id
            name
          }
        }
      }
    `
  );

  return (
    <Select
      options={data?.roles?.nodes?.map((item: Role) => ({
        id: item?.id,
        label: item?.name,
      }))}
      isLoading={loading}
      placeholder="Wybierz"
      {...rest}
    />
  );
}

export function ControlledAddressesSelect({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & Props): React.ReactElement {
  const [, theme] = useStyletron();

  const [fetchAddresses, { data, loading }] = useLazyQuery(
    ADDRESSES_SELECT_INDEX
  );

  useEffect(
    () =>
      fetchAddresses({
        variables: {
          filter: {
            isHidden: {
              is: false,
            },
          },
        },
      }),
    []
  );

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value } }) => (
        <LiveSearchSelect
          options={data?.addressSuggestions?.nodes.map((item: Address) => ({
            id: item?.id,
            label:
              item?.__typename === "Address"
                ? `${item.name}${`${
                    item.streetName ? `, ${item.streetName}` : ""
                  }${item.buildingNumber ? ` ${item.buildingNumber}` : ""}${
                    item.apartmentNumber ? `/${item.apartmentNumber}` : ""
                  }`}${item.postalCode ? `, ${item.postalCode}` : ", b/d"}${
                    item.city ? ` ${item.city}` : ", b/d"
                  }`
                : `${item?.name}`,
            typename: item?.__typename,
          }))}
          isLoading={loading}
          onChange={(params) => {
            params && onChange(params.value);
          }}
          getValueLabel={({ option }) => {
            return (
              <Block display="flex" alignItems="center">
                {option?.typename === "Address" ? (
                  <Building size={16} color={theme.colors.positive} />
                ) : (
                  <Man size={16} color={theme.colors.negative} />
                )}
                <Block marginLeft="10px">{option.label}</Block>
              </Block>
            );
          }}
          getOptionLabel={({ option }) => (
            <Block display="flex" alignItems="center">
              {option?.typename === "Address" ? (
                <Building size={16} color={theme.colors.positive} />
              ) : (
                <Man size={16} color={theme.colors.negative} />
              )}
              <Block marginLeft="10px">{option?.label}</Block>
            </Block>
          )}
          onBlur={onBlur}
          maxDropdownHeight="300px"
          onInputChange={(event: FormEvent) =>
            fetchAddresses({
              variables: {
                filter: {
                  and: [
                    {
                      isHidden: { is: false },
                    },
                    {
                      or: [
                        {
                          name: {
                            iLike: `%${(event.currentTarget as any).value}%`,
                          },
                        },
                        {
                          address: {
                            iLike: `%${(event.currentTarget as any).value}%`,
                          },
                        },
                        {
                          city: {
                            iLike: `%${(event.currentTarget as any).value}%`,
                          },
                        },
                        {
                          postalCode: {
                            iLike: `%${(event.currentTarget as any).value}%`,
                          },
                        },
                      ],
                    },
                  ],
                },
              },
            })
          }
          {...(data && { value: value })}
          {...rest}
        />
      )}
    />
  );
}

export function ControlledCasesSelect({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & Props): React.ReactElement {
  const [fetchCases, { data, loading }] = useLazyQuery(MY_CASES_SELECT_INDEX);

  useEffect(() => fetchCases(), []);

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value } }) => (
        <LiveSearchSelect
          options={data?.myCases?.nodes
            ?.filter((item: Case) => !item?.closedAt)
            ?.map((item: Case) => ({
              label: `[${item?.number}] ${item?.name}`,
              ...item,
            }))}
          getOptionLabel={({ option }) => (
            <Block display="flex" flexDirection="column">
              <MonoLabelXSmall>{option?.number}</MonoLabelXSmall>
              <LabelSmall>{option?.name}</LabelSmall>
            </Block>
          )}
          getValueLabel={({ option }) => (
            <Block display="flex" flexDirection="column">
              <MonoLabelXSmall>{option?.number}</MonoLabelXSmall>
              <LabelSmall>{option?.name}</LabelSmall>
            </Block>
          )}
          isLoading={loading}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          value={value}
          onInputChange={(event: FormEvent) =>
            fetchCases({
              variables: {
                filter: {
                  or: [
                    {
                      number: {
                        iLike: `%${(event.currentTarget as any).value}%`,
                      },
                    },
                    {
                      name: {
                        iLike: `%${(event.currentTarget as any).value}%`,
                      },
                    },
                  ],
                  and: {
                    closedAt: {
                      is: null,
                    },
                  },
                },
              },
            })
          }
          {...rest}
        />
      )}
    />
  );
}

export function CasesSelectForFilters({
  ...rest
}: {
  items?: Case[];
} & Props): React.ReactElement {
  const [fetchCases, { data, loading }] = useLazyQuery(CASES_SELECT_INDEX);

  useEffect(() => fetchCases(), []);

  return (
    <LiveSearchSelect
      options={data?.cases?.nodes
        ?.filter((item: Case) => !item?.closedAt)
        ?.map((item: Case) => ({
          label: `[${item?.number}] ${item?.name}`,
          ...item,
        }))}
      getOptionLabel={({ option }) => (
        <Block display="flex" flexDirection="column">
          <MonoLabelXSmall>{option?.number}</MonoLabelXSmall>
          <LabelSmall>{option?.name}</LabelSmall>
        </Block>
      )}
      getValueLabel={({ option }) => (
        <Block display="flex" flexDirection="column">
          <MonoLabelXSmall>{option?.number}</MonoLabelXSmall>
          <LabelSmall>{option?.name}</LabelSmall>
        </Block>
      )}
      isLoading={loading}
      onInputChange={(event: FormEvent) =>
        fetchCases({
          variables: {
            filter: {
              or: [
                {
                  number: {
                    iLike: `%${(event.currentTarget as any).value}%`,
                  },
                },
                {
                  name: {
                    iLike: `%${(event.currentTarget as any).value}%`,
                  },
                },
              ],
              and: {
                closedAt: {
                  is: null,
                },
              },
            },
          },
        })
      }
      {...rest}
    />
  );
}

export function DocumentsSelectForFilters({
  ...rest
}: {
  items?: Case[];
} & Props): React.ReactElement {
  const [fetchData, { data, loading }] = useLazyQuery(DOCUMENTS_SELECT_INDEX);

  useEffect(() => fetchData(), []);

  const { findValue } = useDictionaries();

  return (
    <LiveSearchSelect
      options={data?.documents?.nodes.map((document: Document) => ({
        label: `${document.internalNumber} ${document.name} ${findValue(
          document?.documentType as string
        )}`,
        ...document,
      }))}
      getValueLabel={({ option }) => (
        <Block display="flex" flexDirection="row">
          <MonoLabelXSmall>{option?.internalNumber}</MonoLabelXSmall>
        </Block>
      )}
      getOptionLabel={({ option }) => {
        return (
          <Block display="flex" flexDirection="column">
            <MonoLabelXSmall>{option?.internalNumber}</MonoLabelXSmall>
            <LabelSmall>{`${findValue(option?.documentType)}${
              option?.name ? ` (${option?.name})` : ""
            }`}</LabelSmall>
          </Block>
        );
      }}
      isLoading={loading}
      onInputChange={(event: FormEvent) => {
        const value = (event.currentTarget as any).value;

        fetchData({
          variables: {
            filter: {
              or: [
                { internalNumber: { iLike: `%${value}%` } },
                { name: { iLike: `%${value}%` } },
              ],
            },
          },
        });
      }}
      {...rest}
    />
  );
}

type DictionaryValuesProps = {
  dictionarySystemName: string | undefined;
  objectId?: number;
  $style?: StyleObject;
  info?: string;
} & SelectProps;

export function ControlledDictionaryValuesSelect({
  control,
  dictionarySystemName,
  name,
  rules,
  info,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & DictionaryValuesProps): React.ReactElement {
  const { refetch, data, loading } = useQuery(DICTIONARIES_SHOW, {
    variables: {
      systemName: dictionarySystemName,
      showHidden: false,
    },
  });

  useEffect(() => data?.dictionary && refetch(), []);

  return control ? (
    <Controller
      control={control}
      name={name}
      rules={rules}
      {...(dictionarySystemName === "countryCodes.alpha2.pl" &&
        info !== "international" && {
          defaultValue: [{ id: "PL" }],
        })}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Select
          id={name}
          options={
            dictionarySystemName === "countryCodes.alpha2.pl"
              ? data?.dictionary?.values.map((item: DictionaryValue) => ({
                  id: item?.value,
                  label: `${item?.name} (${item.value})`,
                }))
              : data?.dictionary?.values.map((item: DictionaryValue) => ({
                  id: item?.value,
                  label: item?.name,
                }))
          }
          isLoading={loading}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          value={value}
          maxDropdownHeight="300px"
          {...rest}
        />
      )}
    />
  ) : (
    <Select
      options={
        dictionarySystemName === "countryCodes.alpha2.pl"
          ? data?.dictionary?.values?.map((item: DictionaryValue) => ({
              id: item?.value,
              label: `${item?.name} (${item.value})`,
            }))
          : data?.dictionary?.values?.map((item: DictionaryValue) => ({
              id: item?.value,
              label: item?.name,
            }))
      }
      isLoading={loading}
      maxDropdownHeight="300px"
      {...rest}
    />
  );
}

export function DictionaryValuesSelect({
  dictionarySystemName,
  ...rest
}: DictionaryValuesProps): React.ReactElement {
  const { refetch, data, loading } = useQuery(DICTIONARIES_SHOW, {
    variables: {
      systemName: dictionarySystemName,
      showHidden: false,
    },
  });

  useEffect(() => data?.dictionary && refetch(), []);

  return (
    <Select
      options={
        dictionarySystemName === "countryCodes.alpha2.pl"
          ? data?.dictionary?.values?.map((item: DictionaryValue) => ({
              id: item?.value,
              label: `${item?.name} (${item.value})`,
            }))
          : data?.dictionary?.values?.map((item: DictionaryValue) => ({
              id: item?.value,
              label: item?.name,
            }))
      }
      isLoading={loading}
      maxDropdownHeight="300px"
      placeholder="Wybierz"
      {...rest}
    />
  );
}

export function ControlledContractsSelect({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & Props): React.ReactElement {
  const CONTRACT_AGREEMENTS = gql`
    query {
      myContractAgreements(
        paging: { limit: 10000 }
        filter: { and: [{ state: { iLike: Accepted } }] }
      ) {
        nodes {
          id
          internalNumber
          description
          contractorAddress {
            __typename
            ... on Address {
              name
            }
            ... on HiddenAddress {
              name
            }
          }
        }
      }
    }
  `;

  const { refetch, data, loading } = useQuery(CONTRACT_AGREEMENTS);

  useEffect(() => data?.myContractAgreements && refetch(), []);

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Select
          id={name}
          options={
            data &&
            data?.myContractAgreements?.nodes?.map(
              (contract: {
                id: number;
                internalNumber: string;
                description: string;
                contractorAddress: Address;
              }) => ({
                id: contract?.id,
                label: `${contract?.internalNumber}${
                  contract?.contractorAddress
                    ? ", " + contract?.contractorAddress?.name
                    : ""
                }${contract?.description ? ", " + contract?.description : ""}`,
              })
            )
          }
          isLoading={loading}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          value={value}
          {...rest}
        />
      )}
    />
  );
}

export function ControlledRequestFormsSelect({
  control,
  name,
  rules,
  withFlowEnded = false,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> &
  Props & { withFlowEnded?: boolean }): React.ReactElement {
  const REQUEST_FORMS = gql`
    query($filter: RequestFormFilter) {
      requestForms(
        paging: { limit: 5, offset: 0 }
        sorting: [
          { field: updatedAt, direction: DESC }
          { field: createdAt, direction: DESC }
        ]
        filter: $filter
      ) {
        nodes {
          id
          documentNumber
          internalNumber
          orderSubject
        }
      }
    }
  `;

  const [fetchData, { data, loading }] = useLazyQuery(REQUEST_FORMS);

  useEffect(
    () =>
      fetchData({
        ...(withFlowEnded && {
          variables: {
            filter: {
              hasDocumentFlowEnded: { is: true },
            },
          },
        }),
      }),
    []
  );

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <LiveSearchSelect
          id={name}
          options={
            data &&
            data?.requestForms?.nodes?.map(
              (requestForm: {
                id: number;
                documentNumber?: string;
                internalNumber?: string;
                orderSubject?: string;
                name?: string;
              }) => ({
                label: `${requestForm?.documentNumber} ${requestForm?.internalNumber} ${requestForm?.orderSubject} ${requestForm?.name}`,
                ...requestForm,
              })
            )
          }
          onInputChange={(event: FormEvent) => {
            const value = (event.currentTarget as any).value;

            fetchData({
              variables: {
                filter: {
                  or: [
                    { internalNumber: { iLike: `%${value}%` } },
                    { documentNumber: { iLike: `%${value}%` } },
                  ],
                  ...(withFlowEnded && {
                    hasDocumentFlowEnded: { is: true },
                  }),
                },
              },
            });
          }}
          onClose={() =>
            fetchData({
              ...(withFlowEnded && {
                variables: {
                  filter: {
                    hasDocumentFlowEnded: { is: true },
                  },
                },
              }),
            })
          }
          getValueLabel={({ option }) => (
            <Block display="flex" flexDirection="column">
              <MonoLabelXSmall>{option?.internalNumber}</MonoLabelXSmall>
              <LabelSmall>{`${option?.documentNumber}`}</LabelSmall>
            </Block>
          )}
          getOptionLabel={({ option }) => {
            return (
              <Block display="flex" flexDirection="column">
                <MonoLabelXSmall>{option?.internalNumber}</MonoLabelXSmall>
                <LabelSmall>{`${option?.documentNumber}`}</LabelSmall>
              </Block>
            );
          }}
          isLoading={loading}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          value={value}
          {...rest}
        />
      )}
    />
  );
}

export function ControlledRequestFormNotesSelect({
  control,
  name,
  rules,
  requestFormId,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> &
  Props & { requestFormId?: string }): React.ReactElement {
  const REQUEST_FORM_NOTES = gql`
    query {
      requestFormNotes(
        paging: { limit: 10000 }
        filter: {
          and: [
            { state: { eq: Accepted } }
            { requestFormId: { eq: ${requestFormId} } }
          ]
        }
      ) {
        nodes {
          id
          internalNumber
          description
        }
      }
    }
  `;

  const { refetch, data, loading } = useQuery(REQUEST_FORM_NOTES);

  useEffect(() => data?.requestFormNotes && refetch(), []);
  useEffect(() => {
    refetch();
  }, [data, requestFormId]);

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Select
          id={name}
          options={
            data &&
            data?.requestFormNotes?.nodes?.map(
              (requestFormNote: {
                id: number;
                internalNumber?: string;
                description: string;
              }) => ({
                id: requestFormNote?.id,
                label: `${requestFormNote?.internalNumber}${
                  requestFormNote?.description
                    ? ", " + requestFormNote?.description
                    : ""
                }`,
              })
            )
          }
          isLoading={loading}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          value={value}
          {...rest}
        />
      )}
    />
  );
}

export function ControlledContractStatusSelect({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & SelectProps): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Select
          id={name}
          options={[
            { id: "Nowa", label: "Nowa" },
            { id: "Realizowana", label: "Realizowana" },
            { id: "Postępowanie", label: "Postępowanie" },
            { id: "Umowa", label: "Umowa" },
            { id: "Dzielona", label: "Dzielona" },
            { id: "Odrzucona", label: "Odrzucona" },
            { id: "Zamknięta", label: "Zamknięta" },
            { id: "Anulowana", label: "Anulowana" },
          ]}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          value={value}
          maxDropdownHeight="300px"
          {...rest}
        />
      )}
    />
  );
}

export function ControlledContractTypeSelect({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & SelectProps): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Select
          id={name}
          options={[
            { id: "Usługa", label: "Usługa" },
            { id: "Dostawa", label: "Dostawa" },
            { id: "Roboty budowlane", label: "Roboty budowlane" },
            { id: "Grant", label: "Grant" },
          ]}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          value={value}
          maxDropdownHeight="300px"
          {...rest}
        />
      )}
    />
  );
}

export function ControlledOrderTypeSelect({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & SelectProps): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Select
          id={name}
          options={[
            { id: "Roboty budowlane", label: "Roboty budowlane" },
            { id: "Dostawy", label: "Dostawy" },
            { id: "Usługi", label: "Usługi" },
          ]}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          value={value}
          maxDropdownHeight="300px"
          {...rest}
        />
      )}
    />
  );
}

export function ControlledCurrencySelect({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & SelectProps): React.ReactElement {
  const { data, loading, refetch } = useQuery(CURRENCIES_SELECT_INDEX, {
    variables: {
      sorting: {
        field: "name",
        direction: "ASC",
      },
      filter: {
        isActive: {
          is: true,
        },
        isVisible: {
          is: true,
        },
      },
    },
  });

  useEffect(() => data?.currencies && refetch(), []);

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Select
          id={name}
          options={data?.currencies?.nodes?.map((currency: Currency) => ({
            id: currency?.code,
            currencyId: currency?.id,
            code: currency?.code,
            label: `${currency?.name} [${currency?.code}]`,
          }))}
          isLoading={loading}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          value={value}
          maxDropdownHeight="300px"
          searchable={false}
          {...rest}
        />
      )}
    />
  );
}

export function ControlledInactiveCurrenciesSelect({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & Props): React.ReactElement {
  const { data, loading, refetch } = useQuery(CURRENCIES_SELECT_INDEX, {
    variables: {
      sorting: {
        field: "name",
        direction: "ASC",
      },
      filter: {
        isActive: {
          is: false,
        },
      },
    },
  });

  useEffect(() => data?.currencies && refetch(), []);

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Select
          id={name}
          options={
            data &&
            data?.currencies?.nodes?.map((value: Currency) => ({
              id: value?.id,
              label: `${value?.name} [${value?.code}]`,
            }))
          }
          isLoading={loading}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          value={value}
          {...rest}
        />
      )}
    />
  );
}

export function ControlledDocumentsSelect({
  control,
  name,
  rules,
  disabled,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & Props): React.ReactElement {
  const [fetchData, { data, loading }] = useLazyQuery(DOCUMENTS_SELECT_INDEX);

  useEffect(() => fetchData(), []);

  const { findValue } = useDictionaries();

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <LiveSearchSelect
          options={data?.documents?.nodes.map((document: Document) => ({
            label: `${document.internalNumber} ${document.name} ${findValue(
              document?.documentType as string
            )}`,
            ...document,
          }))}
          multi={name === "parentIds"}
          getValueLabel={({ option }) => (
            <Block display="flex" flexDirection="row">
              <MonoLabelXSmall
                {...(name === "parentIds" && !disabled && { color: "white" })}
              >
                {option?.internalNumber}
              </MonoLabelXSmall>
            </Block>
          )}
          getOptionLabel={({ option }) => (
            <Block display="flex" flexDirection="column">
              <MonoLabelXSmall>{option?.internalNumber}</MonoLabelXSmall>
              <LabelSmall>{`${findValue(option?.documentType)}${
                option?.name ? ` (${option?.name})` : ""
              }`}</LabelSmall>
            </Block>
          )}
          id={name}
          isLoading={loading}
          onChange={(params) => params && onChange(params.value)}
          onInputChange={(event: FormEvent) => {
            const value = (event.currentTarget as any).value;

            fetchData({
              variables: {
                filter: {
                  or: [
                    { internalNumber: { iLike: `%${value}%` } },
                    { name: { iLike: `%${value}%` } },
                  ],
                },
              },
            });
          }}
          onClose={fetchData}
          onBlur={onBlur}
          value={value}
          disabled={disabled}
          {...rest}
        />
      )}
    />
  );
}

export function ControlledOutgoingDocumentsSelect({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & Props): React.ReactElement {
  const { refetch, data, loading } = useQuery(
    gql`
      query {
        myDocuments(
          paging: { limit: 10000 }
          filter: {
            and: [
              { documentKind: { iLike: Outgoing } }
              { state: { notILike: Canceled } }
              { state: { notILike: Closed } }
            ]
          }
        ) {
          nodes {
            ... on Document {
              id
              documentType
              documentKind
              internalNumber
              state
            }
          }
        }
      }
    `
  );

  useEffect(() => {
    data?.myDocuments && refetch();
  }, []);

  const { findValue } = useDictionaries();

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Select
          id={name}
          options={data?.myDocuments?.nodes?.map((document: Document) => ({
            id: document?.id,
            label: `[${document?.internalNumber}] ${
              document?.documentType ? findValue(document?.documentType) : ""
            }`,
          }))}
          isLoading={loading}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          value={value}
          {...rest}
        />
      )}
    />
  );
}

export function ControlledDocumentsSelectForInvoice({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & Props): React.ReactElement {
  const {
    refetch: documentsRefetch,
    data: documentsData,
    loading: documentLoading,
  } = useQuery(
    gql`
      query {
        myDocuments(
          paging: { limit: 10000 }
          filter: {
            and: [
              { documentType: { notILike: "requestForm" } }
              { documentType: { notILike: "contractAgreement" } }
              { documentType: { notILike: "transferOrder" } }
              { state: { notILike: Canceled } }
            ]
          }
        ) {
          nodes {
            ... on Document {
              id
              documentType
              documentKind
              internalNumber
              state
            }
          }
        }
      }
    `
  );

  const {
    refetch: requestFormsRefetch,
    data: requestFormsData,
    loading: requestFormsLoading,
  } = useQuery(
    gql`
      query {
        myRequestForms(
          paging: { limit: 10000 }
          filter: { state: { iLike: Accepted } }
        ) {
          nodes {
            id
            orderSubject
            documentNumber
            internalNumber
            documentType
            state
            organizationalUnit {
              name
            }
          }
        }
      }
    `
  );

  const {
    refetch: contractAgreementsRefetch,
    data: contractAgreementsData,
    loading: contractAgreementsLoading,
  } = useQuery(
    gql`
      query {
        myContractAgreements(
          paging: { limit: 10000 }
          filter: { state: { iLike: Accepted } }
        ) {
          nodes {
            id
            internalNumber
            description
            documentType
            state
            contractorAddress {
              __typename
              ... on Address {
                name
              }
              ... on HiddenAddress {
                name
              }
            }
          }
        }
      }
    `
  );

  type DocumentForList = {
    id: number;
    label: string;
    internalNumber: string;
    documentType: string;
    documentNumber: string;
    orderSubject?: string;
    description?: string;
    contractorAddress?: Address;
    state?: string;
  };

  const [documentsList, setDocumentList] = useState<DocumentForList[]>();

  const handleDocumentsList = () => {
    setDocumentList([
      ...(requestFormsData
        ? requestFormsData?.myRequestForms?.nodes?.map(
            (document: DocumentForList) => ({
              id: document?.id,
              label: `[${
                document?.documentNumber || document?.internalNumber
              }] ${
                document?.documentType ? findValue(document?.documentType) : ""
              }${document?.orderSubject ? `, ${document?.orderSubject}` : ""}`,
              state: document?.state,
            })
          )
        : []),
      ...(contractAgreementsData
        ? contractAgreementsData?.myContractAgreements?.nodes?.map(
            (document: DocumentForList) => ({
              id: document?.id,
              label: `[${document?.internalNumber}] ${
                document?.documentType ? findValue(document?.documentType) : ""
              }${
                document?.contractorAddress
                  ? `, ${document?.contractorAddress?.name}`
                  : ""
              }${document?.description ? `, ${document?.description}` : ""}`,
              state: document?.state,
            })
          )
        : []),
      ...(documentsData
        ? documentsData?.myDocuments?.nodes?.map(
            (document: DocumentForList) => ({
              id: document?.id,
              label: `[${document?.internalNumber}] ${
                document?.documentType ? findValue(document?.documentType) : ""
              }`,
              state: document?.state,
            })
          )
        : []),
    ]);
  };

  useEffect(() => {
    documentsData?.myDocuments && documentsRefetch();
    requestFormsData?.requestForms && requestFormsRefetch();
    contractAgreementsData?.contractAgreements && contractAgreementsRefetch();
  }, []);

  useEffect(() => handleDocumentsList(), [
    documentsData,
    requestFormsData,
    contractAgreementsData,
  ]);

  const { findValue } = useDictionaries();

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Select
          id={name}
          options={documentsList?.map((item: DocumentForList) => ({
            id: item?.id,
            label: item?.label,
          }))}
          isLoading={
            documentLoading || requestFormsLoading || contractAgreementsLoading
          }
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          value={value}
          {...rest}
        />
      )}
    />
  );
}

export function RequestFormsSelect({
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
Props): React.ReactElement {
  const REQUEST_FORMS = gql`
    query($filter: RequestFormFilter) {
      requestForms(
        paging: { limit: 5, offset: 0 }
        sorting: [
          { field: updatedAt, direction: DESC }
          { field: createdAt, direction: DESC }
        ]
        filter: $filter
      ) {
        nodes {
          id
          documentNumber
          internalNumber
          orderSubject
          name
        }
      }
    }
  `;

  const [fetchData, { data, loading }] = useLazyQuery(REQUEST_FORMS);

  useEffect(
    () =>
      fetchData({
        variables: {
          filter: {
            hasDocumentFlowEnded: { is: true },
          },
        },
      }),
    []
  );

  return (
    <LiveSearchSelect
      options={
        data &&
        data?.requestForms?.nodes?.map(
          (requestForm: {
            id: number;
            documentNumber?: string;
            internalNumber?: string;
            orderSubject?: string;
            name?: string;
          }) => ({
            label: `${requestForm?.documentNumber} ${requestForm?.internalNumber} ${requestForm?.orderSubject} ${requestForm?.name}`,
            internalNumber: requestForm?.internalNumber,
            documentNumber: requestForm?.documentNumber,
            ...requestForm,
          })
        )
      }
      onInputChange={(event: FormEvent) => {
        const value = (event.currentTarget as any).value;

        fetchData({
          variables: {
            filter: {
              or: [
                { internalNumber: { iLike: `%${value}%` } },
                { documentNumber: { iLike: `%${value}%` } },
              ],
              hasDocumentFlowEnded: { is: true },
            },
          },
        });
      }}
      onClose={() =>
        fetchData({
          variables: {
            filter: {
              hasDocumentFlowEnded: { is: true },
            },
          },
        })
      }
      isLoading={loading}
      placeholder="Wybierz"
      $style={{ width: "300px" }}
      getValueLabel={({ option }) => (
        <Block display="flex" flexDirection="column">
          <MonoLabelXSmall>{option?.internalNumber}</MonoLabelXSmall>
          <LabelSmall>{`${option?.documentNumber}`}</LabelSmall>
        </Block>
      )}
      getOptionLabel={({ option }) => (
        <Block display="flex" flexDirection="column">
          <MonoLabelXSmall>{option?.internalNumber}</MonoLabelXSmall>
          <LabelSmall>{`${option?.documentNumber}`}</LabelSmall>
        </Block>
      )}
      {...rest}
    />
  );
}

export function ControlledInvoicesSelect({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & Props): React.ReactElement {
  const { refetch, data, loading } = useQuery(
    gql`
      query {
        invoices(
          paging: { limit: 1000 }
          filter: {
            and: [
              { state: { notILike: Canceled } }
              { state: { notILike: Closed } }
            ]
          }
        ) {
          nodes {
            id
            documentNumber
            internalNumber
            documentDate
            grossValue
            sender {
              __typename
              ... on Address {
                id
                name
              }
              ... on HiddenAddress {
                id
                name
              }
            }
          }
        }
      }
    `
  );

  useEffect(() => {
    data?.financialAccountingDocuments && refetch();
  }, []);

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Select
          id={name}
          options={data?.financialAccountingDocuments?.nodes?.map(
            (
              financialAccountingDocument: Document & {
                grossValue: number;
                currency: string;
              }
            ) => ({
              id: financialAccountingDocument?.id,
              label: `[${
                financialAccountingDocument?.internalNumber
              }] - faktura ${
                financialAccountingDocument.documentNumber
                  ? "nr: " + financialAccountingDocument.documentNumber
                  : ""
              } ${
                financialAccountingDocument.documentDate
                  ? "z dnia: " +
                    new Date(
                      financialAccountingDocument.documentDate
                    ).toLocaleDateString()
                  : ""
              } ${
                financialAccountingDocument.grossValue
                  ? "na kwotę " +
                    financialAccountingDocument.grossValue +
                    " PLN"
                  : ""
              } ${
                financialAccountingDocument.sender
                  ? "wystawiona przez: " +
                    financialAccountingDocument.sender.name
                  : ""
              }`,
            })
          )}
          isLoading={loading}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          value={value}
          {...rest}
        />
      )}
    />
  );
}

export function ControlledOriginalInvoicesSelect({
  control,
  name,
  rules,
  documentId,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & Props & { documentId?: number }): React.ReactElement {
  const { refetch, data, loading } = useQuery(
    gql`
      query {
        myDocuments(
          paging: { limit: 10000 }
          filter: {
            and: [
              { documentType: { iLike: "invoice" } }
              { state: { notILike: Canceled } }
              { state: { notILike: Closed } }
              { state: { notILike: New } }
              { invoiceType: { iLike: Original } }
            ]
          }
        ) {
          nodes {
            ... on Document {
              id
              documentType
              documentKind
              internalNumber
              state
            }
            ... on FinancialAccountingDocument {
              id
              documentType
              documentKind
              internalNumber
              state
            }
          }
        }
      }
    `
  );

  useEffect(() => {
    data?.myDocuments && refetch();
  }, []);

  const { findValue } = useDictionaries();

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Select
          id={name}
          options={data?.myDocuments?.nodes?.map((document: Document) => ({
            id: document?.id,
            label: `[${document?.internalNumber}] ${
              document?.documentType ? findValue(document?.documentType) : ""
            }`,
          }))}
          isLoading={loading}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          value={value}
          filterOptions={(options, filterValue) =>
            options.filter(
              (value: Option) =>
                documentId !== value.id &&
                value?.label
                  ?.toString()
                  .toLocaleLowerCase()
                  .includes(filterValue.toLocaleLowerCase())
            )
          }
          {...rest}
        />
      )}
    />
  );
}

export type RequestFormItemsSelectProps = {
  requestFormItems: RequestFormItem[];
};

export function RequestFormItemsSelect({
  requestFormId,
  ...rest
}: SelectProps & { requestFormId?: number | null }): React.ReactElement {
  const { data, loading } = useQuery(REQUEST_FORM_SELECT_SHOW, {
    skip: !requestFormId,
    variables: {
      id: requestFormId,
    },
    partialRefetch: true,
  });

  return (
    <Select
      options={
        requestFormId
          ? data?.flowEndedRequestForm?.requestFormItems?.map(
              (item: RequestFormItem) => ({
                id: item.id,
                label: `[${item.budgetSymbol || item.year}]${
                  item.categoryPath ? `, ${item.categoryPath}` : ""
                }${item.categoryName ? `, ${item.categoryName}` : ""}${
                  item.budgetName ? `, ${item.budgetName}` : ""
                }`,
              })
            )
          : []
      }
      maxDropdownHeight="300px"
      isLoading={loading}
      placeholder="Wybierz"
      $style={{ width: "500px" }}
      getValueLabel={({ option }) => {
        return (
          <Block display="flex" flexDirection="column">
            <LabelSmall>{option?.label}</LabelSmall>
          </Block>
        );
      }}
      {...rest}
    />
  );
}

export function ControlledDocumentTypesSelect({
  control,
  documentKind,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> &
  Props & { documentKind?: string }): React.ReactElement {
  const { refetch, data, loading } = useQuery(DICTIONARIES_VALUES);

  useEffect(() => data?.dictionaryValues && refetch(), []);
  useEffect(() => {
    refetch();
  }, [documentKind]);

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Select
          id={name}
          options={
            data &&
            data?.dictionaryValues
              ?.filter(
                (value: DictionaryValue) =>
                  (documentKind === "Internal" &&
                    value?.dictionary?.systemName?.includes(
                      "documentTypes.internal"
                    )) ||
                  (documentKind === "Incoming" &&
                    value?.dictionary?.systemName?.includes(
                      "documentTypes.incoming"
                    )) ||
                  (documentKind === "Outgoing" &&
                    value?.dictionary?.systemName?.includes(
                      "documentTypes.outgoing"
                    ))
              )
              .map((value: DictionaryValue) => ({
                id: value?.value,
                label: value?.name,
              }))
          }
          isLoading={loading}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          value={value}
          {...rest}
        />
      )}
    />
  );
}

// bez dokumentów, dla ktorych jest dedykowany formularz
export function ControlledInternalDocumentTypesSelect({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> &
  Props & { documentKind?: string }): React.ReactElement {
  const { refetch, data, loading } = useQuery(DICTIONARIES_VALUES);

  useEffect(() => data?.dictionaryValues && refetch(), []);

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Select
          id={name}
          options={
            data &&
            data?.dictionaryValues
              ?.filter((value: DictionaryValue) =>
                value?.dictionary?.systemName?.includes(
                  "documentTypes.internal"
                )
              )
              ?.filter(
                (value: DictionaryValue) =>
                  value?.value !== "requestForm" &&
                  value?.value !== "billIssuanceRequest" &&
                  value?.value !== "contractPreparationRequest" &&
                  value?.value !== "invoice" &&
                  value?.value !== "transferOrder" &&
                  value?.value !== "requestFormNote"
              )
              .map((value: DictionaryValue) => ({
                id: value?.value,
                label: value?.name,
              }))
          }
          isLoading={loading}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          value={value}
          {...rest}
        />
      )}
    />
  );
}

export function DocumentTypesSelect({ ...rest }: Props): React.ReactElement {
  const { refetch, data, loading } = useQuery(DICTIONARIES_VALUES);

  useEffect(() => data?.dictionaryValues && refetch(), []);

  const documentTypes = data?.dictionaryValues?.filter(
    (value: DictionaryValue) =>
      (value?.dictionary?.systemName?.includes("documentTypes.internal") ||
        value?.dictionary?.systemName?.includes("documentTypes.incoming") ||
        value?.dictionary?.systemName?.includes("documentTypes.outgoing")) &&
      value.value !== "transferOrder"
  );

  const uniqeDocumentTypes = [
    ...new Map(
      documentTypes?.map((item: DictionaryValue) => [
        JSON.stringify(item.name),
        item,
      ])
    ).values(),
  ];

  return (
    <Select
      options={
        data &&
        uniqeDocumentTypes?.map((value: any) => ({
          id: value?.value,
          label: value?.name,
        }))
      }
      multi
      isLoading={loading}
      placeholder="Wybierz"
      {...rest}
    />
  );
}

export function ControlledDocumentKindsSelect({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & Props): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Select
          id={name}
          options={[
            { id: "Internal", label: "Wewnętrzny" },
            { id: "Incoming", label: "Przychodzący" },
            { id: "Outgoing", label: "Wychodzący" },
          ]}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          value={value}
          {...rest}
        />
      )}
    />
  );
}

export function DocumentKindsSelect({
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & Props): React.ReactElement {
  return (
    <Select
      options={[
        { id: "Internal", label: "Wewnętrzny" },
        { id: "Incoming", label: "Przychodzący" },
        { id: "Outgoing", label: "Wychodzący" },
      ]}
      placeholder="Wybierz"
      {...rest}
    />
  );
}

export function DocumentPickupStateSelect({
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & Props): React.ReactElement {
  return (
    <Select
      options={DOCUMENT_PICKUP_STATE_OPTIONS}
      placeholder="Wybierz"
      $style={{ width: "200px" }}
      {...rest}
    />
  );
}

export type GroupSelectProps = {
  groups: string[];
};

export function GroupsSelect({ disabled, ...rest }: Props): React.ReactElement {
  const { refetch, data, loading } = useQuery(gql`
    query {
      groups(paging: { limit: 100 }) {
        nodes {
          id
          name
        }
      }
    }
  `);

  useEffect(() => data?.groups?.nodes && refetch(), []);

  return (
    <Select
      options={data?.groups?.nodes?.map(({ id, name }: Group) => ({
        id,
        label: name,
      }))}
      getValueLabel={({ option }) => `${option?.label}`}
      getOptionLabel={({ option }) => `${option?.label}`}
      disabled={disabled}
      isLoading={loading}
      {...rest}
    />
  );
}

export function ControlledGroupsSelect({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & Props): React.ReactElement {
  const { refetch, data, loading } = useQuery(gql`
    query {
      groups(paging: { limit: 100 }) {
        nodes {
          id
          name
        }
      }
    }
  `);

  useEffect(() => data?.groups?.nodes && refetch(), []);

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Select
          id={name}
          options={data?.groups?.nodes?.map((item: Group) => ({
            id: item?.id,
            label: item?.name,
          }))}
          isLoading={loading}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          value={value}
          {...rest}
        />
      )}
    />
  );
}

export function ControlledCaseGroupsSelect({
  control,
  name,
  rules,
  groups,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & SelectProps & GroupSelectProps): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Select
          id={name}
          options={groups?.map((group: string, index: number) => ({
            id: index + 1,
            label: `${index + 1}. ${group}`,
          }))}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          value={value}
          maxDropdownHeight="300px"
          {...rest}
        />
      )}
    />
  );
}

export type ParcelSize = {
  id: string;
  width: number;
  length: number;
  height: number;
};

export function ControlledParcelSizesSelect({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & SelectProps): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Select
          id={name}
          options={PARCEL_SIZES.map((size: ParcelSize) => ({
            id: size.id,
            label: `${size.id} (${size.width}mm x ${size.length}mm x ${size.height}mm)`,
          }))}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          value={value}
          maxDropdownHeight="300px"
          {...rest}
        />
      )}
    />
  );
}

export function ControlledENadawcaParcelSelect({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & SelectProps): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Select
          id={name}
          options={ENADAWCA_PARCELS}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          value={value}
          maxDropdownHeight="300px"
          {...rest}
        />
      )}
    />
  );
}

export function ControlledENadawcaParcelCategoriesSelect({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & SelectProps): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={[{ id: "EKONOMICZNA", label: "Ekonomiczna" }]}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Select
          id={name}
          options={ENADAWCA_PARCEL_CATEGORIES}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          value={value}
          maxDropdownHeight="300px"
          {...rest}
        />
      )}
    />
  );
}

export function ControlledENadawcaParcelOverallDimensionsSelect({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & SelectProps): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Select
          id={name}
          options={ENADAWCA_PARCEL_OVERALL_DIMENSIONS}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          value={value}
          maxDropdownHeight="300px"
          {...rest}
        />
      )}
    />
  );
}

export function ControlledENadawcaFormatSelect({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & SelectProps): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Select
          id={name}
          options={ENADAWCA_FORMAT}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          value={value}
          maxDropdownHeight="300px"
          {...rest}
        />
      )}
    />
  );
}

export function ControlledDeklaracjaCelnaRodzajSelect({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & SelectProps): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Select
          id={name}
          options={DEKLARACJA_CELNA_RODZAJ}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          value={value}
          maxDropdownHeight="300px"
          {...rest}
        />
      )}
    />
  );
}

export function ControlledZawartoscPrzesylkiSelect({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & SelectProps): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Select
          id={name}
          options={ZAWARTOSC_PRZESYLKI}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          value={value}
          maxDropdownHeight="300px"
          {...rest}
        />
      )}
    />
  );
}

export function ControlledDokumentyTowarzyszaceRodzajSelect({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & SelectProps): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Select
          id={name}
          options={DOKUMENTY_TOWARZYSZACE_RODZAJ}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          value={value}
          maxDropdownHeight="300px"
          {...rest}
        />
      )}
    />
  );
}

export function ControlledENadawcaAddressTypesSelect({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & SelectProps): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Select
          id={name}
          options={ENADAWCA_ADDRESS_TYPES}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          value={value}
          maxDropdownHeight="300px"
          {...rest}
        />
      )}
    />
  );
}

export function ControlledSendingListsSelect({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & Props): React.ReactElement {
  const [firstRender, setFirstRender] = useState(true);

  const { data, loading, refetch } = useQuery(
    gql`
      query {
        eNadawcaSendingLists(
          paging: { limit: 1000 }
          sorting: { field: id, direction: DESC }
          filter: { isSent: { isNot: true } }
        ) {
          nodes {
            id
            eNadawcaUrzadNadania
            isSent
          }
        }
      }
    `
  );

  useEffect(() => {
    data?.eNadawcaSendingLists && refetch();
    setFirstRender(true);
  }, []);

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => {
        if (
          data &&
          (!value ||
            value?.[0]?.id !== data?.eNadawcaSendingLists?.nodes?.[0]?.id) &&
          data?.eNadawcaSendingLists?.nodes?.[0]?.id &&
          firstRender
        ) {
          setFirstRender(false);
          onChange([
            {
              id: data?.eNadawcaSendingLists?.nodes?.[0]?.id,
              label: `Lista wysyłkowa ${data?.eNadawcaSendingLists?.nodes?.[0]?.id}`,
            },
          ]);
        }

        return (
          <Select
            id={name}
            options={data?.eNadawcaSendingLists?.nodes?.map(
              (item: { id: number; eNadawcaUrzadNadania: string }) => ({
                id: item?.id,
                label: `Lista wysyłkowa ${item.id}`,
              })
            )}
            isLoading={loading}
            onChange={(params) => params && onChange(params.value)}
            onBlur={onBlur}
            {...(data && { value: value })}
            {...rest}
          />
        );
      }}
    />
  );
}

type ShipmentSelectProps = {
  data: {
    id: number;
    name: string;
    price: number;
  }[];
};

export function ControlledShipmentRatesSelect({
  control,
  data,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & Props & ShipmentSelectProps): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Select
          id={name}
          options={data?.map(
            (item: { id: number; name: string; price: number }) => ({
              id: item?.id,
              label: `${item.name}, cena: ${item.price + " PLN"}`,
            })
          )}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          creatable={false}
          searchable={false}
          clearable={false}
          value={value}
          {...rest}
        />
      )}
    />
  );
}

type ParcelSelectProps = {
  data: {
    id: number;
    shipmentRate: {
      id: number;
      name: string;
      price: number;
      eNadawcaShipmentType?: string;
    };
  }[];
};

export function ControlledDocumentParcelSelect({
  control,
  data,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & Props & ParcelSelectProps): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Select
          id={name}
          options={data
            ?.filter(
              (item) => !!item?.shipmentRate?.name && !!item.shipmentRate?.price
            )
            ?.map(
              (item: {
                id: number;
                shipmentRate: {
                  id: number;
                  name: string;
                  price: number;
                  eNadawcaShipmentType?: string;
                };
              }) => ({
                id: `${item?.id}/${item?.shipmentRate?.id}`,
                label: `List nadany ${item?.id}, ${
                  item?.shipmentRate?.name
                }, cena: ${item?.shipmentRate?.price + " PLN"}`,
                eNadawcaShipmentType: item?.shipmentRate?.eNadawcaShipmentType,
              })
            )}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          creatable={false}
          searchable={false}
          clearable={false}
          value={value}
          {...rest}
        />
      )}
    />
  );
}

type StepSelectProps = {
  documentFlowId: number | null;
  disabledId?: number;
};

export function ControlledStepsSelect({
  control,
  documentFlowId,
  name,
  rules,
  disabledId,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & Props & StepSelectProps): React.ReactElement {
  const { refetch, data, loading } = useQuery(DOCUMENT_FLOW, {
    variables: { id: documentFlowId },
  });
  const steps = data?.documentFlow?.steps;

  useEffect(() => data?.documentFlow && refetch(), []);

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Select
          id={name}
          options={steps?.map((step: DocumentFlowStep, index: number) => ({
            id: step.id,
            label: `${index + 1}. ${step.name}`,
            ...(disabledId === step.id && { disabled: true }),
          }))}
          isLoading={loading}
          placeholder="Wybierz"
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          value={value}
          clearable={false}
          {...rest}
        />
      )}
    />
  );
}

type BudgetsSelectProps = {
  budgetId?: number;
  budgetCategoryId?: number;
} & Props;

export function BudgetsSelect({
  budgetCategoryId,
  items,
  ...rest
}: { items?: BudgetItem[] } & BudgetsSelectProps): React.ReactElement {
  const { loading } = useQuery<{ budgets: Budget[] }>(BUDGETS_INDEX, {
    skip: true,
  });

  return (
    <Select
      options={items
        ?.filter(
          (budgetItem) => budgetItem.budgetCategoryId === budgetCategoryId
        )
        ?.map((budgetItem) => ({
          ...budgetItem.budget,
          label: budgetItem?.budget?.name,
          budgetItemId: budgetItem.id,
        }))}
      getOptionLabel={({ option }) => option?.name}
      getValueLabel={({ option }) => option?.name}
      isLoading={loading}
      placeholder="Wybierz"
      $style={{ width: "200px" }}
      {...rest}
    />
  );
}

export function ControlledFundingSourceSelect({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & SelectProps): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Select
          id={name}
          options={EXAMPLE_FUNDING_SOURCES.map((source: string) => ({
            label: source,
            id: source,
          }))}
          placeholder="Wybierz"
          creatable
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          value={value}
          {...rest}
        />
      )}
    />
  );
}

export function BudgetSymbolsSelect({
  financialPlanId,
  ...rest
}: {
  financialPlanId?: number;
} & BudgetsSelectProps): React.ReactElement {
  const { data, refetch, loading } = useQuery(FINANCIAL_PLAN_ITEMS_SELECT, {
    skip: !financialPlanId,
    variables: {
      filter: {
        financialPlanId: {
          eq: financialPlanId,
        },
      },
    },
  });

  useEffect(() => {
    refetch();
  }, [financialPlanId]);

  useEffect(() => {
    if (data) refetch();
  }, []);

  const items = data?.financialPlanItems?.nodes?.map(
    (item: FinancialPlanItem) => ({
      id: item?.budgetSymbol,
      label: item?.budgetSymbol,
    })
  );

  type Option = {
    id: string;
    label: string;
  };

  return (
    <Select
      options={items?.filter(
        (a: Option, index: number) =>
          items.findIndex((b: Option) => b.id === a.id) === index
      )}
      isLoading={loading}
      placeholder="Wybierz"
      $style={{ width: "300px" }}
      {...rest}
    />
  );
}

export function BudgetCategoriesSelect({
  financialPlanId,
  budgetSymbol,
  isProjectBudget,
  ...rest
}: {
  financialPlanId?: number;
  budgetSymbol?: string;
  isProjectBudget?: boolean;
} & BudgetsSelectProps): React.ReactElement {
  const { data, refetch, loading } = useQuery(FINANCIAL_PLAN_ITEMS_SELECT, {
    skip: !financialPlanId || !budgetSymbol || isProjectBudget,
    variables: {
      filter: {
        and: [
          {
            financialPlanId: {
              eq: financialPlanId,
            },
          },
          {
            budgetSymbol: {
              iLike: `%${budgetSymbol}%`,
            },
          },
        ],
      },
    },
  });

  useEffect(() => {
    refetch();
  }, [financialPlanId]);

  useEffect(() => {
    if (data) refetch();
  }, []);

  const items = data?.financialPlanItems?.nodes?.map(
    (item: FinancialPlanItem) => ({
      id: item?.categoryPath,
      categoryPath: item?.categoryPath,
      categoryName: item?.categoryName,
    })
  );

  type Option = {
    id: string;
    label: string;
  };

  return (
    <Select
      options={items?.filter(
        (a: Option, index: number) =>
          items.findIndex((b: Option) => b.id === a.id) === index
      )}
      getOptionLabel={({ option }) => (
        <Block display="flex" flexDirection="column">
          <MonoLabelXSmall>{option?.categoryPath}</MonoLabelXSmall>
          <LabelSmall>{option?.categoryName}</LabelSmall>
        </Block>
      )}
      getValueLabel={({ option }) => (
        <Block display="flex" flexDirection="column">
          <MonoLabelXSmall>{option?.categoryPath}</MonoLabelXSmall>
          <LabelSmall>{option?.categoryName}</LabelSmall>
        </Block>
      )}
      isLoading={loading}
      placeholder="Wybierz"
      $style={{ width: "300px" }}
      {...rest}
    />
  );
}

export function BudgetNamesSelect({
  financialPlanId,
  categoryPath,
  budgetSymbol,
  ...rest
}: {
  financialPlanId?: number;
  categoryPath?: string;
  budgetSymbol?: string;
} & BudgetsSelectProps): React.ReactElement {
  const { data, refetch, loading } = useQuery(FINANCIAL_PLAN_ITEMS_SELECT, {
    skip: !financialPlanId || !categoryPath || !budgetSymbol,
    variables: {
      filter: {
        and: [
          {
            financialPlanId: {
              eq: financialPlanId,
            },
          },
          {
            budgetSymbol: {
              iLike: `%${budgetSymbol}%`,
            },
          },
          {
            categoryPath: {
              iLike: `%${categoryPath}%`,
            },
          },
        ],
      },
    },
  });

  useEffect(() => {
    refetch();
  }, [financialPlanId]);

  useEffect(() => {
    if (data) refetch();
  }, []);

  const items = data?.financialPlanItems?.nodes?.map(
    (item: FinancialPlanItem) => ({
      id: item?.budgetName,
      label: item?.budgetName,
      amount: item?.amount,
    })
  );

  type Option = {
    id: string;
    label: string;
  };

  return (
    <Select
      options={items?.filter(
        (a: Option, index: number) =>
          items.findIndex((b: Option) => b.id === a.id) === index
      )}
      isLoading={loading}
      placeholder="Wybierz"
      $style={{ width: "300px" }}
      {...rest}
    />
  );
}

export function BudgetYearsSelect({ ...rest }: Props): React.ReactElement {
  const [css, theme] = useStyletron();

  const { data, refetch, loading } = useQuery(FINANCIAL_PLANS_IN_RANGE);

  const options = data?.financialPlansInRange?.map(
    (item: { year: string; financialPlan: FinancialPlan }) => ({
      id: item?.financialPlan?.id || item?.year,
      label: item?.year,
      isActive: item?.financialPlan?.isActive,
      name: item?.financialPlan?.name,
    })
  );

  useEffect(() => data?.financialPlansInRange && refetch(), []);

  return (
    <Select
      options={options}
      getOptionLabel={({ option }) => (
        <div
          className={css({
            display: "flex",
            gap: "6px",
            alignItems: "center",
          })}
        >
          <LabelSmall>{option?.label}</LabelSmall>

          <div
            className={css({
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              background: option?.isActive
                ? theme.colors.positive
                : theme.colors.negative,
            })}
          />
        </div>
      )}
      getValueLabel={({ option }) => {
        return (
          <div
            className={css({
              display: "flex",
              gap: "6px",
              alignItems: "center",
            })}
          >
            <LabelSmall>{option?.label}</LabelSmall>

            <div
              className={css({
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                background: option?.isActive
                  ? theme.colors.positive
                  : theme.colors.negative,
              })}
            />
          </div>
        );
      }}
      isLoading={loading}
      placeholder="Wybierz"
      $style={{ width: "150px" }}
      {...rest}
    />
  );
}

export function OrganizationalUnitsSelect({
  ...rest
}: {
  items?: OrganizationalUnit[];
} & BudgetsSelectProps): React.ReactElement {
  const { user } = useAuth();
  const { data, loading } = useQuery<{
    organizationalUnits: { nodes: OrganizationalUnit[] };
  }>(ORGANIZATIONAL_UNITS_INDEX);

  return (
    <Select
      isLoading={loading}
      options={[
        !!user?.organizationalUnitToUsers && {
          groupLabel: "Moje",
          disabled: true,
        },
        !!user?.organizationalUnitToUsers &&
          user?.organizationalUnitToUsers?.map(
            (item: {
              organizationalUnit: {
                id: number;
                name: string;
                symbol: string;
                budgetSymbol?: string;
              };
            }) => ({
              id: item.organizationalUnit.id,
              label: `${item?.organizationalUnit.symbol} ${item?.organizationalUnit?.budgetSymbol} ${item?.organizationalUnit?.name}`,
              symbol: item?.organizationalUnit?.symbol,
              budgetSymbol: item?.organizationalUnit?.budgetSymbol,
              name: item?.organizationalUnit?.name,
            })
          ),
        !!data?.organizationalUnits?.nodes?.length && {
          groupLabel: "Wszystkie",
          disabled: true,
        },
        !!data?.organizationalUnits?.nodes?.length &&
          data.organizationalUnits.nodes
            .filter(
              (item) =>
                !user?.organizationalUnitToUsers
                  ?.map(
                    ({
                      organizationalUnit,
                    }: {
                      organizationalUnit: OrganizationalUnit;
                    }) => organizationalUnit.id
                  )
                  .includes(item.id)
            )
            .map((item) => ({
              id: item.id,
              label: `${item?.symbol} ${item?.budgetSymbol} ${item?.name}`,
              symbol: item?.symbol,
              budgetSymbol: item?.budgetSymbol,
              name: item?.name,
            })),
      ].flat()}
      getOptionLabel={({ option }) =>
        option?.groupLabel ? (
          <Block
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <LabelXSmall
              $style={{
                textTransform: "uppercase",
                fontWeight: 600,
                flexShrink: 0,
                color: "#777777",
              }}
            >
              {option.groupLabel}
            </LabelXSmall>
          </Block>
        ) : (
          <Block display="flex" flexDirection="column">
            <Fragment>
              <MonoLabelXSmall>{option?.budgetSymbol}</MonoLabelXSmall>
              <LabelSmall>{`${option?.symbol ? `[${option?.symbol}] ` : ""}${
                option?.name
              }`}</LabelSmall>
            </Fragment>
          </Block>
        )
      }
      getValueLabel={({ option }) => (
        <Block display="flex" flexDirection="column">
          <MonoLabelXSmall>{option?.budgetSymbol}</MonoLabelXSmall>
          <LabelSmall>{`${option?.symbol ? `[${option?.symbol}] ` : ""}${
            option?.name
          }`}</LabelSmall>
        </Block>
      )}
      placeholder="Wybierz"
      $style={{ width: "300px" }}
      {...rest}
    />
  );
}

export function OrganizationalUnitsSelectForFilters({
  ...rest
}: {
  items?: OrganizationalUnit[];
} & Props): React.ReactElement {
  const { data, loading } = useQuery<{
    organizationalUnits: { nodes: OrganizationalUnit[] };
  }>(ORGANIZATIONAL_UNITS_INDEX);

  return (
    <Select
      isLoading={loading}
      options={data?.organizationalUnits?.nodes.map((item) => ({
        id: item.id,
        label: `${item?.symbol} ${item?.budgetSymbol} ${item?.name}`,
        symbol: item?.symbol,
        budgetSymbol: item?.budgetSymbol,
        name: item?.name,
      }))}
      getOptionLabel={({ option }) =>
        option?.groupLabel ? (
          <Block
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <LabelXSmall
              $style={{
                textTransform: "uppercase",
                fontWeight: 600,
                flexShrink: 0,
                color: "#777777",
              }}
            >
              {option.groupLabel}
            </LabelXSmall>
          </Block>
        ) : (
          <Block display="flex" flexDirection="column">
            <Fragment>
              <MonoLabelXSmall>{option?.budgetSymbol}</MonoLabelXSmall>
              <LabelSmall>{`${option?.symbol ? `[${option?.symbol}] ` : ""}${
                option?.name
              }`}</LabelSmall>
            </Fragment>
          </Block>
        )
      }
      getValueLabel={({ option }) => (
        <Block display="flex" flexDirection="column">
          <MonoLabelXSmall>{option?.budgetSymbol}</MonoLabelXSmall>
          <LabelSmall>{`${option?.symbol ? `[${option?.symbol}] ` : ""}${
            option?.name
          }`}</LabelSmall>
        </Block>
      )}
      placeholder="Wybierz"
      {...rest}
    />
  );
}

export function ControlledShipmentsApiSelect({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & SelectProps): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Select
          id={name}
          options={[
            { id: 1, label: "Poczta Polska eNadawca API" },
            { id: 2, label: "Global Express API", disabled: true },
          ]}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          value={value}
          maxDropdownHeight="300px"
          clearable={false}
          {...rest}
        />
      )}
    />
  );
}

export function ControlledPaymentTypesSelect({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & SelectProps): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Select
          id={name}
          options={[
            { id: "PrivateCreditCard", label: "Karta kredytowa prywatna" },
            {
              id: "CorporateCreditCard",
              label: "Karta kredytowa służbowa",
            },
            { id: "Transfer", label: "Przelew" },
            { id: "Cash", label: "Gotówka" },
          ]}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          value={value}
          maxDropdownHeight="300px"
          {...rest}
        />
      )}
    />
  );
}

export function ControlledInternalAccountsSelect({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & SelectProps): React.ReactElement {
  const { data, loading, refetch } = useQuery(INTERNAL_ACCOUNTS_SELECT_INDEX);

  useEffect(() => data?.internalAccounts && refetch(), []);

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Select
          id={name}
          options={data?.internalAccounts?.nodes?.map(
            (internalAccount: InternalAccount) => ({
              label: internalAccount?.name,
              id: internalAccount?.id,
            })
          )}
          isLoading={loading}
          placeholder="Wybierz"
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          value={value}
          {...rest}
        />
      )}
    />
  );
}

export function InternalAccountsSelect({
  ...rest
}: SelectProps): React.ReactElement {
  const { data, loading } = useQuery(INTERNAL_ACCOUNTS_SELECT_INDEX);

  return (
    <Select
      options={data?.internalAccounts?.nodes?.map(
        (internalAccount: InternalAccount) => ({
          label: `${internalAccount?.name}, ${internalAccount?.fundingSource}`,
          ...internalAccount,
        })
      )}
      clearable={false}
      isLoading={loading}
      placeholder="Wybierz"
      $style={{ width: "200px" }}
      {...rest}
    />
  );
}

export function DivisorTemplatesSelect({
  ...rest
}: SelectProps): React.ReactElement {
  const { data, loading, refetch } = useQuery(DIVISOR_TEMPLATES_SELECT_INDEX);

  useEffect(() => data?.divisorTemplates && refetch(), []);

  return (
    <Select
      options={data?.divisorTemplates?.nodes?.map(
        (template: DivisorTemplate) => ({
          label: template?.name,
          id: template?.id,
          divisorItems: template?.divisorItems,
        })
      )}
      isLoading={loading}
      placeholder="Wybierz"
      getValueLabel={({ option }) => option.label}
      getOptionLabel={({ option }) => option?.label}
      $style={{ width: "300px" }}
      {...rest}
    />
  );
}

export function DocumentStatesSelect({
  ...rest
}: SelectProps): React.ReactElement {
  return (
    <Select
      options={[
        { id: "New", label: "Nowy" },
        { id: "InProgress", label: "W obiegu" },
        { id: "Accepted", label: "Zaakceptowany" },
        { id: "Rejected", label: "Odrzucony" },
        { id: "Canceled", label: "Anulowany" },
        { id: "Closed", label: "Zamknięty" },
      ]}
      maxDropdownHeight="300px"
      placeholder="Wybierz"
      {...rest}
    />
  );
}

export function ControlledDocumentStatesSelect({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & SelectProps): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Select
          id={name}
          options={[
            { id: "New", label: "Nowy" },
            { id: "InProgress", label: "W obiegu" },
            { id: "Accepted", label: "Zaakceptowany" },
            { id: "Rejected", label: "Odrzucony" },
            { id: "Canceled", label: "Anulowany" },
          ]}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          value={value}
          maxDropdownHeight="300px"
          placeholder="Wybierz"
          {...rest}
        />
      )}
    />
  );
}

export function ControlledButtonStylesSelect({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & SelectProps): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={[{ id: "neutral" }]}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Select
          id={name}
          options={[
            { id: "positive", label: "Pozytywny", icon: Check },
            { id: "neutral", label: "Neutralny", icon: Send },
            { id: "negative", label: "Negatywny", icon: X },
          ]}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          value={value}
          maxDropdownHeight="300px"
          placeholder="Wybierz"
          getValueLabel={({ option }) => (
            <Block display="flex" alignItems="center">
              {option && <option.icon size={16} />}

              <Block marginLeft="10px">{option.label}</Block>
            </Block>
          )}
          getOptionLabel={({ option }) => (
            <Block display="flex" alignItems="center">
              {option && <option.icon size={16} />}

              <Block marginLeft="10px">{option?.label}</Block>
            </Block>
          )}
          {...rest}
        />
      )}
    />
  );
}

export function AllBudgetsSelect({ ...rest }: SelectProps): React.ReactElement {
  const { allBudgets, budgetsLoading } = useBudgets();

  return (
    <VirtualizedSelect
      options={allBudgets}
      isLoading={budgetsLoading}
      placeholder="Wybierz"
      $style={{ width: "300px" }}
      getOptionLabel={({ option }) => (
        <Block display="flex" flexDirection="column">
          <Block
            display="flex"
            overrides={{ Block: { style: { gap: "5px" } } }}
          >
            <MonoLabelXSmall>
              {option?.id ? `ID: ${option?.id}` : ""}
            </MonoLabelXSmall>
            <MonoLabelXSmall>{option?.budgetName || ""}</MonoLabelXSmall>
          </Block>
          <LabelSmall>{`${option?.positionName}${
            option?.positionName && option?.categoryName ? " | " : " "
          }${option?.categoryPath} ${option?.categoryName}`}</LabelSmall>
        </Block>
      )}
      getValueLabel={({ option }) => (
        <Block display="flex" flexDirection="column">
          <Block
            display="flex"
            overrides={{ Block: { style: { gap: "5px" } } }}
          >
            <MonoLabelXSmall>
              {option?.id ? `ID: ${option?.id}` : ""}
            </MonoLabelXSmall>
            <MonoLabelXSmall>{option?.budgetName || ""}</MonoLabelXSmall>
          </Block>
          <LabelSmall>{`${option?.positionName || ""}${
            option?.positionName && option?.categoryName ? " | " : " "
          }${option?.categoryPath || ""} ${
            option?.categoryName || ""
          }`}</LabelSmall>
        </Block>
      )}
      {...rest}
    />
  );
}

export function ControlledCurrencyExchangeRatesSelect({
  currencyId,
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & Props & { currencyId?: string }): React.ReactElement {
  const [fetchCurrency, { data, loading }] = useLazyQuery(CURRENCIES_SHOW);
  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    if (currencyId) fetchCurrency({ variables: { id: parseInt(currencyId) } });
    if (data?.currency.id === currencyId) setFirstRender(true);
  }, [currencyId, data]);

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value } }) => {
        if (
          data &&
          (!value?.length || value[0]?.currencyId !== currencyId) &&
          !firstRender &&
          data?.currency?.exchangeRates?.length > 0
        ) {
          onChange([
            {
              id: data?.currency?.exchangeRates[0]?.id,
              label: `${data?.currency?.exchangeRates[0]?.value} PLN [${data?.currency?.exchangeRates[0]?.effectiveOn}]`,
              currencyId,
            },
          ]);
          setFirstRender(false);
        }

        return (
          <Select
            options={data?.currency?.exchangeRates?.map(
              (item: { id: number; value: number; effectiveOn: string }) => ({
                id: item.id,
                label: `${item.value} PLN [${item.effectiveOn}]`,
                currencyId,
              })
            )}
            getOptionLabel={({ option }) => (
              <LabelSmall>{option?.label}</LabelSmall>
            )}
            getValueLabel={({ option }) => (
              <LabelSmall>{option?.label}</LabelSmall>
            )}
            isLoading={loading}
            onChange={(params) => params && onChange(params.value)}
            onBlur={onBlur}
            value={value}
            clearable={false}
            {...rest}
          />
        );
      }}
    />
  );
}

export function ControlledBankAccountTypesSelect({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & SelectProps): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={[{ id: BankAccountType.IBAN }]}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Select
          id={name}
          options={[
            { id: BankAccountType.IBAN, label: "IBAN", icon: World },
            { id: BankAccountType.Other, label: "Inny", icon: Circle },
          ]}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          value={value}
          maxDropdownHeight="300px"
          placeholder="Wybierz"
          getValueLabel={({ option }) => (
            <Block display="flex" alignItems="center">
              {option && <option.icon size={16} />}

              <Block marginLeft="10px">{option.label}</Block>
            </Block>
          )}
          getOptionLabel={({ option }) => (
            <Block display="flex" alignItems="center">
              {option && <option.icon size={16} />}

              <Block marginLeft="10px">{option?.label}</Block>
            </Block>
          )}
          {...rest}
        />
      )}
    />
  );
}

export function ControlledBankAccountsSelect({
  control,
  name,
  rules,
  options,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & SelectProps): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Select
          id={name}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          value={value}
          options={[
            { id: 0, label: "(Nowe konto bankowe)" },
            ...((options as Value) ? (options as Value) : []),
          ]}
          maxDropdownHeight="300px"
          placeholder="Wybierz"
          getValueLabel={({ option }) =>
            option?.label || (
              <Block display="flex" alignItems="center">
                <Block display="flex" flexDirection="column">
                  {option?.payeeName && (
                    <MonoLabelXSmall>
                      {option?.payeeName}{" "}
                      {option?.bankName ? `— ${option?.bankName}` : ""}
                    </MonoLabelXSmall>
                  )}
                  <LabelSmall>{`${
                    option?.accountNumber || option?.label
                  }`}</LabelSmall>
                </Block>
              </Block>
            )
          }
          getOptionLabel={({ option }) =>
            option?.label || (
              <Block display="flex" alignItems="center">
                <Block display="flex" flexDirection="column">
                  {option?.payeeName && (
                    <MonoLabelXSmall>
                      {option?.payeeName}{" "}
                      {option?.bankName ? `— ${option?.bankName}` : ""}
                    </MonoLabelXSmall>
                  )}
                  <LabelSmall>{`${
                    option?.accountNumber || option?.label
                  }`}</LabelSmall>
                </Block>
              </Block>
            )
          }
          {...rest}
        />
      )}
    />
  );
}

const ACTIVITY_LOGS_SUBJECTS = [
  { id: "Role", label: "Role" },
  { id: "Dictionary", label: "Dictionary" },
  { id: "User", label: "User" },
  { id: "DictionaryValue", label: "DictionaryValue" },
  { id: "JrwaClassification", label: "JrwaClassification" },
  { id: "OrganizationalUnit", label: "OrganizationalUnit" },
  { id: "Position", label: "Position" },
  { id: "Case", label: "Case" },
  { id: "Address", label: "Address" },
  { id: "Document", label: "Document" },
  { id: "ContractAgreement", label: "ContractAgreement" },
  { id: "RequestForm", label: "RequestForm" },
  { id: "BudgetItem", label: "BudgetItem" },
  { id: "RequestFormItem", label: "RequestFormItem" },
  { id: "FinancialAccountingDocument", label: "FinancialAccountingDocument" },
  { id: "BudgetCategory", label: "BudgetCategory" },
  { id: "BankAccount", label: "BankAccount" },
  { id: "DocumentFlowStep", label: "DocumentFlowStep" },
  { id: "DocumentFlow", label: "DocumentFlow" },
  { id: "File", label: "File" },
  { id: "ShipmentRate", label: "ShipmentRate" },
  { id: "ShipmentContract", label: "ShipmentContract" },
  { id: "DocumentParcel", label: "DocumentParcel" },
  { id: "Currency", label: "Currency" },
  {
    id: "FinancialAccountingDocumentItem",
    label: "FinancialAccountingDocumentItem",
  },
  { id: "DocumentFlowAction", label: "DocumentFlowAction" },
  { id: "Payment", label: "Payment" },
  { id: "InternalAccount", label: "InternalAccount" },
  { id: "DivisorTemplate", label: "DivisorTemplate" },
  { id: "DivisorItem", label: "DivisorItem" },
  { id: "PaymentItem", label: "PaymentItem" },
  { id: "BudgetLedgerItem", label: "BudgetLedgerItem" },
  { id: "ContractPreparationRequest", label: "ContractPreparationRequest" },
  { id: "BillIssuanceRequest", label: "BillIssuanceRequest" },
  { id: "DocumentPickup", label: "DocumentPickup" },
  { id: "RequestFormNote", label: "RequestFormNote" },
];

export function ActivityLogsSubjectSelect({
  disabled,
  ...rest
}: SelectProps): React.ReactElement {
  return (
    <Select
      options={ACTIVITY_LOGS_SUBJECTS}
      placeholder={"Wybierz"}
      disabled={disabled}
      searchable
      {...rest}
    />
  );
}

const ACTIVITY_LOG_ACTIVITIES = [
  { id: "login", label: "Logowanie" },
  { id: "failedLogin", label: "Nieudane logowanie" },
  { id: "logout", label: "Wylogowanie" },
  { id: "created", label: "Utworzenie" },
  { id: "updated", label: "Aktualizacja" },
  { id: "deleted", label: "Usunięcie" },
  { id: "moved", label: "Przeniesienie" },
  { id: "blocked", label: "Zablokowanie" },
  {
    id: "activeDirectoryUsersSynchronizationRequested",
    label: "Wymuszenie synchronizacji z Active Directory",
  },
  {
    id: "activeDirectoryUsersSynchronized",
    label: "Synchronizacja z Active Directory",
  },
  { id: "hidden", label: "Ukrycie" },
  { id: "statusChanged", label: "Zmiana statusu" },
  { id: "revealed", label: "Odczytanie" },
  {
    id: "currencyExchangeRatesSynchronizationRequested",
    label: "Wymuszenie synchronizacji kursów walut",
  },
  {
    id: "currencyExchangeRatesSynchronized",
    label: "Synchronizacja kursów walut",
  },
  { id: "closed", label: "Zamknięcie" },
  { id: "canceled", label: "Anulowanie" },
  { id: "uncanceled", label: "Cofnięcie anulowania" },
  { id: "pickupStarted", label: "Rozpoczęcie odbioru" },
  { id: "pickupConfirmed", label: "Potwierdzenie odbioru" },
  { id: "returnStarted", label: "Rozpoczęcie zwrotu" },
  { id: "returnConfirmed", label: "Potwierdzenie zwrotu" },
  { id: "voided", label: "Unieważnienie" },
  { id: "changedSupervisor", label: "Zmiana prowadzącego" },
  { id: "opened", label: "Otworzenie" },
  { id: "shared", label: "Udostępnienie" },
  { id: "dispatched", label: "Przekazanie" },
  { id: "accepted", label: "Akceptacja" },
  { id: "rejected", label: "Odrzucenie" },
  { id: "shipped", label: "Nadanie" },
  { id: "linked", label: "Powiązanie" },
  { id: "unlinked", label: "Usunięcie powiązania" },
];

export function ActivitiesSelect({
  disabled,
  ...rest
}: SelectProps): React.ReactElement {
  return (
    <Select
      options={ACTIVITY_LOG_ACTIVITIES}
      placeholder={"Wybierz"}
      disabled={disabled}
      {...rest}
    />
  );
}

export function ControlledAvailableFinancialPlansSelect({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & Props): React.ReactElement {
  const { refetch, data, loading } = useQuery(AVAILABLE_FINANCIAL_PLANS);

  useEffect(() => data?.financialPlansAvailable && refetch(), []);

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Select
          id={name}
          options={data?.financialPlansAvailable?.map(
            (financialPlan: AvailableFinancialPlan) => ({
              id: financialPlan?.bazaId,
              year: financialPlan.periodStart.slice(0, 4),
              label: `${financialPlan.periodStart.slice(0, 4)} - ${
                financialPlan?.budgetName
              }, ${financialPlan.categoryName}, ${financialPlan.bazaId}`,
            })
          )}
          isLoading={loading}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          value={value}
          placeholder="Wybierz"
          {...rest}
        />
      )}
    />
  );
}

export function ControlledDocumentsFieldsTableSelect({
  control,
  disabled,
  name,
  rules,
  documentKind,
  documentType,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> &
  SelectProps & {
    documentKind: string;
    documentType: string;
  }): React.ReactElement {
  const [css] = useStyletron();

  const options = renderDocumentFieldsOptions(documentKind, documentType);

  const columns = React.useMemo(
    () => [
      {
        Header: "Nazwa pola",
        accessor: "label",
        Cell: ({ row }: { row: Row<{ id: string; label: string }> }) => (
          <FormattedValue>{`${row.original.label}`}</FormattedValue>
        ),
        disableGlobalFilter: true,
      },
    ],
    []
  );

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => {
        return (
          <Fragment>
            <Select
              options={options}
              getValueLabel={({ option }) => `${option?.label}`}
              getOptionLabel={({ option }) => `${option?.label}`}
              id={name}
              disabled={disabled}
              onChange={(params) => params && onChange(params.value)}
              onBlur={onBlur}
              placeholder="Wybierz"
              overrides={{
                Tag: {
                  component: () => null,
                },
                ...(value?.length > 0 && {
                  Input: {
                    style: {
                      ":empty": {
                        visibility: "visible",
                        height: "auto",
                        overflow: "hidden",
                        "::before": {
                          content: `'Wybrano ${value.length}'`,
                          color: "#666",
                        },
                      },
                    },
                  },
                }),
                ClearIcon: {
                  component: () => null,
                },
                Popover: {
                  props: {
                    placement: "top",
                  },
                },
              }}
              backspaceRemoves={false}
              value={value}
              {...rest}
            />

            {value?.length > 0 && (
              <Block marginTop="16px" overflow="auto">
                <Table<User>
                  columns={[
                    ...columns,
                    {
                      id: "actions",
                      Cell: ({
                        row,
                      }: {
                        row: Row<{ id: string; label: string }>;
                      }) => (
                        <div
                          className={css({
                            display: "flex",
                            justifyContent: "flex-end",
                          })}
                        >
                          <Button
                            kind={KIND.secondary}
                            size={SIZE.mini}
                            $style={{ marginLeft: "6px" }}
                            onClick={() =>
                              onChange(
                                value.filter(
                                  ({ id }: { id: string }) =>
                                    id !== row.original.id
                                )
                              )
                            }
                            startEnhancer={<Trash size={14} />}
                          />
                        </div>
                      ),
                    },
                  ]}
                  data={value}
                  compact
                  stickLastColumn
                  $tableRootStyle={{ maxHeight: "300px" }}
                />
              </Block>
            )}
          </Fragment>
        );
      }}
    />
  );
}
