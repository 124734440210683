import { useStyletron } from "baseui";
import { Modal as BaseModal, ModalProps as BaseModalProps } from "baseui/modal";
import React from "react";
import { StyleObject } from "styletron-react";
import { X } from "tabler-icons-react";

type Props = {
  onClose: () => void;
  isOpen: boolean;
  children: React.ReactNode;
  $style?: StyleObject;
} & BaseModalProps;

export default function Modal({
  onClose,
  children,
  isOpen,
  $style,
  ...props
}: Props): React.ReactElement {
  const [css, theme] = useStyletron();

  return (
    <BaseModal
      onClose={onClose}
      isOpen={isOpen}
      overrides={{
        Root: {
          style: {
            zIndex: 22,
          },
        },
        Dialog: {
          style: {
            borderTopLeftRadius: theme.borders.radius200,
            borderTopRightRadius: theme.borders.radius200,
            borderBottomRightRadius: theme.borders.radius200,
            borderBottomLeftRadius: theme.borders.radius200,
            paddingTop: theme.sizing.scale300,
            paddingBottom: theme.sizing.scale600,
            paddingRight: theme.sizing.scale300,
            paddingLeft: theme.sizing.scale300,
            ...$style,
          },
        },
        DialogContainer: {
          style: {
            fontSize: "12px",
          },
        },
        Close: {
          component: () => (
            <span
              className={css({
                position: "absolute",
                right: theme.sizing.scale600,
                top: theme.sizing.scale600,
                cursor: "pointer",
                color: "#444",
                ":hover": {
                  color: "black",
                },
              })}
              onClick={onClose}
            >
              <X size={20} />
            </span>
          ),
        },
      }}
      {...props}
    >
      {children}
    </BaseModal>
  );
}
