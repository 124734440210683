import { BaseProvider } from "baseui";
import { ToasterContainer } from "baseui/toast";
import { SnackbarProvider } from "notistack";
import React, { HTMLProps } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";
import { X } from "tabler-icons-react";

import theme from "../../baseweb";
import AutoScroll from "../../components/auto-scroll";
import Layout from "../../components/layout";
import Route from "../../components/route";
import {
  SnackbarError,
  SnackbarInfo,
  SnackbarSuccess,
  SnackbarWarning,
} from "../../components/snackbars";
import { AssignmentProvider } from "../../contexts/assignment-context";
import { AuthProvider } from "../../contexts/auth-context";
import { DictionariesProvider } from "../../contexts/dictionaries-context";
import { EuroRateProvider } from "../../contexts/euro-rate-context";
import { FiltersProvider } from "../../contexts/filters-context";
import { LoadingProvider } from "../../contexts/loading-context";
import { PagingProvider } from "../../contexts/paging-context";
import { SubstitutionProvider } from "../../contexts/substitution-context";
import { SupportProvider } from "../../contexts/support-context";
import ROUTES from "../../routes";

export const engine = new Styletron();

export default function App(): React.ReactElement {
  return (
    <StyletronProvider value={engine}>
      <BaseProvider theme={theme}>
        <ToasterContainer
          placement="topRight"
          overrides={{
            Root: {
              style: {
                zIndex: 16,
              },
            },
            ToastBody: {
              style: {
                position: "relative",
                backgroundColor: theme.colors.white,
                color: theme.colors.black,
                borderTopLeftRadius: theme.borders.radius200,
                borderTopRightRadius: theme.borders.radius200,
                borderBottomRightRadius: theme.borders.radius200,
                borderBottomLeftRadius: theme.borders.radius200,
              },
            },
            ToastCloseIcon: (props: HTMLProps<HTMLElement>) => (
              <span
                style={{
                  position: "absolute",
                  top: "17px",
                  right: "16px",
                  cursor: "pointer",
                  display: "inline",
                  lineHeight: 0,
                }}
                {...props}
              >
                <X color={theme.colors.black} size={15} />
              </span>
            ),
          }}
        />

        <LoadingProvider>
          <AuthProvider>
            <SupportProvider>
              <DictionariesProvider>
                <SnackbarProvider
                  maxSnack={3}
                  autoHideDuration={3000}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  dense
                  Components={{
                    success: SnackbarSuccess,
                    error: SnackbarError,
                    warning: SnackbarWarning,
                    info: SnackbarInfo,
                  }}
                  style={{
                    boxShadow: "rgb(0 0 0 / 16%) 0px 4px 16px",
                    borderRadius: "4px",
                    paddingLeft: "12px",
                    paddingRight: "12px",
                    paddingTop: "12px",
                    paddingBottom: "12px",
                    fontSize: "14px",
                  }}
                  preventDuplicate
                >
                  <Router>
                    <AssignmentProvider>
                      <FiltersProvider>
                        <PagingProvider>
                          <AutoScroll />
                          <Switch>
                            {Object.values(ROUTES.auth).map((route, index) => (
                              <Route
                                key={`auth-route` + index}
                                exact={route.exact}
                                path={route.path}
                                component={route.component}
                              />
                            ))}

                            {Object.values(ROUTES.shared).map(
                              (route, index) => (
                                <Route
                                  $shared
                                  key={`shared-route` + index}
                                  path={route.path}
                                  component={route.component}
                                  strict={false}
                                />
                              )
                            )}

                            <SubstitutionProvider>
                              <EuroRateProvider>
                                <Layout>
                                  <Switch>
                                    {Object.values(ROUTES.private.basic).map(
                                      (route, index) => (
                                        <Route
                                          $private
                                          key={`private-route` + index}
                                          exact={route.exact}
                                          path={route.path}
                                          component={route.component}
                                        />
                                      )
                                    )}

                                    {Object.values(ROUTES.private.models).map(
                                      (route) => {
                                        return [
                                          route.index && (
                                            <Route
                                              $private
                                              key={route.name + `-route-index`}
                                              path={
                                                route.path + route?.index?.path
                                              }
                                              component={
                                                route?.index?.component
                                              }
                                              exact
                                            />
                                          ),
                                          route.edit && (
                                            <Route
                                              $private
                                              key={route.name + `-route-edit`}
                                              path={
                                                route.path + route?.edit?.path
                                              }
                                              component={route?.edit?.component}
                                              exact
                                            />
                                          ),
                                          route.editRequestForm && (
                                            <Route
                                              $private
                                              key={
                                                route.name +
                                                `-route-edit-request-form`
                                              }
                                              path={
                                                route.path +
                                                route?.editRequestForm?.path
                                              }
                                              component={
                                                route?.editRequestForm
                                                  ?.component
                                              }
                                              exact
                                            />
                                          ),
                                          route.editContractPreparationRequest && (
                                            <Route
                                              $private
                                              key={
                                                route.name +
                                                `-route-edit-contract-preparation-request`
                                              }
                                              path={
                                                route.path +
                                                route
                                                  ?.editContractPreparationRequest
                                                  ?.path
                                              }
                                              component={
                                                route
                                                  ?.editContractPreparationRequest
                                                  ?.component
                                              }
                                              exact
                                            />
                                          ),
                                          route.editBillIssuanceRequest && (
                                            <Route
                                              $private
                                              key={
                                                route.name +
                                                `-route-edit-bill-issuance-request`
                                              }
                                              path={
                                                route.path +
                                                route?.editBillIssuanceRequest
                                                  ?.path
                                              }
                                              component={
                                                route?.editBillIssuanceRequest
                                                  ?.component
                                              }
                                              exact
                                            />
                                          ),
                                          route.editContractAgreement && (
                                            <Route
                                              $private
                                              key={
                                                route.name +
                                                `-route-edit-contract-agreement`
                                              }
                                              path={
                                                route.path +
                                                route?.editContractAgreement
                                                  ?.path
                                              }
                                              component={
                                                route?.editContractAgreement
                                                  ?.component
                                              }
                                            />
                                          ),
                                          route.editFinancialAccoutingDocument && (
                                            <Route
                                              $private
                                              key={
                                                route.name +
                                                `-route-edit-financial-accouting-document`
                                              }
                                              path={
                                                route.path +
                                                route
                                                  ?.editFinancialAccoutingDocument
                                                  ?.path
                                              }
                                              component={
                                                route
                                                  ?.editFinancialAccoutingDocument
                                                  ?.component
                                              }
                                            />
                                          ),
                                          route.editRequestFormNote && (
                                            <Route
                                              $private
                                              key={
                                                route.name +
                                                `-route-edit-request-form-note`
                                              }
                                              path={
                                                route.path +
                                                route?.editRequestFormNote?.path
                                              }
                                              component={
                                                route?.editRequestFormNote
                                                  ?.component
                                              }
                                            />
                                          ),
                                          route.editIncoming && (
                                            <Route
                                              $private
                                              key={
                                                route.name +
                                                `-route-edit-incoming`
                                              }
                                              path={
                                                route.path +
                                                route?.editIncoming?.path
                                              }
                                              component={
                                                route?.editIncoming?.component
                                              }
                                              exact
                                            />
                                          ),
                                          route.editOutgoing && (
                                            <Route
                                              $private
                                              key={
                                                route.name +
                                                `-route-edit-outgoing`
                                              }
                                              path={
                                                route.path +
                                                route?.editOutgoing?.path
                                              }
                                              component={
                                                route?.editOutgoing?.component
                                              }
                                              exact
                                            />
                                          ),
                                          route.editInternal && (
                                            <Route
                                              $private
                                              key={
                                                route.name +
                                                `-route-edit-internal`
                                              }
                                              path={
                                                route.path +
                                                route?.editInternal?.path
                                              }
                                              component={
                                                route?.editInternal?.component
                                              }
                                              exact
                                            />
                                          ),
                                          route.editStep && (
                                            <Route
                                              $private
                                              key={
                                                route.name + `-route-edit-step`
                                              }
                                              path={
                                                route.path +
                                                route?.editStep?.path
                                              }
                                              component={
                                                route?.editStep?.component
                                              }
                                              exact
                                            />
                                          ),
                                          route.editAction && (
                                            <Route
                                              $private
                                              key={
                                                route.name +
                                                `-route-edit-action`
                                              }
                                              path={
                                                route.path +
                                                route?.editAction?.path
                                              }
                                              component={
                                                route?.editAction?.component
                                              }
                                              exact
                                            />
                                          ),
                                          route.editProcess && (
                                            <Route
                                              $private
                                              key={
                                                route.name +
                                                `-route-edit-process`
                                              }
                                              path={
                                                route.path +
                                                route?.editProcess?.path
                                              }
                                              component={
                                                route?.editProcess?.component
                                              }
                                              exact
                                            />
                                          ),
                                          route.editShipmentContract && (
                                            <Route
                                              $private
                                              key={
                                                route.name +
                                                `-route-edit-shipment-contract`
                                              }
                                              path={
                                                route.path +
                                                route?.editShipmentContract
                                                  ?.path
                                              }
                                              component={
                                                route?.editShipmentContract
                                                  ?.component
                                              }
                                              exact
                                            />
                                          ),
                                          route.editShipmentRate && (
                                            <Route
                                              $private
                                              key={
                                                route.name +
                                                `-route-edit-shipment-rate`
                                              }
                                              path={
                                                route.path +
                                                route?.editShipmentRate?.path
                                              }
                                              component={
                                                route?.editShipmentRate
                                                  ?.component
                                              }
                                              exact
                                            />
                                          ),
                                          route.editDivisorTemplate && (
                                            <Route
                                              $private
                                              key={
                                                route.name +
                                                `-route-edit-divisor-template`
                                              }
                                              path={
                                                route.path +
                                                route?.editDivisorTemplate?.path
                                              }
                                              component={
                                                route?.editDivisorTemplate
                                                  ?.component
                                              }
                                              exact
                                            />
                                          ),
                                          route.editDivisorItem && (
                                            <Route
                                              $private
                                              key={
                                                route.name +
                                                `-route-edit-divisor-item`
                                              }
                                              path={
                                                route.path +
                                                route?.editDivisorItem?.path
                                              }
                                              component={
                                                route?.editDivisorItem
                                                  ?.component
                                              }
                                              exact
                                            />
                                          ),
                                          route.create && (
                                            <Route
                                              $private
                                              key={route.name + `-route-create`}
                                              path={
                                                route.path + route?.create?.path
                                              }
                                              component={
                                                route?.create?.component
                                              }
                                              exact
                                            />
                                          ),
                                          route.createNested && (
                                            <Route
                                              $private
                                              key={
                                                route.name +
                                                `-route-createNested`
                                              }
                                              path={
                                                route.path +
                                                route?.createNested?.path
                                              }
                                              component={
                                                route?.createNested?.component
                                              }
                                              exact
                                            />
                                          ),
                                          route.createStep && (
                                            <Route
                                              $private
                                              key={
                                                route.name + `-route-createStep`
                                              }
                                              path={
                                                route.path +
                                                route?.createStep?.path
                                              }
                                              component={
                                                route?.createStep?.component
                                              }
                                              exact
                                            />
                                          ),
                                          route.createAction && (
                                            <Route
                                              $private
                                              key={
                                                route.name +
                                                `-route-createAction`
                                              }
                                              path={
                                                route.path +
                                                route?.createAction?.path
                                              }
                                              component={
                                                route?.createAction?.component
                                              }
                                              exact
                                            />
                                          ),
                                          route.incoming && (
                                            <Route
                                              $private
                                              key={
                                                route.name +
                                                `-route-documents-incoming`
                                              }
                                              path={
                                                route.path +
                                                route?.incoming?.path
                                              }
                                              component={
                                                route?.incoming?.component
                                              }
                                              exact
                                            />
                                          ),
                                          route.internal && (
                                            <Route
                                              $private
                                              key={
                                                route.name +
                                                `-route-documents-internal`
                                              }
                                              path={
                                                route.path +
                                                route?.internal?.path
                                              }
                                              component={
                                                route?.internal?.component
                                              }
                                              exact
                                            />
                                          ),
                                          route.all && (
                                            <Route
                                              $private
                                              key={
                                                route.name +
                                                `-route-documents-all`
                                              }
                                              path={
                                                route.path + route?.all?.path
                                              }
                                              component={route?.all?.component}
                                              exact
                                            />
                                          ),
                                          route.createIncoming && (
                                            <Route
                                              $private
                                              key={
                                                route.name +
                                                `-route-create-incoming`
                                              }
                                              path={
                                                route.path +
                                                route?.createIncoming?.path
                                              }
                                              component={
                                                route?.createIncoming?.component
                                              }
                                              exact
                                            />
                                          ),
                                          route.createOutgoing && (
                                            <Route
                                              $private
                                              key={
                                                route.name +
                                                `-route-create-outgoing`
                                              }
                                              path={
                                                route.path +
                                                route?.createOutgoing?.path
                                              }
                                              component={
                                                route?.createOutgoing?.component
                                              }
                                              exact
                                            />
                                          ),
                                          route.createInternal && (
                                            <Route
                                              $private
                                              key={
                                                route.name +
                                                `-route-create-internal`
                                              }
                                              path={
                                                route.path +
                                                route?.createInternal?.path
                                              }
                                              component={
                                                route?.createInternal?.component
                                              }
                                              exact
                                            />
                                          ),
                                          route.createContractAgreement && (
                                            <Route
                                              $private
                                              key={
                                                route.name +
                                                `-route-contractAgreementCreate`
                                              }
                                              path={
                                                route.path +
                                                route?.createContractAgreement
                                                  ?.path
                                              }
                                              component={
                                                route?.createContractAgreement
                                                  ?.component
                                              }
                                              exact
                                            />
                                          ),
                                          route.createRequestForm && (
                                            <Route
                                              $private
                                              key={
                                                route.name +
                                                `-route-requestFormCreate`
                                              }
                                              path={
                                                route.path +
                                                route?.createRequestForm?.path
                                              }
                                              component={
                                                route?.createRequestForm
                                                  ?.component
                                              }
                                              exact
                                            />
                                          ),
                                          route.createBillIssuanceRequest && (
                                            <Route
                                              $private
                                              key={
                                                route.name +
                                                `-route-create-bill-issuance-request`
                                              }
                                              path={
                                                route.path +
                                                route?.createBillIssuanceRequest
                                                  ?.path
                                              }
                                              component={
                                                route?.createBillIssuanceRequest
                                                  ?.component
                                              }
                                              exact
                                            />
                                          ),
                                          route.createFinancialAccoutingDocument && (
                                            <Route
                                              $private
                                              key={
                                                route.name +
                                                `-route-create-financial-accouting-document`
                                              }
                                              path={
                                                route.path +
                                                route
                                                  ?.createFinancialAccoutingDocument
                                                  ?.path
                                              }
                                              component={
                                                route
                                                  ?.createFinancialAccoutingDocument
                                                  ?.component
                                              }
                                              exact
                                            />
                                          ),
                                          route.createContractPreparationRequest && (
                                            <Route
                                              $private
                                              key={
                                                route.name +
                                                `-route-create-contract-preparation-request`
                                              }
                                              path={
                                                route.path +
                                                route
                                                  ?.createContractPreparationRequest
                                                  ?.path
                                              }
                                              component={
                                                route
                                                  ?.createContractPreparationRequest
                                                  ?.component
                                              }
                                              exact
                                            />
                                          ),
                                          route.createRequestFormNote && (
                                            <Route
                                              $private
                                              key={
                                                route.name +
                                                `-route-create-request-form-note`
                                              }
                                              path={
                                                route.path +
                                                route?.createRequestFormNote
                                                  ?.path
                                              }
                                              component={
                                                route?.createRequestFormNote
                                                  ?.component
                                              }
                                              exact
                                            />
                                          ),
                                          route.createProcess && (
                                            <Route
                                              $private
                                              key={
                                                route.name +
                                                `-route-create-process`
                                              }
                                              path={
                                                route.path +
                                                route?.createProcess?.path
                                              }
                                              component={
                                                route?.createProcess?.component
                                              }
                                              exact
                                            />
                                          ),
                                          route.createShipmentContract && (
                                            <Route
                                              $private
                                              key={
                                                route.name +
                                                `-route-create-shipment-contract`
                                              }
                                              path={
                                                route.path +
                                                route?.createShipmentContract
                                                  ?.path
                                              }
                                              component={
                                                route?.createShipmentContract
                                                  ?.component
                                              }
                                              exact
                                            />
                                          ),
                                          route.createShipmentRate && (
                                            <Route
                                              $private
                                              key={
                                                route.name +
                                                `-route-create-shipment-rate`
                                              }
                                              path={
                                                route.path +
                                                route?.createShipmentRate?.path
                                              }
                                              component={
                                                route?.createShipmentRate
                                                  ?.component
                                              }
                                              exact
                                            />
                                          ),
                                          route.createDivisorTemplate && (
                                            <Route
                                              $private
                                              key={
                                                route.name +
                                                `-route-create-divisor-template`
                                              }
                                              path={
                                                route.path +
                                                route?.createDivisorTemplate
                                                  ?.path
                                              }
                                              component={
                                                route?.createDivisorTemplate
                                                  ?.component
                                              }
                                              exact
                                            />
                                          ),
                                          route.createDivisorItem && (
                                            <Route
                                              $private
                                              key={
                                                route.name +
                                                `-route-create-divisor-item`
                                              }
                                              path={
                                                route.path +
                                                route?.createDivisorItem?.path
                                              }
                                              component={
                                                route?.createDivisorItem
                                                  ?.component
                                              }
                                              exact
                                            />
                                          ),
                                          route.show && (
                                            <Route
                                              $private
                                              key={route.name + `-route-show`}
                                              path={
                                                route.path + route?.show?.path
                                              }
                                              component={route?.show?.component}
                                              exact
                                            />
                                          ),
                                          route.showFinancialAccoutingDocument && (
                                            <Route
                                              $private
                                              key={
                                                route.name +
                                                `-route-show-financial-accouting-document`
                                              }
                                              path={
                                                route.path +
                                                route
                                                  ?.showFinancialAccoutingDocument
                                                  ?.path
                                              }
                                              component={
                                                route
                                                  ?.showFinancialAccoutingDocument
                                                  ?.component
                                              }
                                              exact
                                            />
                                          ),
                                          route.showRequestForm && (
                                            <Route
                                              $private
                                              key={
                                                route.name +
                                                `-route-show-request-form`
                                              }
                                              path={
                                                route.path +
                                                route?.showRequestForm?.path
                                              }
                                              component={
                                                route?.showRequestForm
                                                  ?.component
                                              }
                                              exact
                                            />
                                          ),
                                          route.showContractPreparationRequest && (
                                            <Route
                                              $private
                                              key={
                                                route.name +
                                                `-route-show-contract-preparation-request`
                                              }
                                              path={
                                                route.path +
                                                route
                                                  ?.showContractPreparationRequest
                                                  ?.path
                                              }
                                              component={
                                                route
                                                  ?.showContractPreparationRequest
                                                  ?.component
                                              }
                                              exact
                                            />
                                          ),
                                          route.showBillIssuanceRequest && (
                                            <Route
                                              $private
                                              key={
                                                route.name +
                                                `-route-show-bill-issuance-request`
                                              }
                                              path={
                                                route.path +
                                                route?.showBillIssuanceRequest
                                                  ?.path
                                              }
                                              component={
                                                route?.showBillIssuanceRequest
                                                  ?.component
                                              }
                                              exact
                                            />
                                          ),
                                          route.showContractAgreement && (
                                            <Route
                                              $private
                                              key={
                                                route.name +
                                                `-route-show-contract-agreement`
                                              }
                                              path={
                                                route.path +
                                                route?.showContractAgreement
                                                  ?.path
                                              }
                                              component={
                                                route?.showContractAgreement
                                                  ?.component
                                              }
                                              exact
                                            />
                                          ),
                                          route.showRequestFormNote && (
                                            <Route
                                              $private
                                              key={
                                                route.name +
                                                `-route-show-request-form-note`
                                              }
                                              path={
                                                route.path +
                                                route?.showRequestFormNote?.path
                                              }
                                              component={
                                                route?.showRequestFormNote
                                                  ?.component
                                              }
                                            />
                                          ),
                                          route.showIncoming && (
                                            <Route
                                              $private
                                              key={
                                                route.name +
                                                `-route-show-incoming`
                                              }
                                              path={
                                                route.path +
                                                route?.showIncoming?.path
                                              }
                                              component={
                                                route?.showIncoming?.component
                                              }
                                              exact
                                            />
                                          ),
                                          route.showOutgoing && (
                                            <Route
                                              $private
                                              key={
                                                route.name +
                                                `-route-show-outgoing`
                                              }
                                              path={
                                                route.path +
                                                route?.showOutgoing?.path
                                              }
                                              component={
                                                route?.showOutgoing?.component
                                              }
                                              exact
                                            />
                                          ),
                                          route.showInternal && (
                                            <Route
                                              $private
                                              key={
                                                route.name +
                                                `-route-show-internal`
                                              }
                                              path={
                                                route.path +
                                                route?.showInternal?.path
                                              }
                                              component={
                                                route?.showInternal?.component
                                              }
                                              exact
                                            />
                                          ),
                                          route.showProcess && (
                                            <Route
                                              $private
                                              key={
                                                route.name +
                                                `-route-show-process`
                                              }
                                              path={
                                                route.path +
                                                route?.showProcess?.path
                                              }
                                              component={
                                                route?.showProcess?.component
                                              }
                                              exact
                                            />
                                          ),
                                          route.showStep && (
                                            <Route
                                              $private
                                              key={
                                                route.name + `-route-show-step`
                                              }
                                              path={
                                                route.path +
                                                route?.showStep?.path
                                              }
                                              component={
                                                route?.showStep?.component
                                              }
                                              exact
                                            />
                                          ),
                                          route.showAction && (
                                            <Route
                                              $private
                                              key={
                                                route.name +
                                                `-route-show-action`
                                              }
                                              path={
                                                route.path +
                                                route?.showAction?.path
                                              }
                                              component={
                                                route?.showAction?.component
                                              }
                                              exact
                                            />
                                          ),
                                          route.showShipmentContract && (
                                            <Route
                                              $private
                                              key={
                                                route.name +
                                                `-route-show-shipment-contract`
                                              }
                                              path={
                                                route.path +
                                                route?.showShipmentContract
                                                  ?.path
                                              }
                                              component={
                                                route?.showShipmentContract
                                                  ?.component
                                              }
                                              exact
                                            />
                                          ),
                                          route.showShipmentRate && (
                                            <Route
                                              $private
                                              key={
                                                route.name +
                                                `-route-show-shipment-rate`
                                              }
                                              path={
                                                route.path +
                                                route?.showShipmentRate?.path
                                              }
                                              component={
                                                route?.showShipmentRate
                                                  ?.component
                                              }
                                              exact
                                            />
                                          ),
                                          route.showSendingListItem && (
                                            <Route
                                              $private
                                              key={
                                                route.name +
                                                `-route-show-sending-list-item`
                                              }
                                              path={
                                                route.path +
                                                route?.showSendingListItem?.path
                                              }
                                              component={
                                                route?.showSendingListItem
                                                  ?.component
                                              }
                                              exact
                                            />
                                          ),
                                          route.showSendingList && (
                                            <Route
                                              $private
                                              key={
                                                route.name +
                                                `-route-show-sending-list`
                                              }
                                              path={
                                                route.path +
                                                route?.showSendingList?.path
                                              }
                                              component={
                                                route?.showSendingList
                                                  ?.component
                                              }
                                              exact
                                            />
                                          ),
                                          route.showDivisorTemplate && (
                                            <Route
                                              $private
                                              key={
                                                route.name +
                                                `-route-show-divisor-template`
                                              }
                                              path={
                                                route.path +
                                                route?.showDivisorTemplate?.path
                                              }
                                              component={
                                                route?.showDivisorTemplate
                                                  ?.component
                                              }
                                              exact
                                            />
                                          ),
                                          route.showDivisorItem && (
                                            <Route
                                              $private
                                              key={
                                                route.name +
                                                `-route-show-divisor-item`
                                              }
                                              path={
                                                route.path +
                                                route?.showDivisorItem?.path
                                              }
                                              component={
                                                route?.showDivisorItem
                                                  ?.component
                                              }
                                              exact
                                            />
                                          ),
                                          route.showBankAccount && (
                                            <Route
                                              $private
                                              key={
                                                route.name +
                                                `-route-show-bank-account`
                                              }
                                              path={
                                                route.path +
                                                route?.showBankAccount?.path
                                              }
                                              component={
                                                route?.showBankAccount
                                                  ?.component
                                              }
                                            />
                                          ),
                                          route.shareEdit && (
                                            <Route
                                              $private
                                              key={
                                                route.name + `-route-share-edit`
                                              }
                                              path={
                                                route.path +
                                                route?.shareEdit?.path
                                              }
                                              component={
                                                route?.shareEdit?.component
                                              }
                                            />
                                          ),
                                          route.share && (
                                            <Route
                                              $private
                                              key={route.name + `-route-share`}
                                              path={
                                                route.path + route?.share?.path
                                              }
                                              component={
                                                route?.share?.component
                                              }
                                            />
                                          ),
                                          route.return && (
                                            <Route
                                              $private
                                              key={route.name + `-route-return`}
                                              path={
                                                route.path + route?.return?.path
                                              }
                                              component={
                                                route?.return?.component
                                              }
                                              exact
                                            />
                                          ),
                                          route.sent && (
                                            <Route
                                              $private
                                              key={route.name + `-route-sent`}
                                              path={
                                                route.path + route?.sent?.path
                                              }
                                              component={route?.sent?.component}
                                              exact
                                            />
                                          ),
                                          route.received && (
                                            <Route
                                              $private
                                              key={
                                                route.name + `-route-received`
                                              }
                                              path={
                                                route.path +
                                                route?.received?.path
                                              }
                                              component={
                                                route?.received?.component
                                              }
                                              exact
                                            />
                                          ),
                                          route.finished && (
                                            <Route
                                              $private
                                              key={
                                                route.name + `-route-finished`
                                              }
                                              path={
                                                route.path +
                                                route?.finished?.path
                                              }
                                              component={
                                                route?.finished?.component
                                              }
                                              exact
                                            />
                                          ),
                                          route.ship && (
                                            <Route
                                              $private
                                              key={route.name + `-route-ship`}
                                              path={
                                                route.path + route?.ship?.path
                                              }
                                              component={route?.ship?.component}
                                              exact
                                            />
                                          ),
                                          route.inFlow && (
                                            <Route
                                              $private
                                              key={
                                                route.name + `-route-in-flow`
                                              }
                                              path={
                                                route.path + route?.inFlow?.path
                                              }
                                              component={
                                                route?.inFlow?.component
                                              }
                                              exact
                                            />
                                          ),
                                          route.shared && (
                                            <Route
                                              $private
                                              key={route.name + `-route-shared`}
                                              path={
                                                route.path + route?.shared?.path
                                              }
                                              component={
                                                route?.shared?.component
                                              }
                                              exact
                                            />
                                          ),
                                        ];
                                      }
                                    )}

                                    {Object.values(ROUTES.fallback).map(
                                      (route, index) => (
                                        <Route
                                          $private
                                          key={`private-route` + index}
                                          exact={route.exact}
                                          path={route.path}
                                          component={route.component}
                                        />
                                      )
                                    )}
                                  </Switch>
                                </Layout>
                              </EuroRateProvider>
                            </SubstitutionProvider>
                          </Switch>
                        </PagingProvider>
                      </FiltersProvider>
                    </AssignmentProvider>
                  </Router>
                </SnackbarProvider>
              </DictionariesProvider>
            </SupportProvider>
          </AuthProvider>
        </LoadingProvider>
      </BaseProvider>
    </StyletronProvider>
  );
}
