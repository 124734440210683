import { ApolloError, useMutation } from "@apollo/client";
import { useStyletron } from "baseui";
import { KIND } from "baseui/button";
import { Check } from "baseui/icon";
import { ModalBody, ModalFooter, ModalHeader } from "baseui/modal";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { AlertCircle, AlertOctagon } from "tabler-icons-react";

import { InputValidationError } from "../../../api";
import Button from "../../../components/button";
import { ControlledCheckbox } from "../../../components/checkbox";
import FormControl from "../../../components/form-control";
import { ControlledInput } from "../../../components/input";
import Modal from "../../../components/modal";
import { formValidation } from "../../../utils/formValidation";
import { DICTIONARIES_VALUE_CREATE } from "../dictionaries.gql";

type FormInputs = {
  name: string;
  value: string;
};

type Props = {
  close: () => void;
  dictionaryId: number | undefined;
  isOpen: boolean;
  onCompleted: () => void;
};

export default function ValueCreate({
  close,
  dictionaryId,
  isOpen,
  onCompleted,
}: Props): React.ReactElement {
  const [css] = useStyletron();

  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<FormInputs>({
    defaultValues: {
      name: "",
      value: "",
    },
  });

  const [createValue, { loading: mutationLoading, error }] = useMutation(
    DICTIONARIES_VALUE_CREATE
  );

  const onSubmit = async ({ name, value }: FormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      await createValue({
        variables: {
          name,
          value,
          dictionaryId,
          isSystem: false,
        },
      });

      enqueueSnackbar({
        message: "Wartość została dodana",
        variant: "success",
      });
      close();
      reset();
      onCompleted();
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal onClose={close} isOpen={isOpen}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>Dodanie wartości</ModalHeader>
        <ModalBody>
          <FormControl
            label="Nazwa"
            required
            error={
              (errors.name && errors.name.message) ||
              (error &&
                error.graphQLErrors[0]?.extensions?.code ===
                  "INPUT_VALIDATION_ERROR" &&
                error.graphQLErrors[0]?.extensions?.validationErrors
                  ?.find((vE: InputValidationError) => vE?.property === "name")
                  ?.errors.map((message: string) => (
                    <div
                      key="error"
                      className={css({
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      })}
                    >
                      {message}
                      <span
                        className={css({
                          color: "#999",
                          marginLeft: "auto",
                          marginRight: "5px",
                        })}
                      >
                        Walidacja serwera
                      </span>
                      <AlertOctagon color="#999" size={12} />
                    </div>
                  ))[0])
            }
            disabled={isLoading}
          >
            <ControlledInput
              control={control}
              name="name"
              placeholder="Nazwa"
              disabled={mutationLoading}
              rules={{
                required: formValidation.messages.required,
              }}
            />
          </FormControl>
          <FormControl
            label="Wartość"
            required
            error={
              (errors.value && errors.value.message) ||
              (error &&
                error.graphQLErrors[0]?.extensions?.code ===
                  "INPUT_VALIDATION_ERROR" &&
                error.graphQLErrors[0]?.extensions?.validationErrors
                  ?.find((vE: InputValidationError) => vE?.property === "value")
                  ?.errors.map((message: string) => (
                    <div
                      key="error"
                      className={css({
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      })}
                    >
                      {message}
                      <span
                        className={css({
                          color: "#999",
                          marginLeft: "auto",
                          marginRight: "5px",
                        })}
                      >
                        Walidacja serwera
                      </span>
                      <AlertOctagon color="#999" size={12} />
                    </div>
                  ))[0])
            }
            disabled={isLoading}
          >
            <ControlledInput
              name="value"
              placeholder="Wartość"
              control={control}
              disabled={mutationLoading}
              rules={{
                required: formValidation.messages.required,
              }}
            />
          </FormControl>
          <FormControl
            label=""
            error={
              (errors.value && errors.value.message) ||
              (error &&
                error.graphQLErrors[0]?.extensions?.code ===
                  "INPUT_VALIDATION_ERROR" &&
                error.graphQLErrors[0]?.extensions?.validationErrors
                  ?.find((vE: InputValidationError) => vE?.property === "value")
                  ?.errors.map((message: string) => (
                    <div
                      key="error"
                      className={css({
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      })}
                    >
                      {message}
                      <span
                        className={css({
                          color: "#999",
                          marginLeft: "auto",
                          marginRight: "5px",
                        })}
                      >
                        Walidacja serwera
                      </span>
                      <AlertOctagon color="#999" size={12} />
                    </div>
                  ))[0])
            }
            disabled={isLoading}
          >
            <ControlledCheckbox
              name="isHidden"
              control={control}
              disabled={mutationLoading}
            >
              Ukryta
            </ControlledCheckbox>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            kind={KIND.secondary}
            $style={{ marginRight: "10px" }}
            onClick={close}
            isLoading={mutationLoading}
          >
            Anuluj
          </Button>
          <Button
            type="submit"
            kind={KIND.primary}
            $style={{ marginLeft: "20px" }}
            isLoading={mutationLoading}
          >
            Zapisz
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
