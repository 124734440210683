import { ApolloError, useMutation } from "@apollo/client";
import { useStyletron } from "baseui";
import { KIND } from "baseui/button";
import { ModalBody, ModalFooter, ModalHeader } from "baseui/modal";
import { useSnackbar } from "notistack";
import React from "react";
import { useForm } from "react-hook-form";
import { AlertOctagon } from "tabler-icons-react";

import { InputValidationError } from "../api";
import { FINANCIAL_PLANS_CREATE } from "../containers/FinancialPlans/financial-plans.gql";
import { useLoading } from "../contexts/loading-context";
import { formValidation } from "../utils/formValidation";
import Button from "./button";
import FormControl from "./form-control";
import Modal from "./modal";
import { ControlledAvailableFinancialPlansSelect } from "./select";

type NewFinancialPlanModalProps = {
  isOpen: boolean;
  close: () => void;
  refetch: () => void;
};

export default function NewFinancialPlanModal({
  isOpen,
  close,
  refetch,
}: NewFinancialPlanModalProps): React.ReactElement {
  const [css] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading } = useLoading();

  type FormInputs = {
    availableFinancialPlans: {
      id: string;
      year: string;
      label: string;
    }[];
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm<FormInputs>({ defaultValues: { availableFinancialPlans: [] } });

  const [createFinancialPlan, { error, loading }] = useMutation(
    FINANCIAL_PLANS_CREATE
  );

  const onSubmit = async ({ availableFinancialPlans }: FormInputs) => {
    try {
      await createFinancialPlan({
        variables: {
          financialPlanCreateInput: {
            year: availableFinancialPlans?.[0]?.year,
          },
        },
      });

      enqueueSnackbar({
        message: "Pomyślnie dodano plan finansowy",
        variant: "success",
      });
      close();
      reset();
      refetch();
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    }
  };

  const onClose = () => {
    close();
    reset();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>Nowy plan finansowy</ModalHeader>

        <ModalBody>
          <FormControl
            error={
              ((errors as any)["availableFinancialPlans"] &&
                (errors as any)["availableFinancialPlans"].message) ||
              (error &&
                error.graphQLErrors[0]?.extensions?.code ===
                  "INPUT_VALIDATION_ERROR" &&
                error.graphQLErrors[0]?.extensions?.validationErrors
                  ?.find(
                    (vE: InputValidationError) =>
                      vE?.property === "availableFinancialPlans"
                  )
                  ?.errors.map((message: string) => (
                    <div
                      key="error"
                      className={css({
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      })}
                    >
                      {message}
                      <span
                        className={css({
                          color: "#999",
                          marginLeft: "auto",
                          marginRight: "5px",
                        })}
                      >
                        Walidacja serwera
                      </span>
                      <AlertOctagon color="#999" size={12} />
                    </div>
                  ))[0])
            }
            disabled={isLoading}
          >
            <ControlledAvailableFinancialPlansSelect
              name="availableFinancialPlans"
              control={control}
              rules={{
                required: formValidation.messages.required,
              }}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            kind={KIND.secondary}
            $style={{ marginRight: "10px" }}
            onClick={onClose}
            disabled={loading}
          >
            Anuluj
          </Button>

          <Button
            disabled={loading || !watch("availableFinancialPlans")?.length}
            isLoading={loading}
            type="submit"
          >
            Dodaj
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
