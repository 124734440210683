import { styled } from "baseui";

const Content = styled(
  "div",
  ({ filtersOffset }: { filtersOffset?: boolean }) => ({
    paddingTop: filtersOffset ? "172px" : "128px",
  })
);

export default Content;
