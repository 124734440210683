import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { ModalBody, ModalFooter, ModalHeader } from "baseui/modal";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { AlertOctagon, DeviceFloppy, FileOff } from "tabler-icons-react";

import { InputValidationError } from "../../../api";
import Button from "../../../components/button";
import Cell from "../../../components/cell";
import ConfirmDialog from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import FormControl from "../../../components/form-control";
import Grid from "../../../components/grid";
import GroupPicker from "../../../components/group-picker";
import Header from "../../../components/header";
import { ControlledInput } from "../../../components/input";
import Modal from "../../../components/modal";
import { ControlledOrganizationalUnitsSelect } from "../../../components/select";
import { useAuth } from "../../../contexts/auth-context";
import { useLoading } from "../../../contexts/loading-context";
import { FIELDS } from "../../../fields.d";
import { checkPermission } from "../../../utils/check-permission";
import { formValidation } from "../../../utils/formValidation";
import { PERMISSIONS } from "../../../utils/permissions";
import { Role } from "../../Roles/roles";
import {
  EditFormInputs,
  ORGANIZATIONAL_UNITS_FIELDS,
} from "../organizational-units.form";
import {
  ORGANIZATIONAL_UNITS_EDIT,
  ORGANIZATIONAL_UNITS_SHOW,
} from "../organizational-units.gql";

export default function OrganizationalUnitsEdit(): React.ReactElement {
  const [enteredGroups, setEnteredGroups] = useState<string[]>([]);
  const [isAddGroupModalOpen, setIsAddGroupModalOpen] = useState(false);
  const [css] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const { user } = useAuth();
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const { isFetching, setIsFetching, isLoading, setIsLoading } = useLoading();

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    setValue,
  } = useForm<EditFormInputs>({
    defaultValues: {
      name: "",
      symbol: "",
    },
  });

  const { data, error: queryError } = useQuery(ORGANIZATIONAL_UNITS_SHOW, {
    variables: { id: id ? parseInt(id) : null },
    fetchPolicy: "cache-first",
  });
  const [editUser, { error }] = useMutation(ORGANIZATIONAL_UNITS_EDIT);

  const onSubmit = async ({
    name,
    symbol,
    description,
    parentId,
    budgetSymbol,
  }: EditFormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      await editUser({
        variables: {
          organizationalUnitUpdateInput: {
            id: id ? parseInt(id) : null,
            name,
            symbol,
            description,
            parentId: parentId && parentId[0]?.id,
            groups: enteredGroups,
            budgetSymbol,
          },
        },
      });

      enqueueSnackbar({
        message: "Zapisano pomyślnie",
        variant: "success",
      });

      history.push(`/organizational-units/${id}`);
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => setIsFetching(true), []);

  useEffect(() => {
    if (data?.organizationalUnit) {
      setEnteredGroups(data.organizationalUnit?.groups);
      setValue("name", data.organizationalUnit.name);
      setValue("symbol", data.organizationalUnit.symbol);
      setValue("budgetSymbol", data.organizationalUnit.budgetSymbol);
      setValue("description", data.organizationalUnit.description);
      setValue(
        "parentId",
        data.organizationalUnit.parentId && [
          {
            id: data.organizationalUnit?.parent?.id,
            name: data.organizationalUnit?.parent?.name,
          },
        ]
      );
      setIsFetching(false);
    }
  }, [data]);

  useEffect(() => {
    if (queryError?.graphQLErrors)
      enqueueSnackbar({
        message: (queryError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [queryError]);

  const {
    control: groupControl,
    handleSubmit: groupHandleSubmit,
    reset: groupReset,
    watch: groupWatch,
    unregister: groupUnregister,
  } = useForm<{
    groupName: string;
  }>({
    defaultValues: {
      groupName: "",
    },
  });
  const watchGroupName = groupWatch("groupName");

  const addGroupSubmit = () => {
    setEnteredGroups(
      enteredGroups ? [...enteredGroups, watchGroupName] : [watchGroupName]
    );
    setIsAddGroupModalOpen(false);
    groupReset();
  };

  if (!checkPermission(PERMISSIONS.organizationalUnit.update))
    return <Redirect to="/" />;

  return (
    <article>
      <Header
        title={data?.organizationalUnit?.name}
        labels={["Jednostki organizacyjne", "Edytowanie"]}
        goBackOption
        buttons={[
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              isDirty ? setIsCancelConfirmDialogOpen(true) : history.goBack(),
          },
          {
            label: "Zapisz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            onClick: handleSubmit(onSubmit),
            disabled: isLoading,
            isLoading: isLoading,
            type: "submit",
            formId: "editOrganizationalUnit",
          },
        ]}
      />
      <Content>
        <form id="editOrganizationalUnit" onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            {ORGANIZATIONAL_UNITS_FIELDS.filter(
              (g) => g.fields.filter((f) => f.edit.visible).length > 0
            ).map((group) => [
              group.label && (
                <Cell key={group.id + `-group`} span={12}>
                  <Block
                    marginTop="scale600"
                    marginBottom="scale200"
                    display="flex"
                    alignItems="center"
                  >
                    <LabelMedium>{group.label}</LabelMedium>
                    {group.id === "groups" && (
                      <Button
                        size={SIZE.mini}
                        kind={KIND.secondary}
                        $style={{ marginLeft: "10px" }}
                        onClick={() => setIsAddGroupModalOpen(true)}
                        type="button"
                      >
                        Dodaj nową
                      </Button>
                    )}
                  </Block>
                  <hr
                    className={css({
                      borderWidth: "0px",
                      height: "1px",
                      backgroundColor: "#eee",
                    })}
                  />
                </Cell>
              ),
              group.fields
                .filter((f) => f.edit.visible)
                .map((item, index) => (
                  <Cell span={item.span || 6} key={group.id + `-field` + index}>
                    <FormControl
                      label={item.label}
                      required={item.edit.required}
                      caption={
                        item.id === "groups" && !enteredGroups?.length
                          ? ""
                          : item.caption
                      }
                      error={
                        ((errors as any)[item.id] &&
                          (errors as any)[item.id].message) ||
                        (error &&
                          error.graphQLErrors[0]?.extensions?.code ===
                            "INPUT_VALIDATION_ERROR" &&
                          error.graphQLErrors[0]?.extensions?.validationErrors
                            ?.find(
                              (vE: InputValidationError) =>
                                vE?.property === item.id
                            )
                            ?.errors.map((message: string) => (
                              <div
                                key="error"
                                className={css({
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                })}
                              >
                                {message}
                                <span
                                  className={css({
                                    color: "#999",
                                    marginLeft: "auto",
                                    marginRight: "5px",
                                  })}
                                >
                                  Walidacja serwera
                                </span>
                                <AlertOctagon color="#999" size={12} />
                              </div>
                            ))[0])
                      }
                      disabled={isLoading}
                    >
                      {isFetching ? (
                        <Skeleton
                          rows={0}
                          height="36px"
                          width="100%"
                          animation
                        />
                      ) : item.type === FIELDS.OrganizationalUnitsSelect ? (
                        <ControlledOrganizationalUnitsSelect
                          control={control}
                          name={item.id}
                          placeholder={item.placeholder}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.GroupPicker ? (
                        <GroupPicker
                          groups={enteredGroups}
                          setGroups={setEnteredGroups}
                        />
                      ) : (
                        <ControlledInput
                          control={control}
                          name={item.id}
                          placeholder={item.placeholder}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                          {...(item.id === "budgetSymbol" &&
                            !user?.roles?.some((role: Role) =>
                              role?.name
                                ?.toLowerCase()
                                ?.includes("administrator systemu")
                            ) && {
                              disabled: true,
                            })}
                        />
                      )}
                    </FormControl>
                  </Cell>
                )),
            ])}
            <ConfirmDialog
              isOpen={isCancelConfirmDialogOpen}
              label="Anulowanie edycji jednostki organizacyjnej"
              close={() => setIsCancelConfirmDialogOpen(false)}
              confirm={() => history.goBack()}
            />
          </Grid>
        </form>
        <Modal
          isOpen={isAddGroupModalOpen}
          onClose={() => {
            setIsAddGroupModalOpen(false);
            groupUnregister("groupName");
            groupReset();
          }}
        >
          <form onSubmit={groupHandleSubmit(addGroupSubmit)}>
            <ModalHeader>Dodanie nowej grupy</ModalHeader>
            <ModalBody>
              <FormControl required label="Nazwa grupy">
                <ControlledInput control={groupControl} name={"groupName"} />
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                kind={KIND.secondary}
                $style={{ marginRight: "12px" }}
                onClick={() => {
                  setIsAddGroupModalOpen(false);
                  groupUnregister("groupName");
                  groupReset();
                }}
              >
                Anuluj
              </Button>
              <Button type="submit" kind={KIND.primary}>
                Dodaj
              </Button>
            </ModalFooter>
          </form>
        </Modal>
      </Content>
    </article>
  );
}
