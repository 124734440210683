import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";

import { useAuth } from "../../../contexts/auth-context";

export default function Logout(): React.ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const { logout } = useAuth();

  useEffect(() => {
    logout();

    enqueueSnackbar({
      message: "Wylogowano pomyślnie",
      variant: "success",
    });
  }, []);

  return <Redirect to={"/login"} />;
}
