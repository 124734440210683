import { FIELDS, FieldsGroup } from "../../fields.d";

export type FormInputs = {
  name: string;
  description: string;
  companyName: string;
  isActive: boolean;
  contractAgreementId?: number;
  contractAgreement?: { id: number; label?: string }[];
  beginAt: string;
  endAt: string;
  shipmentApiSelect?: { id: number; label?: string }[];
  shipmentApi?: string;
};

export const SHIPMENT_CONTRACTS_FIELDS: FieldsGroup[] = [
  {
    id: "basic",
    label: "Podstawowe informacje",
    fields: [
      {
        id: "name",
        label: "Nazwa",
        type: FIELDS.Input,
        span: 6,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "name",
        label: "Nazwa",
        type: FIELDS.Input,
        span: 9,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "beginAt",
        label: "Obowiązuje od",
        type: FIELDS.DatePicker,
        dataType: "date",
        span: 3,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "endAt",
        label: "Obowiązuje do",
        type: FIELDS.DatePicker,
        dataType: "date",
        span: 3,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "validityPeriod",
        label: "Obowiązuje",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "description",
        label: "Opis",
        type: FIELDS.Input,
        span: 6,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "description",
        label: "Opis",
        type: FIELDS.Input,
        span: 9,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "contractAgreement",
        label: "Umowa z dostawcą usługi",
        type: FIELDS.ContractAgreementSelect,
        span: 3,
        show: {
          visible: false,
        },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "isActive",
        label: "Aktywny",
        type: FIELDS.Checkbox,
        dataType: "boolean",
        span: 3,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
    ],
  },
  {
    id: "contractAgreement",
    label: "Umowa z dostawcą usługi",
    fields: [
      {
        id: "contractAgreementId",
        label: "Numer",
        type: FIELDS.Input,
        dataType: "model:documents",
        span: 3,
        show: {
          visible: true,
          accessor: ["contractAgreement", "internalNumber"],
        },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "contractAgreementNumber",
        label: "Numer umowy",
        type: FIELDS.Input,
        span: 3,
        show: {
          visible: true,
          accessor: ["contractAgreement", "documentNumber"],
        },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "contractAgreementDate",
        label: "Data podpisania umowy",
        type: FIELDS.Input,
        dataType: "date",
        span: 3,
        show: {
          visible: true,
          accessor: ["contractAgreement", "documentDate"],
        },
        create: { visible: false },
        edit: { visible: false },
      },
    ],
  },
  {
    id: "shipmentApi",
    label: "Integracja",
    fields: [
      {
        id: "shipmentApiSelect",
        label: "",
        type: FIELDS.ShipmentApiSelect,
        dataType: "model:documents",
        span: 4,
        show: {
          visible: false,
        },
        create: { visible: true },
        edit: { visible: true },
      },
    ],
  },
  {
    id: "shipmentApi",
    label: "Integracja",
    fields: [
      {
        id: "shipmentApi",
        label: "",
        type: FIELDS.Input,
        dataType: "pre",
        span: 4,
        show: {
          visible: true,
        },
        create: { visible: false },
        edit: { visible: false },
      },
    ],
  },
];
