import { BasicFilter, FILTERS } from "./../../filters.d";

export const ROLES_FILTERS: BasicFilter[] = [
  {
    id: "name",
    label: "Nazwa",
    type: FILTERS.Text,
  },
  {
    id: "isSystem",
    label: "Systemowa",
    type: FILTERS.Boolean,
  },
];
