import { BasicFilter, FILTERS } from "./../../filters.d";

export const DOCUMENT_PICKUPS_FILTERS: BasicFilter[] = [
  {
    id: "createdAt",
    label: "Data utworzenia",
    type: FILTERS.Date,
  },
  {
    id: "userId",
    label: "Pracownik odbierający",
    type: FILTERS.User,
  },
  {
    id: "state",
    label: "Status odbioru",
    type: FILTERS.DocumentPickupState,
  },
];

export const DOCUMENT_PICKUPS_FILTERS_DOCUMENTS: BasicFilter[] = [
  {
    id: "internalNumber",
    label: "Numer",
    type: FILTERS.Text,
  },
  {
    id: "documentNumber",
    label: "Numer dokumentu",
    type: FILTERS.Text,
  },
  {
    id: "createdAt",
    label: "Data rejestracji",
    type: FILTERS.Date,
  },
  {
    id: "documentDate",
    label: "Data wystawienia dokumentu",
    type: FILTERS.Date,
  },
  {
    id: "inflowDate",
    label: "Data wpływu",
    type: FILTERS.Date,
  },
  {
    id: "postStampAt",
    label: "Data stempla pocztowego",
    type: FILTERS.Date,
  },
  {
    id: "handDelivered",
    label: "Dostarczony osobiście",
    type: FILTERS.Boolean,
  },
  {
    id: "documentType",
    label: "Typ dokumentu",
    type: FILTERS.DocumentTypes,
    dictionarySystemName: "documentTypes.incoming",
  },
  {
    id: "organizationalUnit",
    label: "Jednostka organizacyjna",
    type: FILTERS.OrganizationalUnit,
  },
];
