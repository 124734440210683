import { gql } from "@apollo/client";

export const INTERNAL_ACCOUNTS_INDEX = gql`
  query(
    $pageSize: Int
    $offset: Int
    $sorting: [InternalAccountSort!]
    $filter: InternalAccountFilter
  ) {
    internalAccounts(
      paging: { limit: $pageSize, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        name
        fundingSource
        limit
        balance
        accountNumber
        currency {
          id
          name
          code
        }
      }
    }
  }
`;

export const INTERNAL_ACCOUNTS_SHOW = gql`
  query($id: Int!) {
    internalAccount(id: $id) {
      id
      name
      fundingSource
      accountNumber
      limit
      currency {
        id
        name
        code
      }
    }
  }
`;

export const INTERNAL_ACCOUNTS_CREATE = gql`
  mutation($internalAccountCreateInput: InternalAccountCreateInput!) {
    internalAccountCreate(
      internalAccountCreateInput: $internalAccountCreateInput
    ) {
      id
    }
  }
`;

export const INTERNAL_ACCOUNTS_UPDATE = gql`
  mutation($internalAccountUpdateInput: InternalAccountUpdateInput!) {
    internalAccountUpdate(
      internalAccountUpdateInput: $internalAccountUpdateInput
    ) {
      id
    }
  }
`;

export const INTERNAL_ACCOUNTS_DELETE = gql`
  mutation($internalAccountDeleteInput: InternalAccountDeleteInput!) {
    internalAccountDelete(
      internalAccountDeleteInput: $internalAccountDeleteInput
    ) {
      id
    }
  }
`;

export const INTERNAL_ACCOUNTS_SELECT_INDEX = gql`
  query {
    internalAccounts(paging: { limit: 100 }) {
      totalCount
      nodes {
        id
        name
        fundingSource
        limit
        accountNumber
        currency {
          id
          name
          code
        }
      }
    }
  }
`;
