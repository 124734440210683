import { ApolloError, useMutation } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND } from "baseui/button";
import { ModalBody, ModalFooter, ModalHeader } from "baseui/modal";
import { LabelMedium, LabelXSmall } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { AlertOctagon } from "tabler-icons-react";

import { InputValidationError } from "../api";
import Button from "../components/button";
import Modal from "../components/modal";
import { DocumentPickupState } from "../containers/DocumentPickups/document-pickups.d";
import { FormInputs } from "../containers/DocumentPickups/document-pickups.form";
import {
  DOCUMENT_PICKUPS_CHANGE_USER,
  DOCUMENT_PICKUPS_CONFIRM_PICKUP,
  DOCUMENT_PICKUPS_CONFIRM_RETURN,
  DOCUMENT_PICKUPS_CREATE,
  DOCUMENT_PICKUPS_RESEND_OTP,
  DOCUMENT_PICKUPS_UPDATE,
} from "../containers/DocumentPickups/document-pickups.gql";
import { User } from "../containers/Users/users";
import { useLoading } from "../contexts/loading-context";
import { formValidation } from "../utils/formValidation";
import FormControl from "./form-control";
import { ControlledInput } from "./input";
import { ControlledUsersSelect } from "./select";

type DocumentPickupModalProps = {
  type: "create" | "edit" | "changeUser" | "confirmPickup" | "confirmReturn";
  pickupId?: string;
  pickupState?: DocumentPickupState;
  user?: User;
  documentIds?: number[];
  isOpen: boolean;
  reloadPage?: () => void;
  setIsOpen: (isOpen: boolean) => void;
};

export default function DocumentPickupModal({
  documentIds,
  isOpen,
  setIsOpen,
  type,
  pickupId,
  pickupState,
  user,
  reloadPage,
}: DocumentPickupModalProps): React.ReactElement {
  const [css] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, setIsLoading } = useLoading();
  const history = useHistory();

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm<FormInputs>({
    defaultValues: {
      users: undefined,
    },
  });

  const [
    createPickup,
    { error: createError, loading: createLoading },
  ] = useMutation(DOCUMENT_PICKUPS_CREATE);

  const [editPickup, { error: editError, loading: editLoading }] = useMutation(
    DOCUMENT_PICKUPS_UPDATE
  );

  const [
    changeUser,
    { error: changeUserError, loading: changeUserLoading },
  ] = useMutation(DOCUMENT_PICKUPS_CHANGE_USER);

  const [
    confirmPickup,
    { error: confirmPickupError, loading: confirmPickupLoading },
  ] = useMutation(DOCUMENT_PICKUPS_CONFIRM_PICKUP);

  const [
    confirmReturn,
    { error: confirmReturnError, loading: confirmReturnLoading },
  ] = useMutation(DOCUMENT_PICKUPS_CONFIRM_RETURN);

  const onSubmit = async ({ users, otp }: FormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      const response =
        type === "create"
          ? await createPickup({
              variables: {
                documentPickupCreateInput: {
                  userId: users && users[0]?.id,
                  documentIds,
                },
              },
            })
          : type === "edit"
          ? await editPickup({
              variables: {
                documentPickupUpdateInput: {
                  id: pickupId ? parseInt(pickupId) : null,
                  userId: users && users[0]?.id,
                  documentIds,
                },
              },
            })
          : type === "changeUser"
          ? await changeUser({
              variables: {
                documentPickupUpdateUserInput: {
                  id: pickupId ? parseInt(pickupId) : null,
                  userId: users && users[0]?.id,
                },
              },
            })
          : type === "confirmPickup"
          ? await confirmPickup({
              variables: {
                documentPickupConfirmPickupInput: {
                  id: pickupId ? parseInt(pickupId) : null,
                  otp,
                },
              },
            })
          : type === "confirmReturn"
          ? await confirmReturn({
              variables: {
                documentPickupConfirmReturnInput: {
                  id: pickupId ? parseInt(pickupId) : null,
                  otp,
                  documentIds,
                },
              },
            })
          : null;

      enqueueSnackbar({
        message:
          type === "create"
            ? "Utworzono pomyślnie"
            : type === "edit"
            ? "Edytowano pomyślnie"
            : type === "changeUser"
            ? "Zmieniono pracownika pomyślnie"
            : type === "confirmPickup"
            ? "Wydano dokumenty pomyślnie"
            : type === "confirmReturn"
            ? "Zwrócono dokumenty pomyślnie"
            : "",

        variant: "success",
      });
      type === "create" &&
        history.push(
          `/document-pickups/${response?.data?.documentPickupCreate?.id}`
        );
      (type === "edit" || type === "changeUser") &&
        history.push(`/document-pickups/${pickupId}`);
      if (type === "confirmPickup" && reloadPage) {
        reloadPage();
        setIsOpen(false);
      }
      type === "confirmReturn" && history.push(`/document-pickups/${pickupId}`);
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError)?.graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (createError?.graphQLErrors)
      enqueueSnackbar({
        message: (createError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [createError]);

  useEffect(() => {
    if (editError?.graphQLErrors)
      enqueueSnackbar({
        message: (editError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [editError]);

  useEffect(() => {
    if (changeUserError?.graphQLErrors)
      enqueueSnackbar({
        message: (changeUserError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [changeUserError]);

  useEffect(() => {
    if (confirmPickupError?.graphQLErrors)
      enqueueSnackbar({
        message: (confirmPickupError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [confirmPickupError]);

  useEffect(() => {
    if (confirmReturnError?.graphQLErrors)
      enqueueSnackbar({
        message: (confirmReturnError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [confirmReturnError]);

  const [
    resendOtp,
    { error: resendOtpError, loading: resendOtpLoading },
  ] = useMutation(DOCUMENT_PICKUPS_RESEND_OTP);

  const resendOtpSubmit = async (): Promise<void> => {
    setIsLoading(true);

    try {
      await resendOtp({
        variables: {
          documentPickupResendOtpInput: {
            id: pickupId,
          },
        },
      });

      enqueueSnackbar({
        message: "Kod został ponownie wysłany na adres e-mail pracownika",
        variant: "success",
      });
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError)?.graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (resendOtpError?.graphQLErrors)
      enqueueSnackbar({
        message: (resendOtpError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [resendOtpError]);

  useEffect(() => {
    if (user)
      setValue("users", [
        {
          label: `${user?.firstName} ${user?.lastName}`,
          ...user,
        },
      ]);
  }, [user]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
        reset();
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>
          {type === "create" && "Nowe wydanie dokumentów"}
          {type === "edit" &&
            `Edycja wydania ${pickupId ? `numer ${pickupId}` : ""}`}
          {type === "changeUser" && "Zmiana pracownika odbierającego"}
          {type === "confirmPickup" && "Wydanie dokumentów"}
          {type === "confirmReturn" && "Zwrot dokumentów"}
        </ModalHeader>
        <ModalBody>
          <Block>
            {(type === "create" || type == "edit" || type == "changeUser") && (
              <form>
                <FormControl
                  label={`${
                    type === "changeUser" ? "Nowy pracownik" : "Pracownik"
                  } odbierający`}
                  required
                  error={
                    type === "create"
                      ? ((errors as any)?.users &&
                          (errors as any)?.users?.message) ||
                        (createError &&
                          createError?.graphQLErrors[0]?.extensions?.code ===
                            "INPUT_VALIDATION_ERROR" &&
                          createError?.graphQLErrors[0]?.extensions?.validationErrors
                            ?.find(
                              (vE: InputValidationError) =>
                                vE?.property === "users"
                            )
                            ?.errors.map((message: string) => (
                              <div
                                key="error"
                                className={css({
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                })}
                              >
                                {message}
                                <span
                                  className={css({
                                    color: "#999",
                                    marginLeft: "auto",
                                    marginRight: "5px",
                                  })}
                                >
                                  Walidacja serwera
                                </span>
                                <AlertOctagon color="#999" size={12} />
                              </div>
                            ))[0])
                      : type === "edit"
                      ? ((errors as any)?.users &&
                          (errors as any)?.users?.message) ||
                        (editError &&
                          editError?.graphQLErrors[0]?.extensions?.code ===
                            "INPUT_VALIDATION_ERROR" &&
                          editError?.graphQLErrors[0]?.extensions?.validationErrors
                            ?.find(
                              (vE: InputValidationError) =>
                                vE?.property === "users"
                            )
                            ?.errors.map((message: string) => (
                              <div
                                key="error"
                                className={css({
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                })}
                              >
                                {message}
                                <span
                                  className={css({
                                    color: "#999",
                                    marginLeft: "auto",
                                    marginRight: "5px",
                                  })}
                                >
                                  Walidacja serwera
                                </span>
                                <AlertOctagon color="#999" size={12} />
                              </div>
                            ))[0])
                      : ((errors as any)?.users &&
                          (errors as any)?.users?.message) ||
                        (changeUserError &&
                          changeUserError?.graphQLErrors[0]?.extensions
                            ?.code === "INPUT_VALIDATION_ERROR" &&
                          editError?.graphQLErrors[0]?.extensions?.validationErrors
                            ?.find(
                              (vE: InputValidationError) =>
                                vE?.property === "users"
                            )
                            ?.errors.map((message: string) => (
                              <div
                                key="error"
                                className={css({
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                })}
                              >
                                {message}
                                <span
                                  className={css({
                                    color: "#999",
                                    marginLeft: "auto",
                                    marginRight: "5px",
                                  })}
                                >
                                  Walidacja serwera
                                </span>
                                <AlertOctagon color="#999" size={12} />
                              </div>
                            ))[0])
                  }
                  disabled={isLoading}
                >
                  <ControlledUsersSelect
                    control={control}
                    name="users"
                    id="users"
                    placeholder="Wybierz"
                    rules={{
                      required: formValidation.messages.required,
                    }}
                  />
                </FormControl>
              </form>
            )}
            {(type === "confirmPickup" || type === "confirmReturn") && (
              <>
                {user && (
                  <div>
                    <LabelMedium
                      $style={{
                        marginTop: "30px",
                        marginBottom: "10px",
                        fontWeight: 400,
                      }}
                    >
                      Pracownik{" "}
                      {`${
                        type === "confirmPickup"
                          ? "odbierający"
                          : type === "confirmReturn"
                          ? "zwracający"
                          : ""
                      }`}
                      :{" "}
                      <strong>{`${user?.firstName} ${user?.lastName}`}</strong>
                    </LabelMedium>
                    <LabelXSmall
                      $style={{
                        marginBottom: "30px",
                        color: "red",
                        fontWeight: 400,
                      }}
                    >
                      Kod został wysłany na adres e-mail pracownika
                    </LabelXSmall>
                  </div>
                )}

                <form>
                  <FormControl
                    label={`Kod ${
                      type === "confirmPickup"
                        ? "odbioru"
                        : type === "confirmReturn"
                        ? "zwrotu"
                        : ""
                    }`}
                    required
                    error={
                      type === "confirmPickup"
                        ? ((errors as any)?.otp &&
                            (errors as any)?.otp?.message) ||
                          (createError &&
                            confirmPickupError?.graphQLErrors[0]?.extensions
                              ?.code === "INPUT_VALIDATION_ERROR" &&
                            confirmPickupError?.graphQLErrors[0]?.extensions?.validationErrors
                              ?.find(
                                (vE: InputValidationError) =>
                                  vE?.property === "otp"
                              )
                              ?.errors.map((message: string) => (
                                <div
                                  key="error"
                                  className={css({
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  })}
                                >
                                  {message}
                                  <span
                                    className={css({
                                      color: "#999",
                                      marginLeft: "auto",
                                      marginRight: "5px",
                                    })}
                                  >
                                    Walidacja serwera
                                  </span>
                                  <AlertOctagon color="#999" size={12} />
                                </div>
                              ))[0])
                        : ((errors as any)?.otp &&
                            (errors as any)?.otp?.message) ||
                          (createError &&
                            confirmReturnError?.graphQLErrors[0]?.extensions
                              ?.code === "INPUT_VALIDATION_ERROR" &&
                            confirmReturnError?.graphQLErrors[0]?.extensions?.validationErrors
                              ?.find(
                                (vE: InputValidationError) =>
                                  vE?.property === "otp"
                              )
                              ?.errors.map((message: string) => (
                                <div
                                  key="error"
                                  className={css({
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  })}
                                >
                                  {message}
                                  <span
                                    className={css({
                                      color: "#999",
                                      marginLeft: "auto",
                                      marginRight: "5px",
                                    })}
                                  >
                                    Walidacja serwera
                                  </span>
                                  <AlertOctagon color="#999" size={12} />
                                </div>
                              ))[0])
                    }
                    disabled={isLoading}
                  >
                    <ControlledInput
                      control={control}
                      name="otp"
                      id="otp"
                      rules={{
                        required: formValidation.messages.required,
                      }}
                      masked
                      mask="999999"
                    />
                  </FormControl>
                </form>
              </>
            )}
          </Block>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            kind={KIND.secondary}
            $style={{ marginRight: "10px" }}
            onClick={() => {
              setIsOpen(false);
              reset();
            }}
            disabled={
              createLoading ||
              editLoading ||
              confirmPickupLoading ||
              confirmReturnLoading ||
              resendOtpLoading ||
              changeUserLoading
            }
          >
            Anuluj
          </Button>

          {pickupState === DocumentPickupState.PickupInProgress && (
            <Button
              type="button"
              kind={KIND.secondary}
              $style={{ marginRight: "10px" }}
              onClick={() => {
                resendOtpSubmit();
              }}
              disabled={
                createLoading ||
                editLoading ||
                confirmPickupLoading ||
                confirmReturnLoading ||
                resendOtpLoading ||
                changeUserLoading
              }
            >
              Wyślij kod ponownie
            </Button>
          )}

          <Button
            disabled={
              createLoading ||
              editLoading ||
              confirmPickupLoading ||
              confirmReturnLoading ||
              resendOtpLoading ||
              changeUserLoading
            }
            isLoading={
              createLoading ||
              editLoading ||
              confirmPickupLoading ||
              confirmReturnLoading ||
              resendOtpLoading ||
              changeUserLoading
            }
            type="submit"
          >
            {type === "create" || type === "edit" || type === "changeUser"
              ? "Zapisz"
              : type === "confirmPickup" || type === "confirmReturn"
              ? "Potwierdź"
              : ""}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
